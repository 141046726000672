<template>
	<vg-modal
		@close="$emit('close')"
		class="vg-verification-modal-viewer"
	>
		<template #header>
			<img src="static/assets/icone/tache-de-verification.png" width="20" height="20"> {{verification.libel_tache}}
		</template>

		<template #body>
			<div>
				<span v-if="verification.isGroupEqp=='0'" class="link-equipement">
					<img v-if="verification.categorie_icon" :src="verification.categorie_icon" width="20">
					<b @click="goToEquipement()">{{verification.libel_equipement}}</b>

					 {{$t("dans")}}
				</span>
				<span><vg-lieu-path-viewer v-model="verification.path"></vg-lieu-path-viewer></span>

			</div>
			<br>
			<div>
				<span class="label">{{$t("date-verification")}} :</span> <span v-html="$vgutils.formatDatetimeCell(verification.dateVerif)"></span>
			</div>
			<div>
				<span class="label">{{$t("qr-code")}} :</span> <span>{{verification.qrCode}}</span>
			</div>
			<div>
				<span class="label">{{$t("operateur")}} :</span> <span>{{verification.username}}</span>
			</div>
			<div>
				<span class="label">{{$t("non-conformite")}} :</span> <span>{{verification.nbNonConformites}}</span>
			</div>
			<div>
				<span class="label">{{$t("commentaire")}} :</span> <span>{{verification.commentaire}}</span>
			</div>
			<hr>
            <div v-if="!$vgutils.isMobile()" style="display:flex;justify-content: flex-end;">
                <action-menu-aggrid
                    :idTableau="'tableau-verification-reponses'"
                    disabledReport
					placement="bottom-right"/>
            </div>
			<vg-button type="info" @click="exportPdf">{{ $t("exporter-pdf") }}</vg-button>

			<tag-grid
				:idTableau="!$vgutils.isMobile()?'tableau-verification-reponses':'tableau-verification-reponses-mobile'"
	            v-model="gridOptions"
	            :columnDefs="columnDefs"
	            :rowData="reponses"
				:defaultHeight="'200px'"
				:showLoadingOverlay="showLoadingOverlay"
				:overlayLoadingText="$t('overlay-loading-text')"
				:overlayNoRowsText="$t('overlay-no-rows-text')" />
			<vg-files-viewer-photo
				:files="verification.photos">
			</vg-files-viewer-photo>
		</template>

		<template #footer>
			<vg-button @click="$emit('close')">{{$t("close")}}</vg-button>
		</template>

	</vg-modal>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import VgFilesViewerPhoto from 'src/components/Vg/Files/VgFilesViewerPhoto.vue';
	import VerificationMixins from "src/mixins/VerificationMixins.js";
	import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
	import ReponseValeurAttendueCellRender from "src/components/Vg/TagGrid/ReponseValeurAttendueCellRender.vue";
	import ReponseCellRender from "src/components/Vg/TagGrid/ReponseCellRender.vue";
    import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
    import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
    import VerificationCheckpointReponseCellRender from "src/components/Vg/TagGrid/VerificationCheckpointReponseCellRender.vue";

	import moment from "moment";

    export default {
        name: 'vg-verification-modal-viewer',
        props: {
            verification: {
				type: Object,
				default: function(){
					return {
			            "id": "50951",
			            "dateVerif": "2019-09-12 08:16:42",
			            "username": "ouvriers-alizes@favron.org",
			            "nbNonConformites": "0",
			            "commentaire": "",
			            "tache_id": "3051",
			            "libel_tache": "Relever temperature",
			            "type_tache": "Relever_Grandeur_physique",
			            "equipement_id": "230871",
			            "libel_equipement": "Ballon Ecs gauche ALIZES",
			            "qrCode": "VLGE411512389224594",
			            "isGroupEqp": "0",
			            "lieu_id": "29071",
			            "libel_lieu": "Local chaufferie",
			            "path": "FpF/PGO-EHPAD les alizés/Logistique/RDC/Local chaufferie"
			        };
				}
			}
        },
		components: {

			VgButton,
			TagGrid,
			VgLieuPathViewer,
			ReponseValeurAttendueCellRender,
			ReponseCellRender,
			VgFilesViewerPhoto,
            ActionMenuAggrid,
			VerificationCheckpointReponseCellRender
		},
		mixins:[
			VerificationMixins
		],
        data: function() {
            return {
				showLoadingOverlay: false,
				gridOptions: {

	            },
				columnDefs: [
					{
						headerName: this.$t("column-header-checkpoint"),//"Point de vérification",
						field: "question",
						colId: "question",
	                    cellRenderer: (params) => {
							if(params.data.type_reponse=="consommation") return this.$t("consommation")+": "+params.data.consommable_name;
							else return params.data.question;
						}
					},
	                {
						headerName: this.$t("column-header-reponse"),//"Réponse",
						field: "reponse",
						colId: "reponse",
	                    cellRenderer: (params) => new ReponseCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("column-header-reponse-attendue"),//"Réponse attendue",
						field: "type_reponse",
						colId: "type_reponse",
	                    cellRenderer: (params) => new ReponseValeurAttendueCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("column-header-statutFM"),//"Réponse attendue",
						field: "statut",
						colId: "statut",
                        cellRenderer:(params)=>{
                            if(params.data.fichesav_id){
                                let maintenanceIcon = "static/assets/icone/maintenance-verte.png";
                                if(!params.data.fichesav_dateFermetureSAV || params.data.fichesav_dateFermetureSAV == ""){
                                    maintenanceIcon = "static/assets/icone/maintenance-rouge.png";
                                }
                                return new IconNameCellRender(
                                        {
                                            propsData: {icon: maintenanceIcon,
                                            dataColumn: params.data.fichesav_statut+" "+params.data.fichesav_dateFermetureSAV,
                                            isMainColumn: false,
                                            route: { router: this.$router, name: '_maintenance', params: {id: params.data.fichesav_id} }}
                                        }
                                ).$mount().$el;
                            }else{
                                return "-";
                            }
                        }
                    }
				],
				reponses:[]
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
		"overlay-loading-text": "Chargement des réponses...",
		"overlay-no-rows-text": "Aucune réponse correspondant au filtrage",
        "close": "Fermer",
        "dans": "dans",
        "date-verification": "Date vérification",
        "operateur": "Opérateur",
        "qr-code": "QR code",
        "non-conformite": "Non conformité",
        "column-header-checkpoint": "Point de vérification",
        "column-header-reponse": "Réponse",
        "column-header-reponse-attendue": "Réponse attendue",
        "exporter-pdf": "éditer un rapport",
        "column-header-statutFM": "Statut fiche curative",
		"consommation": "Consommation"
    },
    "en": {
		"overlay-loading-text": "Loading responses...",
		"overlay-no-rows-text": "No response matching filtering",
        "close": "Close",
        "dans": "in",
        "date-verification": "Verification date",
        "operateur": "Operator",
        "non-conformite": "Non-compliant",
        "column-header-checkpoint": "Check point",
        "column-header-reponse": "Value",
        "column-header-reponse-attendue": "Expected value",
        "qr-code": "QR code",
		"exporter-pdf": "Get a report",
        "column-header-statutFM": "Curative sheet status"
    },
    "th": {
        "close": "ปิด",
        "dans": "ใน",
        "date-verification": "วันที่การตรวจสอบ",
        "operateur": "โอเปอเรเตอร์",
        "non-conformite": "ไม่มีคำร้องเรียน",
        "column-header-checkpoint": "จุดตรวจสอบ",
        "column-header-reponse": "ตอบกลับ",
        "column-header-reponse-attendue": "การตอบกลับที่คาดหวัง"
        }
    }
},
		watch: {

		},
		created: function(){
			if(this.$vgutils.isMobile()) this.columnDefs = this.transformColumnDefsForMobile(this.columnDefs);
		},
		mounted: function(){
			this.fetchReponses();

		},
		methods: {
			transformColumnDefsForMobile:function(columnDefs){
	            columnDefs.forEach((columnDef, i) => {
	                columnDef.hide = true;
	            });
	            columnDefs.unshift({
					cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new VerificationCheckpointReponseCellRender({propsData: {params: params}})
                    .$mount().$el
	            });
	            return columnDefs;
	        },
			fetchReponses:function(){
				this.showLoadingOverlay = true;
				this.VerificationMixins_getVerificationsReponsesById(this.verification.id).then((reponse) =>{
					this.reponses = reponse;
					this.showLoadingOverlay = false;
				})
			},
			goToEquipement:function(){
				this.$router.push({ name: '_equipement_id', params: { id: this.verification.equipement_id } });
			},
			exportPdf(){
				let verificationId = this.verification.id;
				this.VerificationMixins_getPdfFile(verificationId, `verification_${verificationId}`);
			}
		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
.vg-verification-modal-viewer{
	div{
		>.label:first-child{
			color: #9e9da9;
			padding-right: 10px;
		}
	}
	.link-equipement{
		b:hover{
			color:#3999ff;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.vg-lieu-path-viewer{
		display: inline-flex;
	}
}

</style>
