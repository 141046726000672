<template>
	<vg-select v-if="!week"
		v-model="selected"
		:placeholder="$t('select-periode')+value"
		:options="periodes"
		:attributeValue="attributeValue"
		:clearable="false"
		:disabled="disabled"
		@input="$emit('input', selected)">
	</vg-select>
	<div v-else-if="week" class="vg-periode-selector-week">
		<span v-for="day in periodes" :key="day"
			:class="{'day-selected': isDayIsSelected(day)}"
			@click="onClickDay(day)">
			{{day.label}}
		</span>
	</div>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";
    export default {
        name: "vg-periode-selector",
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-periode": "Sélectionner une période",
            "day": "Jour",
            "week": "Semaine",
            "month": "Mois",
            "trimestre": "Trimestre",
            "semestre": "Semestre",
            "year": "Année",
            "lundi-initial": "L",
            "mardi-initial": "M",
            "mercredi-initial": "M",
            "jeudi-initial": "J",
            "vendredi-initial": "V",
            "samedi-initial": "S",
            "dimanche-initial": "D"
        },
        "en": {
            "select-periode": "Select a period"
        }
    }
},
		components: {
			VgSelect
		},
        props: {
			value: {
				type: String,
				default: null
			},
			attributeValue:{
				type: String,
				default: "value"
			},
			disabled: {
				type: Boolean,
				default: false
			},
			day: {
				type: Boolean,
				default: false
			},
			week:{
				type: Boolean,
				default: false
			}
        },
		watch:{
			value: {
				handler: function(val){
					this.selected = val;
				}
			}
		},
        data: function() {
            return {
				selected: this.value
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			isDayIsSelected: function(day){
				return this.value==day.value;	// @TODO ne gère pas encore le multiple
			},
			onClickDay: function(day){
				this.$emit("input", day.value);
			}
		},
        computed: {
			periodes: function(){
				if(this.day){
					return Array(31).fill().map((v,i)=>{
						return {label:i+1, value:i+1};
					});
				}else if(this.week){
					return [
						{label: this.$t("lundi-initial"), value: 1},
						{label: this.$t("mardi-initial"), value: 2},
						{label: this.$t("mercredi-initial"), value: 3},
						{label: this.$t("jeudi-initial"), value: 4},
						{label: this.$t("vendredi-initial"), value: 5},
						{label: this.$t("samedi-initial"), value: 6},
						{label: this.$t("dimanche-initial"), value: 7}
					];
				}else{
					return [
						{label: this.$t("day"), value: "day"},
						{label: this.$t("week"), value: "week"},
						{label: this.$t("month"), value: "month"},
						{label: this.$t("trimestre"), value: "trimestre"},
						{label: this.$t("semestre"), value: "semestre"},
						{label: this.$t("year"), value: "year"}
					];
				}
			},
		}
    };

</script>
<style lang="scss" scoped>
.vg-periode-selector-week{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	>span{
		height: 32px;
		width: 32px;
		text-align: center;
		background-color: #dcdfe6;
		border-radius: 100%;
		color: grey;
		padding-top: 6px;
		margin-right: 10px;
	}
	>span:hover{
		cursor:pointer;
		font-weight: bold;
	}
	.day-selected{
		font-weight: bold;
		color: #fff;
		background-color: #3999ff;
	}
}
</style>
