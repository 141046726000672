export default {
    methods:{
        /**
        * delete  Libelle categorie.
		*
        * @method LibellesCategorieMixins_delete
        * @param Integer idLibelleCategorie
        * @return Promise
        */
        LibellesCategorieMixins_delete: function(idLibelleCategorie){
            return new Promise((resolve, reject)=>{
				this.$rc.delete("/api/libelle-categorie/"+idLibelleCategorie, null, (libelleCategorie) => {
                    resolve({libelleCategorie: libelleCategorie});
                });
			});
        },
        /**
        * get  Libelles categorie.
		*
        * @method LibellesCategorieMixins_getLibellesCategorie
        * @param Metadatas metadatas
        * @return Promise
        */
        LibellesCategorieMixins_getLibellesCategorie: function(metadatas){
            return new Promise((resolve, reject)=>{
                var rc = this.$rc;
                var query = {
                    userId: this.$app.appID,
                    metadatas: metadatas.get()
                };
				this.$rc.get("/api/libelles-categorie", query, (libellesCategorie) => {
                    resolve(libellesCategorie);
                });
			});
        }
    }
};
