<template>
    <vg-app-layout
        :title="$t('title')"
        :icon="'parametre.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :isLoadingState="isLoadingState"
        :importable="false"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters">
        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>
        <template #primary>
            <vg-tabs v-model="focusedTab"
                :color="'gris'"
                :tabs="tabs"
                @tabClicked="onTabClicked"/>
            <vg-consommable-mouvements-table
                :filters="consommableMouvementsFilters" 
                paginate 
                defaultHeight="90%"/>
        </template>
        <template #secondary-filters >
            <vg-filter-date-intervalle :title="$t('filtre-date-mouvement')"
                :defaultStartDate="dateIntervalle.startDate"
                :defaultEndDate="dateIntervalle.endDate"
                @change="onChangeFilterDateMouvement"/>
            <vg-filter-consommable-mouvements-flag v-model="consommableMouvementsFilters.field_flag.value"/>
            <vg-collapse :title="$t('more-filters')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-filter-site v-model="consommableMouvementsFilters.ficheDemandeConsommable_site_id.value"
                        :title="$t('filtre-site-demandeur')"
                        :attributeValue="'id'"/>
                    <vg-filter-service v-model="consommableMouvementsFilters.ficheDemandeConsommable_serviceDemandeur_id.value"
                        :title="$t('filtre-service-demandeur')"
                        showAll/>
                    <vg-filter-consommable-mouvements-demandeur
                        :title="$t('filtre-demandeur')"
                        @input="onChangeFilterDemandeur"/>
                </template>
            </vg-collapse>
            <br>
        </template>
        <template #secondary-widget >
            <vg-tree-consommables @select-node="onSelectNode"
                @remove-selected-node="onRemoveSelectedNode"/>
        </template>
    </vg-app-layout>
</template>

<script>
import VgInput from 'src/components/Vg/VgInput.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

import VgFilterDateIntervalle from 'src/components/Vg/Filters/VgFilterDateIntervalle.vue';
import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
import VgFilterConsommableMouvementsFlag from 'src/components/Vg/Filters/VgFilterConsommableMouvementsFlag.vue';
import VgFilterConsommableMouvementsDemandeur from 'src/components/Vg/Filters/VgFilterConsommableMouvementsDemandeur.vue';
import VgTreeConsommables from 'src/components/Vg/Consommables/VgTreeConsommables.vue';

import VgConsommableMouvementsTable from 'src/components/Vg/Consommables/VgConsommableMouvementsTable.vue';

import StocksMixins from "src/mixins/StocksMixins.js";
import FiltersMixins from "src/mixins/FiltersMixins.js";
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";
export default {
	name:"mouvements-consommables",
	components:{
		VgInput,
		VgTabs,
        VgButton,
        VgCollapse,
        VgTextFilter,
        VgFilterDateIntervalle,
        VgFilterSite,
        VgFilterService,
        VgFilterConsommableMouvementsFlag,
        VgFilterConsommableMouvementsDemandeur,
        VgConsommableMouvementsTable,
        VgTreeConsommables
	},
	mixins:[
        StocksMixins,
        FiltersMixins,
        ConsommablesMixins
	],
	data: function () {
		return {
            consommableMouvementsFilters: {
                consommable_name: {attr: "c.name", value: null, action: "contains", openParenthesis: true},
                consommable_refExterne: {attr: "c.refExterne", value: null, action: "contains", logicalOperator: "OR"},
                consommable_nature: {attr: "c.nature", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                field_dateMouvement: {attr: "cm.dateMouvement", value: null, action: "between"},
                field_flag: {attr: "cm.flag", value: null, action: "equals"},
                ficheDemandeConsommable_site_id: {attr: "fdc.site_id", value: null, action: "equals"},
                ficheDemandeConsommable_serviceDemandeur_id: {attr: "fdc.serviceDemandeur", value: null, action: "equals"},
                field_nomDemandeur: {attr: "fdc.nomDemandeur", value: null, action: "equals"},
                field_user_id: {attr: "cm.user_id", value: null, action: "equals"},
                field_nature: {attr: "c.nature", value: null, action: "equals"},
                flag: {attr: "cm.flag", value: null, action: "equals"},
            },
            searchQuery: "",
            dateIntervalle:{
                startDate: null,
                endDate: null
            },
            focusedTab: "all"
		};
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Mouvements consommables",
            "filtre-date-mouvement": "Date de mouvement",
            "filtre-site-demandeur": "Site demandeur",
            "filtre-service-demandeur": "Service demandeur",
            "filtre-demandeur": "Auteur / Demandeur",
            "more-filters": "Filtres complémentaires",
            "tab-tous": "Tous",
            "tab-entrees": "Entrées",
            "tab-sorties": "Sorties"
        },
        "en": {
            "title": "Consumables movements",
            "filtre-date-mouvement": "Movement date",
            "filtre-site-demandeur": "Requester site",
            "filtre-service-demandeur": "Requester service",
            "filtre-demandeur": "Author / Requester",
            "more-filters": "More filters"
        }
    }
},
	watch: {
        searchQuery: {
            handler: function(newquery){
                this.consommableMouvementsFilters.consommable_name.value = newquery;
                this.consommableMouvementsFilters.consommable_refExterne.value = newquery;
                this.consommableMouvementsFilters.consommable_nature.value = newquery;
            }
        }
	},
	created: function(){
        this.fetchDepots();
	},
	mounted: function(){

	},
	methods:{
        onTabClicked: function(event){
            this.focusedTab = event.name;
            this.consommableMouvementsFilters.flag.value = event.filters.flag.value;
        },
        handleActionExport: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.consommableMouvementsFilters);
            this.ConsommablesMixins_ExportConsommables(metadatas,"consommables-mouvements");
        },
        onSelectNode: function(node){
            this.consommableMouvementsFilters.field_nature.value = node.location;
        },
        onRemoveSelectedNode: function(){
            this.consommableMouvementsFilters.field_nature.value = null;
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.consommableMouvementsFilters[filterName].value = null;
            });
            this.searchQuery = null;
            this.FiltersMixins_deleteStoredCurrentFilters();
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.consommableMouvementsFilters = Object.assign({}, this.consommableMouvementsFilters, filters);
        },
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.consommableMouvementsFilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.consommableMouvementsFilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.consommableMouvementsFilters = filters;
            this.FiltersMixins_saveFilters(this.consommableMouvementsFilters);
        },
        fetchDepots: function(){
            return new Promise((resolve, reject)=>{
                let metadatasDepots = new Metadatas();
                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
                    resolve(datas);
                });
            });
        },
        onChangeFilterDateMouvement: function(data){
            if(data.startDate) this.consommableMouvementsFilters.field_dateMouvement.value = [data.startDate, data.endDate];
            else this.consommableMouvementsFilters.field_dateMouvement.value = null;
            this.dateIntervalle.startDate = data.startDate;
            this.dateIntervalle.endDate = data.endDate;
        },
        onChangeFilterDemandeur: function(data){
            if(data && data.hasOwnProperty("user_id") && data.user_id){
                this.consommableMouvementsFilters.field_nomDemandeur = {attr: "cm.ficheDemandeConsommable_id", value: 1, action: "is_null"};
                this.consommableMouvementsFilters.field_user_id = {attr: "cm.user_id", value: data.user_id, action: "equals"};
            }else{
                this.consommableMouvementsFilters.field_nomDemandeur = {attr: "fdc.nomDemandeur", value: data.demandeur, action: "equals"};
                this.consommableMouvementsFilters.field_user_id = {attr: "cm.user_id", value: null, action: "equals"};
            }
        }
	},
	computed:{
        ...mapGetters({
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant",
            mouvements: 'ConsommableMouvementsStore/getCollection',
            consommable: "ConsommablesStore/getSelectedItem",
            counters: 'ConsommableMouvementsStore/getCounters'
        }),
        pageFilters: function(){
            return ["field_dateMouvement", "field_flag", "ficheDemandeConsommable_site_id", "ficheDemandeConsommable_serviceDemandeur_id", "field_nomDemandeur", "field_user_id"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.consommableMouvementsFilters[filterName] && this.consommableMouvementsFilters[filterName].value!=null && (this.consommableMouvementsFilters[filterName].value && this.consommableMouvementsFilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        hasActiveGlobalFilters: function(){
            let filters = this.consommableMouvementsFilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        },
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        tabs: function() {
            return [{
                    label: this.$t("tab-tous"),
                    name: "all",
                    counter: this.counters.filtered==this.counters.all?this.counters.filtered:this.counters.filtered+"/"+this.counters.all,
                    filters: {
                        flag: { attr: "cm.flag", value: null, action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-entrees"),
                    name: "entrees",
                    counter: this.counters.filtered==this.counters.entrees || this.focusedTab!="entrees"?this.counters.entrees:this.counters.filtered+"/"+this.counters.entrees,
                    filters: {
                        flag: { attr: "cm.flag", value: ["livraison", "inventaire-plus", "bon-dentree", "bon-de-commande-livraison"], action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-sorties"),
                    name: "sorties",
                    counter: this.counters.filtered==this.counters.sorties || this.focusedTab!="sorties"?this.counters.sorties:this.counters.filtered+"/"+this.counters.sorties,
                    filters: {
                        flag: { attr: "cm.flag", value: ["consommation-fm", "inventaire-moins", "bon-de-sortie", "consommation-verification"], action: "equals"}
                    }
                }
            ];
        }
    }
}
</script>

<style lang="scss">

</style>
