<template>
    <div class="tag-cell-render" v-if="typeof tags == 'object'">
        <vg-tag v-for="(tag, index) in tags" :label="tag" :icon="icon" :color="color" :key="index"></vg-tag>
    </div>
    <div class="tag-cell-render" v-else-if="typeof tags == 'string'">
        <vg-tag  :label="tags" :icon="icon" :color="color"></vg-tag>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgTag from "src/components/Vg/VgTag.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"tag-cell-render",
        mixins:[LangMixins],
		props: {
			tags: {
				type: [Array , String],
				required: true,
				default: []
			},
            icon: {
				type: String,
				default: null
			},
            color:{
                type: String,
				default: "blue"
            }
		},
        components:{
            VgTag
        },
        methods:{
        }
    });
</script>

<style lang="scss" scoped>

</style>
