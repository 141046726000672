<template>
    <div class="todo-list">
        <div style="display:flex;flex-direction:column;">
            <h4 v-if="!value.length">{{$t(messageWhenNoItems)}}</h4>
            <div v-else>
                <div
                    class="todo-row"
                    v-for="(item,index) in value"
                    :key="index">
                    <div @click="$emit('click',item)" >
                        <slot name="item"
                              :item="item"
                              v-if="!displayedProperty"
                        >
                       </slot>
                       <div v-else>
                           {{item[displayedProperty]}}
                       </div>
                    </div>

                   <vg-button
                       size="xs"
                       type="default-danger"
                       @click="deleteItem(item)"
                   >
                       {{$t('delete')}}
                   </vg-button>
                </div>
            </div>
            <div>
                <vg-input
                    v-show="!hideInputText"
                    v-model="newItem"
                    style="border-top: none;border-left: none;border-right: none;">
                </vg-input>
                <vg-button
                    size="xs"
                    type="default"
                    :disabled="!newItem || newItem.length<1"
                    @click="addItem"
                    v-show="!hideInputText"
                >
                    {{$t('add')}}
                </vg-button>
            </div>
        </div>

    </div>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

export default {
    name: "vg-todo-list",
    props: {
        value:{
            type:Array,
            default:function(){
                return null;
            }
        },
        displayedProperty:{
            type:String,
            default: null
        },
        hideInputText:{
            type: Boolean,
            default:false
        },
        messageWhenNoItems:{
            type:String,
            default:function(){
                return "no-items";
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-items": "Aucun élément",
            "add": "Ajouter",
            "delete": "Retirer"
        },
        "en": {
            "no-items": "No element",
            "add": "Add",
            "delete": "Remove"
        }
    }
},
    components:{
        VgButton,
        VgInput
    },
    data:function(){
        return {
            items:null,
            newItem:null,
        }
    },
    methods:{
        pushItemInList:function(){
            this.value.push()
        },
        addItem:function(){
            if(this.displayedProperty){
                let item = {};
                item[this.displayedProperty] = this.newItem;
                item.userId = this.$app.appID;
                this.value = this.value.push(item);
            }else{
                this.$emit('addItem', this.newItem);
            }
            this.newItem = null;

        },
        deleteItem:function(item){
            if(this.displayedProperty){
                this.value.splice(this.value.findIndex(v => v[this.displayedProperty] == item[this.displayedProperty]), 1);
            }
            this.$emit('deleteItem', item);
        },
    },
    created:{
    },
    mounted:{},
}
</script>

<style lang="scss" scoped>
.todo-row{

    display:flex;
    justify-content: space-between;
    padding:10px 5px;
    border-bottom:1px solid #d9d9d9;
    width:100%;
}
.todo-row:hover{
    background-color:#a2c2ff;
    color:white;
    cursor:pointer;
}
</style>
