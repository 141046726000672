<template>
    <vg-select
        v-model="selected"
        :options="lieux"
        :attributeValue="attributeValue"
        :attributeLabel="'libel_lieu'"
        :multiple="multiple"
        @input="handleInputChange"
        :disabled="disabled"
        >

    </vg-select>

</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import LieuMixins from "src/mixins/LieuMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: 'vg-lieux-selector',
    mixins:[LieuMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        attributeValue: {
            type:String,
            default:"id"
        },
        multiple:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        }

    },
    data: function() {
        return {
            selected:this.value,
            lieux:[]
        };
    },
    watch:{
        value:{
            handler:function(val){
                this.fetch();
                this.selected = val;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        /**
        * Emit change with array of lieu_s id .
        * @event change
        * @param Array lieux_value
        */
        handleInputChange:function(lieux_value){
            if (!this.multiple) {
                this.$emit("input",lieux_value);
            }
            this.$emit("change",lieux_value);
        },
        fetch: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.filters);
            this.LieuMixins_getLieux(metadatas).then((datas)=>{
                this.lieux = datas.lieux;
                this.$emit("after-feed", this.lieux);
            });
        }
    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-lieux-selector:
    permet de selectionner un lieu, retourne un tableau d'identifiant lieu
    la propriété filters permet d'indiquer à la query quelles critères de filtrage
    appliqué sur la query getLieux
    #### basic usage

    ```html static
        <vg-lieux-selector

            v-model="user.lieux"
            :filters="{'type_lieu':{'attr':'type_lieu','value':'Site','action':'equals'}}"
            @change="handleChangeLieuxRestriction"
        >
        </vg-lieux-selector>
    ```
</docs>
