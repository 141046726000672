<template>
	<tag-grid
		:idTableau="'plan-maintenance-preventive-table'"

		v-model="gridOptions"
		:columnDefs="columnDefs"
		:rowData="getDatas"
		:showLoadingOverlay="showLoadingOverlay"
		:overlayLoadingText="$t('overlay-loading-text')"
		:overlayNoRowsText="$t('overlay-no-rows-text')"
		exportFileName="plan-maintenance-preventive"
		@ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
		@ag-dbl-click="handleDoubleClickRow"
		@ag-click="handleClickRow" />
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";

	import TagGridMixins from "src/mixins/TagGridMixins.js";
	import PlanMaintenanceMixins from "src/mixins/PlanMaintenanceMixins.js";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import EquipementLieuCategorieCellRender from "src/components/Vg/TagGrid/EquipementLieuCategorieCellRender.vue";
	import DatePrevisionnelleCellRender from "src/components/Vg/TagGrid/DatePrevisionnelleCellRender.vue";
	import ProgrammationCellRender from "src/components/Vg/TagGrid/ProgrammationCellRender.vue";
	import EtatRealisationCellRender from "src/components/Vg/TagGrid/EtatRealisationCellRender.vue";
	import PeriodiciteCellRender from "src/components/Vg/TagGrid/PeriodiciteCellRender.vue";
	import RestrictionSiteCellRender from "src/components/Vg/TagGrid/RestrictionSiteCellRender.vue";

    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
import moment from "moment";
    export default {
        name: 'vg-plan-maintenance-preventive-table',
    	props: {
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			showCards:{
				type: Boolean,
				default: false
			},
			isOnlyTaches:{
				type: Boolean,
				default: false
			},
			isOnlyContrats:{
				type: Boolean,
				default: false
			}
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
                    //this.metadatasContrats.setFilters(newfilters);
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			},
		},
		mixins: [
			TagGridMixins,
			PlanMaintenanceMixins
		],
		components: {
			TagGrid,
            VgTextFilter
		},
		i18n:    { "locale":navigator.language,
    "messages": {
		"fr": {
			"overlay-loading-text": "Chargement des données...",
			"overlay-no-rows-text": "Aucune donnée correspondant au filtrage",
			"header-id": "Id",
			"header-intitule": "Intitulé",
			"header-periodicite": "Périodicité",
			"header-sites": "Sites",
			"header-ressource": "Ressource",
			"header-equipement-lieu": "Equipements/lieux",
			"header-dates-previsionnelles": "Dates prévisionnelles",
			"header-tags": "Etiquette",
			"header-programmation": "Programmation",
			"header-etat-realisation": "Etat réalisation",
			"ressource-interne": "Interne",
			"sites-all": "Tous",
			"interne": "( interne )",
			"contrat": "( contrat )"
		}
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
				gridOptions: {},
				columnDefs: [
					{
						headerName: '',
                        colId: 'checkbox',
						width: 50,
						maxWidth: 50,
						checkboxSelection: true,
                        headerCheckboxSelection: true,
						suppressSorting: true,
						cellClass: ["vg-cell-checkbox"],
						pinned: 'left'
					},
					{
						headerName: this.$t("header-id"),
						field: "id",
						colId: "id",
						cellClass: ["vg-cell-ballam-theme"],
	                    hide:true
					},
					{
						headerName: this.$t("header-intitule"),
						width: 260,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new IconNameCellRender({propsData: {
							dataColumn: params.data.hasOwnProperty("name")?params.data.name+" "+this.$t("contrat"):params.data.libel_tache+" "+this.$t("interne"), 
							icon: params.data.hasOwnProperty("icon")?params.data.icon:params.data.categorie.icon, 
							showNoIconSpace: true,
							route: { router: this.$router, name: params.data.hasOwnProperty("name")?"_contrat_id":"_taches", params: {id: params.data.id } }
						}}).$mount().$el
					},
					{
						headerName: this.$t("header-periodicite"),
						width: 160,
						field: "periodicite",
						getQuickFilterText: (params) => {
							if(params.data.hasOwnProperty("periodicite")) return params.data.periodicite;
							else if(params.data.hasOwnProperty("periodiciteIntervention")) return params.data.periodiciteIntervention;
							else return "";
						},
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new PeriodiciteCellRender({propsData: {params: params}}).$mount().$el
					},
	                {
						headerName: this.$t("header-sites"),
						width: 160,
						field: "lieux",
						getQuickFilterText: (params) => {
							if(params.data.lieux && params.data.lieux!=0) return params.data.lieux[0].contratslieux_path;
							else return this.$t("sites-all");
						},
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer: (params) => params.data.lieux && params.data.lieux.length!=0?new RestrictionSiteCellRender({propsData: {lieux: params.data.lieux, attributePath:"contratslieux_path"}}).$mount().$el:this.$t("sites-all")
					},
	                {
						headerName: this.$t("header-ressource"),
						width: 160,
						field: "tiers_id",
						getQuickFilterText: (params) => params.data.hasOwnProperty("tiers_id")?params.data.tiers_name:this.$t("ressource-interne"),
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer: (params) => params.data.hasOwnProperty("tiers_id")?params.data.tiers_name:this.$t("ressource-interne")
					},
					{
						headerName: this.$t("header-equipement-lieu"),
						width: 200,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new EquipementLieuCategorieCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("header-dates-previsionnelles"),
						width: 240,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatePrevisionnelleCellRender({propsData:{params: params}}).$mount().$el
					},
					{
						headerName: this.$t("header-tags"),
						field: "tags",
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t("header-programmation"),
						width: 240,
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new ProgrammationCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("header-etat-realisation"),
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new EtatRealisationCellRender({propsData: {params: params}}).$mount().$el
					}
				],
                metadatas: new Metadatas(),
				focusedDate: moment().format("YYYY-MM-DD"),
				taches: [],
				contrats: [],
            };
        },
		created: function(){
            //this.metadatasContrats.setFilters(this.filters);
			this.fetch();
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		methods: {
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				let filters = Object.assign({}, {}, this.filters);
				this.metadatas.setFilters(filters);
				this.PlanMaintenanceMixins_get(this.focusedDate, this.metadatas).then((datas)=>{
					console.log("SUCCESS FETCH PLAN MAINTENANCE PREVENTIVE", datas);
					this.taches = datas.taches;
					this.contrats = datas.contrats;
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", datas);
				});
			},
		},
		beforeDestroy:function(){

	    },
        computed:{
			...mapGetters({
				
            }),
			getDatas: function(){
				let datas = [];
				if(this.isOnlyTaches) datas = [...this.taches];
				else if(this.isOnlyContrats) datas = [...this.contrats];
				else datas = [...this.taches, ...this.contrats];
				datas.sort((data1, data2) => {
					const libelA = data1.libel_tache!=null?data1.libel_tache:data1.name;
					const libelB = data2.libel_tache!=null?data2.libel_tache:data2.name;
					return libelA.localeCompare(libelB);
				});
				return datas;
			}
        }
    };
</script>
<style lang="scss" scoped>
.vg-contrats-table{
	height: 100%;
}
.cards-container{
	width: 100%;
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	>div{
		width: 100%;
		border: 1px solid whitesmoke;
		padding: 5px;
		cursor: pointer;
	}
	.card-contrat:hover{
		border-left: 4px solid whitesmoke;
	}
	.card-contrat-selected{
		border: 1px solid #059cff;
	}
	.card-contrat-selected:hover{
		border-left: 4px solid #059cff;
	}
}

</style>
