<template lang="html">
    <div class="vg-libelle-editable">
        <div class="label-name">
            {{label}} <div v-show="!$vgutils.isMobile()">:</div>
        </div>
        <div class="data-label edit-version" v-if="edit && isSlotEditEmpty">
            <!-- @slot edit : affichage en mode edit -->
            <slot name="edit"></slot>
        </div>
        <div class="data-label read-version" v-else>
            <!-- @slot read : affichage en mode lecture -->
            <slot name="read"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:"vg-libelle-editable",
    props:{
        /**
         * nom de la donnée a afficher
         */
        label:{
            type:String,
        },
        /**
         * permet la transition entre la vue lecture seul et modification.
         */
        edit:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        isSlotEditEmpty: function(){
            return !!this.$slots["edit"];
        }
    }
}
</script>

<style lang="scss" scoped>
    .vg-libelle-editable{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-bottom: 25px;
        font-size: 14px;
        .label-name{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            white-space: normal;
            text-align: right;
            font-size: 14px;
            color:#BBBCBE;
            margin-right: 5px;
        }
        .data-label{
            width: 60%;
        }
        .edit-version{
        }
        .read-version{}

    }
    @media screen and (min-width: 200px) and (max-width: 640px) {
        .vg-libelle-editable{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            font-size: 11px;
            .label-name{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: normal;
                /*text-align: right;*/
                font-size: 11px;
                color:#BBBCBE;
                margin-right: 5px;
            }
            .data-label{
                width: 60%;
            }
            .edit-version{

            }
            .read-version{}

        }
    }
</style>
