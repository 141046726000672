<template>
    <div class="VgLayoutShowInput" >

        <div class="row">
            <div class="col-md-6" style="text-align:right">
                <label :for="getLabel" class="VgLayoutShowInput-Label">
                    <span> {{getLabel}} :</span>
                </label>
            </div>
            <div class="col-md-6">
                <slot name="input_component" >
                        <input
                            v-if="!readMode"
                            :id="getLabel"
                            v-model="userInput"
                            class="VgLayoutInput-input-mode-read"
                            v-changeBackground="'defineBackgroundColor'"
                            @keyup="$emit('input',$event.target.value)"
                            @change="$emit('input',$event.target.value)"
                            @click="$emit('click')"
                            @blur="$emit('blur')"
                            :disabled="isDisabled"
                            :type="getInputType"
                            style="width:100%;"
                        />
                        <div v-if="readMode">
                            {{userInput}}
                        </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'vg-layout-show-input',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        value: '',
        inputType: {
            type: String,
            default: 'text'
        },
        readMode:{
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            userInput: this.value,
            inputMode: 'read'
        };
    },
    watch: {
        value: function(newValue) {
            this.userInput = newValue;
        }
    },
    computed: {
        isDisabled: function() {
            return this.disabled;
        },
        getLabel: function() {
            return this.label;
        },
        getInputType: function() {
            return this.inputType;
        }
    },
    directives: {
        // NEED REFACTORING : in the directives we loose the context of this., thus i cant make a methods to factorise the changes of backgroundColors
        changeBackground: {
            update: function(el, binding) {
                if (el.value.length) {
                    el.style.backgroundColor = '#ffffff';
                } else {
                    el.style.backgroundColor = '#ffffff';
                }
            },
            inserted: function(el, binding) {
                if (el.value.length) {
                    el.style.backgroundColor = '#ffffff';
                } else {
                    el.style.backgroundColor = '#ffffff';
                }
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .VgLayoutShowInput-Label{
        color: #BBBCBE;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight:bold;
    }

    .VgLayoutInput-input-mode-read {
      -webkit-appearance: none;
      background-image: none;
      box-sizing: border-box;
      color: #40475b;
      font-size: 20px;
      font-family: "Open Sans";
      height: 30px;
      /*line-height: 40px;*/
      outline: none;
      border:0;
      border-bottom :1px solid #dcdfe6 !important;
      /*padding: 0 5px;*/
      /*transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);*/
    }
    .VgLayoutInput-input-mode-read:hover {
      border-bottom :1px solid #429fff !important;
    }
    .VgLayoutInput-input:disabled {
        background: #f3f3f3;
    }
    .VgLayoutInput-input-mode-read:focus {
      border-bottom :1px solid #dcdfe6 !important;
    }
    .VgLayoutShowInput{
        /*margin:5px;*/
        width:100%
    }


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>
