<template>
	<div v-if="!showUpdate" style="display:flex;justify-content:flex-start;align-items:baseline;">
		<span>{{localPattern}}</span>
		<span style="font-size:11px;color:grey;margin-left:2px;">XXXX</span>
		<vg-button v-if="$app.role=='ROLE_ADMIN'" style="margin-left:10px;"
			:type="'info'"
			:size="'sm'"
			@click="pattern=localPattern;showUpdate = true;">
			<i class="fas fa-pen"></i>
		</vg-button>
	</div>
	<div v-else style="display:flex;justify-content:flex-start;align-items:center;">
		<!--div v-for="item in pattern">
			<vg-input v-if="item.type=='string'" style="width: 100px;"
				v-model="item.value"/>
			<vg-select v-else-if="item.type=='date-year' || item.type=='date-month'"  style="width: 150px;"
				v-model="item.value"
				:options="dateTypeOptions"
				:attributeValue="'value'">
			</vg-select>
			<vg-input v-else-if="item.type=='autoincrement-integer'" style="width: 100px;"
				v-model="item.value"
				:inputType="'number'"/>
			<vg-button :size="'sm'"
				:type="'grey'">
				<i class="fas fa-arrow-circle-left"></i>
			</vg-button>
			<vg-button :size="'sm'"
				:type="'grey'">
				<i class="fas fa-arrow-circle-right"></i>
			</vg-button>
		</div-->
		<vg-input v-model="pattern"
			:placeholder="$t('pattern-input-placeholder')"
			style="max-width:300px;"
			/>
		<span style="font-size:11px;color:grey;margin-left:2px;">XXX</span>
		<vg-button style="margin-left:10px;"
			:type="'info'"
			:size="'sm'"
			@click="onSavePattern">
			<i class="fas fa-save"></i>
		</vg-button>
		<vg-button style="margin-left:10px;"
			:type="'grey'"
			:size="'sm'"
			@click="showUpdate = false;">
			<i class="fas fa-times"></i>
		</vg-button>
		<!--vg-input v-model="pattern[0]"/>
		<vg-input v-model="pattern[1]"/>
		<vg-input v-model="pattern[2]"/>
		<vg-input v-model="pattern[3]"/-->
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgInput from "src/components/Vg/VgInput.vue";

	import ParametresMixins from "src/mixins/ParametresMixins.js";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

	export default {
        name: 'vg-bons-de-commande-parametrage-numero',
		components: {
			VgButton,
			VgSelect,
			VgInput
		},
		mixins: [ParametresMixins],
    	props: {

        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "pattern-input-placeholder": "Saisir un text"
        }
    }
},
        data: function() {
            return {
				metadatas: new Metadatas(),
				localPattern: null,
				pattern: null,
				/*localPattern: "BC{date-year}{date-month}{autoincrement-integer:3}",
				pattern: [
					{type: "string", value: "BC"},
					{type: "date-year", value: "YYYY"},
					{type: "date-month", value: "MM"},
					{type: "autoincrement-integer", value: "3"}
				],
				//BC{date-year}{date-month}{autoincrement-integer:3}
				dateTypeOptions: [
					{label: this.$t("date-year"), value: "YYYY"},
					{label: this.$t("date-month"), value: "MM"}
				],*/
				showUpdate: false
            };
        },
		created: function(){
			this.localPattern = this.$app["patternBonDeCommandeNumero"];
			/*if(this.$storage.get("BC-numero-prefix-pattern")) this.localPattern = this.$storage.get("BC-numero-prefix-pattern");
			this.initPattern();*/
		},
		mounted: function(){

		},
		methods: {
			onSavePattern: function(){
				console.log("PATTERN", this.pattern);
				let parameter = {
					patternBonDeCommandeNumero: this.pattern
				};
				this.ParametresMixins_update(parameter).then((datas)=>{
					console.log("AFTER UPDATE PARAMETRES", datas);
					this.pattern = null;
					this.showUpdate = false;
					this.localPattern = this.$app["patternBonDeCommandeNumero"];
				});
			},
			/*initPattern: function(){
				let localPattern = this.localPattern;
				// initialiser this.pattern à partir du localPattern

				//On admet qu'on a 1 seul {date-year} et 1 seul {date-month} et 1 seul {autoincrement-integer}
				//localPattern = BC{date-year}{date-month}{autoincrement-integer:3}
				//je parcours la chaine de caractère localPattern dès que je trouve une { je traite ce que j'avais avant
				let pattern = [];
				let part = "";
				for(let index=0;index<localPattern.length;index++){
					if(localPattern[index] == "}"){
						pattern.push({type: part});
						part = "";
					}else if(localPattern[index] == "{"){
						if(part.length!=0){
							if(part=="date-year") pattern.push({type: "date-year"});
							else if(part=="date-month") pattern.push({type: "date-month"});
							else if(part.search("autoincrement-integer")!=-1) pattern.push({type: "autoincrement-integer", min: part.replace("autoincrement-integer:","")})
							else pattern.push({type: "string", value: part});
							part = "";
						}else{
							continue;
						}
					}else{
						part += localPattern[index];
					}
				}
				this.pattern = pattern;
			}*/
		},
        computed: {
			...mapGetters({

	        }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
