<template>
    <vg-modal
        :title="$t('modal-title')"
        :isSaveDisabled="isLoading"
        @close="close"
        @save="onSave">
        <template #body>
            <form id="equipement-statut-form"
                ref="equipement-statut-form"
                onSubmit="return false;">
                <vg-input>
                    <vg-tags-equipement-statut-selector v-model="selectedStatut"
                        :attributeValue="'label'" 
                        :type="categorie.typeSetStatutEquipement"/>
                </vg-input>
                <vg-input 
                    :label="$t('equipement-label')"
                    v-model="commentaire"
                    inputType="textarea">
                </vg-input>
                <input
                  v-show="false"
                  ref="lieu-submit"
                  type="submit"
                >
            </form>
        </template>
    </vg-modal>
</template>
<script>

import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgTagsEquipementStatutSelector from "src/components/Vg/Selectors/VgTagsEquipementStatutSelector.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-equipement-statut-form',
    components:{
        VgButton,
        VgInput,
        VgTagsEquipementStatutSelector
    },
    mixins: [equipementsMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Modifier statut équipement",
        },
        "en": {
            "modal-title": "Update equipment status",
        }
    }
},
    props: {
        categorie:{
            type: Object,
            default: null
        }
    },
    data: function() {
        return {
            selectedStatut: null,
            commentaire:null,
            isLoading: false
        };
    },
    watch: {
        equipement:{
            handler: function(equipement){
                this.selectedStatut = equipement.statutVerification;
            },
            deep: true
        }
    },
    created: function(){},
    mounted: function(){
        this.selectedStatut = this.equipement.statutVerification;
    },
    computed:{
        ...mapGetters({
            equipement: "EquipementsStore/getSelectedItem"
        })
    },
    methods: {
        close:function(){
            this.$emit("close");
        },
        onSave:function(){
            this.isLoading = true;
            let equipement = {
                id: this.equipement.id,
                statutVerification: this.selectedStatut,
                commentaire: this.commentaire,
                userId: this.$app.appID
            };
            this.equipementsMixins_update(equipement).then((datas)=>{
                console.log("EQUIPEMENTS MIXINS UPDATED EQ", datas);
                this.$emit("save", datas);
            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
