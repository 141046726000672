/**
*
*   VG Utils
*
*
*@author SD
*@version 1.0
*@license MIT
*@class
*
**/

/*

UTILISATION
dans composant: this.$vgutils.maMethodStatic()
dans template: {{$vgutils.maMethodStatic()}}
dans GridOptions:
	import VGutils from 'src/services/VGutils.js';
	VGutils.maMethodStatic()

INTERNATIONALISATION
- initialisation de la langue pour VGutils: this.gridOptions = MaintenanceGridOptions(this.$lang); pour le gridOptions | this.$vgutils.lang=this.$lang dans composant
- récupération de la valeur de la langue dans VGutils: this.lang
ensuite dans VGutils il faut importer le fichier de trad : import monFichierDeTrad from 'src/components/internationalisation/monFichierDeTrad.js';
et là où tu veux implémenter la trad dans VGutils : monFichierDeTrad[this.lang][....] (edited)
*Exemple pour la trad utilisée comme ceci dans un composant vuejs : this.$t("fm.desktop.Header-Title")
dans VGutils tu fais monFichierDeTrad[this.lang].fm.desktop.Header-Title
*/

import moment from "moment";
import accounting from "accounting";

class VGutils{

	/**
	 * @constructor
	 **/
    constructor(_options) {
        this.lang = null;
		this.appID = null;
    }

	/**
	* get statut
	* @param {object} app
	* @return {string} statut
	**/
	static getStatut(app){
		if(app.underSupervisor) return "Supervisor";
		else if(app.isSupervisor) return "Resolue";
		else return "Resolue";
	}


	/**
	* get __action pour cloture fm
	* @param {object} app
	* @return {string} statut
	**/
	static getFM__action(app){
		if(app.underSupervisor) return "fermeture";
		else if(app.isSupervisor) return "validation";
		else return "fermeture";
	}

	static sortAsc(table, attrToOrder){
		return table.sort(function compare(a,b) {
			if (a[attrToOrder] < b[attrToOrder]) return -1;
			if (a[attrToOrder] > b[attrToOrder]) return 1;
			return 0;
		});
	}



    /**
    * Check si device mobile/desktop, renvoie true si device mobile
    * @return {boolean}
    **/
    static isMobile(){
        if( navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)
        ){
            return true;
        }
            return false;
	}
	/**
	 * @returns boolean
	 */
	static isIOS(){
        if( navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
        ){
            return true;
        }
            return false;
	}
	/**
	 * @returns boolean
	 */
	static isAndroid(){
        if( navigator.userAgent.match(/Android/i) ){
            return true;
        }
            return false;
	}

	static formatNumber2decimals(value){
		return accounting.formatMoney(value, "", 2, " ", ",");	// ATTENTION HACK PROVISOIRE !!!
	}
	static round2decimal(value){
		/*var result =*/ return Math.round(value * 100) / 100;
		//return result.toFixed(2);
	}

	static randomColor() {	// random color #FFFFFF
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	/**
	 * affiche le chemin dans les tableaux
	 * @param {string} path
	 * @return {string} html
	**/
	static formatPathCell(path){
		console.log("PATH =",path);
		var pathSplitted = path.split("/");
		pathSplitted.shift();// remove organisation = level 1
		var piece = pathSplitted.pop();
		return '<span class="vg-font-size-11 muted" style="color: grey;">'+pathSplitted.join("/")+'/ </span><span class="vg-font-size-15">'+piece+'</span>';
	}

	/*
	 * affiche le chemin sans la pièce
	 * @param String path
	 * @return String
	*/
	static formatPathWithoutRoom(path){
		var pathSplitted = null;
		var newPath = null;
		pathSplitted = path.split("/");
		pathSplitted.shift(); // remove organisation
		pathSplitted.pop(); // remove room
		newPath = pathSplitted.join("/");
		return newPath;
	}

	/*
	 * affiche le chemin vers la pièce sans l'organisation
	 * @param String path
	 * @return String
	*/
	static getFullPath(path){
		var pathSplitted = null;
		var newPath = null;
		pathSplitted = path.split("/");
		pathSplitted.shift(); // remove organisation
		newPath = pathSplitted.join("/");
		return newPath;
	}

	/*
	 * affiche le chemin sans la pièce
	 * @param String path
	 * @return String
	*/
	static getPathWithoutEnd(path){
		var patharray = path.split("/");
		patharray.pop();
		return patharray.join("/");
	}
	/*
	 * affiche uniquement le chemin de la pièce
	 * @param String path
	 * @return String
	*/
	static getPathEnd(path){
		if(path){
			var patharray = path.split("/");
			return patharray.pop();
		}else{
			return "";
		}
	}
  /*
	 * affiche le chemin dans les v-html pour page zoom equipement & lieux
	 * @param {string} path
	 * @param {string} || Integer level specify level type or level number
	 * @return {string} html
	**/
    static pathHtml(path){
      var lastPath = this.getPathEnd(path);
      var beginPath = this.getPathWithoutEnd(path);

      var htmlBeginPath = '<span class="vg-path-start">'+beginPath+'/ </span>';
      var htmlendPath = '<span class="vg-path-end">'+lastPath+'</span>';
      return htmlBeginPath + htmlendPath;
    }

	/*
	 * affiche le chemin dans les tableaux
	 * @param {string} path
	 * @param {string} || Integer level specify level type or level number
	 * @return {string} html
	**/
	static pathLevel(path, level){
		var levels = {"organisation":0,"site":1,"batiment":2,"etage":3,"piece":4};
		var lvl = typeof(level)=="string"?levels[level]:level;
		var splitted = path.split("/");
		if(lvl<splitted.length){
			return splitted[lvl];
		}else{
			return "-";
		}
	}


	/**
	 * format datetime dd/mm/yyyy hh:mm pour les cells
	 * @param {string} dt datetime
	 * @return {string}
	**/
	static formatDatetimeCell(dt, isrender=true){
		if(dt && isrender){
			var dtformat = moment(dt).format('DD/MM/YYYY | HH:mm');
			dtformat = dtformat.split("|");
			return dtformat[0]+'<span style="font-size:11px;"> '+dtformat[1]+'</span>';
			//return moment(dt).fromNow(true)+"<br>"+moment(dt).calendar();
		}else{	// default value
			return "-";
		}
	}
	static formatDatetime(dt, isrender=true){
		var dtformat = moment(dt).format('DD/MM/YYYY | HH:mm');
		dtformat = dtformat.split("|");
		return dtformat[0]+'<span style="font-size:11px;"> '+dtformat[1]+'</span>';

		//return moment(dt).format('DD/MM/YYYY hh:mm');
	}
	/**
	 * ATTENTION date 2018-05-09T10:38:22+00:00  =>  .utc() || 2018-05-09 10:38:22 => !utc
	**/
	static formatDate(dt, isrender=true){
		//// //console.log(dt, moment(dt).utc().format('HH:mm:ss'));
        if(!dt) return "";
		if(dt.search("T")!=-1) return moment(dt).utc().format('DD/MM/YYYY à HH:mm');
		else return moment(dt).format('DD/MM/YYYY à HH:mm');
	}
	/**
	 * Récupère la date à partir d'un timestamp
	 * @param {string} dt datetime
	 * @return {string}
	**/
	static getDate(dt, isrender=true){
		if(dt && isrender && moment(dt).isValid()){
			var dtformat = moment(dt).format('DD/MM/YYYY');
			return dtformat;
		}else{	// default value
			return "";
		}
	}

	/**
	* format date YYYY-MM-DD to DD-MM-YYYY
	* @param {string} d
	* @return {string}
	**/
	static formatDateYMDtoDMY(d){
		return moment(d,"YYYY-MM-DD").format("DD/MM/YYYY");
	}
	/**
	* format date YYYY-MM-DD hh:mm:ss to DD-MM-YYYY hh:mm
	* @param {string} d
	* @return {string}
	**/
	static formatDatetimeYMDtoDMY(d){
		return d && d.search("T")!=-1?moment(d).format("DD/MM/YYYY à HH:mm"):moment(d,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY à HH:mm");
	}

	/**
	 * Récupère l'heure à partir d'un timestamp
	 * @param {string} dt datetime
	 * @return {string}
	**/
	static getHour(dt, isrender=true){
        // à revoir je ne sais pas pourquoi mon timezone est négatif
        // j'ai hacké en divisant par le -60
        let timezone = moment().zone()/-60;
		if(dt && isrender){
            if(dt.search("T")!=-1){
                moment
               return moment(dt).format('HH:mm')
            }
            else {
                return moment(dt).format('HH:mm')
            }
		}else{	// default value
			return "-";
		}
	}

	/**
	 * Récupère la date et l'heure à partir d'un timestamp
	 * @param {string} dt datetime
	 * @return {string}
	**/
	static formatDatetimeFullDate(dt, isrender=true){
		if(dt && isrender){
			var date = moment(dt).format('DD/MM/YYYY');
			var heure = moment(dt).format('HH:mm');
			return date + " à " + heure;
		}else{	// default value
			return "-";
		}
	}


	/*----------------------------------- MAINTENANCE ------------------------------------*/

	/**
	 * Format fm object to HTML with icon for cell
	 * @param {object} fm
     * @return {string}
	**/
	static descriptionFormatObjectToHtml(fm){
		/*if(fm.isGEP==="1" && fm.composants && fm.composants.length!=0){
			var icon = "/static/assets/icone/composant/"+fm.composants[0].replace(' ','-').toLowerCase()+".png";
			return icon && icon!=""?
				"<img class='refimg' style=\"margin-right:15px; vertical-align: inherit;\" src=\""+icon+"\"/  onerror=\"this.style.visibility='hidden'\">  "+fm.composants[0] + " " + fm.commentaireClient:
				fm.composants[0] + " " + fm.commentaireClient;
		}else{
            var divPadd = "<div style='height:30px;width:30px;float:left;margin-right:16px;'></div>";
            var allDiv = "<div>"+ divPadd + fm.libelEquipement + " " + fm.commentaireClient + "</div>";
			return allDiv;
		}*/
		if(fm.isGEP && fm.isGEP=="1" && fm.idComposant && fm.iconComposant){
			return '<img class="ag-grid-row-refimg" style="margin-right:15px; vertical-align: inherit;" src="'+fm.iconComposant+'"  onerror="this.style.visibility=\'hidden\'" > '+fm.libelComposant+' '+fm.commentaireClient;
		}else if(fm.isGEP && fm.isGEP=="1" && !fm.idComposant && fm.libelComposant){	// aciennes FMs uniquement
			return fm.libelComposant+' '+fm.commentaireClient;
		}else if(fm.isGEP && fm.isGEP=="1" && !fm.idComposant && !fm.libelComposant){
			var splittedCommentaireClient = fm.commentaireClient.split("#!/");
			return splittedCommentaireClient[0].replace("/!#","")+" "+splittedCommentaireClient[1];
		}else{
            //var divPadd = "<div style='height:30px;width:30px;float:left;margin-right:16px;'></div>";
            //var allDiv = "<div>"+ divPadd +  + "</div>";
			return fm.libelEquipement + " " + fm.commentaireClient;
		}
	}
	/**
	 * Format fm object to HTML without icon for cell
	 * @param {object} fm
     * @return {string}
	**/
	static descriptionFormatObjectToHtmlWithoutIcon(fm){
		if(fm.isGEP && fm.isGEP==="1" && fm.libelComposant ){
			//var icon = "/static/assets/icone/composant/"+fm.composants[0].replace(' ','-').toLowerCase()+".png";
			return fm.libelComposant + " " + fm.commentaireClient;
		}else if(fm.isGEP && fm.isGEP==="1" && !fm.libelComposant ){
			var splittedCommentaireClient = fm.commentaireClient.split("#!/");
			return splittedCommentaireClient[0].replace("/!#","")+" "+splittedCommentaireClient[1];
		}else{
			return fm.libelEquipement + " " + fm.commentaireClient;
		}
	}

	/********************************************************/
	/********** CATEGORIE *******************/
	/**
	 * Retourne formatage cellule pour la catégorie (page catégorie desktop)
	 * @param {object} params.data
     * @return {string}
	**/
	static categorieCellRenderer(data){
		var icon = '<div style="float:left;margin-right:15px;"> <img class="ag-grid-row-refimg" src="'+data.icon+'" onerror="this.style.visibility=\'hidden\';"> </div>';
		return icon+'<div style="float:left;margin-top:8px;margin-right:10px;background-color:'+data.codeCouleur+';width:10px;height:10px;"></div> '
			+'<span style="font-weight:bold;">'+data.libelleCatgorie+'</span>';
	}

	/**
	 * Retourne formatage tags pour une cellule ag-grid
	 * @param {String} tags
	 * @param {String} separator
     * @return {string}
	**/
	static tagsCellRenderer(tags, separator){
		var tagsrender = "";
		if(tags && tags.length!=0) {
			var splittedtags = tags.split(separator);
			splittedtags.forEach(function(tag){
				tagsrender+='<span style="padding: 0px 5px;border:solid 1px lightgrey;">'+tag+'</span> ';
			});
		}
		return tagsrender;
	}
	/**
	 * Retourne formatage tags pour une cellule ag-grid
	 * @param {String} tags
	 * @param {String} separator
	 * @param {String} color
     * @return {string}
	**/
	static customTagsCellRenderer(tags, separator=",", color="#777"){
		var tagsrender = "";
		if(tags && tags.length!=0) {
			var splittedtags = tags.split(separator);
			splittedtags.forEach(function(tag){
				tagsrender+='<small style="padding:2px 5px;color:'+color+';" data-toggle="tooltip" title="'+tag+'" ><i class="fa fa-tag"></i> '+tag+'</small> ';
			});
		}
		return tagsrender;
	}


  /**
   * retourne columnDef avec colonne caché
   */
  static columnVisibleTagGrid(api){

      var columnDef = api.columnController.columnDefs;
      var gridColumns = api.columnController.gridColumns;
      for (var pos = 0; pos < gridColumns.length; pos++) {
          columnDef[pos].hide = !gridColumns[pos].visible;

      }

      return columnDef ;
  }

}
export default VGutils;
