<template>
	<vg-select v-model="selectedStatut"
		:options="statuts"
		:attributeLabel="'label'"
		:attributeValue="'value'"
		:clearable="clearable"
		:placeholder="$t('placeholder')"
		@input="$emit('input',selectedStatut)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-intervention-statut-selector',
		components: {
			VgSelect
		},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			*
			*/
			clearable: {
				type: Boolean,
				default: false
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "intervention-prevue": "Intervention prévue",
            "intervention-realisee": "Intervention réalisée",
            "placeholder": "Statut"
        },
        "en": {
            "intervention-prevue": "Planned intervention",
            "intervention-realisee": "Intervention done",
            "placeholder": "Status"
        },
        "th": {
            "intervention-prevue": "การแทรกแซงที่วางแผน",
            "intervention-realisee": "การแทรกแซงที่ทำแล้ว",
            "placeholder": "สถานะ"
        }
    }
},
        data: function() {
            return {
				selectedStatut: this.value,
				statuts: [
					{label:this.$t("intervention-prevue"), value:"intervention-prevue"},
					{label:this.$t("intervention-realisee"), value:"intervention-realisee"}
				]
            };
        },
		watch:{
			statut: {
				handler: function(val){
					this.statut = val;
				}
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
