<template>

    <vg-modal @close="closeModal" id="vg-contrat-form">
        <template #header>
            <b v-if="isAnUpdateContratForm">{{ $t("form-header-update") }}</b>
            <b v-else>{{ $t("form-header-create") }}</b>
        </template>
        <template #body>
            <form ref="contrat-form" onSubmit="return false;" >

                <vg-input :title="$t('form-type')"
                    :isRequiredValue="true">
                    <vg-contrat-type-selector v-model="contrat.type"
                        @input="setLibelleContrat"
                    >
                    </vg-contrat-type-selector>
                </vg-input>
                
                <vg-input :title="$t('form-tiers')"
                    :isRequiredValue="true">
                    <template #actions>
                        <vg-button type="success-link" @click="showCreateTiers=true;">
                            {{ $t("ajouter-tiers") }}
                        </vg-button>
                    </template>
                    <vg-tiers-selector v-model="defaultTiers" 
                        :attributeValue="null"
                        @input="setSelectedTier"
                    >
                    </vg-tiers-selector>
                </vg-input>
                <vg-tiers-form v-if="showCreateTiers"
                    @close="showCreateTiers=false;"
                    @created="afterCreateTiers"/>

                <vg-input :title="$t('form-categories')"
                    v-if="!isAnUpdateContratForm"
                    :isRequiredValue="true"
                    v-show="contrat.type != $t('maintenance-curative') && contrat.type != $t('autre') "
                    >
                    <template #actions>
                        <vg-button type="success-link" @click="isEquipementCategorie=true;showCreateCategorie=true;">
                            {{ $t("ajouter-categorie-equipement") }}
                        </vg-button>
                    </template>
                    <vg-categorie-selector
                        v-model="defaultCategorie"
                        displayAll="true"
                        :attributeValue="null"
                        @change="setSelectedCategories" />
                </vg-input>
                <vg-categorie-form v-if="showCreateCategorie"
                    :isGe="!isEquipementCategorie"
                    @close="showCreateCategorie=false;"
                    @save="afterCreateCategorie"/>

                <vg-input :title="$t('periodicite_intervention')"
                    v-show="contrat.type != $t('maintenance-curative') && contrat.type != $t('autre') ">
                    <vg-input-temps @input="handleInputTemps" />
                </vg-input>

                <vg-input v-model="contrat.name"
                    :title="$t('form-libelle')"
                    :isRequiredValue="true">
                </vg-input>

                <div style="width:100%;display:flex;justify-content:space-between;align-items:flex-start;gap:10px;">
                    <vg-input v-model="contrat.startDate"
                        :title="$t('form-dateDeb')"
                        isRequiredValue
                        inputType="date" 
                        style="width:45%;"
                        @input="onChangeStartDate"/>
                    <vg-input :title="$t('form-duree')"
                        isRequiredValue
                        style="width:45%;">
                        <vg-input-temps v-model="defaultRenewalPeriodicity" 
                            @input="onChangeRenewalPeriodicity"/>
                    </vg-input>
                </div>
                <vg-input
                    v-model="contrat.endDate"
                    :title="$t('form-dateFin')"
                    isRequiredValue
                    inputType="date" 
                    :disabled="true">
                </vg-input>

                <div class="tacite-reconduction">
                    <vg-input :title="$t('form-tacite-reconduction')" >
                        <vg-checkbox
                            :defaultChecked="contrat.isTacit"
                            @checked="contrat.isTacit = 1"
                            @unchecked="contrat.isTacit = 0">
                        </vg-checkbox>
                    </vg-input>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <vg-input v-model="contrat.noticePeriod"
                            :title="'pré-avis résiliation (en jours)'"
                            :inputType="'number'">
                        </vg-input>
                    </div>
                    <div class="col-md-6">
                        <vg-input
                            v-model="contrat.annualCost"
                            :title="$t('form-cout', {devise: $app.devise})"
                            :inputType="'financial'">
                        </vg-input>
                    </div>
                </div>

                <vg-input :title="$t('form-restriction-sites')"
                    v-if="!disabledRestrictionLieu">
                    <vg-sites-selector v-model="contratLieux"
                        :defaultValue="contratLieux"
                        @change="onChangeSelectionSites"
                        multiple />
                </vg-input>

                <input v-show="false" type="submit" ref="contrat-submit" />
            </form>
        </template>
        <template #footer>
            <vg-button @click="closeModal">
                {{ $t("form-btn-annuler") }}
            </vg-button>
            <vg-button v-if="isAnUpdateContratForm"
                @click="isModalConfirmArchivedOpened=true;"
                type="danger" 
                :disabled="isUiDisabled">
                {{ $t("form-btn-archiver") }}
            </vg-button>
            <vg-button v-if="isAnUpdateContratForm"
                @click="isModalConfirmDeleteOpened=true;"
                type="danger" 
                :disabled="isUiDisabled">
                {{ $t("form-btn-supprimer") }}
            </vg-button>
            <vg-button v-if="isAnUpdateContratForm"
                @click="submitForm"
                type="danger"
                :disabled="isUiDisabled">
                {{ $t("form-btn-modifier") }}
            </vg-button>
            <vg-button v-else 
                @click="submitForm"
                :disabled="!contrat || !contrat.type || !contrat.tiers_id || !contrat.name || !contrat.startDate || !contrat.renewalPeriodicity || !contrat.endDate
                    || (contrat.type != $t('maintenance-curative') && contrat.type != $t('autre') && contrat.categories.length==0) || isUiDisabled"
                type="success">
                {{ $t("form-btn-creer") }}
            </vg-button>
            <dialog-secured-action v-if="isModalConfirmDeleteOpened" 
                @save="deleteContrat"  
                @close="isModalConfirmDeleteOpened = false" 
                :validResponse="contrat.name" 
                :isSaveDisabled="!deleteConfirmedByUser">
                <template #header-title>
                    {{ $t("demande-suppression-contrat") }} <b>{{contrat.name}}</b> ?
                </template>
            </dialog-secured-action>
            <dialog-secured-action v-if="isModalConfirmArchivedOpened" 
                @save="archiveContrat"  
                @close="isModalConfirmArchivedOpened = false" 
                :validResponse="contrat.name" 
                :isSaveDisabled="!deleteConfirmedByUser">
                <template #header-title>
                    {{ $t("demande-archivage-contrat") }} <b>{{contrat.name}}</b> ?
                </template>
                <template #objective>
                    {{ $t("objective-archivage-contrat") }}
                </template>
                <template #warning>
                    {{ $t("info-archivage-contrat") }}
                </template>
            </dialog-secured-action>
        </template>
    </vg-modal>
</template>

<script>
import VgContratTypeSelector from "src/components/Vg/Contrat/VgContratTypeSelector.vue";
import VgContratPeriodiciteSelector from "src/components/Vg/Contrat/VgContratPeriodiciteSelector.vue";
import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
import VgSitesSelector from "src/components/Vg/Selectors/VgSitesSelector.vue";
import VgInputTemps from "src/components/Vg/VgInputTemps.vue";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";
import ContratsMixins from "src/mixins/ContratsMixins.js";


import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgCategorieForm from 'src/components/Vg/Forms/VgCategorieForm.vue';
import VgTiersForm from 'src/components/Vg/Forms/VgTiersForm.vue';
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
    name:'vg-contrat-form',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "form-header-create": "Créer contrat",
            "form-header-update": "Modifier contrat",
            "form-tiers": "Tiers",
            "form-restriction-sites": "Restriction sites",
            "form-categories": "Catégorie",
            "form-type": "Type de contrat",
            "periodicite_intervention": "Periodicite interventions",
            "form-libelle": "Libellé",
            "form-dateDeb": "Date début",
            "form-duree": "Durée contrat",
            "form-dateFin": "Date fin",
            "form-tacite-reconduction": "Tacite reconduction",
            "form-cout": "Coût annuel HT en {devise}",
            "form-btn-creer": "Enregistrer",
            "form-btn-annuler": "Annuler",
            "form-btn-modifier": "Modifier",
            "form-btn-supprimer": "Supprimer",
            "form-btn-archiver": "Archiver ce contrat",
            "maintenance-curative": "Maintenance curative",
            "autre": "Autre",
            "demande-suppression-contrat": "Voulez-vous supprimer le contrat ",
            "demande-archivage-contrat": "Voulez-vous archiver le contrat ",
            "info-archivage-contrat": "Si vous archivez ce contrat vous ne pourrez plus le consulter. Les interventions déjà renseignées sur ce contrat seront toujours consultables depuis la page interventions.",
            "objective-archivage-contrat": "pour archiver ce contrat.",
            "ajouter-categorie-equipement": "Ajouter catégorie équipement",
            "ajouter-tiers": "Ajouter tiers",
            "ajouter-categorie-lieu": "Ajouter catégorie lieu"
        },
        "en": {
            "form-header-create": "Create contract",
            "form-tiers": "Supplier",
            "form-type": "Type",
            "periodicite_intervention": "Intervention\'s periodicity",
            "form-libelle": "Label",
            "form-dateDeb": "Start date",
            "form-duree": "Contract duration",
            "form-dateFin": "End date",
            "form-cout": "Annual cost excl. VAT in {devise}",
            "form-btn-annuler": "Cancel",
            "form-restriction-sites": "Site restriction",
            "form-categories": "Category",
            "form-btn-creer": "Save",
            "maintenance-curative": "Curative maintenance",
            "autre": "Other",
            "form-header-update": "Update contract",
            "form-tacite-reconduction": "Tacit agreement",
            "demande-suppression-contrat": "Do you want to delete this contract ",
            "demande-archivage-contrat": "Do you want to archive the contract ",
            "info-archivage-contrat": "If you archive this contract, you will no longer be able to view it. The interventions already entered on this contract will still be viewable from the interventions page.",
            "objective-archivage-contrat": "to archive this contract.",
            "ajouter-categorie-equipement": "Add equipment's category",
            "ajouter-tiers": "Add third part",
            "ajouter-categorie-lieu": "Add room's category"
        },
        "th": {
            "form-header-create": "สร้างสัญญา",
            "form-tiers": "ซัพพลายเออร์",
            "form-type": "ประเภท",
            "periodicite_intervention": "ช่วงระยะเวลาของการแทรกแซง",
            "form-libelle": "ฉลาก",
            "form-dateDeb": "วันที่เริ่มต้น",
            "form-dateFin": "วันที่สิ้นสุด",
            "form-btn-annuler": "ยกเลิก",
            "form-restriction-sites": "ข้อจำกัดเว็บไซต์",
            "form-categories": "หมวดหมู่",
            "form-btn-creer": "บันทึก",
            "maintenance-curative": "การบำรุงรักษาซ่อมแซม",
            "autre": "อื่น ๆ"
        }
    }
}   ,
    props:{
        value:{
            type: Object,
            default:function(){
                return {};
            }
        },
        id:{
            type: Number | String,
            default: null
        },
        skipVueXStorage: {
            type: Boolean,
            default: false
        },
        disabledRestrictionLieu:{
            type:Boolean,
            default:false
        },
        /** 
         * initialise le formulaire création contrat prénourrit avec le contrat selectedContrat dans le but de créer un contrat copié
         */
        initCopie:{
            type: Boolean,
            default: false
        }
    },
    mixins:[CategorieMixins,LieuMixins,ContratsMixins],
    components:{
        VgInput,
        VgCheckbox,
        VgButton,
        VgContratTypeSelector,
        VgTiersSelector,
        VgContratPeriodiciteSelector,
        VgSitesSelector,
        VgCategorieSelector,
        DialogSecuredAction,
        VgCategorieForm,
        VgTiersForm,
        VgInputTemps
    },
    data:function(){
        return{
            defaultRenewalPeriodicity: 365,
            contrat:{
                name:'',
                type:'',
                tiers_id:'',
                number:'',
                startDate:moment().format("YYYY-MM-DD"),
                endDate:moment().add(365, "days").format("YYYY-MM-DD"),
                isTacit: 0,
                noticePeriod:"60",
                renewalPeriodicity:365,
                tags:'',
                userId: this.$app.appID,
                annualCost:0,
                categories:[],
                periodiciteIntervention:null,
                lieux: []
            },
            tiersList:[],
            periodicite:null,
            displayModal:false,
            lieuSelectorFilters:{
                typeLieu:{"attr":"l.type_lieu","value":"Site","action":"equals"}
            },
            selectedTier:null,
            isModalConfirmDeleteOpened: false,
            isModalConfirmArchivedOpened: false,
            deleteConfirmedByUser: false,
            isUiDisabled: false,
            defaultCategorie: null,
            defaultTiers: null,
            showCreateCategorie: false,
            showCreateTiers: false,
            isEquipementCategorie: true
        }
    },
    created:function(){
        if(this.id && this.skipVueXStorage){ 
            this.fetchContrat();
        }else{ 
            this.contrat = Object.assign({}, this.contrat, this.value);
            this.defaultTiers = this.contrat.tiers;
        }
        if(this.initCopie) this.initCopieContrat();
    },
    methods:{
        onChangeStartDate: function(){
            this.recalculDateFin();
        },
        onChangeRenewalPeriodicity: function(datas){
            this.contrat.renewalPeriodicity = datas.value;
            this.recalculDateFin();
        },
        recalculDateFin: function(){
            this.contrat.endDate = moment(this.contrat.startDate).add(this.contrat.renewalPeriodicity, "days").format("YYYY-MM-DD");
        },
        afterCreateTiers: function(datas){
            this.setSelectedTier(datas[0]);
            this.defaultTiers = datas[0];
            this.showCreateTiers = false;
        },
        afterCreateCategorie: function(datas){
            this.$store.dispatch("CategoriesStore/setSelectedItem", datas);
            this.setSelectedCategories();
            this.defaultCategorie = datas;
            this.showCreateCategorie = false;
        },
        initCopieContrat: function(){
            this.defaultCategorie = this.selectedContrat.categories[0];
            this.defaultTiers = this.selectedContrat.tiers;
            let lieux = this.selectedContrat.lieux && this.selectedContrat.lieux.length!=0?this.selectedContrat.lieux.map((contratLieu)=>{return {lieu_id: contratLieu.lieu_id};}):[];
            this.contrat = {
                name: this.selectedContrat.name,
                type:this.selectedContrat.type,
                tiers_id:this.selectedContrat.tiers_id,
                number:this.selectedContrat.number,
                startDate:this.selectedContrat.startDate,
                endDate:this.selectedContrat.endDate,
                isTacit: this.selectedContrat.isTacit || 0,
                noticePeriod:this.selectedContrat.noticePeriod,
                renewalPeriodicity:this.selectedContrat.renewalPeriodicity,
                tags:this.selectedContrat.tags,
                userId: this.$app.appID,
                annualCost:this.selectedContrat.annualCost,
                categories:[this.selectedContrat.categories[0]],
                periodiciteIntervention:null,
                lieux: lieux
            };
        },
        fetchContrat: function(){
            this.ContratsMixins_fetchContrat(this.id, {skipVueXStorage: this.skipVueXStorage}).then((contrats)=>{
                this.contrat = Object.assign({}, this.contrat, contrats[0]);
            });
        },
        submitForm:function(){
            this.isUiDisabled = true;
            this.$refs["contrat-submit"].click();
            this.isDisabledUi = true;
            let form = this.$refs['contrat-form'];
            // je ne sais pas pourquoi ça a été ajouté
            // this.contrat.categories && this.contrat.categories.length!=0
            // car du point de vue métier un contrat peut ne plus avoir
            // de catégories affectées maintenant
            if(form.checkValidity() && this.contrat.name && this.contrat.tiers_id && this.contrat.endDate /*&& this.contrat.categories && this.contrat.categories.length!=0*/){
                if(this.isAnUpdateContratForm){
                    this.contrat = Object.assign(this.value, this.contrat);
                    this.handleUpdateContrat();
                }else{
                    this.handleCreateContrat();
                }
            }else{
                this.isDisabledUi = false;
            }
            return true;
        },
        handleCreateContrat:function(){
            let contrat = this.contrat.categories.length ?
            Object.assign(this.contrat,{categories:[{id:this.categorie?this.categorie.id:this.contrat.categories[0].id}]}) :
            this.contrat;
            this.ContratsMixins_createContrat(contrat).then((contrat)=>{
                this.$emit('created', contrat[0]);
                this.isUiDisabled = false;
                //this.closeModal();
            });

        },
        handleUpdateContrat:function(){
            let contrat = this.contrat.categories && this.contrat.categories.length && this.contrat.categories[0] && this.contrat.categories[0].id?
            Object.assign(this.contrat,{categories:[{id:this.contrat.categories[0].id}]}) :
            this.contrat;
            this.ContratsMixins_updateContrat(contrat, {skipVueXStorage: this.skipVueXStorage}).then((contrat)=>{
                this.$emit('updated', this.contrat);
                this.isUiDisabled = false;
                //this.closeModal();
            });
        },
        handleInputTemps: function(temps){
            this.contrat.periodiciteIntervention = temps.value;
        },
        closeModal:function(){
          this.contrat=null;
          this.contrat={
              name:'',
              type:'',
              tiers_id:'',
              number:'',
              startDate:new Date(),
              endDate:'',
              renewalPeriodicity:365,
              tags:'',
              userId: this.$app.appID,
              annualCost:0,
              categories:[],
              lieux: []
          };
          this.$emit('close');
      },
        onChangeSelectionSites: function(idSites){
            this.contrat.lieux = idSites.map((id)=>{return {lieu_id: id};});
        },
        deleteContrat: function() {
            // this.ContratsMixins_delete(this.contrat, (response)=>{
            //
            // });
            this.ContratsMixins_delete(this.contrat).then((contrat) => {
                this.$router.push({
                    name: "_contrat"
                });
                this.isModalConfirmDeleteOpened = false;
            });
        },
        archiveContrat: function() {
            this.ContratsMixins_archive(this.contrat).then((contrat) => {
                this.$router.push({
                    name: "_contrat"
                });
                this.isModalConfirmArchivedOpened = false;
            });
        },
        setSelectedTier:function(tier){
            console.log("SET SELECTED TIERS", tier);
            this.selectedTier = tier;
            this.contrat.tiers_id = tier.id;
            this.setLibelleContrat()
        },
        setLibelleContrat: function(){
            let typeContrat = this.contrat.type;
            let tierName = this.selectedTier ? this.selectedTier.name : "";
            let categorie = this.categorie ? this.categorie.libelleCatgorie : "";
            this.contrat.name = `${typeContrat} ${categorie} ${tierName}`;
        },
        setSelectedCategories: function(){
            if(!this.categorie){
                this.contrat.categories = [];
                return;
            }else if(this.categorie.id){
                this.contrat.categories = [this.categorie.id];
                this.setLibelleContrat();
            }else{
                this.contrat.categories = [];
                return;
            }

        }
    },
    computed:{
        ...mapGetters({
            categorie: 'CategoriesStore/getSelectedItem',
            selectedContrat: 'ContratsStore/getSelectedItem',
        }),
        isAnUpdateContratForm:function(){
            if(this.id && this.skipVueXStorage && this.contrat) return this.contrat.id;
            else if(this.value) return this.value.hasOwnProperty('id');
            else return false;
        },
        contratLieux: function(){
            return this.contrat.lieux?this.contrat.lieux.map((lieu)=>{return {id: lieu.lieu_id};}):[];
        }
    }
}
</script>

<style lang="scss">
#vg-contrat-form{
    .tacite-reconduction{
        margin:10px 0 25px 0px;
    }
}

</style>
