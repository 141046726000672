<template>
	<vg-group style="margin-bottom:10px;">
		<template slot="title">
			<img src="static/assets/icone/Icones-60x60-appli-web.png" height="15" alt="icon-filter"/>
			<span>{{$t('title')}}</span>
		</template>
		<slot></slot>
	</vg-group>
</template>
<script>
	import VgGroup from "src/components/Vg/VgGroup.vue";
    export default {
        name: 'vg-group-filters',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtres"
        },
        "en": {
            "title": "Filters"
        },
        "th": {
            "title": "ตัวกรอง"
        }
    }
},
		components: {
			VgGroup
		},
        data: function() {
            return {

            };
        },
		created: function(){

		}
    };

</script>
<style lang="scss" scoped>

</style>
