<template>
    <div class="vg-calendar-filters">
        <vg-collapse v-for="section, index in getSections" :key="'collapse-section-'+index"
            :title="section"
            type="section"
            :collapseByDefault="false">
            <template v-slot:content>
                <vg-checkbox v-for="calendar in calendars[section]" :key="calendar.id"
                    :label="calendar.name"
                    :inputValue="calendar.checked"
                    :defaultChecked="calendar.checked"
                    @checked="onDisplay(calendar.id, true)"
                    @unchecked="onDisplay(calendar.id, false)"
                    class="checkbox-calendar-filter">
                    <template>
                        <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;gap:10px;">
                            <span>{{ calendar.name }}</span> 
                            <div :style="'height:14px;width:28px;background-color:'+calendar.bgColor"></div>
                        </div>
                    </template>
                </vg-checkbox>
            </template>
        </vg-collapse>
    </div>
</template>

<script>
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
export default {
    name:"vg-calendar-filters",
    components: {
        VgCollapse,
        VgCheckbox
    },
    props:{
        /**
        * @model calendars à afficher (ouverture, fermeture, affectation, intervention-ponctuelle)
        */
        value: {
            type: Array,
            required: true
        }
    },
    data: function(){
        return {
            calendars: {}
        };
    },
    watch: {
        value: {
            handler: function(val){
                this.dispatchCalendarsParSections();
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par type",
            "filtrage-tiers": "Tiers"
        },
        "en": {
            "title": "Filtering by type"
        },
        "th": {
            "title": "การกรองตามประเภท"
        }
    }
},
    computed:{
        getSections: function(){
            return new Set(this.value.map((calendar)=>calendar.section));
        }
    },
    methods: {
        dispatchCalendarsParSections: function(){
            this.getSections.forEach((section)=>this.calendars[section] = this.value.filter((calendar)=>calendar.section==section));
        },
        onDisplay: function(calendarName, isDisplay){
            let calendarIndex = this.value.findIndex((calendar)=> calendar.id==calendarName);
            this.value[calendarIndex].checked = isDisplay;
            this.$emit("input", this.value);
        }
    },
    created: function(){
        this.dispatchCalendarsParSections();
    }
}
</script>

<style lang="scss">
.checkbox-calendar-filter{
    
}
</style>
