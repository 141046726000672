<template>
	<vg-modal v-if="displayAsAModal"
		:title="$t('modal-demande-consommable-title')"
		:width="'70vw'"
		:isSaveDisabled="isSaveIsDisabled || disabledSave"
		@close="$emit('close')"
		@save="save">
		<template #body>
			<form
				id="demande-consommables-form"
		        ref="demande-consommables-form"
		        class="vg-demande-consommables-form"
		        onSubmit="return false;">
				<vg-input v-if="!isUniqueSite" :title="$t('site')" isRequiredValue>
					<vg-sites-selector v-model="demande.site_id"/>
				</vg-input>
				<vg-input :title="$t('service')" isRequiredValue>
					<vg-service-selector v-model="demande.serviceDemandeur" showAll/>
				</vg-input>
				<vg-input :title="$t('liste-consommables')">
					<div class="consommable-row consommable-row-header">
						<span></span>
						<span>{{$t("quantite")}}</span>
						<span>{{$t("unite")}}</span>
						<span>{{$t("quantite-en-stock")}}</span>
						<span></span>
					</div>
					<div v-for="(item, index) in items" class="consommable-row">
						<vg-consommable-datalist v-if="!item.consommable_id && getDepotId" :key="'consommable-datalist-'+index"
							:placeholder="$t('consommable')"
							:depotId="getDepotId"
							readOnly
							@select="onSelectConsommable($event, item, index)"/>
						<span v-else>{{item.consommable.name}}</span>
						<vg-input inputType="float" v-model="item.quantite" />
						<vg-input>
							<span v-if="item.consommable && item.consommable.unite">{{item.consommable.unite}}</span>
							<span v-else>{{$t("default-unite")}}</span>
						</vg-input>
						<span v-if="item.consommable">{{item.consommable.stock.quantite}} {{item.consommable.unite?item.consommable.unite:$t("default-unite")}}</span>
						<span v-else />
						<vg-button type="default-danger" @click="removeItem(item, index)">{{$t("retirer")}}</vg-button>
					</div>
					<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
						{{$t("add-consommable")}}
					</vg-button>
					<!--vg-button v-if="can('DEMANDE_CONSOMMABLES.CREATE_CONSOMMABLE')" type="success" size="sm" @click="showCreateConsommable=true;">
						{{$t("new-consommable")}}
					</vg-button-->
					<vg-consommable-form v-if="showCreateConsommable"
						@close="showCreateConsommable=false;"
						@save="afterCreateConsommable"/>
				</vg-input>
				<vg-input :title="$t('commentaire')" v-model="demande.description" inputType="textarea" />
				<vg-signataire-datalist
					v-model="demande.nomDemandeur"
					:title="$t('demandeur')"
					:placeholder="$t('demandeur')"
					isDemandeConsommables
					isRequiredValue />
			</form>
		</template>
	</vg-modal>
	<form v-else
		id="demande-consommables-form"
        ref="demande-consommables-form"
        class="vg-demande-consommables-form"
        onSubmit="return false;">
		<vg-input v-if="!isUniqueSite" :title="$t('site')" isRequiredValue>
			<vg-sites-selector v-model="demande.site_id"/>
		</vg-input>
		<vg-input :title="$t('service')" isRequiredValue>
			<vg-service-selector v-model="demande.serviceDemandeur" showAll/>
		</vg-input>
		<vg-input :title="$t('liste-consommables')">
			<div class="consommable-row-4 consommable-row-header">
				<span></span>
				<span>{{$t("quantite")}}</span>
				<span>{{$t("unite")}}</span>
				<span></span>
			</div>
			<div v-for="(item, index) in items" class="consommable-row-4">
				<vg-consommable-datalist v-if="!item.consommable_id && getDepotId" :key="'consommable-datalist-'+index"
					:placeholder="$t('consommable')"
					:depotId="getDepotId"
					readOnly
					@select="onSelectConsommable($event, item, index)"/>
				<span v-else>{{item.consommable.name}}</span>
				<vg-input inputType="float" v-model="item.quantite" />
				<vg-input>
					<span v-if="item.consommable && item.consommable.unite">{{item.consommable.unite}}</span>
					<span v-else>{{$t("default-unite")}}</span>
				</vg-input>
				<vg-button type="default-danger" @click="removeItem(item, index)">{{$vgutils.isMobile()?"x":$t("retirer")}}</vg-button>
			</div>
			<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
				{{$t("add-consommable")}}
			</vg-button>
			<!--vg-button type="success" size="sm" @click="showCreateConsommable=true;">
				{{$t("new-consommable")}}
			</vg-button-->
			<vg-consommable-form v-if="showCreateConsommable"
				@close="showCreateConsommable=false;"
				@save="afterCreateConsommable"/>
		</vg-input>
		<vg-input :title="$t('commentaire')" v-model="demande.description" inputType="textarea"/>
		<vg-signataire-datalist
				v-model="demande.nomDemandeur"
				:title="$t('demandeur')"
				:placeholder="$t('demandeur')"
				isDemandeConsommables
				isRequiredValue />
		<vg-button type="success" style="width:100%;" @click="save" :disabled="isSaveIsDisabled || disabledSave">
			{{$t("save")}}
		</vg-button>
	</form>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSitesSelector from "src/components/Vg/Selectors/VgSitesSelector.vue";
	import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
	import VgConsommableDatalist from "src/components/Vg/Datalists/VgConsommableDatalist.vue";
	import VgConsommableForm from "src/components/Vg/Forms/VgConsommableForm.vue";
	import VgSignataireDatalist from "src/components/Vg/Datalists/VgSignataireDatalist.vue";
	import FicheDemandeConsommablesMixins from "src/mixins/FicheDemandeConsommablesMixins.js";
	import StocksMixins from "src/mixins/StocksMixins";
	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from "vuex";
    export default {
        name: "vg-demande-consommables-form",
		mixins: [ FicheDemandeConsommablesMixins, StocksMixins ],
		components: {
			VgInput,
			VgButton,
			VgSitesSelector,
			VgServiceSelector,
			VgConsommableDatalist,
			VgConsommableForm,
			VgSignataireDatalist
		},
        props: {
            value: {
				type: Object,
				default: function(){
					return null;
				}
			},
			displayAsAModal: {
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
                demande: {
					site_id: null,
					demandeur_id: this.$app.idUser,
					nomDemandeur: null,
					serviceDemandeur: null,
					numero: null,
					statut: "en-cours",
					dateCreation: null,
					dateCloture: null
				},
				items: [],
				showCreateConsommable: false,
				disabledSave: false
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "site": "Site demandeur",
            "service": "Service demandeur",
            "liste-consommables": "Liste consommables",
            "commentaire": "Commentaire",
            "demandeur": "Demandeur",
            "save": "Envoyer ma demande",
            "consommable": "Consommable",
            "quantite": "Quantité",
            "unite": "Unité",
            "quantite-en-stock": "Quantité en stock",
            "retirer": "Retirer",
            "default-unite": "u.",
            "add-consommable": "Ajouter consommable",
            "new-consommable": "Créer consommable",
            "modal-demande-consommable-title": "Demande consommables"
        },
        "en": {
			"site": "Requesting site",
            "service": "Requesting service",
            "liste-consommables": "Consumables list",
            "commentaire": "Comment",
            "demandeur": "Requester",
            "save": "Send my request",
            "consommable": "Consumable",
            "quantite": "Quantity",
            "unite": "Unit",
            "quantite-en-stock": "Quantity in stock",
            "retirer": "Remove",
            "default-unite": "EA",
            "add-consommable": "Add consumable",
            "new-consommable": "Create consumable",
            "modal-demande-consommable-title": "Consumables request"
		}
    }
},
		watch: {

		},
		created: function(){
			this.addItem();
			this.fetchDepots();
		},
		mounted: function(){
			this.setSite();
		},
		methods: {
			afterCreateConsommable: function(datas){
				this.addItem(datas[0]);
				this.showCreateConsommable = false;
			},
			addItem: function(consommable=null){
				let item = {
					consommable_id: null,
					quantite: 1
				};
				if(consommable){
					item.consommable_id = consommable.id;
					item.consommable = Object.assign({}, {}, consommable);
				}
				this.items.push(item);
			},
			removeItem: function(item, index){
				this.items.splice(index, 1);
			},
			onSelectConsommable: function(consommable, item, index){
				this.items[index].consommable_id = consommable.id;
				this.items[index].consommable = Object.assign({}, {}, consommable);
			},
			setSite: function(){
				if(this.isUniqueSite){
					this.demande.site_id = this.sites[0]["id"];
				}
			},
			save: function(){
				this.disabledSave = true;
				let demande = Object.assign({}, this.demande, {
					items: this.items.filter((item)=>item.consommable_id),
					dateCreation: moment().format("YYYY-MM-DD HH:mm")
				});
				this.setSite();
				this.FicheDemandeConsommablesMixins_create([demande]).then((datas)=>{
					if(!this.displayAsAModal) location.reload();
					else this.$emit("save");
					this.disabledSave = false;
				});
			},
			fetchDepots: function(){
	            let metadatasDepots = new Metadatas();
                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{

                });
	        }
		},
        computed: {
			...mapGetters({
				sites: "LieuxStore/getSites",
				depotDefault: "StocksStore/getDepotDefault",
	            depotFlottant: "StocksStore/getDepotFlottant"
	        }),
			getDepotId: function(){
				return this.depotDefault?this.depotDefault.id:null;
			},
			isUniqueSite: function(){
				return this.sites && this.sites.length==1;
			},
			lastItemIsNotEmpty: function(){
				if(this.items.length==0) return true;
				let lastIndex = this.items.length-1;
				return this.items[lastIndex].consommable_id && this.items[lastIndex].quantite;
			},
			isSaveIsDisabled: function(){
				if(!this.demande || !this.demande.serviceDemandeur) return true;
				else if(!this.demande.description && this.items.length==0) return true;
				else if(!this.demande.nomDemandeur) return true;
				else if(!this.demande.site_id && !this.isUniqueSite) return true;
				else return false;
			}
		}
    };

</script>
<style lang="scss" scoped>
.consommable-row{
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
.consommable-row-4{
	display: grid;
	grid-template-columns: 5fr 2fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
.consommable-row-header{
	margin-bottom: 5px;
	font-size: 12px;
}
</style>
