<template>
    <div class="tiers-mobile-cell-render">
        <img :src="'static/assets/icone/circle/tiers-cercle.png'" height="25" style="margin-right:10px;"/>
        <b>{{tiers.name}}</b>
        <div style="margin-left:35px;margin-top:5px;">
            <span>{{$t("type")}}: {{tiers.type}}</span>
            <br>
            <span>{{$t("etiquette")}}: {{tiers.tags}}</span>
            <br>
            <span>{{$t("address")}}: {{ tiers.address }}</span>
        </div>
    </div>
</template>


<script>
/*{
  "path": null,
  "contacts": [
    {
      "firstname": "Sabrina",
      "lastname": "DÖHRING",
      "tels": "+262692823611",
      "email": "sabrina.dohring.dev@gmail.com",
      "id": "291"
    }
  ],
  "id": "291",
  "name": "BERD",
  "type": "Prestataire",
  "description": null,
  "tags": "Maintenance",
  "uid": "5b1169904731e",
  "userId": "7uGOJgrDnp"
}*/
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"tiers-mobile-cell-render",
    mixins:[LangMixins],
    components:{

    },
	props: {
		tiers: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "type": "Type",
            "etiquette": "Etiquette",
            "address": "Adresse"
        },
        "en": {
            "type": "Type",
            "etiquette": "Tag",
            "address": "Address"
        }
    }
},
    methods:{
        handleClick:function(){
            this.$emit("click",this.params);
        }
    },
    computed:{

    }
});
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991.98px) {
    .tiers-mobile-cell-render{
        height: 100%;
        width: 100%;
        border-bottom: 1px solid #CACACB;
        padding: 10px 0;
    }
}
</style>
