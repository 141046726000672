<template lang="html">
	<vg-input
		:title="$t('title')">
		<vg-tags-selector v-model="focusedTags"
			:type="type"
			@input="handleChange">
		</vg-tags-selector>
	</vg-input>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgTagsSelector from "src/components/Vg/Selectors/VgTagsSelector.vue";

    export default {
        name: 'vg-filter-tags',
	    components: {
			VgInput,
			VgSelect,
			VgTagsSelector
        },
		mixins:[],
        props: {
			value: {
				type: Array,
				required: true
			},
			multiple:{
				type: Boolean,
				default: false
			},
			type: {
				type: Array,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par étiquettes"
        },
        "en": {
            "title": "Filter by tags"
        }
    }
},
        data: function(){
            return {
				focusedTags: this.value || []
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedTags = val;
				}
			}
		},
		created: function(){

		},
        methods: {
			handleChange: function(e){
				this.$emit("input", this.focusedTags);
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
