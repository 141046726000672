<template>
	<vg-select
		v-model="selectedIcon"
		:options="getIcons"
		:attributeValue="attributeValue"
		:attributeLabel="'label'"
		:placeholder="$t('placeholder-select-icon')"
		@input="$emit('input',selectedIcon)">
		<template v-slot:selected-option="{option}">
			<img :src="option.src" width="20px" height="20px" style="margin-right:10px;"/>
			<span>{{$t("icon-"+option.label)}}</span>
        </template>
		<template v-slot="{option}">
			<div class="option">
				<img :src="option.src" width="20px" height="20px" style="margin-right:10px;"/>
				<span>{{$t("icon-"+option.label)}}</span>
			</div>
		</template>
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import ComposantMixins from "src/mixins/ComposantMixins.js";

    export default {
        name: 'vg-composant-icon-selector',
		components: {
			VgSelect
		},
		mixins: [ComposantMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet maintenance à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "src"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				icons: [],
				selectedIcon: this.value && this.value.src?this.value.src:this.value
            };
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedIcon = val;
				}
			}
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder-select-icon": "Sélectionner une icone",
            "icon-usb": "USB",
            "icon-NAS": "NAS",
            "icon-WC": "WC",
            "icon-alarme": "Alarme",
            "icon-alarme-incendie": "Alarme incendie",
            "icon-arbre": "Arbre",
            "icon-arbuste": "Arbuste",
            "icon-armoire-basse": "Armoire basse",
            "icon-armoire-electrique": "Armoire électrique",
            "icon-armoire-haute": "Armoire haute",
            "icon-armoire-personnel-haute": "Armoire haute personnel",
            "icon-armoire-refrigeree": "Armoire réfrigerée",
            "icon-ascenseur": "Ascenseur",
            "icon-aspirateur": "Aspirateur",
            "icon-automatisme-portail": "Automatisme portail",
            "icon-automatisme-volet": "Automatisme volet",
            "icon-bac-a-graisse": "Bac-a-graisse",
            "icon-bac-de-douche": "Bac-de-douche",
            "icon-baie-vitree": "Baie-vitree",
            "icon-baignoire": "Baignoire",
            "icon-bain-de-soleil": "Bain-de-soleil",
            "icon-balai-WC": "Balai-WC",
            "icon-balance": "Balance",
            "icon-balancoire": "Balancoire",
            "icon-ballon-eau-chaude": "Ballon-eau-chaude",
            "icon-banc": "Banc",
            "icon-banc-de-musculation": "Banc-de-musculation",
            "icon-barbecue": "Barbecue",
            "icon-barriere": "Barriere",
            "icon-barriere-de-securite": "Barriere-de-securite",
            "icon-batteur-melangeur": "Batteur-melangeur",
            "icon-bibliotheque": "Bibliotheque",
            "icon-blender": "Blender",
            "icon-bloc-eclairage-securite": "Bloc-eclairage-securite",
            "icon-bouche-a-incendie": "Bouche-a-incendie",
            "icon-bouilloire": "Bouilloire",
            "icon-brouette": "Brouette",
            "icon-bureau": "Bureau",
            "icon-cage-de-but": "Cage-de-but",
            "icon-camera-de-surveillance": "Camera-de-surveillance",
            "icon-canape": "Canape",
            "icon-capteur": "Capteur",
            "icon-cardio": "Cardio",
            "icon-carrelage": "Carrelage",
            "icon-casier-personnel": "Casier-personnel",
            "icon-cave-a-vin": "Cave-a-vin",
            "icon-chaise": "Chaise",
            "icon-chambre-froide": "Chambre-froide",
            "icon-chariot-general": "Chariot-general",
            "icon-chariot-medicaments": "Chariot-medicaments",
            "icon-chariot-repas": "Chariot-repas",
            "icon-chariot-sac-golf": "Chariot-sac-golf",
            "icon-chauffage": "Chauffage",
            "icon-chauffe-cire": "Chauffe-cire",
            "icon-chauffe-eau-solaire": "Chauffe-eau-solaire",
            "icon-chauffe-plat": "Chauffe-plat",
            "icon-chausee": "Chausee",
            "icon-clavier": "Clavier",
            "icon-climatiseur": "Climatiseur",
            "icon-clos-couvert": "Clos-couvert",
            "icon-cloture": "Cloture",
            "icon-coffre-fort": "Coffre-fort",
            "icon-compteur-a-gaz": "Compteur-a-gaz",
            "icon-compteur-d_eau": "Compteur-d_eau",
            "icon-compteur-electrique": "Compteur-electrique",
            "icon-court-de-tennis": "Court-de-tennis",
            "icon-couverture-piscine": "Couverture-piscine",
            "icon-cuisiniere": "Cuisiniere",
            "icon-deambulateur": "Deambulateur",
            "icon-debroussailleuse": "Debroussailleuse",
            "icon-defibrilateur": "Defibrilateur",
            "icon-descente-de-gouttiere": "Descente-de-gouttiere",
            "icon-deshumidificateur": "Deshumidificateur",
            "icon-desserte-de-repas": "Desserte-de-repas",
            "icon-desserte-roulante": "Desserte-roulante",
            "icon-detecteur-de-fumee": "Detecteur-de-fumee",
            "icon-diable": "Diable",
            "icon-digicode": "Digicode",
            "icon-distributeur-de-papier": "Distributeur-de-papier",
            "icon-distribution-signal-tv": "Distribution-signal-tv",
            "icon-douche-exterieure": "Douche-exterieure",
            "icon-echelle": "Echelle",
            "icon-eclairage-exterieur": "Eclairage-exterieur",
            "icon-eclairage-stade": "Eclairage-stade",
            "icon-ecran-ordinateur": "Ecran-ordinateur",
            "icon-ecran-projecteur": "Ecran-projecteur",
            "icon-element-decoratif": "Element-decoratif",
            "icon-enceinte-audio": "Enceinte-audio",
            "icon-escabeau": "Escabeau",
            "icon-escalier": "Escalier",
            "icon-espace-vert": "Espace-vert",
            "icon-etagere": "Etagere",
            "icon-etagere-haute-de-rangement": "Etagere-haute-de-rangement",
            "icon-etagere-murale-de-cuisine": "Etagere-murale-de-cuisine",
            "icon-extincteur1": "Extincteur1",
            "icon-extincteur": "Extincteur",
            "icon-extincteur-eau": "Extincteur-eau",
            "icon-extincteur-gaz": "Extincteur-gaz",
            "icon-extincteur-mousse": "Extincteur-mousse",
            "icon-extincteur-poudre": "Extincteur-poudre",
            "icon-facade": "Facade",
            "icon-fausse-septique": "Fausse-septique",
            "icon-fauteuil": "Fauteuil",
            "icon-fauteuil-roulant": "Fauteuil-roulant",
            "icon-fenetre": "Fenetre",
            "icon-fer-a-repasser": "Fer-a-repasser",
            "icon-fience-murale": "Fience-murale",
            "icon-filet-de-tennis": "Filet-de-tennis",
            "icon-filet-volley-ball": "Filet-volley-ball",
            "icon-filtre-piscine": "Filtre-piscine",
            "icon-firewall": "Firewall",
            "icon-flexible-de-douche": "Flexible-de-douche",
            "icon-fontaine": "Fontaine",
            "icon-four": "Four",
            "icon-four-a-boulangerie": "Four-a-boulangerie",
            "icon-four-a-pizza": "Four-a-pizza",
            "icon-frigo": "Frigo",
            "icon-garde-corps": "Garde-corps",
            "icon-gaufrier": "Gaufrier",
            "icon-golf": "Golf",
            "icon-gouttiere": "Gouttiere",
            "icon-grill": "Grill",
            "icon-grille-de-protection": "Grille-de-protection",
            "icon-grille-pain": "Grille-pain",
            "icon-groom-porte": "Groom-porte",
            "icon-groupe-electrogene": "Groupe-electrogene",
            "icon-guirlande-lumineuse": "Guirlande-lumineuse",
            "icon-gym": "Gym",
            "icon-hachoir-a-viande": "Hachoir-a-viande",
            "icon-haie": "Haie",
            "icon-horloge-murale": "Horloge-murale",
            "icon-hotte": "Hotte",
            "icon-hygromètre": "Hygromètre",
            "icon-imprimante": "Imprimante",
            "icon-indicateur-de-niveau": "Indicateur-de-niveau",
            "icon-insect-killer": "Insect-killer",
            "icon-installation-electrique": "Installation-electrique",
            "icon-installation-gaz": "Installation-gaz",
            "icon-interphone": "Interphone",
            "icon-interrupteur": "Interrupteur",
            "icon-interupteur": "Interupteur",
            "icon-jacuzzi": "Jacuzzi",
            "icon-jalousie": "Jalousie",
            "icon-jardiniere": "Jardiniere",
            "icon-kayak": "Kayak",
            "icon-laminoir": "Laminoir",
            "icon-lampadaire": "Lampadaire",
            "icon-lampe-de-bureau": "Lampe-de-bureau",
            "icon-lavabo": "Lavabo",
            "icon-lave-linge": "Lave-linge",
            "icon-lave-main": "Lave-main",
            "icon-lave-verre": "Lave-verre",
            "icon-lecteur-DVD": "Lecteur-DVD",
            "icon-leve-malade": "Leve-malade",
            "icon-lit": "Lit",
            "icon-lit-ambulatoir": "Lit-ambulatoir",
            "icon-lit-ambulatoire": "Lit-ambulatoire",
            "icon-lit-medicalise": "Lit-medicalise",
            "icon-lit-supperpose": "Lit-supperpose",
            "icon-luminaire haut": "Luminaire haut",
            "icon-luminaire": "Luminaire",
            "icon-luminaire-boule-jardin": "Luminaire-boule-jardin",
            "icon-luminaire-chambre": "Luminaire-chambre",
            "icon-luminaire-exterieur": "Luminaire-exterieur",
            "icon-luminaire-lustre": "Luminaire-lustre",
            "icon-luminaire-suspension": "Luminaire-suspension",
            "icon-lutrin-porte-menu": "Lutrin-porte-menu",
            "icon-machine-a-cafe": "Machine-a-cafe",
            "icon-machine-a-coudre": "Machine-a-coudre",
            "icon-machine-a-glacon": "Machine-a-glacon",
            "icon-main-courante": "Main-courante",
            "icon-marquage-au-sol": "Marquage-au-sol",
            "icon-matelas": "Matelas",
            "icon-matelas-piscine": "Matelas-piscine",
            "icon-melangeur-de-cuisine": "Melangeur-de-cuisine",
            "icon-meuble-mural-de-rangement": "Meuble-mural-de-rangement",
            "icon-micro onde": "Micro onde",
            "icon-micro-onde": "Micro-onde",
            "icon-mini-bar": "Mini-bar",
            "icon-miroir": "Miroir",
            "icon-miroir-mural": "Miroir-mural",
            "icon-moquette": "Moquette",
            "icon-moteur-de-bateau": "Moteur-de-bateau",
            "icon-moteur-electrique": "Moteur-electrique",
            "icon-moulin-a-cafe": "Moulin-a-cafe",
            "icon-mur": "Mur",
            "icon-ordinateur-portable": "Ordinateur-portable",
            "icon-paillote": "Paillote",
            "icon-panier-a-linge": "Panier-a-linge",
            "icon-panneau-solaire": "Panneau-solaire",
            "icon-parabole-TV": "Parabole-TV",
            "icon-parasol": "Parasol",
            "icon-paravent": "Paravent",
            "icon-parking": "Parking",
            "icon-pc-fixe": "Pc-fixe",
            "icon-pediluve": "Pediluve",
            "icon-pese-personne": "Pese-personne",
            "icon-petrin": "Petrin",
            "icon-photocopieur": "Photocopieur",
            "icon-piano-cuisine": "Piano-cuisine",
            "icon-piano-cuisson": "Piano-cuisson",
            "icon-pied-tv-roulettes": "Pied-tv-roulettes",
            "icon-piscine": "Piscine",
            "icon-plafond": "Plafond",
            "icon-plage-de-piscine": "Plage-de-piscine",
            "icon-planche-a-voile": "Planche-a-voile",
            "icon-plinthe": "Plinthe",
            "icon-point-irrigation": "Point-irrigation",
            "icon-polycom": "Polycom",
            "icon-pompe": "Pompe",
            "icon-pompe-carburant": "Pompe-carburant",
            "icon-pompe-doseuse": "Pompe-doseuse",
            "icon-portail": "Portail",
            "icon-porte": "Porte",
            "icon-porte-anti-panique": "Porte-anti-panique",
            "icon-porte-coupe-feu": "Porte-coupe-feu",
            "icon-porte-manteau": "Porte-manteau",
            "icon-porte-serviette": "Porte-serviette",
            "icon-porte-vetements": "Porte-vetements",
            "icon-portillon": "Portillon",
            "icon-poste-transfo": "Poste-transfo",
            "icon-pot": "Pot",
            "icon-poubelle": "Poubelle",
            "icon-presentoir-boulangerie": "Presentoir-boulangerie",
            "icon-prise-de-courant": "Prise-de-courant",
            "icon-prise-reseau": "Prise-reseau",
            "icon-pupitre-roulettes": "Pupitre-roulettes",
            "icon-renforcement": "Renforcement",
            "icon-repose-pied": "Repose-pied",
            "icon-reseau-eau-potable": "Reseau-eau-potable",
            "icon-reseau-eaux-usees": "Reseau-eaux-usees",
            "icon-reseau-informatique": "Reseau-informatique",
            "icon-reseau-irrigation": "Reseau-irrigation",
            "icon-reserve-a-eau": "Reserve-a-eau",
            "icon-reservoir-carburant": "Reservoir-carburant",
            "icon-ria": "Ria",
            "icon-rice-cooker": "Rice-cooker",
            "icon-rideau": "Rideau",
            "icon-rideau-de-douche": "Rideau-de-douche",
            "icon-robinet": "Robinet",
            "icon-route-voierie": "Route-voierie",
            "icon-routeur-wifi": "Routeur-wifi",
            "icon-seche-cheveux": "Seche-cheveux",
            "icon-seche-main": "Seche-main",
            "icon-serrure-magnetique-porte": "Serrure-magnetique-porte",
            "icon-server": "Server",
            "icon-siege-de-direction": "Siege-de-direction",
            "icon-signaletique": "Signaletique",
            "icon-sol-dalle": "Sol-dalle",
            "icon-sommier": "Sommier",
            "icon-sterilisateur": "Sterilisateur",
            "icon-store": "Store",
            "icon-support-refrigere": "Support-refrigere",
            "icon-support-tv-mural": "Support-tv-mural",
            "icon-suspension": "Suspension",
            "icon-switch-network": "Switch-network",
            "icon-synthese-verification": "Synthese-verification",
            "icon-syphon-de-sol": "Syphon-de-sol",
            "icon-table": "Table",
            "icon-table-a-repasser": "Table-a-repasser",
            "icon-table-carre": "Table-carre",
            "icon-table-de-massage": "Table-de-massage",
            "icon-table-ronde": "Table-ronde",
            "icon-tableau": "Tableau",
            "icon-tabouret-de-bar-haut": "Tabouret-de-bar-haut",
            "icon-tabouret-general-bas": "Tabouret-general-bas",
            "icon-tapis-de-course": "Tapis-de-course",
            "icon-tapis-de-gym": "Tapis-de-gym",
            "icon-telecommande-clim": "Telecommande-clim",
            "icon-telecommande-tv": "Telecommande-tv",
            "icon-telephone-fixe": "Telephone-fixe",
            "icon-televiseur": "Televiseur",
            "icon-terrain-de-foot": "Terrain-de-foot",
            "icon-thermometre": "Thermometre",
            "icon-tiroirs-de-rangement": "Tiroirs-de-rangement",
            "icon-toile-tendue": "Toile-tendue",
            "icon-toiture": "Toiture",
            "icon-tondeuse": "Tondeuse",
            "icon-trancheuse": "Trancheuse",
            "icon-tremie": "Tremie",
            "icon-tribune-espace-sportif": "Tribune-espace-sportif",
            "icon-trottoir": "Trottoir",
            "icon-trousse-de-secours": "Trousse-de-secours",
            "icon-unite-exterieure-du-climatiseur": "Unite-exterieure-du-climatiseur",
            "icon-vaisselier": "Vaisselier",
            "icon-velo-appartement": "Velo-appartement",
            "icon-velo-d_appartement": "Velo-d_appartement",
            "icon-velo-ellyptique": "Velo-ellyptique",
            "icon-ventilateur-mural": "Ventilateur-mural",
            "icon-ventilateur-plafond": "Ventilateur-plafond",
            "icon-ventilateur-sur-pied": "Ventilateur-sur-pied",
            "icon-vestiaire": "Vestiaire",
            "icon-video-projecteur": "Video-projecteur",
            "icon-vmc": "Vmc",
            "icon-voilier-laser": "Voilier-laser",
            "icon-volet-battant": "Volet-battant",
            "icon-volet-roulant": "Volet-roulant",
            "icon-wifi": "Wifi",
            "icon-fooding-system": "Système d'alimentation",
            "icon-brooding-system": "Système de couvaison",
            "icon-drinking-system": "Système d'abreuvement",
            "icon-electrical-installation": "Installation électrique",
            "icon-atletisme": "Athletisme",
            "icon-base-nautique": "Base-nautique",
            "icon-bassin-natation": "Bassin-natation",
            "icon-boulodrome": "Boulodrome",
            "icon-but-basketball": "But-basketball",
            "icon-but-foot-cinq": "but-foot-cinq",
            "icon-but-foot-onze": "but-foot-onze",
            "icon-but-foot-sept": "but-foot-sept",
            "icon-but-handball": "but-handball",
            "icon-but-rugby": "but-rugby",
            "icon-court-tennis": "court-tennis",
            "icon-gymnase": "gymnase",
            "icon-mur-escalade": "mur-escalade",
            "icon-piste-bicross": "piste-bicross",
            "icon-plateau-eps": "plateau-eps",
            "icon-salle-arts-martiaux": "salle-arts-martiaux",
            "icon-salle-multisports": "salle-multisports",
            "icon-salle-musculation": "salle-musculation",
            "icon-skatepark": "skatepark",
            "icon-sports-de-combat": "sports-de-combat",
            "icon-terrain-exterieur-basket": "terrain exterieur basket",
            "icon-terrain-exterieur-hanball": "terrain exterieur hanball",
            "icon-terrain-footbal-naturel": "terrain footbal naturel",
            "icon-terrain-footbal-synthetique": "terrain footbal synthetique",
            "icon-terrain-roller": "terrain roller",
            "icon-terrain-rugby-naturel": "terrain rugby naturel",
            "icon-terrain-volley": "terrain-volley",
            "icon-accessoires": "Accessoires",
            "icon-boite-mouchoirs": "Boite à mouchoirs",
            "icon-eco-energie": "Economie d'énergie",
            "icon-patere": "Patère",
            "icon-porte-bagages": "Porte bagages",
            "icon-produit-accueil": "Produit accueil",
            "icon-support-gel-douche": "Support gel douche",
            "icon-tete-lit": "Tête de lit",
            "icon-aire-saut": "Aire de saut",
            "icon-artbook":"Artbook",
            "icon-bol-rond":"Bol rond",
            "icon-books":"Livres",
            "icon-serviette":"Serviette",
            "icon-umbrella-stand":"Porte-parapluie",
            "icon-umbrella":"Parapluie",
            "icon-vase":"Vase",
            "icon-cric": "Cric",
            "icon-elevateur": "Elévateur",
            "icon-leve-fut": "Lève fût",
            "icon-verrin-fosse": "Vérin de fosse",
            "icon-lave-vaisselle": "Lave vaisselle",
            "icon-terrain-padel": "Terrain padel",
            "icon-terrain-beach-sable": "Terrain beach volley",
            "icon-abord": "abord",
            "icon-appel-infirmiere": "appel infirmiere",
            "icon-attelle": "attelle",
            "icon-baies-de-sonorisation": "baies de sonorisation",
            "icon-baignade-amenagee": "baignade amenagee",
            "icon-batterie-de-condensateurs-hypermarche": "batterie de condensateurs hypermarche",
            "icon-batteries-de-condensateurs-galerie": "batteries de condensateurs galerie",
            "icon-bornes-de-recharge": "bornes de recharge",
            "icon-bornes-festives": "bornes festives",
            "icon-caniveau": "caniveau",
            "icon-ceinture-pelvienne-confort": "ceinture pelvienne confort",
            "icon-centrale-traitement-d-air-cta": "centrale traitement d'air cta",
            "icon-cercleuse-sp-400": "cercleuse sp 400",
            "icon-chambres-de-pousse": "chambres de pousse",
            "icon-changeur-de-monnaie": "changeur de monnaie",
            "icon-chargeurs-manutention-electrique": "chargeurs manutention electrique",
            "icon-circuit-frigorifique": "circuit frigorifique",
            "icon-circulateurs-doubles-refroidisseur-d-huile-negatif": "circulateurs doubles refroidisseur d'huile negatif",
            "icon-circulateurs-doubles-refroidisseur-d-huile-positif": "circulateurs doubles refroidisseur d'huile positif",
            "icon-coffre-de-transfert": "coffre de transfert",
            "icon-compresseurs-a-air-comprime": "compresseurs a air comprime",
            "icon-compteur-de-billets": "compteur de billets",
            "icon-compteurs-de-pieces": "compteurs de pieces",
            "icon-condenseur-negatif": "condenseur negatif",
            "icon-condenseur-positif": "condenseur positif",
            "icon-congelateur": "congelateur",
            "icon-container-40-pieds-carrier": "container 40 pieds carrier",
            "icon-coupe-haie": "coupe haie",
            "icon-coussin": "coussin",
            "icon-cuve": "cuve",
            "icon-dechets": "dechets",
            "icon-desemfumage-mecanique": "desemfumage mecanique",
            "icon-desemfumage-naturel": "desemfumage naturel",
            "icon-desenfileur": "desenfileur",
            "icon-destructeurs-d-insectes": "destructeurs d'insectes",
            "icon-disconnecteur": "disconnecteur",
            "icon-douche-acides": "douche acides",
            "icon-drapeaux": "drapeaux",
            "icon-drv": "drv",
            "icon-ecailleuse-poissonnerie": "ecailleuse poissonnerie",
            "icon-echarpes-de-contention-de-bras": "echarpes de contention de bras",
            "icon-electrocardiographe": "electrocardiographe",
            "icon-electrode-a-ventouse": "electrode a ventouse",
            "icon-emballeuse-a-baguettes": "emballeuse a baguettes",
            "icon-emballeuse-automatique-boucherie": "emballeuse automatique boucherie",
            "icon-emballeuse-automatique-traiteur": "emballeuse automatique traiteur",
            "icon-emballeuse-manuelle": "emballeuse manuelle",
            "icon-etagere-generale": "Etagère générale",
            "icon-evaporateur-drv": "evaporateur drv",
            "icon-extracteur-d-air": "extracteur d'air",
            "icon-filets-oiseaux": "filets oiseaux",
            "icon-friteuse": "friteuse",
            "icon-gerbeurs-electriques": "gerbeurs electriques",
            "icon-gondoles": "gondoles",
            "icon-groupe-de-pesee-automatique": "groupe de pesee automatique",
            "icon-guidon-de-transfert": "guidon de transfert",
            "icon-harnais-basic": "harnais basic",
            "icon-insufflateur": "insufflateur",
            "icon-lave-vaiselle": "lave vaiselle",
            "icon-lecteur-de-bandelettes": "lecteur de bandelettes",
            "icon-lit-ambulatoir": "lit ambulatoir",
            "icon-luminairehaut": "luminairehaut",
            "icon-machine-a-crepes": "machine a crepes",
            "icon-machine-a-glace": "machine a glace",
            "icon-machine-a-jus-d-orange": "machine a jus d'orange",
            "icon-meule-a-eau": "meule a eau",
            "icon-microonde": "microonde",
            "icon-montre-antifuge-appel-infirmiŠre": "montre antifuge appel infirmiŠre",
            "icon-muscu-cardio": "muscu cardio",
            "icon-nacelle": "nacelle",
            "icon-onduleur-galerie-20kva": "onduleur galerie 20kva",
            "icon-oxymetre-de-pouls": "oxymetre de pouls",
            "icon-oxymetre-saturometre": "oxymetre saturometre",
            "icon-paniers-clients": "paniers clients",
            "icon-peinture": "peinture",
            "icon-piste-athletisme-isolee": "piste athletisme isolee",
            "icon-planche-a-decouper": "planche a decouper",
            "icon-plaque-de-cuisson-electrique": "plaque de cuisson electrique",
            "icon-pompe-a-nutrition": "pompe a nutrition",
            "icon-pompe-a-perfusion": "pompe a perfusion",
            "icon-pompe-nutrition": "pompe nutrition",
            "icon-ponts-de-liaison": "ponts de liaison",
            "icon-port-de-plaisance": "port de plaisance",
            "icon-poste-de-relevage-cote-4-voies": "poste de relevage cote 4 voies",
            "icon-pousse-seringue": "pousse seringue",
            "icon-presse-a-cartons": "presse a cartons",
            "icon-presse-a-plastiques": "presse a plastiques",
            "icon-protections-metalliques": "protections metalliques",
            "icon-radiateur": "radiateur",
            "icon-refroidisseur-d-huile": "refroidisseur d'huile",
            "icon-rehausseur": "rehausseur",
            "icon-rideau-metallique": "rideau metallique",
            "icon-robocoupe": "robocoupe",
            "icon-rooftop-clim": "rooftop clim",
            "icon-rotissoire": "rotissoire",
            "icon-salle-de-danse": "salle de danse",
            "icon-scelleuse": "scelleuse",
            "icon-scie-a-ruban": "scie a ruban",
            "icon-sentier": "sentier",
            "icon-separateur-a-graisse-evac": "separateur a graisse evac",
            "icon-separateurs-hydrocarbures": "separateurs hydrocarbures",
            "icon-soufleur-de-feuilles": "soufleur de feuilles",
            "icon-sous-videuse": "sous videuse",
            "icon-sprinklers": "sprinklers",
            "icon-stade-athlétisme": "stade athlétisme",
            "icon-station-diagnostique": "station diagnostique",
            "icon-stethoscope": "stethoscope",
            "icon-structure-artificielle-escalade": "structure artificielle escalade",
            "icon-tensiometre": "tensiometre",
            "icon-tondeuse-chirurgicale": "tondeuse chirurgicale",
            "icon-tourelles-d-extraction": "tourelles d'extraction",
            "icon-transpalettes-electriques": "transpalettes electriques",
            "icon-tronconneuse": "tronconneuse",
            "icon-verticalisateur": "verticalisateur",
            "icon-centrale-regulation-chlore": "Centrale de régulation de chlore",
            "icon-groupe-eau-glacee": "Groupe d'eau glacée",
            "icon-optimiseur": "Optimiseur",
            "icon-reseau-ecs": "Réseau ECS",
            "icon-sauna": "Sauna",
            "icon-spa": "Spa",
            "icon-ambulance": "Ambulance",
            "icon-buggy": "Buggy",
            "icon-camionnette": "Camionnette",
            "icon-harnais": "Harnais",
            "icon-moto": "Moto",
            "icon-quad": "Quad",
            "icon-tyrolienne": "Tyrolienne",
            "icon-voiture": "Voiture",
            "icon-quad": "Quad",
            "icon-camion": "Camion",
            "icon-ceinture-securite": "Ceinture de sécurité",
            "icon-main-de-levage": "Main de levage",
            "icon-manille": "Manille",
            "icon-sangle": "Sangle",
            "icon-velo": "Vélo"
        },
        "en": {
            "placeholder-select-icon": "Select an icon",
            "icon-usb": "USB",
            "icon-NAS": "NAS",
            "icon-WC": "WC",
            "icon-alarme": "Alarm",
            "icon-alarme-incendie": "Fire alarm",
            "icon-arbre": "Tree",
            "icon-arbuste": "Scrub",
            "icon-armoire-basse": "Low cabinet",
            "icon-armoire-electrique": "Electrical cabinet",
            "icon-armoire-haute": "High cabinet",
            "icon-armoire-personnel-haute": "High personal cabinet",
            "icon-armoire-refrigeree": "Refrigerated cabinet",
            "icon-ascenseur": "Elevator",
            "icon-aspirateur": "Vacuum",
            "icon-automatisme-portail": "Gate automation",
            "icon-automatisme-volet": "Shutter automation",
            "icon-bac-a-graisse": "Fat pan",
            "icon-bac-de-douche": "Shower tray",
            "icon-baie-vitree": "Bay window",
            "icon-baignoire": "Bathtub",
            "icon-bain-de-soleil": "Sunbath",
            "icon-balai-WC": "Toilet broom",
            "icon-balance": "Scale",
            "icon-balancoire": "Swing",
            "icon-ballon-eau-chaude": "Hot water baloon",
            "icon-banc": "Bench",
            "icon-banc-de-musculation": "Exercise bench",
            "icon-barbecue": "Barbecue",
            "icon-barriere": "Fence",
            "icon-barriere-de-securite": "Security fence",
            "icon-batteur-melangeur": "Beater mixer",
            "icon-bibliotheque": "Library",
            "icon-blender": "Blender",
            "icon-bloc-eclairage-securite": "Security lighting block",
            "icon-bouche-a-incendie": "Fire hydrant",
            "icon-bouilloire": "Kettle",
            "icon-brouette": "Wheelbarrow",
            "icon-bureau": "Desk",
            "icon-cage-de-but": "Goal cage",
            "icon-camera-de-surveillance": "Surveillance camera",
            "icon-canape": "Sofa",
            "icon-capteur": "Sensor",
            "icon-cardio": "Cardio",
            "icon-carrelage": "Floor tile",
            "icon-casier-personnel": "Personal locker",
            "icon-cave-a-vin": "Wine cellar",
            "icon-chaise": "Chair",
            "icon-chambre-froide": "Cold room",
            "icon-chariot-general": "General trolley",
            "icon-chariot-medicaments": "Medication trolley",
            "icon-chariot-repas": "Meal trolley",
            "icon-chariot-sac-golf": "Cart bag golf",
            "icon-chauffage": "Heater",
            "icon-chauffe-cire": "Wax heater",
            "icon-chauffe-eau-solaire": "Solar water heater",
            "icon-chauffe-plat": "Dish warmerr",
            "icon-chausee": "Pavement",
            "icon-clavier": "Keyboard",
            "icon-climatiseur": "Air conditioner",
            "icon-clos-couvert": "Closed covered",
            "icon-cloture": "Fencing",
            "icon-coffre-fort": "Safe",
            "icon-compteur-a-gaz": "Gas meter",
            "icon-compteur-d_eau": "Water meter",
            "icon-compteur-electrique": "Electric meter",
            "icon-court-de-tennis": "Tennis court",
            "icon-couverture-piscine": "Pool cover",
            "icon-cuisiniere": "Cooker",
            "icon-deambulateur": "Walker",
            "icon-debroussailleuse": "Brrush cutter",
            "icon-defibrilateur": "Defibrillator",
            "icon-descente-de-gouttiere": "Downspout",
            "icon-deshumidificateur": "Dehumidifier",
            "icon-desserte-de-repas": "Meal service",
            "icon-desserte-roulante": "Rolling trolley",
            "icon-detecteur-de-fumee": "Smoke detector",
            "icon-diable": "Hand truck",
            "icon-digicode": "Digital code",
            "icon-distributeur-de-papier": "Paper dispenser",
            "icon-distribution-signal-tv": "TV signal distribution",
            "icon-douche-exterieure": "Outdoor shower",
            "icon-echelle": "Ladder",
            "icon-eclairage-exterieur": "Outdoor lighting",
            "icon-eclairage-stade": "Stadium lighting",
            "icon-ecran-ordinateur": "Computer scree ",
            "icon-ecran-projecteur": "Projector screen",
            "icon-element-decoratif": "Decorative element",
            "icon-enceinte-audio": "Audio speaker",
            "icon-escabeau": "Stool",
            "icon-escalier": "Stairs",
            "icon-espace-vert": "Green area",
            "icon-etagere-generale": "General shelf",
            "icon-etagere": "Etagere",
            "icon-etagere-haute-de-rangement": "High storage shelf",
            "icon-etagere-murale-de-cuisine": "Kitchen wall shelf",
            "icon-extincteur1": "Fire extinguisher 1",
            "icon-extincteur": "Fire extinguisher",
            "icon-extincteur-eau": "Water extinguisher",
            "icon-extincteur-gaz": "Gaz extinguisher",
            "icon-extincteur-mousse": "Foam extinguisher",
            "icon-extincteur-poudre": "Powder extinguisher",
            "icon-facade": "Facade",
            "icon-fausse-septique": "Septic tank",
            "icon-fauteuil": "Armchair",
            "icon-fauteuil-roulant": "Wheel chair",
            "icon-fenetre": "Window",
            "icon-fer-a-repasser": "Iron",
            "icon-fience-murale": "Faience-mural",
            "icon-filet-de-tennis": "Tennis net",
            "icon-filet-volley-ball": "Volleyball net",
            "icon-filtre-piscine": "Pool filter",
            "icon-firewall": "Firewall",
            "icon-flexible-de-douche": "Shower hose",
            "icon-fontaine": "Fountain",
            "icon-four": "Oven",
            "icon-four-a-boulangerie": "Bakery oven",
            "icon-four-a-pizza": "Pizza oven",
            "icon-frigo": "Fridge",
            "icon-garde-corps": "Crazy guard",
            "icon-gaufrier": "Waffle iron",
            "icon-golf": "Golf",
            "icon-gouttiere": "Gutter",
            "icon-grill": "Grill",
            "icon-grille-de-protection": "Protecive grid",
            "icon-grille-pain": "Toaster",
            "icon-groom-porte": "Groom-porte",
            "icon-groupe-electrogene": "Generator",
            "icon-guirlande-lumineuse": "Light string",
            "icon-gym": "Gym",
            "icon-hachoir-a-viande": "Meat grinder",
            "icon-haie": "Hedge",
            "icon-horloge-murale": "Wall clock",
            "icon-hotte": "Hood",
            "icon-hygromètre": "Hygrometer",
            "icon-imprimante": "Printer",
            "icon-indicateur-de-niveau": "Level indicator",
            "icon-insect-killer": "Insect-killer",
            "icon-installation-electrique": "Electrical installation",
            "icon-installation-gaz": "Gas installation",
            "icon-interphone": "Intercom",
            "icon-interrupteur": "Light switch",
            "icon-interupteur": "Light switch",
            "icon-jacuzzi": "Jacuzzi",
            "icon-jalousie": "Jalousie",
            "icon-jardiniere": "Planter",
            "icon-kayak": "Kayak",
            "icon-laminoir": "Rolling mill",
            "icon-lampadaire": "Street lamp",
            "icon-lampe-de-bureau": "Desk lamp",
            "icon-lavabo": "Sink",
            "icon-lave-linge": "Washing machine",
            "icon-lave-main": "Hand washing",
            "icon-lave-verre": "Glass washer",
            "icon-lecteur-DVD": "DVD Player",
            "icon-leve-malade": "Patient lift",
            "icon-lit": "Bed",
            "icon-lit-ambulatoir": "Ambulatory bed",
            "icon-lit-ambulatoire": "Ambulatory bed",
            "icon-lit-medicalise": "Medical bed",
            "icon-lit-supperpose": "Bunk bed",
            "icon-luminaire haut": "High light",
            "icon-luminaire": "Lamp",
            "icon-luminaire-boule-jardin": "Garden ball light",
            "icon-luminaire-chambre": "Room lamp",
            "icon-luminaire-exterieur": "Outdoor lighting",
            "icon-luminaire-lustre": "Chandelier light",
            "icon-luminaire-suspension": "Pendant light",
            "icon-lutrin-porte-menu": "Lectern menu holder",
            "icon-machine-a-cafe": "Coffee machine",
            "icon-machine-a-coudre": "Sewing machine",
            "icon-machine-a-glacon": "Ice machine",
            "icon-main-courante": "Handraill",
            "icon-marquage-au-sol": "Markings",
            "icon-matelas": "Mattress",
            "icon-matelas-piscine": "Pool mattress",
            "icon-melangeur-de-cuisine": "Kitchen mixer",
            "icon-meuble-mural-de-rangement": "Wall mounted storage cabinet",
            "icon-micro onde": "Microwave",
            "icon-micro-onde": "Microwave",
            "icon-mini-bar": "Minibar",
            "icon-miroir": "Mirror",
            "icon-miroir-mural": "Wall mounted mirror",
            "icon-moquette": "Carpet",
            "icon-moteur-de-bateau": "Boat engine",
            "icon-moteur-electrique": "Electrical engine",
            "icon-moulin-a-cafe": "Coffee grinder",
            "icon-mur": "Wall",
            "icon-ordinateur-portable": "Laptop",
            "icon-paillote": "Straw hut",
            "icon-panier-a-linge": "Laundry basket",
            "icon-panneau-solaire": "Solar panel",
            "icon-parabole-TV": "Parable-TV",
            "icon-parasol": "Beach umbrella",
            "icon-paravent": "Screen",
            "icon-parking": "Parking",
            "icon-pc-fixe": "PC",
            "icon-pediluve": "Pedilbath",
            "icon-pese-personne": "Scale",
            "icon-petrin": "Kneader",
            "icon-photocopieur": "Photocopier",
            "icon-piano-cuisine": "Piano kitchen",
            "icon-piano-cuisson": "Piano cooking",
            "icon-pied-tv-roulettes": "Tv stand casters",
            "icon-piscine": "Swimming pool",
            "icon-plafond": "Seilling",
            "icon-plage-de-piscine": "Pool deck",
            "icon-planche-a-voile": "Sailboard",
            "icon-plinthe": "Baseboard",
            "icon-point-irrigation": "Irrigation point",
            "icon-polycom": "Polycom",
            "icon-pompe": "Pump",
            "icon-pompe-carburant": "Fuel pump",
            "icon-pompe-doseuse": "Metering pump",
            "icon-portail": "Portal",
            "icon-porte": "Door",
            "icon-porte-anti-panique": "Panic door",
            "icon-porte-coupe-feu": "Fire door",
            "icon-porte-manteau": "Coat hanger",
            "icon-porte-serviette": "Towel holder",
            "icon-porte-vetements": "Clothes rack",
            "icon-portillon": "Gate",
            "icon-poste-transfo": "Substation",
            "icon-pot": "Pot",
            "icon-poubelle": "Trash can",
            "icon-presentoir-boulangerie": "Bakery display",
            "icon-prise-de-courant": "Plug",
            "icon-prise-reseau": "Network socket",
            "icon-pupitre-roulettes": "Rolling desk",
            "icon-renforcement": "Enhancement",
            "icon-repose-pied": "Footrest",
            "icon-reseau-eau-potable": "Drinking water network",
            "icon-reseau-eaux-usees": "Wastewater network",
            "icon-reseau-informatique": "IT network",
            "icon-reseau-irrigation": "Irrigation network",
            "icon-reserve-a-eau": "Water reserve",
            "icon-reservoir-carburant": "Fuel tank",
            "icon-ria": "Ria",
            "icon-rice-cooker": "Rice-cooker",
            "icon-rideau": "Curtain",
            "icon-rideau-de-douche": "Shower curtain",
            "icon-robinet": "Tap",
            "icon-route-voierie": "Road",
            "icon-routeur-wifi": "Wifi router",
            "icon-seche-cheveux": "Hair dryer",
            "icon-seche-main": "Hand dryer",
            "icon-serrure-magnetique-porte": "Magnetic door lock",
            "icon-server": "Server",
            "icon-siege-de-direction": "Headquarters",
            "icon-signaletique": "Signage",
            "icon-sol-dalle": "Floor slab",
            "icon-sommier": "Bedspring",
            "icon-sterilisateur": "Sterilizer",
            "icon-store": "Store",
            "icon-support-refrigere": "Refrigerator stand",
            "icon-support-tv-mural": "TV wall mount",
            "icon-suspension": "Suspension",
            "icon-switch-network": "Switch-network",
            "icon-synthese-verification": "Verification summary",
            "icon-syphon-de-sol": "Ground siphon",
            "icon-table": "Table",
            "icon-table-a-repasser": "Ironing board",
            "icon-table-carre": "Square table",
            "icon-table-de-massage": "Massage table",
            "icon-table-ronde": "Round table",
            "icon-tableau": "Board",
            "icon-tabouret-de-bar-haut": "High bar stool",
            "icon-tabouret-general-bas": "Generral low stool",
            "icon-tapis-de-course": "Treadmill",
            "icon-tapis-de-gym": "Fitness mat",
            "icon-telecommande-clim": "Air conditioner remote control",
            "icon-telecommande-tv": "TV remote",
            "icon-telephone-fixe": "Telephone",
            "icon-televiseur": "TV",
            "icon-terrain-de-foot": "Soccer field",
            "icon-thermometre": "Thermometer",
            "icon-tiroirs-de-rangement": "Storage drawers",
            "icon-toile-tendue": "Stretched canvas",
            "icon-toiture": "Roofing",
            "icon-tondeuse": "Mower",
            "icon-trancheuse": "Slicer",
            "icon-tremie": "Hopper",
            "icon-tribune-espace-sportif": "Tribune sports area",
            "icon-trottoir": "Pavement",
            "icon-trousse-de-secours": "First aid kit",
            "icon-unite-exterieure-du-climatiseur": "Outdoor air conditioner unit",
            "icon-vaisselier": "Dresser",
            "icon-velo-appartement": "Apartment bike",
            "icon-velo-d_appartement": "Apartment bike",
            "icon-velo-ellyptique": "Elliptical bike",
            "icon-ventilateur-mural": "Wall fan",
            "icon-ventilateur-plafond": "Seilling fan",
            "icon-ventilateur-sur-pied": "Pedestal fan",
            "icon-vestiaire": "locker room",
            "icon-video-projecteur": "Video projector",
            "icon-vmc": "Vmc",
            "icon-voilier-laser": "Laser sailboat",
            "icon-volet-battant": "Swing shutter",
            "icon-volet-roulant": "Roller shutter",
            "icon-wifi": "Wifi",
            "icon-fooding-system": "Fooding system",
            "icon-brooding-system": "Brooding system",
            "icon-drinking-system": "Drinking system",
            "icon-electrical-installation": "Electrical installation",
            "icon-atletisme": "athletics",
            "icon-base-nautique": "Nautic base",
            "icon-bassin-natation": "Swimming pool",
            "icon-boulodrome": "Bowling alley",
            "icon-but-basketball": "Basketball goal",
            "icon-but-foot-cinq": "Football goal (5)",
            "icon-but-foot-onze": "Football goal (11)",
            "icon-but-foot-sept": "Football goal (7)",
            "icon-but-handball": "Handball goal",
            "icon-but-rugby": "Rugby goal",
            "icon-court-tennis": "Tennis court",
            "icon-gymnase": "Gymnasium",
            "icon-mur-escalade": "climbing wall",
            "icon-piste-bicross": "bicross track",
            "icon-plateau-eps": "Tray EPS",
            "icon-salle-arts-martiaux": "Martial arts room",
            "icon-salle-multisports": "Multisports room",
            "icon-salle-musculation": "bodybuilding room",
            "icon-skatepark": "skatepark",
            "icon-sports-de-combat": "Combats sports",
            "icon-terrain-exterieur-basket": "Outdoor basketball court",
            "icon-terrain-exterieur-hanball": "Outdoor Hanball court",
            "icon-terrain-footbal-naturel": "Natural football field",
            "icon-terrain-footbal-synthetique": "Synthetic football field",
            "icon-terrain-roller": "Rollerblading ground",
            "icon-terrain-rugby-naturel": "Natural rugby ground",
            "icon-terrain-volley": "Volleyball court",
            "icon-accessoires": "Accessories",
            "icon-boite-mouchoirs": "Tissue box",
            "icon-eco-energie": "Energy saving",
            "icon-patere": "Hook",
            "icon-porte-bagages": "Luggage rack",
            "icon-produit-accueil": "Welcome product",
            "icon-support-gel-douche": "Shower gel holder",
            "icon-tete-lit": "Bedhead",
            "icon-aire-saut": "Jumping area",
            "icon-artbook":"Artbook",
            "icon-bol-rond":"Rond bowl",
            "icon-books":"Books",
            "icon-serviette":"Towel",
            "icon-umbrella-stand":"Umbrella stand",
            "icon-umbrella":"Umbrella",
            "icon-vase":"Vase",
            "icon-cric": "Car jack",
            "icon-elevateur": "Elevator",
            "icon-leve-fut": "Barrel lifter",
            "icon-verrin-fosse": "Pit jack",
            "icon-lave-vaisselle": "Dishwasher",
            "icon-terrain-padel": "Paddle court",
            "icon-terrain-beach-sable": "Beach volleyball court",
            "icon-abord": "shore",
            "icon-appel-infirmiere": "nurse call",
            "icon-attelle": "splint",
            "icon-baies-de-sonorisation": "soundproofing bays",
            "icon-baignade-amenagee": "equipped bathing",
            "icon-batterie-de-condensateurs-hypermarche": "hypermarket capacitor bank",
            "icon-batteries-de-condensateurs-galerie": "gallery capacitor banks",
            "icon-bornes-de-recharge": "charging stations",
            "icon-bornes-festives": "festive terminals",
            "icon-caniveau": "channel",
            "icon-ceinture-pelvienne-confort": "comfort pelvic belt",
            "icon-centrale-traitement-d-air-cta": "air handling unit CTA",
            "icon-cercleuse-sp-400": "strapping machine SP-400",
            "icon-chambres-de-pousse": "proofing chambers",
            "icon-changeur-de-monnaie": "currency exchanger",
            "icon-chargeurs-manutention-electrique": "electric handling chargers",
            "icon-circuit-frigorifique": "refrigeration circuit",
            "icon-circulateurs-doubles-refroidisseur-d-huile-negatif": "double circulators negative oil cooler",
            "icon-circulateurs-doubles-refroidisseur-d-huile-positif": "double circulators positive oil cooler",
            "icon-coffre-de-transfert": "transfer box",
            "icon-compresseurs-a-air-comprime": "compressed air compressors",
            "icon-compteur-de-billets": "bill counter",
            "icon-compteurs-de-pieces": "coin counters",
            "icon-condenseur-negatif": "negative condenser",
            "icon-condenseur-positif": "positive condenser",
            "icon-congelateur": "freezer",
            "icon-container-40-pieds-carrier": "40-foot carrier container",
            "icon-coupe-haie": "hedge trimmer",
            "icon-coussin": "cushion",
            "icon-cuve": "tank",
            "icon-dechets": "waste",
            "icon-desemfumage-mecanique": "mechanical smoke extraction",
            "icon-desemfumage-naturel": "natural smoke extraction",
            "icon-desenfileur": "threader",
            "icon-destructeurs-d-insectes": "insect killers",
            "icon-disconnecteur": "backflow preventer",
            "icon-douche-acides": "acid shower",
            "icon-drapeaux": "flags",
            "icon-drv": "VRF",
            "icon-ecailleuse-poissonnerie": "fish scaler",
            "icon-echarpes-de-contention-de-bras": "arm restraint slings",
            "icon-electrocardiographe": "electrocardiograph",
            "icon-electrode-a-ventouse": "suction electrode",
            "icon-emballeuse-a-baguettes": "baguette packaging machine",
            "icon-emballeuse-automatique-boucherie": "automatic butcher packaging machine",
            "icon-emballeuse-automatique-traiteur": "automatic caterer packaging machine",
            "icon-emballeuse-manuelle": "manual packaging machine",
            "icon-etagere-generale": "general shelf",
            "icon-evaporateur-drv": "VRF evaporator",
            "icon-extracteur-d-air": "air extractor",
            "icon-filets-oiseaux": "bird nets",
            "icon-friteuse": "fryer",
            "icon-gerbeurs-electriques": "electric stackers",
            "icon-gondoles": "shelves",
            "icon-groupe-de-pesee-automatique": "automatic weighing group",
            "icon-guidon-de-transfert": "transfer handlebar",
            "icon-harnais-basic": "basic harness",
            "icon-insufflateur": "insufflator",
            "icon-lave-vaiselle": "dishwasher",
            "icon-lecteur-de-bandelettes": "strip reader",
            "icon-lit-ambulatoir": "ambulatory bed",
            "icon-luminairehaut": "high luminaire",
            "icon-machine-a-crepes": "crepe maker",
            "icon-machine-a-glace": "ice machine",
            "icon-machine-a-jus-d-orange": "orange juice machine",
            "icon-meule-a-eau": "water wheel",
            "icon-microonde": "microwave",
            "icon-montre-antifuge-appel-infirmiŠre": "anti-fall nurse call watch",
            "icon-muscu-cardio": "cardio exercise equipment",
            "icon-nacelle": "boom lift",
            "icon-onduleur-galerie-20kva": "20kVA gallery inverter",
            "icon-oxymetre-de-pouls": "pulse oximeter",
            "icon-oxymetre-saturometre": "pulse oximeter saturator",
            "icon-paniers-clients": "shopping baskets",
            "icon-peinture": "paint",
            "icon-piste-athletisme-isolee": "isolated athletics track",
            "icon-planche-a-decouper": "cutting board",
            "icon-plaque-de-cuisson-electrique": "electric cooking plate",
            "icon-pompe-a-nutrition": "nutrition pump",
            "icon-pompe-a-perfusion": "perfusion pump",
            "icon-pompe-nutrition": "nutrition pump",
            "icon-ponts-de-liaison": "link bridges",
            "icon-port-de-plaisance": "marina",
            "icon-poste-de-relevage-cote-4-voies": "4-way lift station",
            "icon-pousse-seringue": "syringe driver",
            "icon-presse-a-cartons": "cardboard press",
            "icon-presse-a-plastiques": "plastic press",
            "icon-protections-metalliques": "metal protections",
            "icon-radiateur": "radiator",
            "icon-refroidisseur-d-huile": "oil cooler",
            "icon-rehausseur": "booster seat",
            "icon-rideau-metallique": "metal curtain",
            "icon-robocoupe": "food processor",
            "icon-rooftop-clim": "rooftop HVAC",
            "icon-rotissoire": "rotisserie",
            "icon-salle-de-danse": "dance hall",
            "icon-scelleuse": "sealer",
            "icon-scie-a-ruban": "band saw",
            "icon-sentier": "path",
            "icon-separateur-a-graisse-evac": "grease separator evac",
            "icon-separateurs-hydrocarbures": "hydrocarbon separators",
            "icon-soufleur-de-feuilles": "leaf blower",
            "icon-sous-videuse": "vacuum sealer",
            "icon-sprinklers": "sprinklers",
            "icon-stade-athletisme": "athletics stadium",
            "icon-station-diagnostique": "diagnostic station",
            "icon-stethoscope": "stethoscope",
            "icon-structure-artificielle-escalade": "artificial climbing structure",
            "icon-tensiometre": "sphygmomanometer",
            "icon-tondeuse-chirurgicale": "surgical trimmer",
            "icon-tourelles-d-extraction": "extraction turrets",
            "icon-transpalettes-electriques": "electric pallet jacks",
            "icon-tronconneuse": "chainsaw",
            "icon-verticalisateur": "verticalizer",
            "icon-centrale-regulation-chlore": "Chlorine Regulation Central",
            "icon-groupe-eau-glacee": "Chilled Water System",
            "icon-optimiseur": "Optimizer",
            "icon-reseau-ecs": "Domestic Hot Water System",
            "icon-sauna": "Sauna",
            "icon-spa": "Spa",
            "icon-ambulance": "Ambulance",
            "icon-buggy": "Buggy",
            "icon-camionnette": "Van",
            "icon-harnais": "Harness",
            "icon-moto": "Motorcycle",
            "icon-quad": "Quad",
            "icon-tyrolienne": "Zip line",
            "icon-voiture": "Car",
            "icon-quad": "Quad",
            "icon-camion": "Truck",
            "icon-ceinture-securite": "Seat belt",
            "icon-main-de-levage": "Lifting hand",
            "icon-manille": "Shackle",
            "icon-sangle": "Strap",
            "icon-velo": "Bike"
        }
    }
},
		created: function(){
			this.ComposantMixins_getIcons().then((icons)=>{
				console.log("ICONS", icons);
				this.icons = icons;
			});
		},
		mounted: function(){
            
		},
		methods: {
            
		},
        computed: {
            getIcons: function(){
                return this.icons.sort((i1,i2)=>{
                    return i1.label.localeCompare(i2.label);
                });
            }
		}
    };

</script>
<style lang="scss" scoped>
.option{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 5px 0px;
}
</style>
