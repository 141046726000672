<template>
    <span v-if="isQuantiteStockAvant">{{ quantiteStockAvant }} {{params.data.consommable.unite?params.data.consommable.unite:"u."}}</span>
    <span v-else-if="isQuantiteStockApres">{{ quantiteStockApres }} {{params.data.consommable.unite?params.data.consommable.unite:"u."}}</span>
    <span v-else-if="isQuantiteEntree && quantiteEntree">{{ quantiteEntree }} {{params.data.consommable.unite?params.data.consommable.unite:"u."}}</span>
    <span v-else-if="isQuantiteSortie && quantiteSortie">{{ quantiteSortie }} {{params.data.consommable.unite?params.data.consommable.unite:"u."}}</span>
    <span v-else></span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isQuantiteStockAvant: {
                type: Boolean,
                default: false
            },
            isQuantiteStockApres: {
                type: Boolean,
                default: false
            },
            isQuantiteEntree: {
                type: Boolean,
                default: false
            },
            isQuantiteSortie: {
                type: Boolean,
                default: false
            }
		},
        computed: {
            quantiteEntree: function(){
                if(this.params.data.flag=="consommation-fm") return null;
                else if(this.params.data.flag=="livraison") return this.params.data.quantite;
                else if(this.params.data.flag=="inventaire-plus") return Number(this.params.data.quantite) - Number(this.params.data.quantiteInitialeOrigin);
                else if(this.params.data.flag=="inventaire-moins") return null;
                else if(this.params.data.flag=="bon-de-sortie") return null;
                else if(this.params.data.flag=="bon-dentree") return this.params.data.quantite;
                else if(this.params.data.flag=="consommation-verification") return null;
                else if(this.params.data.flag=="bon-de-commande-livraison") return this.params.data.quantite;
                else return null;
            },
            quantiteSortie: function(){
                if(this.params.data.flag=="consommation-fm") return this.params.data.quantite;
                else if(this.params.data.flag=="livraison") return null;
                else if(this.params.data.flag=="inventaire-plus") return null;
                else if(this.params.data.flag=="inventaire-moins") return Number(this.params.data.quantiteInitialeOrigin) - Number(this.params.data.quantite);
                else if(this.params.data.flag=="bon-de-sortie") return this.params.data.quantite;
                else if(this.params.data.flag=="bon-dentree") return null;
                else if(this.params.data.flag=="consommation-verification") return this.params.data.quantite;
                else if(this.params.data.flag=="bon-de-commande-livraison") return null;
                else return this.params.data.quantite;
            },
            quantiteStockApres: function(){
                if(this.params.data.flag=="inventaire-moins" || this.params.data.flag=="inventaire-plus") return this.params.data.quantite;
                let quantiteEntree = this.quantiteEntree?Number(this.quantiteEntree):0;
                let quantiteSortie = this.quantiteSortie?Number(this.quantiteSortie):0;
                return this.quantiteStockAvant + quantiteEntree - quantiteSortie;
            },
            quantiteStockAvant: function(){
                return Number(this.params.data.quantiteInitialeOrigin);
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>
