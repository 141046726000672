<template>
    <vg-fichesav-description :fm="params.data">
    </vg-fichesav-description>
</template>

<script>
    import Vue from "vue";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgFichesavDescription
        }
    });
</script>

<style lang="scss" scoped>

</style>
