<template lang="html">
    <vg-modal @close="close"
        :title="$t('title')">
        <template #body>
            <div class="replace-qr-code" >
                <div class="info-equipement">
                    <b>{{equipement.libel_equipement}}</b><span class="grey-color">{{ $t('dans') }}</span>  {{equipement.lieu.path | piece(equipement.lieu.path)}}
                </div>
                <div class="old-info">
                    <span class="grey-color">{{ $t('old-qr-code') }}</span>
                    <span>{{equipement.qrCode}}</span>
                </div>
                <div class="new-info">
                    <div class="grey-color">{{ $t('new-qr-code') }}</div>
                    <div class="scan-qr-code">
                        <input
                            v-show="false"
                            ref="replace-qr-code-submit"
                            type="submit"
                            >
                        <form onSubmit="return false;" ref="replace-qr-code-form">
                            <vg-input v-model="newQrCode"></vg-input>
                            <img src="/static/assets/icone/inventaire.png" @click="showModalQrCode=true">
                        </form>
                    </div>
                    <vg-modal v-if="showModalQrCode" @close="showModalQrCode = false">
                        <template #body>
                            <zxing-scanner
                                @scanSuccess="scanSuccess"
                                :isDemo="true"
                                :code="'qrcode'"
                                ></zxing-scanner>
                        </template>
                        <template #footer>
                            <vg-button
                                @click="showModalQrCode = false"
                                >{{ $t('btn-retour') }}</vg-button>
                        </template>
                    </vg-modal>
                </div>
                <vg-checkbox 
                    :label="$t('refresh-start-date')"
                    v-model="refreshStartDate"
                    @checked="refreshStartDate = true"
                    @unchecked="refreshStartDate = false"
                />
            </div>
        </template>
        <template #footer>
            <vg-button
                @click="close"
                :type="'grey'"
                >{{ $t('btn-retour') }}</vg-button>
            <vg-button class="btn-save-replace-qr-code" :type="'danger'" @click="saveData">{{ $t('btn-save') }}</vg-button>
        </template>
    </vg-modal>
</template>

<script>
import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';

import equipementsMixins from "src/mixins/equipementsMixins.js";
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
import { mapGetters } from 'vuex';

export default {
    name:"replace-qr-code-form",
    props:{
        /**
        * Id de l'equipement associé pour la suppression / fetch data
        * @type number
        **/
        equipementId:{
            type:Number,
            required:true
        }
    },
    components:{
        VgInput,
        VgCheckbox,
        VgButton,
        zxingScanner
    },
    filters:{
        piece:function(path){
            var piece ;
            if (path) {
                piece = path.split("/")[4];
            }
            return piece;

        }
    },
    mixins:[
        equipementsMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Remplacer QRCODE équipement",
            "dans": "dans",
            "new-qr-code": "Nouveau QRCODE ",
            "old-qr-code": "Ancien QRCODE",
            "btn-retour": "Annuler",
            "btn-save": "Enregistrer Définivement le nouveau QRCODE",
            "refresh-start-date": "Rafraîchir la date de mise en service à aujourd'hui",
            "error-message": "Veuillez saisir un QrCode"
        },
        "en": {
            "title": "Replace QRCODE equipment",
            "dans": "in",
            "new-qr-code": "New  QRCODE ",
            "old-qr-code": "Old QRCODE",
            "btn-retour": "Cancel",
            "btn-save": "Definitely save the new QRCODE",
            "refresh-start-date": "Reset start date to now",
            "error-message": "Please enter a QrCode"
        },
        "th": {
            "title": "เปลี่ยนรหัสคิวอาร์อุปกรณ์",
            "dans": "ใน",
            "new-qr-code": "รหัสคิวอาร์ใหม่ ",
            "old-qr-code": "รหัสคิวอาร์เก่า",
            "btn-retour": "ยกเลิก",
            "btn-save": "บันทึกรหัสคิวอาร์ใหม่อย่างแน่นอน",
            "error-message": "โปรดกรอกรหัสคิวอาร์"
        }
    }
},
    data:function(){
        return{
            newQrCode:"",
            showModalQrCode:false,
            "refreshStartDate":false
        }
    },
    methods:{
        /**
         * verifie les données puis envoie les données
         */
        saveData:function(){
            this.$refs['replace-qr-code-submit'].click();
            let form = this.$refs['replace-qr-code-form'];
            let miseEnService = this.refreshMiseEnServiceDate(this.refreshStartDate);
            if (form.checkValidity() && this.newQrCode.length > 0) {
                let equipementPayload = {
                    "id":this.equipement.id,
                    "qrCode":this.newQrCode,
                    "userId":this.$app.appID
                };
                miseEnService ?  equipementPayload["miseEnService"] = miseEnService : null ;
                // this.$emit("input", this.newQrCode);
                this.equipementsMixins_update(equipementPayload).then((equipement)=>{
                    this.$emit('close');
                });

            }
            else{
                alert(this.$t("error-message"))
            }
        },
        /**
         * @param boolean bool
         */
        refreshMiseEnServiceDate:function(bool){
            return bool ? moment().format("YYYY-MM-DD HH:mm:ss") : null;
        },
        /**
         * @emit pour cloturer la modale
         */
        close:function(){
            this.$emit('close');
        },
        /**
         * requete l'equipement concerné
         */
        fetchEquipement: function(){
            this.equipementsMixins_getEquipement(this.equipementId).then((equipement)=>{
            });
        },
        /**
         * Recuperation des info aprés le scan reussi
         */
        scanSuccess: function(content) {
            this.newQrCode = content;
            this.showModalQrCode = false;

        },
    },
    created:function(){
        //this.fetchEquipement();
    },
    computed:{
        ...mapGetters({
            equipement: 'EquipementsStore/getSelectedItem'
        }),
    }
}
</script>

<style lang="scss" scoped>
.btn-save-replace-qr-code{
    font-size: 11px !important;
}
.replace-qr-code{
    .grey-color{
        color:#b8b8b8;
    }
    .info-equipement{
        margin:20px 0;
    }
    .old-info{
        margin-bottom: 20px;
        span{
            margin-right: 15px;
        }
    }
    .new-info{
        >.grey-color{
            margin-bottom: 5px;
        }
        .scan-qr-code{
            display: flex;
            form{
                display: flex;
                width: 100%;
            }
            .vg-input-block{
                width: 90%;

            }
            img{
                margin: 0;
                height: 30px;
                margin-left: 10px;
            }
        }
    }

}

</style>
<docs>
    Composant utiliser dans le widgettree
    'src/components/Tree2/widgettree.vue'
    ## Usage du composant form
    ```html static
          <replace-qr-code-form :equipementId="equipement.id" v-if="modalReplaceQrCode" @close="modalReplaceQrCode = false"></replace-qr-code-form>
    ```
    ## Possede un emit pour cloturer la modale
    ```javascript
    @close="modalReplaceQrCode = false"
    ```

    ## Props
    ```javascript
    equipementId:{
        type:Number,
        required:true
    }
    ```
    ## mixin
    ```javascript
          equipementsMixins_getEquipementById
    ```


</docs>
