<template>
    <div class="vg-fiche-demande-consommables-table">
        <tag-grid v-if="isTableau"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="fiches"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick"
        />
        <div v-else class="vg-fiche-demande-consommables-liste">

        </div>
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        />
    </div>
</template>
<script>
import FicheDemandeConsommablesMixins from "src/mixins/FicheDemandeConsommablesMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
import TVACellRender from "src/components/Vg/TagGrid/TVACellRender.vue";
import BonDeCommandeDemandeurCellRender from "src/components/Vg/TagGrid/BonDeCommandeDemandeurCellRender.vue";
import BonDeCommandeStatutCellRender from "src/components/Vg/TagGrid/BonDeCommandeStatutCellRender.vue";
import ConsommableDetailsCellRender from "src/components/Vg/TagGrid/ConsommableDetailsCellRender.vue";
import FicheDemandeConsommablesCellRender from "src/components/Vg/TagGrid/FicheDemandeConsommablesCellRender.vue";
import FicheDemandeConsommablesStatutCellRender from "src/components/Vg/TagGrid/FicheDemandeConsommablesStatutCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-fiche-demande-consommables-table',
    components:{
        TagGrid,
        VgPagination,
        ConsommableDetailsCellRender
    },
    mixins:[FicheDemandeConsommablesMixins],
    props: {
        id:{
            type: String,
            default: "fiche-demande-consommables-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "overlay-loading-text": "Chargement des fiches demande consommables...",
    		"overlay-no-rows-text": "Aucune fiche demande consommable correspondant au filtrage",
            "header-numero": "Numéro",
            "header-statut": "Statut",
            "header-site": "Site",
            "header-service-demandeur": "Service demandeur",
            "header-demandeur": "Demandeur",
            "header-date-creation": "Date création",
            "header-date-cloture": "Date clôture"
        },
        "en": {
            "overlay-loading-text": "Loading consumables request sheets...",
    		"overlay-no-rows-text": "No consumable request sheet matching filtering",
            "header-numero": "Number",
            "header-statut": "Status",
            "header-site": "Site",
            "header-service-demandeur": "Requesting service",
            "header-demandeur": "Requester",
            "header-date-creation": "Creation date",
            "header-date-cloture": "Closing date"
        }
    }
},

    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
					headerName: this.$t("header-numero"),
					field: 'numero',
					colId: 'numero'
				},
                {
					headerName: this.$t("header-statut"),
					field: 'statut',
					colId: 'statut',
                    cellRenderer: (params) => new FicheDemandeConsommablesStatutCellRender({propsData: {params: params}}).$mount().$el
				},
                {
					headerName: this.$t("header-site"),
					field: 'site',
					colId: 'site',
                    cellRenderer:(params)=>{
                        if(params.data.site && params.data.site.libel_lieu){
                            return params.data.site.libel_lieu;
                        }else{
                            return "-";
                        }
                    }
				},
                {
					headerName: this.$t("header-service-demandeur"),
					field: 'serviceDemandeur',
					colId: 'serviceDemandeur'
				},
                {
					headerName: this.$t("header-demandeur"),
					field: 'nomDemandeur',
					colId: 'nomDemandeur'
				},
                {
					headerName: this.$t("header-date-creation"),
					field: 'dateCreation',
					colId: 'dateCreation',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCreation}}).$mount().$el
				},
                {
					headerName: this.$t("header-date-cloture"),
					field: 'dateCloture',
					colId: 'dateCloture',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCloture}}).$mount().$el
				}
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            });
            this.columnDefs.unshift({
                headerName: "",
                field:"",
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new FicheDemandeConsommablesCellRender({propsData: {params: params}})
                    .$mount().$el
            });
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            fiches: 'FicheDemandeConsommablesStore/getCollection',
            counters: 'FicheDemandeConsommablesStore/getCounters'
        }),
        getTotalItems: function(){
            //if(this.filters.statut.value) return this.counters[this.filters.statut.value];
            return this.counters.filtered;
        }
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.FicheDemandeConsommablesMixins_getFiches(this.metadatas).then((datas)=>{
                console.log("GET FICHES", datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        goToZoom: function(data){
            this.$router.push({ name: '_fichedemandeconsommables_id', params: { id: data.id } });
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$store.dispatch("FicheDemandeConsommablesStore/setSelectedItem", row.data);
            this.$emit("row-click",row);
            if(this.$vgutils.isMobile()) this.goToZoom(row.data);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-fiche-demande-consommables-table{
    height: 100%;
}
.vg-fiche-demande-consommables-liste{
    height: 300px;
    overflow: auto;
    .empty-consommables-message{
        color: rgb(57, 153, 255);
    }
    .consommable{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom:1px solid #eee;
        cursor: pointer;
    }
    .consommable:hover{
        background-color: whitesmoke;
    }
}
</style>
