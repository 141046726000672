<template>
    <div v-if="hasReservesNonLevees">
        <b>{{params.data.nbReservesNonLevees}}</b>
        <vg-button
            :type="'info'"
            :size="'sm'"
            @click="onShowReserves">
            {{$t("voir-reserves")}}
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"nb-reserves-non-levees-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "voir-reserves": "Voir réserves"
        },
        "en": {
            "voir-reserves": "See reservations"
        }
    }
},
        components: {
            VgButton
        },
        data:function(){
            return {

            };
        },
        computed: {
            hasReservesNonLevees: function(){
                return this.params.data.nbReservesNonLevees && this.params.data.nbReservesNonLevees!=0 && this.params.data.nbReservesNonLevees!="0";
            }
        },
        methods: {
            onShowReserves: function(){
                this.$emit("show-reserves", this.params.data);
            },
        }
    });
</script>

<style lang="scss" scoped>
div>b{
    color:red;
    margin-right: 10px;
}
</style>
