<template lang="html">
    <div class="vgCategorieViewer">
        <div class="header">
            <span>
                {{$t("title")}} : <b style="color:#3999ff;">{{value.libelleCatgorie}}</b>
                <vg-button
                    :size="'sm'"
                    :type="$app.role=='ROLE_ADMIN'?'default-round':'locked'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="showEditCategorie=true">
                    <i class="fas fa-pen"></i>
                </vg-button>
            </span>
            <div>
                <vg-button v-if="!showEditComposants"
                    :size="'sm'"
                    :type="$app.role=='ROLE_ADMIN'?'default-info':'locked'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="handleEditComposants(true)">
                    {{$t("edit-composants")}}
                </vg-button>
                <vg-button v-else
                    :size="'sm'"
                    :type="$app.role=='ROLE_ADMIN'?'grey':'locked'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="handleEditComposants(false)">
                    {{$t("cancel")}}
                </vg-button>
            </div>
        </div>
        <div class="body">
            <vg-text-filter v-model="searchQuery"/>
            <span v-if="showEditComposants" style="display:flex;justify-content:center;width:100%;padding:4px 0px;background-color:whitesmoke;font-size:11px;">
                {{value.countComposants}} {{$t("composants-associes")}}
            </span>
            <div v-if="showEditComposants">
                <vg-button
                    :type="'success'"
                    :size="'sm'"
                    @click="showNewComposantModal=true"
                    style="width:100%;">
                    {{$t("nouveau-composant")}}
                </vg-button>
            </div>
            <vg-composants-table
                :searchQuery="searchQuery"
                :isTableau="false"
                :updatable="showEditComposants"
                :filters="composantsFilters"
                :idCategorie="value.id">
            </vg-composants-table>
            <vg-composant-form v-if="showNewComposantModal"
                :categorie="value"
                @save="showNewComposantModal=false;"
                @close="showNewComposantModal=false;">
            </vg-composant-form>
        </div>
        <vg-categorie-form v-if="showEditCategorie"
            v-model="value"
            isGe
            @close="showEditCategorie=false"
            @save="showEditCategorie=false">
        </vg-categorie-form>
    </div>
</template>

<script>
import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

import VgComposantsTable from 'src/components/Vg/Composant/VgComposantsTable.vue';
import VgCategorieComposantsUpdate from 'src/components/Vg/Categorie/VgCategorieComposantsUpdate.vue';

import VgCategorieForm from 'src/components/Vg/Forms/VgCategorieForm.vue';
import VgComposantForm from 'src/components/Vg/Forms/VgComposantForm.vue';

import CategorieMixins from "src/mixins/CategorieMixins.js";

import { mapGetters } from 'vuex';

export default {
    name:"vg-categorie-lieu-viewer",
    components:{
        VgInput,
        VgButton,
        VgTextFilter,
        VgComposantsTable,
        VgCategorieComposantsUpdate,
        VgCategorieForm,
        VgComposantForm
    },
    mixins: [
        CategorieMixins
    ],
    props:{
        value: {
            type: Object,
            required: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Composants associés à la catégorie",
            "edit-composants": "Modifier composants",
            "cancel": "Retour",
            "composants-associes": "composants associés",
            "nouveau-composant": "Nouveau composant"
        },
        "en": {
            "title": "Component associated to this category"
        },
        "th": {
            "title": "ชิ้นส่วนเกี่ยวข้องกับหมวดหมู่นี้"
        }
    }
},
    data: function(){
        return{
            searchQuery: "",
            showEditCategorie: false,
            showEditComposants: false,
            showNewComposantModal: false,
            composantsFilters: {
                categorie_id: {attr: "cat.id", value: this.value.id, action: "equals"},
                categorie_null: {attr: "cat.id", value: null, action: "not_equals"}
            }
        }
    },
    watch: {
        value: {
            handler: function(value, oldvalue){
                console.log("WATCH VgCategorieLieuViewer", value);
                if(oldvalue && value && oldvalue.id==value.id){

                }else{
                    this.composantsFilters = {
                        categorie_id: {attr: "cat.id", value: value.id, action: "equals"},
                        categorie_null: {attr: "cat.id", value: null, action: "not_equals"}
                    };
                    this.showEditComposants = false;
                }
            },
            deep: true
        }
    },
    mounted: function() {

    },
    computed:{
        ...mapGetters({
            counters: 'ComposantsStore/getCounters'
        })
    },
    methods:{
        handleEditComposants: function(isEdit){
            if(isEdit){
                this.composantsFilters = {
                    categorie_id: {attr: "cat.id", value: this.value.id, action: "equals", openParenthesis: true},
                    categorie_null: {attr: "cat.id", value: this.value.id, action: "not_equals", closeParenthesis: true, logicalOperator: "OR"},
                };
            }else{
                this.composantsFilters = {
                    categorie_id: {attr: "cat.id", value: this.value.id, action: "equals"},
                    categorie_null: {attr: "cat.id", value: null, action: "not_equals"}
                };
            }
            this.showEditComposants = isEdit;
        }
    }
}
</script>

<style lang="scss" scoped>
.vgCategorieViewer{
    height: 100%;
}
.header{
    background-color: #f5f7f7;
    width: 100%;
    padding: 0px 10px;
    height: 42px;
    border: 1px solid #bdc3c7;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.body{
    height: calc(100% - 42px);
}
</style>
