<template>
    <vg-bon-de-commande-statut-viewer v-model="value" />
</template>

<script>
    import Vue from "vue";
    import VgBonDeCommandeStatutViewer from "src/components/Vg/BonDeCommande/VgBonDeCommandeStatutViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"bon-de-commande-statut-cell-render",
        mixins:[LangMixins],
		props: {
            value: {
                type: Object
            }
		},
        data: function(){
            return {

            };
        },
        components: {
            VgBonDeCommandeStatutViewer
        }
    });
</script>

<style scoped>

</style>
