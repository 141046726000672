<template>
	<div class="reponse-commentaire">
		<span class="text-reponse" @click="onClickReponse">
			{{reponse.checkpoint.question}}
		</span>
		<div class="input-reponse">
			<vg-input
                inputType="textarea"
				v-model="reponse.reponse"
                :placeholder="reponse.reponse" />
		</div>
	</div>
</template>
<script>

import VgInput from 'src/components/Vg/VgInput.vue';

export default {
    name: 'reponse-commentaire',
    components:{
        VgInput
    },
    props:{
        tache: Object,
		reponse: Object,
		checkpoint: Object
    },
    data: function() {
        return {

        };
    },
    methods: {
        onChangeNumber: function(value){
			// //console.log("ON CHANGE NUMBER", value);
			this.$emit("change", value);
		},
        onClickReponse: function(){
			this.$emit('select', this.reponse);
		},
    }
};
</script>

<style lang="scss" scoped>
	.reponse-commentaire{
		margin-top: 5px;
        color: #74767a;
        font-size: 16px;
	}
</style>
