export default class StringUtilities {
    static isNullOrEmpty(value) {
        return value === null || value === '';
    }
    static getAcronym(str) {
        let words = str.replaceAll('-', ' ').split(' ');
        console.log(words);
        let acronym = '';
        let i = 0;
        for ( i ; i < words.length; ++i) {
            let word = words[i].trim();
            if (word) {
                acronym += word[0];
            }
        }
        return acronym.toUpperCase();
    }
}