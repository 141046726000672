<template>
	<vg-modal
		:title="$t('modal-title-create')"
		:isSaveDisabled="isSaveDisabled"
		:width="'50vw'"
		@close="$emit('close')"
		@save="saveBonDentree">
		<template #body>
			<vg-input :title="$t('fournisseur')" isRequiredValue>
				<vg-tiers-selector v-model="bon.fournisseur_id"/>
			</vg-input>
			<vg-input :title="$t('consommables')" v-if="!$vgutils.isMobile()">
				<div v-for="(consommation, index) in bon.consommations" class="consommable-row">
					<vg-consommable-datalist v-if="!consommation.consommable_id" :key="'consommable-datalist-'+index"
						:placeholder="$t('consommable')"
						:depotId="getDefaultDepotId"
						readOnly
						@select="onSelectConsommable($event, consommation, index)"/>
					<span v-else>{{consommation.consommable.name}} <small>{{$t("consommable-ref")}}: {{consommation.consommable.refExterne}}</small></span>
					<vg-input inputType="float" v-model="consommation.quantite" />
					<vg-input>
						<span v-if="consommation.consommable && consommation.consommable.unite">{{consommation.consommable.unite}}</span>
						<span v-else>{{$t("default-unite")}}</span>
					</vg-input>
					<span v-if="consommation.consommable">{{consommation.consommable.stock.quantite}} {{consommation.consommable.unite?consommation.consommable.unite:$t("default-unite")}}</span>
					<span v-else />
					<vg-button type="default-danger" @click="removeItem(consommation, index)">{{$t("retirer")}}</vg-button>
				</div>
				<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
					{{$t("add-consommable")}}
				</vg-button>
				<vg-button v-if="$vgutils.isMobile()" type="success" size="sm" @click="showScanner=true;">
					{{$t("scanner-consommable")}}
				</vg-button>
			</vg-input>
			<vg-input :title="$t('consommables')" v-else>
				<div v-for="(consommation, index) in bon.consommations" style="width: 100%;margin-bottom:10px;">
					<vg-consommable-datalist v-if="!consommation.consommable_id" :key="'consommable-datalist-'+index"
						style="width:100%;"
						:placeholder="$t('consommable')"
						:depotId="getDefaultDepotId"
						readOnly
						@select="onSelectConsommable($event, consommation, index)"/>
					<vg-button v-if="!consommation.consommable_id" 
						type="default-danger" size="sm" style="width: 100%;" 
						@click="removeItem(consommation, index)">
						{{$t("retirer")}}
					</vg-button>
					<div v-if="consommation.consommable_id" style="width: 100%;display: flex;justify-content: space-between;align-items: flex-start;gap:10px;">
						<div style="width:30%;display: flex;justify-content: flex-start;align-items: center;gap:5px;">
							<vg-input inputType="float" v-model="consommation.quantite" style="width:70%;"/>
							<vg-input style="width:30%;">
								<span v-if="consommation.consommable && consommation.consommable.unite">{{consommation.consommable.unite}}</span>
								<span v-else>{{$t("default-unite")}}</span>
							</vg-input>
						</div>
						<div style="width:70%;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
							<span v-if="consommation.consommable_id">{{consommation.consommable.name}} </span>
							<small>{{$t("consommable-ref")}}: {{consommation.consommable.refExterne}}</small>
							<small v-if="consommation.consommable">{{$t("quantite-en-stock")}}: {{consommation.consommable.stock.quantite}} {{consommation.consommable.unite?consommation.consommable.unite:$t("default-unite")}}</small>
							<vg-button type="default-danger" size="sm" style="width: 100%;" @click="removeItem(consommation, index)">{{$t("retirer")}}</vg-button>
						</div>
					</div>
				</div>
				<vg-button type="info" size="sm" @click="addItem" :disabled="!lastItemIsNotEmpty">
					{{$t("add-consommable")}}
				</vg-button>
				<vg-button v-if="$vgutils.isMobile()" type="success" size="sm" @click="showScanner=true;">
					{{$t("scanner-consommable")}}
				</vg-button>
			</vg-input>
			<vg-input :title="$t('commentaire')" v-model="bon.description" inputType="textarea"/>
			<vg-input :title="$t('signataire')" v-model="bon.signataire" isRequiredValue/>
			<vg-modal v-if="showScanner"
				:title="$t('scanner-consommable')"
				@close="showScanner=false;">
				<template #body>
					<zxing-scanner
						:isDemo="false"
						@scanSuccess="onScannSuccess" />
				</template>
				<template #footer>
					<vg-button @click="showScanner=false;">
						{{$t('close')}}
					</vg-button>
				</template>
			</vg-modal>
			<vg-modal v-if="showUploadDocument"
				:title="$t('upload-document-bon-entree')"
				@close="showUploadDocument=false;$emit('save');">
				<template #footer>
					<vg-button type="default-success" @click="showUploadDocument=false;$emit('save');">
						{{$t('non')}}
					</vg-button>
					<vg-files-uploader style="width:100px;"
						:inputLabel="$t('oui')"
						isOnlyPhoto
						:uidToUpload="createdBon.uid"
						defaultType="bon-dentree"
						:defaultTag="getDefaultDocumentTag"
						@upload-done="onUploadDocumentDone">
					</vg-files-uploader>
				</template>
			</vg-modal>
		</template>
	</vg-modal>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgModal from "src/components/Vg/VgModal.vue";
	import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
	import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
	import VgConsommableDatalist from "src/components/Vg/Datalists/VgConsommableDatalist.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
	import BonsDentreeMixins from "src/mixins/BonsDentreeMixins.js";
	import StocksMixins from "src/mixins/StocksMixins.js";
	import Metadatas from "src/services/Metadatas";
	import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'vg-bon-dentree-form',
		mixins: [ BonsDentreeMixins, StocksMixins ],
		components: {
			VgInput,
			VgButton,
			VgModal,
			VgTiersSelector,
			zxingScanner,
			VgConsommableDatalist,
			VgFilesUploader
		},
        props: {
            value: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
                bon: {
					numero: null,
					dateCreation: null,
					description: null,
					fournisseur_id: null,
					createur_id: this.$app.idUser,
					signataire: null,
					consommations: []
				},
				createdBon: null,
				showScanner: false,
				showUploadDocument: false
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-create": "Créer bon d'entrée",
            "modal-title-update": "Modifier bon d'entrée",
            "fournisseur": "Fournisseur",
            "signataire": "Signataire",
            "commentaire": "Commentaire",
            "consommables": "Consommables livrés",
            "scanner-consommable": "Scanner consommable",
            "add-consommable": "Ajouter consommable",
            "default-unite": "u.",
            "upload-document-bon-entree": "Voulez-vous enregistrer un document?",
            "oui": "Oui",
            "non": "Non",
			"consommable-ref": "Réf."
        },
        "en": {
            "modal-title-create": "Create entrance voucher",
            "modal-title-update": "Update entrance voucher",
            "fournisseur": "Supplier",
            "signataire": "Signatory",
            "commentaire": "Comment",
            "consommables": "Consumables delivered",
            "scanner-consommable": "Scann consumable",
            "add-consommable": "Add consumable",
            "default-unite": "EA",
            "upload-document-bon-entree": "Do you want to save a document?",
            "oui": "Yes",
            "non": "No",
			"consommable-ref": "Ref."
        }
    }
},
		watch: {
			value: function(value){
				if(this.value) this.bon = Object.assign({}, this.bon, this.value);
			}
		},
		created: function(){

		},
		mounted: function(){
			if(this.value) this.bon = Object.assign({}, this.bon, this.value);
			this.initBon();
		},
		methods: {
			initBon: function(){
				this.fetchDepots().then((datas)=>{
					this.bon.depotDestination_id = this.depotDefault.id;
				});
			},
			cleanEmptyConsommations: function(){
				this.bon.consommations = this.bon.consommations.filter((consommation)=>consommation.consommable_id!=null);
			},
			saveBonDentree: function(){
				this.cleanEmptyConsommations();
				if(this.bon.id){
					this.BonsDentreeMixins_update(this.bon).then((bon)=>{
						this.$emit("updated", bon);
					});
				}else{
					this.bon.dateCreation = moment().format("YYYY-MM-DD HH:mm");
					this.BonsDentreeMixins_create([this.bon]).then((bon)=>{
						this.createdBon = bon[0];
						this.showUploadDocument = true;
						//this.$emit("save", bon);
					});
				}
			},
			addItem: function(){
				this.bon.consommations.push({
					consommable_id: null,
					quantite: 1
				});
			},
			removeItem: function(consommation, index){
				this.bon.consommations.splice(index, 1);
			},
			fetchDepots: function(){
				return new Promise((resolve, reject)=>{
					let metadatasDepots = new Metadatas();
					this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
						resolve(datas);
					});
				});
			},
			onSelectConsommable: function(consommable, consommation, index){
				this.bon.consommations[index].consommable_id = consommable.id;
				this.bon.consommations[index].consommable = Object.assign({}, {}, consommable);
			},
			onUploadDocumentDone:function(){
	            this.showUploadDocument = false;
				this.$emit("save");
	        },
		},
        computed: {
			...mapGetters({
	            bonsDentree: 'BonsDentreeStore/getCollection',
				bonDentree: "BonsDentreeStore/getSelectedItem",
				depotDefault: "StocksStore/getDepotDefault",
				depotFlottant: "StocksStore/getDepotFlottant"
	        }),
			isUpdateForm: function(){
				return this.bon && this.bon.id;
			},
			isSaveDisabled: function(){
				if(!this.bon.signataire) return true;
				else if(!this.bon.fournisseur_id) return true;
				else if(!this.bon.consommations || this.bon.consommations.length==0) return true;
				else return false;
			},
			isGestionMagasin: function(){
	            let user = window.sessionStorage.getItem("user");
	            user = JSON.parse(user);
	            return user.isGestionMagasin;
	        },
			lastItemIsNotEmpty: function(){
				if(this.bon.consommations.length==0) return true;
				let lastIndex = this.bon.consommations.length-1;
				return this.bon.consommations[lastIndex].consommable_id && this.bon.consommations[lastIndex].quantite;
			},
			getDefaultDepotId: function(){
				return this.depotDefault.id;
			},
			getDefaultDocumentTag: function(){
				return {
					id: 4341
				};
			}
		}
    };

</script>
<style lang="scss" scoped>
.consommable-row{
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
.consommable-row-header{
	margin-bottom: 5px;
	font-size: 12px;
}
</style>
