<template>
    <vg-modal
        :title="$t('title',{categorie:value.libelleCatgorie})"
        :isSaveDisabled="isSaveButtonDisabled"
        @close="$emit('close')"
        @save="onSaveMarque">
        <template #body>
            <form id="marque-form"
                ref="marque-form"
                onSubmit="return false;">
                <vg-input :title="$t('marque')"
                    v-model="marque">
                </vg-input>
                <input
                  v-show="false"
                  ref="marque-submit"
                  type="submit"
                >
            </form>
        </template>
    </vg-modal>
</template>
<script>

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import CategorieMixins from "src/mixins/CategorieMixins.js";
export default {
    name: 'vg-categorie-form',
    components:{
        VgModal,
        VgButton,
        VgInput
    },
    mixins: [CategorieMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Création catégorie",
            "marque": "Marque",
            "update": "Modifier catégorie",
            "tags": "Etiquettes ( séparer les étiquettes par des ' / ' exemple 'mobilier/chaise')",
            "corps-detat": "Corps d'état par défaut affecté à la fiche curative",
            "prix-par-default": "Prix par défaut",
            "source-financement-default": "Source de financement",
            "ref-constructeur-default": "Référence constructeur défaut",
            "cancel": "Annuler",
            "delete": "Supprimer",
            "save": "Sauvegarder",
            "is-inventoriable": "Les équipements de cette catégorie apparaissent dans l'inventaire ? ",
            "suppression-categorie": "Voulez-vous supprimer cette catégorie ?",
            "suppression-categorie-warning": "Attention cela supprimera les équipements liés à cette catégorie",
            "type-categorie": "Type catégorie",
            "accounting-tag": "étiquette comptable",
            "title-categorie-lieu": "Création catégorie lieu",
            "title-categorie-generique": "Création catégorie générique",
            "icone": "Icone",
            "libelle": "Libellé",
            "use-categorie-proposee": "Catégories proposées",
            "installation-electrique": "Installation électrique",
            "installation-gaz": "Installation gaz",
            "reseau-eau-potable": "Réseau eau potable",
            "reseau-eau-usee": "Réseau eau usée",
            "reseau-eau-pluviale": "Réseau eau pluviale",
            "reseau-informatique": "Réseau informatique",
            "clos-couvert": "Clos couvert",
            "structure-beton": "Structure béton",
            "facades": "Façades",
            "parc-climatiseurs": "Parc climatiseurs",
            "parc-extincteurs": "Parc extincteurs",
            "ou-personnaliser-categorie": "ou personnaliser une catégorie",
            "personnaliser-categorie": "Personnaliser une catégorie",
            "indice-criticite": "Indice de criticité",
            "taux-depreciation-annuel": "Dépréciation annuelle (%)",
            "listing-libeller-categories": "Libellés problème",
            "revoir-categories-proposees": "Revoir les catégories proposées"
        },
        "en":{
            "title": "Create category",
            "marque": "Brand",
            "update": "Update category",
            "tags": "Tags (separate tags with ' / ' example 'furniture/chair')",
            "corps-detat": "Default body of state assigned to the curative sheet",
            "prix-par-default": "Default price",
            "source-financement-default": "Funding source",
            "ref-constructeur-default": "Default manufacturer reference",
            "cancel": "Cancel",
            "delete": "Delete",
            "save": "Save",
            "is-inventoriable": "Do the equipment in this category appear in the inventory?",
            "suppression-categorie": "Do you want to delete this category?",
            "suppression-categorie-warning": "Warning this will delete the equipment linked to this category",
            "type-categorie": "Category type",
            "accounting-tag": "accounting tag",
            "title-categorie-lieu": "Create place category",
            "title-categorie-generique": "Create generic category",
            "icone": "Icon",
            "libelle": "Label",
            "use-categorie-proposee": "Proposed categories",
            "installation-electrique": "Electrical installation",
            "installation-gaz": "Gas installation",
            "reseau-eau-potable": "Drinking water network",
            "reseau-eau-usee": "Waste water network",
            "reseau-eau-pluviale": "Rainwater network",
            "reseau-informatique": "Computer network",
            "clos-couvert": "Closed cover",
            "structure-beton": "Concrete structure",
            "facades": "Facades",
            "parc-climatiseurs": "Air conditioning park",
            "parc-extincteurs": "Fire extinguisher park",
            "ou-personnaliser-categorie": "or customize a category",
            "personnaliser-categorie": "Customize a category",
            "indice-criticite": "Criticality index",
            "taux-depreciation-annuel": "Annual depreciation rate (%)",
            "listing-libeller-categories": "Problem labels",
            "revoir-categories-proposees": "Review proposed categories"
        }
    }
},
    props: {
        value:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data: function() {
        return {
            marque: ""
        };
    },
    computed:{
        isSaveButtonDisabled: function(){
            if(this.marque && this.marque.length!=0){   // marque nourrit
                if(this.value.marque && typeof(this.value.marque)=="string"){   // categorie.marque nourrit
                    let marques = this.value.marque.split(";");
                    return marques.indexOf(this.marque)!=-1;    // marque existe dans categorie.marque
                }else return false;
            }else return true;  // marque !nourrit
        }
    },
    created: function() {

    },
    methods: {
        onSaveMarque: function(){
            let categorie = Object.assign({}, this.value, {
                marque: this.value.marque && this.value.marque.length!=0?this.value.marque+";"+this.marque:this.marque
            });
            this.CategorieMixins_update(categorie).then((datas)=>{
                this.$emit("save", this.marque);
            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-categorie-marque-form
    formulaire ajout catégorie.marque

    #### basic usage
    ```html static
        <vg-categorie-marque-form
            @close="...">
        </vg-categorie-marque-form>
    ```
</docs>
