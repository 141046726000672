<template>
	<vg-select v-model="selectedContratType"
		:options="types"
		:clearable="isClearable"
		@input="$emit('input',selectedContratType)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-contrat-type-selector',
		components: {
			VgSelect
		},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: String,
				default: null
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				selectedContratType: this.value,
				types: [
					this.$t("verification-periodique"), this.$t("controle-reglementaire"),
					this.$t("entretien-periodique"), this.$t("maintenance-curative"),
					this.$t("autre")
				]
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "verification-periodique": "Vérification périodique",
            "controle-reglementaire": "Contrôle réglementaire",
            "entretien-periodique": "Entretien périodique",
            "maintenance-curative": "Maintenance curative",
            "autre": "Autre"
        },
        "en": {
            "verification-periodique": "Periodic verification",
            "controle-reglementaire": "Regulatory control",
            "entretien-periodique": "Periodic maintenance",
            "maintenance-curative": "Curative maintenance",
            "autre": "Other"
        },
        "th": {
            "verification-periodique": "การตรวจสอบตามระยะเวลา",
            "controle-reglementaire": "การควบคุมตามกฎระเบียบ",
            "entretien-periodique": "การบำรุงรักษาตามระยะเวลา",
            "maintenance-curative": "การบำรุงรักษาซ่อมแซม",
            "autre": "อื่น ๆ"
        }
    }
},
		watch: {
			value: {
				handler: function(type){
					this.selectedContratType = type;
				}
			}
		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>

</style>
