<template>
	<iframe
	  id="bon-de-sortie-pdf-iframe"
	  style="
		width: 100%;
		height: 100%;
		border: none;
	  "
	></iframe>
</template>
<script>
	import Pdf from "src/services/Pdf.js";

	import { mapGetters } from 'vuex';

	const STYLES = {
		title: {
			fontWeight: 600,
			fontSize: 12,
			color: "#000"
		},
		bc: {
			attribute:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value: {
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		},
		fournisseur: {
			name: {
				fontWeight: 600,
		        fontSize: 14,
		        color: "#333"
			},
			address: {
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		},
		items: {
			header: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#959595"
			},
			cell: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#3c4042"
			}
		},
		montants: {
			label:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		}
    };

    export default {
        name: 'vg-bon-de-sortie-pdf',
    	props: {
			/**
			* bon de sortie
			*/
            value: {
				type: Object,
				required: true
			},
			signatureImage:{
				type: String,
				default: null
			},
			demande:{
				type: Object,
				default: function(){
					return null;
				}
			},
			site:{
				type: Object,
				default: function(){
					return null;
				}
			},
			serviceDemandeur:{
				type: String,
				default: null
			},
			nomDemandeur:{
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "BON DE SORTIE",
            "signataire": "Transmis par :",
            "footer-capital-siret": "Numéro immatriculation",
            "bc-numero": "Numéro",
            "bc-createur": "Qui transmet",
            "bc-demandeur": "Demandeur",
            "bc-date-creation": "Date création",
            "bc-commentaire": "Commentaire",
            "bc-fiche-demande-consommable": "Fiche demande consommable",
            "bs-header-name": "Désignation",
            "bs-header-ref": "Référence",
            "bs-header-consommable-quantite": "Quantité",
            "bs-header-consommable-unite": "Unité",
			"bs-site": "Site",
			"bs-service": "Service",
			"bc-compte-vg": "Créateur"
        }
    }
},
        data: function() {
            return {
				pdf: null,
				positionTop: null,
				positionLeft: null
            };
        },
		created: function(){

		},
		mounted: function(){
			this.initPdf();
			this.drawPdf();
		},
		methods: {
			initPdf: function(){
				this.pdf = new Pdf(
					this.getLogo,
					this.entiteQuiPasseCommande.name,
					this.entiteQuiPasseCommande.address,
					this.$t("footer-capital-siret")+" "+this.entiteQuiPasseCommande.immatriculation,
					null,
					{
					  orientation: "p",
					  format: "a4"
					}
				);
			},
			drawPdf: function(){
				console.log("BS", this.bonDeSortie);
				this.drawMainHeader();

				// SECTION BS
				this.drawSectionDetails();

				// HEADER ITEMS
				this.drawTableHeaders();

				// ITEMS
				this.drawTableItems();

				// SIGNATURE
				//this.drawSignature();
				document.getElementById("bon-de-sortie-pdf-iframe").setAttribute("src", this.pdf.pdf.output("bloburl"));

				this.emitGeneratedPdfDatas();
			},
			emitGeneratedPdfDatas: function(){
				let pdfDatas = this.pdf.pdf.output("blob", {
					filename: this.$app.organisation+"_bon_de_sortie"+moment().format("YYYY-MM-DD")+".pdf"
				});
				this.$emit("generated-pdf-datas", pdfDatas);
			},
			drawMainHeader: function(){
				// HEADER
				this.pdf.setCurrentXPosition(85);
				this.pdf.setCurrentYPosition(30);
				this.pdf.addText(this.$t("title"), STYLES.title);
			},
			drawSignature: function(){
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.positionTop = this.pdf.getCurrentYPosition();
				this.positionLeft = this.pdf.getCurrentYPosition();
				if(this.signatureImage) {
					this.pdf.addText(this.$t("signataire")+": "+this.bonDeSortie.signataireDonneur, STYLES.title);
					this.pdf.addImage(this.signatureImage, "PNG", 10, this.positionTop)
				};
			},
			drawSectionDetails: function(){
				this.pdf.addNewTextLine();
				this.pdf.addCurrentYPosition(10);
				this.positionTop = this.pdf.getCurrentYPosition();
				console.log("DEMANDE", this.demande, this.bonDeSortie);
				let largeurLibelleSectionDetails = 70;
				this.pdf.addList([
					this.$t("bc-numero"),
					this.$t("bc-createur"),
					this.$t("bc-demandeur"),
					this.$t("bs-site"),
					this.$t("bs-service"),
					this.$t("bc-date-creation"),
					this.$t("bc-fiche-demande-consommable"),
					this.$t("bc-compte-vg")
				], STYLES.bc.attribute);
				this.pdf.setCurrentXPosition(largeurLibelleSectionDetails);
				this.pdf.setCurrentYPosition(this.positionTop);
				let donneur = this.bonDeSortie.signataireDonneur ? this.bonDeSortie.signataireDonneur : this.$app.nom+" "+this.$app.prenom;
				this.pdf.addList([
					this.bonDeSortie.numero,
					donneur,
					this.getDemandeur,
					this.getSite,
					this.getServiceDemandeur,
					moment(this.bonDeSortie.dateCreation).format("DD/MM/YYYY HH:mm"),
					this.bonDeSortie.ficheDemandeConsommable_id?this.ficheDemandeConsommable.numero:"-",
					this.$app.email
				], STYLES.bc.value);
			},
			drawTableHeaders: function(){
				this.pdf.addCurrentYPosition(20);
				this.positionLeft = 10;

				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addText(this.$t("bs-header-name"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+80);
				this.pdf.addText(this.$t("bs-header-ref"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+140);
				this.pdf.addText(this.$t("bs-header-consommable-quantite"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+165);
				this.pdf.addText(this.$t("bs-header-consommable-unite"), STYLES.items.header);

				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addCurrentYPosition(3);
				this.pdf.addLine();
			},
			drawTableItems: function(){
				this.positionLeft = this.pdf.getCurrentXPosition();
				let nextPositionTop = this.pdf.getCurrentYPosition()+3;
				let hasMultipleLine = false;
				let quantite = null;
				this.bonDeSortie.consommations.forEach((consommation)=>{
					this.pdf.setCurrentYPosition(nextPositionTop);
					this.pdf.addCurrentYPosition(this.pdf.getSpaceBetweenLines()/2);
					this.positionTop = this.pdf.getCurrentYPosition();
					// 10 +80 +140 +165
					// designation
					this.pdf.setCurrentXPosition(this.positionLeft);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(consommation.consommable.name, STYLES.items.cell, 80);
					if(!hasMultipleLine || (this.pdf.getCurrentYPosition()!=this.positionTop && nextPositionTop<this.pdf.getCurrentYPosition())){
						hasMultipleLine = this.pdf.getCurrentYPosition()!=this.positionTop;
						if(hasMultipleLine) nextPositionTop = this.pdf.getCurrentYPosition();
						else nextPositionTop = this.positionTop;
					}
					// ref
					this.pdf.setCurrentXPosition(this.positionLeft+80);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(consommation.consommable.refExterne?consommation.consommable.refExterne:"-", STYLES.items.cell, 60);
					hasMultipleLine = this.pdf.getCurrentYPosition()!=this.positionTop;
					if(hasMultipleLine) nextPositionTop = this.pdf.getCurrentYPosition();
					else nextPositionTop = this.positionTop;
					// Qté
					this.pdf.setCurrentXPosition(this.positionLeft+140);
					this.pdf.setCurrentYPosition(this.positionTop);
					quantite = consommation.quantite;
					this.pdf.addText(quantite.toString(), STYLES.items.cell);
					// unite
					this.pdf.setCurrentXPosition(this.positionLeft+165);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(consommation.consommable.unite, STYLES.items.cell);

				});
			}
		},
        computed: {
			...mapGetters({
				ficheDemandeConsommable: 'FicheDemandeConsommablesStore/getSelectedItem'
            }),
			bonDeSortie: function(){
				return this.value;
			},
			entiteQuiPasseCommande: function(){
				return {
					name: this.$app.organisation,
					address: this.$app.account_address,
					immatriculation: this.$app.account_immatriculation
				};
			},
			getLogo: function(){
				return window.sessionStorage.getItem("account_logo");
			},
			getDemandeur: function(){
				if(this.nomDemandeur) return this.nomDemandeur;
				else if(this.bonDeSortie && this.bonDeSortie.ficheDemandeConsommable_id && this.bonDeSortie.ficheDemandeConsommable && this.bonDeSortie.ficheDemandeConsommable.nomDemandeur) return this.ficheDemandeConsommable.nomDemandeur;
				else if(this.demande && this.demande.nomDemandeur) return this.demande.nomDemandeur;
				else return "-";
			},
			getSite: function(){
				if(this.site) return this.site.libel_lieu;
				else if(this.demande && this.demande.site) return this.demande.site.libel_lieu;
				else return "-";
			},
			getServiceDemandeur: function(){
				if(this.serviceDemandeur) return this.serviceDemandeur;
				else if(this.demande && this.demande.serviceDemandeur) return this.demande.serviceDemandeur;
				else return "-";
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>