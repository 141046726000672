<template>
    <div class="vg-composants-table">
        <tag-grid v-if="isTableau"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="composants"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick"
        />
        <div v-else class="vg-composants-liste">
            <composant-cell-render v-for="composant in getComposants" :key="composant.id"
                :params="{data:composant}"
                :updatable="updatable"
                :idCategorie="idCategorie"
                @checked="onCheckComposant"
                @unchecked="onUncheckComposant"
                @show-update="onShowUpdate"/>
            <span v-if="!composants || composants.length==0" class="empty-composants-message">{{$t("aucun-composants-associes")}}</span>
        </div>
        <vg-composant-form v-if="showUpdateComposant"
            v-model="focusedComposant"
            @close="showUpdateComposant=false;"
            @save="showUpdateComposant=false;"/>
    </div>
</template>
<script>
import CategorieMixins from "src/mixins/CategorieMixins.js";
import ComposantMixins from "src/mixins/ComposantMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import ComposantCellRender from "src/components/Vg/TagGrid/ComposantCellRender.vue";
import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";

import VgComposantForm from "src/components/Vg/Forms/VgComposantForm.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-composants-table',
    components:{
        TagGrid,
        ComposantCellRender,
        IconNameCellRender,
        VgComposantForm
    },
    mixins:[ CategorieMixins, ComposantMixins ],
    props: {
        id:{
            type: String,
            default: "vg-composants-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * applique le rendu du tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        },
        updatable: {
            type: Boolean,
            default: false
        },
        idCategorie: {
            type: Number,
            default: null
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
        "fr": {
            "overlay-loading-text": "Chargement des composants...",
            "overlay-no-rows-text": "Aucun composant correspondant au filtrage",
            "header-id": "Id",
            "header-libel-composant": "Libellé",
            "header-type-composant": "Type",
            "aucun-composants-associes": "Aucun composant associé à cette catégorie."
        },
        "en": {
            "overlay-loading-text": "Loading components...",
            "overlay-no-rows-text": "No component matching filtering",
            "header-id": "Id",
            "header-libel-composant": "Label",
            "header-type-composant": "Type",
            "aucun-composants-associes": "No component matching this category."
        }
    },
    },
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: this.$t("header-id"),
                    width: 90,
                    field: 'id',
                    colId: 'id',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    hide: true
                },
                {
                    headerName: this.$t("header-libel-composant"),
                    field: "libelComposant",
                    colId: "libelComposant",
                    cellRenderer: (params) => new IconNameCellRender({ propsData: { dataColumn: params.data.libelComposant, isMainColumn: true, icon: params.data.icon } }).$mount().$el
                },
                {
                    headerName: this.$t("header-type-composant"),
                    field: "typeComposant",
                    colId: "typeComposant"
                }
            ],
            metadatas: new Metadatas(),
            focusedComposant: null,
            showUpdateComposant: false
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                if(this.isTableau) this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("NEW FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){

            },
            deep: true
        }
    },
    mounted:function(){
        this.fetch();
    },
    computed: {
        ...mapGetters({
            composants: 'ComposantsStore/getCollection',
            counters: 'ComposantsStore/getCounters'
        }),
        getComposants: function(){
            if(this.searchQuery) return this.composants.filter((composant)=>composant.libelComposant.toLowerCase().search(this.searchQuery.toLowerCase())!=-1 || composant.typeComposant.toLowerCase().search(this.searchQuery.toLowerCase())!=-1);
            else return this.composants;
        }
    },
    methods:{
        onShowUpdate: function(data){
            this.focusedComposant = data.composant;
            this.showUpdateComposant = true;
        },
        onCheckComposant: function(data){
            console.log("CHECK", data);
            this.CategorieMixins_associateComposant(this.idCategorie, data.composant).then((data)=>{
                console.log("SUCCESS ASSOCIATE COMPOSANT", data);
            });
        },
        onUncheckComposant: function(data){
            console.log("UNCHECK", data);
            let composant_categorie = data.composant.composant_categories.find((cc)=>cc.categorie.id==this.idCategorie);
            this.CategorieMixins_desassociateComposant(composant_categorie).then((data)=>{
                console.log("SUCCESS DESASSOCIATE COMPOSANT", data);
            });
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            if(this.idCategorie && !this.filters.categorie_id) this.filters.categorie_id = {attr: "cat.id", value: this.idCategorie, action: "equals"};
            this.metadatas.setFilters(this.filters);
            this.ComposantMixins_get(this.metadatas).then((datas)=>{
                console.log("COMPOSANTS GET", datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-composants-table{
    height: 100%;
}
.vg-composants-liste{
    height: 100%;
    overflow: auto;
    .empty-composants-message{
        color: rgb(57, 153, 255);
    }
}
</style>
