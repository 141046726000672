<template>
    <vg-modal
        :width="'90vw'"
        @close="$emit('close')">
        <template #header>
            <span v-if="!isAnUpdateForm">{{$t("title-create")}}</span>
            <span v-else>{{$t("title-update")}}</span>
            <span style="font-weight:bold;">&nbsp;<span style="font-size:13px;">{{$t("bc-numero")}}:</span> {{bonDeCommande.numero}}</span>
        </template>
        <template #body>
            <form id="bc-form"
                ref="bc-form"
                onSubmit="return false;">
                
                <div style="display:grid;grid-template-columns: 1fr 1fr; gap: 60px;">
                    <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items:flex-start;gap:10px;">
                        <vg-input v-model="getDateCreation"
                            :title="$t('bc-date-creation')"
                            inputType="date"
                            isRequiredValue
                            disabled />
                        <vg-input :title="$t('bc-entite')">
                            <vg-bons-de-commande-entites-selector v-model="bonDeCommande.bonsDeCommandeEntite_id" 
                                @selected-entite="onSelectedEntite"/>
                        </vg-input>
                        <vg-input :title="$t('bc-service-demandeur')"
                            isRequiredValue>
                            <vg-service-selector v-model="bonDeCommande.serviceDemandeur" showAll
                                :clearable="false"/>
                        </vg-input>
                        <vg-input :title="$t('bc-site-demandeur')"
                            isRequiredValue>
                            <template #actions>
                                <vg-button v-if="!disableSiteSelector"
                                    :type="'success-link'"
                                    @click="onClickSaisirUnSite(true);">
                                    {{ $t("saisir-site") }}
                                </vg-button>
                                <vg-button v-else
                                    :type="'success-link'"
                                    @click="onClickSaisirUnSite(false);">
                                    {{ $t("cancel") }}
                                </vg-button>
                            </template>
                            <vg-input v-if="!disableSiteSelector">
                                <vg-lieux-selector
                                    v-model="bonDeCommande.siteDemandeur_id"
                                    :filters="agfiltersSitesSelector" />
                            </vg-input>
                            <vg-input v-else
                                v-model="bonDeCommande.demandeur"
                                :placeholder="$t('input-placeholder-demandeur')" />
                        </vg-input>
                    </div>
                    <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items:flex-start;gap:10px;">
                        <vg-input :title="$t('bc-tiers')"
                            isRequiredValue>
                            <template #actions>
                                <vg-button
                                    :type="'success-link'"
                                    @click="showCreateTiers=true;">
                                    {{ $t("btn-new-tiers") }}
                                </vg-button>
                            </template>
                            <vg-tiers-selector v-model="bonDeCommande.fournisseur_id"
                                :clearable="false"
                                @change="onChangeTiers"/>
                            <vg-tiers-form v-if="showCreateTiers"
                                @close="showCreateTiers=false;"
                                @created="afterCreateTiers"/>
                        </vg-input>
                        <vg-input :title="$t('bc-tiers-address')"
                            v-model="getTiersAddress"
                            inputType="textarea"
                            rows="2"
                            isRequiredValue
                            disabled />
                        <vg-input
                            :title="$t('bc-delivery-address')"
                            v-model="getDeliveryAddress"
                            inputType="textarea"
                            rows="2"
                            isRequiredValue
                            disabled />
                    </div>
                </div>
                <br>
                <vg-bon-de-commande-items-form v-if="!isAnUpdateForm" v-model="bonDeCommande.items"
                    :fournisseurId="bonDeCommande.fournisseur_id"
                    @change="onChangeBonDeCommandeItems"/>
                <br>
                <hr>
                <div v-if="!isAnUpdateForm" class="bc-total">
                    <div class="bc-total-item">
                        <span class="bc-total-item-title">{{$t("bc-total-ht")}}</span>
                        <vg-input v-model="bonDeCommande.montantHT"
                            :inputType="'financial'"
                            disabled/>
                    </div>
                    <div class="bc-total-item">
                        <span class="bc-total-item-title">{{$t("bc-total-tva")}}</span>
                        <vg-input v-model="bonDeCommande.montantTVA"
                            :inputType="'financial'"
                            disabled/>
                    </div>
                    <div class="bc-total-item">
                        <span class="bc-total-item-title">{{$t("bc-total-ttc")}}</span>
                        <vg-input v-model="bonDeCommande.montantTTC"
                            :inputType="'financial'"
                            disabled/>
                    </div>
                </div>
                <vg-input  v-model="bonDeCommande.commentaire"
                    :title="$t('bc-commentaire')"
                    inputType="textarea"/>
                <input
                  v-show="false"
                  ref="bc-submit"
                  type="submit"
                >
            </form>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button :type="isAnUpdateForm?'danger':'success'" :disabled="isSaveDisabled"
                @click="onSaveBonDeCommande">
                {{isAnUpdateForm?$t("btn-modifier"):$t("btn-creer")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector.vue";
import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";

import VgBonsDeCommandeEntitesSelector from "src/components/Vg/Selectors/VgBonsDeCommandeEntitesSelector.vue";
import VgBonDeCommandeItemsForm from "src/components/Vg/Forms/VgBonDeCommandeItemsForm.vue";
import VgTiersForm from "src/components/Vg/Forms/VgTiersForm.vue";

import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'vg-bon-de-commande-form',
    components:{
        VgModal,
        VgButton,
        VgInput,
        VgSelect,
        VgLieuxSelector,
        VgTiersSelector,
        VgServiceSelector,
        VgBonDeCommandeItemsForm,
        VgTiersForm,
        VgBonsDeCommandeEntitesSelector
    },
    mixins: [BonsDeCommandeMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-create": "Créer bon de commande",
            "title-update": "Modifier bon de commande",
            "section-demandeur": "DEMANDEUR",
            "section-fournisseur": "FOURNISSEUR",
            "bc-entite": "Entité qui passe commande (par défaut le compte)",
            "bc-numero": "N°",
            "bc-service-demandeur": "Service",
            "bc-date-creation": "Date création",
            "bc-site-demandeur": "Site",
            "bc-tiers": "Tiers",
            "bc-tiers-address": "Adresse tiers",
            "bc-delivery-address": "Adresse de livraison",
            "bc-commentaire": "Commentaire",
            "bc-total-ht": "Total HT",
            "bc-total-tva": "Total TVA",
            "bc-total-ttc": "Total TTC",
            "btn-cancel": "Annuler",
            "btn-creer": "Créer",
            "btn-modifier": "Modifier",
            "btn-saisir-un-site": "Saisir un autre site",
            "btn-annuler-saisir-un-site": "Afficher la liste des sites",
            "input-placeholder-demandeur": "Saisir un site",
            "saisir-site": "Saisir site",
            "cancel": "Annuler",
            "btn-new-tiers": "Ajouter tiers"
        },
        "en": {
            "title-create": "Create purchase order",
            "title-update": "Update purchase order",
            "section-demandeur": "REQUESTER",
            "section-fournisseur": "SUPPLIER",
            "bc-entite": "Entity that places the order (by default the account)",
            "bc-numero": "N°",
            "bc-service-demandeur": "Service",
            "bc-date-creation": "Creation date",
            "bc-site-demandeur": "Site",
            "bc-tiers": "Tiers",
            "bc-tiers-address": "Tiers address",
            "bc-delivery-address": "Delivery address",
            "bc-commentaire": "Comment",
            "bc-total-ht": "Total HT",
            "bc-total-tva": "Total TVA",
            "bc-total-ttc": "Total TTC",
            "btn-cancel": "Cancel",
            "btn-creer": "Create",
            "btn-modifier": "Update",
            "btn-saisir-un-site": "Enter another site",
            "btn-annuler-saisir-un-site": "Show the list of sites",
            "input-placeholder-demandeur": "Enter a site",
            "saisir-site": "Enter site",
            "cancel": "Cancel",
            "btn-new-tiers": "Add tiers"
        }
    }
},
    props: {
        value:{
            type: Object,
            default: function(){
                return null;
            }
        },
        defaultValues:{
            type: Object,
            default: function(){
                return null;
            }
        }
    },
    data: function() {
        return {
            bonDeCommande: {
                numero: null,
                serviceDemandeur: null,
                demandeur: null,
                commentaire: null,
                statut: "draft",
                statutLivraison: null,
                statutPaiement: null,
                montantHT: null,
                montantTVA: null,
                montantTTC: null,
                dateCreation: moment().format("YYYY-MM-DD HH:mm:ss"),
                fournisseur_id: null,
                createur_id: this.$app.idUser,
                siteDemandeur_id: null,
                bonsDeCommandeEntite_id: null,
                userId: this.$app.appID,
                items: []
            },
            entite: null,
            tiers: null,
            agfiltersSitesSelector: {
                type_lieu: {attr:"type_lieu",value:"Site",action:"equals"}
            },
            disableSiteSelector: false,
            showCreateTiers: false,
            isLoading: false
        };
    },
    computed:{
        ...mapGetters({
            bonsDeCommande: 'BonsDeCommandeStore/getCollection',
            counters: "BonsDeCommandeStore/getCounters",
            bonDeCommandeItems: 'BonDeCommandeItemsStore/getCollection'
        }),
        isAnUpdateForm: function(){
            return this.value && this.value.id;
        },
        getDateCreation: function(){
            return moment(this.bonDeCommande.dateCreation).format("YYYY-MM-DD");
        },
        isBonDeCommandeItemsIsValid: function(){
            if(this.bonDeCommande.items.length==0) return false;
            else if(this.bonDeCommande.items.length>0) return this.bonDeCommande.items.filter((item)=>item.description && item.description.length>2 && item.quantite).length==this.bonDeCommande.items.length;
            else return false;
        },
        isSaveDisabled: function(){
            if(this.isLoading) return true;
            else return !this.bonDeCommande.dateCreation || !this.bonDeCommande.fournisseur_id
                || !this.isBonDeCommandeItemsIsValid
                || (!this.bonDeCommande.siteDemandeur_id && !this.bonDeCommande.demandeur);
        },
        getTiersAddress: function(){
            if(this.tiers) return this.tiers.address;
            else return null;
        },
        getDeliveryAddress: function(){
            if(this.entite && this.entite.addressLivraison) return this.entite.addressLivraison;
            else return this.$app.account_address;
        }
    },
    created: function() {
        if(this.defaultValues) this.bonDeCommande = Object.assign({}, {}, this.defaultValues);
        if(this.value) this.bonDeCommande = Object.assign({}, this.bonDeCommande, this.value);
        if(this.value && this.value.id) this.bonDeCommande.items = this.bonDeCommandeItems;
        if(this.$app.patternBonDeCommandeNumero && !this.isAnUpdateForm) this.generateNumero();
    },
    methods: {
        afterCreateTiers: function(datas){
            this.tiers = datas[0];
            this.bonDeCommande.fournisseur_id = datas[0].id;
            this.showCreateTiers = false;
        },
        onSelectedEntite: function(entite){
            this.entite = entite;
        },
        onChangeTiers: function(tiers){
            this.tiers = tiers;
        },
        onClickSaisirUnSite: function(disableSiteSelector){
            if(disableSiteSelector) this.bonDeCommande.siteDemandeur_id = null;
            else this.bonDeCommande.demandeur = null;
            this.disableSiteSelector = disableSiteSelector;
        },
        onChangeBonDeCommandeItems: function(items){
            console.log("ITEMS", items);
            let montantHT = 0;
            let montantTVA = 0;
            let montantTTC = 0;
            items.forEach((item)=>{
                montantHT = montantHT + parseFloat(item.totalHT);
                montantTVA = montantTVA + parseFloat(item.totalHT)*parseFloat(item.tva.value)/100;
            });
            montantTTC = montantHT + montantTVA;
            this.bonDeCommande = Object.assign(this.bonDeCommande, {
                montantHT: montantHT.toFixed(2),
                montantTVA: montantTVA.toFixed(2),
                montantTTC: montantTTC.toFixed(2)
            });
        },
        /**
        * Generate numéro du BC à partir du numero le plus grand BC-YYYYn
        */
        generateNumero: function(){
            let lastNumero = this.counters?this.counters.lastNumero:null;
            let prefixNumeroBC = this.$app.patternBonDeCommandeNumero;
            if(lastNumero && lastNumero.includes(prefixNumeroBC)){
                lastNumero = lastNumero.replace(prefixNumeroBC, "");
                let numeroBC = parseInt(lastNumero)+1;
                this.bonDeCommande.numero = prefixNumeroBC+""+numeroBC;
            }else{
                this.bonDeCommande.numero = prefixNumeroBC+"1";
            }
        },
        onSaveBonDeCommande: function(){
            this.isLoading = true;
            this.onChangeBonDeCommandeItems(this.bonDeCommande.items);
            if(!this.isAnUpdateForm){
                this.BonsDeCommandeMixins_create([this.bonDeCommande]).then((datas)=>{
                    console.log("AFTER SAVE BC", datas);
                    this.$emit("save", datas[0]);
                    this.isLoading = false;
                });
            }else{
                this.BonsDeCommandeMixins_update(this.bonDeCommande).then((data)=>{
                    console.log("AFTER UPDATE BC", data);
                    this.$emit("save", data);
                    this.isLoading = false;
                });
            }
        },
    }
};
</script>
<style lang="scss" scoped>

.bc-total{
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    margin-right: 3%;
    .bc-total-item{
        display:flex;
        justify-content:space-between;
        align-items: baseline;
        .bc-total-item-title{
            width: 100px;
            color: #9e9da9;
            font-family: "Open Sans";
            font-size: 0.85em;
        }
    }
}
</style>
<docs>

    ### vg-categorie-form
    formulaire création catégorie

    #### basic usage
    ```html static
        <vg-categorie-form
            @close="...">
        </vg-categorie-form>
    ```
</docs>
