<template>
	<div :class="['vg-tree-node-lieu',{'selected':isSelected, 'tree-node-generique':isGenerique}]"
		@click="onClick"
		@contextmenu.prevent="onContextMenu">
		<div class="main">
			<img :src="getIcon" class="level-icon"/>
			<div  v-if="node.famille" class="rond-famille"><div :style="'background-color:'+familleBackgroundColor"></div></div>
			<span style="white-space: normal;">{{ getLibelLieu }}</span>
			<span v-if="node.nbEquipement" class="tooltip-nb-equipements" :title="node.nbEquipement+' '+$t('equipements')">
				{{node.nbEquipement}}
			</span>
		</div>
		<div class="complement">
			<vg-lieu-famille-selector v-if="node.type_lieu=='Organisation' && !isFamillesSelectorEmpty"
				class="famille-selector"
				onlyExisting
				@input="onSelectFamille"
				@familles-fetched="afterFamillesFetched"/>
			<span v-if="node.libelleCatgorie" class="tag categorie" >
				<img src="/static/assets/icone/lieux/categorie-lieu.png"/> <small>{{node.libelleCatgorie}}</small>
			</span>
			<span v-if="node.service" class="tag service">
				<img src="/static/assets/icone/lieux/service.png"/> <small>{{node.service}}</small>
			</span>
			<span v-if="node.famille" class="tag service only-desktop">
				<img src="/static/assets/icone/tag.png"/> <small>{{node.famille}}</small>
			</span>
			<!-- plans uniquement sur les étages   -->
			<vg-button v-if="node.type_lieu=='Etage' && node.nbPlans && node.nbPlans!=0 && !showPlanInteractifDatas"
				:type="'grey'"
				:size="'xs'"
				@click="onEmitShowPlans">
				<i class="far fa-map"></i> {{$t("plans")}}
			</vg-button>
			<span v-if="node.type_lieu=='Etage' && showPlanInteractifDatas && node.planInteractif" class="badge-pi">{{$t("badge-plan-interactif")}}</span>
		</div>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";
	import LieuMixins from "src/mixins/LieuMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-tree-node-lieu',
		components: {
			VgButton,
			VgLieuFamilleSelector
		},
		mixins: [ LieuMixins ],
        props: {
			value:{
				type: Object
			},
			showPlanInteractifDatas:{
				type: Boolean,
				default: false
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "plans": "Plans",
            "equipements": "équipement(s)",
            "piece-generique": "Pièce générique",
            "etage-generique": "Etage générique",
            "batiment-generique": "Batiment générique",
            "badge-plan-interactif": "Plan interactif"
        },
        "en": {
            "plans": "Floor plans",
            "equipements": "equipment(s)",
            "piece-generique": "Generic room",
            "etage-generique": "Generic floor",
            "batiment-generique": "Generic building",
            "badge-plan-interactif": "Interactive floor level plan"
        },
        "th": {
            "plans": "แผนงานพื้น",
            "equipements": "อุปกรณ์"
        }
    }
},
		watch:{
			value: {
				handler: function(newnode){
					this.node = newnode;
				},
				deep: true
			},
			familles:{
				handler: function(newvalues){
					this.setFamilleBackgroundColor();
				}
			}
		},
        data: function() {
            return {
				node: this.value,
				isSelected: false,
				isFamillesSelectorEmpty: false,
				familleBackgroundColor: null
            };
        },
		created: function(){

		},
		mounted: function(){
			this.setFamilleBackgroundColor();
		},
		methods: {
			setFamilleBackgroundColor: function(){
				if(this.familles && this.familles.length!=0 && this.node.famille) this.familleBackgroundColor = this.familles.find((famille)=>famille.label==this.node.famille).color;
			},
			onEmitShowPlans: function(){
				this.$emit('show-plans', this.node);
			},
			onClick: function(){
				this.isSelected = !this.isSelected;
				this.$emit("select", this.node);
			},
			onContextMenu: function(e){
				this.$emit("context-menu", {event: e, node: this.node, isGenerique: this.isGenerique});
			},
			onSelectFamille: function(famille){
				this.$emit("select-famille", {famille: famille, node:this.node});
			},
			afterFamillesFetched: function(familles){
				this.isFamillesSelectorEmpty = !familles || familles.length==0;
			}
		},
        computed: {
			...mapGetters({
	            familles: "LieuxStore/getFamilles"
	        }),
			isGenerique: function(){
				return this.node.libel_lieu=='-' || this.node.path.includes("/-/");
			},
			getIcon: function(){
				if(this.node.type_lieu=="Organisation") return "/static/assets/icone/lieux/organisation-mauve.png";
				else if(this.node.type_lieu=="Site") return "/static/assets/icone/lieux/site-mauve.png";
				else if(this.node.type_lieu=="Batiment") return "/static/assets/icone/lieux/batiment-mauve.png";
				else if(this.node.type_lieu=="Etage") return "/static/assets/icone/lieux/etage-mauve.png";
				else return "/static/assets/icone/lieux/piece-mauve.png";
			},
			getLibelLieu: function(){
				if(this.node.libel_lieu!='-') return this.node.libel_lieu;
				else if(this.node.type_lieu=="Piece") return this.$t("piece-generique");
				else if(this.node.type_lieu=="Etage") return this.$t("etage-generique");
				else if(this.node.type_lieu=="Batiment") return this.$t("batiment-generique");
				else return "-";
			}
		}
    };

</script>
<style lang="scss" scoped>
$tag-bg-color: #c9ecfc;
$tag-color: #7F7F7F;
$nb-equipements-color: #6f2881;
$nb-equipements-bg-color: #d5b3d3;
$rond-famille-default-color: #d5b3d3;
$rond-famille-height: 16px;
$level-icon-height: 16px;
$tag-img-height: 12px;
$tag-padding-top-bottom: 4px;
.vg-tree-node-lieu{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:10px;
	padding: 8px 8px;
	.main{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		.rond-famille{
			width: $rond-famille-height;
			height: $rond-famille-height;
			>div{
				width: $rond-famille-height;
				height: $rond-famille-height;
				background-color: $rond-famille-default-color;
				border-radius: 50%;
			}
		}
		.level-icon{
			width: $level-icon-height;
			height: $level-icon-height;
		}
		.tooltip-nb-equipements{
			padding: 5px;
			font-weight: bold;
			font-size: 11px;
			color: $nb-equipements-color;
			background-color: $nb-equipements-bg-color;
			border-radius: 100%;
		}
	}
	.complement{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		.famille-selector{
			min-width:200px;
		}
		>.tag{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			background-color: $tag-bg-color;
			padding: $tag-padding-top-bottom 10px;
			/*margin-right: 5px;*/
			border-radius: 2px;
			color: $tag-color;
			>img{
				width: $tag-img-height;
				height: $tag-img-height;
			}
		}
		>.badge-pi{
			padding: 3px;
			background-color: red;
			color: white;
			font-size: 10px;
			border-radius: 2px;
			white-space: nowrap;
		}
	}
}
.tree-node-generique{
	filter: grayscale(80%);
}
@media only screen and (max-width: 1200px){
	.vg-tree-node-lieu{
		gap:5px;
		font-size: 11px !important;
		padding: 0 0 0 5px;
		width: 100%;
		.main{
			gap: 5px;
			.level-icon{
				width: $level-icon-height;
				height: $level-icon-height;
			}
			.rond-famille{
				height: 10px;
				width: 10px;
				border-radius: 0;
				>div{
					height: 10px;
					width: 10px;
				}
			}
		}
		.complement{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 2px;
			overflow-y: auto;
			.famille-selector{
				min-width:150px;
			}
			.tag{
				padding: $tag-padding-top-bottom 5px;
				img{
					width: $tag-img-height;
					height: $tag-img-height;
				}
			}
			.only-desktop{
				display: none;
			}
		}
	}
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-tree-node
                >
            </vg-tree-node>
        </div>
      `
    })
    ```
</docs>
