<template>
    <div>

        <vue-excel-editor 
            ref="excelGrid" 
            v-model="equipementsToImport" 
            @validate-error="logValidationError" 
            @update="excelGridUpdated" 
        >
            <vue-excel-column v-for="rowConfig in excelRowsConfiguration" 
                :field = rowConfig.field   
                :label = rowConfig.label 
                :type = rowConfig.type 
                :width = rowConfig.width
                :mandatory = rowConfig.mandatoryMessage
                :readonly = rowConfig.readonly
                :options = rowConfig.options
                :text-align = rowConfig.textAlign

            />
            <slot></slot>
        </vue-excel-editor>
        <div v-if="errorMessages.length">
            <span> <b> {{ $t("errors-to-solve") }} </b></span>
            <ul id="error-messages-flat-style">
                <li v-for="message in errorMessages">{{ message }}</li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
  name: 'vg-excel-grid-editor',
  components: {
    
  },
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "errors-to-solve": "Erreurs à résoudre"
        },
        "en": {
            "errors-to-solve": "Errors to solve"
        }

    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    showOnlyNotValidRows: {
      type: Boolean,
      default: false
    },
    excelRowsConfiguration: {
      type: Array,
      default: false
    }
  },
    data() {
        return {
            gridDatas:[],
            equipementsToImportRequiredAttentionQuantity:0,
            errorMessages:[]
        }
    },
    watch: {
        value: {
            handler: function (val) {
                //this.gridDatas = val;
            },
            deep: true
        }
    },
    computed:{
        equipementsToImport:{
            get() {
                // populate this.gridDatas with property isValidated
                this.gridDatas = this.gridDatas.map((row)=>{
                    if(!row.hasOwnProperty("isValidated")) row.isValidated = true;
                    return row;
                });
                let notValidRows = this.gridDatas.filter((row)=>!row.isValidated);
                if(this.showOnlyNotValidRows){
                     return notValidRows;
                }
                return this.gridDatas;
            },
            set(value) {
                // Setter: Update items based on the child's input event
                /*this.gridDatas.forEach(equipement => {
                    console.log("set equipements to import", equipement);
                    if( equipement.$id == value.$id ){
                        equipement =  value;
                    }
                });
                console.log("set equipements to import");
                console.log(value);*/
                return;
            }
        }
    },
    created(){
        this.gridDatas = this.value;
    },
    methods:{
        excelGridUpdated(updateItemArray){
            updateItemArray.forEach((item)=>{
                if(item.err != null && item.err != ""){
                    this.equipementsToImportRequiredAttentionQuantity = this.gridDatas.filter((equipement)=>!equipement.isValidated).length;
                    return;
                }
                // in this.gridDatas find the item and update it
                let index = this.gridDatas.findIndex((row)=>{
                    console.log("row.$id", row.$id, item.$id , row.$id == item.$id)
                    return row.$id == item.$id;
                });
                item.isValidated = true;
                this.gridDatas[index][item.name] = item.newVal;
                this.gridDatas[index]["isValidated"] = true;
                this.equipementsToImportRequiredAttentionQuantity = 0;
            })
            this.$emit("update", updateItemArray)
        },
        logValidationError (error, row, field) {
            // For row validation, the field argument will be null value
            row.isValidated = false;
            this.errorMessages = this.getErrorMessages();
            this.$emit("validation-error", error, row, field);
        },
        getErrorMessages(){
            try {             
                let errors = this.$refs.excelGrid.errmsg;
                let messages = Object.values(errors);
                // remove duplicates
                messages = messages.filter((item, index) => messages.indexOf(item) === index);
                return messages;
            } catch (error) {
                console.log("error getting error messages");
                return [];
            }
        }
    },
}
</script>
<style scoped>
    #error-messages-flat-style{
        
        padding: 5px;
        margin: 0;
    }
</style>