<template lang="html">
		<vg-input
			:title="title?title:$t('title')">
			<vg-select
				v-model="selectedStatut"
				:options="statutsEquipement"
				:multiple="false"
				@input="handleChange"
			>
			</vg-select>
		</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";

    import Metadatas from "src/services/Metadatas.js"
    export default {
        name: "vg-filter-statut-equipement",
	    components: {
			VgInput,
            VgSelect
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: String
			},
			title: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par statut équipement",
            "pret": "Sortie temporairement"
        },
        "en": {
            "title": "Filtering by equipment's status",
            "pret": "Away"
        }
    }
},
        data: function(){
            return {
				selectedStatut: this.value,
                statutsEquipement:[
                    {
                        label:this.$t("pret"),
                        value:"pret"
                    },
                    {
                        label:this.$t("sorti"),
                        value:"sorti"
                    }
                ]
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedType = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			/**
	         * emit le type selectionné
	         * @event input type
	         */
			handleChange: function(type){
				this.$emit("input", type);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
<docs>
    vg-filter-sortie-type
</docs>
