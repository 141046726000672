<template>
    <div class="icon-name-cell-render"
        @mouseover="onMouseHover"
        @mouseleave="onMouseLeave">
        <div class="main">
    		<img v-if="icon && dataColumn"  :src="icon" onerror="this.style.visibility='hidden'" height="25" />
            <div v-else-if="showNoIconSpace" class="no-icon"></div>
    		<span :class="[{'blue-data' : isMainColumn, 'link': hasRoute} ]">
                {{dataColumn}}
            </span>
        </div>
        <vg-button v-if="nameIsHover"
            :type="'default-round'"
            :size="'sm'"
            @click="toLink">
            <i class="far fa-eye"></i>
        </vg-button>
	</div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "icon-name-cell-render",
        mixins:[LangMixins],
        components: {
            VgButton
        },
        props: {
            /**
             * icone identifiant de la colonne
             */
			icon: String,
            /**
             * donnée qui caracterise la colonne
             */
            dataColumn:{
				type: String,
				required: true
			},
            /**
             * Pour appliquer le css qui caracterise si c'est la colonne principal, ou une autre colonne entité (differencier au css).
             */
            isMainColumn:{
                type: Boolean,
                default:true
            },
            /**
            * route click
            * { router: this.$router, name: '_contrat_id', params: {id: params.data.id } }
            */
            route: {
                type: Object,
                default: null
            },
            showNoIconSpace: {
                type: Boolean,
                default: false
            }
		},
        data: function(){
            return {
                nameIsHover: false
            };
        },
        created: function(){

        },
        methods: {
            onMouseHover: function(){
                if(this.route) this.nameIsHover = true;
            },
            onMouseLeave: function(){
                if(this.route) this.nameIsHover = false;
            },
            toLink: function(){
                if(this.route) this.route.router.push(this.route);
            }
        },
        computed: {
            hasRoute: function(){
                return this.route?true:false;
            }
        }
    });
</script>

<style lang="scss" scoped>
.icon-name-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .main{
        width: calc(100% - 20px);
        margin-right: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        img{
            margin: 0;
        }
        .no-icon{
            height: 25px;
            width: 25px;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    button{
        i{
            color: #3999FF;
        }
    }
    .blue-data{
        font-weight: bold;
        color:#3999ff;
    }
    .link:hover{
        cursor: pointer;
        text-decoration: underline dotted;
    }
}
</style>
