import 'src/directives/TableLoader/TableLoader.css';
var TabLoaderDirective = {}
TabLoaderDirective.install = function install (_Vue) {
  let _uid = 'vue-directive-loading' + Date.now().toString('16')
  _Vue.directive('tab-loader', {
    inserted: function (el, binding) {
      let spinner = document.createElement('div');
      spinner.id = _uid;
      spinner.style.display = binding.value ? 'block' : 'none';
      let tabs = '<div style="display: flex"> <div class="tab item-tab"></div><div class="tab item-tab"></div><div class="tab item-tab"></div><div class="tab item-tab"></div>';
      let htmlTemplate = `

                    ${tabs}
      `;
      spinner.id = _uid
      /*spinner.classList.add("loader");*/
      spinner.innerHTML = htmlTemplate.trim();
      /*el.childNodes.forEach((item) => {
        item.style.display = binding.value ? 'none' : ''
    })*/
      el.appendChild(spinner)
    },
    update: function (el, binding, vnode) {
      let spinner = document.getElementById(_uid);
      console.log(spinner.style)
      spinner.style.display = binding.value ? 'block' : 'none'
      el.childNodes.forEach((item) => {
        if(item.id === _uid) return
        item.style.display = binding.value ? 'none' : ''
    })
    }
  })
}
export default TabLoaderDirective;
