<template lang="html">
    <div class="maintenance-card-cell-render" v-if="!$vgutils.isMobile()">
        <div class="col-1">
            <span style="color:#9e9da9;">{{isOuverture ? $t("ouverture") : $t("fermeture")}} <b style="color:black;">{{params.data.id}}</b></span>
            <div>
                <vg-datetime-viewer v-if="isOuverture" v-model="params.data.dateOuvertureSAV" />
                <vg-datetime-viewer v-else v-model="params.data.dateFermetureSAV" />
                <vg-fichesav-origin :fm="params.data" style="margin-left:10px;"/>
                <img src="\static\assets\icone\urgence.png" v-if="params.data.urgence == '1'" style="margin-left:10px;height:22px;" />
            </div>
            <div>
                <span style="color:#9e9da9;">{{$t("par")}}</span>
                <span>&nbsp;{{params.data.contact}}</span>
            </div>
            <div class="statut">
                <span style="font-size:12px;color:#9e9da9;">{{$t("statut")}}: </span>
                <vg-fichesav-statut-viewer :fm="params.data" />
            </div>
        </div>
        <div class="col-2">
            <div class="main-col">
                <vg-fichesav-description :fm="params.data" style="white-space:normal;max-width:30vw;"/>
                <span style="color:#9e9da9;margin:0 10px;">{{$t("dans")}}</span>
                <vg-lieu-path-viewer v-if="params.data.path" v-model="params.data.path" onlyPiece/>
                <vg-lieu-path-viewer v-else-if="params.data.equipement" v-model="params.data.equipement.path" onlyPiece/>
            </div>
            <div class="footer">
                <vg-button type="default-info" @click="$emit('open-details-maintenance', params.data)">{{$t("btn-open-maintenance")}}</vg-button>
                <vg-button v-if="params.data.statut!='Resolue' && params.data.statut!='Supervisor'" type="default-danger" @click="$emit('open-relance', params.data)">{{$t("btn-relancer")}}</vg-button>
            </div>
        </div>
    </div>
    <div class="maintenance-card-cell-render" v-else>
        <div class="row-1">
            <span style="color:#9e9da9;">{{isOuverture ? $t("ouverture") : $t("fermeture")}} <b style="color:black;">{{params.data.id}}</b></span>
            <vg-datetime-viewer v-if="isOuverture" v-model="params.data.dateOuvertureSAV" />
            <vg-datetime-viewer v-else v-model="params.data.dateFermetureSAV" />
        </div>
        <div class="row-2">
            <vg-fichesav-description :fm="params.data" style="white-space:normal;"/>
        </div>
        <div class="row-3">
            <span style="color:#9e9da9;margin-right:5px;">{{$t("dans")}}</span>
            <vg-lieu-path-viewer v-if="params.data.path" v-model="params.data.path" onlyPiece/>
            <vg-lieu-path-viewer v-else-if="params.data.equipement" v-model="params.data.equipement.path" onlyPiece/>
        </div>
        <div class="row-4">
            <span style="color:#9e9da9;">{{$t("par")}}&nbsp;<span style="color:black;margin-left:5px;">{{params.data.contact}}</span></span>
            <vg-fichesav-origin :fm="params.data" style="margin-left:10px;"/>
            <img src="\static\assets\icone\urgence.png" v-if="params.data.urgence == '1'" style="margin-left:10px;height:22px;">
            <vg-fichesav-statut-viewer :fm="params.data" />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFichesavOrigin from "src/components/Vg/FicheSAV/VgFichesavOrigin.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
  name:"maintenance-card-cell-render",
  mixins:[LangMixins],
  props:{
        params: {
            type: Object,
            required: true
        },
        isOuverture: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "ouverture": "Ouverture fiche n°",
            "fermeture": "Fermeture fiche n°",
            "dans": "dans",
            "par": "Par",
            "statut": "Statut",
            "btn-open-maintenance": "Voir détails fiche",
            "btn-relancer": "Relancer"
        },
        "en": {
            "ouverture": "Opening sheet n°",
            "fermeture": "Closing sheet n°",
            "dans": "in",
            "par": "By",
            "statut": "Status",
            "btn-open-maintenance": "Show details",
            "btn-relancer": "Revive"
        }
    }
},
    components:{
        VgFichesavDescription,
        VgFichesavStatutViewer,
        VgDatetimeViewer,
        VgLieuPathViewer,
        VgFichesavOrigin,
        VgButton
    }
});
</script>

<style lang="scss" scoped>
.maintenance-card-cell-render{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    .col-1{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: inherit;
        width: 20%;
    }
    .col-2{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: inherit;
        width: 80%;
        .main-col{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            height: inherit;
        }
        .footer{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 10px;
            height: inherit;
        }
    }
}
@media screen and (max-width: 600px) {
  	.maintenance-card-cell-render{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        >div{
            width: 100%;
        }
        .row-1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }
        .row-2{
            height: 60px;
            overflow: auto;
        }
        .row-3{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .row-4{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>
