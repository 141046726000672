<template>
    <vg-select
        v-if="typologiesMaintenance && typologiesMaintenance.length"
        v-model="selectedTypologiesMaintenance"
        :attributeLabel="attributeLabel"
        :attributeValue="attributeValue"
        :options="typologiesMaintenance"
        :clearable="clearable"
        :multiple="multiple"
        @change="handleChangeEvent"
        @input="handleInputEvent"
        :placeholder="placeholder?placeholder:$t('select-typologiesMaintenance')"
    >
    </vg-select>
    <span v-else></span>
</template>

<script>
import TypologiesMaintenanceMixins from "src/mixins/TypologiesMaintenanceMixins.js";
import VgSelect from "src/components/Vg/VgSelect.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: "vg-typologies-maintenance-selector",
    i18n:{
        messages:{
            "fr":{
                "no-datas":"Aucune données",
                "select-typologiesMaintenance":"Selectionner une typologie fiche curative"
            },
            "en":{
                "no-datas":"no datas",
                "select-typologiesMaintenance":"Select a curative sheet typology"
            }
        }
    },
    components: {
        VgSelect
    },
    mixins: [TypologiesMaintenanceMixins],
    props: {
        /**
        * @model
        */
        value: {
            type: String | Array,
            default: null
        },
        /**
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        /**
         * @default true
         */
        clearable: {
            type: Boolean,
            default: true
        },
        /**
         * @default null
         */
        placeholder: {
            type: String,
            default: null
        },
        /**
         * @default label
         */
        attributeLabel:{
            type: String,
            default: "name"
        },
        /**
         * @default id
         */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
         * 
         */
        fetchIfStoreIsEmpty: {
            type: Boolean,
            default: false
        },
        /**
         * 
         */
         executeFetch: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            selectedTypologiesMaintenance: this.value || []
        };
    },
    watch: {
        value:{
            handler:function(val){
                this.selectedTypologiesMaintenance = val;
            }
        }
    },
    computed: {
        ...mapGetters({
            typologiesMaintenance: "TypologiesMaintenanceStore/getCollection"
        })
    },
    methods: {
        fetch:function(){
            if(!this.executeFetch) return;
            if(this.fetchIfStoreIsEmpty && (!this.typologiesMaintenance || this.typologiesMaintenance.length==0)) return;
            let metadatas = new Metadatas();
            this.TypologiesMaintenanceMixins_get(metadatas);
        },
        handleChangeEvent:function(evt){
            this.$emit("changed",evt);
        },
        handleInputEvent:function(evt){
            this.$emit("input",evt);
            this.$emit("selected",this.selectedTypologiesMaintenance);
        }
    },
    created: function(){
        this.fetch();
    },
    mounted: function(){

    }
}
</script>

<style lang="scss" scoped>

</style>
