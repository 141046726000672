//Dashboard store module

import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.curatif = {
    totaux: null,
    urgentes: null,
    repartitionAge: null,
    repartitionComposants: null,
    repartitionDemandeur: null,
    repartitionUserAffecte: null,
    repartitionTiersAffecte: null,
    repartitionCategoriesEquipements: null,
    repartitionCorpsDetat: null,
    repartitionEquipements: null,
    repartitionEquipementsCouts: null,
    repartitionPieces: null,
    repartitionDureeTraitement: null
};
state.preventif = {
    repartitionNonConformites: null,
    releverCompteurs: null,
    prochainesInterventions: null,
    progressionInterne: null
};
state.consommables = {
    repartitionConsommationsMaintenances: null,
    repartitionConsommationsBonsDeSortie: null,
    repartitionEnStock: null
};
let getters = Base.getGetters();
getters.getCuratifTotaux = function(state){
    return state.curatif.totaux;
};
getters.getCuratifUrgentes = function(state){
    return state.curatif.urgentes;
};
getters.getCuratifRepartitionAge = function(state){
    return state.curatif.repartitionAge;
};
getters.getCuratifRepartitionComposants = function(state){
    return state.curatif.repartitionComposants;
};
getters.getCuratifRepartitionDemandeur = function(state){
    return state.curatif.repartitionDemandeur;
};
getters.getCuratifRepartitionUserAffecte = function(state){
    return state.curatif.repartitionUserAffecte;
};
getters.getCuratifRepartitionTiersAffecte = function(state){
    return state.curatif.repartitionTiersAffecte;
};
getters.getCuratifRepartitionCategoriesEquipements = function(state){
    return state.curatif.repartitionCategoriesEquipements;
};
getters.getCuratifRepartitionCorpsDetat = function(state){
    return state.curatif.repartitionCorpsDetat;
};
getters.getCuratifRepartitionEquipements = function(state){
    return state.curatif.repartitionEquipements;
};
getters.getCuratifRepartitionEquipementsCouts = function(state){
    return state.curatif.repartitionEquipementsCouts;
};
getters.getCuratifRepartitionPieces = function(state){
    return state.curatif.repartitionPieces;
};
getters.getCuratifRepartitionDureeTraitement = function(state){
    return state.curatif.repartitionDureeTraitement;
};
getters.getPreventifRepartitionNonConformites = function(state){
    return state.preventif.repartitionNonConformites;
};
getters.getPreventifReleverCompteurs = function(state){
    return state.preventif.releverCompteurs;
};
getters.getPreventifProchainesInterventions = function(state){
    return state.preventif.prochainesInterventions;
};
getters.getPreventifProgressionInterne = function(state){
    return state.preventif.progressionInterne;
};
getters.getConsommablesRepartitionConsommationsMaintenances = function(state){
    return state.consommables.repartitionConsommationsMaintenances;
};
getters.getConsommablesRepartitionConsommationsBonsDeSortie = function(state){
    return state.consommables.repartitionConsommationsBonsDeSortie;
};
getters.getConsommablesRepartitionEnStock = function(state){
    return state.consommables.repartitionEnStock;
};
let actions = Base.getActions();
/**
* @param Object value {"isReserves": {
            "ouvertures": "15",
            "fermetures": "1"
        },
        "isNotReserves": {
            "ouvertures": "488",
            "fermetures": "284"
        }}
*/
actions.setCuratifTotaux = function( context, value ){
    context.commit('SET_CURATIF_TOTAUX', value);
};
actions.setCuratifUrgentes = function( context, value ){
    context.commit('SET_CURATIF_URGENTES', value);
};
actions.setCuratifRepartitionAge = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_AGE', value);
};
actions.setCuratifRepartitionComposants = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_COMPOSANTS', value);
};
actions.setCuratifRepartitionDemandeur = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_DEMANDEUR', value);
};
actions.setCuratifRepartitionUserAffecte = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_USER_AFFECTE', value);
};
actions.setCuratifRepartitionTiersAffecte = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_TIERS_AFFECTE', value);
};
actions.setCuratifRepartitionCategoriesEquipements = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_CATEGORIES_EQUIPEMENTS', value);
};
actions.setCuratifRepartitionCorpsDetat = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_CORPS_DETAT', value);
};
actions.setCuratifRepartitionEquipements = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_EQUIPEMENTS', value);
};
actions.setCuratifRepartitionEquipementsCouts = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_EQUIPEMENTS_COUTS', value);
};
actions.setCuratifRepartitionPieces = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_PIECES', value);
};
actions.setCuratifRepartitionDureeTraitement = function( context, value ){
    context.commit('SET_CURATIF_REPARTITION_DUREE_TRAITEMENT', value);
};
actions.setPreventifRepartitionNonConformites = function( context, value ){
    context.commit('SET_PREVENTIF_REPARTITION_NON_CONFORMITES', value);
};
actions.setPreventifReleverCompteurs = function( context, value ){
    context.commit('SET_PREVENTIF_RELEVER_COMPTEURS', value);
};
actions.setPreventifProchainesInterventions = function( context, value ){
    context.commit('SET_PREVENTIF_PROCHAINES_INTERVENTIONS', value);
};
actions.setPreventifProgressionInterne = function( context, value ){
    context.commit('SET_PREVENTIF_PROGRESSION_INTERNE', value);
};
actions.setConsommablesRepartitionConsommationsMaintenances = function( context, value ){
    context.commit('SET_CONSOMMABLES_REPARTITION_CONSOMMATIONS_MAINTENANCES', value);
};
actions.setConsommablesRepartitionConsommationsBonsDeSortie = function( context, value ){
    context.commit('SET_CONSOMMABLES_REPARTITION_CONSOMMATIONS_BONS_DE_SORTIE', value);
};
actions.setConsommablesRepartitionEnStock = function( context, value ){
    context.commit('SET_CONSOMMABLES_REPARTITION_EN_STOCK', value);
};
let mutations = Base.getMutations();
mutations.SET_CURATIF_TOTAUX = function(state, value){
    state.curatif.totaux = value;
};
mutations.SET_CURATIF_URGENTES = function(state, value){
    state.curatif.urgentes = value;
};
mutations.SET_CURATIF_REPARTITION_AGE = function(state, value){
    state.curatif.repartitionAge = value;
};
mutations.SET_CURATIF_REPARTITION_COMPOSANTS = function(state, value){
    state.curatif.repartitionComposants = value;
};
mutations.SET_CURATIF_REPARTITION_DEMANDEUR = function(state, value){
    state.curatif.repartitionDemandeur = value;
};
mutations.SET_CURATIF_REPARTITION_USER_AFFECTE = function(state, value){
    state.curatif.repartitionUserAffecte = value;
};
mutations.SET_CURATIF_REPARTITION_TIERS_AFFECTE = function(state, value){
    state.curatif.repartitionTiersAffecte = value;
};
mutations.SET_CURATIF_REPARTITION_CATEGORIES_EQUIPEMENTS = function(state, value){
    state.curatif.repartitionCategoriesEquipements = value;
};
mutations.SET_CURATIF_REPARTITION_CORPS_DETAT = function(state, value){
    state.curatif.repartitionCorpsDetat = value;
};
mutations.SET_CURATIF_REPARTITION_EQUIPEMENTS = function(state, value){
    state.curatif.repartitionEquipements = value;
};
mutations.SET_CURATIF_REPARTITION_EQUIPEMENTS_COUTS = function(state, value){
    state.curatif.repartitionEquipementsCouts = value;
};
mutations.SET_CURATIF_REPARTITION_PIECES = function(state, value){
    state.curatif.repartitionPieces = value;
};
mutations.SET_CURATIF_REPARTITION_DUREE_TRAITEMENT = function(state, value){
    state.curatif.repartitionDureeTraitement = value;
};
mutations.SET_PREVENTIF_REPARTITION_NON_CONFORMITES = function(state, value){
    state.preventif.repartitionNonConformites = value;
};
mutations.SET_PREVENTIF_RELEVER_COMPTEURS = function(state, value){
    state.preventif.releverCompteurs = value;
};
mutations.SET_PREVENTIF_PROCHAINES_INTERVENTIONS = function(state, value){
    state.preventif.prochainesInterventions = value;
};
mutations.SET_PREVENTIF_PROGRESSION_INTERNE = function(state, value){
    state.preventif.progressionInterne = value;
};
mutations.SET_CONSOMMABLES_REPARTITION_CONSOMMATIONS_MAINTENANCES = function(state, value){
    state.consommables.repartitionConsommationsMaintenances = value;
};
mutations.SET_CONSOMMABLES_REPARTITION_CONSOMMATIONS_BONS_DE_SORTIE = function(state, value){
    state.consommables.repartitionConsommationsBonsDeSortie = value;
};
mutations.SET_CONSOMMABLES_REPARTITION_EN_STOCK = function(state, value){
    state.consommables.repartitionEnStock = value;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
