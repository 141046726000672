<template>
    <vg-periodicite-viewer v-model="periodicite" :key="params.data.id"/>
</template>
<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import VgPeriodiciteViewer from "src/components/Vg/VgPeriodiciteViewer.vue";
export default Vue.extend({
    name:"etat-realisation-cell-render",
    mixins:[LangMixins],
    components:{
        VgPeriodiciteViewer
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            
        }
    }
},
    methods:{
        
    },
    computed:{
        periodicite: function(){
            if(this.params.data.hasOwnProperty('periodicite') && this.params.data.periodicite) return this.params.data.periodicite;
            else if(this.params.data.hasOwnProperty('periodiciteIntervention') && this.params.data.periodiciteIntervention) return this.params.data.periodiciteIntervention;
            else return null;
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
