<template>
    <div style="display:flex;justify-content:flex-start;align-items:center;">
        <img src="static/assets/icone/lieu.png" height="15" style="margin: 0 5px;" /> {{$t("pieces")}}
    </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
    name:"pieces-layer-control",
    components:{},
	props: {
		params: {
			type: Object,
			required: true
		},
		tooltipTitle: {
			type: String,
			default: "default tooltip title"
		},
		type: {
			type: String,
			default: "default-info"
		},
		label: {
			type: String,
			default: ""
		},
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "pieces": "Pièces"
        },
        "en": {
            "pieces": "Rooms"
        }
    }
},
    methods:{
        handleClick:function(){
            this.$emit("click",this.params);
        }
    }
});
</script>
