<template lang="html">
    <div id="ifc-viewer">
        <div v-if="extractorIsStarting">{{$t("extractor-is-starting")}}</div>
        <vg-tabs v-model="focusedTab"
            :tabs="tabs"
            :color="'gris'"/>
        <tag-grid
            v-if="flatEntitiesMap.length"
			:idTableau="id"
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="getFlatEntitiesMapFiltered"
			:isExportXLS="TagGridMixins.isExportXLS"
            @ag-click="selectIfcEntity"
            />
        <div v-for="key of Object.keys(surfaces)">
            <div style="display:flex">
                <div style="margin-right:10px;">
                    {{$t("storey")}} {{key}} :
                </div>
                <div>
                    <b>{{surfaces[key].toFixed(2)}} m²</b>
                </div>
            </div>
        </div>

        <!--div v-if="levels && levels.length > 0">
            <div v-for="(level,index) in levels" >
                {{level.name}}
                <div v-for="(entity,indexEntity) in level.children">

                    {{entity.type}} - {{entity.Name.value}}
                </div>
            </div>
        </div-->
    </div>
</template>

<script>
import {IFCDatasExtractor} from "src/services/IFC/IFCDatasExtractor.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import VgTabs from "src/components/Vg/VgTabs.vue";
import {mapGetters} from "vuex";

export default {
    name: "ifc-datas-extractor",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "type":"Type",
            "storey":"Etage",
            "name":"Label",
            "longName":"Fonction",
            "overall-height":"Hauteur (m)",
            "overall-width":"Largeur (m)",
            "surface":"Surface",
            "dimensions":"Dimensions",
            "IFCSPACE":"Pièce",
            "IFCDOOR":"Porte",
            "IFCSTAIR":"Escalier",
            "IFCWINDOW":"Fenêtre",
            "IFCSLAB":"Dalles",
            "IFCCOVERING":"Revêtement",
            "IFCBUILDINGELEMENTPROXY":"Equipement intermédiaire",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Mobilier",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Boîtier mural",
            "IFCDISCRETEACCESSORY":"Accessoire discret",
            "IFCCURTAINWALL":"Mur rideau",
            "extractor-is-starting":"Extraction des données en cours...",
            "IFCWALL":"Mur",
            "pieces": "Pièces",
            "portes-fenetres": "Portes / fenêtres",
            "equipements": "Equipements",
            "murs": "Murs"
        },
        "en":{
            "type":"Type",
            "storey":"Floor level",
            "name":"Label",
            "longName":"Role",
            "overall-height":"Height",
            "overall-width":"Width",
            "overall-surface":"Surface",
            "dimensions":"Dimensions",
            "IFCSPACE":"Room",
            "IFCDOOR":"door",
            "IFCSTAIR":"Stair",
            "IFCWINDOW":"Window",
            "IFCSLAB":"Slab",
            "IFCCOVERING":"Covering",
            "IFCBUILDINGELEMENTPROXY":"Proxy equipment",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Furniture",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Wall case",
            "IFCDISCRETEACCESSORY":"Discrete accessory",
            "IFCCURTAINWALL":"Curtain wall",
            "IFCWALL":"Wall",
            "pieces": "Rooms",
            "portes-fenetres": "Doors / windows",
            "equipements": "Equipments",
            "murs": "Walls"
        },
    }
    },
    components:{
        TagGrid,
        VgTabs
    },
    mixins: [ TagGridMixins ],
    props: {
      /**
      * texte qui sera dans le tooltip
      * @type String
      * @default ""
      *
      **/
      url:{
          type:String,
          default:""
      },
      ifcText:{
          type:String,
          default:""
      },
            /**
            * This should be a unique id otherwise if 2 instances of
            * this component are called anywhere on app they will share filters
            * and ag-grid stored configs.
            */
            id:{
                type: String,
                default: "ifc-table"
            },
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return ["id", "libel_equipement"];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* paginate
			*/
			paginate: {
				type: Boolean,
				default: false
			},
			offset: {
				type: Number,
				default: 0
			},
			limit: {
				type: Number,
				default: 25
			},
            floorLevel:{
                type: Object,
                default: null
            }
    },
    watch:{
        searchQuery:{
            handler:function(searchQuery){
                /*let surface = 0;
                this.flatEntitiesMap.forEach((entity)=>{
                    if(entity.type == "IFCSPACE" && entity.storey == searchQuery && entity.properties && entity.properties.length){
                        properties.forEach(property => {
                            if(property.type == IFCELEMENTQUANTITY && property.Quantities && property.Quantities.length ){
                                surface += parseFloat(property.Quantities[0].AreaValue.value);
                            }
                        });
                    }
                })
                //console.log(surface);*/
                this.gridOptions.api.setQuickFilter(searchQuery.replace(" ","_"));
            }
        }
    },
    data:function(){
        return {
            gridOptions: {
                /*rowSelection:'multiple',
                getRowClass: function(params) {
                    if (params.data.dateSortie && params.data.dateSortie.length != 0) {
                        return 'equipement-sorti';
                    }
                }*/

            },
            columnDefs: [
                {
                    headerName: '#',
                    colId: 'expressID',
                    field: 'expressID',
                },
                {
                    headerName: this.$t("type"),
                    colId: 'type',
                    field: 'type',
                    cellRenderer: (params) => {
                        return this.$t(params.data.type);
                    },
                },
                {
                    headerName: this.$t("storey"),
                    colId: 'storey',
                    field: 'storey',
                    getQuickFilterText: params => {
                        let filter = params.data.storey +" "+params.data.type;

                        return filter.replace(" ","_");
                    }
                },
                {
                    headerName: this.$t("name"),
                    colId: 'name',
                    field: 'name',
                    filter:false
                },
                {
                    headerName: this.$t("longName"),
                    colId: 'longName',
                    field: 'longName',
                    cellRenderer: (params) => {
                        return params.data.longName && params.data.longName.value ? params.data.longName.value : "-";
                    }
                },
                {
                            headerName: this.$t("overall-height"),
                            colId: 'overallHeight',
                            field: 'overallHeight',
                            cellRenderer: (params) => {
                                return params.data.overallHeight && params.data.overallHeight.value ? params.data.overallHeight.value.toFixed(2) : "-";
                            }
                        },
                        {
                            headerName: this.$t("overall-width"),
                            colId: 'overallWidth',
                            field: 'overallWidth',
                            cellRenderer: (params) => {
                                return params.data.overallWidth && params.data.overallWidth.value ? params.data.overallWidth.value.toFixed(2) : "-";
                            }
                        },
                        {
                            headerName: this.$t("surface"),
                            colId: 'surface',
                            field: 'surface',
                            cellRenderer: (params) => {
                                let IFCELEMENTQUANTITY = 1883228015;
                                let surface = null;
                                if(params.data.properties && params.data.properties.length){
                                    params.data.properties.forEach(property => {
                                        if(property.type == IFCELEMENTQUANTITY && property.Quantities.length ){
                                            surface = property.Quantities[0].AreaValue.value.toFixed(2);
                                        }
                                    });
                                }
                                return surface;
                            }
                        }


            ],
            ifcExtractor:null,
            flatEntitiesMap:[],
            surfaces:{},
            focusedTab: "pieces"
        }
    },
    methods:{
        /**
         * @param {IFCSPACE} ifcSpace
         */
        addPropertiesToIFCSpace:function(ifcSpace){
            return new Promise((resolve,reject)=>{
                this.ifcExtractor.ifcManager.properties.getPropertySets(0,ifcSpace.expressID, true).then((propertiesSet)=>{
                    resolve(propertiesSet);
                })
            })
        },
        getGeometrie:function(expressID){
            let flatMesh = this.ifcExtractor.ifcManager.GetFlatMesh(0, expressID);
            let geometrieSize = flatMesh.geometries.size();
            for (let index = 0; index < geometrieSize; ++index) {
                let geometries = this.ifcExtractor.ifcManager.GetGeometry(0, flatMesh.geometries.get(index).geometryExpressID)
                //console.log(this.ifcExtractor.ifcManager.GetVertexArray(geometries.GetVertexData(), geometries.GetVertexDataSize()));
                //console.log(this.ifcExtractor.ifcManager.GetIndexArray(geometries.GetIndexData(), geometries.GetIndexDataSize()));
            }
        },
        allFlattenEntities:function(){
            let entities = [];
            let IFCELEMENTQUANTITY = 1883228015;
            for(let [levelName, level] of Object.entries(this.groupByTypePerLevel)){

                for(let [entityName, entities] of Object.entries(level)){
                    entities.forEach((entity)=>{
                        this.addPropertiesToIFCSpace(entity).then((properties)=>{
                            let longName = entity.LongName ? entity.LongName : null;
                            let overallWidth = entity.OverallWidth ? entity.OverallWidth : null;
                            let overallHeight = entity.OverallHeight ? entity.OverallHeight : null;
                            let name = entity.Name ? entity.Name.value: "";
                            let obj = {
                                "storey":levelName,
                                "levelID":level.expressID,
                                "name":name,
                                "Name":{value: name},
                                "LongName": longName,
                                "type":entity.type,
                                "overallWidth":overallWidth,
                                "overallHeight":overallHeight,
                                "expressID":entity.expressID,
                                "properties":properties
                            }
                            this.flatEntitiesMap.push(obj);

                            // refactoring needed
                            if(!this.surfaces.hasOwnProperty(levelName)){
                                this.surfaces[levelName] = 0;
                            }else{
                                if(entity.type =="IFCSPACE" && properties && properties.length){
                                    properties.forEach(property => {
                                        if(property.type == IFCELEMENTQUANTITY && property.Quantities && property.Quantities.length ){
                                            this.surfaces[levelName] += parseFloat(property.Quantities[0].AreaValue.value);
                                        }
                                    });
                                }
                            }
                        });
                    });
                }
            }
            return entities;
        },
        allFlattenEntitiesWithoutProperties:function(){
            let entities = [];
            let IFCELEMENTQUANTITY = 1883228015;
            for(let [levelName, level] of Object.entries(this.groupByTypePerLevel)){
                for(let [entityName, entities] of Object.entries(level)){
                    entities.forEach((entity)=>{
                            let longName = entity.LongName ? entity.LongName : null;
                            let overallWidth = entity.OverallWidth ? entity.OverallWidth : null;
                            let overallHeight = entity.OverallHeight ? entity.OverallHeight : null;
                            let name = entity.Name ? entity.Name.value: "";
                            let obj = {
                                "storey":levelName,
                                "levelID":level.expressID,
                                "name":name,
                                "Name":{value: name},
                                "LongName": longName,
                                "type":entity.type,
                                "overallWidth":overallWidth,
                                "overallHeight":overallHeight,
                                "expressID":entity.expressID
                            }
                            this.flatEntitiesMap.push(obj);
                            if(entity.hasOwnProperty("children") && entity.children.length){
                                entity.children.forEach((child)=>{
                                    let longName = child.LongName ? child.LongName : null;
                                    let overallWidth = child.OverallWidth ? child.OverallWidth : null;
                                    let overallHeight = child.OverallHeight ? child.OverallHeight : null;
                                    let name = child.Name ? child.Name.value: "";
                                    let obj = {
                                        "storey":levelName,
                                        "levelID":level.expressID,
                                        "name":name,
                                        "Name":{value: name},
                                        "LongName": longName,
                                        "type":child.type,
                                        "overallWidth":overallWidth,
                                        "overallHeight":overallHeight,
                                        "expressID":child.expressID
                                    };
                                    this.flatEntitiesMap.push(obj);
                                });
                            }
                    });
                }
            }
            return entities;
        },
        getEntityFromFlatMapByStoreyName:function(storeyName){
            return this.flatEntitiesMap.filter( entity => entity.storey == storeyName )
        },
        selectIfcEntity:function(ifcEntity){
            //console.log(ifcEntity)
            this.$emit("select-ifc-entity-express-id", {expressIDs: [ifcEntity.data.expressID], entity: ifcEntity.data});
            //this.$store.dispatch("LieuxIFCStore/setSelecteditem",this.ifcExtractor.getLevels());
        },
        groupBy:function(xs, key){
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});

        }
    },
    computed:{
        ...mapGetters({
            levels: 'LieuxIFCStore/getCollection',
            groupByTypePerLevel: 'LieuxIFCStore/groupByTypePerLevel',
            getFloorSurfaces: 'LieuxIFCStore/getFloorSurfaces',
        }),
        getIFCSPACE:function(){
            if(!this.flatEntitiesMap.length) return [];
            let spaces = [];
            this.flatEntitiesMap.forEach((entity)=>{
                if(entity.type == "IFCSPACE") spaces.push(entity);
            })
            return spaces;
        },
        getFlatEntitiesMapFiltered: function(){
            let focusedTabIndex = this.tabs.findIndex((tab)=>tab.name==this.focusedTab);
            let filters = this.tabs[focusedTabIndex].filters;
            let isSuccessClauses = true;
            let datasDisplayed = this.flatEntitiesMap;
            if(this.floorLevel) datasDisplayed = this.flatEntitiesMap.filter((entity)=> entity.storey==this.floorLevel.Name.value);
            datasDisplayed = datasDisplayed.filter((entity)=>{
                isSuccessClauses = true;
                filters.forEach((filter)=>{
                    if(typeof(filter.value)=="string") isSuccessClauses = isSuccessClauses && entity[filter.attr]==filter.value;
                    else isSuccessClauses = isSuccessClauses && filter.value.includes(entity[filter.attr]);
                });
                return isSuccessClauses;
            });
            return datasDisplayed;
        },
        tabs: function(){
            return [
                {label: this.$t("pieces"), name: "pieces", filters: [
                    {attr: "type", value: "IFCSPACE", action: "equals"}
                ]},
                {label: this.$t("portes-fenetres"), name: "portes-fenetres", filters: [
                    {attr: "type", value: ["IFCDOOR", "IFCWINDOW"], action: "equals"}
                ]},
                {label: this.$t("murs"), name: "murs", filters: [
                    {attr: "type", value: "IFCWALL", action: "equals"}
                ]},
                {label: this.$t("equipements"), name: "equipements", filters: [
                    {attr: "type", value: ["IFCFURNISHINGELEMENT", "IFCBUILDINGELEMENTPROXY", "IFCFURNITURE"], action: "equals"}
                ]}
            ];
        }
    },
    created:function(){
        this.extractorIsStarting= true;
        this.ifcExtractor = new IFCDatasExtractor(this.ifcText);
        this.ifcExtractor.init().then(()=>{
            this.$emit("initialized",this.ifcExtractor);
            this.$store.dispatch("LieuxIFCStore/set",this.ifcExtractor.getLevels());
            this.$store.dispatch("LieuxIFCStore/setRawIfc",this.ifcExtractor.rawDatas);
            //this.getGeometrie(5267);

            //console.log(this.levels)
            //this.allFlattenEntities()
            this.allFlattenEntitiesWithoutProperties();
            this.extractorIsStarting = false;
        })
    }
}
</script>

<style lang="scss" scoped>
#ifc-viewer{
    height: 100%;
    width: 100%;
}

</style>
