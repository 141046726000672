<template>
    <div class="vg-pagination" >
        <div class="contener-pagination">
            <vg-button v-if="!$vgutils.isMobile()"
                :type="'default-link'"
                :disabled="nbPages==1"
                :size="'sm'"
                @click="first()">
                {{$t("page")}} 1
            </vg-button>
            <vg-button
                :type="'default-link'"
                :disabled="currentPage==1"
                :size="'sm'"
                @click="previous()">
                <i class="fas fa-chevron-left"></i>
            </vg-button>
            <div v-for="pageNumber in pagesToDisplay">
                <vg-button
                    :type="pageNumber!=currentPage?'default-link':'info'"
                    :key="pageNumber"
                    :disabled="pageNumber>nbPages"
                    :size="'sm'"
                    @click="setCurrentPage(pageNumber)">
                    {{pageNumber}}
                </vg-button>
            </div>
            <vg-button
                :type="'default-link'"
                :disabled="currentPage==nbPages"
                :size="'sm'"
                @click="next()">
                <i class="fas fa-chevron-right"></i>
            </vg-button>
            <vg-button v-if="!$vgutils.isMobile()"
                :type="'default-link'"
                :disabled="nbPages==1"
                :size="'sm'"
                @click="last()">
                {{$t("page")}} {{nbPages}}
            </vg-button>
            <span v-if="!$vgutils.isMobile()" class="infos-pagination">
                <i v-if="totalItems<numberOfRowsToDisplay">{{totalItems}}</i> 
                <select v-else v-model="numberOfRowsToDisplay"
                    style="margin-left:10px;"
                    @change="onChangeNumberOfRowsToDisplay">
                    <option v-for="(l, kl) in awailableLimits" :value="l" :key="'al-'+kl">{{ l }}</option>
                </select>
                {{$t("rows-displayed")}} 
                <b><i>{{totalItems}}</i></b>
            </span>
            <span v-else class="infos-pagination"><i>{{getRowsDisplayed}}</i> / <b><i>{{totalItems}}</i></b></span>
        </div>
    </div>
</template>

<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
export default {
  name: "vg-pagination",
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page": "Page",
            "rows-displayed": "lignes affichées sur"
        },
        "en": {
            "page": "Page"
        }
    }
},
    components:{
        VgButton,
        VgSelect
    },
    props: {
        totalItems:{
            type:Number,
            default:function(){
                return 1;
            }
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        currentPageNumber: {
            type: Number,
            default: null
        }
    },
    data: function() {
        return {
            numberOfRowsToDisplay: this.limit,
            numberOfPagesToDisplay: this.$vgutils.isMobile()?3:5,
            currentPage : this.currentPageNumber || this.offset+1,
            nbPages: 1,
            awailableLimits: [25, 50, 100]
        };
    },
    watch:{
        totalItems: {
            handler: function(total){
                this.setNbPages();
            }
        },
        currentPageNumber: {
            handler: function(number){
                if(number!=this.currentPage) this.currentPage = number;
            }
        },
        limit: {
            handler: function(newlimit){
                this.numberOfRowsToDisplay = newlimit;
                this.setNbPages();
            }
        }
    },
    created:function(){
        this.setNbPages();
    },
    computed:{
        getRowsDisplayed: function(){
            if(this.limit>this.totalItems) return this.totalItems;
            else return this.limit;
        },
        pagesToDisplay: function(){
            let pages = [];
            let firstPageToDisplay;
            if(this.currentPage<=5){
                firstPageToDisplay = 1
            }else{
                firstPageToDisplay = this.currentPage-2;
            }
            let lastPageToDisplay = firstPageToDisplay+this.numberOfPagesToDisplay-1;
            for(var p=firstPageToDisplay; p<lastPageToDisplay+1;p++) pages.push(p);
            return pages;
        },
        getPagination:function(){
            return {
                numberOfRowsToDisplay: this.numberOfRowsToDisplay,
                currentPage: this.currentPage,
                offset: this.getOffset
            }
        },
        getOffset:function(){
            return this.currentPage !== 1 ? (this.currentPage-1)*this.numberOfRowsToDisplay:0;
        }
    },
    methods:{
        onChangeNumberOfRowsToDisplay: function(event){
            this.first();
        },
        /**
        * Calcul le nombre de pages à afficher.
        */
        setNbPages: function(){
            let nbPages = parseInt(this.totalItems/this.numberOfRowsToDisplay);
            if(this.totalItems/this.numberOfRowsToDisplay>nbPages) nbPages++;
            this.nbPages = nbPages;
        },
        first: function(){
            this.currentPage = 1;
            this.emitPaginationChange();
        },
        last: function(){
            this.currentPage = this.nbPages;
            this.emitPaginationChange();
        },
        previous: function(){
            this.currentPage--;
            this.emitPaginationChange();
        },
        next: function(){
            this.currentPage++;
            this.emitPaginationChange();
        },
        setCurrentPage: function(page){
            this.currentPage = page;
            this.emitPaginationChange();
        },
        emitPaginationChange: function(){
            this.$emit("pagination-change", this.getPagination);
        }
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.vg-pagination{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    /*i{
        font-size:24px;
        color:#2eaac7
    }*/
    .contener-pagination{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .vg-button{
            margin:0 10px;
        }
    }
    .infos-pagination{
        font-size: 10px;
        color: rgba(0,0,0,0.7);
    }
}
.button-paginator {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 1000;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    color:#2eaac7
}
.button-paginator[class="button-paginator hoverable"]:hover{
  background-color: #e5f5f8;
  border: 1px solid #2eaac7;
}
.selected{
    background-color: #e5f5f8;
    border: 1px solid #2eaac7;
}
.button-paginator[class="button-paginator hoverable focusable"]:focus{
    background-color: #e5f5f8;
    border: 1px solid #2eaac7;
}

.vspButtonFast{
  background-color: #f4f8fb;
  color: #b5b9bc;
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-pagination
                :pageTotal="10"
            ></vg-pagination>
        </div>
      `
    })
    ```
</docs>
