<template>
    <div>
        <vg-fiche-demande-consommables-statut-viewer :fiche="params.data" />
    </div>
</template>

<script>
    import Vue from "vue";
    import VgFicheDemandeConsommablesStatutViewer from "src/components/Vg/FicheDemandeConsommables/VgFicheDemandeConsommablesStatutViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgFicheDemandeConsommablesStatutViewer
        },
        computed:{

        }
    });
</script>

<style lang="scss" scoped>

</style>
