<template lang="html">
	<vg-input
		:title="$t('title')"
	>
		<vg-select
			v-model="focusedType"
			:options="types"
			:attributeValue="'value'"
			@input="handleChange"
		>
		</vg-select>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue"
	import VgSelect from "src/components/Vg/VgSelect.vue"
    export default {
        name: 'vg-filter-tache-type',
	    components: {
			VgInput,
			VgSelect
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			value: String
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par type tache",
            "type-verification-equipement": "Vérification équipement",
            "type-verification-lieu": "Vérification lieu",
            "type-relever-compteur": "Relevé compteur",
            "type-grandeur-physique": "Relevé grandeur physique"
        },
        "en": {
            "title": "Filter by task type",
            "type-verification-equipement": "Verify equipment",
            "type-verification-lieu": "Verify place",
            "type-relever-compteur": "Meter reading",
            "type-grandeur-physique": "List of physical quantities"
        },
        "th": {
            "title": "กรองโดยประเภทของภารกิจ",
            "type-verification-equipement": "ตรวจสอบอุปกรณ์",
            "type-verification-lieu": "ตรวจสอบสถานที่",
            "type-relever-compteur": "การอ่านมิเตอร์",
            "type-grandeur-physique": "รายการของจำนวนทางกายภาพ"
        }
    }
},
        data: function(){
            return {
				focusedType: this.value || null,
				types: [
					{label: this.$t("type-verification-equipement"), value: "Verification_equipement"},
					{label: this.$t("type-verification-lieu"), value: "Verification_Lieu"},
					{label: this.$t("type-relever-compteur"), value: "Relever_compteur"},
					{label: this.$t("type-grandeur-physique"), value: "Relever_Grandeur_physique"}
				]
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedType = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			handleChange: function(e){
				this.$emit("input", this.focusedType);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
