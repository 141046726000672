<template>
	<div class="credits">
		<div class="logos">
			<img src="static/assets/credits/logo_ue.png" :alt="$t('logo-ue')" :title="$t('logo-ue')" style="margin-right: 20px;">
			<img src="static/assets/credits/logo_region_reunion.png" :alt="$t('logo-region-reunion')" :title="$t('logo-region-reunion')">
		</div>
		<span class="text">{{$t("financement-ue-region-reunion")}}</span>
	</div>
</template>
<script>
    export default {
        name: 'vg-credits',
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "financement-ue-region-reunion": "Le projet conception et expérimentation de l’outil Verifgood est cofinancé par l’Union Européenne et la Région Réunion",
            "logo-ue": "Union Européenne",
            "logo-region-reunion": "Région Réunion"
        },
        "en": {
            "financement-ue-region-reunion": "The design and experimentation project of the Verifgood tool is co-financed by the European Union and the Region Réunion ",
            "logo-ue": "European union",
            "logo-region-reunion": "Région Réunion"
        },
        "th": {
            "financement-ue-region-reunion": "โครงการออกแบบและการทดลองของเครื่องมือ Verifgood ได้รับความช่วยเหลือทางการเงินโดยสหภาพยุโรปและภูมิภาครียูเนี่ยน ",
            "logo-ue": "สหภาพยุโรป",
            "logo-region-reunion": "ภูมิภาครียูเนี่ยน"
        }
    }
}
    };
</script>
<style lang="scss" scoped>
.credits{
	display: flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	//position: fixed;
	width: 100%;
	bottom: 0px;
	padding-bottom: 20px;
	@media only screen and (max-width: 768px){
		padding-bottom: 10px;
	}
	>.logos{
		display:flex;
		justify-content:center;
		align-items:center;
		margin: 20px 0;
		>img{
			width: 50px;
		}
	}
	>.text{
		font-style: italic;
		font-size: 11px;
		text-align: center;

	}
}
</style>
