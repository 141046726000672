<template>
	<vg-modal
        @close="$emit('close')"
        @save="handleSave">
		<template #header>
			<span>
				{{$t("modal-title")}}:
				<img :src="getIcon" class="icon"/>
				<b>
					<vg-lieu-path-viewer v-model="value.path"
						onlyPiece
						:type="value.type_lieu"
						:showTooltip="false">
					</vg-lieu-path-viewer>
				</b>
			</span>
		</template>
		<template #body>
			<div>
				<span>{{$t("localisation")}}: </span>
				<vg-lieu-path-viewer v-model="value.path"
					:type="value.type_lieu">
				</vg-lieu-path-viewer>
			</div>
			<div v-if="value.type_lieu=='Piece'">
				<span>{{$t("categorie")}}: </span>
				<span>{{value.libelleCatgorie}}</span>
			</div>
			<div v-if="value.type_lieu=='Piece'">
				<span>{{$t("fonction")}}: </span>
				<span>{{value.codeTrois}}</span>
			</div>
			<div v-if="value.type_lieu=='Piece'">
				<span>{{$t("service")}}: </span>
				<span>{{value.service}}</span>
			</div>
			<div v-if="value.type_lieu=='Site'">
				<!-- mettre adresse & geolocalisation -->
				<span>{{$t("adresse")}}: </span>
				<span>{{value.adresse}}</span>
			</div>
			<div v-else-if="value.type_lieu=='Piece'">
				<!-- mettre info lieu -->

			</div>
			<hr>
			<vg-files-viewer-photo v-if="value.type_lieu=='Piece'"
				:files="VgFilesMixins_filesOnlyPhoto">
            </vg-files-viewer-photo>
			<vg-files-viewer-list-section
				:files="VgFilesMixins_filesListSection"
				@delete="VgFilesMixins_fetchFilesByUid()">
			</vg-files-viewer-list-section>
			<vg-files-uploader v-if="value.type_lieu=='Piece'"
				:tagsList="VgFilesMixins.tags"
				@upload-done="VgFilesMixins_fetchFilesByUid();">
            </vg-files-uploader>
		</template>
		<template #footer>
			<vg-button @click="$emit('close')">{{$t("close")}}</vg-button>
			<vg-button v-if="isAdmin && !isBatimentEtageGenerique && !isContainerEquipementsGlobaux" :type="'danger'" @click="$emit('update')">{{$t("update")}}</vg-button>
		</template>
    </vg-modal>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

	import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

	export default {
        name: 'vg-lieu-detail-modal',
    	props: {
			value: {
                type: Object,
                required: true
            }
        },
		components:{

			VgButton,
			VgLieuPathViewer,
			VgFilesViewerListSection,
			VgFilesViewerPhoto,
			VgFilesUploader
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Détail du lieu",
            "update": "Modifier le lieu",
            "close": "Fermer",
            "localisation": "Localisation",
            "categorie": "Catégorie",
            "fonction": "Fonction",
            "service": "Service",
            "adresse": "Adresse"
        },
        "en": {
            "modal-title": "Location details",
            "update": "Update location",
            "close": "Close",
            "localisation": "Localisation",
            "categorie": "Category",
            "fonction": "Position",
            "service": "Service",
            "adresse": "Address"
        },
        "th": {
            "modal-title": "รายละเอียดที่ตั้ง",
            "update": "อัปเดตที่ตั้ง",
            "close": "ปิด",
            "localisation": "การจำกัดความ",
            "categorie": "หมวดหมู่",
            "fonction": "ตำแหน่ง",
            "service": "บริการ",
            "adresse": "ที่อยู่"
        }
    }
},
        data: function() {
            return {

            };
        },
		computed: {
			getIcon: function(){
				if(this.value.type_lieu=="Organisation") return "/static/assets/icone/lieux/organisation-mauve.png";
				else if(this.value.type_lieu=="Site") return "/static/assets/icone/lieux/site-mauve.png";
				else if(this.value.type_lieu=="Batiment") return "/static/assets/icone/lieux/batiment-mauve.png";
				else if(this.value.type_lieu=="Etage") return "/static/assets/icone/lieux/etage-mauve.png";
				else return "/static/assets/icone/lieux/piece-mauve.png";
			},
			isAdmin: function(){
				return this.$app.role=="ROLE_ADMIN";
			},
			isBatimentEtageGenerique: function(){
				return (this.value.type_lieu=="Batiment" || this.value.type_lieu=="Etage") && this.value.path.includes("/-");
			},
			isContainerEquipementsGlobaux: function(){
	            return this.value.type_lieu=="Piece" && this.value.libel_lieu=="-";
	        }
        },
		created: function(){
			this.VgFilesMixins_init(this.value.uid, "lieu");
		}
    };

</script>
<style lang="scss" scoped>
	.icon{
		width: 30px;
		height: 30px;
	}
</style>
