<template>
    <div>
        <div v-show="isCreatingAssets"> <div>{{$t('is-creating-assets')}}</div>  <div v-show="isCreatingAssets" class="lds-dual-ring"></div> </div>
        <div v-show="isLoadingSpatialStructure">{{$t('is-loading-spatial-tree')}}</div>
        <div v-show="isLoading">{{$t('chargement-en-cours')}}</div>
        <button @click="switchCamera(true)">Vue 2d</button>
        <button @click="switchCamera(false)">Vue 3d</button>
        <div v-show="!isCreatingAssets" id="viewer-container" style="height:400px">  </div>
    </div>
    
</template>

<script>
import {mapGetters} from "vuex";
import { IfcViewerAPI } from 'web-ifc-viewer';
import { IFCWALLSTANDARDCASE,IFCWALL, IFCSLAB,IFCSPACE, IFCDOOR, IFCWINDOW, IFCFURNISHINGELEMENT, IFCMEMBER, IFCPLATE, IFCCOVERING } from "web-ifc";
import {
  OrthographicCamera,
  Color
} from "three";
export default {
    name: "ifc-viewer",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "type":"Type",
            "storey":"Etage",
            "name":"Label",
            "longName":"Fonction",
            "overall-height":"Hauteur (m)",
            "overall-width":"Largeur (m)",
            "surface":"Surface",
            "dimensions":"Dimensions",
            "IFCSPACE":"Pièce",
            "IFCDOOR":"Porte",
            "IFCSTAIR":"Escalier",
            "IFCWINDOW":"Fenêtre",
            "IFCSLAB":"Dalles",
            "IFCCOVERING":"Revêtement",
            "IFCBUILDINGELEMENTPROXY":"Equipement intermédiaire",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Mobilier",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Boîtier mural",
            "IFCDISCRETEACCESSORY":"Accessoire discret",
            "IFCCURTAINWALL":"Mur rideau",
            "IFCWALL":"Mur",
            "fetching-distant-file":"Récupération du fichier",
            "is-creating-assets":"Création des modèles",
            "is-loading-spatial-tree":"Chargement de l'arborescence spatiale",
            "chargement-en-cours":"Chargement en cours"
        },
        "en":{
            "type":"Type",
            "storey":"Floor level",
            "name":"Label",
            "longName":"Role",
            "overall-height":"Height",
            "overall-width":"Width",
            "overall-surface":"Surface",
            "dimensions":"Dimensions",
            "IFCSPACE":"Room",
            "IFCDOOR":"door",
            "IFCSTAIR":"Stair",
            "IFCWINDOW":"Window",
            "IFCSLAB":"Slab",
            "IFCCOVERING":"Covering",
            "IFCBUILDINGELEMENTPROXY":"Proxy equipment",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Furniture",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Wall case",
            "IFCDISCRETEACCESSORY":"Discrete accessory",
            "IFCCURTAINWALL":"Curtain wall",
            "IFCWALL":"Wall"
        },
    }
    },
    components:{},
    mixins: [],
    props: {
        highlitedItems:{
            type: Array,
            default:function(){
                return []
            }
        },
        displayedItems:{
            type: Array,
            default:function(){
                return []
            }
        },
        ifcFileLocation:{
            type: String,
            default:function(){
                return  "";
            }
        },
        ifcFile:{
            default:function(){
                return  null;
            }
        },
        fetchSpatialTree:{
            type: Boolean,
            default:function(){
                return  false;
            }
        },
    },
    watch:{
        highlitedItems:function(newHighlitedItems){
            console.log(newHighlitedItems)
            this.pickElementInIFCViewer(newHighlitedItems);
        },
        displayedItems:function(newHighlitedItems){
            /*if(!newHighlitedItems){
                this.hideAllSubset();
                this.displayAllSubset();
                return;
            }*/
            this.isLoading = true;
            this.displayEntities(0,newHighlitedItems);
            this.isLoading = false;
        },
        fetchSpatialTree:function(openIt){
            if(openIt){
                this.generateSpatialTree(0);
            }
            
        },
    },
    data:function(){
        return {
           //ifcFileLocation:null,
           "searchQuery":null,
           viewer:null,
           subsets:{},
           ignoredEntities:["IFCWALL","IFCWALLSTANDARDCASE"],
           subsetsReady:false,
           loadedState:"Loaded 0%",
           isLoading:false,
           isCreatingAssets:false,
           isLoadingSpatialStructure:false,
           spatialStructure:null,
           floorLevels:[],
           spaces:[],
           selectedFloorLevel:null,
           loadingGroupSubsetName:"",
           modelID:0,
           model:null
       }
    },
    methods:{
        centerCamera:function(){
            this.viewer.context.getIfcCamera().cameraControls.moveTo( 0, 0, 0 );
            this.viewer.context.getIfcCamera().cameraControls.update();
        },
        /**
         * @param boolean enabled
         */
        enableCameraRotation:function(enabled){
            if(enabled){
                this.viewer.context.getIfcCamera().cameraControls.maxPolarAngle = Math.PI;
                this.viewer.context.getIfcCamera().cameraControls.minPolarAngle = 0;
            }else{
                this.viewer.context.getIfcCamera().cameraControls.maxPolarAngle = - Math.PI;
                this.viewer.context.getIfcCamera().cameraControls.minPolarAngle = - Math.PI;
            }
            this.viewer.context.getIfcCamera().cameraControls.update();
        },
        setOrthographicCamera:function(){
            this.viewer.context.getIfcCamera().cameraControls.reset();
            this.viewer.context.getIfcCamera().cameraControls.update();
            console.log(this.viewer.context.ifcCamera)
            if( this.viewer.context.ifcCamera.activeCamera.constructor.name != "OrthographicCamera"){
                this.viewer.context.ifcCamera.toggleProjection();
            }
            this.enableCameraRotation(false);
            this.centerCamera();
            this.viewer.context.getIfcCamera().cameraControls.rotateTo( Math.PI * 0.5, -10 );
            this.viewer.context.getIfcCamera().cameraControls._zoom = 0.5;
            this.viewer.context.getIfcCamera().cameraControls._zoom0 = 0.5;
            this.viewer.context.getIfcCamera().cameraControls._zoomEnd = 0.5;
            this.viewer.context.getIfcCamera().cameraControls.update();
        },
        setperspectiveCamera:function(){
            this.viewer.context.getIfcCamera().cameraControls.reset();
            this.viewer.context.getIfcCamera().cameraControls.update();
            console.log(this.viewer.context)
            if( this.viewer.context.ifcCamera.activeCamera.constructor.name != "PerspectiveCamera"){
                this.viewer.context.ifcCamera.toggleProjection();
            }
            this.enableCameraRotation(true);
            this.centerCamera();
            this.viewer.context.getIfcCamera().cameraControls.rotateTo( 10, Math.PI * 0.5 );
            this.viewer.context.getIfcCamera().cameraControls._zoom = 0.5;
            this.viewer.context.getIfcCamera().cameraControls._zoom0 = 0.5;
            this.viewer.context.getIfcCamera().cameraControls._zoomEnd = 0.5;
            this.viewer.context.getIfcCamera().cameraControls.update();
        },
        switchCamera:function(isOrthographic) {
            if (isOrthographic == false) {
                this.setperspectiveCamera();
            } else {
                this.setOrthographicCamera();
                //controls.enableRotate = false;
            }

        },
        hideAllSubset:function(){
            let subsetsName = Object.keys(this.subsets);
            subsetsName.forEach((subset)=>{
                this.hideSubset(subset);
            });
        },
        displayAllSubset:function(){
            let scene = this.viewer.context.getScene();
            let subsets = Object.values(this.subsets);
            subsets.forEach((subset)=>{
                scene.add(subset);
            });
        },
        /**
         * 
         */
        displayEntities: function(modelID, expressIDs){
            this.isLoading = true;
            this.emitStatus("isLoadingEntities");
            this.hideAllSubset();
            this.model.removeFromParent();
            let scene = this.viewer.context.getScene();
            const subset = this.viewer.IFC.loader.ifcManager.createSubset({
                        "modelID":0,
                        scene,
                        "ids":expressIDs,
                        removePrevious: true,
                        customID: "zefzfezfzf"
                    }
            );
            scene.add(subset);
            this.isLoading = false;
        },
        triggerFiltering:function(searchQuery){
            let completePath = searchQuery.level +" "+searchQuery.entity;
            this.searchQuery = completePath;
            let expressIDs = searchQuery.entities.map(entity => entity.expressID);
            this.pickElementInIFCViewer(expressIDs);
        },
        pickElementInIFCViewer:function(expressIDs){
            this.viewer.IFC.selector.pickIfcItemsByID(0, expressIDs, true);
        },
        ignoredEntities:function(entityName){
            return !this.ignoredEntities.include(entityName);
        },
        getProperties:function(modelID, expressID){
            this.viewer.IFC.getProperties(modelID, expressID, true).then((properties)=>{
                console.log(properties);
            });
        },
        downloadProperties:function(){
            this.viewer.IFC.properties.serializeAllProperties(model).then((properties)=>{
                const file = new File(properties, 'properties');
                const link = document.createElement('a'); 
                document.body.appendChild(link);
                link.href = URL.createObjectURL(file);
                link.download = 'properties.json';
                link.click();
                link.remove();
            });
        },
        createSubset:function(modelID,categorie){
            return new Promise((resolve,reject)=>{
                let scene = this.viewer.context.getScene();
                this.viewer.IFC.loader.ifcManager.getAllItemsOfType(modelID, categorie).then((ids)=>{
                    const subset = this.viewer.IFC.loader.ifcManager.createSubset({
                        "modelID":modelID,
                        scene,
                        ids,
                        removePrevious: true,
                        customID: categorie.toString()
                    });
                    resolve(subset);
                });
            });
        },
        createSubsets:function(modelID){
            return new Promise((resolve,reject)=>{
                this.subsetsReady = false;
                this.isLoading = true;
                let categories = {  
                    IFCWALLSTANDARDCASE,
                    IFCWALL,
                    IFCSLAB,
                    IFCSPACE,
                    IFCFURNISHINGELEMENT,
                    IFCDOOR,
                    //IFCCOVERING,
                    IFCWINDOW
                }
                let promises = [];
                let names = Object.keys(categories);

                let allCategories = Object.values(categories);
                allCategories.forEach((category)=>{
                    let entityName = names.find((name) => categories[name] === category);
                    promises.push(this.createSubset(modelID,category).then((subset)=>{
                        //this.subsets.push(subset);
                        this.subsets[entityName] = subset;
                    }))
                })
                Promise.all(promises).then(([e])=>{
                    resolve(this.subsets);
                    this.subsetsReady = true;
                    this.isLoading = false;
                })
                
            })

        },
        hideSubset:function(subsetName){
            this.subsets[subsetName].removeFromParent();
        },
        showSubset:function(subsetName){
            let scene = this.viewer.context.getScene();
            scene.add(this.subsets[subsetName]);
        },
        initViewer:function(){
            return new Promise((resolve, reject)=>{
                let container = document.getElementById('viewer-container');
                this.viewer = new IfcViewerAPI({container, backgroundColor: new Color("rgb(28,84,133)")});
                this.viewer.IFC.loader.ifcManager.applyWebIfcConfig({
                    USE_FAST_BOOLS: true,
                    COORDINATE_TO_ORIGIN: true
                });
                this.viewer.IFC.loader.ifcManager.parser.setupOptionalCategories({
                    [IFCSPACE]: true,
                    [IFCSLAB]: false,
                    [IFCCOVERING]: false,
                });
                this.viewer.grid.setGrid(100,10);
                this.viewer.IFC.loader.ifcManager.setOnProgress((event) => {
                    const percentage = Math.floor((event.loaded * 100) / event.total);
                    this.loadedState = `Loaded ${percentage}%`;
                });
                this.viewer.IFC.setWasmPath("../../../../static/wasm/").then(()=>{
                    
                    resolve();
                });
            })

        },
        startBimViewer:function(){
            
            //let container = document.getElementById("viewer-container");
            this.emitStatus("isCreatingAssets");
            return new Promise((resolve, reject)=>{
                this.isCreatingAssets = true;
                this.initViewer().then(()=>{
                    
                    this.viewer.IFC.loadIfc(this.ifcFile,true).then((model)=>{
                        this.model = model;
                        let modelID = model.modelID;
                        //model.removeFromParent();
                        this.isCreatingAssets = false;
                        resolve(modelID);
                        
                        // nest plus utile pour le viewer 
                        // ça me permettait de storer les subset representant chaque IFCCategory
                        // et les display or not de manière générale

                        /*this.createSubsets(modelID).then((subsets)=>{
                            
                            this.viewer.shadowDropper.renderShadow(modelID);
                            let scene = this.viewer.context.getScene();
                            let subsetsName = Object.keys(subsets);
                            subsets = Object.values(subsets);
                            this.totalSubsets = subsets.length;
                            subsets.forEach((subset,key)=>{
                                this.loadingGroupSubsetName = subsetsName[key];
                                scene.add(subset);
                            });

                            this.isCreatingAssets = false;
                            resolve(modelID);
                        })*/
                    });
                });
            });

        },
        generateSpatialTree:function(modelID){
            this.isLoadingSpatialStructure = true;
            this.viewer.IFC.getSpatialStructure(modelID,true).then((spatialStructure)=>{
                
                this.spatialStructure = spatialStructure;
                this.isLoadingSpatialStructure = false;
                this.floorLevels = this.extractBuildingStorey(spatialStructure);
                //this.feedSpaces(spatialStructure);
                this.$emit("spatialTreeLoaded", spatialStructure);
                this.$store.dispatch("LieuxIFCStore/set",spatialStructure);
                
                //this.spaces = this.extractSpaces(this.floorLevels);
                //this.displayFloorLevel(modelID,this.floorLevels[2].children);   
            });
        },
        extractBuildingStorey:function(spatialStructure){
            let floorLevels = [];
            if(spatialStructure.children[0].type == "IFCSITE"){
                floorLevels = spatialStructure.children[0].children[0].children;
            }else{
                floorLevels = spatialStructure.children[0].children;
            }
            return floorLevels;
        },
        feedSpaces:function(spatialStructure){
                let project = spatialStructure;
                let site = project.children[0];
                let buildings = site.children;
                buildings.forEach((building, bKey)=>{
                    let storeys = building.children;
                    storeys.forEach((storey, sKey) => {
                        let elements = storey.children;
                        elements.forEach((element, eKey)=>{
                            if(element.type=="IFCSPACE"){
                                
                                    this.viewer.IFC.getProperties(0,element.expressID).then((piece)=>{
                                        
                                        spatialStructure.children[0].children[bKey].children[sKey].children[eKey] = piece;
                                    })
                                //spaces.push(piece);
                            };
                        });
                    });
                });
        },
        extractSpaces:function(floorLevels){
            let spaces = [];
            console.log(this.viewer);
            floorLevels.forEach((floorLevel) => {
                floorLevel.children.forEach((element) => {
                    if(element.type=="IFCSPACE"){
                        /*this.viewer.IFC.getProperties(0,element.expressID).then((piece)=>{
                            spaces.push(piece);
                        })*/
                        spaces.push(piece);
                    };
                });

            });
            
            return spaces;
        },
        emitStatus:function(status){
            this.$emit("status-change",status);
        }
    
    },
    computed:{
        ...mapGetters({
            levels: 'LieuxIFCStore/getCollection',
            groupByTypePerLevel: 'LieuxIFCStore/groupByTypePerLevel',
            getFloorSurfaces: 'LieuxIFCStore/getFloorSurfaces',
        })
    },
    mounted:function(){
        this.startBimViewer().then((modelID)=>{
            
            this.emitStatus("generatingSpatialTree");
            this.generateSpatialTree(modelID);
        });
    },
    destroyed:function(){
        this.$store.dispatch("LieuxIFCStore/clear");
        this.viewer.dispose();
    }
}
</script>

<style lang="scss" scoped>
#ifc-viewer{
    height: 100%;
    width: 100%;
}
.ifcEntity{
        cursor: pointer;
    }
    .ifcEntity:hover{
        text-decoration: underline;
    }
  .badge-75 {
    --badge-size: 75px;
  }

  .badge-200 {
    --badge-size: 200px;
  }

  .badge {
    /* Un rond vert */
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    background-color: limegreen;
    border: calc(var(--badge-size) / 10) solid lightgray;
    /* Des initiales blanches */
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(var(--badge-size) / 1.75);
    font-weight: bold;
    /* Et centrées */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
</style>
