<template>
    <vg-files-thumbnail v-if="params.data[attributeName] && params.data[attributeName].length!=0" :file="file"
        :heightImg="'110'">
    </vg-files-thumbnail>
    <div v-else class="no-file">
        <i class="fas fa-2x fa-minus"></i>
    </div>
</template>

<script>
    import Vue from "vue";

    import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"thumbnail-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            attributeName: {
                type: String,
                default: "documentName"
            },
            attributeType: {
                type: String,
                default: "type"
            },
            attributeUid: {
                type: String,
                default: "uid"
            }
		},
        components:{
            VgFilesThumbnail
        },
        computed: {
            file: function(){
                return {
                    uploadedTo: this.params.data[this.attributeUid],
                    name: typeof(this.params.data[this.attributeName])=="string"?this.params.data[this.attributeName]:this.params.data[this.attributeName][0].name,
                    tagType: this.params.data[this.attributeType]
                };
            }
        }
    });
</script>

<style scoped>
.no-file{
    color: #e0e0e1;
}
</style>
