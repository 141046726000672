<template>
	<div class="consommable-fournisseurs">
		<span v-for="fournisseur in fournisseurs" class="fournisseur">
			{{fournisseur.name}} <i class="fas fa-times" @click="handleRemoveFournisseur(fournisseur)"></i>
		</span>
		<vg-button :type="'default-info'"
			@click="showModalFournisseurs=true">
			<i class="fas fa-plus"></i>
		</vg-button>
		<vg-modal v-if="showModalFournisseurs" :title="$t('modal-title-add-fournisseur')"
			:isSaveDisabled="newFournisseurs.length==0"
			@close="showModalFournisseurs=false;"
			@save="handleSaveNewFournisseurs">
			<template #body>
				<vg-checkbox v-for="tier in tiers"
					:inputValue="tier.id"
					:defaultChecked="false"
					@checked="handleChecked(tier)"
					@unchecked="handleUnchecked(tier)"
                    :key="tier.id"
					>
					{{tier.name}} <small>{{tier.type}}</small>
				</vg-checkbox>
			</template>
		</vg-modal>
	</div>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
	import TiersMixins from "src/mixins/TiersMixins.js";
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
	export default {
        name: 'vg-consommable-fournisseurs',
		components: {

			VgButton,
			VgCheckbox
		},
        props: {
            fournisseurs: {
				type: Array,
				default: []
			},
			consommableId: {
				type: Number|String,
				required: true
			}
        },
		mixins:[
			TiersMixins, ConsommablesMixins
		],
        data: function() {
            return {
				showModalFournisseurs: false,
				newFournisseurs: []
            };
        },
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-add-fournisseur": "Ajout fournisseur(s)",
            "fournisseur-remove-title": "Voulez-vous supprimer ce fournisseur?"
        },
        "en": {
            "modal-title-add-fournisseur": "Add supplier(s)",
            "fournisseur-remove-title": "Would you like to delete this supplier?"
        },
        "th": {
            "modal-title-add-fournisseur": "เพิ่มซัพพลายเออร์",
            "fournisseur-remove-title": "ต้องการลบซัพพลายเออร์นี้?"
        }
    }
},
		watch: {

		},
		created: function(){
            this.TiersMixins_get(new Metadatas());

		},
		mounted: function(){

		},
		methods: {
			handleRemoveFournisseur: function(fournisseur){
				if( confirm(this.$t("fournisseur-remove-title")) ){
					this.ConsommablesMixins_removeConsommableFournisseurs(this.consommableId, fournisseur).then((data)=>this.$emit("refresh-fournisseurs"));
				}
			},

			/**
			 * handleChecked Tier
			 *
			 * @param  {object} tier
			 */
			handleChecked: function(tier){
				this.newFournisseurs.push(tier);
				this.$forceUpdate();
			},

			/**
			 * handleUnchecked tiers
			 *
			 * @param  {object} data tiers
			 */
			handleUnchecked: function(data){
				if(this.newFournisseurs.findIndex((tier)=>tier.id==data.id)!=-1) this.newFournisseurs.splice(this.newFournisseurs.findIndex((tier)=>tier.id==data.id), 1);
				this.$forceUpdate();
			},
			handleSaveNewFournisseurs: function(){
				this.showModalFournisseurs = false;
				this.ConsommablesMixins_createConsommableFournisseurs(this.consommableId, this.newFournisseurs).then((datas)=>{
					this.newFournisseurs = [];
					this.$emit("refresh-fournisseurs");
				});
			}
		},
        computed: {
            ...mapGetters({
                tiers: 'TiersStore/getCollection'
            }),
		}
    };

</script>
<style lang="scss" scoped>
.consommable-fournisseurs{
	display: flex;
	justify-content: flex-start;
	align-content: center;
}
.fournisseur{
	border: 1px solid #BBBCBE;
	padding: 5px;
	margin-right: 2px;
	>i{
		cursor: pointer;
	}
}
</style>
