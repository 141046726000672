
<template>
	<div>
        <vg-button 
					:type="'danger'" 
					@click="displayModalSelectionEntiteADelete = true"
				>
					{{$t("remove-demo-datas")}}
				</vg-button>
        <vg-modal
					v-if="displayModalSelectionEntiteADelete"
					:title="$t('remove-demo-datas')"
					@close="displayModalSelectionEntiteADelete = false"
					@save="handleRemoveDemoDatas"
				>
					<template #body>
						<vg-checkbox
							    v-for="(entity, index) in entities"
								:defaultChecked="deletableEntities[entity]"
								:label="$t('supprimer-'+entity)"
								@checked="deletableEntities[entity]=true"
								@unchecked="deletableEntities[entity]=false"
								:key="index"
							>
						</vg-checkbox>

					</template>
					<template #footer>
						<vg-button @click="displayModalSelectionEntiteADelete = false">{{$t("close")}}</vg-button>
						<vg-button :type="'success'" @click="handleRemoveDemoDatas" :disabled="disableUI" >{{$t("confirmer-supression")}}</vg-button>
					</template>
				</vg-modal>
	</div>
</template>

<script>
    import VgButton from 'src/components/Vg/VgButton.vue';
    import ParametresMixins from "src/mixins/ParametresMixins.js";
    import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
    export default {
        name: 'modal-delete-demo-datas',
        i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    "remove-demo-datas": "Supprimer les données de démonstration",
                    "confirmer-supression": "confirmer suppression",
                    "supprimer-maintenances":"Supprimer fiches curatives",
                    "supprimer-equipements":"Supprimer equipements",
                    "supprimer-lieux":"Supprimer lieux",
                    "supprimer-contrats":"Supprimer contrats",
                    "supprimer-tiers":"Supprimer tiers",
                    "supprimer-contacts":"Supprimer contacts",
                    "supprimer-contrats":"Supprimer contrats",
                    "supprimer-taches":"Supprimer taches",
                    "supprimer-consommables":"Supprimer consommables",
                    "supprimer-categories":"Supprimer Catégories"
                },
                "en": {
                    "remove-demo-datas": "Delete demos datas",
                    "confirmer-supression": "Confirm deletion"
                },
                "th": {}
            }
        } ,
        props:{

        },
        mixins: [ParametresMixins],
        components: {
            VgButton,
            VgCheckbox
        },
        data: function() {
            return {
				displayModalSelectionEntiteADelete:false,
				disableUI:false,
				deletableEntities:{"maintenances":true,"equipements":true,"lieux":true,"contrats":true,"tiers":true,"contacts":true,"taches":false,"consommables":false,"categories":false}
            };
        },
        methods: {
			handleRemoveDemoDatas: function(){
				this.disableUI = true;
				this.ParametresMixins_deleteDemoAccount(this.deletableEntities).then((data) => {
					this.displayModalSelectionEntiteADelete = false;
					this.disableUI = false;
				});
			},
        },
        computed: {
            entities: function(){
                return Object.keys(this.deletableEntities);
            }
        },
		created: function(){

		}
    };
</script>
 <style lang="scss">


 </style>
