import Vue from 'vue';

import LoaderDirective from "src/directives/loader/LoaderDirective.js"
import TableLoaderDirective from "src/directives/TableLoader/TableLoaderDirective.js";
import TabLoaderDirective from "src/directives/TableLoader/TabLoaderDirective.js";
import AppLoaderDirective from "src/directives/TableLoader/AppLoaderDirective.js";
import LongpressDirective from "src/directives/LongpressDirective.js";
import ClosableDirective from "src/directives/ClosableDirective.js";
import TooltipDirective from "src/directives/TooltipDirective.js";

Vue.use(TableLoaderDirective);
Vue.use(TabLoaderDirective);
Vue.use(AppLoaderDirective);
Vue.use(LongpressDirective);
Vue.directive("closable", ClosableDirective);
Vue.directive("tooltip", TooltipDirective);

export default Vue;
