<template>
    <div>
        <small><vg-datetime-viewer v-model="params.data.dateCreation"/> <span>{{params.data.createur.nom}} {{params.data.createur.prenom}}</span></small>
        <br>
        {{$t("bon-dentree")}}
        <b class="link">{{params.data.numero}}</b>
        <br>
        <span>{{$t("fournisseur")}}: {{params.data.fournisseur.name}}</span>
        <br>
        <small>{{$t("signataire")}}: {{params.data.signataire}}</small>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "bon-dentree-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isAutomaticOpenLink:{
                type: Boolean,
                default: true
            }
		},
        components:{
            VgDatetimeViewer,
            VgButton
        },
        data: function() {
            return {

			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "bon-dentree": "Bon d'entrée",
            "fournisseur": "Fournisseur",
            "signataire": "Signataire"
        },
        "en": {}
    }
},
        methods:{

        }
    });
</script>

<style lang="scss" scoped>

</style>
