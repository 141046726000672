// Class Exceptions

export default class Exceptions{
    
    constructor(name,err){
        this.err = err;
        this.name = name;
        throw 'Exception : '+name+' *** '+err;
    }
    getError(){
        return this.err;
    }
    getExceptionName(){
        return this.name;
    }
    unexpectedTypeOfParameter(){
        throw 'unexpected type of parameters';
    }
}