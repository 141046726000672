//Interventions store module
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
getters.getPeriodiques = function(state){
    return state.collection.filter((intervention)=>!intervention.fichesav_id);
};
getters.getPonctuelles = function(state){
    return state.collection.filter((intervention)=>intervention.fichesav_id!=null);
};
let actions = Base.getActions();
/**
* @param Context context
* @param object datas = {intervention: intervention, file: file}
*/
actions.addDocument = function(context, datas){
    context.commit('ADD_DOCUMENT', datas);
};
/**
* @param Context context
* @param array interventionsEquipements
*/
actions.addInterventionsEquipements = function(context, interventionsEquipements){
    context.commit('ADD_INTERVENTIONS_EQUIPEMENTS', interventionsEquipements);
};
/**
* @param Context context
* @param object interventionEquipement = {...interventionEquipement...,equipement:{...equipement...}}
*/
actions.deleteInterventionEquipement = function(context, interventionEquipement){
    context.commit('DELETE_INTERVENTION_EQUIPEMENT', interventionEquipement);
};
/**
* @param Context context
* @param array interventionsEquipements
*/
actions.deleteInterventionsEquipements = function(context, interventionsEquipements){
    context.commit('DELETE_INTERVENTIONS_EQUIPEMENTS', interventionsEquipements);
};
let mutations = Base.getMutations();
/**
* @param State state
* @param object datas = {intervention: intervention, file: file}
*/
mutations.ADD_DOCUMENT = function(state, datas){
    let intervention = datas.intervention;
    let file = datas.file;
    let indexIntervention = state.collection.findIndex((item)=>item.id==intervention.id);
    // pas le choix que d'utiliser numeroIntervention pour maintenir le changement du store
    let numeroIntervention = state.collection[indexIntervention].numeroIntervention;
    state.collection[indexIntervention].numeroIntervention = null;
    state.collection[indexIntervention].numeroIntervention = numeroIntervention;
    if(state.collection[indexIntervention].documents) state.collection[indexIntervention].documents.push(file);
    else state.collection[indexIntervention].documents = [file];
    // modifie selectedItem
    /*if(state.selectedItem && state.selectedItem.id==intervention.id){
        state.selectedItem.numeroIntervention = null;
        state.selectedItem.numeroIntervention = numeroIntervention;
        state.selectedItem.documents = state.collection[indexIntervention].documents;
    }*/
};
/**
* @param State state
* @param array interventionsEquipements
*/
mutations.ADD_INTERVENTIONS_EQUIPEMENTS = function(state, interventionsEquipements){
    let idIntervention = null;
    let indexIntervention = null;
    interventionsEquipements.forEach((interventionEquipement)=>{
        idIntervention = interventionEquipement.intervention_id;
        indexIntervention = state.collection.findIndex((item)=>item.id==idIntervention);
        if(indexIntervention!=-1){
            if(state.collection[indexIntervention].interventionsEquipements){
                state.collection[indexIntervention].interventionsEquipements.unshift(interventionEquipement);
            }else{
                state.collection[indexIntervention].interventionsEquipements = [interventionEquipement];
            }
        }
        if(state.selectedItem && state.selectedItem.id==idIntervention && state.selectedItem.interventionsEquipements){
            state.selectedItem.interventionsEquipements.unshift(interventionEquipement);
        }else if(state.selectedItem && state.selectedItem.id==idIntervention && !state.selectedItem.interventionsEquipements){
            state.selectedItem.interventionsEquipements = [interventionEquipement];
        }
    });
};
/**
* @param State state
* @param object interventionEquipement = {...interventionEquipement...,equipement:{...equipement...}}
*/
mutations.DELETE_INTERVENTION_EQUIPEMENT = function(state, interventionEquipement){
    let idIntervention = interventionEquipement.intervention_id;
    let idInterventionEquipement = interventionEquipement.id;
    if(state.collection.length > 0){
        let indexIntervention = state.collection.findIndex((item)=>item.id==idIntervention);
        let indexInterventionEquipement = state.collection[indexIntervention].interventionsEquipements.findIndex((ie)=>ie.id==idInterventionEquipement);
        state.collection[indexIntervention].interventionsEquipements.splice(indexInterventionEquipement, 1);
        if(state.selectedItem && state.selectedItem.id==idIntervention){
            state.selectedItem.interventionsEquipements = [...state.collection[indexIntervention].interventionsEquipements];
        }
    }else{
        if(state.selectedItem && state.selectedItem.id==idIntervention){
            let indexInterventionEquipement = state.selectedItem.interventionsEquipements.findIndex((ie)=>ie.id==idInterventionEquipement);
            state.selectedItem.interventionsEquipements.splice(indexInterventionEquipement, 1);
        }
    }


};
/**
* @param State state
* @param array interventionsEquipements
*/
mutations.DELETE_INTERVENTIONS_EQUIPEMENTS = function(state, interventionsEquipements){
    let idIntervention = null;
    let idInterventionEquipement = null;
    let indexIntervention = null;
    let indexInterventionEquipement = null;
    interventionsEquipements.forEach((interventionEquipement)=>{
        idIntervention = interventionEquipement.intervention_id;
        idInterventionEquipement = interventionEquipement.id;
        indexIntervention = state.collection.findIndex((item)=>item.id==idIntervention);
        indexInterventionEquipement = state.collection[indexIntervention].interventionsEquipements.findIndex((ie)=>ie.id==idInterventionEquipement);
        state.collection[indexIntervention].interventionsEquipements.splice(indexInterventionEquipement, 1);
        if(state.selectedItem && state.selectedItem.id==idIntervention){
            state.selectedItem.interventionsEquipements = [...state.collection[indexIntervention].interventionsEquipements];
        }
    });
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
