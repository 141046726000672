<template lang="html">
	<vg-input
		:title="$t('title')" >
		<vg-select
			v-model="focusedType"
			:options="getTypes"
			:attributeValue="'value'"
			@input="handleChange" />
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue"
	import VgSelect from "src/components/Vg/VgSelect.vue"
    export default {
        name: 'vg-filter-consommable-mouvements-flag',
	    components: {
			VgInput,
			VgSelect
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			value: String
        },
		i18n:    { "locale":navigator.language,
		"messages": {
			"fr": {
				"title": "Filtrage par type de mouvement",
				"type-consommation-fm": "Consommation à partir d'une fiche curative",
				"type-livraison": "Livraison",
				"type-inventaire-moins": "Ajustement inventaire -",
				"type-inventaire-plus": "Ajustement inventaire +",
				"type-bon-de-sortie": "Bon de sortie",
				"type-bon-dentree": "Bon d'entrée",
				"type-consommation-verification": "Consommation à partir d'une vérification"
			},
			"en": {
				"title": "Filter by movement type",
				"type-consommation-fm": "Consumption from a curative sheet",
				"type-livraison": "Delivery",
				"type-inventaire-moins": "Inventory adjustment -",
				"type-inventaire-plus": "Inventory adjustment +",
				"type-bon-de-sortie": "Exit voucher",
				"type-bon-dentree": "Entry voucher",
				"type-consommation-verification": "Consumption from a verification"
		}
    }
},
        data: function(){
            return {
				focusedType: this.value || null,
				types: [
					{label: this.$t("type-consommation-fm"), value: "consommation-fm"},
					{label: this.$t("type-livraison"), value: "livraison"},
					{label: this.$t("type-inventaire-moins"), value: "inventaire-moins"},
					{label: this.$t("type-inventaire-plus"), value: "inventaire-plus"},
					{label: this.$t("type-bon-de-sortie"), value: "bon-de-sortie"},
					{label: this.$t("type-bon-dentree"), value: "bon-dentree"},
					{label: this.$t("type-consommation-verification"), value: "consommation-verification"}
				]
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedType = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			handleChange: function(e){
				this.$emit("input", this.focusedType);
			}
		},
		computed: {
			getTypes: function(){
				return this.types.sort((a, b) => a.label.localeCompare(b.label));
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
