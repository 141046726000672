<template>
	<div class="vg-equipement-viewer">
		<div class="img-equipement">
			<img v-if="equipement.icon" :src="equipement.icon"/>
	        <img v-else-if="equipement.categorie_icon" :src="equipement.categorie_icon"/>
		</div>
		<div class="info-equipement">
			<div class="name text-ellipsis">
				<span v-if="equipement.libelEquipement">{{equipement.libelEquipement}}</span>
				<span v-else-if="equipement.libel_equipement">{{equipement.libel_equipement}}</span>
			</div>
			<div class="qrcode">
				<span>{{equipement.qrCode}}</span>
			</div>
		</div>


	</div>
</template>
<script>

    export default {
        name: 'vg-equipement-viewer',
		components: {

		},
		mixins: [  ],
        props: {
            equipement:{
				type: Object
			}
        },
        data: function() {
            return {

            };
        },
        watch: {

		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		}
    };

</script>
<style lang="scss" scoped>
.vg-equipement-viewer{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.img-equipement{
		img{
			width:25px;
			height:25px;
			margin-right: 10px;
		}
	}
	.info-equipement{
		display: flex;
	    flex-direction: column;
		.name{
			font-weight: bold;
		}
		.qrcode{
			font-size: 12px;
		}
	}

}
</style>
