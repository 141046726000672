class TreeCategorieFactory{
    constructor(){

    }
    /**
     *
     * @param {array} categories
     * @returns string[]
     */
    static getDistinctTagsList(categories){
        return categories
            .map((categorie)=>!categorie.tags||categorie.tags.length==0||categorie.tags==" "?null:categorie.tags)
            .filter((t, index, self)=>self.indexOf(t)==index)
            .sort((t1,t2)=>t1&&t2?t1.localeCompare(t2):-1);
    }
    /**
     *
     * @param {array} tags
     * @returns array
     */
    static generateTreeFromTags(tags){
        let result = [];
        let level = {result};
        let pathSplitted = null;
        tags.forEach((path) => {
            if(path){
                pathSplitted = path.split("/");
                pathSplitted.reduce((r, label, i, a) => {
                    if(!r[label]){
                        r[label] = {result: []};
                        r.result.push({"location": pathSplitted.slice(0, i+1).join("/"), label, children: r[label].result});
                    }
                    return r[label];
                }, level);
            }else{
                result.push({"location":null, label: null, children:[]});
            }
        });
        return result;
    }

    /**
     *
     * @param {array} tree
     * @param {string} tag
     * @returns object node
     */
    static findNode(tree, tag){
        let found = null;
        let index = 0;
        let treeLength = tree.length;
        let node = null;
        while(index<treeLength && !found){
            node = tree[index];
            if(node.hasOwnProperty("location") && node.location == tag){
                found = node;
            }else if(node.children && node.children.length){
                found = this.findNode(node.children, tag);
            }
            index++;
        }
        return found;
    }

    static feedCategorieTree(tree, categories){
        let node = null;
        categories.forEach((categorie)=>{
            node = this.findNode(tree, (!categorie.tags||categorie.tags.length==0||categorie.tags==" ")?null:categorie.tags);
            if(node && node.children) node.children.push(categorie);
        });
        return tree;
    }

    /**
    * arrayToTree([cat1, cat2, cat3], "mobilier", "mobilier/chaise")
    * @param Array categories categories doivent etre filtrees par tags ORDER ASC
    * @return Array
    */
    static arrayToTree(categories){
        let tags = this.getDistinctTagsList(categories);
        let tree = this.generateTreeFromTags(tags);
        return this.feedCategorieTree(tree, categories);
    }
}
export default TreeCategorieFactory;
