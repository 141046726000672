<template>
	<div class="vg-tree">
		<ul class="tree-folder first-folder" v-if="getDatas && getDatas.length!=0">
			<vg-tree-node
				v-for="(item, index) in getDatas"
				v-model="getDatas[index]"
				:isFolderCallback="isFolderCallback"
				:isOpenCallback="isOpenCallback"
				:isLastFolderCallback="isLastFolderCallback"
				:store="store"
				@open="onOpen"
                :key="'root-'+index">
				<template v-slot:node="{node}">
		            <!-- @slot Use this slot v-slot:node -->
		            <slot name="node" :node="node">
						default (vg-tree)
		            </slot>
		        </template>
			</vg-tree-node>
		</ul>
	</div>
</template>
<script>
	import VgTreeNode from "src/components/Vg/Tree/VgTreeNode.vue";
	export default {
        name: 'vg-tree',
		components: {
			VgTreeNode
		},
        props: {
			store:{
				type: String,
				default: "VgTreeStore"
			},
			isFolderCallback:{
				type: Function,
				default: function(node){
					return node.type_lieu!="Piece";
				}
			},
			isOpenCallback:{
				type: Function,
				default: function(node){
					return node.type_lieu=="Organisation";
				}
			},
			isLastFolderCallback:{
				type: Function,
				default: function(node){
					return (node.type_lieu && node.type_lieu=="Etage") || false;
				}
			}
        },
		methods: {
			/**
			* @emit node-open
			*/
			onOpen: function(node){
				this.$emit("node-open", node);
			}
		},
        computed: {
			/**
			* @return Array
			*/
			getDatas: function(){
				return this.$store.getters[this.store+"/getDatas"];
			}
		}
    };

</script>
<style lang="scss">
$tree-border-color: whitesmoke;
$hover-node-bg-color: rgba(233,222,237,.4);
$node-selected-color: rgb(233,222,237);
.vg-tree{
	min-height: 200px;
	max-height: 400px;
	overflow: auto;
	border: 1px solid $tree-border-color;
	font-size: 12px !important;
	.tree-folder{
		list-style-type: none;
		margin-block-start: 0 !important;
		margin-block-end: 0 !important;
		padding-inline-start: 25px !important;
		&.last-tree-folder{
			padding: 0 !important;
		}
	}
	.first-folder{
		padding-inline-start: 15px !important;
	}
	.tree-node{
		list-style-type: none;
	}
	.tree-node:hover{
		background-color: $hover-node-bg-color;
	}
	.tree-node.selected{
		background-color: $node-selected-color;
	}
}
@media only screen and (max-width: 1200px){
	.vg-tree{
		min-height: 200px;
		max-height: 400px;
		.tree-folder{
			padding-inline-start: 15px !important;
		}
		.first-folder{
			padding-inline-start: 0px !important;
		}
	}
}
</style>
<docs>
    # VgTree
	génère une arborescence
	pour l'instant l'arborescence pointe sur VgTreeStore qui stock les datas de VgTreeLieux
	si on veut faire quelque chose de générique il faudrait passer en props storeName:{type:String,required:true}
	qui permettrait de pointer sur le store de la nouvelle arborescence
	```javascript
		...mapGetters({
			datas: this.storeName+'/getDatas',
			nodePending: this.storeName+'/getNodePending'
		})
	```
	## Appel du composant
	```javascript
	<vg-tree
		@node-open="onNodeOpen">
		<!-- ici ça sera le template custom des nodes de l'arborescence -->
		<template v-slot:node="{node}">
			<vg-tree-node-lieu
				v-model="node"
				@select="onSelectNode"
				@context-menu="onContextMenu"
				@show-plans="onShowPlans">
			</vg-tree-node-lieu>
		</template>
	</vg-tree>
	```
	### @node-open emit le node
	### pour chaque arborescence à faire il faut implémenter Vg/Tree/vg-tree-node-{name} ex: vg-tree-node-lieu
</docs>
