<template>
	<div v-if="!readOnly && taches.length" class="vg-equipement-actives-taches">
		<vg-button v-for="(tache,i) in taches" :key="i"
			type="info"
			size="lg"
			class="equipement-tache"
			@click="handleStartTache(tache)">
			<span>{{tache.libel_tache}}</span>
			<small>{{$t('nb-jours-avant-echeance', {j: joursRestants(tache)})}}</small>
		</vg-button>
	</div>
	<div v-else-if="readOnly && taches.length" class="vg-equipement-actives-taches">
		<div v-for="(tache, i) in taches" style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
			<span>{{tache.libel_tache}}</span>
			<small v-if="tache.isVerificationNeeded">{{$t('nb-jours-avant-echeance', {j: joursRestants(tache)})}}</small>
			<small v-else>{{ $t("done") }}</small>
			<vg-button size="sm" type="info" @click="onOpenDetailstache(tache)">{{ $t("voir-details-tache") }}</vg-button>
		</div>
		<vg-modal v-if="showDetailsTacheModal"
			:title="$t('details-tache-modal-title', {libel: focusedTache.libel_tache})"
			@close="showDetailsTacheModal=false;focusedTache=null;">
			<template #body>
				<div v-if="!isLoading" style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:10px;">
					<span>{{ $t("section-checkpoints") }}:</span>
					<div v-for="checkpoint in checkpoints">
						- <span>{{ checkpoint.question }}</span> <small>[{{ checkpoint.type_reponse }}] </small>
						<small v-if="checkpoint.minVal">({{ checkpoint.minVal }} - {{ checkpoint.maxVal }})</small> 
						<small v-if="checkpoint.messageOnVerifError"> - {{ checkpoint.messageOnVerifError }}</small>
					</div>
				</div>
				<span v-else>
					{{ $t("chargement-checkpoints") }}
				</span>
			</template>
			<template #footer>
				<vg-button type="default" @click="showDetailsTacheModal=false;focusedTache=null;">{{ $t("close") }}</vg-button>
			</template>
		</vg-modal>
	</div>
	<div v-else>
		{{$t("aucune-verif-preventive")}}
	</div>
</template>
<script>
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import CheckpointMixins from "src/mixins/CheckpointMixins.js";
import Metadatas from "src/services/Metadatas";

import { mapGetters } from 'vuex';
export default {
    name: 'vg-equipement-actives-taches',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
			"tache":"Préventif",
            "aucune-verif-preventive":"Aucune tâche récurrente associée",
            "nb-jours-avant-echeance":"Reste {j} jour(s)",
			"done": "Faite",
			"voir-details-tache":"Voir détails tâche",
			"details-tache-modal-title":"Détails de la tâche {libel}",
			"close": "Fermer",
			"chargement-checkpoints": "Chargement des points de vérification en cours...",
			"section-checkpoints": "Liste des points de vérification"
        },
        "en": {
			"tache":"Preventive",
            "aucune-verif-preventive":"No recurrent task associated",
            "nb-jours-avant-echeance":"{j} day(s) remaining",
			"done": "Done",
			"voir-details-tache":"Show task details",
			"details-tache-modal-title":"Details of the task {libel}",
			"close": "Close",
			"chargement-checkpoints": "Loading checkpoints...",
			"section-checkpoints": "Liste des points de vérification"
        }
    }
},
	components: {
        VgDatetimeViewer,
        VgButton,
		VgModal
	},
	mixins: [CheckpointMixins],
    props: {
        readOnly:{
            type:Boolean,
            default:function(){
                return false;
            }
        },
		taches:{
			type:Array,
			default:function(){
				return [];
			}
		},
		afterSaveRedirectTo:{
			type: String,
			default: null
		}
    },
    data: function() {
        return {
			showDetailsTacheModal: false,
			focusedTache: null,
			checkpoints: null,
			isLoading: false
        };
    },
    watch: {},
	created: function(){},
	mounted: function(){
		if(!this.readOnly && this.taches && this.taches.length==1) this.handleStartTache(this.taches[0]);
	},
    computed:{
        ...mapGetters({
			equipement: 'EquipementsStore/getSelectedItem',
        }),
    },
	methods: {
        joursRestants: function(tache){
			return moment(tache.dateprochaineVerif, "YYYY-MM-DD").diff(moment(), "days")+1;
		},
        handleStartTache:function(tache){
            this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:tache.idEquipement, tacheId: tache.idTache, afterSaveRedirectTo: this.afterSaveRedirectTo, backPageRedirectTo: this.taches && this.taches.length==1?this.afterSaveRedirectTo:null} });
        },
		onOpenDetailstache: function(tache){
			this.isLoading = true;
			this.focusedTache = tache;
			this.checkpoints = [];
			let metadatas = new Metadatas();
			metadatas.setFilters({tache_id: {attr: "chk.idTache_id", value: this.focusedTache.idTache, action: "equals"}});
			this.checkpointsMixins_getCheckpoint(metadatas).then((checkpoints)=>{
				this.checkpoints = checkpoints;
				this.isLoading = false;
			});
			this.showDetailsTacheModal = true;
		},
    }
};

</script>
<style lang="scss" scoped>
.vg-equipement-actives-taches{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
	width:100%;
	.equipement-tache{
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5px;
		padding: 10px 0;
	}
}
</style>
