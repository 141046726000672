<template>
    <div v-if="$vgutils.isMobile()" class="categorie-cell-render">
        <div class="header">
            <img v-if="params.icon" class="icon" :src="params.icon" onerror="this.style.visibility=\'hidden\';" />
            <div v-else class="icon"></div>
            <span class="title">
                {{params.libelleCatgorie}}
            </span>
            <div class="color" :style="getBackgroundColor"></div>
        </div>
        <div class="body">
            <div><span>{{$t("nombre-equipements")}}:</span> {{params.countEquipements}}</div>
            <div><span>{{$t("nombre-taches")}}:</span> {{params.countTaches}}</div>
            <div><span>{{$t("is-inventoriable")}}:</span> {{params.isInventoriable?$t("oui"):$t("non")}}</div>
            <div><vg-tag v-for="(corpsDetat, index) in params.corpsDetat" :label="corpsDetat.name" :icon="'tag.png'" :color="'red'" :key="index"/></div>
        </div>
    </div>
    <div v-else class="categorie-cell-render">
        <img v-if="params.icon" class="icon" :src="params.icon" onerror="this.style.visibility=\'hidden\';">
        <div class="categorieIcon" :style="getBackgroundColor"></div>
        <span style="font-weight:bold;">
            {{params.libelleCatgorie}}
        </span>
    </div>
</template>


<script>
import Vue from "vue";
import VgTag from "src/components/Vg/VgTag.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"categorie-cell-render",
    mixins:[LangMixins],
    components:{
        VgTag
    },
	props: {
		params: {
			type: Object,
			required: true
		},
		tooltipTitle: {
			type: String,
			default: "default tooltip title"
		},
		type: {
			type: String,
			default: "default-info"
		},
		label: {
			type: String,
			default: ""
		},
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "nombre-equipements": "Nombre d'équipements",
            "nombre-taches": "Nombre de taches",
            "is-inventoriable": "Est inventoriable",
            "oui": "Oui",
            "non": "Non"
        }
    }
},
    methods:{
        handleClick:function(){
            this.$emit("click",this.params);
        }
    },
    computed:{
        getBackgroundColor:function(){
            return "background-color:"+this.params.codeCouleur;
        }
    }
});
</script>

<style lang="scss" scoped>
.categorieIcon{
     float:left;
     margin-top:8px;
     margin-right:10px;
     background-color:white;
     width:10px;
     height:10px;
}
.icon{ /* ag-grid cell*/
    height: 22px;
    width: 22px;
}
@media screen and (max-width: 991.98px) {
    .categorie-cell-render{
        .header{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon{
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }
            .title{
                font-weight:bold;
                margin-right:10px;
            }
            .color{
                background-color:white;
                width:10px;
                height:10px;
            }
        }
        .body{
            padding-left: 35px;
            font-size: 11px;
        }
        border-bottom: 1px solid #CACACB;
        padding: 10px 0;
        height: 100%;
        width: 100%;
    }
}
</style>
