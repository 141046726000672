import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
let i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'fr', // set fallback locale
  messages:{} // set locale messages
})
var navigatorlanguage = navigator.language || navigator.userLanguage;
Vue.config.lang = navigatorlanguage.split('-')[0] || 'en';
Vue.config.fallbackLang = "fr";
window.addEventListener('setLang',function(evt){
	Vue.config.lang = evt.detail.lang;
});
Object.defineProperty(Vue.prototype, '$langs', {
    get(){
		return {panel: ["fr","en","th"], current: Vue.config.lang};
    }
});
Object.defineProperty(Vue.prototype, '$lang', {
    get(){
		return Vue.config.lang;
    }
});
export default i18n;
