<template>
    <vg-select v-if="!showCreateCategorieModal" 
        v-model="selectedCategories"
        :options="categories"
        :multiple="multiple"
        :attributeLabel="'libelleCatgorie'"
        :attributeValue="attributeValue"
        :clearable="clearable"
        :placeholder="placeholder?placeholder:$t('placeholder')"
        @input="handleInputChange"
        >
        <template v-slot="{option}">
            <div class="vg-selector-categorie-option">
                <div style="display:flex;justify-content:flex-start;align-items:center;width:100%;padding:5px 0;">
                    <div style="margin-right:10px;width:25px;">
                        <div :style="'width:20px;height:20px;border-radius:100%;background-color:'+option.codeCouleur+';'"></div>
                    </div>
                    <div style="margin-right:10px;width:25px;">
                        <img v-if="option.icon" :src="option.icon" height="20" width="20" style="margin-right:10px;" />
                    </div>
                    <span v-if="option.idAccount">C{{option.idAccount}} - </span>
                    <span>{{option.libelleCatgorie}}</span>
                </div>
                <small v-if="option.countEquipements && option.countEquipements!=0 && option.isGe==0">{{ $t("nb-equipements", {nb: option.countEquipements}) }}</small>
                <small v-else-if="option.countEquipements && option.countEquipements!=0 && option.isGe==1">{{ $t("nb-pieces", {nb: option.countEquipements}) }}</small>
                <small v-else-if="option.isGe==0" style="color:red;">{{ $t("aucun-equipement") }}</small>
                <small v-else style="color:red;">{{ $t("aucune-piece") }}</small>
            </div>
            <!--div class="categorie-container-select" >
                <div class="color-categorie">
                  <div :style="{'background-color': option.codeCouleur}" ></div>
                </div>
                <div class="icone-name">
                    <img :src="option.icon" height="20" width="20" style="margin-right:10px;">
                    <span>C{{option.idAccount}} - </span> {{option.libelleCatgorie}}
                </div>
                <div v-if="!isGe" class="img-categorie">
                  <vg-files-thumbnail :file="getFile(option)"
                      :heightImg="'20px'">
                  </vg-files-thumbnail>
                </div>
            </div-->
        </template>
        <template #list-footer v-if="showCreate">
            <vg-button type="success" size="sm" style="width: 100%;" @click="showCreateCategorieModal=true;">{{ $t("nouvelle-categorie") }}</vg-button>
        </template>
    </vg-select>
    <vg-categorie-form v-else-if="showCreateCategorieModal"
        @close="showCreateCategorieModal=false;"
        @save="onSaveNouvelleCategorie"/>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import VgFilesThumbnail from 'src/components/Vg/Files/VgFilesThumbnail.vue';
import VgCategorieForm from 'src/components/Vg/Forms/VgCategorieForm.vue';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-categorie-selector',
    components: {
        VgSelect,
        VgButton,
        VgFilesThumbnail,
        VgCategorieForm
    },
    mixins: [CategorieMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner une catégorie",
            "nouvelle-categorie": "Ajouter catégorie",
            "aucun-equipement": "Aucun équipement",
            "aucune-piece": "Aucune pièce",
            "nb-equipements": "{nb} équipement(s)",
            "nb-pieces": "{nb} pièce(s)"
        },
        "en": {
            "placeholder": "Select a category",
            "nouvelle-categorie": "Add category",
            "aucun-equipement": "No equipment",
            "aucune-piece": "No room",
            "nb-equipements": "{nb} equipment(s)",
            "nb-pieces": "{nb} room(s)"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Array,
            default: null
        },
        /**
        * Attribut de l'objet à retourner comme valeur.
        * @default id
        * Si attributeValue=null retourne l'objet sélectionné.
        */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
         * Selection de plusieurs categories.
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        /**
        * catégorie générique pour les équipements génériques
        * @default false
        */
        isGenerique: {
            type: Boolean,
            default: false
        },
        /**
        * affiche toutes les catégories equipements et lieux
        * @default false
        */
        displayAll: {
            type: Boolean,
            default: false
        },
        /**
        * Récupère uniquement catégorie lieux : c'est un switch entre catégories eqp/lieux
        * @default false
        */
        isGe: {
            type: Boolean,
            default: null
        },
        clearable: {
            type: Boolean,
            default: true
        },
        fetchIfCategorieStoreIsEmpty: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        },
        showCreate: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            selectedCategories: this.value || [],
            metadatasCategories: new Metadatas(),
            agfilters: {
                isGe:{attr:"c.isGe",value:"1",action:"not_equals"},
                isGenerique:{attr:"c.isGenerique",value:"0",action:"equals"}
            },
            showCreateCategorieModal: false
        };
    },
    watch: {
        value: {
            handler: function(newValue){
                this.selectedCategories = newValue;
            },
            deep: true
        },
        isGe:{
            handler: function(value){
                this.fetch();
            }
        }
    },
    created: function(){
        this.fetch();
    },
    methods: {
        onSaveNouvelleCategorie: function(categorie){
            this.showCreateCategorieModal = false;
            this.handleInputChange(this.attributeValue=="id"?categorie.id:categorie);
        },
        fetch: function(){
            if(!this.fetchIfCategorieStoreIsEmpty) return;
            if(this.isGenerique) this.agfilters.isGenerique.value = "1";
            if(this.isGe===true) this.agfilters.isGe = {attr:"c.isGe",value:"1",action:"equals"};
            else if(this.isGe===false) this.agfilters.isGe = {attr:"c.isGe",value:"0",action:"equals"};
            else this.agfilters.isGe = {attr: "c.isGe", value: null, action: "equals"};
            if(this.displayAll) this.agfilters.isGe = {attr:"c.isGe",value:null,action:"equals"};
            this.metadatasCategories.setFilters(this.agfilters);
            this.CategorieMixins_getCategories(this.metadatasCategories).then((datas)=>{
                console.log("CATEGORIES", datas);
            });
        },
        getFile: function(categorie){
            return {
                uploadedTo: categorie.uid,
                name: categorie.documentName
            };
        },
        handleInputChange:function(cat){
            this.$store.dispatch("CategoriesStore/setSelectedItem", cat);
            this.$emit('input',cat);
            this.$emit('change',cat);
        }
    },
    computed: {
        ...mapGetters({
            categories: 'CategoriesStore/getCollection'
        }),
    }
}
</script>

<style lang="scss">
.vg-selector-categorie-option{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 10px;
}
@media only screen and (max-width:1200px){
    .vg-selector-categorie-option{
        justify-content: flex-start !important;
    }
}
</style>
<docs>

    ### vg-users-selector


    ```js
          import VgCategoriesSelector from "src/components/Vg/Selectors/VgCategoriesSelector.vue";
    ```

    #### basic usage

    ```html static
    <vg-users-selector v-model="users" ></vg-users-selector>

    ```
    ``` avec choix multiple utilisateur
    <vg-users-selector v-model="users" multiple></vg-users-selector>
    ```
    #### Emit : input

    Retourne la valeur selectionner

    ### mixins

    CategorieMixins_getCategories

</docs>
