<template>
    <vg-modal
        :title="$t('ajout-detail')"
        @close="$emit('close')">
        <template #body>
            <div class="reponse">
                <img v-if="reponseLocal.checkpoint.composant_id && reponseLocal.checkpoint.composant" :src="reponseLocal.checkpoint.composant.icon" style="width:20px;height:20px;"/>
    			<span>{{reponseLocal.checkpoint.question}}</span>
    			<small v-if="reponseLocal.checkpoint.composant_id && reponseLocal.checkpoint.composant" class="complement">#{{reponseLocal.checkpoint.composant.typeComposant}}</small>
                <span style="color: red;">{{reponseLocal.reponse}}</span>
                <img v-if="!reponseLocal.reponse || reponseLocal.reponse=='Oui'" src="/static/assets/icone/verif-conforme.png"  style="width:20px;height:20px;"/>
                <img v-else src="/static/assets/icone/verif-non-conforme.png"  style="width:20px;height:20px;"/>
            </div>
            <br>
            <vg-input v-if="reponseLocal.composant && reponseLocal.composant.id"
                :title="$t('libelle-problem')">
                <vg-composant-libel-problem-selector v-model="problem"
                    :idComposant="reponseLocal.composant.id" />
            </vg-input>
            <vg-input :title="$t('zoomProb-descProb')" v-if="reponseLocal.reponse=='Non'"
                inputType="textarea"
                :placeholder="$t('zoomProb-descProb')"
                v-model="commentaire" />
            <vg-checkbox :label="$t('zoomProb-urgent')" v-if="reponseLocal.reponse=='Non'"
                :inputValue="reponseLocal.urgence"
                :defaultChecked="reponseLocal.urgence"
                @checked="handleCheckUrgence"
                @unchecked="handleCheckUrgence" />
            <span v-else>{{ $t("ReponseDetail--point-de-verification-conforme") }}</span>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">{{$t("cancel")}}</vg-button>
            <vg-button type="danger" @click="onValid">{{ $t("valider") }}</vg-button>
        </template>
    </vg-modal>
</template>
<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import VgComposantLibelProblemSelector from 'src/components/Vg/Selectors/VgComposantLibelProblemSelector.vue';


export default {
    name: 'reponse-detail',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "ajout-detail": "Ajouter des détails à cette non conformité",
            "libelle-problem": "Libellé problème",
            "zoomProb-descProb": "Décrire le problème",
            "ReponseDetail--selectionner-le-probleme": "Selectionner le problème",
            "zoomProb-urgent": "Urgent",
            "ReponseDetail--point-de-verification-conforme": "Point de vérification conforme",
            "valider": "Valider",
            "cancel": "Fermer"
        },
        "en": {
            "ajout-detail": "Add more description on this non compliance",
            "libelle-problem": "Problem",
            "zoomProb-descProb": "Describe the problem",
            "ReponseDetail--selectionner-le-probleme": "Select the problem",
            "zoomProb-urgent": "Urgent",
            "ReponseDetail--point-de-verification-conforme": "Compliant checkpoint",
            "valider": "Validate",
            "cancel": "Close"
        },
        "th": {
            "zoomProb-descProb": "อธิบายปัญหา",
            "ReponseDetail--selectionner-le-probleme": "เลือกปัญหา",
            "zoomProb-urgent": "เร่งด่วน",
            "ReponseDetail--point-de-verification-conforme": "จุดตรวจสอบสอดคล้อง",
            "valider": "ตรวจสอบความถูกต้อง"
        }
    }
}       ,
    mixins:[],
    components:{
        VgButton,
        VgInput,
        VgCheckbox,
        VgComposantLibelProblemSelector
    },
    props:{
        reponse: Object
    },
    data: function() {
        return {
			reponseLocal: Object.assign({}, {}, this.reponse),
            problem: null,
            commentaire: this.reponse.commentaire || ""
        };
    },
    methods: {
		onValid: function(){
			this.reponseLocal.commentaire = this.problem?this.problem+" "+this.commentaire:this.commentaire;
			this.$emit("save", this.reponseLocal);
		},
        handleCheckUrgence:function(data){
            this.reponseLocal.urgence = data.value;
        },
    }
};
</script>

<style lang="scss" scoped>
.reponse{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

</style>
