// teste push...
import Vue from 'vue';
import VueExcelEditor from 'vue-excel-editor'
console.log("hello there");
Vue.use(VueExcelEditor)
//Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.config.silent = true;
import styles from "src/style/styles.js";
//const DEBUG = process.env.DEBUG || false;
import router from './routing';
if(!parseInt(process.env.DEBUG) ){
    console.log = function(){};
    console.warn = function(){};
    console.error = function(){};
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

/*
 *
 * Registering global mixins
 *
 */
import VgFilesMixins from 'src/components/Vg/Files/VgFilesMixins.js';
import pubnubMixins from 'src/mixins/pubnubMixins.js';
import MainMixins from './mixins/mainMixins.js';
import RolesMixins from "src/mixins/RolesMixins.js";
Vue.mixin(VgFilesMixins);
Vue.mixin(RolesMixins);

import RestClient from 'src/services/RestClient.js';
import AppConfigStorage from 'src/services/AppConfigStorage.js';
import VGutils from 'src/services/VGutils.js';
import Storage from 'src/services/Storage.js';
import VGRoles from 'src/mixins/RolesMixins.js';
import store from 'src/store/store.js';
import i18n from "src/locales.js";
import Directives from "src/directives/directives.js";
import Plugins from "src/plugins/plugins.js";
var eventbus = new Vue({});

/*
 *
 * Registering global component
 *
 */
import VgMenu from 'src/components/Vg/Layout/Menu/VgMenu.vue';


var currentUri = null;
window.location.pathname != '/' ? currentUri = window.location.pathname : currentUri = null ;
//import VgModal from "src/components/Vg/VgModal.vue";
const VgModal = () => import("src/components/Vg/VgModal.vue");
const VgAppLayout = () => import("src/components/Vg/Layout/AppLayout/VgAppLayout.vue");
const VgMap = () => import("src/components/Vg/Map/VgMap.vue");

//import VgAppLayout from "src/components/Vg/Layout/AppLayout/VgAppLayout.vue";

Vue.component('vg-modal', VgModal);
Vue.component('vg-app-layout', VgAppLayout);
Vue.component('vg-map', VgMap);
Vue.component('vg-menu', VgMenu);

/**
*
* CUSTOM GLOBAL METHOD
*
*/
Object.defineProperty(Vue.prototype, '$eventbus', {
    get(){
        return this.$root.eventbus;
    }
});


//or, you can just instantiate the client on its own
import { Auth0Client } from '@auth0/auth0-spa-js';
const config = {
    domain:process.env.Auth0_DOMAIN, //"verifgood.eu.auth0.com",
    clientId: process.env.Auth0_CLIENT_ID, //"gXIFcZ0TAh21W8ytJ78rdlWs61GQIQNL",
    responseType: "token",
    authorizationParams: {
        audience: process.env.Auth0_AUDIENCE,//"https://symlab.herokuapp.com",
        scope: "openid profile email",
        redirect_uri: window.location.origin+"/auth_"
    }
};
// if sessionStorage.user.__t is null
//auth0.loginWithRedirect();

let auth0 = new Auth0Client(config);
auth0.checkSession(
    {
        daysUntilExpire: 1,
        cookieDomain: "localhost:8080"
    }
);
/*auth0.checkSession().then((authenticated) => {
    console.log({authenticated});
});*/
// wait will not auth0 to be ready

Object.defineProperty(Vue.prototype, '$auth0', {
    get(){
		return auth0;
    }
});
Object.defineProperty(Vue.prototype,'$rc',{
    get(){
        
        return new RestClient();
    }
});
Object.defineProperty(Vue.prototype,'$app',{
    get(){
        return JSON.parse(sessionStorage.getItem("user"));
    }
});
Object.defineProperty(Vue.prototype,'$storage',{
    get(){
        //return new Storage();
        let appConfigStorage = new AppConfigStorage();
        let user = JSON.parse(sessionStorage.getItem("user"));
        appConfigStorage.setStorageName(user.appID);
        return appConfigStorage;
    }
});

Object.defineProperty(Vue.prototype, '$vgutils', {
    get(){
        //return new VGutils();
		return VGutils;
    }
});

Object.defineProperty(Vue.prototype, '$vgroles', {
    get(){
        //return new VGutils();
		return VGRoles;
    }
});


/*
 *
 *
 * Root component
 *
 */
import Subscriber from 'src/services/Subscriber.js'
let app = new Vue({
    el: '#app',
    mixins: [MainMixins,pubnubMixins],
    store,
    router,
    i18n,
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr":{
                "save-success":"Sauvegarde réussie",
                "update-success":"Modification effectuée",
                "demo-is-over":"La période de démo est arrivé à terme veuillez contacter le support.",
                "feedback-error-login":"Nous allons vous rediriger vers la page de connexion..."
            },
            "en":{
                "save-success":"Successfully Saved",
                "update-success":"Update success",
                "demo-is-over":"The demo is over, please contact the support.",
                "feedback-error-login":"Redirection to login page..."
            }
        }
    },
    data: {
        currentRoute: currentUri || '_login',
        eventbus: eventbus,
        vgOrange: '#FF6927',
        vgPurple: '#6C3A9E',
        vgYellow: '#FFC73D',
        vgBlue: '#429FFF'
    },
	created: function(){
        let currentUser = JSON.parse(sessionStorage.getItem("user"));
        if(currentUser && currentUser.__t){

        }else{
            this.getTokenSilently();
        }
        //console.log("++++++++++++++++++++++",this.$app);
        this.initFirebase().then((response)=>{

        }).catch((err)=>{
            this.getTokenSilently();
        });
        this.requestPermissionNotifications();
        if(this.$app && this.$app.__subkey){
            this.pubnubMixins_connect(this.$app);
        }
        this.setFallbackLocale();
        this.setDefaultLocale();
	},
    methods:{
        getTokenSilently: function(){
            auth0.getTokenSilently().then((token) => {
                console.log("Get token");
                console.log({token})
                auth0.getUser().then((user) => {
                    sessionStorage.setItem("auth0User",JSON.stringify(user));
                    user.__t = token;
                    // sessionStorage set user.__t and user 
                    sessionStorage.setItem("user",JSON.stringify(user));
                    // si pas de user stored dans la session storage il faut repasser par l'authentification
                   
                    let userFromSessionStorage = sessionStorage.getItem("user");
                    let sessionUser = JSON.parse(userFromSessionStorage);
                    if(sessionUser && sessionUser.idUser){
                        sessionUser.__t = token;
                        sessionStorage.setItem("user",JSON.stringify(sessionUser));
                    }else{
                        // sessionStorage set user.__t and user 
                        sessionStorage.setItem("user",JSON.stringify(user));
                        // si pas de user stored dans la session storage il faut repasser par l'authentification
                        this.$router.push({name:"_authentification"});
                    }
                    //window.location = "/auth_"
                });
            }).catch((error) => {
                auth0.loginWithRedirect({
                    appState: {
                        targetPath: "/auth_",
                    }
                });
                console.error(error);
            });
        },
        initFirebase: function(){
            console.log("======================== INIT FIREBASE", this.$app, firebase);
            return new Promise((resolve, reject) => {
                if(this.$app && (!firebase || (firebase && firebase.apps.length==0))){
                    // //console.log("************** FIREBASE IS EMPTY **************");
                    this.VgFilesMixins_initializeFirebase();
                    this.VgFilesMixins_connectToFirebase().then((response)=>{
                        //console.log("************** FIREBASE CONNECTED **************");
                        resolve(response);
                    }).catch((err)=>{
                        //console.log("************** FIREBASE CONNECTION FAILED **************");
                        reject(err);
                    });
                }
            });
        },
        /**
         * @param string locale
         */
        setFallbackLocale:function(locale="fr"){
            this.$i18n.fallbackLocale = locale;
        },
        /**
         * @param string locale
         */
        setDefaultLocale:function(){
            var navigatorlanguage = navigator.language || navigator.userLanguage;
            let locale = navigatorlanguage.split('-')[0] || 'en';
            this.$i18n.locale = locale;
        },
        listenRestClient: function(){
			var that = this;
            var loader = null;
            window.addEventListener('RestClientStarting',function( data ){


            });
            window.addEventListener('RestClientSuccess',( data )=>{
                if( data.detail.method == "POST" ){
                    this.$message(this.$t("save-success"));
                }
                else if(data.detail.method == "PUT"){
                    this.$message(this.$t("update-success"));
                }
            });
            window.addEventListener('RestClientError',(data)=>{
                if(data.detail == "DEMO_IS_OVER"){
                    this.$message(this.$t("demo-is-over"),{type:"warning",timeout:10000});
                }
                if(data.detail == "INVALID_CREDENTIALS" ){
                    this.$message(this.$t("feedback-error-login"),{type:"reconnection",timeout:5000});
                }       
            });
        },
        requestPermissionNotifications: function(){
            if (!('Notification' in window)) {
              //console.log('This browser does not support desktop notification')
            }
            else if (
              Notification.permission !== 'denied' ||
              Notification.permission === 'default'
            ) {
              Notification.requestPermission((permission) => {
                  // Cela permet d'utiliser Notification.permission avec Chrome/Safari
                  if (Notification.permission !== permission) {
                    Notification.permission = permission;
                  }
              })
            }
        }
    },
    mounted:function(){
        this.$refs["first-app-loader"].remove();
        this.requestPermissionNotifications();
        this.listenRestClient();
        try {
            if(BroadcastChannel){
                new Subscriber("user_actions","main.js")
                .whereAction([
                    "open_maintenance",
                    "open_maintenance_affectation"
                ])
                .onMessageReception(function (ev) {
                    let message = ev.data.message;
                    let maintenanceId = null;
                    if(ev.data.userMetadata.action == "open_maintenance"){
                        maintenanceId = message.id;
                    }
                    else if(ev.data.userMetadata.action == "open_maintenance_affectation"){
                        maintenanceId = message.users[0].fichesavId || message.tiers[0].fichesavId;
                    }
                    window.location = "/maintenance/"+maintenanceId;
                }
                );
            }
        } catch (error) {
            console.log(error);
        }

    },
    computed: {},
});
export default app;
