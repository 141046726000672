/**
 *
 *   PubnubFilters.
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
class PubnubFilters {
    /**
     *@constructor
     */
    constructor() {}

    /**
    * Compare against a list of values.
    *
    * @param string key metadata name
    * @param string value
    */
    contains(key,value){
        return  `${key} CONTAINS '${value}'`;
    }
    /**
    * LIKE operator: asterisk wildcarding, case insensitive.
    * LIKE operator: literal match with string containing asterisk character.
    * LIKE operator: any of the three set members would be a sufficient match
    *
    * @param string key metadata name
    * @param string value
    */
    like(key,value){
        return  `${key} LIKE ${value}`;
    }

    /**
    * Exact match.
    *
    * @param string key metadata name
    * @param string value
    */
    equals(key,value){
        return  `${key} == '${value}'`;
    }
    /**
    * Exact match.
    *
    * @param string key metadata name
    * @param string value
    */
    notEquals(key,value){
        return  `${key} != '${value}'`;
    }


}
export default  PubnubFilters;
