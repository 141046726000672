<template >
        <vg-app-layout v-if="lieu"
            :backButtonPopoverText="$t('lieux.desktop.zoom-header-popoverRetour')"
            :title="pageTitle"
            :colorheader="getHeaderColor"
            :isZoomLayout="true"
            :isActionButtonDisplay="false"
            @backpage="retourPage">

            <template #create-button v-if="lieu && lieu.id">
                <vg-button v-if="can('PIECE.UPDATE')"
                    type="default-danger"
                    @click="islieuFormDisplayed=true">
                    {{$t('update')}}
                </vg-button>
                <vg-button @click="showEquipementForm=true" type="success" v-show="can('PIECE.CREATE_EQUIPEMENT')">
                    {{$t('create-equipement')}}
                </vg-button>
                <vg-button v-if="ongletActif!='consommables' && equipement && equipement.taches.length && equipement.taches[0].idTache" v-show="can('PIECE.CHECK_LIEU')"
                   type="danger"
                   @click="goToCheckLieu()">
                    {{ $t("lieux.mobile.zoom-btnAction-check-lieu") }}
                </vg-button>
                <vg-button @click="goToDemandeIntervention" type="default-info" v-show="can('PIECE.CREATE_DEMANDE_INTERVENTION')">
                    {{$t('demande-intervention')}}
                </vg-button>
            </template>

            <template #primary v-if="lieu && lieu.id && !$vgutils.isMobile()">
                <div>
                    <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=70x70&amp;data='+lieu.qrCode" />
                    <span class="vg-margin-left-10 vg-text-ellipsis">{{ lieu.qrCode }}</span>
                </div>
                <br>
                <vg-collapse :title="$t('lieux.desktop.zoom-caracteristiques')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #pretitle>
                        
                    </template>
                    <template #content>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-libelle')" >
                           <template #read>
                               <vg-lieu-path-viewer
                                   v-model="lieu.path"
                                   :onlyPiece="true"
                                   :showTooltip="false" />
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-localisation')">
                           <template #read>
                               <vg-lieu-path-viewer
                                   v-model="lieu.path" />
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-categorie')" :edit="isFocused('caracteristiques')">
                           <template #read>
                               {{ lieu.equipement_categorie_name }}
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-fonction')" :edit="isFocused('caracteristiques')">
                           <template #read>
                               {{ lieu.fonction }}
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-service')" :edit="isFocused('caracteristiques')">
                           <template #read>
                               {{ lieu.service }}
                           </template>
                       </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('lieux.desktop.zoom-gestionCalendaire')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                       <vg-libelle-editable :label="$t('lieux.desktop.zoom-dateRecensement')" >
                           <template #read>
                               <div>{{ lieu.createdAt }}</div>
                           </template>
                       </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('lieux.desktop.zoom-documentsAttaches')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #pretitle>
                        <vg-button type="default-round"
                            @click="isUploaderVisible=!isUploaderVisible;">
                            <i class="fas fa-plus"></i>
                        </vg-button>
                    </template>
                    <template #content>
                        <div class="composants__documents-attaches inherit">
                            <vg-files-uploader v-if="isUploaderVisible"
                                :tagsList="VgFilesMixins.tags"
                                @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;" />
                            <vg-files-viewer-list-section
                                :files="VgFilesMixins_filesListSection"
                                @delete="VgFilesMixins_fetchFilesByUid()" />
                        </div>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('lieux.desktop.zoom-attributs')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-surface')" >
                            <template #read>
                                <div>NC</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-revetementSol')" >
                            <template #read>
                                <div>NC</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-revetementPlafond')" >
                            <template #read>
                                <div>NC</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-revetementsLateraux')" >
                            <template #read>
                                <div>NC</div>
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('lieux.desktop.zoom-attributs')"
                    :collapseByDefault="false"
                    :type="'section'" >
                    <!--template #pretitle>
                        <vg-button v-if="isFocused(null)"
                            type="default-round"
                            @click="focus('Recensement')">
                            <i class="fas fa-edit"></i>
                        </vg-button>
                        <div>
                            <vg-button type="default-round"
                                 v-if="isFocused('Recensement')">
                                <i  class="fas fa-save"></i>
                            </vg-button>
                            <vg-button type="default-round"
                                @click="focus(null)" v-if="isFocused('Recensement')">
                                <i  class="fas fa-times"></i>
                            </vg-button>
                        </div>
                    </template-->
                    <template #content>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-statutRecensement')" :edit="isFocused('Recensement')">
                            <template #read>
                                <div>{{ lieu.statutRecensement }}</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-photosPanoramiques')" >
                            <template #read>
                                <div class="text-ellipsis">{{ lieu.nbPhotoPanoramique }}</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-equipements')" >
                            <template #read>
                                <div class="text-ellipsis">{{ lieu.nbEquipement }}</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-Markers')" >
                            <template #read>
                                <div class="text-ellipsis">{{ lieu.totalNbMarker }}</div>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-lien-album-google')" :edit="isFocused('Recensement')">
                            <template #read>
                                <a v-if="lieu.googlePhotosAlbumLocation && lieu.googlePhotosAlbumLocation!='' "
                                    :href="lieu.googlePhotosAlbumLocation"
                                    target="_blank">
                                    {{lieu.googlePhotosAlbumLocation}}
                                </a>
                                <span v-else>NC</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-prerecencement')" :edit="isFocused('Recensement')">
                            <template #read>
                                <vg-checkbox v-model="lieu.preRecensementRequired" :checked="lieu.preRecensementRequired == '1' " isDisabled />
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-affectationRecensement')" :edit="isFocused('Recensement')">
                            <template #read>
                                {{lieu.affectationRecensement}}
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-datePrevuRecensement')" :edit="isFocused('Recensement')">
                            <template #read>
                                <vg-datetime-viewer v-model="lieu.dateRecensementPrevu" />
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('lieux.desktop.zoom-commentaireRecensement')" :edit="isFocused('Recensement')">
                            <template #read>
                                {{lieu.commentaireRecensement}}
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>
            </template>

            <template #secondary v-if="lieu && lieu.id && !$vgutils.isMobile()">
                <vg-collapse :title="$t('lieux.desktop.zoom-historiques')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                        <vg-equipement-historique
                            v-model="getEquipementObject" />
                    </template>
                </vg-collapse>
                <br>
                <vg-collapse :title="$t('lieux.desktop.zoom-photos')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                        <vg-files-viewer-photo
                            :files="VgFilesMixins_filesOnlyPhoto" />
                    </template>
                </vg-collapse>
                <br>
                <vg-collapse :title="$t('lieux.desktop.zoom-equipements')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content >
                        <vg-tabs :tabs="tabs" :color="'violet'" @tabClicked="onTabClicked" />
                        <vg-equipements-table v-show="showEquipementOrComposant=='equipement'"
                            id="show-lieu-equipements"
                            :filters="equipementsFilters"
                            :showPrint="false"
                            paginate
                            @row-click="handleClickRow" />
                        <vg-composants-table v-show="showEquipementOrComposant=='composant'"
                            :idCategorie="lieu.equipement_categorie_id" />
                    </template>
                </vg-collapse>
                <br>
                <vg-collapse :title="$t('lieux.desktop.Desktop-section-consommables')"
                    :collapseByDefault="false"
                    :type="'section'" >
                    <template #titlecomplement >
                        <vg-button :type="'default-info'" @click="showModalCreateConsommable=true" style="margin-right:10px;">
                            {{ $t("lieux.desktop.Desktop-section-consommables-nouveau") }}
                        </vg-button>
                        <vg-button :type="'default-info'" @click="openAttacherConsommablesModal">
                            {{ $t("lieux.desktop.Desktop-section-consommables-attacher") }}
                        </vg-button>
                    </template>
                    <template #content >
                        <vg-consommables-viewer v-model="consommablesEquipement" />
                    </template>
                </vg-collapse>
            </template>

            <template #body v-if="$vgutils.isMobile()">
                <div v-if="!isHistoriqueVisible" style="height:inherit;">
                    <vg-tabs :tabs="onglets" :color="'violet'" @tabClicked="gererClickOnglet" style="margin-bottom:10px;" />
                    <!-- onglet caracteristiques -->
                    <div id="caracteristiques-tab" v-show="ongletActif == 'caracteristique'">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-libelle')">
                           <template #read>
                               {{lieu.libel_lieu}}
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-service')">
                           <template #read>
                               <span>{{lieu.service}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-fonction')">
                           <template #read>
                               <span>{{lieu.fonction}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-emplacement')">
                           <template #read>
                               <vg-lieu-path-viewer
                                  v-model="lieu.path"
                                  onlyPath
                                  :showTooltip="false" />
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-surface')">
                           <template #read>
                                <span></span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <br>
                        <div v-if="isFilesViewerVisible" class="row vg-margin-right-0 vg-margin-bottom-20 vg-margin-left-0">
                            <vg-files-viewer-photo :files="VgFilesMixins_filesOnlyPhoto" />
                        </div>
                    </div>
                    <!-- onglet equipements -->
                    <vg-equipements-table v-show="ongletActif == 'equipements'"
                        v-if="lieu && lieu.id"
                        :filters="equipementsFilters"
                        :showPrint="false"
                        paginate
                        @row-click="handleClickRow" />
                    <!-- onglet recensement -->
                    <div v-show="ongletActif == 'recensement'">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-photosPanoramiques')">
                           <template #read>
                               <span>{{lieu.nbPhotoPanoramique}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-equipements')">
                           <template #read>
                               <span>{{lieu.nbEquipement}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-datePrevue')">
                           <template #read>
                               <span>{{lieu.dateRecensementPrevu}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                        <vg-libelle-editable :label="$t('lieux.mobile.zoom-commentaire')">
                           <template #read>
                               <span>{{lieu.commentaireRecensement}}</span>
                           </template>
                        </vg-libelle-editable>
                        <hr class="no-margin">
                    </div>
                    <!-- onglet documents -->
                    <div v-show="ongletActif == 'documentsAttaches'">
                        <vg-button size="small" @click="isUploaderVisible?isUploaderVisible=false:isUploaderVisible=true;">
                            <i :class="{'el-icon-arrow-down':!isUploaderVisible, 'el-icon-arrow-up':isUploaderVisible}"></i> {{ $t("lieux.mobile.zoom-documentsAttaches") }}
                        </vg-button>
                        <vg-files-uploader v-if="isUploaderVisible"
                            :tagsList="VgFilesMixins.tags"
                            @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                        </vg-files-uploader>
                        <vg-files-viewer-list-section
                            :files="VgFilesMixins_filesListSection"
                            @delete="VgFilesMixins_fetchFilesByUid()">
                        </vg-files-viewer-list-section>
                    </div>
                    <!-- onglet consommables -->
                    <div v-show="ongletActif == 'consommables'">
                        <vg-consommables-viewer
                            v-model="consommablesEquipement"
                            @refresh-consommables="handleRefreshConsommables" />
                    </div>
                </div>
                <vg-equipement-historique v-else
                    v-model="getEquipementObject"
                    :activate="historiqueMode" />
            </template>

            <template #footer v-if="$vgutils.isMobile()">
                <vg-button 
                   type="default-link"
                   size="sm"
                   v-if="ongletActif!='consommables'"
                   @click="goToAddEquipement()">
                    {{ $t("lieux.mobile.zoom-btnAction-recenser") }}
                </vg-button>
                <vg-button v-if="can('PIECE.UPDATE') && ongletActif!='consommables'"
                   type="default-link"
                   size="sm"
                   @click="islieuFormDisplayed=true;">
                    {{ $t("lieux.mobile.zoom-btnAction-update") }}
                </vg-button>
                <vg-button
                   type="default-link"
                   size="sm"
                   v-if="ongletActif!='consommables' && equipement && equipement.taches.length && equipement.taches[0].idTache"
                   @click="goToCheckLieu()">
                    {{ $t("lieux.mobile.zoom-btnAction-check-lieu") }}
                </vg-button>
                <vg-files-uploader
                    style="margin-right:10px;"
                    :isOnlyPhoto="true"
                    v-if="ongletActif!='consommables'"
                    @upload-done="VgFilesMixins_fetchFilesByUid()">
                </vg-files-uploader>
                <vg-button
                   type="default-link"
                   size="sm"
                   v-if="ongletActif=='consommables'"
                   @click="showModalCreateConsommable=true">
                    {{ $t("lieux.mobile.zoom-btnAction-consommables-nouveau") }}
                </vg-button>
                <vg-button
                   type="default-link"
                   size="sm"
                   v-if="ongletActif=='consommables'"
                  @click="openAttacherConsommablesModal">
                    {{ $t("lieux.mobile.zoom-btnAction-consommables-attacher") }}
                </vg-button>
            </template>

            <template #panel-menu-more>
                <div class="vg-margin-bottom-20" @click="showHistorique('verifications')">{{ $t("lieux.mobile.menuMore-histoVerif") }}</div>
                <div @click="showHistorique('maintenances')">{{ $t("lieux.mobile.menuMore-histoMaint") }}</div>
            </template>

            <vg-consommable-form v-if="showModalCreateConsommable"
                v-model="ConsommablesMixins.newConsommable"
                :isSaveUnable="ConsommablesMixins_isSaveDisabled"
                @close="showModalCreateConsommable=false;"
                @save="showModalCreateConsommable=false;" />

            <vg-consommables-equipement-modal v-if="showModalGererConsommables"
                v-model="consommables"
                :idEquipement="lieu.equipement_id"
                @close="showModalGererConsommables=false;"
                @refresh-consommables="handleRefreshConsommables" />

            <vg-lieu-form v-if="islieuFormDisplayed"
                v-model="lieu"
                @close="islieuFormDisplayed=false;"
                @updated="onLieuUpdated" />

            <vg-equipement-form v-if="showEquipementForm"
                :idLieu="lieu.id"
                :reloadAfterSave="false"
                skipVueXStorage
                @close="showEquipementForm=false"
                @save="onSaveCreateEquipement" />
            
        </vg-app-layout>
</template>
<script>

	import LieuMixins from 'src/mixins/LieuMixins.js';
    import EquipementsMixins from "src/mixins/equipementsMixins.js";
    import ComposantMixins from 'src/mixins/ComposantMixins.js';
    import CategorieMixins from "src/mixins/CategorieMixins.js";
    import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";

    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgTabs from 'src/components/Vg/VgTabs.vue';
	import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';
    import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
    import VgButton from 'src/components/Vg/VgButton.vue';


    import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

	import VgEquipementHistorique from "src/components/Vg/Equipements/VgEquipementHistorique.vue";
    import RecensementEquipement from "src/views/Equipement/RecensementEquipement.vue";
    import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
    import VgComposantsTable from "src/components/Vg/Composant/VgComposantsTable.vue";

    import VgConsommablesViewer from "src/components/Vg/Consommables/VgConsommablesViewer.vue";
	import VgConsommableForm from 'src/components/Vg/Forms/VgConsommableForm.vue';
	import VgConsommablesEquipementModal from 'src/components/Vg/Consommables/VgConsommablesEquipementModal.vue';
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgEquipementDonneesFinanciere from "src/components/Vg/Equipements/VgEquipementDonneesFinanciere.vue";

    import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";

    import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue"
    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

    export default {
        name: 'lieu',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "update": "Modifier",
            "scan-requis": "Le scan du qrCode est requis",
            "create-equipement": "Créer équipement(s)",
            "demande-intervention": "Demande intervention",
            "lieux": {
                "desktop": {
                    "zoom-header-popoverRetour": "Retour vers le tableau des pièces",
                    "zoom-header-titre": "Détail de la pièce :",
                    "modifier": "Modifier",
                    "enregistrer": "Enregistrer",
                    "annuler": "Annuler",
                    "zoom-caracteristiques": "Caractéristiques",
                    "zoom-libelle": "Libelle",
                    "zoom-localisation": "Localisation",
                    "zoom-recensement": "Recensement",
                    "zoom-documents": "Document",
                    "zoom-categorie": "Catégorie",
                    "zoom-fonction": "Fonction",
                    "zoom-service": "Service",
                    "zoom-gestionCalendaire": "Gestion calendaire",
                    "zoom-dateRecensement": "Date du recensement",
                    "zoom-popover-attacherDocument": "Attacher un document à la pièce",
                    "zoom-documentsAttaches": "Documents attachés",
                    "zoom-attributs": "Attributs",
                    "zoom-surface": "Surface",
                    "zoom-revetementSol": "Revêtement de sol",
                    "zoom-revetementPlafond": "Revêtement de plafond",
                    "zoom-revetementsLateraux": "Revêtements latéraux",
                    "zoom-lien-album-google": "Lien album Google ",
                    "zoom-historiques": "Historique préventif et curatif",
                    "zoom-chronologie": "Chronologie",
                    "zoom-photos": "Photos",
                    "zoom-aucunePhotoLieu": "Aucune photo du lieu",
                    "zoom-contenuDans": "Contenu dans",
                    "zoom-statut-select-option--prerecensement-en-cours": "Pré-recensement en cours",
                    "zoom-statut-select-option--prerecensement-termine": "Pré-recensement terminé",
                    "zoom-statut-select-option--recensement-en-cours": "Recensement en cours",
                    "zoom-statut-select-option--recensement-termine": "Recensement terminé",
                    "zoom-statutRecensement": "Statut recensement",
                    "zoom-photosPanoramiques": "Photos panoramiques",
                    "zoom-equipements": "Equipements ",
                    "zoom-Markers": "Markers",
                    "zoom-prerecencement": "A prérecenser",
                    "zoom-affectationRecensement": "Affectation R ",
                    "zoom-datePrevuRecensement": "Date prévu R",
                    "zoom-commentaireRecensement": "Commentaire R",
                    "Desktop-section-consommables": "Consommables / pièces détachées",
                    "Desktop-section-consommables-nouveau": "Nouveau consommable",
                    "Desktop-section-consommables-attacher": "Attacher consommables"
                },
                "mobile": {
                    "zoom-libelle": "Libellé",
                    "zoom-service": "Service",
                    "zoom-fonction": "Fonction",
                    "zoom-emplacement": "Emplacement",
                    "zoom-surface": "Surface",
                    "zoom-statut": "Statut",
                    "zoom-photosPanoramiques": "Photos panoramiques",
                    "zoom-labels": "Labels",
                    "menuMore-histoMaint": "Historique curatif",
                    "menuMore-histoVerif": "Historique préventif",
                    "zoom-recensement": "Recensement",
                    "zoom-equipements": "Equipements",
                    "zoom-composants": "Composants",
                    "zoom-datePrevue": "Date prévue",
                    "zoom-commentaire": "Commentaire",
                    "zoom-documentsAttaches": "Documents attachés",
                    "zoom-consommables": "Consommables",
                    "zoom-caracteristiques": "Caractéristiques",
                    "zoom-btnAction-recenser": "Recenser équipement",
                    "zoom-btnAction-update": "Modifier",
                    "zoom-btnAction-photo": "Photo pièce",
                    "zoom-btnAction-voirPanoramique": "Voir panoramique",
                    "zoom-btnAction-consommables-nouveau": "Nouveau consommable",
                    "zoom-btnAction-consommables-attacher": "Attacher consommables",
                    "zoom-btnAction-check-lieu": "Check lieu"
                }
            },
            "feedback": {
                "sauvegarde_donnees": "Sauvegarde des données ..."
            }
        },
        "en": {
            "update": "Update",
            "scan-requis": "qrCode scann is required",
            "create-equipement": "Create equipment",
            "demande-intervention": "Intervention request",
            "lieux": {
                "desktop": {
                    "zoom-header-popoverRetour": "Back to the table of rooms",
                    "zoom-header-titre": "Detail of the room :",
                    "modifier": "Update",
                    "enregistrer": "Save",
                    "annuler": "Cancel",
                    "zoom-caracteristiques": "Characteristics",
                    "zoom-libelle": "Wording",
                    "zoom-localisation": "Location",
                    "zoom-categorie": "Category",
                    "zoom-fonction": "Function",
                    "zoom-service": "Service",
                    "zoom-gestionCalendaire": "Progress management",
                    "zoom-dateRecensement": "Date of inventory",
                    "zoom-popover-attacherDocument": "Attach a document to the room",
                    "zoom-documentsAttaches": "Documents attached",
                    "zoom-attributs": "Attributes",
                    "zoom-surface": "Area",
                    "zoom-revetementSol": "Floor covering",
                    "zoom-revetementPlafond": "Ceiling covering",
                    "zoom-revetementsLateraux": "Lateral coverings",
                    "zoom-lien-album-google": "Link to Google album",
                    "zoom-historiques": "Preventive and curative logs",
                    "zoom-chronologie": "Timeline",
                    "zoom-photos": "Photos",
                    "zoom-aucunePhotoLieu": "No photos",
                    "zoom-contenuDans": "Within",
                    "zoom-statut-select-option--prerecensement-en-cours": "Pre-census in progress",
                    "zoom-statut-select-option--prerecensement-termine": "Pre-census done",
                    "zoom-statut-select-option--recensement-en-cours": "Census in progress",
                    "zoom-statut-select-option--recensement-termine": "Census done",
                    "zoom-recensement": "Census",
                    "zoom-documents": "Document",
                    "Desktop-section-consommables": "Consumables / spare parts",
                    "Desktop-section-consommables-nouveau": "New consumables",
                    "Desktop-section-consommables-attacher": "Attach consumables",
                    "zoom-statutRecensement": "Inventory status",
                    "zoom-photosPanoramiques": "Panoramic photos",
                    "zoom-equipements": "Equipments ",
                    "zoom-Markers": "Markers",
                    "zoom-prerecencement": "To pre-register",
                    "zoom-affectationRecensement": "Assigned inventory",
                    "zoom-datePrevuRecensement": "Scheluded inventory",
                    "zoom-commentaireRecensement": "Inventory feedback"
                },
                "mobile": {
                    "zoom-libelle": "Label",
                    "zoom-service": "Service",
                    "zoom-fonction": "Fonction",
                    "zoom-emplacement": "Location",
                    "zoom-surface": "Surface",
                    "zoom-statut": "Status",
                    "zoom-photosPanoramiques": "Panoramic photos",
                    "zoom-labels": "Labels",
                    "zoom-equipements": "Equipments",
                    "zoom-datePrevue": "Planned date",
                    "zoom-commentaire": "Comment",
                    "zoom-documentsAttaches": "Attached documents",
                    "zoom-caracteristiques": "Characteristics",
                    "zoom-btnAction-recenser": "Inventory equipment",
                    "zoom-btnAction-update": "Update",
                    "zoom-btnAction-photo": "Take picture of the place",
                    "zoom-btnAction-voirPanoramique": "Panoramic view",
                    "zoom-recensement": "Census",
                    "zoom-composants": "Components",
                    "zoom-consommables": "Consumables",
                    "zoom-btnAction-consommables-nouveau": "New consumables",
                    "zoom-btnAction-consommables-attacher": "Attach consumables",
                    "zoom-btnAction-check-lieu": "Location check",
                    "menuMore-histoMaint": "Curative logs",
                    "menuMore-histoVerif": "Preventive logs"
                }
            },
            "feedback": {
                "sauvegarde_donnees": "Saving data ..."
            }
        }
    }
},
		mixins:[
			LieuMixins,
            EquipementsMixins,
            CategorieMixins,
            ConsommablesMixins,
            ComposantMixins
		],
		components:{
            VgFilesViewerListSection,
            VgFilesViewerPhoto,
            VgFilesUploader,
            VgTabs,
            VgCheckbox,
            VgEquipementsTable,
            VgComposantsTable,
            VgDatetimeViewer,
			RecensementEquipement,
            VgConsommablesViewer,
            VgConsommableForm,
            VgConsommablesEquipementModal,
            VgButton,
            VgEquipementHistorique,
            VgLieuPathViewer,
            VgCollapse,
            VgLibelleEditable,
            VgLieuForm,
            VgEquipementDonneesFinanciere,
            VgEquipementForm
		},
        props: {},
        data: function() {
            return {
				focusField: null,
				isUploaderVisible: false,
                isHistoriqueVisible: false,
                historiqueMode:'',
                showModal:{
                    recenseEquipement:false
                },
                ongletActif: "caracteristique",
				categories: [],
                showEquipementOrComposant:"equipement",
                consommables: [],
                consommablesEquipement: [],
                showModalCreateConsommable: false,
                showModalGererConsommables: false,
                islieuFormDisplayed:false,
                showEquipementForm: false
            };
        },
        methods: {
            onLieuUpdated: function(lieu){
                this.islieuFormDisplayed = false;
                window.location.reload();
            },
            onSaveCreateEquipement: function(datas){
                console.log("CREATED", datas);
                this.showEquipementForm = false;
                this.fetchEquipements();
            },
            fetchEquipements: function(){
                let metadatas = new Metadatas();
                metadatas.setFilters(this.equipementsFilters);
                this.equipementsMixins_getEquipements(metadatas).then((datas)=>{
					
				});
            },
            fetchConsommables: function(){
                this.ConsommablesMixins_getConsommablesForEquipement(this.lieu.equipement_id).then((consommables)=>this.consommables=consommables);
			},
			fetchConsommablesEquipement: function(){
				this.ConsommablesMixins_getEquipementConsommables(this.lieu.equipement_id).then((consommables)=>this.consommablesEquipement=consommables);
			},
            openAttacherConsommablesModal: function(){
                this.fetchConsommables();
                this.showModalGererConsommables = true;
			},
            handleRefreshConsommables: function(){
                this.fetchConsommables();
                this.fetchConsommablesEquipement();
                if(this.showModalCreateConsommable) this.showModalCreateConsommable = false;
            },
            handleSaveCreateConsommable: function(){
            	this.ConsommablesMixins_saveCreateConsommable(this.consommables, this.lieu.equipement_id).then(()=>{
            		this.handleRefreshConsommables();
            	});
            },
            goToAddEquipement:function(){
                //var key = 'recensement/equipement';
                this.$store.dispatch('appStateSetLastLieuxSelected',this.lieu);
                this.$store.dispatch('appStateEquipementsInLastPlaceVisited',this.equipements);
                // TODO categorieTargeted doit être nourri depuis les tags documents
                //this.lieu.categorieTargeted = 688;
                document.title = 'recensement equipement';
                //this.$router.push('/'+key);
                // '/lieu/'+this.lieu.idLieu+'/categorie/'+this.lieu.categorieTargeted+'/recensement'
                if(this.lieu.categorieTargeted){
                    this.$router.push({ name: '_recensement_equipement_lieu_categorie', params: { idLieu: this.lieu.id, idCategorie: this.lieu.categorieTargeted}});
                }else this.$router.push({ name: '_recensement_equipement_lieu', params: { idLieu: this.lieu.id }});
            },
            goToCheckLieu: function(){
                if(this.equipement.taches.length > 1){
                    this.$router.push({name:"_process_verification_taches_selection", params:{type:"lieux", equipementId:this.equipement.id} });
                }else if(this.equipement.taches.length == 1){
                    let tache = this.equipement.taches[0];
                    if(parseInt(tache.isScanRequired)){
                        alert(this.$t('scan-requis'));
                    }else{
                        this.$router.push({name:"_process_verification", params:{type:"lieux", equipementId:this.equipement.id, tacheId: tache.idTache} });
                    }
                }
            },
            goToDemandeIntervention: function(){
                this.$router.push({name: "_demande_intervention", params: {code: this.equipement.qrCode , isScann: true}});
            },
            retourPage: function(){
                this.$router.go(-1);
			},
			isFocused: function(choice){
				return this.focusField == choice;
			},
			focus: function(choice){
				this.focusField = choice;
			},
            gererClickOnglet: function (event) {
                this.ongletActif = event.name;
                this.VgFilesMixins_init(this.lieu.uid, "lieu");
            },
            onTabClicked: function (tabsEvent){
                // //console.log(tabsEvent);
                this.showEquipementOrComposant = tabsEvent.name;
            },
            showHistorique:function(mode){
                this.historiqueMode = mode;
                this.isHistoriqueVisible = true;
            },
            fetchEquipement: function(){
                this.equipementsMixins_getEquipement(this.lieu.equipement_id);
            }
        },
        computed: {
            ...mapGetters({
                lieu: 'LieuxStore/getSelectedItem',
                equipements: 'EquipementsStore/getCollection',
                equipementsCounters: 'EquipementsStore/getCounters',
                equipement: 'EquipementsStore/getSelectedItem',
                composantsCounters: 'ComposantsStore/getCounters'
            }),
            pageTitle:function(){
                return this.$t('lieux.desktop.zoom-header-titre')+" "+this.lieu.libel_lieu;
            },
            equipementsFilters:function(){
                return{
                    isGEP:{attr:"e.isGroupEqp",value:"1",action:"not_equals"},
                    idLieu:{attr:"e.idLieu_id",value:this.lieu.id,action:"equals"}
                };
            },
            isFilesViewerVisible: function(){
                return this.ongletActif=="caracteristique";
            },
			tabs: function(){
				return [
					{
						label: this.$t('lieux.mobile.zoom-equipements'),
						name:"equipement",
                        counter: this.equipementsCounters?this.equipementsCounters.filtered:null
					},
					{
						label: this.$t('lieux.mobile.zoom-composants'),
						name:"composant",
                        counter: this.composantsCounters?this.composantsCounters.filtered:null
					}
				];
			},
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? 'rgb(108, 58, 158)' : 'white';
            },
            /**
            * GetEquipementObject.
            * Le set de données pour lieu/id nest pas le meme quequipement/id
            * or tableau historique equipement attend les données sous la forme suivante
            * je creer un nouvel objet en partant de ce lieu.
            *
            * @return object
            */
            getEquipementObject:function(){
                return {
                    id:this.lieu.equipement_id,
                    categorie:{
                        id:this.lieu.equipement_categorie_id
                    }
                }
            },
            onglets: function(){
                return [
                    {label: this.$t('lieux.mobile.zoom-caracteristiques'), name: "caracteristique"},
                    {label: this.$t('lieux.mobile.zoom-equipements'), name: "equipements", counter: this.equipementsCounters?this.equipementsCounters.filtered:null},
                    {label: this.$t('lieux.mobile.zoom-recensement'), name: "recensement"},
                    {label: this.$t('lieux.mobile.zoom-documentsAttaches'), name: "documentsAttaches"},
                    {label: this.$t('lieux.mobile.zoom-consommables'), name: "consommables"}
                ];
            }
        },
		created: function(){
            this.LieuMixins_getLieu(this.$route.params.id).then(()=>{
                this.VgFilesMixins_init(this.lieu.uid, "lieu");
                this.fetchEquipement();
                this.fetchConsommablesEquipement();
            });
		},
        destroyed:function(){
            this.$store.dispatch("EquipementsStore/clear");
            this.$store.dispatch("LieuxStore/setSelectedItem", null);
        }
    };

</script>

<style lang="scss" >

</style>
