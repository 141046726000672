<template lang="html">
    <div class="consommable-details-cellrender" @click="$emit('click', params)">
        <div class="consommable-details">
            <span class="title">{{consommable.name}}</span>
            <small>{{$t("ref")}} {{consommable.refExterne}}</small>
        </div>
        <div class="consommable-details">
            <small>{{$t("conso-marque")}}: {{consommable.marque}}</small>
            <small>{{$t("conso-num-serie")}}: {{consommable.numSerie}}</small>
        </div>
        <div class="consommable-details">
            <small>{{$t("conso-code")}}: {{consommable.code}}</small>
        </div>
        <div class="consommable-details">
            <small>{{$t("conso-commentaire")}}: {{consommable.commentaire}}</small>
        </div>
        <div class="consommable-stock">
            <span>{{$t("quantite")}}: {{consommable.stock.quantite}} {{consommable.unite?consommable.unite:"u."}}</span>
            <span>{{$t("cout-unitaire")}}: {{consommable.coutUnitaire}} {{$app.devise}}</span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    mixins:[LangMixins],
    name: "consommable-details-cell-render",
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "conso-ref": "Réf.",
            "conso-marque": "Marque",
            "conso-num-serie": "N° série",
            "conso-commentaire": "Commentaire",
            "conso-code": "Code",
            "ref": "Réf.",
            "quantite": "Quantité en stock",
            "cout-unitaire": "Coût unitaire"
        }
    }
},
    data: function(){
        return {
            consommable: this.params.data
        };
    },
    components:{

    },
    computed:{

    },
    created: function(){

    }
})
</script>

<style lang="scss" scoped>
.consommable-details-cellrender{
    .consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommable-stock{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
</style>
