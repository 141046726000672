<template>
    <vg-app-layout :title="getTitle"
        :isLoading="!this.mouvementEquipements"
        @save-filters="saveCurrentFilters" 
        :colorheader="getHeaderColor" 
        :isZoomLayout="true" 
        :filtersTitle="$t('modal-filters-title')" 
        @global-filters-change="onGlobalFiltersChange"
        @backpage="$router.go(-1)"
    >
        <template v-slot:action-button-item></template>
        <template v-slot:search></template>
        <template #create-button>
            <vg-button type="success" @click="goToCreateRetourForm">
                {{$t("create-mouvement-de-retour")}}
            </vg-button>
        </template>
        <template #panel-menu-more>
            <vg-button type="success" @click="goToCreateRetourForm">
                {{$t("create-mouvement-de-retour")}}
            </vg-button>
        </template>
        <template v-if="this.mouvementEquipements" v-slot:primary>
            <div>
                <vg-collapse 
                    :title="$t('details-mouvement')" 
                    :collapseByDefault="false" 
                    :type="'section'"
                >
                    <template v-slot:pretitle></template>
                    <template v-slot:content>
                        <vg-libelle-editable :label="$t('date-mouvement')">
                            <template v-slot:read>
                                <span>
                                    {{mouvementEquipements.dateMouvement}}
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable v-if="mouvementEquipements.type == 'pret'" :label="$t('date-retour-prevue')">
                            <template v-slot:read>
                                <span>
                                    {{mouvementEquipements.dateRetourPrevu}}
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('signataire-donneur')">
                            <template v-slot:read>
                                <span>
                                    {{mouvementEquipements.signataireDonneur}}
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('signataire-receveur')">
                            <template v-slot:read>
                                <span>
                                    {{mouvementEquipements.signataireReceveur}}
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('commentaire')">
                            <template v-slot:read>
                                <span>
                                    {{mouvementEquipements.commentaire}}
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable v-if="mouvementEquipements.destination.path" :label="$t('destination')">
                            <template v-slot:read>
                                <vg-lieu-path-viewer v-model="mouvementEquipements.destination.path" />
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>
                <vg-collapse 
                    :title="$t('documents')" 
                    :collapseByDefault="false" 
                    :type="'section'"
                >
                    <template v-slot:pretitle></template>
                    <template v-slot:content>
                        <vg-files-viewer-list-section 
                            :files="VgFilesMixins_filesListSection" 
                            @delete="VgFilesMixins_fetchFilesByUid()"
                        >
                        </vg-files-viewer-list-section>
                    </template>
                </vg-collapse>
                <hr>
                <div v-if="$vgutils.isMobile()">
                    <h3>{{ $t("equipements") }}</h3>
                    <ul>
                        <li v-for="equipement in mouvementEquipements.equipements"> 
                            {{ equipement.libel_equipement }} {{ equipement.qrCode }} 
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #secondary v-if="this.mouvementEquipements && !$vgutils.isMobile()">
            <vg-collapse :title="$t('equipements')"
                :collapseByDefault="false" 
                :type="'section'">
                <template #content>
                    <ul>
                        <li v-for="equipement in mouvementEquipements.equipements"> {{ equipement.libel_equipement }}
                            <a :href="'equipement/'+equipement.id">{{ $t("voir") }}</a>
                        </li>
                    </ul>
                </template>
            </vg-collapse>
        </template>
        <template #secondary-widget></template>
        <!--dialog-secured-action
         v-if="isModalConfirmDeleteOpened"
         :valid-response="getSelectedMaintenances.length"
         @save="handleSupprimerMaintenance"
         @close="isModalConfirmDeleteOpened = false"
       ><template v-slot:header-title>
           {{ $t("user-form-confirm-suppression") }}
         </template></dialog-secured-action-->
    </vg-app-layout>
</template>

<script>



import Metadatas from "src/services/Metadatas.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import MouvementsEquipementsMixins from "src/mixins/MouvementsEquipementsMixins.js";
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import VgLieuPathViewer from 'src/components/Vg/Lieu/VgLieuPathViewer.vue';
import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";

import { mapGetters } from 'vuex';

export default {
  name: 'mouvement-equipements',
  i18n:    { "locale":navigator.language,
  "messages": {
      "fr": {
          "create-mouvement-sortie":"Sortir des équipements temporairement",
          "create-mouvement-retour":"Retour équipement",
          "create-mouvement-de-retour":"Créer un retour équipement",
          "pret":"Sortie temporaire équipement(s)",
          "retour":"Retour équipement(s)",
          "destination":"Lieu de destination",
            "signataire-donneur":"Qui transmet le matériel",
            "signataire-receveur":"Qui reçoit le matériel",
            "date-mouvement":"Date du mouvement",
            "date-retour-prevue":"Date de retour prévue",
            "details-mouvement":"Détails du mouvement",
            "commentaire":"Commentaire",
            "equipements":"Equipements",
            "documents": "Documents"
      },
      "en": {
          "title": "Exit voucher"
      }
  }
},
  mixins: [MouvementsEquipementsMixins],
  components: {
      VgButton,
      VgLibelleEditable,
      VgFilesViewerListSection,
      VgCollapse,
      VgLieuPathViewer
  },
  props:{},
  data: function() {
      return {
          mouvement:{}
      };
  },
  watch: {

  },
  computed: {
    ...mapGetters({
            mouvementEquipements: 'MouvementsEquipementsStore/getSelectedItem'
        }),
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        getTitle: function(){
            return this.mouvementEquipements ? this.$t(this.mouvementEquipements.type) : "-";
        }
  },
  methods: {
    getDocument: function(mouvement){
        let folder = mouvement.type == "pret" ? "bon-sortie-equipement" : "bon-de-retour-equipement";
        this.VgFilesMixins_init(mouvement.uid, folder);
    },
    goToCreateRetourForm: function(){
        this.$router.push({ name: '_mouvements_equipements_create_type', params: { type: "retour"} })
    }
  },
  created:function(){
        this.MouvementsEquipementsMixins_getMouvementsEquipementsId(this.$route.params.id).then((mouvement)=>{
             this.getDocument(mouvement);
        });
  },
  destroyed:function(){
    this.$store.dispatch("MouvementsEquipementsStore/deleteSelectedItem");
  }
};
</script>
<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height:400px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
