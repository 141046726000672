<template>
	<div class="parametres-maintenance" v-if="!loading">
		<span class="title">{{ $t("personnalisation-di-header") }}</span>
        <vg-tabs
            :tabs="tabs"
            :color="'jaune'"
            @tabClicked="handleTabClick">
        </vg-tabs>
        <div v-if="active=='maintenance'" style="display: flex;flex-direction: column;width: 100%;">
            <br>
            <div v-if="!isUpdatable">
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isFmClosedBySuperviseur)" isDisabled>{{ $t("personnalisation-di-parametres-maintenance-supervisor") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.handleFmBloquante)" isDisabled>{{ $t("personnalisation-di-parametres-maintenance-handle-fm-bloquante") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isFmRetourAFaire)" isDisabled>{{ $t("personnalisation-di-parametres-maintenance-retour-a-faire") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isRapportClotureCuratifRequired)" isDisabled>{{ $t("personnalisation-di-parametres-maintenance-rapport-cloture-requis") }}</vg-checkbox>
            </div>
            <div v-else>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isFmClosedBySuperviseur)"
                    @checked="parameters.isFmClosedBySuperviseur=true;"
                    @unchecked="parameters.isFmClosedBySuperviseur=false;">{{ $t("personnalisation-di-parametres-maintenance-supervisor") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.handleFmBloquante)"
                    @checked="parameters.handleFmBloquante=true;"
                    @unchecked="parameters.handleFmBloquante=false;">{{ $t("personnalisation-di-parametres-maintenance-handle-fm-bloquante") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isFmRetourAFaire)"
                    @checked="parameters.isFmRetourAFaire=true;"
                    @unchecked="parameters.isFmRetourAFaire=false;">{{ $t("personnalisation-di-parametres-maintenance-retour-a-faire") }}</vg-checkbox>
                <vg-checkbox :defaultChecked="getDefaultCheck(parameters.isRapportClotureCuratifRequired)"
                    @checked="parameters.isRapportClotureCuratifRequired=true;"
                    @unchecked="parameters.isRapportClotureCuratifRequired=false;">{{ $t("personnalisation-di-parametres-maintenance-rapport-cloture-requis") }}</vg-checkbox>
            </div>
            <div v-if="isUpdatable">
                <vg-button @click="isUpdatable=false" size="sm" type="default-danger" >{{ $t("parametres-crud-annuler") }}</vg-button>
                <vg-button type="danger" size="sm" @click="updateParametresMaintenance">{{ $t("parametres-crud-valider") }}</vg-button>
            </div>
            <div>
                <vg-button
                    size="sm"
                    type="danger"
                    @click="isUpdatable=true"
                    v-show="!isUpdatable"
                    v-if="isSessionAdmin">
                    <i class="fas fa-edit"></i>&nbsp{{$t("update")}}
                </vg-button>
            </div>
            <br>
            <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:10px;">
                <vg-working-time-selector v-if="isSessionAdmin"
                    :label="$t('fm-workingTime')"
                    v-model="defaultWorkingTime"
                    style="width:50%;">
                </vg-working-time-selector>
                <span v-else>{{defaultWorkingTime}}</span>
                <div>
                    <vg-button v-if="defaultWorkingTime!=parameters.defaultWorkingTime" 
                        size="sm" 
                        type="danger" 
                        @click="handleSaveDefaultWorkingTime">
                        {{$t("save")}}
                    </vg-button>
                </div>
            </div>
            <br>
            <vg-collapse type="section" :collapseByDefault="false" :title="$t('title-sectionFinanciere')">
                <template #content>
                    <vg-input v-model="tauxHoraire"
                        :title="$t('fm-tauxHoraire')"
                        :inputType="'number'"
                        :min="0" style="width:50%;">
                    </vg-input>
                    <vg-button v-if="tauxHoraire!=parameters.tauxHoraire && tauxHoraire!=''" 
                        type="danger" 
                        size="sm"
                        @click="handleSaveTauxHoraire">
                        {{$t("save")}}
                    </vg-button>
                </template>
            </vg-collapse>
        </div>
        <div v-else-if="active=='di'">
            <h6 >{{ $t("personnalisation-di-parametres-generaux-message-presentation") }}</h6>
            <hr>
            <div style="display:flex;justify-content: space-between;width: 700px;">
                <div style="flex-direction:column">
                    <div v-if="!isUpdatable">
                        <vg-checkbox :defaultChecked="parameters.isDiMultipleLieu" isDisabled>{{ $t("di-parametre-multiple-lieu") }}</vg-checkbox>
                        <!--vg-checkbox :defaultChecked="parameters.isGestionEquipement" isDisabled>{{ $t("personnalisation-di-parametres-generaux-gestion-equipements") }}</vg-checkbox>
                        <vg-checkbox :defaultChecked="parameters.isAutoConvertDItoFM" isDisabled>{{ $t("personnalisation-di-parametres-generaux-conversion") }}</vg-checkbox-->
                        <vg-checkbox :defaultChecked="parameters.isUserTypeAsDemandeur" isDisabled>{{ $t("personnalisation-di-parametres-generaux-identification") }}</vg-checkbox>
                        <vg-checkbox :defaultChecked="parameters.isAutoMailingDemandeur" isDisabled>{{ $t("personnalisation-di-parametres-generaux-mail-auto") }}</vg-checkbox>
                        <!--vg-checkbox :defaultChecked="parameters.isDiGroupByPath" isDisabled>{{ $t("personnalisation-di-parametres-generaux-presentation-arborescence-lieux") }}</vg-checkbox-->
                    </div>
                    <div v-else>
                        <vg-checkbox :defaultChecked="parameters.isDiMultipleLieu"
							@checked="parameters.isDiMultipleLieu=true;"
							@unchecked="parameters.isDiMultipleLieu=false;">{{ $t("di-parametre-multiple-lieu") }}</vg-checkbox>
                        <!--vg-checkbox :defaultChecked="parameters.isGestionEquipement" isDisabled>{{ $t("personnalisation-di-parametres-generaux-gestion-equipements") }}</vg-checkbox>
                        <vg-checkbox :defaultChecked="parameters.isAutoConvertDItoFM" isDisabled>{{ $t("personnalisation-di-parametres-generaux-conversion") }}</vg-checkbox-->
                        <vg-checkbox :defaultChecked="parameters.isUserTypeAsDemandeur"
							@checked="parameters.isUserTypeAsDemandeur=true;"
							@unchecked="parameters.isUserTypeAsDemandeur=false;">{{ $t("personnalisation-di-parametres-generaux-identification") }}</vg-checkbox>
                        <vg-checkbox :defaultChecked="parameters.isAutoMailingDemandeur"
							@checked="parameters.isAutoMailingDemandeur=true;"
							@unchecked="parameters.isAutoMailingDemandeur=false;">{{ $t("personnalisation-di-parametres-generaux-mail-auto") }}</vg-checkbox>
                        <!--vg-checkbox :defaultChecked="parameters.isDiGroupByPath"
							@checked="parameters.isDiGroupByPath=true;"
							@unchecked="parameters.isDiGroupByPath=false;">{{ $t("personnalisation-di-parametres-generaux-presentation-arborescence-lieux") }}</vg-checkbox-->
                    </div>
                </div>
                <div v-if="isUpdatable">
                    <vg-button @click="isUpdatable=false" type="default-danger" size="sm">{{ $t("parametres-crud-annuler") }}</vg-button>
                    <vg-button type="danger" size="sm" @click="updateParametresDI">{{ $t("parametres-crud-valider") }}</vg-button>
                </div>

                <vg-button type="danger" size="sm" @click="isUpdatable=true" v-show="!isUpdatable" v-if="isSessionAdmin"><i class="fas fa-edit"></i>&nbsp{{$t("update")}}</vg-button>

            </div>
        </div>
        <div v-else-if="active=='problemConfiguration'">
            <h6 >{{ $t("personnalisation-di-configuration-problemes-message-presentation") }}</h6>
            <hr>
            <crudlibelle   :isSessionAdmin="isSessionAdmin"
                @crudlibelle-deletelibelle="handleDeleteLibelle" >
            </crudlibelle>
        </div>
        <div v-else-if="active=='typologiesMaintenance'">
            <div>
                <h6>{{ $t("typologies-maintenance") }}</h6>
                <hr>
                <vg-typologies-maintenance-form></vg-typologies-maintenance-form>
            </div>
        </div>

	</div>
</template>

<script>

	import crudlibelle from './CRUDLibelle.vue';
	import ParametresMixins from "src/mixins/ParametresMixins.js";

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgWorkingTimeSelector from "src/components/Vg/FicheSAV/VgWorkingTimeSelector.vue";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
    import VgCollapse from 'src/components/Vg/VgCollapse.vue';
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";
    import VgTypologiesMaintenanceForm from 'src/components/Vg/Forms/VgTypologiesMaintenanceForm.vue';
    export default {
        name: 'parametres-maintenance',
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "save": "Enregistrer",
            "update": "Modifier",
            "work-orders-settings": "Paramètres demandes interventions",
            "general-settings": "Paramètres généraux",
            "title-workingTime": "Durée intervention fiche curative",
            "fm-workingTime": "Durée intervention par défaut (en quart d'heure)",
            "title-sectionFinanciere": "Section financière",
            "fm-tauxHoraire": "Taux horaire moyen",
            "personnalisation-di-header": "Paramètres fiches curatives",
            "personnalisation-di-parametres-maintenance-onglet": "Paramètres fiches curatives",
            "personnalisation-di-parametres-maintenance-supervisor": "Clôture superviseur",
            "personnalisation-di-parametres-maintenance-retour-a-faire": "Retour demandeur",
            "personnalisation-di-parametres-maintenance-rapport-cloture-requis": "Rapport de clôture requis",
            "typologies-maintenance-tab": "Configuration des typologies fiches curatives",
            "typologies-maintenance": "Liste de typologies fiches curatives",
            "parametres-crud-annuler": "Annuler",
            "parametres-crud-valider": "Valider",
            "personnalisation-di-parametres-di-onglet": "Paramètres demande intervention",
            "personnalisation-di-parametres-generaux-message-presentation": "Vous pouvez ici, modifier les paramètres généraux liés à demande d'intervention",
            "personnalisation-di-parametres-generaux-gestion-equipements": "Gérer uniquement les équipements",
            "personnalisation-di-parametres-generaux-conversion": "Convertir automatique les demandes d'intervention en fiches curatives",
            "personnalisation-di-parametres-generaux-identification": "Le demandeur est identifié par le nom du compte",
            "personnalisation-di-parametres-generaux-mail-auto": "Envoyer automatiquement au demandeur les e-mails liés au traitement de la demande d'intervention",
            "personnalisation-di-parametres-generaux-presentation-arborescence-lieux": "Arborescence des lieux : site / bâtiment / étage",
            "personnalisation-di-configuration-problemes-onglet": "Configuration des problèmes",
            "personnalisation-di-configuration-problemes-message-presentation": "Vous pouvez ici, gérer les différents problèmes à afficher lors de création de la demande d'intervention",
            "personnalisation-di-parametres-maintenance-handle-fm-bloquante": "Activer usage statut fiche curative \"bloquant\"",
            "di-parametre-multiple-lieu": "Demande intervention sur plusieurs lieux"
        },
        "en": {
            "personnalisation-di-header": "Curative sheets settings ",
            "personnalisation-di-parametres-maintenance-onglet": "Work order settings",
            "personnalisation-di-parametres-maintenance-supervisor": "Closed by supervisor",
            "personnalisation-di-parametres-maintenance-retour-a-faire": "Buyer feedback",
            "personnalisation-di-parametres-maintenance-rapport-cloture-requis": "Closing report required",
            "parametres-crud-annuler": "Cancel",
            "parametres-crud-valider": "Save",
            "personnalisation-di-parametres-di-onglet": "Intervention request settings",
            "personnalisation-di-parametres-generaux-message-presentation": "Modify the general settings for an intervention request here",
            "personnalisation-di-parametres-generaux-gestion-equipements": "Manage equipment only",
            "personnalisation-di-parametres-generaux-conversion": "Automatically convert intervention request to curative sheet",
            "personnalisation-di-parametres-generaux-identification": "The requester is identified by account name",
            "personnalisation-di-parametres-generaux-mail-auto": "Automatically send the e-mails related to the processing of the intervention request to the requester",
            "personnalisation-di-parametres-generaux-presentation-arborescence-lieux": "Site tree: site / building / floor",
            "personnalisation-di-configuration-problemes-onglet": "Problem configuration",
            "personnalisation-di-configuration-problemes-message-presentation": "Manage the various problems to display during creation of the intervention request here",
            "personnalisation-di-parametres-maintenance-handle-fm-bloquante": "Activate curative sheet status \"blocking\"",
            "save": "Save",
            "typologies-maintenance-tab": "Configuration of curative sheet typologies",
            "maintenance-typologies": "List of curative sheet typologies",
            "title-workingTime": "Curative sheet time",
            "fm-workingTime": "Default Working Time (to the quarter of an hour)",
            "title-sectionFinanciere": "Financial Section",
            "fm-tauxHoraire": "Average hourly rate",
            "di-parametre-multiple-lieu": "Request multi-site response"
        }
    }
},
        props:{

        },
        components: {
			crudlibelle,
			VgButton,
			VgWorkingTimeSelector,
			VgInput,
            VgTabs,
            VgCheckbox,
            VgCollapse,
			VgCredits,
            VgTypologiesMaintenanceForm
        },
		mixins:[ ParametresMixins ],
        data: function() {
            return {
                loading: true,
                parameters: null,
				defaultWorkingTime: null,
				tauxHoraire: null,
				userId: this.$app.appID,
				idUser: this.$app.idUser,
				active: 'maintenance',
				isUpdatable: false
            };
        },
        methods: {
            getDefaultCheck: function(value){
                return value===1 || value==="1" || value===true;
            },
            isTrue: function(value){
                return value===1 || value==="1" || value===true;
            },
            handleTabClick: function(event) {
                this.active = event.name;
            },
			handleSaveDefaultWorkingTime: function(){
				// //console.log("SAVE DEFAULT WORKING TIME", this.defaultWorkingTime);
				this.ParametresMixins_update({
					id: this.parameters.id,
					defaultWorkingTime: this.defaultWorkingTime,
					userId: this.$app.appID
				}).then((data) => {
					this.parameters.defaultWorkingTime = this.defaultWorkingTime;
					this.saveInSessionStorage({
						defaultWorkingTime: this.defaultWorkingTime
					});
				});
			},
			handleSaveTauxHoraire: function(){
				// //console.log("SAVE DEFAULT TAUX HORAIRE", this.tauxHoraire);
				this.ParametresMixins_update({
					id: this.parameters.id,
					tauxHoraire: this.tauxHoraire,
					userId: this.$app.appID
				}).then((data) => {
					this.parameters.tauxHoraire = this.tauxHoraire;
					this.saveInSessionStorage({
						tauxHoraire: this.tauxHoraire
					});
				});
			},
			saveInSessionStorage: function(sessionValues){
				window.sessionStorage.setItem("user", JSON.stringify(
					Object.assign(this.$app, sessionValues)
				) );
			},
			updateParametresMaintenance: function(){
				this.loading = true;
                this.isUpdatable = false;
                this.ParametresMixins_update({
                    isFmClosedBySuperviseur: this.parameters.isFmClosedBySuperviseur,
                    handleFmBloquante: this.parameters.handleFmBloquante,
                    isFmRetourAFaire: this.parameters.isFmRetourAFaire,
                    isRapportClotureCuratifRequired: this.parameters.isRapportClotureCuratifRequired
                    // @TODO here à lister les params qui sont pris en charge lors de l'update
                }).then((datas)=>{
                    console.log("AFTER UPDATE PARAMETER", datas);
                    this.saveInSessionStorage({
						isFmClosedBySuperviseur: this.isTrue(datas.isFmClosedBySuperviseur),
                        handleFmBloquante: this.isTrue(datas.handleFmBloquante),
                        isFmRetourAFaire: this.isTrue(datas.isFmRetourAFaire),
                        isRapportClotureCuratifRequired: this.isTrue(datas.isRapportClotureCuratifRequired)
					});
                    this.loading = false;
                });
			},
            updateParametresDI: function(){
				this.loading = true;
                this.isUpdatable = false;
                this.ParametresMixins_update({
                    isDiMultipleLieu: this.parameters.isDiMultipleLieu,
                    /*isGestionBatiToo: !this.parameters.isGestionEquipement,
                    isAutoConvertDItoFM: this.parameters.isAutoConvertDItoFM,*/
                    isUserTypeAsDemandeur: this.parameters.isUserTypeAsDemandeur,
                    isAutoMailingDemandeur: this.parameters.isAutoMailingDemandeur,
                    //isDiGroupByPath: this.parameters.isDiGroupByPath
                }).then((datas)=>{
                    console.log("AFTER UPDATE PARAMETER", datas);
                    this.saveInSessionStorage({
						isDiMultipleLieu: this.isTrue(datas.isDiMultipleLieu),
						/*isGestionBatiToo: !this.isTrue(datas.isGestionEquipement),
                        isAutoConvertDItoFM: this.isTrue(datas.isAutoConvertDItoFM),*/
                        isUserTypeAsDemandeur: this.isTrue(datas.isUserTypeAsDemandeur),
                        isAutoMailingDemandeur: this.isTrue(datas.isAutoMailingDemandeur),
                        //isDiGroupByPath: this.isTrue(datas.isDiGroupByPath)
					});
                    this.loading = false;
                });
			},
			isSessionAdmin: function(){
				return this.$app.role == "ROLE_ADMIN";
			}
        },
        computed: {
            tabs: function(){
					return [
					{label: this.$t("personnalisation-di-parametres-maintenance-onglet"), name:"maintenance"},
					{label: this.$t("personnalisation-di-parametres-di-onglet"), name:"di"},
					{label: this.$t("personnalisation-di-configuration-problemes-onglet"), name:"problemConfiguration"},
					{label: this.$t("typologies-maintenance-tab"), name:"typologiesMaintenance"}
				];
			},
        },
        mounted: function(){

        },
		created: function(){
			this.ParametresMixins_get().then((datas)=>{ 
				//console.log("PARAMETRES MAINTENANCE", datas);
				this.parameters = datas;
				this.defaultWorkingTime = this.parameters.defaultWorkingTime;
				//this.parameters.handleFmBloquante = parseInt(this.parameters.handleFmBloquante);
				this.tauxHoraire = this.parameters.tauxHoraire;
				//this.parameters.isGestionEquipement = !this.parameters.isGestionBatiToo;
				this.loading = false;
			});
		}
    };
</script>
<style lang="scss" scoped>
.parametres-maintenance{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
}

</style>
