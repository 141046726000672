<template>
    <vg-app-layout
        :title="$t('title')"
        :icon="'parametre.png'"
        :colorheader="getHeaderColor"
        @action-export="handleActionExport">
        <template #create-button>
            <vg-button
                type="success"
                @click="showModalDemandeConsommables=true;">
                {{$t("btn-new-demande-consommables")}}
            </vg-button>
        </template>

        <template #panel-menu-more>
            <vg-button
                type="success"
                @click="showModalDemandeConsommables=true;">
                {{$t("btn-new-demande-consommables")}}
            </vg-button>
        </template>

        <template #search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>

        <template #primary>
            <vg-tabs v-model="focusedTab"
                :tabs="tabs"
                :color="'jaune'"
                @tabClicked="onTabClicked"/>
            <vg-fiche-demande-consommables-table
                :filters="ficheDemandeConsommablesFilters"
                paginate
                :limit="25"
                @row-dbl-click="onRowDblClick"/>
        </template>

        <template #secondary-filters>
            <vg-filter-service v-model="ficheDemandeConsommablesFilters.serviceDemandeur.value" 
                showAll/>
            <vg-filter-site v-model="ficheDemandeConsommablesFilters.site_id.value"
                :attributeValue="'id'" />
        </template>
        <template #panel-filters-attributs>
            <vg-filter-service v-model="ficheDemandeConsommablesFilters.serviceDemandeur.value" 
                showAll/>
            <vg-filter-site v-model="ficheDemandeConsommablesFilters.site_id.value"
                :attributeValue="'id'" />
        </template>

        <vg-demande-consommables-form v-if="showModalDemandeConsommables"
            displayAsAModal
            @close="showModalDemandeConsommables=false;"
            @save="showModalDemandeConsommables=false;" />

    </vg-app-layout>
</template>

<script>
import FicheDemandeConsommablesMixins from "src/mixins/FicheDemandeConsommablesMixins.js";
import FiltersMixins from 'src/mixins/FiltersMixins';

import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgFicheDemandeConsommablesTable from 'src/components/Vg/FicheDemandeConsommables/VgFicheDemandeConsommablesTable.vue';
import VgDemandeConsommablesForm from 'src/components/Vg/Forms/VgDemandeConsommablesForm.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

import VgFilterDateIntervalle from 'src/components/Vg/Filters/VgFilterDateIntervalle.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';

import VgButton from "src/components/Vg/VgButton.vue";
import VgModal from "src/components/Vg/VgModal.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters, mapActions } from "vuex";
export default {
	name:"fiche-demande-consommables",
	components:{
		VgTabs,
        VgButton,
        VgModal,
        VgFicheDemandeConsommablesTable,
        VgTextFilter,
        VgDemandeConsommablesForm,
        VgFilterDateIntervalle,
        VgFilterService,
        VgFilterSite
	},
	mixins:[
        FicheDemandeConsommablesMixins,
        FiltersMixins
	],
	data: function () {
		return {
			showModalDemandeConsommables: false,
            ficheDemandeConsommablesFilters: {
                statut: {attr: "fdc.statut", value: "en-cours", action: "equals"},
                dateCreation: {attr: "fdc.dateCreation", value: null, action: "between"},
                serviceDemandeur: {attr: "fdc.serviceDemandeur", value: null, action: "equals"},
                site_id: {attr: "fdc.id", value: null, action: "equals"}
            },
            focusedTab: "en-cours",
            searchQuery: ""
		};
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Fiches demandes consommables",
            "btn-new-demande-consommables": "Créer demande consommables",
            "tab-en-cours": "En cours",
            "tab-resolues": "Résolues",
            "tab-all": "Toutes",
            "tab-prise-en-compte": "Prise en compte",
            "tab-en-attente": "En attente"
        },
        "en": {
            "title": "Consumables request list",
            "btn-new-demande-consommables": "Create demand",
            "tab-en-cours": "Ongoing",
            "tab-resolues": "Solved",
            "tab-all": "All",
            "tab-prise-en-compte": "Taking into account",
            "tab-en-attente": "Pending"
        }
    }
},
	watch: {
        searchQuery: {
            handler: function(newquery){

            }
        }
	},
	created: function () {
        this.FiltersMixins_page_name = "fiche-demande-consommables";
        if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
	},
	mounted: function () {

	},
	methods:{
        onRowDblClick: function(row){
            console.log("onRowDblClick", row);
            this.$router.push({ name: "_fichedemandeconsommables_id", params: {id: row.data.id, consommable: row.data}});
        },
        onTabClicked: function(tab){
            this.ficheDemandeConsommablesFilters.statut.value = tab.value;
            this.FiltersMixins_saveDefaultTab(tab.name);
        },
        handleActionExport:function(e){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.ficheDemandeConsommablesFilters);
            this.FicheDemandeConsommablesMixins_export(metadatas, 'demandes-consommables' );
            switch (e.name) {
                /*case "askForXLS":
                    this.ConsommablesMixins_getFile(metadatas,"excel").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.ConsommablesMixins_getFile(metadatas,"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"fiche-demande-consommables-tableau"}
                    }));
                    break;*/
                default:
                    break;
            }
        },
        getTabCounter: function(filter){
            if(this.ficheDemandeConsommablesFilters.statut.value==filter) return this.counters.filtered+" / "+this.counters[filter];
            else if(this.ficheDemandeConsommablesFilters.statut.value==null && filter=="all") return this.counters.filtered+" / "+this.counters[filter];
            else return this.counters[filter];
        }
	},
	computed:{
        ...mapGetters({
            counters: "FicheDemandeConsommablesStore/getCounters"
        }),
        isGestionMagasin: function(){
            let user = window.sessionStorage.getItem("user");
            user = JSON.parse(user);
            return user.isGestionMagasin;
        },
		tabs: function(){
            return [
    			{label: this.$t("tab-en-cours"), name:"en-cours", value: "en-cours", counter: this.getTabCounter("en-cours")},
    			{label: this.$t("tab-prise-en-compte"), name:"prise-en-compte", value: "prise-en-compte", counter: this.getTabCounter("prise-en-compte")},
    			{label: this.$t("tab-en-attente"), name:"en-attente", value: "en-attente", counter: this.getTabCounter("en-attente")},
    			{label: this.$t("tab-resolues"), name:"resolue", value: "resolue", counter: this.getTabCounter("resolue")},
    			{label: this.$t("tab-all"), name:"all", value: null, counter: this.getTabCounter("all")}
    		];
		},
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
