<template>
    <vg-modal :title="$t('update-consommables-title')"
        @close="close"
        @save="handleSaveUpdateConsommables">
        <template #body>
            <form id="update-consommables-form"
                ref="update-consommables-form"
                onSubmit="return false;">
                <vg-select
                    v-model="selectedField"
                    :options="fields"
                    :attributeLabel="'label'"
                    :attributeValue="'value'"
                    :clearable="false" />
                <br>
                <vg-consommable-tags-datalist v-if="selectedField=='nature'" v-model="nature"
                    :label="$t('nature')"
                    :placeholder="$t('nature')"/>
                <vg-input v-else-if="selectedField=='services'" :title="$t('services')">
    				<vg-service-selector v-model="services" multiple showAll/>
    			</vg-input>
                <vg-input v-else-if="selectedField=='fournisseurDefault_id'" :title="$t('fournisseurDefault_id')">
                    <vg-tiers-selector v-model="fournisseurDefault_id"/>
                </vg-input>
                <vg-input v-else-if="selectedField=='tva_id'" :title="$t('tva_id')">
                    <vg-tva-selector v-model="tva_id"/>
                </vg-input>
                <vg-input v-else-if="selectedField=='statut'" :title="$t('statut')">
                    <vg-tags-selector v-model="statut"
    					:type="'consommable/statut'"
    					:attributeValue="'label'" />
                </vg-input>
                <input
                  v-show="false"
                  ref="consommable-submit"
                  type="submit">
            </form>
        </template>
    </vg-modal>
</template>
<script>

import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgTvaSelector from "src/components/Vg/Selectors/VgTvaSelector.vue";
import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
import VgTagsSelector from "src/components/Vg/Selectors/VgTagsSelector.vue";
import VgConsommableTagsDatalist from 'src/components/Vg/Datalists/VgConsommableTagsDatalist.vue';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-update-consommables-form',
    components:{
        VgSelect,
        VgInput,
        VgServiceSelector,
        VgTvaSelector,
        VgTiersSelector,
        VgTagsSelector,
        VgConsommableTagsDatalist
    },
    mixins: [ConsommablesMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "update-consommables-title": "Modifier les consommables sélectionnés",
            "fournisseurDefault_id": "Fournisseur par défaut",
            "tva_id": "TVA applicable",
            "services": "Services",
            "nature": "Etiquette consommable/pièce",
            "statut": "Statut"
        },
        "en": {
            "update-equipments-title": "Update selected equipements",
            "fournisseurDefault_id": "Default supplier",
            "tva_id": "Applicable VAT",
            "services": "Services",
            "nature": "Spare part label/part",
            "statut": "Status"
        }
    }
},
    props: {},
    data: function() {
        return {
            fields:[
                {label:this.$t("fournisseurDefault_id"),value:"fournisseurDefault_id"},
                {label:this.$t("tva_id"),value:"tva_id"},
                {label:this.$t("services"),value:"services"},
                {label:this.$t("nature"),value:"nature"},
                {label:this.$t("statut"),value:"statut"}
            ],
            selectedField:null,
            nature: null,
            services: [],
            fournisseurDefault_id: null,
            tva_id: null,
            statut: null
        };
    },
    watch: {},
    created: function(){},
    mounted: function(){},
    computed:{
        ...mapGetters({
            selectedConsommables: "ConsommablesStore/getSelectedItems"
        }),
        selectedFieldTranslated:function(){
            return this.$t(this.selectedField);
        }
    },
    methods: {
        close:function(){
            this.$emit("close");
        },
        handleSaveUpdateConsommables:function(){
            let consommablesToUpdate = [];
            let consommableToUpdate = null;
            this.selectedConsommables.forEach((consommable, index)=>{
                consommableToUpdate = {
                    id: consommable.id,
                    userId: consommable.userId
                };
                consommableToUpdate[this.selectedField] = this[this.selectedField];
                if(this.selectedField=="services" && this[this.selectedField]) consommableToUpdate[this.selectedField] = this[this.selectedField].join(";");
                consommablesToUpdate.push(consommableToUpdate);
            });
            this.ConsommablesMixins_updateConsommables(consommablesToUpdate).then((datas)=>{
                this.$store.dispatch("EquipementsStore/setSelectedItems",[]);
                this.$emit("save", datas);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
    table,
    td {
        border: 1px solid #333;
    }

    thead,
    tfoot {
        background-color: #333;
        color: #fff;
    }
</style>
<docs>

    ### vg-equipement-form
    Create|Update|Delete equipement

    #### basic usage

    ```html static
        <vg-equipement-form v-if="equipement || isEquipementFormDisplayed"
            v-model="equipement"
            @close="isEquipementFormDisplayed=false;equipement=null;"
            @created="isEquipementFormDisplayed=false;fetch()"
            @updated="isEquipementFormDisplayed=false;fetch()"
            @deleted="isEquipementFormDisplayed=false;fetch()"
        >
        </vg-equipement-form>
    ```
</docs>
