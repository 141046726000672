<template>
    <!-- <div class="carte-equipement">
        <div class="libelle-equipement">{{params.libel_equipement}}</div>
        <div class="container-localisation">
            <div class="localisation"> {{params.path}} </div>
        </div>
        <div class="qr-code"> {{params.qrCode}} </div>
    </div> -->
    <div class="equipement-card-ZoomDashboard">
        <div class="info-equip">
            <img :src="icon" alt="" height="25">
            <span class="txt-important"><b>{{params.libel_equipement}}</b></span>
        </div>
        <div class="info-cellrender">
            <div class="left">
                 {{params.qrCode}}
            </div>
            <div class="right">
                {{$t("last-verif")}} :
                <span v-if="params.dateDerniereVerification"
                    class="txt-important">
                    {{getDate}}
                    {{getHour}}
                </span>
                <span v-else class="txt-important">
                    -
                </span>
            </div>
        </div>
        <div class="">
            <span>{{$t("dans")}} <span class="txt-important">{{$vgutils.getPathEnd(params.path)}}</span> </span>
        </div>
        <div class="">
            <vg-lieu-path-viewer v-model="params.path"
                onlyPath
                :showTooltip="false"/>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            icon: {
				type: String,
				default: function (value) {
                    if (value == "") {
                        return "/static/assets/icone/tache-de-verification.png";
                    }
                    else{
                        return value;
                    }
                }
			}
		},
        i18n:    { "locale":navigator.language,
	    	"messages": {
                "fr":{
                    "last-verif":"Dernier le",
                    "dans":"Dans"
                },
                "en":{
                    "last-verif":"Last on",
                    "dans":"in"
                }
            }
        },
        components:{
            VgLieuPathViewer
        },
        computed:{
            getDate:function(){
                return moment(this.params.dateDerniereVerification).format("DD/MM/YYYY");
            },
            getHour:function(){
                return moment(this.params.dateDerniereVerification).format("HH:mm");
            }
        }
    });
</script>

<style lang="scss" scoped>

    .equipement-card-ZoomDashboard{
        color:#a2a2a2;
        font-size: 11px;
        .info-equip{
            display: flex;
            margin-bottom: 5px;
            height: 30px;
            img{
                align-self: center;
                margin-right: 5px;
            }
            span{
                align-self: center;

            }
        }
        .txt-important{
            font-size: 13px;
            color: black;
        }
        .info-cellrender{
            height: 18px;
            width:100%;
            .left{
                float: left;
                width: 50%;
            }
            .right{
                float: right;


            }
        }

    }
    @media (min-width: 768px){
        .equipement-card-ZoomDashboard{
            font-size: 13px;
            > div {
                line-height: 18px;
            }
            .txt-important{
                font-size: 15px;
            }
        }
    }
</style>
