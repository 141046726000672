<template>
<div id='showEquipement' class="full-height">
    <vg-app-layout
       :title="$t('assets-reporting')"
       :isActionButtonDisplay="true"
       :colorheader="getHeaderColor"
       :showSecondary="false"
       :activeFilters="activeFilters"
       @remove-filters="onRemoveFilters"
       @backpage="handleClickBtnRetour" >

        <template v-slot:action-button>
            <vg-button
                @click="exportToFile('excel')"
                type="success">
                {{ $t("modaleRapportAssets-footer-btn-exporter") }} .XLS
            </vg-button>
            <vg-button
                @click="exportToFile('csv')"
                :disabled="!equipements || equipements.length == 0"
                type="success">
                {{ $t("modaleRapportAssets-footer-btn-exporter") }} .CSV
            </vg-button>
        </template>

        <template #primary>
    		<tag-grid
    			idTableau="tableau-rapport-assets"
    			v-model="gridOptions"
    			:columnDefs="columnDefs"
                @ag-click="showEquipement"
    			:rowData="equipements"
    			:showLoadingOverlay="showLoadingOverlay"
    			:overlayLoadingText="$t('overlay-loading-text')"
    			:overlayNoRowsText="$t('overlay-no-rows-text')" />

                <vg-pagination 
                :totalItems="counters.filtered"
                :offset="offset"
                :limit="limit"
                @pagination-change="handlePaginationChange" />
        </template>

        <template #secondary-filters>
            <!--vg-input
                v-if="equipements.length"
                :title="$t('filter-by-accounting-tag')"
            >
                <template>
                    <vg-select
                        v-model="agfilters.accountingTag.value"
                        :options="accountingTagList"
                        :clearable="isClearable"
                    >
                    </vg-select>
                </template>
            </vg-input-->
            <vg-input :title="$t('filtre-categorie')" >
                <vg-categorie-selector
                    v-model="agfilters.categorie.value"
                    :isGe="false"
                    :attributeValue="'id'"
                >
                </vg-categorie-selector>
             </vg-input>

            <!--vg-input
                v-if="equipements.length"
                :title="$t('decote-plus-grand-que-valeur-achat',[decote])"
            >
                <template>
                    <input
                        v-model.lazy.number="decote"
                        type="range"
                        id="volume"
                        name="volume"
                        min="0"
                        max="100"
                    >
                </template>
            </vg-input-->
            <vg-input
                v-model="agfilters.valeurAchatSeuilBas.value"
                inputType="number"
                :title="$t('valeur-achat-mini')"
            >
            </vg-input>

            <vg-input
                v-model="agfilters.valeurAchatSeuilHaut.value"
                inputType="number"
                :title="$t('valeur-achat-maxi')"
            >
            </vg-input>
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                v-once
                v-model="agfilters.path.value"/>
        </template>

    </vg-app-layout>



</div>

</template>
<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';
import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgGroup from 'src/components/Vg/VgGroup.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgInputCost from 'src/components/Vg/VgInputCost.vue';

import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";

import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import Metadatas from "src/services/Metadatas.js";
import VgPagination from "src/components/Vg/VgPagination.vue";

import { mapGetters } from 'vuex';

export default {
    name: 'equipements-rapport-financier',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "overlay-loading-text": "Chargement des équipements...",
    		"overlay-no-rows-text": "Aucun équipement correspondant au filtrage",
            "modaleRapportAssets-header": "Registre des immobilisations",
            "modaleRapportAssets-footer-btn-exporter": "Exporter",
            "tableauRapportAssets-header-id": "Id",
            "tableauRapportAssets-header-qrCode": "QR Code",
            "tableauRapportAssets-header-localisation": "Localisation",
            "tableauRapportAssets-header-fournisseur": "Fournisseur",
            "tableauRapportAssets-header-libelleCategorie": "Libellé catégorie",
            "tableauRapportAssets-header-libelleEquipement": "Libellé équipement",
            "tableauRapportAssets-header-numImmobilisation": "Numero comptable",
            "tableauRapportAssets-header-service": "Service",
            "tableauRapportAssets-header-lieu": "Lieu",
            "tableauRapportAssets-header-tiers": "Tiers",
            "tableauRapportAssets-header-path": "Emplacement",
            "tableauRapportAssets-header-caracTechniques": "Caractéristiques",
            "tableauRapportAssets-header-miseEnService": "Date mise en service",
            "tableauRapportAssets-header-refConstructeur": "Ref. constructeur",
            "tableauRapportAssets-header-numeroSerie": "Numéro de série",
            "tableauRapportAssets-header-etat": "Etat",
            "tableauRapportAssets-header-dateDebut": "Date début",
            "tableauRapportAssets-header-dateFin": "Date fin",
            "tableauRapportAssets-header-moisUtilisation": "Mois d'utilisation",
            "tableauRapportAssets-header-valeurAchat": "Valeur d'achat",
            "tableauRapportAssets-header-tauxDepreciation": "Taux de dépréciation",
            "tableauRapportAssets-header-depreciationAnnuelle": "Dépréciation annuelle",
            "tableauRapportAssets-header-depreciationMensuelle": "Dépréciation mensuelle",
            "tableauRapportAssets-header-depreciationCumulee": "Dépréciation cumulée",
            "tableauRapportAssets-header-depreciationRestante": "Dépréciation restante",
            "tableauRapportAssets-header-accounting-tag": "Etiquette comptable",
            "tableauRapportAssets-header-tag": "Etiquette",
            "onglet-accounting-tag-view": "Vision étiquette comptable",
            "onglet-simple-view": "Vision simplifiée",
            "onglet-recap": "Récapitulatif",
            "print": "Imprimer",
            "recap": "Récapitulatif groupé par tag comptable",
            "filtres": "Filtres",
            "voir-tout": "Voir",
            "voir-tag": "Voir",
            "actions": "Actions",
            "depreciation-restante": "Valeur actuelle",
            "valeur-achat": "Valeur à l'achat",
            "label": "Libellé",
            "assets-reporting": "Registre des immobilisations",
            "total": "Total",
            "filtre-categorie": "Filtrage par catégorie équipement",
            "valeur-achat-maxi": "Valeur d'achat maximum",
            "valeur-achat-mini": "Valeur d'achat minimum",
            "filter-by-accounting-tag": "Filtrage par étiquette comptable",
            "decote-plus-grand-que-valeur-achat": "Montrer les assets dont la valeur actuelle est à moins de {0}% de la valeur d'achat"
        },
        "en": {
            "overlay-loading-text": "Loading equipments...",
    		"overlay-no-rows-text": "No equipment matching filtering",
            "modaleRapportAssets-header": "Assets register",
            "modaleRapportAssets-footer-btn-exporter": "Export",
            "tableauRapportAssets-header-qrCode": "Asset Tag Number",
            "tableauRapportAssets-header-libelleCategorie": "Category of Asset",
            "tableauRapportAssets-header-libelleEquipement": "Full Description",
            "tableauRapportAssets-header-service": "Section",
            "tableauRapportAssets-header-numImmobilisation": "Accounting number",
            "tableauRapportAssets-header-lieu": "Location",
            "tableauRapportAssets-header-tiers": "Supplier's Name",
            "tableauRapportAssets-header-refConstructeur": "Model Number",
            "tableauRapportAssets-header-numeroSerie": "Serial Number",
            "tableauRapportAssets-header-etat": "Condition of Asset",
            "tableauRapportAssets-header-dateDebut": "Start Date",
            "tableauRapportAssets-header-dateFin": "End Date",
            "tableauRapportAssets-header-moisUtilisation": "Months in use",
            "tableauRapportAssets-header-valeurAchat": "Cost",
            "tableauRapportAssets-header-tauxDepreciation": "Depreciation Rate",
            "tableauRapportAssets-header-depreciationAnnuelle": "Annual Depreciation",
            "tableauRapportAssets-header-depreciationMensuelle": "Monthly Depreciation",
            "tableauRapportAssets-header-id": "ID",
            "tableauRapportAssets-header-localisation": "Localisation",
            "tableauRapportAssets-header-fournisseur": "Supplier",
            "tableauRapportAssets-header-path": "Location",
            "tableauRapportAssets-header-caracTechniques": "Characteristics",
            "tableauRapportAssets-header-miseEnService": "Put into service on",
            "tableauRapportAssets-header-depreciationCumulee": "Cumulative depreciation",
            "tableauRapportAssets-header-depreciationRestante": "Remaining depreciation",
            "tableauRapportAssets-header-accounting-tag": "Accouting tag",
            "tableauRapportAssets-header-tag": "Label",
            "onglet-accounting-tag-view": "Accounting tag view",
            "onglet-simple-view": "Simple view",
            "onglet-recap": "Recap",
            "print": "Print",
            "recap": "Recap group by accounting tag",
            "filtres": "Filters",
            "voir-tout": "Show",
            "voir-tag": "Show",
            "actions": "Actions",
            "depreciation-restante": "Current value",
            "valeur-achat": "purchase value",
            "label": "Label",
            "assets-reporting": "Assets register",
            "total": "Total",
            "filtre-categorie": "Filter by equipment's category",
            "valeur-achat-maxi": "Highest purchase value",
            "valeur-achat-mini": "Lower purchase value",
            "filter-by-accounting-tag": "Filter by accounting tag",
            "decote-plus-grand-que-valeur-achat": "Show assets with current value lower than {0}% of purchase value"
        },
        "th": {
            "modaleRapportAssets-header": "รายงานสินทรัพย์",
            "modaleRapportAssets-footer-btn-exporter": "ส่งออก",
            "tableauRapportAssets-header-qrCode": "หมายเลขแท็กสินทรัพย์",
            "tableauRapportAssets-header-libelleCategorie": "หมวดหมู่ของสินทรัพย์",
            "tableauRapportAssets-header-libelleEquipement": "คำอธิบายฉบับเต็ม",
            "tableauRapportAssets-header-service": "หมวด",
            "tableauRapportAssets-header-lieu": "ตำแหน่งที่ตั้ง",
            "tableauRapportAssets-header-tiers": "ชื่อซัพพลายเออร์",
            "tableauRapportAssets-header-refConstructeur": "โมเดลหมายเลข",
            "tableauRapportAssets-header-numeroSerie": "หมายเลขชุด",
            "tableauRapportAssets-header-etat": "เงื่อนไขของสินทรัพย์",
            "tableauRapportAssets-header-dateDebut": "วันที่เริ่มต้น",
            "tableauRapportAssets-header-dateFin": "วันที่สิ้นสุด",
            "tableauRapportAssets-header-moisUtilisation": "เดือนกำลังใช้งาน",
            "tableauRapportAssets-header-valeurAchat": "ต้นทุน",
            "tableauRapportAssets-header-tauxDepreciation": "อัตราค่าเสื่อมราคา",
            "tableauRapportAssets-header-depreciationAnnuelle": "ค่าเสื่อมราคารายปี",
            "tableauRapportAssets-header-depreciationMensuelle": "ค่าเสื่อมราคารายเดือน",
            "tableauRapportAssets-header-numImmobilisation": "หมายเลขการทำบัญชี",
            "tableauRapportAssets-header-id": "ID",
            "tableauRapportAssets-header-localisation": "การจำกัดความ",
            "tableauRapportAssets-header-fournisseur": "ซัพพลายเออร์",
            "tableauRapportAssets-header-path": "ที่ตั้ง",
            "tableauRapportAssets-header-caracTechniques": "ลักษณะเฉพาะ",
            "tableauRapportAssets-header-miseEnService": "เข้ารับบริการเมื่อ",
            "tableauRapportAssets-header-depreciationCumulee": "ค่าเสื่อมราคาสะสม",
            "tableauRapportAssets-header-depreciationRestante": "ค่าเสื่อมราคาคงเหลือ",
            "tableauRapportAssets-header-accounting-tag": "แท็กการทำบัญชี",
            "tableauRapportAssets-header-tag": "ฉลาก",
            "onglet-accounting-tag-view": "มุมมองแท็กการทำบัญชี",
            "onglet-simple-view": "มุมมองแบบง่าย",
            "print": "พิมพ์"
        }
    }
},
    components: {
        TagGrid,
        VgButton,
        VgTabs,
        VgSelect,
        VgTreeLieux,
        VgGroup,
        VgInput,
        VgInputCost,
        VgCategorieSelector,
        VgPagination
    },
    mixins:[
        equipementsMixins
    ],
    data: function() {
        return {
            showLoadingOverlay: false,
            rapportAssets:[],
            gridOptions: {
                isFullWidthCell:function(rowNode){
                    return rowNode.data.isSection;
                },
                fullWidthCellRenderer: 'fullWidthSectionCellRenderer'
            },
            columnDefs: [
                { headerName: this.$t("tableauRapportAssets-header-id"),
                 field: "id", width: 180,cellClass: ["vg-cell-ballam-theme"], pinned: 'left',hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-libelleEquipement"),
                 field: "libelEquipement", width: 240,cellClass: ["vg-cell-ballam-theme"], pinned: 'left'
                },
                { headerName: this.$t("tableauRapportAssets-header-qrCode"),
                 field: "qrCode", width: 180,cellClass: ["vg-cell-ballam-theme"]
                },
                { headerName: this.$t("tableauRapportAssets-header-libelleCategorie"),
                 field: "categorie", width: 200,cellClass: ["vg-cell-ballam-theme"]
                },

                { headerName: this.$t("tableauRapportAssets-header-caracTechniques"),
                 field: "carac_techniques", width: 200,cellClass: ["vg-cell-ballam-theme"], pinned: 'left',hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-miseEnService"),
                 field: "miseEnService", width: 200,cellClass: ["vg-cell-ballam-theme"], pinned: 'left',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.miseEnService}}).$mount().$el
                },

                { headerName: this.$t('tableauRapportAssets-header-numImmobilisation'),
                    width: 150,
                    field: 'numeroImmobilisation',
                    //headerComponent: headerRenderer,
                    cellRenderer: (params) => params.data.numeroImmobilisation?params.data.numeroImmobilisation: params.data.numeroImmobilisationDefault,
                    cellClass: ["vg-cell-ballam-theme"],

                },
                { headerName: this.$t("tableauRapportAssets-header-accounting-tag"),
                 field: "label", width: 200,cellClass: ["vg-cell-ballam-theme"], pinned: 'left',
                },
                { headerName: this.$t("tableauRapportAssets-header-tag"),
                 field: "tags", width: 200,cellClass: ["vg-cell-ballam-theme"], pinned: 'left',hide:true,
                 cellRenderer: (params)=> this.$vgutils.customTagsCellRenderer(params.data.tags)
                },
                { headerName: this.$t("tableauRapportAssets-header-service"),
                 field: "service", width: 150,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-localisation"),
                 field: "path", width: 180,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-fournisseur"),
                 field: "fournisseurName", width: 180,cellClass: ["vg-cell-ballam-theme"]
                },
                {headerName: this.$t("tableauRapportAssets-header-refConstructeur"),
                  field: "refConstructeur", width: 200,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-numeroSerie"),
                 field: "numSerie", width: 240,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-etat"),
                 field: "etat", width: 100,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                {headerName: this.$t("tableauRapportAssets-header-dateDebut"),
                  field: "miseEnService", width: 120,cellClass: ["vg-cell-ballam-theme"],
                     cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.miseEnService}}).$mount().$el
                },
                { headerName: this.$t("tableauRapportAssets-header-dateFin"),
                 field: "dateFin",width: 120,cellClass: ["vg-cell-ballam-theme"],hide:true
                },
                { headerName: this.$t("tableauRapportAssets-header-moisUtilisation"),
                 field: "moisUtilisation", width: 180,cellClass: ["vg-cell-ballam-theme"]
                },
                { headerName: this.$t("tableauRapportAssets-header-valeurAchat"),
                 field: "valeurAchat", width: 180,cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => {
                        console.log(params);
                        return new VgCostCellRender({propsData: {value: params.data.valeurAchat}}).$mount().$el;
                    }
                },
                { headerName: this.$t("tableauRapportAssets-header-tauxDepreciation"),
                 field: "tauxDepreciationAnnuel", width: 200,cellClass: ["vg-cell-ballam-theme"],
                 cellRenderer: (params) => {
                     return params.data.tauxDepreciationAnnuel &&
                     params.data.tauxDepreciationAnnuel != null ?
                     params.data.tauxDepreciationAnnuel + "%" : "0%";
                 }
                },
                { headerName: this.$t("tableauRapportAssets-header-depreciationAnnuelle"),
                 field: "depreciationAnnuelle",width: 220,cellClass: ["vg-cell-ballam-theme"], // depreciation annuelle
                    cellRenderer: (params) => {
                        return new VgCostCellRender({propsData: {value: params.data.depreciationAnnuelle}}).$mount().$el;
                    }
                },
                { headerName: this.$t("tableauRapportAssets-header-depreciationMensuelle"),
                 field: "depreciationMensuelle",width: 220,cellClass: ["vg-cell-ballam-theme"], // depreciation mensuelle
                    cellRenderer: (params) => {
                        return new VgCostCellRender({propsData: {value: params.data.depreciationMensuelle}}).$mount().$el;
                    }
                },
                { headerName: this.$t("tableauRapportAssets-header-depreciationCumulee"),
                 field: "depreciationCumulee",width: 220,cellClass: ["vg-cell-ballam-theme"], // depreciation cumulée
                    cellRenderer: (params) => {
                        return new VgCostCellRender({propsData: {value: params.data.depreciationCumulee}}).$mount().$el;
                    }
                },
                { headerName: this.$t("tableauRapportAssets-header-depreciationRestante"),
                 field: "depreciationRestante",width: 220,cellClass: ["vg-cell-ballam-theme"], // depreciation restante
                    cellRenderer: (params) => {
                        return new VgCostCellRender({propsData: {value: params.data.depreciationRestante}}).$mount().$el;
                    }
                }
            ],
            assets: null,
            selectedtab: "accounting",
            assetsaccounting: null,
            footerline: null,
            agfilters:{
                isGroupEqp: { attr:"e.isGroupEqp", colId: "isGroupEqp", value: 1, action:"not_equals" },
                categorie: { attr:"c.id", colId: "idCategorie", value: null, action:"equals" },
                type: { attr:"e.type", colId: "type", value:"Asset", action:"equals" },
                accountingTag:{ attr:"tag.label", colId: "label", value: null, action:"equals" },
                path: { attr:"l.path", colId: "path", value: null, action:"contains" },
                valeurAchatSeuilBas:{ attr:"e.valeurAchat", colId: "valeurAchat", value: null, action:"greater_than"},
                valeurAchatSeuilHaut:{ attr:"e.valeurAchat", colId: "valeurAchat", value: null, action:"less_than"}
            },
            decote:100,
            minValeurAchat:0,
            maxValeurAchat:1000,
            offset: 0,
			limit: 25,
            metadatas:new Metadatas()
        };
    },
    watch:{
        agfilters:{
            handler:function(newFilters){
                this.fetch(newFilters);
            },
            deep:true
        },
        decote:{
            handler:function(decote){
                this.applyDecote(decote);
            }
        },
        minValeurAchat:{
            handler:function(min){
                this.applyRangeOnValeurAchat(min, this.maxValeurAchat);
            }
        },
        maxValeurAchat:{
            handler:function(max){
                if(max < 1 ) {
                    this.maxValeurAchat = this.getHighestPurchaseValue(this.equipements);
                }else{
                    this.applyRangeOnValeurAchat(this.minValeurAchat, max);
                }

            }
        }
    },
    computed:{
        ...mapGetters({
            equipements: 'EquipementsStore/getCollection',
            counters: "EquipementsStore/getCounters",
        }),
        activeFilters: function(){
            let filtersNames = ["accountingTag", "categorie"];
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            /*if(this.decote && this.decote!=0) nFilters += 1;
            if(this.minValeurAchat && this.minValeurAchat!=0) nFilters += 1;
            if(this.maxValeurAchat && this.maxValeurAchat!=0) nFilters += 1;*/
            return activeFilters.length + nFilters;
        },
        tabs: function(){
            let tabs = [
                {label: this.$t("onglet-accounting-tag-view"), name:"accounting",isNotMobile:true, counter: this.getTotalAssets, filters:{}},
                {label: this.$t("onglet-simple-view"), name:"simple",isNotMobile:true, counter: this.getTotalAssets, filters:{}},
                {label: this.$t("onglet-recap"), name:"recap",isNotMobile:true, filters:{}}
            ];

            return tabs;
        },
        /**
         * @return {integer}
         */
        getTotal:function(){
            let total = 0;
            if(this.equipements){
                this.equipements.forEach((asset, i) => {
                    if(asset.valeurAchat && asset.valeurAchat != ""){
                        total += Number.parseInt(asset.valeurAchat);

                    }

                });
            }

            return total;
        },
        /**
         * @return {array}
         */
        getTotauxBySection:function(){
            if(!this.assetsaccounting)return 0;
            let assetsTotal = [];
            this.assetsaccounting.forEach((asset, i) => {
                if(asset.isSection){
                    assetsTotal.push(asset);
                }
            });
            return assetsTotal;
        },
        /**
         * @return {integer}
         */
        getTotauxAllSection:function(){
            let total = 0;
            if(!this.assetsaccounting)return 0;
            this.assetsaccounting.forEach((asset, i) => {
                if(asset.isSection){
                    let depreciationRestante = Number.isNaN(asset.depreciationRestante) ?
                    0 : asset.depreciationRestante;
                    total += depreciationRestante;
                }
            });
            return total;
        },
        /**
         * @return {array}
         */
        accountingTagList:function(){
            let tags = [];

            if(!this.assetsaccounting)return tags;
            this.assetsaccounting.forEach((asset, i) => {
                if(asset.isSection){
                    tags.push(asset.label);
                }
            });
            return tags;
        },
        /**
         * @return {integer}
         */
        getTotalAssets:function(){
            let count = 0;
            if(!this.assetsaccounting) return count;
            this.assetsaccounting.forEach((asset, i) => {
                if(!asset.isSection){
                    ++count;
                }
            });
            return count;
        }
    },
    methods: {
        reinitPagination: function(){
            this.offset = 0;
            this.limit = 25;
            this.metadatas.setLimit(this.offset, this.limit);
        },
        handlePaginationChange: function(pagination){
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agFilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        feedTable: function(){
            this.gridOptions.pinnedBottomRowData = [this.footerline];
        },
        onClickTab: function(e){
            this.selectedtab = e.name;
            this.feedTable();
        },

        /**
         * applyRangeOnValeurAchat.
         *
         * @param  {integer} min description
         * @param  {integer} max description
         */
        applyRangeOnValeurAchat:function(min, max){
            let filteredAssets = [];
            this.equipements.forEach((asset, i) => {
                if(asset.isSection){
                    //filteredAssets.push(asset);
                }
                let valeurAchat = asset.valeurAchat;
                if(valeurAchat >= min && valeurAchat <= max){
                    filteredAssets.push(asset);
                }
            });
            this.assetsaccounting = this.getAccountingTagSections(filteredAssets);
        },
        /**
         * applyDecote - filter assets with current value
         * lower than decote% of purchase value
         *
         * @param  {integer} decote pourcentage
         */
        applyDecote:function(decote){
            if(decote == 100){
                return;
            }
            let filteredAssets = [];
            this.equipements.forEach((asset, i) => {
                if(asset.valeurAchat && asset.depreciationRestante){
                    let seuilValeur = Number.parseFloat((decote*asset.valeurAchat)/100).toFixed(2);
                    if(asset.depreciationRestante <= seuilValeur){
                        filteredAssets.push(asset);
                    }
                }
            });

        },
        /**
         * fetch - description
         *
         * @param  {object} assetsFilters description
         */
        fetch:function(assetsFilters=null){
            this.showLoadingOverlay = true;
            let filters = this.metadatas;
            filters.setFilters(this.agfilters);
            this.equipementsMixins_getRapportAssets(filters).then((equipements)=>{
                console.log(equipements);
                //this.rapportAssets = equipements;
            });
            /*if(assetsFilters) filters.setFilters(assetsFilters);
            this.equipementsMixins_getEquipements(filters).then((equipements)=>{
                this.assetsaccounting = this.getAccountingTagSections(equipements.datas);
                this.maxValeurAchat = this.getHighestPurchaseValue(equipements.datas);
                this.showLoadingOverlay = false;
                /*this.applyDecote(50);
                this.applyDecote(100);*/
            //});
        },

        /**
         * getAccountingTagSections - order by accountingtag
         * and add a recap line on bottom of the dataset.
         *
         * @param  {array} equipements array of object equipement
         * @return {array}             description
         */
        getAccountingTagSections:function(equipements){
            let sections = this.groupBy(equipements,"accountingTag");
            let accountingTagsSections = [];
            let sectionWithoutAccountingTag = null;
            for (let section of Object.keys(sections)) {
                if(sections[section]){
                    if(section && section !== 'null' ){
                        accountingTagsSections = accountingTagsSections.concat(this.addRecapSectionLine(sections[section],section));
                    }else{
                        sectionWithoutAccountingTag = sections[section];
                    }
                }
            }
            if(sectionWithoutAccountingTag && sectionWithoutAccountingTag.length){
                accountingTagsSections = accountingTagsSections.concat(this.addRecapSectionLine(sectionWithoutAccountingTag,"without accounting tag"));
            }

            return accountingTagsSections;
        },
        /**
         * getSectionRowsDatas - Generate a sectionRecapLine and push it to the
         * given section. it will make sum of depreciationAnnuelle,depreciationMensuelle
         * depreciationCumulee,depreciationRestante, valeurAchat
         *
         * @param  {array} section description
         * @return {array}         description
         */
        addRecapSectionLine:function(section, name){
            let sectionRecapLine = {
                qrCode: '',
                libelleCategorie: '',
                label: name,
                accountingTag: name,
                libelEquipement: 'Total',
                service: '-',
                path: '-',
                fournisseurName: '-',
                refConstructeur: '-',
                etat: '-',
                miseEnService: '-',
                dateFin: '-',
                moisUtilisation: '-',
                valeurAchat: this.sum(section,"valeurAchat"),
                tauxDepreciationAnnuel: '-',
                depreciationAnnuelle: this.sum(section,"depreciationAnnuelle"),
                depreciationMensuelle: this.sum(section,"depreciationMensuelle"),
                depreciationCumulee: this.sum(section,"depreciationCumulee"),
                depreciationRestante: this.sum(section,"depreciationRestante"),
                isSection:true
            };
            section.push(sectionRecapLine);
            return section;
        },
        /**
         * sum - will sum all items.property and return the result.
         *
         * @param  {array} items
         * @param  {string} prop
         * @return {float}
         */
        sum:function(items, prop){
            if(!items) return "0";
            return items.reduce( function(a, b){
                let bb = Number.parseFloat(b[prop]);
                let aa = Number.parseFloat(a);
                bb = isNaN(bb) ? 0 : bb;
                aa = isNaN(aa) ? 0 : aa;
                return aa + bb;
            }, 0);
        },
        /**
         * groupBy - return an object grouped by the given key.
         *
         *
         * @param  {array} objectArray description
         * @param  {string} property    description
         * @return {object}             description
         */
        groupBy:function(objectArray, property){
          return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {});
        },

        /**
         * getColumn.
         *
         * @param  {array} list       description
         * @param  {string} columnName description
         * @return {array}            description
         */
        getColumn: function(list, columnName) {
            return list.map((row)=>{
                return row[columnName]
            });
        },
        exportToFile: function(format){
            let metadatas = new Metadatas();
            this.equipementsMixins_getRapportAssetsExcelFile(metadatas);
            //this.$emit('exported');
        },
        /**
         * showEquipement - got to show equipement/:id
         * @param  {agGridRow} row description
         */
        showEquipement: function(row){
            this.$router.push({ name: '_equipement_id', params: { id: row.data.id } });
        },
        getHighestPurchaseValue:function(equipements){
            let valeursAchat = this.getColumn(equipements, "valeurAchat");
            return Math.max(...valeursAchat);
        }
    },
    created: function() {
        this.reinitPagination();
        this.fetch(this.agfilters);
    },
    mounted:function(){
        this.applyDecote(100);
    }
}

</script>

<style lang="scss" scoped>
    .secondary_section_title{
        color: #35b1ea;
        font-size: 16px;
    }
    .no-margin{
        margin: 5px !important;
    }
    table,
td {
    border: 1px solid #333;
    padding:5px;
}
th{
    padding:5px;
}
thead,
tfoot {
    background-color: #e8eaee;
    color: #484848;
}

</style>
