<template >
	<vg-input :title="$t('title')"> 
        <vg-select
            v-model="focusedOrigine"
            :options="origines"
            :multiple="false"
            @input="handleChange"
        >
            <template v-slot="{option}">
                <div style="display:flex;justify-content:flex-start;align-items:center;width:100%;padding:5px 0;">
                    <div style="margin-right:10px;width:25px;">
                        <img v-if="option.value == 'DI'" src="/static/assets/icone/origine-DI-jaune.png" height="20" width="20" style="margin-right:10px;" />
                        <img v-else-if="option.value == 'Verification'" src="/static/assets/icone/origine-verification-orange.png" height="20" width="20" style="margin-right:10px;" />
                    </div>
                    <span>{{option.label}}</span>
                </div>
            </template>
        </vg-select>
    </vg-input>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-filter-maintenance-origine',
	    components: {
			VgInput,
			VgSelect
        },
		mixins:[],
        props: {
			value: {
				type: Array,
				required: true
			},
			multiple:{
				type: Boolean,
				default: false
			},
			type: {
				type: Array,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par origine",
            "di":"Demande intervention",
            "entretien":"Entretien périodique",
            "intervention":"Intervention préventive",
            "verification":"Maintenance préventive non conforme"
        },
        "en": {
            "title": "Filter by origin"
        }
    }
},
        data: function(){
            return {
				focusedOrigine: this.value || [],
                origines: [
                    {
                        label:this.$t("di"),
                        value:"DI",
                        filters: {
                            field_origin:{attr:"fm.origin",colId: "origin", value: "Verification", action:"not_equals"}
                        },

                    },
                    {
                        label:this.$t("verification"),
                        value:"Verification",
                        filters: {
                            field_origin:{attr:"fm.origin",colId: "origin", value: "Verification", action:"equals"},
                        }
                    },
                ]
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedOrigine = val;
				}
			}
		},
		created: function(){

		},
        methods: {
			handleChange: function(e){
                if(this.focusedOrigine){
                    this.$emit("input", this.focusedOrigine);
                }else{
                    this.$emit("input", {filters:{field_origin:{attr:"fm.origin",colId: "origin", value: null, action:"equals"}}});
                }
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
