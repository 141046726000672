<template lang="html">
    <div :class="['vg-tag', color]" v-if="label || isSlotLabelNotEmpty">
        <div class="icon" v-if="icon">
            <img :src="getIcon" alt="">
        </div>
        <div class="text">
            <!-- @slot pour personnaliser le texte avec du css ou html -->
            <slot>{{label}}</slot>
        </div>
        <div class="cross-close" v-if="key" @click="emitDelete()">
            <i class="fas fa-times"></i>
        </div>
    </div>
</template>

<script>
export default {
    name:"vg-tag",
    props:{
        /**
         * Libellé du tag
         */
        label : {
            type: String,
            default : null
        },
        /**
         * index du tableau associer si plusieurs tag, utiliser pour l'event "deleteTag"
         */
        key:{
            type:Number,
            default:null
        },
        /**
         * Couleur predefini pour le composant
         * @values blue,orange,red
         */
        color:{
            type:String,
            default:"blue"
        },
        /**
         * le chemin de debut deja integré, "/static/assets/icone/" uniquement le nom de l'icone
         */
        icon:{
            type:String,
            default:null
        }

    },
    methods:{
        /**
         * emit la donnée a supprimer
         * @event delete key
         * @property {number} key index du tableau
         * @property {number} label Nom du tags
         */
        emitDelete:function(){
            if (this.key) {
                this.$emit("deleteTag",this.key)
            }
            else{
                this.$emit("deleteTag",this.label)
            }
        }
    },
    computed:{
        /**
         * @return path icon
         */
        getIcon:function(){
            return "/static/assets/icone/" + this.icon ;
        },
        isSlotLabelNotEmpty: function(){
            return !!this.$slots["default"];
        }
    }

}
</script>

<style lang="scss" scoped>
.vg-tag{
    display: flex;
    width: fit-content;
    padding: 0 6px;
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
    align-items: center;
    float: left;
    margin-right: 5px;
    .icon{
        display: flex;
        height: 13px;
        width: 13px;
        margin-right: 5px;
        img{
            height: 12px;
        }

    }
    .text{
    }
    .cross-close{
        font-size: 10px;
        width: 17px;
        height: 17px;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        &:hover{
            background-color: gray;
            color: white;
        }
    }
    &.blue{
        background-color: rgba(64,158,255,.1);
        border: 1px solid rgba(64,158,255,.2);
        color: #409EFF;
    }
    &.orange{
        background-color: rgba(255, 150, 64, 0.1);
        border: 1px solid rgba(255, 150, 64, 0.2);
        color: rgba(255, 150, 64, 1);
    }
    &.red{
        background-color: rgba(#F56C6C , 0.1);
        border: 1px solid rgba(#F56C6C , 0.2);
        color: #F56C6C;
    }
    &.mauve{
        background-color: rgba(#6f2881 , 0.1);
        border: 1px solid rgba(#6f2881 , 0.2);
        color: #6f2881;
    }
    &.dark-mauve{
        background-color: #6f2881;
        border: 1px solid #6f2881;
        color: white;
    }
}
</style>
<docs>
    Composant Tag
    ## Usage du composant
    ```html static
          <vg-tag :label="tag" :icon="icon" :color="color" ></vg-tag>
    ```

    ## Si plusieur Tags
    ```html static
          <vg-tag v-for="(tag,index) in tags" :label="tag" :icon="icon" :color="color" @deleteTag="deleteTag" :key="key"></vg-tag>
    ```
    ## Possede un emit contenant la donnée a supprimer dans les tags

    ```javascript
    @deleteTag
    ```
     pour les couleurs : il existe 3types pour l'instant :
     ```javascript
     :color="'blue'"
     ```
     ```javascript
     :color="'orange'"
     ```
     ```javascript
     :color="'red'"
     ```

     ```js
     new Vue({
       data(){
         return {
         }
       },
       template: `
         <div>
             <vg-tag :label="'tag'" :color="'blue'" ></vg-tag> <span>blue</span>
             <vg-tag :label="'tag'" :color="'orange'" ></vg-tag> <span>orange</span>
             <vg-tag :label="'tag'" :color="'red'" ></vg-tag> <span>red</span>
         </div>
       `
     })
     ```

</docs>
