<template>
	<span>
		{{$t(value.statutLivraison)}}
	</span>
</template>
<script>
    export default {
        name: 'vg-bon-de-commande-statut-livraison-viewer',
    	props: {
            value: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "not-delivered": "Non livré",
            "delivered": "Livraison complète",
            "partially-delivered": "Livraison partielle"
        }
    }
}
    };

</script>
<style lang="scss" scoped>

</style>
