<template >
    <vg-modal
        :title="$t('intervention-programmation-management')"
        width="800px"
        class="vg-programmation-interventions-modal"
        @close="close">
        <template #body>
            <vg-input :title="$t('premiere-date')" inline isRequiredValue>
                <vg-input v-model="programmationSession.premiereDate"
                    inputType="date"/>
                <vg-input v-model="programmationSession.heureDebut"
                    inputType="time"/>
            </vg-input>
            <vg-input :title="$t('programmer-tous-les')" inline isRequiredValue>
                <vg-input v-model="programmationSession.intervalleValue"
                    inputType="number"
                    style="width: 70px;"/>
                <vg-select v-model="programmationSession.intervalleUnite"
                    :attributeValue="'value'"
                    :attributeLabel="'label'"
                    :options="intervalleUnites" 
                    :clearable="false"/>
            </vg-input>
            <vg-input :title="$t('exclure')" inline>
                <vg-button :type="programmationSession.excludeSaturday?'info':'grey'" 
                    @click="programmationSession.excludeSaturday=!programmationSession.excludeSaturday">
                    {{ $t("exclure-samedi") }}
                </vg-button>
                <vg-button :type="programmationSession.excludeSunday?'info':'grey'" 
                    style="margin-left:10px;"
                    @click="programmationSession.excludeSunday=!programmationSession.excludeSunday">
                    {{ $t("exclure-dimanche") }}
                </vg-button>
                <vg-button :type="programmationSession.excludePublicHoliday?'info':'grey'" 
                    style="margin-left:10px;"
                    disabled
                    @click="programmationSession.excludePublicHoliday=!programmationSession.excludePublicHoliday">
                    {{ $t("exclure-jour-ferie") }}
                </vg-button>
            </vg-input>
            <vg-input :title="$t('fixer-jour')" inline>
                <vg-button :type="programmationSession.isMonday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isMonday=!programmationSession.isMonday">
                    {{ $t("lundi") }}
                </vg-button>
                <vg-button :type="programmationSession.isTuesday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isTuesday=!programmationSession.isTuesday">
                    {{ $t("mardi") }}
                </vg-button>
                <vg-button :type="programmationSession.isWednesday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isWednesday=!programmationSession.isWednesday">
                    {{ $t("mercredi") }}
                </vg-button>
                <vg-button :type="programmationSession.isThursday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isThursday=!programmationSession.isThursday">
                    {{ $t("jeudi") }}
                </vg-button>
                <vg-button :type="programmationSession.isFriday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isFriday=!programmationSession.isFriday">
                    {{ $t("vendredi") }}
                </vg-button>
                <vg-button :type="programmationSession.isSaturday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isSaturday=!programmationSession.isSaturday">
                    {{ $t("samedi") }}
                </vg-button>
                <vg-button :type="programmationSession.isSunday?'info':'grey'"
                    style="margin-left:10px;"
                    @click="programmationSession.isSunday=!programmationSession.isSunday">
                    {{ $t("dimanche") }}
                </vg-button>
            </vg-input>
            <vg-input v-model="programmationSession.durationMinutes"
                :title="$t('duree-moyenne-session')" 
                inputType="number" 
                inline isRequiredValue />
            <vg-input 
                v-model="programmationSession.minDaysBeforeGeneratedDate" 
                :title="$t('min-days-before-generated-date')"
                inputType="number"
                inline isRequiredValue
            />
        </template>
        <template #footer>
            <vg-button @click="close" type="defalut-success">
                {{ $t("fermer") }}
            </vg-button>
            <vg-button @click="save" type="success" :disabled="isSaveDisabled">
                {{ $t("validate-scheduling")  }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import AffectationsMixins from "src/mixins/AffectationsMixins.js";
import VgSelect from "src/components/Vg/VgSelect.vue";
import { mapGetters } from "vuex";
export default {
    name: "vg-intervention-previsionnelle-scheduling-form",
    i18n: {
        messages: {
            "fr": {
                "create-intervention-previsionnelle-scheduling": "Créer",
                "update-intervention-previsionnelle-scheduling": "Modifier",
                "delete-intervention-previsionnelle-scheduling": "Supprimer",
                "has-programmation-session": "A-t-il une programmation de session ?",
                "premiere-date": "Première date",
                "programmer-tous-les": "Programmer tous les",
                "exclure": "Exclure",
                "exclure-samedi": "Samedi",
                "exclure-dimanche": "Dimanche",
                "exclure-jour-ferie": "Jour férié",
                "fixer-jour": "Fixer le jour",
                "lundi": "Lundi",
                "mardi": "Mardi",
                "mercredi": "Mercredi",
                "jeudi": "Jeudi",
                "vendredi": "Vendredi",
                "samedi": "Samedi",
                "dimanche": "Dimanche",
                "jours": "Jours",
                "semaines": "Semaines",
                "mois": "Mois",
                "trimestres": "Trimestres",
                "semestre": "Semestres",
                "annees": "Années",
                "duree-moyenne-session": "Durée moyenne de la session (minutes)",
                "validate-scheduling": "Valider la programmation",
                "min-days-before-generated-date": "Génération intervention prévisionnelles x jours avant la prochaine date",
                "nb-jours": "Nombre de jours",
                "fermer": "Fermer",
                "validate-scheduling": "Valider la programmation",
                "intervention-programmation-management": "Gestion de la programmation des interventions"
            },
            "en": {
                "create-intervention-previsionnelle-scheduling": "Create",
                "update-intervention-previsionnelle-scheduling": "Update",
                "delete-intervention-previsionnelle-scheduling": "Delete",
                "has-programmation-session": "Has session scheduling?",
                "premiere-date": "First date",
                "programmer-tous-les": "Schedule every",
                "exclure": "Exclude",
                "exclure-samedi": "Saturday",
                "exclure-dimanche": "Sunday",
                "exclure-jour-ferie": "Public Holiday",
                "fixer-jour": "Set day",
                "lundi": "Monday",
                "mardi": "Tuesday",
                "mercredi": "Wednesday",
                "jeudi": "Thursday",
                "vendredi": "Friday",
                "samedi": "Saturday",
                "dimanche": "Sunday",
                "jours": "Days",
                "semaines": "Weeks",
                "mois": "Months",
                "trimestres": "Trimesters",
                "semestre": "Semesters",
                "annees": "Years",
                "duree-moyenne-session": "Average session duration (minutes)",
                "min-days-before-generated-date": "Generate intervention x days before next date",
                "nb-jours": "Number of days",
                "fermer": "Close",
                "validate-scheduling": "Validate scheduling",
                "intervention-programmation-management": "Intervention scheduling management"
            }
        }
    },
    components: {
        VgInput,
        VgCheckbox,
        VgButton,
        VgSelect
    },
	mixins: [AffectationsMixins],
    props: {
        /**
        *
        * Identifiant de interventionPrevisionnelleScheduling à modifier.
        * S'il est nourrie et que l'objet interventionPrevisionnelleScheduling n'est pas déjà
        * dans le store.
        */
        id:{
            type:Number,
            default:function(){
                return null;
            }
        },
        programmation:{
            type: Object,
            default:function(){
                return null;
            }
        },
        contrat:{
            type: Object,
            default:function(){
                return null;
            }
        }
    },
    data: function(){
        return{
            programmationSession:{
                affectant_id: this.$app.idUser,
                premiereDate: this.contrat && this.contrat.startDate?this.contrat.startDate:moment().format("YYYY-MM-DD"),
                heureDebut: "08:00",
                intervalleValue: 1,
                intervalleUnite: 7,
                excludeSaturday: false,
                excludeSunday: false,
                excludePublicHoliday: false,
                isMonday: false,
                isTuesday: false,
                isWednesday: false,
                isThursday: false,
                isFriday: false,
                isSaturday: false,
                isSunday: false,
                durationMinutes: 60,
                minDaysBeforeGeneratedDate: 7,
                dateAffectation: moment().format("YYYY-MM-DD HH:mm")
            },
            intervalleUnites: [
                {label: this.$t("jours"), value: 1},
                {label: this.$t("semaines"), value: 7},
                {label: this.$t("mois"), value: 30},
                {label: this.$t("trimestres"), value: 90},
                {label: this.$t("semestres"), value: 180},
                {label: this.$t("annees"), value: 365}
            ],
            isLoading:false
        };
    },
    watch: {

    },
    computed: {
        ...mapGetters({
            //getSelectedinterventionPrevisionnelleScheduling: "interventionPrevisionnelleSchedulingStore/getSelectedItem"
        }),
        isSaveDisabled: function(){
            return !this.programmationSession.premiereDate || !this.programmationSession.heureDebut || !this.programmationSession.intervalleValue
                || !this.programmationSession.intervalleUnite || !this.programmationSession.durationMinutes || !this.programmationSession.minDaysBeforeGeneratedDate;
        },
        isAnUpdateForm: function(){
            return this.programmationSession && this.programmationSession.hasOwnProperty("id");
        },
        getSessionDayOfWeek: function(){
                if(this.programmationSession.isMonday) return 1;
                else if(this.programmationSession.isTuesday) return 2;
                else if(this.programmationSession.isWednesday) return 3;
                else if(this.programmationSession.isThursday) return 4;
                else if(this.programmationSession.isFriday) return 5;
                else if(this.programmationSession.isSaturday) return 6;
                else if(this.programmationSession.isSunday) return 7;
                else return null;
            },
        getContratPeriodiciteIntervention: function(){
            let periodicite = 7;
            if(this.contrat && this.contrat.periodiciteIntervention){
                if(Number(this.contrat.periodiciteIntervention)){ 
                    periodicite = Number(this.contrat.periodiciteIntervention);
                }else{
                    switch(this.contrat.periodiciteIntervention){
                        case "semestrielle":
                            periodicite = 180;
                            break;
                        case "annuelle":
                            periodicite = 365;
                            break;
                        case "Mensuelle":
                            periodicite = 30;
                            break;
                        case "Trimestrielle":
                            periodicite = 90;
                            break;
                        case "Hebdomadaire":
                            periodicite = 7;
                            break;
                        case "Monthly":
                            periodicite = 30;
                            break;
                        case "Triannuelle":
                            periodicite = 120;
                            break;
                        case "1 Trimestre":
                            periodicite = 90;
                            break;
                        case "Weekly":
                            periodicite = 7;
                            break;
                        case "Quartely":
                            periodicite = 90;
                            break;
                        case "Bimensuelle":
                            periodicite = 15;
                            break;
                        case "2 Annuelle":
                            periodicite = 180;
                            break;
                        case "1 semaine":
                            periodicite = 7;
                            break;
                        default:
                            break;
                    }
                }
            }
            return periodicite;
        }
    },
    methods: {
        initProgrammationSession: function(){
            if(this.programmation) {
                this.programmationSession = {
                    id: this.programmation.id,
                    affectant_id: this.programmation.affectant_id,
                    premiereDate: moment(this.programmation.start).format("YYYY-MM-DD"),
                    heureDebut: moment(this.programmation.start).format("HH:mm"),
                    intervalleValue: 1,
                    intervalleUnite: 7,
                    excludeSaturday: this.programmation.excludeSaturday,
                    excludeSunday: this.programmation.excludeSunday,
                    excludePublicHoliday: this.programmation.excludePublicHoliday,
                    isMonday: this.programmation.dayOfWeek==1,
                    isTuesday: this.programmation.dayOfWeek==2,
                    isWednesday: this.programmation.dayOfWeek==3,
                    isThursday: this.programmation.dayOfWeek==4,
                    isFriday: this.programmation.dayOfWeek==5,
                    isSaturday: this.programmation.dayOfWeek==6,
                    isSunday: this.programmation.dayOfWeek==7,
                    durationMinutes: this.programmation.durationMinutes,
                    minDaysBeforeGeneratedDate: this.programmation.minDaysBeforeGeneratedDate,
                };
            }
        },
        save: function(){
            let programmation = {
                affectant_id: this.programmationSession.affectant_id,
                start: this.programmationSession.premiereDate+" "+this.programmationSession.heureDebut,
                end: moment(this.programmationSession.premiereDate+" "+this.programmationSession.heureDebut).add(this.programmationSession.durationMinutes, "minutes").format("YYYY-MM-DD HH:mm"),
                recurrence: parseInt(this.programmationSession.intervalleValue)*parseInt(this.programmationSession.intervalleUnite),
                dayOfWeek: this.getSessionDayOfWeek,
                excludeSaturday: this.programmationSession.excludeSaturday,
                excludeSunday: this.programmationSession.excludeSunday,
                excludePublicHoliday: this.programmationSession.excludePublicHoliday,
                durationMinutes: this.programmationSession.durationMinutes,
                minDaysBeforeGeneratedDate: this.programmationSession.minDaysBeforeGeneratedDate,
                userId: this.$app.appID
            };
            if(this.programmationSession.id) programmation.id = this.programmationSession.id;
            if(!this.isAnUpdateForm){
                this.$emit("save", programmation);
            }else{
                this.$emit("update", programmation);
            }

        },
        delete: function(){
            this.$emit("delete", this.formatProgrammationSessionAvantSauvegarde());
            /*this.interventionPrevisionnelleSchedulingMixins_delete(this.interventionPrevisionnelleScheduling).then((data)=>{
                this.close();
            });*/
        },
        close: function(){
            this.$emit("close");
        },
    },
	created: function(){
        this.initProgrammationSession();
        //this.interventionPrevisionnelleScheduling = Object.assign({}, this.interventionPrevisionnelleScheduling, this.getSelectedinterventionPrevisionnelleScheduling);
    },
    mounted: function(){
        if(this.id){
            //this.interventionPrevisionnelleSchedulingMixins_get(this.id).then((interventionPrevisionnelleScheduling)=>{});
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
