<template>
    <div class="operation-textuelle-cell-render">
        <span>{{params.data.operations.operation_last_manuelle}}</span>
        <vg-button
            class="vg-print-hide"
            :type="'default-info'"
            :size="'sm'"
            @click="addOperationTextuelle">
            <i class="fas fa-plus-circle"></i>
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "operation-textuelle-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true
			}
		},
        components: {
            VgButton
        },
        mixins: [

        ],
        data: function(){
            return {
                showModalAddOperation: false
            };
        },
        methods:{
            addOperationTextuelle: function(){
                this.$emit("open-operation-create", {
                    idMaintenance: this.params.data.id
                });
            }
        }
    });
</script>

<style lang="scss" scoped>
.operation-textuelle-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    >span{
        width: calc(100% - 15px);
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
