var LeFlicPlugin = {}

LeFlicPlugin.install = function install (_Vue) {
    if (install.installed) return;
    install.installed = true;
    _Vue.prototype.$leFlic = {
        controle:function(msg,...datas){
            console.log(msg,...datas);
        },
        tarreteSi:function(msg, condition){
            if(!condition){
                throw msg;
            }

        }
    }
}
export default LeFlicPlugin;
