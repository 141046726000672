<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getIconPage"
        :colorheader="getHeaderBackgroundColor"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters" >
        <template #search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>
        <template #action-button-item>
            <li @click="isTacheAffectationFormOpened=true">{{$t("progressionVerif.mobile.affectation-tache")}}</li>
        </template>
        <template #primary>
            <vg-progressions-table 
                :searchQuery="searchQuery"
                :filters="progressionsFilters"/>
        </template>
        <template #secondary-filters>
            <vg-input :title="$t('mes-assignations')" >
                <vg-checkbox
                    :defaultChecked="isMesAffectationsTaches"
                    @checked="HandleGetMyAssignement"
                    @unchecked="HandleGetAllAssignement" />
            </vg-input>
            <br>
            <vg-input v-if="categories && categories.length!=0" :title="$t('progressionVerif.mobile.filtre-categorie')" >
                <vg-select
                    v-model="filterCategorieQuery"
                    :options="categories"
                    :attributeLabel="'libelleCatgorie'"
                    :attributeValue="'libelleCatgorie'">
                    <template v-slot="{option}">
                        <div style="display:flex;justify-content:space-between;align-items:center;width:100%;padding:5px 0;">
                            <div >
                                <img v-if="option.icon" :src="option.icon" height="20" width="20" style="margin-right:10px;" />
                                <span>{{option.libelleCatgorie}}</span>
                            </div>
                            <span v-if="option.isGe!='1'" style="border:1px solid lightgray;color:lightgray;padding:0 2px;font-size:9px;">{{ $t('categorie-equipement') }}</span>
                            <span v-else style="border:1px solid lightgray;color:lightgray;padding:0 2px;font-size:9px;">{{ $t('categorie-lieu') }}</span>
                        </div>
                    </template>
                </vg-select>
            </vg-input>
            <vg-filter-site v-model="progressionsFilters.path.value" />
        </template>

        <vg-affectation-tache-users-form v-if="isTacheAffectationFormOpened"
            isModal
            @close="isTacheAffectationFormOpened=false" />

    </vg-app-layout>
</template>
<script>
import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgAffectationTacheUsersForm from "src/components/Vg/Forms/VgAffectationTacheUsersForm.vue";
const ICON_DESKTOP = "menu/progression.png";
const ICON_MOBILE = "mobile/progression-des-verifications.png";
import FiltersMixins from 'src/mixins/FiltersMixins';

import VgProgressionsTable from "src/components/Vg/Progressions/VgProgressionsTable.vue";
import { mapGetters } from 'vuex';

export default {
    name: "progressions",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Progression",
            "progression-overlay-loading-text": "Chargement de la progression...",
    		"progression-overlay-no-rows-text": "Aucune tache correspondant au filtrage",
            "mes-assignations": "Voir uniquement mes affectations",
            "categorie-equipement": "Catégorie équipement",
            "categorie-lieu": "Catégorie pièce",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progression",
                    "avantLe": "avant le",
                    "reste": "Reste",
                    "total": "Total de",
                    "zoom-equipRest": "équipement(s) ",
                    "feedback-a": "Vérifications Ok",
                    "reste-a-faire": "Reste à faire",
                    "requiert-scan": "Requiert un scan de l'équipement",
                    "filtre-lieu": "Sélectionner une pièce",
                    "filtre-categorie": "Filtrage par categorie",
                    "filtre-group": "Filtre",
                    "affectation-tache": "Affectation tâche"
                }
            },
            "modal-filters-title": "Filtrer la progression"
        },
        "en": {
            "mes-assignations": "Display only my assignements",
            "categorie-equipement": "Equipment category",
            "categorie-lieu": "Room category",
            "progressionVerif": {
                "mobile": {
                    "header-titre": "Progress",
                    "avantLe": "Before",
                    "reste": "Remaining :",
                    "total": "Total of",
                    "zoom-equipRest": "remaining equipments",
                    "feedback-a": "Verifications OK",
                    "reste-a-faire": "To do",
                    "requiert-scan": "This requires an equipment scan",
                    "filtre-lieu": "Select a room",
                    "filtre-categorie": "Filter by category",
                    "filtre-group": "Filter",
                    "affectation-tache": "Task assignment"
                }
            },
            "modal-filters-title": "Filter verifications progress"
        }
    }
},
    components:{
        VgAffectationTacheUsersForm,
        VgTextFilter,
        ActionMenuAggrid,
        VgButton,
        VgSelect,
        VgInput,
        VgGroup,
        VgFilterSite,
        VgCheckbox,
        VgProgressionsTable
    },
    mixins:[
        FiltersMixins
    ],
    filters:{
        dateEU: function(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        timeFromGivenValueToNow:function( value ){
            moment.updateLocale('en', {
                relativeTime : {
                    future: "dans %s",
                    past:   "il y a %s",
                    s:  "secondes",
                    m:  "une minute",
                    mm: "%d minutes",
                    h:  "une heure",
                    hh: "%d heures",
                    d:  "un jour",
                    dd: "%d jours",
                    M:  "un mois",
                    MM: "%d mois",
                    y:  "un an",
                    yy: "%d ans"
                }
            });
            var time = moment(value).toNow(true);
            return time;
        }
    },
    data: function() {
        return {
            isTacheAffectationFormOpened :false,
            targetedTacheState: null,
            searchQuery: null,
            filterCategorieQuery: null,
            progressionsFilters: {
                path: {attr:"path", colId: "path", value: null, action:"contains"},
                nom: {attr:"fu.nom", colId: "nom", value: null, action:"contains"},
                prenom: {attr:"fu.prenom", colId: "prenom", value: null, action:"contains"},
                isActive: {attr:"t.isActive", value:"0", action:"not_equals"},
                libelleCategorie: {attr:"c.libelleCatgorie", value:null, action:"equals"}
            }
        };
    },
    mounted: function(){
        this.FiltersMixins_page_name = "progression";
    },
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.progressionsFilters[filterName].value = null;
            });
            this.progressionsFilters.nom.value = null;
            this.progressionsFilters.prenom.value = null;
            this.searchQuery = null;
            this.filterCategorieQuery = null;
        },
        restoreFilters: function(){
            this.progressionsFilters = this.$storage.get("agFilters-progression.vue") || this.agfilters;
            this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
        },
        HandleGetMyAssignement: function(e){
            this.progressionsFilters.nom.value = this.$app.nom;
            this.progressionsFilters.prenom.value = this.$app.prenom;
        },
        HandleGetAllAssignement: function(e){
            this.progressionsFilters.nom.value = null;
            this.progressionsFilters.prenom.value = null;
        }
    },
    computed: {
        ...mapGetters({
            progressions: "ProgressionsStore/getCollection",
            categories: "ProgressionsStore/getCategories"
        }),
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.progressionsFilters[filterName].value!=null && (this.progressionsFilters[filterName].value && this.progressionsFilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.filterCategorieQuery && this.filterCategorieQuery.length!=0) nFilters += 1;
            if(this.progressionsFilters.nom.value || this.progressionsFilters.prenom.value) nFilters += 1;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgOrange : "";
        },
        getIconPage: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        },
        isMesAffectationsTaches : function(){
            return this.progressionsFilters.nom.value == null ? false : true;
        }
    },
    watch:{
        searchQuery: {
			handler: function(query){
                this.FiltersMixins_saveSearchQuery(query);
			}
		},
        filterCategorieQuery: {
            handler: function(libelleCatgorie) {
                console.log("FILTER CATEGORIE", libelleCatgorie);
                if(libelleCatgorie && libelleCatgorie.length!=0) this.progressionsFilters.libelleCategorie.value = libelleCatgorie;
                else this.progressionsFilters.libelleCategorie.value = null;
            },
            deep: true
        },
        progressionsFilters:{
            handler: function(nfilters) {
                const progressionsFilters = Object.values(nfilters);
                this.$storage.set("agFilters-progression.vue",nfilters);
            },
            deep: true
        },
    }
}
</script>
<style lang="scss" >

</style>
