<template>
	<div>
		<iframe
		id="bc-pdf-iframe"
		style="
			width: 100%;
			height: 80vh;
			border: none;
		"/>
	</div>

</template>
<script>

	const STYLES = {
		title: {
			fontWeight: 600,
			fontSize: 12,
			color: "#000"
		},
		bc: {
			attribute:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value: {
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		},
		fournisseur: {
			name: {
				fontWeight: 600,
		        fontSize: 14,
		        color: "#333"
			},
			address: {
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		},
		items: {
			header: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#959595"
			},
			cell: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#3c4042"
			}
		},
		montants: {
			label:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		}
    };
    import { mapGetters } from 'vuex';
	import Pdf from "src/services/Pdf.js";
    export default {
        name: 'mouvement-equipements-bon-de-retour',
    	props: {
            value: {
				type: Object,
				required: true
			},
			signaturesImages:{
				type: Array,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "BON DE RETOUR EQUIPEMENTS",
			"signataire-donneur":"Qui transmet le matériel",
			"signataire-receveur":"Qui reçoit le matériel",
			"date-mouvement":"Date du mouvement",
			"date-retour-prevue":"Date de retour prévue",
			"commentaire":"Commentaire",
			"lieu-destination": "Lieu destination"
        }
    }
},
        data: function() {
            return {
				pdf: null,
				positionTop: null,
				positionLeft: null,
				imgLink:null
            };
        },
		created: function(){

		},
		mounted: function(){
			this.initPdf();
			this.drawPdf();
		},
		methods: {
			initPdf: function(){
				this.pdf = new Pdf(
					window.sessionStorage.getItem("account_logo"),
					this.$app.organisation,
					null,
					this.$t("title"),
					null,
					{
					  orientation: "p",
					  format: "a4"
					}
				);
			},
			drawPdf: function(){

				this.drawMainHeader();

				// SECTION BC
			    this.drawSectionDetails();

				// FOURNISSEUR
				/*this.drawSectionFournisseur();*/

				// HEADER ITEMS
				this.drawTableHeaders();

				// ITEMS
				this.drawTableItems();
				//this.drawSectionBonPourCommande();

				// TOTAUX
				//this.drawSectionTotaux();

				// SIGNATURE
				this.drawSignature();
				document.getElementById("bc-pdf-iframe").setAttribute("src",this.pdf.pdf.output("bloburl"));
				this.emitGeneratedPdfDatas();
			},
			drawSignature: function(){
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.pdf.addNewTextLine();
				this.positionTop = this.pdf.getCurrentYPosition();
				this.positionLeft = this.pdf.getCurrentXPosition();
				this.signaturesImages.forEach((signature,key) => {
									
					if(signature.img){ 
						if(key > 0){
							this.positionLeft = this.pdf.getCurrentXPosition()+100;
							
							this.pdf.setCurrentXPosition(this.positionLeft);
						}
						this.pdf.addText(signature.name, STYLES.title);
						this.pdf.addImage(signature.img, "PNG", this.positionLeft, this.positionTop);
					}
					/*this.positionTop = this.pdf.getCurrentXPosition();
					this.positionLeft = this.pdf.getCurrentYPosition();

					this.pdf.addText(this.$t("signataire")+": "+signature.name, STYLES.title);
					this.positionTop = this.pdf.getCurrentYPosition();
					this.positionLeft = this.pdf.getCurrentXPosition();*/
				});
				/*this.pdf.addText(this.$t("signataire")+": "+this.value.signataireDonneur, STYLES.title);
				if(this.signatureImage) this.pdf.addImage(this.signatureImage, "PNG", 10, this.positionTop);*/
			},
			emitGeneratedPdfDatas: function(){
				let pdfDatas = this.pdf.pdf.output("blob", {
					filename: this.$app.organisation+"_"+this.$t("title")+"_"+moment().format("YYYY-MM-DD")+".pdf"
				});
				this.$emit("generated-pdf-datas", pdfDatas);
			},
			drawMainHeader: function(){
				// HEADER
				this.pdf.setCurrentXPosition(75);
				this.pdf.setCurrentYPosition(30);
				this.pdf.addText(this.$t("details-mouvement"), STYLES.title);
			},
			drawSectionDetails: function(){
				this.pdf.addNewTextLine();
				this.pdf.addCurrentYPosition(10);
				this.positionTop = this.pdf.getCurrentYPosition();
				let largeurLibelleSectionDetails = 55;
                let mouvement = this.value;
				let labels = [
                    this.$t("signataire-receveur"),
                    this.$t("signataire-donneur"),
                    this.$t("type"),
                    this.$t("date-mouvement"),
                    //this.$t("date-retour-prevu"),
					this.$t("commentaire")
                    
                ];

				let values = [
						mouvement.signataireReceveur,
                        mouvement.signataireDonneur,
						mouvement.type,
						mouvement.dateMouvement,
						//mouvement.dateRetourPrevu,
						mouvement.commentaire
						
					];
				/*if(mouvement.destination &&  mouvement.origine.path){
					labels.push(this.$t("lieu-origine"));
					values.push(mouvement.origine.path);
				}*/
				if(mouvement.destination &&  mouvement.destination.path){
					labels.push(this.$t("lieu-destination"));
					values.push(mouvement.destination.path);
				}

                this.pdf.addList(labels, STYLES.bc.attribute);

				this.pdf.setCurrentXPosition(largeurLibelleSectionDetails);
				this.pdf.setCurrentYPosition(this.positionTop);
				this.pdf.addList(values, STYLES.bc.value);
			},
			drawSectionFournisseur: function(){
				let largeurSectionDetails = 130;
				this.pdf.setCurrentXPosition(largeurSectionDetails);
				this.pdf.setCurrentYPosition(this.positionTop);
				this.pdf.addText(this.bonDeCommande.fournisseur.name, STYLES.fournisseur.name);
				// FOURNISSEUR ADDRESS
				this.pdf.setCurrentXPosition(largeurSectionDetails);
				this.pdf.addCurrentYPosition(this.pdf.getSpaceBetweenLines());
				this.pdf.addText(this.bonDeCommande.fournisseur.address, STYLES.fournisseur.address, 60);
			},
			drawTableHeaders: function(){
				this.pdf.addCurrentYPosition(20);
				this.positionLeft = 10;

				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addText(this.$t("libel-equipement"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+38);
				this.pdf.addText(this.$t("categorie"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+60);
				this.pdf.addText(this.$t("qrCode"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+100);
				this.pdf.addText(this.$t("lieu-origine"), STYLES.items.header);
				/*this.pdf.setCurrentXPosition(this.positionLeft+135);
				this.pdf.addText(this.$t("bc-items-header-pu-ht"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+155);
				this.pdf.addText(this.$t("bc-items-header-montant-ht"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+180);
				this.pdf.addText(this.$t("bc-items-header-tva"), STYLES.items.header);*/

				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addCurrentYPosition(3);
				this.pdf.addLine();
			},
			drawTableItems: function(){
				this.positionLeft = this.pdf.getCurrentXPosition();
				let nextPositionTop = this.pdf.getCurrentYPosition()+3;
				let hasMultipleLine = false;
				this.value.equipements.forEach((item)=>{
					this.pdf.setCurrentYPosition(nextPositionTop);
					this.pdf.addCurrentYPosition(this.pdf.getSpaceBetweenLines()/2);
					this.positionTop = this.pdf.getCurrentYPosition();
					// libel_equipement
					// ref
					this.pdf.setCurrentXPosition(this.positionLeft);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(item.libel_equipement?item.libel_equipement:"-", STYLES.items.cell, 26);
					hasMultipleLine = this.pdf.getCurrentYPosition()!=this.positionTop;
					if(hasMultipleLine) nextPositionTop = this.pdf.getCurrentYPosition();
					else nextPositionTop = this.positionTop;
					// libel categorie
					this.pdf.setCurrentXPosition(this.positionLeft+38);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(item.categorie.libelle, STYLES.items.cell, 80);
					if(!hasMultipleLine || (this.pdf.getCurrentYPosition()!=this.positionTop && nextPositionTop<this.pdf.getCurrentYPosition())){
						hasMultipleLine = this.pdf.getCurrentYPosition()!=this.positionTop;
						if(hasMultipleLine) nextPositionTop = this.pdf.getCurrentYPosition();
						else nextPositionTop = this.positionTop;
					}
					// qrCode
					this.pdf.setCurrentXPosition(this.positionLeft+60);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(item.qrCode, STYLES.items.cell);

					// lieu
					this.pdf.setCurrentXPosition(this.positionLeft+100);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(item.lieu.path, STYLES.items.cell);
					/*// PuHT
					this.pdf.setCurrentXPosition(this.positionLeft+135);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(Number.parseFloat(item.puHT).toFixed(2), STYLES.items.cell);
					// montant HT
					this.pdf.setCurrentXPosition(this.positionLeft+155);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(Number.parseFloat(item.totalHT).toFixed(2), STYLES.items.cell);
					// TVA
					this.pdf.setCurrentXPosition(this.positionLeft+180);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(Number.parseFloat(item.tva.value).toFixed(1), STYLES.items.cell);*/
				});
			},
			drawSectionBonPourCommande: function(){
				this.pdf.setCurrentXPosition(120);
				this.positionTop = this.pdf._bodyBottomLimit()-50;
				this.pdf.setCurrentYPosition(this.positionTop);
				let historiqueValidationComplete = this.bonDeCommandeHistorique.find((historique)=>historique.flag=='bc-validation-complete');
				if(historiqueValidationComplete){	//moment(historiqueValidationComplete.dateCreation).format("DD/MM/YYYY")
					this.pdf.addText(this.$t("bon-pour-commande-le")+" "+moment().format("DD/MM/YYYY"), STYLES.items.cell);
				}
			},
			drawValidations: function(){
				this.pdf.setCurrentXPosition(10);
				this.positionTop = this.pdf._bodyBottomLimit()-50;
				this.pdf.setCurrentYPosition(this.positionTop);

				this.pdf.addText(this.value.signataireDonneur, STYLES.items.cell);
				this.pdf.setCurrentYPosition(this.positionTop+5);
				this.pdf.setCurrentXPosition(10);

				this.pdf.addText(this.value.signataireReceveur, STYLES.items.cell);
				this.pdf.setCurrentYPosition(this.positionTop+5);
				this.pdf.setCurrentXPosition(10);
			},
			drawSectionTotaux: function(){
				this.pdf.setCurrentXPosition(140);
				this.positionTop = this.pdf._bodyBottomLimit()-30;
				this.pdf.setCurrentYPosition(this.positionTop);
				this.pdf.addList([
					this.$t("bc-montant-ht")+" : ",
					this.$t("bc-montant-tva")+" : ",
					this.$t("bc-montant-ttc")+" : "
				], STYLES.montants.label);
				this.pdf.setCurrentXPosition(this.pdf._bodyRightLimit()-4);
				this.pdf.setCurrentYPosition(this.positionTop);
				this.pdf.addList([
					Number.parseFloat(this.bonDeCommande.montantHT).toFixed(2),
					Number.parseFloat(this.bonDeCommande.montantTVA).toFixed(2),
					Number.parseFloat(this.bonDeCommande.montantTTC).toFixed(2)
				], STYLES.montants.value, {textAlignRight: true});
			},
		},
        computed: {

			getLogo: function(){
				return window.sessionStorage.getItem("account_logo");
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
