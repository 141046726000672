<template>
    <div class="vg-statistiques-verification-dashboard">
        <div v-if="showLeftPanel" class="charts-left-panel">
            <!-- LEGENDS -->
            <div class="chart-legend">
                <div v-for="stat in statistiquesBarEtatLegends">
                    <div v-if="stat.label" class="chart-legend-item">
                        <div class="rond" :style="'backgroundColor:'+stat.color"></div>
                        {{stat.label}}
                    </div>
                </div>
            </div>
            <vg-filter-site
                v-model="filtreSites"
                :multiple="true"
                :display="'modal'"
                :isAllSitesSelected="true"
                @input="onChangeFiltreSites">
            </vg-filter-site>
        </div>
        <div :class="['charts-container',{'width-100-percent':!showLeftPanel}]">
            <div class="chart-section">
                <vg-bar-chart class="chart-bar"
                    :chart-id="'statistiques-etat-annuelle'"
                    :height="'250px'"
                    :datas="getStatistiquesAnnuelle"
                    :horizontalaxis="yearXAxis"
                    :options="optionsBarStatistiques"
                    :showlegend="false"
                    @selected="onSelectedMonthlyBar">
                </vg-bar-chart>
                <div class="chart-selector">
                    <vg-year-selector
                        v-model="period"
                        @input="onInputPeriod">
                    </vg-year-selector>
                </div>
            </div>
            <br>
            <div class="chart-section">
                <vg-bar-chart class="chart-bar"
                    :chart-id="'statistiques-etat-hebdomadaire'"
                    :height="'150px'"
                    :datas="getStatistiquesHebdomadaire"
                    :horizontalaxis="weekXAxis"
                    :options="optionsBarStatistiques"
                    :showlegend="false"
                    @selected="onSelectedDailyBar">
                </vg-bar-chart>
                <div class="chart-selector">
                    <vg-week-selector
                        v-model="period"
                        @input="onInputPeriod">
                    </vg-week-selector>
                </div>
            </div>
        </div>
        <vg-modal v-if="showVerificationsListModal"
            :width="'80vw'"
            :height="'75vh'"
            @close="onCloseMaintenancesListModal">
            <template #header>
                <span><b>{{focusedChartItem.label}}</b> {{$t("du")}} {{intervalDate.start | readableDate}} {{$t("au")}} {{intervalDate.end | readableDate}}</span>
            </template>
            <template v-slot:body>
                <vg-verifications-viewer
                    :filters="verificationFilters">
                </vg-verifications-viewer>
            </template>
            <template v-slot:footer>
                <vg-button
                    type="default"
                    @click="onCloseMaintenancesListModal">
                    {{$t("fermer")}}
                </vg-button>
                <vg-button
                    type="success"
                    @click="printingSheet = true;"
                    :disabled="!(maintenances && maintenances.length!=0)">
                    {{$t("generer-rapport")}}
                </vg-button>
                <vg-button
                    type="success"
                    @click="exportCSV()">
                    {{$t("export-csv")}}
                </vg-button>
            </template>
        </vg-modal>
    </div>
</template>

<script>
import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
import VgYearSelector from 'src/views/Statistiques/components/VgYearSelector.vue';
import VgWeekSelector from 'src/views/Statistiques/components/VgWeekSelector.vue';
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgButton from 'src/components/Vg/VgButton.vue';
import VgVerificationsViewer from "src/components/Vg/Verification/VgVerificationsViewer.vue";

import StatistiquesMixins from 'src/mixins/StatistiquesMixins.js';

export default {
    name: "vg-statistiques-verification-dashboard",
    components:{
        VgBarChart,
        VgYearSelector,
        VgWeekSelector,
        VgFilterSite,
        VgTabs,

        VgButton,
        VgVerificationsViewer
    },
    mixins: [StatistiquesMixins],
    props: {
        filters: {
            type: Array,
            default: function(){
                return [];
            }
        },
        showLeftPanel: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            period: {
				year: moment().year(),
				week: moment().week()
			},
            statistiquesAnnuelle: [],
            statistiquesHebdomadaire: [],
            filtreSites: null,
            yearXAxis: [this.$t('janvier'),this.$t('fevrier'),this.$t('mars'),this.$t('avril'),this.$t('mai'),this.$t('juin'),this.$t('juillet'),this.$t('aout'),this.$t('septembre'),this.$t('octobre'),this.$t('novembre'),this.$t('decembre')],
			weekXAxis: [this.$t('lundi'),this.$t('mardi'),this.$t('mercredi'),this.$t('jeudi'),this.$t('vendredi'),this.$t('samedi'),this.$t('dimanche')],
			optionsBarStatistiques: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						display: false,
						gridLines: {
							display: true
						},
						stacked: true
					}],
					xAxes: [{
						gridLines: {
							display: false
						},
						ticks: {
							mirror: true
						},
						stacked: true
					}]
				},
				animation: {
					easing: "linear",
					onProgress: (e)=>this.drawCustomBarElements(e),
					onComplete: (e)=>this.drawCustomBarElements(e)
				}
			},
            showVerificationsListModal: false,
            focusedChartItem: null,
            verificationFilters: [],
            searchQuery: "",
			printingSheet: false,
            tacheFilter: null
        }
    },
    filters:{
		readableDate: function(d){
			return moment(d).format("DD/MM/YYYY");
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "ouvertures": "Ouvertures",
            "fermetures": "Fermetures",
            "modal-details-title": "Activité curative",
            "du": "du",
            "au": "au",
            "fermer": "Fermer",
            "generer-rapport": "Générer rapport pdf",
            "export-csv": "Export csv",
            "legend-label-compteurs": "Compteurs",
            "legend-label-lieux": "Lieux",
            "legend-label-equipements": "Equipements",
            "legend-label-grandeurs": "Grandeurs physique",
            "janvier": "Janvier",
            "fevrier": "Février",
            "mars": "Mars",
            "avril": "Avril",
            "mai": "Mai",
            "juin": "Juin",
            "juillet": "Juillet",
            "aout": "Août",
            "septembre": "Septembre",
            "octobre": "Octobre",
            "novembre": "Novembre",
            "decembre": "Décembre",
            "lundi": "Lundi",
            "mardi": "Mardi",
            "mercredi": "Mercredi",
            "jeudi": "Jeudi",
            "vendredi": "Vendredi",
            "samedi": "Samedi",
            "dimanche": "Dimanche",
            "relever-compteur-conformes": "Relevés compteurs conformes",
            "relever-compteur-non-conformes": "Relevés compteurs non conformes",
            "verification-lieu-conformes": "Vérifications lieux conformes",
            "verification-lieu-non-conformes": "Vérifications lieux non conformes",
            "verification-equipement-conformes": "Vérifications équipements conformes",
            "verification-equipement-non-conformes": "Vérifications équipements non conformes",
            "relever-grandeur-physique-conformes": "Relevés grandeur physique conformes",
            "relever-grandeur-physique-non-conformes": "Relevés grandeur physique non conformes",
            "verifications-conformes": "Vérifications conformes",
            "verifications-non-conformes": "Vérifications non conformes"
        },
        "en": {
            "ouvertures": "Opened",
            "fermetures": "Closed",
            "modal-details-title": "Curative activity",
            "du": "from",
            "au": "to",
            "fermer": "Close",
            "generer-rapport": "Create PDF",
            "export-csv": "Export csv",
            "legend-label-compteurs": "Counters",
            "legend-label-lieux": "Location",
            "legend-label-equipements": "Equipments",
            "legend-label-grandeurs": "Physical value",
            "janvier": "January",
            "fevrier": "February",
            "mars": "March",
            "avril": "April",
            "mai": "May",
            "juin": "June",
            "juillet": "July",
            "aout": "August",
            "septembre": "September",
            "octobre": "October",
            "novembre": "November",
            "decembre": "December",
            "lundi": "Monday",
            "mardi": "Tuesday",
            "mercredi": "Wednesday",
            "jeudi": "Thursday",
            "vendredi": "Friday",
            "samedi": "Saturday",
            "dimanche": "Sunday",
            "relever-compteur-conformes": "Compliant meter readings check",
            "relever-compteur-non-conformes": "Non compliant meter readings check",
            "verification-lieu-conformes": "Compliant location verification",
            "verification-lieu-non-conformes": "Non compliant location check",
            "verification-equipement-conformes": "Compliant equipment check",
            "verification-equipement-non-conformes": "Non compliant equipment check",
            "relever-grandeur-physique-conformes": "Compliant physical value check",
            "relever-grandeur-physique-non-conformes": "Non compliant physical value check",
            "verifications-conformes": "Compliant check",
            "verifications-non-conformes": "Non compliant check"
        },
        "th": {
            "ouvertures": "เปิดแล้ว",
            "fermetures": "ปิดแล้ว",
            "du": "ตั้งแต่",
            "au": "ถึง",
            "fermer": "ปิด",
            "generer-rapport": "สร้าง PDF",
            "export-csv": "ส่งออก csv",
            "legend-label-compteurs": "เคาน์เตอร์",
            "legend-label-lieux": "ที่ตั้ง",
            "legend-label-equipements": "อุปกรณ์",
            "legend-label-grandeurs": "มูลค่าทางกายภาพ",
            "janvier": "มกราคม",
            "fevrier": "กุมภาพันธ์",
            "mars": "มีนาคม",
            "avril": "เมษายน",
            "mai": "พฤษภาคม",
            "juin": "มิถุนายน",
            "juillet": "กรกฏาคม",
            "aout": "สิงหาคม",
            "septembre": "กันยายน",
            "octobre": "ตุลาคม",
            "novembre": "พฤศจิกายน",
            "decembre": "ธันวาคม",
            "lundi": "วันจันทร์",
            "mardi": "วันอังคาร",
            "mercredi": "วันพุธ",
            "jeudi": "วันพฤหัสบดี",
            "vendredi": "วันศุกร์",
            "samedi": "วันเสาร์",
            "dimanche": "วันอาทิตย์",
            "relever-compteur-conformes": "การตรวจสอบการอ่านมิเตอร์ที่ถูกร้องเรียน",
            "relever-compteur-non-conformes": "การตรวจสอบการอ่านมิเตอร์ที่ไม่ถูกร้องเรียน",
            "verification-lieu-conformes": "การตรวจสอบที่ตั้งที่ถูกร้องเรียน",
            "verification-lieu-non-conformes": "การตรวจสอบที่ตั้งที่ไม่ถูกร้องเรียน",
            "verification-equipement-conformes": "การตรวจสอบอุปกรณ์ที่ถูกร้องเรียน",
            "verification-equipement-non-conformes": "การตรวจสอบอุปกรณ์ที่ไม่ถูกร้องเรียน",
            "relever-grandeur-physique-conformes": "การตรวจสอบมูลค่าทางกายภาพที่ถูกร้องเรียน",
            "relever-grandeur-physique-non-conformes": "การตรวจสอบมูลค่าทางกายภาพที่ไม่ถูกร้องเรียน",
            "verifications-conformes": "การตรวจสอบการร้องเรียน",
            "verifications-non-conformes": "การตรวจสอบการไม่ร้องเรียน"
        }
    }
},
    created: function(){
        this.fetch();
    },
    methods: {
        onInputPeriod: function(period){
			this.fetch();
		},
        exportCSV: function(){
			window.dispatchEvent(new CustomEvent("askForCSV", {
				"detail": {"idTableau": "vg-verifications-table"}
			}));
		},
        onCloseMaintenancesListModal: function(){
			this.showVerificationsListModal = false;
			this.verificationFilters = [];
			this.focusedChartItem = null;
		},
        drawCustomBarElements: function(e){
			let ctx = e.chart.ctx;
			let datasets = e.chart.config.data.datasets;
			let meta = null;
            // draw bar legend
            let getAlias = (stack)=>{
                if(stack=="Relever_Grandeur_physique") return "GP";
                else if(stack=="Relever_compteur") return "C";
                else if(stack=="Verification_Lieu") return "L";
                else if(stack=="Verification_equipement") return "E";
                return "";
            };
            datasets.forEach((dataset)=>{
                meta = Object.values(dataset._meta)[0];
                meta.data.forEach((bar)=>{
                    if(datasets[bar._datasetIndex].data[bar._index]!=0){	// si bar non vide
                        ctx.beginPath();
                        ctx.fillStyle = this.statistiquesBarEtatLegends[datasets[bar._datasetIndex].value].color;
                        ctx.strokeStyle = this.statistiquesBarEtatLegends[datasets[bar._datasetIndex].value].color;
                        ctx.arc(bar._model.x, bar._yScale.height-10, 4, 0, 2 * Math.PI, false);
                        ctx.fill();
                        ctx.stroke();
                        ctx.closePath();
                    }
                });
            });
			// draw x axis line
			ctx.beginPath();
			ctx.moveTo(0, ctx.canvas.height-25);
			ctx.lineTo(ctx.canvas.width, ctx.canvas.height-25);
			ctx.closePath();
			ctx.lineWidth = 1;
			ctx.strokeStyle = "#787878";
			ctx.stroke();
		},
        /**
		* @param string start date debut
		* @param string end date fin
		*/
		showStatistiquesList: function(start, end){
			this.verificationFilters.push({"attr":"et.dateVerif","value":[start, end],"action":"between"});
            if(this.filtreSites && this.filtreSites.length!=0){
                this.verificationFilters.push({"attr":"l.path","value":this.filtreSites,"action":"start_with"});
            }
			this.showVerificationsListModal = true;
		},
		onSelectedMonthlyBar: function(e){
			let statistiques = this.getStatistiquesAnnuelle;
			this.focusedChartItem = statistiques[e.datasetIndex];
			this.verificationFilters = this.focusedChartItem.filters || [];
			let monthFirstDay = moment(this.period.year+"-"+(e.index+1)+"-01").format("YYYY-MM-DD");
			let monthLastDay = moment(this.period.year+"-"+(e.index+1)+"-01").endOf("month").format("YYYY-MM-DD");
            if(this.filters && this.filters.length!=0) this.verificationFilters = this.verificationFilters.concat(this.filters);
            this.showStatistiquesList(monthFirstDay, monthLastDay);
		},
		onSelectedDailyBar: function(e){
			let statistiques = this.getStatistiquesHebdomadaire;
			this.focusedChartItem = statistiques[e.datasetIndex];
			this.verificationFilters = this.focusedChartItem.filters || [];
			let day = moment().year(this.period.year).week(this.period.week).isoWeekday(e.index+1).format("YYYY/MM/DD");
            if(this.filters && this.filters.length!=0) this.verificationFilters = this.verificationFilters.concat(this.filters);
            this.showStatistiquesList(day+" 00:00:00", day+" 23:59:59");
		},
        onChangeFiltreSites: function(sites){
            this.fetch();
        },
        fetch: function(){
            this.StatistiquesMixins_fetchStatistiquesVerificationEtat(this.period.year, null, this.getEtatMetadatas).then((statistiques)=>{
                this.statistiquesAnnuelle = statistiques;
            });
            this.StatistiquesMixins_fetchStatistiquesVerificationEtat(this.period.year, this.period.week, this.getEtatMetadatas).then((statistiques)=>{
                this.statistiquesHebdomadaire = statistiques;
            });
		},
    },
    computed: {
        intervalDate: function(){
			return {
				start: this.verificationFilters[this.verificationFilters.length-1].value[0],
				end: this.verificationFilters[this.verificationFilters.length-1].value[1]
			};
		},
        getEtatMetadatas: function(){
            if(this.filtreSites && this.filtreSites.length!=0) return {"directives":[],"filters":[
                {"attr":"l.path","value":this.filtreSites,"action":"start_with"}
            ]};
			return {"directives":[],"filters":[]};
		},
        getStatistiquesAnnuelle: function(){
			if(!this.tacheFilter){
				let normalizedStatistiques = {
					"Relever_compteur": {
						conformes: { label: this.$t("relever-compteur-conformes"), value: "Relever_compteur", backgroundColor: "#84e6cd", data: [], stack: "Relever_compteur", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
					 	filters: [
							{"attr":"t.type_tache","value":"Relever_compteur","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						] },
						nonconformes: { label: this.$t("relever-compteur-non-conformes"), value: "Relever_compteur", backgroundColor: "#f0c0c0", data: [], stack: "Relever_compteur", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Relever_compteur","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						] }
					},
					"Verification_Lieu": {
						conformes: { label: this.$t("verification-lieu-conformes"), value: "Verification_Lieu", backgroundColor: "#84e6cd", data: [], stack: "Verification_Lieu", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Verification_Lieu","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						] },
						nonconformes: { label: this.$t("verification-lieu-non-conformes"), value: "Verification_Lieu", backgroundColor: "#f0c0c0", data: [], stack: "Verification_Lieu", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Verification_Lieu","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						] }
					},
					"Verification_equipement": {
						conformes: { label: this.$t("verification-equipement-conformes"), value: "Verification_equipement", backgroundColor: "#84e6cd", data: [], stack: "Verification_equipement", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Verification_equipement","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]},
						nonconformes: { label: this.$t("verification-equipement-non-conformes"), value: "Verification_equipement", backgroundColor: "#f0c0c0", data: [], stack: "Verification_equipement", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Verification_equipement","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						]}
					},
					"Relever_Grandeur_physique": {
						conformes: { label: this.$t("relever-grandeur-physique-conformes"), value: "Relever_Grandeur_physique", backgroundColor: "#84e6cd", data: [], stack: "Relever_Grandeur_physique", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Relever_Grandeur_physique","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]},
						nonconformes: { label: this.$t("relever-grandeur-physique-non-conformes"), value: "Relever_Grandeur_physique", backgroundColor: "#f0c0c0", data: [], stack: "Relever_Grandeur_physique", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
						filters: [
							{"attr":"t.type_tache","value":"Relever_Grandeur_physique","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						] }
					}
				};
				let statistiquesAnnuelle = this.statistiquesAnnuelle;
				for(let key in statistiquesAnnuelle){
					normalizedStatistiques["Relever_compteur"]["conformes"].data.push(statistiquesAnnuelle[key]["Relever_compteur"]["conformes"]);
					normalizedStatistiques["Relever_compteur"]["nonconformes"].data.push(statistiquesAnnuelle[key]["Relever_compteur"]["nonconformes"]);
					normalizedStatistiques["Verification_Lieu"]["conformes"].data.push(statistiquesAnnuelle[key]["Verification_Lieu"]["conformes"]);
					normalizedStatistiques["Verification_Lieu"]["nonconformes"].data.push(statistiquesAnnuelle[key]["Verification_Lieu"]["nonconformes"]);
					normalizedStatistiques["Verification_equipement"]["conformes"].data.push(statistiquesAnnuelle[key]["Verification_equipement"]["conformes"]);
					normalizedStatistiques["Verification_equipement"]["nonconformes"].data.push(statistiquesAnnuelle[key]["Verification_equipement"]["nonconformes"]);
					normalizedStatistiques["Relever_Grandeur_physique"]["conformes"].data.push(statistiquesAnnuelle[key]["Relever_Grandeur_physique"]["conformes"]);
					normalizedStatistiques["Relever_Grandeur_physique"]["nonconformes"].data.push(statistiquesAnnuelle[key]["Relever_Grandeur_physique"]["nonconformes"]);
				}
				normalizedStatistiques = Object.values(normalizedStatistiques);
				let result = [];
				normalizedStatistiques.map((stat)=>{
					result.push(stat.conformes);
					result.push(stat.nonconformes);
				});
				return result;
			}else{
				let normalizedStatistiques = {
					conformes: { label: this.$t("verifications-conformes"), value: this.tacheFilter.typeTache, backgroundColor: "#84e6cd", data: [], stack:"conforme", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
					filters: [
						{"attr":"t.id","value":this.tacheFilter.id,"action":"equals"},
						{"attr":"et.nbNonConformites","value":0,"action":"equals"}
					]},
					nonconformes: { label: this.$t("verifications-non-conformes"), value: this.tacheFilter.typeTache, backgroundColor: "#f0c0c0", data: [], stack:"nonconforme", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
					filters: [
						{"attr":"t.id","value":this.tacheFilter.id,"action":"equals"},
						{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
					] }
				};
				let statistiquesAnnuelle = this.statistiquesAnnuelle;
				for(let key in statistiquesAnnuelle){
					normalizedStatistiques["conformes"].data.push(statistiquesAnnuelle[key][this.tacheFilter.typeTache]["conformes"]);
					normalizedStatistiques["nonconformes"].data.push(statistiquesAnnuelle[key][this.tacheFilter.typeTache]["nonconformes"]);

				}
				return Object.values(normalizedStatistiques);
			}
		},
        getStatistiquesHebdomadaire: function(){
			if(!this.tacheFilter){
				let normalizedStatistiques = {
					"Relever_compteur": {
						conformes: { label: this.$t("relever-compteur-conformes"), value: "Relever_compteur", backgroundColor: "#84e6cd", data: [], stack: "Relever_compteur", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#FF8C00", legendLabel: this.$t("legend-label-compteurs"),
					 	filters: [
							{"attr":"t.type_tache","value":"Relever_compteur","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]  },
						nonconformes: { label: this.$t("relever-compteur-non-conformes"), value: "Relever_compteur", backgroundColor: "#f0c0c0", data: [], stack: "Relever_compteur", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#FF8C00", legendLabel: this.$t("legend-label-compteurs"),
					 	filters: [
							{"attr":"t.type_tache","value":"Relever_compteur","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						]  }
					},
					"Verification_Lieu": {
						conformes: { label: this.$t("verification-lieu-conformes"), value: "Verification_Lieu", backgroundColor: "#84e6cd", data: [], stack: "Verification_Lieu", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#1E90FF", legendLabel: this.$t("legend-label-lieux"),
					 	filters: [
							{"attr":"t.type_tache","value":"Verification_Lieu","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]  },
						nonconformes: { label: this.$t("verification-lieu-non-conformes"), value: "Verification_Lieu", backgroundColor: "#f0c0c0", data: [], stack: "Verification_Lieu", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#1E90FF", legendLabel: this.$t("legend-label-lieux"),
					 	filters: [
							{"attr":"t.type_tache","value":"Verification_Lieu","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						]  }
					},
					"Verification_equipement": {
						conformes: { label: this.$t("verification-equipement-conformes"), value: "Verification_equipement", backgroundColor: "#84e6cd", data: [], stack: "Verification_equipement", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#2E8B57", legendLabel: this.$t("legend-label-equipements"),
					 	filters: [
							{"attr":"t.type_tache","value":"Verification_equipement","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]  },
						nonconformes: { label: this.$t("verification-equipement-non-conformes"), value: "Verification_equipement", backgroundColor: "#f0c0c0", data: [], stack: "Verification_equipement", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#2E8B57", legendLabel: this.$t("legend-label-equipements"),
					 	filters: [
							{"attr":"t.type_tache","value":"Verification_equipement","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						]  }
					},
					"Relever_Grandeur_physique": {
						conformes: { label: this.$t("relever-grandeur-physique-conformes"), value: "Relever_Grandeur_physique", backgroundColor: "#84e6cd", data: [], stack: "Relever_Grandeur_physique", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#8A2BE2", legendLabel: this.$t("legend-label-grandeurs"),
					 	filters: [
							{"attr":"t.type_tache","value":"Relever_Grandeur_physique","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"equals"}
						]  },
						nonconformes: { label: this.$t("relever-grandeur-physique-non-conformes"), value: "Relever_Grandeur_physique", backgroundColor: "#f0c0c0", data: [], stack: "Relever_Grandeur_physique", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1, legendColor: "#8A2BE2", legendLabel: this.$t("legend-label-grandeurs"),
					 	filters: [
							{"attr":"t.type_tache","value":"Relever_Grandeur_physique","action":"equals"},
							{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
						]  }
					}
				};
				let statistiquesHebdomadaire = this.statistiquesHebdomadaire;
				for(let key in statistiquesHebdomadaire){
					normalizedStatistiques["Relever_compteur"]["conformes"].data.push(statistiquesHebdomadaire[key]["Relever_compteur"]["conformes"]);
					normalizedStatistiques["Relever_compteur"]["nonconformes"].data.push(statistiquesHebdomadaire[key]["Relever_compteur"]["nonconformes"]);
					normalizedStatistiques["Verification_Lieu"]["conformes"].data.push(statistiquesHebdomadaire[key]["Verification_Lieu"]["conformes"]);
					normalizedStatistiques["Verification_Lieu"]["nonconformes"].data.push(statistiquesHebdomadaire[key]["Verification_Lieu"]["nonconformes"]);
					normalizedStatistiques["Verification_equipement"]["conformes"].data.push(statistiquesHebdomadaire[key]["Verification_equipement"]["conformes"]);
					normalizedStatistiques["Verification_equipement"]["nonconformes"].data.push(statistiquesHebdomadaire[key]["Verification_equipement"]["nonconformes"]);
					normalizedStatistiques["Relever_Grandeur_physique"]["conformes"].data.push(statistiquesHebdomadaire[key]["Relever_Grandeur_physique"]["conformes"]);
					normalizedStatistiques["Relever_Grandeur_physique"]["nonconformes"].data.push(statistiquesHebdomadaire[key]["Relever_Grandeur_physique"]["nonconformes"]);
				}
				normalizedStatistiques = Object.values(normalizedStatistiques);
				let result = [];
				normalizedStatistiques.map((stat)=>{
					result.push(stat.conformes);
					result.push(stat.nonconformes);
				});
				return result;
			}else{
				let normalizedStatistiques = {
					conformes: { label: this.$t("verifications-conformes"), value: this.tacheFilter.typeTache, backgroundColor: "#84e6cd", data: [], stack:"conforme", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
					filters: [
						{"attr":"t.id","value":this.tacheFilter.id,"action":"equals"},
						{"attr":"et.nbNonConformites","value":0,"action":"equals"}
					] },
					nonconformes: { label: this.$t("verifications-non-conformes"), value: this.tacheFilter.typeTache, backgroundColor: "#f0c0c0", data: [], stack:"nonconforme", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
					filters: [
						{"attr":"t.id","value":this.tacheFilter.id,"action":"equals"},
						{"attr":"et.nbNonConformites","value":0,"action":"not_equals"}
					] }
				};
				let statistiquesHebdomadaire = this.statistiquesHebdomadaire;
				for(let key in statistiquesHebdomadaire){
					normalizedStatistiques["conformes"].data.push(statistiquesHebdomadaire[key][this.tacheFilter.typeTache]["conformes"]);
					normalizedStatistiques["nonconformes"].data.push(statistiquesHebdomadaire[key][this.tacheFilter.typeTache]["nonconformes"]);
				}
				return Object.values(normalizedStatistiques);
			}
		},
        statistiquesBarEtatLegends: function(){
			return {
                "Relever_compteur": { color: "#FF8C00", label: this.$t("legend-label-compteurs") },
				"Verification_Lieu": { color: "#1E90FF", label: this.$t("legend-label-lieux") },
				"Verification_equipement": { color: "#2E8B57", label: this.$t("legend-label-equipements") },
				"Relever_Grandeur_physique": { color: "#8A2BE2", label: this.$t("legend-label-grandeurs") }
			};
		},
    }
}
</script>

<style lang="scss" scoped>
.vg-statistiques-verification-dashboard{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    height: 100%;
    .charts-left-panel{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 20%;
        height: 100%;
        border-right: 1px solid #ccc;
        padding:0 20px;
        .chart-legend{
    		.chart-legend-item{
    			display: flex;
    			justify-content: flex-start;
    			align-items: center;
                .rond{
    				width: 12px;
    				height: 12px;
    				border-radius: 50%;
    				margin-right: 10px;
    			}
    		}
    	}
    }
    .charts-container{
		width: 80%;
		min-width: 400px;
		height: 100%;
		align-items: center;
        .chart-section{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.chart-bar{
				width: 100%;
                padding: 40px;
			}
            .chart-selector{
                width: 200px;
    			text-align: center;
    		}
		}
	}
    .width-100-percent{
        width: 100% !important;
    }
}
</style>
