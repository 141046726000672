//Notifications store module

// initial state
const state = {
    currentView : '',
    lastEquipmentSelected : {},
    lastTachesFetched : [],
    lastLieuSelected:{},
    lastCheckpointSelected:[],
    equipementsInLastPlaceVisited:[]
};

// getters
const getters = {
    getCurrentView: function( state ){
      return state.currentView;
    },
    getLastEquipmentSelected: function( state ){
      return state.lastEquipmentSelected;
    },
    getLastTachesFetched: function( state ){
      return state.lastTachesFetched;
    },
    getLastLieuxSelected: function( state ){
      return state.lastLieuSelected;
    },
    getLastCheckpointSelected: function( state ){
      return state.lastCheckpointSelected;
    },
    getEquipementsInLastPlaceVisited: function(){
        return state.equipementsInLastPlaceVisited;
    }
};

// actions
const actions = {
    appStateUpdateCurrentView: function( context, view ){
        context.commit('APP_UPDATE_CURRENT_VIEW', view);
    },
    appStateSetLastEquipmentSelected: function( context, equipement){
         context.commit('APP_SET_LAST_EQUIPEMENT_SELECTED', equipement);
    },
    appStateSetLastTachesFetched: function( context, taches){
         context.commit('APP_SET_LAST_TACHES_FETCHED', taches);
    },
    appStateSetLastLieuxSelected: function( context, taches){
         context.commit('APP_SET_LAST_LIEUX_SELECTED', taches);
    },
    appStateSetLastCheckpointSelected: function( context, checkpoint){
         context.commit('APP_SET_LAST_CHECKPOINT_SELECTED', checkpoint);
    },
    appStateEquipementsInLastPlaceVisited: function( context, equipements){
         context.commit('APP_SET_EQUIPEMENTS_IN_LAST_PIECE_VISITED', equipements);
    }
};

// mutations
const mutations = {
    APP_UPDATE_CURRENT_VIEW: function( state, view ){
        state.currentView = view;
    },
    APP_SET_LAST_EQUIPEMENT_SELECTED: function( state, equipement ){
        state.lastEquipmentSelected = equipement;
    },
    APP_SET_LAST_TACHES_FETCHED: function( state, taches ){
        state.lastTachesFetched = taches;
    },
    APP_SET_LAST_LIEUX_SELECTED:function(state, lieu){
        state.lastLieuSelected = lieu;
    },
    APP_SET_LAST_CHECKPOINT_SELECTED:function(state, checkpoint){
        state.lastCheckpointSelected = checkpoint;
    },
    APP_SET_EQUIPEMENTS_IN_LAST_PIECE_VISITED:function(state,equipements){
        state.equipementsInLastPlaceVisited = equipements;
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};
