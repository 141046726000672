<template>
    <div id="zxing-scanner"  v-if="!personnalParameters_getUseIntegratedScanerInKeyboard()">
        <!--button @click="lightTorch(true)" >On</button>
        <button @click="lightTorch(false)" >Off</button>

        <button @click="lightTorchB(true)" >One</button>

        <button @click="lightTorchC(true)" >Two</button-->

        <!--video id="video" class="video_box" width="100%" height="70%" style="border: 1px solid gray"></video-->

        <!--div style="display:flex;justify-content:center;">
            <div>
                <i v-if="selectedCodeReader == 'qrCode'" class="fas fa-qrcode"></i>
                <i v-else class="fas fa-barcode"></i>
                {{$t("cadrer-code")}} {{this.selectedCodeReader}}
            </div>
        </div-->
        <video id="video"></video>
        <div style="display:flex;justify-content:space-between;align-items:center;width:100%;margin:10px 0 0 10px;">
            <vg-button type="grey" @click="setBarcodeReader()" style="width:50%;display:flex;justify-content:center;align-items:center;"> <i class="fas fa-barcode" style="margin-right:5px;"></i> <span>{{$t("code-barre")}}</span></vg-button>
            <vg-button type="grey" @click="setQrCodeReader()" style="width:50%;display:flex;justify-content:center;align-items:center;"> <i class="fas fa-qrcode" style="margin-right:5px;"></i> <span>{{$t("qrcode")}}</span></vg-button>
        </div>
    </div>
    <div v-else class="scannerClav">
        <div class="scannerClav-Etp1">

            <div class="second-text">
                {{ $t("QrCodeScanner.utiliserScannerClavier") }}
            </div>

            <i class="fa fa-arrow-down fa-3x"></i>
            <div class="qrCode-input">
                <input  v-model="resultText" autofocus="true"  :placeholder="$t('QrCodeScanner.placeholder-valeurQrCode')" >
            </div>
        </div>
        <div class="scannerClav-Etp2">

            <vg-button @click="emitSuccess" type="success" :disabled="resultText ==''" >{{ $t("QrCodeScanner.validerSaisie") }}</vg-button>
        </div>
        <!-- <button class="btn btn-primary" @click="emitSuccess"  ><i class="fa fa-arrow-right" aria-hidden="true"></i></button> -->
    </div>
</template>

<script>
//import * as ScanditSDK from "scandit-sdk";
import * as ZXing from '@zxing/library';
import PersonalParameters from 'src/mixins/personnalParameters';
import VgButton from "src/components/Vg/VgButton.vue";
export default {
    name:'zxing-scanner',
	mixins:[PersonalParameters],
    components:{
        VgButton
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "code-barre": "Code barre",
            "qrcode": "QrCode",
            "QrCodeScanner": {
                "utiliserScannerClavier": "Utiliser le scanner du clavier",
                "placeholder-valeurQrCode": "Valeur code",
                "validerSaisie": "Valider la saisie"
            }
        },
        "en": {
            "QrCodeScanner": {
                "utiliserScannerClavier": "Use keyboard scanner",
                "placeholder-valeurQrCode": "Code value",
                "validerSaisie": "Validate "
            }
        },
        "th": {
            "QrCodeScanner": {
                "utiliserScannerClavier": "ใช้สแกนเนอร์แป้นพิมพ์",
                "placeholder-valeurQrCode": "ค่า โค้ด",
                "validerSaisie": "ตรวจสอบความถูกต้อง "
            }
        }
    }
},
    props:{
        onScanSuccess:Function,
        isDemo:{
            type:Boolean,
            default:false
        },
        code:{
            type:String,
            default:"qrcode"
        }
    },
    data:function(){
        return{
            selectedCodeReader:"qrCode",
            codeReader:null,
            trackVideo:null,
            resultText:""

        }
    },
    created:function(){
    },
    beforeDestroy:function(){
        this.codeReader.reset();
        this.codeReader = null;
        this.trackVideo = null;
    },
    methods:{
        initTorch:function(bool){
            //Test browser support
            const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;
            if (SUPPORTS_MEDIA_DEVICES) {
              var that = this;
              //Get the environment camera (usually the second one)
              navigator.mediaDevices.enumerateDevices().then(devices => {

                var cameras = devices.filter((device) => device.kind === 'videoinput');
                if (cameras.length === 0) {
                  throw 'No camera found on this device.';
                  this.$emit("error",{message:"No camera found on this device."});
                  return false;
                }
                var camera = cameras[cameras.length - 1];
                // Create stream and get video track
                navigator.mediaDevices.getUserMedia({
                  video: {
                    deviceId: camera.deviceId,
                    facingMode: ['user', 'environment'],
                    height: {ideal: 1080},
                    width: {ideal: 1920}
                  }
                }).then(stream => {
                    // // //console.log("Got stream : ",stream);
                    // // //console.log("that : ",that);
                    // // //console.log("stream.getVideoTracks() :",stream.getVideoTracks());
                  var track = stream.getVideoTracks()[0];
                  that.trackVideo = track;
                });
              });

              //The light will be on as long the track exists
            }

        },
        emitSuccess:function(){
            if(!this.resultText)return;
             this.$emit('scanSuccess',this.resultText);
        },
        initCodeReader:function(){
            if(this.codeReader != null){
                this.codeReader.reset();
                this.codeReader = null;
            }
            var that = this;
            var codeReader = this.code == "qrcode" ? new ZXing.BrowserQRCodeReader() : new ZXing.BrowserBarcodeReader() ;
            codeReader.getVideoInputDevices()
            .then(videoInputDevices => {
                /*videoInputDevices.forEach(
                    device => alert(`${device.label}`)
                );*/
                if (videoInputDevices.length === 0) {
                  throw 'No camera found on this device.';
                  this.$emit("error",{message:"No camera found on this device."});
                  return false;
                }
                const secondDeviceId = videoInputDevices[videoInputDevices.length-1].deviceId;
                codeReader.decodeFromInputVideoDevice(secondDeviceId, 'video')
                    .then(
                        function(result){
                            var audio = new Audio('/static/assets/sounds/beep-07.wav');
                            audio.play();
                            // // //console.log("scan success : ",result.text);
                            that.$emit("scanSuccess",result.text);
                            that.resultText = result.text;

                            /*that.trackVideo = null;*/
                            that.onScanSuccess && that.onScanSuccess(result);
                            codeReader.reset();
                            //that.codeReader = null;
                        }
                    )
                    .catch(err => alert(err));
            })
            .catch(err => console.error(err));
            //this.initTorch(); //init torch before using it
            this.codeReader = codeReader;
        },
        setBarcodeReader:function(){
            this.code = "barcode";
            this.selectedCodeReader="barcode";
            this.initCodeReader();
        },
        setQrCodeReader:function(){
            this.code = "qrcode";
            this.selectedCodeReader="qrCode";
            this.initCodeReader();
        },
        lightTorch:function(bool){
            var that = this;
            if(!bool){
                that.trackVideo.applyConstraints({
                    advanced: [{torch: false}]
                });
                this.trackVideo = null;
                this.trackVideo = this.initTorch();
                return false;
            }else{
                var imageCapture = new ImageCapture(this.trackVideo);
                var photoCapabilities = imageCapture.getPhotoCapabilities().then(() => {
                    that.trackVideo.applyConstraints({
                        advanced: [{torch: true}]
                    });
                });
                return true;
            }
        }
    },
    mounted:function(){
        this.initCodeReader();
    }
}
</script>
<style lang="scss" scoped>
#zxing-scanner{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}
#video{
    width:100%;
    height:100%;
    background-color: whitesmoke;
}
#video_overlays {
    opacity: 0.6;
    z-index: 25;
}

.scannerNav{
    .scannerNav-Viewer{
    	border: 1px solid grey;
        width: 30vh;
        height: 48vh;
        margin: auto;
        video{
            height: 100%;
            width: 100%;
        }
    }
    .scannerNav-Test{
      width: 100%;
      height: 100%;
      .scannerNav-Viewer{
      	border: 1px solid grey;
        width: 100%;
        height: 100%;
      }
    }
    .scannerNav-text{
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: bold;
    }
    .scannerNav-Button{
        text-align: center;
        margin-top: 30px;
        button{
            font-weight: bold;
            width: 150px;
            height: 30px;
            padding: 0px 15px;
            border-color:grey;
            border-radius: 0px;

        }
    }
}


.scannerClav{
    margin-top: 50px;
    .scannerClav-Etp1{
        text-align: center;
        margin-bottom: 50px;
        i{
            color:#FF6633;
            margin-bottom:20px;

        }
        img{
            height: 24px;
            width: 24px;
        }
        .first-text{
            margin-bottom:3px;
            margin-top:10px;
            font-size: 16px;

        }
        .second-text{
            color: #FF6633;
            font-size: 12px;
            margin-bottom:20px;
        }
        .qrCode-input{
            margin-top: 10px;
            input{
                width: 100%;
                text-align: center;
                border-color:grey;
            }
        }
    }
    .scannerClav-Etp2{
        text-align: center;
        margin-bottom: 20px;
        .btnValid{
            width: 170px;
            background-color: #FFCC99;
            border-color: #FF6633
        }
    }
}
</style>
