<template>
    <vg-app-layout
        :title="getTitle"
        :icon="'parametre.png'"
        :isActionButtonDisplay="false"
        :isLoadingState="isLoading">
        <template #create-button>
            <vg-button v-show="totalSelectedRows" size="xs" @click="showCategorieAssignement = true">{{ $t("assigner-categorie") }}</vg-button>
            <vg-button v-show="totalSelectedRows" size="xs" @click="showServiceAssignement = true">{{ $t("assigner-service") }}</vg-button>             
        </template>
        <template #panel-menu-more>

        </template>
        <template #primary>
            <!-- un hack poutr nourrir le store categories car le selecteur dans la boucle for ne doit surtout pas executer les fetchs en boucles -->
            <vg-sites-selector v-show="selectedSite == null && lieuxAEnvoyer.length" @after-feeded="initSite"/>
            <vg-categorie-selector v-show="false" :clearable="false" isGe style="width:100%;"/>
            <vg-service-selector v-show="false"  />

            <label v-show="!lieuxAEnvoyer.length" for="file-input" class="label-file">
                <i class="fa fa-file" style="color:grey;" aria-hidden="true"></i>
                {{ $t("choix-fichier") }} <br>
                <div style="font-size: 10px;margin: 9px 100px;color:grey;">OU</div>
                <vg-input :placeholder="'lien http vers fichier ifc'" v-model="urlFichierIfc"  @input="onInputUrl"/>
            </label>
            <input v-show="!lieuxAEnvoyer.length" id="file-input" class="file-input" type="file" @change="onInputFile">
            <div class="chips">
                <div class="chip" style="270px; !important" v-show="getOnlyPieceWithoutCategory">
                    <div class="chip-content"> 
                        <div style="font-size:11px;color:grey;"></div> 
                        <div style="font-weight: 600;">{{ $t("voir-pieces-sans-categorie") }} </div>
                    </div>
                    <div>
                        
                        <i @click="getOnlyPieceWithoutCategory = false;isCheckAllChecked = false;uncheckAll()" class="fa fa-times clickable" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="chip" v-show="search">
                    <div class="chip-content"> 
                        <div style="font-size:11px;color:grey;">{{ $t("search") }} ::</div> 
                        <div style="font-weight: 600;">{{ search }} </div>
                    </div>
                    <div>
                    
                        <i @click="search = null;isCheckAllChecked = false;uncheckAll()" class="fa fa-times clickable" aria-hidden="true"></i>
                    </div>
                </div>
                
                <div class="chip" v-show="serviceFilterString">
                    <div class="chip-content"> 
                        <div style="font-size:11px;color:grey;">{{ $t("search-service") }} ::</div> 
                        <div style="font-weight: 600;">{{ serviceFilterString }} </div>
                    </div>
                    <div>
                        
                        <i @click="serviceFilterString = null;isCheckAllChecked = false;uncheckAll()" class="fa fa-times clickable" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="chip" v-show="categoryFilterString">
                    <div class="chip-content"> 
                        <div style="font-size:11px;color:grey;">{{ $t("search-category") }} ::</div> 
                        <div style="font-weight: 600;" >{{ categoryFilterString }} </div>
                    </div>
                    <div>
                        
                        <i @click="categoryFilterString = null;isCheckAllChecked = false;uncheckAll()" class="fa fa-times clickable" aria-hidden="true"></i>
                    </div>
                </div>

                
            </div>


            <div id="lieux-a-envoyer" v-show="lieuxAEnvoyer.length" >
                <table id="ifc-to-pieces-table" @click="setLibelLieuFilter">
                    
                    <tr>
                        <th colspan="6" style="font-weight: 100;text-align: right;" >

                                    <b>{{ totalSelectedRows }}</b>/ {{ lieuxAEnvoyer.length }} {{ $t("pieces-selectionnees") }}
                            <vg-button v-show="search && search != ''" type="info" size="xs" @click="isCheckAllChecked = true;checkAll()">{{ $t("check-all") }}</vg-button>
                            <vg-button v-show="search && search != ''" type="info" size="xs" @click="isCheckAllChecked = false;uncheckAll()">{{ $t("uncheck-all") }}</vg-button>
                            <vg-button v-show="totalSelectedRows" size="xs" @click="showCategorieAssignement = true">{{ $t("assigner-categorie") }}</vg-button>
                            <vg-button v-show="totalSelectedRows" size="xs" @click="showServiceAssignement = true">{{ $t("assigner-service") }}</vg-button>
                            <!--vg-button v-show="totalSelectedRows == 1" @click="displayIfcViewer = true">{{ $t("assigner-piece-sur-modele-3d") }}</vg-button-->

                        
                        </th>
                    </tr>
                    <tr>
                        <th style="text-align: center;" >
                            <input type="checkbox" class="selectable" v-model="isCheckAllChecked" name="check-all" @change="SelectAllRows" />
                        </th>
                        <th>{{ $t("batiment") }}</th>
                        <th>{{ $t("etage") }}</th>
                        <th>{{ $t("piece") }}</th>
                        <th>{{ $t("categorie") }}</th>
                        <th>{{ $t("service") }}</th>
                    </tr>
                        <tr v-for="(lieu, key) in FilteredLieuxAEnvoyer" :key="key">
                        <td style="text-align: center;">
                            <input type="checkbox"  name="check-it"  v-model="lieu.selected">
                        </td>
                        <td>{{ lieu.path.split("/")[0] }}</td>
                        <td>{{ lieu.path.split("/")[1] }}</td>
                        <td>
                            <div  > 
                                <span> {{ lieu.path.split("/")[2].split("-")[0] }}</span>  
                                <span class="clickable" > {{ lieu.path.split("/")[2].split("-")[1] }}</span>  
                            </div>
                        </td>
                        <td>
                            {{ lieu.categorie }}
                        </td>
                        <td>
                            {{ lieu.service }}
                        </td>
                    </tr>
                    <tr v-show="!FilteredLieuxAEnvoyer.length" >
                        <td colspan="6" style="text-align: center;padding: 25px;font-size: medium;">{{ $t("no-rows") }}</td>
                    </tr>
                </table>

            </div>

            <vg-modal 
                v-if="showCategorieAssignement"
                :title="$t('assigner-categorie')"
                @close="showCategorieAssignement=false;">
                <template #body>
                    <vg-categorie-selector 
                        :fetchIfCategorieStoreIsEmpty="false" 
                        :attributeValue="'libelleCatgorie'" 
                        :clearable="false" 
                        isGe 
                        style="width:100%;margin-bottom:15px;" 
                        @change="setCategorieOnSelectedPieces"
                        key="showCategorieAssignement-setCategorieOnSelectedPieces"
                    />
                    <vg-button @click="displayCategorieForm = true" key="add-categorie-button"> <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; {{ $t("add-categorie") }}</vg-button>

                </template>
                <template #footer>
                    <vg-button @click="showCategorieAssignement=false;">
                        {{$t('close')}}
                    </vg-button>
                </template>
            </vg-modal>

            <vg-modal 
                v-if="showServiceAssignement"
                :title="$t('assigner-service')"
                @close="showServiceAssignement=false;">
                <template #body>
                    <vg-service-selector 
                        :fetchIfStoreIsEmpty="false" 
                        :clearable="false"
                        style="width:100%;margin-bottom:15px;" 
                        @change="setServiceOnSelectedPieces"
                        key="showServiceAssignement-setServiceOnSelectedPieces"
                    />
                </template>
                <template #footer>
                    <vg-button @click="showCategorieAssignement=false;">
                        {{$t('close')}}
                    </vg-button>
                </template>
            </vg-modal>

            <vg-categorie-form v-show="displayCategorieForm" 
                :isGe="true" 
                @save="setCreatedCategorieOnSelectedPieces"
                @close="displayCategorieForm"
                key="add-categorie-form"
            />
        </template>

        <template #secondary-filters v-if="lieuxAEnvoyer.length" >
                <vg-input :title="$t('rechercher-par-libel-piece')" :placeholder="$t('rechercher-par-libel-piece')" v-model="search" :clearable="true"/>
                <vg-button v-show="search && search != ''" type="info" size="xs" @click="isCheckAllChecked = true;checkAll()">{{ $t("check-all") }}</vg-button>
                <vg-button v-show="search && search != ''" type="info" size="xs" @click="isCheckAllChecked = false;uncheckAll()">{{ $t("uncheck-all") }}</vg-button>
                <vg-button v-show="totalSelectedRows" size="xs" @click="showCategorieAssignement = true">{{ $t("assigner-categorie") }}</vg-button>
                <vg-button v-show="totalSelectedRows" size="xs" @click="showServiceAssignement = true">{{ $t("assigner-service") }}</vg-button>             

                <!--vg-button v-show="totalSelectedRows == 1" size="xs" @click="displayIfcViewer = true">{{ $t("assigner-piece-sur-modele-3d") }}</vg-button-->

                <vg-input>
                    <vg-checkbox style="margin:40px 0 20px 0;font-weight:normal !important;"
                        :label="$t('voir-pieces-sans-categorie')"
                        v-model="getOnlyPieceWithoutCategory"
                        @checked="getOnlyPieceWithoutCategory=true"
                        @unchecked="getOnlyPieceWithoutCategory=false"
                        />
                </vg-input>
                <vg-input
                    :title="$t('rechercher-par-categorie')"
                >
                    <vg-categorie-selector 
                        :fetchIfCategorieStoreIsEmpty="false" 
                        isGe 
                        :attributeValue="'libelleCatgorie'"
                        style="width:100%;" 
                        v-model="categoryFilterString"
                        key="selector-categoryFilterString"
                    />
                </vg-input>

                <vg-input
                    :title="$t('rechercher-par-service')"
                >
                    <vg-service-selector 
                        :fetchIfStoreIsEmpty="false" 
                        v-model="serviceFilterString"
                        key="selector-serviceFilterString"
                    />
                </vg-input>
                <vg-button type="success" @click="send" >{{ $t("terminer-importation") }}</vg-button>
                
        </template>


    </vg-app-layout>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import IfcMixins from 'src/mixins/IfcMixins.js';
import LieuMixins from 'src/mixins/LieuMixins.js';
import VgCategorieSelector from 'src/components/Vg/Selectors/VgCategorieSelector.vue';
import VgServiceSelector from 'src/components/Vg/Selectors/VgServiceSelector.vue';
import VgSitesSelector from 'src/components/Vg/Selectors/VgSitesSelector.vue';
import VgCategorieForm from 'src/components/Vg/Forms/VgCategorieForm.vue';

export default {
	name: "ifc-importer",
	components:{
		VgInput,
		VgTabs,
        VgButton,
        VgCategorieSelector,
        VgServiceSelector,
        VgSitesSelector,
        VgCheckbox,
        VgCategorieForm
	},
	mixins:[IfcMixins, LieuMixins],
	data: function () {
		return {
            lieuxAEnvoyer:[],
            selectedSite: null,
            getOnlyPieceWithoutCategory:false,
            search:"",
            categoryFilterString:"",
            serviceFilterString:"",
            showCategorieAssignement:false,
            showServiceAssignement:false,
            displayIfcViewer:false,
            isCheckAllChecked:false,
            isLoading:false,
            isValid:false,
            displayCategorieForm:false,
            urlFichierIfc:null
        };
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "assigner-categorie":"Assigner une catégorie",
            "assigner-service":"Définir le service",
            "pieces-selectionnees":"Pièces sélectionnées",
            "rechercher-par-categorie":"Recherche par catégorie",
            "rechercher-par-service":"Recherche par service",
            "uncheck-all":"Tout déselectionné",
            "voir-pieces-sans-categorie":"Voir uniquement pièces non catégorisée",
            "rechercher-par-libel-piece":"Recherche par libeller pièce",
            "site":"Site",
            "batiment":"Bâtiment",
            "etage":"Etage",
            "piece":"Pièce",
            "categorie":"Catégorie",
            "service":"Service",
            "choix-fichier":"Choisir un fichier ifc",
            "assigner-piece-sur-modele-3d":"définir localisation sur le modèle 3D",
            "terminer-importation":"Valider et envoyer",
            "categorie-requise":"La catégorie est requise",
            "check-all":"Tout sélectionné",
            "search-category":"categorie",
            "search-service":"service",
            "search":"recherche",
            "no-rows":"Aucune pièce(s) pour le critère de filtrage courant",
            "add-categorie":"Créer une catégorie non présente dans la liste ci dessus",
            "title":"Intégration bâtiment à partir d'un fichier ifc sur le site : "
        },
        "en": {
            "assigner-categorie":"Assign a category",
            "assigner-service":"Set department",
            "pieces-selectionnees":"Selected rooms",
            "rechercher-par-categorie":"Search by category",
            "rechercher-par-service":"Search by department",
            "uncheck-all":"Deselect all",
            "voir-pieces-sans-categorie":"Display only uncategorized room",
            "rechercher-par-libel-piece":"Search by room label",
            "site":"Site",
            "batiment":"Building",
            "etage":"Level",
            "piece":"Room",
            "categorie":"Category",
            "service":"Department",
            "choix-fichier":"Choose an ifc file",
            "assigner-piece-sur-modele-3d":"Set location on the 3d model",
            "terminer-importation":"Valider et envoyer",
            "categorie-requise":"Category required",
            "check-all":"Select all",
            "search-category":"category",
            "search-service":"department",
            "search":"search",
            "no-rows":"No rooms with current filters",
            "add-categorie":"Create a category not in the above list",
            "title":"Create building from an ifc file on site : "
        }
    }
},
	watch: {

	},
	created: function () {
        //let ifcURL = URL.createObjectURL(file);
        let url = "https://raw.githubusercontent.com/buildingSMART/Sample-Test-Files/master/IFC%202x3/Medical-Dental%20Clinic/Clinic_Architectural.ifc";

	},
	mounted: function () {
        console.log(this.$app);
	},
	methods:{
        onInputFile:function(event){
            let ifcURL = URL.createObjectURL(event.target.files[0]);
            this.init(ifcURL);
        },
        onInputUrl:function(event){
            this.init(this.urlFichierIfc);
        },
        init:function(url){
            this.lieuxAEnvoyer = [];
            this.isLoading = true;
            this.IfcMixins_readfile(url).then((ifcText)=>{
            let extractor = this.IfcMixins_getIFCDatasExtractor(ifcText);
            extractor.init().then(()=>{
                    let levels = extractor.getLevels();
                    let tree = this.generateBaseTree(levels);
                    this.flattenBaseTree(tree);
                    this.isLoading = false;
                });
            });
        },
        generateBaseTree: function(ifcLevels){
            let tree = {};
            ifcLevels.forEach(level => {
                let buildingName = level.building.Name && buildingName != "" ? level.building.Name : "Batiment" ;
                if(!tree[buildingName]) tree[buildingName] = {};
                tree[buildingName][level.name] = this.getPieces(level);
            });
            return tree;
        },
        getPieces: function(levelStorey){
            let children = levelStorey.children;
            let ifcSpaces = [];
            children.forEach((entity)=>{
                if(entity.type == "IFCSPACE"){
                    ifcSpaces.push(
                        {
                            "Name":entity.Name.value,
                            "LongName":entity.LongName.value.replace("/","|"),
                            "expressID":entity.expressID,
                            "categorie":null,
                            "service":null,
                            "gobalId":entity.gobalId
                        }
                    );
                }
            });
            return ifcSpaces;
        },
        flattenBaseTree: function(batiments){
            let lieux = [];
            let batimentsNames = Object.keys(batiments);
            batimentsNames.forEach((batiment)=>{
                let etages = Object.keys(batiments[batiment]);
                etages.forEach((etage)=>{
                    let pieces = batiments[batiment][etage];
                    pieces.forEach((piece)=>{
                        lieux.push(
                            {
                                "selected":false,
                                "path": `${batiment}/${etage}/${piece.Name}-${piece.LongName}`,
                                "categorie":null,
                                "service":null,
                                "expressId": piece.expressID,

                            }
                        )
                    });
                });
            })
            this.lieuxAEnvoyer = lieux;
            return lieux;
        },
        checkAll:function(){
            this.FilteredLieuxAEnvoyer.forEach((lieu)=>{
                lieu.selected = true;
            });
        },
        uncheckAll:function(){
            this.FilteredLieuxAEnvoyer.forEach((lieu)=>{
                lieu.selected = false;
            });
        },
        SelectAllRows:function(evt){
            let isChecked = evt.target.checked;
            if(isChecked){
                this.checkAll();
            }else{
                this.uncheckAll();
            }
        },
        setCategorieOnSelectedPieces: function(categoryName){
            let lieux = this.lieuxAEnvoyer;
            lieux.forEach((piece)=>{
                if(piece.selected){
                    piece.categorie = categoryName;
                    piece.selected = false;
                }

            });
            this.showCategorieAssignement = false;
            this.isCheckAllChecked = false;
        },
        setServiceOnSelectedPieces: function(serviceName){
            console.log({serviceName});
            let lieux = this.lieuxAEnvoyer;
            lieux.forEach((piece)=>{
                if(piece.selected){
                    piece.service = serviceName;
                    piece.selected = false;
                }

            });
            this.showServiceAssignement = false;
            this.isCheckAllChecked = false;
        },
        validate :function(){
            let lieux = this.lieuxAEnvoyer.filter(piece => piece.categorie == null);
            return this.selectedSite != null && lieux.length == 0;
        },
        initSite:function(sites){
            if(sites.length == 1){
                this.selectedSite = sites[0]["libel_lieu"];
            }
        },
        setSite:function(site){
            this.lieuxAEnvoyer.forEach((piece)=>{
                piece.path = this.$app.organisation+"/"+site+"/"+piece.path;
            });
        },
        getCsv:function(lieuxAEnvoyer){
            return [
                [
                "organisation",
                "site",
                "batiment",
                "etage",
                "libel_lieu",
                "categorie",
                "service"
                ],
                ...lieuxAEnvoyer.map((item) => {
                    let organisation = item.path.split("/")[0];
                    let site = item.path.split("/")[1];
                    let batiment = item.path.split("/")[2];
                    let etage = item.path.split("/")[3];
                    let piece = item.path.split("/")[4];
                    
                    return [
                        organisation,
                        site,
                        batiment,
                        etage,
                        piece,
                        item.categorie,
                        item.service,
                        item.expressId
                    ]
            })
            ]
            .map(e => e.join(",")) 
            .join("\n");
        },
        saveInLocalDB:function(lieux){
            lieux.forEach(lieu => {
                lieu.userId = this.$app.userId;
            });
            //let db = this.$storage.indexDb.getManager();
            this.$storage.indexDb.add("lieux",lieux);
            /*db.transaction('rw', db.lieux, function () {
                db.lieux.clear();
                console.log ("Successfully deleted  lieux");
                db.lieux.bulkAdd(lieux).then(()=>{
                    console.log("inserted")
                });
            }).catch (e => {
                console.error (e);
            });*/

        },
        send:function(){
            let isValid = this.validate();
            this.saveInLocalDB(this.lieuxAEnvoyer);
            this.setSite(this.selectedSite);
            
            //isValid ? this.LieuMixins_importPieces(this.getCsv(this.lieuxAEnvoyer)) : null;
        },
        setLibelLieuFilter:function(evt){
            let completeLibelLieu = evt.srcElement.innerText;
            //let splittedLibelLieu = completeLibelLieu.split("-");
            if(evt.srcElement._prevClass == "clickable"){
                this.search = completeLibelLieu;
                this.isCheckAllChecked = true;
                this.checkAll();
            }
        },
        setCreatedCategorieOnSelectedPieces:function(categorie){
            this.setCategorieOnSelectedPieces(categorie.libelleCatgorie);
            this.displayCategorieForm = false;
        }
	},
	computed:{
        FilteredLieuxAEnvoyer:function(){
            let lieux = this.lieuxAEnvoyer;
            this.isLoading = true;
            this.isCheckAllChecked = false;
            if(this.search && this.search != ""){ 
                lieux = lieux.filter(piece => piece.path.indexOf(this.search) != -1 );
            }
            if(this.getOnlyPieceWithoutCategory){
                lieux = lieux.filter(piece => piece.categorie == null );
            }
            if(this.categoryFilterString && this.categoryFilterString != "" ){
                lieux = lieux.filter(piece => piece.categorie == this.categoryFilterString);
            }
            if(this.serviceFilterString && this.serviceFilterString != "" ){
                lieux = lieux.filter(piece => piece.categorie == this.serviceFilterString);
            }
            this.isLoading = false;
            return lieux;
        },
        totalSelectedRows: function(){
            return this.lieuxAEnvoyer.filter(piece => piece.selected == true ).length;
        },
        getTitle:function(){
            return this.selectedSite ? this.$t('title') + this.selectedSite : "";
        }
    }
}
</script>

<style lang="scss" scopped>

#ifc-to-pieces-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    tr{
        
        /* padding: 40px; */
        color: grey;
        font-weight: 100 !important;
    }
    th{
        background-color: rgb(244, 246, 247);
    }
    td, th {
        border: 1px solid rgb(212, 218, 224);
        text-align: left;
        padding: 4px;
    }
}
.selectable{
    cursor: pointer;
}
.clickable{
    cursor: pointer;
    border-bottom: 2px dotted #ceceed;
}
.clickable:hover{
        color:#e91e63;
    }
.chips{
    display:flex;
    gap: 5px;
    margin-bottom: 5px;
}
.chip{
    /*width: 200px;*/
    background-color: #ebcf2a;
    border-radius: 8px;
    padding: 3px 10px;
    display: flex;
    gap: 5px;
    justify-content: space-around;
}
.chip-content{
    display: flex;
    align-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    gap:10px;
}
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
    border: 1px solid rgb(203, 207, 209);
    padding: 10px;
    border-color: #cad1d3;
    margin:auto;
}
.label-file:hover {
    color: #6b6f70;
}

// et on masque le input
.file-input {
    display: none;
}
</style>
