<template>
	<iframe
	  id="interventions-previsionnelles-pdf-iframe"
	  style="
		width: 100%;
		height: 400px;
		border: none;
	  "
	></iframe>
</template>
<script>
	import Metadatas from "src/services/Metadatas.js";
	import Pdf from "src/services/Pdf.js";

	import { mapGetters } from 'vuex';

	const STYLES = {
		title: {
			fontWeight: 600,
			fontSize: 12,
			color: "#000"
		},
		contrat: {
			attribute:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value: {
				fontWeight: 600,
		        fontSize: 11,
		        color: "#000"
			}
		},
		fournisseur: {
			name: {
				fontWeight: 600,
		        fontSize: 14,
		        color: "#333"
			},
			address: {
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		},
		items: {
			header: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#959595"
			},
			cell: {
				fontWeight: 100,
		        fontSize: 10,
		        color: "#3c4042"
			}
		},
		montants: {
			label:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#959595"
			},
			value:{
				fontWeight: 100,
		        fontSize: 11,
		        color: "#3c4042"
			}
		}
    };

    export default {
        name: 'vg-interventions-previsionnelles-pdf',
    	props: {
            contrat: {
				type: Object,
				required: true
			},
			interventions:{
				type: Array,
				required: true
			},
			directDownload:{
				type: Boolean,
				default: false
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Transmission liste des dates prévisionnelles d'intervention",
            "entreprise": "Entreprise:",
            "contrat": "Contrat:",
            "numero-contrat": "Numéro contrat:",
            "header-date-previsionnelle": "Date prévisionnelle intervention",
            "header-intitule": "Intitulé",
            "header-commentaire": "Commentaire",
            "footer-date-rapport": "Date rapport: {dateRapport}",
            "pdf-title": "{organisation}_{tiersName}_interventions_{date}"
        }
    }
},
        data: function() {
            return {
				pdf: null,
				positionTop: null,
				positionLeft: null
            };
        },
		created: function(){

		},
		mounted: function(){
			this.initPdf();
			this.drawPdf();
		},
		methods: {
			initPdf: function(){
				this.pdf = new Pdf(
					window.sessionStorage.getItem("account_logo"),
					this.$app.organisation,
					null,
					this.$t("footer-date-rapport", {dateRapport: moment().format("DD/MM/YYYY")}),
					null,
					{
					  orientation: "landscape",
					  format: "a4"
					}
				);
			},
			drawPdf: function(){

				this.drawMainHeader();

				this.drawDetails();

				this.drawTableHeaders();

				this.drawTableItems();

				document.getElementById("interventions-previsionnelles-pdf-iframe").setAttribute("src", this.pdf.pdf.output("bloburl"));

				this.emitGeneratedPdfDatas();
				if(this.directDownload) this.directDownloadPdf();
			},
			emitGeneratedPdfDatas: function(){
				let pdfDatas = this.pdf.pdf.output("blob", {
					filename: this.$t("pdf-title", {organisation: this.$app.organisation, tiersName: this.contrat.tiers.name, date: moment().format("YYYY-MM-DD")})+".pdf"
				});
				this.$emit("generated-pdf-datas", pdfDatas);
			},
			directDownloadPdf: function(){
				let fileName = this.$t("pdf-title", {organisation: this.$app.organisation, tiersName: this.contrat.tiers.name, date: moment().format("YYYY-MM-DD")})+".pdf";
				this.pdf.pdf.output("save", fileName);
				this.$emit("after-direct-download", fileName);
			},
			drawMainHeader: function(){
				// HEADER
				this.pdf.setCurrentXPosition(100);
				this.pdf.setCurrentYPosition(25);
				this.pdf.addText(this.$t("title"), STYLES.title);
			},
			drawDetails: function(){
				this.pdf.addNewTextLine();
				this.pdf.addCurrentYPosition(5);
				this.positionTop = this.pdf.getCurrentYPosition();
				let largeurLibelleSectionDetails = 55;
				this.pdf.addList([
					this.$t("entreprise"),
					this.$t("contrat"),
					this.$t("numero-contrat")
				], STYLES.contrat.attribute);
				this.pdf.setCurrentXPosition(largeurLibelleSectionDetails);
				this.pdf.setCurrentYPosition(this.positionTop);
				this.pdf.addList([
					this.contrat.tiers.name,
					this.contrat.name,
					this.contrat.number
				], STYLES.contrat.value);
			},
			drawTableHeaders: function(){
				this.pdf.addCurrentYPosition(10);
				this.positionLeft = 10;
				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addText(this.$t("header-date-previsionnelle"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+70);
				this.pdf.addText(this.$t("header-intitule"), STYLES.items.header);
				this.pdf.setCurrentXPosition(this.positionLeft+150);
				this.pdf.addText(this.$t("header-commentaire"), STYLES.items.header);

				this.pdf.setCurrentXPosition(this.positionLeft);
				this.pdf.addCurrentYPosition(3);
				this.pdf.addLine();
			},
			drawTableItems: function(){
				this.positionLeft = this.pdf.getCurrentXPosition();
				let nextPositionTop = this.pdf.getCurrentYPosition()+3;
				let hasMultipleLine = false;
				this.interventions.forEach((intervention)=>{
					this.pdf.setCurrentYPosition(nextPositionTop);
					this.pdf.addCurrentYPosition(this.pdf.getSpaceBetweenLines()/2);
					this.positionTop = this.pdf.getCurrentYPosition();
					// 10 70 150
					this.pdf.setCurrentXPosition(this.positionLeft);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(moment(intervention.datePrevisionnelleDebut).format("DD/MM/YYYY HH:mm")+" - "+moment(intervention.datePrevisionnelleFin).format("HH:mm"), STYLES.items.cell, 60);

					this.pdf.setCurrentXPosition(this.positionLeft+70);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(intervention.intitule, STYLES.items.cell, 60);

					this.pdf.setCurrentXPosition(this.positionLeft+150);
					this.pdf.setCurrentYPosition(this.positionTop);
					this.pdf.addText(intervention.commentaire, STYLES.items.cell, 60);

					hasMultipleLine = this.pdf.getCurrentYPosition()!=this.positionTop;
					if(hasMultipleLine) nextPositionTop = this.pdf.getCurrentYPosition();
					else nextPositionTop = this.positionTop;
				});
			}
		},
        computed: {
			...mapGetters({

            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
