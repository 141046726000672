<template>
	<div class="vg-equipements-pieces-taches-actives-table">
		<vg-text-filter v-model="searchQuery"/>
		<equipement-piece-to-check-cell-render v-for="(equipement, index) in equipementsFiltered"
			:params="{data: equipement}"
			:key="index+'-'+equipement.id"
			class="item-to-check" 
			@go-to-check="goToCheck"/>
		<vg-pagination v-show="paginate"
			:totalItems="counters.filtered"
			:offset="offset"
			:limit="limit"
			@pagination-change="handlePaginationChange" />
	</div>
</template>
<script>
	import equipementsMixins from 'src/mixins/equipementsMixins.js';

    import EquipementPieceToCheckCellRender from "src/components/Vg/TagGrid/EquipementPieceToCheckCellRender.vue";
	import VgPagination from "src/components/Vg/VgPagination.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-equipements-table',
		components: {
			VgPagination,
			VgTextFilter,
            EquipementPieceToCheckCellRender
		},
		mixins: [ equipementsMixins ],
        props: {
            /**
            * This should be a unique id otherwise if 2 instances of
            * this component are called anywhere on app they will share filters
            * and ag-grid stored configs.
            */
            id:{
                type: String,
                default: "vg-equipements-pieces-taches-actives-table"
            },
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* paginate
			*/
			paginate: {
				type: Boolean,
				default: true
			},
			offset: {
				type: Number,
				default: 0
			},
			limit: {
				type: Number,
				default: 25
			}
        },
        data: function() {
            return {
				showLoadingOverlay:false,
				metadatas: new Metadatas(),
				equipements: [],
				counters: {all: 0, filtered: 0},
				searchQuery: "",
				agfilters: {
					libel_equipement: {attr: "eq.libel_equipement", value: null, action: "contains", openParenthesis: true},
					qrCode: {attr: "eq.qrCode", value: null, action: "contains", logicalOperator: "OR"},
					libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
				}
            };
        },
	    i18n:    { 
			"locale":navigator.language,
			"messages": {
				"fr": {
					"overlay-loading-text": "Chargement des équipements et pièces à vérifier...",
					"overlay-no-rows-text": "Aucun équipement ou pièce correspondant au filtrage",
				},
				"en": {
					"overlay-loading-text": "Loading equipments and rooms to check...",
					"overlay-no-rows-text": "No equipment or room matching filtering",
				}
			}
		},
		watch: {
			filters: {
				handler: function(newfilters){
					this.agfilters = Object.assign({}, this.agfilters, newfilters);
                    this.reinitPagination();
					this.fetch();
				},
				deep: true
			},
			searchQuery:{
				handler: function(query){
					this.agfilters.libel_equipement.value = query;
					this.agfilters.qrCode.value = query;
					this.agfilters.libel_lieu.value = query;
					this.reinitPagination();
					this.fetch();
				}
			}
		},
		created: function(){
			this.agfilters = Object.assign({}, this.agfilters, this.filters);
			if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			reinitPagination: function(){
				this.offset = 0;
				this.limit = 25;
				this.metadatas.setLimit(this.offset, this.limit);
			},
			handlePaginationChange: function(pagination){
	            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
	            this.fetch();
	        },
			fetch: function(){
				this.showLoadingOverlay = true;
				this.metadatas.setFilters(this.agfilters);
				this.equipementsMixins_getEquipementsTachesActivesSites(this.site.path, this.metadatas).then((datas)=>{
					this.equipements = datas.datas;
					this.counters = datas.metadatas.counters;
					this.showLoadingOverlay = false;
				});
			},
			goToCheck: function(equipement){
				this.$router.push({ name: '_process_verification', params: { 
					type: equipement.isGroupEqp=='1'?'lieu':'equipement', 
					equipementId: equipement.id,
					tacheId: equipement.tache_id
				}});
			}
		},
		computed: {
			...mapGetters({
				site: 'LieuxStore/getSelectedItem',
            }),
			equipementsFiltered: function(){
				return this.equipements;
			}
		},
		destroyed: function(){
			
		}
    };

</script>
<style lang="scss" scoped>
.vg-equipements-pieces-taches-actives-table{
	height: inherit;
    overflow-y: auto;
    width: 100%;
	.item-to-check{
		width: 100%;
		border-bottom: 1px solid #d7d7d9;
	}
}
</style>
