
<template>
	<div class="parametres-tags">
		<span class="title">{{$t('title')}}</span>
        <vg-tabs
            :tabs="tabs"
            :color="'gris'"
            @tabClicked="handleTabClick">
        </vg-tabs>
        <tags-parametres
            v-show="activeTabName == 'categorie/comptable'"
            key="1"
            :tagType="'categorie/comptable'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>
		<vg-tabs v-show="activeTabName == 'documents'"
			style="font-size: 11px !important;margin-top:20px;"
			:tabs="documentsTabs"
			:type="'pane'"
			@tabClicked="handleTabDocumentClick"/>
        <tags-parametres v-show="activeTabName == 'documents'"
            :tagType="activeTabNameDocuments"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>

        <tags-parametres
            key="3"
            v-show="activeTabName == 'composant/type'"
            :tagType="'composant/type'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>

        <tags-parametres
            key="4"
            v-show="activeTabName == 'operation-tache'"
            :tagType="'operation/tache'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>

        <tags-parametres
            key="5"
            v-show="activeTabName == 'operation-a-prevoir'"
            :tagType="'operation/a-prevoir'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>

        <tags-parametres
            key="6"
            v-show="activeTabName == 'equipement/tag'"
            :tagType="'equipement/tag'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag">
        </tags-parametres>
        <tags-parametres
            key="7"
            v-show="activeTabName == 'consommable/statut'"
            :tagType="'consommable/statut'"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag" />

        <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="deleteTag"
          @close="isModalConfirmDeleteOpened = false"
        >
			<template #header-title>
				{{$t('delete-tag')}} {{currentTagSelected.label}}
			</template>
        </dialog-secured-action>
        <vg-modal
            :title="$t('update')"
            v-if="isModalConfirmUpdateOpened && currentTagSelected"
            @save="updateTag"
            @close="handleCloseUpdateForm"
        >
            <template #body>
                <vg-input
                    :title="$t('label')"
                    v-model="currentTagSelected.label"
                />
            </template>
        </vg-modal>
	</div>
</template>

<script>
	import TagsMixins from "src/mixins/TagsMixins.js";
	import TagsParametres from "src/views/Parametres/components/TagsParametres.vue";
    import Metadatas from "src/services/Metadatas.js";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";

    import { mapGetters } from 'vuex';

    export default {
        name: 'parametres-tags',
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Personnalisation étiquettes",
            "update": "modifier",
            "delete-tag": "Supprimer l'étiquette",
            "label": "Libellé",
            "tab-documents": "Etiquettes documents",
            "tab-comptable": "Etiquettes comptable",
            "tab-composant-type": "Etiquettes type composant",
            "tag-documents-fichemaintenance": "Fiche de maintenance",
            "tag-documents-categorie": "Catégorie équipement",
            "tag-documents-equipement": "Equipement",
            "tag-documents-lieu": "Lieu",
            "tag-documents-tiers": "Tiers",
            "tag-documents-contrat": "Contrat",
            "tag-documents-intervention": "Intervention",
            "tag-documents-consommable": "Consommable",
            "tag-documents-operation": "Opération",
            "tag-documents-sortieequipement": "Sortie équipement",
            "tag-documents-bons-de-commande": "Bons de commande",
            "tab-operation-tache": "Etiquettes opération tache",
            "tab-tags-equipements": "Etiquettes équipement",
            "tab-tags-consommables-statut": "Statut consommables",
            "tab-operation-a-prevoir": "Etiquettes opération à prévoir"
        },
        "en": {
            "title": "Customise tags",
            "update": "Update",
            "label": "Label",
            "tab-documents": "Documents tags",
            "tab-comptable": "Accounting tags",
            "tab-composant-type": "Label component",
            "tag-documents-fichemaintenance": "Maintenance sheet",
            "tag-documents-categorie": "Equipment category",
            "tag-documents-equipement": "Equipment",
            "tag-documents-lieu": "Location",
            "tag-documents-tiers": "Supplier",
            "tag-documents-contrat": "Contract",
            "tag-documents-intervention": "Intervention",
            "tag-documents-consommable": "Consummable",
            "tag-documents-operation": "Operation",
            "tag-documents-sortieequipement": "Equipment output",
            "tab-operation-tache": "Task label",
            "tab-tags-equipements": "Equipment tag list",
            "tab-tags-consommables-statut": "Consumables status",
            "tab-operation-a-prevoir": "Task label to schedule"
        },
        "th": {
            "title": "กำหนดแท็กเอง",
            "tab-documents": "แท็กเอกสาร",
            "tab-comptable": "แท็กการทำบัญชี",
            "tab-composant-type": "ฉลากอุปกรณ์",
            "tag-documents-fichemaintenance": "แผ่นงานการบำรุงรักษา",
            "tag-documents-categorie": "หมวดหมู่อุปกรณ์",
            "tag-documents-equipement": "อุปกรณ์",
            "tag-documents-lieu": "ที่ตั้ง",
            "tag-documents-tiers": "ซัพพลายเออร์",
            "tag-documents-contrat": "สัญญา",
            "tag-documents-intervention": "การแทรกแซง",
            "tag-documents-consommable": "วัสดุสิ้นเปลือง",
            "tag-documents-operation": "ปฏิบัติการ",
            "tag-documents-sortieequipement": "ผลงานอุปกรณ์"
        }
    }
},
        components: {
			TagsParametres,
            VgTabs,
            DialogSecuredAction,
            VgInput,
			VgCredits
        },
		mixins:[ TagsMixins ],
        data() {
            return {
                activeTabName: "categorie/comptable",
                isModalConfirmDeleteOpened:false,
                isModalConfirmUpdateOpened:false,
                activeTabNameDocuments: "documents/equipement",
                currentTagSelected:null
            };
        },
        methods: {
			handleInputConfirm: function(e){
				var newtag = {
					label: e.value,
					type: e.type
				};
				this.TagsMixins_postTags([newtag]).then((data)=>this.fetchTags());
			},
			updateTag: function(){
				this.TagsMixins_putTag(this.currentTagSelected).then((data)=>{
                    this.fetchTags();
                    this.handleCloseUpdateForm();
                });
			},
			deleteTag:function(){
				this.TagsMixins_deleteTag(this.currentTagSelected).then((data)=>{
                    this.fetchTags();
                    this.isModalConfirmDeleteOpened = false;
                    this.handleCloseUpdateForm();
                });
			},
			openConfirmUpdateTag: function(data){
                this.isModalConfirmUpdateOpened = true;
                this.currentTagSelected = data.tag;
                return true;
			},
			openConfirmDeleteTag: function(data){
                this.isModalConfirmDeleteOpened = true;
                this.currentTagSelected = data.tag;
                return true;
			},
			fetchTags: function(){
				this.TagsMixins_get(new Metadatas());
			},
            handleTabClick: function(event) {
                this.activeTabName = event.name;
            },
            handleTabDocumentClick: function(event) {
                this.activeTabNameDocuments = event.name;
            },
            handleCloseUpdateForm:function(){
                this.isModalConfirmUpdateOpened = false;
                this.currentTagSelected = null;
            }
        },
        computed: {
            ...mapGetters({
                tags: 'TagsStore/getCollection'
            }),
            tabs: function(){
                    return [
                        {label: this.$t('tab-comptable'), name: 'categorie/comptable'},
    					{label: this.$t('tab-documents'), name: 'documents'},
    					{label: this.$t('tab-composant-type'), name: 'composant/type'},
    					{label: this.$t('tab-operation-tache'), name: 'operation-tache'},
    					{label: this.$t('tab-operation-a-prevoir'), name: 'operation-a-prevoir'},
    					{label: this.$t('tab-tags-equipements'), name: 'equipement/tag'},
    					{label: this.$t('tab-tags-consommables-statut'), name: 'consommable/statut'}
                ];
            },
			documentsTabs: function(){
				return [
					{label: this.$t("tag-documents-equipement"), name: "documents/equipement"},
					{label: this.$t("tag-documents-lieu"), name: "documents/lieu"},
					{label: this.$t("tag-documents-categorie"), name: "documents/categorie"},
					{label: this.$t("tag-documents-sortieequipement"), name: "documents/sortieequipement"},
					{label: this.$t("tag-documents-consommable"), name: "documents/consommable"},
					{label: this.$t("tag-documents-fichemaintenance"), name: "documents/fichemaintenance"},
					{label: this.$t("tag-documents-operation"), name: "documents/operation"},
					{label: this.$t("tag-documents-tiers"), name: "documents/tiers"},
					{label: this.$t("tag-documents-contrat"), name: "documents/contrat"},
					{label: this.$t("tag-documents-intervention"), name: "documents/intervention"},
					{label: this.$t("tag-documents-bons-de-commande"), name: "documents/bon-de-commande"}
				];
			},
        },
		created: function(){
			this.fetchTags();
		}
    };
</script>
<style lang="scss" scoped>
.parametres-tags{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	*{
		width: 100%;
	}
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
}
</style>
