
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
let actions = Base.getActions();
let mutations = Base.getMutations();
state.rawIfc = null
getters.getRawIfc = function(state){
    return state.rawIfc;
}
getters.groupByTypePerLevel = function( state ){
    let levels = state.collection;
    if(!levels.length) return [];
    let entityCount = {}
    levels.forEach((level)=>{
        entityCount[level.name] = level.children.reduce(function(rv, x) {
            (rv[x["type"]] = rv[x["type"]] || []).push(x);
            return rv;
        }, {});
    });
    return entityCount;
};
getters.getFloorSurfaces = function(state){
    /*if(!Object.entries(state.collection).length) return {};
    let surface = {};
    let IFCELEMENTQUANTITY = 1883228015;
    for(const [levelName, level] of Object.entries(state.groupByTypePerLevel)){
        surface[levelName] = {"total":0};
        for(const [entityName, entities] of Object.entries(level)){

            entities.forEach((entity)=>{
                if(entity.type == "IFCSPACE"){
                    if(entity.properties && entity.properties.length){
                        console.log(entity.properties)
                        entity.properties.forEach(property => {
                            if(property.type == IFCELEMENTQUANTITY && property.Quantities && property.Quantities.length ){
                                surface[levelName]["total"] += property.Quantities[0].AreaValue.value.toFixed(2);
                            }
                        });
                    }
                }
            });
        }
    }
    return surface;*/
}

actions.setRawIfc = function( context, values ){
    context.commit('SET_RAW_IFC', values);
},
mutations.SET_RAW_IFC = function(state, values){
    state.rawIfc = values;
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
