<template>
	<vg-select v-model="selectedProblem"
		:options="getProblems"
		:attributeLabel="'libelle'"
		:attributeValue="attributeValue"
		:placeholder="placeholder?placeholder:$t('selectionner-un-problem')"
		@input="handleInput">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import LibelProblemMixins from "src/mixins/LibelProblemMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-composant-libel-problem-selector',
		components: {
			VgSelect
		},
		mixins: [LibelProblemMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			idComposant: {
				type: String | Number,
				required: true
			},
			/**
			* attribut de l'objet corpsDetat à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "libelle"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			},
			placeholder: {
				type: String,
				default: null
			},
			idCategorie: {
				type: String | Number,
				default: null
			}
        },
        data: function() {
            return {
				selectedProblem: this.value,
				metadatasProblems: new Metadatas(),
				agfilters: {}
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "selectionner-un-problem": "Sélectionner un libellé"
        },
        "en": {
            "selectionner-un-problem": "Set a label"
        }
    }
},
		watch: {
			value: {
				handler: function(val){
					this.selectedProblem = val;
				}
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				this.LibelProblemMixins_DI_get_composant_problem(this.idComposant).then((datas)=>{
					
				});
			},
            handleInput:function(selectedProblem){
                this.$emit('input',selectedProblem);
                this.$emit('change',selectedProblem);
            }
		},
        computed: {
			...mapGetters({
                problems: 'LibellesProblemStore/getCollection'
            }),
			getProblems: function(){
				return this.problems.sort((p1, p2)=>p1.libelle.localeCompare(p2.libelle));
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
