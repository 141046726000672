<template>
    <div class="vg-consommable-datalist"
        @keyup.tab="onCloseDatalist"
        @keyup.enter="onCloseDatalist"
        >
        <!--v-closable="{exclude: ['consommable-datalist-input'+datalistId], handler: 'onBlurComponent'}"-->
        <vg-input v-model="inputValue"
            :refInput="'consommable-datalist-input'+datalistId"
            :placeholder="placeholder"
            @delayed-input="onDelayedInput"
            @input="onInput"
            @blur="onBlur"
            @focus="onFocus"/>
        <div v-if="displayDatalist"
            class="datalist-container">
            <div class="datalist-container-header">
                <span style="font-size:12px;color:#3999FF;">
                    {{$t("title-datalist")}}
                </span>
                <vg-button v-if="!readOnly" :type="'success'" :size="'sm'" style="float:right;margin-left:10px;" @click="showModalCreateConsommable=true;">{{$t("creer-consommable")}}</vg-button>
                <vg-button :type="'default-link'" :size="'sm'"
                    @click="displayDatalist=false;">
                    <i class="fas fa-times fa-2x"></i>
                </vg-button>
            </div>
            <div class="datalist-container-body">
                <vg-button size="sm" type="grey" v-if="fournisseurId"
                    @click="onChangeFiltreFournisseur">
                    <img src="static/assets/icone/Icones-60x60-appli-web.png" height="11" alt="">
                    <span v-if="agfilters.fournisseurId && agfilters.fournisseurId.value">{{ $t("filtre-fournisseur-actif") }}</span>
                    <span v-else>{{ $t("filtre-fournisseur-non-actif") }}</span>
                    <i v-if="agfilters.fournisseurId && agfilters.fournisseurId.value" class="fas fa-times" style="margin-left: 2px;"></i>
                </vg-button>
                <div v-for="(consommable, index) in consommables" :key="index"
                    class="consommable"
                    @click="onClickConsommable(consommable, index)">
                    <span v-if="inputDisplayLabel=='refExterne'" style="color:#3999FF;">{{consommable.refExterne}}</span>
                    <span v-else style="color:#3999FF;">{{consommable.name}}</span>
                    <span v-if="inputDisplayLabel=='refExterne'" style="color:#3999FF;">{{consommable.name}}</span>
                    <span v-else>{{$t("conso-ref")}}: {{consommable.refExterne}}</span><span>|</span>
                    <span><u>{{$t("en-stock")}}: {{consommable.stock.quantite}} {{consommable.unite?consommable.unite:"u."}}</u></span><span>|</span>
                    <span>{{$t("conso-marque")}}: {{consommable.marque}}</span><span>|</span>
                    <span v-if="!readOnly">{{$t("conso-cout-unitaire")}}: {{consommable.coutUnitaire}}</span><span v-if="!readOnly">|</span>
                    <span v-if="!readOnly">{{$t("conso-unite")}}: {{consommable.unite}}</span><span v-if="!readOnly">|</span>
                    <span v-if="!readOnly">{{$t("conso-statut")}}: {{consommable.statut}}</span><span v-if="!readOnly">|</span>
                    <span v-if="!readOnly">{{$t("conso-commentaire")}}: {{consommable.commentaire}}</span>
                </div>
            </div>
            <vg-pagination
                :totalItems="counters.filtered"
                :offset="offset"
                :limit="limit"
                @pagination-change="handlePaginationChange"
            />
        </div>
        <!--datalist :id="datalistId">
            <option v-for="(consommable, index) in consommables" :key="index"
                :value="getOptionValue(consommable)">
                <span v-if="inputDisplayLabel=='refExterne'">{{consommable.name}}&nbsp;|&nbsp;</span>
                <span v-else>{{$t("conso-ref")}}: {{consommable.refExterne}}&nbsp;|&nbsp;</span>
                {{$t("conso-marque")}}: {{consommable.marque}}&nbsp;|&nbsp;
                {{$t("conso-cout-unitaire")}}: {{consommable.coutUnitaire}}&nbsp;|&nbsp;
                {{$t("conso-unite")}}: {{consommable.unite}}&nbsp;|&nbsp;
                {{$t("conso-statut")}}: {{consommable.statut}}&nbsp;|&nbsp;
                {{$t("conso-commentaire")}}: {{consommable.commentaire}}
            </option>
        </datalist-->
        <vg-consommable-form v-if="showModalCreateConsommable"
            v-model="newConsommable"
            @close="showModalCreateConsommable=false;"
            @save="onSaveConsommable"/>
    </div>
</template>
<script>
import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";
import VgConsommableForm from "src/components/Vg/Forms/VgConsommableForm.vue";
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import Metadatas from "src/services/Metadatas";
import { mapGetters, mapActions } from "vuex";
export default {
    name: 'vg-consommable-datalist',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "conso-ref": "Réf.",
            "conso-marque": "Marque",
            "conso-commentaire": "Commentaire",
            "conso-cout-unitaire": "PuHT",
            "conso-statut": "Statut",
            "conso-unite": "Unité",
            "precendents": "Précédents",
            "suivants": "Suivants",
            "title-datalist": "Liste des consommables",
            "creer-consommable": "Créer consommable",
            "en-stock": "Quantité en stock",
            "filtre-fournisseur-actif": "Filtre actif: fournisseur",
            "filtre-fournisseur-non-actif": "Activer filtre fournisseur"
        }
    }
},
    mixins:[ConsommablesMixins],
	components: {
		VgInput,
        VgButton,
        VgPagination,
        VgConsommableForm
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        inputDisplayLabel: {
            type: String,
            default: "name"
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        depotId: {
            type: Number | String,
            default: null
        },
        fournisseurId: {
            type: String | Number,
            default: null
        }
    },
    data: function() {
        return {
            inputValue: this.value,
            metadatas: new Metadatas(),
            agfilters: {},
            datalistId: this.generateDatalistId(),
            displayDatalist: false,
            offset: 0,
            limit: 100,
            selectedConsommable: null,
            showModalCreateConsommable: false,
            consommables: []
        };
    },
    watch: {
        value: {
            handler: function(newvalue){
                this.inputValue = newvalue;
            }
        },
        depotId: {
            handler: function(id){
                this.fetch();
            }
        },
        filters:{
            handler: function(newfilters){
                this.agfilters = Object.assign({}, this.agfilters, newfilters);
                this.fetch();
            },
            deep: true
        },
        fournisseurId: {
            handler: function(newId){
                this.setFiltreFournisseur(newId);
            }
        }
    },
	created: function(){
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        if(this.fournisseurId) this.setFiltreFournisseur(this.fournisseurId);
        this.fetch();
	},
    methods:{
        setFiltreFournisseur: function(fournisseurId){
            this.agfilters.fournisseurId = {attr: "c.fournisseurDefault_id", value: fournisseurId, action: "equals"};
            this.fetch();
        },
        onChangeFiltreFournisseur: function(){
            if(this.agfilters && this.agfilters.fournisseurId && this.agfilters.fournisseurId.value) this.agfilters.fournisseurId.value = null;
            else if(this.agfilters && this.agfilters.fournisseurId && !this.agfilters.fournisseurId.value) this.agfilters.fournisseurId.value = this.fournisseurId;
            this.fetch();
        },
        onSaveConsommable: function(consommable){
            this.showModalCreateConsommable = false;
            this.fetch();
            this.$emit("select", consommable);
        },
        onCloseDatalist: function(){
            this.displayDatalist = false;
        },
        onBlurComponent: function(event){
            //console.log("ON BLUR COMPONENT", event);
            if(this.selectedConsommable){

            }else{
                this.displayDatalist = false;
            }
        },
        onBlur: function(event){
            console.log("onBlur", event);
        },
        onFocus: function(value){
            console.log("onFocus", value);
            this.displayDatalist = true;
        },
        onDelayedInput: function(value){
            console.log("DELAYED INPUT", value);
            this.agfilters = Object.assign({}, this.agfilters, {
                name: {attr: "c.name", value: value, action: "contains", openParenthesis: true},
                refExterne: {attr: "c.refExterne", value: value, action: "contains", logicalOperator: "OR"},
                marque: {attr: "c.marque", value: value, action: "contains", logicalOperator: "OR"},
                commentaire: {attr: "c.commentaire", value: value, action: "contains", logicalOperator: "OR"},
                code: {attr: "c.code", value: value, action: "contains", logicalOperator: "OR"},
                category: {attr: "c.category", value: value, action: "contains", logicalOperator: "OR"},
                nature: {attr: "c.nature", value: value, action: "contains", logicalOperator: "OR", closeParenthesis: true},
            });
            this.fetch();
        },
        onInput: function(value){
            if(!this.displayDatalist) this.displayDatalist = true;
            this.$emit("input", value);
        },
        /**
        * Selectionne le consommable.
        *
        * @param Object consommable
        * @param Number index default null
        */
        onClickConsommable: function(consommable, index=null){
            console.log("SELECT CONSOMMABLE", consommable);
            this.selectedConsommable = consommable;
            this.$emit("select", consommable);
            this.displayDatalist = false;
            this.selectedConsommable = null;
        },
        generateDatalistId: function(){
            return moment().format("x")+"-"+Math.floor(Math.random()*1000);
        },
        getOptionValue: function(consommable){
            return consommable[this.inputDisplayLabel];
        },
        fetch: function(){
            this.metadatas.setLimit(this.offset, this.limit);
            let filters = Object.assign({}, this.agfilters, {
                depot_id: {attr: "s.depot_id", value: this.depotId?this.depotId:null, action: "equals"}
            });
            this.metadatas.setFilters(filters);
            this.ConsommablesMixins_getConsommables(this.metadatas, {_stored: false}).then((datas)=>{
                console.log("AFTER GET CONSOS", datas);
                this.consommables = [...datas.consommables];
                this.counters = datas.metadatas.counters;
            });
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    },
    computed: {
        ...mapGetters({
            /*consommables: 'ConsommablesStore/getCollection',
            counters: 'ConsommablesStore/getCounters'*/
        }),
        newConsommable: function(){
            return {name: this.inputValue};
        }
    }
};

</script>
<style lang="scss" scoped>
.vg-consommable-datalist{
    width:100%;
    >input{
        width:100%;
    }
    .datalist-container{
        padding:15px;
        z-index:100;
        position:absolute;
        border:2px solid #dcdfe6;
        background-color:#fff;
        .datalist-container-header{
            display:flex;
            justify-content:space-between;
            align-items: center;
            margin-bottom: 10px;
        }
        .datalist-container-body{
            display:flex;
            flex-direction:column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            max-height:200px;
            overflow:scroll;
            .consommable{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                cursor:pointer;
            }
            .consommable:hover{
                /*background-color: whitesmoke;*/
                font-weight: bold;
            }
        }
    }
}
</style>
<docs>

    ### vg-consommable-datalist
    Permet d'avoir un input avec une datalist de consommables

    #### basic usage

    ```html static
    <vg-consommable-datalist v-model="bonDeCommandeItem.description"
        :placeholder="$t('saisir-designation')"
        @selected=""/>
    ```
</docs>
