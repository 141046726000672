<template>
    <span v-if="params.data.hasOwnProperty('nbEquipementToCheck') && params.data.hasOwnProperty('categorie') && params.data.categorie.libelleCatgorie">
        {{ params.data.nbEquipementChecked }} / {{ params.data.nbEquipementToCheck }} {{ params.data.categorie.libelleCatgorie }}
    </span>
    <span v-else-if="params.data.hasOwnProperty('name') && params.data.hasOwnProperty('programmation')">
        {{ params.data.nbInterventionsRealisees }} / {{ getTotalInterventionsDuContrat }} {{ $t("annee-en-cours") }}
    </span>
    <span v-else>{{ $t("aucun-etat") }}</span>
</template>
<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"etat-realisation-cell-render",
    mixins:[LangMixins],
    components:{
        
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "aucun-etat": "Aucun état",
            "annee-en-cours": "année en cours"
        }
    }
},
    methods:{
        
    },
    computed:{
        getTotalInterventionsDuContrat: function(){
            let recurrenceIntervention = this.params.data.programmation.recurrence; // 1 intervention programmée tous les x jours
            let anneeContrat = 365;
            let nbInterventionsProgrammees = parseInt(anneeContrat/recurrenceIntervention);
            return nbInterventionsProgrammees;
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
