<template>
	<div class="consommable-card" @click="$emit('select')">
		<span><b>{{consommable.name}}</b></span>
		<span v-if="consommable.refExterne"> / {{$t('field-refExterne')}} : {{consommable.refExterne}}</span>
		<span v-if="consommable.marque"> / {{consommable.marque}}</span>
		<span v-if="consommable.commentaire"> / {{consommable.commentaire}}</span>
		<slot name="complement"></slot>
	</div>
</template>
<script>
	//import VgConsommableCard from "src/components/VG/Consommables/VgConsommableCard.vue";
    export default {
        name: 'vg-consommable-card',
		components: {
			//VgConsommableCard
		},
        props: {
            consommable: Object
        },
        data: function() {
            return {

            };
        },
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Consommable",
            "field-refExterne": "Réf",
            "field-marque": "Marque",
            "field-PU": "PU"
        },
        "en": {
            "title": "Consumable",
            "field-refExterne": "Ref",
            "field-marque": "Brand",
            "field-PU": "PU"
        },
        "th": {
            "title": "วัสดุสิ้นเปลือง",
            "field-refExterne": "อ้างอิง",
            "field-marque": "ยี่ห้อ",
            "field-PU": "ราคาต่อหน่วย"
        }
    }
},
		watch: {

		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
	.consommable-card{
		padding: 10px;
		border: 1px solid #eee;
		cursor: pointer;
		span:not(:first-child){
			font-size: 12px;
		}
	}
	.complement{
		display: inline;
	}

</style>
