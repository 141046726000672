<template>
	<div :class="['vg-input-step',{'step-is-valid': isAValidStep, 'step-is-unvalid': !isAValidStep}]"
		:id="'input-step-'+step">
		<div class="title-container">
			<div class="main">
				<div class="step-number"><span>{{step}}</span></div>
				<span class="title">{{title}}</span>
			</div>
			<div v-if="!!$slots['title-right']" class="complement">
				<slot name="title-right"></slot>
			</div>
		</div>
		<!--vg-button-group style="width: 100%;"-->
		<slot name="main-input">
			<div class="input-step-main-button"
				@click="onClickInput">
				<span v-if="!value.value" class="placeholder">{{ placeholder }}</span>
				<div class="main">
					<div class="subvalue"><slot name="subvalue">{{value.subvalue}}</slot></div>
					<div class="value"><slot name="value">{{value.value}}</slot></div>
				</div>
	            <i class="fas fa-2x fa-angle-down"></i>
			</div>
		</slot>
		<slot name="complement"></slot>
			<!--vg-button plain @click="onClickInput" style="width: 20%;">
				<span style="color: grey;">{{ $t("di.desktop.btn-selection") }}</span>
			</vg-button-->
		<!--/vg-button-group-->
		<vg-modal v-if="showModal"
			width="60vw"
			@close="showModal=false;"
			@cancel="showModal=false;">
			<template #header>
				<div>
					<span class="step">{{step}}</span>
					<span>{{title}}</span>
					<slot name="title-complement"></slot>
				</div>
			</template>
			<template #body>
				<slot name="body">
					<vg-text-filter v-model="searchFilter"
					/>
					<div class="step-modal-body">
						<slot name="modal-body"></slot>
					</div>
				</slot>
			</template>
			<template #pre-footer>
				<slot name="modal-pre-footer"></slot>
			</template>
			<template #footer>
				<slot name="modal-footer"></slot>
			</template>
		</vg-modal>
	</div>
</template>

<script>


import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
import VgButton from 'src/components/Vg/VgButton.vue';

export default {
	name: "vg-input-step",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "di": {
                "desktop": {
                    "btn-selection": "Sélectionnez"
                }
            }
        },
        "en": {
            "di": {
                "desktop": {
                    "btn-selection": "Select"
                }
            }
        },
        "th": {
            "di": {
                "desktop": {
                    "btn-selection": "เลือก"
                }
            }
        }
    }
} ,
	components: {
		VgTextFilter,
        VgButton
	},
	props: {
		/**
		* field label
		*
		*/
		step: {
			type: Number,
			default: 1
		},
		/**
		* field label
		*
		*/
		title: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		value : {
			type: Object,
			default: {id: null, value: null, subvalue: null}
		},
		validationrequired: {
			type: Boolean,
			default: false
		}
	},
	data:function() {
		return {
			showModal: false,
			searchFilter: null,

		};
	},
	watch: {
		searchFilter: function(searchFilter){
			if(this.searchFilter != null ){
                this.$emit('input-search', searchFilter)
            }else{
                this.$emit('input-search',"");
            }
		},
		value: {
			handler: function(model){
				/*if(!this.validationrequired) */this.showModal = false;
			},
			deep:true
		},
	},
	computed: {
		isAValidStep: function(){
			return this.value && this.value.value;
		}
	},
	methods: {
		onClickInput: function(){
			//// //console.log("ON CLICK INPUT");
			this.showModal = true;
			this.searchFilter = null;
		},
		scrollToStep: function(){
			let element = document.getElementById("input-step-"+this.step);
  			if(element) element.scrollIntoView({behavior: "smooth"});
		}
	},
	directives: {

	},
	mounted: function(){
		this.scrollToStep();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.step{
	background-color: #feca1b;
	padding: 0px 8px;
}
.step-modal-body{
	max-height: 60vh;
	overflow: auto;
}
</style>
<style lang="scss" scoped>
$yellow-color: #ffc73d;
.vg-input-step {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	width: 100%;
	padding: 10px;
	.title-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 10px;
		.main{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 20px;
			.step-number{
				/*font-weight: bold;*/
				font-size: 20px;
				background-color: $yellow-color;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 5px 10px;
			}
			.title{
				/*font-weight: bold;*/
				font-size:16px;
				white-space: nowrap;
			}
		}
		.complement{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 20px;
		}
	}
	.input-step-main-button{
		width: 100%;
		height: 40px;
		cursor:pointer;
		border: 1px solid #ccc;
		padding: 5px 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		@media only screen and (max-width: 600px) {
			height: auto;
		}
		i{
			color: #333;
		}
		.main{
			display:flex;
			justify-content:flex-start;
			align-items:center;
			gap:10px;
			@media only screen and (max-width: 600px) {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 2px;
			}
		}
		.placeholder{

		}
		.subvalue{

		}
		.value{
			font-weight: bold;
		}
	}
	.input-step-main-button:hover{
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
	}
}
.step-is-valid{

}
.step-is-unvalid{

}
</style>
<!--docs>

    ### vg-input examples:

    #### basic usage

    ```html static
          <vg-input title="Prénom"  inputType="text" :isRequiredValue="true" ></vg-input>
    ```
    #### Make a form with multiple input

    **Remarque**
    il faut mettre les input dans un formulaire et y ajouter un submit cacher, et déclencher ce submit via la méthode .click()
    les référence ajoutées sur les inputs permettent de récupérer l'élément du DOM. pour déclencher linput avec vuejs on peut alors faire
    ```javascript this.$refs['btnSubmit'].click()```

    ```html static
        <form ref="formulaire">
          <vg-input title="Nom"></vg-input>
          <vg-input title="Prénom" inputType="text" :isRequiredValue="true"></vg-input>
          <vg-input title="E.mail" inputType="email"></vg-input>
          <vg-input title="date" inputType="date"></vg-input>
          <vg-input title="textarea" inputType="textarea"></vg-input>
          <input v-show="false" type="submit" ref="btnSubmit" />
        </form>
    ```


</docs-->
