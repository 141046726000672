<template>
	<div v-if="(!value.label || value.label.length==0) && !value.isNotNode"
		:class="['vg-tree-node-consommable',{'selected':isSelected}]"
		@click="onClick" >
		{{$t("empty-label")}} <small v-if="value.counter && value.counter!=0" class="counter">{{value.counter}} {{$t("consommables")}}</small>
	</div>
	<div v-else-if="value.isNotNode"
		:class="['see-node','vg-tree-node-consommable',{'selected':isSelected}]"
		@click="onClick" >
		<span>{{$t("see")}} <u>{{value.location}}</u></span>
	</div>
	<div v-else
		:class="['vg-tree-node-consommable',{'selected':isSelected}]"
		@click="onClick">
		{{value.label}} <small v-if="value.counter && value.counter!=0" class="counter">{{value.counter}} {{$t("consommables")}}</small>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import UserParametersMixins from "src/mixins/UserParametersMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-tree-node-consommable',
		components: {
			VgButton
		},
		mixins: [UserParametersMixins],
        props: {
			value:{
				type: Object
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "empty-label": "Aucune étiquette",
            "consommables": "consommables",
            "see": "Voir consommables"
        },
        "en": {
            "empty-label": "No tag",
            "consommables": "supply",
            "see": "Show supplies"
        }
    }
},
		watch:{
			value: {
				handler: function(newnode){
					this.node = newnode;
				},
				deep: true
			}
		},
        data: function() {
            return {
				node: this.value,
				isSelected: false,
				isTagVisible: true
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			onClick: function(){
				this.isSelected = !this.isSelected;
				this.$emit("select", this.node);
			}
		},
        computed: {
			...mapGetters({

	        })
		}
    };

</script>
<style lang="scss" scoped>
.vg-tree-node-consommable{
	width: 100%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	.icon-consommable{
		width: 16px;
		height: 16px;
	}
}
.counter{
	border: 1px solid #e8eaee;
	padding: 2px 5px;
}
.see-node{
	color: #3999FF;
	font-size: 10px;
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-tree-node
                >
            </vg-tree-node>
        </div>
      `
    })
    ```
</docs>
