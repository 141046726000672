<template>
    <div class="composant-cell-renderer">
        <div class="composant">
            <i v-if="!updatable" class="far fa-check-square"></i>
            <vg-checkbox v-else style="width:30px;"
                :defaultChecked="isComposantAssociated"
                @checked="onCheckComposant"
                @unchecked="onUncheckComposant"
                />
            <img :src="params.data.icon" />
            <span class="libel">{{params.data.libelComposant}}</span>
            <span class="type">{{params.data.typeComposant}}</span>
            <span class="problems-counter">{{params.data.problems.length}} {{$t("problems")}}</span>
        </div>
        <vg-button
            :type="'default-round'"
            @click="onUpdateComposant">
            <i class="fas fa-pen"></i>
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "composant-cell-render",
        mixins:[LangMixins],
		props: {
            params: {
                type: Object,
                required: true
            },
            updatable: {
                type: Boolean,
                default: false
            },
            idCategorie: {
                type: Number,
                required: true
            }
		},
        components:{
            VgCheckbox
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "problems": "problèmes associés"
        }
    }
},
        data:function(){
            return {

            };
        },
        methods:{
            onCheckComposant: function(){
                console.log("CHECK COMPOSANT", this.params.data);
                this.$emit("checked", {composant: this.params.data, categorie_id: this.idCategorie});
            },
            onUncheckComposant: function(){
                console.log("UNCHECK COMPOSANT", this.params.data);
                this.$emit("unchecked", {composant: this.params.data, categorie_id: this.idCategorie});
            },
            onUpdateComposant: function(){
                this.$emit("show-update", {composant: this.params.data, categorie_id: this.idCategorie});
            },
        },
        computed:{
            isComposantAssociated: function(){
                return this.params.data.composant_categories?this.params.data.composant_categories.findIndex((cc)=>cc.categorie.id==this.idCategorie)!=-1:false;
            }
        }
    });
</script>

<style lang="scss" scoped>
.composant-cell-renderer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .composant{
        width:80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 0;
        >i{
            margin-right: 20px;
        }
        >img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        .libel{
            color: #3999ff;
            font-weight: bold;
            margin-right: 10px;
            width: 30%;
            min-width: 180px;
        }
        .type{
            font-size: 11px;
            margin-right: 10px;
            width: 20%;
            min-width: 120px;
        }
        .problems-counter{
            font-size: 11px;
            font-style: italic;
        }
    }
}
.composant-cell-renderer:hover{
    background-color: whitesmoke;
}
</style>
