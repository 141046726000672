<template>
    <div v-if="params.data.hasOwnProperty('programmation') && params.data.programmation" 
        style="line-height: 1.2; height: 100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;">
        <span>
            {{getReadableRecurrence}} <small>{{ getReadableTime }}</small> 
            <small v-if="!isDateBegin(params.data.programmation.start)">{{$t("a-partir-du", {date:getDateDDMMYYYY(params.data.programmation.start) }) }}</small>
        </span>
        <small v-if="params.data.programmation.minDaysBeforeGeneratedDate">{{ $t("se-genere", {days: params.data.programmation.minDaysBeforeGeneratedDate}) }}</small>
    </div>
    <span v-else>{{ $t("aucune-programmation") }}</span>
</template>
<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import moment from "moment";
export default Vue.extend({
    name:"programmation-cell-render",
    mixins:[LangMixins],
    components:{
        VgDatetimeViewer
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "aucune-programmation": "Aucune programmation",
            "tous-les-1": "Tous les jours {weekDay}",
            "tous-les-7": "Toutes les semaines {weekDay}",
            "tous-les-30": "Tous les mois {weekDay}",
            "tous-les-90": "Tous les trimestre {weekDay}",
            "tous-les-180": "Tous les semestre {weekDay}",
            "tous-les-365": "Tous les ans {weekDay}",
            "tous-les-x-jours": "Tous les {jours} jours {weekDay}",
            "week-day-1": "le lundi",
            "week-day-2": "le mardi",
            "week-day-3": "le mercredi",
            "week-day-4": "le jeudi",
            "week-day-5": "le vendredi",
            "week-day-6": "le samedi",
            "week-day-7": "le dimanche",
            "a-partir-du": "à partir du {date}",
            "se-genere": "se génère {days} jours avant"
        }
    }
},
    methods:{
        getDateDDMMYYYY: function(dt){
            return moment(dt).format("DD/MM/YYYY");
        },
        isDateBegin: function(dt){
            return moment().format("YYYY-MM-DD 00:00") >= dt;
        }
    },
    computed:{
        getReadableRecurrence: function(){
            let readableRecurrence = "";
            let weekDay = "";
            if(this.params.data.programmation.dayOfWeek) weekDay = this.$t("week-day-"+this.params.data.programmation.dayOfWeek);
            if([1,7,30,90,180,365].includes(this.params.data.programmation.recurrence)) readableRecurrence = this.$t("tous-les-"+this.params.data.programmation.recurrence, {weekDay: weekDay}); 
            else readableRecurrence = this.$t("tous-les-x-jours", {jours: this.params.data.programmation.recurrence, weekDay: weekDay});
            return readableRecurrence;
        },
        getReadableTime: function(){
            let start = moment(this.params.data.programmation.start);
            let end = moment(this.params.data.programmation.start).add(this.params.data.programmation.durationMinutes, "minutes");
            return start.format("HH:mm")+" - "+end.format("HH:mm");
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
