<template>
	<div style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;margin-left:20px;width:100%;">
		<div v-for="(entite, index) in bonsDeCommandeEntites" :key="index"
			style="display:flex;flex-direction:row;justify-content:flex-start;align-items:center;margin:5px 0;padding-bottom:5px;border-bottom:1px solid whitesmoke;width:100%;">
			<vg-files-thumbnail
				:file="entite.logo"
				:heightImg="'100px'"
				style="margin-right:10px;"/>
			<div style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;">
				<span><b>{{entite.name}}</b></span>
				<span><small>{{ $t("immatriculation") }}: </small>{{entite.immatriculation}}</span>
				<span><small>{{ $t("address") }}: </small>{{entite.address}}</span>
				<span><small>{{ $t("address-livraison") }}: </small>{{entite.addressLivraison}}</span>
				<span><small>{{ $t("text-complementaire") }}: </small>{{entite.textComplementaire}}</span>
			</div>
			<vg-button :size="'sm'" :type="'info'" style="margin-left:20px;"
				@click="updateLogo(entite)">
				{{$t("logo")}}
			</vg-button>
			<vg-button :size="'sm'" :type="'danger'"
				@click="showUpdate(entite)">
				{{$t("update")}}
			</vg-button>
		</div>
		<vg-button :size="'sm'"
			:type="'success'"
			style="width:100%;"
			@click="showEntiteForm=true;">
			{{$t("add-entite")}}
		</vg-button>
		<vg-bon-de-commande-entite-form v-if="showEntiteForm"
			v-model="focusedEntite"
			@close="showEntiteForm=false;focusedEntite=null;"
			@save="showEntiteForm=false;focusedEntite=null;fetch();" />
		<vg-modal v-if="showUpdateLogo"
			@close="showUpdateLogo=false;focusedEntite=null;">
			<template #body>
				<vg-bon-de-commande-entite-logo-uploader v-model="focusedEntite"
					@upload-done="onUploadLogoEntiteDone" />
			</template>
			<template #footer>
				<vg-button
					@click="showUpdateLogo=false;focusedEntite=null;">
					{{$t("close")}}
				</vg-button>
			</template>
		</vg-modal>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgTodoList from "src/components/Vg/VgTodoList.vue";

	import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
	import VgBonDeCommandeEntiteForm from "src/components/Vg/Forms/VgBonDeCommandeEntiteForm.vue";
	import VgBonDeCommandeEntiteLogoUploader from "src/components/Vg/BonDeCommandeEntite/VgBonDeCommandeEntiteLogoUploader.vue";

	import BonsDeCommandeEntitesMixins from "src/mixins/BonsDeCommandeEntitesMixins.js";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

	export default {
        name: 'vg-bon-de-commande-entite-parametrage',
		components: {
			VgButton,
			VgSelect,
			VgInput,
			VgTodoList,
			VgFilesThumbnail,
			VgBonDeCommandeEntiteForm,
			VgBonDeCommandeEntiteLogoUploader
		},
		mixins: [ BonsDeCommandeEntitesMixins ],
    	props: {

        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "add-entite": "Nouvelle entité",
            "update": "Modifier",
            "logo": "Changer logo",
            "close": "Fermer",
			"address": "Adresse",
			"address-livraison": "Adresse livraison",
			"immatriculation": "Immatriculation",
			"text-complementaire": "Text complémentaire"
        },
		"en": {
			"add-entite": "New entity",
			"update": "Modify",
			"logo": "Change logo",
			"close": "Close",
			"address": "Address",
			"address-livraison": "Delivery Address",
			"immatriculation": "Registration number",
			"text-complementaire": "Additionnal text"
		}
    }
},
        data: function() {
            return {
				metadatas: new Metadatas(),
				filters:{},
				showEntiteForm: false,
				showUpdateLogo: false,
				focusedEntite: null
            };
        },
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			onUploadLogoEntiteDone: function(){
				if(this.focusedEntite && this.focusedEntite.logo && this.focusedEntite.logo.id){ 
					this.VgFilesMixins_deleteFile(this.focusedEntite.logo).then(()=>{
						this.showUpdateLogo = false;
						this.focusedEntite = null;
						this.fetch();
					});
				}else{
					this.showUpdateLogo = false;
					this.focusedEntite = null;
					this.fetch();
				}
			},
			showUpdate: function(entite){
				this.focusedEntite = entite;
				this.showEntiteForm = true;
			},
			updateLogo: function(entite){
				this.focusedEntite = entite;
				this.showUpdateLogo = true;
			},
			fetch: function(){
				this.metadatas.setFilters(this.filters);
	            this.BonsDeCommandeEntitesMixins_getEntites(this.metadatas).then((datas)=>{
	                console.log("GET BCs ENTITES", datas);
	            });
			}
		},
        computed: {
			...mapGetters({
				bonsDeCommandeEntites: "BonsDeCommandeEntitesStore/getCollection"
	        }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
