<template>
	<vg-select v-model="selectedTiers"
		:options="tiers"
		:attributeLabel="'name'"
		:attributeValue="attributeValue"
		:multiple="multiple"
		:clearable="clearable"
		:placeholder="placeholder?placeholder:$t('selectionner-un-tiers')"
		@input="emitSelectedTiers">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import TiersMixins from "src/mixins/TiersMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-tiers-selector',
		components: {
			VgSelect
		},
		mixins: [TiersMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet tiers à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			clearable: {
				type: Boolean,
				default: true
			},
			placeholder: {
				type: String,
				default: null
			},
			filters: {
				type: Object,
				default:()=>{
					return {};
				}
			},
			multiple:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				selectedTiers: this.value,
				metadatasTiers: new Metadatas(),
				agfilters: {}
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "selectionner-un-tiers": "Sélectionner un tiers"
        }
    }
},
		watch: {
			value: {
				handler: function(val){
					this.selectedTiers = val;
				}
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				this.metadatasTiers.setFilters(Object.assign({},this.agfilters, this.filters));
				this.TiersMixins_get(this.metadatasTiers).then((datas)=>{
					// //console.log("TIERS", datas);
					this.$emit("fetch-success", {tiers: datas});
				});
			},
			emitSelectedTiers: function(tierId){
				this.$emit('input',this.selectedTiers);
				if(!tierId) return ;
				this.$emit("change",this.findTierById(tierId));
			},
			findTierById: function(tierId){
				return this.tiers.filter(tier => tier.id == tierId)[0];
			}
		},
        computed: {
			...mapGetters({
                tiers: 'TiersStore/getCollection'
            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
