<template>
    <div>
        <vg-button :type="'grey'"
            :size="'xs'"
            @click="onClickClone">
            <i class="far fa-clone" style="margin-right:2px;"></i> {{$t("cloner")}}
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "bon-de-commande-actions-cell-render",
        mixins:[LangMixins],
		props: {
            dataColumn: {
                type: Object
            }
		},
        components: {
            VgButton
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "cloner": "Cloner"
        }
    }
},
        methods: {
            onClickClone: function(){
                this.$emit("clone", this.dataColumn);
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>
