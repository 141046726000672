<template>
    <tag-grid
      class="vg-tableau-card"
      :idTableau="'tableau-inventaire-mobile'"
      slot="primary"
      v-model="gridOptions"
      :columnDefs="columnDefs"
      :rowData="getStateByPathFiltered"
      :showLoadingOverlay="showLoadingOverlay"
      :overlayLoadingText="$t('overlay-loading-text')"
      :overlayNoRowsText="$t('overlay-no-rows-text')"
      @ag-click="selectPiece" />
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import InventaireLieuCellRender from "src/components/Vg/TagGrid/InventaireLieuCellRender.vue";
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";


import { mapGetters } from 'vuex';

export default {
    name: "vg-inventaire-recapitulatif",
    components: {
        TagGrid,
        InventaireLieuCellRender
    },
    mixins: [TagGridMixins],
    props:{
        searchQuery:String,
        agfilters:{
            type: Object,
            default: function(){
                return null;
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des pièces...",
		"overlay-no-rows-text": "Aucune pièce correspondant au filtrage",
        "path-inventaire": "Emplacement",
        "qty-inventaire": "Equipements inventoriés",
        "qtynot-inventaire": "Equipements non inventoriés",
        "total": "total"
    },
    "en": {
        "overlay-loading-text": "Loading rooms...",
		"overlay-no-rows-text": "No room matching filtering",
        "path-inventaire": "Location",
        "qty-inventaire": "qty inventoried",
        "qtynot-inventaire": "qty not inventoried",
        "total": "total"
    },
    "th": {
        "path-inventaire": "ที่ตั้ง",
        "qty-inventaire": "จำนวนที่ตรวจสินค้าคงคลังแล้ว",
        "qtynot-inventaire": "จำนวนที่ยังไม่ตรวจสินค้าคงคลัง",
        "total": "รวมทั้งหมด"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            columnDefs: [
                {
                    headerName: this.$t("path-inventaire"),
                    field: "path",
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el,
                    hide:false,
                },
                {
                    headerName: this.$t("qty-inventaire"),
                    field: "qtyInventoried",
                    hide:false,
                },
                {
                    headerName: this.$t("qtynot-inventaire"),
                    field: "qtyNotInventoried",
                    hide:false,
                },
                {
                    headerName: this.$t("total"),
                    hide:false,
                    cellRenderer:function(params){
                        return parseInt(params.data.qtyInventoried) + parseInt(params.data.qtyNotInventoried);
                    }
                }
            ],
            gridOptions: {
                enableColResize: false,
                enableSorting: false,
                enableFilter: false,
                suppressDragLeaveHidesColumns: true,
                suppressMovableColumns: true
            },
        }

    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        agfilters: {
            handler: function(newfilters){
                this.$forceUpdate();
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            stateByPath: 'InventairesStore/getStateByPath'
        }),
        getStateByPathFiltered: function(){
            if(this.agfilters && this.agfilters.path && this.agfilters.path.value) return this.stateByPath.all.filter((state)=>state.path.indexOf(this.agfilters.path.value)!=-1);
            else return this.stateByPath.all;
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            })
            this.columnDefs.unshift({
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new InventaireLieuCellRender({
                    propsData: {
                        inventaire: params.data
                    }
                }).$mount().$el
            });
        }
    },
    methods: {
        selectPiece: function(row){
            this.$emit("select-piece", row);
        }
    }
}
</script>

<style lang="scss">
</style>
