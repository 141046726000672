<template>
    <vg-modal v-if="!isUpdateEquipementDisplayed" @save="handleSave" @close="$emit('cancel')" :isSaveDisabled="false" >
		<template #header v-if="!hasDisplayMessageUpdateEquipement">
            <span v-if="equipements.length > 1" >
                <span style="font-size:16px;" v-if="typeof getEquipement.libelle_categorie==='string'">{{getEquipement.libelle_categorie}}</span>
                <span style="font-size:16px;" v-else>{{getEquipement.libelle_categorie.libelle}}</span>
                <span style="font-size:12px;">{{equipements.length+$t("feedback-in-categorie")}} </span>
            </span>
            <span v-else-if="typeof getEquipement.libelle_categorie==='string'" > {{getEquipement.libelle_categorie}} </span>
            <span v-else>{{getEquipement.libelle_categorie.libelle}}</span>
        </template>

		<template #header v-else>
            <span>{{$t("modifierEquipementDialog")}}</span>
        </template>
        <template #body>
    		<div v-if="!hasDisplayMessageUpdateEquipement" style="display:flex;flex-direction:column;">
                    <div style="flex-direction:row;">
                        <img :src="getEquipement.icon" alt="..." height="32px" width="32px" />
                        <span>{{getEquipement.libel_equipement}}</span>
                    </div>

                    <span v-show="false">{{getEquipement.path}}</span>
                    <span class="secondary-text" style="margin-left:37px" >{{getEquipement.qrCode}}</span>
                    <vg-files-viewer-photo
                    	:files="VgFilesMixins_filesOnlyPhoto"
                    >
                    </vg-files-viewer-photo>
                    <div v-if="equipements.length == 1 || (equipements.length > 1 && scanNotRequired)" style="display:inherit;flex-direction:inherit;" >
                        <vg-select
                            v-model="getEquipement.statut"
                            :options="presence"
                            :attributeValue="'value'"
                            ref="dropdownStatut"
                        ></vg-select>
                        <vg-select
                            v-model="getEquipement.etat"
                            :options="etats"
                            :attributeValue="'value'"
                        ></vg-select>
                        <vg-input
                            :title="$t('commentaire')"
                            :inputType="'textarea'"
                            v-model="getEquipement.commentaire"
                        >
                        </vg-input>
                    </div>
                    <hr>
                    <div id="choix-equipement" v-if="equipements.length > 1 && !scanNotRequired" >
                        {{$t("feedback-multiple-equipement-found")}}
                    </div>
    		</div>
    		<div v-else style="display:flex;flex-direction:column;" v-html="equipementCellRender" @click="showEquipement"></div>
        </template>
        <template #footer>
            <div v-if="!hasDisplayMessageUpdateEquipement">
                <vg-button type="default-info" @click="$emit('cancel')" > {{$t("annuler")}} </vg-button>
                <vg-button type="default" @click="onClickScann" > <i class="fas fa-qrcode"></i> </vg-button> <!--quand on valide il faut confirmer avec scan -->
                <vg-button v-if="!scanNotRequired && equipements.length > 1" type="default-danger" @click="handleClickValiderAbsence">  {{$t("valider-absence")}} </vg-button>
                <vg-button v-else type="success" @click="handleClickValider">  {{$t("valider")}} </vg-button>
            </div>
            <div v-else>
                <vg-button type="default-info" @click="$emit('cancel')" > {{$t("non")}} </vg-button>
                <vg-button type="success" @click="isUpdateEquipementDisplayed=true">  {{$t("oui")}} </vg-button>
            </div>
        </template>
    </vg-modal>
    <vg-equipement-form
        v-else
        :id="this.getEquipement.equipement_id"
        :idLieu="this.getEquipement.lieu_id"
        @close="isUpdateEquipementDisplayed = false"
        @save="$emit('save')"
    >
    </vg-equipement-form>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import InventaireMixins from "src/mixins/InventaireMixins.js";
import VgEquipementMobileCellRender from "src/components/Vg/TagGrid/EquipementMobileCellRender.vue";
const ICON_DESKTOP = "fil.png";
const ICON_MOBILE = "fil_blanc.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(255, 199, 61)";

export default {
    name: "vg-operation-inventaire-create-form",
    components: {

        VgInput,
        VgSelect,
        VgButton,
        VgFilesViewerPhoto,
        VgEquipementForm
    },
    props:{
        equipements:{
            type:Array,
            default:function(){
                return [];
            }
        },
        lieu:{
            type:Object,
            default:function(){
                return null;
            }
        },
        scanNotRequired:Boolean
    },
    mixins: [ InventaireMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "feedback-in-categorie": "dans cette catégorie",
            "commentaire": "commentaire",
            "feedback-multiple-equipement-found": "Plusieurs equipements de cette catégorie Veuillez scanner ou déclarer absent",
            "annuler": "Annuler",
            "valider-absence": "Valider Absence",
            "valider": "Valider",
            "bon": "Bon",
            "moyen": "Moyen",
            "mauvais": "Mauvais",
            "present-dans": "présent dans",
            "etait": "était",
            "not-found": "Non trouvé",
            "feedback-status-input-empty": "preciser si l'equipement est présent ou absent avant de valider",
            "modifierEquipementDialog": "Voulez-vous modifier les informations de cet équipement ?",
            "oui": "Oui",
            "non": "Non"
        },
        "en": {
            "feedback-in-categorie": "in this category",
            "commentaire": "Comment",
            "feedback-multiple-equipement-found": "Multiple equivalent equipment's categories found, Please Scan or declare missing",
            "annuler": "Cancel",
            "valider-absence": "Declare Missing",
            "valider": "Validate",
            "bon": "Good",
            "moyen": "Middling",
            "mauvais": "Bad",
            "present-dans": "Located in",
            "etait": "was",
            "not-found": "Not found",
            "feedback-status-input-empty": "Define equipment's status before validation",
            "modifierEquipementDialog": "Do you want to update equipment's datas ?",
            "oui": "Yes",
            "non": "No"
        },
        "th": {
            "feedback-in-categorie": "ในหมวดหมู่นี้",
            "commentaire": "ความคิดเห็น",
            "feedback-multiple-equipement-found": "พบหมวดหมู่อุปกรณ์ที่ซ้ำเหมือนกัน โปรดแสกนหรืออธิบายส่วนที่สูญหาย",
            "annuler": "ยกเลิก",
            "valider-absence": "อธิบายส่วนที่สูญหาย",
            "valider": "ตรวสอบ",
            "bon": "ดี",
            "moyen": "ปานกลาง",
            "mauvais": "แย่",
            "present-dans": "ที่ตั้งใน",
            "etait": "คือ",
            "not-found": "ไม่พบ",
            "feedback-status-input-empty": "ระบุสถานะอุปกรณ์ก่อนการตรวจสอบ"
        }
    }
},
    data: function() {
        return {
            etats:[
                {"value":"Bon","label":this.$t("bon")},
                {"value":"Moyen","label":this.$t("moyen")},
                {"value":"Mauvais","label":this.$t("mauvais")}
            ],
            equipementsList:this.equipements,
            indexEquipement:0,
            isScannerOpened:false,
            isUpdateEquipementDisplayed:false,
            hasDisplayMessageUpdateEquipement:false
        }
    },
    watch: {},
    computed: {
        // computed et non data car si c'est en data this.getEquipement n'existe pas encore
        presence:function(){
            var path;
            if(this.lieu){
                path = this.lieu.path == this.getEquipement.path ?
                this.$t("etait") +" "+ this.$t("present-dans")+ " "+this.getEquipement.path.split("/")[this.getEquipement.path.split("/").length-1] :
                this.$t("present-dans")+ " " + this.getEquipement.path.split("/")[this.getEquipement.path.split("/").length-1];
            }else{
                path = this.$t("present-dans")+" "+this.getEquipement.path.split("/")[this.getEquipement.path.split("/").length-1];
            }


            return [
                {"value":"Ok","label":path},
                {"value":"notFound","label":this.$t('not-found')}
            ];
        },
        getEquipement:function(){
            return this.equipementsList[0];
        },
        equipementCellRender:function(){
            return new VgEquipementMobileCellRender({propsData: {params: this.getEquipement}}).$mount().$el.outerHTML;
        }
    },
    methods: {
        handleClickValiderAbsence:function(){
            this.getEquipement.statut = "notFound";
            this.getEquipement.etat = null;
            this.handleClickValider();
            //  ça enregistre bien par contre l'état de la bouteille ne refresh pas dans la vu precedente
            // check sur workbench que ça fonctionne bien
        },
        onClickScann:function(){
            this.$emit("launch-scanner");
        },
        handleClickValider:function(){
            let operationInventaire = {
                "inventaire_id":this.$route.params.id,
                "user_id":null,
                "statut":this.getEquipement.statut,
                "etat":this.getEquipement.etat,
                "equipement":this.getEquipement.equipement_id,
                "createdAt":moment().format("YYYY-MM-DD HH:mm:ss"),
                "updatedAt":moment().format("YYYY-MM-DD HH:mm:ss"),
                "userId":this.$app.appID,
                "derniersLieuConnu_id":this.getEquipement.lieu_id,
                "lieuInventorier_id":this.$route.params.lieuId
            };
            if(operationInventaire.statut != null && operationInventaire.statut != ""){
                this.InventaireMixins_create_operation(operationInventaire, this.$route.params.id, this.$route.params.lieuId).then((datas)=>{
                    this.hasDisplayMessageUpdateEquipement = true;
                    // this.$emit("save");
                });
            }else{
                alert(this.$t("feedback-status-input-empty"));
                this.$refs['dropdownStatut'].$el.focus();
            }
        },
        showEquipement:function(){
            this.$router.push({ name: "_equipement_id", params: { id: this.getEquipement.equipement_id } })
        }
    },
    created: function() {
        this.VgFilesMixins_init(this.getEquipement.categorie_uid, "equipement");
    }
}
</script>

<style lang="scss">
.secondary-text{
    font-size: 10px;
    color: grey;
}
</style>
