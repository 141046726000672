<template>
    <span>{{tva.name}} <small>{{tva.value}}%</small></span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"tva-cell-render",
        mixins:[LangMixins],
		props: {
			tva: {
				type: Object,
				required: true,
				default: function(){
                    return {};
                }
			}
		},
    });
</script>

<style lang="scss" scoped>

</style>
