
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
let actions = Base.getActions();
/**
* @param Object value {composant_id: <idComposant>, datas: [...]}
*/
actions.addComposantProblems = function( context, value ){
    context.commit('ADD_COMPOSANT_PROBLEMS', value);
};
/**
* @param Object value {composant_id: <idComposant>, problem_id: <idProblem>}
*/
actions.removeComposantProblem = function( context, value ){
    context.commit('REMOVE_COMPOSANT_PROBLEM', value);
};
let mutations = Base.getMutations();
mutations.ADD_COMPOSANT_PROBLEMS = function(state, value){
    let idComposant = value.composant_id;
    let indexComposant = state.collection.findIndex((composant)=>composant.id==idComposant);
    if(indexComposant!=-1){
        state.collection[indexComposant].problems = state.collection[indexComposant].problems.concat(value.datas);
    }
};
mutations.REMOVE_COMPOSANT_PROBLEM = function(state, value){
    let idComposant = value.composant_id;
    let indexComposant = state.collection.findIndex((composant)=>composant.id==idComposant);
    if(indexComposant!=-1){
        let indexProblem = state.collection[indexComposant].problems.findIndex((problem)=>problem.id==value.problem_id);
        if(indexProblem!=-1) state.collection[indexComposant].problems.splice(indexProblem, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
