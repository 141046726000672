<template>
    <vg-button id="portalBtn" size="sm" type="danger" @click="stripeMixins_openCustomerPortal()">{{ $t("open-portal") }}</vg-button>
</template>

<script>
import StripeMixins from 'src/mixins/StripeMixins.js';
import VgButton from 'src/components/Vg/VgButton.vue';
export default {
    name: 'stripe-open-portal-button',
    mixins:[],
    components:{
        VgButton
    },
    mixins:[
        StripeMixins
    ],
    props:{
    },
    directives: {
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "open-portal":"Gérer votre abonnement"
        },
        "en": {
            "open-portal":"Manage your subscription"
        }
    }
},
    data:function(){
        return {
            showMenu:false,
            isVisibleReport: !this.disabledReport,
        }
    },
    methods:{

    }

}
</script>

<style lang="scss">
#portalBtn{
		padding-left: 10px !important;
		padding-right: 10px !important;
		background-color: #fd5a55;
		border: 1px solid #fd5a55;
		box-shadow: 0 1px #fd5a55;
		border-radius: 4px;
		text-transform: uppercase;
		color: white !important;
	}

</style>
