// http://raw.githack.com/MrRio/jsPDF/master/docs/index.html

import { jsPDF } from "jspdf";

export default class Pdf {
    // @// TODO: revoir header et footer
    /**
    * @param string logo data en base 64
    * @param string title
    * @param string footerText
    * @param object jsPdfOptions
    */
  constructor(logo, title = "", address="", footerText="" , preFooter=null, jsPdfOptions = {}) {
    this.pdf = new jsPDF(jsPdfOptions);
    this.title = title;
    this.address = address;
    this.footerText = footerText;
    this.preFooter = preFooter;
    this.width = this.pdf.internal.pageSize.getWidth();
    this.height = this.pdf.internal.pageSize.getHeight();
    this.totalPage = 1;
    this.currentPage = 1;
    this.currentYPosition = 30; // position verticale
    this.currentXPosition = 10; // position horizontale
    this.spaceBetweenLines = 6;
    this.marginLeft = 10;
    this.marginRight = 10;
    this.footerHeight = 20;
    this.headerHeight = 12;
    this.theme = {
      primary: "#2aa9d4",
      primaryAccent: "#79d0ee",
      surface: "#ffffff",
      onSurface: "#bbbbbb"
    };
    if(logo) this.addImageOrganisation(logo);
    else{
        //this.setCurrentXPosition(80);
        this.setCurrentYPosition(20);
    }
    this.addHeader(this.title, this.address);
    if(preFooter && preFooter.length!=0) this.addPreFooter(this.preFooter);
    this.addFooter(this.footerText);
  }
  /**
   * Set font size.
   * @method setFontsize
   * @param {integer} fontSize
   * @return {Pdf} this
   */
  setFontSize(fontSize) {
    if (!fontSize) return false;
    this.pdf.setFontSize(fontSize);
    return this;
  }
  /**
   * Set Font Weigth.
   * @method setFontWeigth
   * @param  {integer} fontWeight description
   * @return {Pdf} this
   */
  setFontWeigth(fontWeight) {
    if (!fontWeight) return false;
    this.pdf.setFont("Helvetica", "", fontWeight);
    return this;
  }
  /**
   * @method setDrawColorFromHexadecimalColor
   * @param {string} hexaColor
   * @return {Pdf} this
   */
  setDrawColorFromHexadecimalColor(hexaColor) {
    this.pdf.setDrawColor(hexaColor);
    return this;
  }
  /**
   * @method setMarginLeft
   * @param {integer} value
   * @return {Pdf} this
   */
  setMarginLeft(value) {
    this.marginLeft = value;
    return this;
  }
  /**
   * SetCurrentXPosition.
   * @method setCurrentXPosition
   * @param  {type} value description
   * @return {type}       description
   */
  setCurrentXPosition(value){
      this.currentXPosition = value;
      return this;
  }
  /**
   * @method setCurrentYPosition
   * @param {integer} position
   * @param {Pdf} this
   */
  setCurrentYPosition(value) {
    this.currentYPosition = value;
    return this;
  }
  /**
   * getCurrentXPosition.
   * @method getCurrentXPosition
   * @return {integer}       currentXPosition
   */
  getCurrentXPosition(){
      return this.currentXPosition;
  }
  /**
   * getCurrentYPosition.
   * @method getCurrentYPosition
   * @return {integer}       currentYPosition
   */
  getCurrentYPosition(){
      return this.currentYPosition;
  }
  /**
   * addCurrentXPosition.
   * @method addCurrentXPosition
   * @param {integer} value
   */
  addCurrentXPosition(value){
      this.currentXPosition += value;
  }
  /**
   * addCurrentYPosition.
   * @method addCurrentYPosition
   * @param {integer} value
   */
  addCurrentYPosition(value){
      this.currentYPosition += value;
  }
  /**
   * getSpaceBetweenLines.
   * @method getSpaceBetweenLines
   * @return {integer}       spaceBetweenLines
   */
  getSpaceBetweenLines(){
      return this.spaceBetweenLines;
  }
  /**
   * Set color from hexa color string.
   * @method setTextHexadecimalColor
   * @param {string} hexColor
   */
  setTextHexadecimalColor(hexColor) {
    if (!hexColor) return false;
    this.pdf.setTextColor(hexColor);
  }
  /**
   * addList to pdf.
   * @method addList
   * @param  {array} labels list of string element
   * @param  {object} style  contains style for text and list
   * @param  {object} options  {textAlignRight: true} alignement du text à gauche default true
   */
  addList(labels, style, options={}){
      if(!options.textAlignRight){
          labels.forEach((label, i) => {
              this.addText(label, style);
              this.currentXPosition -= this._getTextLength(label);
              this.currentYPosition += this.spaceBetweenLines;
          });
      }else{
          let textLength = 0;
          labels.forEach((label, i) => {
              textLength = this._getTextLength(label);
              this.currentXPosition -= textLength;
              this.addText(label, style);
              this.currentYPosition += this.spaceBetweenLines;
          });
      }
  }
  /**
   * Set yAxis to
   * @method addNewTextLine
   * @return {Pdf} this.
   */
  addNewTextLine() {
    this.currentYPosition += this.spaceBetweenLines;
    this.currentXPosition = this._bodyLeftLimit();
    return this;
  }
  /**
   * @method addText
   * @param {string} text
   * @param {object} options
   * @param {integer} maxWidth
   */
  addText(text, options = {}, maxWidth=null) {
    if(!text && text == null) text = "";
    this.setFontSize(options.fontSize);
    this.setTextHexadecimalColor(options.color);
    //this.setFontWeigth(options.fontWeight);
    if (!this._isNotOverflowingBottom(this.currentYPosition)) {
      this.addPage();
    }
    if(maxWidth){
        let textLines = this.pdf.splitTextToSize(text, maxWidth);
        this.pdf.text(textLines, this.currentXPosition, this.currentYPosition);
        let lineHeight = this._getLineHeightFactor();
        let fontSizemm = this._getFontSizemm();
        this.currentYPosition += textLines.length * fontSizemm * lineHeight;    // décalle le curseur à après le text multiligne
    }else{
        this.pdf.text(text, this.currentXPosition, this.currentYPosition);
        this.currentXPosition += this._getTextLength(text);
    }
    return this;
  }
  /**
   * @method addText
   * @param {string} text
   * @param {object} options
   */
  addLine(options = {}) {
    this.pdf.line(
        this.currentXPosition,
        this.currentYPosition,
        this.width - this.marginRight,
        this.currentYPosition
    );
    return this;
  }
  /**
   * Draw table on the current yAxis and align to the left margin.
   * @method addTable
   * @param {array} headers ["a","b","c"]
   * @param {array} datas [{a:"...",b:"...",c:"..."},{...}]
   */
  addTable(headers, datas) {
    let defaultOptions = {
      autosize: true,
      printHeaders: true,
      primary: this.theme.primary
    };
    this.setDrawColorFromHexadecimalColor(this.theme.primaryAccent);
    this.pdf.table(
      this._bodyLeftLimit(),
      this.currentYPosition,
      datas,
      headers,
      defaultOptions
    );
    this.currentYPosition += this.spaceBetweenLines * 10; // 10 en dur à revoir
    return this;
  }
  /**
   * Draw html element with current x/yAxis.
   * @method addHtml
   * @param {string} html
   * @return {Promise}
   */
  addHtml(html) {
    return new Promise((resolve, reject) => {
      this.pdf.html(html, {
        callback: (doc) => {
          resolve(doc);
        },
        x: this.currentXPosition,
        y: this.currentYPosition
      });
    });
  }
  /**
   * @method addPage
   * @return {Pdf}
   */
  addPage(format = "a4", orientation = "p") {
    ++this.totalPage;
    ++this.currentPage;
    console.log("addPage");
    this.pdf = this.pdf.addPage(format, orientation);
    this.currentYPosition = this._bodyLeftLimit();
    this.currentXPosition = this._bodyLeftLimit();
    this.addHeader(this.title);
    this.addFooter(this.footerText);
    this.currentYPosition = 30; // document body start after the header

    return this;
  }
  addImageOrganisation(logoBase64){
      let imageProperties = this.pdf.getImageProperties(logoBase64);
      //console.log("IMAGE PROPS", imageProperties);
      let maxHeight = 20;
      let maxWidth = 80;
      let ratio = Math.min(maxWidth / imageProperties.width, maxHeight / imageProperties.height);
      this.pdf.addImage(logoBase64, imageProperties.fileType, this._bodyLeftLimit(), 0, imageProperties.width*ratio, imageProperties.height*ratio);
      this.setCurrentXPosition(80);
      this.setCurrentYPosition(20);
  }
  addSvgAsImage(datas, x, y, width, height){
      this.pdf.addSvgAsImage(datas, x, y, width, height);
  }
  getImageFormat(datas){
    let imageProperties = this.pdf.getImageProperties(datas);
    return imageProperties.fileType;
  }
  addImage(datas, format, x, y, _options={maxHeight: 30, maxWidth: 50}){
      let imageProperties = this.pdf.getImageProperties(datas);
      let maxHeight = _options.maxHeight;
      let maxWidth = _options.maxWidth;
      let ratio = Math.min(maxWidth / imageProperties.width, maxHeight / imageProperties.height);
      this.pdf.addImage(datas, format, x, y, imageProperties.width*ratio, imageProperties.height*ratio);
  }
  /**
   * add header on page .
   * @method addHeader
   * @param {string} title
   * @param {object} options
   */
  addHeader(title, address=null, options = {}) {
    this.setFontSize(options.fontSize);
    this.setTextHexadecimalColor(options.color);
    this.setDrawColorFromHexadecimalColor(this.theme.primaryAccent);
    this.pdf.setLineWidth(1);
    let lineYPosition = this.currentYPosition;
    this.setCurrentYPosition(lineYPosition/2);
    //this.pdf.text(title , this.currentXPosition, this.currentYPosition/2);
    this.addText(title, {}, 200);
    if(address) {
        this.pdf.setLineWidth(1 / 72);
        this.pdf.setFontSize(11);
        this.setFontWeigth(100);
        this.setTextHexadecimalColor("#3c4042");
        //this.setCurrentYPosition(this.currentYPosition/2+this._getLineHeightFactor*);
        this.addText(address, {}, 100);
        this.setCurrentYPosition(this.currentYPosition+4);
    }
    this.setCurrentXPosition(this._bodyLeftLimit());
    //this.setCurrentYPosition(lineYPosition);
    this.pdf.line(
      this.currentXPosition,
      this.currentYPosition-2, //headerHeight,
      this.width - this.marginRight,
      this.currentYPosition-2
    );
    //this.currentYPosition += this.spaceBetweenLines;
    this.pdf.setLineWidth(1 / 72);
    this.pdf.setFontSize(11); //optional
  }
  /**
   * Add footer.
   * @method addFooter
   * @param {string} text
   */
  addFooter(text) {
    this.setFontSize(12);
    this.pdf.line(
      this.currentXPosition,
      this._bodyBottomLimit(),
      this._bodyRightLimit(),
      this._bodyBottomLimit()
    );
    let centerLineFooter = this.height - this.footerHeight + this.footerHeight / 2;
    let bottomRightTextSlot = this._bodyRightLimit() - this._getTextLength(text);
    this.pdf.text(
      this.currentXPosition,
      centerLineFooter,
      this.currentPage + ""
    ); //print number bottom left
    this.pdf.text(bottomRightTextSlot, centerLineFooter, text); //print number bottom left
  }
  /**
   * Add pre footer.
   * @method addPreFooter
   * @param {string} text
   */
  addPreFooter(text) {
    let centerLineFooter = this.height - this.footerHeight + this.footerHeight / 4;
    this.setFontSize(10);
    this.pdf.text(this._bodyLeftLimit(), centerLineFooter, text);
  }
  /**
   * @method addImageFromUrl
   * @param {string} url
   * @return {Promise}
   */
  addImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
      image.onload = () => {
        this.pdf.addImage(
          image,
          "JPEG",
          10,
          this.currentYPosition + this.spaceBetweenLines,
          50,
          50
        );
        resolve();
      };
      image.src = url;
    });
  }
  /**
   * save pdf with given name.
   * @method save
   * @param {string} name
   */
  save(name) {
    this.pdf.save(name);
  }

  /**
   * _getTextLength.
   * @param  {string} text
   * @return {integer} return text length in mm
   */
  _getTextLength(text) {
    if(!text)return 0;
    let fontSize = this.pdf.getFontSize();
    return this.pdf.getStringUnitWidth(text) * fontSize/2.5;
  }
  /**
   * _getLineHeightFactor.
   * http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html#getLineHeightFactor
   * @return {integer} return line height factor
   */
  _getLineHeightFactor() {
    return this.pdf.getLineHeightFactor();
  }
  /**
   * _getFontSize().
   * http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html#getFontSize
   * @return {integer} return current fontSize en px
   */
  _getFontSize() {
    return this.pdf.getFontSize();
  }
  /**
   * _getFontSizemm().
   * @return {integer} return current fontSize
   */
  _getFontSizemm() {
    let fontSize = this._getFontSize();
    return fontSize / (72/25.6);
  }
  /**
   * @return {integer}
   */
  _bodyBottomLimit() {
    return this.height - this.footerHeight;
  }
  /**
   * @return {integer}
   */
  _bodyRightLimit() {
    return this.width - this.marginRight;
  }
  /**
   * @return {integer}
   */
  _bodyLeftLimit() {
    return this.marginLeft;
  }
  /**
   *
   * @param {integer} currentXPosition
   * @return {boolean}
   */
  _isNotOverflowingRight(currentXPosition) {
    return currentXPosition < this.width - this.marginRight;
  }
  /**
   *
   * @param {integer} currentYPosition
   * @return {boolean}
   */
  _isNotOverflowingBottom(currentYPosition) {
    return currentYPosition < this.height - this.footerHeight;
  }
  /**
   * get formatted date dd/mm/yyyy
   * @return {string}
   */
  _getDate() {
    let now = new Date();
    return now.getDate() + "/" + now.getMonth() + "/" + now.getFullYear();
  }
  /**
   * @param integer size
   */
  setSpaceBetweenLines(size){
    this.spaceBetweenLines = size;
    return this;
  }
}
