//ConsommableMouvements store module

import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();

let state = Base.getState();
state.evolutionQuantiteStock = [];
state.evolutionQuantiteConsommee = [];
state.repartitionQuantitesSites = [];
state.repartitionQuantitesServices = [];
state.repartitionQuantitesDemandeurs = [];
state.demandeurs = [];

let getters = Base.getGetters();
getters.getEvolutionQuantiteStock = function(state){
    return state.evolutionQuantiteStock;
};
getters.getEvolutionQuantiteConsommee = function(state){
    return state.evolutionQuantiteConsommee;
};
getters.getRepartitionQuantitesSites = function(state){
    return state.repartitionQuantitesSites;
};
getters.getRepartitionQuantitesServices = function(state){
    return state.repartitionQuantitesServices;
};
getters.getRepartitionQuantitesDemandeurs = function(state){
    return state.repartitionQuantitesDemandeurs;
};
getters.getDemandeurs = function(state){
    return state.demandeurs;
};

let actions = Base.getActions();
actions.setRepartitionQuantites = function( context, value ){
    context.commit('SET_REPARTITION_QUANTITES', value);
};
actions.setEvolutionQuantiteStock = function( context, value ){
    context.commit('SET_EVOLUTION_QUANTITE_STOCK', value);
};
actions.setEvolutionQuantiteConsommee = function( context, value ){
    context.commit('SET_EVOLUTION_QUANTITE_CONSOMMEE', value);
};
actions.setRepartitionQuantitesSites = function( context, value ){
    context.commit('SET_REPARTITION_QUANTITES__SITES', value);
};
actions.setRepartitionQuantitesServices = function( context, value ){
    context.commit('SET_REPARTITION_QUANTITES__SERVICES', value);
};
actions.setRepartitionQuantitesDemandeurs = function( context, value ){
    context.commit('SET_REPARTITION_QUANTITES__DEMANDEURS', value);
};
actions.setDemandeurs = function( context, value ){
    context.commit('SET_DEMANDEURS', value);
};

let mutations = Base.getMutations();
mutations.SET_REPARTITION_QUANTITES = function(state, value){
    state.evolutionQuantiteStock = value.evolutionQuantiteStock;
    state.evolutionQuantiteConsommee = value.evolutionQuantiteConsommee;
    state.repartitionQuantitesSites = value.repartitionQuantitesSites;
    state.repartitionQuantitesServices = value.repartitionQuantitesServices;
    state.repartitionQuantitesDemandeurs = value.repartitionQuantitesDemandeurs;
};
mutations.SET_EVOLUTION_QUANTITE_STOCK = function(state, value){
    state.evolutionQuantiteStock = value.evolutionQuantiteStock;
};
mutations.SET_EVOLUTION_QUANTITE_CONSOMMEE = function(state, value){
    state.evolutionQuantiteConsommee = value;
};
mutations.SET_REPARTITION_QUANTITE_SITES = function(state, value){
    state.repartitionQuantitesSites = value;
};
mutations.SET_REPARTITION_QUANTITES_SERVICES = function(state, value){
    state.repartitionQuantitesServices = value;
};
mutations.SET_REPARTITION_QUANTITES_DEMANDEURS = function(state, value){
    state.repartitionQuantitesDemandeurs = value;
};
mutations.SET_DEMANDEURS = function(state, value){
    state.demandeurs = value;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
