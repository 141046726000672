<template>
	<vg-modal
        @close="$emit('close')">
        <template #header>
			<span>{{$t("demande-validation")}}: <b>{{bonDeCommande.numero}}</b></span>
        </template>
        <template #body>
			<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
				<span style="font-size:18px;"><b>{{bonDeCommande.fournisseur.name}}</b></span>
				<br>
				<span>{{$t("envoie-email-demande-a")}}: </span>
				<br>
				<div v-for="(groupe, indexGroupe) in groupeValidateurs"
					:key="'groupe'+indexGroupe"
					style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
					<span v-for="(groupeValidateurUser, indexValidateur) in groupe.validateurs"
						:key="'groupe'+indexGroupe+'validateur'+indexValidateur"
						style="margin-bottom:5px;">
						{{groupeValidateurUser.validateur.nom}} {{groupeValidateurUser.validateur.prenom}}
					</span>
				</div>
			</div>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button :type="'danger'"
				@click="envoyerBonDeCommande">
                {{$t("btn-envoyer")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgButton from "src/components/Vg/VgButton.vue";

	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

    import { mapGetters } from 'vuex';

    export default {
        name: 'vg-bon-de-commande-demande-validation',
		components: {
			VgModal,
			VgButton
		},
		mixins: [ BonsDeCommandeMixins ],
    	props: {
            value: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "demande-validation": "Demande validation",
            "envoie-email-demande-a": "Envoyer l'email de demande de validation à",
            "btn-cancel": "Annuler",
            "btn-envoyer": "Confirmer l'envoi"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			envoyerBonDeCommande: function(){
				this.BonsDeCommandeMixins_demandeValidation(this.bonDeCommande).then((data)=>{
                    console.log("AFTER DEMANDE VALIDATION BC", data);
					this.$emit("save", data);
                });
			},
		},
        computed: {
			...mapGetters({
                bonDeCommande: 'BonsDeCommandeStore/getSelectedItem',
                groupeValidateurs: "GroupeValidateursStore/getCollection"
            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
