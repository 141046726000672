//VgTree store module

// initial state
const state = {
    datas: [
        /*{label:"mobilier", children: [
            {label:"chaise", children:[
                {id:4,libelleCatgorie:"Chaise haute bar"}
            ]},
            {label:"table", children:[
                {id:5,libelleCatgorie:"Table ronde"}
            ]}
        ]},
        {label:"SSI", children: [
            {id:6,libelleCatgorie:"PC"},
            {label:"Extincteur", children:[
                {id:7,libelleCatgorie:"Extincteur CO2"},
                {id:8,libelleCatgorie:"Extincteur à poudre"}
            ]}
        ]},
        {label:null, children: [
            {id:10,libelleCatgorie:"Aileron"}
        ]}*/
    ],
    nodePending: null,
    isCollapseAll: false, // collapse tous les nodes de l'Arborescence
    nodeSelected: null
};

// getters
const getters = {
    getDatas: function( state ){
        return state.datas;
    },
    getNodePending: function( state ){
        return state.nodePending;
    },
    getIsCollapseAll: function( state ){
        return state.isCollapseAll;
    },
    getNodeSelected: function( state ){
        return state.nodeSelected;
    }
};

// actions
const actions = {
    setDatas: function( context, datas ){
        context.commit('SET_DATAS', datas);
    },
    updateNodePending: function( context, nodePending ){
        context.commit('UPDATE_NODE_PENDING', nodePending);
    },
    updateIsCollapseAll: function( context, isCollapseAll ){
        context.commit('UPDATE_IS_COLLAPSE_ALL', isCollapseAll);
    },
    updateNodeSelected: function( context, nodeSelected ){
        context.commit('UPDATE_NODE_SELECTED', nodeSelected);
    }
};

// mutations
const mutations = {
    SET_DATAS: function( state, datas ){
        state.datas = datas;
    },
    UPDATE_NODE_PENDING: function( state, nodePending ){
        state.nodePending = nodePending;
    },
    UPDATE_IS_COLLAPSE_ALL: function( state, isCollapseAll ){
        state.isCollapseAll = isCollapseAll;
    },
    UPDATE_NODE_SELECTED: function( state, nodeSelected ){
        state.nodeSelected = nodeSelected;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
