<template>
    <label class="switch">
      <input type="checkbox" v-model="isChecked" :name="name" />
      <span class="slider"></span>
    </label>
</template>

<script>
export default {
  name: "vg-switch",
    props: {
        checked: {
          type: Boolean,
          default: false
        },
        value: {
          type: String,
          default: ""
        },
        name: {
          type: String,
          default: ""
        }
    },
    data: function() {
        return {
            isChecked: this.checked
        };
    },
    watch:{
        isChecked: function(isCheckedCheckbox){
            if(isCheckedCheckbox){
                this.$emit('input',true);
            } else {
                this.$emit('input',false);
            }
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {
            status:true
        }
      },
      template: `
        <div>
            <vg-switch
                name="label"
                v-model="status"
                :checked="false"
            ></vg-switch>
        </div>
      `
    })
    ```
</docs>
