<template lang="html">
    <span class="vg-fichesav-origin">
        <img src="/static/assets/icone/origine-DI-jaune.png" :height="height" alt="origine-DI" v-if="fm.origin=='DI'">
        <img src="/static/assets/icone/origine-verification-orange.png" :height="height" alt="origine-verification" v-if="fm.origin=='Verification'">
    </span>
</template>

<script>
export default {
    name:"vg-fichesav-origin",
    props:{
        fm:{
            type:Object,
            required:true
        },
        /**
         * Defini la hauteur de l'image
         */
        height:{
            type:String,
            default:"22px"
        }
    },

}
</script>

<style lang="css" scoped>
img{
    margin: 0;
}
</style>
