<template>
    <vg-modal
        @close="$emit('close')">
        <template #header>
            <span>{{$t("title")}}
                <b>
                    <vg-lieu-path-viewer v-model="lieu.path"
                        onlyPiece
                        :type="lieu.type_lieu"
                        :showTooltip="false">
                    </vg-lieu-path-viewer>
                </b>
            </span>
        </template>
        <template #body>
            <vg-files-uploader
                :tagsList="VgFilesMixins_plansTags"
                :fileType="'application/pdf'"
                @upload-done="VgFilesMixins_fetchFilesByUid()">
            </vg-files-uploader>
            <hr>
            <vg-files-viewer-list-section
                :files="VgFilesMixins_plansListSection"
                @delete="VgFilesMixins_fetchFilesByUid()">
            </vg-files-viewer-list-section>
        </template>
        <template #footer>
            <vg-button
                @click="$emit('close')">
                {{$t("close")}}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";

import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

export default {
    name:"vg-files-plans-upload",
    props:{
        lieu:{
            type: Object,
            required: true
        }
    },
    components:{

        VgButton,
        VgFilesUploader,
        VgFilesViewerListSection,
        VgLieuPathViewer
    },
    mixins:[

    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "close": "Fermer",
            "title": "Attacher un plan à "
        },
        "en": {
            "close": "Close",
            "title": "Attach a plan to"
        },
        "th": {
            "close": "ปิด",
            "title": "แนบแผนงานไปที่"
        }
    }
},
    data:function(){
        return {

        }

    },
    methods:{
        /**
         * close modal
         *
         * @event close
         */
        close:function(){
            this.$emit("close");
        },
    },
    created: function() {
        this.VgFilesMixins_init(this.lieu.uid, "lieu");
    }
}
</script>
<style lang="scss" scoped>

</style>
