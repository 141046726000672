<template>
    <div>
        <small><vg-datetime-viewer v-model="params.data.dateMouvement"/> <span>{{params.data.user}}</span></small>
        <br>
        <span v-if="params.data.flag=='consommation-fm' && params.data.operation">
            {{$t("consommation-fm")}}
            <b class="link">{{params.data.operation.ficheSav_id}}</b>
            <vg-button size="xs" type="info" @click="onClickMaintenance">
                {{$t("voir-fm")}}
            </vg-button>
        </span>
        <span v-else-if="params.data.flag=='inventaire-moins'">
            {{$t("inventaire-moins")}}
        </span>
        <span v-else-if="params.data.flag=='inventaire-plus'">
            {{$t("inventaire-plus")}}
        </span>
        <span v-else-if="params.data.flag=='bon-de-sortie'">
            {{$t("bon-de-sortie")}}
            <b v-if="this.params.data.bonDeSortie" class="link">{{params.data.bonDeSortie.numero}}</b>
            <vg-button size="xs" type="info" @click="openFile">
                {{$t("voir-bs")}}
            </vg-button>
            <br>
            <vg-button v-if="params.data.ficheDemandeConsommable_id"
                size="xs" type="info" @click="openFDC">
                {{$t("voir-fdc")}}
            </vg-button>
        </span>
        <span v-else-if="params.data.flag=='bon-de-commande-livraison'">
            {{$t("bon-de-commande-livraison")}} <b @click="onClickBonDeCommande" class="link">{{ params.data.bonDeCommande.numero }}</b>
        </span>
        <span v-else-if="params.data.flag=='livraison'">
            {{ $t("livraison") }}
        </span>
        <span v-else>
            {{params.data.flag}}
        </span>
        <br>
        <small>{{$t("consommation")}}: {{params.data.quantite}} {{params.data.consommable.unite}}</small>
        <small v-if="params.data.quantiteInitialeOrigin">| {{$t("stock-avant-mouvement")}}: {{params.data.quantiteInitialeOrigin}} {{params.data.consommable.unite}}</small>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "consommable-mouvements-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isAutomaticOpenLink:{
                type: Boolean,
                default: true
            }
		},
        components:{
            VgOperationFlag,
            VgDatetimeViewer,
            VgButton
        },
        data: function() {
            return {

			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "consommation-fm": "Consommation fiche n°",
            "inventaire-plus": "Ajustement + inventaire physique",
            "inventaire-moins": "Ajustement - inventaire physique",
            "bon-de-sortie": "Bon de sortie",
            "stock-avant-mouvement": "Stock avant consommation",
            "consommation": "Consommation",
            "voir-bs": "Voir bon de sortie",
            "voir-fm": "Voir fiche curative",
            "fdc": "FDC n°",
            "voir-fdc": "Voir fiche demande consommables",
            "bon-de-commande-livraison": "Livraison à partir du bon de commande n° ",
            "livraison": "Livraison"
        },
        "en": {
            "inventaire-plus": "Adjustment + physical inventory",
            "inventaire-moins": "Adjustment - physical inventory",
            "consommation-fm": "Consumption sheet n°",
            "stock-avant-mouvement": "Stock before consumption",
            "consommation": "Consumption",
            "voir-bs": "See delivery note",
            "voir-fm": "See maintenance sheet",
            "fdc": "FDC n°",
            "voir-fdc": "See consumables request form",
            "bon-de-commande-livraison": "Delivery from purchase order n° ",
            "livraison": "Delivery"
        },
        "th": {
            "inventaire-plus": "การปรับเพิ่มสินค้าคงคลังทางกายภาพ",
            "inventaire-moins": "การปรับลดสินค้าคงคลังทางกายภาพ"
        }
    }
},
        methods:{
            onClickMaintenance: function(){
                this.$emit("go-to-maintenance",this.params.data.operation.ficheSav_id);
            },
            onClickBonDeCommande: function(){
                this.$emit("go-to-bon-de-commande", this.params.data.bonDeCommande_id);
            },
            openFile: function(){
				if(this.params.data.bonDeSortie.document) this.VgFilesMixins_getDownloadFileLink(this.params.data.bonDeSortie.document).then((link)=>
                    this.isAutomaticOpenLink ? window.open(link, '_blank') : this.$emit("click-link", link)
                );
			},
            openFDC: function(){
                this.$emit("go-to-fdc",this.params.data.ficheDemandeConsommable_id);
            }
        }
    });
</script>

<style lang="scss" scoped>
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
</style>
