<template>
<div class="vg-categories-table">
	<tag-grid
		:idTableau="isGe?'vg-categories-lieux-table':'vg-categories-equipements-table'"

		v-model="gridOptions"
		:columnDefs="columnDefs"
		:rowData="getCategories"
        :exportFileName="$t('categories')"
		:showLoadingOverlay="showLoadingOverlay"
		:overlayLoadingText="$t('overlay-loading-text')"
		:overlayNoRowsText="$t('overlay-no-rows-text')"
        @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
		@ag-dbl-click="handleDoubleClickRow"
		@ag-click="handleClickRow" >
	</tag-grid>
</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";

    import VgButton from "src/components/Vg/VgButton.vue";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import CategorieCellRender from "src/components/Vg/TagGrid/CategorieCellRender.vue";
	import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
	import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";
	import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
	import CategorieQuantiteEquipementsCellRender from "src/components/Vg/TagGrid/CategorieQuantiteEquipementsCellRender.vue";

	import TagGridMixins from 'src/mixins/TagGridMixins.js';
	import CategorieMixins from "src/mixins/CategorieMixins.js";

    import Metadatas from "src/services/Metadatas.js";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-categories-table',
    	props: {
			/**
			* affiche les catégories lieux ou équipements: isGe=true => catégories lieux || isGe=false => catégories équipements
			* default false
			*/
			isGe:{
				type: Boolean,
				default: false
			},
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			agfilters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			* @deprecated
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			}
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			agfilters: {
				handler: function(newfilters){
                    //console.log("FILTERS WATCH", newfilters);
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			}
		},
		mixins: [
			TagGridMixins,
			CategorieMixins
		],
		components: {
			TagGrid,
			IconNameCellRender,
			VgButton
		},
		i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"column-header-libel-categorie": "Libellé",
					"column-header-tags": "étiquettes",
					"column-header-nb-lieux": "Nb pièces",
					"column-header-nb-equipements": "Nb équipements",
					"column-header-nb-taches": "Nombre taches",
					"column-header-nb-composants": "Nombre composants",
					"indice-criticite": "Indice de criticité",
					"position-etiquette": "Emplacement étiquette collé",
					"inventoriable": "Inventoriable",
					"column-taux-depreciation": "Taux dépréciation annuelle",
					"column-header-corps-detat": "Corps d'état",
					"column-header-prix-defaut": "Prix par défaut",
					"column-header-ref-constructeur-default": "Ref constructeur",
					"column-header-source-financement-default": "Source financement par défaut",
					"categories": "catégories",
					"column-accountingtag": "étiquette comptable",
					"column-set-statut-equipement": "Set statut équipement"
				},
				"en": {
					"column-header-libel-categorie": "Description",
					"column-header-tags": "Tags",
					"column-header-nb-lieux": "Number of rooms",
					"column-header-nb-equipements": "Number of equipments",
					"column-header-nb-taches": "Number of tasks",
					"column-header-nb-composants": "Number of components",
					"indice-criticite": "Criticity index",
					"position-etiquette": "Sticked Label code location",
					"inventoriable": "Inventoriable",
					"column-taux-depreciation": "Annual deprecation rate",
					"column-header-corps-detat": "Technical field",
					"column-header-prix-defaut": "Default price",
					"column-header-ref-constructeur-default": "Manufacturer reference",
					"column-header-source-financement-default": "Funding origin",
					"categories": "categories",
					"column-accountingtag": "Accounting tag",
					"column-set-statut-equipement": "Set equipment status"
				},
				"th": {
					"column-header-libel-categorie": "คำอธิบาย",
					"column-header-nb-lieux": "หมายเลขของสถานที่",
					"column-header-nb-taches": "หมายเลขของภารกิจ",
					"column-header-nb-composants": "หมายเลขของส่วนประกอบ"
				}
			}
		},
        data: function(){
            return {
				showLoadingOverlay: false,
				gridOptions: {
					isExternalFilterPresent: ()=>this.TagGridMixins_isExternalFilterPresent(),
                    doesExternalFilterPass: (node)=>this.TagGridMixins_doesExternalFilterPass(node)
				},
				columnDefs: [
                    {
						headerName: this.$t("column-header-libel-categorie"),
						field: "libelleCatgorie",
						colId: "libelleCatgorie",
						cellRenderer: (params) => new CategorieCellRender({ propsData: { params: params.data } }).$mount().$el
					},
                    {
						headerName: this.$t("column-header-nb-"+this.isGe?"lieux":"equipements"),
						field: "countEquipements",
						colId: "countEquipements",
						hide: !this.isGe,
						isColumnShowable:!this.isGe,
						cellRenderer: (params) => new CategorieQuantiteEquipementsCellRender({propsData: {
                            params: params,
                            attributeQuantite: "countEquipements",
                            libelleCategorie: params.data.libelleCatgorie,
                            typeEquipement: this.isGe?"piece":"equipement",
                            isGep: this.isGe
                        }})
                        .$on("open-page-equipements", this.openPageEquipements)
                        .$on("open-page-lieux", this.openPageLieux)
                        .$mount().$el
					},
                    {
						headerName: this.$t("column-header-tags"),
						field: "tags",
						colId: "tags",
                        cellRenderer: (params) => {

                            if(params.data.tags && params.data.tags.length!=0){
                                let tags = params.data.tags.split("/");
                                return new TagCellRender({propsData: {tags: tags, icon:"tag.png",color:"blue"}}).$mount().$el;
                            }else{
                                return "-";
                            }
                        },
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-header-corps-detat"),
						field: "corpsdetat",
						colId: "corpsdetat",
                        cellRenderer: (params) => {
                            if(params.data.corpsDetat){
                                return new TagCellRender({propsData: {tags: params.data.corpsDetat && params.data.corpsDetat.length!=0?params.data.corpsDetat[0].name:null, icon:"tag.png",color:"red"}}).$mount().$el;
                            }else{
                                return "-";
                            }
                        },
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-accountingtag"),
						field: "accountingTagLabel",
						colId: "accountingTagLabel",
                        cellRenderer: (params) => new TagCellRender({propsData: {tags: params.data.accountingTagLabel, icon:"tag.png"}}).$mount().$el,
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-taux-depreciation"),
						field: "tauxDepreciationAnnuelDefault",
						colId: "tauxDepreciationAnnuelDefault",
                        cellRenderer: (params) => {
                            let tauxDepreciationAnnuel = "";
                            if(params.tauxDepreciationAnnuelDefault){
                                tauxDepreciationAnnuel = params.tauxDepreciationAnnuelDefault + "%"
                            }
                            return tauxDepreciationAnnuel;
                        },
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-header-prix-defaut"),
						field: "prixDefault",
						colId: "prixDefault",
                        cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.prixDefault}}).$mount().$el,
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-header-ref-constructeur-default"),
						field: "refConstructeurDefault",
						colId: "refConstructeurDefault",
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("column-header-source-financement-default"),
						field: "sourceFinancementDefault",
						colId: "sourceFinancementDefault",
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
					{
						headerName: this.$t("indice-criticite"),
						field: "indiceCriticite",
						colId: "indiceCriticite",
						hide: this.isGe
					},
					{
						headerName: this.$t("position-etiquette"),
						field: "positionEtiquette",
						colId: "positionEtiquette",
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
                    {
						headerName: this.$t("inventoriable"),
						field: "isInventoriable",
						colId: "isInventoriable",
                        cellRenderer: (params) => new OuiNonCellRender({
                            propsData: {
                                params: params,
                                isValid: params.data.isInventoriable && params.data.isInventoriable == "1"
                            }
                        }).$mount().$el,
						hide: this.isGe,
						isColumnShowable:this.isGe
					},
					{
						headerName: this.$t("column-header-nb-taches"),
						field: "countTaches",
						colId: "countTaches"
					},
					{
						headerName: this.$t("column-header-nb-composants"),
						field: "countComposants",
						colId: "countComposants"
					}
				],
                metadatasCategories: new Metadatas(),
            };
        },
		created: function(){
            if(this.$vgutils.isMobile()){
                this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                })

                this.columnDefs.unshift({
					headerName: this.$t("column-header-libel-categorie"),
					field: "libelleCatgorie",
					colId: "libelleCatgorie",
					cellRenderer: (params) => new CategorieCellRender({ propsData: { params: params.data } }).$mount().$el
				});
            }

			this.fetch();
		},
		mounted: function(){
			if(!this.$vgutils.isMobile()){
				this.gridOptions.columnApi.setColumnVisible('countEquipements', true);
	            //this.gridOptions.columnApi.setColumnVisible('thumbnail', true);
	            if(this.isGe){
	                this.gridOptions.columnApi.setColumnVisible('accountingTagLabel', false);
	                this.gridOptions.columnApi.setColumnVisible('tauxDepreciationAnnuelDefault', false);
	                this.gridOptions.columnApi.setColumnVisible('positionEtiquette', false);
	                this.gridOptions.columnApi.setColumnVisible('isInventoriable', false);
	                this.gridOptions.columnApi.setColumnVisible('openMaintenanceOnError', false);
	                this.gridOptions.columnApi.setColumnVisible('indiceCriticite', false);
	            }else{
	                this.gridOptions.columnApi.setColumnVisible('countComposants', false);
	            }
			}
		},
		methods: {
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				this.metadatasCategories.setFilters(Object.assign({}, this.agfilters, {
					isGe: {attr: "c.isGe", value: this.isGe?"1":"0", action: "equals"}
				}));
				this.CategorieMixins_getCategories(this.metadatasCategories).then((datas)=>{
					console.log("SUCCESS GET CATEGORIES", datas);
					this.showLoadingOverlay = false;
				});
			},
			handleClickRow: function(e){
                this.$store.dispatch("CategoriesStore/setSelectedItem", e.data);
				this.$emit("row-click", e.data);
				// if(this.$vgutils.isMobile()) this.$router.push({ name: '_equipement_id', params: { id: row.data.id } });
			},
			handleDoubleClickRow: function(e){
                this.$store.dispatch("CategoriesStore/setSelectedItem", e.data);
				this.$emit("row-dbl-click", e.data);
			},
            handleMultipleSelectionRowChanged: function(gridOptions){
				var selectedNodes = gridOptions.api.getSelectedNodes();
                /*let categories = selectedNodes.map((node)=>{
                    return node.data;
                });
                this.$store.dispatch("MaintenancesStore/selectMaintenances", maintenances);*/
			},
			openPageEquipements: function(datas){
				this.$router.push({ name: '_equipements', params: { defaultFilters: datas.filters }});
			},
			openPageLieux: function(datas){
				this.$router.push({ name: '_lieux', params: { defaultFilters: datas.filters }});
			},
		},
		beforeDestroy:function(){

	    },
        computed:{
            ...mapGetters({
                  categoriesLieux: 'CategoriesStore/getCategoriesLieux',
                  categoriesEquipements: 'CategoriesStore/getCategoriesEquipements'
            }),
			getCategories: function(){
				return this.isGe?this.categoriesLieux:this.categoriesEquipements;
			}
        }
    };
</script>
<style lang="scss" scoped>
.vg-categories-table{
	height: 100%;
}
</style>
