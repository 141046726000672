<template lang="html">
    <div class="VgChips">
        <div class="vg-chips" v-for="chip,place in chips" v-if="!(chip.value == null || chip.value == '') ">
            <span class="vg-chips-key">{{chip.name}}</span> : <span class="vg-chips-value">{{chip.value}}</span>
            <div class="chips-close" @click="suppChip(place)">
                <img src="static/assets/icone/fermer.png" height="10" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "vg-chips",
    props: {
        value : {
            type: [Object, Array],
            default : function(){
                return null;
            }
        },
        chips:{
            type: Array,
            default: function(){
                return [];
            }
        },

    },
    data:function(){
        return {
            // chips: this.value,
        }
    },
    methods:{
        suppChip: function (data){

            this.value[data] = {colId: data, value: null};
            this.$emit('input', this.value);
            this.$emit('chips', this.value);
        }
    },
    mounted: function(){
        this.$emit('input', this.value);
    }

}
</script>

<style lang="scss">
.VgChips{
    .vg-chips{
        float: left;
        height: 35px;
        padding: 8px;
        border: 1px solid #0066FF;
        font-size: 13px;
        // border-radius: 5px;
        margin-right: 15px;
        .vg-chips-key{
            color: #0099FF;
            font-weight: bold;
        }
        .vg-chips-value{
            color:#7d7d7d;
        }
    }
    .chips-close{
        float: right;
        cursor: pointer;
        margin-left: 15px;
        margin-top: -2px;
        img{
            height: 10px !important;
        }
    }
}
</style>
