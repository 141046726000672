<template>

    <!-- ETAT INVENTAIRE SUR UNE PIECE ? CE QUI EST FAIT ET CE QUI NEST PAS ENCORE FAIT  -->
    <vg-app-layout
        :title="$t('inventorier-dans')+ getLocation()"
        :icon="'Icone-blanche-equipement_VG.png'"
        :colorheader="getHeaderBackgroundColor"
        :isZoomLayout="true"
        @backpage="btnBackPage()">

        <template v-slot:search>
            <vg-text-filter v-model="searchQuery">
            </vg-text-filter>
        </template>

        <template v-slot:body>
            <!-- liste des pièces à inventorier pour l'inventaire courant -->
            <vg-inventaire-recapitulatif-lieu-id v-show="!isScannerOpened"
                :searchQuery="searchQuery"
                @select-equipement="selectEquipement">
            </vg-inventaire-recapitulatif-lieu-id>
            <zxing-scanner v-if="isScannerOpened"
                :isDemo="false"
                :code="'qrcode'"
                @scanSuccess="findEquipementByQrCode"
                @error="handleScannerError">
            </zxing-scanner>
        </template>
        <template v-slot:footer v-if="!isScannerOpened">
            <vg-button
                class="footer-btn-open-scanner"
                :type="'info'"
                @click="isScannerOpened=true">
                <i class="fas fa-qrcode fa-2x"></i> {{$t('open-scanner')}}
            </vg-button>
        </template>
        <template v-slot:footer v-else>
            <vg-button
                class="footer-btn-open-scanner"
                :type="'info'"
                @click="isScannerOpened=false">
                {{$t('close-scanner')}}
            </vg-button>
        </template>
        <vg-operation-inventaire-create-form
            v-show="!isScannerOpened"
            v-if="isOperationInventaireOpened"
            :equipements="selectedEquipement"
            :scanNotRequired="openCreateFormHasBeenOpenedFromScanSuccess"
            @cancel="closeOperationInventaire"
            @save="closeOperationInventaire"
            @launch-scanner="isScannerOpened=true">
        </vg-operation-inventaire-create-form>
    </vg-app-layout>
</template>

<script>

import VgInventaireCreateUpdateForm from "src/components/Vg/Inventaire/VgInventaireCreateUpdateForm.vue";
import VgInventaireRecapitulatif from "src/components/Vg/Inventaire/VgInventaireRecapitulatif.vue";
import VgInventaireRecapitulatifLieuId from "src/components/Vg/Inventaire/VgInventaireRecapitulatifLieuId.vue";
import VgOperationInventaireCreateForm from "src/components/Vg/Inventaire/VgOperationInventaireCreateForm.vue";
import InventaireLieuCellRender from "src/components/Vg/TagGrid/InventaireLieuCellRender.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
import equipementsMixins from "src/mixins/equipementsMixins.js";
import Metadatas from "src/services/Metadatas.js";
// import LieuMixins from 'src/mixins/LieuMixins.js';
import InventaireMixins from "src/mixins/InventaireMixins.js";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';


const ICON_DESKTOP = "fil.png";
const ICON_MOBILE = "fil_blanc.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "#2AA2DB";

import { mapGetters } from 'vuex';

export default {
    name: "inventaire-lieu",
    components: {

        VgTextFilter,
        VgSwitch,
        VgSelect,
        VgInput,
        VgButton,
        VgInventaireCreateUpdateForm,
        InventaireLieuCellRender,
        VgInventaireRecapitulatif,
        VgInventaireRecapitulatifLieuId,
        VgOperationInventaireCreateForm,
        zxingScanner
    },
    mixins: [
        equipementsMixins,
        InventaireMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "feedback-not-found-equipement": "Equipement non trouvé dans la base de données",
            "feedback-equipement-already-inventoried": "Voulez vous modifier l'inventaire de cet equipement ?",
            "inventorier-dans": "Inventorier dans ",
            "open-scanner": "Ouvrir le scanner d étiquette",
            "close-scanner": "Fermer le scanner"
        },
        "en": {
            "feedback-not-found-equipement": "Equipement not found in the database",
            "feedback-equipement-already-inventoried": "Do you want to modify this tracking ?",
            "inventorier-dans": "Inventories in ",
            "open-scanner": "Open tag reader",
            "close-scanner": "Close scanner"
        },
        "th": {
            "feedback-not-found-equipement": "ไม่พบอุปกรณ์บนฐานข้อมูลนี้",
            "feedback-equipement-already-inventoried": "ต้องการแก้ไขสินค้าคงคลังนี ?",
            "inventorier-dans": "สินค้าคงคลังใน ",
            "open-scanner": "เปิดตัวอ่านแท็ก"
        }
    }
},
    data: function() {
        return {
            isCreateInventaireModalOpened:false,
            isScannerOpened:false,
            isOperationInventaireOpened:false,
            selectedEquipement:null,
            openCreateFormHasBeenOpenedFromScanSuccess:false,
            searchQuery: ""
        };
    },
    computed: {
        ...mapGetters({
            operationsInventaire: 'OperationsInventairesStore/getCollection',
            inventoried: "OperationsInventairesStore/getInventoried",
            notInventoried: "OperationsInventairesStore/getNotInventoried"
        }),
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        }
    },
    methods: {
        btnBackPage:function(){
            if(this.isScannerOpened) this.isScannerOpened=false;
            else this.$router.back();
        },
        deleteOperationInventaire: function(equipement){
            this.InventaireMixins_removeOperationInventaire(equipement).then((data)=>{

            });
        },
        selectEquipement : function(row,equipementWasInThisPiece=true){
            let equipement = row.data;
            let categorie = equipement.libelle_categorie;
            let equipements = !equipementWasInThisPiece ? [equipement]:[];
            let equipementsWithTheSameCategorie = this.operationsInventaire.filter((eqp)=>eqp.libelle_categorie == categorie && eqp.isMissing == null);
            // TODO verifier si x equipement avec la même catégorie
            if( equipementsWithTheSameCategorie.length ){
                equipementsWithTheSameCategorie.forEach(function(e){
                    e['commentaire'] = "";
                    e['etat'] = "Bon";
                    equipements.push(e);
                });
            }
            // si c'est le cas on balance le tout au form de creation operation
            if(equipement.isMissing != null && !equipement.isMissing){

                var r = confirm(this.$t("feedback-equipement-already-inventoried"));
                if (r == true) {
                    //this.$emit("cancel-operation-inventaire",equipement);
                    this.deleteOperationInventaire(equipement);
                } else {
                }
                return;
            };
            // @// HACK:  le formulaire prend x equipements mais il selectionne le premier
            // donc je met lequipement sur la première position.
            // et j'enlève son doublon dans la liste
            equipements.splice(equipements.findIndex((element) => element.equipement_id == equipement.equipement_id), 1);
            equipements.unshift(equipement);
            this.selectedEquipement = equipements; // Attention il envoie les équipements de la même catégorie au form pourquoi il nen faut qu'un seul
            this.isOperationInventaireOpened = true;
        },
        findEquipementByQrCode: function(code){
            var row = {"data":{}};
            var that = this;
            this.openCreateFormHasBeenOpenedFromScanSuccess = true;
            row.data = this.operationsInventaire.filter((equipement)=>equipement.qrCode == code);
            // gérer le cas qrCode non trouvé ici
            if(!row.data.length){
                let metadatasEquipements = new Metadatas();
                let filters = {
                    path: { attr:"e.qrCode",colId: "qrCode", value: code, action:"contains" }
                }
                this.isScannerOpened = false;
                metadatasEquipements.setFilters(filters);
                // replace by getEquipements with metadatas
                this.equipementsMixins_getEquipements(metadatasEquipements).then((equipements)=>{
                    if(!equipements.datas.length){
                        alert(this.$t('feedback-not-found-equipement'));
                        return false;
                    }else{
                        let foundEquipement = equipements.datas[0];
                        var equipement = {
                            "libel_equipement":foundEquipement.libel_equipement,
                            "path":foundEquipement.path,
                            "inventaire_id":that.$route.params.id,
                            "lieu_id":foundEquipement.idLieu,
                            "equipement_id":foundEquipement.id,
                            "createdAt":null,
                            //"service":foundEquipement.id_lieu.service,
                            //"servicesLieu":null,
                            "qrCode":foundEquipement.qrCode,
                            "libelle_categorie":foundEquipement.categorie,
                            "icon":foundEquipement.icon,
                            "isMissing":null,
                            "hasMoved":true,
                            "commentaire":"",
                            "etat":"Bon"
                        }
                        row.data = equipement;
                        row.data['statut'] = "Ok";
                        that.selectEquipement(row,false);
                    }
                });
            }else{
                row.data = row.data[0];
                row.data['statut'] = "Ok";
                this.selectEquipement(row,true);
            }
            this.isScannerOpened = false;
        },
        closeOperationInventaire:function(){
            this.isOperationInventaireOpened = false;
            this.openCreateFormHasBeenOpenedFromScanSuccess = false;
        },
        handleScannerError: function(e){
            //alert(e.message);
            //this.isScannerOpened = false;
        },
        getLocation: function(){
            let result;
            let firstElementOfInventoried = this.inventoried && this.inventoried.length?this.inventoried[0]:null;
            let firstElementOfNotInventoried = this.notInventoried && this.notInventoried.length?this.notInventoried[0]:null;
            if( firstElementOfInventoried ){
                let splittedPath = firstElementOfInventoried.path.split("/");
                result = splittedPath[splittedPath.length-1];
            }else if( firstElementOfNotInventoried ){
                let splittedPath = firstElementOfNotInventoried.path.split("/");
                result = splittedPath[splittedPath.length-1];
            }
            return result;
        }
    },
    created: function() {

    }
}
</script>

<style lang="scss" scoped>
.footer-btn-open-scanner{
    height: 100%;
    width: 100%;
    margin-right: 0;
    opacity: 1 !important;
    background-color: #059cff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    >i{
        margin-right: 10px;
    }
}
</style>
