<template>
    <div class="equipement-check-lieu-cell-render">
        <div class="reponse">
            <div class="icon"></div>
            <vg-equipement-viewer :equipement="params.data"/>
        </div>
        <img v-if="!isMaintenance" src="/static/assets/icone/verif-conforme.png" @click="onClickEquipement"/>
        <img v-else src="/static/assets/icone/verif-non-conforme.png"/>
        <vg-maintenance-form v-if="showMaintenanceForm"
            :key="'maintenance-form-'+params.data.id"
            :displayedOpenButton="false"
            @close="onCloseMaintenanceForm"
            @created="onCreatedMaintenance"/>
    </div>
</template>

<script>
    import Vue from "vue";
	import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
    import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
    export default Vue.extend({
        name:"equipement-check-lieu-cell-render",
		mixins:[LangMixins],
        components: {
            VgEquipementViewer,
            VgButton,
            VgMaintenanceForm
        },
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isDefaultMaintenance:{
                type: Boolean,
                default: false
            }
		},
        data: function(){
            return {
                isMaintenance: this.isDefaultMaintenance || false,
                showMaintenanceForm: false
            }
        },
        watch:{
            isDefaultMaintenance:{
                handler: function(isDefault){
                    this.isMaintenance = isDefault;
                }
            }
        },
        methods:{
            onClickEquipement: function(){
                this.$store.dispatch("EquipementsStore/setSelectedItem", this.params.data);
                this.isMaintenance = true;
                this.showMaintenanceForm = true;
            },
            onCloseMaintenanceForm: function(){
                this.isMaintenance = false;
                this.showMaintenanceForm = false;
                this.$store.dispatch("EquipementsStore/deleteSelectedItem");
            },
            onCreatedMaintenance: function(){
                this.showMaintenanceForm = false;
                this.$store.dispatch("EquipementsStore/deleteSelectedItem");
            },
        }
    });
</script>
<style lang="scss" scoped>
.equipement-check-lieu-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
    width: inherit;
    padding: 10px 0;
    .reponse{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        color: #74767a !important;
        .icon{
				width:3px;
				height: 14px;
				background-color: rgb(243, 111, 58);
			}
        .name{
            font-weight: normal !important;
        }
    }
	img{
		width:20px;
		height:20px;
	}
}
</style>
<style lang="scss">
.reponse{
    span{
        font-weight: normal !important;
    }
}
</style>
