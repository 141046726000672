<template>
    <div style="display: flex;flex-direction:column;">
        <h3> {{ $t("stripe-customer-state") }} </h3>
        <div v-if="customerState && customerState.subscriptions.length">
            <div v-for="subscription in customerState.subscriptions" style="display:flex;align-items: center;">
                <div> <img :src="subscription.product_images[0]" alt="" width="32px" height="32px"/> </div>
                <div> {{ subscription.product_name }} </div>
            </div>
            <stripe-open-portal-button></stripe-open-portal-button>
        </div>
        <div v-else>
            <div> {{ $t("no-subscription") }} 
            <!-- Add button to pricing list -->
            </div>
        </div>
    </div>
</template>

<script>
import StripeMixins from 'src/mixins/StripeMixins.js';
import VgButton from 'src/components/Vg/VgButton.vue';
import StripeOpenPortalButton from "src/components/Vg/Stripe/StripeOpenPortalButton.vue";

export default {
    name: 'stripe-customer-state',
    mixins:[],
    components:{
        VgButton,
        StripeOpenPortalButton
    },
    mixins:[
        StripeMixins
    ],
    props:{
    },
    directives: {
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "stripe-customer-state":"État de l'abonnement",
        },
        "en": {
            "stripe-customer-state":"Subscription state",
        }
    }
},
    data:function(){
        return {
            showMenu:false,
            customerState: {}
        }
    },
    created(){
        this.stripeMixins_getCustomerState().then((customerState)=>{
            this.customerState = customerState;
        });
    },
    methods:{

    }

}
</script>

<style lang="scss">


</style>
