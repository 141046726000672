<template>
    <vg-app-layout
        :title="getPageTitle"
        :icon="'lieu.png'"
        :colorheader="getHeaderColor"
        :isActionButtonDisplay="false"
        :isZoomLayout="true"
        @backpage="retourPage">

        <template #primary>
            <div v-if="ifcText && spatialTree">
                <vg-button v-show="!showFurnitures" @click="showFurnitures = true">{{$t("show-furnitures")}}</vg-button>
                <vg-button v-show="showFurnitures" @click="showFurnitures = false">{{$t("show-furnitures")}}</vg-button>
                <div class="arborescence-header">
                    <img src="static/assets/icone/lieu.png" height="15" alt="icone_lieu"/> <span>{{$t("arborescence-lieux")}}</span>
                </div>
                <vg-collapse v-for="(floorLevel, key) in getStoreys" :key="key"
                    :collapseByDefault="isCollapseByDefault(floorLevel)"
                    :killContentOnCollapse="false"
                    :type="'section'"
                    style="margin:0 20px;">
                    <template #title>
                        <div :class="['ifcEntity',{'ifc-entity-selected': isIfcEntitySelected(floorLevel)}]"
                            @click="displayItems(0,floorLevel)">
                            <img src="/static/assets/icone/lieux/etage-mauve.png" class="level-icon"/>
                            <span>{{floorLevel.Name.value}}</span>
                            <span v-if="floorLevel.LongName && floorLevel.LongName.value" > - {{floorLevel.LongName.value}}</span>
                        </div>
                    </template>
                    <template #content>
                        <ul>
                            <li v-for="(entity,entityName) in ifcSpaceOf(floorLevel.children)" :key="entityName">
                                <div :class="['ifcEntity',{'ifc-entity-selected': isIfcEntitySelected(entity)}]"
                                    @click="highlightItems([floorLevel.children[entityName].expressID], floorLevel.children[entityName])">
                                    <img src="/static/assets/icone/lieux/piece-mauve.png" class="level-icon"/>
                                    <span>{{entity.Name.value}} - {{entity.LongName.value}}</span>
                                </div>
                                <ul v-if="showFurnitures && floorLevel.children[entityName].children.length">
                                    <li v-for="child in floorLevel.children[entityName].children" :key="child.expressID">
                                        <div class="ifcEntity" @click="highlightItems([child.expressID])"> {{child.Name.value}} </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </template>
                </vg-collapse>
            </div>
                <!--vg-collapse :title="$t('montrer-cacher')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                        <div v-if="subsetsReady">
                            <vg-checkbox
                                v-for="entityName in Object.keys(subsets)"
                                :defaultChecked="true"
                                :label="$t(entityName)"
                                @checked="showSubset(entityName)"
                                @unchecked="hideSubset(entityName)"
                                :key="entityName"
                            ></vg-checkbox>
                        </div>
                    </template>
                </vg-collapse-->


        </template>

        <template #secondary>
            <div>
                {{  loadingFeedback }}
            </div>
            <div v-if="selectedLieu && !selectedLieu.lienFichierIfc && !ifcText" class="screen-centered">
                <span>{{ $t("no-ifc-file-attached") }}</span>
                <label for="file-upload" class="file-input-wrapper">
                    {{ $t("attacher-un-fichier-ifc") }}
                    <input id="file-upload" type="file" @change="onInputFile">
                </label>
            </div>
            <div>
                <span v-if="loadingPercentage != 0 && loadingPercentage != 100" >{{$t("loading-file")}} {{loadingPercentage}} %</span>
                <div v-if="loadingPercentage != 0 && loadingPercentage != 100" class="lds-dual-ring"></div>
    
                <vg-collapse :title="$t('ifc-table')"
                    :collapseByDefault="false"
                    :killContentOnCollapse="false"
                    :type="'section'"
                    v-if="loadingPercentage == 100">
                    <template #titlecomplement>
                        <span v-if="selectedFloorLevel && selectedFloorLevel.Name && selectedFloorLevel.Name.value" class="viewer-filter">
                            <span class="subtitle">{{$t("etage-selectionne")}}:</span>
                            {{selectedFloorLevel.Name.value}}
                            <!--vg-button type="grey" size="sm" @click="deselectEtage">X</vg-button-->
                        </span>
                        <span v-if="selectedFloorLevel && selectedFloorLevel.LongName && selectedFloorLevel.LongName.value" class="viewer-filter">
                            <span class="subtitle">{{$t("etage-selectionne")}}:</span>
                            - {{selectedFloorLevel.Name.value}}
                            <!--vg-button type="grey" size="sm" @click="deselectEtage">X</vg-button-->
                        </span>
                    </template>
                    <template #content>
                        <ifc-datas-extractor v-if="ifcText"
                            :ifcText="ifcText"
                            :searchQuery="searchQuery"
                            :floorLevel="selectedFloorLevel"
                            @select-ifc-entity-express-id="onSelectIfcRow" />
                    </template>
                </vg-collapse>
                <br>
                <vg-collapse :title="$t('ifc-viewer')"
                    :collapseByDefault="false"
                    :killContentOnCollapse="false"
                    :type="'section'"
                    v-if="loadingPercentage == 100">
                    <template #titlecomplement>
                        <span v-if="selectedFloorLevel && selectedFloorLevel.Name && selectedFloorLevel.Name.value" class="viewer-filter">
                            <span class="subtitle">{{$t("etage-selectionne")}}:</span>
                            {{selectedFloorLevel.Name.value}}
                            <!--vg-button type="grey" size="sm" @click="deselectEtage">X</vg-button-->
                        </span>
                        <span v-if="selectedFloorLevel && selectedFloorLevel.LongName && selectedFloorLevel.LongName.value" class="viewer-filter">
                            <span class="subtitle">{{$t("etage-selectionne")}}:</span>
                            - {{selectedFloorLevel.Name.value}}
                            <!--vg-button type="grey" size="sm" @click="deselectEtage">X</vg-button-->
                        </span>
                        <span v-if="selectedEntity" class="viewer-filter">
                            <span class="subtitle">{{$t(selectedEntity.type+"-selectionne")}}:</span>
                            <span v-if="selectedEntity.Name && selectedEntity.LongName">{{selectedEntity.Name.value}} - {{selectedEntity.LongName.value}}</span>
                            <span v-else>{{selectedEntity.name}}</span>
                            <vg-button type="grey" size="sm" @click="deselectPiece">X</vg-button>
                        </span>
                    </template>
                    <template #content>
                        <ifc-viewer v-if="ifcFile"
                            @spatialTreeLoaded="keepSpatialTreeInMemory"
                            :ifcFile="ifcFile"
                            :highlitedItems="highlitedItems"
                            :displayedItems="displayedItems"
                            :fetchSpatialTree="fetchSpatialTree"
                            :status-change="displayViewerStatus" />
                    </template>
                </vg-collapse>
            </div>
        </template>
    </vg-app-layout>
</template>
<script>

import { mapGetters } from 'vuex';

import LieuMixins from 'src/mixins/LieuMixins.js';
import IfcDatasExtractor from "src/components/Vg/IFC/IfcDatasExtractor.vue";
import IfcViewer from "src/components/Vg/IFC/IfcViewer.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgButton from "src/components/Vg/VgButton.vue";

export default {
	name: 'batiment',
    mixins:[ LieuMixins ],
	props:{
		//value : Object
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "IFCSPACE":"Pièce",
            "IFCDOOR":"Porte",
            "IFCSTAIR":"Escalier",
            "IFCWINDOW":"Fenêtre",
            "IFCSLAB":"Dalles",
            "IFCCOVERING":"Revêtement",
            "IFCBUILDINGELEMENTPROXY":"Equipement intermédiaire",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Mobilier",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Mur porteur",
            "IFCDISCRETEACCESSORY":"Accessoire discret",
            "IFCCURTAINWALL":"Mur rideau",
            "IFCBEAM":"IPE",
            "ifc-table":"Détails",
            "ifc-viewer":"Vue 3d",
            "entities":"Entités",
            "montrer-cacher":"montrer/cacher",
            "loading-file":"Chargement du fichier IFC",
            "selected-etage":"Etage selectionné",
            "IFCWALL":"Mur",
            "arborescence-lieux": "Arborescence lieux",
            "batiment": "Batiment",
            "etage-selectionne": "Etage sélectionné",
            "IFCSPACE-selectionne": "Pièce sélectionnée",
            "IFCWINDOW-selectionne": "Fenêtre sélectionnée",
            "IFCDOOR-selectionne": "Porte sélectionnée",
            "IFCWALL-selectionne": "Mur sélectionné",
            "IFCFURNISHINGELEMENT-selectionne": "Equipement sélectionné",
            "IFCBUILDINGELEMENTPROXY-selectionne": "Equipement sélectionné",
            "show-furnitures": "Montrer les équipements",
            "IFCFURNITURE-selectionne": "Equipement sélectionné",
            "no-ifc-file-attached": "Aucun fichier IFC (BIM) attaché à ce bâtiment",
            "attacher-un-fichier-ifc": "Attacher un fichier IFC",
            "invalid-ifc-file": "Uniquement les fichier .ifc sont autorisés",
            "fetching-batiment": "Chargement des informations du bâtiment"
        },
        "en":{
            "IFCSPACE":"Room",
            "IFCDOOR":"door",
            "IFCSTAIR":"Stair",
            "IFCWINDOW":"Window",
            "IFCSLAB":"Slab",
            "IFCCOVERING":"Covering",
            "IFCBUILDINGELEMENTPROXY":"Proxy equipment",
            "IFCFLOWTERMINAL":"Flow terminal",
            "IFCFURNISHINGELEMENT":"Furniture",
            "IFCANNOTATION":"Annotation",
            "IFCRAILING":"Rail",
            "IFCWALLSTANDARDCASE":"Bearing wall",
            "IFCDISCRETEACCESSORY":"Discrete accessory",
            "IFCCURTAINWALL":"Curtain wall",
            "IFCBEAM":"EPI",
            "ifc-table":"Details",
            "ifc-viewer":"3d viewer",
            "entities":"Entities",
            "montrer-cacher":"Show/hide",
            "loading-file":"Loading ifc file",
            "selected-etage":"Selected floor level",
            "IFCWALL":"Wall",
            "batiment": "Building",
            "etage-selectionne": "Selected level",
            "IFCSPACE-selectionne": "Selected room",
            "IFCWINDOW-selectionne": "Selected window",
            "IFCDOOR-selectionne": "Selected door",
            "IFCWALL-selectionne": "Selected wall",
            "IFCFURNISHINGELEMENT-selectionne": "Selected equipment",
            "IFCBUILDINGELEMENTPROXY-selectionne": "Equipement sélectionné",
            "show-furnitures": "Hide the equipments",
            "IFCFURNITURE-selectionne": "Selected equipments",
            "no-ifc-file-attached": "No IFC file attached to this building",
            "attacher-un-fichier-ifc": "Attach an IFC file",
            "invalid-ifc-file": "Only .ifc files are allowed",
            "fetching-batiment": "Fetching building informations"
        },
    }
    },
	components:{
        IfcDatasExtractor,
        IfcViewer,
        VgCollapse,
        VgCheckbox,
        VgButton
    },
    data:function(){
       return {
           //ifcFileLocation:"https://raw.githubusercontent.com/buildingSMART/Sample-Test-Files/master/IFC%202x3/Medical-Dental%20Clinic/Clinic_Architectural.ifc",
           //ifcFileLocation:"https://raw.githubusercontent.com/buildingSMART/Sample-Test-Files/master/IFC%202x3/Medical-Dental%20Clinic/Clinic_Architectural.ifc",
           ifcFileLocation:"",
           searchQuery:null,
           floorAreaSurface:0,
           viewer:null,
           subsets:{},
           spatialTree:null,
           highlitedItems:[],
           displayedItems:[],
           ignoredEntities:["IFCWALL","IFCWALLSTANDARDCASE"],
           subsetsReady:false,
           fetchSpatialTree:false,
           showFurnitures:false,
           ifcFile:null,
           ifcText:null,
           selectedFloorLevel:null,
           loadingPercentage:0,
           selectedEntity: null,
           loadingFeedback:""
       }
    },
    watch: {},
    created:function(){
        if(!this.selectedLieu){
            this.fetchBatiment();
        }else{
            this.initIfcFile();
            /**/
        }



    },
    mounted:function(){

    },
    computed:{
        ...mapGetters({
            selectedLieu:"LieuxStore/getSelectedItem",
            groupByTypePerLevel: 'LieuxIFCStore/groupByTypePerLevel',
            getFloorSurfaces: 'LieuxIFCStore/getFloorSurfaces',
            getRawIfc: 'LieuxIFCStore/getRawIfc'
        }),
        getStoreys:function(){
            if(!this.spatialTree) return;
            let project = this.spatialTree;
            let site = null;
            let batiments = project.children;
            if(project.children[0].type == "IFCSITE"){
                site = project.children[0];
            }
            if(site){
                batiments = site.children;
            }
            let storeys = batiments[0].children;
            // should not have more than 1 building
            return storeys;

        },
        			/**
			 * @return array tabs
			 */
			tabs: function(){
					return [
                        { label: this.$t("tab-maintenances"), name: "table",
							filters: []
						},
						{ label: this.$t("tab-verifications"), name: "viewer",
							filters: []
						}
					];
			},
        /**
         * @return object
         */
        currentTab: function(){
            return this.tabs.find((tab)=>tab.name==this.currentTabName);
        },
        getPageTitle: function(){
            if(this.selectedLieu) return this.$t("batiment")+": "+this.selectedLieu.libel_lieu;
            else return this.$t("batiment");
        },
    },
    methods:{
        initIfcFile: function(){
            if(this.selectedLieu.lienFichierIfc){
                this.ifcFileLocation = this.selectedLieu.lienFichierIfc;
                this.fetchIfcFile(this.ifcFileLocation).then((ifcFile)=>{
                    this.ifcFile = ifcFile;
                });
            }
        },
        fetchBatiment: function(){
            this.loadingFeedback = this.$t("fetching-batiment");
            this.LieuMixins_getLieu(this.$route.params.id).then(()=>{
                this.initIfcFile();
                this.loadingFeedback = ""
            });
        },
        retourPage: function(){
            this.$router.go(-1);
        },
        fetchIfcFile:function(url){
            return new Promise((resolve, reject)=>{
                var oReq = new XMLHttpRequest();
                oReq.responseType = "text";
                oReq.addEventListener('progress', (event) => {
                    this.loadingPercentage = Math.floor((event.loaded * 100) / event.total)
                })
                oReq.addEventListener("load", () => {
                    console.log("IFC TEXT", oReq.reponse);
                    this.ifcText = oReq.response;
                    var file = new File([oReq.response], "ifcFile");
                    resolve(file);
                    //this.viewer.IFC.loadIfc(file,true);
                    //new Uint8Array(oReq.response);
                });
                oReq.open("GET", url);
                oReq.send();
            });
        },
        onInputFile:function(event){
            const fileInput = event.target;
            const fileName = fileInput.files[0].name;

            if (!fileName.toLowerCase().endsWith('.ifc')) {
                alert(this.$t("invalid-ifc-file"));
                return;
            }
            let ifcURL = URL.createObjectURL(event.target.files[0]);
            this.fetchIfcFile(ifcURL).then((ifcFile)=>{
                this.ifcFile = ifcFile;
            });
        },
        keepSpatialTreeInMemory:function(spatialtree){
            this.spatialTree = spatialtree;
        },
        displayItems:function(modelID, floorLevel){
            let entities = floorLevel.children;
            this.selectedFloorLevel = floorLevel;
            this.displayedItems = this.getStoreysId(modelID, entities);
        },
        onSelectIfcRow: function(datas){
            this.highlightItems(datas.expressIDs, datas.entity);
        },
        highlightItems:function(expressIDs, entity){
            this.highlitedItems = expressIDs;
            this.selectedEntity = entity;
        },
        ifcSpaceOf:function(elementsIncludesInStorey){
            let elements =  elementsIncludesInStorey.filter(element => element.type == "IFCSPACE" );
            return elements;
        },
        getStoreysId:function(modelID, floorLevelEntities){
            console.log("GET STOREYS ID", modelID, floorLevelEntities);
            function findAllByKey(obj, keyToFind) {
                if(!obj) return [];
                return Object.entries(obj)
                    .reduce((acc, [key, value]) => (key === keyToFind)
                    ? acc.concat(value)
                    : (typeof value === 'object')
                    ? acc.concat(findAllByKey(value, keyToFind))
                    : acc
                    , [])
            }
            let removeIfcTypes = function(ifcEntities, typesToRemove){

                return ifcEntities.filter(entity => !typesToRemove.includes(entity.type))
            }

            floorLevelEntities = removeIfcTypes(floorLevelEntities,["IFCSPACE","IFCBEAM","IFCCOVERING"]);
            let ids = findAllByKey(floorLevelEntities, 'expressID');

            return ids;
        },
        displayViewerStatus:function(status){
            console.log(status);
        },
        isIfcEntitySelected: function(entity){
            return this.highlitedItems.indexOf(entity.expressID)!=-1 || (this.selectedFloorLevel?this.selectedFloorLevel.expressID==entity.expressID:false);
        },
        isCollapseByDefault: function(floorLevel){
            if(this.selectedFloorLevel) return this.selectedFloorLevel.expressID!=floorLevel.expressID;
            else return true;
        },
        deselectEtage: function(){
            this.selectedFloorLevel = null;
            this.displayedItems = [];
        },
        deselectPiece: function(){
            this.highlitedItems = [];
            this.selectedEntity = null;
        }
    }

}

</script>
<style lang="scss" scoped>
$level-icon-height: 16px;
$level-icon-width: 16px;
ul{
    list-style-type: none;
}
li{
    list-style-type: none;
}
    .ifcEntity{
        cursor: pointer;
        padding: 5px 0;
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    .ifcEntity:hover{
        background-color: rgba(233, 222, 237, 0.5);
    }
    .ifc-entity-selected{
        background-color: rgb(233, 222, 237);
    }
  .badge-75 {
    --badge-size: 75px;
  }

  .badge-200 {
    --badge-size: 200px;
  }

  .badge {
    /* Un rond vert */
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    background-color: limegreen;
    border: calc(var(--badge-size) / 10) solid lightgray;
    /* Des initiales blanches */
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(var(--badge-size) / 1.75);
    font-weight: bold;
    /* Et centrées */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

.arborescence-header{
    width:100%;
    background-color:#E8EAEE;
    font-size:13px;
    padding:5px 20px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    margin-bottom: 10px;
}
.level-icon{
    width: $level-icon-height;
    height: $level-icon-height;
}
.viewer-filter{
    border: 1px solid #35b1ea;
    padding: 0 5px;
    .subtitle{
        font-size: 11px;
    }
}
.screen-centered{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 8px 12px;
  cursor: pointer;
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-wrapper span {
  display: inline-block;
  vertical-align: middle;
}

.file-input-label {
  margin-left: 8px;
}
</style>
