<template>
    <vg-datetime-viewer v-if="datetime" v-model="datetime"
        :hideTime="hideTime">
    </vg-datetime-viewer>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "datetime-cell-render",
        mixins:[LangMixins],
		props: {
			datetime: {
				type: String,
				required: true
			},
            hideTime:{
                type: Boolean,
                default: false
            }
		},
        components: {
            VgDatetimeViewer
        }
    });
</script>

<style scoped>

</style>
