<template>
	<span v-if="fm.statut=='En_cours'" class="en_cours">
		{{$t("en_cours")}}
	</span>
	<span v-else-if="fm.statut=='Resolue'" class="resolue">
		{{$t("resolue")}}
	</span>
	<span v-else-if="fm.statut=='en_attente' && fm.dateEnAttente" class="en_attente titlehover"
		:title="tooltipEnAttenteContent">
		{{$t("en_attente")}}
	</span>
	<span v-else-if="fm.statut=='en_attente' && !fm.dateEnAttente" class="en_attente">
		{{$t("en_attente")}}
	</span>
	<span v-else-if="fm.statut=='prise_en_compte' && fm.datePriseEnCompte" class="prise_en_compte titlehover"
		:title="tooltipPriseEnCompteContent">
		{{$t("prise_en_compte")}}
	</span>
	<span v-else-if="fm.statut=='prise_en_compte' && !fm.datePriseEnCompte" class="prise_en_compte">
		{{$t("prise_en_compte")}}
	</span>
	<span v-else-if="fm.statut=='Supervisor'" class="a_valider">
		{{$t("a_valider")}}
	</span>
	<span v-else>
		{{fm.statut}}
	</span>
</template>
<script>
    export default {
        name: 'vg-fichesav-statut-viewer',
    	props: {
            fm: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "en_cours": "En cours",
            "resolue": "Résolue",
            "en_attente": "En attente",
            "prise_en_compte": "Prise en compte",
            "a_valider": "A valider",
            "par": "par"
        },
        "en": {
            "en_cours": "In progress",
            "resolue": "Resolved",
            "en_attente": "On hold",
            "prise_en_compte": "taken into account",
            "a_valider": "To validate",
            "par": "by"
        },
        "th": {
            "en_cours": "กำลังดำเนินการ",
            "resolue": "แก้ไขปัญหาแล้ว",
            "en_attente": "พัก",
            "prise_en_compte": "รวม",
            "a_valider": "เพื่อตรวจสอบ",
            "par": "โดย"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			tooltipPriseEnCompteContent: function(){
				return this.$vgutils.formatDatetimeYMDtoDMY(this.fm.datePriseEnCompte)+" "+this.$t("par")+" "+this.fm.userPriseEnCompte;
			},
			tooltipEnAttenteContent: function(){
				return this.$vgutils.formatDatetimeYMDtoDMY(this.fm.dateEnAttente)+" "+this.$t("par")+" "+this.fm.userEnAttente;
			}
		}
    };

</script>
<style lang="scss" scoped>
span{
	margin-left: 5px;
	vertical-align: middle;
	font-size: 11px;
	display: inline-block;
	line-height: 1;
	padding: .5833em .833em;
	font-weight: 700;
	border: 0 solid transparent;
    border-radius: .28571429rem;
}
.en_cours{
	color: white;
	border-color: #f5504a;
	background-color: #f5504a;
}
.resolue{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
.en_attente{
	color: white;
	border-color: #a5673f;
	background-color: #a5673f;
}
.prise_en_compte{
	color: white;
	border-color: #2185d0;
	background-color: #2185d0;
}
.titlehover:hover{
	opacity: 0.8;
}
.a_valider{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
</style>
