<template>
	<label class="vg-checkbox">
		<div class="checkbox-label">
      <slot>{{label}}</slot>
    </div>
		<input v-if="!isDisabled" type="checkbox" :value="inputValue" :checked="defaultChecked" @change="handleChangeCheckbox">
		<input v-else type="checkbox" :value="inputValue" :checked="defaultChecked" @change="handleChangeCheckbox" disabled>
		<span class="checkmark"></span>
	</label>
</template>
<script>

    export default {
        name: 'vg-checkbox',
		components: {

		},
        props: {
			inputValue: {
				type: Boolean,
				required: true
			},
			label: {
				type: String,
				default: null
			},
			defaultChecked: {
				type: Boolean,
				default: false
			},
			isDisabled: {
				type: Boolean,
				default: false
			}
        },
		watch:{

		},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			handleChangeCheckbox: function(e){
				if(e.target.checked) this.$emit("checked", {value: e.target.checked});
				else this.$emit("unchecked", {value: e.target.checked});
				if(e.target.checked) this.$emit("input", true);
				else this.$emit("input", false);
			}
		},
        computed: {

		}
    };

</script>
<style style="scss" scoped>
/* The container */
.vg-checkbox {
  /*display: block;*/
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  font-weight: normal !important;
}
.checkbox-label{
  display: flex;
  justify-content:flex-start;
  align-items:center;
  gap: 5px;
}

/* Hide the browser's default checkbox */
.vg-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /*top: 0;*/
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #cccccc;
}

/* On mouse-over, add a grey background color */
.vg-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.vg-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

.vg-checkbox input:disabled ~ .checkmark {
  background-color: #bdc3c7;
}
.vg-checkbox input:disabled ~ .checkmark {
  background-color: #bdc3c7;
  cursor: not-allowed;
  opacity:0.5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.vg-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.vg-checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {
            isChecked:true,
            defaultChecked:true
        }
      },
      template: `
        <div>
            <vg-checkbox
                :defaultChecked="defaultChecked"
                @checked="isChecked"
                @unchecked="isChecked">
            </vg-checkbox>
        </div>
      `
    })
    ```
</docs>
