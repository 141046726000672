<template>
    <vg-modal
        :title="$t('title')"
        @close="closeModal"
        @save="submitOperation"
        :isSaveDisabled="operation.length<2">
        <template #body>
            <vg-input :inputType="'textarea'" v-model="operation"></vg-input>
        </template>
    </vg-modal>

</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
export default {
    name:"vg-operation-retour-fait",
    components:{

        VgButton,
        VgInput
    },
    props:{

    },
    data:function() {
        return{
            operation:"",
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Retour fait",
            "textearea-operation": "Opération",
            "btn-enreg": "Enregistrer"
        },
        "en": {
            "title": "Feedback given",
            "textearea-operation": "Operation",
            "btn-enreg": "Save"
        },
        "th": {
            "title": "ให้ข้อเสนอแนะแล้ว",
            "textearea-operation": "ปฏิบัติการ",
            "btn-enreg": "บันทึก"
        }
    }
},
    methods:{
        submitOperation: function(){
            this.$emit('save', this.operation);
        },
        closeModal: function(){
            this.$emit('close');
        }
    }

}
</script>

<style lang="scss">
</style>
