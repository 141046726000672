<template>
    <div class="vg-bon-de-commande-historique-table">
        <tag-grid
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="historique"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
        />
    </div>
</template>
<script>
import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import BonDeCommandeHistoriqueFlagCellRender from "src/components/Vg/TagGrid/BonDeCommandeHistoriqueFlagCellRender.vue";
import BonDeCommandeHistoriqueEvenementCellRender from "src/components/Vg/TagGrid/BonDeCommandeHistoriqueEvenementCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bon-de-commande-historique-table',
    components:{
        TagGrid
    },
    mixins:[BonsDeCommandeMixins],
    props: {
        id:{
            type: String,
            default: "bon-de-commande-historique-tableau"
        },
        idBonDeCommande: {
            type: String | Number,
            required: true
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "overlay-loading-text": "Chargement de l'historique du bon de commande...",
    		"overlay-no-rows-text": "Aucun historique correspondant au filtrage",
            "header-date": "Date",
            "header-type": "Type évènement",
            "header-evenement": "Evènement",
            "header-commentaire": "Commentaire"
        },
        "en": {
            "overlay-loading-text": "Loading purchase order log...",
    		"overlay-no-rows-text": "No log matching filtering",
            "header-date": "Date",
            "header-type": "Type évènement",
            "header-evenement": "Evènement",
            "header-commentaire": "Commentaire"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: this.$t("header-date"),
                    width: 100,
                    field: 'dateCreation',
                    colId: 'dateCreation',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCreation}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-type"),
                    width: 100,
                    field: 'flag',
                    colId: 'flag',
                    cellRenderer: (params) => new BonDeCommandeHistoriqueFlagCellRender({propsData: {value: params.data}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-evenement"),
                    width: 150,
                    field: 'flag',
                    colId: 'flag',
                    cellRenderer: (params) => new BonDeCommandeHistoriqueEvenementCellRender({propsData: {value: params.data}}).$mount().$el,
                },
                {
                    headerName: this.$t("header-commentaire"),
                    width: 150,
                    field: 'validation.commentaire',
                    colId: 'validation.commentaire',
                    cellRenderer: (params)=> params.data.validation?params.data.validation.commentaire:""
                }
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        },
        bonDeCommande: {
            handler: function(value){
                //console.log("VG BC HISTORIQUE BC", value);
                this.fetch();
            },
            deep: true
        }
    },
    created:function(){
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            historique: 'BonsDeCommandeHistoriquesStore/getCollection',
            bonDeCommande: "BonsDeCommandeStore/getSelectedItem"
        })
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.BonsDeCommandeMixins_getHistorique(this.idBonDeCommande, this.metadatas).then((datas)=>{
                //console.log("GET BC HISTORIQUE", datas);
                this.showLoadingOverlay = false;
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-bon-de-commande-historique-table{
    height: 300px !important;
}
</style>
