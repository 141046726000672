<template>
    <div class="popup-equipement-marker">
        <vg-equipement-viewer :equipement="equipement"/>
        <div style="width:100%;border-bottom:1px solid lightgrey;"></div>
        <vg-lieu-path-viewer v-model="equipement.path"/>
        <div v-if="hasMaintenanceEncours" style="width:100%;border-bottom:1px solid lightgrey;"></div>
        <div v-if="hasMaintenanceEncours" class="maintenance-en-cours">
            <img src="static/assets/icone/maintenance-rouge.png"/> <span>{{$t("maintenances-en-cours")}}</span>
        </div>
        <div class="footer" v-if="equipement && equipement.id && !isCreationMode">
            <vg-button v-if="can('EQUIPEMENT.PAGE')"
                size="sm"
                type="success"
                @click="toZoomEquipement">
                {{$t("voir-equipement")}}
            </vg-button>
            <vg-button
                size="sm"
                type="danger"
                @click="toDemandeIntervention">
                {{$t("faire-une-di")}}
            </vg-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import RolesMixins from "src/mixins/RolesMixins.js";
export default Vue.extend({
    name:"popup-equipement-marker",
    components:{
        VgButton,
        VgInput,
        VgEquipementViewer,
        VgLieuPathViewer
    },
    mixins:[ RolesMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "voir-equipement": "Voir équipement",
            "faire-une-di": "Faire une demande d'intervention",
            "maintenances-en-cours": "Fiches curatives non résolues sur cet équipement"
        },
        "en": {
            "voir-equipement": "View equipment",
            "faire-une-di": "Make an intervention request",
            "maintenances-en-cours": "Unresolved curative sheets on this equipment"
        }
    }
},
	props: {
        equipement:{
            type: Object
        },
        geoJsonPoint: {
            type: Object
        },
        isCreationMode: {
            type: Boolean,
            default: false
        },
        /**
        * router
        * this.$router
        */
        router: {
            type: Object,
            default: null
        }
	},
    data: function(){
        return {
            showUpdateMarker: false,
            updatedEquipement: null
        };
    },
    created: function(){
        console.log("EQUIPEMENT", this.geoJsonPoint, this.equipement);
    },
    methods:{
        onShowUpdateMarker: function(){
            this.updatedEquipement = Object.assign({}, this.equipement);
            this.showUpdateMarker = true;
        },
        emitUpdateMarker: function(){
            this.$emit("update-marker", {equipement: this.equipement, geoJsonPoint: this.geoJsonPoint, updatedEquipement: this.updatedEquipement});
        },
        emitDeleteMarker: function(){
            this.$emit("delete-marker", {equipement: this.equipement, geoJsonPoint: this.geoJsonPoint});
        },
        toZoomEquipement: function(){
            this.router.push({name:'_equipement_id', params: {id: this.equipement.id}});
        },
        toDemandeIntervention: function(){
            this.router.push({name: "_demande_intervention", params: {code: this.equipement.qrCode, isScann: true}});
        },
    },
    computed: {
        hasMaintenanceEncours: function(){
            if(this.equipement.maintenances) return this.equipement.maintenances.findIndex((fm)=>fm.statut=="En_cours")!=-1;
            else return false;
        }
    }
});
</script>
<style lang="scss" scoped>
.popup-equipement-marker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    font-size: 12px !important;
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
}
.maintenance-en-cours{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    img{
        width: 15px;
        height: 15px;
    }
}
</style>
