
<template>
	<div :class="{disabledUi:isDisabledUi}" style="width: 400px;">
        <div style="display:flex;flex-direction:column;">
            <div v-for="(problem,i) in libellesProblem" :key="i" class="rowProblem">
                <div>{{problem.libelle}}</div>
                <div>
                    <vg-button
                        size="xs"
                        type="danger"
                        @click="handleDeleteLibelleProblem(problem.id)"
                        :disabled="isDisabledUi"
                    >
                    {{ $t("parametres.desktop.parametres-crud-libelle-suppression") }}
                    </vg-button>
                </div>
            </div>
        </div>

		<div  v-if="isSessionAdmin" >
			<vg-input :placeholder="$t('parametres.desktop.parametres-crud-libelle-input-ajout')" v-model="newlibelle" ></vg-input>
			<vg-button type="success" @click="handleSaveLibelle" v-show="newlibelle.length!=0" :disabled="isDisabledUi">{{$t('save')}}</vg-button>
		</div>
	</div>
</template>

<script>
    import VgInput from 'src/components/Vg/VgInput.vue';
    import VgButton from 'src/components/Vg/VgButton.vue';
    import LibelProblemMixins from 'src/mixins/LibelProblemMixins.js';
    import Metadatas from 'src/services/Metadatas.js';
    import { mapGetters } from 'vuex';
    export default {
        name: 'crud-libelle',
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "parametres": {
                "desktop": {
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-libelle": "Libellé",
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-actions": "Actions",
                    "parametres-crud-libelle-suppression": "Supprimer",
                    "parametres-crud-libelle-input-ajout": "Saisissez le libellé à ajouter"
                }
            }
        },
        "en": {
            "parametres": {
                "desktop": {
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-libelle": "Description",
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-actions": "Actions",
                    "parametres-crud-libelle-suppression": "Remove",
                    "parametres-crud-libelle-input-ajout": "Enter the description to add"
                }
            }
        },
        "th": {
            "parametres": {
                "desktop": {
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-libelle": "คำอธิบาย",
                    "personnalisation-di-configuration-problemes-tableau-problemes-column-header-actions": "การดำเนินการ",
                    "parametres-crud-libelle-suppression": "ลบออก",
                    "parametres-crud-libelle-input-ajout": "กรอกคำอธิบายเพื่อเพิ่ม"
                }
            }
        }
    }
} ,
        props:{
			isSessionAdmin: Boolean,
        },
        mixins:[LibelProblemMixins],
        components: {
            VgInput,
            VgButton
        },
        data: function() {
            return {
                isDisabledUi:false,
                newlibelle:""
            };
        },
        methods: {
			handleSaveLibelle: function(){
                this.isDisabledUi = true;
                let libelles = [
                    {
                        userId: this.$app.appID,
                        libelle: this.newlibelle
                    }
                ];
                this.LibelProblemMixins_DI_create(libelles).then(()=>{
                    this.newlibelle = "";
                    this.isDisabledUi = false;
                });

			},
            /**
             * @param {integer} libelleProblemId
             *
             */
            handleDeleteLibelleProblem:function(libelleProblemId){
                this.isDisabledUi = true;
                this.LibelProblemMixins_DI_delete(libelleProblemId).then(()=>{
                    this.isDisabledUi = false;
                });
            }
        },
        computed: {
            ...mapGetters({
                libellesProblem: 'LibellesProblemStore/getCollection'
            }),
        },
		created: function(){
            this.LibelProblemMixins_DI_get(new Metadatas());
		}
    };
</script>
 <style lang="scss">
 .rowProblem{
     display: flex;
     justify-content: space-between;
     border-bottom: solid 1px darkgrey;
     margin-bottom: 5px;
     align-items: center;
     padding: 3px;
 }
 .disabledUi{
    opacity:0.3;
 }

 </style>
