<template>
    <div :class="['vg-collapse', type]">
        <div class="collapse-header" >
            <div class="collapse-header-pre">
                <slot name="pretitle"></slot>
            </div>
            <div class="collapse-header-main">
                <slot name="title">
                    <div class="title">
                        <span @click="onCollapse">{{title}}</span>
                        <slot name="titlecomplement"></slot>
                    </div>
                </slot>
                <div class="collapseur" @click="onCollapse">
                    <i v-if="!isCollapse" class="fas fa-chevron-up"></i>
                    <i v-else class="fas fa-chevron-down"></i>
                </div>
            </div>
        </div>
        <div v-if="(!isCollapse && killContentOnCollapse) || !killContentOnCollapse" v-show="killContentOnCollapse || (!isCollapse && !killContentOnCollapse)" class="collapse-content">
            <slot name="content"><span class="default-message">{{defaultMessage}}</span></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "vg-collapse",
    props: {
        /**
        * collapse by default
        *
        */
        collapseByDefault: {
            type: Boolean,
            default: true
        },
        /**
        * section title
        *
        */
        title: {
            type: String,
            default: "default title"
        },
        defaultMessage: {
            type: String,
            default: "empty message"
        },
        /**
        * not in use
        */
        drawTitleLine: {
            type: Boolean,
            default: true
        },
        /**
        * type de collapse à utiliser valeurs : section
        */
        type: {
            type: String,
            default: ''
        },
        /**
        * use v-if or v-show on collapse content
        */
        killContentOnCollapse: {
            type: Boolean,
            default: true
        }
    },
    data:function() {
        return {
            isCollapse: this.collapseByDefault
        };
    },
    watch:{
        collapseByDefault:{
            handler: function(newValue){
                this.isCollapse = newValue;
            }
        }
    },
    computed: {},
    methods: {
        onCollapse: function(e){
            this.isCollapse = !this.isCollapse;
            this.$emit("collapse", this.isCollapse);
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.vg-collapse{
    /*padding: 10px;*/
}
.section{
    >.collapse-header{
        color: #35b1ea;
        font-size: 16px;
    }
}
.collapse-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .collapse-header-pre{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        *:last-child{
            margin-right: 10px;
        }
    }
}
.collapse-header-main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        >span{
            white-space: nowrap;
            margin-right: 10px;
        }
    }
    .collapseur{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
.collapse-content{
    margin: 10px 0px;
}
.default-message{
    font-size: 11px;
}
</style>
