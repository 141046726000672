<template>
	<div class="vg-dashboard-curatif-rapport"
		@dragover="dragOver"
		@dragstart="dragStart"
		@drop="onDrop">
		<div class="header">
			<span class="title">{{title}}</span>
			<div class="header-right" v-show="!hideHeaderComplement">
				<slot name="header-complement"></slot>
				<!-- PAGINATION -->
				<vg-pagination-limit-selector v-if="limit"
					class="pagination"
					:text="limitSelectorText"
					@input="onChangeLimit" />
				<!-- PERIOD -->
				<vg-date-picker-shortcuts v-if="!hideDatePickerShortcut" 
					:key="$vnode.key+'-date-picker-shortcuts'"
					:previousDatesPresets="!showFutur"
					:nextDatesPresets="showFutur"
					:defaultStartDate="defaultDateIntervalle.startDate"
					:defaultEndDate="defaultDateIntervalle.endDate"
					:appendToBody="!inline"
					:opens="inline ? 'right' : 'left'"
					@select="onChangeDateIntervalle"
					@finish-selection="onChangeDateIntervalle" />
			</div>
		</div>
		<div class="body">
			<!-- GRAPH -->
			<slot></slot>
		</div>
		<div v-if="limit" class="footer">
			<vg-button
				:type="'grey'"
				:size="'sm'"
				:disabled="pagination.offset==0"
				@click="onClickPrecedents">
				<i class="fas fa-chevron-left" style="margin-right:5px;"></i> {{$t("precedents", {limit: pagination.limit})}}
			</vg-button>
			<vg-button
				:type="'grey'"
				:size="'sm'"
				@click="onClickSuivants">
				{{$t("suivants", {limit: pagination.limit})}} <i class="fas fa-chevron-right" style="margin-left:5px;"></i>
			</vg-button>
		</div>
	</div>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';

	import VgButton from 'src/components/Vg/VgButton.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: "vg-dashboard-curatif-rapport",
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgButton
		},
    	props: {
			limit:{
				type: Number,
				default: null
			},
			title:{
				type: String,
				required: true
			},
			limitSelectorText: {
				type: String,
				default: null
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			},
			hideDatePickerShortcut: {
				type: Boolean,
				default: false
			},
			showFutur: {
				type: Boolean,
				default: false
			},
			hideHeaderComplement: {
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				}
            };
        },
		i18n:    { "locale":navigator.language,
		    "messages": {
		        "fr": {
		            "precedents": "Voir les {limit} précédents",
		            "suivants": "Voir les  {limit} suivants",
		            "date-intervalle-generale": "Définir période rapport"
		        },
		        "en": {
		            "precedents": "View previous {limit}",
		            "suivants": "View next {limit}",
		            "date-intervalle-generale": "Defined reporting period"
		        }
		    }
		},
		methods: {
			onDrop: function(ev){
				ev.preventDefault();
				this.$emit("drop", ev);
			},
			dragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			dragOver: function(ev){
				ev.preventDefault();
				ev.stopPropagation();
				return false;
			},
			onChangeLimit: function(limit){
				this.pagination.offset = 0;
				this.pagination.limit = limit;
				this.$emit("change", {pagination: this.pagination, dateIntervalle: this.dateIntervalle});
			},
			onChangeDateIntervalle: function(data){
				console.log("ON CHANGE DATE INTERVALLE", data);
				this.dateIntervalle = data;
				this.pagination.offset = 0;
				this.pagination.limit = this.limit;
				this.$emit("change", {pagination: this.pagination, dateIntervalle: this.dateIntervalle});
			},
			onClickPrecedents: function(){
				this.pagination.offset = this.pagination.offset - this.limit;
				this.pagination.limit = this.limit;
				this.$emit("change", {pagination: this.pagination, dateIntervalle: this.dateIntervalle});
			},
			onClickSuivants: function(){
				this.pagination.offset = this.pagination.offset + this.limit;
				this.pagination.limit = this.limit;
				this.$emit("change", {pagination: this.pagination, dateIntervalle: this.dateIntervalle});
			}
		},
		computed: {
			getShortcutsCollection: function(){
				if(this.showFutur) return this.DashboardMixins_datePickerFuturShortcutsCollection;
				else return this.DashboardMixins_datePickerShortcutsCollection;
			}
		}
    };

</script>
<style lang="scss" scoped>

$border-color: #d3d7dd;
$header-background-color: #f7f7f9;
$header-text-color: #505967;
$header-border-bottom-color: #e1e2e4;
$graph-bar-fill-color: #87b0dd;
$graph-text-color: #9496a1;

.vg-dashboard-curatif-rapport{
	border: 1px solid $border-color;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: inherit;
	.header{
		background-color: $header-background-color;
		border-bottom: 1px solid $header-border-bottom-color;
		color: $header-text-color;
		font-size: 12px;
		font-family: Helvetica Neue Light, Open Sans;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 5px 10px;
		min-height:40px;
		height: auto;
		.title{
			font-weight: bold;
		}
		.header-right{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.pagination{
				margin-right: 10px;
			}
			.shortcuts{
				min-width: 180px;
			}
		}
	}

	.body{
		padding: 15px;
		width: 100%;
		height: 100%;
		>div{
			width: 100%;
			height: 100%;
		}
	}

	.footer{
		padding: 0px 5px 10px 15px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media screen and (max-width: 991.98px) {
	.vg-dashboard-curatif-rapport{
		.header{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			min-height: inherit;
			.header-right{
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.pagination{
					margin-right: 10px;
				}
				.shortcuts{
					min-width: 180px;
				}
			}
		}
	}
}
</style>
