<template>
    <vg-duree-viewer v-if="duree" v-model="duree"/>
    <span v-else></span>
</template>

<script>
    import Vue from "vue";
    import VgDureeViewer from "src/components/Vg/VgDureeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "duree-cell-render",
        mixins:[LangMixins],
		props: {
            /** duree en minutes */
            duree: {
                type: Number | String
            }
		},
        components: {
            VgDureeViewer
        },
        i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    
                }
            }
        },
        methods: {
            
        }
    });
</script>

<style lang="scss" scoped>

</style>
