<template lang="html">
    <div class="vg-equipement-select" v-if="modalview">
        <div class="search">
            <vg-input v-model="searchQuery" :placeholder="$t('search')"></vg-input>
        </div>
        <div class="tableau">
            <tag-grid
                :idTableau="'vg-equipement-select'"
                v-model="gridOptions"
                :columnDefs="columnDefs"
                :rowData="equipements"
    			:showLoadingOverlay="showLoadingOverlay"
    			:overlayLoadingText="$t('overlay-loading-text')"
    			:overlayNoRowsText="$t('overlay-no-rows-text')"
                @ag-click="handleClickRow" />
            <vg-pagination v-if="equipements && counters"
                :totalItems="counters.filtered"
                :offset="offset"
                :limit="limit"
                @pagination-change="handlePaginationChange"/>
        </div>
    </div>
    <vg-select v-else
        v-model="value"
        :placeholder="$t('placeholder')"
        :options="equipements"
        :attributeValue="attributeValue"
        :attributeLabel="'libelEquipement'"
        :filterBy="filterBy"
        :clearable="false"
        @input="handleSelect"
        @close="onCloseEquipementsSelect">
        <template v-slot="{option}">
            <div style="display:flex;justify-content:flex-start;align-items:center;">
                <img v-if="option.icon" :src="option.icon" height="20" width="20" style="margin-right:10px;">
                <div style="display:flex;flex-direction:column;">
                    <span style="margin-right:10px;"><b>{{option.libelEquipement}}</b></span>
                    <small>{{option.qrCode}}</small>
                </div>
            </div>
            <!--div class="select-equipement-template">
                <div class="info">
                    <div class="icon">
                        <img  :src="option.icon" height="20">
                    </div>
                    <span class="name">{{option.libelEquipement}}</span><br>

                </div>
                <div class="info-supp">
                    <div class="path">
                        <vg-lieu-path-viewer v-model="option.path"></vg-lieu-path-viewer>
                    </div>
                    <div class="qr-code">
                        {{option.qrCode}}
                    </div>
                </div>
            </div-->
        </template>
        <template #list-footer>
            <!--div style="width:100%;display:flex;justify-content:space-between;gap:5px;align-items:center;">
                <vg-button type="info"
                    size="sm"
                    @click="previous">
                    {{ $t("previous") }}
                </vg-button>
                <vg-button type="info"
                    size="">
                    {{ $t("next") }}
                </vg-button>
            </div-->
            <vg-pagination v-if="equipements && counters"
                :totalItems="counters.filtered"
                :offset="offset"
                :limit="limit"
                @pagination-change="handlePaginationChange"/>
        </template>
    </vg-select>
</template>

<script>
import EquipementMobileCellRender from "src/components/Vg/TagGrid/EquipementMobileCellRender.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import Metadatas from "src/services/Metadatas.js";
import TagGridMixins from 'src/mixins/TagGridMixins.js';
export default {
    name: 'vg-equipement-selector',
    components: {
        EquipementMobileCellRender,
        TagGrid,
        VgInput,
        VgLieuPathViewer,
        VgSelect,
        VgPagination
    },
    props:{
        value:{
            type: Object,
            default:function(){
                return null;
            }
        },
        modalview:{
            type:Boolean,
            default:false
        },
        idLieu:{
            type:Number,
            default:null
        },
        /**
        * filtres externes appliqué sur le selector au fetch
        * agFilters: {
        *	path: { attr:"l.path",colId: "path", value: null, action:"contains" },
        * 	...
        * }
        */
        agFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        defaultOffset: {
            type: Number,
            default: 0
        },
        defaultLimit: {
            type: Number,
            default: 25
        },
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des équipements...",
		"overlay-no-rows-text": "Aucun équipement correspondant au filtrage",
        "search": "Recherche...",
        "placeholder": "Sélectionner un équipement"
    },
    "en": {
        "overlay-loading-text": "Loading equipments...",
		"overlay-no-rows-text": "No equipment matching filtering",
        "search": "Searching...",
        "placeholder": "Select an equipment"
        }
    }
},
    mixins: [ equipementsMixins,TagGridMixins],
    data: function(){
        return {
            showLoadingOverlay: false,
            gridOptions:{
                headerHeight: 0,
                rowHeight: 110
            },
            columnDefs:[
                {
                    headerName: "libelEquipement",
                    field: "libelEquipement",
                    width: 60,
                    cellClass: ["categoriecellrenderermobile"],
                    suppressSizeToFit: false,
                    ////headerComponent: headerRendererMobile,
                    cellRenderer: (params) => new EquipementMobileCellRender({propsData: {params: params}}).$mount().$el
                },
                {
                    headerName: "path",
                    field: "path",
                    width: 60,
                    cellClass: ["categoriecellrenderermobile"],
                    suppressSizeToFit: false,
                    hide:true
                },
                {
                    headerName: "dateRecensement",
                    field: "dateRecensement",
                    width: 60,
                    cellClass: ["categoriecellrenderermobile"],
                    suppressSizeToFit: false,
                    hide:true
                },
            ],
            metadatas:null,
            searchQuery:'',
            agfilters:{
                isGroupEqp: { attr:"e.isGroupEqp", colId: "isGroupEqp", value: 1, action:"not_equals" },
                libelEquipement: { attr:"e.libel_equipement", colId: "libelEquipement", value: null, action:"contains" },
                lieu: { attr:"l.id", colId: "idLieu", value: null, action:"equals" }
            },
            equipements:null,
            counters: null,
            offset: this.defaultOffset,
            limit: this.defaultLimit
        }
    },
    created:function(){
        this.agfilters.lieu.value = this.idLieu;
        this.metadatas = new Metadatas();
        this.metadatas.setFilters(Object.assign(this.agfilters, this.agFilters));
        this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    watch:{
        searchQuery: {
            handler: function(query){
                if (query != null && query.length) {
                    let searchQuery = query.split(" ");
                    this.agfilters.libelEquipement.value = searchQuery.join('","');
                }else {
                    this.agfilters.libelEquipement.value = null;
                }
            }
        },
        agfilters:{
            handler:function(nfilters){
                this.metadatas.setFilters(this.agfilters);

                this.fetch();
            },
            deep:true
        },
        agFilters: {
            handler: function(newFilters){
                this.agfilters = Object.assign({}, this.agfilters, newFilters);
                this.metadatas.setFilters(this.agfilters);
            },
            deep: true
        }
    },
    methods:{
        onCloseEquipementsSelect: function(){
            this.offset = this.defaultOffset;
            this.limit = this.defaultLimit;
            this.metadatas.setLimit(this.offset, this.limit);
            this.fetch();
        },
        handlePaginationChange: function(pagination){
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        },
        /**
         * fetch equipementsMixins_getEquipements : recuperation des données pour le tableau
         */
        fetch: function(){
            this.showLoadingOverlay = true;
            this.equipementsMixins_getEquipements(this.metadatas, {stored: false}).then((equipements)=>{
                this.equipements = equipements.datas;
                this.equipement = this.value;
                this.counters = equipements.metadatas.counters;
                this.showLoadingOverlay = false;
                this.$emit("fetch-success", {equipements: this.equipements});
            });
        },
        /**
         * action clique sur ligne du tableau
         * @params {Object} row
         * @emit {Object} row selectionner
         */
        handleClickRow: function(row){
            this.$emit("input",row.data);
        },
        /**
         * emit dédié uniquement pour le select
         * @params {Object} option
         * @emit {Object} option selectionner
         */
        handleSelect: function(option){
            this.$emit("input",option);

        },
        /**
         * filtre passé à VgSelect
         *
         * @param {Object} option Object correspondant à l'option
         * @param {String} label correspond à l'attributeLabel
         * @param {String} search chaine de caractère saisie par l'user dans l'input
         */
        filterBy: function(option, label, search){
            // filtre par libelEquipement, qrCode, path
            return option.libelEquipement.toLowerCase().indexOf(search.toLowerCase())!=-1
                || option.qrCode.toLowerCase().indexOf(search.toLowerCase())!=-1
                || option.path.toLowerCase().indexOf(search.toLowerCase())!=-1;
        },
    }
}
</script>

<style lang="scss" scoped>
.vg-equipement-select{
    height: 100%;

    .tableau{
        height: 90%;
    }
}
.select-equipement-template{
    display: flex;
    flex-direction: column;
    .info{
        display: flex;
        flex-direction: row;
        margin-bottom:10px;
        .name{
            width: 80%
        }
        .icon{
            width: 20%;
        }
    }
    .info-supp{
        flex-direction: row;
        display: none;
        .path{
            width: 50%;
        }
        .qr-code{
            width: 50%;
            text-align: right;
        }
    }

    &:hover{
        .info-supp{
            display: flex;
        }
    }
}
</style>
<docs>

    ### vg-composant-select


    ```js
          import VgEquipementSelect from "src/components/Vg/Selectors/VgEquipementSelect.vue";
    ```

    #### basic usage

    ```html static
    <vg-equipement-select  @select-row="selectEquipement"></vg-equipement-select>

    ```
    #### Emit : select-row

    Retourne la valeur selectionner, clique sur la row

    ### mixins

    equipementsMixins_getEquipements

</docs>
