<template>
    <vg-select
        v-model="selected"
        :options="tvas"
        :attributeValue="'id'"
        :placeholder="$t('placeholder')"
        :clearable="false"
        @input="handleInputChange">
        <template v-slot="{option}">
            <b>{{option.name}}</b> <small>{{option.value}}%</small>
        </template>
        <template v-slot:selected-option="{option}">
			{{option.value}}%
        </template>
    </vg-select>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import TvasMixins from "src/mixins/TvasMixins.js";
import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-tva-selector',
    mixins:[TvasMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: null
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner une tva"
        }
    }
},
    data: function() {
        return {
            selected: this.value
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selected = newValue;
                this.setDefaultTva();
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        fetch: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.filters);
            this.TvasMixins_getTvas(metadatas).then((tvas)=>{
                this.setDefaultTva();
            });
        },
        setDefaultTva: function(){
            //console.log("SET DEFAULT TVA", this.value, this.$storage.get("tva-default"));
            if(!this.value && this.$storage.get("tva-default")){
                this.selected = this.$storage.get("tva-default");
                this.handleInputChange(this.selected);
            }else if(this.value && !this.$storage.get("tva-default")){
                this.handleInputChange(this.value);
            }
        },
        /**
        * Emit input.
        * @event input
        * @event select
        * @param String tva_id
        */
        handleInputChange:function(tva_id){
            //console.log("INPUT CHANGE", tva_id);
            this.$storage.set("tva-default", tva_id);
            this.$emit("input", tva_id);
            this.$emit("select", this.tvas.find((t)=>t.id==tva_id));
        }
    },
    computed: {
        ...mapGetters({
            tvas: 'TvasStore/getCollection'
        })
    }
};

</script>
<style lang="scss" scoped>

</style>
