<template lang="html">
    <div :class="['equipement-mobile-cell-render',{'equipement-selected': isEquipementSelected}]"
        @click="emitSelectEquipement">
        <div class="checkbox-container" v-if="showCheckbox">
            <i v-if="isEquipementSelected" class="far fa-check-square fa-2x checkbox-checked"></i>
            <i v-else class="far fa-square fa-2x checkbox"></i>
        </div>
        <div :class="['main',{'width-100': !showCheckbox}]">
            <div class="title">
                <img v-if="params.data.icon" :src="params.data.icon" height="20" width="20"  onerror="this.style.visibility='hidden'">
                <img v-else-if="params.data.categorie_icon" :src="params.data.categorie_icon">
                <span style="font-size: 15px; font-weight: bold;"> {{params.data.libelEquipement || params.data.libel_equipement}} </span>
                <span v-if="params.data.isHorsService" style="margin-left:5px"> <vg-tag :label="$t('hors-service')" color="red"/>  </span>
            </div>
            <div class="content">
                <vg-lieu-path-viewer v-model="params.data.path"  showTooltip="false" />
            </div>
            <div class="footer">
                <div>
                    <span style="color:#c9c9c9"> {{$t("recencele")}} </span>
                    <vg-datetime-viewer v-model="params.data.dateRecensement"/>
                </div>
                <vg-button v-if="params.data.etiquetter != '1'"
                    type="info"
                    size="xs"
                    name="equipement-etiquetage"
                    @click="$emit('show-etiquetage',params.data.id)" >
                    {{$t('etiquetter')}}
                </vg-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import VgTag from "src/components/Vg/VgTag.vue";
export default Vue.extend({
    name: "equipement-mobile-cell-render",
    mixins:[LangMixins],
    components:{
        VgLieuPathViewer,
        VgDatetimeViewer,
        VgButton,
        VgTag
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "recencele": "Recensé le ",
            "dans": "dans",
            "etiquetter": "Coller étiquette"
        },
        "en": {
            "recencele": "Inventoried on the ",
            "dans": "In",
            "etiquetter": "Stick qrCode"
        }
    }
    },
    props: {
        params: {
            type: Object,
            required: true
        },
        showCheckbox:{
            type: Boolean,
            default: false
        },
        selectedEquipements:{
            type: Array,
            default: function(){
                return [];
            }
        }
    },
    datas:function(){
        return {
            isEquipementSelected: false
        } 
    },
    watch:{
        selectedEquipements:{
            handler: function(newSelectedEquipements){
                this.setIsEquipementSelected();
            },
            deep: true
        }
    },
    methods:{
        setIsEquipementSelected: function(){
            if(this.selectedEquipements.findIndex((eq)=>eq.id==this.params.data.id)==-1) this.isEquipementSelected = false;
            else this.isEquipementSelected = true;
        },
        emitSelectEquipement: function(){
            if(this.isEquipementSelected){ 
                this.isEquipementSelected = false;
                this.$emit("deselect-equipement", this.params.data);
            }else{ 
                this.isEquipementSelected = true;
                this.$emit("select-equipement", this.params.data);
            }
        }
    },
    created: function(){
        this.setIsEquipementSelected();
    }

})
</script>

<style lang="scss" scoped>
.equipement-mobile-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    margin: auto;
    .checkbox-container{
        display: block;
        margin: auto;
    }
    .checkbox-checked{
        color: #1E90FF;
    }
    .main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        width: calc( 100% - 30px );
        padding: 6px 0;
        .title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }
        .content{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .footer{
            width: -webkit-fill-available;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
    }
    .width-100{
        width: 100%;
    }
}
</style>
