<template lang="html">
	<div :class="['progress-container', {'overlay':showOverlay}]">
		<progress max="100" :value="ratio">
			<div class="progress-bar">
		        <span style="width: 80%;">{{$t("progress")}}: {{ratio}}%</span>
		    </div>
		</progress>
		<span>{{$t("save-inprogress")}}</span>
	</div>
</template>
<script>

    export default {
        name: 'vg-files-progress-upload-bar',
	    components: {

        },
		filters: {

		},
		mixins:[

        ],
        props: {
			transferredBytes: Number,
			totalBytes: Number,
			showOverlay: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {

			};
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "progress": "Progression",
            "save-inprogress": "Sauvegarde en cours..."
        },
        "en": {
            "progress": "Progress",
            "save-inprogress": "Saving in progress"
        },
        "th": {
            "save-inprogress": "กำลังบันทึก",
            "progress": "ความคืบหน้า"
        }
    }
},
		watch: {

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {

		},
		computed: {
			ratio: function(){
				return Number(this.transferredBytes)/Number(this.totalBytes)*100;
			}
		}
	};
</script>

<style lang="scss" scoped>
.progress-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	>span{
		color: white;
	}
	>progress{
		/*position: absolute;*/

	}
	>progress[value] {
		width: 250px;
		height: 15px;
		-webkit-appearance: none;
		appearance: none;
	}
	>progress[value]::-webkit-progress-bar {
		background-color: #eee;
		border-radius: 2px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
	}
	>progress[value]::-webkit-progress-value {
		background-color: #4fbeff;
	}
	>progress[value]::-webkit-progress-value::after {
		content: '';
		width: 6px;
		height: 6px;
		position: absolute;
		border-radius: 100%;
		right: 7px;
		top: 7px;
		background-color: white;
	}
}
.overlay {
	/* Height & width depends on how you want to reveal the overlay (see JS below) */
	height: 100%;
	width: 100%;
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	background-color: rgb(0,0,0); /* Black fallback color */
	background-color: rgba(0,0,0, 0.6); /* Black w/opacity */
	overflow-x: hidden; /* Disable horizontal scroll */
	transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

}


</style>
