<template>

    <vg-modal
        :title="showValidation?$t('title-validation'):$t('title')"
        :isSaveDisabled="!file"
        @close="$emit('close')"
        @save="onSaveSortie">

        <template #body>
            <div class="body-header">
                <vg-equipement-viewer :equipement="equipement" />
            </div>
            <form v-if="!showValidation"
                ref="sortie-form"
                onSubmit="return false;" >
                <vg-input :title="$t('form-status')"
                    :isRequiredValue="true">
                    <vg-select v-model="sortie.type"
                        :options="getTypes"
                        :clearable="false" />
                </vg-input>
                <vg-input :title="$t('form-commentaire')"
                    v-model="sortie.commentaire"
                    :inputType="'textarea'" />
                <vg-input :title="$t('form-document')"
                    isRequiredValue>
                    <vg-files-uploader
                        :tagsList="VgFilesMixins.tags"
                        :isUploadOnFileChange="false"
                        @file-change="handleFileChange" />
                </vg-input>

                <input v-show="false" type="submit" ref="sortie-submit" />
            </form>
            <div v-else class="body-content-validation">
                <vg-lieu-path-viewer v-model="equipement.path">
                </vg-lieu-path-viewer>
                <br>
                <i class="fas fa-arrow-down"></i>
                <br>
                <div class="sortie-equipement-text">
                    <span>{{$t("sortie-equipement")}}</span>
                    <b>{{sortie.type}}</b>
                </div>
            </div>
            <vg-files-progress-upload-bar v-if="VgFilesMixins.uploadState"
    			:transferredBytes="VgFilesMixins.uploadState.bytesTransferred"
    			:totalBytes="VgFilesMixins.uploadState.totalBytes">
            </vg-files-progress-upload-bar>
        </template>
    </vg-modal>
</template>

<script>

import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFilesProgressUploadBar from "src/components/Vg/Files/VgFilesProgressUploadBar.vue";

export default {
    name:'vg-equipement-sortie-form',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Sortir l'équipement",
            "title-validation": "Confirmation sortie définitive",
            "form-status": "Statut",
            "form-commentaire": "Commentaire",
            "form-document": "Document",
            "mise-au-rebut": "Mise au rebut",
            "transfert": "Transfert",
            "non-trouve": "Non trouvé",
            "vendu": "Vendu",
            "vol": "Vol",
            "perte": "Perte",
            "sortie-equipement": "Sortie équipement"
        },
        "en": {
            "title": "Remove equipment",
            "title-validation": "Confirm removal",
            "form-status": "Status",
            "form-commentaire": "Commentary",
            "form-document": "Document",
            "mise-au-rebut": "Disposal",
            "transfert": "Transfer",
            "non-trouve": "Not found",
            "vendu": "Sold",
            "vol": "Theft",
            "perte": "Loss",
            "sortie-equipement": "Remove equipment"
        }
    }
},
    props:{
        equipement:{
            type: Object,
            required: true
        }
    },
    mixins:[VgFilesMixins, equipementsMixins],
    components:{

        VgInput,
        VgSelect,
        VgButton,
        VgEquipementViewer,
        VgFilesUploader,
        VgLieuPathViewer,
        VgFilesProgressUploadBar
    },
    data:function(){
        return{
            showValidation: false,
            sortie: {
                type: this.$t("mise-au-rebut"),
                dateSortie: moment().format("YYYY-MM-DD HH:mm:ss"),
                commentaire: "",
                equipement_id: this.equipement.id,
                userId: this.$app.appID,
                user_id: this.$app.idUser
            },
            file: null,
            types: [
                this.$t("mise-au-rebut"),
                this.$t("transfert"),
                this.$t("non-trouve"),
                this.$t("vendu"),
                this.$t("vol"),
                this.$t("perte")
            ]
        }
    },
    created:function(){
        this.VgFilesMixins_init(null, "sortieequipement");
    },
    methods:{
        sortirEquipement: function(){
            this.equipementsMixins_sortirEquipement(this.sortie, (reponse)=>{
                // //console.log("SUCCESS SORTIR EQ", reponse, this.file);
                // @TODO impacter le store au lieu de le faire dans showEquipement
                this.VgFilesMixins_setUid(reponse.seUid);
                this.VgFilesMixins_setType("sortieequipement");
                this.VgFilesMixins_uploadFile(this.file.file, this.file.idTag).then((document)=>{
                    this.VgFilesMixins_setUid(this.equipement.uid);
                    this.VgFilesMixins_setType("equipement");
                    this.$emit("save", reponse);
                });
            });
        },
        onSaveSortie:function(){
            if(!this.showValidation){
                this.$refs["sortie-submit"].click();
                this.isDisabledUi = true;
                let form = this.$refs['sortie-form'];
                if(form.checkValidity() && this.sortie.type && this.file){
                    this.showValidation = true;
                }else{
                    this.isDisabledUi = false;
                }
                return true;
            }else{
                this.sortirEquipement();
            }
        },
        handleFileChange: function(data){
            this.file = data;
        }
    },
    computed:{
        getTypes: function(){
            return this.types.sort();
        }
    }
}
</script>

<style lang="scss" scoped>
.body-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.body-content-validation{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sortie-equipement-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #a782b2;
    }
}
</style>
