<template>
    <span>
        {{$t(value.flag)}}
    </span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"bon-de-commande-historique-flag-cell-render",
        mixins:[LangMixins],
		props: {
            value: {
                type: Object
            }
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "bc-canceled": "Commande annulée",
            "bc-create": "Création",
            "bc-demande-validation": "Demande validation",
            "bc-validation-accepted": "Validation acceptée",
            "bc-validation-rejected": "Validation rejetée",
            "bc-validation-complete": "Validation complète",
            "bc-rejected": "Refusé",
            "bc-sended": "Commande envoyée",
            "bc-skip-sending": "Commande non envoyée",
            "bc-delivered": "Livraison complète",
            "bc-partially-delivered": "Livraison partielle"
        }
    }
},
        data: function(){
            return {

            };
        },
        components: {

        }
    });
</script>

<style lang="scss" scoped>

</style>
