<template>
	<div class="vg-consommable-mouvements-graphs">
		<div class="charts-container">
			<vg-line-chart v-if="evolutionQuantiteConsommee" 
				style="flex-grow: 1;"
				:chart-id="'consommable-repartition-quantite-consommee'"
				:height="'200px'"
				:datas="getEvolutionQuantiteConsommeeDatas"
				:horizontalaxis="xAxisQuantiteConsommee"
				:options="getOptionsLines('graph-title-evolution-consommee', 'quantite-consommee')"
				:showlegend="false"
				@selected="onSelectedBar"/>
			<vg-bar-chart v-if="evolutionQuantiteStock" 
				style="flex-grow: 1;"
				:chart-id="'consommable-repartition-quantite-stock'"
				:height="'200px'"
				:datas="getEvolutionQuantiteStockDatas"
				:horizontalaxis="xAxisQuantiteStock"
				:options="getOptionsLines('graph-title-evolution-stock', 'quantite-en-stock')"
				:showlegend="false"
				@selected="onSelectedBar"/>
		</div>
		<div class="charts-container">
			<vg-pie-chart v-if="repartitionQuantitesSites" 
				:chart-id="'consommable-repartition-quantites-sites'"
				:height="'200px'"
				:datas="getRepartitionQuantitesSitesDatas"
				:title="$t('graph-title-sites')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedPie"/>
			<vg-pie-chart v-if="repartitionQuantitesServices"
				:chart-id="'consommable-repartition-quantites-services'"
				:height="'200px'"
				:datas="getRepartitionQuantitesServicesDatas"
				:title="$t('graph-title-services')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedPoint"/>
			<vg-pie-chart v-if="repartitionQuantitesDemandeurs" 
				:chart-id="'consommable-repartition-quantites-par-demandeurs'"
				:height="'200px'"
				:datas="getRepartitionQuantitesDemandeursDatas"
				:title="$t('graph-title-demandeurs')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedBar"/>
		</div>
	</div>
</template>
<script>
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";

	import VgLineChart from 'src/views/Statistiques/components/VgLineChart.vue';
	import VgScatterChart from 'src/views/Statistiques/components/VgScatterChart.vue';
	import VgPieChart from 'src/views/Statistiques/components/VgPieChart.vue';
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-consommable-mouvements-graphs',
		components: {
			VgLineChart,
			VgScatterChart,
			VgPieChart,
			VgBarChart
		},
		mixins: [ ConsommablesMixins ],
        props: {
            dateIntervalle:{
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				showLoadingOverlay: false,
				metadatas: new Metadatas(),
				xAxisQuantiteStock: [],
				xAxisQuantiteConsommee: [],
				xAxisQuantitesParSites: [],
				xAxisQuantitesParServices: [],
				xAxisQuantitesParDemandeurs: [],
				optionsLines: {
					legend: {
						display: false,
						position: "bottom"
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 8,
								precision: 0
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks:{
								mirror: true,
								fontSize: 10,
								callback: (label, index, labels)=>{
									let dateMonth = moment(label+"-01");
									return this.$t("month-"+dateMonth.format("MM"))+" "+dateMonth.format("YY");
								}
							}
						}]
					},
					animation: {
						easing: "linear"
					}
				},
				pieOptions:{
					tooltips: {
						enabled: true,
						callbacks: {
							label: ((tooltipItems, data) => {
								let site = data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index];
								let value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
								if(this.consommable.unite) return site+": "+value+" "+this.consommable.unite;
								else return site+": "+value+" "+this.$t("default-unite");
							})
						}
					}
				}
            };
        },
	    i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"graph-title-evolution-stock": "Evolution mensuelle quantité en stock en fin de mois ({unit})",
					"graph-title-evolution-consommee": "Evolution mensuelle quantités sorties ({unit})",
					"set-stock": "Stock",
					"set-consommee": "Quantité sortie",
					"graph-title-sites": "Répartition des quantités consommées par site",
					"graph-title-services": "Répartition des quantités consommées par service",
					"graph-title-demandeurs": "Répartition des quantités consommées par demandeur",
					"aucun-site-associe": "Aucun site associé",
					"month-01": "Janv",
					"month-02": "Fév",
					"month-03": "Mars",
					"month-04": "Avril",
					"month-05": "Mai",
					"month-06": "Juin",
					"month-07": "Juillet",
					"month-08": "Août",
					"month-09": "Sept",
					"month-10": "Oct",
					"month-11": "Nov",
					"month-12": "Déc",
					"default-unite": "u.",
					"quantite-en-stock": "Quantité en stock à la fin du mois",
					"quantite-consommee": "Quantité sortie"
				},
				"en": {
					"graph-title-evolution-stock": "Monthly evolution of stock quantity at the end of the month ({unit})",
					"graph-title-evolution-consommee": "Monthly evolution of consumed quantities ({unit})",
					"set-stock": "Stock",
					"set-consommee": "Consumed quantity",
					"graph-title-sites": "Consumption distribution by site",
					"graph-title-services": "Consumption distribution by service",
					"graph-title-demandeurs": "Consumption distribution by applicant",
					"aucun-site-associe": "No associated site",
					"month-01": "Jan",
					"month-02": "Feb",
					"month-03": "Mar",
					"month-04": "Apr",
					"month-05": "May",
					"month-06": "Jun",
					"month-07": "Jul",
					"month-08": "Aug",
					"month-09": "Sep",
					"month-10": "Oct",
					"month-11": "Nov",
					"month-12": "Dec",
					"default-unite": "EA",
					"quantite-en-stock": "Stock quantity at the end of the month",
					"quantite-consommee": "Consumed quantity"
				}
			}
		},
		watch: {
			filters: {
				handler: function(newfilters){
					this.fetch();
				},
				deep: true
			},
			dateIntervalle: {
				handler: function(newDateIntervalle){
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){
			
		},
		mounted: function(){
			this.fetch();
		},
		methods: {
			getOptionsLines: function(titleRef, tooltipRef){
				return Object.assign({}, this.optionsLines, {
					title: {
						display: true,
						position: "bottom",
						text: this.$t(titleRef, {unit: this.consommable.unite?this.consommable.unite:this.$t("default-unite")})
					},
					tooltips: {
						enabled: true,
						callbacks: {
							label: ((tooltipItems, data) => {
								let dateMonth = moment(tooltipItems.xLabel+"-01");
								if(this.consommable.unite) return this.$t("month-"+dateMonth.format("MM"))+" "+dateMonth.format("YY")+": "+tooltipItems.value+" "+this.consommable.unite;
								else return this.$t("month-"+dateMonth.format("MM"))+" "+dateMonth.format("YY")+": "+tooltipItems.value+" "+this.$t("default-unite");
							}),
							title: ((tooltipItems)=>{
								return this.$t(tooltipRef);
							})
						}
					}
				});

			},
			fetch: function(){
				this.showLoadingOverlay = true;
				let filters = Object.assign({}, {}, this.filters);
				if(this.consommable) filters.consommable_id = {attr: "cm.consommable_id", value: this.consommable.id, action: "equals"};
				if(this.dateIntervalle) filters.dateMouvement = {attr: "cm.dateMouvement", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				this.metadatas.setFilters(filters);
				this.ConsommablesMixins_getRepartitionQuantites(this.metadatas).then((datas)=>{
					this.showLoadingOverlay = false;
				});
			},
		},
		computed: {
			...mapGetters({
				mouvements: 'ConsommableMouvementsStore/getCollection',
				evolutionQuantiteStock: 'ConsommableMouvementsStore/getEvolutionQuantiteStock',
				evolutionQuantiteConsommee: 'ConsommableMouvementsStore/getEvolutionQuantiteConsommee',
				repartitionQuantitesSites: 'ConsommableMouvementsStore/getRepartitionQuantitesSites',
				repartitionQuantitesServices: 'ConsommableMouvementsStore/getRepartitionQuantitesServices',
				repartitionQuantitesDemandeurs: 'ConsommableMouvementsStore/getRepartitionQuantitesDemandeurs',
				consommable: "ConsommablesStore/getSelectedItem"
            }),
			colors: function(){
				return [
					'#4A90E2', // Bleu clair
					'#50E3C2', // Vert
					'#F5A623', // Jaune
					'#D0021B', // Rouge
					'#9013FE', // Violet
					'#BD10E0', // Rose
					'#F8E71C', // Orange
					'#417505'  // Bleu foncé
				];
			},
			getEvolutionQuantiteStockDatas: function(){
				let datasets = {
					stock: {
						label: this.$t("set-stock"),
						value: "stock",
						backgroundColor: "rgba(74, 144, 226)",
						pointBackgroundColor: "#4A90E2",
						borderColor: "#4A90E2",
						borderWidth: 1,
						data: [],
						stack: "stock",
						hoverBorderColor: "#80b6de",
						hoverBorderWidth: 1,
						filters: []
					}
				};
				let startMonth = moment(this.dateIntervalle.startDate).format("YYYY-MM");
				let endMonth = moment(this.dateIntervalle.endDate).format("YYYY-MM");
				let generatedMonths = [startMonth];
				let generatedMonth = startMonth;
				while(generatedMonth<endMonth){
					generatedMonth = moment(generatedMonth).add(1, "month").format("YYYY-MM");
					generatedMonths.push(generatedMonth);
				}
				let previousValue = 0;
				generatedMonths.forEach((month, index)=>{
					let monthStock = this.evolutionQuantiteStock.find((item)=>item.month==month);
					if(monthStock){ 
						datasets.stock.data.push(monthStock.quantite);
						previousValue = monthStock;
					}else{
						datasets.stock.data.push(previousValue);
						previousValue = 0;
					}
				});
				this.xAxisQuantiteStock = generatedMonths;
				return Object.values(datasets);
			},
			getEvolutionQuantiteConsommeeDatas: function(){
				let datasets = {
					consommee: {
						label: this.$t("set-consommee"),
						value: "consommee",
						backgroundColor: "rgba(255, 255, 255, 0)",
						pointBackgroundColor: "#D0021B",
						borderColor: "#D0021B",
						borderWidth: 1,
						data: [],
						stack: "consommee",
						hoverBorderColor: "#80b6de",
						hoverBorderWidth: 1,
						filters: [],
						cubicInterpolationMode: 'default',
						tension: 0
					}
				};
				let startMonth = moment(this.dateIntervalle.startDate).format("YYYY-MM");
				let endMonth = moment(this.dateIntervalle.endDate).format("YYYY-MM");
				let generatedMonths = [startMonth];
				let generatedMonth = startMonth;
				while(generatedMonth<endMonth){
					generatedMonth = moment(generatedMonth).add(1, "month").format("YYYY-MM");
					generatedMonths.push(generatedMonth);
				}
				generatedMonths.forEach((month, index)=>{
					let monthStock = this.evolutionQuantiteConsommee.find((item)=>item.month==month);
					datasets.consommee.data.push(monthStock?monthStock.quantite:0);
				});
				this.xAxisQuantiteConsommee = generatedMonths;
				return Object.values(datasets);
			},
			getRepartitionQuantitesSitesDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: this.colors,
					hoverOffset: 4
				};
				this.repartitionQuantitesSites.forEach((repartition, index)=>{
					dataset.label.push(repartition.site?repartition.site:this.$t("aucun-site-associe"));
					dataset.data.push(repartition.quantiteConsommee);
				});
				return [dataset];
			},
			getRepartitionQuantitesServicesDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: this.colors,
					hoverOffset: 4
				};
				this.repartitionQuantitesServices.forEach((repartition, index)=>{
					dataset.label.push(repartition.service?repartition.service:this.$t("aucun-site-associe"));
					dataset.data.push(repartition.quantiteConsommee);
				});
				return [dataset];
			},
			getRepartitionQuantitesDemandeursDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: this.colors,
					hoverOffset: 4
				};
				this.repartitionQuantitesDemandeurs.forEach((repartition, index)=>{
					dataset.label.push(repartition.demandeur?repartition.demandeur:this.$t("aucun-site-associe"));
					dataset.data.push(repartition.quantiteConsommee);
				});
				return [dataset];
			}
		}
    };

</script>
<style lang="scss" scoped>
.vg-consommable-mouvements-graphs{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 40px;
	.charts-container{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		gap: 20px;
	}
}
</style>
