<template>
	<span v-if="intervention.status=='intervention-prevue' && !isDatePrevueDepassee" class="prevue"
		:title="$t('tooltip-date-prevue')">
		{{$t("intervention-prevue")}}
	</span>
	<span v-else-if="intervention.status=='intervention-prevue' && isDatePrevueDepassee" class="prevue retard"
		:title="$t('tooltip-date-prevue-depassee')">
		{{$t("intervention-prevue")}}
	</span>
	<span v-else-if="intervention.status=='intervention-realisee'" class="realisee"
		:title="$t('tooltip-date-realisee')">
		{{$t("intervention-realisee")}}
	</span>
	<span v-else-if="intervention.status=='intervention-annulee'" class="annulee"
		:title="$t('tooltip-date-annulee')">
		{{$t("intervention-annulee")}}
	</span>
	<span v-else class="prevue">
		default statut
	</span>
</template>
<script>
    export default {
        name: 'vg-intervention-statut-viewer',
    	props: {
            intervention: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "intervention-prevue": "Prévue",
            "intervention-realisee": "Réalisée",
            "intervention-annulee": "Annulée",
            "tooltip-date-prevue-depassee": "Intervention en retard",
            "tooltip-date-prevue": "Intervention prévue prochainement",
            "tooltip-date-realisee": "Intervention ok",
            "tooltip-date-annulee": "Intervention annulée"
        },
        "en": {
            "intervention-prevue": "Expected",
            "intervention-realisee": "Done",
            "intervention-annulee": "Canceled",
            "tooltip-date-prevue-depassee": "Late",
            "tooltip-date-prevue": "Planned intervention soon",
            "tooltip-date-realisee": "Done",
            "tooltip-date-annulee": "Canceled"
        },
        "th": {
            "intervention-prevue": "คาดการณ์",
            "intervention-realisee": "ทำแล้ว",
            "intervention-annulee": "ถูกยกเลิก",
            "tooltip-date-prevue-depassee": "ล่าช้า",
            "tooltip-date-prevue": "การแทรกแซงที่วางแผนเร็ว ๆ นี้",
            "tooltip-date-realisee": "ทำแล้ว",
            "tooltip-date-annulee": "ถูกยกเลิก"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			isDatePrevueDepassee: function(){
                let today = moment().format("YYYY-MM-DD HH:mm");
             	return this.intervention.status=="intervention-prevue" && today >= this.intervention.datePrevisionnelleFin;
            }
		}
    };

</script>
<style lang="scss" scoped>
span{
	/*margin-left: 5px;*/
	vertical-align: middle;
	font-size: 11px;
	display: inline-block;
	line-height: 1;
	padding: .5833em .833em;
	font-weight: 700;
	border: 0 solid transparent;
    border-radius: .28571429rem;
}
.prevue{
	color: white;
	border-color: #2185d0;
	background-color: #2185d0;
}
.retard{
	border-color: red;
	background-color: red;
}
.realisee{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
.annulee{
	color: white;
	border-color: #a5673f;
	background-color: #a5673f;
}
</style>
