<template lang="html" src="./GenereQrCode.html">

</template>

<script>



export default {
    components:{},
    data:function(){
        return {
          nbQrCode:0,
          displayTabs:false,
          rowData:[{
            num:"0",
            type:"Equipement",
            qrCode:"vlge",
          }],
          header: {
  					title: "Generateur QR Code",
  					buttonname: "",
  					isimg: true,
  					img: '/static/assets/icone/categorie-equipement-gris.png',
  					imgvisible: false,
  					buttonvisible: false,
                      noBorder:true,
                      buttonclass: 'newBtnCategorie'
  				},

        }
    },
    methods: {
      /**
    	 * affiche le tableau de previsualisation des QR Codes
    	**/
      previsuQrCode: function(){
        this.displayTabs=false;
        this.qrCodeCreator();

      },
      /**
    	 * recupere le html du tableau par l'id et genere le xls
    	**/
      exportToExcel: function(){
        var htmltable= document.getElementById('qrCodeGeneratorBERD');
        var html = htmltable.outerHTML;
        // window.open();
        var uri = 'data:text/xls;charset=utf-8,' + html;

       var downloadLink = document.createElement("a");
       downloadLink.href = uri;
       downloadLink.download = "equipementQrCode.xls";

       document.body.appendChild(downloadLink);
       downloadLink.click();
       document.body.removeChild(downloadLink);
      },
      /**
    	 * fonction qui genere le QrCode
    	 *
    	 * @return {Array} mise a jour rowData
    	**/
      qrCodeCreator: function(){
        var allData = [];
        var position = 0;
        var timeplus = 0;
        for (var pos = 1; pos <= this.nbQrCode; pos++) {
          // remet position a 0 pour gerer les VLGE trop long
          //
          var indexBoucle ;
          if (position >= 99) {
            position = 0;
            indexBoucle= position;
            timeplus++;

          }

          if (position <= 9) {
            indexBoucle = "0" + position;
          }
          else{
            indexBoucle= position;
          }

          position++;

          // formation de l'object
          var timestamp = new Date().getTime() + timeplus;
          var oneQrCode = new Object();
          oneQrCode.num = pos;
          oneQrCode.type = "Equipement";
          oneQrCode.qrCode = "VLGE" + indexBoucle + timestamp;
          // regroupement des object
          allData.push(oneQrCode);
        }
        // mise a jour rowData finale
        // //console.log(allData);
        this.rowData = allData;
        this.displayTabs=true;
      },
    },
    created: function() {

    }
}
</script>

<style lang="scss" src="./GenereQrCode.scss">
</style>
