<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:limit="limit"
		:limitSelectorText="$t('limit-selector-text')"
		:defaultDateIntervalle="defaultDateIntervalle"
		key="dashboard-curatif-rapport-repartition-equipements-couts"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<template #header-complement>
			<vg-select v-model="typeCouts"
				class="type-couts"
				:options="typeCoutsOptions"
				:attributeValue="'value'"
				:attributeLabel="'label'"
				:clearable="false"
				@input="onChangeTypeCouts"/>
		</template>
		<vg-bar-chart v-if="repartitionEquipementsCouts && !isLoading"
			class="chart-bar"
			:chart-id="'dashboard-curatif-repartition-equipements-couts'"
			:height="'100%'"
			:datas="getDatas"
			:horizontalaxis="xAxis"
			:options="optionsBar"
			:showlegend="false"
			@selected="onSelectedBar"/>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgSelect from 'src/components/Vg/VgSelect.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-repartition-equipements-couts',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport,
			VgButton,
			VgSelect
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},
				xAxis: [],
				optionsBar: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 8,
								precision: 0
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								mirror: true,
								fontSize: 10,
								callback: (label, index, labels)=>{
									if(labels.length>1){
										let labelSplitted = label.split(" ");
										let result = [];
										let encoreDeLaPlace = null;
										let graphWidth = document.getElementById("dashboard-curatif-repartition-equipements-couts").width;
										let barWidth = graphWidth/labels.length;
										let pxParCaractere = 4; // 4 px réservé par caractère
										let generalMaxCaractere = labels.length>=7?15:20;
										let nombreMaxCaractereInline = barWidth/pxParCaractere<=generalMaxCaractere?barWidth/pxParCaractere:generalMaxCaractere;
										labelSplitted.forEach((item, i) => {
											if(item.length>=nombreMaxCaractereInline){	// si mot trop long
												if(encoreDeLaPlace) result.push(encoreDeLaPlace);
												result.push(item);
												encoreDeLaPlace = null;
											}else{ // sinon mot plus court
												if(encoreDeLaPlace){
													if(encoreDeLaPlace.length+1+item.length<=nombreMaxCaractereInline){
														encoreDeLaPlace += " "+item;
													}else{
														result.push(encoreDeLaPlace);
														encoreDeLaPlace = item;
													}
												}else{
													encoreDeLaPlace = item;
												}
											}
										});
										if(encoreDeLaPlace) result.push(encoreDeLaPlace);
										return result;
									}else return label;
								}
							}
						}]
					},
					animation: {
						easing: "linear",
						onProgress: (e)=>this.drawCustomBarElements(e),
						onComplete: (e)=>this.drawCustomBarElements(e)
					}
				},
				typeCoutsOptions: [
					{label: this.$t("type-couts-externes"), value: "externe"},
					{label: this.$t("type-couts-internes"), value: "interne"},
					{label: this.$t("type-couts-consommables"), value: "consommable"},
				],
				isLoading: false,
				typeCouts: "externe" // externe interne consommable
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "REPARTITION des coûts fiches curatives par équipements",
            "bar-hover-label": "Nombre de fiches curatives ouvertes",
            "bar-hover-label-couts-externe": "dépenses externes en {devise} ",
            "bar-hover-label-couts-interne": "dépenses internes en {devise} ",
            "bar-hover-label-couts-consommable": "dépenses consommables en {devise} ",
            "limit-selector-text": "Equipements affichés",
            "precedents": "Voir les {limit} précédents",
            "suivants": "Voir les {limit} suivants",
            "type-couts-externes": "Top coûts externes",
            "type-couts-internes": "Top coûts internes",
            "type-couts-consommables": "Top coûts consommables",
            "loading": "Chargement des données..."
        },
        "en": {
            "title": "Curative sheets costs per equipments",
            "bar-hover-label": "Number of opened curative sheets",
            "bar-hover-label-couts-externe": "external expenses in {devise} ",
            "bar-hover-label-couts-interne": "internal expenses in {devise} ",
            "bar-hover-label-couts-consommable": "consumable expenses in {devise} ",
            "limit-selector-text": "Equipments displayed",
            "precedents": "View previous {limit}",
            "suivants": "View next {limit}",
            "type-couts-externes": "Top external costs",
            "type-couts-internes": "Top internal costs",
            "type-couts-consommables": "Top consumable costs",
            "loading": "Loading datas..."
        }
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			onChangeTypeCouts: function(type){
				this.isLoading = true;
				this.fetch();
			},
			onSelectedBar: function(bar){
				let barSelected = this.repartitionEquipementsCouts[bar.index];
				let filters = [
					{attr: "fm.materiel_id", value: barSelected.id, action: "equals"}
				];
				this.$emit("selected", {bar: barSelected, filters: filters, dateIntervalle: this.dateIntervalle});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				if(this.dateIntervalle){
					filters.dateOuvertureSAV = {attr: "fm.dateOuvertureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				}
				this.metadatas.setFilters(filters);
				if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getCuratifRepartitionEquipementsCouts(this.metadatas, this.typeCouts).then((datas)=>{
					this.isLoading = false;
				});
			},
			drawCustomBarElements: function(e){

			}
		},
        computed: {
			...mapGetters({
	            repartitionEquipementsCouts: 'DashboardStore/getCuratifRepartitionEquipementsCouts'
	        }),
			getDatas: function(){
				let barCoutsExterne = {
					label: this.$t("bar-hover-label-couts-externe", {devise: this.$app.devise}),
					value: "equipements-couts-externe",
					backgroundColor: this.typeCouts=="externe"?"#87b0dd":"#fff",
					borderColor: "#87b0dd",
					borderWidth: 1,
					data: [],
					stack: "equipements-couts-externe",
					hoverBackgroundColor: this.typeCouts=="externe"?"#87b0dd":"#fff",
					hoverBorderColor: "#80b6de",
					hoverBorderWidth: 1,
					filters: []
				};
				let barCoutsInterne = {
					label: this.$t("bar-hover-label-couts-interne", {devise: this.$app.devise}),
					value: "equipements-couts-interne",
					backgroundColor: this.typeCouts=="interne"?"#87b0dd":"#fff",
					borderColor: "#87b0dd",
					borderWidth: 1,
					data: [],
					stack: "equipements-couts-interne",
					hoverBackgroundColor: this.typeCouts=="interne"?"#87b0dd":"#fff",
					hoverBorderColor: "#80b6de",
					hoverBorderWidth: 1,
					filters: []
				};
				let barCoutsConsommable = {
					label: this.$t("bar-hover-label-couts-consommable", {devise: this.$app.devise}),
					value: "equipements-couts-consommable",
					backgroundColor: this.typeCouts=="consommable"?"#87b0dd":"#fff",
					borderColor: "#87b0dd",
					borderWidth: 1,
					data: [],
					stack: "equipements-couts-consommable",
					hoverBackgroundColor: this.typeCouts=="consommable"?"#87b0dd":"#fff",
					hoverBorderColor: "#80b6de",
					hoverBorderWidth: 1,
					filters: []
				};
				let datasExterne = [];
				let datasInterne = [];
				let datasConsommable = [];
				let xAxis = [];
				this.repartitionEquipementsCouts.forEach((equipement)=>{
					xAxis.push(equipement.libel_equipement);
					datasExterne.push(equipement.coutExterne);
					datasInterne.push(equipement.coutInterne);
					datasConsommable.push(equipement.coutConsommable);
				});
				this.xAxis = xAxis;
				barCoutsExterne.data = datasExterne;
				barCoutsInterne.data = datasInterne;
				barCoutsConsommable.data = datasConsommable;
				return [barCoutsExterne, barCoutsInterne, barCoutsConsommable];
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
