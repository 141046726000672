<template>
    <div v-if="equipement && (!markerVersion || markerVersion=='version-1')"
        :class="['equipement-marker-version-1',{'equipement-marker-has-maintenance': hasMaintenanceEncours, 'equipement-marker-zoom-out': zoomOut}]"
        :draggable="draggable"
        @dragstart="$emit('drag-start', {event: $event, equipement: equipement})"
        @click="$emit('click', {event: $event, equipement: equipement})">
        <vg-icon v-if="equipement.categorie && equipement.categorie.length!=0" 
            :url="equipement.categorie.icon" 
            :color="equipement.categorie.codeCouleur"
            :size="zoomOut?'xs':'md'" />
        <div class="content" v-if="!zoomOut">
            <span style="white-space: nowrap;">{{equipement.libel_equipement}}</span>
            <small>{{equipement.qrCode}}</small>
        </div>
        <!--img v-if="hasMaintenanceEncours" src="static/assets/icone/maintenance-rouge.png" style="height:15px;width:15px;"/-->
    </div>
    <div v-else-if="equipement && markerVersion=='version-2'"
        :class="['equipement-marker-version-2',{'equipement-marker-has-maintenance': hasMaintenanceEncours, 'equipement-marker-zoom-out': zoomOut}]"
        :draggable="draggable"
        @dragstart="$emit('drag-start', {event: $event, equipement: equipement})"
        @click="$emit('click', {event: $event, equipement: equipement})">
        <vg-icon v-if="equipement.categorie && equipement.categorie.length!=0" 
            :url="equipement.categorie.icon" 
            :color="equipement.categorie.codeCouleur"
            :size="zoomOut?'xs':'lg'" />
        <div class="content" v-if="!zoomOut">
            <span style="white-space: nowrap;">{{equipement.libel_equipement}}</span>
            <small>{{equipement.qrCode}}</small>
        </div>
    </div>
    <div v-else class="equipement-marker-to-delete">
        <span>{{$t("none")}}</span>
    </div>
</template>

<script>
import Vue from "vue";
import VgIcon from "src/components/Vg/VgIcon.vue";
export default Vue.extend({
    name:"equipement-marker",
    components:{
        VgIcon
    },
	props: {
        equipement:{
            type: Object,
            required: true
        },
        geoJsonPoint: {
            type: Object
        },
        draggable: {
            type: Boolean,
            default: false
        },
        markerVersion: {
            type: String,
            default: "version-1"
        },
        /** 
         * marker minimaliste pour rendre le marker plus petit 
         * */
        zoomOut:{
            type: Boolean,
            default: false
        }
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "none": "A supprimer"
        },
        "en": {
            "none": "To delete"
        }
    }
},
    data: function(){
        return {

        };
    },
    methods:{

    },
    computed: {
        hasMaintenanceEncours: function(){
            if(this.equipement.maintenances) return this.equipement.maintenances.findIndex((fm)=>fm.statut=="En_cours")!=-1;
            else return false;
        }
    }
});
</script>
<!--style lang="scss">
.leaflet-div-icon{
    border: white !important;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaflet-marker-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style-->
<style lang="scss">
.leaflet-div-icon{
    background: none !important;
}
</style>
<style lang="scss" scoped>
$marker-default-background-color: whitesmoke;
$marker-equipement-background-color: #7b54a0;
$marker-to-delete-background-color: #aaa;
$marker-default-color: white;
.equipement-marker-version-1{
    cursor:pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    background-color: $marker-default-background-color;
    padding: 1px 3px;
    small{
        color: #929396;
    }
    .content{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:flex-start;
    }
}
.equipement-marker-version-2{
    cursor:pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: none;
    font-size: 10px;
    padding: 3px;
    small{

    }
    .content{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:center;
    }
}

.equipement-marker-zoom-out{
    font-size: 8px !important;
    gap: 2px !important;
    padding: 1px !important;
}
.equipement-marker-to-delete{
    background-color: $marker-to-delete-background-color;
}
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
.equipement-marker-has-maintenance {
    background-color: red !important;
    color: white;
    img{
        filter: brightness(0) invert(1);
    }
    small{
        color: white;
    }
    -webkit-animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: blink 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation: blink 1s;
    -o-animation-iteration-count: infinite;
}
</style>
