<template>
    <vg-select v-if="services && services.length>0"
        v-model="selectedService"
        :options="services"
        :attributeValue="'libelService'"
        :attributeLabel="'libelService'"
        :multiple="multiple"
        :clearable="clearable"
        :placeholder="placeholder?placeholder:$t('select-service')"
        @input="handleInputChange">
    </vg-select>
    <span v-else></span>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import LibelServicesMixins from "src/mixins/LibelServicesMixins.js";
import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-service-selector',
    mixins:[LibelServicesMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String | Array,
            default: function(){
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        multiple:{
            type:Boolean,
            default:false
        },
        clearable:{
            type:Boolean,
            default:true
        },
        fetchIfStoreIsEmpty: {
            type: Boolean,
            default: false
        },
        restrictionSite: {
            type: Boolean,
            default: false
        },
        showAll:{
            type: Boolean,
            default: false
        },
        executeFetch: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-service": "Sélectionner un service"
        },
        "en": {
            "select-service": "Select a department"
        }
    }
},
    data: function() {
        return {
            selectedService: this.value?this.value:null,
            agfilters: {}
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selectedService = newValue;
            }
        },
        filters: {
            handler: function(newValue){
                this.agfilters = Object.assign({}, this.agfilters, newValue);
                this.fetch();
            },
            deep: true
        }
    },
	created: function(){
        this.initSelectedService();
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        this.fetch();
	},
    methods:{
        fetch: function(){
            if(!this.executeFetch) return;
            if(this.fetchIfStoreIsEmpty && (!this.services || this.services.length==0)) return;
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agfilters);
            this.LibelServicesMixins_getLibelServices(metadatas, {_stored: true, _restrictionSite: this.restrictionSite, _all: this.showAll}).then((datas)=>{
                console.log("SERVICES", datas);
                this.$storage.set("has-only-one-service", this.services.length==0 || this.services.length==1);
                this.$emit("services-feeded", this.services);
            });
        },
        /**
        * Emit input service label.
        * @event input
        * @param String service
        */
        handleInputChange:function(service){
            this.$emit("input", service);
            this.$emit("change", service);
            this.$emit("selected", this.selectedService);
        },
        initSelectedService: function(){
            if(this.multiple) return this.value?this.value:[];
            else return this.value?this.value:null;
        }
    },
    computed:{
        ...mapGetters({
            services: 'LibelServicesStore/getCollection'
        })
    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-service-selector:
    permet de selectionner un service, retourne le service selectionné
    la propriété filters permet d'indiquer à la query quelles critères de filtrage
    appliqué sur la query getLieux
    #### basic usage

    ```html static
        <vg-service-selector
            v-model="agfilters.service.value" />
    ```
</docs>
