<template>
	<div class="vg-operation-tags-viewer">
		<vg-tag v-for="tag in value" :key="tag.id"
			:label="tag.label">
		</vg-tag>
	</div>
</template>
<script>
	import VgTag from "src/components/Vg/VgTag.vue";
	export default {
        name: 'vg-operation-tags-viewer',
		components: {
			VgTag
		},
    	props: {
			value: {
				type: Array,
				required: true
			}
        },
        data: function() {
            return {

			};
        }
    };
</script>
<style scoped lang="scss">
.vg-operation-tags-viewer{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
</style>
