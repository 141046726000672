
<template lang="html">
	<div v-if="filesToShow" class="viewer-container">
		<div v-for="item in filesToShow" class="section">
			<vg-collapse
				:title="item.tag.label"
				:defaultMessage="$t('no-file')"
				class="file-collapse"
				:collapseByDefault="collapseByDefault">
				<template v-slot:titlecomplement>
					<span v-if="item.files && item.files.length!=0"  class="badge-red">{{item.files.length}}</span>
				</template>

				<div slot="content" v-if="item.files.length!=0" >
					<div v-for="file in item.files" class="file-line">
						<div class="details">
							<vg-datetime-viewer v-model="file.uploadedAt">
							</vg-datetime-viewer>
							<!--span style="flex-grow:1;font-size: 11px;">{{file.user}}</span-->
							<span @click="openFile(file)">
								<img v-if="file.isInherited && file.isInherited=='1'"
									src="/static/assets/icone/categorie-equipement-gris.png"
									alt=""
									width="20px" height="20px">
								{{file.name}}
							</span>
						</div>
						<div class="buttons">
							<vg-button type="default-info" @click="downloadFile(file)"><i class="fas fa-cloud-download-alt"></i></vg-button>
							<vg-button v-if="!file.isInherited || file.isInherited=='0'" type="default-danger" @click="openDeleteFileDialog(file)"><i class="fas fa-trash"></i></vg-button>
						</div>
					</div>
				</div>
			</vg-collapse>
		</div>
		<vg-file-viewer v-if="loadedFileInViewer" :file="loadedFileInViewer" @close="loadedFileInViewer = null"/>
		<dialog-secured-action
			v-if="fileToDelete"
			:valid-response="1"
			@save="onDeleteFile"
			@close="fileToDelete=null">
			<template v-slot:header-title>
				{{ $t("delete-confirm") }} <b>{{ fileToDelete.name }}</b> ?
			</template>
	    </dialog-secured-action>
	</div>
	<div v-else class="viewer-container">
		{{$t("no-data")}}
	</div>
</template>
<script>
	import VgCollapse from "src/components/Vg/VgCollapse.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
	import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
	import VgFileViewer from 'src/components/Vg/Files/VgFileViewer.vue';
    export default {
        name: 'vg-files-viewer-list-section',
	    components: {
			VgCollapse,
			VgButton,
			VgDatetimeViewer,
			DialogSecuredAction,
			VgFileViewer
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-data": "Aucune données",
            "no-file": "Aucun document",
            "delete-confirm": "Confirmer la suppression du document"
        },
        "en": {
            "no-data": "No data",
            "no-file": "No document",
            "delete-confirm": "Confirm documents deletion"
        },
        "th": {
            "no-data": "ไม่มีข้อมูล",
            "no-file": "ไม่มีเอกสาร",
            "delete-confirm": "ยืนยันการลบเอกสาร"
        }
    }
},
        props: {
			files: {
				type: Array,
				default: []
			},
			collapseByDefault:{
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				filesToShow: this.files || [],
				fileToDelete: null,
				loadedFileInViewer: null
			};
        },
		watch: {
			files: {
				handler: function(values){
					////console.log("WATCH FILES", values);
					this.filesToShow = values;
					this.$forceUpdate();
				},
				deep: true
			}
		},
        methods: {
			downloadFile: function(file){
				// //console.log("DOWNLOAD FILE", file);
				this.VgFilesMixins_getDownloadFileLink(file).then((link)=>{
					this.VgFilesMixins_downloadFile(file, link);
				});
			},
			openFile: function(file){
				this.VgFilesMixins_getDownloadFileLink(file).then((filelink)=> {
					fetch(filelink).then((response)=>{
						response.blob().then((blob) => {
							this.loadedFileInViewer = new File([blob], "bon-entre_sortie", { type: blob.type });
						});
					})
				});
			},
			openDeleteFileDialog: function(file){
				this.fileToDelete = file;
			},
			onDeleteFile: function(){
				this.VgFilesMixins_deleteFile(this.fileToDelete).then((data)=>{
					this.$emit("delete", this.fileToDelete);
					this.fileToDelete = null;
				});
			}
		}
	};
</script>
<style lang="scss">
.file-collapse{
	.title{
		color:#bbbcbe;
	}
}
</style>
<style lang="scss" scoped>
.viewer-container{
	width: 100%;
	.collapse-header-main{

	}
	.section{
		margin-bottom: 10px;
	}
}
.badge-red{
	padding: 0px 2px;
	font-size: 12px;
	background-color: red;
	color: white;
	border: 1px solid red;
	border-radius: 3px;
}

.file-line{
	display: flex;
	justify-content: space-between;
	align-items:baseline;
	overflow: auto;
	margin-bottom:10px;
	>.details{
		span:nth-child(2){
			margin: 0px 10px;
			text-decoration: underline;
			cursor: pointer;

		}
		span:nth-child(2):hover{
			color: #35B1E6;
		}
	}
}
</style>
