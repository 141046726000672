<template lang="html">
    <div class="vg-box-info" @click="$emit('click')">
        <div class="libelle">
            <slot name="label"></slot>
            {{label}}
        </div>
        <div class="info">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:"vg-box-info",
    props:{
        label:{
            type:String,
        }
    }

}
</script>

<style lang="scss" scoped>
    .vg-box-info{
        width: 50%;
        display: flex;
        flex-direction: column;
        border: 1px solid lightgray;
        font-size: 11px;
        padding:12px 10px;
        .libelle{
            color:lightgray;
            height: 20px;
        }
        .info{

        }
    }
</style>
