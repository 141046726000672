<template>
    <div>
        <small><vg-datetime-viewer v-model="params.data.dateCreation"/> <span>{{params.data.demandeur.nom}} {{params.data.demandeur.prenom}}</span></small>
        <br>
        <span>{{$t("numero")}}: <b>{{params.data.numero}}</b></span> | 
        <span>{{$t("statut")}}: {{params.data.statut}}</span>
        <br>
        <small>{{$t("service")}}: {{params.data.serviceDemandeur}}</small>
        <small v-if="params.data.site_id && params.data.site.libel_lieu">{{$t("site")}}: {{params.data.site.libel_lieu}}</small>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "fiche-demande-consommables-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgOperationFlag,
            VgDatetimeViewer,
            VgButton
        },
        data: function() {
            return {

			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "statut": "Statut",
            "numero": "Numéro",
            "service": "Service demandeur",
            "Site": "Site"
        },
        "en": {}
    }
},
        methods:{

        }
    });
</script>

<style lang="scss" scoped>
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
</style>
