<template lang="html">
	<div class="vg-filter-fichesav-statut">
		<vg-input
			:title="title?title:$t('title')">
			<vg-fichesav-statut-selector v-model="selectedStatut"
				@input="handleChange"/>
		</vg-input>
	</div>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgFichesavStatutSelector from "src/components/Vg/Selectors/VgFichesavStatutSelector.vue";

    import Metadatas from "src/services/Metadatas.js"
    export default {
        name: 'vg-filter-fichesav-statut',
	    components: {
			VgInput,
			VgFichesavStatutSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: String
			},
			title: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par statut"
        },
        "en": {
            "title": "Filtering by status"
        }
    }
},
        data: function(){
            return {
				selectedStatut: this.value
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedStatut = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			/**
	         * emit le statut selectionné
	         * @event input selectedStatut
	         */
			handleChange: function(statut){
				this.$emit("input", statut);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
<docs>
    vg-filter-fichesav-statut
</docs>
