<template>
    <div class="intervention-cell-render">
        <div class="title" v-if="intervention.contrat">
            <img src="static/assets/icone/contrat.png"/>
            <span ><b>{{intervention.contrat.name}}</b></span>
        </div>
        <div class="title" v-else>
            <span ><b>{{intervention.intitule}}</b></span>
        </div>
        <div class="content">
            <div class="left">
                <div>
                    <span>{{$t("prevue-le")}}: </span>
                    <vg-datetime-viewer
                        v-model="intervention.datePrevisionnelleDebut">
                    </vg-datetime-viewer>
                </div>
                <div>
                    <span>{{$t("effectuee-le")}}: </span>
                    <vg-datetime-viewer
                        v-model="intervention.dateEffectiveDebut">
                    </vg-datetime-viewer>
                </div>
                <div>
                    <span>{{$t("par")}}: </span>
                    <span v-if="intervention.tiers">{{intervention.tiers.name}}</span>
                </div>
            </div>
            <div class="right">
                <span v-if="intervention.status=='intervention-realisee'" class="statut-validee">{{$t("validee")}}</span>
                <span v-else class="statut-non-validee">{{$t("non-validee")}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "intervention-cell-render",
        mixins:[LangMixins],
		props: {
			intervention: {
				type: Object,
				required: true
			}
		},
        components: {
            VgDatetimeViewer
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "prevue-le": "Prévue le",
            "effectuee-le": "Effectuée le",
            "par": "Par",
            "validee": "Validée",
            "non-validee": "Non validée"
        },
        "en": {
            "prevue-le": "Expected at : ",
            "effectuee-le": "Done at :",
            "par": "By",
            "validee": "Validated",
            "non-validee": "Not validated"
        },
        "th": {
            "prevue-le": "คาดการณ์เมื่อ : ",
            "effectuee-le": "ทำแล้วเมื่อ :",
            "par": "โดย",
            "validee": "ตรวจสอบแล้ว",
            "non-validee": "ยังไม่ได้ตรวจสอบ"
        }
    }
}
    });
</script>

<style lang="scss" scoped>
.intervention-cell-render{

    .title{
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        img{
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
    .content{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 10px;
            >div{
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                >span:first-child{
                    width: 80px;
                    color: #a6a6a6;
                }
            }

        }
        .right{
            font-size: 10px;
            color: white;
            >*{
                padding: 2px 4px;
            }
            .statut-validee{
                background-color: green;
            }
            .statut-non-validee{
                background-color: red;
            }
        }
    }

}
</style>
