<template>
    <div class="vg-date-picker">
        <input
            class="date-picker-start"
            v-model="dateMin"
            :type="type"
            @change="handleChange"
        />
        <span>{{$t("au")}}</span>
        <input
            class="date-picker-end"
            v-model="dateMax"
            :min="dateMin"
            :type="type"
            @change="handleChange"
        />
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "vg-date-picker",
    props: {
        from : {
            type: String,
            default: function(){
                return moment().format("YYYY-MM-DD");
            }
        },
        to : {
            type: String,
            default: function(){
                return moment().format("YYYY-MM-DD");
            }
        },
        type: {
            type: String,   // date || datetime-local
            default: "date"
        }
    },
    data: function() {
        return{
            dateMin: this.getInputDate(this.from),
            dateMax: this.getInputDate(this.to)
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "au": "au"
        },
        "en": {
            "au": "to"
        },
        "th": {
            "au": "ถึง"
        }
    }
},
    watch:{
        
    },
    methods:{
        getInputDate: function(date){
            if(this.type=="date") return date;
            else return moment(date).format("YYYY-MM-DDTHH:mm");
        },
        getDefaultDateFormat: function(date){
            if(this.type=="date") return date;
            else return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        handleChange: function(e){
            let dateMin = moment(this.dateMin);
            let dateMax = moment(this.dateMax);
            if( dateMin > dateMax ){
                dateMax = dateMin;
                this.dateMax = dateMax.add(30,"m").format("YYYY-MM-DDTHH:mm:ss");
                this.$forceUpdate();
            }
            this.$emit("change",
                {
                    dateMin: this.getDefaultDateFormat(this.dateMin),
                    dateMax: this.getDefaultDateFormat(this.dateMax)
                }
            );
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .vg-date-picker{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            color: #9e9da9;
            font-size: 0.85em;
        }
    }
    .date-picker-start , .date-picker-end{
        text-align: center;
        color: #252525;
        font-family: "Open Sans";
        width: 45%;
    }
    @media only screen and (max-width: 768px) {
        .vg-date-picker{
            flex-direction: column;
            input{
                width: 100%;
            }
            span{
                display: block;
                height: 20px;
                width: 100%;
                text-align: center;
                margin: 3px 0;
            }
        }
    }
</style>
