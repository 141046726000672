<template >

    <div class="inventaireLieuCellRenderB">
        <span class="row_one">
            <div class="col_one">
                <div>
                    <img :src="equipements.icon" alt="..." height="30px"   onerror="this.style.visibility='hidden'"/>
                </div>
                <div class="nomequip">
                    {{equipements.libel_equipement}}
                </div>
                <div class="vlge">
                    {{equipements.qrCode}}
                </div>
                <div class="date" v-if="equipements.createdAt">
                    {{$t("le")}} : {{$vgutils.formatDate(equipements.createdAt)}}
                </div>

            </div>
            <div class="col_two">
                <div class="badge-missing">
                    <div class="green-badge" v-if="!equipements.isMissing && equipements.statut == 'Ok'">
                        {{$t('present')}}
                    </div>
                    <div class="red-badge" v-else-if="!equipements.isMissing && equipements.statut == 'notFound'">
                        {{$t('absent')}}
                    </div>
                    <div  class="empty-badge" v-else>
                        {{$t('afaire')}}
                    </div>
                </div>
                <div class="mouv" v-if="equipements.etat">
                    {{$t('mouvement')}} :
                    <span class="secondary-text" v-if="equipements.hasMoved">{{$t("yes")}}</span>
                    <span class="secondary-text" v-else>{{$t("no")}}</span>
                </div>
                <div class="etat" v-if="equipements.etat">
                    {{$t('etat')}} : <span class="secondary-text"  >{{equipements.etat}} </span>
                </div>

            </div>
        </span>




    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"inventaire-cell-render",
    mixins:[LangMixins],
    filters:{
        BooleanToMouvement:function(value){
            return value ? this.$t("yes"):this.$t("no")
        }
    },
    props:{
        equipements:{
          type:Object,
          default:function(){
              return {};
          }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "present": "Present",
            "yes": "Yes",
            "absent": "Absent",
            "le": "Le",
            "afaire": "A faire",
            "mouvement": "Mouvement",
            "etat": "Etat"
        },
        "en": {
            "present": "Found",
            "no": "No",
            "absent": "Missing",
            "afaire": "To do",
            "mouvement": "Has moved",
            "etat": "Condition",
            "le": "On the"
        },
        "th": {
            "le": "บน",
            "present": "แสดงตน",
            "absent": "สูญหาย",
            "afaire": "ต้องทำ",
            "mouvement": "เคลื่อนย้ายแล้ว",
            "etat": "สถานะ"
        }
    }
}
});
</script>

<style lang="scss">
#InventaireCellRender{
}
.inventaireLieuCellRenderB{
    .secondary-text{
        font-size: 10px;
        color: black;
        font-weight: bold;
        text-transform: uppercase;

    }
    .row_one{
        display:flex;
        flex-direction:row;
        padding:5px;
        .col_one{
            order:1;
            flex-grow:2;
            margin-top: 5px;

            display:flex;
            flex-direction:column;
            .nomequip{
                font-size: 14px;
                font-weight: bold;
                flex-grow:2;
                white-space: normal;
                margin-bottom: 2px;

            }
            .vlge{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
            }
            .date{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
            }
            img{
                flex-grow:3;
                align-self:flex-start;
                margin-bottom: 5px;
                height: 25px;
            }
        }
        .col_two{
            display:flex;
            flex-direction:column;
            order:2;
            flex-grow:1;
            // margin-top: 10px;
            margin-left: 10px;
            font-size: 18px;
            .badge-missing{
                width: 80px;
                flex-grow: 2;
                align-self: flex-end;
                font-size: 12px;
                color: white;
                > div{
                    text-align: center;
                    padding: 5%;
                }
                .green-badge{
                    background-color: #60BB46;
                }
                .red-badge{
                    background-color: #e60000;
                }
                .empty-badge{
                    // color:grey;
                    background-color: #a2a2a2;
                }
            }
            .mouv{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:0;
                align-self: flex-end;

            }
            .etat{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
                align-self: flex-end;

            }
        }
    }


}
</style>
