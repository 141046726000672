import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.inventaire = {};
state.inventoried = [];
state.notInventoried = [];
let getters = Base.getGetters();
getters.getInventaire = function( state ){
    return state.inventaire;
};
getters.getInventoried = function( state ){
    return state.inventoried;
};
getters.getNotInventoried = function( state ){
    return state.notInventoried;
};
let actions = Base.getActions();
actions.setOperations = function( context, operationsInventaire ){
    context.commit('SET_OPERATIONS', operationsInventaire);
};
actions.addOperation = function( context, operationInventaire ){
    console.log("ADD OPERATION", operationInventaire);
};
actions.removeInventoried = function( context, idOperationInventaire ){
    context.commit("REMOVE_OPERATION_INVENTORIED", idOperationInventaire);
};
let mutations = Base.getMutations();
mutations.SET_OPERATIONS = function(state, operationsInventaire){
    state.inventaire = operationsInventaire.inventaire;
    state.inventoried = operationsInventaire.inventoried;
    state.notInventoried = operationsInventaire.notInventoried;
    let inventoried = operationsInventaire.inventoried.map((item)=> Object.assign(item,{
        isMissing: false,
        hasMoved: item.lieuInventorier_id != item.derniersLieuConnu_id
    }));
    let notInventoried = operationsInventaire.notInventoried.map((item)=> Object.assign(item,{
        isMissing: null,
        hasMoved: item.lieuInventorier_id != item.derniersLieuConnu_id
    }));
    // concat inventorier et non inventorier
    // problème la chaise a été inventorié donc elle est dans le bloc inventoriés
    // mais ici elle est aussi dans le non inventorié donc à l'affichage la chaise apparait
    // inventorié et non inventorié , backend voir si c'est à cause du fait que cette chaise n''est pas été
    // inventorié dans un inventaire précédent
    // ok je valide. quand il y a un inventaire précédent ou la chaise n'a pas été inventorié
    // elle est inventorié dans l'inventaire courant mais rentre quand même dans le bloc non inventoriés
    // car elle ne l'était pas avant 
    state.collection = inventoried.concat(notInventoried);
};
mutations.REMOVE_OPERATION_INVENTORIED = function(state, idOperationInventaire){
    let indexOperationInventaire = state.inventoried.findIndex((operation)=>{
        return operation.id == idOperationInventaire;
    });
    if(indexOperationInventaire!=-1) indexOperationInventaire = state.notFound.findIndex((operation)=>{
        return operation.id == idOperationInventaire;
    });
    if(indexOperationInventaire==-1) return;
    let operationInventaire = Object.assign({}, state.getInventoried[indexOperationInventaire]);
    operationInventaire = Object.assign(operationInventaire, {
        isMissing: null,
        hasMoved: operationInventaire.lieuInventorier_id != operationInventaire.derniersLieuConnu_id,
        statut: null
    });
    // remove l'equipement operation de inventoried
    state.inventoried.splice(indexOperationInventaire, 1);
    // ajoute l'equipement operation dans notInventoried
    state.notInventoried.shift(operationInventaire);
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
