<template>
    <vg-modal
        :title="$t('title-'+operation.flag)"
        @close="close"
        @save="submit">
        <template #body>
            <vg-input :inputType="'textarea'" v-model="operation.retourClient" />
        </template>
    </vg-modal>
</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
export default {
    name:"vg-operation-commentaire-update",
    components:{
        VgButton,
        VgInput
    },
    mixins:[
        MaintenanceMixins
    ],
    props:{
        value: {    // operation
            type: Object,
            required: true
        }
    },
    data:function() {
        return{
            operation: Object.assign({}, {}, this.value)
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-tache": "Commenter la tache",
            "title-a_prevoir": "Commenter à prévoir"
        },
        "en": {
            "title-tache": "Task feedback",
            "title-a_prevoir": "Feedback task"
        }
    }
},
    methods:{
        submit: function(){
            let operation = {
                id: this.operation.id,
                retourClient: this.operation.retourClient
            };
            this.MaintenanceMixins_putOperation(operation).then((data)=>{
                this.$emit('save', data);
            });
        },
        close: function(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
</style>
