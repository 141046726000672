<template>
    <div class="equipement-piece-to-check-cell-render">
        <vg-equipement-viewer v-if="params.data.isGroupEqp!='1'"
            :equipement="params.data" />
        <vg-lieu-path-viewer v-else
            v-model="params.data.path"
            onlyPiece/>
        <div class="content">
            <vg-button type="danger"
                style="min-width: 30vw;"
                @click="goToCheck">
                {{ $t('verifier') }}
            </vg-button>
            <div v-if="params.data.dateVerif" 
                class="derniere-verif">
                <small>{{ $t('derniere-verification') }}</small>
                <vg-datetime-viewer v-model="params.data.dateVerif"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
	import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
    export default Vue.extend({
        name:"equipement-piece-to-check-cell-render",
		mixins:[LangMixins],
        components: {
            VgEquipementViewer,
            VgLieuPathViewer,
            VgButton,
            VgDatetimeViewer,
            VgMaintenanceForm
        },
    i18n:    { "locale":navigator.language,
"messages": {
    "fr": {
        "derniere-verification": "Dernière vérification le",
        "verifier": "Vérifier"
    },
    "en": {
        "derniere-verification": "Last checked",
        "verifier": "Check"
    }
}
},
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isDefaultMaintenance:{
                type: Boolean,
                default: false
            }
		},
        data: function(){
            return {
                isMaintenance: this.isDefaultMaintenance || false,
                showMaintenanceForm: false
            }
        },
        watch:{
            isDefaultMaintenance:{
                handler: function(isDefault){
                    this.isMaintenance = isDefault;
                }
            }
        },
        methods:{
            goToCheck: function(){
                this.$emit("go-to-check", this.params.data);
            }
        }
    });
</script>
<style lang="scss" scoped>
.equipement-piece-to-check-cell-render{
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:5px;
    padding: 20px 0;
    .content{
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap:5px;
        .derniere-verif{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            small{
                color: rgb(156, 156, 156);
            }
        }
    }
}
</style>
