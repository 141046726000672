<template>
	<div class="vg-equipement-donnees-financiere">
        <div class="flexRow">
            <div class="flexCol" style="margin-right: 50px;font-size:10px;">
                <div v-if="!parseInt(getEquipement.isGroupEqp)" class="title">{{$t('valeur-achat')}}</div>
                <div class="title">{{$t('total-cost')}}</div>
                <div  v-if="!parseInt(getEquipement.isGroupEqp)" class="title">{{$t('age')}}</div>
            </div>

            <div class="flexCol">
                <div v-if="!parseInt(getEquipement.isGroupEqp) && getEquipement.valeurAchat" class="value">{{getEquipement.valeurAchat}} {{$app.devise}}</div>
                <div v-else class="value">-</div>
                <div class="value" v-if="maintenances.length" >
                    {{totalCost}} {{$app.devise}}
                    <span v-if="!parseInt(getEquipement.isGroupEqp) && getEquipement.valeurAchat" style="margin-left:10px;font-size:16px;color:green;">{{percentageActualCostComparedToInitialCost}} %</span>
                    <span v-if="!parseInt(getEquipement.isGroupEqp) && getEquipement.valeurAchat" style="font-size:10px;color:grey;">{{$t('percentage-acquisition-vs-maintenances-costs')}}</span>
                </div>
                <div v-else>
                    <div class="value">
                        -
                    </div>
                </div>
                <div v-if="!parseInt(getEquipement.isGroupEqp)" class="value">{{getAge}}</div>
            </div>
        </div>
	</div>
</template>


<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-equipement-donnees-financiere',
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "valeur-achat": "Valeur Achat",
            "total-cost": "Coût total",
            "age": "Age",
            "days": "Jours",
            "and": "et",
            "years": "An(s)",
            "percentage-acquisition-vs-maintenances-costs": "de la valeur acquisition"
        },
        "en": {
            "valeur-achat": "Purchase value",
            "total-cost": "Total cost",
            "age": "Age",
            "days": "Days",
            "and": "and",
            "years": "Years",
            "percentage-acquisition-vs-maintenances-costs": "of purchase value"
        }
    }
},
		components: {},
		mixins: [],
        props: {},
        data: function() {
            return {
                internalCost:0,
                externalCost:0,
                consumptionCost:0
            };
        },
        watch: {},
		created: function(){},
		mounted: function(){

        },
		methods: {
            /**
             * @param string column
             * @return integer
             */
            getCost:function(maintenance, column){
                let cost = parseInt(maintenance[column]);
                return cost;
            }
        },
        computed:{
            ...mapGetters({
                getEquipement: 'EquipementsStore/getSelectedItem',
                maintenances: 'MaintenancesStore/getMaintenances'

            }),
            /**
             * get equipment's age based on date mise en service
             * @return string

             */
            getAge:function(){
                if(!this.getEquipement.miseEnService){
                    return "-";
                }
                let days = moment().diff(this.getEquipement.miseEnService, "days");
                let years = 0;
                if(days > 365){
                    years = moment().diff(this.getEquipement.miseEnService, "year");
                    let yearsInDays = years * 365;
                    days = days - yearsInDays;
                }
                if(years != 0 ){
                    years = `${years} ${this.$t("years")} ${this.$t("and")}`;
                }else{
                    years="";
                }
                return `${years}  ${days} ${this.$t("days")}`;
            },
            /**
             * get total cost on all maintenances
             * @return integer
             */
            totalCost:function(){
                this.internalCost = 0;
                this.externalCost = 0;
                this.consumptionCost = 0;
                this.maintenances.forEach((maintenance, i) => {
                    this.internalCost += this.getCost(maintenance, "coutInterne");
                    this.externalCost += this.getCost(maintenance, "coutExterne");
                    this.consumptionCost += this.getCost(maintenance, "coutConsommations");
                });
                return this.internalCost + this.externalCost + this.consumptionCost;
            },
            /**
             * @return float
             */
            percentageActualCostComparedToInitialCost:function(){
                let percentage = 0;
                if(this.getEquipement.valeurAchat){
                    percentage = this.totalCost / this.getEquipement.valeurAchat  *100;
                    percentage = Number.parseFloat(percentage).toFixed(1);
                }
                return percentage;
            }
        }
    };

</script>
<style lang="scss" scoped>
    .flexRow{
        display:flex;
        /*justify-content: space-between;
        width: 30%;*/
    }
    .flexCol{
        display:flex;
        flex-direction:column
    }
    .title{
        color:grey;
        margin-bottom: 15px;
    }
    .value{
        color: #353333;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .vg-equipement-donnees-financiere{
        border: 1px solid lightgrey;
        padding: 20px 15px;
    }
</style>
