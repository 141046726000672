<template lang="html">
    <div class="site-mobile-cell-render">
        <div class="title">
            <img src="static/assets/icone/circle/site-cercle.png" width="20" height="20"/>
            <span style="color:#3999FF;">{{params.data.libel_lieu}}</span>
        </div>
        <div class="complement">
            <small v-if="params.data.famille">{{ params.data.famille }}</small>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name: "lieu-mobile-cell-render",
    mixins:[LangMixins],
    components:{
        VgLieuPathViewer,
        VgDatetimeViewer
    },
    props: {
        params: {
            type: Object,
        },
    },
    computed: {
        hasMaintenance: function(){
            return this.params.data.idFM && (this.params.data.statut=='En_cours' || this.params.data.statut=='prise_en_compte' || this.params.data.statut=='en_attente');
        },
    }
})
</script>

<style lang="scss" scoped>
.site-mobile-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: inherit;
    height: inherit;
    .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    .complement{

    }
}
</style>
