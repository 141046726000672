<template lang="html">
    <div class="assets-reporting-verification">
        <vg-button class="hidden-print"
            :type="'success'"
            @click="preLaunchPrint()">
            {{ $t("rapportAsset-imprimer") }}
        </vg-button>
        <vg-button class="hidden-print"
            :type="'success'"
            @click="TagGridMixins.isModalShowHideColumnsOpened=true;">
            {{ $t("rapportAsset-GestionColonne") }}
        </vg-button>
        <vg-button class="hidden-print"
            :type="'info'"
            @click="setRowHeight()">
            {{ isRowHeightIsSmall?$t("augmenter-hauteur-ligne"):$t("reduire-hauteur-ligne") }}
        </vg-button>
        <!-- <button class="btn btn-info hidden-print vg-margin-bottom-10" @click="hidePhoto">{{ $t("rapportAsset-HidePhoto") }}</button> -->
        <div class="header-rapport">
            <div class="entete-lieux">
                <span v-if="organisation">{{organisation}}</span>
            </div>
            <div class="entete-title" v-if="allTitle">
                <b class="titlehead">{{allTitle}}</b>
            </div>
            <div class="entete-title" v-else>
                <b>{{$t("rapportAsset-Rapport")}}</b> {{$t("rapportAsset-title")}} <b class="titlehead">{{titlehead}}</b>
            </div>

            <div class="entete-img">
                <img src="\static\assets\icone\new-logo-verifgood-jaune-gris.png" height="30">

            </div>

        </div>
        <div class="separateur">

        </div>

        <div class="body-rapport">
            <tag-grid
                :idTableau="getIdTableau"
                :isModalShowHideColumnsOpened="TagGridMixins.isModalShowHideColumnsOpened"
                v-model="gridOptions"
                :columnDefs="columnDefs"
                :rowData="data"
        		:pathSplitKey="pathSplitKey"
                @grid-feeded="setDefaultFilterPrint()"
                @modal-show-hide-columns-closed="TagGridMixins_modalShowHideColumnsClosed"
                @on-grid-ready="setDefaultFilterPrint()"
                :defaultHeight="'400px'" />
        </div>
        <vg-modal class="hidden-print" v-if="showModalSetNamePrint"  @close="closeModalPrint" @save="print()"
            :title="$t('rapportAsset-filename')">
            <template #body>
                <vg-input v-model="titlePrint"></vg-input>
            </template>
        </vg-modal>

    </div>
</template>

<script>
import TagGrid from "src/components/Grid/TagGrid.vue";
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import DescriptionFmCellRender from "src/components/Vg/TagGrid/DescriptionFmCellRender.vue";

import StatutFmCellRender from "src/components/Vg/TagGrid/StatutFmCellRender.vue";

import PriseEnCompteFmCellRender from "src/components/Vg/TagGrid/PriseEnCompteFmCellRender.vue";


import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import LangMixins from "src/components/Vg/TagGrid/LangMixins";

export default {
    name: 'vg-reporting-prints',
    mixins:[LangMixins],
    props: {
        data: {
            type: Array,
            default: function(){
                return null;
            },
        },
        titlehead:String,
        allTitle:String,
        idTableau:{
            type:String,
            default:""
        },
        columnDefs:{
            type: Array,
            default: function(){
                return null;
            }
        },
        search:{
            type: String,
        },
        pathSplitKey:{
            type: String,
            default: "path"
        }
    },
    mixins:[
        TagGridMixins
    ],
    components:{
        TagGrid,

        DescriptionFmCellRender,
        StatutFmCellRender,
        PriseEnCompteFmCellRender,
        VgInput,
        VgButton
    },
    data: function() {
        return {
            showModalSetNamePrint:false,
            titlePrint:"Rapport_",
            searchQuery:this.search,
            isPhotoVisible: false,
            gridOptions: {
                rowHeight: 40,
                domLayout:"forPrint",
                isExternalFilterPresent: ()=>this.TagGridMixins_isExternalFilterPresent(),
                doesExternalFilterPass: (node)=>this.TagGridMixins_doesExternalFilterPass(node),
            },
            gridIdLargeRow:["vg-fiche-sav"]
        }
    },
    computed:{
        organisation: function(){
            return this.$app.organisation;
        },
        getIdTableau: function(){
            return "report-print-" + this.idTableau;
        },
        isRowHeightIsSmall: function(){
            return this.gridOptions.rowHeight==40;
        }
    },
    methods:{
        setDefaultFilterPrint: function(){
            // this.isPrintMode = "forPrint";
            ////;
            this.gridOptions.domLayout= "forPrint";
            this.$emit("grid-feeded", this.gridOptions);

            // this.TagGridMixins_setFilters(this.gridOptions, this.tabs[0].filters);
            this.TagGridMixins_setQuickFilter(this.gridOptions, this.search);
        },
        setRowHeight: function(){
            this.gridOptions.rowHeight = this.isRowHeightIsSmall?120:40;
            this.gridApi.resetRowHeights();
        },
        changeToLargeRow:function(){
            this.gridOptions.rowHeight = 140;
            this.gridApi.resetRowHeights();
        },
        preLaunchPrint: function(){
            var gridApi = this.gridApi;
            this.setPrinterFriendly(gridApi);
            this.showModalSetNamePrint = true;
        },
        hidePhoto: function () {
            // //console.log("hidePhoto");
            // //console.log(this.columnDefs.length);
            // //console.log(this.columnDefs);
            this.isPhotoVisible = !this.isPhotoVisible;
            for (var pos = 0; pos < this.columnDefs.length; pos++) {

                if (this.columnDefs[pos].field == "photo1" || this.columnDefs[pos].field == "photo2" || this.columnDefs[pos].field == "photo3") {
                    // this.columnDefs[pos].hide = !this.columnDefs[pos].hide;
                    //console.log(this.columnDefs[pos].field);
                    this.gridOptions.columnApi.setColumnVisible(this.columnDefs[pos].field, this.isPhotoVisible);
                }
            }
            // this.$forceUpdate();
        },
        print: function() {
            var gridApi = this.gridApi;
            this.showModalSetNamePrint = false;
            document.title = this.titlePrint;
            window.print();
            this.setNormal(gridApi);
            document.title = "Verifgood";
        },
        setPrinterFriendly: function(api) {
            //console.log(this.getIdTableau);
            var selector ="#"+this.getIdTableau;
            var eGridDiv = document.querySelector(selector);
            eGridDiv.style.width = "";
            eGridDiv.style.height = "";
            api.setDomLayout("print");
        },
        setNormal: function(api){
            //console.log(this.getIdTableau);
            var selector ="#"+this.getIdTableau;
            var eGridDiv = document.querySelector(selector);
            eGridDiv.style.width = "auto";
            eGridDiv.style.height = "70vh";
            api.setDomLayout(null);
        },
        closeModalPrint: function() {
            var gridApi = this.gridApi;
            this.showModalSetNamePrint = false;
            this.setNormal(gridApi);
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "rapportAsset-Rapport": "Rapport",
            "rapportAsset-imprimer": "Générer PDF",
            "rapportAsset-GestionColonne": "Gestion Colonne ",
            "rapportAsset-HidePhoto": "Cacher Colonne",
            "rapportAsset-title": "d'activité curative",
            "table-header-photo-1": "Photo 1",
            "table-header-photo-2": "Photo 2",
            "table-header-photo-3": "Photo 3",
            "rapportAsset-filename": "Nom du fichier",
            "augmenter-hauteur-ligne": "Augmenter hauteur de ligne",
            "reduire-hauteur-ligne": "Réduire hauteur de ligne"
        },
        "en": {
            "rapportAsset-Rapport": "Report",
            "rapportAsset-GestionColonne": "Column management ",
            "rapportAsset-HidePhoto": "Hide column",
            "rapportAsset-title": "curative activities",
            "table-header-photo-1": "Photo 1",
            "table-header-photo-2": "Photo 2",
            "table-header-photo-3": "Photo 3",
            "rapportAsset-filename": "File title",
            "rapportAsset-imprimer": "Generate PDF",
            "augmenter-hauteur-ligne": "Increase row height",
            "reduire-hauteur-ligne": "Reduce row height"
        },
        "th": {
            "rapportAsset-Rapport": "รายงาน",
            "rapportAsset-GestionColonne": "การบริหารจัดการคอลัมน์ ",
            "rapportAsset-HidePhoto": "ซ่อนคอลัมน์",
            "table-header-photo-1": "รูปภาพ 1",
            "table-header-photo-2": "รูปภาพ 2",
            "table-header-photo-3": "รูปภาพ 3",
            "rapportAsset-imprimer": "พิมพ์ ",
            "rapportAsset-filename": "หัวข้อไฟล์"
        }
    }
},
    mounted:function(){
        var dateNow = moment().format("DDMMYYYY");
        // //console.log(dateNow);
        this.titlePrint += dateNow;
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        for (var grid = 0; grid < this.gridIdLargeRow.length; grid++) {
            if (this.gridIdLargeRow[grid] == this.idTableau) {
                this.changeToLargeRow();
            }
        }
    },
    created: function(){
    }
}
</script>

<style lang="scss">
.assets-reporting-verification{
    padding:0 40px;
    height: 100%;
    .separateur{
        height: 2px;
        width: 100%;
        background-color: #385FAF;
        margin-bottom: 100px;
    }
}
.body-rapport{
    height: calc(100% - 200px);
}
.header-rapport{
    width: 100%;
    height: 50px;
    margin-bottom: 7px;
    margin-top: 30px;
    display: flex;
    .entete-lieux{
        flex-grow: 1;
        align-self:center;
        font-size: 20px;
    }
    .entete-title{
        text-align: center;
        flex-grow: 2;
        align-self:center;
        font-size: 20px;
        .titlehead{
            font-size: 24px;
        }
    }
    .entete-img{
        float: right;
        flex-grow: 1;
        img{
            float: right;
            margin-right: 30px;
        }
    }

}


@media print{
    .assets-reporting-verification{
        padding:10px;
        &.vg-print-show{
            visibility: visible !important;
        }
        .vg-print-show{
            visibility: visible !important;
        }
    }
}
</style>
