<template>
    <vg-modal v-if="maintenance" @close="$emit('close')">
        <template #header>
            <img src="/static/assets/icone/maintenance.png" alt="maintenance" class="refimg vg-margin-right-15">
            <span>{{ $t("detailFiche") }} {{ maintenance.id }}</span>
        </template>
        <template #body >
            <div class="detail-fichesav-modal">
                <div class="fichesav-description">
                    <vg-fichesav-description :fm="maintenance" showPiece />
                </div>
                <div class="fichesav-info">
                    <div>{{ $t("dateOuverture") }} :</div>
                    <div><vg-datetime-viewer v-model="maintenance.dateOuvertureSAV" /></div>
                </div>
                <div class="fichesav-info">
                    <div>{{ $t("origine") }}  :</div>
                    <div><vg-fichesav-origin :fm="maintenance" /></div>
                </div>
                <div class="fichesav-info" v-if="maintenance.affectation">
                    <div>{{ $t("affectation") }} </div>
                    <div v-for="affecte in maintenance.affectation.affectes">{{affecte.name}}</div>
                </div>
                <div class="fichesav-info">
                    <div>{{ $t("demandeur") }} </div>
                    <div>
                        <div v-if="maintenance.contact">{{maintenance.contact}}</div>
                        <div v-else>{{maintenance.prenom}} {{maintenance.nom}}</div>
                    </div>
                </div>
            </div>
            <br>
            <span style="color: #35b1ea;font-size: 16px;">{{$t("operations-collapse-title")}}</span>
            <vg-operations-viewer style="height:200px;"
                v-model="maintenance.operations"
                :fm="maintenance"
                :oneColumnView="true" />
            <br>
            <vg-files-viewer-photo
				:files="VgFilesMixins_filesOnlyPhoto" />
            <vg-fichesav-relance-modal v-if="showRelanceModal"
                @close="showRelanceModal=false;disabledRelance=false;"
                @save="showRelanceModal=false;"/>
        </template>
        <template #footer>
            <vg-button v-if="isStatutNotResolue" type="danger" @click="openRelance" :disabled="disabledRelance">
                {{ $t("relancer") }}
            </vg-button>
            <vg-button @click="$emit('close')" >
                {{ $t("fermer") }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgOperationsViewer from "src/components/Vg/Operation/VgOperationsViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import VgFichesavOrigin from "src/components/Vg/FicheSAV/VgFichesavOrigin.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgFichesavRelanceModal from "src/components/Vg/FicheSAV/VgFichesavRelanceModal.vue";

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

export default {
    name:"vg-fichesav-modal",
    props:{
        /**
         * Uniquement id de la Fiche de maintenance
         */
        idMaintenance:{
            type:String,
            required:true
        },
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "detailFiche": "Détail fiche n°",
            "dans": "dans",
            "dateOuverture": "Date ouverture",
            "origine": "Origine",
            "affectation": "Affectée à",
            "demandeur": "Demandeur",
            "feedbackOperation": "AUCUNE OPERATION",
            "fermer": "FERMER",
            "affect-to": "à",
            "affect-by": "par",
            "operations-collapse-title": "Historique",
            "relancer": "Relancer"
        },
        "en": {
            "detailFiche": "Details of sheet n°",
            "dans": "in",
            "dateOuverture": "Opening date",
            "origine": "Origin",
            "affectation": "Affected to",
            "feedbackOperation": "NO OPERATION",
            "fermer": "CLOSE",
            "demandeur": "Requester",
            "affect-to": "to",
            "affect-by": "by",
            "relancer": "Revive"
        },
        "th": {
            "detailFiche": "รายละเอียดของแผ่นงานหมายเลข",
            "dans": "ใน",
            "dateOuverture": "วันที่เปิด",
            "origine": "ต้นกำเนิด",
            "affectation": "ได้รับผลกระทบไปยัง",
            "feedbackOperation": "ไม่มีปฏิบัติการ",
            "fermer": "ปิด",
            "demandeur": "ผู้ร้องขอ",
            "affect-to": "ถึง",
            "affect-by": "โดย"
        }
    }
},
    mixins: [ MaintenanceMixins ],
    data: function(){
        return {
            disabledRelance: false,
            showRelanceModal: false,
        };
    },
    components:{

        VgFilesViewerPhoto,
        VgOperationsViewer,
        VgFichesavOrigin,
        VgFichesavDescription,
        VgButton,
        VgLieuPathViewer,
        VgDatetimeViewer,
        VgCollapse,
        VgFichesavRelanceModal

    },
    computed:{
        ...mapGetters({
              maintenance: 'MaintenancesStore/getSelectedMaintenance'
        }),
        isStatutNotResolue: function(){
            return this.maintenance.statut=="En_cours" || this.maintenance.statut=="prise_en_compte" || this.maintenance.statut=="en_attente";
        }
    },
    created:function(){
        this.fetch();
    },
    methods: {
        /**
         * requete sur la maintenance(operation comprise) et les photos
         */
        fetch:function(){
            this.MaintenanceMixins_getMaintenance(this.idMaintenance).then((datas)=>{
                this.VgFilesMixins_init(datas.uid, "ficheSAV");

            });
        },
        openRelance: function(){
            this.disabledRelance = true;
            this.showRelanceModal = true;
        }
    },
    beforeDestroy: function(){
        this.$store.dispatch("MaintenancesStore/deleteSelectedMaintenance");
    }
}
</script>

<style lang="scss" scoped>
.detail-fichesav-modal{
    .fichesav-info{
        display: flex;
        > div{
            margin-bottom: 10px;
            &:first-child {
                color: lightgray;
                width: 40%;
            }
        }
    }
}

.fichesav-description{
    margin-bottom: 15px;
    .fichesav-localisation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
}
</style>
