<template>
	<vg-input
		:title="$t('devise')"
		style="width:50%;"
		:autoGenerateHtmlInput="false">
		<select v-model="currency" style="width:50%">
			<option value="€">€ - EUR</option>
			<option value="$">$ - USD / AUD</option>
			<option value="£">£ - GBP</option>
			<option value="CHF">CHF</option>
			<option value="₨">₨ - MUR / SCR</option>
			<option value="฿">฿ - THB</option>
			<option value="¥">¥ - CNY / JPY</option>
			<option value="R">R - ZAR</option>
			<!--option value="kr">kr - NOK</option-->
		</select>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
    export default {
        name: 'vg-currency-selector',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "devise": "Devise"
        },
        "en": {
            "devise": "Currency"
        },
        "th": {
            "devise": "สกุลเงิน"
        }
    }
},
		components: {
			VgInput
		},
        props: {
			value: {
				type: String
			}
        },
		watch:{
			currency: function(newvalue){
				this.emitInput(newvalue);
			}
		},
        data: function() {
            return {
				currency: this.value
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			emitInput: function(newvalue){
				this.$emit("input", newvalue);
			}
		},
        computed: {

		}
    };

</script>
<style scoped>

</style>
