import { render, staticRenderFns } from "./VgDureeViewer.vue?vue&type=template&id=c553a5dc&scoped=true&"
import script from "./VgDureeViewer.vue?vue&type=script&lang=js&"
export * from "./VgDureeViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c553a5dc",
  null
  
)

export default component.exports