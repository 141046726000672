<template lang="html">
    <div :class="['consommable-quantite-cellrenderer', {'quantite-info': info, 'quantite-warning': warning}]">
        <span>{{getQuantite}}</span>
        <span v-if="!isNonDisponible || (isNonDisponible && getQuantite)">{{params.data.unite?params.data.unite:"u."}}</span>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    mixins:[LangMixins],
    props: {
        params: {
            type: Object,
        },
        /** attribut cible 
         * ex: stock.quantite 
        */
        attribut: {
            type: String,
            required: true
        },
        info: {
            type: Boolean,
            default: false
        },
        warning: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr":{
                
            }
        }
    },
    computed:{
        getQuantite: function(){
            if(this.isNonDisponible) return this.calculQuantiteNonDisponible;
            let attributs = this.attribut.split(".");
            let quantite = this.params.data;
            attributs.forEach((attr)=>quantite = quantite[attr]);
            return quantite;
        },
        isEnDemande: function(){
            return this.enDemande && this.params.data.quantite_demandee;
        },
        isDejaLivree: function(){
            return this.dejaLivree && this.params.data.quantite_livree;
        },
        isNonDisponible: function(){
            return this.attribut=="quantite_non_disponible";
        },
        calculQuantiteNonDisponible: function(){
            let consommable = this.params.data;
            if(consommable.quantite_demandee){
                let quantiteEnStock = Number(consommable.stock.quantite);
                let quantiteDemandee = Number(consommable.quantite_demandee);
                let quantiteLivree = consommable.quantite_livree?Number(consommable.quantite_livree):0;
                let quantiteRestanteDemandee = quantiteDemandee - quantiteLivree;
                if(quantiteEnStock<0) return quantiteRestanteDemandee;
                else if(quantiteEnStock<quantiteRestanteDemandee) return quantiteRestanteDemandee - quantiteEnStock;
                else return null;
            }else return null;
        }
    },
    created: function(){

    }
})
</script>

<style lang="scss" scoped>
.consommable-quantite-cellrenderer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.quantite-info{
    color: blue;
    font-weight: bold;
}
.quantite-warning{
    color: red;
    font-weight: bold;
}
</style>
