<template>
    <div v-if="filesToShow" class="files-cell-renderer">
        <div class="file" @click="openFirstFile">
            <i class="far fa-file-alt"></i>
            {{getFirstFileName}}
        </div>
        <div v-if="getFilesLength>1" class="more-file">
            + {{getFilesLength-1}}
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
            /**
            * array de documents
            */
			files: {
				type: Array,
				required: true
			}
		},
        components:{

        },
        watch:{
            files:{
                handler: function(values){
                    ////console.log("WATCH FILES", values);
                    this.filesToShow = values;
                }
            }
        },
        data:function(){
            return{
                filesToShow: this.files
            }
        },
        methods:{
            openFirstFile: function(){
				this.VgFilesMixins_getDownloadFileLink(this.filesToShow[0]).then((link)=> window.open(link, '_blank'));
			}
        },
        computed:{
            getFirstFileName: function(){
                return this.filesToShow[0].name;
            },
            getFilesLength: function(){
                return this.filesToShow.length;
            }
        }
    });
</script>

<style lang="scss" scoped>
.files-cell-renderer{
    display: flex;
    justify-content: flex-start;
    >*{
        font-size: 11px;
        border: 1px solid #ccc;
        padding: 0 5px;
    }
    .file{
        cursor: pointer;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
