<template>
    <div v-if="this.params.data.bonsDeCommandeEntite_id" class="bon-de-commande-entite-cell-render">
        {{params.data.bonsDeCommandeEntite.name}} <small>{{params.data.bonsDeCommandeEntite.immatriculation}}</small>
	</div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "bon-de-commande-entite-cell-render",
        mixins:[LangMixins],
        components: {

        },
        props: {
			params: {
                type: Object,
                required: true
            }
		},
        data: function(){
            return {

            };
        },
        created: function(){

        },
        methods: {

        },
        computed: {

        }
    });
</script>

<style lang="scss" scoped>
.bon-de-commande-entite-cell-render{

}
</style>
