<template>
	<vg-modal
		:title="isUpdateForm?$t('modal-title-update'):$t('modal-title-create')"
		:isSaveDisabled="isSaveDisabled"
		@close="$emit('close')">
		<template #body>
			<vg-input :title="$t('intitule')" isRequiredValue v-model="consommable.name" />
			<vg-input :title="$t('marque')" v-model="consommable.marque" />
			<vg-input :title="$t('refExterne')" v-model="consommable.refExterne" />
			<vg-input :title="$t('code')" >
				<vg-input v-model="consommable.code" :placeholder="$t('code')"/>
				<vg-button v-if="$vgutils.isMobile()" type="success" @click="showScanner=true;">
					{{$t("scanner-code-barres")}}
				</vg-button>
			</vg-input>
			<vg-consommable-tags-datalist v-model="consommable.nature"
				:label="$t('nature')"/> <!--Etiquette consommable/pièce-->
			<vg-input :title="$t('coutUnitaire',{devise:$app.devise})" v-model="consommable.coutUnitaire" :inputType="'financial'" />
			<div v-if="consommable" class="consommable-quantite">
				<vg-input :title="$t('stock')" isRequiredValue v-model="stock.quantite" :inputType="'float'" :disabled="isUpdateForm" style="width:20%;min-width:100px;" />
				<vg-input :title="$t('stockMin')" isRequiredValue v-model="stock.quantiteMin" :inputType="'float'" style="width:20%;min-width:100px;" />
				<vg-input :title="$t('unite')" isRequiredValue style="width:20%;min-width:100px;" >
					<vg-unite-selector v-model="consommable.unite" />
				</vg-input>
				<vg-input :title="$t('colisage')" style="width:40%;min-width:100px;">
					<div style="display: flex;justify-content: flex-start;gap:5px;align-items: baseline;">
						<vg-consommable-colisage-conditionnement-datalist v-model="consommable.conditionnementColisage" style="width:60%;min-width: 100px;"/>
						<span style="color:#9e9da9;">{{$t("de")}}</span>
						<vg-input v-model="consommable.quantiteColisage" :inputType="'number'" style="min-width:60px;"/>
					</div>
				</vg-input>
			</div>
			<vg-input :title="$t('tva')">
				<vg-tva-selector v-model="consommable.tva_id"/>
			</vg-input>
			<vg-input :title="$t('fournisseur-default')">
				<template #actions>
					<vg-button type="success-link" @click="showCreateTiers=true;">
						{{ $t("ajouter-tiers") }}
					</vg-button>
				</template>
				<vg-tiers-selector v-model="consommable.fournisseurDefault_id"/>
			</vg-input>
			<vg-input :title="$t('descriptif')" :inputType="'textarea'" v-model="consommable.commentaire" />
			<vg-input :title="$t('services')">
				<vg-service-selector v-model="services" multiple showAll/>
			</vg-input>
			<vg-collapse :title="$t('collapse-more')">
				<template #content>
					<vg-input :title="$t('autres-fournisseurs')" v-if="consommable && !consommable.id">
						<template #actions>
							<vg-button type="success-link" @click="showCreateTiers=true;">
								{{ $t("ajouter-tiers") }}
							</vg-button>
						</template>
						<vg-tiers-selector v-model="consommable.fournisseurs" multiple/>
					</vg-input>
					<vg-input :title="$t('statut')" >
						<vg-tags-selector v-model="consommable.statut"
							:type="'consommable/statut'"
							:attributeValue="'label'" />
					</vg-input>
					<!--vg-input :title="$t('product-group')" v-model="consommable.productGroup"/>
					<vg-input :title="$t('product-sub-group')" v-model="consommable.productSubGroup"/-->
				</template>
			</vg-collapse>
			<vg-tiers-form v-if="showCreateTiers" @close="showCreateTiers=false;" @created="afterCreateTiers"/>
			<vg-modal v-if="showScanner"
				:title="$t('scanner-code-barres')"
				@close="showScanner=false;">
				<template #body>
					<zxing-scanner
						:isDemo="false"
						@scanSuccess="onScannSuccess" />
				</template>
				<template #footer>
					<vg-button @click="showScanner=false;">
						{{$t('close')}}
					</vg-button>
				</template>
			</vg-modal>
		</template>
		<template #footer>
			<vg-button type="default-success" @click="$emit('close')">
				{{$t('close')}}
			</vg-button>
			<vg-button v-if="consommable.id" type="danger" @click="showDelete=true;">
				{{$t('delete')}}
			</vg-button>
			<vg-button v-if="consommable.id" type="danger" :disabled="isSaveDisabled" @click="saveConsommable">
				{{$t('update')}}
			</vg-button>
			<vg-button v-else type="success" :disabled="isSaveDisabled" @click="saveConsommable">
				{{$t('save')}}
			</vg-button>
			<dialog-secured-action v-if="showDelete"
				:validResponse="'1'"
				@save="deleteConsommable"
				@close="showDelete=false;">
				<template #header-title>
					{{$t('delete-consommable', {name: consommable.name})}}
				</template>
			</dialog-secured-action>
		</template>
	</vg-modal>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCollapse from "src/components/Vg/VgCollapse.vue";
	import DialogSecuredAction from "src/components/Vg/DialogSecuredAction.vue";
	import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
	import VgTvaSelector from "src/components/Vg/Selectors/VgTvaSelector.vue";
	import VgUniteSelector from "src/components/Vg/Utils/VgUniteSelector.vue";
	import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
	import VgTagsSelector from "src/components/Vg/Selectors/VgTagsSelector.vue";
	import VgConsommableColisageConditionnementDatalist from "src/components/Vg/Datalists/VgConsommableColisageConditionnementDatalist.vue";
	import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
	import VgConsommableTagsDatalist from 'src/components/Vg/Datalists/VgConsommableTagsDatalist.vue';
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import StocksMixins from "src/mixins/StocksMixins.js";
	import Metadatas from "src/services/Metadatas.js";
	import VgTiersForm from 'src/components/Vg/Forms/VgTiersForm.vue';

	import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'vg-consommable-form',
		mixins: [ ConsommablesMixins, StocksMixins ],
		components: {
			VgInput,
			VgButton,
			VgCollapse,
			DialogSecuredAction,
			VgServiceSelector,
			VgTvaSelector,
			VgUniteSelector,
			VgTiersSelector,
			VgTagsSelector,
			VgConsommableColisageConditionnementDatalist,
			zxingScanner,
			VgConsommableTagsDatalist,
			VgTiersForm
		},
        props: {
            value: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
                consommable: {
					name: null,
					refExterne: null,
					marque: null,
					commentaire: null,
					coutUnitaire: 0,
					fournisseurDefault_id: null,
					statut: null,
					unite: this.getDefaultUnit,
					tva_id: null,
					code: null,
					productGroup: null,
					productSubGroup: null,
					category: null,
					nature: null,
					saleRate: null,
					saleCost: null,
					saleDisc: null,
					saleAccount: null,
					expDtAppl: null,
					suppRateMand: null,
					purAccount: null,
					services: null,
					fournisseurs: [],
					quantiteColisage: null,
					conditionnementColisage: null
				},
				stock: {
					quantite: 1,
					quantiteMin:  0,
					depot_id: null
				},
				showScanner: false,
				showDelete: false,
				services: [],
				showCreateTiers: false
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-create": "Créer consommable",
            "modal-title-update": "Modifier consommable",
            "intitule": "Intitulé",
            "nature": "Etiquette consommable/pièce détachée",
            "refExterne": "Référence constructeur",
            "marque": "Marque",
            "coutUnitaire": "Coût unitaire par défaut en {devise}",
            "stock": "Quantité stock initial",
            "stockMin": "Quantité stock minimal",
            "fournisseur": "Fournisseur",
            "statut": "Statut",
            "descriptif": "Descriptif",
            "code": "Code-barre / QRcode",
            "tva": "TVA applicable",
            "services": "Services restreints",
            "collapse-more": "Informations complémentaires",
            "product-group": "Groupe",
            "product-sub-group": "Sous-groupe",
            "fournisseur-default": "Fournisseur par défaut",
            "autres-fournisseurs": "Autres Fournisseurs",
            "scanner-code-barres": "Scanner code-barre / QRcode",
            "close": "Fermer",
            "save": "Enregistrer",
            "update": "Modifier",
            "delete": "Supprimer",
            "delete-consommable": "Supprimer le consommable {name}?",
			"de": "de",
			"colisage": "Colisage",
			"quantite-colisage": "Qté",
			"ajouter-tiers": "Ajouter un fournisseur"
        },
        "en": {
            "modal-title-create": "Create spare part",
            "modal-title-update": "Update spare part",
            "intitule": "Naming",
            "nature": "Spare part label/part",
            "refExterne": "Manufacturer reference",
            "marque": "Brand",
            "coutUnitaire": "Default unit cost in {devise}",
            "stock": "Initial inventory quantity",
            "stockMin": "Minimum inventory quantity",
            "autres-fournisseurs": "Other Suppliers",
            "statut": "Status",
            "descriptif": "Description",
            "close": "Close",
            "save": "Update",
            "delete": "Delete",
            "delete-consommable": "Delete spare part {name}?",
			"de": "of",
			"colisage": "Packing",
			"quantite-colisage": "Qty",
			"ajouter-tiers": "Add a provider"
        }
    }
},
		watch: {
			value: function(value){
				if(this.value){
					this.consommable = Object.assign({}, this.consommable, this.value);
					this.services = this.consommable.services?this.consommable.services.split(";"):[];
				}
			}
		},
		created: function(){
			this.fetchDepots();
			if(this.value){
				this.consommable = Object.assign({}, this.consommable, this.value);
				this.stock.quantiteMin = this.consommable.stock.quantiteMin;
				this.services = this.consommable.services?this.consommable.services.split(";"):[];
			}
			if(this.selectedConsommable){
				this.consommable = Object.assign({}, this.consommable, this.selectedConsommable);
				this.stock.quantiteMin = this.consommable.stock.quantiteMin;
				this.services = this.selectedConsommable.services && this.selectedConsommable.services.length!=0?this.selectedConsommable.services.split(";"):[];
			}
		},
		mounted: function(){

		},
		methods: {
			afterCreateTiers: function(datas){
				this.setSelectedTier(datas[0]);
				this.showCreateTiers = false;
			},
			setSelectedTier:function(tier){
				this.consommable.fournisseurs.push(tier.id);
				this.consommable.fournisseurDefault_id = tier.id;
			},
			fetchDepots: function(){
	            return new Promise((resolve, reject)=>{
	                let metadatasDepots = new Metadatas();
	                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
						this.stock.depot_id = this.depotDefault.id;
	                    resolve(datas);
	                });
	            });
	        },
			saveConsommable: function(){
				if(this.consommable.id){
					let consommable = {
						id: this.consommable.id,
						name: this.consommable.name,
						refExterne: this.consommable.refExterne,
						marque: this.consommable.marque,
						commentaire: this.consommable.commentaire,
						coutUnitaire: this.consommable.coutUnitaire,
						fournisseurDefault_id: this.consommable.fournisseurDefault_id,
						statut: this.consommable.statut,
						unite: this.consommable.unite,
						tva_id: this.consommable.tva_id,
						code: this.consommable.code,
						productGroup: this.consommable.productGroup,
						productSubGroup: this.consommable.productSubGroup,
						nature: this.consommable.nature,
						stock:{
							id:this.consommable.stock.id,
							quantiteMin:this.stock.quantiteMin
						},
						services: this.services && this.services.length!=0?this.services.join(";"):null,
						conditionnementColisage: this.consommable.conditionnementColisage,
						quantiteColisage: this.consommable.quantiteColisage,
						userId: this.$app.appID
					};
					this.ConsommablesMixins_update(consommable).then((consommable)=>{
						this.$emit("updated", consommable);
					});
				}else{
					let consommable = Object.assign({}, this.consommable, {
						stock: this.stock,
						services: this.services && this.services.length!=0?this.services.join(";"):null
					});
					this.ConsommablesMixins_create([consommable]).then((datas)=>{
						this.$emit("save", datas);
					});
				}
			},
			deleteConsommable: function(){
				this.ConsommablesMixins_delete(this.consommable).then((consommable)=>{
					this.$emit("deleted", consommable);
				});
			},
			onScannSuccess: function(code){
				this.consommable.code = code;
				this.showScanner = false;
			},
		},
        computed: {
			...mapGetters({
	            consommables: 'ConsommablesStore/getCollection',
	            selectedConsommable: 'ConsommablesStore/getSelectedItem',
				depotDefault: "StocksStore/getDepotDefault",
	            depotFlottant: "StocksStore/getDepotFlottant"
	        }),
			getDefaultUnit: function(){
				if(this.$lang.toLowerCase()=="fr") return "u.";
				else return "unit";
			},
			isUpdateForm: function(){
				return this.consommable && this.consommable.id;
			},
			isSaveDisabled: function(){
				return !this.consommable.name || this.consommable.name.length==0 || this.stock.quantite==null || this.stock.quantiteMin==null || !this.stock.depot_id;
			}
		}
    };

</script>
<style scoped lang="scss">
.consommable-quantite{
	display:flex;
	justify-content:space-between;
	align-items:baseline;
	gap:10px;
}
@media only screen and (max-width: 1200px){
	.consommable-quantite{
		width: 100%;
		display:flex;
		justify-content:flex-start;
		align-items:baseline;
		gap:10px;
		flex-wrap: wrap;
	}
}
</style>
