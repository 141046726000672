//VgTree store module

// initial state
const state = {
    datas: [
        /*{id: 1, libel_lieu:"Organisation 1", children: [
            {id:2, libel_lieu:"Site 1", parents: [1], children:[{id:4,libel_lieu:"Batiment A", parents:[1,2]}]},
            {id:3, libel_lieu:"Site 2", parents: [1]}
        ]}*/
    ],
    nodePending: null,
    isCollapseAll: false, // collapse tous les nodes de l'Arborescence
    nodeSelected: null
};

// getters
const getters = {
    getDatas: function( state ){
        return state.datas;
    },
    getNodePending: function( state ){
        return state.nodePending;
    },
    getIsCollapseAll: function( state ){
        return state.isCollapseAll;
    },
    getNodeSelected: function( state ){
        return state.nodeSelected;
    }
};

// actions
const actions = {
    updateDatas: function( context, datas ){
        context.commit('UPDATE_DATAS', datas);
    },
    updateNodePending: function( context, nodePending ){
        context.commit('UPDATE_NODE_PENDING', nodePending);
    },
    updateIsCollapseAll: function( context, isCollapseAll ){
        context.commit('UPDATE_IS_COLLAPSE_ALL', isCollapseAll);
    },
    updateNodeSelected: function( context, nodeSelected ){
        context.commit('UPDATE_NODE_SELECTED', nodeSelected);
    }
};

// mutations
const mutations = {
    UPDATE_DATAS: function( state, datas ){
        state.datas = datas;
    },
    UPDATE_NODE_PENDING: function( state, nodePending ){
        state.nodePending = nodePending;
    },
    UPDATE_IS_COLLAPSE_ALL: function( state, isCollapseAll ){
        state.isCollapseAll = isCollapseAll;
    },
    UPDATE_NODE_SELECTED: function( state, nodeSelected ){
        state.nodeSelected = nodeSelected;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
