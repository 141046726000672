<template>
	<vg-modal
		theme="without-header"
		@close="$emit('close')">
		<template #body>
			<span>{{$t("msg-fin-demo")}}</span>
		</template>
		<template #footer>
			<vg-button type="default-success" @click="$emit('close')">{{$t("fermer")}}</vg-button>
			<vg-button type="success" @click="openLink">{{$t("prendre-rdv")}}</vg-button>
			<vg-button type="success" @click="openContact">{{$t("nous-contacter")}}</vg-button>
		</template>
	</vg-modal>
</template>
<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";

    export default {
        name: 'vg-fin-demo-modal',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "msg-fin-demo": "Votre période d'essai est expirée",
			"prendre-rdv": "Planifier une présentation personnalisée",
			"nous-contacter": "Nous contacter",
			"fermer": "Fermer"
        },
        "en": {
			"msg-fin-demo": "Your trial period has expired",
			"prendre-rdv": "Schedule a custom presentation",
			"nous-contacter": "Contact us",
			"fermer": "Close"
        }
	}
},
		components: {
			VgModal,
			VgButton
		},
        props: {
			
        },
        data: function() {
            return {
				
            };
        },
		methods: {
			openLink: function(){
				window.open("https://www.verifgood.io/rdv", "_blank");
			},
			openContact: function(){
				window.open("https://www.verifgood.io/contact", "_blank");
			}
		},
		computed: {
			
		}
    };

</script>
<style lang="scss" scoped>

</style>
