<template lang="html">
    <div class="consommable-cellrenderer">
        <div class="files-image">
            <vg-files-thumbnail v-if="document && document.name"
                :file="file"
                :heightImg="'60px'">
            </vg-files-thumbnail>
            <vg-files-uploader v-else
                :isOnlyPhoto="true"
                :uidToUpload="params.data.uid"
                @upload-done="$emit('refresh')">
            </vg-files-uploader>
        </div>

        <div class="row-1">
            <span style='font-size: 15px; font-weight: bold;'> {{params.data.name}} </span>
        </div>
        <div class="row-2">
            <span class="label-row">{{$t("label-coutunitaire")}}:  </span>
            <span  class="data-row"> {{params.data.coutUnitaire}} </span>
        </div>
        <div class="row-2">
            <span class="label-row">{{$t("label-marque")}} :  </span>
            <span class="data-row"> {{params.data.marque}} </span>

        </div>
        <div class="row-2">
            <span class="label-row">{{$t("label-quantite")}} :  </span>

            <span class="data-row"> {{params.data.quantite}} </span>
        </div>

    </div>
</template>

<script>
import Vue from "vue";
import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    mixins:[LangMixins],
    props: {
        params: {
            type: Object,
        },
        document: {
            type: Object,
            default: function(){ return null; }
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr":{
                "label-coutunitaire":"Cout unitaire",
                "label-marque":"Marque",
                "label-quantite":"Quantité"
            }
        }
    },
    components:{
        VgFilesThumbnail,
        VgFilesUploader
    },
    computed:{
        file: function(){
            return {
                uploadedTo: this.params.data.uid,
                name: this.document.name
            };
        }
    },
    created: function(){
        this.VgFilesMixins_init(this.params.data.uid, "consommable");
    }
})
</script>

<style lang="scss">
.consommable-cellrenderer{
    height: 100%;
    padding:5px;
    border-bottom: 1px solid #cccccc;
    .row-1{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .row-2{
        font-size: 13px;
        .label-row{
            color:#838383;
        }
        .data-row{
            color:#000000;
            font-weight: bold;

        }
    }
    .files-image{
        float: right;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .files-only-image{
        // juste pour supprimer le texte "photo" pour avoir juste le bouton avec la photo
        margin-right: 20px;
        label{
            span{
                display: none !important;
            }
        }
    }
}
</style>
