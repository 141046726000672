<template>
    <span v-if="params.data.hasOwnProperty('dateprochaineVerif')">{{ $t("prochaine-date-butoir") }} <vg-datetime-viewer v-model="params.data.dateprochaineVerif" hideTime/></span>
    <span v-else-if="params.data.hasOwnProperty('prochaineIntervention')">{{ $t("prochaine-visite-le") }} <b><vg-datetime-viewer v-model="params.data.prochaineIntervention.datePrevisionnelleDebut"/></b></span>
    <span v-else>{{ $t("aucune-programmation") }}</span>
</template>
<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
export default Vue.extend({
    name:"date-previsionnelle-cell-render",
    mixins:[LangMixins],
    components:{
        VgDatetimeViewer
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "prochaine-date-butoir": "prochaine date butoir",
            "prochaine-visite-le": "prochaine visite",
            "aucune-programmation": "Aucune date"
        }
    }
},
    methods:{
        
    },
    computed:{
        
    }
});
</script>

<style lang="scss" scoped>

</style>
