<template lang="html">
    <div class="etage-mobile-cell-render">
        <div class="main">
            <vg-lieu-path-viewer v-model="params.data.path"
                :showTooltip="false"
                onlyPiece
                class="libel-piece"/>
            <vg-lieu-path-viewer v-model="params.data.path"
                class="start-path"
                :showTooltip="false"
                onlyPath />
        </div>
        <vg-button type="info"
            size="sm"
            @click="openPlanInteractif">
            {{$t("voir-plan-interactif")}}
        </vg-button>
    </div>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name: "etage-mobile-cell-render",
    mixins:[LangMixins],
    components:{
        VgButton,
        VgLieuPathViewer,
        VgDatetimeViewer
    },
    props: {
        params: {
            type: Object,
        },
    },
    i18n:    { "locale":navigator.language,
"messages": {
    "fr": {
        "voir-plan-interactif": "Consulter plan interactif"
    },
    "en": {
        "voir-plan-interactif": "View interactive level plan"
    }
}
},
    methods: {
        openPlanInteractif: function(){
            this.$router.push({ name: "_plan_interactif_etage", params: { etage: this.params.data.id } });
        }
    }
})
</script>

<style lang="scss" scoped>
.etage-mobile-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    .main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }
}
</style>
