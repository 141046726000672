
<template>
	<div class="parametres-bon-de-commande">
		<span class="title">{{$t('title')}}</span>
		<vg-collapse
			:title="$t('collapse-title-modele-numero')"
			:collapseByDefault="false">
			<template #content>
				<vg-bons-de-commande-parametrage-numero />
			</template>
		</vg-collapse>
		<br>
		<vg-collapse
			:title="$t('collapse-title-configuration-pdf')"
			:collapseByDefault="false">
			<template #content>
				<vg-checkbox :label="$t('mettre-noms-validateurs-sur-pdf')"
					:defaultChecked="showValidationsSurPDF"
					@checked="onCheckMettreValidateursSurPDF(true)"
					@unchecked="onCheckMettreValidateursSurPDF(false)"/>
			</template>
		</vg-collapse>
		<br>
		<vg-collapse
			:title="$t('collapse-title-configuration-entites')"
			:collapseByDefault="false">
			<template #content>
				<vg-bon-de-commande-entite-parametrage />
			</template>
		</vg-collapse>
		<br>
		<vg-collapse
			:title="$t('collapse-title-process-validation-interne')"
			:collapseByDefault="false">
			<template #content>
				<vg-groupe-validateurs-form />
			</template>
		</vg-collapse>
		<br>
		<vg-collapse
			:title="$t('collapse-title-statuts-livraison')"
			:collapseByDefault="false">
			<template #content>
				<div style="display:flex;flex-direction:column;justify-content:flex-start;">
					<span style="border:1px solid grey;width:150px;padding:2px 5px;text-align:center;margin-bottom:10px;">{{$t("statut-livraison-non-livre")}}</span>
					<span style="border:1px solid grey;width:150px;padding:2px 5px;text-align:center;margin-bottom:10px;">{{$t("statut-livraison-partielle")}}</span>
					<span style="border:1px solid grey;width:150px;padding:2px 5px;text-align:center;">{{$t("statut-livraison-complete")}}</span>
				</div>
			</template>
		</vg-collapse>
		<br>
		<!--vg-collapse
			:title="$t('collapse-title-statuts-paiement')"
			:collapseByDefault="false">
			<template #content>
				<vg-bons-de-commande-parametrage-paiement/>
			</template>
		</vg-collapse>
		<br-->
		<vg-collapse
			:title="$t('collapse-title-taux-tva')"
			:collapseByDefault="false">
			<template #content>
				<vg-tva-form />
			</template>
		</vg-collapse>
	</div>
</template>

<script>
	import TagsMixins from "src/mixins/TagsMixins.js";

	import VgCollapse from "src/components/Vg/VgCollapse.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

	import VgBonDeCommandeEntiteParametrage from "src/components/Vg/BonDeCommandeEntite/VgBonDeCommandeEntiteParametrage.vue";
	import VgBonsDeCommandeParametrageNumero from "src/components/Vg/BonDeCommande/VgBonsDeCommandeParametrageNumero.vue";
	import VgBonsDeCommandeParametragePaiement from "src/components/Vg/BonDeCommande/VgBonsDeCommandeParametragePaiement.vue";
	import VgTvaForm from "src/components/Vg/Forms/VgTvaForm.vue";
	import VgGroupeValidateursForm from "src/components/Vg/Forms/VgGroupeValidateursForm.vue";
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";

	import ParametresMixins from "src/mixins/ParametresMixins.js";

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

    export default {
        name: 'parametres-bons-de-commande',
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Personnalisation bons de commande",
            "collapse-title-process-validation-interne": "Processus de validation/approbation interne",
            "collapse-title-statuts-livraison": "Les statuts livraison",
            "collapse-title-statuts-paiement": "Les statuts paiement",
            "collapse-title-taux-tva": "Les taux de TVA",
            "statut-livraison-non-livre": "Non livré",
            "statut-livraison-partielle": "Livraison partielle",
            "statut-livraison-complete": "Livraison complète",
            "collapse-title-modele-numero": "Modèle numéro bon de commande",
            "collapse-title-configuration-pdf": "Configuration PDF",
            "collapse-title-configuration-entites": "Entités qui passent la commande",
            "mettre-noms-validateurs-sur-pdf": "Afficher les noms des validateurs du bon de commande sur le PDF"
        },
        "en": {
            "title": "Customise purchase orders"
        }
    }
},
        components: {
			VgCollapse,
			VgCheckbox,
			VgBonsDeCommandeParametragePaiement,
			VgBonsDeCommandeParametrageNumero,
			VgTvaForm,
			VgGroupeValidateursForm,
			VgCredits,
			VgBonDeCommandeEntiteParametrage
        },
		mixins:[ ParametresMixins ],
        data: function() {
            return {
				showValidationsSurPDF: this.$app.showValidationsInPdf
            };
        },
        methods: {
			onCheckMettreValidateursSurPDF: function(mettreLesValidateurs){
				let parameter = {
					showValidationsInPdf: mettreLesValidateurs
				};
				this.ParametresMixins_update(parameter).then((datas)=>{
					this.showValidationsSurPDF = mettreLesValidateurs;
					let sessionUser = JSON.parse(window.sessionStorage.getItem('user'));
                    sessionUser = Object.assign({}, sessionUser, parameter);
                    window.sessionStorage.setItem('user', JSON.stringify(sessionUser));
				});
			},
        },
        computed: {
            ...mapGetters({

            })
        },
		created: function(){

		}
    };
</script>
<style lang="scss" scoped>
.parametres-bon-de-commande{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
}
</style>
