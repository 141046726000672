<template>
	<div class="vg-dashboard-curatif-equipements-ratios"
		style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;">
		<div style="width:100%;display:flex;justify-content:space-between;align-items:center;">
			<span>{{$t("title")}}</span>
			<vg-pagination-limit-selector v-if="limit"
				:text="$t('limit-selector-text')"
				@input="onChangeLimit" />
		</div>
		<div style="margin-top:0px;width:100%;height:100%;color:#3999ff;text-align:center;display:flex;justify-content:center;align-items:center;">
			Bientôt disponible
		</div>
	</div>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-equipements-ratios',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas()
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Les équipements ratio maintenance/cout éleves",
            "limit-selector-text": "affichés"
        }
    }
},
		watch:{

		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			...mapGetters({
	            //equipementsRatios: 'DashboardStore/getCuratifEquipementsRatios',
	        })
		}
    };

</script>
<style lang="scss" scoped>
.vg-dashboard-curatif-equipements-ratios{
	border: 1px solid #cccccc;
	padding:10px 20px;
}
</style>
