<template>
    <div v-if="isDebutFinSameDate">
        <vg-datetime-viewer v-model="start">
        </vg-datetime-viewer> - <small v-if="end">{{getEndTime}}</small>
    </div>
    <div v-else>
        <vg-datetime-viewer v-model="start" /> - <vg-datetime-viewer v-model="end" />
    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import moment from "moment";
    export default Vue.extend({
        name: "datetime-intervalle-cell-render",
        mixins:[LangMixins],
		props: {
			start: {
				type: String,
				required: true
			},
			end: {
				type: String,
				required: true
			}
		},
        components: {
            VgDatetimeViewer
        },
        computed:{
            isDebutFinSameDate: function(){
                return moment(this.start).format("YYYY-MM-DD")==moment(this.end).format("YYYY-MM-DD");
            },
            getEndTime: function(){
                return moment(this.end).format("HH:mm");
            }
        }
    });
</script>

<style scoped>

</style>
