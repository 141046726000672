<template>
	<vg-modal ref="mainContainer"
		:title="$t('modal-title')"
		@close="$emit('close')"
		@save="$emit('save')">
		<template #body>
			<vg-text-filter v-model="searchString" ></vg-text-filter>
			<vg-consommable-card v-for="conso in consommablesComputed"
				:consommable="conso"
				:key="conso.id">
				<div slot="complement">
					<vg-button v-if="!conso.equipement_id" @click="handleAttacherConsommableEquipement(conso)">
						<i class="fas fa-link"></i> {{$t("button-attacher")}}
					</vg-button>
					<vg-button v-else :type="'default-danger'" @click="handleDetacherConsommableEquipement(conso)">
						<i class="fas fa-unlink"></i> {{$t("button-detacher")}}
					</vg-button>
				</div>
			</vg-consommable-card>
		</template>
		<template #footer>
			<vg-button
				type="default"
				@click="$emit('close')"
				>
				{{$t("modal-footer-button-fermer")}}
			</vg-button>
	  	</template>
	</vg-modal>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgConsommableCard from "src/components/Vg/Consommables/VgConsommableCard.vue";
	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";

    export default {
        name: 'vg-consommables-equipement-modal',
		components: {

			VgButton,
			VgConsommableCard,
			VgTextFilter
		},
		mixins:[
			ConsommablesMixins
		],
        props: {
            value: Array,
			idEquipement:{
				type: String|Number,
				required: true
			}
        },
        data: function() {
            return {
				searchString: '',
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Attacher/détacher consommables à l'équipement",
            "button-attacher": "Attacher",
            "button-detacher": "Détacher",
            "consommable-detacher-title": "Voulez-vous détacher ce consommable de équipement?",
            "modal-footer-button-fermer": "Fermer"
        },
        "en": {
            "modal-title": "Attach/detach consumable to the equipment",
            "button-attacher": "Attach",
            "button-detacher": "Detach",
            "consommable-detacher-title": "Would you like to detach this consumable from the equipment?",
            "modal-footer-button-fermer": "Close"
        },
        "th": {
            "modal-title": "แนบ/ไม่แนบวัสดุสิ้นเปลืองไปยังอุปกรณ์",
            "button-attacher": "แนบ",
            "button-detacher": "ไม่แนบ",
            "consommable-detacher-title": "ต้องการไม่แนบวัสดุสิ้นเปลืองนี้ไปยังอุปกรณ์?",
            "modal-footer-button-fermer": "ปิด"
        }
    }
},
		watch: {

		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			handleDetacherConsommableEquipement: function(consommable){
				if( confirm(this.$t("consommable-detacher-title")) ){
					this.ConsommablesMixins_removeConsommablesEquipements(consommable.id, consommable.equipement_id).then((data)=>this.$emit("refresh-consommables"));
				}
			},
			handleAttacherConsommableEquipement: function(consommable){
				this.ConsommablesMixins_createConsommablesEquipements([{
					consommable_id: consommable.id,
					equipement_id: this.idEquipement
				}]).then((datas)=>this.$emit("refresh-consommables"));
			}
		},
        computed: {
			consommablesComputed: function(){
				return this.value.filter((conso)=>{
					return (conso.name && conso.name.toLowerCase().search(this.searchString.toLowerCase())!=-1)
						|| (conso.marque && conso.marque.toLowerCase().search(this.searchString.toLowerCase())!=-1)
						|| (conso.commentaire && conso.commentaire.toLowerCase().search(this.searchString.toLowerCase())!=-1)
						|| (conso.refExterne && conso.refExterne.toLowerCase().search(this.searchString.toLowerCase())!=-1)
						|| (conso.numSerie && conso.numSerie.toLowerCase().search(this.searchString.toLowerCase())!=-1);
				});
			},
		}
    };

</script>
<style scoped>

</style>
