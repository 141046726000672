//Equipements store module

import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
getters.getCategoriesLieux = function(state){
    return state.collection?state.collection.filter((categorie)=>categorie.isGe==true || categorie.isGe==="1"):[];
};
getters.getCategoriesEquipements = function(state){
    return state.collection?state.collection.filter((categorie)=>categorie.isGe==false || categorie.isGe==="0"):[];
};
getters.getSelectedCategorieMarques = function(state){
    if(state.selectedItem && state.selectedItem.marque && state.selectedItem.marque.length!=0) return state.selectedItem.marque.split(";");
    else return [];
};
let actions = Base.getActions();
/**
* @param Object value {categorie_id: <idCategorie>, counter: <counterName>}
*/
actions.incrementCategorieCounter = function( context, value ){
    context.commit('INCREMENT_CATEGORIE_COUNTER', value);
};
/**
* @param Object value {categorie_id: <idCategorie>, counter: <counterName>}
*/
actions.decrementCategorieCounter = function( context, value ){
    context.commit('DECREMENT_CATEGORIE_COUNTER', value);
};
let mutations = Base.getMutations();
mutations.INCREMENT_CATEGORIE_COUNTER = function(state, value){
    let idCategorie = value.categorie_id;
    let indexCategorie = state.collection.findIndex((categorie)=>categorie.id==idCategorie);
    if(indexCategorie!=-1){
        state.collection[indexCategorie][value.counter] = Number(state.collection[indexCategorie][value.counter])+1;
    }
};
mutations.DECREMENT_CATEGORIE_COUNTER = function(state, value){
    let idCategorie = value.categorie_id;
    let indexCategorie = state.collection.findIndex((categorie)=>categorie.id==idCategorie);
    if(indexCategorie!=-1){
        state.collection[indexCategorie][value.counter] = Number(state.collection[indexCategorie][value.counter])-1;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
