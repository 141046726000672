<template>
    <span>{{getDifference}} {{$t(mesure)}}</span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "from-date-cell-render",
        mixins:[LangMixins],
		props: {
			value: {
				type: String,
				required: true
			},
            /**
            * years, months, weeks, days, hours, minutes, and seconds
            */
            mesure: {
                type: String,
                default: "days"
            },
            /**
            * date de reference pour la comparaison par default date now
            */
            reference: {
                type: String,
                default: null
            }
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "years": "an(s)",
            "months": "mois",
            "weeks": "semaine(s)",
            "days": "jour(s)",
            "hours": "heure(s)",
            "minutes": "minute(s)",
            "seconds": "seconde(s)"
        },
        "en": {
            "years": "year(s)",
            "months": "month",
            "weeks": "week(s)",
            "days": "day(s)",
            "hours": "hour(s)",
            "minutes": "minute(s)",
            "seconds": "seconde(s)"
        },
        "th": {
            "years": "ปี",
            "months": "เดือน",
            "weeks": "สัปดาห์",
            "days": "วัน",
            "hours": "ชั่วโมง",
            "minutes": "นาที",
            "seconds": "วินาที"
        }
    }
},
        computed: {
            getDifference: function(){
                if(!this.reference) return moment().diff(this.value, this.mesure);
                else return moment(this.reference).diff(this.value, this.mesure);
            }
        }
    });
</script>

<style scoped>

</style>
