<template>
    <div class="checkpoint-reponse">
        <img v-if="params.data.composant_id" :src="params.data.icon" style="width:20px;height:20px;"/>
        <span>{{params.data.question}}</span>
        <small v-if="params.data.composant_id" class="complement">#{{params.data.typeComposant}}</small>
        <span style="color: red;">{{params.data.reponse}}</span>
        <img v-if="!params.data.reponse || params.data.reponse=='Oui'" src="/static/assets/icone/verif-conforme.png"  style="width:20px;height:20px;"/>
        <img v-else src="/static/assets/icone/verif-non-conforme.png"  style="width:20px;height:20px;"/>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "verification-checkpoint-reponse-cell-render",
        mixins:[LangMixins],
        components:{

        },
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
    });
</script>

<style lang="scss" scoped>
.checkpoint-reponse{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
}
</style>
