<template>
    <div>
        <vg-app-layout v-if="site"
           :title="site.libel_lieu"
           :icon="'lieux.png'"
           :colorheader="getHeaderColor"
           :isZoomLayout="true"
           :isActionButtonDisplay="false"
           @backpage="handleBackPage">
           <template v-slot:create-button>
                <vg-button
                    type="default-danger"
                    @click="isUpdateLieuModalOpened = true">
                    {{ $t("modifier") }}
                </vg-button>
               <vg-button type="success"
                   @click="showTirroirDePlan = true">
                   {{$t("voir-plans")}}
               </vg-button>
   		</template>
           <template v-slot:primary v-if="!$vgutils.isMobile()">
               <vg-collapse :title="$t('details')" :type="'section'" :collapseByDefault="false" >
                   <template v-slot:pretitle>
                       
                   </template>
                   <template v-slot:content>
                       <vg-libelle-editable :label="$t('adresse')">
                           <template v-slot:read> {{site.adresse}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable v-if="site.adresse && site.adresse.length">
                           <template v-slot:read>
                               <div class="mapouter">
                                   <div class="gmap_canvas">
                                       <iframe width="241" height="243" id="gmap_canvas" :src="emplacementMapDuSite" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                       <a href="https://embedgooglemap.net/142/">its</a>
                                   </div>
                               </div>
                           </template>
                       </vg-libelle-editable>

                       <vg-libelle-editable :label="$t('reference-cadastrale')">
                           <template v-slot:read> {{site.referenceCadastrale}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('surface-hors-oeuvre-brut')">
                           <template v-slot:read> {{site.surfaceHorsOeuvreBrut}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('surface-parcelle')">
                           <template v-slot:read> {{site.surfaceParcelle}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('type-erp')">
                           <template v-slot:read> {{site.typeErp}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('categorie-erp')">
                           <template v-slot:read> {{site.categorieErp}}</template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('capacite-accueil')">
                           <template v-slot:read> {{site.capaciteAccueil}}</template>
                       </vg-libelle-editable>
                   </template>

               </vg-collapse>

               <vg-collapse :title="$t('gestion-calendaire')" :type="'section'" :collapseByDefault="false" >
                   <template v-slot:pretitle>
                       <vg-button
                           size="md"
                           type="default-round"
                       >
                           <i class="far fa-calendar"></i>
                       </vg-button>
                   </template>
                   <template v-slot:content>
                       <vg-libelle-editable :label="$t('date-mise-en-service')">
                           <template v-slot:read>
                               <vg-datetime-viewer
                                   v-model="site.dateMiseEnService">
                               </vg-datetime-viewer>
                           </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('date-prochaine-commission-securite')">
                           <template v-slot:read>
                               <vg-datetime-viewer
                                   v-model="site.dateProchaineCommissionDeSecurite">
                               </vg-datetime-viewer>
                            </template>
                       </vg-libelle-editable>
                       <vg-libelle-editable :label="$t('frequence-commission-securite')">
                           <template v-slot:read> {{site.periodiciteCommissionSecurite}}</template>
                       </vg-libelle-editable>
                   </template>
               </vg-collapse>

               <vg-collapse :title="$t('documents')" :type="'section'" :collapseByDefault="false" >
                   <template v-slot:pretitle>
                       <vg-button
                           size="md"
                           type="default-round"
                           @click="isUploaderDisplayed=!isUploaderDisplayed;">
                           <i class="fas fa-plus"></i>
                       </vg-button>
                   </template>
                   <template v-slot:content>
                       <vg-files-uploader
                            v-show="isUploaderDisplayed"
                           :tagsList="VgFilesMixins.tags"
                           @upload-done="VgFilesMixins_fetchFilesByUid();" >
                       </vg-files-uploader>
                       <vg-files-viewer-list-section
                           :files="VgFilesMixins_filesListSection"
                           @delete="VgFilesMixins_fetchFilesByUid()">
                       </vg-files-viewer-list-section>
                   </template>
               </vg-collapse>

           </template>
           <template v-slot:secondary v-if="!$vgutils.isMobile()">
                <vg-tabs v-model="focusedTab"
                    :tabs="tabs"
                    :color="'gris'"
                    :type="'pane'" />
                <br>
                <div v-if="focusedTab=='associees'">
                    <vg-collapse
                        :title="$t('lieux-generiques')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:titlecomplement>
                            <vg-button
                                :size="'xs'"
                                :type=" 'info' "
                                @click="showPieceGeneriqueForm=true">
                                <i class="fas fa-plus"></i>
                            </vg-button>
                        </template>
                        <template v-slot:content>
                            <vg-lieux-table
                                class="height-300"
                                :filters="lieuxFilters"
                                @row-click="handleClickRow"
                                @row-dbl-click="handleDoubleClickRow">
                            </vg-lieux-table>
                        </template>
                    </vg-collapse>
                    <br>
                    <vg-collapse
                        :title="$t('equipements')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:titlecomplement>
                            <vg-button v-if="pieceEquipementsGlobaux"
                                :size="'xs'"
                                :type="'info'"
                                @click="showModalCreateEquipementGlobal=true">
                                <i class="fas fa-plus"></i>
                            </vg-button>
                            <vg-button v-else
                                :size="'sm'"
                                :type="'info'"
                                style="width:200px;"
                                @click="onCreateContainerEquipementsGlobaux">
                                {{$t("creer-piece-generique")}}
                            </vg-button>
                        </template>
                        <template v-slot:content>
                            <vg-tabs
                                :tabs="equipementsTabs"
                                :color="'gris'"
                                @tabClicked="onClickEquipementsTabs">
                            </vg-tabs>
                            <vg-equipements-table v-if="!showModalCreateEquipementGlobal"
                                class="height-300"
                                :filters="equipementsFilters">
                            </vg-equipements-table>
                        </template>
                    </vg-collapse>
                </div>
                <div v-if="focusedTab=='curatif'">
                    <vg-collapse
                        :title="$t('maintenances')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-tabs
                                :tabs="maintenancesTabs"
                                :color="'jaune'"
                                @tabClicked="onClickMaintenancesTabs">
                            </vg-tabs>
                            <vg-fichesav-table
                                class="height-300"
                                :filters="maintenancesFilters">
                            </vg-fichesav-table>
                        </template>
                    </vg-collapse>
                    <vg-collapse
                        :title="$t('statistiques')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-statistiques-maintenance-dashboard
                                :filters="statistiquesMaintenanceFilters"
                                :showLeftPanel="false">
                            </vg-statistiques-maintenance-dashboard>
                        </template>
                    </vg-collapse>
                </div>
                <div v-if="focusedTab=='preventif-interne'">
                    <vg-collapse
                        :title="$t('verifications')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-verifications-viewer
                                class="height-300"
                                :filters="verificationsFilters">
                            </vg-verifications-viewer>
                        </template>
                    </vg-collapse>
                    <br>
                    <vg-collapse
                        :title="$t('statistiques')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-statistiques-verification-dashboard
                                :filters="statistiquesVerificationFilters"
                                :showLeftPanel="false">
                            </vg-statistiques-verification-dashboard>
                        </template>
                    </vg-collapse>
                </div>
                <div v-if="focusedTab=='preventif-prestataire'">
                    <vg-collapse
                        :title="$t('contrats')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:titlecomplement>
                            <vg-button
                                :size="'xs'"
                                :type=" 'info' "
                                @click="isContratFormOpened=true">
                                <i class="fas fa-plus"></i>
                            </vg-button>
                        </template>
                        <template v-slot:content>
                            <vg-contrats-table
                                class="height-300"
                                :filters="contratsFilters"
                                @row-click="handleClickRow"
                                @row-dbl-click="handleDblClickContrat">
                            </vg-contrats-table>
                        </template>
                    </vg-collapse>
                    <br>
                    <vg-collapse
                        :title="$t('interventions-periodiques')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:titlecomplement>
                            <vg-button
                                :size="'sm'"
                                :type="'default-info'"
                                style="width:200px;"
                                @click="isInterventionsVueCalendrier=!isInterventionsVueCalendrier">
                                {{isInterventionsVueCalendrier?$t("vision-tableau"):$t("vision-calendrier")}}
                            </vg-button>
                        </template>
                        <template v-slot:content v-if="!isInterventionsVueCalendrier">
                            <vg-tabs
                                :tabs="interventionsTabs"
                                :color="'bleu'"
                                @tabClicked="onClickInterventionsTabs">
                            </vg-tabs>
                            <vg-interventions-table
                                class="height-300"
                                :agFilters="interventionsFilters"
                                :localFilters="interventionsLocalFilters"
                                :hiddenColumns="['contrat.name','tiers.name','cout']"
                                :notShowableColumns="['cout']"
                                isPeriodiques>
                            </vg-interventions-table>
                        </template>
                        <template v-slot:content v-else>
                            <vg-calendar-dashboard
                                :filters="interventionsCalendarFilters"
                                :mode="'preventive'">
                            </vg-calendar-dashboard>
                        </template>
                    </vg-collapse>
                    <br>
                    <vg-collapse
                        :title="$t('reserves')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-tabs
                                :tabs="reservesTabs"
                                :color="'dark'"
                                @tabClicked="onClickReservesTabs">
                            </vg-tabs>
                            <vg-fichesav-table
                                class="height-300"
                                :filters="reservesFilters"
                                :isReserves="true">
                            </vg-fichesav-table>
                        </template>
                    </vg-collapse>
                    <br>
                    <vg-collapse
                        :title="$t('tiers')"
                        :type="'section'"
                        :collapseByDefault="false">
                        <template v-slot:content>
                            <vg-tiers-table v-if="site && !showModalCreateEquipementGlobal"
                                class="height-300"
                                :filters="tiersFilters">
                            </vg-tiers-table>
                        </template>
                    </vg-collapse>
                </div>
                <br>
                <vg-collapse :title="$t('section-photos')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #content>
                        <vg-files-viewer-photo
                            :files="VgFilesMixins_filesOnlyPhoto" />
                    </template>
                </vg-collapse>
                <vg-files-plans
                    v-if="showTirroirDePlan"
                    :defaultFilters="{Site:site.libel_lieu,lieu:site}"
                    @close="showTirroirDePlan=false">
                </vg-files-plans>
                <vg-lieu-form
                     v-if="isUpdateLieuModalOpened"
                     v-model="site"
                     @close="isUpdateLieuModalOpened = false"
                     @updated="isUpdateLieuModalOpened = false">
                </vg-lieu-form>
                <vg-pieces-generique-form
                     v-if="showPieceGeneriqueForm"
                     :siteId="$route.params.id"
                     @close="showPieceGeneriqueForm = false"
                     @save="showPieceGeneriqueForm = false">
                </vg-pieces-generique-form>
                <vg-contrat-form
                    v-if="isContratFormOpened"
                    v-model="contrat"
                    @close="isContratFormOpened = false"
                    @created="isContratFormOpened = false"
                    @updated="isContratFormOpened = false"
                    disabledRestrictionLieu>
                </vg-contrat-form>
                <vg-equipement-form v-if="showModalCreateEquipementGlobal && pieceEquipementsGlobaux"
                    :isGlobal="true"
                    :idLieu="pieceEquipementsGlobaux.id"
                    @close="showModalCreateEquipementGlobal=false;"
                    @save="showModalCreateEquipementGlobal=false;">
                </vg-equipement-form>
           </template>
           <template #body v-if="$vgutils.isMobile()">
                <vg-tabs v-model="focusedMobileTab" 
                    :tabs="tabs" 
                    :color="'violet'"/>
                <br>
                <div v-if="focusedMobileTab=='caracteristiques'">
                    <vg-libelle-editable :label="$t('site')">
                        <template #read>
                            {{site.libel_lieu}}
                        </template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('famille')">
                        <template #read>
                            <span>{{site.famille}}</span>
                        </template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('adresse')">
                        <template #read>
                            {{site.adresse}}
                        </template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('reference-cadastrale')">
                        <template v-slot:read> {{site.referenceCadastrale}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('surface-hors-oeuvre-brut')">
                        <template v-slot:read> {{site.surfaceHorsOeuvreBrut}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('surface-parcelle')">
                        <template v-slot:read> {{site.surfaceParcelle}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('type-erp')">
                        <template v-slot:read> {{site.typeErp}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('categorie-erp')">
                        <template v-slot:read> {{site.categorieErp}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <vg-libelle-editable :label="$t('capacite-accueil')">
                        <template v-slot:read> {{site.capaciteAccueil}}</template>
                    </vg-libelle-editable>
                    <hr class="no-margin">
                    <br>
                    <vg-files-viewer-photo :files="VgFilesMixins_filesOnlyPhoto" />
                </div>
                <div v-else-if="focusedMobileTab=='documents'">
                    <vg-files-uploader
                        :tagsList="VgFilesMixins.tags"
                        @upload-done="VgFilesMixins_fetchFilesByUid();" />
                    <vg-files-viewer-list-section
                        :files="VgFilesMixins_filesListSection"
                        @delete="VgFilesMixins_fetchFilesByUid()" />
                </div>
                <vg-equipements-pieces-taches-actives-table v-else-if="focusedMobileTab=='verifications'" />
            </template>
            <template #footer v-if="$vgutils.isMobile()">
                
            </template>
        </vg-app-layout>
    </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";

import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
import VgContratsTable from "src/components/Vg/Contrat/VgContratsTable.vue";
import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";
import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
import VgTiersTable from "src/components/Vg/Tiers/VgTiersTable.vue";
import VgVerificationsViewer from "src/components/Vg/Verification/VgVerificationsViewer.vue";
import VgEquipementsPiecesTachesActivesTable from "src/components/Vg/Equipements/VgEquipementsPiecesTachesActivesTable.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue"
import Metadatas from "src/services/Metadatas";
import VgPiecesGeneriqueForm from "src/components/Vg/Forms/VgPiecesGeneriqueForm.vue";
import VgContratForm from "src/components/Vg/Forms/VgContratForm.vue";
import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";
import VgStatistiquesMaintenanceDashboard from "src/components/Vg/Statistiques/VgStatistiquesMaintenanceDashboard.vue";
import VgStatistiquesVerificationDashboard from "src/components/Vg/Statistiques/VgStatistiquesVerificationDashboard.vue";
import VgCalendarDashboard from "src/components/Vg/Calendar/VgCalendarDashboard.vue";

import { mapGetters } from 'vuex';
export default {
	name: 'site',
    mixins:[LieuMixins],
	props:{
		//value : Object
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modifier": "Modifier",
            "details": "Détails",
            "adresse": "Adresse",
            "reference-cadastrale": "Référence cadastrale",
            "surface-hors-oeuvre-brut": "SHOB(m²)",
            "surface-parcelle": "Surface parcelle(m²)",
            "type-erp": "Type ERP",
            "categorie-erp": "Categorie ERP",
            "capacite-accueil": "Capacité accueil",
            "date-mise-en-service": "Date mise en service",
            "date-prochaine-commission-securite": "Date prochaine commission de sécurité",
            "frequence-commission-securite": "Fréquence commission sécurité (en année)",
            "documents": "Documents",
            "reserves": "Réserves",
            "maintenances": "Fiches curatives",
            "contrats": "Contrats",
            "lieux-generiques": "Lieux génériques",
            "equipements": "Equipements",
            "toutes-reserves-en-cours": "Toutes les réserves en cours",
            "levees": "Levées",
            "onglet-contrat-courant": "Tous",
            "onglet-contrat-expire": "Expirés",
            "onglet-controle": "Contrôle réglementaire",
            "onglet-entretient-periodique": "Entretien périodique",
            "onglet-verification-periodique": "Vérification périodique",
            "onglet-maintenance": "Fiches curatives",
            "onglet-autre": "Autres",
            "globaux": "Globaux",
            "tous": "Tous",
            "creer-piece-generique": "Créer pièce générique",
            "gestion-calendaire": "Gestion calendaire",
            "donnees-associees": "Lieux / équipements",
            "donnees-curatif": "Curatif",
            "donnees-preventif-interne": "Préventif interne",
            "donnees-preventif-prestataire": "Préventif prestataire",
            "interventions-periodiques": "Interventions périodiques",
            "tiers": "Tiers",
            "verifications": "Préventif",
            "statistiques": "Statistiques",
            "vision-calendrier": "Vision calendrier",
            "vision-tableau": "Vision tableau",
            "encours": "en cours",
            "resolue": "clôturées",
            "urgent": "urgentes",
            "urgence": "urgence",
            "retourAFaire": "retours à faire",
            "relance": "relance",
            "non-affecte": "non affectées",
            "mes-affectations": "mes affectations",
            "prendre-en-compte": "Prendre en compte",
            "retour-a-faire": "Retour à faire",
            "mettre-en-attente": "Mettre en attente",
            "toutes": "toutes",
            "voir-plans": "Voir les plans de ce site",
            "section-photos": "Photos",
            "site": "Site",
            "famille": "Famille",
            "tab-caracteristiques": "Caractéristiques",
            "tab-verifications": "Préventif",
            "tab-documents": "Documents attachés"
        },
        "en": {
            "modifier": "Update",
            "details": "Details",
            "adresse": "Adress",
            "reference-cadastrale": "Cadastral reference",
            "surface-hors-oeuvre-brut": "GSA(m²)",
            "surface-parcelle": "Plot surface(m²)",
            "type-erp": "ERP type",
            "categorie-erp": "ERP category",
            "capacite-accueil": "Reception capacity",
            "date-mise-en-service": "Commissioning date",
            "date-prochaine-commission-securite": "Next security committee date",
            "frequence-commission-securite": "Safety committee frequency (in years)",
            "documents": "Documents",
            "reserves": "Reservations",
            "maintenances": "Curative sheets",
            "contrats": "Contracts",
            "lieux-generiques": "Generic location",
            "equipements": "Equipments",
            "toutes-reserves-en-cours": "All current reservations",
            "levees": "Lifted",
            "onglet-contrat-courant": "ALl",
            "onglet-contrat-expire": "Expired",
            "onglet-controle": "Regulatory control",
            "onglet-entretient-periodique": "Periodic maintenance",
            "onglet-verification-periodique": "Periodic verification",
            "onglet-maintenance": "Curative sheets",
            "onglet-autre": "Others",
            "globaux": "Global",
            "tous": "All",
            "creer-container-equipements-globaux": "Create global equipment container",
            "gestion-calendaire": "Calendar management",
            "donnees-associees": "Location / equipment",
            "donnees-curatif": "Curative",
            "donnees-preventif-interne": "Internal preventive",
            "donnees-preventif-prestataire": "Preventive provider",
            "interventions-periodiques": "Periodic interventions",
            "tiers": "Third parties",
            "verifications": "Verification",
            "statistiques": "Statistics",
            "vision-calendrier": "Calendar vision",
            "vision-tableau": "Vision table",
            "encours": "In progress",
            "resolue": "Fenced",
            "urgent": "Urgent",
            "urgence": "Emergency",
            "retourAFaire": "Feedbacks",
            "relance": "Reminder",
            "non-affecte": "Not assigned",
            "mes-affectations": "Assignments",
            "prendre-en-compte": "Taken into account",
            "retour-a-faire": "Feedbacks",
            "mettre-en-attente": "Put on hold",
            "toutes": "All",
            "voir-plans": "See site map",
            "section-photos": "Photos",
            "site": "Site",
            "famille": "Family",
            "tab-caracteristiques": "Caracteristics",
            "tab-verifications": "Préventif",
            "tab-documents": "Documents"
        }
    }
},
	components:{
        VgButton,
        VgTabs,
        VgLieuForm,
        VgLibelleEditable,
        VgCollapse,
        VgFilesUploader,
        VgFilesViewerListSection,
        VgFilesViewerPhoto,
        VgFichesavTable,
        VgContratsTable,
        VgLieuxTable,
        VgEquipementsTable,
        VgPiecesGeneriqueForm,
        VgDatetimeViewer,
        VgEquipementForm,
        VgContratForm,
        VgInterventionsTable,
        VgInterventionForm,
        VgTiersTable,
        VgVerificationsViewer,
        VgEquipementsPiecesTachesActivesTable,
        VgStatistiquesMaintenanceDashboard,
        VgStatistiquesVerificationDashboard,
        VgCalendarDashboard,
        VgFilesPlans
    },
    data:function(){
        return {
            isUpdateLieuModalOpened:false,
            fileToUpload: null,
            isUploaderDisplayed: false,
            documents: [],
            contrat:{lieux:[{"id":this.$route.params.id}]},
            reservesLocalFilters: null,
            equipementsLocalFilters: null,
            equipementsFilters: null,
            showPieceGeneriqueForm:false,
            showModalCreateEquipementGlobal: false,
            isContratFormOpened:false,
            reservesTabFocused: null,
            maintenancesTabFocused: null,
            focusedTab: "associees",
            focusedMobileTab: "caracteristiques",
            interventionsLocalFilters: null,
            isInterventionsVueCalendrier: false,
            showTirroirDePlan:false
        }
    },
    watch: {
        site:{
            handler: function(newsite){
                this.equipementsFilters = Object.values(this.equipementsTabs[0].filters);
            }
        }
    },
    created:function(){
        this.reservesTabFocused = this.reservesTabs[0];
        this.maintenancesTabFocused = this.maintenancesTabs[0];
        this.fetch();
    },
    computed:{
        ...mapGetters({
            site: 'LieuxStore/getSelectedItem',
            maintenancesCounters: 'MaintenancesStore/getMaintenancesCounters',
            contratsCounters: 'ContratsStore/getCounters',
            pieceEquipementsGlobaux: "LieuxStore/getPieceEquipementsGlobaux"
        }),
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? 'rgb(108, 58, 158)' : 'white';
        },
        getMesAffectations:function(){
            if(this.$app.role=="ROLE_SOUS_TRAITANT" && this.$app.tiers_id) return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByTiersId"](this.$app.tiers_id);
            else return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByIdUser"](this.$app.idUser);
        },
        interventionsCalendarFilters: function(){
            return {
                /*contrat_lieu_id: { attr:"cl.id", value: "1", action:"is_null", openParenthesis: true },
                lieu_contrat_path: { attr:"lc.path", colId: "path", value: this.site.path, action:"equals", closeParenthesis: true, logicalOperator: "OR"},
                contrat_id: {attr: "c.id", colId:"contrat_id", value: "1", action:"is_not_null", openParenthesis: true},
                contrat_id_null: {attr: "c.id", colId:"contrat_id", value: "1", action:"is_null", closeParenthesis: true, logicalOperator: "OR"}*/
                contrat_lieu_id: { attr:"cl.id", value: "1", action:"is_null", openParenthesis: true },
                lieu_contrat_path: { attr:"lc.path", colId: "path", value: this.site.path, action:"equals", closeParenthesis: true, logicalOperator: "OR"},
                contrat_id: {attr: "c.id", colId:"contrat_id", value: "1", action:"is_not_null"},
                fichesav_id: { attr:"i.fichesav_id", colId:"fichesav_id", value: 1, action:"is_null"}
            };
        },
        tabs:function(){
            if(this.$vgutils.isMobile()){
                return [
                    {label: this.$t('tab-caracteristiques'), name: "caracteristiques"},
                    {label: this.$t('tab-verifications'), name: "verifications"},
                    {label: this.$t('tab-documents'), name: "documents"}
                ];
            }else{
                return [
                    {label: this.$t("donnees-associees"), name:"associees"},
                    {label: this.$t("donnees-curatif"), name:"curatif"},
                    {label: this.$t("donnees-preventif-interne"), name:"preventif-interne"},
                    {label: this.$t("donnees-preventif-prestataire"), name:"preventif-prestataire"},
                ];
            }
        },
        statistiquesMaintenanceFilters: function(){
            return [
                { attr:"l.path",colId: "path", value: this.site.path, action:"start_with" },
                { attr:"fm.isReserve",colId: "isReserve", value: "0", action:"equals" }
            ];
        },
        statistiquesVerificationFilters: function(){
            return [
                { attr:"l.path",colId: "path", value: this.site.path, action:"start_with" }
            ];
        },
        getIdLieuEquipementGlobal: function(){
            return this.site.lieu_generique.id;
        },
        reservesFilters: function(){
            let filters = {
                path: { attr:"l.path",colId: "path", value: this.site.path, action:"start_with" }
            };
            filters = Object.assign(filters, this.reservesTabFocused.filters);
            return Object.values(filters);
        },
        maintenancesFilters: function(){
            let filters = {
                path: { attr:"l.path",colId: "path", value: this.site.path, action:"start_with" }
            };
            filters = Object.assign(filters, this.maintenancesTabFocused.filters);
            return Object.values(filters);
        },
        reservesTabs: function(){
            let tabs = [
                {label: this.$t("toutes-reserves-en-cours"), name:"toutes", counter: this.maintenancesCounters.all, filters:
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"equals"},
                        libelleCatgorie: {attr: "cat.libelleCatgorie", colId: "libelleCatgorie", value: null, action: "equals"}
                    }
                }
            ];
            let categories = Object.keys(this.maintenancesCounters);
            categories.shift(); // remove all
            categories.pop(); // remove levees
            categories.forEach((categorie)=>{
                tabs.push(
                    {label: categorie, name:categorie, counter: this.maintenancesCounters[categorie], filters:
                        {
                            statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"equals"},
                            libelleCatgorie: {attr: "cat.libelleCatgorie", colId: "libelleCatgorie", value: categorie, action: "equals"},
                        }
                    }
                );
            });
            tabs.push(
                {label: this.$t("levees"), name:"levees", counter: this.maintenancesCounters.levees, filters:
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"},
                        libelleCatgorie: {attr: "cat.libelleCatgorie", colId: "libelleCatgorie", value: null, action: "equals"}
                    }
                }
            );
            return tabs;
        },
        maintenancesTabs: function(){
            let tabs = [
                {label: this.$t("encours"), name:"encours", counter: this.maintenancesCounters["En_cours"], filters:
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}

                    }
                },
                {label: this.$t("urgent"), name:"urgence",isNotMobile:true, counter: this.maintenancesCounters["urgence"], filters:
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:true, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }
                },
                {label: this.$t("non-affecte"), name:"nonAffecte",isNotMobile:true, counter: this.maintenancesCounters["nonAffectes"], filters:

                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_null"}
                    }
                },
                {label: this.$t("mes-affectations"), name:"mesAffectations", counter: this.getMesAffectations.length, filters:
                     // cas partiulier l'objet affecté arrive en dehors de la requête
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:1, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"is_not_null"}
                    }
                },
                {label: this.$t("retourAFaire"), name:"retourAFaire",isNotMobile:true, counter: this.maintenancesCounters["isRetourAFaire"], filters:

                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:true, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }
                },
                {label: this.$t("relance"), name:"relance", counter: this.maintenancesCounters["isRelance"], filters:

                    {
                        statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:true, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }
                },
                {label: this.$t("resolue"), name:"résolue",isNotMobile:true, counter: this.maintenancesCounters["Resolue"], filters: {
                    statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"},
                    urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                    retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                    relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                    affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                    mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                }},
                {label: this.$t("toutes"), name:"toutes",isNotMobile:true, counter: this.maintenancesCounters["all"], filters:
                    {
                        statut: {attr:"fm.statut",colId: "statut", value:null, action:"equals"},
                        urgence : {attr:"fm.urgence",colId: "urgence", value:null, action:"equals"},
                        retourAFaire: {attr:"fm.isRetourAFaire",colId: "retourAFaire", value:null, action:"equals"},
                        relance: {attr:"fm.isRelance",colId: "relance", value:null, action:"equals"},
                        affectation: {attr:"aff.id",colId: "affectation", value:null, action:"is_not_null"},
                        mesAffectations : {attr:"aff.id",colId: "mesAffectations", value:null, action:"is_not_null"}
                    }
                }
            ];
            return tabs;
        },
        interventionsFilters: function() {
            return {
                // liste intervention dont le contrat restreint à aucun site ou contrat lieu == site.path
                contrat_lieu_id: { attr:"l.id", value: "1", action:"is_null", openParenthesis: true },
                lieu_contrat_path: { attr:"l.path", colId: "path", value: this.site.path, action:"equals", closeParenthesis: true, logicalOperator: "OR"},
                contrat_id: {attr: "c.id", colId:"contrat_id", value: "1", action:"is_not_null"}
            };
        },
        interventionsTabs: function(){
            return [
                {label: this.$t("toutes"), name:"toutes", filters:
                    {
                        status: {attr:"i.status",colId: "status", value:null, action:"equals"}
                    }
                },
                {label: this.$t("previsionnelles"), name:"previsionnelles", filters:
                    {
                        status: {attr:"i.status",colId: "status", value:"intervention-prevue", action:"equals"}
                    }
                },
                {label: this.$t("effectives"), name:"effectives", filters:
                    {
                        status: {attr:"i.status",colId: "status", value:"intervention-realisee", action:"equals"}
                    }
                }
            ];
        },
        verificationsFilters: function(){
            return Object.values({
                path: { attr:"l.path",colId: "path", value: this.site.path, action:"start_with" }
            });
        },
        contratsFilters: function(){
            return {
                path: { attr:"path", value: this.site.path, action:"start_with", openParenthesis:true },
                id: { attr:"l.id", value: "1", action:"is_null", closeParenthesis:true, logicalOperator: "OR" }
            };
        },
        tiersFilters: function(){
            return {
                path_is_null: { attr:"l.id", value: "1", action:"is_null", openParenthesis: true },
                path: { attr:"l.path",colId: "path", value: this.site.path, action:"start_with", closeParenthesis: true, logicalOperator: "OR" }
            };
        },
        lieuxFilters: function(){
            return {
                type: { attr:"l.type_lieu",colId: "l.type_lieu", value: "Piece", action:"equals" },
                path: { attr:"l.path",colId: "l.path", value: this.site.path+"/-/-", action:"start_with" }
            };
        },
        equipementsTabs: function(){
            return [
                {label: this.$t("globaux"), name:"globaux", counter: null, filters:
                    {
                        path: {attr:"path",colId: "path", value: this.site.path+"/-/-", action:"start_with"},
                        isGroupEqp: {attr:"e.isGroupEqp",colId: "isGroupEqp", value:"1", action:"not_equals"}
                    }
                },
                {label: this.$t("tous"), name:"tous", counter: null, filters:
                    {
                        path1: {attr:"path",colId: "path", value: this.site.path, action:"start_with"},
                        isGroupEqp: {attr:"e.isGroupEqp",colId: "isGroupEqp", value:"1", action:"not_equals"}
                    }
                }
            ];
        },
        emplacementMapDuSite: function(){
            return "https://maps.google.com/maps?q="+this.site.adresse+"&t=k&z=15&ie=UTF8&iwloc=&output=embed";
        }
    },
    methods:{
        onCreateContainerEquipementsGlobaux: function(){
            this.LieuMixins_createPieceGenerique(this.site.id).then((piece)=>{

            });
        },
        onClickReservesTabs: function(e){
            this.reservesTabFocused = e;
        },
        onClickMaintenancesTabs: function(e){
            this.maintenancesTabFocused = e;
        },
        onClickEquipementsTabs: function(e){
            this.equipementsFilters = Object.values(e.filters);
        },
        onClickInterventionsTabs: function(e){
            this.interventionsLocalFilters = Object.values(e.filters);
        },
        fetch:function(){
            let metadatas = new Metadatas();
            metadatas.setFilters({
                id:{attr:"l.id",value:this.$route.params.id,action:"equals"}
            });
            // je dispatch dici et non dans la mixins car cas particulier
            // j'utilise la methode getLieux au lieu de getLieu
            // pour store un lieu by id
            this.LieuMixins_getLieux(metadatas).then((datas)=>{
                this.$store.dispatch("LieuxStore/setSelectedItem", datas.lieux[0]);
                this.VgFilesMixins_init(datas.lieux[0].uid, "lieu");
            });
        },
        handleBackPage:function(){
            this.$router.go(-1);
        },
        handleDblClickContrat:function(contrat){
            this.$router.push({ name: '_contrat_id', params: { id: contrat.id }});
        }
    }
}

</script>
<style lang="scss" scoped>
.mapouter{position:relative;text-align:right;height:243px;width:241px;}
.gmap_canvas {overflow:hidden;background:none!important;height:243px;width:241px;}
.height-300{
    height: 300px !important;
}
</style>
