<template>
	<vg-modal :title="$t('title')"
		:width="'50%'"
		:isSaveDisabled="isSaveDisabled"
		@close="$emit('close')">
		<template #body>
			<div class="step">
				<span class="title"><b>1/</b> {{$t("create-info-step-1")}}</span>
				<img src="static/assets/screencapture/lieu/create_1.png"
					width="50%"/>
			</div>
			<div class="step">
				<span class="title"><b>2/</b> {{$t("create-info-step-2")}}</span>
				<img src="static/assets/screencapture/lieu/create_2.png"
					width="50%"/>
			</div>
			<div class="step">
				<span class="title"><b>3/</b> {{$t("create-info-step-3")}}</span>
				<img src="static/assets/screencapture/lieu/create_3.png"
					width="50%"/>
			</div>

		</template>
		<template #footer>
			<vg-button @click="openLink('https://verifgood.tawk.help/article/12-comment-cr%C3%A9er-des-lieux')" type="success">
				{{$t("voir-tutorial")}}
			</vg-button>
			<vg-button @click="$emit('close')">
				{{$t("close")}}
			</vg-button>
		</template>
	</vg-modal>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
    export default {
        name: 'vg-lieu-info-create',
		components: {

			VgInput,
			VgButton
		},
		mixins:[],
        props: {

        },
        data: function() {
            return {

            };
        },
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Création lieu / container",
            "close": "Fermer",
            "create-info-step-1": "Sélectionnez un niveau de structure dans l'arborescence en bas à droite",
            "create-info-step-2": "Cliquez droit",
            "create-info-step-3": "Utilisez menu contextuel",
			"voir-tutorial": "Voir le tutoriel complet"
        },
        "en": {
            "title": "Location / container creation",
            "close": "Close",
            "create-info-step-1": "Select where you want to create a new location on the tree view at the bottom right of your screen",
            "create-info-step-2": "Right click",
            "create-info-step-3": "Follow the instructions on the menu",
			"voir-tutorial": "See the complete tutorial"
        },
        "th": {
            "title": "การสร้างที่ตั้ง / คอนเทนเนอร์",
            "close": "ปิด",
            "create-info-step-1": "เลือกสถานที่ที่คุณต้องการสร้างที่ตั้งใหม่บนมุมมองต้นไม้ที่อยู่ด้านล่างของหน้าจอ",
            "create-info-step-2": "คลิกขวา",
            "create-info-step-3": "ปฏิบัติตามคำแนะนำบนเมนู",
			"voir-tutorial": "ดูคู่มือการใช้งานทั้งหมด"
        }
    }
},
		created: function(){

		},
		methods: {
			openLink(url){
				window.open(url, '_blank');
			}
		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
.step{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	.title{

	}
}
</style>
