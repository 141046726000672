/**
 *
 *   Storage access wrapper
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */

import {getCurrentDatabase} from "../databases/versions/DBVersions";

class Storage {
    /**
     *@constructor
     */
    constructor(__options) {
		this.__options = __options || {};
        this.indexDb = getCurrentDatabase(__options);
        if (this.isStorageAvailable('localStorage')) {
            this.storageName = "";
        }
        else {
          console.warn("Storage unaivalable");
        }
    }
    /**
    * Get element from storage.
    * @param string key
    * @param string storage default = localStorage can be sessionStorage
    * @return any
    */
    read(key,storage="localStorage"){
        let datas = null;
        switch (storage) {
            case "localStorage":
                datas = JSON.parse(localStorage.getItem(key));
                break;
            case "sessionStorage":
                datas = JSON.parse(sessionStorage.getItem(key));
                break;
            default:

        }
        return datas;
    }
    /**
    * Set element into  storage.
    *
    * @param string key
    * @param Object value
    * @param string storage default = localStorage can be sessionStorage
    * @return boolean
    */
    insert(key,value,storage="localStorage"){
        //if(!value)return false;
        switch (storage) {
            case "localStorage":
                localStorage.setItem(key, JSON.stringify(value));
                break;
            case "sessionStorage":
                sessionStorage.setItem(key, JSON.stringify(value));
                break;
            default:

        }
        return true;
    }
    /**
    * remove element from storage.
    *
    * @param string key
    * @param Object value
    * @param string storage default = localStorage can be sessionStorage
    * @return boolean
    */
    remove(key, storage="localStorage"){
        //if(!value)return false;
        switch (storage) {
            case "localStorage":
                localStorage.removeItem(key);
                break;
            case "sessionStorage":
                sessionStorage.removeItem(key);
                break;
            default:

        }
        return true;
    }
    /**
    * @param string type
    * @return boolean | DOMException
    */
    isStorageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }
    /**
     * 
     * @param string key 
     * @return string
     */
    addPrefix(key){
        return `${this.__options.name}-${key}`;
    }
    /**
     * 
     * @param string name 
     * @return string
     */
    useStorage(name){
        this.storageName = name;
        return this;
    }
    /**
     * @return string
     */
    getName(){
        return this.storageName;
    }


}
export default  Storage;
