<template>
<div>
    <vg-modal
        :title="$t('operation-modal-title')"
        @close="$emit('close')">
        <template v-slot:body>
            <vg-operation-viewer
                v-model="operation"
                :fm="maintenance">
            </vg-operation-viewer>
            <br>
            <span>{{$t("par")}} {{operation.username}}, </span>
            <span>
                {{$t("le")}}
                <vg-datetime-viewer v-model="operation.dateOperation">
                </vg-datetime-viewer>
            </span>
        </template>
        <template v-slot:footer>
            <vg-button
                @click="$emit('close')">
                {{$t("close")}}
            </vg-button>
            <vg-button v-if="!operation.flag || operation.flag=='manuelle' || operation.flag==''"
                :type="'danger'"
                @click="onDelete">
                {{$t("delete")}}
            </vg-button>
            <vg-button
                :type="'success'"
                @click="onShowUpdate">
                {{$t("update")}}
            </vg-button>
        </template>
    </vg-modal>
    <vg-operation-update v-if="showUpdateModal"
        v-model="operation"
        :flag="operation.flag"
        @close="showUpdateModal=false"
        @save="onSave">
    </vg-operation-update>
</div>
</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgOperationViewer from 'src/components/Vg/Operation/VgOperationViewer.vue';
import VgOperationUpdate from 'src/components/Vg/Operation/VgOperationUpdate.vue';
import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

export default {
    name:"vg-operation-modal",
    components:{

        VgButton,
        VgOperationViewer,
        VgOperationUpdate,
        VgDatetimeViewer
    },
    mixins: [MaintenanceMixins],
    props: {
        value: {
            type: Object
        },
        maintenance:{
            type: Object,
            required: true
        }
    },
    data:function() {
        return{
            operation: this.value,
            showUpdateModal: false
        }
    },
    watch: {
        value:{
            handler: function(val){
                this.operation = val;
            },
            deep: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "operation-modal-title": "Opération",
            "close": "Fermer",
            "delete": "Supprimer",
            "update": "Modifier",
            "par": "Par",
            "le": "le"
        },
        "en": {
            "operation-modal-title": "Operation",
            "close": "Close",
            "delete": "Delete",
            "update": "Update",
            "par": "By",
            "le": "On the"
        }
    }
},
    methods:{
        submitOperation: function(){
            this.$emit('save', this.operation);
        },
        closeModal: function(){
            this.$emit('close');
        },
        onDelete: function(){
            this.MaintenanceMixins_deleteOperation(this.operation.id, this.operation).then((data)=>{
                this.$emit("after-delete", data);
            });
        },
        onShowUpdate: function(){
            this.showUpdateModal = true;
        },
        onSave: function(operation){
            this.$emit("after-update", operation);
        }
    }

}
</script>

<style lang="scss">
.textOperation{
    textarea{
        line-height: 25px !important;
        height: 150px;
    }
}
</style>
