<template>
    <vg-select v-model="selectedMarque"
        :options="getMarques"
        :multiple="multiple"
        :attributeLabel="'label'"
        :attributeValue="'value'"
        :clearable="clearable"
        :placeholder="placeholder?placeholder:$t('placeholder')"
        @input="$emit('input', selectedMarque)"
        >
    </vg-select>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import VgFilesThumbnail from 'src/components/Vg/Files/VgFilesThumbnail.vue';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-categorie-marque-selector',
    components: {
        VgSelect,
        VgFilesThumbnail
    },
    mixins: [CategorieMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner une marque"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Array,
            default: null
        },
        /**
         * Selection de plusieurs marques.
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            selectedMarque: this.value || []
        };
    },
    watch: {
        value: {
            handler: function(newValue){
                this.selectedMarque = newValue;
                console.log("SELECTED MARQUE", newValue);
            },
            deep: true
        }
    },
    mounted: function(){

    },
    methods: {

    },
    computed: {
        ...mapGetters({
            marques: "CategoriesStore/getSelectedCategorieMarques"
        }),
        getMarques: function(){
            return this.marques.map((marque)=>{
                return {label: marque, value: marque};
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.categorie-container-select{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .icone-name{
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .color-categorie{
        width: 5%;

        >div{
            width: 25px;
            height: 25px;
            margin:auto;
            border-radius: 20px;
        }
    }
}

</style>
<docs>

    ### vg-users-selector


    ```js
          import VgCategoriesSelector from "src/components/Vg/Selectors/VgCategoriesSelector.vue";
    ```

    #### basic usage

    ```html static
    <vg-users-selector v-model="users" ></vg-users-selector>

    ```
    ``` avec choix multiple utilisateur
    <vg-users-selector v-model="users" multiple></vg-users-selector>
    ```
    #### Emit : input

    Retourne la valeur selectionner

    ### mixins

    CategorieMixins_getCategories

</docs>
