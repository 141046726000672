<template>
    <vg-select v-model="selectedEntite"
        :options="bonsDeCommandeEntites"
        :multiple="multiple"
        :attributeLabel="'name'"
        :attributeValue="attributeValue"
        :clearable="clearable"
        :placeholder="placeholder?placeholder:$t('placeholder')"
        @input="handleInputChange"
        >
        <template v-slot="{option}">
            <div style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;width:100%;padding:5px 0;">
                <b>{{option.name}}</b>
                <span><small>{{ $t("address") }}: </small>{{option.address}}</span>
				<span v-if="option.addressLivraison"><small>{{ $t("address-livraison") }}: </small>{{option.addressLivraison}}</span>
            </div>
        </template>
    </vg-select>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import BonsDeCommandeEntitesMixins from "src/mixins/BonsDeCommandeEntitesMixins.js";
import VgFilesThumbnail from 'src/components/Vg/Files/VgFilesThumbnail.vue';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bons-de-commande-entites-selector',
    components: {
        VgSelect,
        VgFilesThumbnail
    },
    mixins: [BonsDeCommandeEntitesMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner une entité",
            "address": "Adresse",
            "address-livraison": "Adresse livraison"
        },
        "en": {
            "placeholder": "Select an entity",
            "address": "Address",
            "address-livraison": "Delivery address"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Array,
            default: null
        },
        /**
        * Attribut de l'objet à retourner comme valeur.
        * @default id
        * Si attributeValue=null retourne l'objet sélectionné.
        */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
         * Selection de plusieurs entités.
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            selectedEntite: this.value || [],
            metadatas: new Metadatas(),
            agfilters: {}
        };
    },
    watch: {
        value: {
            handler: function(newValue){
                this.selectedEntite = newValue;
            },
            deep: true
        }
    },
    created: function(){
        this.fetch();
    },
    methods: {
        fetch: function(){
            this.metadatas.setFilters(this.agfilters);
            this.BonsDeCommandeEntitesMixins_getEntites(this.metadatas).then((datas)=>{
                console.log("GET BCs ENTITES", datas);
            });
        },
        handleInputChange:function(entite){
            this.$emit('input', entite);
            this.$emit('change', entite);
            this.$emit("selected-entite", this.findEntiteById(entite));
        },
        findEntiteById: function(entiteId){
            return this.bonsDeCommandeEntites.find(entite => entite.id == entiteId);
        }
    },
    computed: {
        ...mapGetters({
            bonsDeCommandeEntites: "BonsDeCommandeEntitesStore/getCollection"
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>
<docs>

    ### vg-bons-de-commande-entites-selector
