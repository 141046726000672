<template>
<div id="deplacement-equipement" v-if="equipment" >
    <vg-app-layout
        :title="headerMobileTitle"
        :isLoadingState="isLoading"
        :colorheader="$root.vgPurple"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        :isbackButtonDisplayed="false"
        class="hidden-print"
        @action-export="actionExport">
        <template v-slot:body >
            <div style="display:flex;flex-direction:column;align-items:center;">
                <div class="header" style="border:1px solid #e9e9e9;margin: 40px 0;padding:10px;">
                    <span style="display:flex;justify-content:center;"><img :src="equipment.icon" alt="" height="22" style="margin-right:10px;"><b>{{equipment.libelEquipement}}</b></span><br>
                    <span style="color:#999999;">{{equipment.qrCode}}</span>
                </div>
                <div class="body" :style="$vgutils.isMobile()?'width:90vw;':'width:60vw;'">
                    <div v-if="stageMove=='menu'" style="display:flex;flex-direction:column;align-items:center;">
                        <vg-button :type="'info'" style="width:100%;background-color:rgb(108, 58, 158)" @click="stagemoveUp('interne')">{{$t('deplacementEquip.mobile.listAction-interne')}}</vg-button>
                        <br><vg-button :type="'info'" style="width:100%;background-color:rgb(108, 58, 158)" @click="stagemoveUp('retour')" disabled>{{$t('deplacementEquip.mobile.listAction-retour')}}</vg-button>
                    </div>
                    <div v-else-if="stageMove=='interne'">
                        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center;gap:10px;margin-bottom: 10px;">
                            <vg-button
                                :type="'info'"
                                @click="showTreeLieu=!showTreeLieu">
                                {{showTreeLieu?$t("show-list-lieux"):$t("show-tree-lieux")}}
                            </vg-button>
                            <vg-button
                                type="grey"
                                @click="openPlansInteractifs=true;">
                                <i class="far fa-map"></i> <span>{{ $t("btn-plans-interactifs") }}</span>
                            </vg-button>
                            <vg-button
                                type="grey"
                                @click="openTiroir=true;">
                                <i class="far fa-map"></i> <span>{{ $t("btn-plans-pdf") }}</span>
                            </vg-button>
                        </div>
                        <span style="color:rgb(108, 58, 158);">{{$t("select-piece-destination")}}</span>
                        <vg-tree-lieux v-if="showTreeLieu"
                            style="margin-top:20px;"
                            @select-piece="selectPiece" />
                        <div v-else style="margin-top:20px;">
                            <vg-text-filter @input="onInputSearchPiece" />
                            <vg-filter-site v-model="agfiltersPieces.path.value"
                                style="margin: 10px 0;" />
                            <vg-filter-service v-model="agfiltersPieces.service.value"
                                style="margin: 10px 0;" />
                            <vg-lieux-table
                                :id="'vg-lieux-deplacement-table'"
                                :filters="agfiltersPieces"
                                paginate
                                isDemandeIntervention
                                style="height: 400px;"
                                @select-piece="selectPiece($event);confirmSortie();" />
                        </div>
                        <br>
                        <div style="display:flex;justify-content:space-between">
                            <vg-button style="width:50%;"
                                @click="stagemoveDown">
                                {{$t("deplacementEquip.mobile.Btn-retour")}}
                            </vg-button>
                            <vg-button :type="'success'" style="width:50%;"
                                @click="confirmSortie"
                                :disabled="!selected">
                                {{$t('deplacementEquip.mobile.Btn-confirme')}}
                            </vg-button>
                        </div>

                        <vg-etages-plans-interactifs-modal v-if="openPlansInteractifs"
                            @close="openPlansInteractifs=false;" />

                        <vg-files-plans v-if="openTiroir"
                            @close="openTiroir = false" />
                    </div>
                </div>
            </div>
        </template>
        <vg-modal v-if="showModalConfirm"
            :size="'huge'"
            :title="headerModalConfirm"
            @close="showModalConfirm = false"
            @save="validateMove()">
            <template v-slot:body>
                <div style="display:flex;flex-direction:column;align-items:center;">
                    <div class="header" style="border:1px solid #e9e9e9;margin: 40px 0;padding:10px;">
                        <span style="display:flex;justify-content:center;"><img :src="equipment.icon" alt="" height="22" style="margin-right:10px;"><b>{{equipment.libelEquipement}}</b></span><br>
                        <span style="color:#999999;">{{equipment.qrCode}}</span>
                    </div>
                    <vg-lieu-path-viewer
                        v-model="equipment.path"
                        :showTooltip="false">
                    </vg-lieu-path-viewer>
                    <i class="fa fa-4x fa-arrow-down"></i>
                    <vg-lieu-path-viewer
                        v-model="selected.path"
                        :showTooltip="false">
                    </vg-lieu-path-viewer>
                </div>
            </template>
        </vg-modal>
    </vg-app-layout>


</div>

</template>

<script>
import VgButton from 'src/components/Vg/VgButton.vue';

import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";

import VgEtagesPlansInteractifsModal from "src/components/Vg/Lieu/VgEtagesPlansInteractifsModal.vue";
import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";

import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';

import VgLieuPathViewer from 'src/components/Vg/Lieu/VgLieuPathViewer.vue';
import EquipmentsMixins from "src/mixins/equipementsMixins.js";
import LieuxInDeplacementEqpMixins from 'src/mixins/LieuMixins.js';
import Metadatas from "src/services/Metadatas.js";

export default {
    name:'deplacement-equipement',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "deplacementEquip": {
                "mobile": {
                    "Header-sortir": "Deplacer/ sortir équipement",
                    "Header-Modal-deplacement": "Confirmation déplacement",
                    "Header-Deplacer": "Deplacer équipement",
                    "Header-Modal-tiers": "Confirmation déplacement vers tiers",
                    "Header-tiers": "Déplacement vers tiers",
                    "Header-Modal-sortie": "Confirmation sortie définitive",
                    "Header-Sortie": "Sortie définitive équipement",
                    "Header-Modal-retour": "Confirmation retour équipement",
                    "Header-Retour": "Retour équipement",
                    "listAction-interne": "Déplacement interne",
                    "listAction-tiers": "Déplacement vers tiers",
                    "listAction-retour": "retour",
                    "listAction-definitive": "Sortie definitive",
                    "headerInfo-Qualifier": "QUALIFIER LA SORTIE",
                    "headerInfo-Select": "SELECTIONNER LA PIECE DE DESTINATION",
                    "Btn-retour": "Retour",
                    "Btn-confirme": "Confirmer",
                    "sortiDef-Sortie": "Sortie",
                    "sortiDef-Equip": "equipement ",
                    "Btn-Annuler": "Annuler"
                }
            },
            "select-piece-destination": "SELECTIONNER LA PIECE DE DESTINATION",
            "show-list-lieux": "Basculer vue liste",
            "show-tree-lieux": "Basculer vue arborescence",
            "btn-plans-interactifs": "Plans interactifs",
            "btn-plans-pdf": "Plans PDF"
        },
        "en": {
            "deplacementEquip": {
                "mobile": {
                    "Header-sortir": "Move equipement",
                    "Header-Modal-deplacement": "Confirmation Move",
                    "Header-Deplacer": "Move equipement",
                    "Header-Modal-tiers": "Confirmation Move to suppliers",
                    "Header-tiers": "Move to suppliers",
                    "Header-Modal-sortie": "Confirmation output equipment",
                    "Header-Sortie": "Final output equipment",
                    "Header-Modal-retour": "Confirmation return equipment",
                    "Header-Retour": "return equipment",
                    "listAction-interne": "Move to internal",
                    "listAction-tiers": "Move to suppliers",
                    "listAction-retour": "Return",
                    "listAction-definitive": "Final output",
                    "headerInfo-Qualifier": "QUALIFY OUTPUT",
                    "headerInfo-Select": "SELECT PLACE DESTINATION",
                    "Btn-retour": "Back",
                    "Btn-confirme": "Confirm",
                    "sortiDef-Sortie": "Output",
                    "sortiDef-Equip": "equipment ",
                    "Btn-Annuler": "Cancel"
                }
            },
            "select-piece-destination": "SELECT LOCATION",
            "show-list-lieux": "See list view",
            "show-tree-lieux": "See tree view",
            "btn-plans-interactifs": "Interactive level plans",
            "btn-plans-pdf": "PDF level plans"
        },
        "th": {
            "deplacementEquip": {
                "mobile": {
                    "Header-sortir": "เคลื่อนย้ายอุปกรณ์",
                    "Header-Modal-deplacement": "การยืนยันการเคลื่อนย้าย",
                    "Header-Deplacer": "เคลื่อนย้ายอุปกรณ์",
                    "Header-Modal-tiers": "การยืนยันการเคลื่อนย้ายไปยังซัพพลายเออร์",
                    "Header-tiers": "เคลื่อนย้ายไปยังซัพพลายเออร์",
                    "Header-Modal-sortie": "การยืนยันข้อมูลที่ส่งออกมาของอุปกรณ์",
                    "Header-Sortie": "ข้อมูลที่สงออกมาสุดท้ายของอุปกรณ์",
                    "Header-Modal-retour": "การยืนยันการส่งคืนอุปกรณ์",
                    "Header-Retour": "ส่งคืนอุปกรณ์",
                    "listAction-interne": "เคลื่อนย้ายภายใน",
                    "listAction-tiers": "เคลื่อนย้ายไปยังซัพพลายเออร์",
                    "listAction-retour": "ส่งคืน",
                    "listAction-definitive": "ข้อมูลที่ส่งออกมาสุดท้าย",
                    "headerInfo-Qualifier": "ตรวจความถูกต้องข้อมูลที่ออกมา",
                    "headerInfo-Select": "เลือกสถานที่ปลายทาง",
                    "Btn-retour": "กลับ",
                    "Btn-confirme": "ยืนยันแล้ว",
                    "sortiDef-Sortie": "ข้อมูลที่ส่งออกมา",
                    "sortiDef-Equip": "อุปกรณ์ ",
                    "Btn-Annuler": "ยกเลิก"
                }
            }
        }
    }
}    ,
    components:{
        VgLieuPathViewer,
        VgButton,
        VgTreeLieux,
        VgTextFilter,
        VgFilterSite,
        VgFilterService,
        VgLieuxTable,
        VgEtagesPlansInteractifsModal,
        VgFilesPlans
    },
    mixins:[EquipmentsMixins,LieuxInDeplacementEqpMixins],
    data: function() {
        return {
            stageMove : "menu",
            selected:'',
            showModalConfirm:false,
            motifSortieDefinitif:[
              {name:"Mise au rebut"},
              {name:"Disparu"},
              {name:"Volé"},],
            motifSelect:"",
            headerModalConfirm:'',
            equipment: null,
            pieces : null,
            filterString : null,
            showTreeLieu: true,
            openPlansInteractifs: false,
            openTiroir: false,
            agfiltersPieces: {
				type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"},
				path: {attr: "l.path", value: null, action: "contains"},
				service: {attr: "l.service", value: null, action: "equals"},
				libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
				codeTrois: {attr: "l.codeTrois", value: null, action: "contains", logicalOperator: "OR"},
				codeDeux: {attr: "l.codeDeux", value: null, action: "contains", logicalOperator: "OR"},
				servicetext: {attr: "l.service", value: null, action: "contains", closeParenthesis: true, logicalOperator: "OR"}
			},
        }
    },
    filters:{
        getPath: function(path){
            let splittedPath = path.split("/");
            splittedPath.shift();
            splittedPath.pop();
            return splittedPath.join("/");
        }
    },
    methods:{
        onInputSearchPiece: function(input){
            this.agfiltersPieces.libel_lieu.value = input;
			this.agfiltersPieces.codeTrois.value = input;
			this.agfiltersPieces.codeDeux.value = input;
			this.agfiltersPieces.servicetext.value = input;
        },
        stagemoveUp : function(stage){
            this.stageMove = stage;
        },
        stagemoveDown : function(){
            this.stageMove= "menu";
        },
        selectPiece: function(piece){
            console.log("PIECE", piece);
            this.selected = piece;
        },
        confirmSortie: function(){
          this.showModalConfirm = true;
          // //console.log("confirmSortie",this.motifSelect,this.selected);
          //this.motifSelect = this.motifSortieDefinitif[this.selected].name;
        },
        isPieceIsSelected: function(piece){
            return piece.id==this.selected.id;
        },
        validateMove: function(){
            var that = this;
            var rc = this.$rc;
            var query = {
                equipement_id:this.equipment.id,
                fromLieu_id:this.equipment.idLieu,
                toLieu_id:this.selected.id,
                userId:this.$app.appID,
                user_id:this.$app.idUser,
                commentaire:""
            };
            // TODO faire route spécial pour update equipement
            rc.post('/api/equipement/deplacement', query, function(result){
                // //console.log(result);
                that.showModalConfirm = false;
                that.$router.back();
            });
        }
    },
    computed:{
        getPieces: function(){
            if(this.filterString) return this.pieces.filter((piece)=>piece.path.toLowerCase().includes(this.filterString.toLowerCase()));
            else return this.pieces;
        },
        headerMobileTitle: function(){
            if(this.stageMove=='menu'){
                this.headerModalConfirm = "";
                return this.$t("deplacementEquip.mobile.Header-sortir");
            }
            else if(this.stageMove=='interne'){
                this.headerModalConfirm = this.$t("deplacementEquip.mobile.Header-Modal-deplacement");
                return this.$t("deplacementEquip.mobile.Header-Deplacer");
            }
            else if(this.stageMove=='tiers'){
                this.headerModalConfirm = this.$t("deplacementEquip.mobile.Header-Modal-tiers");
                return this.$t("deplacementEquip.mobile.Header-tiers");
            }
            else if(this.stageMove=='definitive'){
                this.headerModalConfirm = this.$t("deplacementEquip.mobile.Header-Modal-sortie");
                return this.$t("deplacementEquip.mobile.Header-Sortie");
            }
            else if(this.stageMove=='retour'){
                this.headerModalConfirm = this.$t("deplacementEquip.mobile.Header-Modal-retour");
                return this.$t("deplacementEquip.mobile.Header-Retour");
            }
        }
    },
    created:function(){
        this.$eventbus.$emit("loader-stop",null);
        let metadatasEquipement = new Metadatas();
        let metadatasPieces = new Metadatas();
        metadatasEquipement.setFilters(
            {
                "uid": {
                    "attr": "e.uid",
                    "colId": "uid",
                    "value": this.$route.params.uid,
                    "action": "equals"
                }
            }
        );
        metadatasPieces.setFilters({
            path: {attr:"type_lieu", value: "Piece", action: "equals"}
        });
        this.equipementsMixins_getEquipements(metadatasEquipement).then((reponses)=>{
            if (reponses.datas) {
                this.equipment = reponses.datas[0];
            }
            this.LieuMixins_getLieux(metadatasPieces).then((datas)=>{
                this.pieces = datas.lieux;
            });
        });
    }
}
</script>

<style lang="scss" scoped>
.piece-selected{
    background-color: rgba(108, 58, 158, 0.5);
}
</style>
