<template lang="html">
    <div class="vg-categorie-composants-update">
        <div>
            <vg-input
                v-model="search"
                :placeholder="$t('search-placeholder')"
                @input="handleChangeSearch">
            </vg-input>
            <vg-button
                @click="showCreateComposant=true">
                <i class="fas fa-plus"></i> {{$t("creer-composant")}}
            </vg-button>
        </div>
		<div v-if="composants && composants.length!=0" class="composants">
			<div v-show="isComposantPassFilter(composant)" v-for="(composant, index) in getComposants" :key="index" class="composant">
				<vg-checkbox
					class="checkboxComposant"
					:defaultChecked="isSelected(composant)"
                    @checked="handleChecked(composant)"
                    @unchecked="handleUnchecked(composant)">
					<img
						:src="composant.icon"
						width="20"
						height="20"
						onerror="this.style.visibility='hidden'"/>
					<div class="typeCompo" >{{composant.typeComposant}}</div>
					<div class="libelCompo"><b>{{composant.libelComposant}}</b></div>
				</vg-checkbox>
                <vg-button
                    :type="'default-info'"
                    @click="handleEditComposant(composant)">
                    <i class="fas fa-pen"></i>
                </vg-button>
			</div>
		</div>
		<span v-else-if="composants && composants.length==0" class="none">
			{{$t("aucun-composants")}}
		</span>
		<span v-else class="none">
			{{$t("chargement-composants")}}
		</span>
        <vg-composants-create v-if="showCreateComposant"
            @close="showCreateComposant=false"
            @success="handleSuccessComposantSaved"
            :categorieId="value.id">
        </vg-composants-create>
        <vg-composants-update v-if="showEditComposant"
            v-model="focusedComposant"
            @close="showEditComposant=false"
            @success="handleSuccessComposantUpdated"
            @delete-composant="handleDeleteComposant">
        </vg-composants-update>
	</div>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgComposantsCreate from "src/components/Vg/Composant/VgComposantsCreate.vue";
import VgComposantsUpdate from "src/components/Vg/Composant/VgComposantsUpdate.vue";

import ComposantMixins from "src/mixins/ComposantMixins.js";

export default {
    name:"vg-categorie-composants-update",
    components:{

        VgInput,
        VgButton,
        VgCheckbox,
        VgComposantsCreate,
        VgComposantsUpdate
    },
    mixins: [ComposantMixins],
    props:{
        value: {
            type: Array,
            required: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-composant-update": "Modification des composants de la catégorie",
            "aucun-composants": "Aucun composants",
            "chargement-composants": "Chargement des composants en cours...",
            "search-placeholder": "Rechercher un composant",
            "creer-composant": "Nouveau composant"
        },
        "en": {
            "modal-title-composant-update": "Update component",
            "aucun-composants": "No component",
            "chargement-composants": "Loading components",
            "search-placeholder": "Searching for components",
            "creer-composant": "New component"
        },
        "th": {
            "modal-title-composant-update": "อัปเดตชิ้นส่วน",
            "aucun-composants": "ไม่มีชิ้นส่วน",
            "chargement-composants": "กำลังโหลดชิ้นส่วน",
            "search-placeholder": "กำลังค้นหาชิ้นส่วน",
            "creer-composant": "ชิ้นส่วนใหม่"
        }
    }
},
    data: function(){
        return{
            categorie: this.value,
            composants: null,
            composantsSelected: null,
            composantsToAssociate: [],
            composantsToDesassociate: [],
            search: "",
            showCreateComposant: false,
            showEditComposant: false,
            focusedComposant: null
        }
    },
    watch: {
		value: {
			handler: function(value){
                this.categorie = value;
				this.composantsSelected = value.composants;
			}
		}
    },
    mounted: function(){
        this.composantsSelected = this.value.composants;
        this.fetchComposants();
    },
    computed:{
        getComposants: function(){
            return this.composants.filter((composant, index, self)=>self.findIndex((c)=>c.id==composant.id)==index);
        }
    },
    methods:{
        handleDeleteComposant: function(){
            window.location.reload();
        },
        fetchComposants: function(){
            this.ComposantMixins_getComposants().then((datas)=>{
                this.composants = datas;
            });
        },
        handleEditComposant: function(composant){
            this.focusedComposant = composant;
            this.showEditComposant = true;
        },
        handleSuccessComposantUpdated: function(composant){
            this.fetchComposants();
            this.showEditComposant = false;
        },
        handleSuccessComposantSaved: function(composant){
            this.fetchComposants();
            this.handleChecked(composant);
            this.showCreateComposant = false;
        },
        isComposantPassFilter: function(composant){
            return this.search.length==0
                || composant.libelComposant.toLowerCase().indexOf(this.search.toLowerCase())!=-1
                || composant.typeComposant.toLowerCase().indexOf(this.search.toLowerCase())!=-1
            ;
        },
        handleChangeSearch: function(search){
            this.search = search;
        },
        isSelected: function(composant){
            return this.composantsSelected.findIndex((comp)=>comp.id==composant.id)!=-1
                || this.composantsToAssociate.findIndex((comp)=>comp.id==composant.id)!=-1;
        },
        handleChecked: function(composant){
            // ajoute le composant de composantsToAssociate
            // emit @change
            if(this.composantsSelected.findIndex((c)=>c.id==composant.id)==-1){ // composant n'est pas encore associé à la catégorie
                // ajoute le composant dans la liste des composants à associer à la catégorie
                this.composantsToAssociate.push(composant);
            }
            if(this.composantsToDesassociate.findIndex((c)=>c.id==composant.id)!=-1){ // composant est à desassocier
                // supprime le composant dans les composants à desassocier
                this.composantsToDesassociate.splice(this.composantsToDesassociate.findIndex((c)=>c.id==composant.id), 1);
            }
            this.$emit("change", {
                toAssociate: this.composantsToAssociate,
                toDesassociate: this.composantsToDesassociate
            });
        },
        handleUnchecked: function(composant){
            // supprime le composant de composantsToDesassociate
            // emit @change
            if(this.composantsSelected.findIndex((c)=>c.id==composant.id)!=-1){ // composant est associé à la catégorie
                // on le desassocie de la catégorie
                this.composantsToDesassociate.push(composant);
            }
            if(this.composantsToAssociate.findIndex((c)=>c.id==composant.id)!=-1){  // composant est dans les composants à associer
                // on le retire des composants à associer
                this.composantsToAssociate.splice(this.composantsToAssociate.findIndex((c)=>c.id==composant.id), 1);
            }
            this.$emit("change", {
                toAssociate: this.composantsToAssociate,
                toDesassociate: this.composantsToDesassociate
            });
        }
    }
}
</script>

<style lang="scss">
.vg-categorie-composants-update{
	border: 1px solid #dcdfe6;
	width: 100%;
	height: 100%;
	.checkboxComposant{
		margin:0 !important;
		>div{
			display: flex;
			width:100%;
		}
		img{
			margin-right: 10px;
		}
		.no-photo{
			width: 20px;
			height: 20px;
			margin-right: 10px;

		}
		.libelCompo{
			width: calc(75% - 40px);
		}
		.typeCompo{
			color: #999b9e;
			overflow: hidden;
			margin-right: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 25%;
		}
	}
	>.composants{
        width: 100%;
    	height: 85%;
    	overflow: auto;
		padding: 10px;
		font-size: 10px;
		>div{
			display: flex;
			height: 50px;
			justify-content: flex-start;
			align-items: center;

		}
	}
	>.none{
		display: flex;
		justify-content: center;
	}

}
</style>
