<template lang="html">
    <vg-modal
        v-if="isModal"
        :title="$t('title')"
        @close="closeModal">
        <template #body>
            <form id="tache-affectation-form"
                ref="tache-affectation-form"
                onSubmit="return false;">
                <!-- test -->
                <vg-input :title="$t('user-form')"  :isRequiredValue="isRequiredValue">
                    <vg-users-selector v-model="tacheUsers.users" multiple />
                </vg-input>

                <vg-input :title="$t('tache-form')"  :isRequiredValue="true" v-show="!hideTacheSelection" >
                    <vg-taches-selector v-model="tacheUsers.taches" ></vg-taches-selector>
                </vg-input>
                <input
                  v-show="false"
                  ref="tache-affectation-submit"
                  type="submit"
                >
            </form>
        </template>
        <template #footer>
            <vg-button
                :type="'default'"
                @click="closeModal()">
                {{$t("cancel")}}
            </vg-button>
            <vg-button
                :type="'success'"
                @click="submitForm">
                {{$t("save")}}
            </vg-button>
        </template>
    </vg-modal>
    <div v-else>
        <form id="tache-affectation-form"
            ref="tache-affectation-form"
            onSubmit="return false;">
            <hr>
            <!-- test -->
            <vg-input :title="$t('user-form')"   :isRequiredValue="isRequiredValue" >
                <vg-users-selector v-model="tacheUsers.users" :placeholder="$t('description')" @input="handleUsersChange" multiple />
            </vg-input>

            <vg-input :title="$t('tache-form')"  :isRequiredValue="true" v-show="!hideTacheSelection" >
                <vg-taches-selector v-model="tacheUsers.taches" ></vg-taches-selector>
            </vg-input>
            <input
                v-show="false"
                ref="tache-affectation-submit"
                type="submit"
            >
        </form>
        <vg-button
            v-show="hasUsersHaveBeenChanged && !hideValidation"
            :type="'success'"
            @click="submitForm">
            {{$t("save")}}
        </vg-button>
    </div>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import VgTachesSelector from "src/components/Vg/Selectors/VgTachesSelector.vue";
import TacheUsersMixins from "src/mixins/TacheUsersMixins.js";
export default {
    name:'vg-affectation-tache-users-form',
    mixins:[TacheUsersMixins],
    components:{

        VgButton,
        VgInput,
        VgUsersSelector,
        VgTachesSelector
    },
    props:{
        value:{
            type:Array,
            default:[]
        },
        tache:{
            type:Object,
            default:null
        },
        // a list of id user
        users:{
            type:Array,
            default:null
        },
        hideTacheSelection:{
            type:Boolean,
            default:false
        },
        hideValidation:{
            type:Boolean,
            default:false
        },
        isModal:{
            type:Boolean,
            default:false
        },
        isRequiredValue:{
            type:Boolean,
            default:true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Affecter une tâches à des utilisateurs",
            "description": "Selectionnez les utilisateurs assigner à cette tâche.",
            "cancel": "Annuler",
            "save": "Valider l'affectation",
            "user-form": "Affecter l'utilisateur ou un tiers à cette tâche",
            "tache-form": "Tâche"
        },
        "en": {
            "title": "Assign tasks to users",
            "description": "Select the users to assign to this task.",
            "cancel": "Cancel",
            "save": "Validate the assignment",
            "user-form": "Assign the user or a third party to this task",
            "tache-form": "Task"
        }
    }
},
    data:function(){
        return {
            /**
             * Data formulaire.
             */
            tacheUsers:{
                users: [],
                taches:[]
            }
        }
    },
    watch:{
        value:function(){
            this.tacheUsers.users = this.value;
        }
    },
    computed:{
        isAnUpdateAffectationForm:function(){
            return this.value ? this.value.hasOwnProperty('users'):false;
        },
        hasUsersHaveBeenChanged:function(){
            // is this.users similar to this.tacheUsers.users
            let hasChanged = JSON.stringify(this.users) !== JSON.stringify(this.tacheUsers.users);
            return hasChanged;
        }
    },
    methods:{
        handleUsersChange(users){
            this.$emit('user-assignation-change',users);
        },
        /**
         * Verification formulaire.
         */
        submitForm:function(){
            this.$refs['tache-affectation-submit'].click();
            let form = this.$refs['tache-affectation-form'];
            if (form.checkValidity() && this.tacheUsers.users && this.tacheUsers.taches) {
                if (this.isAnUpdateAffectationForm) {
                    this.handleUpdateAffectation();
                }
                else{
                    this.handleCreateAffectation();
                }
            }
        },
        /**
        * Transform datas.
        * @param Object o.taches,o.users
        * @return array
        */
        normalize:function(tacheUsers){
            let users = tacheUsers.users;
            let tache = tacheUsers.taches;
            let normalizedDatas = [];
            users.forEach((idUser, i) => {
                normalizedDatas.push(
                    {
                        "tache_id":tache.id,
                        "user_id":idUser,
                        "userId":this.$app.appID
                    }
                )
            });
            return normalizedDatas;
        },
        /**
         * Save.
         * @emit Object.
         */
        handleSave:function(){
            this.tacheUsersMixins_create(this.normalize(this.tacheUsers),this.tacheUsers.taches.id).then(()=>{
                this.users = this.tacheUsers.users;
                this.$emit("input",this.normalize(this.tacheUsers));
                this.closeModal();
            });

        },
        /**
         * Actions dédié a l'update .
         */
        handleUpdateAffectation:function(){
            // //console.log(this.tacheUsers);
            this.handleSave();

        },
        /**
         * Actions dédié a la creation .
         */
        handleCreateAffectation:function(){
            // //console.log(this.tacheUsers);
            this.handleSave();

        },
        /**
         * Close de la modal.
         * @emit vide.
         */
        closeModal: function(){
            this.$emit('close');
        },
    },
    created:function(){
        this.tacheUsers.users = this.users;
        this.tacheUsers.taches = this.tache;
    }

}
</script>

<style lang="css" scoped>
</style>
