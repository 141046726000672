import 'src/directives/TableLoader/TableLoader.css';
var tableLoaderDirective = {}
tableLoaderDirective.install = function install (_Vue) {
  let _uid = 'vue-directive-loading' + Date.now().toString('16')
  _Vue.directive('table-loader', {
    inserted: function (el, binding) {
      let spinner = document.createElement('div');
      spinner.id = _uid;
      spinner.style.display = binding.value ? 'block' : 'none';
      let row = '<div style="display:flex;border-bottom:1px solid #dbdbdb;"> <div class="short-title"></div> <div class="short-title"></div> <div class="short-title"></div> <div class="short-title"></div>  </div>';
      let header = ' <div style="display: flex;align-items: baseline;"> <div class="item-image"></div> <div class="title"></div> </div>';
      let rows = "";
      for (var i = 0; i < 8; i++) {
          rows += row;
      }
      let htmlTemplate = `
      <div class="card" style="border: 1px #dbdbdb solid;">
        <div class="card-body item">
            <div class="d-flex">
                <div class="content">
                    ${header}
                    ${rows}
                </div>
            </div>
        </div>
      </div>`;
      spinner.id = _uid
      /*spinner.classList.add("loader");*/
      spinner.innerHTML = htmlTemplate.trim();
      /*el.childNodes.forEach((item) => {
        item.style.display = binding.value ? 'none' : ''
    })*/
      el.appendChild(spinner)
    },
    update: function (el, binding, vnode) {
      let spinner = document.getElementById(_uid);
      console.log(spinner.style)
      spinner.style.display = binding.value ? 'block' : 'none'
      el.childNodes.forEach((item) => {
        if(item.id === _uid) return
        item.style.display = binding.value ? 'none' : ''
    })
    }
  })
}
export default tableLoaderDirective;
