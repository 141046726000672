<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getHeaderIcon"
        :colorheader="getHeaderBackgroundColor"
        @action-export="actionExport">
        <template v-slot:search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>
        <template v-slot:header-bottom-left>
            <vg-tabs
                v-model="focusedTab"
                :color="'gris'"
                :tabs="tabs">
            </vg-tabs>
        </template>
        <template v-slot:create-button>
            <vg-button type="success"
                @click="showIntegrationSetCategories=true">
                {{$t("integration-categories-lieu")}}
            </vg-button>
            <vg-button type="success"
                @click="showCategorieCreate=true">
                {{$t("create-categorie-lieu")}}
            </vg-button>
        </template>
        <template v-slot:primary>
            <vg-categories-table
                isGe
                :searchQuery="searchQuery"
                :agfilters="categoriesFilters"
                @row-click="onRowClick">
            </vg-categories-table>
        </template>
        <template v-slot:secondary>
            <div v-if="!focusedCategorie" style="height:50vh;display:flex;justify-content:center;align-items:center;">
                <span style="color:#3999ff;font-size:18px;">{{$t("selectionner-categorie")}}</span>
            </div>
            <vg-categorie-lieu-viewer v-else
                v-model="focusedCategorie"
                @close="handleCloseCategorieViewer"
                @refresh-categories="handleRefreshCategories" />
        </template>
        <vg-categorie-form v-if="showCategorieCreate"
            isGe
            @close="showCategorieCreate=false"
            @save="onSaveCreateCategorie">
        </vg-categorie-form>
        <vg-categorie-template-integration v-if="showIntegrationSetCategories"
            isGe
            @close="showIntegrationSetCategories=false"
            @save="showIntegrationSetCategories=false">
        </vg-categorie-template-integration>
    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgCategoriesTable from "src/components/Vg/Categorie/VgCategoriesTable.vue";
import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";
import VgCategorieLieuViewer from "src/components/Vg/Categorie/VgCategorieLieuViewer.vue";
import VgCategorieTemplateIntegration from "src/components/Vg/Categorie/VgCategorieTemplateIntegration.vue";

import CategorieMixins from "src/mixins/CategorieMixins.js";
import TagGridMixins from 'src/mixins/TagGridMixins.js';

import Metadatas from "src/services/Metadatas.js"

const ICON_DESKTOP = "categorie-equipement-gris.png";
const ICON_MOBILE = "mobile/verification.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(66,159,255)";

import { mapGetters } from 'vuex';

export default {
    name: "categories-lieux",
    components: {
        TagGrid,
        VgTextFilter,
        VgTabs,
        VgButton,
        VgCategoriesTable,
        VgCategorieForm,
        VgCategorieLieuViewer,
        VgCategorieTemplateIntegration
    },
    mixins: [
        TagGridMixins,
        CategorieMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Categories lieux",
            "tab-categories-lieux": "Toutes",
            "create-categorie-lieu": "Nouvelle catégorie lieu",
            "integration-categories-lieu": "Intégrer set de catégories",
            "selectionner-categorie": "Sélectionner une catégorie"
        },
        "en": {
            "page-title": "Location categories",
            "tab-categories-lieux": "All",
            "create-categorie-lieu": "New room category",
            "integration-categories-lieu": "Integrate set of room categories",
            "selectionner-categorie": "Select a category"
        },
        "th": {
            "tab-categories-lieux": "ทั้งหมด",
            "create-categorie-lieu": "หมวดหมู่ของสถานที่ใหม่"
        }
    }
},
    data: function() {
        return {
            focusedTab: "tous",
            searchQuery: '',
            categoriesFilters: {},
            showCategorieCreate: false,
            showIntegrationSetCategories: false,
            focusedCategorie: null
        }
    },
    computed: {
        ...mapGetters({
            counters: 'CategoriesStore/getCounters'
        }),
        tabs: function(){
            return [
                {
                    label: this.$t("tab-categories-lieux"),
                    name: "tous",
                    counter: this.counters.lieux,
                    filters: []
                }
            ];
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
        getHeaderIcon: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        }
    },
    methods: {
        onSaveCreateCategorie: function(categorie){
            this.showCategorieCreate = false;
            this.focusedCategorie = categorie;
        },
        onRowClick: function(data){
            this.focusedCategorie = data;
        },
        actionExport: function(event){ 
                let metadatas = new Metadatas();
                // metadatas only to get categorie lieux
                switch (event.name) {
                    case "askForXLS":
                        this.CategorieMixins_getFile(metadatas,"lieux", this.$t("liste-categories"),"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.CategorieMixins_getFile(metadatas, "lieux", this.$t("liste-categories"),"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent(event.name, {
                            "detail": {"idTableau":"vg-categories-lieux-table"}
                        }));
                        break;
                    default:
                }

        }
    },
    created: function() {

    },
    destroyed: function(){
        this.$store.dispatch("CategoriesStore/clear");
    }
}
</script>

<style lang="scss" scoped>

</style>
