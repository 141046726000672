<template>
    <div class="files-plan-cell-renderer" @click="openFile">
        <span class="files">{{params.data.name}}</span>
        <span style="font-weight:bold;">{{params.data.label}}</span>
        <vg-lieu-path-viewer
            v-model="params.data.path" />
        <span style="font-size:11px;">
            {{$t("date")}} :
            <vg-datetime-viewer v-model="params.data.uploadedAt">
            </vg-datetime-viewer>
        </span>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgLieuPathViewer,
            VgDatetimeViewer
        },
        data:function(){
            return{
            }
        },
        methods:{
            openFile: function(){
				this.VgFilesMixins_getDownloadFileLink(this.params.data).then((link)=> window.open(link, '_blank'));
			}
        },
        computed:{
        },
        i18n:    { "locale":navigator.language,
	    	"messages": {
                "fr":{
                    "date": "Date d'enregistrement"
                },
                "en":{
                    "date": "Recording date"
                }
            }
        }
    });
</script>

<style lang="scss" scoped>
.files-plan-cell-renderer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .files{
        text-decoration: underline;
        color: #007dff;
    }
}
</style>
