<template lang="html">
    <div class="vg-composant-selector">
        <div class="search">
            <vg-input v-model="searchQuery" :placeholder="$t('search')"></vg-input>
        </div>
        <div class="tableau">
            <tag-grid
                :idTableau="'vg-composant-selector'"
                v-model="gridOptions"
                :columnDefs="columnDefs"
                :rowData="composants"
    			:showLoadingOverlay="showLoadingOverlay"
    			:overlayLoadingText="$t('overlay-loading-text')"
    			:overlayNoRowsText="$t('overlay-no-rows-text')"
                @ag-click="handleClickRow" />
        </div>
    </div>
</template>

<script>
import ComposantMobileCellRender from "src/components/Vg/TagGrid/ComposantMobileCellRender.vue";
import ComposantMixins from "src/mixins/ComposantMixins.js";
import EquipementsMixins from "src/mixins/equipementsMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgInput from "src/components/Vg/VgInput.vue";
import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'vg-composant-selector',
    components: {
        ComposantMobileCellRender,
        TagGrid,
        VgInput
    },
    props:{
        idLieu:{
            type: Number,
            default:function(){
                return null;
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des composants...",
		"overlay-no-rows-text": "Aucun composant correspondant au filtrage",
        "search": "Recherche..."
    },
    "en": {
        "overlay-loading-text": "Loading components...",
		"overlay-no-rows-text": "No component matching filtering",
        "search": "Searching..."
        }
    }
},
    mixins: [ ComposantMixins, EquipementsMixins ],

    data: function(){
        return {
            showLoadingOverlay: false,
            gridOptions:{
                headerHeight: 0,
                rowHeight: 110
            },
            columnDefs:[
                {
                    headerName: "libelComposant",
                    field: "libelComposant",
                    width: 60,
                    cellClass: ["vg-composant-selector-cellrender"],
                    suppressSizeToFit: false,
                    ////headerComponent: headerRendererMobile,
                    cellRenderer: (params) => new ComposantMobileCellRender({propsData: {params: params.data}}).$mount().$el
                },

            ],
            composants:null,
            metadatas:null,
            searchQuery:'',
            equipementGep:null,
            agfilters:{
                libelComposant: { attr:"c.libelComposant", colId: "libelComposant", value: null, action:"contains" },
                categorie: { attr:"cc.categorie", colId: "categorie_id", value: null, action:"equals" }
            }
        }
    },
    created:function(){
        this.metadatas = new Metadatas();

        let metadatasGep = new Metadatas();
        metadatasGep.setFilters({
            libelComposant: { attr:"e.isGroupEqp", colId: "e.isGroupEqp", value: "1", action:"equals" },
            lieu: { attr:"e.idLieu_id", colId: "lieu", value: this.idLieu, action:"equals" }
        });
        this.equipementsMixins_getEquipements(metadatasGep).then((equipements)=>{
            //console.log("equipementsMixins_getEquipements",equipements);
            this.equipementGep = equipements.datas[0];
            let categorieId = equipements.datas[0].idCategorie;
            this.agfilters.categorie.value = categorieId;
        });

    },
    watch:{
        searchQuery: {
            handler: function(query){
                if (query != null && query.length) {
                    let searchQuery = query.split(" ");
                    this.agfilters.libelComposant.value = searchQuery.join('","');
                }else {
                    this.agfilters.libelComposant.value = null;
                }
            }
        },
        agfilters:{
            handler:function(nfilters){
                //console.log("ag-filter change");
                this.metadatas.setFilters(this.agfilters);
                this.fetch();
            },
            deep:true
        },
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            // récupérer le GEP pour avoir son idCategorie
            // puis executer getComposant en passant comme filtre lidCategorie
            this.ComposantMixins_getComposants(this.metadatas).then((composants)=>{
                //console.log(composants);
                this.composants = composants;
                this.showLoadingOverlay = false;
            });

        },
        /**
         * action clique sur ligne du tableau
         * @params {Object} row
         * @emit {Object} row selectionner
         */
        handleClickRow: function(row){
            //console.log(row)
            row.data.equipement = this.equipementGep;
            this.$emit("input",row.data);
        }
    }
}
</script>

<style lang="scss" >
.vg-composant-selector-cellrender{
    display: flex;
    align-items: center;
}
.vg-composant-selector{
    height: 100%;

    .tableau{
        height: 90%;
    }
}
</style>
<docs>

    ### vg-composant-selector


    ```js
          import VgComposantSelect from "src/components/Vg/Selectors/VgComposantSelector.vue";
    ```

    #### basic usage

    ```html static
          <vg-composant-selector  @select-row="selectComposant"></vg-input>
    ```
    #### Emit : select-row

    Retourne la valeur selectionner, clique sur la row

    ### mixins

    ComposantMixins_getComposants

</docs>
