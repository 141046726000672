<template>
	<span>{{ getReadablePeriodicite }}</span>
</template>
<script>

    export default {
        name: 'vg-periodicite-viewer',
		components: {

		},
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "non-defini": "ND",
            "periodicite-1": "Journalière",
            "periodicite-7": "Hebdomadaire",
            "periodicite-14": "Bi-mensuelle",
            "periodicite-15": "Bi-mensuelle",
            "periodicite-21": "3 semaines",
            "periodicite-30": "Mensuelle",
            "periodicite-60": "Bimestrielle",
            "periodicite-90": "Trimestrielle",
            "periodicite-180": "Semestrielle",
            "periodicite-365": "Annuelle",
            "periodicite-730": "Biennale",
            "periodicite-1095": "Triannuelle",
            "periodicite-jours": "Tous les {jours} jours"
        },
        "en": {
            
        }
    }
},
        props: {
			/** value en minutes */
			value:{
				type: Number | String,
				default: null
			}
        },
        data: function() {
            return {
				
            };
        },
		created: function(){
			
		},
        computed: {
			getReadablePeriodicite: function(){
				if(this.value && Number(this.value) && [1,7,14, 15,21, 30,60,90,180,365, 1095].includes(Number(this.value))) return this.$t("periodicite-"+this.value);
				else if(this.value) return this.$t("periodicite-jours", {jours: this.value});
				else return this.$t("non-defini");
			},
		}
    };

</script>
<style style="scss" scoped>

</style>
