<template>
    <span v-if="fondDeMapExist && getGeoJsonPieces" class="geoJsonPieces">{{$t("oui")}}</span>
    <span v-else-if="fondDeMapExist">{{$t("non")}}</span>
    <span v-else></span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "plan-interactif-geo-json-pieces-cell-renderer",
        mixins:[LangMixins],
        mixins: [],
        props: {
			params: {
                type: Object,
                required: true
            },
            /**
            * affiche le draft ou pas
            */
            draft: {
                type: Boolean,
                default: false
            }
		},
        computed: {
            getGeoJsonPieces: function(){
                if(this.draft) return this.params.data.geoJsonPiecesDraft;
                else return this.params.data.geoJsonPieces;
            },
            fondDeMapExist: function(){
                return this.params.data.planInteractif;
            },
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "oui": "Oui",
            "non": "Non"
        },
        "en": {
            "oui": "Yes",
            "non": "No"
        }
    }
}
    });
</script>

<style lang="scss" scoped>
.geoJsonPieces{

}
</style>
