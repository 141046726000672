<template>
    <div style="display:flex;justify-content:flex-start;align-items:center;">
        <img src="static/assets/icone/equipement.png" height="15" style="margin:0 5px;"/> {{$t("equipements")}}
    </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
    name:"equipements-layer-control",
    components:{},
	props: {
		params: {
			type: Object,
			required: true
		},
		tooltipTitle: {
			type: String,
			default: "default tooltip title"
		},
		type: {
			type: String,
			default: "default-info"
		},
		label: {
			type: String,
			default: ""
		},
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "equipements": "Equipements"
        },
        "en": {
            "equipements": "Equipments"
        }
    }
},
    methods:{
        handleClick:function(){
            this.$emit("click",this.params);
        }
    }
});
</script>
