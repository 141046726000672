<template>
    <vg-fichesav-workingtime-viewer v-model="workingtime"/>
</template>

<script>
    import Vue from "vue";
    import VgFichesavWorkingtimeViewer from "src/components/Vg/FicheSAV/VgFichesavWorkingtimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "working-time-cell-render",
        mixins:[LangMixins],
		props: {
			workingtime: {
                type: String | Number,
                required: true
            }
		},
        components: {
            VgFichesavWorkingtimeViewer
        }
    });
</script>

<style scoped>

</style>
