<template lang="html">
	<vg-input
		:title="$t('title')">
		<vg-taches-selector v-model="focusedTache"
			:attributeValue="attributeValue"
			:multiple="multiple"
			:verbose="verbose"
			:filters="filters"
			:isLieux="isLieux"
			:isEquipement="isEquipement"
			:isCompteur="isCompteur"
			:isReleverGrandeurPhysique="isReleverGrandeurPhysique"
			@input="handleChange"
		>
		</vg-taches-selector>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgTachesSelector from "src/components/Vg/Selectors/VgTachesSelector.vue";

    export default {
        name: 'vg-filter-tache',
	    components: {
			VgInput,
			VgSelect,
			VgTachesSelector
        },
		mixins:[],
        props: {
			/**
			* @model
			*/
			value: {
				type: Object,
				default: null
			},
			/**
	        * attribut de l'objet tache à retourner comme valeur
	        * @default null
	        * si attributeValue=null retourne l'objet sélectionné
	        */
	        attributeValue: {
	            type: String,
	            default: null
	        },
			multiple: {
				type: Boolean,
				default: false
			},
			verbose: {
				type: Boolean,
				default: false
			},
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
	        * get tache lieux
	        */
	        isLieux:{
	            type:Boolean,
	            default:false
	        },
	        /**
	        * get tache lieux
	        */
	        isEquipement:{
	            type:Boolean,
	            default:false
	        },
	        /**
	        * get tache lieux
	        */
	        isCompteur:{
	            type:Boolean,
	            default:false
	        },
	        /**
	        * get tache lieux
	        */
	        isReleverGrandeurPhysique:{
	            type:Boolean,
	            default:false
	        }
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par tache"
        },
        "en": {
            "title": "Filter by task"
        },
        "th": {
            "title": "กรองโดยภารกิจ"
        }
    }
},
        data: function(){
            return {
				focusedTache: this.value ? this.value : null
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedTache = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			handleChange: function(e){
				this.$emit("input", this.focusedTache);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
