<template>
    <vg-todo-list style="width:100% !important;"
            @addItem="addTypologieMaintenance"
            @deleteItem="deleteTypologieMaintenance"
            v-model="TypologiesMaintenance"
        >
        <template v-slot:item="{item}">
            <div>{{item.name}}</div>
        </template>
    </vg-todo-list>  
</template>
<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgTodoList from "src/components/Vg/VgTodoList.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import TypologiesMaintenanceMixins from "src/mixins/TypologiesMaintenanceMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";

export default {
    name: 'vg-typologies-maintenance-form',
    components:{
        VgButton,
        VgInput,
        DialogSecuredAction,
        VgTodoList
    },
    mixins: [TypologiesMaintenanceMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            
        }
    }
},
    props: {

    },
    data: function() {
        return {
            metadatas: new Metadatas(),
            displayloadingOverlay:false,
            agfilters: {

            },
            selectedTypologieMaintenance: null,
            showCreate: false,
            showUpdate: false,
            showDelete: false,
            newTypologiesMaintenance: {
                name: null
            }
        };
    },
    watch:{

    },
    computed:{
        ...mapGetters({
            TypologiesMaintenance: 'TypologiesMaintenanceStore/getCollection'
        })
    },
    created: function() {
        this.fetch();
    },
    methods: {
        /**
         * @param boolean bool 
         */
        displayLoadingOverlay:function(bool){
            this.displayloadingOverlay = bool;
        },
        addTypologieMaintenance: function(name){
            this.showCreate = false;
            this.displayLoadingOverlay(true);
            this.TypologiesMaintenanceMixins_create([{ name }]).then((datas)=>{
                this.displayLoadingOverlay(false);
            });
        },
        /**
         * 
         * @param {TypologieMaintenance} typologieMaintenance 
         */
        deleteTypologieMaintenance: function(typologieMaintenance){
            this.showDelete = false;
            this.displayLoadingOverlay(true);
            this.TypologiesMaintenanceMixins_delete(typologieMaintenance).then((data)=>{
                this.displayLoadingOverlay(false);
            });
        },
        fetch: function(){
            this.displayLoadingOverlay(true);
            //this.metadatas.setFilters(this.agfilters);
            this.TypologiesMaintenanceMixins_get(this.metadatas).then((datas)=>{
                this.displayLoadingOverlay(false);
            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
