<template lang="html">
	<vg-input v-if="display=='dropdown'"
		:title="title?title:$t('title')">
		<vg-tiers-selector
			v-model="selectedTiers"
			:attributeValue="attributeValue"
			:filters="filters"
			@input="$emit('input',selectedTiers)">
		</vg-tiers-selector>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
    export default {
        name: 'vg-filter-tiers',
	    components: {
			VgInput,
			VgTiersSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: Array | String,
				required: true
			},
			title: {
				type: String,
				default: null
			},
			/**
			* attribut du tiers choisi renvoyé au v-model
			* default "id"
			* peut être null renvoie tout l'objet tiers
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			 * Donne au select la possibilité de contenir plusieur reponse
			 */
			multiple:{
				type: Boolean,
				default: false
			},
			/**
			 * 2version d'affichage du composant disponible, "dropdown" = avec vg-select || "modal" = avec modale de selection multiple
			 * @values dropdown || modal
			 */
			display:{
				type:String,
				default:"dropdown"
			},
			filters:{
				type: Object,
				default:()=>{
					return {};
				}
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par tiers"
        },
        "en": {
            "title": "Filter by third party"
        }
    }
},
        data: function(){
            return {
				selectedTiers: this.multiple ? [] : null
			};
        },
		watch: {
			value: {
				handler: function(val){
					if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && val[0].hasOwnProperty(this.attributeValue))
						this.selectedTiers = val;
					else if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && !val[0].hasOwnProperty(this.attributeValue))
						this.selectedTiers = this.sites.filter((site)=>val.includes(site[this.attributeValue]));
					else this.selectedTiers = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {

		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
