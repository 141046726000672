<template>
	<vg-modal
		:width="'400px'"
		:title="$t('title-modal')"
		:isSaveDisabled="disabledSave"
		@close="$emit('close')"
		@save="handleSave">
		<template #body>
			<div v-show="!showScanner">
				<vg-input :title="$t('input-select-title')">
					<vg-equipement-selector
						v-model="idEquipement"
						:equipementFilter="equipementFilter"
						@input="handleInputEquipement">
					</vg-equipement-selector>
				</vg-input>
				<hr>
				<div class="choix">
					<span>{{$t("ou")}} <b>{{$t("scanner-equipement")}}</b></span>
					<vg-button
						@click="showScanner=true">
						<img src="static/assets/icone/inventaire.png" height="30">
					</vg-button>
				</div>
			</div>
			<zxing-scanner v-if="showScanner"
				@scanSuccess="onScanSuccess"
				:isDemo="false"
				:code="'qrcode'" />
		</template>
	</vg-modal>
</template>
<script>

	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgInput from 'src/components/Vg/VgInput.vue';
	import VgEquipementSelector from 'src/components/Vg/Selectors/VgEquipementSelector.vue';
	import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';

    export default {
        name: 'vg-equipement-select-modal',
		components: {

			VgButton,
			VgInput,
			VgEquipementSelector,
			zxingScanner
		},
		mixins: [],
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-modal": "Modifier l'équipement",
            "ou": "ou",
            "scanner-equipement": "Scanner un qrCode",
            "input-select-title": "Sélectionner un équipement"
        },
        "en": {
            "title-modal": "Modify equipment",
            "ou": "or",
            "scanner-equipement": "Scan qrCode",
            "input-select-title": "Select equipment"
        },
        "th": {
            "title-modal": "แก้ไขอุปกรณ์",
            "ou": "หรือ",
            "scanner-equipement": "สแกนรหัสคิวอาร์",
            "input-select-title": "เลือกอุปกรณ์"
        }
    }
},
        props: {
			value: {
				type: String | Number,
				default: null
			}
        },
        data: function() {
            return {
				idEquipement: this.value,
				showScanner: false,
				equipementFilter: null
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			handleSave: function(){
				this.$emit("save", this.idEquipement);
				this.$emit("input", this.idEquipement);
			},
			onScanSuccess: function(qrCode){
				this.equipementFilter = {qrCode: qrCode};
				this.showScanner = false;
			},
			handleInputEquipement: function(idEquipement){
				this.idEquipement = idEquipement;
			},
		},
        computed: {
			disabledSave: function(){
				return this.value==this.idEquipement;
			}
		}
    };

</script>
<style lang="scss" scoped>
.choix{
    text-align: center;
}
</style>
