<template>
    <span v-if="!format && !onlyTime && datetime">{{getDate}} <small v-if="getTime && !hideTime">{{getTime}}</small></span>
    <span v-else-if="!format">{{getTime}}</span>
    <span v-else-if="format && format=='dddd MM/DD/YYYY HH:mm'">{{getdddd}} {{getDate}} {{getTime}}</span>
    <span v-else-if="format && format=='MMMM YYYY'">{{getMMMM}}</span>
</template>

<script>
export default {
  name: "vg-datetime-viewer",
  props: {
    /**
    *
    * @type String YYYY-MM-DD HH:mm:ss
    * @default null
    *
    **/
    value:{
        type: String,
        default: null
    },
    hideTime: {
        type: Boolean,
        default: false
    },
    onlyTime: {
        type: Boolean,
        default: false
    },
    /**
    * format = "dddd DD/MM/YYYY HH:mm" cf moment doc
    */
    format: {
        type: String,
        default: null
    },
    useDeviceTimezone: {
        type: Boolean,
        default: false
    }
  },
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "day-1": "Lundi",
            "day-2": "Mardi",
            "day-3": "Mercredi",
            "day-4": "Jeudi",
            "day-5": "Vendredi",
            "day-6": "Samedi",
            "day-7": "Dimanche",
            "month-01": "Janvier",
            "month-02": "Février",
            "month-03": "Mars",
            "month-04": "Avril",
            "month-05": "Mai",
            "month-06": "Juin",
            "month-07": "Juillet",
            "month-08": "Août",
            "month-09": "Septembre",
            "month-10": "Octobre",
            "month-11": "Novembre",
            "month-12": "Décembre"
        },
        "en": {
            "day-1": "Monday",
            "day-2": "Tuesday",
            "day-3": "Wednesday",
            "day-4": "Thursday",
            "day-5": "Friday",
            "day-6": "Saturday",
            "day-7": "Sunday",
            "month-01": "January",
            "month-02": "Februar",
            "month-03": "March",
            "month-04": "April",
            "month-05": "May",
            "month-06": "June",
            "month-07": "July",
            "month-08": "August",
            "month-09": "September",
            "month-10": "October",
            "month-11": "November",
            "month-12": "December"
        }
    }
},
  watch: {
      value: {
          handler: function(val){
              this.datetime = val? moment(val): null;
          }
      }
  },
  data:function() {
    return{
        datetime: this.value? moment(this.value): null
    }
  },
  methods: {

  },
    computed: {
        getdddd: function(){
            return this.$t("day-"+this.datetime.format("E"));
        },
        getMMMM: function(){
            return this.$t("month-"+this.datetime.format("MM"))+" "+this.datetime.format("YYYY");
        },
        getDate: function(){
            if(!this.useDeviceTimezone){ 
                return this.datetime.format("DD/MM/YYYY");
            }else{
                let serverDatetime = moment.utc(this.value);
                var localDate = moment(serverDatetime).local();
                return localDate.format("DD/MM/YYYY");
            }
        },
        getTime: function(){
            if(!this.useDeviceTimezone){ 
                return this.value && this.value.includes(":")?this.datetime.format("HH:mm"):null;
            }else{ 
                if(this.value && this.value.includes(":")){
                    let serverDatetime = moment.utc(this.value);
                    var localDate = moment(serverDatetime).local();
                    return localDate.format("HH:mm");
                }else return null;
            }
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
