<template>
	<div class="parametres-corps-detat">
		<span class="title">{{$t('title')}}</span>
		<div class="liste">
			<div v-for="corpsDetat in corpsDetats" :key="corpsDetat.id" class="corps-detat">
				<span>{{corpsDetat.name}}</span>
				<vg-button
					size="xs"
					type="default-danger"
					@click="handleClickCorpsDetatName(corpsDetat)">
					{{$t('update')}}
				</vg-button>
			</div>
		</div>
        <vg-button type="success" size="sm" @click="isCorpsDetatFormDisplayed = true">
            {{$t('add-corps-detat')}}
        </vg-button>
        <vg-corps-detat-form
            v-if="isCorpsDetatFormDisplayed"
            v-model="this.selectedCorpsDetat"
            @close="isCorpsDetatFormDisplayed = false" />
	</div>
</template>

<script>
    import CorpsDetatMixins from "src/mixins/CorpsDetatMixins.js";
    import VgCorpsDetatForm from "src/components/Vg/Forms/VgCorpsDetatForm.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'parametres-corps-detat',
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Liste des corps d'état",
            "add-corps-detat": "Ajouter un corps d'état",
            "update": "Modifier",
            "delete-corps-detat-button": "Supprimer"
        },
        "en": {
            "title": "Job categories list",
            "add-corps-detat": "Add technical field",
            "update": "Update",
            "delete-corps-detat-button": "Delete"
        }
    }
},
    	props:{

        },
        mixins:[CorpsDetatMixins],
        components: {
            VgCorpsDetatForm,
            VgButton
        },
        data() {
            return {
                isCorpsDetatFormDisplayed:false,
                userId: this.$app.appID,
            };
        },
		watch: {

		},
        methods: {
            /**
             * @param Object corpsDetat
             */
            handleClickCorpsDetatName:function(corpsDetat){
                this.$store.dispatch("CorpsDetatsStore/setSelectedItem", corpsDetat);
                this.isCorpsDetatFormDisplayed = true;
            }
        },
        computed: {
            ...mapGetters({
                  corpsDetats: 'CorpsDetatsStore/getCollection',
                  selectedCorpsDetat: 'CorpsDetatsStore/getSelectedItem'
            }),
        },
        mounted: function(){},
		created: function(){
            let metadatas = new Metadatas();
            this.CorpsDetatsMixins_getCorpsDetats(metadatas);
        }
    };
</script>
<style lang="scss" scoped>
.parametres-corps-detat{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
	.liste{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap:10px;
		width: 100%;
		.corps-detat{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap:10px;
			border-bottom: 1px solid whitesmoke;
			width: 100%;
			padding: 10px;
		}
	}
}
</style>
