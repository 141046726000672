<template>
	<div class="reponse-nombre">
		<span class="text-reponse" @click="onClickReponse">
			{{reponse.checkpoint.question}} <span style="font-size: 12px;" v-if="reponse.checkpoint.seuils && reponse.checkpoint.seuils[0]">({{reponse.checkpoint.seuils[0].unite}})</span>
		</span>
		<div class="input-reponse">
			<vg-input-number
				v-model="reponse.reponse"
				@input="onChangeNumber"
                :placeholder="reponse.reponse" />
		</div>
	</div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import VgInputNumber from 'src/components/Vg/VgInputNumber.vue';

export default {
    name: 'reponse-nombre',
    components:{
        VgInputNumber
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "checkCompteur": {
                "mobile": {
                    "min": "min",
                    "max": "max"
                }
            }
        },
        "en": {
            "checkCompteur": {
                "mobile": {
                    "min": "min",
                    "max": "max"
                }
            }
        },
        "th": {
            "checkCompteur": {
                "mobile": {
                    "min": "ต่ำสุด",
                    "max": "สูงสุด"
                }
            }
        }
    }
}       ,
    mixins:[],
    props:{
        tache: Object,
		reponse: Object
    },

    filters : {

    },
    data: function() {
        return {

        };

    },
    computed: {

    },
    methods: {
        onChangeNumber: function(value){
			// //console.log("ON CHANGE NUMBER", value);
			this.$emit("change", value);
		},
        onClickReponse: function(){
			this.$emit('select', this.reponse);
		},
    },
    created: function(){
		var that = this;
		// this.reponse.reponse = null ;
		if (this.reponse.reponse===null) {
			this.reponse.reponse=0;
		}
    },
    mounted: function(){

    }
};
</script>

<style lang="scss" scoped>
	.reponse-nombre{
		width: 100%;
		padding: 20px 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		.text-reponse{
			color: #74767a;
			font-size: 16px;
		}
		.input-reponse{

		}
	}
</style>
