<template>
    <div>
        <vg-modal
            v-show="!isModalConfirmDeleteOpened || !showModalCreateSousTraitant"
            :title="title"
            @close="$emit('close')">
            <template #body>
                <form ref="tiers-form" onSubmit="false">
                    <vg-input
                        isRequiredValue
                        v-model="tiers.name"
                        :title="$t('tiers-form-name')">
                    </vg-input>

                    <vg-input
                        isRequiredValue
                        :title="$t('tiers-form-type')">
                        <vg-tiers-type-selector
                            v-model="tiers.type"
                            :clearable="false"/>
                    </vg-input>
                    <vg-input v-model="tiers.address"
                        :title="$t('tiers-form-address')"
                        :subtitle="isACustomer?$t('tiers-form-address-format'):null"
                        inputType="textarea"/>
                    <vg-input v-if="!isACustomer"
                        isRequiredValue
                        :title="$t('tiers-form-tags')">
                        <vg-tiers-tags-selector
                            v-model="tiers.tags" />
                    </vg-input>

                    <vg-input
                        v-model="tiers.description"
                        :title="$t('tiers-form-description')"
                        inputType="textarea" />
                    <vg-input :title="$t('lieux')">
                        <vg-lieux-selector
                            v-model="tiers.lieux"
                            :filters="lieuxFilters"
                            @change="handleChangeAffectationSite"
                            multiple />
                    </vg-input>
                    <input v-show="false" type="submit" ref="submitTiers" />
                </form>
                <vg-button v-if="$app.account_type=='prestataire' && tiers.type=='Client'"
                    type="success"
                    @click="showCreationSite=true;">
                    {{$t("add-sites")}}
                </vg-button>
                <vg-lieu-form v-if="showCreationSite"
                    isClient
                    @close="showCreationSite=false;"
                    @created="onCreatedSite"/>
            </template>
            <template  #footer>
                <vg-button
                    type="default"
                    @click="$emit('close')"
                    >
                    {{$t("tiers-form-cancel")}}
                </vg-button>

                <vg-button v-if="isAnUpdateTiersForm"
                    type="danger"
                    @click="isModalConfirmDeleteOpened = true"
                    :disabled="$app.role!='ROLE_ADMIN'">
                    {{$t("tiers-form-delete")}}
                </vg-button>

                <vg-button v-if="isAnUpdateTiersForm"
                    type="default-danger"
                    @click="handleUpdateTiers"
                    >
                    {{$t("tiers-form-update")}}
                </vg-button>

                <vg-button v-if="!isAnUpdateTiersForm"
                    type="success"
                    @click="handleCreateTiers"
                    >
                    {{$t("tiers-form-save")}}
                </vg-button>
              </template>
        </vg-modal>
        <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="tiers.name"
          @save="handleDeleteTiers"
          @close="isModalConfirmDeleteOpened = false"
        >
          <template v-slot:header-title>
            {{ $t("tiers-form-delete") }} <b>{{ tiers.name }}</b> ?
          </template>
          <template v-slot:warning>
            {{ $t("tiers-form-feedback-before-delete") }}
          </template>
        </dialog-secured-action>

        <vg-sous-traitant-form v-if="showModalCreateSousTraitant"
            :tiers="tiers"
            @close="handleCloseSousTraitantForm">
        </vg-sous-traitant-form>
    </div>

</template>
<script>
    import TiersMixins from "src/mixins/TiersMixins.js";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector";
    import VgTiersTypeSelector from "src/components/Vg/Selectors/VgTiersTypeSelector.vue";
    import VgTiersTagsSelector from "src/components/Vg/Selectors/VgTiersTagsSelector.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgSousTraitantForm from 'src/components/Vg/Forms/VgSousTraitantForm.vue';
    import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";
    import AdressValidator from "src/services/AdressValidator.js";
    export default {
        name: 'vg-tiers-form',
        mixins: [ TiersMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            VgSelect,
            VgLieuxSelector,
            VgTiersTypeSelector,
            VgTiersTagsSelector,
            DialogSecuredAction,
            VgSousTraitantForm,
            VgLieuForm
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "tiers-form-cancel": "Annuler",
            "tiers-form-save": "Enregistrer",
            "tiers-form-update": "Modifier",
            "tiers-form-delete": "Supprimer",
            "tiers-form-add": "Ajouter",
            "tiers-form-name": "Nom",
            "tiers-form-type": "Type",
            "tiers-form-address": "Adresse",
            "tiers-form-address-format": "Veuillez saisir l'adresse au format: \" 9 rue renaudiere de vaux, Le Port 97420, La Réunion \"",
            "tiers-form-tags": "Etiquette",
            "tiers-form-description": "Commentaire",
            "lieux": "Affectation aux sites",
            "tiers-form-feedback-before-delete": "Attention supprimer ce Tiers supprimera son historique d'interventions ainsi que les contrats qui y sont liés",
            "tiers-form-tiers": "Tiers",
            "add-sites": "Nouveau site",
            "invalid-adress": "L'adresse saisie n'est pas valide"
        },
        "en": {
            "tiers-form-cancel": "Cancel",
            "tiers-form-save": "Save",
            "tiers-form-update": "Update",
            "tiers-form-delete": "Delete",
            "tiers-form-add": "Add",
            "tiers-form-name": "Name",
            "tiers-form-type": "Type",
            "tiers-form-address": "Address",
            "tiers-form-address-format": "Format: \" 9 rue renaudiere de vaux, Le Port 97420, La Réunion \"",
            "tiers-form-tags": "Tag",
            "tiers-form-description": "Comment",
            "lieux": "Acting perimeter",
            "tiers-form-feedback-before-delete": "Warning delete this provider will delete its interventions history and its linked contracts",
            "tiers-form-tiers": "Provider",
            "add-sites": "Add site",
            "invalid-adress": "Invalid mail format"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            },
            uid: {
                type: Number | String,
                default: null
            },
            skipVueXStorage: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                tiers:{
                    name: null,
                    type: null,
                    address: null,
                    description: null,
                    userId: this.$app.appID,
                    tags: null,
                    rate: null,
                    lieux: []
                },
                isModalConfirmDeleteOpened:false,
                showModalCreateSousTraitant:false,
                showCreationSite:false,
                newTiers: null
            };
        },
        computed:{
            lieuxFilters: function(){
                return {
                    type_lieu: {attr: "type_lieu", value: "Site", action: "equals"}
                };
            },
            isAnUpdateTiersForm:function(){
                if(this.uid && this.skipVueXStorage && this.tiers) return this.tiers.id;
                else if(this.value) return this.value.hasOwnProperty('id');
                else return false;
            },
            title:function(){
                return !this.isAnUpdateTiersForm ?
                this.$t("tiers-form-add")+" : "+this.$t("tiers-form-tiers") :
                this.$t("tiers-form-update")+" : "+this.$t("tiers-form-tiers") ;
            },
            isACustomer:function(){
                return this.tiers.type=='Client' || this.tiers.type=='Customer';
            }
        },
        created: function() {
            if(this.uid && this.skipVueXStorage) this.fetchTiers();
            else this.tiers = Object.assign({}, this.tiers, this.value);
        },
        methods: {
            fetchTiers: function(){
                this.TiersMixins_getTiersId(this.uid, {skipVueXStorage: this.skipVueXStorage}).then((tier)=>{
                    this.tiers = Object.assign({}, this.tiers, tier);
                });
            },
            onCreatedSite: function(sites){
                this.showCreationSite = false;
                this.tiers.lieux.push(sites[0].id);
            },
            /**
             * @param {string}
             * @return {boolean}
             */
            isValidAdress(adress){
                return AdressValidator.validate(adress);
            },
            /**
             * handleChangeAffectationSite.
             *
             * @param  {array} lieux_id id list of lieu
             */
            handleChangeAffectationSite:function(lieux_id){
                this.tiers.lieux = lieux_id;
            },
            handleUpdateTiers:function(){
                this.TiersMixins_update(this.tiers, {skipVueXStorage: this.skipVueXStorage}).then((tier)=>{
                    this.$emit("updated", tier);
                });
            },
            handleCreateTiers:function(){
                if(this.isACustomer && !this.isValidAdress(this.tiers.address)){
                    alert(this.$t("invalid-adress"));
                    return;
                }
                this.TiersMixins_create(this.tiers).then((datas)=>{
                    this.newTiers = datas;
                    if(this.tiers.type!="Client") this.showModalCreateSousTraitant = true;
                    else this.$emit("created", datas); // liste tiers
                });
            },
            handleDeleteTiers:function(){
                this.TiersMixins_delete(this.tiers).then(()=>{
                    this.$emit("deleted");
                    this.$emit("close");
                });
            },
            handleCloseSousTraitantForm:function(){
                this.$emit("created", this.newTiers);
            }
        }
    };
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-tiers-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-tiers-form></vg-tiers-form>
    ```

    ### Create form tiers
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-tiers-form>
            </vg-tiers-form>

        </div>
      `
    })
    ```
    ### update form tiers
    ```js
    new Vue({
      data(){
        return {
            tiers:{id:1}
        }
      },
      template: `
        <div>
            <vg-tiers-form v-model="tiers">
            </vg-tiers-form>

        </div>
      `
    })
    ```
</docs>
