<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:limit="limit"
		:limitSelectorText="$t('limit-selector-text')"
		:defaultDateIntervalle="defaultDateIntervalle"
		key="dashboard-curatif-rapport-repartition-tiers-affecte"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<vg-bar-chart v-if="repartitionTiersAffecte"
			class="chart-bar"
			:chart-id="'dashboard-curatif-repartition-tiers-affecte'"
			:height="'100%'"
			:horizontalaxis="xAxis"
			:datas="getDatas"
			:options="optionsBar"
			:showlegend="false"
			@selected="onSelectedBar"/>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-repartition-tiers-affecte',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport,
			VgButton
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},
				xAxis: [],
				optionsBar: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 8,
								precision: 0
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks:{
								mirror: true,
								fontSize: 10,
								callback: (label, index, labels)=>{
									if(labels.length>1){
										let labelSplitted = label.split(" ");
										let result = [];
										let encoreDeLaPlace = null;
										let graphWidth = document.getElementById("dashboard-curatif-repartition-tiers-affecte").width;
										let barWidth = graphWidth/labels.length;
										let pxParCaractere = 4; // 4 px réservé par caractère
										let generalMaxCaractere = labels.length>=7?15:20;
										let nombreMaxCaractereInline = barWidth/pxParCaractere<=generalMaxCaractere?barWidth/pxParCaractere:generalMaxCaractere;
										labelSplitted.forEach((item, i) => {
											if(item.length>=nombreMaxCaractereInline){	// si mot trop long
												if(encoreDeLaPlace) result.push(encoreDeLaPlace);
												result.push(item);
												encoreDeLaPlace = null;
											}else{ // sinon mot plus court
												if(encoreDeLaPlace){
													if(encoreDeLaPlace.length+1+item.length<=nombreMaxCaractereInline){
														encoreDeLaPlace += " "+item;
													}else{
														result.push(encoreDeLaPlace);
														encoreDeLaPlace = item;
													}
												}else{
													encoreDeLaPlace = item;
												}
											}
										});
										if(encoreDeLaPlace) result.push(encoreDeLaPlace);
										return result;
									}else return label;
								}
							}
						}]
					},
					animation: {
						easing: "linear",
						onProgress: (e)=>this.drawCustomBarElements(e),
						onComplete: (e)=>this.drawCustomBarElements(e)
					}
				},
				datasets:[
					{
						label: this.$t("bar-hover-label-affectees"),
						data: [],
						backgroundColor: "#87b0dd",
						borderColor: "#87b0dd"
					},
					{
						label: this.$t("bar-hover-label-cloturees"),
						data: [],
						backgroundColor: "#3baa36",
						borderColor: "#333"
					}
				]
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "REPARTITION des fiches curatives affectées/clôturées par tiers",
            "limit-selector-text": "Tiers affichés",
            "precedents": "Voir les {limit} précédents",
            "suivants": "Voir les  {limit} suivants",
			"bar-hover-label-affectees": "Nombre de fiches curatives affectées au tiers",
			"bar-hover-label-cloturees": "Nombre de fiches curatives affectées au tiers clôturées"
        },
        "en": {
            "title": "Curative sheets assigned/closed per Thrid Party",
            "limit-selector-text": "Thrid Party displayed",
            "precedents": "View previous {limit}",
            "suivants": "View next {limit}",
			"bar-hover-label-affectees": "Number of curative sheets assigned to the Thrid Party",
			"bar-hover-label-cloturees": "Number of curative sheets assigned to the Thrid Party closed"
        }
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			onSelectedBar: function(bar){
				console.log("SELECTED BAR", bar);
				let barSelected = this.getRepartitionTiersAffecte[bar.index];
				let filters = [];
				let attributes = ["affectes", "clotures"];
				if(attributes[bar.datasetIndex]=="affectes") filters.push({attr: "aff.dateAffectation", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"});
				else if(attributes[bar.datasetIndex]=="clotures") filters.push({attr: "fm.dateFermetureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"});
				this.$emit("selected", {bar: barSelected, idTiersSelected: barSelected.id, filters: filters, dateIntervalle: this.dateIntervalle});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				this.metadatas.setFilters(filters);
				if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getCuratifRepartitionTiersAffecte(this.metadatas, this.dateIntervalle.startDate, this.dateIntervalle.endDate).then((datas)=>{
					
				});
			},
			drawCustomBarElements: function(e){
				let ctx = e.chart.ctx;
				let datasets = e.chart.config.data.datasets;
				let meta = null;
			},
		},
        computed: {
			...mapGetters({
	            repartitionTiersAffecte: 'DashboardStore/getCuratifRepartitionTiersAffecte',
	        }),
			getRepartitionTiersAffecte: function(){
				let repartitionTiersAffecte = [...this.repartitionTiersAffecte];
				repartitionTiersAffecte.sort((tiers1, tiers2)=>{
					if(tiers1.total_counters==tiers2.total_counters) return 0;
					else if(tiers1.total_counters<tiers2.total_counters) return 1;
					else return -1;
				});
				return repartitionTiersAffecte;
			},
			getDatas: function(){
				let xAxis = [];
				let datasets = [
					{
						label: this.$t("bar-hover-label-affectees"),
						data: [],
						backgroundColor: "#87b0dd",
						borderColor: "#87b0dd"
					},
					{
						label: this.$t("bar-hover-label-cloturees"),
						data: [],
						backgroundColor: "#3baa36",
						borderColor: "#3baa36"
					}
				];
				this.getRepartitionTiersAffecte.forEach((tier)=>{
					xAxis.push(tier.name);
					datasets[0].data.push(tier.affectes);
					datasets[1].data.push(tier.clotures);
				});
				this.xAxis = xAxis;
				return datasets;
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
