<template>
    <vg-modal
        :title="$t('bon-dentree-details')+' '+bonDentree.numero"
        @close="$emit('close')">
        <template #body>
            <span>{{$t('date-creation')}}: <vg-datetime-viewer v-model="bonDentree.dateCreation"/></span>
            <br>
            <span>{{$t('fournisseur')}}: {{bonDentree.fournisseur.name}}</span>
            <br>
            <span>{{$t('createur')}}: {{bonDentree.createur.nom}} {{bonDentree.createur.prenom}}</span>
            <br>
            <span>{{$t('signataire')}}: {{bonDentree.signataire}}</span>
            <br>
            <span>{{$t('description')}}: {{bonDentree.description}}</span>
            <hr>
            <vg-files-uploader
                :tagsList="VgFilesMixins.tags"
                @upload-done="VgFilesMixins_fetchFilesByUid();">
            </vg-files-uploader>
            <vg-files-viewer-list-section v-if="VgFilesMixins_filesListSection"
                :collapseByDefault="false"
                :files="VgFilesMixins_filesListSection"
                @delete="VgFilesMixins_fetchFilesByUid()">
            </vg-files-viewer-list-section>
        </template>
        <template #footer>
            <vg-button type="default-success" @click="$emit('close')">
                {{$t("fermer")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>
import BonsDentreeMixins from "src/mixins/BonsDentreeMixins";

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

import { mapGetters } from 'vuex';
export default {
    name: 'vg-bons-dentree-modal',
    components:{
        VgModal,
        VgButton,
        VgDatetimeViewer,
        VgFilesViewerListSection,
        VgFilesUploader
    },
    mixins:[ BonsDentreeMixins ],
    props: {

    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "fermer": "Fermer",
            "bon-dentree-details": "Détails bon d'entrée",
            "date-creation": "Date création",
            "fournisseur": "Fournisseur",
            "createur": "Créé par",
            "signataire": "Signataire",
            "description": "Commentaire"
        },
        "en": {}
    }
},
    data: function() {
        return {

        };
    },
    watch: {

    },
    created:function(){
        this.VgFilesMixins_init(this.bonDentree.uid, "bon-dentree");
    },
    computed: {
        ...mapGetters({
            bonDentree: 'BonsDentreeStore/getSelectedItem'
        })
    },
    methods:{

    }
};
</script>
<style lang="scss" scoped>

</style>
