<template>

    <vg-modal
        :title="msg"
        @close="handleCancel">

        <template #body>
            <slot>
            </slot>
        </template>

        <template #footer>
            <vg-button type="default-info" @click="handleCancel"> {{$t("fmDialog.btn-Cancel")}} </vg-button>
            <vg-button type="success" @click="handleSuccess"> {{$t("fmDialog.btn-Confirm")}} </vg-button>
        </template>

    </vg-modal>

</template>

<script>
import VgButton from "src/components/Vg/VgButton.vue";
export default {
  name: 'fm-dialog-notif',
  components:{
      VgButton
  },
  props:{
    fm: Object,
    msg:String,
    actions: Object,
    displayDialog:Boolean,

},
i18n:    { "locale":navigator.language,
    "messages": {
        "en": {
            "fmDialog": {
                "btn-Cancel": "Cancel",
                "btn-Confirm": "Confirm"
            }
        },
        "fr": {
            "fmDialog": {
                "btn-Cancel": "Annuler",
                "btn-Confirm": "Confirmer"
            }
        },
        "th": {
            "fmDialog": {
                "btn-Cancel": "ยกเลิก",
                "btn-Confirm": "ยืนยัน"
            }
        }
    }
},
  data :function () {
      return {}
  },
  methods: {
    handleSuccess : function(){

      // //console.log("Success");
      this.$emit("fm-dialog-notifier-success");
      this.$emit("close");

    },
    handleCancel : function(data){
      // //console.log("Cancel");

      //this.$emit("fm-dialog-notifier-cancel",data);
      this.$emit("close");
    },
  },
}
</script>

<style lang="css" scoped>
</style>
