<template>
    <vg-select
        v-model="etiqueter"
        :options="etatsEtiquetage"
        :attributeValue="'value'"
        @input="$emit('input',etiqueter)"
    >
    </vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-equipement-etiquetage-selector',
		components: {
			VgSelect
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "yes": "Oui",
            "no": "Non",
            "not-set": "Non renseigné"
        },
        "en": {
            "yes": "Yes",
            "no": "No",
            "not-set": "No information"
        }
    }
},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: Boolean,
				default: null
			}
        },
        data: function() {
            return {
                etiqueter:this.value,
                etatsEtiquetage:[
					{label: this.$t("yes"), value: "1"},
					{label: this.$t("no"), value: "0"},
					{label: this.$t("not-set"), value: null}
				]
            };
        },
		watch: {},
		created: function(){},
		mounted: function(){},
		methods: {},
        computed: {}
    };

</script>
<style lang="scss" scoped>

</style>
