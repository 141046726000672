<template>
    <div style="height:100%">
        <tag-grid
            :idTableau="'batiments-tableau-recap'"
            v-model="gridOptions"
            :columnDefs="columnDefs"
            :rowData="batiments"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-dbl-click="targetBatiment"
             />
         <vg-pagination v-if="paginate"
             :totalItems="counters?counters.batiments:0"
             :offset="offset"
             :limit="limit"
             @pagination-change="handlePaginationChange"
         />

    </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins";
import Metadatas from "src/services/Metadatas";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";
import LibelLieuCellRender from "src/components/Vg/TagGrid/LibelLieuCellRender.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    name: 'vg-batiments-table',
	props: {},
    mixins:[LieuMixins],
	components:{
        TagGrid,
        VgButton,
        LibelLieuCellRender,
        VgPagination
	},
    props:{
        /**
        *
        * filters for metadatas.
        *
        */
        filters:{
            type: Array,
            default: function(){
                return [];
            }
        },
        displayMap:{
            type:Boolean,
            default: false
        },
        displayCard:{
            type: Boolean,
            default: false
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: true
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des batiments...",
		"overlay-no-rows-text": "Aucun site correspondant au filtrage",
        "intitule": "Intitulé",
        "shob": "SHOB",
        "famille": "Famille",
        "typeErp": "Type ERP",
        "categorieErp": "Catégorie ERP",
        "dateProchaineCommissionDeSecurite": "Date Prochaine commission de securité"
    },
    "en": {
        "overlay-loading-text": "Loading batiments...",
		"overlay-no-rows-text": "No site matching filtering",
        "intitule": "Title",
        "shob": "GSA (Grosse Surface Area)",
        "famille": "Family",
        "typeErp": "ERP type",
        "categorieErp": "ERP category",
        "dateProchaineCommissionDeSecurite": "Date Next security committee"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: '',
                    colId: 'checkbox',
                    width: 50,
                    maxWidth: 50,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    pinned: 'left',
                    isVisible: false
                },
                {
                    headerName: this.$t("intitule"),
                    width: 180,
                    field: 'libel_lieu',
                    colId: 'libel_lieu',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme", "vg-important-cell"],
                    cellStyle: {
                        fontWeight: 'bold'
                    },
                    cellRenderer: (params) => new LibelLieuCellRender({
                        propsData: {
                            icon: "static/assets/icone/circle/site-cercle.png",
                            params: params
                        }
                    }).$mount().$el
                }
            ],
            agfilters:{
                path:{action:"contains",attr: "l.path",colId: "l.path",value: null},
                famille:{action:"equals",attr: "l.famille",colId: "l.famille",value: null},
                type_lieu:{action:"equals",attr: "l.type_lieu",colId: "l.type_lieu",value: "Batiment"},
                //isGroupEqpSite:{"attr":"e.isGroupEqpSite","value":1,"action":"equals"}
            },
            metadatas: new Metadatas()
        };
    },
    watch:{
        filters:{
            handler:function(newFilters){
                this.agfilters = Object.assign({}, this.agfilters, newFilters);
                this.fetch();
            },
            deep:true
        }
    },
    mounted:function(){
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.agfilters);
            this.LieuMixins_getLieux(this.metadatas).then((datas)=>{
                this.batiments = datas.lieux;
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit targetRow on dblClick row.
        *
        * @param Object row
        * @event targetRow
        */
        targetBatiment:function(row){
            this.$emit("targetRow",row);
            this.$store.dispatch("LieuxStore/setSelectedItem",row.data);
            this.$router.push({ name: '_batiment_id', params: { id: row.data.id }});
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    },
	computed: {
        ...mapGetters({
            counters: 'LieuxStore/getCounters'
        })
    }
};
</script>
<style lang="scss" scoped>
	span{
		small{
			/*color: grey;*/
		}
	}
</style>
