<template>
	<vg-modal :title="$t('title')"
		@close="$emit('close')"
		@save="handleSave">
		<template #body>
			<vg-input
				:title="$t('composant-icon')"
				:isRequiredValue="true">
				<vg-composant-icon-selector v-if="!composant.icon"
					v-model="composant.icon">
				</vg-composant-icon-selector>
				<div v-else>
					<div class="option">
						<img :src="composant.icon" width="20px" height="20px"/>
						<vg-button @click="composant.icon=null">
							<i class="fas fa-eraser"></i>
						</vg-button>
					</div>
				</div>
			</vg-input>
			<vg-input
				:title="$t('composant-libel')"
				:isRequiredValue="true"
			>
				<vg-input
					v-model="composant.libelComposant"
					:placeholder="$t('placeholder-input-composant')"
				>
				</vg-input>
			</vg-input>
			<vg-input
				:title="$t('composant-type')"
				:isRequiredValue="true"
			>
				<vg-select
					v-model="composant.typeComposant"
					:options="typesComposant"
					:attributeValue="'label'"
					:placeholder="$t('placeholder-select-type')"
				>
				</vg-select>
			</vg-input>
		</template>
		<template #footer>
            <vg-button
                :type="'danger'"
                @click="showConfirmDeleteModal=true">
                <i class="fas fa-trash-alt"></i>
            </vg-button>
            <dialog-secured-action v-if="showConfirmDeleteModal"
                :validResponse="composant.libelComposant"
                @save="handleDelete"
                @close="showConfirmDeleteModal=false">
                <h3 slot="header-title">{{$t("confirm-delete-composant")}}</h3>
            </dialog-secured-action>
            <vg-button @click="$emit('close')">{{$t("close")}}</vg-button>
            <vg-button :type="'success'" @click="handleSave">{{$t("save")}}</vg-button>
        </template>
	</vg-modal>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

	import VgComposantIconSelector from "src/components/Vg/Selectors/VgComposantIconSelector.vue";

	import ComposantMixins from "src/mixins/ComposantMixins.js";
	import TagsMixins from "src/mixins/TagsMixins.js";
    export default {
        name: 'vg-composants-update',
		components: {

			VgInput,
			VgButton,
			VgSelect,
			DialogSecuredAction,
			VgComposantIconSelector
		},
		mixins:[ ComposantMixins, TagsMixins ],
        props: {
			value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
				composant: this.value,
				typesComposant: [],
				showConfirmDeleteModal: false
            };
        },
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Création composant",
            "alarme-incendie": "Alarme incendie",
            "composant-icon": "Icône",
            "placeholder-input-composant": "Libellé composant",
            "placeholder-select-type": "Sélectionner un type",
            "placeholder-select-icon": "Sélectionner une icone",
            "composant-libel": "Libellé",
            "composant-type": "Type",
            "composant-type-sanitaire": "Sanitaire",
            "composant-type-menuiserie": "Menuiserie",
            "composant-type-composant-du-bati": "Composant du bâti",
            "composant-type-plomberie": "Plomberie",
            "composant-type-accessoire": "Accessoire",
            "composant-type-appareil-electrique": "Appareil électrique",
            "composant-type-eclairage": "Eclairage",
            "composant-type-mobilier": "Mobilier",
            "composant-type-securite": "Sécurité",
            "composant-type-controle-acces": "Contrôle accès",
            "composant-type-exterieur": "Exterieur",
            "composant-type-image-son": "Image/son",
            "composant-type-telephonie": "Téléphonie",
            "composant-type-confort": "Confort",
            "composant-type-appareil-cuisine": "Appareil cuisine",
            "composant-type-appareil-froid": "Appareil froid",
            "close": "Fermer",
            "save": "Enregistrer",
            "confirm-delete-composant": "Ce composant peut être lié à plusieurs catégories lieu, voulez vous vraiment le supprimer? Cette suppression entrainera sa suppression pour toutes les catégories lieu associées.",
            "composant-delete-error-message": "Impossible de supprimer ce composant."
        },
        "en": {
            "title": "Component creation",
            "alarme-incendie": "Fire alarm",
            "composant-icon": "Icon",
            "placeholder-input-composant": "Component name",
            "placeholder-select-type": "Select type",
            "placeholder-select-icon": "Select an icon",
            "composant-libel": "Name",
            "composant-type": "Type",
            "composant-type-sanitaire": "Sanitary",
            "composant-type-menuiserie": "Carpentry",
            "composant-type-composant-du-bati": "Built component",
            "composant-type-plomberie": "Plumbing",
            "composant-type-accessoire": "Accessory",
            "composant-type-appareil-electrique": "Electrical appliance",
            "composant-type-eclairage": "Lighting",
            "composant-type-mobilier": "Furniture",
            "composant-type-securite": "Security",
            "composant-type-controle-acces": "Acces control",
            "composant-type-exterieur": "Outside",
            "composant-type-image-son": "Image/sound",
            "composant-type-telephonie": "Phone",
            "composant-type-confort": "Comfort",
            "composant-type-appareil-cuisine": "Kitchen appliance",
            "composant-type-appareil-froid": "Cold appliance",
            "close": "Close",
            "save": "Save",
            "confirm-delete-composant": "This component can be linked to more than one location category, do you really want to delete it? This will result in deleting it for all associated location categories.",
            "composant-delete-error-message": "This component can not be deleted"
        },
        "th": {
            "title": "การสร้างชิ้นส่วน",
            "alarme-incendie": "สัญญาณเพลิงไหม้",
            "composant-icon": "ไอคอน",
            "placeholder-input-composant": "ชื่อชิ้นส่วน",
            "placeholder-select-type": "เลือกประเภท",
            "placeholder-select-icon": "เลือกไอคอน",
            "composant-libel": "ชื่อ",
            "composant-type": "ประเภท",
            "composant-type-sanitaire": "สุขาภิบาล",
            "composant-type-menuiserie": "งานไม้",
            "composant-type-composant-du-bati": "ชิ้นส่วนที่สร้างแล้ว",
            "composant-type-plomberie": "ระบบประปา",
            "composant-type-accessoire": "อุปกรณ์เสริม",
            "composant-type-appareil-electrique": "เครื่องใช้ไฟฟ้า",
            "composant-type-eclairage": "แสงสว่าง",
            "composant-type-mobilier": "เฟอร์นิเจอร์",
            "composant-type-securite": "ความปลอดภัย",
            "composant-type-controle-acces": "ควบคุมการเข้าถึง",
            "composant-type-exterieur": "ด้านนอก",
            "composant-type-image-son": "รูปภาพ/เสียง",
            "composant-type-telephonie": "โทรศัพท์",
            "composant-type-confort": "ความสะดวกสบาย",
            "composant-type-appareil-cuisine": "เครื่องใช้ในครัว",
            "composant-type-appareil-froid": "เครื่องทำความเย็น",
            "close": "ปิด",
            "save": "บันทึก",
            "confirm-delete-composant": "ชิ้นส่วนนี้สามารถเชื่อมโยงได้มากกว่าหนึ่งหมวดหมู่ที่ตั้ง ต้องการมันลบจริงหรือ? การลบครั้งนี้จะส่งผลให้ลบหมวดที่ตัั้งที่เกี่ยวข้อง",
            "composant-delete-error-message": "ชิ้นส่วนนี้ไม่สามารถลบได้"
        }
    }
},
		created: function(){
			this.TagsMixins_getTags("composant/type").then(
				(datas)	=>	this.typesComposant = this.TagsMixins_listComposantType(datas)
			);
		},
		methods: {
			handleSave: function(){
				this.ComposantMixins_putComposant(this.composant).then((datas)=>{
					this.$emit("success", datas[0]);
				});
			},
			handleDelete: function(){
				this.ComposantMixins_deleteComposant(this.composant.id).then((datas)=>{
					this.$emit("delete-composant");
				}).catch((err)=>{
					alert(this.$t("composant-delete-error-message"));
					this.$emit("success");
				});
			}
		},
        computed: {
			isSaveDisabled: function(){
				return !this.composant.libelComposant || !this.composant.typeComposant || !this.composant.icon;
			}
		}
    };

</script>
<style lang="scss" scoped>
.option{
	display: flex;
	justify-content: flex-start;
	>img{
		margin-right: 10px;
	}
}
</style>
