<template>
    <div class="operation-tache-a-prevoir">
        <vg-checkbox
            :inputValue="operation.statut!='en_cours'"
            :defaultChecked="operation.statut!='en_cours'"
            style="width:10%;margin-top:2px;"
            @checked="onCheckOperation"
            @unchecked="onUnCheckOperation"/>
        <div class="main-content">
            <span style="font-weight:bold;">{{operation.operation}}</span>
            <vg-fichesav-description :fm="fm" :showIcon="false" style="margin: 5px 0;"/>
            <vg-lieu-path-viewer v-model="fm.equipement.path" style="line-height: 12px !important;margin-bottom:5px;"/>
            <div class="footer-content">
                <span style="text-decoration:underline;color:#3999ff;font-weight:bold;" @click="goToMaintenance">{{$t("fm-numero", {id: fm.id})}}</span>
                <span style="font-size:11px !important;">{{$t("cree-le")}} <vg-datetime-viewer v-model="fm.dateOuvertureSAV"/></span>
                <vg-button
                    :type="'info'"
                    :size="'xs'"
                    @click="onClickCommenter">
                    {{$t("commenter")}}
                </vg-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";


    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

    import VgOperationViewer from "src/components/Vg/Operation/VgOperationViewer.vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "operation-tache-a-prevoir-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true
			},
            fm: {
                type: Object,
                required:true
            }
		},
        components:{
            VgButton,
            VgCheckbox,
            VgFichesavDescription,
            VgLieuPathViewer,
            VgDatetimeViewer,
            VgOperationViewer,
            VgOperationFlag,
            VgFilesThumbnail
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "fm-numero": "Fiche n° {id}",
            "cree-le": "Créé le",
            "commenter": "Commenter"
        },
        "en": {
            "fm-numero": "N° {id}",
            "cree-le": "Created at",
            "commenter": "Comment"
        }
    }
},
        data:function(){
            return {
                operation: this.params.data
            };
        },
        watch:{
            params: {
                handler: function(newparams){
                    this.operation = newparams.data;
                }
            }
        },
        computed:{
            file: function(){
                return {
                    uploadedTo: this.operation.data.document_uploadedTo,
                    name: this.operation.data.document_name
                };
            }
        },
        methods: {
            onCheckOperation: function(){
                this.operation.statut = "resolue";
                this.onUpdateOperation(this.operation);
            },
            onUnCheckOperation: function(){
                this.operation.statut = "en_cours";
                this.onUpdateOperation(this.operation);
            },
            onClickCommenter: function(){
                console.log("onClickCommenter", this.operation, this.fm);
                this.$emit("click-commenter", this.operation);
            },
            onUpdateOperation: function(operation){
                this.$emit("update", operation);
            },
            goToMaintenance: function(){
                this.$emit("go-to-maintenance", this.fm);
            }
        }
    });
</script>

<style lang="scss" scoped>

.operation-tache-a-prevoir{
    width: 100%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    padding: 10px 0;
    .main-content{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        width:90%;
    }
    .footer-content{
        width: 100%;
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
    }
}

</style>
