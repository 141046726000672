import { render, staticRenderFns } from "./VgConsommableInventaireModal.vue?vue&type=template&id=26849d15&scoped=true&"
import script from "./VgConsommableInventaireModal.vue?vue&type=script&lang=js&"
export * from "./VgConsommableInventaireModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26849d15",
  null
  
)

export default component.exports