<template lang="html">
	<div class="vg-filter-demandeur">
		<vg-input
			:title="title?title:$t('title')">
			<vg-demandeur-selector v-model="selectedDemandeur"
				@input="handleChange" />
		</vg-input>
	</div>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgDemandeurSelector from "src/components/Vg/Selectors/VgDemandeurSelector.vue";

    export default {
        name: 'vg-filter-demandeur',
	    components: {
			VgInput,
			VgDemandeurSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: String
			},
			title: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par demandeur"
        },
        "en": {
            "title": "Filtering by requestor"
        }
    }
},
        data: function(){
            return {
				selectedDemandeur: this.value
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedDemandeur = val;
				}
			}
		},
		created: function(){
			
		},
        mounted: function(){

        },
        methods: {
			/**
	         * emit les sites selectionnés
	         * @event input selectedSites
	         */
			handleChange: function(service){
				//console.log("HANDLE CHANGE", this.getSelectedSites);
				this.$emit("input", service);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
<docs>
    vg-filter-demandeur
</docs>
