<template>
	<vg-modal
        @close="$emit('close')">
        <template #header>
			<span>{{$t("validation-approbation")}}: <b>{{bonDeCommande.numero}}</b></span>
        </template>
        <template #body>
			<div>
				<div v-for="(validation, indexValidation) in validations" :key="'validation'+indexValidation">
					<span>{{validation.groupeValidateur.name}}</span>
					<vg-input v-model="validation.commentaire"
						:type="'textarea'"
						:placeholder="$t('commentaire-placeholder')"/>
					<div style="display:flex;justify-content:center;align-items:center;">
						<vg-button
							:size="'sm'"
							:type="validation.statut=='accepted'?'success':'info'"
							@click="changeStatut(validation, indexValidation, 'accepted')">
							<i class="fas fa-check" v-if="validation.statut=='accepted'"></i> {{$t('btn-valider')}}
						</vg-button>
						<vg-button
							:size="'sm'"
							:type="validation.statut=='rejected'?'danger':'info'"
							@click="changeStatut(validation, indexValidation, 'rejected')">
							<i class="fas fa-ban" v-if="validation.statut=='rejected'"></i> {{$t('btn-refuser')}}
						</vg-button>
						<vg-button
							:size="'sm'"
							:type="validation.statut=='standby'?'danger':'info'"
							:disabled="true"
							@click="changeStatut(validation, indexValidation, 'standby')">
							<i class="far fa-clock" v-if="validation.statut=='standby'"></i> {{$t('btn-mettre-en-attente')}}
						</vg-button>
					</div>
				</div>
			</div>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button :type="'danger'"
				:disabled="isBtnConfirmDisabled"
				@click="confirmerValidationBonDeCommande">
                {{$t("btn-confirm")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgInput from "src/components/Vg/VgInput.vue";

	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
	import ValidationsMixins from "src/mixins/ValidationsMixins.js";

    import { mapGetters } from 'vuex';

    export default {
        name: 'vg-bon-de-commande-validation',
		components: {
			VgModal,
			VgButton,
			VgInput
		},
		mixins: [ BonsDeCommandeMixins, ValidationsMixins ],
    	props: {

        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "validation-approbation": "Validation/approbation",
            "btn-valider": "Valider",
            "btn-refuser": "Refuser",
            "btn-mettre-en-attente": "Mettre en attente",
            "btn-cancel": "Annuler",
            "btn-confirm": "Confirmer",
            "commentaire-placeholder": "Commentaire"
        }
    }
},
        data: function() {
            return {
				validations: []
            };
        },
		created: function(){
			this.initValidations();
		},
		mounted: function(){

		},
		methods: {
			initValidations: function(){
				let validationsDejaFaites = this.bonDeCommande.validations?this.bonDeCommande.validations.filter((validation)=>validation.validateur_id==this.$app.idUser):[];
				this.groupeValidateurs.forEach((groupe)=>{
					if(groupe.validateurs.findIndex((validateur)=>validateur.validateur_id==this.$app.idUser)!=-1	// si user fait parti des validateurs
						&& validationsDejaFaites.findIndex((validation)=>validation.groupeValidateur_id==groupe.id)==-1){	// si user n'a pas encore validé pour ce groupeValidateur
						this.validations.push({
							commentaire: null,
							validateur_id: this.$app.idUser,
							groupeValidateur_id: groupe.id,
							groupeValidateur: groupe,
							userId: this.$app.appID,
							statut: null,
							dateValidation: null
						});
					}
				});
			},
			changeStatut: function(validation, index, statut=null){
				if(statut!=null && validation.statut==statut){
					this.validations[index].statut = null;
				}else{
					this.validations[index].statut = statut;
				}

			},
			confirmerValidationBonDeCommande: function(){
				let validationsToConfirm = this.validations.filter((validation)=>validation.statut!=null);
				validationsToConfirm.forEach((validation, index)=>{
					validationsToConfirm[index].dateValidation = moment().format("YYYY-MM-DD HH:mm");
					validationsToConfirm[index].bonDeCommande_id = this.bonDeCommande.id;
				});
				this.ValidationsMixins_validationsBonDeCommande(this.bonDeCommande.id, validationsToConfirm).then((datas)=>{
					this.$emit("save", datas);
				});
			},
		},
        computed: {
			...mapGetters({
                bonDeCommande: "BonsDeCommandeStore/getSelectedItem",
                groupeValidateurs: "GroupeValidateursStore/getCollection"
            }),
			isBtnConfirmDisabled: function(){
				return this.validations.findIndex((validation)=>validation.statut!=null)==-1;
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
