<template>
    <vg-app-layout
        :title="$t('header-title')"
        :icon="$vgutils.isMobile()?'mobile/tiers.png':'tiers.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :filtersTitle="$t('modal-filters-title')"
        @action-export="actionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters"
    >
        <template v-slot:create-button>
            <vg-button
                type="success"
                @click="isTiersFormOpened = true"
            >
                <span>{{$t('header-btnCreate-tiers')}}</span>
            </vg-button>
        </template>

        <template v-slot:search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>
        <template #panel-filters-attributs>
            <vg-input
                :title="$t('filtre-type')">
                <vg-tiers-type-selector v-model="agfilters.field_type.value"/>
            </vg-input>
            <vg-input
                :title="$t('filtre-tags')">
                <vg-tiers-tags-selector v-model="agfilters.field_tags.value"/>
            </vg-input>
        </template>

        <template v-slot:header-bottom-left>
            <vg-tabs
                :tabs="tabs"
                :color="'bleu'"
                @tabClicked="handleTabClicked">
            </vg-tabs>
        </template>

        <template v-slot:primary>
            <vg-tabs v-if="$vgutils.isMobile()"
                :tabs="tabs"
                :color="'bleu'"
                @tabClicked="handleTabClicked" />
            <vg-tiers-table v-show="activeTab == 'tiers'"
                :filters="agfilters" />
            <vg-contacts-table v-show="activeTab == 'contacts'" />
        </template>
        <template #secondary-filters>
            <vg-input
                :title="$t('filtre-type')">
                <vg-tiers-type-selector v-model="agfilters.field_type.value"/>
            </vg-input>
            <vg-input
                :title="$t('filtre-tags')">
                <vg-tiers-tags-selector v-model="agfilters.field_tags.value"/>
            </vg-input>
        </template>
        <vg-tiers-form v-if="isTiersFormOpened"
            @created="onCreateTiers"
            @close="isTiersFormOpened=false"
        >
        </vg-tiers-form>
    </vg-app-layout>
</template>

<script>
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgSousTraitantForm from 'src/components/Vg/Forms/VgSousTraitantForm.vue';
import VgTiersForm from 'src/components/Vg/Forms/VgTiersForm.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";
import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTiersTable from "src/components/Vg/Tiers/VgTiersTable.vue";
import VgContactsTable from "src/components/Vg/Contacts/VgContactsTable.vue";
import VgTiersTypeSelector from "src/components/Vg/Selectors/VgTiersTypeSelector.vue";
import VgTiersTagsSelector from "src/components/Vg/Selectors/VgTiersTagsSelector.vue";
import FiltersMixins from 'src/mixins/FiltersMixins';

export default {
    name:"tiers",
    components:{
        TagGrid,
        VgTabs,
		VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgCollapse,
        VgGroup,
        TagCellRender,
        IconNameCellRender,
        VgSousTraitantForm,
        VgTiersTable,
        VgTiersForm,
        VgTiersTypeSelector,
        VgContactsTable,
        VgTiersTagsSelector
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-title": "Tiers / Contacts",
            "filtres-header-title": "Filtres",
            "header-btnCreate-tiers": "Créer tiers",
            "header-btnCreate-contact": "Créer contact",
            "tableauTiers-header-id": "ID",
            "tableauTiers-header-nom": "Nom",
            "tableauTiers-header-type": "Type",
            "tableauTiers-header-tags": "Tags",
            "tableauTiers-header-adresse": "Adresse",
            "zoomTiers-details-label-appreciation": "Appréciation",
            "Filtres_Arborescence": "Arborescence",
            "filtre-filtre-title": "Filtres",
            "collapse-filter": "Filtres complémentaires",
            "filtre-type": "Type",
            "filtre-tags": "Tags",
            "filtre-tiersName": "Tiers",
            "tableauContacts-header-id": "ID",
            "tableauContacts-header-nom": "Nom",
            "tableauContacts-header-prenom": "Prenom",
            "tableauContacts-header-tiers": "Tiers",
            "tableauContacts-header-tels": "Tels",
            "tableauContacts-header-mails": "Mails",
            "onglet-tiers": "Mes tiers",
            "onglet-contacts": "Mes contacts",
            "onglet-all-tiers": "Tous les tiers",
            "form-annuler": "Annuler",
            "form-creez": "Créer",
            "modaleCreationTiers-header-title": "Créer Tiers",
            "modaleCreationContact-header-title": "Créer Contact",
            "zoom-header-title": "Détails",
            "modal-filters-title": "Filtrer les tiers"
        },
        "en": {
            "header-title": "Suppliers / Contacts",
            "filtres-header-title": "FILTERS",
            "header-btnCreate-tiers": "Create supplier",
            "tableauTiers-header-id": "ID",
            "tableauTiers-header-nom": "Name",
            "tableauTiers-header-type": "Type",
            "tableauTiers-header-tags": "Tags",
            "tableauTiers-header-adresse": "Address",
            "zoomTiers-details-label-appreciation": "Rate",
            "tableauContacts-header-id": "ID",
            "tableauContacts-header-nom": "Lastname",
            "tableauContacts-header-prenom": "Firstname",
            "tableauContacts-header-tiers": "Suppliers",
            "tableauContacts-header-tels": "Phone numbers",
            "tableauContacts-header-mails": "Mails",
            "onglet-tiers": "My suppliers",
            "onglet-contacts": "My contacts",
            "onglet-all-tiers": "All suppliers",
            "form-annuler": "Cancel",
            "form-creez": "Create",
            "modaleCreationTiers-header-title": "Create supplier",
            "modaleCreationContact-header-title": "Created contact",
            "zoom-header-title": "Details",
            "header-btnCreate-contact": "Create contact",
            "Filtres_Arborescence": "Tree",
            "filtre-filtre-title": "Filters",
            "collapse-filter": "More filters",
            "filtre-type": "Type",
            "filtre-tags": "Tags",
            "filtre-tiersName": "Provider",
            "modal-filters-title": "Filter suppliers"
        }
    }
},
	mixins: [FiltersMixins],
    props:{
        defaultFilters: {
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data:function(){
        return {
            isTiersFormOpened: false,
            showModalCreateSousTraitant: false,
            activeTab: 'tiers',
			searchQuery: null,
            agfilters:{
                name: {attr:"t.name", colId: "t.name", value: null, action:"contains", openParenthesis: true},
                type: {attr:"t.type", colId: "t.type", value: null, action:"contains", logicalOperator: "OR"},
                address: {attr:"t.address", colId: "t.address", value: null, action:"contains", logicalOperator: "OR"},
                tags: {attr:"t.tags", colId: "t.tags", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true},
                field_type: {attr:"t.type", colId: "t.type", value: null, action:"equals"},
                field_tags: {attr:"t.tags", colId: "t.tags", value: null, action:"contains"}
            },
        }
    },
	watch: {
		searchQuery: {
			handler: function(query){
                console.log("WATCH QUERY", query);
				this.agfilters.name.value = query;
				this.agfilters.type.value = query;
				this.agfilters.address.value = query;
				this.agfilters.tags.value = query;
                this.FiltersMixins_saveSearchQuery(query);

			}
		}
	},
    methods: {
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.agfilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.agfilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.agfilters = filters;
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.agfilters = Object.assign({}, this.agfilters, filters);
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        onCreateTiers: function(){
            this.isTiersFormOpened = false;
        },
        actionExport: function(event){
            window.dispatchEvent(new CustomEvent(event.name, {
                "detail": {"idTableau":"tiers-tableau"}
            }));
        },
		handleTabClicked: function(tab){
			this.activeTab = tab.name;
		}
    },
	computed: {
        pageFilters: function(){
            return ["field_type", "field_tags"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
		counters: function(){
			var counters = {
				tiers: "",
				contacts: ""
			};
			return counters;
		},
		tabs: function(){
			var counters = this.counters;
			return [
				{label: this.$t("onglet-tiers"), name:"tiers", counter: counters.tiers, filters:[]},
				{label: this.$t("onglet-contacts"), name:"contacts", counter: counters.contacts, filters:[]}
			];
		},
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgBlue : 'white';
        },
        hasActiveGlobalFilters: function(){
            let filters = this.agfilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        }
	},
	created: function(){
        this.agfilters = Object.assign({}, this.agfilters, this.defaultFilters);
        this.FiltersMixins_page_name = "tiers";
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
	}
}
</script>
