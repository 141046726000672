const RULES = {
	READ: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
	CREATE: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
	UPDATE: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	DELETE: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	EQUIPEMENTS: {
		PAGE: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DEPLACER:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	EQUIPEMENT: {
		PAGE: 								{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE:								{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:								{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE:								{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DEPLACER:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		SORTIR: 							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPLOAD_DOCUMENT: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_MAINTENANCE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_INTERVENTION_PREVENTIVE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_DEMANDE_INTERVENTION: 		{ROLE_ADMIN: false, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE_DEMANDE_INTERVENTION_MOBILE: {ROLE_ADMIN: false, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		READ_SYNTHESE:						{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		READ_CONSOMMABLES:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_CONSOMMABLE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		ATTACHER_CONSOMMABLE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CHECKER:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		REMPLACER_QR_CODE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	LIEUX: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	SITES: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	PIECE: {
		PAGE:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE:							{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_EQUIPEMENT:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_MAINTENANCE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_DEMANDE_INTERVENTION: 	{ROLE_ADMIN: false, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CHECK_LIEU: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	SITE: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	BATIMENT: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	REGISTRE_IMMOBILISATIONS: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	INVENTAIRES: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	MOUVEMENTS_EQUIPEMENTS: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CATEGORIES_EQUIPEMENTS:{
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CATEGORIE_EQUIPEMENT:{
		CREATE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false}
	},
	CATEGORIES_LIEUX: {
		PAGE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	PLAN_INTERACTIF:{
		PAGE:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE_MARKER: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	TACHES: {
		PAGE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	VERIFICATIONS: {
		PAGE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	PROGRESSION: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	STATISTIQUES_VERIFICATION: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CHECKS: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: false},
	},
	DEMANDE_INTERVENTION: {
		PAGE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true}
	},
	MAINTENANCES: {
		PAGE: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_EN_COURS: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_BLOQUANTES: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_URGENTES: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_NON_AFFECTES: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_MES_AFFECTATIONS: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_RETOUR_A_FAIRE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_RELANCES: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_SUPERVISOR: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_RESOLUES: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		TAB_TOUTES: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	MAINTENANCE: {
		PAGE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		ACTIONS: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CONSOMMATION: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		BON_DE_COMMANDE: 		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		GESTION_FINANCIERE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		VALIDATION_SUPERVISOR: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		RELANCER:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	MAINTENANCES_TACHES: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	RESERVES: {
		PAGE: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	ACTIVITE_MAINTENANCE:{
		PAGE:						{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE_MAINTENANCE_RELANCE: {ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true}
	},
	PLANIFICATION: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	STATISTIQUES_MAINTENANCE: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	PLAN_MAINTENANCE_PREVENTIF: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	GAMME_MAINTENANCE_PREVENTIF: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	DEMANDE_CONSOMMABLES: {
		PAGE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE_CONSOMMABLE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONSOMMABLES: {
		PAGE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_BON_DE_SORTIE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_BON_DENTREE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	MOUVEMENTS_CONSOMMABLES: {
		PAGE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONSOMMABLE: {
		PAGE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		STOCK_AJUSTEMENT:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	GESTION_DE_STOCK:{
		SECTION:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
	},
	BONS: {
		PAGE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_BON_DE_SORTIE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_BON_DENTREE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	FICHES_DEMANDE_CONSOMMABLES: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	FICHE_DEMANDE_CONSOMMABLES: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	BONS_DE_COMMANDE: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CANCEL:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	TIERS: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	TIER: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONTACTS: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONTACT: {
		CREATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONTRATS: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	CONTRAT: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	INTERVENTIONS: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	INTERVENTION:{
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		VALIDATION:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		DELETE_ASSOCIATED_EQUIPEMENT: {ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false}
	},
	DASHBOARD: {
		PAGE:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CURATIF:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		PREVENTIF:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CONSOMMABLE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		BON_DE_COMMANDE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false}
	},
	CALENDRIER: {
		PAGE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE: {ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false}
	},
	PARAMETRES: {
		PAGE:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		UPDATE_PASSWORD:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UTILISATEURS:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE_UTILISATEUR:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_UTILISATEUR:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		ENTREPRISE:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE_ENTREPRISE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		MAINTENANCE:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		LIEUX:					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		ETIQUETTES:				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		BONS_DE_COMMANDE:		{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CORPS_D_ETAT:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		EQUIPEMENTS:			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	RELEVE_COMPTEUR:{
		CREATE_DATE_DEPASSEE:	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	LANCEMENT: {
		PAGE: {ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
	},
	RECHERCHE_GENERALE: {
		CREATE: 						{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		UPDATE: 						{ROLE_ADMIN: true, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		CREATE_DEMANDE_INTERVENTION: 	{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE_MAINTENANCE: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CREATE_CHECK: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: false},
		CREATE_SIGNALEMENT: 			{ROLE_ADMIN: false, ROLE_SUPERVISEUR: false, ROLE_TECHNICIEN: false, ROLE_SOUS_TRAITANT: false, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: false, ROLE_BASIC: false},
		EQUIPEMENTS_READ: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		LIEUX_READ: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: true, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		MAINTENANCES_READ: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CONTRATS_READ: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		TIERS_READ: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CATEGORIES_READ: 				{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		INTERVENTIONS_READ: 			{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
		CONTACTS_READ: 					{ROLE_ADMIN: true, ROLE_SUPERVISEUR: true, ROLE_TECHNICIEN: true, ROLE_SOUS_TRAITANT: true, ROLE_CHEF_EQUIPE: false, ROLE_BASIC_VERIFICATEUR: true, ROLE_BASIC: true},
	}
};

export default {
	data: function(){
		return {
			rules: RULES
		};
	},
	created: function(){

	},
	methods: {
        /**
        * get authorized functionalities for a role
        *
        * @param String role
        */
        getRoleRules: function(role){

        },
        getRoleFromStorage : function(){

        },
        can:function(rule){
            if (this.$app){
				let pathToRule = rule.split(".");
                let role = this.$app.role;
				let ruleValue = this.rules;
				let ruleExist = true;
				pathToRule.forEach((partOfRule)=>{
					if(ruleExist && ruleValue.hasOwnProperty(partOfRule)) ruleValue = ruleValue[partOfRule];
					else ruleExist = false;
				});
				if(!ruleExist) console.log("-----------------------------> ! RULE EXIST", pathToRule);
                return ruleExist && ruleValue && ruleValue[role]===true;
            }else{
                // hack car restclient nest pas un composant donc je ne peux pas me servir de cette mixin
				let rules = RULES;
                var app = JSON.parse(sessionStorage.getItem('user'));
                if(!app) return true;
				let pathToRule = rule.split(".");
                let role = app.role;
				let ruleValue = rules;
				let ruleExist = true;
				pathToRule.forEach((partOfRule)=>{
					if(ruleExist && ruleValue.hasOwnProperty(partOfRule)) ruleValue = ruleValue[partOfRule];
					else ruleExist = false;
				});
                if(ruleExist && ruleValue && ruleValue[role]===true){ 
					return true;
				}else{
					alert("You can't "+ruleValue+" in accordance to your restrictions in this app");
					return false;
				}
            }
        }
    }
};
