<template>
    <div class="restriction-site-cell-render">
        <span v-for="site in lieux">
            {{site[attributePath] | site}} |
        </span>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "restriction-site-cell-render",
        mixins:[LangMixins],
		props: {
			lieux: {
				type: Array,
				required: true,
				default: function(){
                    return [];
                }
			},
            attributePath: {
                type: String,
                default: "path"
            }
		},
        filters:{
            site: function(path){
                return path.split("/").pop();
            }
        },
        data: function() {
            return {

			};
        }
    });
</script>

<style lang="scss" scoped>
.restriction-site-cell-render{

}
</style>
