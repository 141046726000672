<template>
	<vg-modal ref="mainContainer"
		:title="$t('modal-header-title')"
		@close="$emit('close')">
		<template #body>
			<vg-fichesav-table :idTableau="'fichesav-relances'"
				:filters="maintenancesFilters"
				:counterName="'filtered'"
				showCards
				showRelance
				disabledRowClick />
		</template>
		<template #footer>
			<vg-button @click="$emit('close')">{{$t("btn-annule")}}</vg-button>
		</template>
	</vg-modal>
</template>
<script>

	import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";

	import VgButton from "src/components/Vg/VgButton.vue";

	import { mapGetters } from 'vuex';

    export default {
        name: "vg-fichesav-consultation-relances",
        props: {
            /*fms: {
				type: Array,
				default: []
			},*/
			lieux: {
				type: Array
			}
        },
		components: {
			VgButton,
			VgFichesavTable
		},
		mixins: [],
        data: function() {
            return {

            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-header-title": "Liste des fiches de maintenance",
            "entete-Fm": "Liste des fiches de maintenance du lieu {libelLieu}",
            "entete-Fm-lieu": "Liste des fiches de maintenance sur les {nLieux} lieux",
            "openat": "Ouverte le",
            "last-restart": "Dernière relance le",
            "par": "par",
            "btn-relance": "Relancer",
            "btn-annule": "Annuler"
        },
        "en": {
            "modal-header-title": "List of maintenance sheets",
            "entete-Fm": "List of site maintenance sheets {libelLieu}",
            "entete-Fm-lieu": "List of maintenance sheets on {nLieux} rooms",
            "openat": "Opened the",
            "last-restart": "Last reminder on the",
            "par": "by",
            "btn-relance": "Reminder",
            "btn-annule": "Cancel"
        },
        "th": {
            "modal-header-title": "รายการของแผ่นงานการซ่อมบำรุง",
            "openat": "เปิดแล้ว",
            "last-restart": "การเตือนความจำล่าสุดเมื่อ",
            "par": "โดย",
            "btn-relance": "การเตือนความจำ",
            "btn-annule": "ยกเลิก"
        }
    }
},
        computed: {
			...mapGetters({
                  maintenances: 'MaintenancesStore/getMaintenances',
                  maintenance: 'MaintenancesStore/getSelectedMaintenance',
                  reserves: 'MaintenancesStore/getReserves',
				  counters: "MaintenancesStore/getMaintenancesCounters"
            }),
			getLieuxIds: function(){
				return this.lieux.map((lieu)=>lieu.id);
			},
			maintenancesFilters: function(){
				return [
					{ attr: "l.id", value: this.getLieuxIds, action: "equals" },
					{ attr:"fm.statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"contains" }
				];
			}
		}
    };
</script>
<style lang="scss" scoped>

</style>
