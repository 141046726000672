<template >
      <vg-app-layout
         :title="$t('title')"
         @save-filters="saveCurrentFilters"
         :colorheader="getHeaderColor"
         :filtersTitle="$t('modal-filters-title')"
         @global-filters-change="onGlobalFiltersChange"
         :isActionButtonDisplay="true"
         @action-export="handleActionExport"
    >
         <template v-slot:action-button-item >
         </template>

         <template v-slot:search>

         </template>
         <template #create-button>
            <vg-button 
                type="success"
                @click="showMouvementEquipementsCreate('pret')">
                {{$t("create-mouvement-sortie")}}
            </vg-button>
            <vg-button 
                type="success"
                @click="showMouvementEquipementsCreate('retour')">
                {{$t("create-mouvement-retour")}}
            </vg-button>
        </template>
        <template #panel-menu-more>
            <vg-button 
                type="success"
                @click="showMouvementEquipementsCreate('pret')">
                {{$t("create-mouvement-sortie")}}
            </vg-button>
            <vg-button 
                type="success"
                @click="showMouvementEquipementsCreate('retour')">
                {{$t("create-mouvement-retour")}}
            </vg-button>
        </template>
         <template v-slot:primary>
            <div >
                <vg-tabs
                    :color="'gris'"
                    :tabs="tabs"
                    @tabClicked="onTabClicked">
                </vg-tabs>
                <mouvements-equipements-table 
                    style="height:500px;" 
                    :filters="agFilters"
                    v-show="!displayMouvementEquipementsForm && !displayMouvementEquipementsBS"
                    @row-click="showMouvement"
                ></mouvements-equipements-table>
                
                <hr>

            </div>


         </template>

         <template #secondary-filters>

         </template>
         <template #secondary-widget>
             
         </template>



      </vg-app-layout>
    
</template>

<script>

import MouvementsEquipementsTable from 'src/components/Vg/MouvementsEquipements/MouvementsEquipementsTable.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import MouvementsEquipementsMixins from 'src/mixins/MouvementsEquipementsMixins';
import Metadatas from "src/services/Metadatas.js";
export default {
    name: 'mouvements-equipements',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Mouvements équipements",
            "create-mouvement-sortie":"Sortir des équipements temporairement",
            "tab-equipement-retour":"Retours",
            "tab-equipement-sortis-temporairement":"Sorties temporaire",
            "tab-all":"Tous",
            "create-mouvement-retour":"Retour équipement"
        },
        "en": {
            "title": "Equipment movements",
            "create-mouvement-sortie":"Take out equipment temporary",
            "tab-equipement-retour":"Returns",
            "tab-equipement-sortis-temporairement":"Equipment away",
            "tab-all":"All",
            "create-mouvement-retour":"Returned equipments"
        }
    }
},
    mixins: [MouvementsEquipementsMixins],
    components: {
        VgButton,
        MouvementsEquipementsTable,
        VgTabs
    },
    props:{},
    data: function() {
        return {
            agFilters:{
                field_typeMouvement:{ attr: "m.type",colId: "m.type", value: null, action:"equals"},
            }
        };
    },
    watch: {

    },
    computed: {
        getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
        tabs: function() {
        return [{
                label: this.$t("tab-all"),
                name: "mouvements",
                counter: null,
                filters: []
            },
            {
                label: this.$t("tab-equipement-sortis-temporairement"),
                name: "pret",
                counter: null,
                filters: []
            },
            {
                label: this.$t("tab-equipement-retour"),
                name: "retour",
                counter: null,
                filters: []
            }
        ]
    },
    },
    methods: {
        showMouvement: function(mouvement){
            this.$router.push({ name: '_mouvement_equipements_id', params: { id: mouvement.data.id } })
        },
        showMouvementEquipementsCreate: function(type){
            this.$router.push({ name: '_mouvements_equipements_create_type', params: { type: type} })
        },
        onTabClicked: function(e) {
            if(e.name == "pret"){
                this.agFilters.field_typeMouvement.value = "pret";
            }else if(e.name == "retour"){
                this.agFilters.field_typeMouvement.value = "retour";
            }
            else{
                this.agFilters.field_typeMouvement.value = null;
            }
        },
        handleActionExport: function(e) {
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agFilters);
            this.MouvementsEquipementsMixins_export(metadatas, 'mouvements-equipements');
        }
    },
    created:function(){
    },
    destroyed:function(){
    }
};
</script>
<style lang="scss" scoped>
// on personnalise le label comme on veut
.btn-action{
    margin: 0 5px;
}
</style>
