<template>

	<vg-app-layout
		:icon="$vgutils.isMobile()?'mobile/di_blanc.png':'origine-DI.png'"
		:title="$t('header-title')"
		:colorheader="$vgutils.isMobile()?$root.vgYellow:''"
		:isActionButtonDisplay="false"
		:isLoadingState="isLoadingState"
        hideKeyFiguresPanel>

		<template #body>
			<vg-demande-consommables-form />
		</template>


	</vg-app-layout>

</template>

<script>

import VgDemandeConsommablesForm from "src/components/Vg/Forms/VgDemandeConsommablesForm.vue";
import { mapGetters } from 'vuex';

export default {
    name: "demande-consommables",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-title": "Demande consommables"
        },
        "en": {
            "header-title": "Consumables request"
        }
    }
} ,
    components: {
		VgDemandeConsommablesForm
    },
	mixins: [  ],
    data: function() {
        return {

        };
    },
	created: function(){

	},
    methods: {

    },
	computed: {
		...mapGetters({

        }),

    },
    mounted: function(){

    },
	destroyed: function(){

	}
};
</script>

<style lang="scss" scoped>

</style>
