<template>
    <vg-modal
        :width="'70vw'"
        :title="$t('title',{id:maintenance.id})"
        @close="$emit('close')">
        <template #body >
            <div :class="{'body-full-height':showAffectationPourTiers, 'body-height':!showAffectationPourTiers}">
                <div style="width:100%;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;">
                    <vg-fichesav-description :fm="maintenance"></vg-fichesav-description>
                    <div style="display:flex;flex-direction:row;justify-content:center;align-items:baseline;">
                        <span style="color:#BBBCBE;margin-right:10px;">{{$t("dans")}}&nbsp;</span>
                        <vg-lieu-path-viewer v-model="maintenance.equipement.path" :onlyPiece="true"
                            style="font-weight:bold;"/>
                    </div>
                </div>
                <br>
                <br>
                <div style="width:100%;">
                    <vg-input :title="$t('affecter-a')" isRequiredValue>
                        <vg-affectables-selector
                            :defaultAffectes="defaultAffectes"
                            :filtersTiers="$app.account_type=='prestataire'?filtersTiersAffectables:{}"
                            @select="onSelectAffectes"
                            @set-default-affectes="onSetDefaultAffectes"/>
                    </vg-input>
                    <br>
                    <div class="plan-intervention">
                        <vg-checkbox :label="$t('planifier-intervention')"
                            :defaultChecked="isDatePlanifiee"
                            :inputValue="isDatePlanifiee"
                            @checked="isDatePlanifiee=true;"
                            @unchecked="isDatePlanifiee=false;"
                            style="min-width:200px;"/>
                        <span v-if="isDatePlanifiee" style="color:#BBBCBE;font-size:12px;min-width:150px;">{{$t("date-previsionnelle")}}</span>
                        <vg-date-picker v-if="isDatePlanifiee"
                            :type="'datetime-local'"
                            :from="affectation.start"
                            :to="affectation.end"
                            @change="onChangeDateAffectation"/>
                    </div>
                    <div v-if="showAffectationPourTiers" style="margin-bottom:40px;">
                        <vg-checkbox :label="$t('envoyer-email-entreprise')"
                            :defaultChecked="affectationEmail.sendEmail"
                            :inputValue="affectationEmail.sendEmail"
                            @checked="onSendEmailChecked"
                            @unchecked="affectationEmail.sendEmail=false;">
                        </vg-checkbox>
                        <div class="send-email-section" v-show="affectationEmail.sendEmail">
                            <vg-input :title="$t('email-destinataire')"
                                isRequiredValue>
                                <vg-contacts-selector v-model="affectationEmail.destinataireTiers"
                                    :attributeValue="'email'"
                                    multiple
                                    :filters="contactsFilters"/>
                            </vg-input>
                            <vg-input :title="$t('email-cc-interne')">
                                <vg-users-selector v-model="affectationEmail.cc"
                                    :attributeValue="'email'"
                                    multiple
                                    showEmail
                                    :placeholder="$t('cc-interne-placeholder')" />
                            </vg-input>
                            <vg-checkbox v-show="isDatePlanifiee"
                                :label="$t('transmettre-date-previsionnelles-entreprise')"
                                :defaultChecked="affectationEmail.sendDatePlanifieeDansEmail"
                                :inputValue="affectationEmail.sendDatePlanifieeDansEmail"
                                @checked="affectationEmail.sendDatePlanifieeDansEmail=true;"
                                @unchecked="affectationEmail.sendDatePlanifieeDansEmail=false;"/>
                            <vg-input v-model="affectationEmail.commentaireEmail"
                                :title="$t('text-complementaire-email')"
                                :inputType="'textarea'"/>
                            <!-- selecteur documents de la fiche curative à envoyer en PJ à l'email -->
                            <vg-input :title="$t('select-files-to-send')">
                                <vg-files-selector 
                                    @loading="isFilesSelectorLoading=$event"
                                    @downloaded-files="onDownloadedFiles"/>
                            </vg-input>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <vg-button type="default" @click="$emit('close')">{{$t("close")}}</vg-button>
            <vg-button type="success" :disabled="isFilesSelectorLoading || isLoading || isSaveButtonIsDisabled" @click="saveAffectation">{{$t("save")}}</vg-button>
        </template>
    </vg-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgDatePicker from "src/components/Vg/VgDatePicker.vue";
import VgAffectablesSelector from "src/components/Vg/Selectors/VgAffectablesSelector.vue";
import VgContactsSelector from "src/components/Vg/Selectors/VgContactsSelector.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import VgFilesSelector from "src/components/Vg/Files/VgFilesSelector.vue";

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import AffectationsMixins from "src/mixins/AffectationsMixins.js";

export default {
    name:"vg-fichesav-affectation",
    components:{
        VgFichesavDescription,
        VgLieuPathViewer,
        VgButton,
        VgInput,
        VgCheckbox,
        VgDatePicker,
        VgAffectablesSelector,
        VgContactsSelector,
        VgUsersSelector,
        VgFilesSelector
    },
    mixins: [ MaintenanceMixins, AffectationsMixins ],
    props:{
        defaultStartDate: {
            type: String,
            default: null
        },
        defaultEndDate: {
            type: String,
            default: null
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Affectation fiche maintenance n° {id}",
            "dans": "dans",
            "save": "Enregistrer",
            "close": "Annuler",
            "affecter-a": "Affecter à",
            "text-complementaire-email": "Texte complémentaire dans email (optionnel)",
            "planifier-intervention": "Planifier intervention ?",
            "date-previsionnelle": "Date prévisionnelle",
            "envoyer-email-entreprise": "Envoyer email à l'entreprise",
            "transmettre-date-previsionnelles-entreprise": "Transmettre les dates prévisionnelles à l'entreprise dans l'email",
            "envoyer-email-entreprise-infos": "dans le cas d'une affectation à une personne un email est systematiquement envoyé si cet utilisateur a réglé dans ses paramètres la réception de ce type d'email",
            "email-cc-interne": "Utilisateurs en CC (optionnel)",
            "email-destinataire": "Destinataire email",
            "cc-interne-placeholder": "Sélectionner les utilisateurs à mettre en CC",
            "select-files-to-send": "Sélectionner les fichiers à envoyer en pièces-jointes"
        },
        "en": {
            "title": "Maintenance sheet assignment n° {id}",
            "dans": "in",
            "save": "Save",
            "close": "Cancel",
            "affecter-a": "Assign to",
            "text-complementaire-email": "Additionnal text to send in the email (optionnal)",
            "planifier-intervention": "Plan intervention ?",
            "date-previsionnelle": "Provisional date",
            "envoyer-email-entreprise": "Send email to company",
            "transmettre-date-previsionnelles-entreprise": "Send provisional dates to the company in the email",
            "envoyer-email-entreprise-infos": "in the case of an assignment to a person an email is systematically sent if this user has set in his parameters the reception of this type of email",
            "email-cc-interne": "Users in CC (optional)",
            "email-destinataire": "Email recipient",
            "cc-interne-placeholder": "Select users to include in CC",
            "select-files-to-send": "Select files to send as attachments"
        }
    }
},
    data: function(){
        return {
            isDatePlanifiee: this.defaultStartDate && this.defaultEndDate?true:false,
            affectation:{
                dateAffectation: null,
                affectes: [],
                start: this.defaultStartDate?this.defaultStartDate:moment().format("YYYY-MM-DD HH:mm"),
                end: this.defaultEndDate?this.defaultEndDate:moment().add(30,"minutes").format("YYYY-MM-DD HH:mm")
            },
            affectationEmail: {
                sendEmail: false,
                destinataireTiers: [],
                cc: this.getEmailCCLocalStorage,
                sendDatePlanifieeDansEmail: false,
                commentaireEmail: null,
                files: []
            },
            contactsFilters: {
                tiers_id: {attr: "c.tiers_id", value: null, action: "equals"}
            },
            filtersTiersAffectables: {
                type: {attr: "t.type", value: "Client", action: "not_equals"}
            },
            showAffectationPourTiers: false,
            isLoading: false,
            isFilesSelectorLoading: false,
            defaultAffectes: {users:[], tiers: []}, // {users: [array of ids], tiers: [array of ids]}
        };
    },
    computed:{
        ...mapGetters({
            maintenance: 'MaintenancesStore/getSelectedMaintenance'
        }),
        isSaveButtonIsDisabled: function(){
            if(this.affectationEmail.sendEmail && this.affectationEmail.destinataireTiers.length==0) return true;
            if(!this.affectation.affectes || this.affectation.affectes.length==0) return true;
            else if(this.isDatePlanifiee && (!this.affectation.start || !this.affectation.end)) return true;
            else if(this.isDatePlanifiee && typeof(this.affectation.start)=="string" && this.affectation.start=="Invalid date") return true;
            else if(this.isDatePlanifiee && typeof(this.affectation.end)=="string" && this.affectation.end=="Invalid date") return true;
            else return false;
        },
        getTiersAffectes: function(){
            let indexTiersAffecte = this.affectation.affectes.findIndex((affecte)=>affecte.hasOwnProperty("name"));
            if(indexTiersAffecte!=-1) return this.affectation.affectes[indexTiersAffecte];
            else return null;
        },
        getEmailCCLocalStorage: function(){
            return localStorage.getItem("maintenance-affectation-email-cc")?JSON.parse(localStorage.getItem("maintenance-affectation-email-cc"), true):[];
        }
    },
    created:function(){
        this.getMaintenanceAffectes();
    },
    mounted: function(){
        
    },
    methods: {
        onDownloadedFiles: function(base64DownloadedFiles){
            this.affectationEmail.files = [...base64DownloadedFiles];
        },
        saveEmailCC: function(){
            localStorage.setItem("maintenance-affectation-email-cc", JSON.stringify(this.affectationEmail.cc));
        },
        saveDestinataireTiersInLocalStorage: function(){
            if(!localStorage.getItem("maintenance-affectation-email-tiers-destinataires")){
                localStorage.setItem("maintenance-affectation-email-tiers-destinataires", JSON.stringify({}));
            }
            let localDestinataireTiers = JSON.parse(localStorage.getItem("maintenance-affectation-email-tiers-destinataires"), true);
            localDestinataireTiers[this.getTiersAffectes.id] = this.affectationEmail.destinataireTiers;
            localStorage.setItem("maintenance-affectation-email-tiers-destinataires", JSON.stringify(localDestinataireTiers));
        },
        onSendEmailChecked: function(value){
            this.affectationEmail.sendEmail = true;
            let localDestinataireTiers = JSON.parse(localStorage.getItem("maintenance-affectation-email-tiers-destinataires"), true);
            if(localDestinataireTiers && this.getTiersAffectes && localDestinataireTiers[this.getTiersAffectes.id]) this.affectationEmail.destinataireTiers = [...localDestinataireTiers[this.getTiersAffectes.id]];
            else this.affectationEmail.destinataireTiers = [];
        },
        onSetDefaultAffectes: function(affectes){
            this.affectation.affectes = [...affectes];
        },
        getMaintenanceAffectes: function(){
            if(this.maintenance && this.maintenance.affectation && this.maintenance.affectation.affectes){
                this.defaultAffectes = { users: [], tiers: [] };
                this.maintenance.affectation.affectes.forEach((affecte)=>{
                    if(affecte.hasOwnProperty("user_id")) this.defaultAffectes.users.push(affecte["user_id"]);
                    /*else if(affecte.hasOwnProperty("tiers_id")){
                        this.defaultAffectes.tiers.push(affecte["tiers_id"]);
                        this.showAffectationPourTiers = true;
                        this.isDatePlanifiee = !this.showAffectationPourTiers;
                        this.contactsFilters.tiers_id.value = affecte["tiers_id"];
                    }*/
                });
                if(this.maintenance.affectation.start && this.maintenance.affectation.end) this.isDatePlanifiee = true;
                if(!this.defaultStartDate) this.affectation.start = this.maintenance.affectation.start;
                if(!this.defaultEndDate) this.affectation.end = this.maintenance.affectation.end;
            }
        },
        onSelectAffectes: function(affectes){
            this.affectation.affectes = [...affectes];
            let indexTiersAffecte = affectes.findIndex((affecte)=>affecte.hasOwnProperty("name"));
            this.showAffectationPourTiers = indexTiersAffecte!=-1;
            this.isDatePlanifiee = !this.showAffectationPourTiers;
            if(indexTiersAffecte!=-1) this.contactsFilters.tiers_id.value = affectes[indexTiersAffecte].id;
            this.affectationEmail = {
                sendEmail: false,
                destinataireTiers: [],
                cc: this.getEmailCCLocalStorage,
                sendDatePlanifieeDansEmail: false,
                commentaireEmail: null,
                files: []
            };
        },
        onChangeDateAffectation: function(data){
            this.affectation.start = data.dateMin;
            this.affectation.end = data.dateMax;
        },
        saveAffectation: function(){
            this.isLoading = true;
            this.affectation.dateAffectation = moment().format("YYYY-MM-DD HH:mm");
            this.affectation.affectant_id = this.$app.idUser;
            if(!this.isDatePlanifiee){
                this.affectation.start = null;
                this.affectation.end = null;
            }
            if(this.affectationEmail.sendEmail){
                this.saveDestinataireTiersInLocalStorage();
                this.saveEmailCC();
            }
            this.AffectationsMixins_saveSchedule(this.affectation, this.maintenance, this.affectationEmail).then((datas)=>{
                this.MaintenanceMixins_getMaintenance(this.maintenance.id).then((datas)=>{
                    this.$emit("save");
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.detail-fichesav-modal{
    .fichesav-info{
        display: flex;
        > div{
            margin-bottom: 10px;
            &:first-child {
                color: lightgray;
                width: 40%;
            }
        }
    }
}
.plan-intervention{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;
}
.send-email-section{
    margin: 10px 35px;
}
@media only screen and (max-width: 1200px){
    .plan-intervention{
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items:center;
    }
    .send-email-section{
        margin: 10px 0;
    }
}
.closebtn-fichesav{
    display: flex;
    justify-content: center;
}

.fichesav-description{
    margin-bottom: 15px;
    font-size: 16px;
    .vg-fichesav-description{
        display: inline;
    }
}
.body-full-height{
    /*height:100vh !important;*/
}
.body-height{
    /*height: auto !important;*/
}
</style>
