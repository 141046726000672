<template>
    <div v-if="reponse.fichesav_id" @click="$emit('click', {reponse: reponse})" class="reponse-maintenance-statut-cell-render">
        <vg-fichesav-statut-viewer :fm="getMaintenance" style="margin-right:10px;"/>
        <vg-datetime-viewer v-if="reponse.fichesav_dateFermetureSAV"
            v-model="reponse.fichesav_dateFermetureSAV"/>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "reponse-maintenance-statut-cell-render",
        mixins:[LangMixins],
        components: {
            VgFichesavStatutViewer,
            VgDatetimeViewer
        },
		props: {
			reponse: {
				type: Object,
				required: true,
				default: {}
			}
		},
        computed: {
            getMaintenance: function(){
                return {
                    id: this.reponse.fichesav_id,
                    statut: this.reponse.fichesav_statut
                };
            }
        }
    });
</script>

<style style="scss" scoped>
.reponse-maintenance-statut-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>
