<template>
	<div v-if="percentage" class="vg-progress-bar">
		<div class="vg-progress-bar__outer" style="height: 26px;">
			<div :class="['vg-progress-bar__inner', {'green':percentageValue==100}]"
				:style="'width:'+percentageValue+'%;'">
				<div class="vg-progress-bar__innerText">{{percentageValue}}%</div>
			</div>
		</div>
	</div>
	<div v-else>
		not implemented
	</div>
</template>
<script>

    export default {
        name: 'vg-progress',
		components: {

		},
        props: {
			value:{
				type: Number,
				default: 0
			},
			bar:{
				type: Boolean,
				default: false
			},
			percentage:{
				type: Boolean,
				default: false
			},
			/**
			* si total==100: value représente un pourcentage
			* si total!=100: value doit être convertie en pourcentage
			*/
			total:{
				type: Number,
				default: 100,
				required: true
			}
        },
		watch:{

		},
        data: function() {
            return {
				percentageValue: 0
            };
        },
		created: function(){
			if(this.percentage) this.setPercentageValue();
		},
		mounted: function(){

		},
		methods: {
			setPercentageValue: function(){
				if(this.total==100) this.percentageValue = this.value;
				else{
					let percentageValue = Math.round(parseFloat(this.value)*100/parseFloat(this.total));
					if(percentageValue>100) percentageValue = 100;
					this.percentageValue = percentageValue;
				}
			}
		},
        computed: {

		}
    };

</script>
<style style="scss" scoped>
.vg-progress-bar{
	padding-right: 0px !important;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
}
.vg-progress-bar__outer{
    height: 26px;
	border-radius: 100px;
	background-color: #ebeef5;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
}
.vg-progress-bar__inner{
    width: 70%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #409eff;
	/*text-align: right;*/
	border-radius: 100px;
	line-height: 1;
	white-space: nowrap;
	transition: width .6s ease;
	/*display: inline-block;
	vertical-align: middle;*/
	color: #fff;
	font-size: 12px;
	margin: 0px;
	display: flex;
	justify-content: flex-start;
    align-items: center;
}
.vg-progress-bar__inner:after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle;
}
.vg-progress-bar__inner.green{
	background-color: #67c23a;
}
.vg-progress-bar__innerText{
	width: 100%;
	margin: 0 5px;
	text-align: right;
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-progress v-model="70" />
        </div>
      `
    })
    ```
</docs>
