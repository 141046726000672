<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:limit="limit"
		hideDatePickerShortcut
		key="dashboard-curatif-rapport-repartition-progression-interne"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<div style="overflow-x:auto;height:80%;" v-if="progressionInterne && progressionInterne.length!=0">
			<div v-for="(progression, index) in progressionInterne" :key="index" class="progression-row"
				@click="openResteAFaireProgression(progression)">
				<span>{{progression.libel_tache}}</span>
				<div style="display:flex;justify-content:space-between;align-items:center;gap: 10px;width:100%;">
					<vg-progress v-model="progression.total_done" :total="progression.total_todo" percentage/>
					<span style="white-space: nowrap;">{{$t("avant-le")}} <vg-datetime-viewer v-model="progression.date_butoire" hideTime /></span>
				</div>
			</div>
		</div>
		<div v-else style="color:#35b1ea;display:flex;justify-content:center;align-items:center;">
			<span>{{$t("aucune-progression")}}</span>
		</div>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgProgress from 'src/components/Vg/VgProgress.vue';
	import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-preventif-progression-interne',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport,
			VgButton,
			VgProgress,
			VgDatetimeViewer
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},

            };
        },
		i18n:    { "locale":navigator.language,
	    	"messages": {
			    "fr": {
					"title": "Progression préventif interne",
					"avant-le": "Avant le",
					"aucune-progression": "Aucune progression"
			    },
			    "en": {
					"title": "Internal preventive progression",
					"avant-le": "Before the",
					"aucune-progression": "No progression"
			    }
		    }
		},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){
			
		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			openResteAFaireProgression: function(progression){
				this.$emit("selected", {progression: progression});
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				this.metadatas.setFilters(filters);
				if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getPreventifProgressionInterne(this.metadatas).then((datas)=>{

				});
			},
		},
        computed: {
			...mapGetters({
	            progressionInterne: 'DashboardStore/getPreventifProgressionInterne',
	        })
		}
    };

</script>
<style lang="scss" scoped>
.progression-row{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 5px;
	padding: 5px 15px;
	font-size: 11px;
	border: 1px solid #d3d7dd;
	margin-bottom: 10px;
	overflow-x: auto;
	cursor: pointer;

}
.progression-row:hover{
	background-color: whitesmoke;
}
</style>
