<template>
	<vg-select v-model="selectedContacts"
		:options="contacts"
		:attributeLabel="'firstname'"
		:attributeValue="attributeValue"
		:clearable="isClearable"
		:multiple="multiple"
		:placeholder="$t('placeholder')"
		@input="$emit('input',selectedContacts)">
		<template v-slot:selected-option="{option}">
			<span>{{option.firstname}} {{option.lastname}} <i style="font-size:11px;">{{option.email}}</i></span>
        </template>
		<template v-slot="{option}">
			<span>{{option.firstname}} {{option.lastname}} <i style="font-size:11px;">{{option.email}}</i></span>
		</template>
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import ContactsMixins from "src/mixins/ContactsMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-contacts-selector',
		components: {
			VgSelect
		},
		mixins: [ContactsMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet contact à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			},
			filters: {
				type: Object,
				default: function(){
					return {};
				},
			},
			multiple:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				agfilters: this.filters,
				selectedContacts: this.value,
				metadatasContacts: new Metadatas()
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner un contact"
        }
    }
},
		watch: {
			value: {
				handler: function(val){
					this.selectedContacts = val;
				}
			},
			filters: {
				handler: function(val){
					this.agfilters = Object.assign({},{},val);
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				this.metadatasContacts.setFilters(this.agfilters);
				this.ContactsMixins_getContacts(this.metadatasContacts).then((datas)=>{
					this.$emit("fetch-success", {tiers: datas});
				});
			}
		},
        computed: {
			...mapGetters({
                contacts: 'ContactsStore/getCollection'
            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
