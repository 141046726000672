<template>
    <vg-input :title="label">
        <input v-model="inputValue"
            :list="'consommable-tags-list-'+datalistId"
            :id="'consommable-tags-'+datalistId"
            name="consommable-tags"
            class="consommable-tags"
            :placeholder="placeholder"
            @input="onInput" />
        <datalist :id="'consommable-tags-list-'+datalistId">
            <option v-for="tag, index in tags" :value="tag.nature" :key="'tag'+index" />
        </datalist>
    </vg-input>
</template>
<script>
import VgInput from "src/components/Vg/VgInput.vue";
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: "vg-consommable-tags-datalist",
    mixins: [ ConsommablesMixins ],
	components: {
		VgInput
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: "default label"
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        }
    },
    data: function() {
        return {
            inputValue: this.value,
            metadatas: new Metadatas(),
            agfilters: {},
            datalistId: this.generateDatalistId(),
            tags: []
        };
    },
    watch: {
        value: {
            handler: function(newvalue){
                this.inputValue = newvalue;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        generateDatalistId: function(){
            return moment().format("x")+"-"+Math.floor(Math.random()*1000);
        },
        fetch: function(){
            let filters = Object.assign({}, this.agfilters, this.filters);
            this.metadatas.setFilters(filters);
            this.ConsommablesMixins_getConsommablesEtiquettes(this.metadatas).then((datas)=>{
                this.tags = datas.etiquettes;
            });
        },
        onInput: function(data){
            this.$emit("input", this.inputValue);
        }
    }
};

</script>
<style lang="scss" scoped>
.consommable-tags{
    width:100%;
    border: 1px solid #dcdfe6;
    height: 30px;
    padding: 0 15px;
}
</style>
