<template lang="html">
    <div class="vg-affecte-name" v-if="affecte.tiers_id">
        <span > <img src="/static/assets/icone/tiers.png" alt="" height="15"> {{affecte.name}} </span>
    </div>
    <div class="vg-affecte-name" v-else>
        <span > <img src="/static/assets/icone/user.png" alt="" height="15"> {{affecte.user_name}} </span>
    </div>
</template>

<script>

export default {
    name:"vg-affecte-name",
    props:{
        /**
         * donnée sur l'affectés
         */
        affecte:{
            type:Object,
            required:true
        }

    }
}
</script>

<style lang="scss" scoped>
.vg-affecte-name{
    img{
        margin: 0;
    }
}
</style>
<docs>
    Composant Tag
    ## Usage du composant
    ```html static
          <vg-affecte-name :affecte="affecte"></vg-affecte-name>
    ```
    ou dans un v-for
    ```html static
    <div v-for="affecte in getSelectedMaintenance.affectation.affectes" >
        <vg-affecte-name  :affecte="affecte"></vg-affecte-name>
    </div>
    ```
    affiche le nom du tiers ou de l'utilisateur avec l'icone associer



</docs>
