//SearchDatas store module

// initial state
const state = {
    equipements: [],
    lieux: [],
    maintenances: [],
    contrats: [],
    tiers: [],
    categories: [],
    interventions: [],
    contacts: [],
    taches: [],
    counters:{
        equipements: null,
        lieux: null,
        maintenances: null,
        contrats: null,
        tiers: null,
        categories: null,
        interventions: null,
        contacts: null
    },
    entities:{
        equipements: true,
        lieux: true,
        maintenances: true,
        contrats: null,
        tiers: null,
        categories: null,
        interventions: null,
        contacts: null,
        taches: null
    },
    filters:{
        equipements: {},
        lieux: {},
        maintenances: {},
        contrats: {},
        tiers: {},
        categories: {},
        interventions: {},
        contacts: {},
        taches: {}
    }
};

// getters
const getters = {
    getEquipements: function( state ){
        return state.equipements;
    },
    getLieux: function( state ){
        return state.lieux;
    },
    getMaintenances: function( state ){
        return state.maintenances;
    },
    getContrats: function( state ){
        return state.contrats;
    },
    getTiers: function( state ){
        return state.tiers;
    },
    getCategories: function( state ){
        return state.categories;
    },
    getInterventions: function( state ){
        return state.interventions;
    },
    getContacts: function( state ){
        return state.contacts;
    },
    getTaches: function( state ){
        return state.taches;
    },
    getCounters: function( state ){
        return state.counters;
    },
    getEntities: function( state ){
        return state.entities;
    },
    getFilters: function( state ){
        return state.filters;
    }
};

// actions
const actions = {
    setDatas: function( context, datas ){
        context.commit("SET_DATAS", datas);
    },
    setCounters: function( context, counters ){
        context.commit("SET_COUNTERS", counters);
    },
    setEntities: function( context, entities ){
        context.commit("SET_ENTITIES", entities);
    },
    setFilters: function( context, filters ){
        context.commit("SET_FILTERS", filters);
    },
    clear: function( context ){
        context.commit("CLEAR");
    }
};

// mutations
const mutations = {
    SET_DATAS: function( state, datas ){
        state.equipements = datas.equipements;
        state.lieux = datas.lieux;
        state.maintenances = datas.maintenances;
        state.contrats = datas.contrats;
        state.tiers = datas.tiers;
        state.categories = datas.categories;
        state.interventions = datas.interventions;
        state.contacts = datas.contacts;
        state.taches = datas.taches;
    },
    SET_COUNTERS: function( state, counters ){
        if(counters.equipements) state.counters.equipements = Number(counters.equipements);
        else state.counters.equipements = null;
        if(counters.lieux) state.counters.lieux = Number(counters.lieux);
        else state.counters.lieux = null;
        if(counters.maintenances) state.counters.maintenances = Number(counters.maintenances);
        else state.counters.maintenances = null;
        if(counters.contrats) state.counters.contrats = Number(counters.contrats);
        else state.counters.contrats = null;
        if(counters.tiers) state.counters.tiers = Number(counters.tiers);
        else state.counters.tiers = null;
        if(counters.categories) state.counters.categories = Number(counters.categories);
        else state.counters.categories = null;
        if(counters.interventions) state.counters.interventions = Number(counters.interventions);
        else state.counters.interventions = null;
        if(counters.contacts) state.counters.contacts = Number(counters.contacts);
        else state.counters.contacts = null;
    },
    SET_ENTITIES: function( state, entities ){
        state.entities = Object.assign({}, {}, entities);
    },
    SET_FILTERS: function( state, filters ){
        let entities = Object.keys(filters);
        let formatedFilters = {};
        let filterKeys = [];
        entities.forEach((entity)=>{
            filterKeys = Object.keys(filters[entity]);
            filterKeys.forEach((key)=>{
                formatedFilters["global_"+key] = filters[entity][key];
            });
            state.filters[entity] = formatedFilters;
            formatedFilters = {};
        });
    },
    CLEAR: function(state){
        state = {
            equipements: [],
            lieux: [],
            maintenances: [],
            contrats: [],
            tiers: [],
            categories: [],
            interventions: [],
            contacts: [],
            taches: [],
            counters:{
                equipements: null,
                lieux: null,
                maintenances: null,
                contrats: null,
                tiers: null,
                categories: null,
                interventions: null,
                contacts: null
            },
            entities:{
                equipements: true,
                lieux: true,
                maintenances: true,
                contrats: null,
                tiers: null,
                categories: null,
                interventions: null,
                contacts: null,
                taches: null
            },
            filters:{
                equipements: {},
                lieux: {},
                maintenances: {},
                contrats: {},
                tiers: {},
                categories: {},
                interventions: {},
                contacts: {},
                taches: {}
            }
        };
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
