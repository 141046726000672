<template>
	<div class="select-lieu">
		<div class="header">
			<vg-input class="search-field"
				:placeholder="$t('filtre-recherche')"
				v-model="searchfield" />
	        <vg-taches-selector v-model="tache" isLieux style="margin-bottom: 10px;"
				@fetch-success="onSuccessFetchTaches"/>
	        <vg-service-selector v-model="service" style="margin-bottom: 10px;"/>
			<vg-tabs v-model="verificationStatut"
				:tabs="tabs"
				:color="'gris'"/>
		</div>
		<div class="body">
			<vg-button v-for="(lieu,index) in lieuxComputed" class="lieu"
				:key="lieu.idEquipement+'-'+index"
				type="default-danger"
				@click="goToProcessVerification(lieu)" >
				<vg-lieu-path-viewer v-model="lieu.path" class="title"
					:key="'piece'+lieu.idEquipement+'-'+index"
					:showTooltip="false"
					onlyPiece />
				<span v-if="lieu.dateVerif" class="subtitle">
					{{$t("dernier-le")}}
					<vg-datetime-viewer v-model="lieu.dateVerif"/>
				</span>
			</vg-button>
		</div>
	</div>
</template>
<script>
import VgTachesSelector from "src/components/Vg/Selectors/VgTachesSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgInput from "src/components/Vg/VgInput";
import VgSelect from "src/components/Vg/VgSelect";
import VgTabs from "src/components/Vg/VgTabs";
import VgButton from "src/components/Vg/VgButton";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

import { mapGetters, mapActions } from 'vuex';


export default {
    name: 'select-lieu',

    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
			"filtre-recherche": "Rechercher un lieu ...",
			"placeholder-select-tache": "Sélectionner une tâche",
			"restantes": "Restantes",
			"terminees": "Terminées",
			"dernier-le": "Dernier le"
        },
        "en": {
			"filtre-recherche": "Search a place ...",
			"placeholder-select-tache": "Select a task",
			"restantes": "Remaining",
			"terminees": "Completed",
			"dernier-le": "Last at"
        }
    }
}       ,
    mixins:[],
    components:{
        VgTachesSelector,
        VgInput,
        VgSelect,
        VgTabs,
        VgButton,
        VgDatetimeViewer,
        VgLieuPathViewer,
		VgServiceSelector
    },
    props:{
        lieux: Array,

    },

    data: function() {
        return {
			searchfield: null,
			tache: null,
			service: null,
			verificationStatut: "restantes",
			//lieux: []
        };

    },
    computed: {
		...mapGetters({
            taches: 'TachesStore/getCollection'
        }),
		tabs: function(){
			return [
				{label: this.$t("restantes"), name: "restantes", counter: this.lieuxRestantsCounter, filters: []},
				{label: this.$t("terminees"), name: "terminees", counter: this.lieuxTerminesCounter, filters: []}
			];
		},
		hasFilterTache: function(){
			return this.tache && this.tache.hasOwnProperty("id");
		},
		hasFilterService: function(){
			return this.service && this.service.length!=0;
		},
		hasFilterSearch: function(){
			return this.searchfield && this.searchfield.length!=0;
		},
		isRestantes: function(){
			return this.verificationStatut=="restantes";
		},
        lieuxComputed: function(){
			let foundLieux = [...this.lieux];
			let searchValue = this.hasFilterSearch?this.searchfield.toLowerCase():null;
			let tache = this.tache;
			// filtre par statut fait/pas fait
			if(this.isRestantes) foundLieux = foundLieux.filter((lieu)=>lieu.isVerificationNeeded=="1");
			else foundLieux = foundLieux.filter((lieu)=>lieu.isVerificationNeeded=="0");
			// filtre champ recherche
			if(this.hasFilterSearch) foundLieux = foundLieux.filter((lieu)=>lieu.libel_equipement.toLowerCase().search(searchValue)!=-1 || lieu.path.toLowerCase().search(searchValue)!=-1);
			// filtre par tache
			if(this.hasFilterTache) foundLieux = foundLieux.filter((lieu)=>lieu.idTache==tache.id);
			// filtre par service
			if(this.hasFilterService) foundLieux = foundLieux.filter((lieu)=>lieu.service?lieu.service.toLowerCase()==this.service.toLowerCase():false);
			// store
			this.$storage.set("searchfield-selectLieu", searchValue);
			this.$storage.set("tacheSelector-selectLieu", tache);
			// return lieux sans dupliques
			return foundLieux.filter((lieu, index, self)=>self.findIndex((l)=>l.idEquipement==lieu.idEquipement)==index);
		},
		lieuxRestantsCounter: function(){
			let foundLieux = [...this.lieux];
			let searchValue = this.hasFilterSearch?this.searchfield.toLowerCase():null;
			let tache = this.tache;
			// filtre par statut pas fait
			foundLieux = foundLieux.filter((lieu)=>lieu.isVerificationNeeded=="1");
			// filtre champ recherche
			if(this.hasFilterSearch) foundLieux = foundLieux.filter((lieu)=>lieu.libel_equipement.toLowerCase().search(searchValue)!=-1 || lieu.path.toLowerCase().search(searchValue)!=-1);
			// filtre par tache
			if(this.hasFilterTache) foundLieux = foundLieux.filter((lieu)=>lieu.idTache==tache.id);
			// lieux sans dupliques
			foundLieux = foundLieux.filter((lieu, index, self)=>self.findIndex((l)=>l.idEquipement==lieu.idEquipement)==index);
			return foundLieux.length;
		},
		lieuxTerminesCounter: function(){
			let foundLieux = [...this.lieux];
			let searchValue = this.hasFilterSearch?this.searchfield.toLowerCase():null;
			let tache = this.tache;
			// filtre par statut fait
			foundLieux = foundLieux.filter((lieu)=>lieu.isVerificationNeeded=="0");
			// filtre champ recherche
			if(this.hasFilterSearch) foundLieux = foundLieux.filter((lieu)=>lieu.libel_equipement.toLowerCase().search(searchValue)!=-1 || lieu.path.toLowerCase().search(searchValue)!=-1);
			// filtre par tache
			if(this.hasFilterTache) foundLieux = foundLieux.filter((lieu)=>lieu.idTache==tache.id);
			// lieux sans dupliques
			foundLieux = foundLieux.filter((lieu, index, self)=>self.findIndex((l)=>l.idEquipement==lieu.idEquipement)==index);
			return foundLieux.length;
		}
    },
    methods: {
        goToProcessVerification:function(lieu){
			if(this.tache && this.tache.id){
				this.$router.push({name:"_process_verification", params:{type:"lieu", equipementId:lieu.idEquipement, tacheId:this.tache.id} });
			}else{
				let dupliquesLieux = this.lieux.filter((l)=>l.idEquipement==lieu.idEquipement);
				if(dupliquesLieux.length==1) this.$router.push({name:"_process_verification", params:{type:"lieu", equipementId:lieu.idEquipement, tacheId:lieu.idTache} });
				else this.$router.push({name:"_process_verification_taches_selection", params: {type:"lieu", equipementId:lieu.idEquipement}});
			}
		},
		onSuccessFetchTaches: function(){
			if(this.$route.params.tacheId && this.taches) this.tache = this.taches.find((t)=>t.id==this.$route.params.tacheId);
		}
    },
    created: function(){
        let searchField = this.$storage.get("searchfield-selectLieu");
        let tacheSelector = this.$storage.get("tacheSelector-selectLieu");
        searchField ? this.searchfield = searchField:null;
        tacheSelector ? this.tache = tacheSelector:null;
    },
    mounted: function(){

    }
};
</script>

<style lang="scss" scoped>
	.select-lieu{
		height: inherit;
		width: inherit;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 5px;
		.header{
			width: inherit;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.body{
			width: inherit;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 5px;
			.lieu{
				width: inherit;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				padding: 20px;
				.title{
					font-size: 11px;
					color: #333;
					font-weight: bold;
					display: flex;
					justify-content: flex-start;
				    white-space: nowrap;
				}
				.subtitle{
					color: #333;
					font-size: 10px;
				}
			}
		}
	}
</style>
