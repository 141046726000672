
<template >
    <vg-app-layout
        :title="$t('Header-Title-Equipement')"
        :icon="'categorie-equipement-gris.png'"
        :titleDataKeyword="'categorie-equipement'"
        :colorheader="getHeaderBackgroundColor"
        @action-export="handleActionExport">


        <template v-slot:search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>

        <template #panel-menu-more>
            <vg-button type="success"
                @click="displayCreateCategorieForm">
                {{$t("Header-ButtonName-Equipement")}}
            </vg-button>
        </template>

        <template v-slot:create-button>
            <vg-button type="success"
                @click="displayCreateCategorieForm">
                {{$t("Header-ButtonName-Equipement")}}
            </vg-button>
        </template>

        <template v-slot:primary>
            <vg-categories-table
                :agfilters="agFilters"
                @row-dbl-click="handleDblClick"
                @row-click="selectCategorie"
            >
            </vg-categories-table>

            <vg-equipements-table
                 v-if="selectedCategorie && !$vgutils.isMobile()"
                id="show-equipements-categories"
                :filters="equipementsFilters"
                hiddenColumns="['']"
                :showPrint="false"
                paginate
                @row-click="handleClickRow"
            >
            </vg-equipements-table>

        </template>
        <template v-slot:secondary>
            <div v-if="!selectedCategorie" style="height:50vh;display:flex;justify-content:center;align-items:center;">
                <span style="color:#3999ff;font-size:18px;">{{$t("selectionner-categorie")}}</span>
            </div>
            <vg-files-uploader v-if="selectedCategorie"
                :tagsList="VgFilesMixins.tags"
                @upload-done="handleUploadDone">
            </vg-files-uploader>
            <vg-files-viewer-list-section v-if="selectedCategorie"
                :files="VgFilesMixins_filesListSection"
                @delete="VgFilesMixins_fetchFilesByUid()">
            </vg-files-viewer-list-section>
            <hr>
            <vg-files-viewer-photo v-if="selectedCategorie"
                :files="VgFilesMixins_filesOnlyPhoto">
            </vg-files-viewer-photo>
        </template>
        <vg-categorie-form v-if="showCategorieCreate"
            v-model="selectedCategorie"
            @close="handleCloseCategorieForm"
            @save="handleSavedCategorie">
        </vg-categorie-form>
    </vg-app-layout>
</template>

<script>
	import Swatches from 'vue-swatches';
	import "vue-swatches/dist/vue-swatches.min.css";



	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

	import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
	import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
	import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";
    import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";
    import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";

	import VgInput from 'src/components/Vg/VgInput.vue';
	import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
	import VgButton from 'src/components/Vg/VgButton.vue';

    import CategorieMixins from 'src/mixins/CategorieMixins';
    import Metadatas from "src/services/Metadatas.js";
    import VgCategoriesTable from 'src/components/Vg/Categorie/VgCategoriesTable.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'categories-equipements',
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "menu-more-rapport": "Créer rapport",
            "Header-Title-Equipement": "Categories equipements",
            "Header-ButtonName-Equipement": "Créer catégorie équipements",
            "categorie": {
                "desktop": {
                    "Header-Title-Equipement": "Categories equipements",
                    "Header-ButtonName-Equipement": "Créer catégorie équipements",
                    "AgGrid-HeaderEquip-Categorie": "Catégorie",
                    "AgGrid-HeaderEquip-numeroImmobilisation": "Num. comptable",
                    "updateRow-placeholder-tag": "Tag",
                    "AgGrid-HeaderEquip-Tags": "Tags",
                    "AgGrid-HeaderEquip-nbEquip": "Nbre d éqts",
                    "AgGrid-HeaderEquip-valeur": "Valeur",
                    "AgGrid-HeaderEquip-dateDebut": "Date début",
                    "AgGrid-HeaderEquip-tauxDepreciation": "Taux de dépréciation",
                    "AgGrid-HeaderEquip-fournisseur": "Fournisseur",
                    "AgGrid-HeaderEquip-refConstructeur": "Ref. constructeur",
                    "AgGrid-HeaderEquip-sourceFinancement": "Source financement",
                    "RightPanel-recherche-placeholder": "Recherche catégorie",
                    "TabPanel-toutesCategories": "Toutes les catégories",
                    "indication-modifierValeur": "double click modifier valeurs",
                    "indication-modifierCategorie": "modifier catégorie",
                    "RightPanel-DefaultMessage": "Selectionner une categorie pour afficher le detail",
                    "RightPanel-TacheAsso-Header": "Tâches associées à catégorie",
                    "RightPanel-TacheAsso-btnName": "Accéder aux tâches",
                    "RightPanel-TacheAsso-DefaultMessage": "Aucune tâche associée à cette catégorie",
                    "photoCategorie-infoMessage": "photo(s) de la catégorie",
                    "photoCategorie-noData": "Aucune photo de la catégorie",
                    "Modal-CreateCat-HeaderEquipement": "Creer une nouvelle catégorie d equipement",
                    "Modal-CreateCat-icon": "Icône catégorie",
                    "Modal-CreateCat-icon-placeholder": "Sélectioner icône",
                    "Modal-CreateCat-tags": "Tags catégorie",
                    "Modal-CreateCat-Libelle": "Libellé catégorie",
                    "Modal-CreateCat-placeholder-Libelle": "Libellé Catégorie",
                    "Modal-CreateCat-Marque": "Marque",
                    "Modal-CreateCat-placeholder-Marque": "Marque",
                    "Modal-CreateCat-TypeCategorie": "Type catégorie",
                    "Modal-CreateCat-placeholder-TypeCategorie": "Type Categorie",
                    "Modal-CreateCat-Inventoriable": "Categories inventoriable",
                    "Modal-CreateCat-MaintenanceSysteme": "Maintenance systematique",
                    "Modal-CreateCat-positionEtiquette": "Position étiquette",
                    "Modal-CreateCat-Ref": "Référence constructeur (valeur par défaut)",
                    "Modal-CreateCat-Prix": "Prix (valeur par défaut)",
                    "Modal-CreateCat-Footer-BtnAnnule": "Annuler",
                    "Modal-CreateCat-Footer-BtnValide": "Créer",
                    "Modal-UpdateCatEquipement-Header": "Modifier la catégorie",
                    "Modal-UpdateCatEquipement-icon": "Icône catégorie",
                    "Modal-UpdateCatEquipement-icon-placeholder": "Sélectioner icône",
                    "Modal-UpdateCatEquipement-tags": "Tags catégorie",
                    "Modal-UpdateCatEquipement-Libelle": "Libellé catégorie",
                    "Modal-UpdateCatEquipement-Marque": "Marque",
                    "Modal-UpdateCatEquipement-TypeCategorie": "Type catégorie",
                    "Modal-UpdateCatEquipement-MaintenanceSysteme": "Maintenance systematique",
                    "Modal-UpdateCatEquipement-positionEtiquette": "Position étiquette",
                    "Modal-UpdateCatEquipement-refConstructeur": "Référence constructeur (valeur par défaut)",
                    "Modal-UpdateCatEquipement-prix": "Prix (valeur par défaut)",
                    "Modal-Suppression-Footer-BtnSuppr": "Supprimer",
                    "Modal-UpdateCatEquipement-Footer-BtnAnnule": "Annuler",
                    "Modal-UpdateCatEquipement-Footer-BtnValide": "Enregistrer"
                },
                "mobile": {
                    "tableau-header-categorie": "Catégorie",
                    "tableau-header-tags": "",
                    "tableau-header-nbEquip": "Nbre équipements",
                    "header-titre": "Catégorie équipements",
                    "more-menu-creation": "Créer une catégorie",
                    "zoom-btnAction-photo": "Photo catégorie",
                    "Modal-CreateCat-HeaderEquipement": "Nouvelle catégorie"
                }
            },
            "accounting-tag": "Etiquette comptable",
            "selectionner-categorie": "Sélectionner une catégorie",
            "liste-categories": "Liste des catégories"
        },
        "en": {
            "Header-Title-Equipement": "Equipment categories",
            "Header-ButtonName-Equipement": "Create equipment category",
            "categorie": {
                "desktop": {
                    "Header-Title-Equipement": "Equipment categories",
                    "Header-ButtonName-Equipement": "Create equipment category",
                    "AgGrid-HeaderEquip-Categorie": "Categories",
                    "AgGrid-HeaderEquip-numeroImmobilisation": "Accounting number",
                    "updateRow-placeholder-tag": "Tag",
                    "AgGrid-HeaderEquip-Tags": "Tags",
                    "AgGrid-HeaderEquip-nbEquip": "Number of equipment",
                    "AgGrid-HeaderEquip-valeur": "Cost",
                    "AgGrid-HeaderEquip-dateDebut": "Start Date",
                    "AgGrid-HeaderEquip-tauxDepreciation": "Depreciation Rate",
                    "AgGrid-HeaderEquip-fournisseur": "Supplier s Name",
                    "AgGrid-HeaderEquip-refConstructeur": "Serial Number",
                    "AgGrid-HeaderEquip-sourceFinancement": "Funding source",
                    "RightPanel-recherche-placeholder": "Search category",
                    "TabPanel-toutesCategories": "All",
                    "indication-modifierValeur": "double click : update row",
                    "indication-modifierCategorie": "update category",
                    "RightPanel-DefaultMessage": "Select a category to view details",
                    "RightPanel-TacheAsso-Header": "Tasks associated with category",
                    "RightPanel-TacheAsso-btnName": "Access to tasks",
                    "RightPanel-TacheAsso-DefaultMessage": "No task associated with this category",
                    "photoCategorie-infoMessage": "photo(s)",
                    "photoCategorie-noData": "No photo",
                    "Modal-CreateCat-HeaderEquipement": "Create a new equipment category",
                    "Modal-CreateCat-icon": "Category icon",
                    "Modal-CreateCat-icon-placeholder": "Select icon",
                    "Modal-CreateCat-tags": "Category tags",
                    "Modal-CreateCat-Libelle": "Category label",
                    "Modal-CreateCat-placeholder-Libelle": "Category label",
                    "Modal-CreateCat-Marque": "Brand",
                    "Modal-CreateCat-placeholder-Marque": "Brand",
                    "Modal-CreateCat-TypeCategorie": "Category type",
                    "Modal-CreateCat-placeholder-TypeCategorie": "Category type",
                    "Modal-CreateCat-Inventoriable": "Inventory categories",
                    "Modal-CreateCat-MaintenanceSysteme": "Systematic maintenance",
                    "Modal-CreateCat-positionEtiquette": "Label position",
                    "Modal-CreateCat-Ref": "Manufacturer s reference (valeur par défaut)",
                    "Modal-CreateCat-Prix": "Price",
                    "Modal-CreateCat-Footer-BtnAnnule": "Cancel",
                    "Modal-CreateCat-Footer-BtnValide": "Create",
                    "Modal-UpdateCatEquipement-Header": "Update category",
                    "Modal-UpdateCatEquipement-icon": "Category icon",
                    "Modal-UpdateCatEquipement-icon-placeholder": "Select icon",
                    "Modal-UpdateCatEquipement-tags": "Category tags",
                    "Modal-UpdateCatEquipement-Libelle": "Category label",
                    "Modal-UpdateCatEquipement-Marque": "Brand",
                    "Modal-UpdateCatEquipement-TypeCategorie": "Category type",
                    "Modal-UpdateCatEquipement-MaintenanceSysteme": "Systematic maintenance",
                    "Modal-UpdateCatEquipement-positionEtiquette": "Sticker position",
                    "Modal-UpdateCatEquipement-refConstructeur": "Reference number",
                    "Modal-UpdateCatEquipement-prix": "Price",
                    "Modal-Suppression-Footer-BtnSuppr": "Delete",
                    "Modal-UpdateCatEquipement-Footer-BtnAnnule": "Cancel",
                    "Modal-UpdateCatEquipement-Footer-BtnValide": "Save"
                },
                "mobile": {
                    "tableau-header-categorie": "Category",
                    "tableau-header-tags": "Tags",
                    "tableau-header-nbEquip": "Num of equipments",
                    "header-titre": "Equipments categories",
                    "more-menu-creation": "Create a category",
                    "zoom-btnAction-photo": " ",
                    "Modal-CreateCat-HeaderEquipement": "Create category"
                }
            },
            "menu-more-rapport": "Create report",
            "accounting-tag": "Accounting tag",
            "selectionner-categorie": "Select a category",
            "liste-categories": "List of categories"
        }
    }
},
		mixins: [CategorieMixins],
        components: {
			TagCellRender,
            VgFilesUploader,
            VgFilesViewerListSection,
            VgFilesViewerPhoto,
            Swatches,
            VgTextFilter,
            VgInput,
            VgCheckbox,
			VgButton,
            VgCategoriesTable,
            VgCategorieForm,
            VgEquipementsTable
        },
        props:{
            defaultFilters:{
                type: Object,
                default: function(){
                    return {};
                }
            }
        },
        data:function() {
            return {
				animateLoadingGrid: true,
                showCategorieCreate:false,
                searchQuery: '',
                equipementsFilters:{
                    isGEP:{attr:"e.isGroupEqp",colId:"e.isGroupEqp",value:"1",action:"not_equals"},
                    idCategorie:{attr:"e.idCategorie_id",colId:"e.idCategorie_id",value:null,action:"equals"}
                },
                agFilters:{
                    libelleCatgorie: { attr:"c.libelleCatgorie", value: null, action:"contains", openParenthesis: true },
                    tags: { attr:"c.tags", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true },
                }
            };
        },
		watch: {
			searchQuery: {
				handler: function(query){
                    this.agFilters.libelleCatgorie.value = query;
                    this.agFilters.tags.value = query;
				}
			}
		},
        computed: {
            ...mapGetters({
                  selectedCategorie: 'CategoriesStore/getSelectedItem'
            }),
            getHeaderBackgroundColor:function(){
                if(this.$vgutils.isMobile()){
                    return "#6c3a9e";
                }else{
                    return "white";
                }
            }
		},
        methods: {
            selectCategorie: function(row){
                if(!this.$vgutils.isMobile()){
                    this.equipementsFilters.idCategorie.value = row.id;
    				this.VgFilesMixins_setUid(row.uid);
    				this.VgFilesMixins_fetchFilesByUid();
                }
			},
            handleSavedCategorie:function(categorie){
                this.showCategorieCreate = false;
            },
            handleDblClick:function(categorie){
                this.showCategorieCreate = true;
            },
            handleCloseCategorieForm:function(){
                this.$store.dispatch("CategoriesStore/setSelectedItem", null);
                this.showCategorieCreate=false;
            },
            handleActionExport:function(event){
                let metadatas = new Metadatas();
                // metadatas only to get categorie lieux
                switch (event.name) {
                    case "askForXLS":
                        this.CategorieMixins_getFile(metadatas,"equipements", this.$t("liste-categories"),"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.CategorieMixins_getFile(metadatas, "equipements", this.$t("liste-categories"),"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent(event.name, {
                            "detail": {"idTableau":"vg-categories-equipements-table"}
                        }));
                        break;
                    default:
                }
            },
            displayCreateCategorieForm:function(){
                this.$store.dispatch("CategoriesStore/setSelectedItem",null);
                this.showCategorieCreate = true;
            }
        },
		created: function(){
            this.VgFilesMixins_init(null, "categorie");
            this.agFilters = Object.assign({}, this.agFilters, this.defaultFilters);
        },
        mounted: function() {},
        destroyed: function(){
            this.$store.dispatch("CategoriesStore/clear");
        }
    };
</script>
<style lang="scss" >

</style>
