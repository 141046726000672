<template lang="html">
    <div class="contrat-cell-render" @click="$emit('clicked', {data: contact})">
        <div class="header">
            <span><b>{{contact.tiers_name}}</b></span>
            <span>{{contact.firstname}} {{contact.lastname}}</span>
        </div>
        <div class="content">
            <a class="like-a-button" v-if="contact.tels && contact.tels != ''" :href="'tel:'+contact.tels">{{$t("appeler")}}</a>
            <a class="like-a-button" v-if="contact.telMobile && contact.telMobile != ''" :href="'tel:'+contact.telMobile">{{$t("appeler-mobile")}}</a>
            <a class="like-a-button" v-if="contact.email && contact.email != ''" :href="'mailto:'+contact.email">{{$t("envoyer-email")}}</a>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton.vue"
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
  name:"contacts-tiers-cell-render",
  mixins:[LangMixins],
  props:{
        contact:{
            type:Object,
            required:true,
            default: function(){
                return {};
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "appeler": "Appeler",
            "appeler-mobile": "Appeler mobile",
            "envoyer-email": "Envoyer un email"
        },
        "en": {
            "appeler": "Call",
            "appeler-mobile": "Call mobile",
            "envoyer-email": "Send an email"
        }
    }
},
    components:{
        VgButton
    }
});
</script>

<style lang="scss" scoped>
.contrat-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    .like-a-button{
        color: #673ab7;
        text-decoration: none;
        border: 1px solid #673ab7;
        padding: 5px;
    }
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
}
</style>
