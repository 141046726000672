<script>
import { Scatter } from 'vue-chartjs';

// https://www.chartjs.org/docs/latest/charts/mixed.html

export default {
	name: "vg-scatter-chart",
    extends: Scatter,
	props: {
		/**
		* datas à rendre
		* [{label: <bar name>, backgroundColor: <bar color>, data: <array of number>}]
		* <array of number> valeur pour chaque element de l'axe x
		*/
        datas : Array,
		/**
		* labels de l'axe horizontal
		* ex: ["janvier", "fevrier", ...]
		*/
        horizontalaxis : Array,
		/**
		* options du bar chart
		*/
		options : {
			type: Object,
			default: {}
		},
		/**
		* affiche ou cache la legend du bar chart
		*/
		showlegend: {
			type: Boolean,
			default: true
		}
	},
	i18n: {
		locale: "fr",
		messages: {
			fr: {
				"no-datas": "Aucune donnée à afficher"
			}
		}
	},
	data: function(){
		return {
			OPTIONS: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						display: false,
						gridLines: {
							display: false
						}
					}],
					xAxes: [{
						display: true,
						gridLines: {
							display: false
						}
					}]
				},
				legend: {
					display: this.showlegend,
					position: "right"
				},
				responsive: true,
				maintainAspectRatio: false,
				hover: {
					onHover: function(e) {
						var point = this.getElementAtEvent(e);
						if (point.length) e.target.style.cursor = 'pointer';
						else e.target.style.cursor = 'default';
					}
				},
				onClick: (e) => this.handleClickChart(e)
			}
		};
	},
	watch: {
		datas: {
			handler: function(value){
				this.renderLineChart();
			}
		}
	},
	methods: {
		renderScatterChart: function(){
			const emptyDataPlugin = {
				id: 'emptyData',
				afterDraw: (chart) => {
					if(chart.data && chart.data.datasets){
						if (chart.data.datasets.length==0 || chart.data.datasets[0].data.length==0) {
							const ctx = chart.ctx;
							const width = chart.width;
							const height = chart.height;
							ctx.save();
							ctx.textAlign = 'center';
							ctx.textBaseline = 'middle';
							ctx.font = '12px Arial';
							ctx.fillStyle = "#333";
							ctx.fillText(this.$t("no-datas"), width / 2, height / 2);
							ctx.restore();
						}
					}
				}
			};

			this.addPlugin(emptyDataPlugin);
			this.renderChart({
				labels: this.horizontalaxis,
				datasets: this.datas
			}, Object.assign(this.OPTIONS, this.options));
		},
		handleClickChart: function(e){
			// //console.log("HANDLE CLICK CHART", this.$data._chart.getElementAtEvent(e));
			var activeElement = this.$data._chart.getElementAtEvent(e);
			if(activeElement && activeElement[0]) this.$emit("selected", {
				index: activeElement[0]._index,
				datasetIndex: activeElement[0]._datasetIndex
			});
		}
	},
	mounted: function() {
		this.renderScatterChart();
	}
}
</script>

<style lang="scss">

</style>
