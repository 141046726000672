<template>
    <date-range-picker
        :ref="'_ref-'+$vnode.key"
        :locale-data="localeData"
        :autoApply="true"
        :opens="opens"
        :append-to-body="appendToBody"
        :ranges="presetsRanges"
        :show-week-numbers="true"
        v-model="dateRange"
        style="width:210px;"
        @select="logEvent('select', $event)"
        @toggle="onToggle"
        @start-selection="logEvent('start-selection', $event)"
        @finish-selection="logEvent('finish-selection', $event)">
        <template v-slot:input="picker">
            <span v-if="!picker.startDate && !picker.endDate" style="color:#acabbb;">{{ $t("aucune-dates") }}</span>
            <span v-else-if="readableDate(picker.startDate)==readableDate(picker.endDate)"><small>{{$t("le")}}</small> <b>{{ readableDate(picker.startDate) }}</b></span>
            <span v-else><small>{{ $t("du") }}</small> <b>{{ readableDate(picker.startDate) }}</b> <small>{{ $t("au") }}</small> <b>{{ readableDate(picker.endDate) }}</b></span>
        </template>
        <template v-slot:footer="datas">
            <div style="display: flex;justify-content: flex-start;gap:5px;align-items: baseline;padding: 5px 20px 5px 10px;border-top:1px solid whitesmoke;font-size:12px;">
                <span>{{ $t("personnalise") }}</span>
                <vg-input v-model="dateRangeLocale.startDate" 
                    inputType="date" 
                    style="width:120px;height:20px;font-size: 12px;"/>
                <span>-</span>
                <vg-input v-model="dateRangeLocale.endDate" 
                    inputType="date" 
                    style="width:120px;height:20px;font-size: 12px;"/>
                <vg-button v-if="dateRangeLocale.startDate!=dateRange.startDate || dateRangeLocale.endDate!=dateRange.endDate" 
                    size="sm" 
                    type="success" 
                    @click="saveDateRangeLocale()">
                    {{ $t("ok") }}
                </vg-button>
            </div>
        </template>
    </date-range-picker>
</template>

<script>
// https://innologica.github.io/vue2-daterange-picker/#example-playground
import { end, start } from '@popperjs/core';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import moment from 'moment';

export default {
    name: "vg-date-picker-shortcuts",
    props: {
        /**
        * @model
        * {startDate: ..., endDate: ...}
        */
        value: {
            type: Object,
            default: function(){
                return {startDate: null, endDate: null};
            }
        },
        shortcutsCollection: {
            type: Array,
            default: function(){
                return null;
            }
        },
        previousDatesPresets:{
            type: Boolean,
            default: false
        },
        nextDatesPresets:{
            type: Boolean,
            default: false
        },
        defaultStartDate: {
            type: String,
            default: null
        },
        defaultEndDate: {
            type: String,
            default: null
        },
        format:{
            type: String,
            default: "YYYY-MM-DD HH:mm"
        },
        opens:{
            type: String,
            default: "center"
        },
        appendToBody:{
            type: Boolean,
            default: false
        },
        showCustomRange:{
            type: Boolean,
            default: true
        }
    },
    components: {
        DateRangePicker,
        VgInput,
        VgButton
    },
    data: function() {
        return{
            dateRange: {
                startDate: null,
                endDate: null
            },
            dateRangeLocale:{
                startDate: null,
                endDate: null
            },
            localeData:{
                firstDay: 1, 
                format: 'dd-mm-yyyy HH:mm:ss',
                showDropdowns: true,
                separator: " - ",
                fromLabel: "From",
                toLabel: "To",
                showCustomRangeLabel: true,
                customRangeLabel: "Custom",
                weekLabel: this.$t("week-label"),
                daysOfWeek: [
                    this.$t("dimanche"),
                    this.$t("lundi"),
                    this.$t("mardi"),
                    this.$t("mercredi"),
                    this.$t("jeudi"),
                    this.$t("vendredi"),
                    this.$t("samedi")
                ],
                monthNames: [
                    this.$t("janvier"),
                    this.$t("fevrier"),
                    this.$t("mars"),
                    this.$t("avril"),
                    this.$t("mai"),
                    this.$t("juin"),
                    this.$t("juillet"),
                    this.$t("aout"),
                    this.$t("septembre"),
                    this.$t("octobre"),
                    this.$t("novembre"),
                    this.$t("decembre")
                ]
            }
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-date-selected":"Aucune date sélectionnée",
            "period": "Période",
            "today": "Aujourd'hui",
            "tomorrow": "Demain",
            "yesterday": "Hier",
            "last-2-weeks": "Ces 2 dernières semaines",
            "next-2-weeks": "Ces 2 prochaines semaines",
            "last-30-days": "Ces 30 derniers jours",
            "next-30-days": "Ces 30 prochains jours",
            "this-week": "Cette semaine",
            "last-7-days": "Ces 7 derniers jours",
            "this-month": "Ce mois-ci",
            "last-3-months": "Ces 3 derniers mois",
            "last-12-months": "Ces 12 derniers mois",
            "last-year": "L'année dernière",
            "this-year": "Cette année",
            "intervalle": "Intervalle de dates",
            "modal-intervalle-title": "Intervalle de dates",
            "du": "du",
            "au": "au",
            "week-label": "Sem.",
            "dimanche": "D",
            "lundi": "L",
            "mardi": "M",
            "mercredi": "M",
            "jeudi": "J",
            "vendredi": "V",
            "samedi": "S",
            "janvier": "Janvier",
            "fevrier": "Février",
            "mars": "Mars",
            "avril": "Avril",
            "mai": "Mai",
            "juin": "Juin",
            "juillet": "Juillet",
            "aout": "Août",
            "septembre": "Septembre",
            "octobre": "Octobre",
            "novembre": "Novembre",
            "decembre": "Décembre",
            "aucune-dates": "Aucune dates sélectionnées",
            "le": "Le",
            "year": "Année",
            "personnalise": "Personnalisé",
            "ok": "Ok"
        },
        "en": {
            "period": "Period",
            "today": "Today",
            "tomorrow": "Tomorrow",
            "yesterday": "Yesterday",
            "last-30-days": "Last 30 days",
            "this-week": "This Week",
            "last-7-days": "Last 7 days",
            "this-month": "This month",
            "last-3-months": "Last 3 months",
            "last-12-months": "Last 12 months",
            "last-year": "Last year",
            "this-year": "This year",
            "intervalle": "Date range",
            "modal-intervalle-title": "Date range",
            "du": "From",
            "au": "To",
            "week-label": "Week",
            "dimanche": "Sun",
            "lundi": "Mon",
            "mardi": "Tue",
            "mercredi": "Wed",
            "jeudi": "Thu",
            "vendredi": "Fri",
            "samedi": "Sat",
            "janvier": "January",
            "fevrier": "February",
            "mars": "March",
            "avril": "April",
            "mai": "May",
            "juin": "June",
            "juillet": "July",
            "aout": "August",
            "septembre": "September",
            "octobre": "October",
            "novembre": "November",
            "decembre": "December",
            "aucune-dates": "No date selected",
            "le": "On",
            "year": "",
            "personnalise": "Custom",
            "ok": "Ok"
        },
        "th": {
            "period": "ช่วงเวลา",
            "today": "วันนี้",
            "tomorrow": "วันพรุ่งนี้",
            "this-week": "สัปดาห์นี้",
            "this-month": "เดือนนี้",
            "this-year": "ปีนี้",
            "intervalle": "ขอบข่ายเวลา",
            "modal-intervalle-title": "ขอบข่ายวันที่",
            "du": "ตั้งแต่",
            "au": "ถึง"
        }
    }
},
    watch: {
        defaultStartDate: {
            handler: function(newStartDate){
                this.dateRange.startDate = newStartDate;
                this.setDateRangeLocale();
            }
        },
        defaultEndDate: {
            handler: function(newEndDate){
                this.dateRange.endDate = newEndDate;
                this.setDateRangeLocale();
            }
        }
    },
    created: function(){
        if(this.value) this.dateRange = Object.assign({}, {}, this.value);
        if(this.defaultStartDate) this.dateRange.startDate = this.defaultStartDate;
        if(this.defaultEndDate) this.dateRange.endDate = this.defaultEndDate;
        this.setDateRangeLocale();
    },
    methods: {
        onToggle: function(event){
            if(event) this.setDateRangeLocale();
            this.logEvent('toggle', event);
        },
        logEvent: function(name, dt){
            console.log("LOG EVENT", name, dt);
            if(name!="select" && moment(dt).isValid()) this.$emit(name, moment(dt).format("YYYY-MM-DD"));
            else if(dt && dt.startDate && dt.endDate) this.$emit(name, {startDate: moment(dt.startDate).format("YYYY-MM-DD 00:00"), endDate: moment(dt.endDate).format("YYYY-MM-DD 23:59")});
        },
        readableDate: function(dt){
            if(dt) return moment(dt).format("DD/MM/YYYY");
            else return "";
        },
        setDateRangeLocale: function(){
            this.dateRangeLocale.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
            this.dateRangeLocale.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
        },
        saveDateRangeLocale: function(){
            console.log("dateRangeLocale", this.dateRangeLocale);
            this.logEvent('select', {
                startDate: moment(this.dateRangeLocale.startDate).format("YYYY-MM-DD 00:00"),
                endDate: moment(this.dateRangeLocale.endDate).format("YYYY-MM-DD 23:59"),
                value: "custom"
            });
            this.$refs['_ref-'+this.$vnode.key].togglePicker(false);
        }
    },
    computed: {
        presetsRanges: function(){
            let presets = {};
            this.shortcuts.forEach((shortcut)=>{
                presets[shortcut.label] = [moment(shortcut.value.startDate).toDate(), moment(shortcut.value.endDate).toDate()];
            });
            return presets;
        },
        today: function(){
            let today = moment();
            return {startDate: today.format("YYYY-MM-DD 00:00"), endDate: today.format("YYYY-MM-DD 23:59"), value: "today"};
        },
        tomorrow: function(){
            let tomorrow = moment().add(1,"days");
            return {startDate: tomorrow.format("YYYY-MM-DD 00:00"), endDate: tomorrow.format("YYYY-MM-DD 23:59"), value: "tomorrow"};
        },
        yesterday: function(){
            let yesterday = moment().subtract(1,"days");
            return {startDate: yesterday.format("YYYY-MM-DD 00:00"), endDate: yesterday.format("YYYY-MM-DD 23:59"), value: "yesterday"};
        },
        thisWeek: function(){
            return {startDate: moment().isoWeekday(1).format("YYYY-MM-DD 00:00"), endDate: moment().isoWeekday(7).format("YYYY-MM-DD 23:59"), value: "week"};
        },
        last7Days: function(){
            return {startDate: moment().subtract(7,"days").format("YYYY-MM-DD 00:00"), endDate: moment().format("YYYY-MM-DD 23:59"), value: "last7days"};
        },
        last2Weeks: function(){
            return {startDate: moment().isoWeekday(1).format("YYYY-MM-DD 00:00"), endDate: moment().isoWeekday(7).format("YYYY-MM-DD 23:59"), value: "last2weeks"};
        },
        next2Weeks: function(){
            return {startDate: moment().isoWeekday(1).format("YYYY-MM-DD 00:00"), endDate: moment().isoWeekday(7).format("YYYY-MM-DD 23:59"), value: "next2weeks"};
        },
        thisMonth: function(){
            return {startDate: moment().startOf('month').format("YYYY-MM-DD 00:00"), endDate: moment().endOf('month').format("YYYY-MM-DD 23:59"), value: "month"};
        },
        last30days: function(){
            let last30days = moment().subtract(30,"days");
            return {startDate: last30days.format("YYYY-MM-DD 00:00"), endDate: moment().format("YYYY-MM-DD 23:59"), value: "last30days"};
        },
        last3Months: function(){
            return {startDate: moment().subtract(3, "months").format("YYYY-MM-DD 00:00"), endDate: moment().format("YYYY-MM-DD 23:59"), value: "last3Months"};
        },
        last12Months: function(){
            return {startDate: moment().subtract(12, "months").format("YYYY-MM-DD 00:00"), endDate: moment().format("YYYY-MM-DD 23:59"), value: "last12months"};
        },
        lastLastYear: function(){
            return {startDate: moment().subtract(2, "year").format("YYYY-01-01 00:00"), endDate: moment().subtract(2, "year").format("YYYY-12-31 23:59"), value: "lastlastyear"};
        },
        lastYear: function(){
            return {startDate: moment().subtract(1, "year").format("YYYY-01-01 00:00"), endDate: moment().subtract(1, "year").format("YYYY-12-31 23:59"), value: "lastyear"};
        },
        thisYear: function(){
            return {startDate: moment().startOf('year').format("YYYY-MM-DD 00:00"), endDate: moment().endOf('year').format("YYYY-MM-DD 23:59"), value: "year"};
        },
        shortcuts: function(){
            if(this.shortcutsCollection){
                let shortcuts = [];
                this.shortcutsCollection.forEach((shortcutName)=>{
                    switch(shortcutName){
                        case "today":
                            shortcuts.push({label: this.$t("today"), value: this.today, name: "today"});
                            break;
                        case "tomorrow":
                            shortcuts.push({label: this.$t("tomorrow"), value: this.tomorrow, name: "tomorrow"});
                            break;
                        case "yesterday":
                            shortcuts.push({label: this.$t("yesterday"), value: this.yesterday, name: "yesterday"});
                            break;
                        case "week":
                            shortcuts.push({label: this.$t("this-week"), value: this.thisWeek, name: "week"});
                            break;
                            //"last7days", "last30days", "month", "last3months", "last12months", "lastyear"
                        case "last7days":
                            shortcuts.push({label: this.$t("last-7-days"), value: this.last7Days, name: "last7days"});
                            break;
                        case "last2weeks":
                            shortcuts.push({label: this.$t("last-2-weeks"), value: this.last2Weeks, name: "last2weeks"});
                            break;
                        case "next2weeks":
                            shortcuts.push({label: this.$t("next-2-weeks"), value: this.next2Weeks, name: "next2weeks"});
                            break;
                        case "month":
                            shortcuts.push({label: this.$t("this-month"), value: this.thisMonth, name: "month"});
                            break;
                        case "last30days":
                            shortcuts.push({label: this.$t("last-30-days"), value: this.last30days, name: "last30days"});
                            break;
                        case "next30days":
                            shortcuts.push({label: this.$t("next-30-days"), value: this.next30days, name: "next30days"});
                            break;
                        case "last3months":
                            shortcuts.push({label: this.$t("last-3-months"), value: this.last3Months, name: "last3months"});
                            break;
                        case "last12months":
                            shortcuts.push({label: this.$t("last-12-months"), value: this.last12Months, name: "last12months"});
                            break;
                        case "lastyear":
                            shortcuts.push({label: this.$t("last-year"), value: this.lastYear, name: "lastyear"});
                            break;
                        case "year":
                            shortcuts.push({label: this.$t("this-year"), value: this.thisYear, name: "year"});
                            break;
                        default:
                            break;
                    }
                });
                return shortcuts;
            }else if(this.previousDatesPresets){
                return [
                    {label: this.$t("today"), value: this.today, name: "today"},
                    {label: this.$t("yesterday"), value: this.yesterday, name: "yesterday"},
                    {label: this.$t("this-week"), value: this.thisWeek, name: "week"},
                    {label: this.$t("last-7-days"), value: this.last7Days, name: "last7Days"},
                    {label: this.$t("last-30-days"), value: this.last30days, name: "last30days"},
                    {label: this.$t("this-month"), value: this.thisMonth, name: "month"},
                    {label: this.$t("last-12-months"), value: this.last12Months, name: "last12Months"},
                    {label: this.$t("year")+" "+moment().subtract(2, "year").format("YYYY"), value: this.lastLastYear, name: "lastlastyear"},
                    {label: this.$t("year")+" "+moment().subtract(1, "year").format("YYYY"), value: this.lastYear, name: "lastyear"},
                    {label: this.$t("year")+" "+moment().format("YYYY"), value: this.thisYear, name: "year"}
                ];
            }else if(this.nextDatesPresets){
                return [
                    {label: this.$t("today"), value: this.today, name: "today"},
                    {label: this.$t("tomorrow"), value: this.tomorrow, name: "tomorrow"},
                    {label: this.$t("this-week"), value: this.thisWeek, name: "week"},
                    {label: this.$t("this-month"), value: this.thisMonth, name: "month"},
                    {label: this.$t("this-year"), value: this.thisYear, name: "year"}
                ];
            }else{
                return [
                    {label: this.$t("today"), value: this.today, name: "today"},
                    {label: this.$t("tomorrow"), value: this.tomorrow, name: "tomorrow"},
                    {label: this.$t("this-week"), value: this.thisWeek, name: "week"},
                    {label: this.$t("this-month"), value: this.thisMonth, name: "month"},
                    {label: this.$t("this-year"), value: this.thisYear, name: "year"}
                ];
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .daterangepicker{
        .calendars{
            .ranges{
                li{
                    padding: 6px 8px !important;
                }
            }
            .calendars-container{
                .table-condensed{
                    .today{
                        color: #059cff !important;
                    }
                    .active:not(.off){
                        color: white !important;
                        background-color: #059cff !important;
                    }
                    td:hover{
                        font-weight: bold !important;
                    }
                    .week:hover{
                        font-weight: normal !important;
                    }
                }
            }
        }
    }
    .date-range-picker-footer-date-editable{
        
    }
    .date-range-picker-footer-date-editable:hover{
        cursor: text;
        font-weight: bold;
    }
</style>
