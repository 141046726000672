<template>
    <form id="groupe-validateurs-form"
        ref="groupe-validateurs-form"
        class="vg-groupe-validateurs-form"
        onSubmit="return false;">
        <div v-for="(groupe,index) in groupesValidateurs" style="margin:15px 0;display:flex;justify-content:flex-start;align-items:center;">
            <vg-input v-if="showUpdate && selectedGroupeValidateurs.id==groupe.id"
                v-model="selectedGroupeValidateurs.name"
                style="margin-right:10px;width:200px;"/>
            <div v-else style="display:flex;justify-content:flex-start;align-items:center;border: 1px solid grey;padding: 5px;">
                <span style="margin-right:10px;font-weight:bold;">{{groupe.name}}</span>
                <!-- btn show update groupe -->
                <vg-button
                    :type="'info'"
                    :size="'xs'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="showUpdateGroupeValidateurs(groupe, index)">
                    <i class="fas fa-pen"></i>
                </vg-button>
                <!-- btn delete groupe -->
                <vg-button
                    :type="'danger'"
                    :size="'xs'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="showDeleteGroupeValidateurs(groupe, index)">
                    <i class="fas fa-trash-alt"></i>
                </vg-button>
            </div>

            <!-- btn update groupe -->
            <vg-button v-if="showUpdate && selectedGroupeValidateurs.id==groupe.id"
                :type="'success'"
                :size="'xs'"
                @click="onUpdateGroupeValidateurs(groupe, index)">
                <i class="fas fa-save"></i>
            </vg-button>
            <!-- btn close update groupe -->
            <vg-button v-if="showUpdate && selectedGroupeValidateurs.id==groupe.id"
                :type="'grey'"
                :size="'xs'"
                @click="showUpdate=false;selectedGroupeValidateurs=null;">
                <i class="fas fa-times"></i>
            </vg-button>


            <!-- USERS ASSOCIES à la validation -->
            <div style="display:flex;justify-content:flex-start;align-items:center;border: 1px solid grey;padding: 5px;margin: 0 10px;">
                <div v-for="(validateur, indexValidateur) in groupe.validateurs" style="margin: 0 10px;display:flex;align-items:center;">
                    <span style="margin-right:5px;font-size:12px;">{{validateur.validateur.nom}} {{validateur.validateur.prenom}}</span>
                    <vg-button v-if="groupe.validateurs.length>1"
                        :type="'danger'"
                        :size="'xs'"
                        :disabled="$app.role!='ROLE_ADMIN'"
                        @click="showDeleteGroupeValidateurUser(groupe, validateur)">
                        <i class="fas fa-trash-alt"></i>
                    </vg-button>
                    <span>/</span>
                </div>
                <vg-button v-if="!showAssociateValidateurSelector || (showAssociateValidateurSelector && selectedGroupeValidateurs.id!=groupe.id)"
                    :type="'info'"
                    :size="'xs'"
                    :disabled="$app.role!='ROLE_ADMIN'"
                    @click="showAssociateValidateur(groupe, index)">
                    <i class="fas fa-plus"></i>
                </vg-button>
                <div v-else style="width:300px;display:flex;justify-content:flex-start;align-items:center;">
                    <vg-users-selector v-model="newValidateur"
                        style="margin-right:10px;"
                        :clearable="false"/>
                    <vg-button
                        :type="'success'"
                        :size="'xs'"
                        :disabled="$app.role!='ROLE_ADMIN'"
                        @click="onAssociateNewValidateur">
                        <i class="fas fa-save"></i>
                    </vg-button>
                    <vg-button
                        :type="'grey'"
                        :size="'xs'"
                        :disabled="$app.role!='ROLE_ADMIN'"
                        @click="showAssociateValidateurSelector=false;newValidateur=null;">
                        <i class="fas fa-times"></i>
                    </vg-button>
                </div>
            </div>

        </div>

        <!-- CREATION NOUVEAU NIVEAU VALIDATION -->
        <div v-if="showCreate" style="display:flex;justify-content:flex-start;align-items:center;">
            <vg-input v-model="newGroupeValidateurs.name" style="margin-right:10px;width:300px;" :placeholder="$t('libel-groupe-validateurs')"/>
            <vg-users-selector v-model="newValidateur"
                style="margin-right:10px;width:200px;"
                :clearable="false"/>
            <vg-button
                :type="'success'"
                :size="'sm'"
                :disabled="!newGroupeValidateurs.name || !newValidateur"
                @click="onSaveGroupeValidateurs(newGroupeValidateurs, index)">
                <i class="fas fa-save"></i>
            </vg-button>
        </div>
        <vg-button v-else :type="'info'"
            :size="'sm'"
            :disabled="$app.role!='ROLE_ADMIN'"
            @click="showCreate=true;">
            <i class="fas fa-plus"></i>
        </vg-button>

        <input
          v-show="false"
          ref="tva-submit"
          type="submit"
        >

        <dialog-secured-action
          v-if="showDelete && selectedGroupeValidateurs"
          :valid-response="selectedGroupeValidateurs.name"
          @save="onDeleteGroupeValidateurs"
          @close="showDelete=false;selectedGroupeValidateurs=null;">
          <template v-slot:header-title>
            {{ $t("confirm-suppression") }} <b>{{ selectedGroupeValidateurs.name }}</b> ?
          </template>
        </dialog-secured-action>
        <dialog-secured-action
          v-if="showDeleteValidateur && selectedGroupeValidateurUser"
          :valid-response="'1'"
          @save="onDeleteGroupeValidateurUser"
          @close="showDeleteValidateur=false;selectedGroupeValidateurUser=null;">
          <template v-slot:header-title>
            {{ $t("confirm-suppression-validateur", {nom: selectedGroupeValidateurUser.validateur.nom, prenom: selectedGroupeValidateurUser.validateur.prenom}) }} <b>{{ selectedGroupeValidateurs.name }}</b> ?
          </template>
        </dialog-secured-action>
    </form>
</template>
<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import GroupeValidateursMixins from "src/mixins/GroupeValidateursMixins.js";
import GroupeValidateursUsersMixins from "src/mixins/GroupeValidateursUsersMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'vg-groupe-validateurs-form',
    components:{
        VgButton,
        VgInput,
        VgUsersSelector,
        DialogSecuredAction
    },
    mixins:[ GroupeValidateursMixins, GroupeValidateursUsersMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "libel-groupe-validateurs": "Nom du niveau de validation/approbation",
            "confirm-suppression": "Voulez-vous supprimer le niveau de validation",
            "confirm-suppression-validateur": "Voulez-vous désassocier l'utilisateur {nom} {prenom} au niveau de validation",
            "impossible-suprimer-groupe-validateurs": "Impossible de supprimer le groupe de validateur {name}. Veuillez contacter notre support."
        }
    }
},
    props: {

    },
    data: function() {
        return {
            metadatas: new Metadatas(),
            agfilters: {

            },
            selectedGroupeValidateurs: null,
            selectedGroupeValidateurUser: null,
            showCreate: false,
            showUpdate: false,
            showDelete: false,
            showDeleteValidateur: false,
            showAssociateValidateurSelector: false,
            newValidateur: null,
            newGroupeValidateurs: {
                name: null,
                userId: this.$app.appID,
                validateurs: []
            }
        };
    },
    watch:{

    },
    computed:{
        ...mapGetters({
            groupesValidateurs: 'GroupeValidateursStore/getCollection'
        })
    },
    created: function() {
        this.fetch();
    },
    methods: {
        showDeleteGroupeValidateurUser: function(groupe, validateur){
            this.selectedGroupeValidateurs = groupe;
            this.selectedGroupeValidateurUser = validateur;
            this.showDeleteValidateur = true;
        },
        onDeleteGroupeValidateurUser: function(){
            this.showDeleteValidateur = false;
            this.GroupeValidateursUsersMixins_delete(this.selectedGroupeValidateurUser).then((datas)=>{
                this.selectedGroupeValidateurs = null;
                this.selectedGroupeValidateurUser = null;
            });
        },
        onAssociateNewValidateur: function(){
            this.showAssociateValidateurSelector = false;
            this.GroupeValidateursUsersMixins_create(this.selectedGroupeValidateurs, [{id: this.newValidateur}]).then((datas)=>{
                this.selectedGroupeValidateurs = null;
                this.newValidateur = null;
            });
        },
        onSaveGroupeValidateurs: function(){
            this.showCreate = false;
            let groupeValidateurs = Object.assign({}, this.newGroupeValidateurs, {validateurs: [{id: this.newValidateur}]});
            this.GroupeValidateursMixins_create([groupeValidateurs]).then((datas)=>{
                this.newGroupeValidateurs = {
                    name: null,
                    userId: this.$app.appID,
                    validateurs: []
                };
                this.newValidateur = null;
            });
        },
        onDeleteGroupeValidateurs: function(){
            this.showDelete = false;
            this.GroupeValidateursMixins_delete(this.selectedGroupeValidateurs).then((data)=>{
                this.selectedGroupeValidateurs = null;
            }).catch((err)=>{
                this.$message(this.$t("impossible-suprimer-groupe-validateurs", {name: this.selectedGroupeValidateurs.name}), {type: "warning", timeout: 5000});
            });
        },
        onUpdateGroupeValidateurs: function(){
            this.showUpdate = false;
            this.GroupeValidateursMixins_update(this.selectedGroupeValidateurs).then((data)=>{
                this.selectedGroupeValidateurs = null;
            });
        },
        showAssociateValidateur: function(groupeValidateurs, index){
            this.selectedGroupeValidateurs = groupeValidateurs;
            this.showAssociateValidateurSelector = true;
        },
        showUpdateGroupeValidateurs: function(groupeValidateurs, index){
            this.selectedGroupeValidateurs = Object.assign({}, {}, groupeValidateurs);
            this.showUpdate = true;
        },
        showDeleteGroupeValidateurs: function(groupeValidateurs, index){
            this.selectedGroupeValidateurs = Object.assign({}, {}, groupeValidateurs);
            this.showDelete = true;
        },
        fetch: function(){
            this.metadatas.setFilters(this.agfilters);
            this.GroupeValidateursMixins_getGroupeValidateurs(this.metadatas).then((datas)=>{

            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
