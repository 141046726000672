class ObjectUtilities{
    static compareObjects(obj1, obj2) {
        let differences = {};
    
        // Function to compare values, considering null values as equal
        const isDifferent = (value1, value2) => {
            if (value1 === null && value2 === null) {
                return false;
            }
            return value1 !== value2 || (typeof value1 === 'object' && typeof value2 === 'object');
        };
    
        // Check for keys in obj1 that differ from obj2
        for (let key in obj1) {
            if (!obj2.hasOwnProperty(key) || isDifferent(obj1[key], obj2[key])) {
                differences[key] = obj1[key];
            }
        }
    
        // Check for keys in obj2 that differ from obj1
        for (let key in obj2) {
            if (!obj1.hasOwnProperty(key) || isDifferent(obj1[key], obj2[key])) {
                differences[key] = obj2[key];
            }
        }
    
        return differences;
    }
    
  static copy(obj) {
    console.log('copy',{obj})
    return JSON.parse(JSON.stringify(obj));
  }
}
export default ObjectUtilities;