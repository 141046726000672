<template>
    <div class="vg-calendar-planning">
        <div class="header" v-if="showCalendarDays">
            <vg-button type="default-grey" @click="previousDay" style="width:40px;"><i class="fas fa-chevron-left"></i></vg-button>
            <div class="days">
                <div v-for="(day, indexDay) in days" 
                    :key="'day-'+indexDay"
                    :class="['day', {'current-day': day==currentDay, 'today': day==today}]"
                    @click="onClickDay(day)">
                    {{ getDD(day) }}
                </div>
            </div>
            <vg-button type="default-grey" @click="nextDay" style="width:40px;"><i class="fas fa-chevron-right"></i></vg-button>
        </div>
        <div class="content">
            <div class="verbose-day">{{ getVerboseCurrentDay }}</div>
            <div v-for="(schedule, indexSchedule) in getCurrentDaySchedules" class="schedule" :key="'schedule-'+indexSchedule"
                @click="onClickSchedule(schedule)">
                <div class="schedule-icon">
                    <div v-if="schedule.calendarId=='maintenance-affectation' || schedule.calendarId=='maintenance-ouverture' || schedule.calendarId=='maintenance-fermeture'" 
                        class="icon-container">
                        <img class="icon"
                            :src="schedule.raw.statut=='Resolue'?'static/assets/icone/maintenance-verte.png':'static/assets/icone/maintenance-rouge.png'"/>
                            <img v-if="schedule.raw.isGEP=='1' && schedule.raw.libelComposant && schedule.raw.iconComposant"
                                class="icon"
                                :src="schedule.raw.iconComposant"
                                onerror="this.style.visibility='hidden'"/>
                            <img v-else-if="schedule.raw.equipement && schedule.raw.equipement.isGroupEqp=='1' && schedule.raw.composant && schedule.raw.composant.libelComposant && schedule.raw.composant.icon"
                                class="icon"
                                :src="schedule.raw.composant.icon"
                                onerror="this.style.visibility='hidden'"/>
                            <img v-else-if="schedule.raw.equipement && schedule.raw.equipement.categorie_icon"
                                class="icon"
                                :src="schedule.raw.equipement.categorie_icon"
                                onerror="this.style.visibility='hidden'"/>
                    </div>
                    <div v-else-if="schedule.calendarId=='intervention-programmee'" class="icon-container">
                        <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                            :color="schedule.raw.statut=='intervention-realisee'?'#45b348':'red'"
                            :size="'xs'" />
                        <img v-if="schedule.raw.contrat && schedule.raw.contrat.categorie && schedule.raw.contrat.categorie.icon" 
                            :src="schedule.raw.contrat.categorie.icon" class="icon" />
                        <img v-else-if="schedule.raw.contrat && schedule.raw.contrat.categorie && (schedule.raw.contrat.categorie.isGe==1 || schedule.raw.contrat.categorie.isGe=='1')" 
                            src="static/assets/icone/lieu.png" class="icon" />
                    </div>
                    <div v-else-if="schedule.calendarId=='tache-session'" class="icon-container">
                        <vg-icon :url="'static/assets/icone/calendar-event-recurrent.svg'" 
                            :color="'#059cff'"
                            :size="'xs'" />
                        <img v-if="schedule.raw.tache && schedule.raw.tache.categorie_icon" :src="schedule.raw.tache.categorie_icon" class="icon" />
                        <img v-else-if="schedule.raw.tache && (schedule.raw.tache.categorie_isGe==1 || schedule.raw.tache.categorie_isGe=='1')" 
                            src="static/assets/icone/lieu.png" class="icon" />
                    </div>
                </div>
                <div v-if="schedule.calendarId=='maintenance-affectation' || schedule.calendarId=='maintenance-ouverture' || schedule.calendarId=='maintenance-fermeture'" 
                    class="schedule-description">
                    <div class="title">
                        <span style="font-weight: bold;">{{$t("fm-n")}} {{ schedule.raw.id }}</span>
                        <span>&nbsp;{{$t("dans")}}&nbsp;</span>
                        <span>{{schedule.raw.equipement.libel_lieu}}</span>
                    </div>
                    <vg-fichesav-description :fm="schedule.raw" :showIcon="false" />
                </div>
                <div v-else-if="schedule.calendarId=='intervention-programmee'" class="schedule-description">
                    <div class="title" style="font-weight: bold;font-size:12px;">
                        {{schedule.raw.intitule}}
                    </div>
                    <div class="title">
                        <span :class="schedule.raw.status">{{ $t("status-"+schedule.raw.status) }}</span>
                    </div>
                </div>
                <div v-else-if="schedule.calendarId=='tache-session'" class="schedule-description">
                    <div class="title" style="font-weight: bold;font-size:12px;">
                        {{schedule.raw.tache.libel_tache}}
                    </div>
                    <div class="title">
                        <small>{{ $t("date-butoir") }}: {{ getDDMMYYYY(schedule.raw.tache.dateprochaineVerif) }}</small>
                        <progress style="width:50px;cursor:pointer;margin-left: 10px;" 
                            :max="schedule.raw.tache.nbEquipementToCheck" nbEquipementChecked
                            :value="schedule.raw.tache.nbEquipementChecked"></progress>
                        <small style="margin-left:10px;">{{ schedule.raw.tache.nbEquipementChecked }} / {{ schedule.raw.tache.nbEquipementToCheck }}</small>
                    </div>
                </div>
                <div class="schedule-time-interval">
                    <span class="debut">{{ getHHmm(schedule.start) }}</span>
                    <span class="fin">{{ getHHmm(schedule.end) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import VgButton from "src/components/Vg/VgButton.vue";
import VgIcon from "src/components/Vg/VgIcon.vue";
import VgProgress from "src/components/Vg/VgProgress.vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";

export default {
    name: "vg-calendar-planning",
    components:{
        VgButton,
        VgIcon,
        VgProgress,
        VgFichesavDescription
    },
    props: {
        calendars: {
            type: Array,
            default: []
        },
        schedules: {
            type: Array,
            default: []
        },
        isReadOnly: {
            type: Boolean,
            default: false
        },
        focusedDay: {
            type: String,
            default: null
        },
        showCalendarDays:{
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "dayname-Monday": "Lundi {DD} {MM} {YYYY}",
            "dayname-Tuesday": "Mardi {DD} {MM} {YYYY}",
            "dayname-Wednesday": "Mercredi {DD} {MM} {YYYY}",
            "dayname-Thursday": "Jeudi {DD} {MM} {YYYY}",
            "dayname-Friday": "Vendredi {DD} {MM} {YYYY}",
            "dayname-Saturday": "Samedi {DD} {MM} {YYYY}",
            "dayname-Sunday": "Dimanche {DD} {MM} {YYYY}",
            "today": "Aujourd'hui",
            "view-day": "Jour",
            "view-week": "Semaine",
            "view-month": "Mois",
            "view-planning": "Planning",
            "month-Sun": "D",
            "month-Mon": "L",
            "month-Tue": "M",
            "month-Wed": "M",
            "month-Thu": "J",
            "month-Fri": "V",
            "month-Sat": "S",
            "month-01": "Janvier",
            "month-02": "Février",
            "month-03": "Mars",
            "month-04": "Avril",
            "month-05": "Mai",
            "month-06": "Juin",
            "month-07": "Juillet",
            "month-08": "Août",
            "month-09": "Septembre",
            "month-10": "Octobre",
            "month-11": "Novembre",
            "month-12": "Décembre",
            "popup-update": "Modifier",
            "popup-delete": "Supprimer",
            "tous": "Tous",
            "status-intervention-realisee": "Réalisée",
            "status-intervention-prevue": "Prévue",
            "affectation": "Affectation",
            "date-butoir": "Date butoir",
            "fm-n": "Fiche n°"
        },
        "en": {
            "dimanche": "SUN.",
            "lundi": "MON.",
            "mardi": "TUE.",
            "mercredi": "WED.",
            "jeudi": "THU",
            "vendredi": "FRI.",
            "samedi": "SAT.",
            "today": "Saturday",
            "view-day": "Day",
            "view-week": "Week",
            "view-month": "Month",
            "view-planning": "Planning",
            "month-Sun": "Sunday",
            "month-Mon": "Monday",
            "month-Tue": "Tuesday",
            "month-Wed": "Wednesday",
            "month-Thu": "Thursday",
            "month-Fri": "Friday",
            "month-Sat": "Saturday",
            "month-01": "January",
            "month-02": "February",
            "month-03": "March",
            "month-04": "April",
            "month-05": "May",
            "month-06": "June",
            "month-07": "July",
            "month-08": "August",
            "month-09": "September",
            "month-10": "October",
            "month-11": "November",
            "month-12": "Décember",
            "popup-update": "Update",
            "popup-delete": "Delete",
            "tous": "All",
            "autres": "autres",
            "status-intervention-realisee": "Completed",
            "status-intervention-prevue": "Scheduled",
            "affectation": "Assignation",
            "date-butoir": "Deadline",
            "fm-n": "MS n°"
        },
        "th": {
            "dimanche": "อา.",
            "lundi": "จ.",
            "mardi": "อ.",
            "mercredi": "พ.",
            "jeudi": "พฤ.",
            "vendredi": "ศ.",
            "samedi": "ส.",
            "today": "วันเสาร์",
            "view-day": "วัน",
            "view-week": "สัปดาห์",
            "view-month": "เดือน",
            "month-Sun": "วันอาทิตย์",
            "month-Mon": "วันจันทร์",
            "month-Tue": "วันอังคาร",
            "month-Wed": "วันพุธ",
            "month-Thu": "วันพฤหัสบดี",
            "month-Fri": "วันศุกร์",
            "month-Sat": "วันเสาร์",
            "month-01": "มกราคม",
            "month-02": "กุมภาพันธ์",
            "month-03": "มีนาคม",
            "month-04": "เมษายน",
            "month-05": "พฤษภาคม",
            "month-06": "มิถุนายน",
            "month-07": "กรกฏาคม",
            "month-08": "สิงหาคม",
            "month-09": "กันยายน",
            "month-10": "ตุลาคม",
            "month-11": "พฤศจิกายน",
            "month-12": "ธันวาคม",
            "popup-update": "อัปเดต",
            "popup-delete": "ลบ",
            "tous": "ทั้งหมด",
            "autres": "more"
        }
    }
},
    data: function() {
        return {
            days: [],
            currentDay: this.focusedDay?this.focusedDay:moment().format("YYYY-MM-DD"),
            today: moment().format("YYYY-MM-DD")
        };
    },
    watch: {
        focusedDay:{
            handler: function(value){
                if(value && this.currentDay!=value){ 
                    this.currentDay = value;
                    this.generateDays();
                    this.$emit("remove-focused-day", null);
                }
            }
        }
    },
    created: function(){
        this.generateDays();
    },
    mounted: function(){

    },
    computed:{
        calendarsDisplayed: function(){
            return this.calendars.filter((calendar)=>calendar.checked);
        },
        getCurrentDaySchedules: function(){
            let daySchedules = this.schedules.filter(
                (schedule)=>
                this.calendarsDisplayed.findIndex((calendar)=>schedule.calendarId==calendar.id)!=-1
                && schedule.start.includes(this.currentDay)
            );
            return daySchedules.sort((schedule1, schedule2)=>{
                if(schedule1.start<schedule2.start) return -1;
                else if(schedule1.start>schedule2.start) return 1;
                else return 0;
            });
        },
        getVerboseCurrentDay: function(){
            let currentDay = moment(this.currentDay);
            return this.$t("dayname-"+currentDay.format("dddd"), {DD: currentDay.format("DD"), MM: this.$t("month-"+currentDay.format("MM")), YYYY: currentDay.format("YYYY")});
        },
    },
    methods:{
        isCategorieLieu: function(schedule){
            if(schedule.calendarId=='intervention-programmee') return schedule.raw.contrat.categorie.isGe==1 || schedule.raw.contrat.categorie.isGe=="1";
            else if(schedule.calendarId=='tache-session') return schedule.raw.tache.categorie_isGe==1 || schedule.raw.tache.categorie_isGe=="1";
            else return false;
        },
        getCalendar: function(schedule){
            return this.calendars.find((cal)=>cal.id==schedule.calendarId);
        },
        getDD: function(day){
            return moment(day).format("DD");
        },
        getDDMMYYYY: function(date){
            return moment(date).format("DD/MM/YYYY");
        },
        getHHmm: function(datetime){
            return moment(datetime).format("HH:mm");
        },
        previousDay: function(){
            let startOfPreviousDayWeek = moment(this.currentDay).subtract(1, "day").startOf('isoWeek');
            this.currentDay = moment(this.currentDay).subtract(1, "day").format("YYYY-MM-DD");
            if(startOfPreviousDayWeek.format("YYYY-MM-DD")!=this.days[0]){
                this.generateDays();
            }
            this.$emit("remove-focused-day", null);
        },
        nextDay: function(){
            let startOfNextDayWeek = moment(this.currentDay).add(1, "day").endOf('isoWeek');
            this.currentDay = moment(this.currentDay).add(1, "day").format("YYYY-MM-DD");
            if(startOfNextDayWeek.format("YYYY-MM-DD")!=this.days[6]){
                this.generateDays();
            }
            this.$emit("remove-focused-day", null);
        },
        generateDays: function(){
            let days = [];
            let currentDay = moment(this.currentDay).startOf('isoWeek');
            days = [
                currentDay.format("YYYY-MM-DD"), 
                currentDay.add(1, "days").format("YYYY-MM-DD"),
                currentDay.add(1, "days").format("YYYY-MM-DD"),
                currentDay.add(1, "days").format("YYYY-MM-DD"),
                currentDay.add(1, "days").format("YYYY-MM-DD"),
                currentDay.add(1, "days").format("YYYY-MM-DD"),
                currentDay.add(1, "days").format("YYYY-MM-DD")
            ];
            this.days = days;
        },
        onClickDay: function(day){
            this.currentDay = day;
            this.$emit("remove-focused-day", null);
        },
        onClickSchedule: function(schedule){
            this.$emit("click-schedule", {schedule: schedule});
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-calendar-planning{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        .days{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 100%;
            flex-grow: 3;
            .day{
                flex-grow: 1;
                text-align: center;
                background-color: white;
                color: #333;
                font-weight: normal;
            }
            .current-day{
                background-color: #059cff !important;
                color: white;
                font-weight: bold;
                border-radius: 100%;
                height: 30px;
                padding: 4px 0px;
            }
            .today{
                border-bottom: 2px solid #059cff;
            }
        }
        
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        .verbose-day{
            width: 100%;
            text-align: center;
            font-size: 12px;
            background-color: #F0F0F0;
            height: 30px;
            padding: 8px;
        }
        .schedule{
            border-bottom: 1px solid #ccc;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            .schedule-icon{
                width: 20px;
                .icon{
                    height:15px;
                    width: 15px;
                }
                .icon-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                }
            }
            .schedule-description{
                width: calc(100% - 20px - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                font-size: 12px;
                .title{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                }
                .intervention-prevue{
                    color: red;
                }
                .intervention-realisee{
                    color: #45b348;
                }
            }
            .schedule-time-interval{
                width: 40px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

</style>
<docs>

</docs>
