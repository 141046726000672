<template>
	<vg-modal :title="$t('title')"
		:isSaveDisabled="isSaveDisabled"
		@close="$emit('close')"
		@save="handleSave">
		<template #body>
			<vg-input
				:title="$t('composant-icon')"
				:isRequiredValue="true">
				<vg-composant-icon-selector v-if="!composant.icon"
					v-model="composant.icon">
				</vg-composant-icon-selector>
				<div v-else>
					<div class="option">
						<img :src="composant.icon" width="20px" height="20px"/>
						<vg-button @click="composant.icon=null">
							<i class="fas fa-eraser"></i>
						</vg-button>
					</div>
				</div>
			</vg-input>
			<vg-input
				:title="$t('composant-libel')"
				:isRequiredValue="true"
			>
				<vg-input
					v-model="composant.libelComposant"
					:placeholder="$t('placeholder-input-composant')"
				>
				</vg-input>
			</vg-input>
			<vg-input
				:title="$t('composant-type')"
				:isRequiredValue="true"
			>
				<vg-select
					v-model="composant.typeComposant"
					:options="typesComposant"
					:attributeValue="'label'"
					:placeholder="$t('placeholder-select-type')"
				>
				</vg-select>
			</vg-input>
		</template>
	</vg-modal>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import VgComposantIconSelector from "src/components/Vg/Selectors/VgComposantIconSelector.vue";

	import ComposantMixins from "src/mixins/ComposantMixins.js";
	import TagsMixins from "src/mixins/TagsMixins.js";
    export default {
        name: 'vg-composants-create',
		components: {

			VgInput,
			VgButton,
			VgSelect,
			VgComposantIconSelector
		},
		mixins:[ ComposantMixins, TagsMixins ],
        props: {
            categorieId:{
                type: Number,
                required:true
            }
        },
        data: function() {
            return {
				composant:{
					libelComposant: null,
					typeComposant: null,
					icon: null,
					userId: this.$app.appID,
                    categorie:{
                        id:null //required
                    }
				},
				typesComposant: []
            };
        },
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Création composant",
            "alarme-incendie": "Alarme incendie",
            "composant-icon": "Icône",
            "placeholder-input-composant": "Libellé composant",
            "placeholder-select-type": "Sélectionner un type",
            "composant-libel": "Libellé",
            "composant-type": "Type",
            "composant-type-sanitaire": "Sanitaire",
            "composant-type-menuiserie": "Menuiserie",
            "composant-type-composant-du-bati": "Composant du bâti",
            "composant-type-plomberie": "Plomberie",
            "composant-type-accessoire": "Accessoire",
            "composant-type-appareil-electrique": "Appareil électrique",
            "composant-type-eclairage": "Eclairage",
            "composant-type-mobilier": "Mobilier",
            "composant-type-securite": "Sécurité",
            "composant-type-controle-acces": "Contrôle accès",
            "composant-type-exterieur": "Exterieur",
            "composant-type-image-son": "Image/son",
            "composant-type-telephonie": "Téléphonie",
            "composant-type-confort": "Confort",
            "composant-type-appareil-cuisine": "Appareil cuisine",
            "composant-type-appareil-froid": "Appareil froid",
            "composant-type-electricite": "Electricité"
        },
        "en": {
            "title": "Component creation",
            "alarme-incendie": "Fire alarm",
            "composant-icon": "Icon",
            "placeholder-input-composant": "Component name",
            "placeholder-select-type": "Select type",
            "composant-libel": "Name",
            "composant-type": "Type",
            "composant-type-sanitaire": "Sanitary",
            "composant-type-menuiserie": "Carpentry",
            "composant-type-composant-du-bati": "Built component",
            "composant-type-plomberie": "Plumbing",
            "composant-type-accessoire": "Accessory",
            "composant-type-appareil-electrique": "Electrical appliance",
            "composant-type-eclairage": "Lighting",
            "composant-type-mobilier": "Furniture",
            "composant-type-securite": "Security",
            "composant-type-controle-acces": "Acces control",
            "composant-type-exterieur": "Outside",
            "composant-type-image-son": "Image/sound",
            "composant-type-telephonie": "Phone",
            "composant-type-confort": "Comfort",
            "composant-type-appareil-cuisine": "Kitchen appliance",
            "composant-type-appareil-froid": "Cold appliance",
            "close": "Close",
            "save": "Save",
            "confirm-delete-composant": "This component can be linked to more than one location category, do you really want to delete it? This will result in deleting it for all associated location categories.",
            "composant-delete-error-message": "This component can not be deleted",
            "composant-type-electricite": "Electricity"
        },
        "th": {
            "title": "การสร้างชิ้นส่วน",
            "alarme-incendie": "สัญญาณเพลิงไหม้",
            "composant-icon": "ไอคอน",
            "placeholder-input-composant": "ชื่อชิ้นส่วน",
            "placeholder-select-type": "เลือกประเภท",
            "composant-libel": "ชื่อ",
            "composant-type": "ประเภท",
            "composant-type-sanitaire": "สุขาภิบาล",
            "composant-type-menuiserie": "งานไม้",
            "composant-type-composant-du-bati": "ชิ้นส่วนที่สร้างแล้ว",
            "composant-type-plomberie": "ระบบประปา",
            "composant-type-accessoire": "อุปกรณ์เสริม",
            "composant-type-appareil-electrique": "เครื่องใช้ไฟฟ้า",
            "composant-type-eclairage": "แสงสว่าง",
            "composant-type-mobilier": "เฟอร์นิเจอร์",
            "composant-type-securite": "ความปลอดภัย",
            "composant-type-controle-acces": "ควบคุมการเข้าถึง",
            "composant-type-exterieur": "ด้านนอก",
            "composant-type-image-son": "รูปภาพ/เสียง",
            "composant-type-telephonie": "โทรศัพท์",
            "composant-type-confort": "ความสะดวกสบาย",
            "composant-type-appareil-cuisine": "เครื่องใช้ในครัว",
            "composant-type-appareil-froid": "เครื่องทำความเย็น",
            "close": "ปิด",
            "save": "บันทึก",
            "confirm-delete-composant": "ชิ้นส่วนนี้สามารถเชื่อมโยงได้มากกว่าหนึ่งหมวดหมู่ที่ตั้ง ต้องการมันลบจริงหรือ? การลบครั้งนี้จะส่งผลให้ลบหมวดที่ตัั้งที่เกี่ยวข้อง",
            "composant-delete-error-message": "ชิ้นส่วนนี้ไม่สามารถลบได้",
            "composant-type-electricite": "ไฟฟ้า"
        }
    }
},
		created: function(){
            this.composant.categorie.id = this.categorieId;
			this.TagsMixins_getTags("composant/type").then(
				(datas)	=>	this.typesComposant = this.TagsMixins_listComposantType(datas)
			);
		},
		methods: {
			handleSave: function(){
				this.ComposantMixins_postComposants([this.composant]).then((composants)=>{
					this.$emit("success", composants[0]);
				});
			}
		},
        computed: {
			isSaveDisabled: function(){
				return !this.composant.libelComposant || !this.composant.typeComposant || !this.composant.icon;
			}
		}
    };

</script>
<style lang="scss" scoped>
.option{
	display: flex;
	justify-content: flex-start;
	>img{
		margin-right: 10px;
	}
}
</style>
