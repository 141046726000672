<template>
   <div class="vg-equipement-select" >
      <div class="equipement-select" @click="openModal">
         <div class="icon">
            <img v-if="equipement && equipement.categorie_icon" :src="equipement.categorie_icon">
            <img v-else-if="equipement && equipement.icon" :src="equipement.icon">
            <img v-if="composant && composant.icon" :src="composant.icon">
         </div>
         <div class="name text-ellipsis">
            <b v-if="composant">{{composant.libelComposant}}</b>
            <b v-else>{{equipement.libel_equipement}} {{equipement.libelEquipement}}</b>
         </div>
      </div>
      <!-- Mis de côté pour le moment il faut que je repasse dessus le cas update composant est compliqué -->
      <vg-modal v-if="showModal" @close="showModal=false" @cancel="handleCancel" @save="handleSave">
          <template #header>
             <div  v-if="tabSelect == 'equipement'">
                {{$t("choix-equipement")}}
             </div>
             <div  v-else>
                {{$t("choix-composant")}}
             </div>
          </template>

          <template #body>
             <div class="onglet">
                <vg-tabs
                   :tabs="tabs"
                   :color="'bleu'"
                   @tabClicked="handleTabs">
                </vg-tabs>
             </div>
             <div class="list">
                <vg-equipement-selector v-if="tabSelect == 'equipement'"
                    v-model="equipementsSelected"
                   :modalview="true"
                  >
                </vg-equipement-selector>
                <vg-composant-selector v-else
                    v-model="composantsSelected"
                    :idLieu="idLieu">
                </vg-composant-selector>
             </div>
          </template>
      </vg-modal>
   </div>
</template>
<script>

import VgEquipementSelector from "src/components/Vg/Selectors/VgEquipementSelector.vue";
import VgComposantSelector from "src/components/Vg/Selectors/VgComposantSelector.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import Metadatas from "src/services/Metadatas.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import ComposantMixins from "src/mixins/ComposantMixins.js";
export default {
    name: 'vg-equipement-composant-select',
    components: {

        VgEquipementSelector,
        VgComposantSelector,
        VgTabs
    },
    mixins: [equipementsMixins, ComposantMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "dans": "dans",
            "choix-equipement": "Choisir equipement",
            "choix-composant": "Choisir composant"
        },
        "en": {
            "dans": "in",
            "choix-equipement": "Choose equipment",
            "choix-composant": "Choose component"
        },
        "th": {
            "dans": "ใน",
            "choix-equipement": "เลือกอุปกรณ์",
            "choix-composant": "เลือกชิ้นส่วน"
        }
    }
},
    props: {
        /**
        * Identifiant lieu servant à récupérer uniquement équipements et composants
        * du lieu selectionné.
        */
        idLieu:{
            type:Number,
            default: function(){
                return null;
            }
        },
        /**
         * @model
         */
        equipement: {
            type: Object,
            default: () => {
                return null;
            }
        },
        /**
         * @model
         */
        composant: {
            type: Object,
            default: () => {
                return null;
            }
        },
    },
    data: function() {
        return {
            equipementsSelected: null,
            composantsSelected: null,
            showModal: false,
            tabs: [{
                    label: "Equipement",
                    name: "equipement",
                },
                {
                    label: "Composant",
                    name: "composant",
                },
            ],
            tabSelect: "equipement",
        };
    },
    watch: {

    },
    created: function() {

    },
    mounted: function() {

    },
    methods: {
        /**
         * Ouvre la modale.
         */
        openModal: function() {
            this.showModal = true;
        },
        /**
         * Ferme la modale.
         */
        closeModal: function() {
            this.showModal = !this.showModal;
        },
        /**
         * Validation de la donnée selectionner.
         *
         * @param {Object} equipement : donnée selectionner
         * @emit {Array}
         */
        handleSave: function() {
            // emit a decommenter quand la requete equipementsMixins_getEquipements aura la clé libel_equipement
            // this.$emit("input", this.equipementSelect);
            // Alors la attention quand on avait un équipement qui devient composant
            // il faut changer le materiel_id de la FM ET le composan_id
            ////console.log("handleSave", this.equipementsSelected, this.composantsSelected);
            if(this.equipementsSelected){
                this.equipement = null;
                this.equipement = this.equipementsSelected;
                this.$emit("equipement-change", this.equipementsSelected);
            }
            if(this.composantsSelected){
                this.composant = null;
                this.composant = this.composantsSelected;
                this.$emit("composant-change", this.composantsSelected);
            }
            this.closeModal();
            // this.$forceUpdate();
        },
        /**
         * Annulation du changement.
         */
        handleCancel: function() {
            this.closeModal();
        },
        /**
         * Evenement changement d'onglet.
         *
         * @param {object} onglet selectionné
         */
        handleTabs: function(tabs) {
            this.composantsSelected = null;
            this.equipementsSelected = null;
            this.tabSelect = tabs.name;
        }

    }
};
</script>
<style lang="scss" scoped>
.vg-equipement-select{
	.equipement-select{
		display: flex;
		border: 1px solid lightgray;
		height: 30px;
		padding: 0 10px;
		align-items: center;
		cursor: pointer;
		.icon{
			width: 20%;
			img{
				margin: 0;
				width: 20px;
				height: 20px;
			}
		}
		.name{
			width: 80%;
		}
	}
	.onglet{
		margin-bottom: 15px;
	}
	.list{
		height: 40vh;
		overflow: auto;
		.list-equipement{
			&.active{
		      background-color:#ebebeb;
			  height: 70px;
		    }
			height: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			border: 1px solid lightgray;
			padding:15px;
			.info{
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			.info-supp{
				display: flex;
				margin-top: 10px;
			}
		}
	}

}

</style>
<docs>

    ### vg-equipement-composant-select


    ```js
		import VgEquipementComposantSelect from "src/components/Vg/Equipements/VgEquipementComposantSelect.vue";

    ```

    #### basic usage

    ```html static
	<vg-equipement-composant-select
		v-model="composant ? composant : equipement">
	</vg-equipement-composant-select>

    ```
	**Remarque**

	Pas trouvé d'autre moyen pour savoir si c'est un composant ou un equipement que d'utiliser les clés inexistante si c'est equipement.
	a revoir


    #### Emit : select-row

    Retourne la valeur selectionner, clique sur la row

    ### mixins

    equipementsMixins_getEquipements

</docs>
