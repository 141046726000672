<template lang="html">
	<vg-input v-if="display=='dropdown'"
		:title="title?title:$t('title')">
		<vg-bons-de-commande-entites-selector v-model="selectedEntite"
			:attributeValue="attributeValue"
			@input="$emit('input',selectedTiers)"/>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgBonsDeCommandeEntitesSelector from "src/components/Vg/Selectors/VgBonsDeCommandeEntitesSelector.vue";
    export default {
        name: 'vg-filter-bons-de-commande-entites',
	    components: {
			VgInput,
			VgBonsDeCommandeEntitesSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: Array | String,
				required: true
			},
			title: {
				type: String,
				default: null
			},
			/**
			* attribut de l'entité choisi renvoyé au v-model
			* default "id"
			* peut être null renvoie tout l'objet entité
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			 * 2version d'affichage du composant disponible, "dropdown" = avec vg-select || "modal" = avec modale de selection multiple
			 * @values dropdown || modal
			 */
			display:{
				type:String,
				default:"dropdown"
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par entité qui passe commande"
        }
    }
},
        data: function(){
            return {
				selectedEntite: null
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedEntite = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {

		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
