<template lang="html">
    <div class="action-menu-aggrid">
        <div class="menu-more">
            <div class="icone" @click="openContent" ref="button">
                <i class="fa fa-ellipsis-v icon"></i>
            </div>
            <div v-if="showMenu"
                :class="['menu',placement]"
                v-closable="{
                    exclude: ['button'],
                    handler: 'onClose'
                  }">
                <div class="item" @click="dispatchGestionColonne" > <span>{{$t("actions_gerercolonne")}}</span></div>
                <div class="item" @click="dispatchExportCSV"> <span>{{$t("actions_exportcsv")}}</span></div>
                <div class="item" @click="dispatchExportXLS"> <span>{{$t("actions_exportexcel")}}</span></div>
                <div class="item" @click="dispatchExportXML"> <span>{{$t("actions_exportxml")}}</span></div>
                <div class="item" v-if="isVisibleReport" @click="dispatchGenerateReport"> <span>{{$t("actions_generatereport")}}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import ClosableDirective from 'src/directives/ClosableDirective.js';
export default {
    name: 'action-menu-aggrid',
    mixins:[],
    components:{

    },
    mixins:[
        TagGridMixins
    ],
    props:{
        idTableau : {
            type: String,
            default: null
        },
        disabledReport:{
            type:Boolean,
            default:false,
        },
        /**
        * placement du dropdown bottom || top
        */
        placement: {
            type: String,
            default: "bottom"
        }
    },
    directives: {
        ClosableDirective
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "actions_gerercolonne": "Gérer les colonnes",
            "actions_exportexcel": "Export Excel (.xlsx)",
            "actions_exportxml": "Export Excel (.xml)",
            "actions_exportcsv": "Export CSV",
            "actions_generatereport": "Créer un rapport"
        },
        "en": {
            "actions_gerercolonne": "Show/hide columns",
            "actions_exportexcel": "Export Excel (.xlsx)",
            "actions_exportxml": "Export Excel (.xml)",
            "actions_exportcsv": "Export CSV",
            "actions_generatereport": "Create a report"
        },
        "th": {
            "actions_gerercolonne": "แสดง/ซ่อนคอลัมน์",
            "actions_exportexcel": "ส่งออก Excel (.xlsx)",
            "actions_exportxml": "ส่งออก Excel (.xml)",
            "actions_exportcsv": "ส่งออก CSV",
            "actions_generatereport": "สร้างรายงาน"
        }
    }
},
    data:function(){
        return {
            showMenu:false,
            isVisibleReport: !this.disabledReport,
        }
    },
    methods:{
        openContent:function(){
            this.showMenu = !this.showMenu;
        },
        onClose:function(){
            this.showMenu = false;
        },
        emitEvent: function (eventName) {
            window.dispatchEvent(new CustomEvent(eventName, {
                "detail": this.idTableau? {"idTableau":this.idTableau}: null
            }));
        },
        dispatchGestionColonne: function () {
            this.emitEvent("gestionColonnesEventId");
        },
        dispatchExportXLS: function () {
            this.emitEvent("askForXLS");
        },
        dispatchExportXML: function () {
            this.emitEvent("askForXML");
        },
        dispatchExportCSV: function () {
            this.emitEvent("askForCSV");
        },
        dispatchGenerateReport: function () {
            this.emitEvent("askGenerateReport");
        }
    }

}
</script>

<style lang="scss">
.action-menu-aggrid{
    height: 40px;
    width: 40px;
    display: flex;
}
.menu-more{
    height: 30px;
    width: 30px;
    // display: flex;
    align-self: center;
    float: none !important;
    .icone{
        cursor:pointer;
        // align-self: center;
        margin: 0 auto;

        height: 25px;
        width: 25px;
        display: flex;
        border-radius: 30px;
        &:active{
            background-color: #f2f2f2;
        }
        i{
            align-self: center;
            margin: 0 auto;
        }
    }
    .menu{
        font-size: 12px;
        height: max-content;
        width: 150px;
        background: white;
        position: absolute;
        padding:0;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 1;
        transform-origin: center top;
        -webkit-animation-name: transform,opacity; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: .3s; /* Safari 4.0 - 8.0 */
        animation-name: transform,opacity;
        animation-duration:.3s;
        .item{
            height: 30px;
            width: 100%;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 1px 6px;
            display: flex;
            span{
                align-self:center;
                width: 100%;
                text-align: center;
            }
            &:hover{
                background-color:#f2f2f2 ;
            }
        }
    }
    .bottom-right{
        right: 0;
    }
    .bottom-left{
        left: 0;
    }
    @keyframes transform {
       from { width: 30px;}
       to { width: 150px;}
   }
    @keyframes opacity {
       from {opacity:0;}
       to {opacity: 1; }
   }
 }



</style>
