/**
*
*   VG cell render
*
*
*@author SD
*@version 1.0
*@license MIT
*@class
*
**/

import moment from "moment";
import VGutils from "src/services/VGutils.js";

// ------------------------------------------
// ASSETS
// FULL WIDTH CELL RENDERER
function FullWidthSectionCellRenderer() {}
FullWidthSectionCellRenderer.prototype.init = function(params) {
    // trick to convert string of html into dom object
    var eTemp = document.createElement('div');
    eTemp.innerHTML = this.getTemplate(params);

	this.eGui = eTemp.firstElementChild;
    //this.consumeMouseWheelOnCenterText();
};
FullWidthSectionCellRenderer.prototype.getTemplate = function(params) {
    var data = params.node.data;
	var template =
		'<div style="background-color: #f9b934; height:40px;width:100%;display:flex;justify-content:space-between;align-items:baseline;">'
			+'<div style="font-size:16px; padding-top: 7px;padding-left:20px;">';
	if(data.label) template += '<span><i class="fa fa-tag"></i> '+data.label+' </span>';
	else template += '<span><i class="fa fa-tag"></i> Without accounting tag</span>';
	template +='</div>'
			+'<div style="font-size: 12px;padding-top:10px;text-align:right;">'
				+'<span>Cost <strong style="margin-left:20px;width: 50px;">'+Number.parseFloat(data.valeurAchat).toFixed(2)+'</strong></span>'
			+'</div>'
			+'<div style="font-size: 12px;padding-top:10px;text-align:right;">'
				+'<span>Annual depreciation <strong style="margin-left:20px;">'+Number.parseFloat(data.depreciationAnnuelle).toFixed(2)+'</strong></span>'
			+'</div>'
			+'<div style="font-size: 12px;padding-top:10px;text-align:right;">'
				+'<span>Monthly depreciation <strong style="margin-left:20px;">'+Number.parseFloat(data.depreciationMensuelle).toFixed(2)+'</strong></span>'
			+'</div>'
			+'<div style="font-size: 12px;padding-top:10px;text-align:right;">'
				+'<span>Accumulated depreciation <strong style="margin-left:20px;">'+Number.parseFloat(data.depreciationCumulee).toFixed(2)+'</strong></span>'
			+'</div>'
			+'<div style="font-size: 12px;padding-top:10px;text-align:right;">'
				+'<span>Remaining depreciation <strong style="margin-left:20px;">'+Number.parseFloat(data.depreciationRestante).toFixed(2)+'</strong></span>'
			+'</div>'
		+'</div>';

    /*var template =
		'<div class="full-width-panel" style="background-color: #f5f7f7;height:40px;padding:7px;width:100%;border: solid 1px red;">'
		+'<div style="width:20%;">';

	if(data.label) template+= '<span style=font-size:20px;width:10%;">Accounting tag </span><span style="font-size:20px;color: lightblue;width:20%;"><i class="fa fa-tag"></i> '+data.label+' </span>';
	else template+= '<span style=font-size:20px;width:10%;">Without accounting tag </span><span style="font-size:20px;color: lightblue;width:20%;"> </span>';
	template +=  '</div>'
		+'<div style="width:20%;">'
			+'<span style="font-size:20px;width:30%;text-align:right;"> Subtotals</span>'
			+'<span style="width:10%;">valeurAchat= '+data.valeurAchat+'</span>'
			+'<span style="width:10%;">depreciationAnnuelle= '+data.depreciationAnnuelle+'</span>'
			+'<span style="width:10%;">depreciationMensuelle = '+data.depreciationMensuelle+'</span>'
			+'<span style="width:10%;">depreciationCumulee = '+data.depreciationCumulee+'</span>'
			+'</div>'
        +'</div>';*/

    return template;
};
FullWidthSectionCellRenderer.prototype.getGui = function() {
    return this.eGui;
};
// if we don't do this, then the mouse wheel will be picked up by the main
// grid and scroll the main grid and not this component. this ensures that
// the wheel move is only picked up by the text field
FullWidthSectionCellRenderer.prototype.consumeMouseWheelOnCenterText = function() {
    var eFullWidthCenter = this.eGui.querySelector('.full-width-center');
    var mouseWheelListener = function(event) {
        event.stopPropagation();
    };
    // event is 'mousewheel' for IE9, Chrome, Safari, Opera
    eFullWidthCenter.addEventListener('mousewheel', mouseWheelListener);
    // event is 'DOMMouseScroll' Firefox
    eFullWidthCenter.addEventListener('DOMMouseScroll', mouseWheelListener);
};


// -----------------------------------
// Cell TAGS COMPTABLE
function TagsComptableCellRenderer() {}
TagsComptableCellRenderer.prototype.init = function(params) {
	this.eGui = document.createElement('span');
	if(params.data.accountingTag){
		this.eGui.innerHTML = VGutils.customTagsCellRenderer(params.data.accountingTag, ",");
		return;
	}
	if(params.data.accountingTagId && params.data.accountingTagId!=""){
		var value = params.column.colDef.cellEditorParams.values.find(function(tag){
			return tag.id==params.data.accountingTagId;
		});
		if(value){
			this.eGui.innerHTML = VGutils.customTagsCellRenderer(value.label, ",");
		}else{
			if(params.data.accountingTagLabel) this.eGui.innerHTML = VGutils.customTagsCellRenderer(params.data.accountingTagLabel, ",");
			else this.eGui.innerHTML = "";
		}
	}else{
		this.eGui.innerHTML = "";
	}
};
TagsComptableCellRenderer.prototype.getGui = function() {
	return this.eGui;
};


class VGCellRender{

	/**
	 * @constructor
	 **/
    constructor(_options) {
        this.lang = null;
    }

	static init(){
		return {
			tagsComptableCellRenderer: TagsComptableCellRenderer,
			fullWidthSectionCellRenderer: FullWidthSectionCellRenderer
		};
	}

}
export default VGCellRender;
