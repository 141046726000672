<template>
	<div>
		<div style="max-height:60vh;overflow-y: scroll;">
			<div v-for="(tag, index) in tagsLocale" style="margin-top:10px;" >
                <vg-tag
                    :key="tag.id"
                    style="padding: 0 25px;"
                >
                    <template>
                        {{tag.label}}
                    </template>
                </vg-tag>
				<vg-button
                    v-if="tag.userId"
                    type="info"
                    size="xs"
    				@click="$emit('update',{index: index, tag: tag})"
                >
                    <i class="fas fa-edit"></i>
                </vg-button>
				<vg-button v-if="tag.userId"
                    type="danger"
                    size="xs"
					@click="$emit('delete',{index: index, tag: tag})"
                >
                    <i class="fas fa-trash"></i>
                </vg-button>
				<br>
			</div>
		</div>
        <hr v-if="hasASeparation">
        <div style="display:inline-block;width:100%;">
			<vg-button
                v-if="!inputVisible"
                class="button-new-tag"
                size="xs"
                type="info"
                @click="showInput"
            >
                + {{$t("new")}}
            </vg-button>

			<vg-input v-if="inputVisible"
                style="width:30%"
				v-model="inputValue"
				ref="saveTagInput"
				size="xs"
				:placeholder="$t('new-input-placeholder')">
			</vg-input>

			<vg-button
                v-if="inputVisible"
                size="xs"
                type="success"
    			@click="onSave"
    			:disabled="!inputValue || (inputValue && inputValue.length==0)"
            >
                <i class="fas fa-save"></i>
            </vg-button>

			<vg-button
                v-if="inputVisible"
                size="xs"
                type="danger"
    			@click="inputVisible=false;inputValue=null;"
            >
                <i class="fas fa-times"></i>
            </vg-button>
		</div>
	</div>
</template>
<script>
    import TagsMixins from "src/mixins/TagsMixins.js";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgTag from "src/components/Vg/VgTag.vue";
    import { mapGetters } from 'vuex';

    export default {
        name: 'tags-parametres',
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "new": "Ajouter une étiquette",
            "new-input-placeholder": "Nouvelle étiquette",
            "composant-type-sanitaire": "Sanitaire",
            "composant-type-menuiserie": "Menuiserie",
            "composant-type-composant-du-bati": "Composant du bâti",
            "composant-type-plomberie": "Plomberie",
            "composant-type-accessoire": "Accessoire",
            "composant-type-appareil-electrique": "Appareil électrique",
            "composant-type-eclairage": "Eclairage",
            "composant-type-mobilier": "Mobilier",
            "composant-type-securite": "Sécurité",
            "composant-type-controle-acces": "Contrôle accès",
            "composant-type-exterieur": "Exterieur",
            "composant-type-image-son": "Image/son",
            "composant-type-telephonie": "Téléphonie",
            "composant-type-confort": "Confort",
            "composant-type-appareil-cuisine": "Appareil cuisine",
            "composant-type-appareil-froid": "Appareil froid",
			"consommable-statut-a-commander": "A commander",
			"consommable-statut-a-prevoir": "A prévoir",
			"consommable-statut-envoie-demande": "Envoie demande"
        },
        "en": {
            "new": "Add a label",
            "new-input-placeholder": "New label",
            "composant-type-sanitaire": "Sanitary",
            "composant-type-menuiserie": "Carpentry",
            "composant-type-composant-du-bati": "Built component",
            "composant-type-plomberie": "Plumbing",
            "composant-type-accessoire": "Accessory",
            "composant-type-appareil-electrique": "Electrical appliance",
            "composant-type-eclairage": "Lighting",
            "composant-type-mobilier": "Furniture",
            "composant-type-securite": "Security",
            "composant-type-controle-acces": "Access control",
            "composant-type-exterieur": "Outside",
            "composant-type-image-son": "Image/sound",
            "composant-type-telephonie": "Telephony",
            "composant-type-confort": "Comfort",
            "composant-type-appareil-cuisine": "Kitchen appliance",
            "composant-type-appareil-froid": "Cold appliance",
			"consommable-statut-a-commander": "To order",
			"consommable-statut-a-prevoir": "To plan",
			"consommable-statut-envoie-demande": "Sending request"
        },
        "th": {
            "new": "เพิ่มฉลาก",
            "new-input-placeholder": "ฉลากใหม่",
            "composant-type-sanitaire": "สุขาภิบาล",
            "composant-type-menuiserie": "งานไม้",
            "composant-type-composant-du-bati": "ชิ้นส่วนที่สร้างแล้ว",
            "composant-type-plomberie": "ระบบประปา",
            "composant-type-accessoire": "อุปกรณ์เสริม",
            "composant-type-appareil-electrique": "เครื่องใช้ไฟฟ้า",
            "composant-type-eclairage": "แสงสว่าง",
            "composant-type-mobilier": "เฟอร์นิเจอร์",
            "composant-type-securite": "ความปลอดภัย",
            "composant-type-controle-acces": "ควบคุมการเข้าถึง",
            "composant-type-exterieur": "ภายนอก",
            "composant-type-image-son": "รูปภาพ/เสียง",
            "composant-type-telephonie": "โทรศัพท์",
            "composant-type-confort": "ความสะดวกสบาย",
            "composant-type-appareil-cuisine": "เครื่องใช้ในครัว",
            "composant-type-appareil-froid": "เครื่องทำความเย็น"
        }
    }
} ,
		mixins: [
            TagsMixins
		],
        components:{
            VgButton,
            VgInput,
            VgTag
        },
		props:{
			tagType: String,
            hasASeparation:{
                type: Boolean,
                default: true
            }
		},
        data: function() {
            return {
                inputVisible: false,
				inputValue: null,
                tagsLocale:null
            };
        },
        watch:{
            tags:{
                handler:function(refreshedTags){
                    this.updateTags(refreshedTags);
                },
                deep:true
            },
			tagType: {
				handler: function(type){
					this.updateTags(this.tags);
				}
			}
        },
        created: function(){
            this.updateTags(this.tags);
        },
        methods: {
			showInput: function(){
				this.inputVisible = true;
			},
			onSave: function(){
				var value = this.inputValue;
				this.$emit("save", {value: value, type: this.tagType});
				this.inputValue = null;
				this.inputVisible = false;
			},
            updateTags:function(tags){
                let result = [];
                if(!this.tagType)return null;

                switch (this.tagType) {
                    case "operation/a-prevoir":
                        result = this.TagsMixins_listComposantType(tags.filter((tag)=> tag.type=="operation/a-prevoir"));
                        break;
                    case "operation/tache":
                        result = this.TagsMixins_listComposantType(tags.filter((tag)=> tag.type=="operation/tache"));
                        break;
                    case "composant/type":
                        result = this.TagsMixins_listComposantType(tags.filter((tag)=> tag.type=="composant/type"), "composant-type-");
                        break;
                    case "consommable/statut":
                        result = this.TagsMixins_listComposantType(tags.filter((tag)=> tag.type=="consommable/statut"), "consommable-statut-");
                        break;
                    default:
                        result = tags.filter((tag)=> tag.type==this.tagType);
                        result.sort((tag1, tag2)=>{
                            if (tag1.label < tag2.label) return -1;
                            if (tag1.label > tag2.label) return 1;
                            return 0;
                        });
                        break;
                }
                this.tagsLocale = [...result];

            }
        },
        computed:{
            ...mapGetters({
                tags: 'TagsStore/getCollection'
            })
        }
    };

</script>
