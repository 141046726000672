<template>
	<div class="vg-composant-viewer">
		<img v-if="value.icon" :src="value.icon" width="20" height="20">
		<span style="color: #999b9e;">{{value.typeComposant}}</span>
		<span><b>{{value.libelComposant}}</b></span>
	</div>
</template>
<script>

    export default {
        name: 'vg-composant-viewer',
		components: {

		},
        props: {
			value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {

            };
        },
		watch: {

		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
.vg-composant-viewer{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	>*{
		margin-right: 10px;
	}
}
</style>
