<template>
    <div class="statut-description-fm">
        <div>
            <img v-if="maintenance.statut != 'Resolue'" width="15px;" src="/static/assets/icone/wrench-red-light.png">
            <img v-else width="15px;" src="/static/assets/icone/wrench-green-light.png">
            &nbsp;
        </div>
        <div>
            {{maintenance.description}}
            &nbsp;
        </div>
        <div class="fm-id" @click="$emit('click',maintenance)">
            n° {{maintenance.id}}
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			maintenance: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{

        }
    });
</script>

<style lang="scss" scoped>
.statut-description-fm{
    display:flex;
}
.fm-id:hover{
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor:pointer;
}
</style>
