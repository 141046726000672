<template>
	<div class="vg-synthese-maintenance-preventive-table">
		<div class="header">
			<span class="main-cell">{{ $t("header-categorie") }}</span>
			<div class="month-cells">
				<span class="month-cell" v-for="(month, index) in months">{{ $t("month-"+month.month, {year: month.year}) }}</span>
			</div>
		</div>
		<div class="body">
			<div class="table-group" v-for="(categorie, index) in getDatas">
				<div class="table-row">
					<span class="main-cell section">
						<i v-if="isCollapsedCategories(categorie.id)" class="fas fa-chevron-right collapser" @click="removeCollapsedCategorie(categorie.id)"></i>
						<i v-else class="fas fa-chevron-up collapser"  @click="addCollapsedCategorie(categorie.id)"></i> <span>{{ categorie.libelleCatgorie }}</span><span style="color: rgba(0, 0, 0, 0.54);font-weight: normal;"> - {{ categorie.nbEquipements }} {{ $t("equipements") }}</span>
					</span>
					<div class="month-cells">
						<div class="month-cell" v-for="month in months">
							<div v-if="categorie.progressions[month.YYYYMM].todo!=null && categorie.progressions[month.YYYYMM].done!=null && categorie.progressions[month.YYYYMM].todo!=0"
								:class="'progression-dot '+getProgressionDotColor(categorie.progressions[month.YYYYMM])">
							</div>
							<span v-if="categorie.progressions[month.YYYYMM].todo!=null && categorie.progressions[month.YYYYMM].done!=null && categorie.progressions[month.YYYYMM].todo!=0">
								{{getPurcentage(categorie.progressions[month.YYYYMM])}} - {{ categorie.progressions[month.YYYYMM].done }}/{{ categorie.progressions[month.YYYYMM].todo }}
							</span>
						</div>
					</div>
				</div>
				<div class="table-row grouped" v-for="data in categorie.datas">
					<span class="main-cell subsection">
						{{ data.name || data.libel_tache }} {{ data.libel_tache?$t("interne"):"" }}
					</span>
					<div class="month-cells">
						<div class="month-cell month-cell-clickable" v-for="month in months" @click="openDetailProgression(data, month)">
							<div v-if="data.progressionsForEachMonth[month.YYYYMM].todo!=null && data.progressionsForEachMonth[month.YYYYMM].done!=null"
								:class="'progression-dot '+getProgressionDotColor(data.progressionsForEachMonth[month.YYYYMM])"></div>
							<span v-if="data.progressionsForEachMonth[month.YYYYMM].todo!=null && data.progressionsForEachMonth[month.YYYYMM].done!=null">
								{{getPurcentage(data.progressionsForEachMonth[month.YYYYMM])}} - {{ data.progressionsForEachMonth[month.YYYYMM].done }}/{{ data.progressionsForEachMonth[month.YYYYMM].todo }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<vg-modal v-if="showModalDetailProgression"
			:title="selectedMonth.YYYYMM"
			@close="onCloseModalDetailProgression">
			<template #body>
				<div v-if="selectedTache">
					Tache: {{ selectedTache.libel_tache }}
					<br>
					Type: {{ selectedTache.type_tache }}
					<br>
					Périodicité en jours: {{ selectedTache.periodicite }}
					<br>
					Date prochaine vérification: {{ selectedTache.dateprochaineVerif }}
					<br>
					Progressions du mois {{ selectedMonth.YYYYMM }} :
					<div v-for="progression in getMonthProgressions(selectedTache.progressions)">
						{{ progression }}
						<br>
					</div>
				</div>
				<div v-if="selectedContrat">
					Contrat: {{ selectedContrat.name }}
					<br>
					Type: {{ selectedContrat.type }}
					<br>
					Tiers: {{ selectedContrat.tiers.name }}
					<br>
					Périodicité d'intervention en jours: {{ selectedContrat.periodiciteIntervention }}
					<br>
					Interventions du mois {{ selectedMonth.YYYYMM }} :
					<div v-for="progression in getMonthProgressions(selectedContrat.progressions)">
						{{ progression }}
						<br>
					</div>
				</div>
			</template>
			<template #footer>
				<vg-button type="default" @click="onCloseModalDetailProgression">{{ $t("close") }}</vg-button>
			</template>
		</vg-modal>
	</div>
</template>
<script>

	import SyntheseMaintenanceMixins from "src/mixins/SyntheseMaintenanceMixins.js";

    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
    import VgTooltip from "src/components/Vg/VgTooltip.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
	
    export default {
        name: 'vg-synthese-maintenance-preventive-table',
    	props: {
			startDate: {
				type: String,
				default: null
			},
			endDate:{
				type: String,
				default: null
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
        },
		watch: {
			filters: {
				handler: function(newfilters){
                    //this.metadatasContrats.setFilters(newfilters);
					this.fetch();
				},
				deep: true
			},
			startDate: {
				handler: function(){
					this.fetch();
				}
			}
		},
		mixins: [
			SyntheseMaintenanceMixins
		],
		components: {
            VgTextFilter,
			VgTooltip,
			VgModal,
			VgButton
		},
		i18n:    { "locale":navigator.language,
    "messages": {
		"fr": {
			"overlay-loading-text": "Chargement des données...",
			"overlay-no-rows-text": "Aucune donnée correspondant au filtrage",
			"header-categorie": "Catégorie",
			"month-01": "Janv",
			"month-02": "Fév",
			"month-03": "Mars",
			"month-04": "Avr",
			"month-05": "Mai",
			"month-06": "Juin",
			"month-07": "Juil",
			"month-08": "Août",
			"month-09": "Sept",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Déc",
			"interne": "(interne)",
			"equipements": "eqts",
			"close": "Fermer"
		},
		"en": {
			"overlay-loading-text": "Loading datas...",
			"overlay-no-rows-text": "No datas matching the filters",
			"header-categorie": "Category",
			"month-01": "Jan",
			"month-02": "Feb",
			"month-03": "Mar",
			"month-04": "Apr",
			"month-05": "May",
			"month-06": "Jun",
			"month-07": "Jul",
			"month-08": "Aug",
			"month-09": "Sep",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Dec",
			"interne": "(internal)",
			"equipements": "eqts",
			"close": "Close"
		}
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
                metadatas: new Metadatas(),
				synthese: [],
				months: [],
				monthsProgression: {},
				collapsedCategories: [],
				showModalDetailProgression: false,
				selectedContrat: null,
				selectedTache: null,
				selectedMonth: null
            };
        },
		created: function(){
			this.initTableau();
			this.fetch();
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		methods: {
			getMonthProgressions: function(progression){
				return progression.filter((progression)=>progression.hasOwnProperty("dateFin")?moment(progression.dateFin).subtract(1, 'days').format("YYYY-MM")==this.selectedMonth.YYYYMM:progression.yearMonth==this.selectedMonth.YYYYMM);
			},
			onCloseModalDetailProgression: function(){
				this.showModalDetailProgression = false;
				this.selectedContrat = null;
				this.selectedTache = null;
				this.selectedMonth = null;
			},
			openDetailProgression: function(data, month){
				if(data.hasOwnProperty("libel_tache")) this.selectedTache = data;
				else if(data.hasOwnProperty("name")) this.selectedContrat = data;
				this.selectedMonth = month;
				this.showModalDetailProgression = true;
			},
			getPurcentage: function(progression){
				return Math.round(progression.done*100/progression.todo)+"%";
			},
			getProgressionDotColor: function(progression){
				if(progression.done===null && progression.todo===null) return "progression-dot-grey";
				else if(progression.todo==0) return "progression-dot-grey";
				else if(progression.todo==progression.done || progression.done>progression.todo) return "progression-dot-green";
				else if(progression.done==0) return "progression-dot-red";
				else return "progression-dot-orange";
			},
			removeCollapsedCategorie: function(idCategorie){
				let index = this.collapsedCategories.indexOf(idCategorie);
				if(index != -1) this.collapsedCategories.splice(index, 1);
			},
			addCollapsedCategorie: function(idCategorie){
				if(this.collapsedCategories.indexOf(idCategorie) == -1) this.collapsedCategories.push(idCategorie);
			},
			isCollapsedCategories: function(idCategorie){
				return this.collapsedCategories.indexOf(idCategorie) == -1;
			},
			getProgression: function(progressions, month){
				let monthProgressions = progressions.filter((progression)=>{
					if(progression.hasOwnProperty("yearMonth")) return progression.yearMonth==month.YYYYMM;
					else if(progression.hasOwnProperty("dateDebut")) return progression.dateDebut.indexOf(month.YYYYMM)==0;
					else return false;
				});
				return monthProgressions;
			},
			generateMonths: function(){
				let start = new Date(this.startDate);
				let end = new Date(this.endDate);
				let months = [];

				// Normalisation des dates pour commencer à la première date du mois
				start.setDate(1);
				end.setDate(1);

				let year = null;
				let month = null;

				// Boucle pour ajouter chaque mois à la liste
				while (start <= end) {
					year = start.getFullYear();
					month = start.getMonth() + 1; // Les mois commencent à 0, donc on ajoute 1
					months.push({year: year, month: month.toString().padStart(2, '0'), YYYYMM: year+"-"+month.toString().padStart(2, '0')});
					start.setMonth(start.getMonth() + 1); // Passer au mois suivant
				}

				this.months = months;
			},
			generateProgressionsForEachMonth: function(){
				let progressionsForEachMonth = {};
				this.months.forEach((month)=>{
					progressionsForEachMonth[month.YYYYMM] = {todo: null, done: null};
				});
				this.monthsProgression = progressionsForEachMonth;
			},
			initTableau: function(){
				this.generateMonths();
				this.generateProgressionsForEachMonth()
			},
			fetch: function(){
				this.SyntheseMaintenanceMixins_get(this.startDate, this.endDate, this.metadatas).then((datas)=>{
					this.synthese = datas;
				});
			},
		},
        computed:{
			...mapGetters({
				
            }),
			getDatas: function(){
				console.log("SYNTHESE+++++++++++++++++++++++++++++++++++++", this.synthese);
				this.synthese.forEach((categorie, indexCategorie)=>{
					let categorieProgressionsForEachMonth = {};
					let nbContrats = categorie.contrats.length;
					let nbTaches = categorie.taches.length;
					this.months.forEach((month)=>{
						categorieProgressionsForEachMonth[month.YYYYMM] = {todo: nbContrats+nbTaches, done: 0};
					});
					console.log("CATEGORIE++++++++++", categorie.libelleCatgorie, categorieProgressionsForEachMonth);
					categorie.contrats.forEach((contrat, indexContrat)=>{
						let progressionsContratsForEachMonth = {};
						this.months.forEach((month)=>{
							progressionsContratsForEachMonth[month.YYYYMM] = {todo: null, done: null};
						});
						contrat.progressions.forEach((progression)=>{
							if(progression.yearMonth){
								progressionsContratsForEachMonth[progression.yearMonth] = {todo: progression.prevues, done: progression.realisees};
							}
						});
						this.months.forEach((month)=>{
							let progression = progressionsContratsForEachMonth[month.YYYYMM];
							if(progression.done===null && progression.todo===null){
								categorieProgressionsForEachMonth[month.YYYYMM].todo--;
							}else if(progression.done>=progression.todo){
								categorieProgressionsForEachMonth[month.YYYYMM].done++;
							}
						});
						this.synthese[indexCategorie].contrats[indexContrat].progressionsForEachMonth = progressionsContratsForEachMonth;
					});
					categorie.taches.forEach((tache, indexTache)=>{
						let progressionsTachesForEachMonth = {};
						this.months.forEach((month)=>{
							progressionsTachesForEachMonth[month.YYYYMM] = {todo: null, done: null};
						});
						this.months.forEach((month)=>{
							let progressionsForThisMonth = tache.progressions.filter((progression)=>moment(progression.dateFin).subtract(1, 'days').format("YYYY-MM")==month.YYYYMM);
							console.log("CATEGORIE TACHES PROGRESSIONS FOR THIS MONTH", progressionsForThisMonth);
							if(progressionsForThisMonth.length!=0){
								progressionsTachesForEachMonth[month.YYYYMM].todo = progressionsForThisMonth.length;
								progressionsTachesForEachMonth[month.YYYYMM].done = progressionsForThisMonth.filter((progression)=>progression.verified!=null && progression.total!=null && progression.verified>=progression.total).length;
							}
							let progression = progressionsTachesForEachMonth[month.YYYYMM];
							if(progression.done===null && progression.todo===null){
								categorieProgressionsForEachMonth[month.YYYYMM].todo--;
							}else if(progression.done>=progression.todo){
								categorieProgressionsForEachMonth[month.YYYYMM].done++;
							}
						});
						this.synthese[indexCategorie].taches[indexTache].progressionsForEachMonth = progressionsTachesForEachMonth;
					});
					this.synthese[indexCategorie].progressions = categorieProgressionsForEachMonth;
					this.synthese[indexCategorie].datas = categorie.contrats.concat(categorie.taches).sort((a, b)=>{
						let aname = a.name || a.libel_tache;
						let bname = b.name || b.libel_tache;
						return aname.localeCompare(bname);
					});
				});
				return this.synthese;
			}
        }
    };
</script>
<style lang="scss" scoped>
.vg-synthese-maintenance-preventive-table{
	border: 1px solid #BDC3C7;
	font-size: 12px;
	height: 100%;
	.header{
		display: grid;
		grid-template-columns: minmax(200px, 1fr) 4fr;
		height: 40px;
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		border-bottom: 1px solid #BDC3C7;
	}
	.body{
		height: calc(100% - 40px);
		overflow-y: auto;
		.table-row{
			height: 50px;
			display: grid;
			grid-template-columns: minmax(200px, 1fr) 4fr;
			justify-self: stretch;
			align-items: stretch;
			border-bottom: 1px solid #d9dcde;
		}
	}
	.main-cell{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap:5px;
		padding-left: 10px;
		padding-right: 10px;
		border-right: 1px solid #d9dcde;
		i{
			margin-right: 10px;
		}
	}
	.section{
		font-weight: bold;
	}
	.subsection{
		padding-left: 32.25px;
	}
	.grouped{
		background-color: rgba(0, 0, 0, 0.02);
	}
	.month-cells{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
		span{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.month-cell{
		border-right: 1px solid #d9dcde;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
	}
	.month-cell-clickable:hover{
		cursor: pointer;
		border: 1px solid #3498db;
	}
	.collapser{
		cursor: pointer;
	}
	.collapser:hover{
		color: #3498db;
	}
	.progression-dot{
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin: 0 2px;
	}
	.progression-dot-grey{
		background-color: #d2d5dd;
	}
	.progression-dot-red{
		background-color: rgba(255, 0, 0, 0.5);
	}
	.progression-dot-orange{
		background-color: rgba(255, 165, 0, 0.5);
	}
	.progression-dot-green{
		background-color: rgba(0, 128, 0, 0.5);
	}
}
</style>
