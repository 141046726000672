
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.repartitionMontantHtSite = [];
state.repartitionMontantHtService = [];
state.repartitionMontantHtFournisseur = [];
state.createurs = [];
state.validateurs = [];
let getters = Base.getGetters();
getters.getRepartitionMontantHtSite = function(state){
    return state.repartitionMontantHtSite;
};
getters.getRepartitionMontantHtService = function(state){
    return state.repartitionMontantHtService;
};
getters.getRepartitionMontantHtFournisseur = function(state){
    return state.repartitionMontantHtFournisseur;
};
getters.getCreateurs = function(state){
    return state.createurs;
};
getters.getValidateurs = function(state){
    return state.validateurs;
};
let actions = Base.getActions();
actions.setRepartitionMontantHt = function( context, value ){
    context.commit('SET_REPARTITION_MONTANT_HT', value);
};
actions.setCreateurs = function( context, value ){
    context.commit('SET_CREATEURS', value);
};
actions.setValidateurs = function( context, value ){
    context.commit('SET_VALIDATEURS', value);
};
let mutations = Base.getMutations();
mutations.SET_REPARTITION_MONTANT_HT = function(state, value){
    state.repartitionMontantHtSite = value.site;
    state.repartitionMontantHtService = value.service;
    state.repartitionMontantHtFournisseur = value.fournisseur;
};
mutations.SET_CREATEURS = function(state, value){
    state.createurs = value;
};
mutations.SET_VALIDATEURS = function(state, value){
    state.validateurs = value;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
