<template>
    <span>
		{{isUrgence}}
    </span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"urgence-fm-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "oui": "Oui",
            "non": "Non"
        },
        "en": {
            "oui": "Yes",
            "non": "No"
        },
        "th": {
            "oui": "ใช่",
            "non": "ไม่"
        }
    }
},
        computed:{
            isUrgence: function(){
                return this.params.data.urgence && this.params.data.urgence=="1"?this.$t("oui"):this.$t("non");
            }
        }
    });
</script>

<style scoped>

</style>
