<template>
	<div>
        <h3>{{ $t("recapitulatif-mouvement-"+value.type)}}</h3>
        <div style="display:flex;flex-direction:column">
            <div> {{$t("donneur")}} : <b>{{ value.signataireDonneur }}</b> </div>
            <div>{{$t("receveur")}} : <b>{{ value.signataireReceveur }}</b> </div>
            <div>{{$t("date-"+value.type)}} : <b>{{ value.dateMouvement }}</b> </div>
            <div v-if="isSortieTemporaireEquipement">{{$t("date-retour-prevue")}} : <b>{{ value.dateRetourPrevu }}</b> </div>
        </div>

        <hr v-if="value.destination && value.destination.path">
        <div v-if="value.destination && value.destination.path">
            {{$t("destination")}} : {{ value.destination.path }}
        </div>

        <hr>
        <div>
            <h3> {{ value.equipements.length }} {{ $t("equipements-concernes") }}</h3>
            <div v-for="equipement in value.equipements">
                <equipement-mobile-cell-render 
                    :params='{"data":equipement}'
                >
                </equipement-mobile-cell-render>
            </div>
        </div>

    </div>
</template>
<script>
    import EquipementMobileCellRender from "src/components/Vg/TagGrid/EquipementMobileCellRender.vue"
	export default {
        name: 'mouvement-equipements-viewer',
		i18n:
        {
            "locale":navigator.language,
            "messages": {
                "fr": {
                    "recapitulatif-mouvement-pret":"Récapitulatif sortie équipement(s)",
                    "recapitulatif-mouvement-retour":"Récapitulatif retour équipement(s)",
                    "equipements-concernes":"Equipement(s) concerné(s)",
                    "recapitulatif-mouvement":"Récapitulatif",
                    "donneur": "Donneur",
                    "receveur": "Receveur",
                    "date-pret": "Date prêt équipement(s)",
                    "date-retour": "Date retour équipement(s)",
                    "date-retour-prevue": "Date retour prévue",
                    "destination": "Destination"
                },
                "en": {
                }
            }
        },
		components:{
            EquipementMobileCellRender
		},
    	props: {
			value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
			};
        },
		computed: {
			title: function(){},
            isSortieTemporaireEquipement:function(){
                return this.value.type == "pret";
            }
        },
        created:function(){
            
        }
    };

</script>
<style scoped lang="scss">

</style>
