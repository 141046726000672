<template>
	<!--vg-tooltip v-if="showTooltip" :text="getFullPath" class="vg-lieu-path-viewer">
		<span>

			<small class="path" v-if="getPathLength==5 && !onlyPiece">{{getPath}}</small>

			<small class="path" v-else-if="getPathLength!=1 && !onlyPiece">{{getPath}}</small>
			<span class="piece">{{getPiece}}</span>
	    </span>
	</vg-tooltip-->
    <div v-if="!onlyPiece" class="pathViewerBloc lineHeight">
        <div>
            <span class="piece">{{getPiece}}</span>
        </div>
        <div>
            <!-- Piece -->
    		<small class="path" v-if="getPathLength==5 && !onlyPiece">{{getPath}}</small>
    		<!--  -->
    		<small class="path" v-else-if="getPathLength!=1 && !onlyPiece">{{getPath}}</small>
        </div>
    </div>
    <span v-else>
        {{getPiece}}
    </span>
</template>
<script>
	import VgTooltip from "src/components/Vg/VgTooltip.vue";

	import LieuMixins from "src/mixins/LieuMixins.js";

    export default {
        name: 'vg-lieu-path-viewer',
		mixins: [LieuMixins],
    	props: {
			value: {
                type: String,
                required: true
            },
			/**
			 * Affiche uniquement la piece
			 */
			onlyPiece:{
				type:Boolean,
				default:false
			},
			/**
			 * Affiche uniquement le path ie sans dernier element/pièce
			 */
			onlyPath:{
				type:Boolean,
				default:false
			},
			/**
			* affiche le tooltip onhover
			*/
			showTooltip:{
				type: Boolean,
				default: true
			},
			/**
			*
			*/
			type: {
				type: String,
				default: "Piece"
			}
        },
		components:{
			VgTooltip
		},
        data: function() {
            return {

            };
        },
		watch:{

		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "piece-generique": "Pièce générique",
            "batiment-generique": "Batiment générique",
            "etage-generique": "Etage générique"
        },
        "en": {
            "piece-generique": "Generic room",
            "etage-generique": "Generic floor",
            "batiment-generique": "Generic building",
            "container-equipements-globaux": "Global equipment container"
        }
    }
},
		computed: {
            getPath: function(){
				if(this.value){
					let path = this.value;
					path = path.replace("/-/","/"+this.$t("batiment-generique")+"/");
					path = path.replace("/-/","/"+this.$t("etage-generique")+"/");
					return path.split("/").slice(1,-1).join("/")+"/ ";
				}else return "";
            },
			getLibelLieuTranslation: function(){
				if(this.type=="Piece") return this.LieuMixins_getInitiales({path: this.value})+this.$t("piece-generique");
				else if(this.type=="Etage") return this.$t("etage-generique");
				else if(this.type=="Batiment") return this.$t("batiment-generique");
				return "-";
			},
            getPiece: function(){
				if(this.value){
					let piece = this.value.split("/").pop();
					if(!this.onlyPath && piece=="-") return this.getLibelLieuTranslation;
					else if(!this.onlyPath && this.isGenerique) return piece;
					else if(!this.onlyPath) return piece;
					else return "";
				}else return "";
            },
			getPathLength: function(){
				return this.value?this.value.length:0;
			},
			isGenerique: function(){
				return this.value?this.value.includes("/-/"):false;
			},
			getFullPath: function(){
				return this.getPath + this.getPiece;
			},
        }
    };

</script>
<style lang="scss" scoped>
.pathViewerBloc{
    display:flex;
    flex-direction:column;
}
.path{
    font-size: 11px;
    color:#9e9da9;
}
.path-cell-render{
	&.vg-lieu-path-viewer{
		.path{
			font-size: 12px;
			color:#888888;
		}
		.piece{
			font-size: 14px;
		}
	}
}
@media screen and (min-width: 600px) {
    .lineHeight{
        line-height:15px !important;
    }
}
@media screen and (max-width: 600px) {

}
</style>
