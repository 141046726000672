<template>
	<div class="vg-fichesav-activite">
		<tag-grid
			:idTableau="'vg-fichesav-activite'"

			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="maintenanceActivite"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
			@ag-click="onClickRow" />
		<vg-fichesav-modal v-if="showMaintenance"
			:idMaintenance="selectedMaintenance.id"
			@close="showMaintenance=false;" />
		<vg-fichesav-relance-modal v-if="showRelance"
			@close="showRelance=false;"
			@save="showRelance=false;" />
		<vg-pagination v-if="paginate"
			:totalItems="counters.filtered"
			:offset="offset"
			:limit="limit"
			:currentPageNumber="currentPage"
			@pagination-change="handlePaginationChange" />
	</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";
	import MaintenanceCardCellRender from "src/components/Vg/TagGrid/MaintenanceCardCellRender.vue";
	import VgFichesavModal from 'src/components/Vg/FicheSAV/VgFichesavModal.vue';
	import VgFichesavRelanceModal from 'src/components/Vg/FicheSAV/VgFichesavRelanceModal.vue';
	import VgPagination	from 'src/components/Vg/VgPagination.vue';

	import TagGridMixins from 'src/mixins/TagGridMixins.js';

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import Metadatas from "src/services/Metadatas.js";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-fichesav-activite',
    	props: {
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			agfilters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			paginate: {
				type: Boolean,
				default: true
			},
			offset: {
	            type: Number,
	            default: 0
	        },
	        limit: {
	            type: Number,
	            default: 25
	        },
        },
		i18n:    { "locale":navigator.language,
	    	"messages": {
			    "fr": {
					"overlay-loading-text": "Chargement du fil d'activité...",
					"overlay-no-rows-text": "Aucune activité correspondant au filtrage"
				},
			    "en": {
					"overlay-loading-text": "Loading activity thread...",
					"overlay-no-rows-text": "No activity matching filtering"
				}
			}
		},
		watch: {
			agfilters: {
				handler: function(newfilters){
					this.currentPage = 1;
					this.metadatasMaintenances.setLimit(this.offset, this.limit);
					this.fetch();
				},
				deep: true
			}
		},
		mixins: [
			TagGridMixins,
			MaintenanceMixins
		],
		components: {
			TagGrid,
			VgFichesavModal,
			VgFichesavRelanceModal,
			VgPagination
		},
        data: function(){
            return {
				showLoadingOverlay: false,
				gridOptions: {
					rowHeight: this.$vgutils.isMobile()?140:130,
					headerHeight: 0,
					isExternalFilterPresent: ()=>this.TagGridMixins_isExternalFilterPresent(),
                    doesExternalFilterPass: (node)=>this.TagGridMixins_doesExternalFilterPass(node)
				},
				columnDefs: [
                    {
						field: 'id',
						colId: 'id',
						hide: true,
						isVisible: false
					},
					{
						field: 'commentaireClient',
						colId: 'commentaireClient',
						cellClass: ["vg-cell-mobile-card"],
						/*getQuickFilterText: (params) => {
							var result = "";
							if(params.data.composant && params.data.composant.libelComposant) result += params.data.composant.libelComposant+" ";
							else result += params.data.equipement.libel_equipement+" ";
							result += params.data.commentaireClient;
							return result;
						},*/
						cellRenderer: (params) => new MaintenanceCardCellRender({propsData: {params: params, isOuverture: params.data.__reference.isOuverture}})
							.$on("open-details-maintenance", this.openDetailsMaintenance)
							.$on("open-relance", this.openRelance)
							.$mount().$el
					}
				],
                metadatasMaintenances: new Metadatas(),
				maintenanceActivite: [],
				showMaintenance: false,
				showRelance: false,
				selectedMaintenance: null,
				currentPage: this.offset + 1
            };
        },
		created: function(){
			if(this.paginate) this.metadatasMaintenances.setLimit(this.offset, this.limit);
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			onClickRow: function(e){
				if(this.$vgutils.isMobile()) this.openDetailsMaintenance(e.data);
			},
			openDetailsMaintenance: function(maintenance){
				this.selectedMaintenance = maintenance;
				this.showMaintenance = true;
			},
			openRelance: function(maintenance){
				this.$store.dispatch("MaintenancesStore/setMaintenance", maintenance);
				this.showRelance = true;
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				this.metadatasMaintenances.setFilters(this.agfilters);
				this.MaintenanceMixins_getMaintenances(this.metadatasMaintenances).then((datas)=>{
					this.showLoadingOverlay = false;
					this.feedMaintenanceActivite();
				});
			},
			feedMaintenanceActivite: function(){
				//console.log("THIS.AGFILTERS", this.agfilters.dateOuvertureSAV.value);
				let activite = [];
				let dateIntervalleDebut = moment(this.agfilters.dateOuvertureSAV.value[0]).format("YYYY-MM-DD 00:00:00");
				let dateIntervalleFin = moment(this.agfilters.dateOuvertureSAV.value[1]).format("YYYY-MM-DD 23:59:59");
				//console.log("DATE INTERVALLE", this.agfilters.dateOuvertureSAV);
				this.maintenances.forEach((maintenance)=>{
					//console.log("DATE OUVERTURE IS BETWEEN", maintenance.dateOuvertureSAV, dateIntervalleDebut, dateIntervalleFin, moment(maintenance.dateOuvertureSAV).isBetween(dateIntervalleDebut, dateIntervalleFin));
					if(moment(maintenance.dateOuvertureSAV).isBetween(dateIntervalleDebut, dateIntervalleFin)) activite.push(Object.assign({}, maintenance, {__reference: {date: maintenance.dateOuvertureSAV, isOuverture: true}}));
					if(maintenance.dateFermetureSAV && moment(maintenance.dateFermetureSAV).isBetween(dateIntervalleDebut, dateIntervalleFin)) activite.push(Object.assign({}, maintenance, {__reference: {date: maintenance.dateFermetureSAV, isOuverture: false}}));
				});
				//console.log("ACTIVITE", activite);
				activite = activite.sort((m1, m2)=>{
					if(m1.__reference.date < m2.__reference.date) return 1;
					else if(m1.__reference.date > m2.__reference.date) return -1;
					else return 0;
				});
				this.maintenanceActivite = activite;
			},
			handlePaginationChange: function(pagination){
	            console.log("handlePaginationChange", pagination);
				this.currentPage = pagination.currentPage;
	            this.metadatasMaintenances.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
	            this.fetch();
	        }
		},
        computed:{
            ...mapGetters({
				maintenances: 'MaintenancesStore/getMaintenances',
				counters: 'MaintenancesStore/getMaintenancesCounters'
            }),
        }
    };
</script>
<style lang="scss">
.vg-fichesav-activite{
	height: 90%;
	width: 100%;
	.ag-header{
		display: none !important;
	}
}
</style>
