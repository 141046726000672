/**
 *
 *   High level on dexi DB
 *      doc here http://dexie.org/docs/Dexie/Dexie.%5Btable%5D
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
//import RestClientExceptions from 'src/Exceptions/services/RestClientExceptions.js';
import Dexie from 'dexie';


class LocalDB {
    /**
     *@constructor
     */
    constructor(__options) {
		this.__options = __options || {};
        this.dbName = "_local_verifgood_";
        let db = new Dexie('_local_verifgood_');
        this.tables = {};
        this.version = 1;
        this.dbInstance = db;
        /*this.dbInstance.open().catch(function(error) {
            alert('Uh oh : ' + error);
        });*/
    }
    getVersion(){
        return this.version
    };
    setVersion(version){
        this.version = version;
        return this;
    };
    getLocalDB(){
        return this.dbInstance;
    }
    
    setTables(tables){
        this.tables = tables;
    }
    
    setOptions(__options){
        this.__options=__options;
    }
    
    getTables(){
        return this.tables;
    }
    
    initLocalDB(){
        
        	// Open the database
            if(!this.dbInstance.isOpen()){
                this.dbInstance.version(this.version).stores(this.tables);
                this.dbInstance.open().catch(function(error) {
                    alert('Uh oh : ' + error);
                });
            }
        // //console.log(this.tables);
    }
    addTable(table){
        //this.version+=1;
        this.dbInstance.version(this.version).stores(table);

    }
    /**
     * 
     * @param string table 
     * @param {*} datas 
     */
    add(table, datas){
        this.dbInstance.transaction('rw', this.dbInstance[table], () =>{
            this.dbInstance[table].clear();
            this.dbInstance[table].bulkAdd(datas).then(()=>{});
        }).catch (e => {
            console.error (e);
        });
    }

    getManager(){
        return this.dbInstance;
    }

}
export default  LocalDB;