<template>
	<div class="">
		<vg-select
			v-model="statutFilter"
			:options="options"
			:attributeValue="'value'"
	        :attributeLabel="'libel'"
			:placeholder="$t('placeholder')"
			@input="handleChange"
			>
		</vg-select>
	</div>

</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-fichesav-statut-selector',
    	props: {
			value: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Statut",
            "en_cours": "En cours",
            "resolue": "Résolue",
            "en_attente": "En attente",
            "prise_en_compte": "Prise en compte"
        },
        "en": {
            "placeholder": "Status",
            "en_cours": "In progress",
            "resolue": "Resolved",
            "en_attente": "On hold",
            "prise_en_compte": "taken into account"
        },
        "th": {
            "placeholder": "สถานะ",
            "en_cours": "กำลังดำเนินการ",
            "resolue": "แก้ไขปัญหาแล้ว",
            "en_attente": "พัก",
            "prise_en_compte": "รวม"
        }
    }
},
		components:{
			VgSelect
		},
        data: function() {
            return {
				statutFilter: this.value,
				options:[
					{
						value:"En_cours",
						libel:this.$t("en_cours")
					},
					{
						value:"prise_en_compte",
						libel:this.$t("prise_en_compte")
					},
					{
						value:"en_attente",
						libel:this.$t("en_attente")
					},
					{
						value:"Resolue",
						libel:this.$t("resolue")
					},
				]
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			handleChange: function(){
				this.$emit('input', this.statutFilter);
				this.$emit('change', this.statutFilter);
			}
		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
select{
	width: 100%;
}
</style>
