<template>
    <div>
        <form
          ref="lieu-form"
          onSubmit="return false;"
          id="piece-form"
          v-if="multiple">
            <div  class="multiple-create">
                <h3 v-show="lieux.length" >{{ $t("liste_piece_a_creer") }}</h3>
                <hr v-show="lieux.length">
                <p v-show="!lieux.length">{{ $t("pas_de_pieces_dans_la_liste") }}</p>
                <div v-for="lieu,index in lieux" :key="index" class="{not-valid-lieu: lieu.isInvalid }" style="display: flex;align-items: baseline;" >
                    <vg-input
                        :is-required-value="true">
                        <vg-categorie-selector v-model="selectedCategorie"
                          @input="handleChangeCategorie"
                          :attributeValue="null"
                          :clearable="false"
                          isGe
                          style="width:100%;"
                        />
                    </vg-input>
                    <vg-input
                        :is-required-value="false">
                        <vg-service-selector
                          v-model="lieu.service"/>
                    </vg-input>
                    <vg-input
                        v-model="lieu.prefixA"
                        :is-required-value="true"
                    />
                    -
                    <vg-input
                        v-model="lieu.prefixB"
                        :is-required-value="true"
                    />
                    <vg-input
                        style="width:30%"
                        v-model="lieu.derniersNumeroConnu"
                        :is-required-value="true"
                    />
                    -
                    <vg-input
                        v-model="lieu.libel_lieu"
                        :is-required-value="true"
                    />
                    -
                    <vg-input
                        style="width:30%"
                        v-model="lieu.numAISuffix"
                        :is-required-value="true"
                    />
                    <vg-button
                        style="margin-left:5px;"
                        :type="'danger'"
                        :size="'xs'"
                        @click="onRemovePiece(lieu,index)">
                        {{ $t('retirer') }}
                    </vg-button>
                </div>
                <hr>

            </div>
            <input
              v-show="false"
              ref="lieu-submit"
              type="submit"
            >
        </form>
        <div style="display: flex;align-items: flex-end;gap: 4px;background-color: rgb(242 242 242);;padding: 7px;">
                    <vg-input
                        :title="$t('categorie')"
                        :is-required-value="true">
                        <vg-categorie-selector v-model="selectedCategorie"
                          @input="handleChangeCategorie"
                          :attributeValue="null"
                          :clearable="false"
                          isGe
                          style="width:100%;"
                        />
                    </vg-input>
                    <vg-input
                        :title="$t('service')"
                        @input="emitLieuInput"
                        :is-required-value="false">
                        <vg-service-selector v-model="lieu.service"/>
                    </vg-input>
                    <vg-input
                        :title="$t('prefixA')"
                        @input="emitLieuInput"
                        v-model="lieu.prefixA"
                        :is-required-value="true"
                    />
                    <vg-input
                        :title="$t('prefixB')"
                        @input="emitLieuInput"
                        v-model="lieu.prefixB"
                        :is-required-value="true"
                    />
                    <vg-input
                        style="width:30%"
                        :title="$t('derniersNumeroConnu')"
                        @input="emitLieuInput"
                        v-model="lieu.derniersNumeroConnu"
                        :is-required-value="true"
                        key="'numAi'"
                    />
                    <vg-input
                        :title="$t('libelLieu')"
                        @input="emitLieuInput"
                        v-model="lieu.libel_lieu"
                        :is-required-value="true"
                    />
                    <vg-input
                        style="width:30%"
                        @input="emitLieuInput"
                        :title="$t('derniersNumeroConnu')"
                        v-model="lieu.numAISuffix"
                        :is-required-value="true"
                        key="'numAiSuffix'"
                    />

                    <div style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;flex-grow:3;min-width:300px;">
                        <vg-checkbox v-model="isQrCodeEqualLibellePiece"
                            :defaultChecked="isQrCodeEqualLibellePiece"
                            :label="$t('checkbox-valeur-qrcode-egal-libelle')"
                            @checked="onCheckQrCodeEqualLibel(true)"
                            @unchecked="onCheckQrCodeEqualLibel(false)" />
                        <span v-if="lieu.libel_lieu" style="font-size:11px;">{{$t("libel-piece")}}: <b>{{ getLibelPiece() }}</b></span>
                        <span v-if="lieu.codeUn" style="font-size:11px;">{{$t("qrcode")}}: <b>{{lieu.codeUn}}</b></span>
                    </div>

                    <vg-button 
                        v-if="multiple"
                        :type="'success'"
                        :size="'xs'"
                        @click="onAddPiece(lieu)">
                        {{ $t('ajouter') }}
                    </vg-button>
        </div>
    </div>
</template>

<script>
import LieuMixins from "src/mixins/LieuMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";


import StringUtilities from 'src/utilities/StringUtilities.js';
import LieuxValidator from 'validators/LieuxValidator.js';


import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";

import Metadatas from "src/services/Metadatas";
export default{
    name: 'vg-pieces-form',
    mixins: [LieuMixins],
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "liste_piece_a_creer": "Liste des pièces à créer",
                "pas_de_pieces_dans_la_liste": "Pas de pièces dans la liste",
                "categorie": "Catégorie",
                "service": "Service",
                "prefixA": "Racine 1",
                "prefixB": "Racine 2",
                "derniersNumeroConnu": "Numéro auto.",
                "libelLieu": "Libellé du lieu",
                "retirer": "Retirer",
                "ajouter": "Ajouter",
                "aucune_piece_a_creer":"Aucune pièce à créer, veuillez en ajouter au moins une en utilisant le formulaire d'ajout.",
                "pas_de_pieces_dans_la_liste":"Aucune pièce dans la liste, veuillez en ajouter au moins une en utilisant le formulaire ci-dessous.",
                "categorie_manquante":"Veuillez renseigner la catégorie de la pièce",
                "libellieu_manquant":"Veuillez renseigner le libellé de la pièce",
                "checkbox-valeur-qrcode-egal-libelle": "Valeur QRCODE = libellé pièce?",
                "qrcode": "QRCODE"
            },
            "en": {
                "liste_piece_a_creer": "List of pieces to create",
                "pas_de_pieces_dans_la_liste": "No pieces in the list",
                "categorie": "Category",
                "service": "Service",
                "prefixA": "Prefix A",
                "prefixB": "Prefix B",
                "derniersNumeroConnu": "Last known numbers",
                "libelLieu": "Place label",
                "retirer": "Remove",
                "ajouter": "Add",
                "aucune_piece_a_creer":"No pieces to create, please add at least one using the form below.",
                "pas_de_pieces_dans_la_liste":"No pieces in the list, please add at least one using the form below.",
                "categorie_manquante":"Please provide the category of the piece",
                "libellieu_manquant":"Please provide the label of the piece"
            }
        }
    },
    components: {
        VgButton,
        VgInput,
        VgSelect,
        VgCheckbox,
        VgCategorieSelector,
        VgServiceSelector
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        etage:{
            type: Object,
            default: null
        },
        showOnlyNotValidRows: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: null
        },
        skipVueXStorage: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            lieux: this.value,
            lieu:{
                libel_lieu: null,
                type_lieu: "Piece",
                codeUn:null,
                adresse: null,
                codeTrois: null,
                service: null,
                vary: null,
                varx: null,
                prefixA:null,
                derniersNumeroConnu : null,
                prefixB: null,
                numAISuffix:null
            },
            selectedCategorie:null,
            libel_lieu: null,
            isDisabledUi:false,
            isQrCodeEqualLibellePiece: true
        }
    },
    created(){
        if(this.skipVueXStorage && this.id){ 
            this.fetchLieu();
        }else{
            this.lieu.userId = this.$app["appID"];
            this.lieu.derniersNumeroConnu = this.$app["derniersNumeroPiece"]+1;
            this.lieu.prefixB = this.$app["racineBCreationLibelPiece"] ? this.$app["racineBCreationLibelPiece"] : "P";
            //this.lieu.numAISuffix = 1;
            let path = this.etage.path;
            this.lieu.prefixA = this.$app["racineACreationLibelPiece"] ? this.$app["racineACreationLibelPiece"] : StringUtilities.getAcronym(path.split("/")[1]);
            this.lieu = Object.assign(this.lieu, this.value[this.value.length - 1]);
        }
    },
    watch: {
        etage: {
            handler: function (val) {
                // console.log({val});
            },
            deep: true
        },
        /*lieu: {
            handler: function (val) {
                if(!this.multiple){
                    this.$emit("lieu-update", this.lieu);
                }
            },
            deep: true
        },*/
        value: {
            handler: function (val) {
                //this.lieu = Object.assign(val[val.length - 1],this.lieu);
            },
            deep: true
        }
    },
    computed:{

    },
    methods: {
        handleChangeCategorie(categorie){
            this.selectedCategorie = categorie;
            this.lieu.categorie = categorie;
            this.lieu.libel_lieu = categorie.libelleCatgorie;
            this.lieu.categorie_id = categorie.id;
            this.$emit("categorie-changed", categorie);
            this.emitLieuInput();
        },
        onRemovePiece(lieu, index){
            this.lieux.splice(index, 1);
            this.emitLieuxUpdate();
        },
        onAddPiece(piece){
            let validator = new LieuxValidator();
            let validation = validator.validatePiece(piece);
            if(!validation.success){
                alert(this.$t(validation.code));
                return;
            }
            let pieceCopy = Object.assign({}, {}, piece);
            pieceCopy.libel_lieu = this.getLibelPiece();
            this.lieux.push(pieceCopy);
            this.lieu.derniersNumeroConnu++;
            this.lieu.numAISuffix ? this.lieu.numAISuffix++ : null;
            this.setQrCode();
            this.emitLieuxUpdate();
        },
        emitLieuxUpdate(){
            this.$emit('input', this.lieux);
        },
        emitLieuInput(){
            this.setQrCode();
            this.$emit('input', this.lieu);
        },
        fetchLieu(){
            this.LieuMixins_getLieu(this.id, {skipVueXStorage: this.skipVueXStorage}).then((lieu)=>{
                this.lieu = lieu;
            });
        },
        setQrCode(){
            if(this.isQrCodeEqualLibellePiece){
                this.lieu.codeUn = this.getLibelPiece();
            }else{
                this.lieu.codeUn = "VLGL"+moment().valueOf();      // timestamp in millisecond
            }
        },
        onCheckQrCodeEqualLibel(isEqual){
            this.isQrCodeEqualLibellePiece = isEqual;
            this.setQrCode();
        },
        refreshLibelPiece(){
            this.lieu.libel_lieu = this.getLibelPiece();
        },
        getLibelPiece(){
            let libel = "";
            if(this.lieu.prefixA && this.lieu.prefixA.length!=0) libel += this.lieu.prefixA;
            if(this.lieu.prefixB && this.lieu.prefixB.length!=0){
                if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                libel += this.lieu.prefixB;
            }
            if(this.lieu.derniersNumeroConnu && this.lieu.derniersNumeroConnu.length!=0){
                if(libel.length!=0 && (!this.lieu.prefixB || this.lieu.prefixB.length==0) && libel[libel.length-1]!="-") libel += "-";
                libel += this.lieu.derniersNumeroConnu;
            }
            if(this.selectedCategorie.libelleCatgorie && this.selectedCategorie.libelleCatgorie.length!=0){
                if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                //libel += this.selectedCategorie.libelleCatgorie;
                libel += this.lieu.libel_lieu;
            }
            if(this.lieu.numAISuffix && this.lieu.numAISuffix.length!=0){
                //if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                let numAISuffix = parseInt(this.lieu.numAISuffix)
                libel += " "+numAISuffix;
            }
            return libel;
        }
    }
}
</script>