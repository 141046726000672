<template lang="html">
	<!-- selecteur dropdown -->
	<div class="vg-filter-site" v-if="display == 'dropdown' && hasManySites">
		<vg-input
			:title="title?title:$t('title')">
			<vg-select
                v-if="sites && sites.length"
				v-model="selectedSites"
				:options="sites"
				:attributeLabel="'libel_lieu'"
				:attributeValue="attributeValue"
				:multiple="multiple"
				@input="handleChange"
			>
			</vg-select>
		</vg-input>
	</div>
	<!-- selecteur custom liste des sites affiché & modal de sélection de sites -->
	<div class="vg-filter-site-alternative" v-else-if="display == 'modal' && hasManySites">
		<div class="header">
			<span >{{$t("sites")}}</span>
			<vg-button :size="'sm'" :type="'info'" @click="openModal">{{$t('update-btn')}}</vg-button>
		</div>
		<div class="content">
			<div class="site-select" v-for="site, index in selectedSites" :key="index">
				<div class="libel-site text-ellipsis">
					<img src="static/assets/icone/site.png" height="20" style="margin:0 10px;">
					<span>{{site.libel_lieu}}</span>
				</div>
				<i class="fas fa-times site-close" @click="unSelectSite(site, index)"></i>
			</div>
		</div>
		<vg-modal v-if="showModal"
			:title="$t('title')"
			:isSaveDisabled="tmpSelectedSites.length==0"
			@close="closeModal"
			@save="onSaveSelectedSites">
			<template #body>
				<div class="content-header-modal">
					<span>{{$t('sites')}}</span>
					<div class="options-filtrage">
						<vg-button :size="'sm'" :type="'info'" @click="unSelectAllSites()">
							{{$t('all-deselect')}}
						</vg-button>
						<vg-button :size="'sm'" :type="'info'" @click="selectAllSites()">
							{{$t('all-select')}}
						</vg-button>
					</div>
				</div>
				<div class="content-body-modal">
					<vg-checkbox v-for="site in sites" :key="site.id"
						:label="site.libel_lieu"
						:inputValue="isSelected(site)"
						:defaultChecked="isSelected(site)"
						@checked="selectSite(site)"
						@unchecked="unSelectSite(site)" />
				</div>
			</template>
		</vg-modal>
	</div>

</template>
<script>
	import LieuMixins from "src/mixins/LieuMixins.js";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgButton from "src/components/Vg/VgButton.vue";

	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import Metadatas from "src/services/Metadatas.js"
    export default {
        name: 'vg-filter-site',
	    components: {
			VgInput,
			VgButton,
			VgSelect,
			VgCheckbox
        },
		mixins:[
			LieuMixins
        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: Array | String,
				required: true
			},
			title: {
				type: String,
				default: null
			},
			/**
			* attribut du site choisi renvoyé au v-model
			* default "path"
			* peut être null renvoie tout l'objet site
			*/
			attributeValue: {
				type: String,
				default: "path"
			},
			/**
			 * Donne au select la possibilité de contenir plusieur reponse
			 */
			multiple:{
				type: Boolean,
				default: false
			},
			/**
			 * 2version d'affichage du composant disponible, "dropdown" = avec vg-select || "modal" = avec modale de selection multiple
			 * @values dropdown || modal
			 */
			display:{
				type:String,
				default:"dropdown"
			},
			/**
			* sélectionne tous les sites
			* utilisé dans le cas
			*/
			isAllSitesSelected: {
				type: Boolean,
				default: false
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par site",
            "sites": "Sites",
            "update-btn": "Modifier",
            "all-select": "Tout sélectionner",
            "all-deselect": "Tout effacer"
        },
        "en": {
            "title": "Filter by site",
            "sites": "Sites",
            "update-btn": "Update",
            "all-select": "Select All",
            "all-deselect": "Unselect all"
        },
        "th": {
            "title": "กรองโดยไซต์งาน",
            "sites": "หน้างาน",
            "update-btn": "อัปเดต",
            "all-select": "เลือกทั้งหมด",
            "all-deselect": "ไม่เลือกทั้งหมด"
        }
    }
},
        data: function(){
            return {
				sites: [],
				selectedSites: this.multiple ? [] : null,
				tmpSelectedSites: [],
				showModal: false,
				hasManySites: false
			};
        },
		watch: {
			value: {
				handler: function(val){
					if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && val[0].hasOwnProperty(this.attributeValue))
						this.selectedSites = val;
					else if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && !val[0].hasOwnProperty(this.attributeValue))
						this.selectedSites = this.sites.filter((site)=>val.includes(site[this.attributeValue]));
					else this.selectedSites = val;
				}
			}
		},
		created: function(){
			this.getHasManySitesValue();
			this.fetch();
		},
        mounted: function(){

        },
        methods: {
			getHasManySitesValue: function(){
				this.hasManySites = !this.$storage.get("has-only-one-site");
			},
			/**
			*
			*/
			onSaveSelectedSites: function(){
				//console.log("SELECTED SITES", this.selectedSites, this.tmpSelectedSites);
				this.selectedSites = [...this.tmpSelectedSites];
				this.handleChange();
			},
			/**
			* open modal
			*/
			openModal: function(){
				this.tmpSelectedSites = [...this.selectedSites];
				this.showModal = true;
			},
			/**
			* close modal
			*/
			closeModal: function(){
				this.tmpSelectedSites = [];
				this.showModal = false;
			},
			/**
			* fetch sites
			*/
			fetch: function(){
                let metadatas = new Metadatas();
				this.LieuMixins_getSites(metadatas).then((datas)=>{
					this.$store.dispatch("LieuxStore/setSites", datas.lieux);
					this.sites = [...datas.lieux];
					this.$storage.set("has-only-one-site", this.sites.length && this.sites.length==1);
					this.getHasManySitesValue();
					if(this.isAllSitesSelected && !this.value) this.selectedSites = [...datas.lieux];
					else {
                        if(this.value) {
                            if(this.multiple){
                                this.selectedSites = datas.lieux.filter((site)=>this.value.indexOf(site.path)!=-1);
                            }else{
                                this.selectedSites = datas.lieux.filter((site)=>this.value.indexOf(site.path)!=-1)[0];
                            }
                        }
                    }
				});
			},
			/**
	         * emit les sites selectionnés
	         * @event input selectedSites
	         */
			handleChange: function(){
				//console.log("HANDLE CHANGE", this.getSelectedSites);
				this.$emit("input", this.getSelectedSites);
				if(this.showModal) this.$emit("change-sites-validation", this.getSelectedSites);
				this.closeModal();
				this.$forceUpdate();
			},
			/**
			* déselectionne un site
			* @param number indexSelectedSite
			*/
			unSelectSite: function(site, indexSelectedSite=null){
				if(this.showModal){
					if(indexSelectedSite) this.tmpSelectedSites.splice(indexSelectedSite, 1);
					else{
						let index = this.tmpSelectedSites.findIndex((selectedSite)=>selectedSite.id==site.id);
						this.tmpSelectedSites.splice(index, 1);
					}
				}else{
					if(indexSelectedSite) this.selectedSites.splice(indexSelectedSite, 1);
					else{
						let index = this.selectedSites.findIndex((selectedSite)=>selectedSite.id==site.id);
						this.selectedSites.splice(index, 1);
					}
					this.handleChange();
				}
			},
			/**
			* @param object site
			*/
			selectSite: function(site){
				if(this.showModal){
					this.tmpSelectedSites.push(site);
				}else{
					this.selectedSites.push(site);
				}
			},
			/**
			* @param object site
			*/
			isSelected: function(site){
				return this.tmpSelectedSites.findIndex((selectedSite)=>selectedSite.id==site.id)!=-1;
			},
			selectAllSites: function(){
				this.tmpSelectedSites = [...this.sites];
			},
			unSelectAllSites: function(){
				this.tmpSelectedSites = [];
			},
		},
		computed: {
			getSelectedSites: function(){
				if(!this.multiple) return this.selectedSites;
				else if(this.multiple && this.attributeValue) return this.selectedSites.map((site)=>site[this.attributeValue]);
				else return this.selectedSites;
			}
		}
	};
</script>

<style lang="scss" scoped>
.vg-filter-site-alternative{
	.header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom:5px;
		border-bottom: 1px solid #c7c7c7;
		color: #8d8d8d;
	}
	.content{
		height: 200px;
		overflow: auto;
		.site-select{
			margin: 5px 0;
			padding: 5px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: whitesmoke;
			.libel-site{
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			.site-close{
				cursor: pointer;
				margin-right: 10px;
			}
			/*height: 35px;
		    display: flex;
		    align-items: center;
			justify-content: space-between;
		    border: 1px solid #c4c4c4;
		    margin-bottom: 5px;
		    margin-top: 5px;
		    padding: 5px;
		    background-color: #f2f2f2;
			.libel-site{
				font-size: 11px;
				img{
					margin: 0;
					margin-right: 5px;
				}
			}
			.site-close{
				cursor: pointer;
				margin-right: 2px;
			}*/
		}
	}
}
.content-header-modal{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom:5px;
	border-bottom: 1px solid #c7c7c7;
	color: #8d8d8d;
	.options-filtrage{
		display: flex;
		justify-content: flex-end;
	}
}
.content-body-modal{
	height: 300px;
	overflow: auto;
	padding: 5px 0;
}
</style>
<docs>
    ## Composant VgFilterSite
    'src/components/Vg/Filters/VgFilterSite.vue'

    ## Usage du composant
    ```html static
	<vg-filter-site
		v-model="filtreSites"
		@input="onChangeFiltreSites">
	</vg-filter-site>
    ```

    ## Si plusieurs sites doivent etre choisis
    ```html static
	<vg-filter-site
		v-model="filtreSites"
		:multiple="true"
		@input="onChangeFiltreSites">
	</vg-filter-site>
    ```

	## Si plusieurs sites doivent etre choisis (deuxieme version : modale )
	```html static
	<vg-filter-site
		v-model="filtreSites"
		:multiple="true"
		:display="'modal'"
		@input="onChangeFiltreSites">
	</vg-filter-site>
	```

</docs>
