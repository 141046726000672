<template>
    <div>
        <h1>{{$t("integration-categories-lieu")}}</h1>
    </div>
</template>
<script>


import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgButton from "src/components/Vg/VgButton.vue";
// 1. include the @flatfile/sdk  is installed with npm install @flatfile/sdk
import { Flatfile } from "@flatfile/sdk";

// 2. Pass in your EMBED_ID
const EMBED_ID = "3ff36117-5b19-4d7c-b731-6cdfc5b7cfa0";
//const EMBED_ID = "c6a0f833-6d5d-4031-a792-992e827543b6";


import IntegrationsDonneesMixins from "src/mixins/IntegrationsDonneesMixins.js";

export default {
	name: 'integrations-donnees',
    mixins:[ IntegrationsDonneesMixins ],
	props:{
		//value : Object
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "integration-categories-lieu":"Intégration des catégories de lieu"
        },
        "en":{},
    }
    },
	components:{
        VgCollapse,
        VgCheckbox,
        VgButton
    },
    data:function(){
       return {}
    },
    watch: {},
    created:function(){

    },
    mounted:function(){
         this.importData();
    },
    computed:{

    },
    methods:{
        /**
         * @param array rows 
         * @param function validCondition 
         */
        extractFinalDatas:function(rows){
            let finalResult = [];
            rows.forEach((row)=>{
                finalResult.push(row.data);
            })
            return finalResult;
        },
        importData: function() {
            let that = this;
            Flatfile.requestDataFromUser({
                embedId: EMBED_ID,
                user: { id: 99, name: "sarlberd", email: "jp.naillet@berd.re" },
                org: { id: 77, name: "Acme Inc." },
            // handle submitted data
            onData(chunk, next) {
                // do something with the data
                console.log(chunk);
                next();
                /*let finalDatas = that.extractFinalDatas(chunk.records);
                that.IntegrationsDonneesMixins_categoriesLieux(finalDatas).then(()=>{
                    next();
                    
                });*/
            },
            onComplete(e) {
                //that.$router.push({ name: '_categories_lieux', params: {}});
            },
            onError(error) {
                console.error(error);
            },
            });
        }
    }

}

</script>
<style lang="scss" scoped>

</style>
