<template>
    <vg-app-layout
        :title="$t('title')"
        :icon="'parametre.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :isLoadingState="isLoadingState"
        :contactSupportToImportDatas="false"
        :titleImportationDonneesModal="$t('importer-modele-integration')"
        @importation-donnees-file-change="handleImportConsommablesModel"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters">
        <template #create-button>
            <vg-button v-if="can('CONSOMMABLES.CREATE')"
                type="success"
                @click="showModalCreateConsommable=true;">
                {{$t("button-addConsommables")}}
            </vg-button>
        </template>
        <template #panel-menu-more>
            <vg-button v-if="can('CONSOMMABLES.CREATE')"
                type="success"
                @click="showModalCreateConsommable=true;">
                {{$t("button-addConsommables")}}
            </vg-button>
            <vg-button v-if="can('CONSOMMABLES.CREATE_BON_DE_SORTIE')"
                type="success"
                @click="showModalCreateBonDeSortie=true;">
                {{$t("button-new-bon-de-sortie")}}
            </vg-button>
            <vg-button v-if="can('CONSOMMABLES.CREATE_BON_DENTREE')"
                type="success"
                @click="showModalCreateBonDentree=true;">
                {{$t("button-new-bon-dentree")}}
            </vg-button>
            <vg-button v-if="$vgutils.isMobile()"
                type="success"
                @click="showScanner=true;">
                {{$t("scanner-consommable")}}
            </vg-button>
        </template>
        <template #action-button-item >
            <li v-if="can('CONSOMMABLES.CREATE_BON_DE_SORTIE')"
                @click="showModalCreateBonDeSortie=true;">
                {{$t("button-new-bon-de-sortie")}}
            </li>
            <li v-if="can('CONSOMMABLES.CREATE_BON_DENTREE')"
                @click="showModalCreateBonDentree=true;">
                {{$t("button-new-bon-dentree")}}
            </li> 
            <li @click="handleExportConsommablesIntegrationModel">{{$t("telecharger-modele-integration")}}</li>

        </template>
        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>
        <template #header-bottom-left>
            <div v-if="selectedConsommables && selectedConsommables.length!=0"
                style="width:100%;padding: 0 5px;display:flex;justify-content:flex-start;align-items:center;gap:10px;">
                <span  style="color: rgba(0, 0, 0, 0.54);font-weight: 600;font-size: 12px;">
                    {{$t("consommables-selected", {nb: selectedConsommables.length})}}
                </span>
                <vg-button type="grey" @click="showUpdateSelectedConsommables=true;">
                    {{$t("modifier")}}
                </vg-button>
            </div>
        </template>

        <template #primary>
            <vg-tabs v-model="focusedTab"
                :tabs="tabs"
                :color="'jaune'"
                @tabClicked="onTabChange"/>
            <vg-consommables-table v-if="(focusedTab=='nondisponibles' || focusedTab=='endemande') && getDepotId"
                :id="getConsommablesTableId"
                :filters="getConsommablesFilters"
                paginate
                :limit="100"
                :nonDisponibles="focusedTab=='nondisponibles'"
                :enDemande="focusedTab=='endemande'"
                :hiddenColumns="['stock.quantiteMin']"
                :depotId="getDepotId"
                key="vg-consommables-nondisponibles-table"
                clearCollectionAfterDestroyed
                @row-dbl-click="handleSelectedRow" />
            <vg-consommables-table v-else-if="getDepotId"
                :id="getConsommablesTableId"
                :filters="getConsommablesFilters"
                paginate
                :limit="100"
                :enStock="focusedTab=='enstock'"
                :aCommander="focusedTab=='acommander'"
                :depotId="getDepotId"
                key="vg-consommables-table"
                clearCollectionAfterDestroyed
                @row-dbl-click="handleSelectedRow" />
        </template>
        <template #secondary-filters >
            <vg-input :title="$t('quantite-en-stock')" inline>
                <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;color: #9e9da9;font-family: Open Sans;font-size: 12px;">
                    <span>{{$t("entre")}}</span>
                    <vg-input v-model="quantiteStockFilter.min" inputType="number" min="0" style="width:80px;" 
                        @input="onInputQuantiteStock" />
                    <span>{{$t("et")}}</span>
                    <vg-input v-model="quantiteStockFilter.max" inputType="number" min="0" style="width:80px;" 
                        @input="onInputQuantiteStock" />
                    <vg-button size="sm" type="grey" @click="resetInputQuantiteStock">{{$t("delete")}}</vg-button>
                </div>
            </vg-input>
            <vg-input :title="$t('colisage')" inline>
                <vg-consommable-colisage-selector 
                    @input="onInputColisageFilter"/>
            </vg-input>
            <vg-filter-tiers v-model="consommablesFilters.field_fournisseurDefault_id.value"
                :title="$t('filtre-fournisseur-default')"
                :placeholder="$t('placeholder-fournisseur-default')" />
        </template>
        
        <template #panel-filters-attributs >
            <vg-input :title="$t('quantite-en-stock')">
                <div style="display: flex;justify-content: flex-start;align-items: baseline;gap:10px;color: #9e9da9;font-family: Open Sans;font-size: 12px;">
                    <span>{{$t("entre")}}</span>
                    <vg-input v-model="quantiteStockFilter.min" inputType="number" min="0" style="width:80px;" 
                        @input="onInputQuantiteStock" />
                    <span>{{$t("et")}}</span>
                    <vg-input v-model="quantiteStockFilter.max" inputType="number" min="0" style="width:80px;" 
                        @input="onInputQuantiteStock" />
                    <vg-button size="sm" type="grey" @click="resetInputQuantiteStock">{{$t("delete")}}</vg-button>
                </div>
            </vg-input>
            <vg-input :title="$t('colisage')">
                <vg-consommable-colisage-selector 
                    @input="onInputColisageFilter"/>
            </vg-input>
            <vg-filter-tiers v-model="consommablesFilters.field_fournisseurDefault_id.value"
                :title="$t('filtre-fournisseur-default')"
                :placeholder="$t('placeholder-fournisseur-default')" />
        </template>
        <template #secondary-widget >
            <vg-tree-consommables @select-node="onSelectNode"
                @remove-selected-node="onRemoveSelectedNode"/>
        </template>

        <vg-consommable-form v-if="showModalCreateConsommable"
            :isSaveUnable="ConsommablesMixins_isSaveDisabled"
            @close="showModalCreateConsommable=false;"
            @save="showModalCreateConsommable=false" />
        <vg-bon-de-sortie-form v-if="showModalCreateBonDeSortie"
            @close="showModalCreateBonDeSortie=false;"
            @save="showModalCreateBonDeSortie=false;isLoadingState=false;"
            @loading="loadingState" />
        <vg-bon-dentree-form v-if="showModalCreateBonDentree"
            @close="showModalCreateBonDentree=false;"
            @save="showModalCreateBonDentree=false;" />
        <vg-bon-dentree-modal v-if="showBonEntreeModal"
            @close="showBonEntreeModal=false;"/>
        <vg-modal v-if="showScanner"
            :title="$t('scanner-consommable')"
            @close="showScanner=false;">
            <template #body>
                <zxing-scanner
                    :isDemo="false"
                    @scanSuccess="onScannSuccess" />
            </template>
            <template #footer>
                <vg-button @click="showScanner=false;">
                    {{$t('close')}}
                </vg-button>
            </template>
        </vg-modal>
        <vg-update-consommables-form v-if="showUpdateSelectedConsommables"
            @close="showUpdateSelectedConsommables=false;"
            @save="onSaveUpdateConsommables"/>
    </vg-app-layout>
</template>

<script>
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import StocksMixins from "src/mixins/StocksMixins";
import FiltersMixins from "src/mixins/FiltersMixins.js";

import VgInput from 'src/components/Vg/VgInput.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgConsommableForm from 'src/components/Vg/Forms/VgConsommableForm.vue';

import VgConsommablesTable from 'src/components/Vg/Consommables/VgConsommablesTable.vue';
import VgBonDeSortieForm from 'src/components/Vg/Forms/VgBonDeSortieForm.vue';
import VgBonDentreeForm from 'src/components/Vg/Forms/VgBonDentreeForm.vue';
import VgBonDentreeModal from 'src/components/Vg/BonsDentree/VgBonDentreeModal.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
import VgTreeConsommables from 'src/components/Vg/Consommables/VgTreeConsommables.vue';
import VgUpdateConsommablesForm from 'src/components/Vg/Forms/VgUpdateConsommablesForm.vue';
import VgConsommableColisageSelector from 'src/components/Vg/Selectors/VgConsommableColisageSelector.vue';

import VgFilterTiers from 'src/components/Vg/Filters/VgFilterTiers.vue';

import VgButton from "src/components/Vg/VgButton.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";
export default {
	name:"consommables",
	components:{
		VgInput,
		VgTabs,
		VgConsommableForm,
        VgButton,
        VgConsommablesTable,
        VgTextFilter,
        VgBonDeSortieForm,
        VgBonDentreeForm,
        zxingScanner,
        VgBonDentreeModal,
        VgTreeConsommables,
        VgUpdateConsommablesForm,
        VgFilterTiers,
        VgConsommableColisageSelector
	},
	mixins:[
        ConsommablesMixins,
        StocksMixins,
        FiltersMixins
	],
	data: function () {
		return {
			showModalCreateConsommable: false,
			showModalCreateBonDeSortie: false,
			showModalCreateBonDentree: false,
			showUpdateSelectedConsommables: false,
			targetedConsommable: null,
			consommables: null,
            consommablesFilters: {
                name: {attr: "c.name", value: null, action: "contains", openParenthesis: true},
                refExterne: {attr: "c.refExterne", value: null, action: "contains", logicalOperator: "OR"},
                marque: {attr: "c.marque", value: null, action: "contains", logicalOperator: "OR"},
                commentaire: {attr: "c.commentaire", value: null, action: "contains", logicalOperator: "OR"},
                code: {attr: "c.code", value: null, action: "contains", logicalOperator: "OR"},
                productGroup: {attr: "c.productGroup", value: null, action: "contains", logicalOperator: "OR"},
                productSubGroup: {attr: "c.productSubGroup", value: null, action: "contains", logicalOperator: "OR"},
                category: {attr: "c.category", value: null, action: "contains", logicalOperator: "OR"},
                nature: {attr: "c.nature", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                field_fournisseurDefault_id: {attr: "c.fournisseurDefault_id", value: null, action: "equals"},
                field_nature_widget: {attr: "c.nature", value: null, action: "contains"},
                field_stock_quantite: {attr: "s.quantite", value: null, action: "between"},
                field_conditionnementColisage: {attr: "c.conditionnementColisage", value: null, action: "equals"},
                field_quantiteColisage: {attr: "c.quantiteColisage", value: null, action: "equals"}
            },
            focusedTab: "tous",
            searchQuery: "",
            showScanner: false,
            isLoadingState: false,
            showBonEntreeModal: false,
            quantiteStockFilter:{
                min: null,
                max: null
            }
		};
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Consommables",
            "button-addConsommables": "Créer consommables",
            "button-new-bon-de-sortie": "Créer bon de sortie",
            "button-new-bon-dentree": "Créer bon d'entrée",
            "tab-tous": "Tous",
            "tab-enStock": "Disponible en stock",
            "tab-stock-magasin": "En magasin",
            "tab-a-commander": "Seuil mini atteint",
            "tab-non-disponibles": "Non disponible",
            "tab-en-demande": "En demande",
            "tab-sortis-non-consommes": "Sortis non consommés",
            "scanner-consommable": "Scanner consommable",
            "filtre-fournisseur-default": "Filtrage fournisseur par défaut",
            "placeholder-fournisseur-default": "Sélectionner un fournisseur",
            "consommables-selected": "{nb} consommables sélectionnés",
            "modifier": "Modifier",
            "quantite-en-stock": "Quantité en stock",
            "entre": "entre",
            "et": "et",
            "delete": "Réinitialiser",
            "telecharger-modele-integration": "Télécharger modèle importation consommables",
            "importer-modele-integration": "Importer liste des consommables",
            "importer-modele-integration-confirm": "Confirmer l'importation des consommables ?",
            "colisage": "Colisage",
            "consommable-import-missing-fields": "Les champs suivants sont manquants dans le fichier d'importation: {fields}"
        },
        "en": {
            "title": "Consumables",
            "button-addConsommables": "Create consumable",
            "button-new-bon-de-sortie": "Create exit voucher",
            "button-new-bon-dentree": "Create entrance voucher",
            "tab-tous": "All",
            "tab-enStock": "Available in stock",
            "tab-stock-magasin": "In store",
            "tab-a-commander": "To order",
            "tab-non-disponibles": "Minimum threshold reached",
            "tab-en-demande": "In demand",
            "tab-sortis-non-consommes": "Outputs not consumed",
            "filtre-fournisseur-default": "Default supplier filter",
            "placeholder-fournisseur-default": "Select a supplier",
            "consommables-selected": "{nb} consumables selected",
            "modifier": "update",
            "quantite-en-stock": "Quantity in stock",
            "entre": "between",
            "et": "and",
            "delete": "Reinit",
            "telecharger-modele-integration": "Download Spare parts importation template",
            "importer-modele-integration": "Import spare parts list",
            "colisage": "Packing",
            "importer-modele-integration-confirm": "Confirm the import of spare parts?",
            "consommable-import-missing-fields": "The following fields are missing in the import file: {fields}"
        },
        "th": {
            "title": "ชิ้นส่วนอะไหล่/วัสดุสิ้นเปลือง",
            "button-addConsommables": "เพิ่มวัสดุสิ้นเปลือง",
            "tab-enStock": "ในสต็อก"
        }
    }
},
	watch: {
        searchQuery: {
            handler: function(newquery){
                if(newquery && newquery.length>0){
                    let query = newquery.split(" ");
                    this.consommablesFilters = {
                        name: {attr: "c.name", value: query, action: "contains", openParenthesis: true},
                        refExterne: {attr: "c.refExterne", value: query, action: "contains", logicalOperator: "OR"},
                        marque: {attr: "c.marque", value: query, action: "contains", logicalOperator: "OR"},
                        commentaire: {attr: "c.commentaire", value: query, action: "contains", logicalOperator: "OR"},
                        code: {attr: "c.code", value: query, action: "contains", logicalOperator: "OR"},
                        productGroup: {attr: "c.productGroup", value: query, action: "contains", logicalOperator: "OR"},
                        productSubGroup: {attr: "c.productSubGroup", value: query, action: "contains", logicalOperator: "OR"},
                        category: {attr: "c.category", value: query, action: "contains", logicalOperator: "OR"},
                        nature: {attr: "c.nature", value: query, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                        field_fournisseurDefault_id: {attr: "c.fournisseurDefault_id", value: null, action: "equals"},
                        field_nature_widget: {attr: "c.nature", value: null, action: "contains"},
                        field_stock_quantite: {attr: "s.quantite", value: null, action: "between"},
                        field_conditionnementColisage: {attr: "c.conditionnementColisage", value: null, action: "equals"},
                        field_quantiteColisage: {attr: "c.quantiteColisage", value: null, action: "equals"}
                    };
                }else{
                    this.consommablesFilters = {
                        name: {attr: "c.name", value: null, action: "contains", openParenthesis: true},
                        refExterne: {attr: "c.refExterne", value: null, action: "contains", logicalOperator: "OR"},
                        marque: {attr: "c.marque", value: null, action: "contains", logicalOperator: "OR"},
                        commentaire: {attr: "c.commentaire", value: null, action: "contains", logicalOperator: "OR"},
                        code: {attr: "c.code", value: null, action: "contains", logicalOperator: "OR"},
                        productGroup: {attr: "c.productGroup", value: null, action: "contains", logicalOperator: "OR"},
                        productSubGroup: {attr: "c.productSubGroup", value: null, action: "contains", logicalOperator: "OR"},
                        category: {attr: "c.category", value: null, action: "contains", logicalOperator: "OR"},
                        nature: {attr: "c.nature", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                        field_fournisseurDefault_id: {attr: "c.fournisseurDefault_id", value: null, action: "equals"},
                        field_nature_widget: {attr: "c.nature", value: null, action: "contains"},
                        field_stock_quantite: {attr: "s.quantite", value: null, action: "between"},
                        field_conditionnementColisage: {attr: "c.conditionnementColisage", value: null, action: "equals"},
                        field_quantiteColisage: {attr: "c.quantiteColisage", value: null, action: "equals"}
                    };
                }
                this.FiltersMixins_saveSearchQuery(newquery);
                
            }
        }
	},
	created: function () {
        this.fetchDepots();
        this.FiltersMixins_page_name = "consommables";
        //this.restoreFilters();
        
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
        if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
	},
	mounted: function () {

	},
	methods:{
        onTabChange: function(tab){
            this.consommablesFilters = Object.assign({}, this.consommablesFilters, tab.filters);
            this.FiltersMixins_saveDefaultTab(tab.name);
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.consommablesFilters[filterName].value = null;
            });
            this.searchQuery = null;
            this.FiltersMixins_deleteStoredCurrentFilters();
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.consommablesFilters = Object.assign({}, this.consommablesFilters, filters);
        },
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.consommablesFilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.consommablesFilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.consommablesFilters = filters;
            this.FiltersMixins_saveFilters(this.consommablesFilters);
        },
        restoreFilters:function(){
            let filters = this.FiltersMixins_getStoredFilters();
            let min;
            let max;
            if(filters.stock_quantite.value != null){
                if(filters.stock_quantite.action == "less_than"){
                    this.quantiteStockFilter.max = filters.stock_quantite.value;
                }else if(filters.stock_quantite.action == "greater_than"){
                    this.quantiteStockFilter.min = filters.stock_quantite.value;
                }else if(filters.stock_quantite.action == "between"){
                    min = filters.stock_quantite.value[0];
                    max = filters.stock_quantite.value[1];
                    this.quantiteStockFilter.min = min;
                    this.quantiteStockFilter.max = max;
                }
            }
            this.consommablesFilters = Object.assign(this.consommablesFilters, filters);
        },
        onInputColisageFilter: function(data){
            this.consommablesFilters.field_conditionnementColisage.value = data.conditionnementColisage;
            this.consommablesFilters.field_quantiteColisage.value = data.quantiteColisage;
            this.FiltersMixins_saveFilters(this.consommablesFilters);
        },
        resetInputQuantiteStock: function(){
            this.quantiteStockFilter = {
                min: null,
                max: null
            };
            this.onInputQuantiteStock();
        },
        onInputQuantiteStock: function(data){
            if(this.quantiteStockFilter.min && this.quantiteStockFilter.max) this.consommablesFilters.field_stock_quantite = {attr: "s.quantite", value: [this.quantiteStockFilter.min, this.quantiteStockFilter.max], action: "between"};
            else if(this.quantiteStockFilter.min) this.consommablesFilters.field_stock_quantite = {attr: "s.quantite", value: this.quantiteStockFilter.min, action: "greater_than"};
            else if(this.quantiteStockFilter.max) this.consommablesFilters.field_stock_quantite = {attr: "s.quantite", value: this.quantiteStockFilter.max, action: "less_than"};
            else this.consommablesFilters.field_stock_quantite = {attr: "s.quantite", value: null, action: "between"};
            this.FiltersMixins_saveFilters(this.consommablesFilters);
        },
        onChangeFilterDateIntervalle: function(intervalle){
            if(intervalle && intervalle.dateMin && intervalle.dateMax){
                if(this.tabIsBonsDeSortie) this.bonsDeSortieFilters.dateCreation.value = [intervalle.dateMin, intervalle.dateMax];
                else if(this.tabIsBonsDentree) this.bonsDentreeFilters.dateCreation.value = [intervalle.dateMin, intervalle.dateMax];
                this.FiltersMixins_saveFilters(this.consommablesFilters);
            }
        },
        onSaveUpdateConsommables: function(datas){
            this.showUpdateSelectedConsommables = false;
        },
        onSelectNode: function(node){
            this.consommablesFilters["field_nature_widget"].value = node.location;
        },
        onRemoveSelectedNode: function(){
            this.consommablesFilters["field_nature_widget"].value = null;
        },
        onRowDblClick: function(row){
            this.$store.dispatch("BonsDentreeStore/setSelectedItem", row.data);
            this.showBonEntreeModal = true;
        },
        loadingState: function(){
            this.isLoadingState = true;
        },
        onScannSuccess: function(code){
            let metadatas = new Metadatas();
            metadatas.setFilters({
                code: {attr: "c.code", value: code, action: "equals"},
                depot_id: {attr: "s.depot_id", value: this.getDepotId, action: "equals"}
            });
            this.ConsommablesMixins_getConsommables(metadatas, {_stored: false}).then((datas)=>{
                if(datas && datas.consommables && datas.consommables.length!=0){
                    let consommable = datas.consommables[0];
                    this.$router.push({ name: "_consommable_id", params: {id: consommable.id, consommable: consommable}});
                }else alert(this.$t("aucun-consommable-correspondant"));
                this.showScanner = false;
            });
        },
        fetchDepots: function(){
            return new Promise((resolve, reject)=>{
                let metadatasDepots = new Metadatas();
                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
                    resolve(datas);
                });
            });
        },
		handleSelectedRow: function(row){
			if(this.focusedTab!="sortisnonconsommes") this.$router.push({ name: "_consommable_id", params: {id: row.data.id, consommable: row.data}});
		},
        handleActionExport:function(e){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.getConsommablesFilters);
            switch (e.name) {
                case "askForXLS":
                    this.ConsommablesMixins_getFile(metadatas,"excel").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.ConsommablesMixins_getFile(metadatas,"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"consommables-tableau"}
                    }));
                    break;
                default:
                    break;
            }
        },
        handleExportConsommablesIntegrationModel:function(){
            this.ConsommablesMixins_getExcelFileModeleIntegration();
        },
        handleImportConsommablesModel:function(files){
            this.isLoadingState = true;
            this.isLoading = true;
            let result = [];
            var reader = new FileReader();
            reader.onload = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, {type: 'array'});

                // Assuming the first sheet here; adapt as needed
                var firstSheetName = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[firstSheetName];
                
                // Convert to JSON
                var json = XLSX.utils.sheet_to_json(worksheet, {raw: true});
                // check if all required fields are present, 
                const requiredFields = [
                    "intitulé consommable"
                ];
                let missingFields = [];
                json.forEach((consommable, index)=>{
                    requiredFields.forEach((field)=>{
                        if(!consommable.hasOwnProperty(field)){
                            if(!missingFields.includes(field)) missingFields.push(field);
                        }
                    });
                });

                if(missingFields.length){
                    this.isLoading = false;
                    alert(this.$t("consommable-import-missing-fields",{fields:missingFields.join(", ")}));
                    this.isLoadingState = false;
                    return;
                }
                if (window.confirm(this.$t("importer-modele-integration-confirm"))) {
                    this.ConsommablesMixins_ImportModelConsommablesExcel(files).then(()=>{
                            this.isLoadingState = false;
                            window.location.reload();
                        });
                }else{
                    this.isLoadingState = false;
                }
                this.isLoadingState = false;
            }
            reader.readAsArrayBuffer(files[0]);
        }
	},
	computed:{
        ...mapGetters({
            counters: "ConsommablesStore/getCounters",
            countersBonsDeSortie: "BonsDeSortieStore/getCounters",
            countersBonsDentree: "BonsDentreeStore/getCounters",
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant",
            selectedConsommables: "ConsommablesStore/getSelectedItems"
        }),
        pageFilters: function(){
            return ["field_fournisseurDefault_id", "field_nature_widget", "field_stock_quantite", "field_conditionnementColisage", "field_quantiteColisage"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.consommablesFilters[filterName] && this.consommablesFilters[filterName].value!=null && (this.consommablesFilters[filterName].value && this.consommablesFilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        hasActiveGlobalFilters: function(){
            let filters = this.consommablesFilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        },
        getConsommablesTableId: function(){
            if(this.focusedTab=='nondisponibles') return "consommables-non-disponibles-tableau";
            else if(this.focusedTab=='endemande') return "consommables-en-demande-tableau";
            else return "consommables-tableau";
        },
        isGestionMagasin: function(){
            let user = window.sessionStorage.getItem("user");
            user = JSON.parse(user);
            return user.isGestionMagasin;
        },
		tabs: function(){
			var counters = this.counters;
            if(this.isGestionMagasin){
                return [
    				{label: this.$t("tab-tous"), name:"tous", counter: counters.filtered!=counters.all?counters.filtered+" / "+counters.all:counters.all},
    				{label: this.$t("tab-stock-magasin"), name:"enstock", counter: counters.enstock},
    				{label: this.$t("tab-a-commander"), name:"acommander", counter: counters.acommander},
    				{label: this.$t("tab-en-demande"), name:"endemande", counter: counters.endemande},
    				{label: this.$t("tab-sortis-non-consommes"), name:"sortisnonconsommes", counter: null}
    			];
            }else{
                return [
                    {label: this.$t("tab-tous"), name:"tous", counter: counters.filtered!=counters.all?counters.filtered+" / "+counters.all:counters.all},
    				{label: this.$t("tab-enStock"), name:"enstock", counter: counters.enstock},
    				{label: this.$t("tab-a-commander"), name:"acommander", counter: counters.acommander},
    				{label: this.$t("tab-en-demande"), name:"endemande", counter: counters.endemande}
    			];
            }
		},
        tabIsConsommables: function(){
            return this.focusedTab=="tous" || this.focusedTab=="enstock" || this.focusedTab=="acommander"|| this.focusedTab=="sortisnonconsommes" || this.focusedTab=="nondisponibles" || this.focusedTab=="endemande";
        },
        
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        getDepotId: function(){
            if(this.focusedTab=='sortisnonconsommes' && this.depotFlottant) return this.depotFlottant.id;
            else if(this.depotDefault) return this.depotDefault.id;
            else return null;
        },
        getConsommablesFilters: function(){
            if(this.focusedTab=="sortisnonconsommes") return Object.assign({}, this.consommablesFilters, { quantite: {attr: "s.quantite", value: "0", action: "strictly_greater_than"}});
            else return this.consommablesFilters;
        },
        getFromDateIntervalle: function(){
            return moment().subtract(3, 'month').format('YYYY-MM-DD');
        },
        getToDateIntervalle: function(){
            return moment().format('YYYY-MM-DD');
        },
    }
}
</script>

<style lang="scss">
#consommables{
	height: 100%;
}
.btn-new-consommables{
    background-color: #5EBC55;
    top: 6px;
    right: 35px;
    height: 40px;
    padding-top: 10px;
    transition: background-color .5s ease;
    background-color: #5EBC55 !important;
    color: white !important;
    border: 1px solid #5EBC55 !important;
}
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
}
.label-file:hover {
    color: #535353;
}

// et on masque le input
.input-file {
    display: none;
}
</style>
