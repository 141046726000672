<template>
	<vg-input :title="title?title:$t('title')">
		<vg-typologies-maintenance-selector
			v-model="selectedTypologie"
			attributeValue="name"
			@input="$emit('input',selectedTypologie)">
		</vg-typologies-maintenance-selector>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgTypologiesMaintenanceSelector from "src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue";
    export default {
        name: 'vg-typologie-maintenance-filter',
	    components: {
			VgInput,
			VgTypologiesMaintenanceSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: Array | String,
				required: true
			},
			title: {
				type: String,
				default: null
			},
			/**
			* attribut du tiers choisi renvoyé au v-model
			* default "id"
			* peut être null renvoie tout l'objet tiers
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			 * Donne au select la possibilité de contenir plusieur reponse
			 */
			multiple:{
				type: Boolean,
				default: false
			},
			/**
			 * 2version d'affichage du composant disponible, "dropdown" = avec vg-select || "modal" = avec modale de selection multiple
			 * @values dropdown || modal
			 */
			display:{
				type:String,
				default:"dropdown"
			},
			filters:{
				type: Object,
				default:()=>{
					return {};
				}
			}
        },
		i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    "title": "Filtrage par Typologie"
                },
                "en": {
                    "title": "Typologie filtering"
                }
            }
        },
        data: function(){
            return {
                "selectedTypologie":this.value
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedTypologie = val;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
