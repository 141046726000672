<template>
    <div class="bon-de-commande-demandeur-cell-render">
        <span v-if="params.data.siteDemandeur_id && params.data.siteDemandeur" class="site">{{params.data.siteDemandeur.libel_lieu}}</span>
        <span v-if="params.data.demandeur" class="site">{{params.data.demandeur}}</span>
        <span v-if="(params.data.siteDemandeur_id || params.data.demandeur) && params.data.serviceDemandeur">&nbsp;/&nbsp;</span>
        <span v-if="params.data.serviceDemandeur" class="service">{{params.data.serviceDemandeur}}</span>
	</div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "bon-de-commande-demandeur-cell-render",
        mixins:[LangMixins],
        components: {

        },
        props: {
			params: {
                type: Object,
                required: true
            }
		},
        data: function(){
            return {

            };
        },
        created: function(){

        },
        methods: {

        },
        computed: {

        }
    });
</script>

<style lang="scss" scoped>
.bon-de-commande-demandeur-cell-render{

}
</style>
