<template>
	<span v-if="workingTime<60">
		{{workingTime}} {{$t("minutes")}}
	</span>
	<span v-else-if="workingTime>=60">
		{{workingTimeConvertIntoHours}} {{$t("heure")}}
		<span v-if="workingTimeMinutes">{{workingTimeMinutes}} {{$t("minutes")}}</span>
	</span>
</template>
<script>
    export default {
        name: 'vg-fichesav-workingtime-viewer',
		components: {

		},
        props: {
			value: {
				type: Number | String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "minutes": "min",
            "heure": "h"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			workingTime: function(){
				return parseInt(this.value);
			},
			workingTimeConvertIntoHours: function(){
				let hours = parseInt(this.value)/60;
				return Math.floor(hours);
			},
			workingTimeMinutes: function(){
				if(this.workingTimeConvertIntoHours*60==this.value) return null;
				else return parseInt(this.value)-this.workingTimeConvertIntoHours*60;
			},
		}
    };

</script>
<style lang="scss" scoped>

</style>
