<template>
	<div class="vg-search-datas-results">
		
		<div v-if="isLoading"
			class="loading">
			<span>{{ $t("loading") }} <b>&quot;{{ searchValue }}&quot;</b> ...</span>
		</div>

		<!-- EQUIPEMENTS / LIEUX -->
		<vg-search-datas-viewer v-if="can('RECHERCHE_GENERALE.EQUIPEMENTS_READ')" 
			v-model="equipements"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isEquipementsOuLieux
			key="equipements-viewer"
			@open="openEquipement"
			@redirect="redirectToEquipements"
			@create="showCreateEquipement=true;"
			@create-fm="openCreateEquipementFm"
			@demande-intervention="openDemandeIntervention"
			@check="openCheck"
			@update="openUpdateEquipement"/>

		<div v-if="!isLoading && equipements.length==0 && counters.equipements==0"
			class="result-no-datas">
			{{ $t("no-datas") }} <b>&quot;{{ searchValue }}&quot;</b>
		</div>

	</div>
</template>
<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgSearchDatasViewer from "src/components/Vg/SearchDatas/VgSearchDatasViewer.vue";

import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import VgLieuInfoCreate from "src/components/Vg/Lieu/VgLieuInfoCreate.vue";
import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";
import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
import VgContratForm from "src/components/Vg/Forms/VgContratForm.vue";
import VgTiersForm from "src/components/Vg/Forms/VgTiersForm.vue";
import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
import VgContactsForm from "src/components/Vg/Forms/VgContactsForm.vue";
import VgFichesavManagement from "src/components/Vg/FicheSAV/VgFichesavManagement.vue";

import SearchDatasMixins from "src/mixins/SearchDatasMixins.js";

import Metadatas from 'src/services/Metadatas.js';
import { mapGetters } from 'vuex';

    export default {
        name: 'vg-search-equipements-results',
		i18n:    { 
			"locale":navigator.language,
			"messages": {
				"fr": {
					"no-datas": "Aucune donnée ne correspond à votre recherche",
					"loading": "Recherche en cours",
					"saisir-un-terme": "Saisir un terme",
					"impossible-charger-check": "Impossible de lancer check pour {libel}"
				},
				"en": {
					"no-datas": "No data matches your search",
					"loading": "Searching"
				}
			}
		},
		components: {
			VgButton,
			VgSearchDatasViewer,
			VgEquipementForm,
			VgLieuInfoCreate,
			VgLieuForm,
			VgMaintenanceForm,
			VgContratForm,
			VgTiersForm,
			VgCategorieForm,
			VgInterventionForm,
			VgContactsForm,
			VgFichesavManagement
		},
		mixins: [ SearchDatasMixins ],
        props: {
			value:{
				type: String,
				default: null
			},
			showEmpty: {
				type: Boolean,
				default: false
			},
			afterSaveRedirectTo: {
				type: String,
				default: null
			}
        },
		watch:{
			value: {
				handler: function(newValue){
					this.searchValue = newValue;
					this.fetch();
				}
			}
		},
        data: function() {
            return {
				searchValue: this.value,
				isLoading: false,
				metadatas: new Metadatas()
            };
        },
		methods: {
			fetch: function(){
				if(this.searchValue==null){ 
					this.$store.dispatch("SearchDatasStore/clear");
					return;
				}
				this.isLoading = true;
				this.SearchDatasMixins_getEquipements(this.searchValue, this.metadatas).then((datas)=>{
					console.log("DATAS", datas);
					this.isLoading = false;
				});
			},
			/**
			 * @TODO il faudrai refactorizer tout ça pour que ça marche en dehors de ce composant
			 */
			routerPush: function(routeName, filters){
				this.$router.push({name: routeName, params: {defaultFilters: filters}})
					.catch(failure => {
						if(failure.to && failure.from && failure.to.name==failure.from.name){
							this.$emit("filters-change", filters);
						}
					});
			},
			// Equipements
			redirectToEquipements: function(){
				this.routerPush("_equipements", this.filters.equipements);
			},
			openEquipement: function(equipement){
				this.$router.push({ name: '_equipement_id', params: { id: equipement.id } });
			},
			openCreateEquipementFm: function(equipement){
				this.focusedEquipement = equipement;
				this.showCreateEquipementFm = true;
			},
			openUpdateEquipement: function(equipement){
				this.focusedEquipement = equipement;
				this.showUpdateEquipement = true;
			},
			onSaveEquipement: function(equipements){
				this.showCreateEquipement = false;
				this.openEquipement(equipements[0]);
			},
			onUpdateEquipement: function(equipement){
				this.showUpdateEquipement = false;
				this.focusedEquipement = null;
				this.$emit("close");
			},
			// Lieux
			redirectToLieux: function(){
				this.routerPush("_lieux", this.filters.lieux);
			},
			openLieu: function(lieu){
				if(lieu.type_lieu=="Piece" || lieu.type_lieu=="Etage") this.$router.push({ name: '_lieu_id', params: { id: lieu.id } });
				else if(lieu.type_lieu=="Batiment") this.$router.push({ name: '_batiment_id', params: { id: lieu.id } });
				else if(lieu.type_lieu=="Site") this.$router.push({ name: '_site_id', params: { id: lieu.id } });
			},
			openUpdateLieu: function(lieu){
				this.focusedLieu = lieu;
				this.showUpdateLieu = true;
			},
			onUpdateLieu: function(lieu){
				this.showUpdateLieu = false;
				this.focusedLieu = null;
				this.$emit("close");
			},
			// Maintenances
			redirectToMaintenances: function(){
				this.routerPush("_maintenances", this.filters.maintenances);
			},
			openMaintenance: function(maintenance){
				this.$router.push({ name: '_maintenance', params: { id: maintenance.id } });
			},
			openMaintenanceActions: function(maintenance){
				this.focusedMaintenance = maintenance;
				this.$router.push({ name: '_maintenance', params: { id: maintenance.id, openActions: true } });
			},
			openDemandeIntervention: function(item=null){
				if(!item) this.routerPush("_demande_intervention", null);
				else if(item.hasOwnProperty("codeUn") && item.hasOwnProperty("libel_lieu") && item.libel_lieu){ // cas équipement
					this.$router.push({name: "_demande_intervention", params: {code: item.codeUn , isScann: true, afterSaveRedirectTo: this.$app.role=="ROLE_CHEF_EQUIPE"?"_lancement":null}})
						.catch(failure => {
							if(failure.to && failure.from && failure.to.name==failure.from.name){
								this.$emit("filters-change", {code: item.codeUn , isScann: true});
							}
						});
				}else if(item.hasOwnProperty("qrCode") && item.qrCode){ // cas équipement
					this.$router.push({name: "_demande_intervention", params: {code: item.qrCode, isScann: true, afterSaveRedirectTo: this.$app.role=="ROLE_CHEF_EQUIPE"?"_lancement":null}})
						.catch(failure => {
							if(failure.to && failure.from && failure.to.name==failure.from.name){
								this.$emit("filters-change", {code: item.qrCode , isScann: true});
							}
						});
				}
			},
			openCheck: function(item){
				if(item.hasOwnProperty("codeUn") && item.hasOwnProperty("libel_lieu") && item.libel_lieu){
					let tachesLieu = this.taches.filter((tache)=>tache.categorie_id==item.idCategorie_id);
					if(tachesLieu.length==1){
						this.$router.push({name: "_process_verification", params: {type: "lieux" , equipementId: item.equipement_id, tacheId: tachesLieu[0].id, afterSaveRedirectTo: this.afterSaveRedirectTo}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_lieu}));
							});
					}else{
						this.$router.push({name: "_process_verification_taches_selection", params: {type: "lieux" , equipementId: item.equipement_id, afterSaveRedirectTo: this.afterSaveRedirectTo}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_lieu}));
							});
					}
				}else if(item.hasOwnProperty("qrCode") && item.hasOwnProperty("libel_equipement") &&item.qrCode){
					let tachesEquipement = this.taches.filter((tache)=>tache.categorie_id==item.idCategorie_id);
					if(tachesEquipement.length==1){
						this.$router.push({name: "_process_verification", params: {type: "equipement" , equipementId: item.id, tacheId: tachesEquipement[0].id, afterSaveRedirectTo: this.afterSaveRedirectTo}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_equipement}));
							});
					}else{
						this.$router.push({name: "_process_verification_taches_selection", params: {type: "equipement" , equipementId: item.id, afterSaveRedirectTo: this.afterSaveRedirectTo}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_equipement}));
							});
					}
				}
			},
			// Contrats
			redirectToContrats: function(){
				this.routerPush("_contrat", this.filters.contrats);
			},
			openContrat: function(contrat){
				this.$router.push({ name: '_contrat_id', params: { id: contrat.id } });
			},
			openUpdateContrat: function(contrat){
				this.focusedContrat = contrat;
				this.showUpdateContrat = true;
			},
			onSaveContrat: function(contrat){
				this.openContrat(contrat);
			},
			onUpdateContrat: function(contrat){
				this.showUpdateContrat = false;
				this.focusedContrat = null;
				this.$emit("close");
			},
			// Tiers
			redirectToTiers: function(){
				this.routerPush("_tiers", this.filters.tiers);
			},
			openTier: function(tier){
				this.$router.push({ name: '_tiers_id', params: { id: tier.uid } });
			},
			openUpdateTier: function(tier){
				this.focusedTier = tier;
				this.showUpdateTier = true;
			},
			onSaveTiers: function(tiers){
				this.openTiers(tiers[0]);		
			},
			onUpdateTiers: function(tier){
				this.showUpdateTier = false;
				this.focusedTier = null;
				this.$emit("close");
			},
			// categories
			redirectToCategories: function(){
				this.routerPush("_categories_equipements", this.filters.categories);
			},
			openCategorie: function(categorie){
				//this.$router.push({ name: '_categories_equipements', params: { id: tier.uid } });
			},
			openUpdateCategorie: function(categorie){
				this.focusedCategorie = categorie;
				this.showUpdateCategorie = true;
			},
			onSaveCategorie: function(categorie){
				this.showCreateCategorie = false;
				// this.openCategorie(categorie);		
			},
			onUpdateCategorie: function(categorie){
				this.showUpdateCategorie = false;
				this.focusedCategorie = null;
				this.$emit("close");
			},
			// interventions
			redirectToInterventions: function(){
				this.routerPush("_interventions", this.filters.interventions);
			},
			openIntervention: function(intervention){
				this.$router.push({ name: '_intervention', params: { id: intervention.id } });
			},
			openUpdateIntervention: function(intervention){
				this.focusedIntervention = intervention;
				this.showUpdateIntervention = true;
			},
			onSaveIntervention: function(intervention){
				this.showCreateIntervention = false;
				this.openIntervention(intervention);		
			},
			onUpdateIntervention: function(intervention){
				this.showUpdateIntervention = false;
				this.focusedIntervention = null;
				this.$emit("close");
			},
			// contacts
			redirectToContacts: function(){
				//this.routerPush("_tiers", this.filters.contacts);
			},
			openTierContact: function(contact){
				this.$router.push({ name: '_tiers_id', params: { id: contact.tiers_uid } });
			},
			openUpdateContact: function(contact){
				this.focusedContact = contact;
				this.showUpdateContact = true;
			},
			onSaveContact: function(contact){
				this.openTierContact(contact);
			},
			onUpdateContact: function(contact){
				this.showUpdateCategorie = false;
				this.focusedCategorie = null;
				this.$emit("close");
			},
		},
		computed: {
			...mapGetters({
                entities: "SearchDatasStore/getEntities",
                counters: "SearchDatasStore/getCounters",
                filters: "SearchDatasStore/getFilters",
                equipements: "SearchDatasStore/getEquipements",
				lieux: "SearchDatasStore/getLieux",
				maintenances: "SearchDatasStore/getMaintenances",
				contrats: "SearchDatasStore/getContrats",
				tiers: "SearchDatasStore/getTiers",
				categories: "SearchDatasStore/getCategories",
				interventions: "SearchDatasStore/getInterventions",
				contacts: "SearchDatasStore/getContacts",
				taches: "SearchDatasStore/getTaches"
            }),
			
		},
		mounted: function(){
			this.fetch();
		}
    };

</script>
<style lang="scss" scoped>
.vg-search-datas-results{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 5px;
	width: inherit;
	min-height: 40px;
	max-height: 60vh;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px #ccc;
	background-color: white;
	overflow-y: auto;
	z-index: 100;
    max-width: -webkit-fill-available;
	.result-no-datas{
		color: #35b1ea;

	}
	.loading{
		font-weight: normal;
		color: #35b1ea;
	}
	.empty{
		font-weight: normal;
		color: #35b1ea;
		margin: 5px 0;
	}
}
</style>
