
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.categories = [];
state.equipementsRestants = [];
let getters = Base.getGetters();
getters.getCategories = function( state ){
    return state.categories;
};
getters.getEquipementsRestants = function( state ){
    return state.equipementsRestants;
};
let actions = Base.getActions();
actions.setCategories = function( context, values ){
    context.commit('SET_CATEGORIES', values);
};
actions.setEquipementsRestants = function( context, values ){
    context.commit('SET_EQUIPEMENTS_RESTANTS', values);
};
let mutations = Base.getMutations();
mutations.SET_CATEGORIES = function(state, values){
    state.categories = values;
};
mutations.SET_EQUIPEMENTS_RESTANTS = function(state, values){
    state.equipementsRestants = values;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
