
<template lang="html">
	<div v-if="VgFilesMixins.files && VgFilesMixins.files.length!=0" class="files-selector-container">
		<div v-for="(file, index) in VgFilesMixins.files">
			<!-- image affiche thumbnail -->
			<div v-if="file.extension.includes('image/')" :class="['item', {'item-selected': isFileSelected(file)}]" @click="onSelectFile(file)">
				<vg-files-thumbnail :file="file" :key="'file-'+index"
					heightImg="60px" 
					:clickable="false"/>
				<span>{{ file.name }}</span>
				<small>{{ file.tagLabel }}</small>
				<i v-if="isFileSelected(file)" class="far fa-check-circle" style="color: rgb(0, 153, 255);"></i>
				<i v-else class="far fa-circle" style="color: rgb(0, 153, 255);"></i>
			</div>
			<div v-else-if="file.extension=='application/pdf'" :class="['item', {'item-selected': isFileSelected(file)}]" @click="onSelectFile(file)">
				<div class="item-icon"><i class="far fa-2x fa-file-pdf"></i></div>
				<span>{{ file.name }}</span>
				<small>{{ file.tagLabel }}</small>
				<i v-if="isFileSelected(file)" class="far fa-check-circle" style="color: rgb(0, 153, 255);"></i>
				<i v-else class="far fa-circle" style="color: rgb(0, 153, 255);"></i>
			</div>
			<div v-else :class="['item', {'item-selected': isFileSelected(file)}]" @click="onSelectFile(file)">
				<div class="item-icon"><i class="far fa-2x fa-file"></i></div>
				<span>{{ file.name }}</span>
				<small>{{ file.tagLabel }}</small>
				<i v-if="isFileSelected(file)" class="far fa-check-circle" style="color: rgb(0, 153, 255);"></i>
				<i v-else class="far fa-circle" style="color: rgb(0, 153, 255);"></i>
			</div>
			<!-- document affiche icone document -->
		</div>
	</div>
	<div v-else class="files-selector-container" style="color:#35b1ea;">
		{{$t("no-file")}}
	</div>
</template>
<script>
	import VgCollapse from "src/components/Vg/VgCollapse.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";

	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-files-selector',
	    components: {
			VgCollapse,
			VgButton,
			VgFilesThumbnail
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-data": "Aucune données",
            "no-file": "Aucun document",
            "delete-confirm": "Confirmer la suppression du document"
        },
        "en": {
            "no-data": "No data",
            "no-file": "No document",
            "delete-confirm": "Confirm documents deletion"
        },
        "th": {
            "no-data": "ไม่มีข้อมูล",
            "no-file": "ไม่มีเอกสาร",
            "delete-confirm": "ยืนยันการลบเอกสาร"
        }
    }
},
        props: {
			
        },
        data: function() {
            return {
				selectedIdsFiles: [],
				downloadedFiles: {}
			};
        },
		watch: {
			
		},
		created: function(){
			this.VgFilesMixins_init(this.maintenance.uid, "fichemaintenance");
			this.VgFilesMixins_fetchFilesByUid();
		},
        methods: {
			downloadFile: function(file){
				if(!this.downloadedFiles[file.id]){
					this.$emit("loading", true);
					this.VgFilesMixins_getDownloadFileLink(file).then((link)=>{
						this.VgFilesMixins_convertUrlToBase64String(link).then((base64File)=>{
							this.downloadedFiles[file.id] = Object.assign({}, file, {base64: base64File});
							this.emitDownloadedFiles();
							this.$emit("loading", false);
						});
					});
				}else{
					this.emitDownloadedFiles();
				}
			},
			emitDownloadedFiles: function(){
				let downloadedFiles = [];
				this.selectedIdsFiles.forEach((id)=>{
					downloadedFiles.push(this.downloadedFiles[id]);
				});
				this.$emit("downloaded-files", downloadedFiles);
			},
			isFileSelected: function(file){
				return this.selectedIdsFiles.indexOf(file.id)!=-1;
			},
			onSelectFile: function(file){
				if(this.isFileSelected(file)){
					this.selectedIdsFiles.splice(this.selectedIdsFiles.indexOf(file.id), 1);
					this.$emit("selected-files", this.selectedFiles);
					this.emitDownloadedFiles();
				}else{
					this.selectedIdsFiles.push(file.id);
					this.$emit("selected-files", this.selectedFiles);
					this.downloadFile(file);
				}
			}
		},
		computed: {
			...mapGetters({
				maintenance: 'MaintenancesStore/getSelectedMaintenance'
			}),
			selectedFiles: function(){
				return this.VgFilesMixins.files.filter(f=>this.selectedIdsFiles.indexOf(f.id)!=-1);
			}
		}
	};
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.files-selector-container{
	width:100%;
	height: 200px;
	overflow-y: auto;
	border:1px solid #dcdfe6;
	padding:10px;
	display: flex;
	flex-direction:row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	.item{
		max-width:180px;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap:5px;
		padding:5px;
		cursor: pointer;
		>span{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
		.item-icon{
			height: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	.item:hover{
		background-color: whitesmoke;
	}
	.item-selected:hover{
		background-color: rgba(53, 177, 234, 0.3);
	}
	.item-selected{
		background-color: rgba(53, 177, 234, 0.3);
	}
}

</style>
