<template>
    <vg-modal
        :title="$t('title-'+flag)"
        @close="closeModal"
        @save="submitOperation"
        :isSaveDisabled="operation.length<2">
        <template #body>
            <vg-input :inputType="'textarea'" v-model="operation.operation"></vg-input>
            <vg-collapse :title="$t('more')" v-if="flag=='tache' || flag=='a_prevoir'">
                <template v-slot:content>
                    <vg-input :title="$t('etiquette')">
                        <vg-tags-selector v-model="tagOperation"
                            :type="getTypeTags"
                            :attributeValue="null">
                        </vg-tags-selector>
                    </vg-input>
                </template>
            </vg-collapse>
        </template>
    </vg-modal>

</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgTagsSelector from 'src/components/Vg/Selectors/VgTagsSelector.vue';
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
export default {
    name:"vg-operation-update",
    components:{
        VgButton,
        VgInput,
        VgCollapse,
        VgTagsSelector
    },
    mixins: [ MaintenanceMixins ],
    props:{
        value: {
            type: Object,
            required: true
        },
        flag: {
            type: String,
            default: "manuelle"
        }
    },
    data:function() {
        return{
            operation: this.value,
            tagOperation: this.value.tags?this.value.tags[0]:null
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-manuelle": "Modifier le commentaire",
            "title-tache": "Modifier la tache",
            "title-a_prevoir": "Modifier à prévoir",
            "more": "Données complémentaires",
            "etiquette": "Etiquette"
        },
        "en": {
            "title": "Modify operation",
            "title-manuelle": "Update commentary",
            "title-tache": "Update task",
            "title-a_prevoir": "Update scheduled task",
            "more": "Additional data",
            "etiquette": "Label"
        },
        "th": {
            "title": "แก้ไขปฏิบัติการ"
        }
    }
},
    methods:{
        submitOperation: function(){
            //this.$emit('save', this.operation); // ATTENTION ça ne va plus marcher pour les autres appel ce cette modal update opération
            // PUT /operation/{id} à faire ici utiliser MaintenanceMixins_putOperation
            if(this.tagOperation) this.operation.tags = [this.tagOperation];    // pour l'instant bloqué à un tag par opération
            else if(this.flag=="tache" || this.flag=="a_prevoir") this.operation.tags = [];
            this.MaintenanceMixins_putOperation(this.operation).then((response)=>{
                console.log("SUCCESS PUT OPERATIONS", response);
                this.$emit('save');
            });
        },
        closeModal: function(){
            this.$emit('close');
        }
    },
    computed:{
        getTypeTags: function(){
            if(this.flag=="tache") return ["operation/tache"];
            else if(this.flag=="a_prevoir") return ["operation/a-prevoir"];
            else return null;
        }
    }
}
</script>

<style lang="scss">
</style>
