<template>
    <div style="display:flex; gap:5px;width:100%;">
        <vg-input v-model="quantity" inputType="number" @input="onChangeQuantity" 
            style="width:100px;"/>
        <vg-select v-model="periodicite"
            :placeholder="$t(placeholder)"
            :options="shortcuts"
            :attributeValue="'value'"
            :attributeLabel="'label'"
            :clearable="clearable"
            @input="onChangePeriodicite" />
    </div>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";

export default {
    name: "vg-input-temps",
    props: {
        /**
        * @model
        */
        value: {
            type: Number | String
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder:{
            type: String,
            default: "periodicite"
        },
        shortcutsCollection: {
            type: Array,
            default: function(){
                return null;
            }
        }
    },
    components: {
        VgSelect,
        VgInput
    },
    data: function() {
        return{
            quantity:1,
            shortcuts: [
                {label: this.$t("jours"), value: 1},
                {label: this.$t("semaine"), value: 7},
                {label: this.$t("mois"), value: 30},
                {label: this.$t("trimestre"), value: 90},
                {label: this.$t("semestre"), value: 180},
                {label: this.$t("annuelle"), value: 365},
                //{label: this.$t("personnalisee"), value: null}
            ],
            periodicite: 7,
            showCustomPeriodicity: false
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "periodicite": "Périodicité",
            "semaine": "Semaines",
            "mois": "Mois",
            "trimestre": "Trimestres",
            "semestre": "Semestres",
            "annuelle": "Années",
            "personnalisee": "Personnalisée",
            "jours": "Jours"
        },
        "en": {
            "periodicite": "Periodicity",
            "semaine": "Weeks",
            "mois": "Months",
            "trimestre": "Quarters",
            "semestre": "Semestres",
            "annuelle": "Years",
            "personnalisee": "Personalized",
            "jours": "Days"
        }
    }
},
    watch: {
        
    },
    mounted: function(){
        if(this.value) this.periodicite = this.value;
        if(this.value && this.shortcuts.findIndex((cut)=>cut.value==this.value)==-1) this.showCustomPeriodicity = true;
    },
    methods: {
        onChangeQuantity: function(inputTemps){
            this.$emit("input", {
                quantity: this.quantity,
                periodicite: this.periodicite,
                value: Number(this.quantity)*Number(this.periodicite),
                label: this.getTempsLabel
            });
        },
        onChangePeriodicite: function(inputTemps){
            this.$emit("input", {
                quantity: this.quantity,
                periodicite: this.periodicite,
                value: Number(this.quantity)*Number(this.periodicite),
                label: this.getTempsLabel
            });
        },
    },
    computed: {
        getTempsLabel: function(){
            if(!this.quantity || !this.periodicite) return null;
            let readablePeriodicity = this.shortcuts.find((short)=>short.value==this.periodicite);
            return this.quantity+" "+readablePeriodicity.label;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .vg-periodicite-shortcuts{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5px;
        .custom{
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 5px;
        }
    }
</style>
