/**
* this file was generated
*/
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.receveurs = [];
state.donneurs = [];
let getters = Base.getGetters();
getters.getReceveurs = function(state){
    return state.receveurs;
};
getters.getDonneurs = function(state){
    return state.donneurs;
};
let actions = Base.getActions();
actions.setReceveurs = function( context, value ){
    context.commit('SET_RECEVEURS', value);
};
actions.setDonneurs = function( context, value ){
    context.commit('SET_DONNEURS', value);
};
let mutations = Base.getMutations();
mutations.SET_RECEVEURS = function(state, value){
    state.receveurs = value;
};
mutations.SET_DONNEURS = function(state, value){
    state.donneurs = value;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
