<template>
    <div v-if="lieu" class="popup-piece-marker">
        <span v-if="!existInDatabase">{{ $t("marker-a-supprimer") }}</span>
        <vg-lieu-path-viewer v-if="lieu && !showUpdateMarker"
            v-model="lieu.path"/>
        <div v-if="lieu.hasMaintenance"
            style="width:100%;border-bottom:1px solid lightgrey;"></div>
        <div v-if="lieu.hasMaintenance" class="maintenance-en-cours">
            <img src="static/assets/icone/maintenance-rouge.png"/> <span>{{$t("maintenances-en-cours")}}</span>
        </div>
        <span v-if="!lieu || !lieu.id" class="info">
            {{$t("lieu-non-creer")}}
        </span>
        <div v-if="isCreationMode" class="content-update">
            <vg-input v-if="showUpdateMarker" v-model="updatedLieu.libel_lieu"
                :title="$t('libel-lieu')"
                style="width:200px;">
            </vg-input>
            <vg-input v-if="showUpdateMarker" v-model="updatedLieu.codeUn"
                :title="$t('qrcode')"
                style="width:200px;">
            </vg-input>
            <vg-input v-if="showUpdateMarker" 
                :title="$t('categorie')"
                style="width:200px;">
                <vg-select v-model="updatedLieu.categorie"
                    :options="categories"
                    :attributeLabel="'libelleCatgorie'"
                    :attributeValue="null"
                    :clearable="false">
                    <template v-slot="{option}">
                        <div style="display:flex;justify-content:flex-start;align-items:center;gap:3px;width:100%;padding:2px 0;">
                            <div style="width:12px;">
                                <div :style="'width:12px;height:12px;border-radius:100%;background-color:'+option.codeCouleur+';'"></div>
                            </div>
                            <img v-if="option.icon" :src="option.icon" height="15" width="15" />
                            <div v-else style="width:15px;"></div>
                            <span v-if="option.idAccount" style="font-size: 11px;">C{{option.idAccount}} - </span>
                            <span style="font-size: 11px;">{{option.libelleCatgorie}}</span>
                        </div>
                    </template>
                </vg-select>
            </vg-input>
            <vg-input v-if="showUpdateMarker" 
                :title="$t('service')"
                style="width:200px;">
                <vg-select v-model="updatedLieu.service"
                    :options="services"
                    :attributeValue="'libelService'"
                    :attributeLabel="'libelService'"/>
            </vg-input>
            <div class="footer">
                <vg-button v-if="isCreationMode && !showUpdateMarker && !(lieu && lieu.id)" :size="'sm'" :type="'info'" @click="onShowUpdateMarker"><i class="fas fa-pen"></i></vg-button>
                <vg-button v-if="isCreationMode && !showUpdateMarker && !(lieu && lieu.id)" :size="'sm'" :type="'danger'" @click="emitDeleteMarker"><i class="fas fa-trash-alt"></i></vg-button>
                <vg-button v-if="showUpdateMarker" :size="'sm'" :type="'success'"
                    @click="emitUpdateMarker">
                    <i class="fas fa-save"></i>
                </vg-button>
                <vg-button v-if="showUpdateMarker" :size="'sm'" :type="'default-success'"
                    @click="showUpdateMarker=false;">
                    <i class="fas fa-times"></i>
                </vg-button>
            </div>
        </div>
        <div class="footer" v-if="lieu && lieu.id && !isCreationMode">
            <vg-button v-if="can('PIECE.PAGE')"
                size="md"
                type="success"
                @click="toZoomLieu">
                {{$t("voir-lieu")}}
            </vg-button>
            <vg-button v-if="can('DEMANDE_INTERVENTION.PAGE')"
                size="md"
                type="danger"
                @click="toDemandeIntervention">
                {{$t("faire-une-di")}}
            </vg-button>
        </div>
        <div class="footer" v-else-if="lieu && lieu.id && isCreationMode">
            <vg-button v-if="can('PLAN_INTERACTIF.DELETE_MARKER')"
                size="md"
                type="danger"
                @click="emitDeleteMarker">
                {{ $t("delete-marker") }}
            </vg-button>
            <vg-button v-if="can('PIECE.UPDATE') && existInDatabase"
                size="md"
                type="danger"
                @click="openUpdateLieuForm">
                {{$t("update-piece")}}
            </vg-button>
            <vg-button v-if="can('EQUIPEMENT.CREATE') && existInDatabase"
                size="md"
                type="success"
                @click="openCreateEquipementForm">
                {{$t("creer-un-equipement")}}
            </vg-button>
        </div>
    </div>
    <div v-else class="popup-piece-marker">
        {{$t("marker-a-supprimer")}}
        <vg-button v-if="isCreationMode"
            :size="'sm'"
            :type="'danger'"
            @click="emitDeleteMarker">
            <i class="fas fa-trash-alt"></i>
        </vg-button>
    </div>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import RolesMixins from "src/mixins/RolesMixins.js";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import LibelServicesMixins from "src/mixins/LibelServicesMixins.js";
import Metadatas from "src/services/Metadatas.js";
export default Vue.extend({
    name:"popup-piece-marker",
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgLieuPathViewer,
        VgCategorieSelector,
        VgServiceSelector
    },
    mixins:[ RolesMixins, CategorieMixins, LibelServicesMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "voir-lieu": "Voir pièce",
            "faire-une-di": "Faire une demande d'intervention",
            "libel-lieu": "Libellé lieu",
            "qrcode": "QRCODE",
            "lieu-non-creer": "Pièce non créée",
            "piece": "Pièce",
            "marker-a-supprimer": "A supprimer",
            "maintenances-en-cours": "Fiches curatives non résolues dans cette pièce",
            "creer-un-equipement": "Créer un équipement dans cette pièce",
            "categorie": "Catégorie",
            "service": "Service",
            "update-piece": "Modifier pièce",
            "delete-marker": "Supprimer marqueur"
        },
        "en": {
            "voir-lieu": "See room",
            "faire-une-di": "Request intervention",
            "libel-lieu": "location label",
            "qrcode": "QRCODE",
            "lieu-non-creer": "Room not created",
            "piece": "Room",
            "marker-a-supprimer": "To delete",
            "maintenances-en-cours": "Unresolved curative sheets in this room",
            "creer-un-equipement": "Add an equipment in this room",
            "categorie": "Category",
            "service": "Service",
            "update-piece": "Update room",
            "delete-marker": "Delete marker"
        }
    }
},
	props: {
        lieu:{
            type: Object
        },
        geoJsonPoint: {
            type: Object
        },
        isCreationMode: {
            type: Boolean,
            default: false
        },
        /**
        * router
        * this.$router
        */
        router: {
            type: Object,
            default: null
        },
        pieces: {
            type: Array,
            default: function(){
                return [];
            }
        },
        services:{
            type: Array,
            default: function(){
                return [];
            }
        }
	},
    data: function(){
        return {
            showUpdateMarker: false,
            updatedLieu: null,
            categories: []
        };
    },
    created: function(){
        
    },
    methods:{
        onShowUpdateMarker: function(){
            this.updatedLieu = Object.assign({}, this.lieu);
            this.showUpdateMarker = true;
        },
        emitUpdateMarker: function(){
            this.$emit("update-marker", {lieu: this.lieu, geoJsonPoint: this.geoJsonPoint, updatedLieu: this.updatedLieu});
        },
        emitDeleteMarker: function(){
            this.$emit("delete-marker", {lieu: this.lieu, geoJsonPoint: this.geoJsonPoint});
        },
        toZoomLieu: function(){
            this.router.push({name:'_lieu_id', params: {id: this.lieu.id}});
        },
        toDemandeIntervention: function(){
            this.router.push({name: "_demande_intervention", params: {code: this.lieu.codeUn, isScann: true}});
        },
        openCreateEquipementForm: function(){
            this.$emit("open-equipement-form", {lieu: this.lieu, geoJsonPoint: this.geoJsonPoint});
        },
        openUpdateLieuForm: function(){
            this.$emit("open-update-lieu", {lieu: this.lieu, geoJsonPoint: this.geoJsonPoint});
        },
    },
    computed: {
        existInDatabase: function(){
            return this.lieu && this.pieces && this.pieces.findIndex((piece)=>piece.id==this.lieu.id)!=-1;
        }
    }
});
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.popup-piece-marker{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    gap: 10px;
    font-size: 12px !important;
    .title{
        margin-bottom: 10px;
    }
    .link{
        text-decoration: underline;
        color: #1e90ff;
        margin: 5px;
        cursor: pointer;
    }
    .info{
        font-style:italic;
        margin: 5px;
    }
    .content-update{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
}
.maintenance-en-cours{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    img{
        width: 15px;
        height: 15px;
    }
}
</style>
