// Base store module

export default class BaseStore {
    getState(){
        return {
            collection: [],
            counters: {},
            selectedItem: null,
            selectedItems: [],
            /**
            * @exemple { foo: Metadatas, bar: Metadatas }
            *
            */
            metadatas:{}
        };
    }
    getGetters(){
        return {
            getCollection: function( state ){
                return state.collection;
            },
            getCounters: function( state ){
                return state.counters;
            },
            getSelectedItem: function( state ){
                return state.selectedItem;
            },
            getSelectedItems: function( state ){
                return state.selectedItems;
            },
            getMetadatas:function(state){
                return state.metadatas;
            },
            getSelectedItemById: (state) => (id) => {
                return state.selectedItems.find(selectedItem => selectedItem.id === id)
              }
        };
    }
    getActions(){
        return {
            set: function( context, values ){
                context.commit('SET_COLLECTION', values);
            },
            setMetadatas:function(context, value){
                context.commit('SET_METADATAS', value)
            },
            addMetadatasObject:function(context, value){
                context.commit('ADD_METADATAS_OBJECT',value);
            },
            setCounters: function( context, counters){
                context.commit('SET_COUNTERS', counters);
            },
            setSelectedItem: function( context, item ){
                context.commit('SET_SELECTED_ITEM', item);
            },
            setSelectedItems: function( context, items ){
                context.commit('SET_SELECTED_ITEMS', items);
            },
            addSelectedItems: function( context, items ){
                context.commit('ADD_SELECTED_ITEMS', items);
            },
            /**
            * supprime un ensemble d'items dans le tableau des selectedItems
            */
            removeSelectedItems: function( context, items ){
                context.commit('REMOVE_SELECTED_ITEMS', items);
            },
            deleteSelectedItem: function( context ){
                context.commit('DELETE_SELECTED_ITEM');
            },
            deleteSelectedItems: function( context ){
                context.commit('DELETE_SELECTED_ITEMS');
            },
            addItem: function( context, item ){
                context.commit('ADD_ITEM', item);
            },
            addItems: function( context, items ){
                context.commit('ADD_ITEMS', items);
            },
            deleteItem: function( context, id ){
                context.commit('DELETE_ITEM', id);
            },
            updateItem: function( context, item ){
                context.commit('UPDATE_ITEM', item);
            },
            updateItems: function( context, items ){
                context.commit('UPDATE_ITEMS', items);
            },
            clearCollection: function( context ){
                context.commit('CLEAR_COLLECTION');
            },
            clear: function( context ){
                context.commit('CLEAR_STORE');
            }
        };
    }
    getMutations(){
        return {
            SET_COLLECTION: function(state, values){
                state.collection = values;
            },
            SET_METADATAS:function(state, metadatas){
                state.metadatas = metadatas;
            },
            ADD_METADATAS_OBJECT:function(state, metadatas){
                if(metadatas.isStorable()){
                    state.metadatas[metadatas.getName()] = metadatas;
                }
            },
            SET_COUNTERS: function(state, counters){
                state.counters = counters;
            },
            SET_SELECTED_ITEM: function(state, item){
                state.selectedItem = item;
            },
            SET_SELECTED_ITEMS: function(state, items){
                state.selectedItems = items;
            },
            ADD_SELECTED_ITEMS: function(state, items){
                // Filter out items that are not already in selectedItems
                let itemsToAdd = items.filter((item) =>
                    item.id && (!state.selectedItems || state.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id) === -1)
                );
                // Initialize selectedItems if it's null
                if (!state.selectedItems) state.selectedItems = [];
                // Add items to selectedItems using push
                state.selectedItems.push(...itemsToAdd);

                console.log("ADD_SELECTED_ITEMS", state.selectedItems);
            },
            REMOVE_SELECTED_ITEMS: function(state, items){
                state.selectedItems = state.selectedItems.filter((selectedItem)=>items.findIndex((item)=>item.id==selectedItem.id)==-1);
            },
            DELETE_SELECTED_ITEM: function(state){
                state.selectedItem = null;
            },
            DELETE_SELECTED_ITEMS: function(state){
                state.selectedItems = [];
            },
            ADD_ITEM: function(state, item){
                let indexItem = state.collection.findIndex((itemCollection)=>itemCollection.id==item.id);
                if(indexItem==-1) state.collection.unshift(item); // si item n'existe pas alors l'ajouter
                else state.collection[indexItem] = Object.assign(state.collection[indexItem], item); // sinon update l'item
            },
            ADD_ITEMS: function(state, items){
                items.forEach((item)=>{
                    let indexItemInCollection = state.collection.findIndex((element)=>element.id==item.id);
                    if(indexItemInCollection!=-1) state.collection[indexItemInCollection] = item;
                    else state.collection.unshift(item);
                });
            },
            INCREMENT_COUNTER: function( state, payload ){
                state.counters[payload.name] = parseInt(state.counters[payload.name]) + payload.qty;
            },
            DECREMENT_COUNTER: function( state, payload ){
                state.counters[payload.name] = parseInt(state.counters[payload.name]) - payload.qty;
            },
            DELETE_ITEM: function( state, id ){
                let index = state.collection.findIndex((item)=>item.id==id);
                state.collection.splice(index, 1);
            },
            UPDATE_ITEM: function(state, updatedItem){
                let hasCollection = state.collection.length;
                let hasSelectedItem = state.selectedItem != null;
                if( hasCollection ){
                    let foundItemIndex = state.collection.findIndex((item)=>item.id==updatedItem.id);
                    let itemExists = foundItemIndex != -1;
                    if(itemExists){
                        state.collection[foundItemIndex] = Object.assign(state.collection[foundItemIndex], updatedItem);
                    }else{
                        // add item in collection ?
                    }
                }
                if(hasSelectedItem){
                    let isCorrectSelectedItem = state.selectedItem && state.selectedItem.id==updatedItem.id;
                    if(isCorrectSelectedItem){
                        state.selectedItem = Object.assign(state.selectedItem, updatedItem);
                    }
                }
            },
            UPDATE_ITEMS: function(state, updatedItems){
                let hasCollection = state.collection.length;
                console.log("HAS COLLECTION", hasCollection);
                if( hasCollection ){
                    let foundItemIndex = -1;
                    updatedItems.forEach((updatedItem)=>{
                        foundItemIndex = state.collection.findIndex((item)=>item.id==updatedItem.id);
                        console.log("foundItemIndex", foundItemIndex);
                        if(foundItemIndex!=-1) state.collection[foundItemIndex] = Object.assign({}, state.collection[foundItemIndex], updatedItem);
                        console.log("after found item index", state.collection[foundItemIndex]);
                        if(state.selectedItem && state.selectedItem.id==updatedItem.id) state.selectedItem = Object.assign({}, state.selectedItem, updatedItem);
                        console.log("after update selectedItem");
                    });
                }
            },
            CLEAR_COLLECTION: function(state){
                state.collection = [];
            },
            CLEAR_STORE: function(state){
                state.collection = [];
                state.counters = {};
                state.selectedItem = null;
                state.selectedItems = [];
                state.metadatas = {};
            }
        };
    }
}
