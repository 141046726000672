<template>
    <div>
        <div
            class="vg-input-categorie-selector"
            v-if="!onlyModal && !readMode"
            @click="openModal = true">
            <div v-if="categorieSelect && categorieSelect.length == 0"> {{placeholder?placeholder:$t("placeholder")}}</div>
            <div class="multiple" v-for="categorie in categorieSelect" v-else>
                {{categorie.libelleCatgorie}}
                <span v-if="categorieSelect &&categorieSelect.length > 1">, </span>
            </div>
            <img src="static/assets/icone/fleche-fine-bas.png" height="15" />
        </div>
        <div v-if="!onlyModal && readMode">
            <span v-if="categorieSelect.length == 0"> {{placeholder?placeholder:$t("placeholder")}}</span>
            <span class="multiple" v-for="categorie in categorieSelect" v-else>
                {{categorie.libelleCatgorie}}
                <span v-if="categorieSelect.length > 1">, </span>
            </span>
        </div>
        <vg-modal v-if="openModal" class="vg-categorie-selector"
            @save="validCategorieSelect"
            @close="closeModal"
            :isSaveDisabled="disabledSave"
            :title="$t('title')">
            <template #body>
              <div class="vg-categorie-selector-search">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="text" name="" v-model="queryString" :placeholder="$t('search-query-placeholder')">
              </div>
              <div class="list-container">
                <div v-for="categorie in getCategorieList" @click="handleSelectCategorie(categorie)"  :class="{ 'list-categorie':true, 'active': categorie.isactive}">
                  <div class="color-categorie">
                    <div :style="{'background-color': categorie.codeCouleur}" ></div>
                  </div>

                  <div class="icone-categorie">
                    <img :src="categorie.icon" height="15" onerror="this.style.visibility='hidden'">

                  </div>
                  <div  class="label-categorie" >

                    <span> C{{categorie.idAccount}} -</span> {{categorie.libelleCatgorie}}

                  </div>

                  <div class="img-categorie">
                    <!--img :src="categorie.uri" height="20" onerror="this.style.visibility='hidden'"-->
                    <vg-files-thumbnail :file="getFile(categorie)"
                        :heightImg="'20px'">
                    </vg-files-thumbnail>
                    <!-- <div v-else class="no-file">
                        <i class="fas fa-2x fa-ban"></i>
                    </div> -->
                  </div>
                </div>
              </div>

          </template>
        </vg-modal>
    </div>



</template>

<script>

import VgFilesThumbnail from 'src/components/Vg/Files/VgFilesThumbnail.vue';

export default {

  name:"vg-categories-selector",
  components:{

    VgFilesThumbnail
  },
  props:{
    categorieList : {
      type: Array,
      default: []
    },
    value:{
        type: Array,
        default:function(){
            return [];
        }
    },
    multiplechoice:{
        type:Boolean,
        default:false,
    },
    onlyModal:{
        type:Boolean,
        default:false,
    },
    idlistReturn:{
        type:Boolean,
        default:false
    },
    readMode:{
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: null
    }
  },
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Choisir une categorie equipement",
            "placeholder": "Selectionner une categorie",
            "search-query-placeholder": "Rechercher..."
        },
        "en": {
            "title": "Choose a category",
            "placeholder": "Select a category"
        },
        "th": {
            "title": "เลือกหมวดหมู่",
            "placeholder": "เลือกหมวดหมู่"
        }
    }
},
    mounted: function() {
        //console.log(typeof this.value === "string");
        //console.log(typeof this.value);

        if (typeof this.value === "object") {
            this.categorieSelect = [];
            this.categorieSelect = this.categorieList.filter((categorie)=>this.value.includes(categorie.id));

        }
        else{

            var categorieSearch = this.categorieList.find(categorie => categorie.id == this.value);
            this.categorieSelect.push(categorieSearch);
        }
    },
  data: function(){
    return{
      categorieSelect:this.multiplechoice? [] : null,
      queryString:'',
      openModal:this.onlyModal,
      // categorieList: [
        // {
        //   id: "1791",
        //   libelleCatgorie: "Bouteille",
        //   marque: "",
        //   typeCategorie: "25CL;33CL;50CL;75CL;1L",
        //   idAccount: "5",
        //   codeCouleur: "#1ac74b",
        //   uid: "5a9fce35ec77e",
        //   tags: "Stockage",
        //   icon: "",
        //   prixDefault: "3.75",
        //   miseEnServiceDefault: "2018-01-01 00:00:00",
        //   tauxDepreciationAnnuelDefault: "5",
        //   uri: "/users/A7X1zGO9nK/5a9fce35ec77e/Photo/bottle.jpg"
        // },
        // {
        //   id: "1801",
        //   libelleCatgorie: "Cuve de Brassage",
        //   marque: "Brewferm",
        //   typeCategorie: "Electrique;Thermique",
        //   idAccount: "6",
        //   codeCouleur: "#ab2ac6",
        //   uid: "5a9fcf4d14e2c",
        //   tags: "Cuve",
        //   icon: "",
        //   positionEtiquette: "",
        //   prixDefault: "140",
        //   miseEnServiceDefault: "2018-01-01 00:00:00",
        //   tauxDepreciationAnnuelDefault: "3",
        //   uri: "/users/A7X1zGO9nK/5a9fcf4d14e2c/Photo/kettle.jpg"
        // }
      // ]
    }
  },
  computed:{
    getCategorieList:function(){
			var links = this.categorieList;
			var results = this.queryString ? links.filter(this.createFilter(this.queryString)) : links;
			return results;
		},
    disabledSave:function(){
        var isEmpty = false;
        if (this.categorieSelect[0] ) {
            isEmpty = false;
        }
        else{
            isEmpty = true;
        }
        return isEmpty;
    }
  },
  methods:{
    getFile: function(categorie){
        return {
            uploadedTo: categorie.uid,
            name: categorie.documentName
        };
    },
    handleSelectCategorie: function(data){
        var isCategorieSelectActive = false;

        if (this.multiplechoice) {

            for (var pos = 0; pos < this.categorieList.length; pos++) {
              if (data.isactive) {
                // si identique supprime l'objet et deselectionne
                isCategorieSelectActive = true;
                this.categorieSelect.splice(pos,1);
                data.isactive = false;

              }
            }
            if (!isCategorieSelectActive) {
              // rajoute un objet
              data.isactive = true;
              this.categorieSelect.push(data);

            }

        }
        else{
            this.categorieSelect = [] ;
            this.categorieSelect.push(data) ;
            for (var pos = 0; pos < this.categorieList.length; pos++) {
              this.categorieList[pos].isactive = false;
            }
            data.isactive = true;
        }

      this.$forceUpdate();

      //// //console.log("%c data :", "color: rgb(191, 126, 226)", this.categorieList);
      //// //console.log("%c data :", "color: rgb(191, 126, 40)", data.isactive);
      // utilisé id des categorieselect et data
    },
    validCategorieSelect: function(){
        this.$emit('input', this.idlistReturn? this.categorieSelect.map( (categorie) => categorie.id ): this.categorieSelect );
        this.$emit('categorie-selector-confirm', this.idlistReturn? this.categorieSelect.map( (categorie) => categorie.id ): this.categorieSelect  );
        if (!this.onlyModal) {
            this.openModal = false;
        }
    },
    closeModal: function(){
        if (this.onlyModal) {
            this.openModal = true;
        }
        else{
            this.openModal = false;
        }
        this.$emit('close');

    },
    createFilter: function(queryString) {
        return (link) => {
          // // //console.log(link);
          //// //console.log(link.libelleCatgorie.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          return (link.libelleCatgorie.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    },
  },


}
</script>

<style lang="scss" scoped>
    .no-file{
        color: #e0e0e1;
    }
    .vg-input-categorie-selector{
        // text-align: center;
        border: 1px solid #d6d1ce;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 0 10px;
        color: #d6d1ce;
        cursor: pointer;
    }
    .vg-input-categorie-selector:hover{

    }
  .vg-categorie-selector-search{
    margin-bottom: 5px;
    margin-top: -10px;

    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #e9e9e9;
    i & .fa{
      width: 2%;
      color:#626262;
    }
    input{
      width: 90%;
      border: none;
      border-bottom: 1px solid white;
      padding-left: 20px;
      padding-bottom: 0;
      height: 30px;
      font-size: 15px;
      color:#626262;
      &:focus{
        border:none;
        height: 35px;
        // border-bottom: 1px solid #b5b5b5;
        box-shadow: 0 0 0 0 lightgrey;
        color:black;
      }
    }
  }

  .list-container{
    height: calc(100% - 30px);
    overflow-y: scroll;
    /* width */

  }
  .list-categorie{
    width: 100%;
    display: flex;
    padding: 5px;
    padding-top:15px;
    border-bottom : 2px solid #e9e9e9;
    &.active{
      background-color:rgb(217, 217, 217);
    }
    .color-categorie{
      width: 15%;
      align-self:center;
      float: left;
      >div{
        width: 25px;
        height: 25px;
        margin:auto;
        border-radius: 20px;
      }
    }
    .icone-categorie{
      width: 10%;
      align-self:center;

      float: left;
      img{
        margin:0;
      }
    }
    .label-categorie{
      width: 60%;
      font-size: 13px;
      float: left;
      span{
        font-size: 11px;
        margin-right: 1px;
      }

    }
    .img-categorie{
        width: 15%;
        float: left;
        align-self:center;

        text-align: center;
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
