<template>
	<div class="reponse-boolean">
		<div class="reponse">
			<div class="icon"></div>
			<img v-if="reponse.checkpoint.composant_id && reponse.checkpoint.composant" :src="reponse.checkpoint.composant.icon" @click="onClickCheckReponse()"/>
			<span @click="onClickCheckReponse()">{{reponse.checkpoint.question}}</span>
			<vg-button v-if="reponse.reponse && reponse.reponse != 'Oui'" size="xs" type="info" @click="onClickReponse()">
                {{$t('commenter')}}
            </vg-button>
		</div>
		<div class="check" @click="onClickCheckReponse()">
			<img v-if="!reponse.reponse || reponse.reponse=='Oui'" src="/static/assets/icone/verif-conforme.png"/>
			<img v-else src="/static/assets/icone/verif-non-conforme.png"/>
		</div>
	</div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import moment from "moment";
import VgButton from "src/components/Vg/VgButton"
export default {
    name: 'reponse-boolean',
    mixins:[],
    components:{
        VgButton
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "commenter": "Commenter"
        },
        "en": {
            "commenter": "Comment"
        }
    }
},
    props:{
        tache: Object,
		reponse: Object

    },

    watch : {
        reponse: {
			handler: function(value){
				// //console.log("REPONSE WATCHER", value);
				this.$emit('change', value);
			},
			deep: true
		},
    },
    data: function() {
        return {

        };

    },
    computed: {

    },
    methods: {
        onClickReponse: function(){
			//this.onClickCheckReponse();
			// //console.log("REPONSE", this.reponse);
			this.reponse.reponse = "Non";
			this.reponse.date_reponse = moment().format("YYYY-MM-DD HH:MM:ss");
			this.$emit('select', this.reponse);
		},
		onClickCheckReponse: function(){
			if(this.reponse.reponse=="Oui") this.reponse.reponse = "Non";
			else if(this.reponse.reponse=="Non") this.reponse.reponse = "Oui";
			else this.reponse.reponse = "Non";
			this.reponse.date_reponse = moment().format("YYYY-MM-DD HH:MM:ss");
			this.$emit('change', this.reponse);
		},
    },
    created: function(){

    },
    mounted: function(){
		console.log("ICON COMPOSANT", this.reponse.checkpoint);
    }
};
</script>
<style lang="scss" scoped>
	.reponse-boolean{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #d7d7d9;
		.reponse{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			.icon{
				width:3px;
				height: 14px;
				background-color: rgb(243, 111, 58);
			}
			img{
				width:20px;
				height:20px;
			}
			span{
				color: #74767a;
				font-size: 16px;
			}
			.complement{
				color: lightblue;
			}
		}
		.check{
			img{
				width:20px;
				height:20px;
			}
		}
	}
</style>
