<template>
	<vg-modal
        @close="$emit('close')">
        <template #header>
			<span>{{$t("envoi-commande")}}: <b>{{bonDeCommande.numero}}</b></span>
        </template>
        <template #body>
			<br>
			<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
				<span style="font-size:16px;font-weight:bold;">{{bonDeCommande.fournisseur.name}}</span>
				<span style="margin:20px 0 20px 0;">{{$t("envoyer-commande-a")}}: </span>
				<vg-contacts-selector v-model="destinataire"
					:filters="contactsFilters"
					:attributeValue="null"/>
				<vg-checkbox style="margin:40px 0 20px 0;font-weight:normal !important;"
					:label="$t('envoyer-une-copie')"
					@checked="onCheckSendCopy"
					@unchecked="onUncheckSendCopy"
					/>
				<vg-users-selector v-model="destinataireCC"
					:attributeValue="null"/>
				<vg-bon-de-commande-pdf-viewer v-show="false"
					@generated-pdf-datas="getGeneratedPdfDatas"/>
				<span v-if="signatureEnCours" style="color: #50b659;margin-top: 10px;">{{ $t("signature-en-cours") }}</span>
				<span v-if="!signatureEnCours && envoiEnCours" style="color: #50b659;">{{ $t("envoi-de-l-email") }}</span>
			</div>
        </template>
        <template #footer>
            <vg-button @click="$emit('close')">
                {{$t("btn-cancel")}}
            </vg-button>
            <vg-button type="danger"
				@click="skipSendedBonDeCommande">
                {{$t("btn-skip-sended")}}
            </vg-button>
            <vg-button :type="'danger'"
				:disabled="disabledEnvoyerBonDeCommande"
				@click="envoyerBonDeCommande">
                {{$t("btn-confirm")}}
            </vg-button>
            <vg-button :type="'danger'"
				:disabled="disabledEnvoyerBonDeCommande || signatureEnCours"
				@click="signerEtEnvoyerBonDeCommande">
                {{$t("btn-signature-confirm")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

	import VgContactsSelector from "src/components/Vg/Selectors/VgContactsSelector.vue";
	import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";

	import VgBonDeCommandePdfViewer from "src/components/Vg/BonDeCommande/VgBonDeCommandePdfViewer.vue";

	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

    import { mapGetters } from 'vuex';

    export default {
        name: 'vg-bon-de-commande-envoi-commande',
		components: {
			VgModal,
			VgButton,
			VgCheckbox,
			VgContactsSelector,
			VgUsersSelector,
			VgBonDeCommandePdfViewer
		},
		mixins: [ BonsDeCommandeMixins ],
    	props: {
			base64Logo: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "envoi-commande": "Envoi commande",
            "envoyer-commande-a": "Envoyer le bon de commande en fichier PDF joint à",
            "envoyer-une-copie": "Envoyer une copie à",
            "btn-confirm": "Confirmer l'envoi",
            "btn-signature-confirm": "Signer le BC et confirmer l'envoi",
            "btn-cancel": "Annuler",
			"signature-en-cours": "Signature en cours...",
			"envoi-de-l-email": "Envoi de l'email en cours...",
			"btn-skip-sended": "Ne pas envoyer et mettre en attente de livraison"
        },
		"en": {
			"envoi-commande": "Send Order",
			"envoyer-commande-a": "Send the purchase order as an attached PDF file to",
			"envoyer-une-copie": "Send a copy to",
			"btn-confirm": "Confirm Sending",
			"btn-signature-confirm": "Sign the PO and confirm sending",
			"btn-cancel": "Cancel",
			"signature-en-cours": "Signing in progress...",
  			"envoi-de-l-email": "Sending email in progress...",
			"btn-skip-sended": "Do not send and put on hold"
		}
    }
},
        data: function() {
            return {
				destinataire: null,
				destinataireCC: null,
				sendCC: false,
				contactsFilters:{
					tiers_id: {attr:"c.tiers_id", value:null, action:"equals"}
				},
				pdfDatas: null,
				envoiEnCours: false,
				signatureEnCours: false,
				base64Signature: null,
                defaultCheckedCommentairePdf: false
            };
        },
		created: function(){
			this.contactsFilters.tiers_id.value = this.bonDeCommande.fournisseur.id;
			this.getLocalStorageValues();
		},
		mounted: function(){

		},
		methods: {
			getLocalStorageValues: function(){
                if(localStorage.getItem("bon-de-commande-commentaire-dans-pdf")) this.defaultCheckedCommentairePdf = localStorage.getItem("bon-de-commande-commentaire-dans-pdf")==="true";
            },
			signerEtEnvoyerBonDeCommande: function(){
				this.signatureEnCours = true;
				// @TODO signer BC
				// récupérer signature
				this.VgFilesMixins_getBase64UserSignature().then((base64Signature)=>{
					// apposer signature sur PDF
					this.base64Signature = base64Signature;
					this.envoyerBonDeCommande();
				});
				//this.envoyerBonDeCommande();
			},
			skipSendedBonDeCommande: function(){
				this.envoiEnCours = true;
				this.BonsDeCommandeMixins_skipSending(this.bonDeCommande).then((datas)=>{
					this.$emit("save", datas);
				});
			},
			envoyerBonDeCommande: function(){
				this.envoiEnCours = true;
				this.BonsDeCommandeMixins_envoiCommande(this.bonDeCommande, this.destinataire, this.sendCC?this.destinataireCC:null, this.pdfDatas).then((datas)=>{
					this.$emit("save", datas);
				});
			},
			getGeneratedPdfDatas: function(datas){
				this.pdfDatas = datas;
				if(this.signatureEnCours){
					this.envoyerBonDeCommande();
				}
			},
			onCheckSendCopy: function(){
				this.sendCC = true;
			},
			onUncheckSendCopy: function(){
				this.sendCC = false;
			},
		},
        computed: {
			...mapGetters({
                bonDeCommande: 'BonsDeCommandeStore/getSelectedItem'
            }),
			disabledEnvoyerBonDeCommande: function(){
				return this.destinataire==null || this.envoiEnCours;
			},
			disabledSignerEtEnvoyerBonDeCommande: function(){
				return this.signatureEnCours;
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
