<template>
    <vg-user-role-viewer v-model="role"/>
</template>

<script>
import Vue from "vue";
import VgUserRoleViewer from "src/components/Vg/User/VgUserRoleViewer";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"user-role-cell-render",
    mixins:[LangMixins],
    components:{
        VgUserRoleViewer
    },
	props: {
		role: {
			type: String,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {

        },
        "en": {

        }
    }
},
    methods:{

    }
});
</script>

<style scoped>

</style>
