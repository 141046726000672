<template>
    <vg-app-layout v-show="!$vgutils.isMobile() && !isPieceTargeted "
        :title="$t('header-title')"
        :icon="'contrat.png'"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        @remove-filters="onRemoveFilters"
        @action-export="actionExport"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters">
        <template #create-button>
            <vg-button type="success" @click="showModalCreateContrat=true;">{{$t("header-btn-creerContrat")}}</vg-button>
        </template>

        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>

        <template #primary>
            <vg-tabs v-if="contrats"
                :tabs="tabs"
                :color="'gris'"
                @tabClicked="handleTabClick" />
            <vg-contrats-table
                :searchQuery="searchQuery"
                :filters="contratsFilters"
                @fetch-success="onFetchContratsSuccess" />
        </template>

        <template #secondary-filters>
            <vg-filter-site
                v-model="agfilters.field_path.value" 
                @input="onChangeFilterSite"/>
            <vg-filter-categorie
                v-model="agfilters.field_categorie.value" />
            <vg-filter-tiers v-if="$app.role!='ROLE_SOUS_TRAITANT'"
                v-model="agfilters.field_tiers_id.value" />
        </template>

        <template #secondary-widget>
            <vg-tree-lieux
                v-model="agfilters.field_path.value"/>
        </template>

        <vg-contrat-form v-if="showModalCreateContrat"
            @close="showModalCreateContrat=false;"
            @updated="showModalCreateContrat=false;"
            @created="showModalCreateContrat=false;" />

    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgContratsTable from 'src/components/Vg/Contrat/VgContratsTable.vue';
import VgContratForm from 'src/components/Vg/Forms/VgContratForm.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgButton from 'src/components/Vg/VgButton.vue';
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgGroupFilters from "src/components/Vg/Utils/VgGroupFilters.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterCategorie from "src/components/Vg/Filters/VgFilterCategorie.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";

import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import FiltersMixins from 'src/mixins/FiltersMixins';

export default {
    name:'contrats',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-title": "Contrats tiers",
            "header-btn-creerContrat": "Créer contrat",
            "onglet-contrat-courant": "Tous",
            "onglet-contrat-expire": "Expirés",
            "filter-types": "Type de contrat",
            "filter-actif": "Contrat actif",
            "onglet-controle": "Contrôle réglementaire",
            "onglet-entretient-periodique": "Entretien périodique",
            "onglet-verification-periodique": "Vérification périodique",
            "onglet-maintenance": "Maintenance",
            "onglet-autre": "Autres"
        },
        "en": {
            "header-title": "Third party contracts",
            "header-btn-creerContrat": "Create contract",
            "onglet-contrat-courant": "Current contracts",
            "onglet-contrat-expire": "Expired contracts",
            "filter-tiers": "Suppliers",
            "filter-types": "Contract",
            "filter-actif": "Current contract",
            "onglet-controle": "Regulatory control",
            "onglet-entretient-periodique": "Periodic maintenance",
            "onglet-verification-periodique": "Periodic verification",
            "onglet-maintenance": "Maintenance",
            "onglet-autre": "Others"
        },
        "th": {
            "header-title": "สัญญา",
            "header-btn-creerContrat": "สร้างสัญญา",
            "onglet-contrat-courant": "สัญญาปัจจุบัน",
            "onglet-contrat-expire": "สัญญาที่หมดอายุ",
            "filter-tiers": "ซัพพลายเออร์",
            "filter-types": "สัญญา",
            "filter-actif": "สัญญาปัจจุบัน",
            "onglet-controle": "การควบคุมตามกฎระเบียบ",
            "onglet-entretient-periodique": "การบำรุงรักษาตามระยะเวลา",
            "onglet-verification-periodique": "การตรวจสอบตามระยะเวลา",
            "onglet-maintenance": "การบำรุงรักษา",
            "onglet-autre": "อื่น ๆ"
        }
    }
},
    mixins:[FiltersMixins],
    components:{
        TagGrid,
        VgContratForm,
        VgTabs,
		VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgCollapse,
        VgGroupFilters,
        VgCheckbox,
        VgFilterSite,
        VgFilterCategorie,
        VgTreeLieux,
        ActionMenuAggrid,
        VgFilterTiers,
        VgContratsTable
    },
    props:{
        defaultFilters:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data:function(){
        return {
            showModalCreateContrat:false,
            contrats: [],
            activeTab:'courant',
            types:[],
            searchQuery: "",
            agfilters: {
                type: {attr:"c.type", value: null, action: "contains", openParenthesis: true},
                name: {attr:"c.name", value: null, action: "contains", logicalOperator: "OR"},
                number: {attr:"c.number", value: null, action: "contains", logicalOperator: "OR"},
                tags: {attr:"c.tags", value: null, action: "contains", logicalOperator: "OR"},
                tiers_name: {attr:"t.name", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
                field_type: {attr:"c.type", value: null, action: "equals"},
                field_endDate: {attr:"c.endDate", value: null, action:"less_than"},
                field_path: {attr:"path", value: null, action:"contains", openParenthesis: true},
                field_path_null: {attr:"path", value: null, action:"is_null", closeParenthesis: true, logicalOperator: "OR"},
                field_categorie: {attr:"cat.id", value: null, action:"contains"},
                field_tiers_id: {attr: "c.tiers_id", value: null, action: "contains"}
            },
            counters:{
                courant: 0,
				expire: 0,
                controle: 0,
                entretien : 0,
                verification : 0,
                maintenance : 0,
                autre : 0
            }
        }
    },
    watch:{
        activeTab: function(newValue,oldValue){
            this.activeTab = newValue;
            if(newValue != "expire" && this.agfilters.field_endDate.value ) this.agfilters.field_endDate.value = null;
        },
		searchQuery: {
			handler: function(query){
				this.agfilters.type.value = query;
				this.agfilters.name.value = query;
				this.agfilters.number.value = query;
				this.agfilters.tags.value = query;
				this.agfilters.tiers_name.value = query;
                this.FiltersMixins_saveSearchQuery(query);

			}
		}
    },
	computed:{
        pageFilters: function(){
            return ["field_path", "field_path_null", "field_categorie", "field_tiers_id"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            return activeFilters.length;
        },
        contratsFilters : function(){
            return this.agfilters;
        },
		tabs: function(){
			var counters = this.counters;
			return [
				{label: this.$t("onglet-contrat-courant"), name:"courant", counter: counters.courant, filters:[
                    {"attr":"c.type","colId": "type", "value": null, "action": "equals"}
                ]},
				{label: this.$t("onglet-controle"), name:"controle", counter: counters.controle, filters:[
                    {"attr":"c.type","colId": "type", "value": "Controle réglementaire", "action": "equals"}
                ]},
				{label: this.$t("onglet-entretient-periodique"), name:"entretient", counter: counters.entretien, filters:[
                    {"attr":"c.type","colId": "type", "value": "Entretien périodique", "action": "equals"}
                ]},
				{label: this.$t("onglet-verification-periodique"), name:"verification", counter: counters.verification, filters:[
                    {"attr":"c.type","colId": "type", "value": "Verification periodique", "action": "equals"}
                ]},
				{label: this.$t("onglet-maintenance"), name:"maintenance", counter: counters.maintenance, filters:[
                    {"attr":"c.type","colId": "type", "value": "Maintenance curative", "action": "equals"}
                ]},
				{label: this.$t("onglet-autre"), name:"autre", counter: counters.autre, filters:[
                    {"attr":"c.type","colId": "type", "value": "Autre", "action": "equals"}
                ]},
				{label: this.$t("onglet-contrat-expire"), name:"expire", counter: counters.expire, filters:[
                    {"attr":"c.type","colId": "type", "value": null, "action": "equals"},
                    {"attr":"endDate", "colId": "endDate", "value": moment().format("YYYY-MM-DD"), "action":"less_than"}
                ]}
			];
		},
        hasActiveGlobalFilters: function(){
            let filters = this.agfilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        }
	},
    methods: {
        onChangeFilterSite: function(datas){
            this.agfilters.field_path_null.value = this.agfilters.field_path.value?1:null;
        },
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.agfilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.agfilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.agfilters = filters;
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.agfilters = Object.assign({}, this.agfilters, filters);
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        setCounters: function(){
            var contrats = this.contrats;
			var counters = {
				courant: contrats.length,
				expire: 0,
                controle: contrats.filter(contrat => contrat.type == "Controle reglementaire").length,
                entretien : contrats.filter(contrat => contrat.type == "Entretien périodique").length,
                verification : contrats.filter(contrat => contrat.type == "Verification periodique").length,
                maintenance : contrats.filter(contrat => contrat.type == "Maintenance curative").length,
                autre : contrats.filter(contrat => contrat.type == "Autre").length
			};
			var today = moment();
			if(contrats) contrats.forEach(function(contrat){
				if(today.diff(moment(contrat.endDate, "YYYY-MM-DD"), 'days')>0 ) counters.expire++;
			});
			this.counters = counters;
        },
        onFetchContratsSuccess: function(datas){
            console.log("SUCCESS", datas);
            this.contrats = datas.datas;
            this.setCounters();
        },
        actionExport: function(event){
            window.dispatchEvent(new CustomEvent(event.name, {
                "detail": {"idTableau":"vg-contrats-table"}
            }));
        },
        handleTabClick: function(event) {
          this.activeTab = event.name;
          // pas top à revoir les indices en dur
          if(event.name == "expire"){
              this.agfilters.field_type = event.filters[0];
              this.agfilters.field_endDate = event.filters[1];
          }else{
            this.agfilters.field_type = event.filters[0];
          }

        }
    },
    created: function(){
        this.FiltersMixins_page_name = "contrats";
        
        this.agfilters = Object.assign({}, this.agfilters, this.defaultFilters);
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();

    }
}
</script>

<style lang="scss">

</style>
