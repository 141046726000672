<template>
    <span :class="{'oui': isValid, 'non': !isValid}"
		data-toggle="tooltip"
		:title="tooltipTitle">
		{{ isValid? $t("Oui"): $t("Non")}}
    </span>
</template>

<script>
    import Vue from "vue";
	import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"oui-non-cell-render",
		mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
			isValid: {
				type: Boolean,
				required: true,
				default: true
			},
			tooltipTitle: {
				type: String,
				required: true,
				default: "default tooltip title"
			}
		},

    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "Oui": "Oui",
            "Non": "Non"
        },
        "en": {
            "Oui": "Yes",
            "Non": "No"
        },
        "th": {
            "Oui": "ใช่",
            "Non": "ไม่"
        }
    }
} ,
    });
</script>

<style scoped>
    span{
		padding: 0px 5px;
		font-size: 10px;
	}
	.oui{
		border: solid 1px green;
		color: green;
	}
	.non{
		border: solid 1px red;
		color: red;
	}
</style>
