<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:limit="limit"
		:limitSelectorText="$t('limit-selector-text')"
		:defaultDateIntervalle="defaultDateIntervalle"
		key="dashboard-curatif-rapport-repartition-consommations-maintenances"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<vg-bar-chart v-if="repartition"
			class="chart-bar"
			:chart-id="'dashboard-curatif-repartition-consommations-maintenances'"
			:height="'100%'"
			:datas="getDatas"
			:horizontalaxis="xAxis"
			:options="optionsBar"
			:showlegend="false"
			@selected="onSelectedBar"/>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-consommables-repartition-consommations-maintenances',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport,
			VgButton
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},
				xAxis: [],
				optionsBar: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 8,
								precision: 0
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks:{
								mirror: true,
								fontSize: 10,
								callback: (label, index, labels)=>{
									if(labels.length>1){
										let labelSplitted = label.split(" ");
										let result = [];
										let encoreDeLaPlace = null;
										let graphWidth = document.getElementById("dashboard-curatif-repartition-consommations-maintenances").width;
										let barWidth = graphWidth/labels.length;
										let pxParCaractere = 4; // 4 px réservé par caractère
										let generalMaxCaractere = labels.length>=7?15:20;
										let nombreMaxCaractereInline = barWidth/pxParCaractere<=generalMaxCaractere?barWidth/pxParCaractere:generalMaxCaractere;
										labelSplitted.forEach((item, i) => {
											if(item.length>=nombreMaxCaractereInline){	// si mot trop long
												if(encoreDeLaPlace) result.push(encoreDeLaPlace);
												result.push(item);
												encoreDeLaPlace = null;
											}else{ // sinon mot plus court
												if(encoreDeLaPlace){
													if(encoreDeLaPlace.length+1+item.length<=nombreMaxCaractereInline){
														encoreDeLaPlace += " "+item;
													}else{
														result.push(encoreDeLaPlace);
														encoreDeLaPlace = item;
													}
												}else{
													encoreDeLaPlace = item;
												}
											}
										});
										if(encoreDeLaPlace) result.push(encoreDeLaPlace);
										return result;
									}else return label;
								}
							}
						}]
					},
					animation: {
						easing: "linear",
						onProgress: (e)=>this.drawCustomBarElements(e),
						onComplete: (e)=>this.drawCustomBarElements(e)
					}
				},
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "REPARTITION des consommations de la maintenance",
            "bar-hover-label": "Nombre de consommations",
            "limit-selector-text": "Consommables affichés",
            "precedents": "Voir les {limit} précédents",
            "suivants": "Voir les  {limit} suivants"
        },
        "en": {
            "title": "DISTRIBUTION of maintenance consumptions",
            "bar-hover-label": "Number of consumptions",
            "limit-selector-text": "Consumables displayed",
            "precedents": "View previous {limit}",
            "suivants": "View next {limit}"
        }
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			onSelectedBar: function(bar){
				let barSelected = this.repartition[bar.index];
				let filters = {
					consommable_id: {attr: "cm.consommable_id", value: barSelected.consommable_id, action: "equals"},
					flag: {attr: "cm.flag", value: "consommation-fm", action: "equals"}
				};
				this.$emit("selected", {bar: barSelected, filters: filters, dateIntervalle: this.dateIntervalle});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				if(this.dateIntervalle){
					filters.dateMouvement = {attr: "cm.dateMouvement", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				}
				this.metadatas.setFilters(filters);
				if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getConsommablesRepartitionConsommationsMaintenances(this.metadatas).then((datas)=>{

				});
			},
			drawCustomBarElements: function(e){
				let ctx = e.chart.ctx;
				let datasets = e.chart.config.data.datasets;
				let meta = null;
			},
		},
        computed: {
			...mapGetters({
	            repartition: 'DashboardStore/getConsommablesRepartitionConsommationsMaintenances',
	        }),
			getDatas: function(){
				let bar = {
					label: this.$t("bar-hover-label"),
					value: "consommables",
					backgroundColor: "#87b0dd",
					borderColor: "#87b0dd",
					borderWidth: 1,
					data: [],
					stack: "consommables",
					hoverBackgroundColor: "#87b0dd",
					hoverBorderColor: "#80b6de",
					hoverBorderWidth: 1,
					filters: []
				};
				let datas = [];
				let xAxis = [];
				this.repartition.forEach((consommable)=>{
					xAxis.push(consommable.consommable_name);
					datas.push(consommable.quantite_total);
				});
				this.xAxis = xAxis;
				bar.data = datas;
				return [bar];
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
