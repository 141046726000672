<template>
	<vg-select v-model="selectedContrat"
		:options="getContrats"
		:attributeLabel="'name'"
		:attributeValue="attributeValue"
		:clearable="isClearable"
		@input="$emit('input',selectedContrat)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import ContratsMixins from "src/mixins/ContratsMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-contrat-selector',
		components: {
			VgSelect
		},
		mixins: [ContratsMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet tiers à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			},
			/**
			* idTiers
			*/
			idTiers: {
				type: String | Number,
				default: null
			}
        },
        data: function() {
            return {
				selectedContrat: this.value,
				metadatasContrats: new Metadatas(),
				agfilters: {}
            };
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedContrat = val;
				}
			}
		},
		created: function(){
			this.setAgFilters();
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			setAgFilters: function(){
				//if(this.idTiers) this.agfilters.tiers_id = { attr: "c.tiers_id", colId: "tiers_id", value: this.idTiers, action: "equals" };
				if(this.$app.role=="ROLE_SOUS_TRAITANT") this.agfilters.tiers_id = { attr: "c.tiers_id", colId: "tiers_id", value: this.$app.tiers_id, action: "equals" };
			},
			fetch: function(){
				this.metadatasContrats.setFilters(this.agfilters);
				this.ContratsMixins_getContrats(this.metadatasContrats).then((datas)=>{

				});
			}
		},
        computed: {
			...mapGetters({

            }),
			getContrats: function(){
				if(this.idTiers || this.$app.role=="ROLE_SOUS_TRAITANT"){
					return this.$store.getters["ContratsStore/getContratsByIdTiers"](this.$app.role=="ROLE_SOUS_TRAITANT"?this.$app.tiers_id:this.idTiers);
				}else return this.$store.getters["ContratsStore/getCollection"];
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
