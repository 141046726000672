<template>
	<span :class="['vg-bon-de-commande-statut-viewer', value.statut]">
		{{$t(value.statut)}}
	</span>
</template>
<script>
    export default {
        name: 'vg-bon-de-commande-statut-viewer',
    	props: {
            value: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "draft": "En création/Mode brouillon",
            "demande-validation": "Demande de validation",
            "validation-partielle": "Validation partielle",
            "validation-complete": "Validation complète",
            "canceled": "Annulé",
            "rejected": "Refusé",
            "sended": "Commande envoyée et en cours",
            "delivered": "Commande livrée",
            "skip-sending": "En attente de livraison",
            "partially-delivered": "Commande livrée partiellement"
        }
    }
}
    };

</script>
<style lang="scss" scoped>
.vg-bon-de-commande-statut-viewer{
	color: white;
	padding: 3px 5px;
	font-size: 12px;
}
.draft{
	background-color: #BDBDBD;	/*gris*/
}
.demande-validation{
	background-color: #FFB300;	/*jaune*/
}
.validation-partielle{
	background-color: #C0CA33;	/*jaune-vert*/
}
.validation-complete{
	background-color: #4CAF50;	/*vert*/
}
.canceled{
	background-color: #8D6E63;	/*marron*/
}
.rejected{
	background-color: #F44336;	/*rouge*/
}
.sended{
	background-color: #26C6DA;	/*bleu clair*/
}
.skip-sending{
	background-color: #26C6DA;	/*bleu foncé*/
}
.delivered{
	background-color: #1E88E5;	/*bleu foncé*/
}
.partially-delivered{
	background-color: #4DB6AC;	/*bleu clair-foncé*/
}
</style>
