<template>
    <span @click="openFile">
        <i class="fas fa-file-pdf" style="margin-right:3px;"></i> {{params.data.name}}
    </span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"file-link-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isAutomaticOpenLink: {
                type: Boolean,
                default: true
            }
		},
        methods:{
            openFile: function(){
				this.VgFilesMixins_getDownloadFileLink(this.params.data).then((link)=>
                    this.isAutomaticOpenLink ? window.open(link, '_blank') : this.$emit("click-link", link)
                );
			}
        }
    });
</script>

<style lang="scss" scoped>
    span{
        color: #007dff;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
</style>
