<template>
    <vg-app-layout
        :title="$t('page-title-mobile')"
        :icon="'intervention.png'"
        :colorheader="getHeaderColor"
        isZoomLayout
        :isActionButtonDisplay="false"
        @backpage="backPage()">

        <template #create-button>
            <vg-button @click="exportPdf">{{ $t("exporter-pdf") }}</vg-button>
                <vg-button
                    v-if="intervention && intervention.status!='intervention-realisee'"
                    :type="'default-danger'"
                    @click="showInterventionForm=true">
                    {{$t("modifier")}}
                </vg-button>
            <vg-button
                :type="'info'"
                @click="showAttacherDocument=true">
                {{$t("attacher-document")}}
            </vg-button>
            <vg-button v-if="intervention && intervention.status!='intervention-realisee'"
                style="margin-left:20px;"
                :type="'success'"
                @click="onShowValidateIntervention">
                {{$t("valider-intervention")}}
            </vg-button>
        </template>

        <template #primary v-if="!$vgutils.isMobile() && intervention">
            <vg-collapse :title="$t('section-details')"
                :collapseByDefault="false"
                :type="'section'">
                <template #pretitle>

                </template>
                <template #content>
                    <vg-libelle-editable :label="$t('numero')">
                        <template #read>
                            <vg-intervention-numero-viewer :intervention="intervention" style="font-weight: bold;"/>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('intitule')">
                        <template #read>
                            <span >
                                <b> {{intervention.intitule}} </b>
                            </span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.tiers" :label="$t('par')">
                        <template #read>
                            <span>{{intervention.tiers.name}}</span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.contrat" :label="$t('contrat')">
                        <template #read>
                            <span >{{intervention.contrat.name}}</span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.site" :label="$t('site')">
                        <template #read>
                            <span>{{ intervention.site.libel_lieu }}</span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('status')">
                        <template #read>
                            <vg-intervention-statut-viewer :intervention="intervention" />
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('prevue-le')">
                        <template #read>
                            <vg-datetime-viewer v-model="intervention.datePrevisionnelleDebut"/> -
                            <vg-datetime-viewer v-model="intervention.datePrevisionnelleFin" />
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="!intervention.dateEffectiveDebut" :label="$t('confirme')">
                        <template #read>
                            <span>{{ intervention.confirmed }}</span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.dateEffectiveDebut" :label="$t('effectuee-le')">
                        <template #read>
                            <vg-datetime-viewer v-model="intervention.dateEffectiveDebut"/> -
                            <vg-datetime-viewer v-model="intervention.dateEffectiveFin" onlyTime/>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.dateValidation" :label="$t('validation-le')">
                        <template #read>
                            <vg-datetime-viewer v-model="intervention.dateValidation"/>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.validateur" :label="$t('validateur')">
                        <template #read>
                            {{ intervention.validateur.nom }} {{ intervention.validateur.prenom }}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('commentaire')">
                        <template #read>
                            <span>{{ intervention.commentaire }}</span>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable v-if="intervention.nbReservesNonLevees" :label="$t('nb-reserves-non-levees')">
                        <template #read>
                            <span>{{ intervention.nbReservesNonLevees }}</span>
                        </template>
                    </vg-libelle-editable>
                </template>
            </vg-collapse>

            <vg-collapse :title="$t('section-documents')"
                :collapseByDefault="false"
                :type="'section'">
                <template #pretitle>

                </template>
                <template #content>
                    <vg-files-viewer-list
                        v-model="getDocuments">
                    </vg-files-viewer-list>
                </template>
            </vg-collapse>

    </template>
    <template #primary v-else-if="intervention">
            <div v-if="intervention" style="width:100%;">
                <div class="section-details">
                    <div class="title">
                        <img src="static/assets/icone/contrat.png"/>
                        <span v-if="intervention.contrat"><b>{{intervention.contrat.name}}</b></span>
                    </div>
                    <div class="content">
                        <div class="left">
                            <div>
                                <span>{{$t("prevue-le")}}: </span>
                                <vg-datetime-viewer
                                    v-model="intervention.datePrevisionnelleDebut">
                                </vg-datetime-viewer>
                            </div>
                            <div v-if="intervention.dateEffectiveDebut">
                                <span>{{$t("effectuee-le")}}: </span>
                                <vg-datetime-viewer
                                    v-model="intervention.dateEffectiveDebut">
                                </vg-datetime-viewer>
                            </div>
                            <div>
                                <span>{{$t("par")}}: </span>
                                <span v-if="intervention.tiers">{{intervention.tiers.name}}</span>
                            </div>
                        </div>
                        <div class="right">
                            <span v-if="intervention && intervention.status=='intervention-realisee'" class="statut-validee">{{$t("validee")}}</span>
                            <span v-else class="statut-non-validee">{{$t("non-validee")}}</span>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="section section-commentaire">
                    <span>{{$t("commentaire-previsionnel")}}</span>
                    <div>
                        <span>{{intervention.commentaire}}</span>
                    </div>
                </div>
                <div class="section section-rapport">
                    <span>{{$t("rapport-intervention")}}</span>
                    <div>
                        <vg-files-viewer-document v-if="getRapport"
                            :file="getRapport">
                        </vg-files-viewer-document>
                        <span v-else>{{$t("aucun-rapport-intervention")}}</span>
                    </div>
                </div>
                <div class="section section-documents">
                    <span>{{$t("documents-intervention")}}</span>
                    <div>
                        <vg-files-viewer-list
                            v-model="getDocuments">
                        </vg-files-viewer-list>
                    </div>
                </div>
                <div class="section section-equipements">
                    <span>{{$t("equipements-concernes")}}</span>
                    <div class="equipements-container" v-if="displayEquipements && intervention.interventionsEquipements && intervention.interventionsEquipements.length>0">
                        <div v-for="interventionequipement,index in intervention.interventionsEquipements" :key="index" class="equipement-row">
                            <div class="equipement">
                                <img :src="interventionequipement.equipement.categorie.icon" height="20">
                                <span>{{interventionequipement.equipement.libel_equipement}}</span>
                            </div>
                            <vg-button
                                :type="'default-link'"
                                @click="onRemoveInterventionEquipement(interventionequipement)">
                                {{$t("retirer")}}
                            </vg-button>
                        </div>
                    </div>
                    <div class="equipements-container default-text" v-else>
                        {{$t("tous-equipements-contrat")}}
                    </div>
                </div>
                <div v-if="intervention.status=='intervention-realisee'" class="section section-reserves">
                    <span>{{$t("reserves")}}</span>
                    <vg-reserves-viewer
                        :intervention="intervention">
                    </vg-reserves-viewer>
                </div>
                <div class="section section-photos">
                    <span>{{$t("photos")}}</span>
                    <vg-files-viewer-photo v-if="getPhotos"
                        :files="getPhotos"
                        style="height:200px;margin-bottom:50px;">
                    </vg-files-viewer-photo>
                </div>
            </div>
        </template>
        <template #secondary v-if="intervention">
            <vg-collapse 
                :title="$t('equipements-concernes')"
                :type="'section'"
                :collapseByDefault="false">
                <template #content>
                    <div class="equipements-container" v-if="displayEquipements && intervention.interventionsEquipements && intervention.interventionsEquipements.length>0">
                        <div v-for="interventionequipement,index in intervention.interventionsEquipements" :key="index" class="equipement-row">
                            <div class="equipement">
                                <img :src="interventionequipement.equipement.categorie.icon" height="20">
                                <span>{{interventionequipement.equipement.libel_equipement}}</span>
                            </div>
                            <vg-button v-if="can('INTERVENTION.DELETE_ASSOCIATED_EQUIPEMENT')"
                                :type="'default-link'"
                                @click="onRemoveInterventionEquipement(interventionequipement)">
                                {{$t("retirer")}}
                            </vg-button>
                        </div>
                    </div>
                    <div class="equipements-container default-text" v-else>
                        <span v-if="intervention.contrat_id">{{$t("tous-equipements-contrat")}}</span>
                        <span v-else>{{$t("tous-equipements")}}</span>
                    </div>
                </template>
            </vg-collapse>
                   <br>
            <vg-collapse v-if="intervention.status=='intervention-realisee'"
                :title="$t('reserves')"
                :type="'section'"
                :collapseByDefault="false">
                <template #content>
                    <vg-reserves-viewer
                        :intervention="intervention">
                    </vg-reserves-viewer>
                </template>
            </vg-collapse>
                   <br>
            <vg-collapse
                :title="$t('photos')"
                :type="'section'"
                :collapseByDefault="false">
                <template #content>
                    <vg-files-viewer-photo v-if="getPhotos"
                        :files="getPhotos"
                        style="height:200px;margin-bottom:50px;">
                    </vg-files-viewer-photo>
                </template>
            </vg-collapse>
        </template>

        <template v-slot:footer v-if="$vgutils.isMobile() && isUserIsAuthorized">
            <vg-button v-if="intervention && intervention.status!='intervention-realisee'"
            :size="'sm'"
            :type="'default-link'"
            @click="onShowValidateIntervention">
            {{$t("valider-intervention")}}
            </vg-button>
            <vg-button v-if="intervention && intervention.status!='intervention-realisee'"
            :size="'sm'"
            :type="'default-link'"
            @click="showInterventionForm=true">
            {{$t("modifier")}}
            </vg-button>
            <vg-button
            :size="'sm'"
            :type="'default-link'"
            @click="onScannEquipement">
            <i class="fas fa-qrcode"></i> {{$t("scanner-equipement")}}
            </vg-button>
        </template>
        <vg-intervention-validation v-if="showValidateIntervention"
            v-model="intervention"
            @close="showValidateIntervention=false;"
            @save="onSaveValidation">
        </vg-intervention-validation>
        <vg-modal v-if="showAttacherDocument"
            :title="$t('attacher-document')"
            @close="showAttacherDocument=false">
            <template v-slot:body>
                <vg-files-uploader
                    :tagsList="VgFilesMixins.tags"
                    @upload-done="onUploadFileDone">
                </vg-files-uploader>
            </template>
            <template v-slot:footer>
                <vg-button
                    @click="showAttacherDocument=false;">
                    {{$t("close")}}
                </vg-button>
            </template>
        </vg-modal>
        <vg-modal v-if="showScanner"
            :title="$t('title-scanner-equipement')"
            @close="showScanner=false;"
            >
            <template v-slot:body>
                <zxing-scanner
                    @scanSuccess="onScanSuccess"
                    :isDemo="false"
                    :code="'qrcode'">
                </zxing-scanner>
            </template>
        </vg-modal>
        <vg-intervention-form v-show="showInterventionForm"
            :isPeriodique="true"
            @close="showInterventionForm=false;"
            @save="showInterventionForm=false;">
        </vg-intervention-form>
    </vg-app-layout>
</template>

<script>

    import InterventionsMixins from "src/mixins/InterventionsMixins.js";
    import TagsMixins from "src/mixins/TagsMixins.js";
    import equipementsMixins from "src/mixins/equipementsMixins.js";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';

    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgFilesViewerDocument from "src/components/Vg/Files/VgFilesViewerDocument.vue";
    import VgFilesViewerList from "src/components/Vg/Files/VgFilesViewerList.vue";
    import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
    import VgInterventionValidation from "src/components/Vg/Interventions/VgInterventionValidation.vue";
    import VgReservesViewer from "src/components/Vg/Reserves/VgReservesViewer.vue";
    import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
    import VgInterventionStatutViewer from "src/components/Vg/Interventions/VgInterventionStatutViewer.vue";
    import VgInterventionNumeroViewer from "src/components/Vg/Interventions/VgInterventionNumeroViewer.vue";

    import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'intervention',
        components: {

            VgFilesUploader,
            VgButton,
            VgInput,
            VgLibelleEditable,

            VgCollapse,
            VgDatetimeViewer,
            VgFilesViewerDocument,
            VgFilesViewerList,
            VgFilesViewerPhoto,
            VgInterventionValidation,
            VgReservesViewer,
            zxingScanner,
            VgInterventionForm,
            VgInterventionStatutViewer,
            VgInterventionNumeroViewer
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "section-details":"Details",
            "contrat": "Contrat",
            "intitule": "Intitule",
            "numero": "Numéro",
            "site": "Site concerné",
            "status": "Statut",
            "confirme": "Est confirmée?",
            "commentaire": "Commentaire",
            "validation-le": "Validation le",
            "validateur": "Validateur",
            "par": "par",
            "le": "le",
            "nb-reserves-non-levees": "Nombre de réserves non levées",
            "section-documents": "Documents",
            "page-title-mobile": "Détails intervention externe",
            "valider-intervention": "Valider intervention",
            "scanner-equipement": "Scanner équipement",
            "prevue-le": "Prévue le",
            "effectuee-le": "Effectuée le",
            "par": "Par",
            "non-validee": "Non validée",
            "validee": "Validée",
            "commentaire-previsionnel": "Commentaire prévisionnel",
            "rapport-intervention": "Rapport intervention",
            "documents-intervention": "Documents intervention",
            "equipements-concernes": "Equipements concernés",
            "photos": "Photos",
            "reserves": "Réserves",
            "aucun-rapport-intervention": "Aucun rapport d'intervention",
            "title-scanner-equipement": "Scanner un équipement",
            "aucun-equipement": "Aucun équipement associé au qrCode {qrCode}.",
            "equipement-pas-intervention": "L'équipement {libelEquipement} ne fait pas partit de cette intervention. Veuillez scanner un {libelleCatgorie}.",
            "retirer": "Retirer",
            "tous-equipements-contrat": "Tous les équipements du contrat",
            "tous-equipements": "Tous les équipements",
            "attacher-document": "Attacher document",
            "exporter-pdf": "éditer un rapport",
            "close": "Fermer"
        },
        "en": {
            "section-details": "Details",
            "contrat": "Contract",
            "intitule": "Title",
            "numero": "Number",
            "site": "Site concerned",
            "status": "Status",
            "confirme": "Is confirmed?",
            "commentaire": "Comment",
            "validation-le": "Validation on",
            "validateur": "Validator",
            "par": "by",
            "le": "on",
            "nb-reserves-non-levees": "Number of unresolved reservations",
            "section-documents": "Documents",
            "page-title-mobile": "External intervention's details",
            "valider-intervention": "Validate intervention",
            "scanner-equipement": "Equipment scan",
            "prevue-le": "Expected at",
            "effectuee-le": "done at",
            "par": "By",
            "non-validee": "Not validated",
            "validee": "Validated",
            "commentaire-previsionnel": "Comment",
            "rapport-intervention": "Intervention report",
            "documents-intervention": "Intervention Documents",
            "equipements-concernes": "Affected equipments",
            "photos": "Photos",
            "aucun-rapport-intervention": "No intervention report",
            "reserves": "Reservations",
            "title-scanner-equipement": "Scan equipment",
            "aucun-equipement": "No equipment attached to the qrCode {qrCode}.",
            "equipement-pas-intervention": "This equipment {libelEquipement} is not part of this intervention. Scan a {libelleCatgorie}.",
            "retirer": "Remove",
            "tous-equipements-contrat": "All contract equipment",
            "tous-equipements": "All equipments",
            "attacher-document": "Attach document",
            "exporter-pdf": "Get a report",
            "close": "Close"
        },
        "th": {
            "page-title-mobile": "รายละเอียดการแทรกแซงที่เกิดซ้ำ",
            "valider-intervention": "ตรวจสอบการแทรกแซง",
            "scanner-equipement": "การสแกนอุปกรณ์",
            "prevue-le": "คาดการณ์เมื่อ",
            "effectuee-le": "ทำแล้วเมื่อ",
            "par": "โดย",
            "non-validee": "ยังไม่ได้ตรวจสอบ",
            "validee": "ตรวจสอบแล้ว",
            "commentaire-previsionnel": "ความคิดเห็น",
            "rapport-intervention": "รายงานการแทรกแซง",
            "documents-intervention": "เอกสารการแทรกแซง",
            "equipements-concernes": "อุปกรณ์ที่ได้รับผลกระทบ",
            "photos": "รูปภาพ",
            "aucun-rapport-intervention": "ไม่มีรายงานการแทรกแซง"
        }
    }
},
		mixins:[
            InterventionsMixins,
            TagsMixins,
            equipementsMixins
        ],
        watch: {
            intervention: {
                handler: function(value){
                    console.log("WATCH INTERVENTION Intervention.vue", value);
                },
                deep: true
            }
        },
        data: function() {
            return {
                showValidateIntervention: false,
                showScanner: false,
                rapport: null,
                showInterventionForm: false,
                displayEquipements:true,
                metadatasEquipements: new Metadatas(),
                agfilters: {
                    "qrCode": {attr:"qrCode",value:null,action:"equals"}
                },
                showAttacherDocument: false
            }
        },
        methods:{
            exportPdf(){
                this.InterventionsMixins_getPdfFile(this.$route.params.id , "Rapport_intervention_externalisé");
            },
            onUploadFileDone: function(data){
                this.VgFilesMixins_fetchFilesByUid();
                if(!this.intervention.documents) this.intervention.documents = [];
                this.intervention.documents.unshift(data);
                this.showAttacherDocument=false;
            },
            onRemoveInterventionEquipement: function(interventionEquipement){
                //console.log("onRemoveInterventionEquipement", interventionEquipement);
                this.InterventionsMixins_deleteInterventionEquipement(interventionEquipement).then((data)=>{
                    //console.log("AFTER DELETE INTERVENTION EQUIPEMENT", data);
                    this.displayEquipements = false;
                    this.displayEquipements = true;
                });
            },
            /**
	        * Fetch tous les tags.
	        */
	        fetchTags: function(){
	            this.TagsMixins_get(new Metadatas()).then((datas)=>{
	                //console.log("TAGS", datas);
	            });
	        },
            backPage: function(){
                this.$store.dispatch("InterventionsStore/deleteSelectedItem");
                this.$router.go(-1);
            },
            onShowValidateIntervention: function(){
                this.showValidateIntervention = true;
            },
            onScannEquipement: function(){
                this.showScanner = true;
            },
            onScanSuccess: function(code){
                //console.log("SCANN", code);
                this.agfilters.qrCode.value = code;
                this.metadatasEquipements.setFilters(this.agfilters);
				this.equipementsMixins_getEquipements(this.metadatasEquipements).then((equipements)=>{
					//console.log("EQUIPEMENT SCANNED", equipements);
                    this.showScanner = false;
                    if(equipements.datas.length!=0){
                        let equipement = equipements.datas[0];
                        if(equipement.idCategorie==this.intervention.contrat.categorie.id){
                            this.InterventionsMixins_createInterventionsEquipements(this.intervention.id, [equipement]).then((datas)=>{
                                //console.log("INTERVENTIONS EQUIPEMENTS", datas); 
                                this.displayEquipements = false;
                                this.displayEquipements = true;
                            });
                        }else alert(this.$t("equipement-pas-intervention", {
                            libelEquipement: equipement.libelEquipement,
                            libelleCatgorie: this.intervention.contrat.categorie.libelleCatgorie
                        }));
                    }else alert(this.$t("aucun-equipement",{qrCode: code}));
				});
            },
            onSaveValidation: function(){
                this.showValidateIntervention = false;
                this.fetch();
            },
            fetch: function(){
                this.InterventionsMixins_getIntervention(this.$route.params.id).then((intervention)=>{
                    //console.log("INTERVENTION", intervention);
                    this.VgFilesMixins_init(intervention.uid, "intervention");
                });
            }
        },
		created: function(){
            this.fetchTags();
            this.fetch();
        },
		mounted: function(){

        },
        computed:{
            ...mapGetters({
                intervention: 'InterventionsStore/getSelectedItem',
                documentsInterventionTags: "TagsStore/getDocumentsInterventionTags"
            }),
            getPhotos: function(){
                if(this.documentsInterventionTags && this.intervention.documents){
                    let photoInterventionTag = this.documentsInterventionTags.find((tag)=>tag.label=="photo" || tag.label=="Photo");
                    if(photoInterventionTag) return this.intervention.documents.filter((doc)=>doc.tags_id==photoInterventionTag.id);
                }
                return null;
            },
            getRapport: function(){
                if(this.documentsInterventionTags && this.intervention.documents){
                    let rapportInterventionTag = this.documentsInterventionTags.find((tag)=>tag.label=="rapport-intervention" || tag.label=="Rapport intervention");
                    if(rapportInterventionTag) return this.intervention.documents.find((doc)=>doc.tags_id==rapportInterventionTag.id);
                }
                return null;
            },
            getDocuments: function(){
                return this.intervention.documents;
            },
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#66cc00' : 'white';
            },
            isUserIsAuthorized: function(){
                return this.$app.role=="ROLE_ADMIN" || this.$app.role=="ROLE_SOUS_TRAITANT";
            }
        },
        destroyed: function(){
            this.$store.dispatch("InterventionsStore/setSelectedItem", null);
        }
    };
</script>
<style lang="scss" scoped>
.section{
    margin-bottom: 5px;
}
.section>span{
    font-size: 11px;
    color: #a6a5a3;
}
.section>div{
    height: 50px;
    overflow: auto;
}
.section-details{
    .title{
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        img{
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
    .content{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 10px;
            >div{
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                >span:first-child{
                    width: 80px;
                    color: #a6a6a6;
                }
            }

        }
        .right{
            margin-left: 5px;
            font-size: 10px;
            color: white;
            >*{
                padding: 2px 4px;
            }
            .statut-validee{
                background-color: green;
            }
            .statut-non-validee{
                background-color: red;
            }
        }
    }

}
.section-equipements{
    .default-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .equipements-container{
        height: 100px;
        border: 1px solid #dfdfdf;
        .equipement-row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0 5px 5px;
            .equipement{
                display:flex;
                justify-content:flex-start;
                align-items: center;
                >img{
                    margin-right: 10px;
                }
            }
        }
    }
}
</style>
