<template>
    <vg-operation-flag
        v-model="operation">
    </vg-operation-flag>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "flag-operation-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgOperationFlag
        },
        data: function() {
            return {
				operation: this.params.data
			};
        },
    });
</script>

<style scoped>

</style>
