import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
let actions = Base.getActions();
let mutations = Base.getMutations();
state.tags = [];
getters.getTags = function(state){
    return state.tags;
};
actions.setTags = function(context, tags){
    context.commit('SET_TAGS', tags);
};
mutations.SET_TAGS = function(state, tags){
    state.tags = [...tags];
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
