<template>
    <vg-app-layout
        :title="$t('header-title')"
        :icon="'equipement.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :importable="false"
        @remove-filters="onRemoveFilters">

        <template #search>
            <vg-text-filter v-model="searchQuery" />
        </template>

        <template #create-button>
            <vg-date-picker-shortcuts
                opens="left"
                :defaultStartDate="initialDateIntervalleRange.startDate"
                :defaultEndDate="initialDateIntervalleRange.endDate"
                @select="onChangeDateIntervalle"
                @finish-selection="onFinishDateSelection" />
        </template>

        <template #secondary-filters>
            
        </template>

        <template #header-bottom-left>
            
        </template>

        <template #primary>
            <vg-synthese-maintenance-preventive-table
                :filters="agfilters" 
                :startDate="initialDateIntervalleRange.startDate"
                :endDate="initialDateIntervalleRange.endDate" />
        </template>

        <template #tools>
            
        </template>
    </vg-app-layout>
</template>

<script>
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgSyntheseMaintenancePreventiveTable from "src/components/Vg/SyntheseMaintenancePreventive/VgSyntheseMaintenancePreventiveTable.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
import FiltersMixins from 'src/mixins/FiltersMixins';

export default {
    name: "synthese-maintenance-preventive",
    components:{
        VgTabs,
		VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgCollapse,
        VgGroup,
        VgSyntheseMaintenancePreventiveTable,
        VgCategorieSelector,
        VgUsersSelector,
        VgDatePickerShortcuts,
        VgFilterTiers,
        VgFilterSite
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-title": "Synthèse mensuelle maintenance préventive"
        },
        "en": {
            "header-title": "Monthly preventive maintenance synthesis"
        }
    }
},
	mixins: [FiltersMixins],
    props:{
        defaultFilters: {
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data:function(){
        return {
            searchQuery: null,
            initialDateIntervalleRange: {
                startDate: moment().format("YYYY-01-01"),
                endDate: moment().format("YYYY-12-31")
            },
            agfilters:{
                
            }
        }
    },
	watch: {
		searchQuery: {
			handler: function(query){
                
			}
		}
	},
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
    },
	computed: {
        pageFilters: function(){
            return [];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            return activeFilters.length;
        },
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgPurple : 'white';
        }
	},
	created: function(){
        this.agfilters = Object.assign({}, this.agfilters, this.defaultFilters);
        this.FiltersMixins_page_name = "synthese-maintenance-preventive";
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
	}
}
</script>
