<template>
    <vg-modal
        :title="$t('title-'+flag)"
        :isSaveDisabled="operation.operation.length<2 || isSaveDisabled"
        @close="closeModal"
        @save="submitOperation">
        <template #body>
            <vg-input class="textOperation" :inputType="'textarea'" v-model="operation.operation"></vg-input>
            <vg-collapse :title="$t('more')" v-if="flag=='tache' || flag=='a_prevoir'">
                <template v-slot:content>
                    <vg-input :title="$t('etiquette')">
                        <vg-tags-selector v-model="tagOperation"
                            :type="getTypeTags"
                            :attributeValue="null">
                        </vg-tags-selector>
                    </vg-input>
                </template>
            </vg-collapse>
        </template>
    </vg-modal>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgTagsSelector from 'src/components/Vg/Selectors/VgTagsSelector.vue';
import MaintenanceMixins from 'src/mixins/MaintenanceMixins.js';
export default {
    name:"vg-operation-create",
    props:{
        flag:{
            type: String,
            default: "manuelle"
        },
        idMaintenance: {
            type: Number,
            required: true
        }
    },
    components:{
        VgInput,
        VgCollapse,
        VgTagsSelector
    },
    mixins:[
        MaintenanceMixins
    ],
    data:function() {
        return{
            operation: {
                idUser: this.$app.idUser,
                operation: "",
                retourClient: null,
                flag: this.flag,
                ficheSav_id: this.idMaintenance
            },
            tagOperation: null,
            isSaveDisabled: false
        }
    },
    watch:{
        flag: {
            handler: function(val){
                this.operation.flag = val;
            }
        },
        idMaintenance: {
            handler: function(val){
                this.operation.ficheSav_id = val;
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-manuelle": "Nouveau commentaire",
            "title-tache": "Sous-action fiche curative",
            "title-a_prevoir": "Matériel à prévoir fiche curative",
            "textearea-operation": "Opération",
            "btn-enreg": "Enregistrer",
            "more": "Données complémentaires",
            "etiquette": "Etiquette"
        },
        "en": {
            "title-manuelle": "New comment",
            "textearea-operation": "Operation",
            "btn-enreg": "Save",
            "title-tache": "Sub-action",
            "title-a_prevoir": "Material to provide",
            "more": "Additional data",
            "etiquette": "Label"
        },
        "th": {
            "title-manuelle": "ความคิดเห็นใหม่",
            "textearea-operation": "ปฏิบัติการ",
            "btn-enreg": "บันทึก"
        }
    }
},
    methods:{
        submitOperation: function(){
            this.isSaveDisabled = true;
            this.operation.dateOperation = moment().format("YYYY-MM-DD HH:mm:ss");
            if(this.operation.flag=="tache" || this.operation.flag=="a_prevoir") this.operation.statut = "en_cours";
            if(this.tagOperation) this.operation.tags = [this.tagOperation];    // pour l'instant bloqué à un tag par opération
            console.log("OPERATION BEFORE POST", this.operation);
            this.MaintenanceMixins_postOperations([this.operation]).then((response)=>{
                console.log("SUCCESS POST OPERATIONS", response);
                this.$emit('save');
            });
        },
        closeModal: function(){
            this.$emit('close');
        }
    },
    computed: {
        getTypeTags: function(){
            if(this.flag=="tache") return ["operation/tache"];
            else if(this.flag=="a_prevoir") return ["operation/a-prevoir"];
            else return null;
        },
    }
}
</script>

<style lang="scss">
.textOperation{
    textarea{
        line-height: 25px !important;
        height: 150px;
    }
}
</style>
