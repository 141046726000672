//Notifications store module

// initial state
const state = {
    isDisplayables : []
};

// getters
const getters = {
    getAllDisplayablesInStore: function( state ){
      return state.isDisplayables;
    },
    getAllDisplayable: function( state ){
        if (typeof(Storage) !== "undefined") {
            // Code for localStorage/sessionStorage.
            return JSON.parse(localStorage.getItem("personalParameters_isdisplayable"));
        } else {
            // Sorry! No Web Storage support..
        }
    },
};

// actions
const actions = {
    addDisplayable:function(context, displayable){
        context.commit('PERSONALPARAMETERS_ADD_DISPLAYABLE', displayable);
    }
};

// mutations
const mutations = {

    PERSONALPARAMETERS_ADD_DISPLAYABLE: function( state, displayable ){
        state.isDisplayables.push( message );
    },

};
export default {
  state,
  getters,
  actions,
  mutations
};