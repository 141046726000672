<template lang="html">
    <vg-app-layout
         :title="title"
         :icon="'equipements.png'"
         :isZoomLayout="true"
         :colorheader="getHeaderColor"
         :isActionButtonDisplay="false"
         @backpage="retourPage">
         <template #create-button>
            <vg-button
                type="default-danger"
                @click="showUpdateConsommable = true">
                {{$t("update")}}
            </vg-button>
         </template>
         <template #primary v-if="consommable">
              <!--vg-button
                  type="danger"
                  size="xs"
                  :disabled="true"
                  @click="deleteConsommable"
                  style="width:70px;">
                  {{$t('supprimer')}}
              </vg-button>
          </br-->
          <vg-collapse :title="$t('section-caracteristiques')"
              :collapseByDefault="false"
              :type="'section'">
                <template #content>
                    <vg-libelle-editable :label="$t('intitule')">
                        <template v-slot:read>
                            <b>{{consommable.name}}</b>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('ref-externe')">
                        <template v-slot:read>
                            <b>{{consommable.refExterne}}</b>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('marque')">
                        <template v-slot:read>
                            <b>{{consommable.marque}}</b>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('code')">
                        <template v-slot:read>
                            <b>{{consommable.code}}</b>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('nature')">
                        <template v-slot:read>
                            {{consommable.nature}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('statut')">
                        <template v-slot:read>
                            {{consommable.statut}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('commentaire')">
                        <template v-slot:read>
                            {{consommable.commentaire}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('fournisseur')">
                        <template v-slot:read>
                            {{getFournisseurDefault}}
                            <!--vg-consommable-fournisseurs
                                :consommableId="$route.params.id"
                                :fournisseurs="fournisseurs"
                                @refresh-fournisseurs="handleRefreshFournisseurs" /--><!-- @TODO composant à revoir -->
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('cout-unitaire')">
                        <template v-slot:read>
                            {{consommable.coutUnitaire}} {{$app.devise}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('tva')">
                        <template v-slot:read>
                            {{consommable.tva?consommable.tva.value:'-'}} %
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('services-restriction')">
                        <template v-slot:read>
                            {{consommable.services}}
                        </template>
                    </vg-libelle-editable>
                </template>
          </vg-collapse>

          <vg-collapse :title="$t('stock')"
              :collapseByDefault="false"
              :type="'section'">
                <template #content>
                    <vg-libelle-editable :label="$t('stock')">
                        <template #read>
                            {{consommable.stock.quantite}} {{consommable.unite?consommable.unite:$t("default-unite")}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('stockMin')">
                        <template #read>
                            {{consommable.stock.quantiteMin}} {{consommable.unite?consommable.unite:$t("default-unite")}}
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('aCommander')">
                        <template #read>
                            <i v-if="Number(consommable.stock.quantite)>Number(consommable.stock.quantiteMin)" class="far fa-square"></i>
                            <i v-else class="far fa-check-square"></i>
                        </template>
                    </vg-libelle-editable>
                    <vg-libelle-editable :label="$t('colisage')" v-if="consommable.conditionnementColisage && consommable.quantiteColisage">
                        <template #read>
                            {{consommable.conditionnementColisage}} {{$t("de")}} {{consommable.quantiteColisage}}
                        </template>
                    </vg-libelle-editable>
                </template>
          </vg-collapse>

          <vg-collapse :title="$t('section-documents')"
              :collapseByDefault="false"
              :type="'section'">
                <template v-slot:pretitle>
                    <div>
                        <vg-button
                            :size="'sm'"
                            :type="'default-link'"
                            @click="isUploaderVisible=!isUploaderVisible;">
                            <i class="fa fa-plus"></i>
                        </vg-button>
                    </div>
                </template>

                <template v-slot:content>
                    <div class="composants__documents-attaches inherit">
                        <vg-files-uploader v-if="isUploaderVisible"
                            :tagsList="VgFilesMixins.tags"
                            @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                        </vg-files-uploader>
                        <vg-files-viewer-list-section
                            :files="VgFilesMixins_filesListSection"
                            @delete="VgFilesMixins_fetchFilesByUid()">
                        </vg-files-viewer-list-section>
                    </div>
                </template>
          </vg-collapse>

          <div v-if="$vgutils.isMobile()">
              <hr>
              <vg-collapse :title="$t('title-consommable-mouvements')"
                  :collapseByDefault="false"
                  :type="'section'">
                  <template #content>
                    <vg-button size="xs" type="info" @click="showModalMouvement.livraison=true;" >
                        <i class="fas fa-plus-square" style="margin-right:5px;"></i>
                        {{$t('livraison')}}
                    </vg-button>
                      <vg-consommable-mouvements-table
                        :filters="consommableMouvementsFilters"
                        paginate/>
                  </template>
              </vg-collapse>
              <br>
              <vg-collapse :title="$t('photos')"
                  :collapseByDefault="false"
                  :type="'section'">
                  <template #content>
                      <vg-files-viewer-photo
                          :files="VgFilesMixins_filesOnlyPhoto" />
                  </template>
              </vg-collapse>
              <br>
              <vg-collapse :title="$t('title-consommable-equipements')"
                  :collapseByDefault="false"
                  :type="'section'">
                  <template #content>
                      <vg-consommables-equipements-table v-if="consommable" />
                  </template>
              </vg-collapse>
          </div>

      </template>

      <template #secondary v-if="consommable">
          <vg-collapse :title="$t('title-consommable-mouvements')"
              :collapseByDefault="false"
              :type="'section'">
              <template #titlecomplement>
                  <vg-button size="xs" type="info" @click="showModalMouvement.livraison=true;" >
                     <i class="fas fa-plus-square" style="margin-right:5px;"></i>
                      {{$t('livraison')}}
                  </vg-button>
                  <vg-button v-if="can('CONSOMMABLE.STOCK_AJUSTEMENT')" size="xs" type="info" @click="showModalMouvement.inventaire=true;" style="margin-left:10px;">
                      <i class="fas fa-minus-square" style="margin-right:5px;"></i>
                      {{$t('ajustement-inventaire')}}
                  </vg-button>
                  <vg-button
                    size="xs" 
                    type="success"
                    @click="exportXls"
                    style="margin-left:5px;"
                  >
                        {{ $t('exporter') }}
                  </vg-button>
              </template>
              <template #content>
                  <vg-consommable-mouvements-table
                      :filters="consommableMouvementsFilters" 
                      paginate />
              </template>
          </vg-collapse>
          <br>
          <vg-collapse :title="$t('title-graphs')"
              :collapseByDefault="false"
              :type="'section'">
              <template #titlecomplement>
                    <vg-date-picker-shortcuts 
                        :key="'consommable-graphs-date-picker-shortcuts'"
                        opens="right"
                        :previousDatesPresets="true"
                        :defaultStartDate="dateIntervalle.startDate"
                        :defaultEndDate="dateIntervalle.endDate"
                        style="width:200px;font-size:12px;color:#333;"
                        @select="onChangeDateIntervalle"
                        @finish-selection="onChangeDateIntervalle" />
              </template>
              <template #content>
                <vg-consommable-mouvements-graphs :dateIntervalle="dateIntervalle"/>
              </template>
          </vg-collapse>
          <br>
          <vg-collapse :title="$t('photos')"
              :collapseByDefault="false"
              :type="'section'">
              <template #content>
                  <vg-files-viewer-photo
                      :files="VgFilesMixins_filesOnlyPhoto" />
              </template>
          </vg-collapse>
          <br>
          <vg-collapse :title="$t('title-consommable-equipements')"
              :collapseByDefault="false"
              :type="'section'">
              <template #content>
                  <vg-consommables-equipements-table v-if="consommable" />
              </template>
          </vg-collapse>
          <br>
          <vg-collapse :title="$t('title-consommable-demandes-consommables')"
              :collapseByDefault="false"
              :type="'section'">
              <template #content>
                  <vg-fiche-demande-consommables-table v-if="consommable" 
                    :filters="ficheDemandeConsommablesFilters"/>
              </template>
          </vg-collapse>
        </template>

        <template #footer v-if="$vgutils.isMobile()">
            <vg-button size="xs" type="default-link" @click="showModalMouvement.livraison=true;" >
               <i class="fas fa-plus-square" style="margin-right:5px;"></i>
                {{$t('livraison')}}
            </vg-button>
            <vg-button size="xs" type="default-link" @click="showModalMouvement.inventaire=true;" >
                <i class="fas fa-minus-square" style="margin-right:5px;"></i>
                {{$t('ajustement-inventaire')}}
            </vg-button>
        </template>

        <vg-consommable-form v-if="showUpdateConsommable"
            @close="showUpdateConsommable=false;"
            @updated="showUpdateConsommable=false;"
            @deleted="showUpdateConsommable=false;$router.go(-1);" />
        <vg-consommable-livraison-modal v-if="showModalMouvement.livraison"
            @close="showModalMouvement.livraison=false;"
            @save="showModalMouvement.livraison=false;" />
        <vg-consommable-inventaire-modal v-if="showModalMouvement.inventaire"
            @close="showModalMouvement.inventaire=false;"
            @save="showModalMouvement.inventaire=false;" />
    </vg-app-layout>
</template>

<script>
import VgConsommableMouvementsTable from "src/components/Vg/Consommables/VgConsommableMouvementsTable.vue";
import VgConsommableMouvementsGraphs from "src/components/Vg/Consommables/VgConsommableMouvementsGraphs.vue";
import VgConsommableLivraisonModal from "src/components/Vg/Consommables/VgConsommableLivraisonModal.vue";
import VgConsommableInventaireModal from "src/components/Vg/Consommables/VgConsommableInventaireModal.vue";

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

import VgConsommableForm from "src/components/Vg/Forms/VgConsommableForm.vue";
import VgConsommableFournisseurs from "src/components/Vg/Consommables/VgConsommableFournisseurs.vue";
import VgConsommablesEquipementsTable from "src/components/Vg/Consommables/VgConsommablesEquipementsTable.vue";

import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import VgDatePickerShortcuts from 'src/components/Vg/VgDatePickerShortcuts.vue';

import VgFicheDemandeConsommablesTable from 'src/components/Vg/FicheDemandeConsommables/VgFicheDemandeConsommablesTable.vue';

import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
	name: 'consommable',
	props:{

	},
    mixins:[ ConsommablesMixins ],
	components:{
        VgFilesUploader,
        VgFilesViewerListSection,
        VgFilesViewerPhoto,
		VgConsommableMouvementsTable,
		VgConsommableMouvementsGraphs,
		VgConsommableLivraisonModal,
		VgConsommableInventaireModal,
        VgConsommableFournisseurs,
        VgConsommablesEquipementsTable,
        VgButton,
        VgCollapse,
        VgLibelleEditable,
        VgDatePickerShortcuts,
        VgConsommableForm,
        VgFicheDemandeConsommablesTable
	},
	data:function(){
		return {
			fournisseurs: null,
			showModalMouvement: {
				livraison: false,
				inventaire: false,
				consommation: false
			},
            showUpdateConsommable: false,
			isUploaderVisible: false,
            dateIntervalle: {
                startDate: moment().format("YYYY-01-01"),
                endDate: moment().format("YYYY-12-31")
            }
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Détail pièce détachée",
            "retour": "Retour vers liste des consommables",
            "section-caracteristiques": "Caractéristiques",
            "section-stock": "Gestion stock",
            "section-alerte": "Gestion alertes",
            "section-financiere": "Gestion financière",
            "section-documents": "Documents attachés",
            "intitule": "Intitulé",
            "nature": "Etiquette consommable/pièce",
            "refExterne": "Référence constructeur",
            "marque": "Marque",
            "coutUnitaire": "Coût unitaire par défaut",
            "stock": "Quantité en stock",
            "stockMin": "Quantité stock minimal",
            "fournisseur": "Fournisseur",
            "cout-unitaire": "Coût unitaire",
            "descriptif": "Descriptif",
            "statut": "Statut",
            "aCommander": "A commander",
            "title-consommable-mouvements": "Mouvements",
            "title-consommable-equipements": "Equipements associés",
            "title-graphs": "Graphiques",
            "commentaire": "Description",
            "livraison": "Livraison",
            "ajustement-inventaire": "Ajustement inventaire",
            "photos": "Photos",
            "services-restriction": "Services restreints",
            "update": "Modifier",
            "ref-externe": "Référence externe",
            "code": "Code-barre / QRcode",
            "tva": "TVA appliquée",
            "title-consommable-demandes-consommables": "Fiche demandes consommables non résolues",
            "default-unite": "u.",
            "de": "de",
            "colisage": "Colisage",
            "exporter": "Export excel"
        },
        "en": {
            "title": "Spare part details",
            "retour": "Back to list of consumables",
            "section-caracteristiques": "Characteristics",
            "section-stock": "Inventory Management",
            "section-alerte": "Alert Management",
            "section-financiere": "Financial management",
            "section-documents": "Documents attached",
            "intitule": "Title",
            "nature": "Consumable/part label",
            "refExterne": "Manufacturer's reference",
            "marque": "Brand",
            "coutUnitaire": "Default unit cost",
            "stock": "Quantity in stock",
            "stockMin": "Minimum stock quantity",
            "fournisseur": "Supplier",
            "cout-unitaire": "Unit cost",
            "descriptif": "Description",
            "statut": "Status",
            "aCommander": "To order",
            "title-consommable-mouvements": "Transactions",
            "title-consommable-equipements": "Associated equipment",
            "title-graphs": "Graphs",
            "commentaire": "Description",
            "livraison": "Delivery",
            "ajustement-inventaire": "Inventory adjustment",
            "photos": "Photos",
            "code": "Barcode / QRcode",
            "title-consommable-demandes-consommables": "Unresolved consumables request list",
            "default-unite": "EA",
            "de": "of",
            "colisage": "Packing",
            "update": "Update",
            "ref-externe": "External reference",
            "tva": "Applied VAT",
            "services-restriction": "Restricted services",
            "exporter": "Export excel"
        },
        "th": {
            "title": "รายละเอียดชิ้นส่วนอะไหล่",
            "retour": "กลับไปยังรายการวัสดุสิ้นเปลือง",
            "section-caracteristiques": "ลักษณะ",
            "section-stock": "การจัดการสินค้าคงคลัง",
            "section-alerte": "การจัดการการแจ้งเตือน",
            "section-financiere": "การจัดการด้านการเงิน",
            "section-documents": "เอกสารที่แนบแล้ว",
            "intitule": "หัวเรื่อง",
            "nature": "วัสดุสิ้นเปลือง/ฉลากชิ้นส่วน",
            "refExterne": "การอ้างอิงของผู้ผลิต",
            "marque": "ยี่ห้อ",
            "coutUnitaire": "ค่าเริ่มต้นต้นทุนต่อหน่วย",
            "stock": "ปริมาณในสต็อก",
            "stockMin": "ปริมาณสต็อกต่ำสุด",
            "fournisseur": "ซัพพลายเออร์",
            "descriptif": "คำอธิบาย",
            "aCommander": "เพื่อสั่งซื้อ",
            "title-consommable-mouvements": "ธุรกรรม",
            "title-consommable-equipements": "อุปกรณ์ที่เกี่ยวข้อง"
        }
    }
},
	created: function(){

	},
	mounted: function(){
        this.fetch();
	},
	methods:{
        onChangeDateIntervalle: function(data){
            console.log("CHANGE DATE INTERVALLE", data);
            this.dateIntervalle = data;
        },
        fetch: function(){
            this.ConsommablesMixins_getConsommable(this.$route.params.id).then((consommable) => {
                this.VgFilesMixins_init(this.consommable.uid, "consommable");
            });
        },
		retourPage: function(){
			this.$router.go(-1);
		},
        exportXls(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.consommableMouvementsFilters);
            this.ConsommablesMixins_ExportConsommables(metadatas,"consommables-mouvements");
        }
  },
	computed:{
        ...mapGetters({
            consommables: 'ConsommablesStore/getCollection',
            consommable: 'ConsommablesStore/getSelectedItem',
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant"
        }),
        consommableMouvementsFilters: function(){
            return [];
        },
        ficheDemandeConsommablesFilters: function(){
            return {
                consommable_id: {attr: "fdci.consommable_id", value: this.consommable.id, action: "equals"},
                statut: {attr: "fdc.statut", value: "resolue", action: "not_equals"}
            };
        },
		title: function(){
			return this.consommable?this.$t("title")+": "+this.consommable.name:this.$t("title");
		},
        setActionAttacherDocumentIcon: function(){
            return this.isUploaderVisible?'el-icon-arrow-up':'el-icon-plus';
        },
        /**
        * @return string
        */
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        getFournisseurDefault: function(){
            if(this.consommable.fournisseurDefault) return this.consommable.fournisseurDefault.name;
            else return "";
        }
	},
    destroyed: function(){
        this.$store.dispatch("ConsommablesStore/setSelectedItem", null);
    }
}
</script>

<style lang="scss">

</style>
