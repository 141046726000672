//RestClient exceptions
import ServicesExceptions from '../ServiceExceptions.js';
export default class RestClientExceptions extends ServicesExceptions{
    constructor(){
        super();
    }
    static unknowEntityId(){
         throw 'No id set in parameters';
    }
    
    static noDatasToUpdate(){
        throw "No data to update...";
    }
    
}