<template>
	<div class="vg-email-already-taken">
		<span>{{$t("msg-email-already-taken")}}</span>
		<vg-button size="xs" type="default-info" @click="$emit('click-changer')">{{$t("changer")}}</vg-button>
	</div>
</template>
<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";

    export default {
        name: 'vg-email-already-taken',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "msg-email-already-taken": "Cette email est déjà utilisé.",
            "changer": "Changer email"
        },
        "en": {
			"msg-email-already-taken": "Email already taken",
			"changer": "Change email"
        }
	}
},
		components: {
			VgModal,
			VgButton
		},
        data: function() {
            return {
				
            };
        },
    };

</script>
<style lang="scss" scoped>
.vg-email-already-taken{
	display:flex;
	width: inherit;
	padding: 10px;
	background-color: #f8d7da;
	margin-bottom: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	font-size: 12px;
	span{
		color: #aa585c;
	}
}
</style>
