<template lang="html">
    <div class="affectation-fm-cell-render" v-if="params.data.affectation">
        <div class="affectation" v-for="affecte,index in params.data.affectation.affectes" :key="index">
            <img :src="affecte.user_id?'/static/assets/icone/user.png':'/static/assets/icone/tiers.png'"/>
            <span v-if="affecte.user_id">{{affecte.user_name}}</span>
            <span v-else>{{affecte.name}}</span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"fm-cell-render",
    mixins:[LangMixins],
    props:{
        params:{
            type: Object,
            required: true
        }
    }
});
</script>

<style lang="scss" scoped>
.affectation-fm-cell-render{
    .affectation{
        img{
            width: 15px;
            height: 15px;
        }
    }
}
</style>
 }