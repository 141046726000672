<template>
	<span>{{tva.value}}% <!--small>{{tva.value}}%</small--></span>
</template>
<script>
    export default {
        name: 'vg-tva-viewer',
    	props: {
            value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
				tva: this.value
            };
        },
		watch:{
			value:{
				handler: function(newValue){
					this.tva = newValue;
				}
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
