<template lang="html">
    <div class="lieu-mobile-cell-render">
        <vg-lieu-path-viewer v-model="params.data.path"
            :showTooltip="false"
            onlyPiece
            class="libel-piece">
        </vg-lieu-path-viewer><br>
        <vg-lieu-path-viewer v-model="params.data.path"
            class="start-path"
            :showTooltip="false"
            onlyPath>
        </vg-lieu-path-viewer>
        <div class="info-lieu">
            <div>{{params.data.service}}</div>
            <div class="verification-lieu">
                <img v-if="hasMaintenance" src="static/assets/icone/maintenance.png" width="15px" height="15px"/>
                <div class="badge-verification" v-if="params.data.derniereDateVerif">
                    <vg-datetime-viewer v-model="params.data.derniereDateVerif">
                    </vg-datetime-viewer>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Vue from "vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name: "lieu-mobile-cell-render",
    mixins:[LangMixins],
    components:{
        VgLieuPathViewer,
        VgDatetimeViewer
    },
    props: {
        params: {
            type: Object,
        },
    },
    computed: {
        hasMaintenance: function(){
            return this.params.data.idFM && (this.params.data.statut=='En_cours' || this.params.data.statut=='prise_en_compte' || this.params.data.statut=='en_attente');
        },
    }
})
</script>

<style lang="scss" scoped>
.lieu-mobile-cell-render{
    overflow: auto;
    .libel-piece{
        font-weight: bold;
    }
    .start-path{
        color: gray;
    }
    /*.path{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        .start-path{
            display: flex;
            overflow: scroll;
            width: 50%;
            color: gray;
        }
        .end-path{
            width: 40%;
            text-align: right;
        }
    }*/
    .info-lieu{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .verification-lieu{
            display: flex;
            flex-direction: row;
            align-items: center;
            .badge-verification{
                background-color: #95CB45;
                color: #FFFFFF;
                font-size: 11px;
                padding: 3px 6px ;
                margin-left: 5px;
            }
        }
    }
}
</style>
