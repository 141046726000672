<template>
    <vg-select
        v-model="tiersTagsSelected"
        :options="getTags"
        @input="$emit('input',tiersTagsSelected)"
        >
    </vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-tiers-tags-selector',
		components: {
			VgSelect
		},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			}
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "Electricite": "Electricité",
            "Plomberie": "Plomberie",
            "Chauffage": "Chauffage",
            "Climatisation": "Climatisation",
            "Securite-incendie": "Securité incendie",
            "informatique": "Informatique",
            "Wifi": "Wifi",
            "Controle-acces": "Contrôle d'accès",
            "Mobilier": "Mobilier",
            "Telephonie": "Téléphonie",
            "Electromenager": "Electroménager",
            "Equipement-cuisine": "Equipement cuisine",
            "Son-image": "Son-Image",
            "Menuiserie": "Menuiserie",
            "Peinture": "Peinture",
            "Maconnerie": "Maçonnerie",
            "Eclairage-luminaire": "Eclairage-luminaire",
            "Reseau-fluide": "Réseau fluide",
            "Serrurerie": "Serrurerie",
            "Sanitaire": "Sanitaire",
            "autre": "Autre",
            "produit-entretien": "Produit d'entretien",
            "fourniture-bureau": "Fourniture de bureaux",
            "vetements-epi": "Vêtements/EPI"
        },
        "en": {
            "Electricite": "Electricity",
            "Plomberie": "Plumbing",
            "Chauffage": "Heating",
            "Climatisation": "Air Conditionner",
            "Securite-incendie": "Fire security",
            "informatique": "Computer science",
            "Wifi": "Wifi",
            "Controle-acces": "Access controls",
            "Mobilier": "Furniture",
            "Telephonie": "Telephony",
            "Electromenager": "Home appliance",
            "Equipement-cuisine": "Kitchen equipment",
            "Son-image": "Sound/Images",
            "Menuiserie": "Woodwork",
            "Peinture": "Peint",
            "Maconnerie": "Masonry",
            "Eclairage-luminaire": "Lights",
            "Reseau-fluide": "Liquids network",
            "Serrurerie": "Locksmith",
            "Sanitaire": "Sanitary",
            "autre": "Other",
            "produit-entretien": "Cleaning product",
            "fourniture-bureau": "Office supplies",
            "vetements-epi": "Clothing/PPE"
        }
    }
},
        data: function() {
            return {
                tiersTagsSelected:null
            };
        },
        created:function(){
            this.tiersTagsSelected = this.value;
        },
        computed: {
            getTags: function(){
                let tags = this.tiersType.sort();
                tags.push(this.$t("autre"));
                return tags;
            },
            tiersType:function(){
                return [
                    this.$t("Electricite"),
                    this.$t("Plomberie"),
                    this.$t("Chauffage"),
                    this.$t("Climatisation"),
                    this.$t("Securite-incendie"),
                    this.$t("informatique"),
                    this.$t("Wifi"),
                    this.$t("Controle-acces"),
                    this.$t("Mobilier"),
                    this.$t("Telephonie"),
                    this.$t("Electromenager"),
                    this.$t("Equipement-cuisine"),
                    this.$t("Son-image"),
                    this.$t("Menuiserie"),
                    this.$t("Peinture"),
                    this.$t("Maconnerie"),
                    this.$t("Eclairage-luminaire"),
                    this.$t("Reseau-fluide"),
                    this.$t("Serrurerie"),
                    this.$t("Sanitaire"),
                    this.$t("produit-entretien"),
                    this.$t("fourniture-bureau"),
                    this.$t("vetements-epi")
                ];
            }
        }
    };

</script>
<style lang="scss" scoped>

</style>
