import { render, staticRenderFns } from "./VgComposantsCreate.vue?vue&type=template&id=036b83ad&scoped=true&"
import script from "./VgComposantsCreate.vue?vue&type=script&lang=js&"
export * from "./VgComposantsCreate.vue?vue&type=script&lang=js&"
import style0 from "./VgComposantsCreate.vue?vue&type=style&index=0&id=036b83ad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036b83ad",
  null
  
)

export default component.exports