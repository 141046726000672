<template>
  <vg-modal
    :title="$t('modal-title', {nbMaintenances: getSelectedMaintenances.length})"
    @close="$emit('close')">
    <template #body>
        <vg-input :title="$t('field-rapport')"
            inputType="textarea"
            v-model="rapportCloture"
            :isRequiredValue="isRapportClotureCuratifRequired" />
    </template>
    <template #footer>
        <vg-button @click="$emit('close')">{{$t("cancel")}}</vg-button>
        <vg-button type="danger" @click="cloturer" :disabled="isSaveButtonDisabled || isLoading">{{$t("cloturer", {nbMaintenances: getSelectedMaintenances.length})}}</vg-button>
    </template>
  </vg-modal>
</template>
<script>
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

import { mapGetters } from 'vuex';
export default {
    name: 'vg-maintenances-cloture-form',
    components:{
        VgModal,
        VgButton,
        VgInput
    },
    mixins: [ MaintenanceMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Clôture de {nbMaintenances} fiches curatives",
            "field-rapport": "Rapport de clôture",
            "cancel": "Annuler",
            "cloturer": "Clôturer {nbMaintenances} fiches",
        }
    }
},
    props: {
        
    },
    data: function() {
        return {
            rapportCloture: null,
            isLoading: false
        };
    },
    computed:{
        ...mapGetters({
            getSelectedMaintenances: 'MaintenancesStore/getSelectedMaintenances'
        }),
        isSaveButtonDisabled: function(){
            return this.isRapportClotureCuratifRequired && (!this.rapportCloture || this.rapportCloture.length<2);
        },
        isRapportClotureCuratifRequired: function(){
            return this.$app.isRapportClotureCuratifRequired?this.$app.isRapportClotureCuratifRequired:false;
        },
    },
    created: function() {
        
    },
    methods: {
        cloturer:function(){
            this.isLoading = true;
            let maintenances = this.getSelectedMaintenances;
            let rapportCloture = this.rapportCloture && this.rapportCloture.length>=2?this.rapportCloture:null;
            if(this.isRapportClotureCuratifRequired && !rapportCloture){
                return;
            }
            this.MaintenanceMixins_resolveMaintenances(maintenances, rapportCloture).then((datas)=>{
                this.isLoading = false;
                this.$emit("save", datas);
            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
