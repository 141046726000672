<template>
    <div class="vg-calendar-picker">
        <v-calendar v-if="calendarAttributes"
            :attributes="calendarAttributes"
            :locale="locale"
            is-expanded
            @dayfocusin="onDayFocusIn">
        </v-calendar>
    </div>
</template>

<script>
import VCalendar from 'v-calendar/lib/components/calendar.umd';
export default {
    name: "vg-date-picker",
    props: {
        events:{
            type: Array,
            default: function(){
                return [];
            }
        },
        defaultEventColor: {
            type: String,
            default: "yellow"
        },
        defaultEventFillMode: {
            type: String,
            default: "light"
        }
    },
    components: {
        VCalendar
    },
    data: function() {
        return{
            calendarAttributes: null
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "au": "au"
        },
        "th": {
            "au": "ถึง"
        }
    }
},
    watch:{
        events: {
            handler: function(newEvents){
                this.initCalendarEvents();
            },
            deep: true
        }
    },
    methods:{
        onDayFocusIn: function(e){
            let date = e.year+"-"+e.month+"-"+e.day;
            this.$emit("focused-date", {date: date, start: date+" 00:00", end: date+" 23:59"});
        },
        initCalendarEvents: function(){
            let dots = {red: [], green: []};
            this.events.forEach((event)=>{
                dots[event.color].push(new Date(event.date));
            });
            this.calendarAttributes =  [
                {
                    key: 'today',
                    highlight: {
                        color: 'yellow',
                        fillMode: 'solid',
                    },
                    dates: moment().toDate(),
                },
                {
                    dot: "red",
                    dates: dots.red
                },
                {
                    dot: "green",
                    dates: dots.green
                }
            ];
        },
    },
    computed: {
        locale: function(){
            return {
                masks: {
                    weekdays: "W"
                }
            };
        }
    },
    created: function(){
        this.initCalendarEvents();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.vc-border{
    border: none !important;
}
.vg-calendar-picker{
    .vc-title{
        font-family: Verdana,sans-serif;
        font-size: 14px;
    }
    .vc-weekday{
        color: black;
        font-size: 12px;
    }
}
@media screen and (max-width: 600px) {
    .vc-container{
        --day-content-width: 40px !important;
        --day-content-height: 40px !important;
        --weeks-padding: 0px !important;
    }
    .vc-weekday{
        color: #b7b7b7 !important;
    }
    .vc-grid-cell{
        border-bottom: 1px whitesmoke solid !important;
    }
    .vc-rounded-full{
        border-radius: 3px !important;
    }
    .vc-dots{
        padding-bottom: 1px !important;
    }
}
</style>
