
<template lang="html">
	<div v-if="!isFileChange" class="uploader-container">
		<vg-select v-if="!isOnlyPhoto && defaultType!='plan-interactif'"
			class="select-tag"
			v-model="idTag"
			:options="tagsList"
			:attributeValue="'id'"
			:placeholder="$t('select-tag-placeholder')" />

		<label v-if="!$vgutils.isMobile()" :class="['upload-button', 'upload-button-'+buttonSize, {'upload-photo': isOnlyPhoto, 'upload-file': !isOnlyPhoto, 'upload-disabled': !(isOnlyPhoto || (!isOnlyPhoto && idTag)), 'upload-pi': isUploadPI, 'upload-photo-info': buttonColor=='info'}]">
		    <input v-if="isOnlyPhoto || (!isOnlyPhoto && idTag)" type="file" :accept="getFileType" capture="environment" @change="upload" />
		    <i :class="['fas', {'fa-cloud-upload-alt': !isOnlyPhoto, 'fa-camera': isOnlyPhoto}]"></i>
			<span v-if="!inputLabel && isOnlyPhoto">{{$t("input-label-photo")}}</span>
			<span v-else-if="!inputLabel">{{$t("input-label-file")}}</span>
			<span v-else>{{inputLabel}}</span>
		</label>
		<label v-else 
			:class="['upload-button', 'upload-button-'+buttonSize, {'upload-photo': isOnlyPhoto, 'upload-file': !isOnlyPhoto, 'upload-disabled': !(isOnlyPhoto || (!isOnlyPhoto && idTag)), 'upload-pi': isUploadPI, 'upload-photo-info': buttonColor=='info'}]"
			@click="showOptionsModal=true;">
		   	<i :class="['fas', {'fa-cloud-upload-alt': !isOnlyPhoto, 'fa-camera': isOnlyPhoto}]"></i>
			<span v-if="!inputLabel && isOnlyPhoto">{{$t("input-label-photo")}}</span>
			<span v-else-if="!inputLabel">{{$t("input-label-file")}}</span>
			<span v-else>{{inputLabel}}</span>
		</label>
		<div v-if="$vgutils.isMobile()" class="hidden-file-input">
			<input
				type="file"
				:accept="getFileType"
				ref="file-input-explorer"
				@change="upload"
				class="hidden-file-input"
				/>
			<input
				type="file"
				:accept="getFileType"
				capture="environment"
				ref="file-input-camera"
				@change="upload"
				class="hidden-file-input"
				/>
		</div>

		<vg-files-progress-upload-bar v-if="VgFilesMixins.uploadState"
			:transferredBytes="VgFilesMixins.uploadState.bytesTransferred"
			:totalBytes="VgFilesMixins.uploadState.totalBytes" />

		<vg-modal v-if="showOptionsModal"
			theme="without-header">
			<template #footer>
				<vg-button style="width:50%;" 
					size="lg"
					type="info"
					@click="triggerFileInput('explorer')">
					<i class="fas fa-2x fa-file" style="margin-right:10px;"></i> {{ $t("choose-file") }}
				</vg-button>
				<vg-button style="width:50%;" 
					size="lg"
					type="info"
					@click="triggerFileInput('camera')">
					<i class="fas fa-2x fa-camera" style="margin-right:10px;"></i> {{ $t("take-photo") }}
				</vg-button>
			</template>
		</vg-modal>
	</div>
	<div v-else class="uploader-container-file">
		<div class="file">
			<i :class="'fa-lg '+getFileIcon(file)"  ></i>
			<span>{{ file.name }}</span>
			<small v-if="idTag && tag">{{ tag.label }}</small>
		</div>
		<vg-button size="sm" type="default-danger" @click="handleClickDelete(file)">
			{{ $t("retirer") }}
		</vg-button>
	</div>
</template>
<script>
	import VgFilesProgressUploadBar from "src/components/Vg/Files/VgFilesProgressUploadBar.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgModal from "src/components/Vg/VgModal.vue";
    export default {
        name: 'vg-files-uploader',
	    components: {
			VgFilesProgressUploadBar,
			VgSelect,
			VgButton,
			VgModal
        },
		mixins:[],
        props: {
			isOnlyPhoto: {
				type: Boolean,
				default: false
			},
			isUnnamed: {
				type: Boolean,
				default: false
			},
			tagsList: {
				type: Array,
				default: function(){ 
					return [];
				}
			},
			inputLabel: {
				type: String
			},
			defaultTag: {
				type: Object,
				default: null
			},
			defaultIdTag: {
				type: String | Number,
				default: null
			},
			isUploadOnFileChange: {
				type: Boolean,
				default: true
			},
			uidToUpload: {
				type: String,
				default: null
			},
			defaultType: {
				type: String,
				default: null
			},
			/**
			* type du fichier à uploader ex: image/* | application/pdf | * | ...
			*/
			fileType: {
				type: String,
				default: "*"
			},
			isUploadPI:{
				type: Boolean,
				default: false
			},
			buttonColor:{
				type: String,
				default: null
			},
			buttonSize:{
				type: String,
				default: "md"
			}
        },
        data: function() {
            return {
				showOptionsModal: false,
				idTag: this.defaultTag? this.defaultTag.id: null,
				isFileChange: false,
				file: null,

				icons: {
					pdfIcon: "far fa-file-pdf",
					fileIcon: "far fa-file",
					txtIcon: "far fa-file-alt",
					imgIcon: "far fa-file-image",
					excelIcon: "far fa-file-excel",
					wordIcon: "far fa-file-word",
					zipIcon: "far fa-file-archive"
		        }
			};
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "input-label-file": "Importer un fichier",
            "input-label-photo": "Photo",
            "select-tag-placeholder": "Etiquette du document",
			"retirer": "Retirer",
			"choose-file": "Choisir un fichier",
			"take-photo": "Prendre une photo"
        },
        "en": {
            "input-label-file": "Import a file",
            "input-label-photo": "Photo",
            "select-tag-placeholder": "Document label",
			"retirer": "Remove",
			"choose-file": "Choose a file",
			"take-photo": "Take a photo"
        },
        "th": {
            "input-label-file": "นำเข้าไฟล์",
            "input-label-photo": "รูปภาพ",
            "select-tag-placeholder": "ฉลากเอกสาร"
        }
    }
},
		watch: {
			tagsList: {
				handler: function(newtags){
					console.log("TAGS WATCH--------------", this.tagsList);
				},
				deep: true
			}
		},
		created: function(){

		},
        mounted: function(){
			// //console.log("VG FILES UPLOADER TEST MIXIN", this.VgFilesMixins.uid);
			if(this.defaultIdTag) this.idTag = this.defaultIdTag;
			console.log("TAAAAAAAAAAAAAAAAAGS", this.tagsList);
        },
        methods: {
			triggerFileInput: function(type){
				if(type==="explorer") this.$refs['file-input-explorer'].click();
      			else if(type==="camera") this.$refs['file-input-camera'].click();
				this.showOptionsModal = false;
			},
			upload: function(event){
				this.$emit("begin-upload-file");
				////console.log("FILE", event.target.files[0], this.VgFilesMixins_getUid());
				if(this.uidToUpload) this.VgFilesMixins_setUid(this.uidToUpload);
				if(this.defaultType) this.VgFilesMixins_setType(this.defaultType);
				if(event.target.files[0] && this.isUploadOnFileChange) {
					this.VgFilesMixins_uploadFile(event.target.files[0], this.idTag, this.isUnnamed).then((document)=>{
						this.$emit("upload-done", document);
					});
				}else if(event.target.files[0] && !this.isUploadOnFileChange) {
					this.isFileChange = true;
					this.file = event.target.files[0];
					this.$emit("file-change", {file: event.target.files[0], idTag: this.idTag, isUnnamed: this.isUnnamed});
				}
			},
			handleClickFile: function(elementLi) {
				// //console.log("show file: ", elementLi);
				this.readURL(elementLi);
		    },
			readURL: function(input) {
				var path = (window.URL || window.webkitURL).createObjectURL(input);
				this.open_in_new_tab(path);
			},
			open_in_new_tab: function(url) {
				var win = window.open(url);
				win.focus();
		    },
			handleClickDelete: function(elementLi) {
				// //console.log("delete file: ", elementLi);
				this.removeFileFromFiles(elementLi);
		    },
			removeFileFromFiles: function(file) {
		      	this.file = null;
				this.isFileChange = false;
				this.$emit("file-change", null);
		    },
			defineSpecialFileType: function(filename) {
		      var that = this;
		      var splitName = filename.split(".");
		      var extension = splitName[splitName.length - 1];
		      var foundExtension = "";
		      switch (extension) {
		        case "xls":
		          foundExtension = that.icons.excelIcon;
		          break;
		        case "xlsx":
		          foundExtension = that.icons.excelIcon;
		          break;
		        default:
		          foundExtension = that.icons.fileIcon;
		      }
		      return foundExtension;
		    },
		    getFileIcon: function(file) {
		      var selectedIcon = null;
		      var that = this;
		      switch (file.type) {
		        case "image/png":
		          selectedIcon = that.icons.imgIcon;
		          break;
		        case "image/jpeg":
		          selectedIcon = that.icons.imgIcon;
		          break;
		        case "image/jpg":
		          selectedIcon = that.icons.imgIcon;
		          break;
		        case "application/pdf":
		          selectedIcon = that.icons.pdfIcon;
		          break;
		        case "application/x-msdownload":
		          selectedIcon = that.icons.fileIcon;
		          break;
		        case "application/x-zip-compressed":
		          selectedIcon = that.icons.zipIcon;
		          break;
		        case "application/x-zip-compressed":
		          selectedIcon = that.icons.zipIcon;
		          break;
		        case "":
		          selectedIcon = that.defineSpecialFileType(file.name);
		          break;
		        default:
		          selectedIcon = that.icons.fileIcon;
		      }
		      return selectedIcon;
		    }
		},
		computed: {
			tag: function(){
				return this.tagsList.find((t)=>t.id==this.idTag);
			},
			getFileType: function(){
				if(this.isOnlyPhoto) return 'image/png, image/jpeg'
				return this.fileType;
			}
		}
	};
</script>

<style lang="scss" scoped>
.uploader-container{
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;

	input[type="file"] {
	    display: none;
	}
	.upload-photo{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: baseline;
		border: 1px solid #ccc;
		border-radius: 5px !important;
		padding: 6px 12px;
		cursor: pointer;
		>span{
			padding-left: 5px;
		}
	}
	.select-tag{
		width: 50%;
	}
	.upload-file{
		border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
		width: 50%;
	}
	.upload-pi{
		padding: 0px 5px !important;
		font-size: 10px;
		font-weight: normal;
	}
	.upload-disabled{
		cursor: not-allowed;
	}
	.upload-button:not(.upload-disabled):hover{
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
	}
	.upload-button:not(.upload-disabled):active,.upload-button:not(.upload-disabled):focus{
	  box-shadow: 0 1px #00cc66;
	}
	
	.upload-photo-info{
		font-weight: normal;
		background-color: #059CFF !important;
		color: white !important;
		border: none !important;
		margin-bottom: 0px !important;
	}
	.upload-button-sm{
		line-height: 11px;
		font-size: 1.1rem;
		font-weight: normal;
	}
	.upload-button-md{
		
	}
}
.uploader-container-file{
	width: 100%;
	border: 1px solid whitesmoke;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:10px;
	.file{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 5px;
	}
}
.hidden-file-input {
  display: none;
}
</style>
