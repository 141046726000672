<template>
    <vg-select
        style="width:96px;"
        v-model="lang"
        :options="$langs.panel"
        :attributeLabel="'label'"
        :clearable="false"
        @input="setLang">
        <template v-slot:selected-option="{option}">
            <span v-if="option.label!='en'" :class="'flag-icon flag-icon-'+option.label"></span>
            <span v-else class="flag-icon flag-icon-gb"></span>
        </template>
        <template v-slot="{option}">
            <span v-if="option.label!='en'" :class="'flag-icon flag-icon-'+option.label"></span>
            <span v-else class="flag-icon flag-icon-gb"></span>
        </template>
    </vg-select>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";

export default {
    name: 'vg-lang-selector',
    components:{
        VgSelect
    },
    data: function() {
        return {
            lang: this.$langs.current
		};
    },

    methods: {
        setLang: function(lang){
            this.$root.$i18n.locale = this.lang;
            var event = new CustomEvent('setLang',{'detail':{lang:this.lang}});
            window.dispatchEvent(event);
        }
    },
};

</script>
<style lang="css">

</style>
