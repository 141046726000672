<template>
    <div v-if="params.data.datePriseEnCompte && params.data.userPriseEnCompte"
        style="font-size: 12px;">
        <span>{{params.data.userPriseEnCompte}}</span>
        <vg-datetime-viewer v-model="params.data.datePriseEnCompte">
        </vg-datetime-viewer>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgDatetimeViewer
        }
    });
</script>

<style lang="scss" scoped>

</style>
