import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.treeCollection = [];
state.filterCollection = [];
state.sites = [];
state.familles = [];
let getters = Base.getGetters();
getters.getTreeCollection = function( state ){
    return state.treeCollection;
};
getters.getFilterCollection = function( state ){
    return state.filterCollection;
};
getters.getSites = function( state ){
    return state.sites;
};
getters.getFamilles = function( state ){
    return state.familles;
};
/**
* récupère la pièce du site pour la création des équipements
* @param State state
*/
getters.getPieceEquipementsGlobaux = function(state){
    let site = state.selectedItem;
    return state.collection.find((lieu)=>lieu.path == site.path+"/-/-/-");
};
let actions = Base.getActions();
actions.setTreeCollection = function( context, values ){
    context.commit('SET_TREE_COLLECTION', values);
};
actions.setFilterCollection = function( context, values ){
    context.commit('SET_FILTER_COLLECTION', values);
};
actions.setSites = function( context, values ){
    context.commit('SET_SITES', values);
};
actions.setFamilles = function( context, values ){
    context.commit('SET_FAMILLES', values);
};
let mutations = Base.getMutations();
mutations.SET_TREE_COLLECTION = function(state, values){
    state.treeCollection = values;
};
mutations.SET_FILTER_COLLECTION = function(state, values){
    state.filterCollection = values;
};
mutations.SET_SITES = function(state, values){
    state.sites = values;
};
mutations.SET_FAMILLES = function(state, values){
    if(!state.familles || state.familles.length==0) state.familles = values;
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
