
<template>

	<div class="parametres-entreprise" v-loading="loading">
        <span class="title">{{ $t("parametres-entreprise-header") }}</span>
        <!--vg-tabs
            :tabs="tabs"
            :color="'dark'"
            @tabClicked="handleTabClick">
        </vg-tabs-->
        <div v-if="active=='generalSettings' && parameters" style="padding-top:30px;">
            <div>
				<span>{{ $t("parametres-entreprise-parametres-generaux-nom") }}</span>
				<span style="font-size:24px;">{{parameters.nom}}</span>
			</div>
			<br>
			<div style="display:flex;justify-content:flex-start;align-items:center;">
				<span>{{ $t("parametres-entreprise-parametres-generaux-logo") }}</span>
				<vg-files-logo-uploader v-if="!logo && isSessionAdmin"
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"
					@upload-done="onUploadLogoDone"/>
				<vg-files-logo-viewer v-else/>
			</div>
			<div style="display:flex;justify-content:flex-start;align-items:baseline;">
				<span>{{ $t("parametres-entreprise-parametres-generaux-address") }}</span>
				<span v-if="$app.account_address && !showUpdateAddress" style="margin-left:10px;">
					{{$app.account_address}}
				</span>
				<vg-input v-else-if="showUpdateAddress && isSessionAdmin" v-model="address"
					style="width:50%;margin-left:10px;"/>
				<vg-button v-if="!showUpdateAddress && isSessionAdmin"
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"
					style="margin-left:10px;"
					:size="'sm'"
					:type="'danger'"
					@click="address=$app.account_address;showUpdateAddress=true">{{$t('update')}}</vg-button>
				<vg-button v-if="showUpdateAddress && isSessionAdmin"
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"
					style="margin-left:10px;"
					:size="'sm'"
					:type="'success'"
					:disabled="isSaveAddressDisabled"
					@click="onSaveAddress">{{$t('save')}}</vg-button>
			</div>
			<div style="display:flex;justify-content:flex-start;align-items:baseline;">
				<span>{{ $t("parametres-entreprise-parametres-generaux-immatriculation") }}</span>
				<span v-if="$app.account_immatriculation && !showUpdateImmatriculation" 
					style="margin-left:10px;">{{$app.account_immatriculation}}</span>
				<vg-input v-else-if="showUpdateImmatriculation && isSessionAdmin" 
					v-model="immatriculation"
					style="width:50%;margin-left:10px;"/>
				<vg-button v-if="!showUpdateImmatriculation && isSessionAdmin" 
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"
					style="margin-left:10px;"
					:size="'sm'"
					:type="'danger'"
					@click="immatriculation=$app.account_immatriculation;showUpdateImmatriculation=true">{{$t('update')}}</vg-button>
				<vg-button v-if="showUpdateImmatriculation && isSessionAdmin" 
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"
					style="margin-left:10px;"
					:size="'sm'"
					:type="'success'"
					:disabled="isSaveImmatriculationDisabled"
					@click="onSaveImmatriculation">{{$t('save')}}</vg-button>
			</div>
			<br>
			<hr>
			<br>
			<div v-show="can('PARAMETRES.UPDATE_ENTREPRISE')">
				<vg-currency-selector v-model="devise" />
				<modal-delete-demo-datas v-show="can('PARAMETRES.UPDATE_ENTREPRISE')"/>
				<vg-button v-if="devise!=$app.devise && devise!=''"
					v-show="can('PARAMETRES.UPDATE_ENTREPRISE')" 
					:type="'info'" 
					@click="handleSaveDevise">
					{{$t("save")}}
				</vg-button>
			</div>
        </div>
	</div>
</template>

<script>
	import VgCurrencySelector from "src/components/Vg/Utils/VgCurrencySelector.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import ParametresMixins from "src/mixins/ParametresMixins.js";
	import AccountMixins from "src/mixins/AccountMixins.js";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgFilesLogoUploader from "src/components/Vg/Files/VgFilesLogoUploader.vue";
    import VgFilesLogoViewer from "src/components/Vg/Files/VgFilesLogoViewer.vue";
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";
    import ModalDeleteDemoDatas from "src/views/Parametres/components/ModalDeleteDemoDatas.vue";

    export default {
        name: 'parametres-entreprise',
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "save": "Enregistrer",
            "update": "Modifier",
            "integrations": "Intégrations",
            "parametres-entreprise-header": "Paramètres du compte",
            "parametres-entreprise-parametres-generaux-onglet": "Paramètres généraux",
            "parametres-entreprise-configuration-externe-onglet": "Configuration externe",
            "parametres-entreprise-parametres-generaux-nom": "Nom du compte: ",
            "parametres-entreprise-parametres-generaux-logo": "Logo du compte: ",
            "parametres-entreprise-parametres-generaux-address": "Adresse: ",
            "parametres-entreprise-parametres-generaux-immatriculation": "Immatriculation: ",
            "parametres-entreprise-parametres-generaux-bouton-edition": "Modifier le logo",
            "parametres-entreprise-configuration-externe-message-presentation": "Vous pouvez ici, modifier les paramètres liés aux configurations externes de l entreprise",
            "parametres-entreprise-configuration-externe-checkbox-compte-slack": "J ai un compte Slack",
            "parametres-entreprise-configuration-externe-bouton-edition": "Configuration des clés Slack",
            "parametres-entreprise-configuration-externe-cle-standard": "Clé standard",
            "parametres-entreprise-configuration-externe-cle-erreur": "Clé erreur",
            "parametres-crud-annuler": "Annuler",
            "parametres-crud-valider": "Valider"
        },
        "en": {
            "parametres-entreprise-header": "Account Settings",
            "parametres-entreprise-parametres-generaux-onglet": "General settings",
            "parametres-entreprise-configuration-externe-onglet": "External configuration",
            "parametres-entreprise-parametres-generaux-nom": "Account name: ",
            "parametres-entreprise-parametres-generaux-logo": "Account logo: ",
            "parametres-entreprise-parametres-generaux-address": "Address: ",
            "parametres-entreprise-parametres-generaux-immatriculation": "Registration number: ",
            "parametres-entreprise-parametres-generaux-bouton-edition": "Edit logo",
            "parametres-entreprise-configuration-externe-message-presentation": "Change settings related to the Account s external configurations here",
            "parametres-entreprise-configuration-externe-checkbox-compte-slack": "I have a Slack account",
            "parametres-entreprise-configuration-externe-bouton-edition": "Configuration of Slack keys",
            "parametres-entreprise-configuration-externe-cle-standard": "Standard key",
            "parametres-entreprise-configuration-externe-cle-erreur": "Key error",
            "parametres-crud-annuler": "Cancel",
            "parametres-crud-valider": "Save",
            "save": "Save",
            "integrations": "Integrations"
        },
        "th": {
            "parametres-entreprise-header": "การตั้งค่าบริษัท",
            "parametres-entreprise-parametres-generaux-onglet": "การตั้งค่าทั่วไป",
            "parametres-entreprise-configuration-externe-onglet": "การกำหนดค่าภายนอก",
            "parametres-entreprise-parametres-generaux-nom": "ชื่อบริษัท: ",
            "parametres-entreprise-parametres-generaux-logo": "โลโก้บริษัท: ",
            "parametres-entreprise-parametres-generaux-bouton-edition": "แก้ไขโลโก้",
            "parametres-entreprise-configuration-externe-message-presentation": "เปลี่ยนการตั้งค่าที่เกี่ยวข้องกับการกำหนดภายนอกของบริษัทได้ที่นี่",
            "parametres-entreprise-configuration-externe-checkbox-compte-slack": "ฉันมีบัญชีที่ใช้งานได้ช้า",
            "parametres-entreprise-configuration-externe-bouton-edition": "การกำหนดค่าของกุญแจที่เชื่องช้า",
            "parametres-entreprise-configuration-externe-cle-standard": "กุญแจมาตรฐาน",
            "parametres-entreprise-configuration-externe-cle-erreur": "กุญแจผิดพลาด",
            "parametres-crud-annuler": "ยกเลิก",
            "parametres-crud-valider": "บันทึก",
            "save": "บันทึก"
        }
    }
},
        components: {
			VgCurrencySelector,
			VgInput,
			VgButton,
            VgTabs,
			VgFilesLogoUploader,
			VgFilesLogoViewer,
			VgCredits,
			ModalDeleteDemoDatas
        },
		mixins: [ParametresMixins, AccountMixins],
        data() {
            return {
                loading: true,
                parameters: null,
				devise: this.$app.devise,
				active: 'generalSettings',
				role: this.$app.role,
				logo: null,
				address: null,
				immatriculation: null,
				showUpdateAddress: false,
				showUpdateImmatriculation: false,
			};
        },
		watch: {

		},
        methods: {
			getSessionStorageLogo: function(){
				this.logo = window.sessionStorage.getItem('account_logo');
			},
			getSessionStorageAddress: function(){
				this.address = this.$app.account_address;
			},
			getSessionStorageImmatriculation: function(){
				this.immatriculation = this.$app.account_immatriculation;
			},
			onUploadLogoDone: function(document){
				console.log("onUploadLogoDone", document);
				this.getSessionStorageLogo();
			},
			saveInSessionStorage: function(sessionValues){
				window.sessionStorage.setItem("user", JSON.stringify(
					Object.assign(this.$app, sessionValues)
				) );
			},
			onSaveAddress: function(){
				this.AccountMixins_update({address: this.address}).then((account)=>{
					this.getSessionStorageAddress();
					this.showUpdateAddress = false;
				});
			},
			onSaveImmatriculation: function(){
				this.AccountMixins_update({immatriculation: this.immatriculation}).then((account)=>{
					this.getSessionStorageImmatriculation();
					this.showUpdateImmatriculation = false;
				});
			},
			handleSaveDevise: function(){
				this.ParametresMixins_update({
					id: this.parameters.id,
					devise: this.devise,
					userId: this.$app.appID
				}).then((data) => {
					//this.parameters.devise = this.devise;
					this.saveInSessionStorage({
						devise: this.devise
					});
					this.$forceUpdate();
				});
			},
			fetch: function(){
				this.AccountMixins_fetch().then((datas)=>{
					this.parameters = datas[0];
					this.loading = false;
				});
			},
			isSessionAdmin: function(){
				return this.$app.role == "ROLE_ADMIN";
			},
            handleTabClick: function(event) {
                this.active = event.name;
            },
        },
        computed: {
            isAdmin: function(){
				return this.role=="ROLE_ADMIN";
			}
        },
        mounted: function(){

        },
		created: function(){
			this.fetch();
			this.getSessionStorageLogo();
		}
    };
</script>
<style lang="scss" scoped>
	.parametres-entreprise{
		.title{
			color: #02a7f0;
			font-size: 20px;
			width: 100%;
		}
	}
</style>
