<template>
    <div>
        <vg-modal
            v-show="!isModalConfirmDeleteOpened"
            @close="$emit('close')">
            <template #header>
                {{isAnUpdateComposantForm?$t("composant-form-update"):$t("composant-form-add")}} : {{$t("composant")}}
            </template>
            <div slot="body">
                <form ref="composant-form" onSubmit="false">
                    <vg-input
                        :title="$t('icon')"
                        isRequiredValue>
                        <!-- VG ICON SELECTOR -->
                        <vg-composant-icon-selector v-model="composant.icon"
                            :isClearable="false"/>
                    </vg-input>
                    <vg-input
                        :title="$t('corps-detat')"
                      >
                      <vg-corps-detat-selector
                          v-model="composant.corpsDetat_id"
                      >
                      </vg-corps-detat-selector>
                    </vg-input>
                    <vg-input v-show="hasAtLeastOneTypologieMaintenance" 
                        :title="$t('typologie-maintenance-par-defaut')" >
                        <vg-typologies-maintenance-selector  v-model="composant.typologieMaintenance_id" />
                    </vg-input>
                    <vg-input v-model="composant.libelComposant"
                        :title="$t('libelle')"
                        isRequiredValue/>
                    <!--vg-input
                        :title="$t('type')"
                        isRequiredValue>
                        <vg-tags-selector v-model="composant.typeComposant"
                            :type="'composant/type'"
                            :attributeValue="'label'"/>
                    </vg-input-->
                    <input v-show="false" type="submit" ref="submitComposant" />
                </form>
                <vg-input :title="$t('libels-problem-associes')"
                    isRequiredValue>
                    <div style="height:30vh;overflow:auto;">
                        <div v-for="(problem, index) in composant.problems"
                            class="libel-problem-row"
                            :key="problem.id">
                            <vg-input v-model="problem.libelle"
                                :key="'input-libelle-'+problem.id"
                                :disabled="isAnUpdateComposantForm"/>
                            <vg-button
                                :type="'danger'"
                                :size="'sm'"
                                :key="'delete-button-'+problem.id"
                                @click="onDeleteLibelProblem(problem, index)">
                                <i class="far fa-trash-alt"></i>
                            </vg-button>
                        </div>
                        <div class="libel-problem-row">
                            <vg-input v-model="newProblem.libelle"
                                :placeholder="$t('new-problem-placeholder')"/>
                            <vg-button
                                :type="'success'"
                                :size="'sm'"
                                :disabled="!newProblem.libelle || newProblem.libelle.length<2"
                                @click="onAddLibelProblem">
                                <i class="fas fa-plus"></i>
                            </vg-button>
                        </div>
                    </div>
                </vg-input>
            </div>
            <div slot="footer">
                <vg-button
                    type="default"
                    @click="$emit('close')">
                    {{$t("cancel")}}
                </vg-button>

                <vg-button v-if="isAnUpdateComposantForm"
                    type="danger"
                    @click="isModalConfirmDeleteOpened = true">
                    {{$t("delete")}}
                </vg-button>

                <vg-button v-if="isAnUpdateComposantForm"
                    type="default-danger"
                    :disabled="!composant.icon || !composant.libelComposant"
                    @click="handleUpdateComposant">
                    {{$t("update")}}
                </vg-button>

                <vg-button v-if="!isAnUpdateComposantForm"
                    type="success"
                    :disabled="!composant.icon || !composant.libelComposant"
                    @click="handleCreateComposant">
                    {{$t("save")}}
                </vg-button>
              </div>
        </vg-modal>

        <dialog-secured-action v-if="isModalConfirmDeleteOpened"
            :valid-response="'1'"
            @save="handleDeleteComposant"
            @close="isModalConfirmDeleteOpened = false">
            <template v-slot:header-title>
                {{ $t("composant-form-delete") }} <b>{{ composant.libelComposant }}</b> ?
            </template>
            <template #warning>
                {{$t("composant-form-delete-infos")}}
            </template>
        </dialog-secured-action>
    </div>

</template>
<script>
    import ComposantMixins from "src/mixins/ComposantMixins.js";
    import LibelProblemMixins from "src/mixins/LibelProblemMixins.js";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgComposantIconSelector from 'src/components/Vg/Selectors/VgComposantIconSelector.vue';
    import VgTagsSelector from 'src/components/Vg/Selectors/VgTagsSelector.vue';
    import VgCorpsDetatSelector from "src/components/Vg/Selectors/VgCorpsDetatSelector.vue";
    import VgTypologiesMaintenanceSelector from "src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue";
    import { mapGetters } from 'vuex';

    export default {
        name: 'vg-composant-form',
        mixins: [ ComposantMixins, LibelProblemMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            DialogSecuredAction,
            VgComposantIconSelector,
            VgTagsSelector,
            VgCorpsDetatSelector,
            VgTypologiesMaintenanceSelector
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "icon": "Icône",
            "libelle": "Libellé",
            "type": "Type",
            "libel-problem-autre": "Autre",
            "composant-form-add": "Nouveau",
            "composant-form-update": "Modifier",
            "composant": "Composant",
            "libels-problem-associes": "Problèmes associés",
            "new-problem-placeholder": "Nouveau problème",
            "cancel": "Annuler",
            "update": "Modifier",
            "save": "Enregistrer",
            "delete": "Supprimer",
            "composant-form-delete": "Voulez-vous supprimer le composant",
            "corps-detat": "Corps d'état",
            "composant-form-delete-infos": "Cette action supprimera définitivement le composant associé à toutes les catégories du compte.",
            "typologie-maintenance-par-defaut": "Selectionner une typologie à l'ouverture d'une fiche curative"
        },
        "en": {
            "icon": "Icon",
            "libelle": "Name",
            "type": "Type",
            "libel-problem-autre": "Other",
            "composant-form-add": "New",
            "composant-form-update": "Update",
            "composant": "Component",
            "libels-problem-associes": "Problèmes associés",
            "new-problem-placeholder": "Nouveau problème",
            "cancel": "Cancel",
            "update": "Update",
            "save": "Save",
            "delete": "Delete",
            "composant-form-delete": "Do you want to delete this component",
            "corps-detat": "Technical field",
            "composant-form-delete-infos": "This action will delete the component's associations with categories on the whole account.",
            "typologie-maintenance-par-defaut": "Select a typologie on curative sheet creation"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            },
            categorie:{
                type: Object,
                default: function(){
                    return null;
                }
            }
        },
        data: function() {
            return {
                composant:{
                    libelComposant: null,
                    typeComposant: "",
                    icon: null,
                    problems: [
                        {libelle: this.$t("libel-problem-autre")}
                    ],
                    categorie: {
                        id: this.categorie?this.categorie.id:null
                    },
                    userId: this.$app.appID
                },
                newProblem: {
                    libelle: null,
                    userId: this.$app.appID
                },
                isModalConfirmDeleteOpened:false
            };
        },
        computed:{
            ...mapGetters({
                typologiesMaintenance: "TypologiesMaintenanceStore/getCollection",
                hasAtLeastOneTypologieMaintenance: "TypologiesMaintenanceStore/hasAtLeastOneTypologieMaintenance"
            }),
            isAnUpdateComposantForm:function(){
                return this.value.hasOwnProperty('id');
            }
        },
        created: function() {
            this.composant = Object.assign({}, this.composant, this.value);
        },
        methods: {
            onAddLibelProblem: function(){
                if(this.isAnUpdateComposantForm){
                    this.LibelProblemMixins_create(this.composant.id, [this.newProblem]).then((datas)=>{
                        this.composant.problems = this.composant.problems.concat(datas);
                        this.newProblem = {
                            libelle: null,
                            userId: this.$app.appID
                        };
                    });
                }else{
                    this.composant.problems.push(this.newProblem);
                    this.newProblem = {
                        libelle: null,
                        userId: this.$app.appID
                    };
                }

            },
            onDeleteLibelProblem: function(problem, index){
                if(this.isAnUpdateComposantForm){
                    this.LibelProblemMixins_delete(problem).then((data)=>{
                        let index = this.composant.problems.findIndex((p)=>p.id==problem.id);
                        this.composants.problems.splice(index, 1);
                    });
                }else{
                    this.composant.problems.splice(index, 1);
                }
            },
            handleCreateComposant:function(){
                if(this.newProblem.libelle) this.onAddLibelProblem();
                this.ComposantMixins_create([this.composant]).then((composants)=>{
                    this.$emit("save", composants);
                });
            },
            handleUpdateComposant:function(){
                this.ComposantMixins_update(this.composant).then((composant)=>{
                    this.$emit("save", composant);
                });
            },
            handleDeleteComposant:function(){
                this.ComposantMixins_delete(this.composant).then((composant)=>{
                    this.$emit("save", composant);
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
.libel-problem-row{
    display:flex;
    justify-content:space-between;
    align-items:baseline;
}
</style>
<docs>

    ### vg-composant-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-composant-form></vg-composant-form>
    ```

    ### Create form composant
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-composant-form>
            </vg-composant-form>

        </div>
      `
    })
    ```
    ### update form composant
    ```js
    new Vue({
      data(){
        return {
            composant:{id:1}
        }
      },
      template: `
        <div>
            <vg-composant-form v-model="composant">
            </vg-composant-form>

        </div>
      `
    })
    ```
</docs>
