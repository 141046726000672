<template>
	<div class="vg-reserves-viewer">
		<div v-for="reserve in reserves">
			<vg-equipement-viewer :equipement="reserve.equipement">
			</vg-equipement-viewer>
			<span>{{reserve.commentaireClient}}</span>
		</div>
	</div>
</template>
<script>
	import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-reserves-viewer',
		components:{
			VgEquipementViewer
		},
		mixins: [MaintenanceMixins],
    	props: {
			intervention: {
				type: Object
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas
            };
        },
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				let reservesFilters = {
					isReserve: { attr:"fm.isReserve",colId: "isReserve", value: true, action: "equals" },
					intervention_id: {attr:"fm.intervention_id", colId:"intervention_id", value: this.intervention.id, action:"equals"}
				};
	            this.metadatas.setFilters(reservesFilters);
				this.MaintenanceMixins_getMaintenances(this.metadatas).then((reserves)=>{

				});
			}
		},
        computed: {
			...mapGetters({
                  reserves: 'MaintenancesStore/getReserves'
            })
		}
    };

</script>
<style scoped lang="scss">
.vg-reserves-viewer{
	>div{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
</style>
