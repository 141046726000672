import 'src/directives/TooltipDirective.css';

export default{
    bind: function(el, binding, vnode){
        if(binding.value && binding.value.length!=0){
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let pos = { top: rect.top + scrollTop, left: rect.left + scrollLeft };
            let tooltip = document.createElement('span');
            el.className += " tooltip-directive";
            tooltip.className = "tooltip-directive-text";
            tooltip.innerHTML = binding.value;
            tooltip.style.left = pos.left;
            tooltip.style.top = pos.top;
            tooltip.style.zIndex = 999999;
            el.appendChild(tooltip);
        }
    }
};
