<template>
	<vg-modal ref="mainContainer"
		:title="$t('modal-title')"
		:isSaveDisabled="isSaveDisabled"
		@close="$emit('close')"
		@save="save">
		<template #body>
            <div v-if="isSaveUnable">{{$t("info")}}</div>
			<vg-input
                inputType="datetime-local"
                :title="$t('date')"
                :isRequiredValue="true"
				v-model="dateMouvement" />
			<vg-input inputType="number" 
				:title="$t('quantite')" 
				:isRequiredValue="true"
				v-model="quantite">
			</vg-input>
		</template>
	</vg-modal>
</template>
<script>

	import moment from "moment";
import VgInput from "src/components/Vg/VgInput.vue";
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-consommable-livraison-modal',
		components: {
			VgInput
		},
		mixins:[ ConsommablesMixins ],
        props: {

        },
        data: function() {
            return {
				quantite: 1,
				dateMouvement: moment().format("YYYY-MM-DD HH:mm")
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Livraison consommable",
            "fournisseur": "Fournisseur",
            "quantite": "Quantité",
            "cout-unitaire": "Coût unitaire",
            "date": "Date livraison",
            "info": "Pour ajouter une livraison il faut au préalable associer un fournisseur à ce consommable ainsi qu'un côut unitaire par défaut."
        },
        "en": {
            "modal-title": "Consumable delivery",
            "fournisseur": "Vendor",
            "quantite": "Quantity",
            "cout-unitaire": "Unit cost",
			"date": "Delivery date",
            "info": "A provider and a cost per unit must be defined to save this delivery. "
        },
        "th": {
            "modal-title": "การส่งมอบวัสดุสิ้นเปลือง",
            "fournisseur": "ผู้ขาย",
            "quantite": "ปริมาณ",
            "cout-unitaire": "ต้นทุนต่อหน่วย"
        }
    }
},
		methods: {
			save: function(){
				let stock = Object.assign({}, this.consommable.stock, {
					quantite: Number(this.consommable.stock.quantite)+Number(this.quantite),
					consommation: {
						user_id: this.$app.idUser,
						dateMouvement: this.dateMouvement,
						quantite: Number(this.quantite),
						quantiteInitialeOrigin: this.consommable.stock.quantite,
						flag: "livraison"
					}
				});
				this.ConsommablesMixins_updateStock(this.consommable, stock).then((datas)=>{
					this.$emit("save", datas);
				});
			},
		},
        computed: {
			...mapGetters({
	            consommable: 'ConsommablesStore/getSelectedItem'
	        }),
			isSaveDisabled: function(){
				return !this.quantite || !this.dateMouvement;
			},
		}
    };

</script>
<style scoped>

</style>
