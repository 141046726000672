class TreeConsommableFactory{
    constructor(){

    }
    /**
     *
     * @param {array} consommables
     * @returns string[]
     */
    static getDistinctTagsList(consommables){
        return consommables
            .map((consommable)=>consommable.nature)
            .filter((t, index, self)=>self.indexOf(t)==index)
            .sort((t1,t2)=>t1&&t2?t1.localeCompare(t2):-1);
    }
    /**
     *
     * @param {array} tags
     * @returns array
     */
    static generateTreeFromTags(tags){
        let result = [];
        let level = {result};
        let pathSplitted = null;
        tags.forEach((path) => {
            if(path){
                pathSplitted = path.split("/");
                pathSplitted.reduce((r, label, i, a) => {
                    if(!r[label]){
                        r[label] = {result: [
                            {location: pathSplitted.slice(0, i+1).join("/"), label: label, counter: 0, isNotNode: true}
                        ]};
                        r.result.push({location: pathSplitted.slice(0, i+1).join("/"), label, counter: 0, children: r[label].result});
                    }
                    return r[label];
                }, level);
            }else{
                result.push({location:null, label: null, counter: 0, children:[
                    {location: null, label: null, counter: 0, isNotNode: true}
                ]});
            }
        });
        return result;
    }

    /**
     *
     * @param {array} tree
     * @param {string} tag
     * @returns object node
     */
    static findNode(tree, tag){
        let found = null;
        let index = 0;
        let treeLength = tree.length;
        let node = null;
        while(index<treeLength && !found){
            node = tree[index];
            if(node.hasOwnProperty("location") && node.location == tag && !node.isNotNode){
                found = node;
            }else if(node.children && node.children.length){
                found = this.findNode(node.children, tag);
            }
            index++;
        }
        return found;
    }

    static feedConsommableTree(tree, consommables){
        let node = null;
        consommables.forEach((consommable)=>{
            node = this.findNode(tree, (!consommable.nature||consommable.nature.length==0||consommable.nature==" ")?null:consommable.nature);
            if(node) node.counter = consommable.counter;
        });
        return tree;
    }

    /**
    * arrayToTree
    * @param Array consommables
    * @return Array
    */
    static arrayToTree(consommables){
        let tags = this.getDistinctTagsList(consommables);
        let tree = this.generateTreeFromTags(tags);
        return this.feedConsommableTree(tree, consommables);
    }
}
export default TreeConsommableFactory;
