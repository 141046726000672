<template>
    <div class="vg-body-login" v-app-loader="isCheckingCredentials">
        <div :class="{'login-screen':true ,'vg-background-app': true || true} ">
            <div class="content-login-page">
                   
                    <!--div style="display:flex;justify-content:center;"><vg-files-logo-viewer /></div-->
                    <div>
                        <vg-email-already-taken style="width:100%" v-if="showEmailAlreadyTaken" @click-changer="changerEmail" />
                        <form ref="signup-form"
                            id="signup-form" 
                            onsubmit="return false;" 
                            style="padding: 15px;" 
                            autocomplete="false">
                            <div class="login-img">
                                <img src="static/assets/icone/new-logo-verifgood-jaune-gris.png" alt="logo-Verifgood">
                                    <div v-show="!displayHbForm && !finalValidation" style="display:flex; flex-direction:column;">
                                        <div class="text-engage" v-html="$t('gratuit')"></div>
                                        <div id="text-sans-engagement" v-html="$t('sans-engagement')"></div>
                                    </div>
                            </div>
                            <div class="login-form" >
                                <div v-if="!displayHbForm && !finalValidation">
                                    <div class="hs-fieldtype-text field hs-form-field">
                                        <!--label  :placeholder="$t('email')" for="email">
                                            <span>{{$t('email')}}</span>
                                            <span class="hs-form-required">*</span>
                                        </label-->
                                        <legend class="hs-field-desc" style="display: none;"></legend>
                                        <div class="input" style="margin-bottom:5px;">
                                            <input id="email"  
                                                type="email" name="" 
                                                class="hs-input" 
                                                v-model="email" 
                                                :placeholder="$t('email')" 
                                                required 
                                                autocomplete="new-password" 
                                                onblur="this.setAttribute('readonly', 'readonly');" 
                                                onfocus="this.removeAttribute('readonly');" 
                                                readonly /> 
                                            <div v-if="email && email.length!=0 && !isEmailOk" class="password-not-ok">
                                                <span><i class="fas fa-times"></i> {{ $t("email-incorrect") }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="hs-fieldtype-text field hs-form-field">
                                        <!--label  :placeholder="$t('email')" for="email">
                                            <span>{{$t('password')}}</span>
                                            <span class="hs-form-required">*</span>
                                        </label-->
                                        <legend class="hs-field-desc" style="display: none;"></legend>
                                        <div class="input">
                                            <input id="password" 
                                                type="password" 
                                                class="hs-input" 
                                                v-model="password" 
                                                :placeholder="$t('password')" 
                                                required 
                                                autocomplete="new-password" 
                                                onblur="this.setAttribute('readonly', 'readonly');" 
                                                onfocus="this.removeAttribute('readonly');" 
                                                readonly
                                                pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&\\*-]).{8,}"
                                            />
                                            <div v-if="!isPasswordOk" class="password-not-ok">
                                                <span v-if="!isPasswordContient8Caracteres"><i class="fas fa-times"></i> {{ $t("password-8-caracteres") }}</span>
                                                <span v-if="!isPasswordContientLettreMajuscule"><i class="fas fa-times"></i> {{ $t("password-majuscule") }}</span>
                                                <span v-if="!isPasswordContientLettreMinuscule"><i class="fas fa-times"></i> {{ $t("password-minuscule") }}</span>
                                                <span v-if="!isPasswordContientChiffre"><i class="fas fa-times"></i> {{ $t("password-chiffre") }}</span>
                                                <span v-if="!isPasswordContientCaractereSpecial"><i class="fas fa-times"></i> {{ $t("password-caractere-special") }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="login-footer">
                                        <vg-button id="signup_button"  type="info" 
                                            :disabled="!isEmailOk || !isPasswordOk"
                                            @click="displayAdditionnalForm" 
                                            style="width: 150px;font-size: 14px;font-weight: 900;margin-top:25px;">
                                            {{ $t('cest-parti') }}
                                        </vg-button>
                                         <div style="display:flex;gap: 10px;font-size: 11px;color: #6a6a6a;margin-top:15px;">
                                            <div>{{$t("deja-un-compte")}}</div>
                                            <div><a href="/login" style="color: #6a6a6a;"><u>{{$t("connectez-vous")}}</u></a></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="displayHbForm && !finalValidation" id="hubspot-form-container" ref="hbForm" ></div>
                                <div v-if="showErrorFormMessage" class="password-not-ok">
                                    <span><i class="fas fa-times"></i> {{ $t("veuillez-remplir-required-fields") }}</span>
                                </div>
                                <div v-if="finalValidation" id="final-validation">
                                    <div id="title" class="text-engage">
                                        <h2> 
                                            <i class="fas fa-check-circle" style="color: #50b156;"></i>
                                            {{$t("fantastique")}}
                                        </h2>
                                    </div>
                                    <div id="message">
                                        <p>{{$t("indication-des-actions")}}</p>
                                    </div>
                                    <div style="display:flex;flex-direction:column;">

                                        <div class="actions">
                                            <div style="display:flex; flex-direction:column;"> 
                                                <div style="margin-bottom: 15px;">
                                                    <b>{{$t("decouvrir-tout-seul")}} ,</b> 
                                                    {{$t("nous-sommes-la-si-besoin")}}. 
                                                </div>
                                                <vg-button size="lg" type="info" @click="redirectToDemandeIntervention"> {{$t("explorer")}} </vg-button>
                                            </div>
                                            <div style="display:flex; flex-direction:column;"> 
                                                <div style="margin-bottom: 15px;"> 
                                                    <b>{{$t("ok-pour-coup-de-main")}}, </b> 
                                                    {{$t("trouver-un-creneau")}}.  
                                                </div>
                                                <vg-button size="lg" type="success" @click="redirectToPriseRDV"> {{$t("prendre-rdv")}} </vg-button>
                                            </div>

                                        </div>

                                    </div>
            
                                    <br>
                                    <br>
                                    <br>
                                </div>
                            </div>

                            <input v-show="false"
                                ref="signup-submit"
                                type="submit">
                        </form>



                    </div>
            </div>

        </div>
        <img v-show="false" v-if="accountLogoUrl" :src="accountLogoUrl"/>
    </div>
</template>
<script>
import personalParameters from 'src/mixins/personnalParameters';
import AccountMixins from 'src/mixins/AccountMixins.js';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgEmailAlreadyTaken from 'src/components/Vg/Utils/VgEmailAlreadyTaken.vue';

import pubnubMixins from 'src/mixins/pubnubMixins';
import UserGuidingMixins from 'src/mixins/UserGuidingMixins.js';

export default {
    name: 'signup',
    components:{
        VgButton,
        VgInput,
        VgEmailAlreadyTaken
    },
    mixins:[personalParameters,AccountMixins, pubnubMixins, UserGuidingMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr":{
            "gratuit":"Testez 15 jours l'application",
            "sans-engagement":"<b>Gratuitement</b>, sans engagement, sans CB",
            "cest-parti":"C'est parti !",
            "deja-un-compte":"Déjà un compte ?",
            "debutez-votre-config":"Débutez votre configuration",
            "fantastique":"Fantastique, tout est prêt !",
            "indication-des-actions":"Vous pouvez plonger dans votre période d'essai et découvrir toutes nos fonctionnalités pour faciliter la gestion de votre entreprise",
            "explorer":"Explorer",
            "prendre-rdv":"Prendre rendez-vous",
            "decouvrir-tout-seul":"Découvrir tout seul",
            "nous-sommes-la-si-besoin":"Nous sommes là si besoin",
            "ok-pour-coup-de-main":"Ok pour un coup de main ",
            "trouver-un-creneau":"trouver un créneau",
            "connectez-vous":"Connectez-vous",
            "password-8-caracteres": "Minimum de 8 caractères.",
            "password-majuscule": "Au moins une lettre majuscule.",
            "password-minuscule": "Au moins une lettre minuscule.",
            "password-chiffre": "Au moins un chiffre.",
            "password-caractere-special": "Au moins un caractère spécial.",
            "email-incorrect": "Email incorrecte.",
            "veuillez-remplir-required-fields": "Veuillez remplir les champs requis."
        },
        "en":{
            "gratuit":"Free 15 trial's days",
            "sans-engagement":"Without commitment, without credit card",
            "cest-parti":"let's go!",
            "deja-un-compte":"Already have an account ?",
            "debutez-votre-config":"Start your configuration",
            "fantastique":"Fantastic, all is set up !",
            "indication-des-actions":"Dive in your trial version and discover all our features to make your company management easier.",
            "explorer":"Explore",
            "prendre-rdv":"Schedule a meeting",
            "decouvrir-tout-seul":"Discover by your own",
            "nous-sommes-la-si-besoin":"We are at disposal",
            "ok-pour-coup-de-main":"Ok to be helped",
            "trouver-un-creneau":"Schedule a meeting",
            "connectez-vous":"Sign-in",
            "password-8-caracteres": "Minimum of 8 characters.",
            "password-majuscule": "At least one uppercase letter.",
            "password-minuscule": "At least one lowercase letter.",
            "password-chiffre": "At least one digit.",
            "password-caractere-special": "At least one special character.",
            "email-incorrect": "Incorrect email.",
            "veuillez-remplir-required-fields": "Please complete required fields."
        },
    }
},
    data:function() {
        return {
            step:1,
            email: null,
            organisation: "Groupe Hotels",
            nom: "-",
            prenom: "-",
            password: null,
            confirmPassword: null,
            displayHbForm: false,
            finalValidation: false,
            isCheckingCredentials:false,
            showEmailAlreadyTaken: false,
            showErrorFormMessage: false
        };
    },
    computed:{
        isPasswordContient8Caracteres: function(){
            return this.password && this.password.length>=8;
        },
        isPasswordContientLettreMajuscule: function(){
            if(!this.password) return false;
            let regex = /[A-Z]+/;
            return regex.exec(this.password)!==null?true:false;
        },
        isPasswordContientLettreMinuscule: function(){
            if(!this.password) return false;
            let regex = /[a-z]+/;
            return regex.exec(this.password)!==null?true:false;
        },
        isPasswordContientChiffre: function(){
            if(!this.password) return false;
            let regex = /[0-9]+/;
            return regex.exec(this.password)!==null?true:false;
        },
        isPasswordContientCaractereSpecial: function(){
            if(!this.password) return false;
            let regex = /[#?!@$%^&*-]+/;
            return regex.exec(this.password)!==null?true:false;
        },
        isPasswordOk: function(){
            let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
            return regex.exec(this.password)!==null?true:false;
        },
        isEmailOk: function(){
            let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return regex.exec(this.email)!==null?true:false;
        }
    },
    methods: {
        isRequiredFieldsOk: function(payload){
            return payload.userNom && payload.userNom.length>=2 && payload.userPrenom && payload.userPrenom.length>=2 && payload.activite && payload.activite.length!=0;
        },
        signUp:function(){
            let form = document.querySelector("#hsForm_2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30");
            this.showErrorFormMessage = false;
            let fields = this.getFieldsValue(form);
            let payload = {
                "organisation": fields.company,
                "userNom": fields.lastname,
                "userPrenom": fields.firstname,
                "password": this.password,
                "email": this.email,
                "roles": ["ROLE_ADMIN"],
                "categories": [],
                "lieux": [],
                "activite":fields.votre_activite
            };
            if(this.isRequiredFieldsOk(payload)){
                this.AccountMixins_create(payload).then((response)=>{
                    if(!this.showEmailAlreadyTaken){
                        this.submitHubspotForm();
                        this.finalValidation = true;
                        this.logIn();
                    }
                    //this.redirectToDemandeIntervention();
                });
            }else{ 
                this.isCheckingCredentials = false;
                this.showErrorFormMessage = true;
            }
            return ;
        },
        changerEmail: function(){
            this.showEmailAlreadyTaken = false;
            this.displayHbForm = false;
        },
        submitHubspotForm:function(){
            let form = document.querySelector("#hsForm_2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30");
            form.submit();
        },
        /**
         * extract input and select from HTML form.
         *
         * @param DOMELEMENT form
         * @return Object
         */
        getFieldsValue:function(form){
            let fields = form.querySelectorAll("input");
            let selects = form.querySelectorAll("select");
            let jsonResult = {};
            selects.forEach(select => {
                let name = select.id.split("-")[0];
                let value = select.value;
                name != "" ? jsonResult[name] = value : null;
            });
            fields.forEach(field => {
                let name = field.id.split("-")[0];
                let value = field.value;
                name != "" ? jsonResult[name] = value : null;
            });
            return jsonResult;
        },
        //_____________________________________________________
        // REFACTOR c'est un copier coller depuis Login.vue
        //_____________________________________________________
        getAccountLogo: function(){
            this.VgFilesMixins_getBase64Logo();
        },
        /**
        * @param String storageKey
        * @param String data
        */
        saveInSessionStorage: function(storageKey, data){
            window.sessionStorage.setItem(storageKey, data);
        },
                /**
         * @param integer hours
         * @return string
         */
        setSessionExpirationDate(hours){
            let now = moment().add(hours, 'h');
            return now.format();
        },
        formatUser:function(response){
            return Object.assign({}, response, {
                idUser: response.user,
                fullname: response.prenom && response.prenom.length!=0?response.nom+" "+response.prenom:response.nom,
                role: response.role[0],
                isSupervisor: response.role[0]=="ROLE_ADMIN" && response.isFmClosedBySuperviseur,
                underSupervisor: response.role[0]=="ROLE_TECHNICIEN" && response.isFmClosedBySuperviseur,
                __fconf: JSON.stringify(response.__fconf)
            });
        },
        initAccount: function(response){
            let user = this.formatUser(response);
            this.saveInSessionStorage("user", JSON.stringify(user));
            this.saveInSessionStorage("exclude_categorie_tags", user["exclude_categorie_tags"]);
            this.saveInSessionStorage("exclude_categorie_tags_user_parameter_id", user["exclude_categorie_tags_user_parameter_id"]);
            localStorage.setItem('_expired_at', this.setSessionExpirationDate(10));
            this.isCheckingCredentials=false;
            this.VgFilesMixins_initializeFirebase();
			this.VgFilesMixins_connectToFirebase().then(()=>{
                this.getAccountLogo();
                this.pubnubMixins_connect(user);
                //this.UserGuidingMixins_identify(user);

            });
        },
        logIn: function(){
                if( !this.email.length ) return false;
                if( !this.password.length ) return false;
                this.isCheckingCredentials=true;
                var rc = this.$rc;
                rc.setDispatchResponse(true);
                var query = {
                    'email': this.email,
                    'password': this.password
                };
                rc.post("/api/remote_logins", query, (response)=> {
                    this.isCheckingCredentials = false;
                    /*console.log("*** LOGIN ***");
                    console.log(JSON.stringify(response));*/
                    if( response && response.user ){
                        this.initAccount(response);
                        this.isCheckingCredentials=false;
                    }else if( response && !response.user ){
                        if(response.message == "INVALID_LOGIN") alert(this.$t("desktop.feedback-error-login"));
                        this.isCheckingCredentials=false;
                    }else{  // !response
                        this.isCheckingCredentials=false;
                    }
                    
                }, (err)=>{
                    //console.log(err);
                    this.isCheckingCredentials=false;
                });
                return false;

        },
        //_____________________________________________________
        // REFACTOR ci-dessus c'est un copier coller depuis Login.vue
        //_____________________________________________________

        redirectToDemandeIntervention:function(){
            this.$router.push({ name: '_demande_intervention' });
        },
        redirectToPriseRDV:function(){
            window.open("https://www.verifgood.io/rdv", '_blank').focus();
        },
        validateEmail:function(email){
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        displayAdditionnalForm:function(){
            this.displayHbForm = true;

            let hbForm = this.$refs["hbForm"];
            let email = this.email;
            hbForm.innerHTML = "";
              let hForm = hbspt.forms.create({
                region: "na1",
                portalId: "4502775",
                formId: "2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30",
                onFormSubmitted: (e)=>{

                },
                onFormReady: (form) => {
                    let div = document.createElement("div");
                    div.className = "text-engage";
                    div.append(this.$t("debutez-votre-config"));
                    hbForm.appendChild(div);
                    hbForm.appendChild(form);
                    //let formElement = document.querySelector("#hsForm_2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30");
                    let submitButton = document.querySelector("#hsForm_2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30 > div.hs_submit.hs-submit > div.actions > input")

                    submitButton.addEventListener("click",()=>{
                        this.isCheckingCredentials = true;
                        this.signUp();
                    });

                    let emailInput = document.getElementById("email-2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30");
                    let emailLabel = document.getElementById("label-email-2f506af4-1ac2-4b9d-81d6-c1fc4fa35e30");
                    emailInput.value = email;
                    //emailInput.dispatchEvent(new Event('input', { bubbles: true }));
                    emailInput.style.display = "none";
                    emailLabel.style.display = "none";
                },
            });
        }
    },
    mounted:function(){
        window.addEventListener('RestClientError',(data)=>{
            console.log(data, data.detail);
            if(data.detail == "EMAIL_ALREADY_EXISTS"){
                this.showEmailAlreadyTaken = true;
                this.isCheckingCredentials = false;
            }
        });
    }

};
</script>
<style lang="scss">
#hubspot-form-container{
    @media only screen and (max-width: 768px){
        font-size: 18px;

    }
    .hs-input{
        width: 100%;
        height: 40px;
        padding: 5px;
        margin-bottom: 15px;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        font-size: 16px;
        color: #a0a6ad;
    }
    .hs-input::placeholder {
        font-size: 16px;
        color: #a0a6ad;
    }

    label{
        display:none;
    }
    .hs-fieldtype-select{
        margin-bottom:15px;
    }
    .hs-button{
        height: 47px;
        width: 100%;
        margin-bottom: 15px;
        margin-top:20px;
        border-radius: 4px;
        background-color: #169bd5;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
    }
    .text-engage{
        display: flex;
        gap: 10px;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 15px;
        /*margin-top: 24px;*/
        color: #50b9f3;
    }
    
} 
</style>
<style lang="scss" scoped>
@import "../../style/setting.scss";
$h-input:50px;
.text-engage{
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 26px;
    /*margin-bottom: 15px;*/
    margin-top: 24px;
    color: #50b9f3;
}
#text-gratuit{
    font-weight: 900;
}
#text-sans-engagement{
    margin-bottom: 50px;
    font-size: 19px;
    color: #50b9f3;
}
.hs-input{
    width: 100%;
    height: 50px;
    padding: 15px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
}
.hs-button{
    height: 47px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: #169bd5;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
}

#login_button{
    height:$h-input - 3px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: $maintenance-color;
    color: white;
    border: none;
    font-weight: bold;
    &:hover{
        background-color: #1689d5;
        transition: background-color 1s ease;

    }
}
.actions{
    display: flex;
    gap: 5px;
}
@media only screen and (max-width: 768px){
    .text-engage{
        display: flex;
        gap: 10px;
        justify-content: center;
        font-size: 20px;
        margin-bottom: 15px;
        /*margin-top: 24px;*/
        color: #50b9f3;
    }
    #text-sans-engagement{
        margin-bottom: 50px;
        font-size: 16px;
        color: #50b9f3;
    }
    .actions{
        flex-wrap:wrap;
    }
    .vg-body-login{
        .vg-background-app{
            background-image: url("../../../static/assets/screencapture/other/Sketch002.jpg");
        }
        .login-screen{
            .content-login-page{
                /*width: 85%;*/
                padding: 0px;
                div{
                    margin-bottom:5px;
                }
            }
        }
    }
}

@media only screen and (min-width: 769px){
    .actions{
        flex-wrap:wrap;
    }
    .vg-body-login{
        .vg-background-app{
            background-image: url("../../../static/assets/screencapture/other/Sketch001.jpg");
        }
       
        .login-screen{
            .content-login-page{
                width: 22%;
                padding: 25px;
                div{
                    margin-bottom:20px;
                }
            }
        }
    }
}
.vg-body-login{
    /*background-color: white !important;*/
    height: inherit;


    .login-screen{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content-login-page{
            background-color: white;
            border-radius: 10px;
            height: auto;
            margin: 0px auto;
            >div{
                @media (max-width: 320px){
                    width: 300px;
                }
            }
            .login-img{
                img{
                    /*height: 85px;*/
                    /*width: 200px;*/
                }
                text-align: center;
            }
            .login-form{
                .login-input{
                    width: 100%;
                    margin-bottom:20px;
                    height: $h-input;
                    padding: 0 10px;
                    &:focus{
                        border-color: $maintenance-color;
                        box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, $maintenance-color 0px 0px 8px;
                    }
                }
            }
            .login-footer{
                text-align: center;
                color: #169bd5;
                button{
                    height:$h-input - 3px;
                    width: 100%;
                    margin-bottom: 15px;
                    border-radius: 4px;
                    background-color: #169bd5;
                    color: white;
                    border: none;
                    font-weight: bold;
                    &:hover{
                        background-color: #1689d5;
                        transition: background-color 1s ease;

                    }
                }

            }
        }

    }
}

.password-not-ok{
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  padding: 5px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  i{
    color: red;
    margin-right: 2px;
  }
}

</style>
