<template>
    <img v-if="params.data.isBloquant && params.data.isBloquant=='1'" src="static/assets/icone/maintenance-bloquante-red.svg" style="width:30px;height:30px;"/>
    <span v-else>{{$t("non")}}</span>
    <!--span :class="{'bloquant': params.data.isBloquant && params.data.isBloquant=='1'}">
		{{isBloquant}}
    </span-->
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"bloquant-fm-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "oui": "Oui",
            "non": "Non"
        },
        "en": {
            "oui": "Yes",
            "non": "No"
        },
        "th": {
            "oui": "ใช่",
            "non": "ไม่"
        }
    }
},
        computed:{
            isBloquant: function(){
                return this.params.data.isBloquant && this.params.data.isBloquant=="1"?this.$t("oui"):this.$t("non");
            }
        }
    });
</script>

<style lang="scss" scoped>
.bloquant{
    color: white;
    background-color: red;
    padding: 5px;
    font-weight: bold;
}
</style>
