
<template lang="html">
	<div class="viewer-photo-container">
		<div class="header">

			<span v-if="files && files.length!=0">
				<img v-if="files[currentIndex] && files[currentIndex].isInherited && files[currentIndex].isInherited=='1'"
				src="/static/assets/icone/categorie-equipement-gris.png"
				alt=""
				width="20px" height="20px">
				{{currentIndex+1}} / {{files.length}}
			</span>
		</div>
		<div class="body" height="60px" width="60px">
			<i :class="['fas fa-chevron-left fa-2x',{'inactive':files.length==0 || currentIndex==0}]" @click="decrementCurrentIndex"></i>
			<vg-files-thumbnail v-if="files && files.length!=0"
                :heightImg="getThumbnailSize"
				:file="files[currentIndex]">
			</vg-files-thumbnail>
			<div v-else class="no-files">
				<i class="far fa-2x fa-images"></i>
				<span>{{$t("no-files")}}</span>
			</div>
			<i :class="['fas fa-chevron-right fa-2x',{'inactive':files.length==0 || currentIndex==files.length-1}]" @click="incrementCurrentIndex"></i>

		</div>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
    export default {
        name: 'vg-files-viewer-photo',
	    components: {
			VgButton,
			VgFilesThumbnail
        },
		filters: {

		},
		mixins:[

        ],
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-files": "Aucune photo"
        },
        "en": {
            "no-files": "No photo"
        },
        "th": {
            "no-files": "ไม่มีรูปภาพ"
        }
    }
},
        props: {
			files: {
				type: Array,
				default: []
			}
        },
        data: function() {
            return {
				currentIndex: 0
			};
        },
		watch: {
			files: function(values){

			}
		},
		filters:{

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			decrementCurrentIndex: function(){
				if(this.currentIndex>0){
					this.currentIndex--;
				}
			},
			incrementCurrentIndex: function(){
				if(this.currentIndex<this.files.length-1){
					this.currentIndex++;
				}
			}
		},
		computed: {
            getThumbnailSize:function(){
                return this.$vgutils.isMobile()?"124px":"250px";
            }
		}
	};
</script>

<style lang="scss" scoped>
.viewer-photo-container{
	width: 100%;
	padding: 10px;
	.header{
		>span{
			font-weight: bold;
			font-size: 11px;
			padding: 10px;
		}
		display: flex;
		justify-content: flex-end;
	}
	.body{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		>i{
			color: black;
			cursor: pointer;
		}
		>i:hover{
			border-bottom: 1px solid #f5f7f7;
		}
		>i.inactive{
			color: #e0e0e1;
		}
		>div.no-files{
			color: #e0e0e1;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
</style>
