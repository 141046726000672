<template>
	<div :class="['vg-search-datas',{'vg-search-datas-slot':position=='slot', 'vg-search-datas-lg': size=='lg', 'vg-search-datas-menu': menu}]" v-closable="{exclude: [], handler: 'handleClose'}">
		<div class="search-datas-input-container">
			<input v-model="query"
				:class="{'input-search-listening': isListening}"
				type="text"
				:placeholder="placeholder?placeholder:$t('placeholder')"
				@input="onChangeInput"
				@focus="onFocusInput" />
			<span class="input_suffix" @click="openMicro">
				<span class="input_suffix-inner">
					<vg-icon url="static/assets/icone/input/input-micro.svg" :color="isListening?'#2563EB':'#AFB6BF'" size="18"/>
					<!--i :class="['fas fa-microphone', {'micro-listening': isListening}]"/-->
				</span>
				<span class="input_suffix-inner">
					<vg-icon url="static/assets/icone/input/input-search.svg" color="#AFB6BF" size="18"/>
				</span>
			</span>
		</div>
		<vg-search-datas-results v-if="type=='default' && (isFocused || (searchValue && searchValue.length!=0))" 
			v-model="searchValue"
			:showEmpty="isFocused"
			:class="'search-datas-results-'+position" 
			@filters-change="$emit('filters-change', $event)"
			@close="onCloseResults"/>
		<vg-search-equipements-results v-else-if="type=='lancement' && (isFocused || (searchValue && searchValue.length!=0))" 
			v-model="searchValue"
			:afterSaveRedirectTo="afterSaveRedirectTo"
			:class="'search-datas-results-'+position"
			@filters-change="$emit('filters-change', $event)"
			@close="onCloseResults"/>
	</div>
</template>
<script>
	import VgIcon from 'src/components/Vg/VgIcon.vue';
	import VgSearchDatasResults from 'src/components/Vg/SearchDatas/VgSearchDatasResults.vue';
	import VgSearchEquipementsResults from 'src/components/Vg/SearchDatas/VgSearchEquipementsResults.vue';

    export default {
        name: 'vg-search-datas-filter',
		i18n:    { 
			"locale":navigator.language,
			"messages": {
				"fr": {
					"placeholder": "Recherche globale..."
				},
				"en": {
					"placeholder": "Global search..."
				}
			}
		},
		components: {
			VgIcon,
			VgSearchDatasResults,
			VgSearchEquipementsResults
		},
		mixins: [],
        props: {
			/**
			 * "menu" || "slot"
			 */
			position:{
				type: String,
				default: "menu"
			},
			/**
			 * "default" || "lancement"
			 */
			type:{
				type: String,
				default: "default"
			},
			/**
			 * "sm" || "md" || "lg"
			 */
			size:{
				type: String,
				default: null
			},
			placeholder:{
				type: String,
				default: null
			},
			afterSaveRedirectTo:{
				type: String,
				default: null
			},
			menu:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				query: null,
				searchValue: null,
				timeout: null,
				isFocused: false,
				recognition: null,
				isListening: false
            };
        },
		methods: {
			openMicro: function(){
				console.log("OPEN MICRO");
				if(this.isListening) this.recognition.stop();
				else this.recognition.start();
			},
			handleClose: function(event){
				this.searchValue = null;
				this.isFocused = false;
				this.$emit("close");
			},
			onFocusInput: function(event){
				this.isFocused = this.query && this.query.length!=0?false:true;
				this.searchValue = this.query && this.query.length>=3?this.query:null;
				this.$emit("focused", this.isFocused);
			},
			onChangeInput: function(event){
				this.isFocused = false;
				let value = null;
				let timer = 1;
				if( event && event.target.value ){
					value =  event.target.value;
					timer = 1000;
				}
				if (this.timeout !== null) {
					clearTimeout(this.timeout);
				}
				this.timeout = setTimeout(()=>{
					if(value.length>=3) this.searchValue = value;
				}, timer);
			},
			onCloseResults: function(){
				this.isFocused = false;
				this.searchValue = null;
				this.$emit('close');
			}
		},
		computed: {
			
		},
		mounted: function(){
			this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();

			this.recognition.lang = 'fr-FR';
			this.recognition.interimResults = false;
			this.recognition.maxAlternatives = 1;

			this.recognition.onstart = ()=>{
				this.isListening = true;
			};
			this.recognition.onend = ()=>{
				this.isListening = false;
			};
			this.recognition.onresult = (event)=>{
				console.log("MICRO RECOGNITION", event.results[0][0].transcript);
				this.query = event.results[0][0].transcript;
				this.searchValue = this.query;
			};

		},
		destoyed: function(){
			this.recognition.stop();
		}
    };

</script>
<style lang="scss" scoped>
.vg-search-datas{
	display: block;
	width: inherit;
	height: inherit;
	color: #333 !important;

	/* Conteneur principal */
	.search-datas-input-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 400px; /* Ajustez la largeur selon vos besoins */
	}

	/* Champ de saisie */
	.search-datas-input-container input {
		height: 30px;
		width: 100%;
		padding: 0 50px 0 15px; /* Ajuste le padding à droite pour les icônes */
		border: 1px solid whitesmoke;
		border-radius: 5px;
		outline: none;
		box-sizing: border-box;
	}
	.search-datas-input-container input::-webkit-input-placeholder {
		color: #AFB6BF !important;
		font-size: 14px;
	}

	/* Conteneur pour les icônes */
	.input_suffix {
		position: absolute;
		right: 10px; /* Ajustez selon votre besoin */
		display: flex;
		align-items: center;
		gap: 8px; /* Espace entre les icônes */
		cursor: pointer;
	}
	.search-datas-results-menu{
		position: absolute;
		top: 60px;
		left: inherit;
		min-width: 300px;
	}
	.search-datas-results-slot{
		position: relative;
		min-width: 300px;
	}
}
.vg-search-datas-slot{
	height: 36px !important;
	width: 100% !important;
}
.vg-search-datas-lg{
	height: 50px !important;
	input{
		height: 50px !important;
		max-height: 50px !important;
		font-size: 16px !important;
	}
	input::-webkit-input-placeholder {
		font-size: 16px !important;
	}
}
.vg-search-datas-menu{
	height: 30px;
}
@media screen and (max-width: 991.98px) {
	.vg-search-datas{
		width: 100%;
		.search-datas-results-menu{
			z-index: 999;
			min-width: -webkit-fill-available;
		}
		.search-datas-results-slot{
			min-width: -webkit-fill-available;
		}
	}
}
</style>
