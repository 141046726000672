
<template>
	<div class="w3-container">
		<div class="row">
			<h3>{{$t('title')}}</h3>
		</div>
		<div class="row" >
		</div>
	</div>
</template>

<script>

    export default {
        name: 'parametres-integrations',
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Gestion des intégrations"
        },
        "en": {
            "title": "Intergration management"
        },
        "th": {
            "title": "การจัดการการบูรณาการ"
        }
    }
},
        components: {
			
        },
		mixins:[  ],
        data() {
            return {

            };
        },
        methods: {

        },
        computed: {

        },
		created: function(){

		}
    };
</script>
<style lang="css">

</style>
