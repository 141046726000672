
<template lang="html">
	<img :src="getSessionStorageLogo()"
		height="200"/>
</template>
<script>
    export default {
        name: 'vg-files-logo-viewer',
	    components: {

        },
		mixins:[],
        props: {

        },
        data: function() {
            return {
				logo: null
			};
        },
		watch: {

		},
		created: function(){
			this.logo = window.sessionStorage.getItem('account_logo');
		},
        mounted: function(){

        },
        methods: {
			getSessionStorageLogo: function(){
				return window.sessionStorage.getItem('account_logo');
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
