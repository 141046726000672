<template>

    <iframe
      id="main-iframe"
      style="
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        border: none;
      "
    ></iframe>

</template>
<script>
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
    import equipementsMixins from "src/mixins/equipementsMixins.js";
	import MaintenanceMixins from 'src/mixins/MaintenanceMixins.js';
    import Metadatas from "src/services/Metadatas.js";
    import Pdf from "src/services/Pdf.js";
    import { mapGetters } from 'vuex';

    let styleDesValeurs = {
      fontWeight: 600,
      fontSize: 14,
      color: "#1e1e1e",
    };
    let styleDesLibeller = {
      fontWeight: 100,
      fontSize: 12,
      color: "#adadb1",
    };
    let title = {
      fontWeight: 800,
      fontSize: 24,
      color: "#1e1e1e",
    };
    let subTitle = {
      fontWeight: 100,
      fontSize: 18,
      color: "#1e1e1e",
    };
    let list = {
      fontWeight: 100,
      fontSize: 18,
      color: "#1e1e1e",
    };

    export default {
        name: 'rapport-equipement-pdf',
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "update": "Modifier",
            "Desktop-header-titre": "Equipements",
            "Desktop-header-bouton": "Nouvel équipement",
            "Desktop-filtres-header": "Filtres",
            "Desktop-filtres-localisation": "Localisation",
            "Desktop-filtres-site": "Site",
            "Desktop-filtres-batiment": "Bâtiment",
            "Desktop-filtres-etage": "Etage",
            "Desktop-filtres-pieces": "Pièces",
            "Desktop-filtres-groupe_equipement": "Montrer groupe équipements",
            "Desktop-filtres-etat_equipement": "Etat équipement",
            "Desktop-filtres-caracteristiques": "Caractéristiques",
            "Desktop-filtres-categorie": "Catégorie",
            "Desktop-filtres-marque": "Marque",
            "Desktop-filtres-numero_serie": "Numéro série",
            "Desktop-filtres-type_categorie": "type catégorie",
            "Desktop-table-header-dateVerification": "Date vérification",
            "Desktop-table-header-emplacement": "Emplacement",
            "Desktop-table-header-equipement": "Equipement",
            "Desktop-table-header-numImmobilisation": "Numero comptable",
            "Desktop-table-header-etat": "Etat",
            "Desktop-table-header-QRcode": "QR Code",
            "Desktop-table-header-caracteristiques": "Caractéristiques",
            "Desktop-table-header-numSerie": "N° série",
            "Desktop-table-header-refConstructeur": "Ref. constructeur",
            "Desktop-table-header-miseEnService": "Date Recensement",
            "Desktop-table-header-gep": "GEP",
            "Desktop-table-header-nonConformite": "Non-conformité",
            "Desktop-table-header-autre": "Autre",
            "Desktop-table-header-tag": "compte comptable",
            "Desktop-table-header-tag-categorie": "étiquettes catégorie",
            "Desktop-table-header-sourceFinancement": "Source financement",
            "Desktop-table-header-typeCategorie": "Type catégorie",
            "Desktop-table-header-marque": "Marque",
            "Desktop-table-header-valeurAchat": "Valeur achat",
            "Desktop-table-header-categorie": "Catégorie",
            "Desktop-modaleCreationFM-header": "Création d'une fiche curative sur cet équipement",
            "Desktop-modaleCreationFM-equipement": "Equipement N°",
            "Desktop-modaleCreationFM-urgent": "Urgent",
            "Desktop-modaleCreationFM-enregistrer": "Enregistrer",
            "Desktop-zoom-header-popoverRetour": "Retour vers le tableau des équipements",
            "Desktop-zoom-header-titre": "Détail de l'équipement",
            "Desktop-zoom-header-creerFM": "Créer une fiche curative",
            "Desktop-zoom-numSerie": "Numéro de série",
            "Desktop-zoom-caracteristiques": "Caractéristiques",
            "Desktop-zoom-typeCategorie": "Type de la catégorie",
            "Desktop-zoom-etat": "état",
            "Desktop-zoom-marque": "Marque",
            "Desktop-zoom-fournisseur": "Fournisseur",
            "Desktop-zoom-popover-modificationCaracteristiques": "Modifier les caractéristiques de l'équipement",
            "Desktop-zoom-libelle": "Libellé",
            "Desktop-zoom-gestionCalendaire": "Gestion calendaire",
            "Desktop-zoom-gestionFinanciere": "Gestion financière",
            "Desktop-zoom-popover-modificationInformationsFinancieres": "Modifier les informations financières",
            "Desktop-zoom-documentsAttaches": "Documents attachés",
            "Desktop-zoom-popover-attacherDocument": "Attacher un document à l'équipement",
            "Desktop-zoom-champsPersonnalises": "Champs personnalisés",
            "Desktop-zoom-historique": "Historique",
            "Desktop-zoom-contrats": "Contrats",
            "Desktop-zoom-chronologie": "Chronologie",
            "Desktop-zoom-categorie": "Catégorie",
            "Desktop-zoom-refConstructeur": "Référence constructeur",
            "Desktop-zoom-tagComptable": "Tag comptable",
            "Desktop-zoom-numeroImmobilisation": "Numéro comptable",
            "Desktop-zoom-recensement": "Date du recensement",
            "Desktop-zoom-qrCode": "QrCode",
            "zoom--sorti": "Sorti",
            "Desktop-zoom-posEtiquette": "Position étiquette",
            "Desktop-zoom-dateSortie": "Date de sortie",
            "Desktop-zoom-sortir": "SORTIR",
            "Desktop-zoom-popover-sortirEquipement": "Sortir l'équipement",
            "Desktop-zoom-localisation": "Localisation",
            "Desktop-zoom-dateMiseEnService": "Date de mise en service",
            "Desktop-zoom-ValeurAchat": "Valeur d'achat",
            "Desktop-zoom-tauxDepreciation": "Taux de dépréciation",
            "Desktop-zoom-dureeAmortissement": "Durée d'amortissement",
            "Desktop-zoom-valActualisee": "Valeur actualisée",
            "Desktop-zoom-sourceFinancement": "Source de financement",
            "Desktop-zoom-photos": "Photos",
            "Desktop-zoom-popover-enregistrer": "Enregistrer",
            "Desktop-zoom-popover-annuler": "Annuler",
            "Desktop-zoom-imprimer": "Imprimer la fiche",
            "Desktop-section-consommables": "Consommables / pièces détachées",
            "Desktop-section-consommations": "Consommations",
            "Desktop-section-consommables-nouveau": "Nouveau consommable",
            "Desktop-section-consommables-attacher": "Attacher consommables",
            "modaleRapportAssets-header": "Rapport assets",
            "modaleRapportAssets-footer-btn-exporter": "Exporter",
            "tableauRapportAssets-header-qrCode": "QR Code",
            "tableauRapportAssets-header-libelleCategorie": "Libellé catégorie",
            "tableauRapportAssets-header-libelleEquipement": "Libellé équipement",
            "tableauRapportAssets-header-service": "Service",
            "tableauRapportAssets-header-lieu": "Lieu",
            "tableauRapportAssets-header-tiers": "Tiers",
            "tableauRapportAssets-header-refConstructeur": "Ref. constructeur",
            "tableauRapportAssets-header-numeroSerie": "Numéro de série",
            "tableauRapportAssets-header-etat": "Etat",
            "tableauRapportAssets-header-dateDebut": "Date début",
            "tableauRapportAssets-header-dateFin": "Date fin",
            "tableauRapportAssets-header-moisUtilisation": "Mois d'utilisation",
            "tableauRapportAssets-header-valeurAchat": "Valeur d'achat",
            "tableauRapportAssets-header-tauxDepreciation": "Taux de dépréciation",
            "tableauRapportAssets-header-depreciationAnnuelle": "Dépréciation annuelle",
            "tableauRapportAssets-header-depreciationMensuelle": "Dépréciation mensuelle",
            "Mobile-header-titre": "Equipements",
            "menuMore-scanEquip": "Scanner équipement",
            "menuMore-scanLieu": "Scanner lieu pour voir les equipements contenus",
            "modaleScan-header": "Scanner lieu ou équipement",
            "Mobile-filtres-site": "Site …",
            "Mobile-filtres-batiment": "Bâtiment …",
            "filtre-recherche": "Rechercher",
            "tableauRecensementLe": "Recensement le",
            "tableauA": "à",
            "Mobile-zoom-titre": "Détail",
            "zoom-menuMore-histoVerif": "Voir historique préventif",
            "zoom-menuMore-histoMaint": "Voir historique curatif",
            "zoom-menuMore-deplacerEquipement": "Déplacer équipement",
            "zoom-numeroImmobilisation": "Numéro comptable",
            "zoom-recensementLe": "Recensement le",
            "zoom-a": "à",
            "Mobile-zoom-libelle": "Libellé",
            "Mobile-zoom-categorie": "Catégorie",
            "Mobile-zoom-typeCategorie": "Type",
            "Mobile-zoom-marque": "Marque",
            "Mobile-zoom-refConstructeur": "Ref cons.",
            "Mobile-zoom-path": "Emplacement",
            "Mobile-zoom-etat": "état",
            "Mobile-zoom-caracteristiques": "Caractéristiques",
            "Mobile-zoom-gestionCalendaire": "Gestion calendaire",
            "Mobile-zoom-documentsAttaches": "Documents attachés",
            "Mobile-zoom-consommables": "Consommables",
            "Mobile-zoom-numSerie": "Numéro série",
            "Mobile-zoom-dateAchat": "Date d'achat",
            "Mobile-zoom-dureeGarantie": "Durée de garantie",
            "Mobile-zoom-sousGarantie": "Sous garantie",
            "Mobile-zoom-dateSortie": "Date de sortie",
            "Mobile-zoom-dateRecensement": "Date de recensement",
            "Mobile-zoom-dateMiseEnService": "Date de mise en service",
            "Mobile-zoom-boutonPhoto": "Photo équipement",
            "Mobile-zoom-creationFM": "Créer fiche curative",
            "mobile-zoom-check-equipement": "Check équipement",
            "Mobile-zoom-modaleCreationFM-header": "Création fiche curative",
            "Mobile-zoom-modaleCreationFM-equipement": "Equipement n°",
            "Mobile-zoom-modaleCreationFM-urgent": "Urgent",
            "Mobile-zoom-modaleCreationFM-probleme": "Description du problème",
            "Mobile-zoom-modaleCreationFM-enregistrer": "Enregistrer",
            "zoom-attacherDocument": "Attacher un document",
            "histoMaint-titre": "Historique curatif",
            "histoMaint-tableau-fm": "FM n°",
            "histoMaint-tableau-resolue": "Résolue",
            "histoMaint-tableau-enCours": "En cours",
            "histoVerif-titre": "Historique des vérifications",
            "histoVerif-tableau-a": "à",
            "histo-tableau-vide": "Pas de données",
            "histo-tableau-chargement": "Chargement",
            "sauvegarde_fm": "Sauvegarde de la fiche curative ...",
            "sauvegarde_donnees": "Sauvegarde des données ...",
            "chargement_operations": "Chargement des opérations ...",
            "voir-historique-verification": "Voir préventif",
            "voir-historique-maintenance": "Voir curatif",
            "voir-historique-interventions-periodiques": "Voir intervention périodiques",
            "voir-historique-interventions-ponctuelles": "Voir interventions ponctuelles",
            "Desktop-btn-deplacer": "Déplacer",
            "Desktop-zoom-valeurActualisee": "Valeur actualisée",
            "Desktop-zoom-duree-amortissement": "Ammortissement ( en mois )",
            "sorti": "Sorti",
            "menuMore-replaceQrCode": "Remplacer qrCode"
        },
        "en": {
            "Desktop-header-titre": "Equipment",
            "Desktop-header-bouton": "New equipment",
            "Desktop-filtres-header": "Filters",
            "Desktop-filtres-localisation": "Location",
            "Desktop-filtres-site": "Site",
            "Desktop-filtres-batiment": "Building",
            "Desktop-filtres-etage": "Level",
            "Desktop-filtres-pieces": "Rooms",
            "Desktop-filtres-groupe_equipement": "Show equipment group",
            "Desktop-filtres-etat_equipement": "Equipment status",
            "Desktop-filtres-caracteristiques": "Characteristics",
            "Desktop-filtres-categorie": "Category",
            "Desktop-filtres-marque": "Brand",
            "Desktop-filtres-numero_serie": "Series number",
            "Desktop-filtres-type_categorie": "category type",
            "Desktop-table-header-dateVerification": "Verification date",
            "Desktop-table-header-emplacement": "Position",
            "Desktop-table-header-equipement": "Equipment",
            "Desktop-table-header-numImmobilisation": "Accounting number",
            "Desktop-table-header-etat": "Status",
            "Desktop-table-header-QRcode": "QR Code",
            "Desktop-table-header-caracteristiques": "Characteristics",
            "Desktop-table-header-numSerie": "Series n°",
            "Desktop-table-header-refConstructeur": "Manufacturer ref.",
            "Desktop-table-header-miseEnService": "date of inventory",
            "Desktop-table-header-gep": "GEP ?",
            "Desktop-table-header-nonConformite": "Non-compliant",
            "Desktop-table-header-autre": "Other",
            "Desktop-table-header-tag": "Accounting Tag",
            "Desktop-table-header-tag-categorie": "category tags",
            "Desktop-table-header-sourceFinancement": "Source of financing",
            "Desktop-table-header-typeCategorie": "Category type",
            "Desktop-table-header-marque": "Brand",
            "Desktop-table-header-valeurAchat": "Purchase value",
            "Desktop-table-header-categorie": "Category",
            "Desktop-modaleCreationFM-header": "Create curative sheet",
            "Desktop-modaleCreationFM-equipement": "Equipment N°",
            "Desktop-modaleCreationFM-urgent": "Urgent",
            "Desktop-modaleCreationFM-enregistrer": "Save",
            "Desktop-zoom-header-popoverRetour": "Go back to table of equipment ",
            "Desktop-zoom-header-titre": "Equipment details",
            "Desktop-zoom-header-creerFM": "Create curative sheet",
            "Desktop-zoom-numSerie": "Serial number",
            "Desktop-zoom-caracteristiques": "Characteristics",
            "Desktop-zoom-typeCategorie": "Category type",
            "Desktop-zoom-etat": "state",
            "Desktop-zoom-marque": "Brand",
            "Desktop-zoom-fournisseur": "Supplier",
            "Desktop-zoom-popover-modificationCaracteristiques": "Amend equipment characteristics",
            "Desktop-zoom-libelle": "Heading",
            "Desktop-zoom-gestionCalendaire": "Calendar management",
            "Desktop-zoom-gestionFinanciere": "Financial management",
            "Desktop-zoom-popover-modificationInformationsFinancieres": "Amend financial information",
            "Desktop-zoom-documentsAttaches": "Attached documents",
            "Desktop-zoom-popover-attacherDocument": "Attach a document to the equipment",
            "Desktop-zoom-champsPersonnalises": "Customised fields",
            "Desktop-zoom-historique": "History",
            "Desktop-zoom-chronologie": "Chronology",
            "Desktop-zoom-categorie": "Category",
            "Desktop-zoom-contrats": "Contracts",
            "Desktop-zoom-refConstructeur": "Manufacturer reference",
            "Desktop-zoom-tagComptable": "Accounting tag",
            "Desktop-zoom-numeroImmobilisation": "Accounting number",
            "Desktop-zoom-recensement": "date of inventory",
            "Desktop-zoom-dateSortie": "Date of removal",
            "Desktop-zoom-sortir": "RETIRE",
            "Desktop-zoom-popover-sortirEquipement": "Retire equipment ",
            "Desktop-zoom-localisation": "Location",
            "Desktop-zoom-dateMiseEnService": "Start date",
            "Desktop-zoom-ValeurAchat": "Purchase value",
            "Desktop-zoom-tauxDepreciation": "Annual depreciation",
            "Desktop-zoom-dureeAmortissement": "Depreciation period",
            "Desktop-zoom-valActualisee": "Discounted value",
            "Desktop-zoom-sourceFinancement": "Source of financing",
            "Desktop-zoom-photos": "Photos",
            "Desktop-zoom-popover-enregistrer": "Save",
            "Desktop-zoom-popover-annuler": "Cancel",
            "Desktop-zoom-qrCode": "QrCode",
            "zoom--sorti": "Out",
            "Desktop-zoom-posEtiquette": "Label position",
            "Desktop-zoom-imprimer": "Print sheet",
            "modaleRapportAssets-header": "Assets report",
            "modaleRapportAssets-footer-btn-exporter": "Export",
            "tableauRapportAssets-header-qrCode": "Asset Tag Number",
            "tableauRapportAssets-header-libelleCategorie": "Category of Asset",
            "tableauRapportAssets-header-libelleEquipement": "Full Description",
            "tableauRapportAssets-header-service": "Section",
            "tableauRapportAssets-header-lieu": "Location",
            "tableauRapportAssets-header-tiers": "Supplier's Name",
            "tableauRapportAssets-header-refConstructeur": "Model Number",
            "tableauRapportAssets-header-numeroSerie": "Serial Number",
            "tableauRapportAssets-header-etat": "Condition of Asset",
            "tableauRapportAssets-header-dateDebut": "Start Date",
            "tableauRapportAssets-header-dateFin": "End Date",
            "tableauRapportAssets-header-moisUtilisation": "Months in use",
            "tableauRapportAssets-header-valeurAchat": "Cost",
            "tableauRapportAssets-header-tauxDepreciation": "Depreciation Rate",
            "tableauRapportAssets-header-depreciationAnnuelle": "Annual Depreciation",
            "tableauRapportAssets-header-depreciationMensuelle": "Monthly Depreciation",
            "Mobile-header-titre": "Equipments",
            "menuMore-scanEquip": "Scan equipment",
            "menuMore-scanLieu": "Scan place to see the inside equipments",
            "modaleScan-header": "Scan place or equipment",
            "Mobile-filtres-site": "Site ...",
            "Mobile-filtres-batiment": "Building ...",
            "filtre-recherche": "Search",
            "tableauRecensementLe": "Inventoried on",
            "tableauA": "at",
            "Mobile-zoom-titre": "Detail",
            "zoom-menuMore-histoVerif": "Verifications chronology",
            "zoom-menuMore-histoMaint": "Maintenances chronology",
            "zoom-menuMore-deplacerEquipement": "Displace equipment",
            "zoom-recensementLe": "Inventoried on",
            "Mobile-zoom-etat": "state",
            "zoom-a": "at",
            "Mobile-zoom-libelle": "Label",
            "Mobile-zoom-categorie": "Category",
            "Mobile-zoom-typeCategorie": "Type",
            "Mobile-zoom-marque": "Brand",
            "Mobile-zoom-refConstructeur": "Ref",
            "Mobile-zoom-path": "Location",
            "Mobile-zoom-caracteristiques": "Characteristics",
            "Mobile-zoom-gestionCalendaire": "Calendar management",
            "Mobile-zoom-documentsAttaches": "Attached documents",
            "zoom-numeroImmobilisation": "Accounting number",
            "Mobile-zoom-numSerie": "Serial number",
            "Mobile-zoom-dateAchat": "Purchase date",
            "Mobile-zoom-dureeGarantie": "Warranty period",
            "Mobile-zoom-sousGarantie": "Under warranty",
            "Mobile-zoom-dateSortie": "Removal date",
            "Mobile-zoom-dateRecensement": "Inventory date",
            "Mobile-zoom-dateMiseEnService": "Start date",
            "Mobile-zoom-boutonPhoto": "Equipment photo",
            "Mobile-zoom-creationFM": "Create fiche curative",
            "Mobile-zoom-modaleCreationFM-header": "Create curative sheet",
            "Mobile-zoom-modaleCreationFM-equipement": "Equipment n°",
            "Mobile-zoom-modaleCreationFM-urgent": "Urgent",
            "Mobile-zoom-modaleCreationFM-probleme": "Explain the problem",
            "Mobile-zoom-modaleCreationFM-enregistrer": "Save",
            "zoom-attacherDocument": "Attach file",
            "histoMaint-titre": "Curative chronology",
            "histoMaint-tableau-fm": "Fiche n°",
            "histoMaint-tableau-resolue": "Closed",
            "histoMaint-tableau-enCours": "Ongoing",
            "histoVerif-titre": "Verifications chronology",
            "histoVerif-tableau-a": "at",
            "histo-tableau-vide": "No data",
            "histo-tableau-chargement": "Loading",
            "sauvegarde_fm": "Saving curative sheet ...",
            "sauvegarde_donnees": "Saving data ...",
            "sorti": "Out",
            "chargement_operations": "Loading operations ..."
        }
    }
},
        mixins:[
            equipementsMixins,
            ConsommablesMixins,
            MaintenanceMixins
		],
        props: {},
        components: {},
        data: function() {
            return {
            };
        },
        methods: {
            /**
             * @duplicate from VgEquipementDonneesFinanciere
             * @return string
             */
            getAge:function(startDate){
                let days = moment().diff(startDate, "days");
                let years = 0;
                if(days > 365){
                    years = moment().diff(startDate, "year");
                    let yearsInDays = years * 365;
                    days = days - yearsInDays;
                }
                if(years != 0 ){
                    years = `${years} ${this.$t("years")}`;
                }else{
                    years="";
                }
                return `${years} ${days} ${this.$t("days")}`;
            },
            /**
             * @duplicate from VgEquipementDonneesFinanciere
             * @return float
             */
            percentageActualCostComparedToInitialCost:function(totalCostMaintenance,valeurAchat){
                let percentage = totalCostMaintenance / valeurAchat  *100;
                return Number.parseFloat(percentage).toFixed(1)
            },
            /**
             * formatMaintenances for Pdf output
             *
             * @param  {array} maintenances array of object maintenance
             * @return {array} an array of maintenance object
             */
            formatMaintenances:function(maintenances){
                let finalMaintenances = [];
                maintenances.forEach((maintenance, i) => {
                    finalMaintenances.push(
                        {
                            description:maintenance.commentaireClient || "-",
                            contact:maintenance.contact || "-",
                            dateOuvertureSAV:maintenance.dateOuvertureSAV || "-",
                            dateOuvertureSAV:maintenance.dateFermetureSAV || "-",
                            coutInterne:maintenance.coutInterne+" "+this.$app.devise || "-",
                            coutExterne:maintenance.coutExterne+" "+this.$app.devise || "-",
                            coutConsommations:maintenance.coutConsommations+" "+this.$app.devise || "-",
                            coutTotal:maintenance.coutTotal+" "+this.$app.devise || "-",

                        }
                    );
                });

                return finalMaintenances;
            },

            /**
             * Sum of maintenance.coutTotal.
             *
             * @param  {array} maintenances
             * @return {type}              description
             */
            getCoutTotalMaintenances:function(maintenances){
                let coutTotal = 0;
                maintenances.forEach((maintenance, i) => {
                    coutTotal += parseInt(maintenance.coutTotal);
                });
                return coutTotal;
            }

        },
        computed: {
            ...mapGetters({
                getEquipement: 'EquipementsStore/getSelectedItem',
                maintenances: 'MaintenancesStore/getMaintenances',
            })
        },
		created: function(){
            this.equipementsMixins_getEquipement(this.$route.params.id)
            .then(()=>{
                let metadatas = new Metadatas();
                metadatas.setFilters({
                    equipementId:{attr: "eq.id", value: this.getEquipement.id, action: "equals"}
                })
                this.MaintenanceMixins_getMaintenances(metadatas).then((reponse)=>{
                    let eq = this.getEquipement;
                    let pdf = new Pdf(
                        null,
                        "Rapport Synthèse équipement QRCODE   "+eq.qrCode,
                        null,
                        "Rapport "+eq.libel_equipement+" "+eq.qrCode,
                        null,
                        {
                          orientation: "l",
                          format: "a4",
                        }
                    );
                    pdf.setSpaceBetweenLines(8);
                    pdf.setCurrentXPosition(10);
                    pdf.setCurrentYPosition(30);
                    pdf.addList(
                        [
                            "Libellé : ",
                            "Marque : ",
                            "Fournisseur : ",
                            "Caractéristique : ",
                            "Date de mise en service : ",
                            "Date de recensement : ",
                            "Numéro comptable : ",
                            "Numéro de série : "
                        ],styleDesLibeller
                    );
                    pdf.setCurrentXPosition(60);
                    pdf.setCurrentYPosition(30);
                    pdf.addList([
                        eq.libel_equipement,
                        eq.marque?eq.marque: "",
                        eq.tiers?eq.tiers.name: "",
                        eq.carac_techniques?eq.carac_techniques:"",
                        eq.miseEnService?eq.miseEnService:"",
                        eq.createdAt?eq.createdAt:"",
                        eq.numeroImmobilisation?eq.numeroImmobilisation:"",
                        eq.num_serie?eq.num_serie:""
                    ],styleDesValeurs);



                    let maintenances = this.formatMaintenances(this.maintenances);
                    let coutsMaintenance = this.getCoutTotalMaintenances(maintenances);

                    pdf.addNewTextLine()
                    .addText("Synthèse valeur/cout maintenance",{color:"#00a6e6"})
                    .addNewTextLine();
                    pdf.addNewTextLine();
                    pdf.setCurrentXPosition(10);
                    pdf.addList(
                        [
                            "Valeur acquisition : ",
                            "Total coût maintenance : ",
                            "Age équipement : "
                        ],styleDesLibeller
                    );

                    pdf.setCurrentXPosition(60);
                    pdf.setCurrentYPosition(118);
                    pdf.addList(
                        [
                            eq.valeurAchat+"",
                            coutsMaintenance+" ("+this.percentageActualCostComparedToInitialCost(coutsMaintenance,eq.valeurAchat)+" % de la valeur d'acquisition)",
                            this.getAge(eq.miseEnService)
                        ],styleDesValeurs
                    );

                    if(maintenances.length){
                        let headers = Object.keys(maintenances[0]);
                        pdf.addPage("a4","l")
                        .addText("Maintenances sur : "+eq.libel_equipement)
                        .addNewTextLine()
                        .addTable(headers,maintenances)
                        .addNewTextLine()
                        .addText("Cout total de la maintenance : "+coutsMaintenance+" "+this.$app.devise);
                    }
                    document.getElementById("main-iframe").setAttribute("src", pdf.pdf.output("bloburl"));
                })
            })
		},

    };

</script>

<style lang="scss">

</style>
