<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:defaultDateIntervalle="defaultDateIntervalle"
		key="dashboard-curatif-rapport-repartition-totaux"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<div class="container-totaux">
			<div class="totaux">
				<span class="totaux-row-title">{{ $t("nb-fiches") }}</span>
				<div class="totaux-row" @click="onClickEnCoursActuellement"><div class="red-counter title">{{ totaux.isNotReserves["en-cours-actuellement"] }}</div> <span>{{ $t("en-cours-actuellement") }}</span></div>
				<div class="totaux-row" v-if="$app.isFmClosedBySuperviseur==true" @click="onClickNonValideesActuellement"><div class="red-counter">{{ totaux.isNotReserves["non-validees-actuellement"] }}</div> <span>{{ $t("non-validees-actuellement") }}</span></div>
				<div class="totaux-row" @click="onClickFermetures"><div class="green-counter">{{ totaux.isNotReserves.fermetures }}</div> <span>{{ $t("fermetures") }}</span></div>
				<div class="totaux-row" @click="onClickOuvertures"><div class="red-counter">{{ totaux.isNotReserves.ouvertures }}</div> <span>{{ $t("ouvertures") }}</span></div>
			</div>
			<div class="totaux-reserves" v-if="!$vgutils.isMobile()">
				<span class="totaux-row-title">{{ $t("nb-reserves") }}</span>
				<div class="totaux-row"><div class="red-counter title">{{ totaux.isReserves["en-cours-actuellement"] }}</div> <span>{{ $t("en-cours-actuellement") }}</span></div>
				<div class="totaux-row"><div class="green-counter">{{ totaux.isReserves.fermetures }}</div> <span>{{ $t("fermetures") }}</span></div>
				<div class="totaux-row"><div class="red-counter">{{ totaux.isReserves.ouvertures }}</div> <span>{{ $t("ouvertures") }}</span></div>
			</div>
		</div>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-totaux',
		mixins: [ DashboardMixins ],
		components: {
			VgDatePickerShortcuts,
			VgDashboardCuratifRapport
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Totaux fiches curatives",
			"nb-fiches": "Nbre de fiches",
			"nb-reserves": "Nbre de réserves",
			"en-cours-actuellement": "En cours actuellement",
			"non-validees-actuellement": "Non validées actuellement",
			"ouvertures": "Ouvertes sur la période",
			"fermetures": "Clôturées sur la période",
        },
        "en": {
			"title": "Total curative sheets",
			"nb-fiches": "Sheets number",
			"nb-reserves": "Reserves number",
            "en-cours-actuellement": "Currently open",
			"non-validees-actuellement": "Currently not validated",
			"ouvertures": "Opened during the period",
			"fermetures": "Closed during the period",
        }
    }
},
		watch:{
			totaux: {
				handler: function(value){
					console.log("WATCH TOTAUX", value);
				},
				deep: true
			},
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.fetch();
			},
			onClickEnCoursActuellement: function(){
				let filters = [
					{attr: "fm.statut", value: ["Resolue", "Supervisor"], action: "not_equals"}
				];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			onClickNonValideesActuellement: function(){
				let filters = [
					{attr: "fm.statut", value: "Supervisor", action: "equals"}
				];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			onClickOuvertures: function(){
				let filters = [
					{attr: "fm.dateOuvertureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"}
				];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			onClickFermetures: function(){
				let filters = [
					{attr: "fm.dateFermetureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"}
				];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				if(this.dateIntervalle){
					filters = Object.assign(filters, {
						dateOuvertureSAV: {attr: "fm.dateOuvertureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"},
						dateFermetureSAV: {attr: "fm.dateFermetureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"}
					});
				}
				this.metadatas.setFilters(filters);
				this.DashboardMixins_getCuratifTotaux(this.metadatas).then((datas)=>{

				});
			}
		},
        computed: {
			...mapGetters({
	            totaux: 'DashboardStore/getCuratifTotaux',
	        })
		}
    };

</script>
<style lang="scss" scoped>

.container-totaux{
	width: 100%;
	display: grid;
	grid-template-columns: 70% 30%;
	.totaux{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:flex-start;
		gap:5px;
		padding-left: 30%;
		.totaux-row-title{
			color:#0099ff;
			font-size:12px;
		}
		.totaux-row{
			color:#0099ff;
			font-weight: normal;
			display: grid;
			grid-template-columns: 1fr 4fr;
			align-items: baseline;
			gap:10px;
			font-size:12px;
			.title{
				font-size: 26px !important;
			}
			.red-counter{
				color:#d9001b;
				font-size:20px;
				text-align: right;
				width: 50px;
			}
			.green-counter{
				color:#63a103;
				font-size:20px;
				text-align: right;
				width: 50px;
			}
		}
		.totaux-row:hover{
			cursor:pointer;
			font-weight: bold;
		}
	}
	.totaux-reserves{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:flex-start;
		gap:10px;
		.totaux-row-title{
			color:#0099ff;
			font-size:12px;
		}
		.totaux-row{
			color:#0099ff;
			font-weight: normal;
			display: grid;
			grid-template-columns: 1fr 4fr;
			align-items: baseline;
			gap:10px;
			font-size:12px;
			.red-counter{
				color:#d9001b;
				text-align: right;
			}
			.green-counter{
				color:#63a103;
				text-align: right;
			}
		}
	}
}

</style>
