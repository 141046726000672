<template>
	<div class="main-container">
        <vg-modal
            :title="$t('title')"
    		@close="$emit('close')"
    		>
            <template #body>
                <div v-if="!questionCreateOperation">
                    <vg-input :title="$t('selection-prestataire')"
                    isRequiredValue>
                        <!-- select tiers -->
                        <vg-tiers-selector
                            v-model="selectedTiers"
                            :attributeValue="null"
                            :isClearable="false">
                        </vg-tiers-selector>
        			</vg-input>

                    <vg-input v-if="contacts"
                        :title="$t('contacts')"
			            isRequiredValue>
                        <vg-select
                            v-model="destinataire"
                            :options="emails"
                            >
                        </vg-select>
        			</vg-input>



                    <vg-input :title="$t('expeditor')"
        				v-model="expediteur"
                        isRequiredValue>
        			</vg-input>

                    <vg-input :title="$t('corps-du-message')"
                    isRequiredValue>
                        <!-- <textarea name="name"  cols="74" v-model="message" ></textarea> -->
						<vg-input :inputType="'textarea'" :rows="4" v-model="message"></vg-input>
        			</vg-input>
                    <vg-input :title="$t('recevoir-email')"
        				>
                        <!-- checkbox -->
                        <vg-checkbox :inputValue="isExpediteurReceiveACopy"
                            :defaultChecked="isExpediteurReceiveACopy"
                            @checked="isExpediteurReceiveACopy=true"
                            @unchecked="isExpediteurReceiveACopy=false">
                        </vg-checkbox>
        			</vg-input>


                </div>
            </template>

            <template #footer>

                    <vg-button @click="$emit('close');" >
        				{{ $t("annuler") }}
        			</vg-button>
        			<vg-button :disabled="!isComplete" type="success" @click="handleClickConfirmSendmail">
        				{{ $t("continuer") }}
        			</vg-button>

            </template>
        </vg-modal>
	</div>
</template>
<script>
    import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";

    import VgInput from "src/components/Vg/VgInput.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";


    import TiersMixins from "src/mixins/TiersMixins.js";
    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-fichesav-emails-helper',
		components:{
            VgTiersSelector,
            VgCheckbox,
            VgInput,
            VgSelect,
            VgButton
        },
		mixins:[TiersMixins, MaintenanceMixins],
    	props: {
            emailToSend:{
                type:String,
                default:function(){
                    return "demande-intervention";
                }
            }
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Demande devis par email",
            "corps-du-message": "Informations additionnelles",
            "objet-du-mail": "Objet du mail",
            "sujet-demande-intervention-technique": "Demande de votre intervention technique pour {organisation} sur le site {site}",
            "message-demande-intervention": "Bonjour, \n Pouvez-vous intervenir pour {organisation} sur le site {site}\n Description du problème: {description}\nLocalisation: {piece}\nNuméro de fiche en interne est le n° {maintenanceId}\n\nCordialement, \n{username}\nTel:  {telephone}\nEmail: {expediteur}",
            "message-demande-devis": "Bonjour, \n Pouvez-vous établir un devis pour {organisation} sur le site {site}\n Description du problème: {description}\nLocalisation: {piece}\nNuméro de fiche en interne est le n° {maintenanceId}\n\n Cordialement, \n{username}\nTel:  {telephone}\nEmail: {expediteur}",
            "selection-prestataire": "Selectionner un prestataire",
            "contacts": "Selectionner un destinataire",
            "conserver-trace-email": "Voulez-vous conserver une trace de cet email sur cette fiche ?",
            "non": "Non",
            "oui": "Oui",
            "recevoir-email": "Recevoir une copie du mail",
            "operation-demande-intervention": "Email demande d'intervention à {destinataire} prestataire {prestataire}",
            "operation-demande-devis": "Email demande de devis à {destinataire} prestataire {prestataire}",
            "ouvrir-le-client-email": "Envoyer",
            "continuer": "Continuer",
            "expeditor": "Expéditeur"
        },
        "en": {
            "title": "Quote request by email",
            "corps-du-message": "Additionnal informations",
            "objet-du-mail": "email Subject",
            "recevoir-email": "Send me a copy",
            "sujet-demande-intervention-technique": "technical intervention request for {organisation} on site {site}",
            "message-demande-intervention": "Hello, \n could you intervene for {organisation} located on site {site}\n Description: {description}\n Location: {piece}\n Intervention request reference n° {maintenanceId}\n\n Cordially, \n{username}\nTel:  {telephone}\nEmail: {expediteur}",
            "message-demande-devis": "Hello, \n Could you make a quote for {organisation} located on site {site}\n Description: {description}\n Location: {piece}\n Intervention request reference n° {maintenanceId}\n\n Cordially, \n{username}\nTel:  {telephone}\nEmail: {expediteur}",
            "selection-prestataire": "Choose a service provider",
            "contacts": "Choose the receiver",
            "conserver-trace-email": "Would you keep this email in the operation table?",
            "non": "No",
            "oui": "Yes",
            "operation-demande-intervention": "Intervention request email to {destinataire} service provider {prestataire}",
            "operation-demande-devis": "Quote request email to {destinataire} service provider {prestataire}",
            "ouvrir-le-client-email": "Send",
            "continuer": "Next",
            "expeditor": "Expeditor"
        },
        "th": {
            "title": "การร้องขอการแทรกแซง",
            "corps-du-message": "เนื้อหาของข้อความ",
            "objet-du-mail": "หัวข้ออีเมล",
            "sujet-demande-intervention-technique": "การร้องขอการแทรกแซงทางเทคนิคสำหรับ {organisation} บนหน้างาน {site}",
            "message-demande-intervention": "สวัสดี \n คุณสามารถแทรกแซงสำหรับ {organisation} ที่ตั้งอยู่ที่หน้างาน {site}\n คำอธิบาย: {description}\n ที่ตั้ง: {piece}\n หมายเลขอ้างอิงคำขอการแทรกแซง {maintenanceId}\n\n นับถือ \n{username}\nโทรศัพท์:  {telephone}\nอีเมล: {expediteur}",
            "message-demande-devis": "สวัสดี \n คุณสามารถเสนอราคาสำหรับ  {organisation} ที่ตั้งอยู่ที่หน้างาน {site}\n คำอธิบาย: {description}\n ที่ตั้ง: {piece}\n หมายเลขอ้างอิงคำขอการแทรกแซง {maintenanceId}\n\n นับถือ \n{username}\nโทรศัพท์:  {telephone}\nอีเมล: {expediteur}",
            "selection-prestataire": "เลือกผู้ให้บริการ",
            "contacts": "เลือกผู้รับ",
            "conserver-trace-email": "คุณต้องการเก็บอีเมลนี้ไว้ในตารางปฏิบัติการ?",
            "non": "ไม่",
            "oui": "ใช่",
            "operation-demande-intervention": "คำขอการแทรกแซงอีเมลไปยัง {destinataire} ผู้ให้บริการ {prestataire}",
            "operation-demande-devis": "คำขอการเสนอราคาอีเมลไปยัง {destinataire} ผู้ให้บริการ {prestataire}",
            "ouvrir-le-client-email": "ส่ง",
            "continuer": "ถัดไป"
        }
    }
},
        data: function() {
            return {
				selectedTiers: null,
                expediteur: this.$app.email,
                sujet: "",
                message: "",
                contacts:null,
                destinataire:null,
                questionCreateOperation:false,
                isExpediteurReceiveACopy:true
            };
        },
        watch:{
            selectedTiers:{
                handler:function(newValue){
                    this.contacts = newValue && newValue.contacts? newValue.contacts : null;
                }
            }
        },
		mounted: function(){
		},
		methods: {
            handleClickConfirmSendmail: function(){
                let emailDemandeDevisPayload = {
                    "body": this.message,
                    "receivers": this.emails,
                    "user_id":this.$app.idUser,
                    "tiers_id":this.selectedTiers.id,
                    "responseAdress": this.expediteur
                }
                if(this.isExpediteurReceiveACopy){
                    if(emailDemandeDevisPayload.receivers.indexOf(this.expediteur) == -1 ){
                        emailDemandeDevisPayload.receivers.push(this.expediteur);
                    }
                }
                this.MaintenanceMixins_demandeDevis(this.getSelectedMaintenance.id,emailDemandeDevisPayload).then(()=>{
                    this.$emit("close");
                });


            }
        },
        computed: {
            ...mapGetters({
                  getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance',
                  getSelectedTier: 'TiersStore/get'
            }),
            /**
            * @return string
            */
            organisation:function(){
                return this.getSelectedMaintenance.equipement.path.split("/")[0];
            },
            /**
            * @return string
            */
            site:function(){
                return this.getSelectedMaintenance.equipement.path.split("/")[1];
            },
            /**
            * @return string
            */
            batiment:function(){
                return this.getSelectedMaintenance.equipement.path.split("/")[2];
            },
            /**
            * @return string
            */
            etage:function(){
                return this.getSelectedMaintenance.equipement.path.split("/")[3];
            },
            /**
            * @return string
            */
            piece:function(){
                return this.getSelectedMaintenance.equipement.path.split("/")[4];
            },
            /**
            * @return array
            */
            emails:function(){
                return this.contacts.map((contact)=>{
                    return contact.email;
                });
            },
            /**
            * @return array
            */
            isComplete:function(){
                return this.destinataire && this.message;
            }

        }
    };

</script>
<style scoped lang="scss">

</style>
