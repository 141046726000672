<template>
    <div>
        <div v-if="params.type === 'pret'">
            <span>{{ $t('pret') }}</span>
        </div>
        <div v-else-if="params.type === 'retour'">
            <span>{{ $t('retour') }}</span>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
        i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    "pret":"Sortie temporaire",
                    "retour":"Retour"
                }
            },
        },
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            type: {
                type: String,
                required: true,
                default: ''
            }
		}
    });
</script>

<style lang="scss" scoped>

</style>
