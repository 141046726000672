<template>
    <vg-select
        v-model="selected"
        :options="getFamilles"
        :attributeLabel="'value'"
        :attributeValue="'value'"
        @input="handleInputChange">
    </vg-select>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import LieuMixins from "src/mixins/LieuMixins.js";
import Metadatas from "src/services/Metadatas.js";
export default {
    name: 'vg-lieu-famille-selector',
    mixins:[ LieuMixins ],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
        onlyExisting: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "culturel": "Culturel",
            "cultuel": "Cultuel",
            "scolaire": "Scolaire",
            "sportif": "Site sportif",
            "administration": "Administration",
            "logement": "Logement",
            "terrain": "Terrain",
            "association": "Association",
            "creche": "Crêche",
            "aire-de-jeu": "Aire de jeu",
            "divers": "Divers"
        },
        "en": {
            "culturel": "Cultural",
            "cultuel": "Religious",
            "scolaire": "School",
            "sportif": "Sports site",
            "administration": "Administration",
            "logement": "Housing",
            "terrain": "Field",
            "association": "Association",
            "creche": "Nursery",
            "aire-de-jeu": "Play area",
            "divers": "Various"
        }
    }
},
    data: function() {
        return {
            selected:this.value,
            familles: [],
            localFamilles:[
                {value: this.$t("culturel"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("cultuel"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("scolaire"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}},
                        {libel_lieu: "Classes", categorie: {libelleCatgorie: "Salle de classe"}}
                    ],
                    equipements: []
                },
                {value: this.$t("sportif"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("administration"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("logement"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("terrain"),
                    pieces: [],
                    equipements: []
                },
                {value: this.$t("association"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ],
                    equipements: []
                },
                {value: this.$t("creche"),
                    pieces: [
                        {libel_lieu: "Espaces extérieurs", categorie: {libelleCatgorie: "Espace vert"}},
                        {libel_lieu: "Bureaux", categorie: {libelleCatgorie: "Espace privé"}},
                        {libel_lieu: "Couloirs", categorie: {libelleCatgorie: "Couloir"}},
                        {libel_lieu: "Sanitaires", categorie: {libelleCatgorie: "Sanitaire"}}
                    ]
                },
                {value: this.$t("aire-de-jeu"),
                    pieces: [],
                    equipements: []
                },
                {value: this.$t("divers"),
                    pieces: [],
                    equipements: []
                }
            ]
        };
    },
	created: function(){
        this.fetchFamilles();
	},
    methods:{
        /**
        * Emit input .
        * @event input
        * @param String famille_value
        */
        handleInputChange: function(famille){
            this.$emit("input", famille);
            this.$emit("set-predefined-pieces", famille?this.familles.find((data)=>data["value"]==famille)["pieces"] || []:[]);
            this.$emit("set-predefined-equipements", famille?this.familles.find((data)=>data["value"]==famille)["equipements"] || []:[]);
        },
        fetchFamilles: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters({type_lieu:{attr:"l.type_lieu",value:"Site",action:"equals"},famille:{attr:"l.famille",value:1,action:"is_not_null"}});
            metadatas.setColumns({"DISTINCT l.famille": null});
            this.LieuMixins_getLieux(metadatas).then((datas)=>{
                this.$store.dispatch("LieuxStore/setFamilles", this.LieuMixins_getFamilles(datas.lieux));
                if(this.onlyExisting){
                    this.familles = datas.lieux.map((data)=>{
                        return {value: data.famille, label: data.famille, pieces: []};
                    });
                }else{
                    let familles = datas.lieux.filter((value, index, self)=>self.findIndex((item)=>item.famille==value.famille)==index && this.localFamilles.findIndex((item)=>item.value==value.famille)==-1);
                    this.familles = this.localFamilles.concat(familles.map((data)=>{
                        return {value: data.famille, label: data.famille, pieces: []};
                    }));
                }
                this.$emit("familles-fetched", this.familles);
            });
        },
    },
    computed:{
        getFamilles: function(){
            return this.familles.sort((f1, f2)=>f1.value-f2.value);
        }
    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-lieu-famille-selector:

</docs>
