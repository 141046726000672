<template lang="html">
    <div class="composantmobilecellrender">
        <div class="type">
            <img :src="params.icon" height="30">
            <div class="">
                {{params.typeComposant}}
            </div>
        </div>
        <div class="name">
            {{params.libelComposant}}
        </div>

    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    mixins:[LangMixins],
    props: {
        params: {
            type: Object,
        },
    },


})
</script>

<style lang="scss">
.composantmobilecellrender{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    .type,.name{
        width: 50%;
        display: flex;
        img{
            margin:0;
            margin-right: 10px
        }
        justify-content: center;
    }
}
</style>
