<template>
    <div class="vg-progressions-table">
        <tag-grid
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="progressions"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick" />
    </div>
</template>
<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import DashboardCellRender from "src/components/Vg/TagGrid/DashboardCellRender.vue";
import VerificationMixins from "src/mixins/VerificationMixins.js";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-progressions-table',
    components:{
        TagGrid
    },
    mixins:[ VerificationMixins ],
    props: {
        id:{
            type: String,
            default: "progressions-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: null
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement de la progression...",
                "overlay-no-rows-text": "Aucune progression correspondant au filtrage"
            },
            "en": {
                "overlay-loading-text": "Loading progression...",
                "overlay-no-rows-text": "No progression matching the filtering"
            }
        }
    },
    data: function() {
        return {
            agfilters: {
                searchQuery_libelleCatgorie: {attr: "c.libelleCatgorie", value: null, action: "contains", openParenthesis: true},
                searchQuery_libel_tache: {attr: "t.libel_tache", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true}
            },
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: 0,
                rowHeight: this.$vgutils.isMobile() ? 130 : 160
            },
            columnDefs: [
                {
                    headerName: "Libelle tache",
                    width: 180,
                    field: 'libel_tache',
                    colId: 'libel_tache',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new DashboardCellRender({
                        propsData: {
                            params: params.data
                        }
                    }).$mount().$el,
                },
                {
                    headerName: "date_butoire",
                    width: 180,
                    field: 'date_butoire',
                    colId: 'date_butoire',
                    hide: true,
                },
                {
                    headerName: "libelleCatgorie",
                    width: 180,
                    field: 'libelleCatgorie',
                    colId: 'libelleCatgorie',
                    hide: true,
                }
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.fetch();
            },
            deep: true
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        this.fetch();
    },
    computed: {
        ...mapGetters({
            progressions: "ProgressionsStore/getCollection"
        })
    },
    methods:{
        setSearchQueryFilters: function(query){
            if(query && query.length!=0){
                this.agfilters.searchQuery_libelleCatgorie.value = query;
                this.agfilters.searchQuery_libel_tache.value = query;
            }else{
                this.agfilters.searchQuery_libelleCatgorie.value = null;
                this.agfilters.searchQuery_libel_tache.value = null;
            }
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            this.agfilters = Object.assign({}, this.agfilters, this.filters);
            this.setSearchQueryFilters(this.searchQuery);
            this.metadatas.setFilters(this.agfilters);
            this.VerificationMixins_getProgression(null, this.metadatas).then((result)=>{
                this.showLoadingOverlay = false;
            });
        },
        goToZoomProgression:function(tacheId){
            this.$router.push({name:"_progression",params:{"tacheId":tacheId}});
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
            this.$store.dispatch("ProgressionsStore/setSelectedItem", row.data);
            this.goToZoomProgression(row.data.id);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            if(this.$vgutils.isMobile()) this.handleDblClick(row);
            else this.$emit("row-click",row);
        },
    }
};
</script>
<style lang="scss" scoped>
.vg-progressions-table{
    height: 100%;
}
</style>