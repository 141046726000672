<template>
    <vg-app-layout
       :title="$t('title-page',{numero:fiche?fiche.numero:''})"
       :icon="'lieux.png'"
       :colorheader="getHeaderColor"
       :isZoomLayout="true"
       :isActionButtonDisplay="false"
       :isLoadingState="isLoadingState"
       @backpage="handleBackPage">
       <template v-slot:create-button>
            <vg-button v-if="fiche && fiche.statut!='resolue'"
                type="default-danger" @click="showActions=true;">
                {{$t("actions")}}
            </vg-button>
		</template>
       <template v-slot:primary>
           <vg-collapse :title="$t('details')" :type="'section'" :collapseByDefault="false" >
               <template v-slot:pretitle>

               </template>
               <template v-slot:content>
                   <vg-libelle-editable :label="$t('numero')">
                       <template v-slot:read>{{fiche.numero}}</template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('statut')">
                       <template v-slot:read>
                           <vg-fiche-demande-consommables-statut-viewer :fiche="fiche" />
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('site')">
                       <template v-slot:read>{{fiche.site.libel_lieu}}</template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('service-demandeur')">
                       <template v-slot:read>{{fiche.serviceDemandeur}}</template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('demandeur')">
                       <template v-slot:read>{{fiche.nomDemandeur}}</template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('consommables')">
                       <template v-slot:read>
                            <div v-for="(item, index) in fiche.items" :key="index" style="display:flex;justify-content:flex-start;align-items:center;gap:5px;">
                                <span>{{item.quantite}} {{item.consommable.unite}}</span>
                                <span>{{item.consommable.name}} <small>{{item.consommable.refExterne}}</small></span>
                                <span v-if="item.maintenance_id">{{$t("maintenance-numero",{numero: item.maintenance_id})}}</span>
                            </div>
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('commentaire')">
                       <template v-slot:read>{{fiche.description}}</template>
                   </vg-libelle-editable>
               </template>

           </vg-collapse>

           <vg-collapse :title="$t('gestion-calendaire')" :type="'section'" :collapseByDefault="false" >
               <template v-slot:pretitle>
                   <vg-button
                       size="md"
                       type="default-round">
                       <i class="far fa-calendar"></i>
                   </vg-button>
               </template>
               <template v-slot:content>
                   <vg-libelle-editable :label="$t('date-demande')">
                       <template v-slot:read>
                           <vg-datetime-viewer
                               v-model="fiche.dateCreation">
                           </vg-datetime-viewer>
                       </template>
                   </vg-libelle-editable>
                   <vg-libelle-editable :label="$t('date-cloture')">
                       <template v-slot:read>
                           <vg-datetime-viewer
                               v-model="fiche.dateCloture">
                           </vg-datetime-viewer>
                        </template>
                   </vg-libelle-editable>
               </template>
           </vg-collapse>

           <!--vg-collapse :title="$t('documents')" :type="'section'" :collapseByDefault="false" >
               <template v-slot:pretitle>
                   <vg-button
                       size="md"
                       type="default-round"
                       @click="isUploaderDisplayed=!isUploaderDisplayed;">
                       <i class="fas fa-plus"></i>
                   </vg-button>
               </template>
               <template v-slot:content>
                   <vg-files-uploader
                        v-show="isUploaderDisplayed"
                       :tagsList="VgFilesMixins.tags"
                       @upload-done="VgFilesMixins_fetchFilesByUid();" >
                   </vg-files-uploader>
                   <vg-files-viewer-list-section
                       :files="VgFilesMixins_filesListSection"
                       @delete="VgFilesMixins_fetchFilesByUid()">
                   </vg-files-viewer-list-section>
               </template>
           </vg-collapse-->

           <div v-if="$vgutils.isMobile()">
               <hr>
               <vg-collapse :title="$t('section-consommables-livres')"
                   :type="'section'"
                   :collapseByDefault="false">
                   <template #content>
                       <div v-if="fiche && fiche.items">
                           <div v-for="item in getFicheItems">
                               <span>{{item.quantiteLivree}} / {{item.quantite}}</span> <span>{{item.consommable.name}}</span>
                           </div>
                       </div>
                   </template>
               </vg-collapse>
               <hr>
               <vg-collapse :title="$t('section-bons-de-sortie')"
                   :type="'section'"
                   :collapseByDefault="false">
                   <template v-slot:content>
                       <vg-bons-de-sortie-table v-if="fiche"
                            :filters="bonsDeSortieFilters"/>
                   </template>
               </vg-collapse>
           </div>

       </template>
       <template v-slot:secondary>
           <vg-collapse :title="$t('section-bons-de-sortie')"
               :type="'section'"
               :collapseByDefault="false">
               <template v-slot:titlecomplement>
                   <vg-button type="info" @click="showCreateBonDeSortie=true;">
                       {{$t("nouveau-bon-de-sortie")}}
                   </vg-button>
               </template>
               <template v-slot:content>
                   <vg-bons-de-sortie-table v-if="fiche"
                        :filters="bonsDeSortieFilters"/>
               </template>
           </vg-collapse>
           <br>
           <vg-collapse :title="$t('section-consommables-livres')"
               :type="'section'"
               :collapseByDefault="false">
               <template #titlecomplement>
                   <span v-if="fiche && fiche.items && isItemsTousLivres" style="border:1px solid #35B1EA;padding:0 5px;font-size:12px;"><i class="fa fa-check"/> {{$t("consommables-tous-livres")}}</span>
               </template>
               <template #content>
                   <div v-if="fiche && fiche.items">
                       <div v-for="item in getFicheItems">
                           <span>{{item.quantiteLivree}} / {{item.quantite}}</span> <span>{{item.consommable.name}}</span>
                       </div>
                   </div>
                   <br>
                    <vg-consommable-mouvements-table v-if="fiche && fiche.id"
                        :filters="consommationsFilters"/>
               </template>
           </vg-collapse>
       </template>

       <template #footer v-if="$vgutils.isMobile() && fiche && fiche.statut!='resolue'">
           <vg-button type="default-link" @click="showCreateBonDeSortie=true;">
               {{$t("nouveau-bon-de-sortie")}}
           </vg-button>
           <vg-button
               type="default-link" @click="showActions=true;">
               {{$t("actions")}}
           </vg-button>
       </template>

        <vg-bon-de-sortie-form v-if="showCreateBonDeSortie"
            @close="showCreateBonDeSortie=false;"
            @save="onSaveBonDeSortie"
            @loading="loadingState"/>

        <vg-modal v-if="showActions"
            :title="$t('modal-actions-title')"
            @close="showActions=false;">
            <template #body>
                <vg-button v-if="fiche.statut!='prise-en-compte'" type="info" style="width:100%;margin-bottom:10px;" @click="prendreEnCompte">
                    {{$t("prendre-en-compte")}}
                </vg-button>
                <vg-button v-if="fiche.statut!='en-attente'" type="info" style="width:100%;margin-bottom:10px;" @click="mettreEnAttente">
                    {{$t("mettre-en-attente")}}
                </vg-button>
                <vg-button v-if="fiche.statut!='resolue'" type="info" style="width:100%;" @click="closeFiche">
                    {{$t("close")}}
                </vg-button>
            </template>
        </vg-modal>

    </vg-app-layout>
</template>
<script>
import FicheDemandeConsommablesMixins from "src/mixins/FicheDemandeConsommablesMixins.js";
import BonsDeSortieMixins from "src/mixins/BonsDeSortieMixins.js";
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgModal from "src/components/Vg/VgModal.vue";

import VgLibelleEditable from "src/components/Vg/Forms/VgLibelleEditable.vue";
import VgTabs from "src/components/Vg/VgTabs.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

import VgBonsDeSortieTable from "src/components/Vg/BonsDeSortie/VgBonsDeSortieTable.vue";
import VgBonDeSortieForm from "src/components/Vg/Forms/VgBonDeSortieForm.vue";
import VgConsommableMouvementsTable from "src/components/Vg/Consommables/VgConsommableMouvementsTable.vue";
import VgFicheDemandeConsommablesStatutViewer from "src/components/Vg/FicheDemandeConsommables/VgFicheDemandeConsommablesStatutViewer.vue";

import Metadatas from "src/services/Metadatas";

import { mapGetters } from 'vuex';
export default {
	name: "fiche-demande-consommable",
    mixins:[ FicheDemandeConsommablesMixins, BonsDeSortieMixins, ConsommablesMixins ],
	props:{
		//value : Object
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title-page": "Fiche demande consommables n° {numero}",
            "section-bons-de-sortie": "Bons de sortie",
            "section-consommables-livres": "Consommables livrés",
            "details": "Détails",
            "numero": "Numéro",
            "statut": "Statut",
            "commentaire": "Commentaire",
            "consommables": "Consommables",
            "site": "Site",
            "service-demandeur": "Service demandeur",
            "demandeur": "Demandeur",
            "gestion-calendaire": "Gestion calendaire",
            "date-demande": "Date demande",
            "date-cloture": "Date cloture",
            "maintenance-numero": "Fiche n° {numero}",
            "nouveau-bon-de-sortie": "Nouveau bon de sortie",
            "modal-actions-title": "Actions",
            "prendre-en-compte": "Prendre en compte la demande",
            "mettre-en-attente": "Mettre en attente la demande",
            "close": "Clôturer la demande",
            "actions": "Actions",
            "consommables-tous-livres": "Consommables tous livrés"
        },
        "en": {
            "title-page": "Consumables request form n° {numero}",
            "section-bons-de-sortie": "Exit vouchers",
            "section-consommables-livres": "Delivered consumables",
            "details": "Details",
            "numero": "Number",
            "statut": "Status",
            "commentaire": "Comment",
            "consommables": "Consumables",
            "site": "Site",
            "service-demandeur": "Requesting service",
            "demandeur": "Requester",
            "gestion-calendaire": "Calendar management",
            "date-demande": "Request date",
            "date-cloture": "Closing date",
            "maintenance-numero": "Sheet n° {numero}",
            "nouveau-bon-de-sortie": "New exit voucher",
            "modal-actions-title": "Actions",
            "prendre-en-compte": "Take into account the request",
            "mettre-en-attente": "Put the request on hold",
            "close": "Close the request",
            "actions": "Actions",
            "consommables-tous-livres": "All consumables delivered"
        }
    }
},
	components:{
        VgButton,
        VgLibelleEditable,
        VgCollapse,
        VgModal,
        VgTabs,
        VgDatetimeViewer,
        VgBonsDeSortieTable,
        VgBonDeSortieForm,
        VgConsommableMouvementsTable,
        VgFicheDemandeConsommablesStatutViewer
    },
    data:function(){
        return {
            showCreateBonDeSortie: false,
            showActions: false,
            isLoadingState:false
        }
    },
    watch: {

    },
    created:function(){
        this.fetch();
    },
    computed:{
        ...mapGetters({
            fiche: "FicheDemandeConsommablesStore/getSelectedItem",
            consommations: "ConsommableMouvementsStore/getCollection",
        }),
        bonsDeSortieFilters: function(){
            return {
                ficheDemandeConsommable_id: {attr: "bs.ficheDemandeConsommable_id", value: this.fiche?this.fiche.id:null, action: "equals"}
            };
        },
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        consommationsFilters: function(){
            return {
                ficheDemandeConsommable_id: {attr: "cm.ficheDemandeConsommable_id", value: this.fiche?this.fiche.id:null, action: "equals"}
            };
        },
        getFicheItems: function(){
            let consommations = this.consommations;
            let totalConsomme = 0;
            return this.fiche.items.map((item)=>{
                totalConsomme = consommations.filter((consommation)=>consommation.consommable_id==item.consommable_id)
                    .map((consommation)=>consommation.quantite)
                    .reduce((partialSum, quantite) => Number(partialSum) + Number(quantite), 0);
                return Object.assign({}, item, {
                    quantiteLivree: totalConsomme
                });
            });
        },
        isItemsTousLivres: function(){
            return this.getFicheItems.findIndex((item)=>item.quantite!=item.quantiteLivree)==-1;
        }
    },
    methods:{
        loadingState: function(){
            this.isLoadingState = true;
        },
        closeFiche: function(){
            this.FicheDemandeConsommablesMixins_close(this.fiche).then((datas)=>{
                this.handleBackPage();
            });
        },
        fetchConsommations: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.consommationsFilters);
            this.ConsommablesMixins_getConsommations(metadatas).then((datas)=>{

            });
        },
        fetchBonsDeSortie: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.bonsDeSortieFilters);
            this.BonsDeSortieMixins_getBonsDeSortie(metadatas).then((datas)=>{

            });
        },
        onSaveBonDeSortie: function(data){
            if(data.automaticallyClose) this.closeFiche();
            this.showCreateBonDeSortie = false;
            this.isLoadingState = false;
            this.fetchConsommations();
            this.fetchBonsDeSortie();
        },
        fetch:function(){
            this.FicheDemandeConsommablesMixins_getFiche(this.$route.params.id).then((datas)=>{
                //this.VgFilesMixins_init(datas.lieux[0].uid, "lieu");
            });
        },
        handleBackPage:function(){
            this.$router.go(-1);
        },
        prendreEnCompte: function(){
            this.showActions = false;
            this.FicheDemandeConsommablesMixins_priseEnCompte(this.fiche).then((datas)=>{

            });
        },
        mettreEnAttente: function(){
            this.showActions = false;
            this.FicheDemandeConsommablesMixins_enAttente(this.fiche).then((datas)=>{

            });
        },
    },
    destroyed: function(){
        this.$store.dispatch("FicheDemandeConsommablesStore/setSelectedItem", null);
        this.$store.dispatch("BonsDeSortieStore/clear");
    }
}

</script>
<style lang="scss" scoped>

</style>
