<template>
    <vg-lieu-path-viewer class="path-cell-render"
        v-model="path"
        :onlyPath="onlyPath">
    </vg-lieu-path-viewer>
</template>

<script>
    import Vue from "vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"path-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            path: {
                type: String,
                required: true,
                default: "default"
            },
            onlyPath:{
                type: Boolean,
                default: 0
            }
		},
        components: {
            VgLieuPathViewer
        },
        computed: {
            getSiteBatimentEtage: function(){
                if(!this.path) return "";
                return this.path.split("/").slice(1,-1).join("/");
            },
            getPiece: function(){
                if(!this.path) return "";
                return this.path.split("/").pop();
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>
