<template>
    <div class="vg-timeline">
        <div style="width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;">
            <vg-button
                :type="'info'"
                style="width:100%"
                @click="onClickShowList">
                <i v-if="showList" class="far fa-calendar-alt"></i>
                <i v-else class="fas fa-th-list"></i>
                <span style="margin-left:10px;">{{showList?$t("switch-calendar"):$t("switch-list")}}</span>
            </vg-button>
        </div>
        <vg-calendar-picker v-if="!showList"
            :events="events"
            @focused-date="onFocusDate"
            style="margin-bottom:5px;"/>
        <div class="vg-timeline-container" v-if="!showList">
            <div class="vg-timeline-container-info" v-if="filteredMaintenances.length==0" style="margin-top:20px;">
                <span class="default-empty-text" style="margin-right:5px;">{{$t("aucune-donnee")}}</span>
                <vg-datetime-viewer v-model="start" hideTime style="font-weight:bold;"/>
            </div>
            <div class="vg-timeline-item" v-for="item in filteredMaintenances" :key="item.id"
                @click="goToMaintenance(item)"
                v-longpress:[item]="onLongpressMaintenance">
                <fm-cell-render
                    :fm="item"
                    showAffectation/>
            </div>
        </div>
        <div class="vg-timeline-container" v-else-if="showList">
            <div style="display:flex;justify-content:space-between;align-items:center;width:100%;margin-top:10px;">
                <vg-button :type="'grey'" style="min-width:20vw;"
                    @click="goToPreviousMonth">
                    {{$t("mois-precedent")}}
                </vg-button>
                <vg-datetime-viewer v-model="start" :format="'MMMM YYYY'" style="font-size:16px;"/>
                <span style="color:white;background-color:#4fbeff;padding:2px 5px;font-weight:bold;font-size:11px;border-radius:100%;">
                    {{maintenances.length}}
                </span>
                <vg-button :type="'grey'" style="min-width:20vw;"
                    @click="goToNextMonth">
                    {{$t("mois-suivant")}}
                </vg-button>
            </div>
            <div class="vg-timeline-container-info" v-if="filteredMaintenances.length==0" style="margin-top:20px;">
                <span class="default-empty-text" style="margin-right:5px;">{{$t("aucune-affectation")}}</span>
            </div>
            <div class="vg-timeline-item" v-for="item in filteredMaintenances" :key="item.id"
                @click="goToMaintenance(item)"
                v-longpress:[item]="onLongpressMaintenance">
                <div v-if="item.__section && item.__section.date" style="color:#3999ff;font-weight:bold;">
                    <vg-datetime-viewer v-model="item.__section.date" :key="item.__section.date"/>
                </div>
                <fm-cell-render v-else
                    :fm="item"
                    showAffectation/>
            </div>
        </div>
        <vg-pagination v-if="paginate"
    		:totalItems="counters[counterName]"
    		:offset="offset"
    		:limit="limit"
    		:currentPageNumber="currentPage"
    		@pagination-change="handlePaginationChange"
    	/>
        <vg-fichesav-management v-if="showModalMaintenanceActions"
    		@close="onCloseModalMaintenanceActions"/>
    </div>
</template>

<script>
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCalendarPicker from "src/components/Vg/VgCalendarPicker.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import FmCellRender from "src/components/Vg/TagGrid/FmCellRender.vue";

import VgFichesavManagement from "src/components/Vg/FicheSAV/VgFichesavManagement.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import Metadatas from "src/services/Metadatas.js";

import { mapGetters } from 'vuex';

export default {
    name:"vg-maintenance-timeline",
    components: {
        VgGroup,
        VgButton,
        VgCalendarPicker,
        VgDatetimeViewer,
        FmCellRender,
        VgFichesavManagement,
        VgPagination
    },
    mixins: [MaintenanceMixins],
    props:{
        agfilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        mesAffectations: {
            type: Boolean,
            default: false
        },
        paginate: {
            type: Boolean,
            default: true
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        counterName:{
            type: String,
            default: "mesAffectationsPlanifiees"
        }
    },
    data: function(){
        return {
            datas: null,
            start: moment().format("YYYY-MM-DD 00:00"),
            end: moment().format("YYYY-MM-DD 23:59"),
            events: [],
            maintenances: [],
            filteredMaintenances: [],
            metadatasMaintenances: new Metadatas(),
            showList: true,
            showModalMaintenanceActions: false,
            currentPage: this.offset + 1
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "todo": "A faire le",
            "aucune-donnee": "Aucune affectation le",
            "aucune-affectation": "Aucune affectation",
            "switch-calendar": "Afficher le calendrier",
            "switch-list": "Afficher la liste",
            "mois-precedent": "Mois précédent",
            "mois-suivant": "Mois suivant"
        },
        "en": {
            "todo": "To do for the",
            "aucune-donnee": "No datas",
            "aucune-affectation": "No datas",
            "switch-calendar": "Show calendar view",
            "switch-list": "Show list view",
            "mois-precedent": "Previous month",
            "mois-suivant": "Next month"
        }
    }
},
    watch: {
        agfilters: {
            handler: function(newValue){
                this.currentPage = 1;
                this.metadatasMaintenances.setLimit(this.offset, this.limit);
                this.fetch();
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            maintenance: 'MaintenancesStore/getSelectedMaintenance',
            counters: "MaintenancesStore/getMaintenancesCounters"
        })
    },
    methods: {
        goToPreviousMonth: function(){
            this.start = moment(this.start).subtract(1, "months").format("YYYY-MM-DD 00:00");
            this.end = moment(this.end).subtract(1, "months").format("YYYY-MM-DD 23:59");
            this.fetch();
        },
        goToNextMonth: function(){
            this.start = moment(this.start).add(1, "months").format("YYYY-MM-DD 00:00");
            this.end = moment(this.end).add(1, "months").format("YYYY-MM-DD 23:59");
            this.fetch();
        },
        onLongpressMaintenance: function(maintenance){
            if(this.$vgutils.isMobile()){
                this.$store.dispatch("MaintenancesStore/setMaintenance", maintenance);
                this.showModalMaintenanceActions = true;
            }
        },
        onCloseModalMaintenanceActions: function(){
            this.$store.dispatch("MaintenancesStore/updateMaintenance", this.maintenance);
            this.showModalMaintenanceActions = false;
            this.$store.dispatch("MaintenancesStore/deleteSelectedMaintenance");
        },
        onClickShowList: function(){
            if(this.showList){
                this.showList = false;
                localStorage.setItem("mes-affectations-show-list", false);
            }else{
                this.showList = true;
                localStorage.setItem("mes-affectations-show-list", true);
            }
            this.setFilteredMaintenances();
        },
        getMesAffectations:function(){
            if(this.$app.role=="ROLE_SOUS_TRAITANT" && this.$app.tiers_id) return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByTiersId"](this.$app.tiers_id);
            else return this.$store.getters["MaintenancesStore/getMaintenancesAffecteeByIdUser"](this.$app.idUser);
        },
        goToMaintenance: function(maintenance){
            this.$router.push({ name: '_maintenance', params: { id: maintenance.id, datas: maintenance } });
        },
        fetch: function(){
            let dateIntervalleStart = moment(this.start).startOf('month').format("YYYY-MM-DD 00:00");
            let dateIntervalleEnd = moment(this.end).endOf('month').format("YYYY-MM-DD 23:59");
            let filters = Object.assign({}, this.agfilters, {
                statut: {attr:"fm.statut", value: null, action: "equals"},
                affectationStart: {attr: "aff.start", value: [dateIntervalleStart, dateIntervalleEnd], action: "between"},
                affectationEnd: {attr: "aff.end", value: [dateIntervalleStart, dateIntervalleEnd], action: "between"}
            });
            if(this.mesAffectations) filters.mesAffectations = {attr:"aff.id",colId: "mesAffectations", value:this.$app.idUser, action:"equals"};
            this.metadatasMaintenances.setFilters(filters);
            this.MaintenanceMixins_getMesMaintenancesPlanifiees(this.metadatasMaintenances).then((datas)=>{
                this.maintenances = this.getMesAffectations();
                this.setEvents();
                this.setFilteredMaintenances();
            });
        },
        onFocusDate: function(datas){
            this.start = datas.start;
            this.end = datas.end;
            this.setFilteredMaintenances();
        },
        setFilteredMaintenances: function(){
            let items = [];
            if(!this.showList){ // vue calendrier
                let currentDate = moment(this.start).format("YYYY-MM-DD");
                let currentStartDate = null;
                let currentEndDate = null;
                this.maintenances.forEach((value)=>{
                    currentStartDate = moment(value.affectation.start).format("YYYY-MM-DD");
                    currentEndDate = moment(value.affectation.end).format("YYYY-MM-DD");
                    if(currentStartDate == currentDate || currentEndDate == currentDate){
                        items.push(value);
                    }else if(moment(currentDate).isBetween(currentStartDate, currentEndDate)){
                        items.push(value);
                    }
                });
                this.filteredMaintenances = items.sort((m1,m2)=>{
                    if(moment(m1.affectation.start).format("HH:mm")<moment(m2.affectation.start).format("HH:mm")) return -1;
                    else if(moment(m1.affectation.start).format("HH:mm")==moment(m2.affectation.start).format("HH:mm")) return 0;
                    else return 1;
                });
            }else{
                items = [...this.maintenances];
                items = items.filter((maintenance)=>maintenance.statut!="Resolue");
                items = items.sort((m1,m2)=>{
                    if(m1.affectation.start && m2.affectation.start && moment(m1.affectation.start).format("YYYY-MM-DD HH:mm")<moment(m2.affectation.start).format("YYYY-MM-DD HH:mm")) return -1;
                    else if(m1.affectation.start && m2.affectation.start && moment(m1.affectation.start).format("YYYY-MM-DD HH:mm")==moment(m2.affectation.start).format("YYYY-MM-DD HH:mm")) return 0;
                    else return 1;
                });
                let itemsCopy = [...items];
                let indexItem = 0;
                let currentDate = null;
                let sectionDate = null;
                itemsCopy.forEach((maintenance, index)=>{
                    if(!maintenance.affectation){
                        indexItem++;
                    }else{
                        currentDate = moment(maintenance.affectation.start).format("YYYY-MM-DD");
                        if(currentDate!=sectionDate){   // nouvelle date
                            sectionDate = currentDate;
                            items.splice(indexItem, 0, {__section: {date: currentDate}});
                            indexItem = indexItem+2;
                        }else{
                            indexItem++;
                        }
                    }
                });
                this.filteredMaintenances = items;
            }
        },
        setEvents: function(){
            let events = [];
            this.maintenances.forEach((maintenance)=>{
                console.log("maintenance", maintenance);
                if(moment(maintenance.affectation.start).format("YYYY-MM-DD")==moment(maintenance.affectation.end).format("YYYY-MM-DD")){
                    events.push({date: moment(maintenance.affectation.start).format("YYYY-MM-DD"), color: maintenance.statut=="En_cours"?"red":"green"});
                }else{
                    let dureeAffectationJours = moment(maintenance.affectation.end).diff(moment(maintenance.affectation.start), "days");
                    for(let index=0;index<=dureeAffectationJours; index++){
                        events.push({date:moment(maintenance.affectation.start).add(index, "days").format("YYYY-MM-DD"), color: maintenance.statut=="En_cours"?"red":"green"});
                    }
                }
            });
            this.events = events;
        },
        getValueFromObject: function (object, path) {
            return path.reduce((result, currentKey)=>{
                return result[currentKey];
            }, object);
        }
    },
    created: function(){
        if(localStorage.getItem("mes-affectations-show-list")) this.showList = localStorage.getItem("mes-affectations-show-list");
        if(this.paginate) this.metadatasMaintenances.setLimit(this.offset, this.limit);
        this.fetch();
    }
}
</script>

<style lang="scss" scoped>
.vg-timeline{
    width: 100%;
    height: 100%;
    overflow: auto;
    .vg-timeline-container{
        height: 100%;
        width:100%;
        overflow: auto;
        .vg-timeline-item{
            border-bottom: 1px solid whitesmoke;
            padding: 10px 0;
        }
    }
    .vg-timeline-container-info{
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #35b1ea;
    }
    /*.classement{
        margin: 5px 0;
        width: 100%;
        .classement-label{
            background-color: #eee;
            font-size: 0.85em;
            font-weight: bold;
            display: flex;
            justify-content: center;
        }
        .classement-group{
            margin: 5px 0;
        }
    }*/

}

</style>
<style lang="scss">
.vc-opacity-0{
    opacity: 0.3 !important;
}
</style>
