import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.stateByPath = {}; // {notInventoried:...,inventoried:...,notFound:...}
let getters = Base.getGetters();
getters.getStateByPath = function( state ){
    return state.stateByPath;
};
let actions = Base.getActions();
actions.setStateByPath = function( context, stateByPath ){
    context.commit('SET_STATE_BY_PATH', stateByPath);
};
let mutations = Base.getMutations();
mutations.SET_STATE_BY_PATH = function(state, stateByPath){
    state.stateByPath = stateByPath;
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
