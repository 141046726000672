//VgTree store module

// initial state
const state = {
    maintenances: [],
    selectedMaintenances:[],
    maintenancesCounters:{},
    selectedMaintenance:{}, // after a fetch we store it here
    demandeurs:[]
};

// getters
const getters = {
    getMaintenances: function( state ){
        return state.maintenances.filter((maintenance)=>{
            return maintenance.isReserve=="0";
        });
    },
    getSelectedMaintenance: function( state ){
        return state.selectedMaintenance;
    },
    getSelectedMaintenances: function( state ){
        return state.selectedMaintenances;
    },
    getMaintenancesCounters:function( state ){
        return state.maintenancesCounters;
    },
    getReserves: function( state ){
        return state.maintenances.filter((maintenance)=>{
            return maintenance.isReserve=="1";
        });
    },
    getMaintenancesAffecteeByIdUser: (state) => (id) => {
        let mesAffectations = [];
        state.maintenances.forEach((maintenance, i) => {
            if(maintenance["affectation"]){
                maintenance["affectation"]["affectes"].forEach((affecte, i) => {
                    affecte.user_id == id | affecte.tiers_id == id? mesAffectations.push(maintenance) : null;
                });
            }
        });
        return mesAffectations;
    },
    getMaintenancesAffecteeByTiersId: (state) => (id) => {
        let mesAffectations = [];
        state.maintenances.forEach((maintenance, i) => {
            if(maintenance["affectation"]){
                maintenance["affectation"]["affectes"].forEach((affecte, i) => {
                    affecte.tiers_id == id? mesAffectations.push(maintenance) : null;
                });
            }
        });
        return mesAffectations;
    },
    getMaintenancesAffectees : function( state ){
        let maintenancesAffectees = [];
        state.maintenances.forEach((maintenance, i) => {
            if(maintenance["affectation"]){
                maintenancesAffectees.push(maintenance);
            }
        });

        return maintenancesAffectees;
    },
    getDemandeurs: function(state){
        return state.demandeurs;
    }
};

// actions
const actions = {
    addMaintenance: function( context, maintenance ){
        context.commit('ADD_MAINTENANCES', maintenance);
    },
    addMaintenances: function( context, maintenances ){
        context.commit('ADD_MAINTENANCES', maintenances);
    },
    updateMaintenance: function( context, maintenance ){
        context.commit('UPDATE_MAINTENANCE', maintenance);
    },
    addMaintenanceCounters: function( context, maintenancesCounters ){
        context.commit('ADD_MAINTENANCES_COUNTERS', maintenancesCounters);
    },
    setMaintenanceCounters: function( context, maintenancesCounters ){
        context.commit('SET_MAINTENANCES_COUNTERS', maintenancesCounters);
    },
    setMaintenances: function( context, maintenances ){
        context.commit('SET_MAINTENANCES', maintenances);
    },
    setMaintenance: function( context, maintenance ){
        context.commit('SET_MAINTENANCE', maintenance);
    },
    deleteMaintenance: function( context, idMaintenance ){
        context.commit('DELETE_MAINTENANCE', idMaintenance);
    },
    selectMaintenances: function( context, maintenances ){
        context.commit('SELECT_MAINTENANCES', maintenances);
    },
    deleteSelectedMaintenance: function( context ){
        context.commit('DELETE_SELECTED_MAINTENANCE');
    },
    incrementCounter: function( context, payload ){
        context.commit('INCREMENT_COUNTER', payload);
    },
    decrementCounter: function( context, payload ){
        context.commit('DECREMENT_COUNTER', payload);
    },
    updateOperation: function(context, operation){
        context.commit("UPDATE_OPERATION", operation);
    },
    setDemandeurs: function(context, demandeurs){
        context.commit("SET_DEMANDEURS", demandeurs);
    }
};

// mutations
const mutations = {
    ADD_MAINTENANCE: function( state, maintenance ){
        state.maintenances.unshift(maintenance);
    },
    ADD_MAINTENANCES: function( state, maintenances ){
        let maintenanceIds = state.maintenances.map((maintenance)=>{
            return maintenance.id;
        });
        maintenances.forEach((maintenance, i) => {
            if(maintenanceIds.indexOf(maintenance.id) == -1){
                state.maintenances.unshift(maintenance);
            }

        });
    },
    UPDATE_MAINTENANCE: function( state, newMaintenance ){
        let localMaintenance = state.maintenances.map((maintenance)=>{
            if(maintenance.id == newMaintenance.id){
                maintenance = Object.assign(maintenance,newMaintenance);
            }
        });
    },
    ADD_MAINTENANCES_COUNTERS: function( state, maintenancesCounters ){
        state.maintenancesCounters = maintenancesCounters;
    },
    SET_MAINTENANCES_COUNTERS: function( state, maintenancesCounters ){
        state.maintenancesCounters = Object.assign({}, state.maintenancesCounters, maintenancesCounters);
    },
    SET_MAINTENANCES: function( state, maintenances ){
        state.maintenances = maintenances;
    },
    SET_MAINTENANCE: function( state, maintenance ){
        state.selectedMaintenance = maintenance;
    },
    DELETE_MAINTENANCE: function( state, idMaintenance ){
        state.maintenances.forEach((maintenance, i) => {
            if(maintenance["id"] == idMaintenance){
                state.maintenances.splice(i, 1);
            }
        });
    },
    SELECT_MAINTENANCES: function( state, maintenances ){
        state.selectedMaintenances = maintenances;
    },
    INCREMENT_COUNTER: function( state, payload ){
        state.maintenancesCounters[payload.name] = parseInt(state.maintenancesCounters[payload.name]) + payload.qty;
    },
    DECREMENT_COUNTER: function( state, payload ){
        state.maintenancesCounters[payload.name] = parseInt(state.maintenancesCounters[payload.name]) - payload.qty;
    },
    DELETE_SELECTED_MAINTENANCE: function(state){
        state.selectedMaintenance = null;
    },
    UPDATE_OPERATION: function(state, operation){
        console.log("UPDATE_OPERATION", state);
        if(state.selectedMaintenance && state.selectedMaintenance.id){
            let indexOperation = state.selectedMaintenance.operations.findIndex((op)=>op.id==operation.id);
            if(indexOperation!=-1){
                state.selectedMaintenance.operations[indexOperation] = Object.assign(state.selectedMaintenance.operations[indexOperation], operation);
            }
        }
    },
    SET_DEMANDEURS: function(state, demandeurs){
        state.demandeurs = demandeurs;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
