<template>
    <vg-modal
        :title="$t('create-inventaire')"
        :isSaveDisabled="false"
        @save="handleSave"
        @close="$emit('close')">
		<template #body>
            <vg-input
                isRequiredValue="true"
                v-model="inventaire.numeroInventaire"
                :title="$t('numero-inventaire')">
			</vg-input>
            <vg-input
                isRequiredValue="true"
                v-model="inventaire.name"
                :title="$t('libeller-inventaire')"
				:inputType="'text'">
			</vg-input>
            <vg-input
                isRequiredValue="true"
                v-model="inventaire.dateDebut"
                :title="$t('date-debut-inventaire')"
				:inputType="'date'">
			</vg-input>
            <vg-input
                v-model="inventaire.dateFin"
                :title="$t('date-fin-prevue-inventaire')"
				:inputType="'date'">
			</vg-input>
            <vg-input
                isRequiredValue="true"
                :title="$t('localisation-inventaire')">
                <vg-sites-selector v-model="inventaire.lieu_id"/>
			</vg-input>
            <vg-input
                v-model="inventaire.commentaire"
                :title="$t('commentaire-inventaire')"
				:inputType="'textarea'">
			</vg-input>
		</template>
    </vg-modal>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgSitesSelector from 'src/components/Vg/Selectors/VgSitesSelector.vue';
import moment from 'moment';
const ICON_DESKTOP = "fil.png";
const ICON_MOBILE = "fil_blanc.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(255, 199, 61)";

export default {
    name: "vg-inventaire-create-update-form",
    components: {
        VgInput,
        VgSelect,
        VgSitesSelector
    },
    mixins: [],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "create-inventaire": "Créer inventaire",
            "numero-inventaire": "Numéro inventaire",
            "libeller-inventaire": "Libellé inventaire",
            "date-debut-inventaire": "Date début",
            "date-fin-prevue-inventaire": "Date de fin prévue",
            "localisation-inventaire": "Localisation",
            "all-sites-inventaire": "Sélectionner le site concerné",
            "lieu-manquant": "Il faut sélectionner le site concerné",
            "commentaire-inventaire": "Commentaire"
        },
        "en": {
            "create-inventaire": "Create Inventory",
            "numero-inventaire": "Inventory's number",
            "libeller-inventaire": "Inventory's name",
            "date-debut-inventaire": "Start date",
            "date-fin-prevue-inventaire": "Scheduled end date",
            "localisation-inventaire": "Location",
            "all-sites-inventaire": "Select location",
            "lieu-manquant": "Location is required",
            "commentaire-inventaire": "Comment"
        },
        "th": {
            "create-inventaire": "สร้างสินค้าคงคลัง",
            "numero-inventaire": "หมายเลขสินค้าคงคลัง",
            "libeller-inventaire": "ชื่อสินค้าคงคลัง",
            "date-debut-inventaire": "วันที่เริ่มต้น",
            "date-fin-prevue-inventaire": "วันสิ้นสุดที่กำหนดไว้",
            "localisation-inventaire": "ที่ตั้ง",
            "all-sites-inventaire": "ปฏิบัติการบนทุกหน้างาน",
            "commentaire-inventaire": "ความคิดเห็น"
        }
    }
},
    props:{
        sitesList:{
            type:Array,
            default:function(){
                return [];
            }
        }
    },
    data: function() {
        return {
            inventaire:{
                "numeroInventaire":1,
                "dateDebut":moment().format("YYYY-MM-DD"),
                "dateFin":"",
                "name":"",
                "lieu_id":null,
                "servicesLieu":"",
                "created_at":moment().format(),
                "updated_at":moment().format(),
                "statut":"En_cours",
                "userId":this.$app.appID,
                "commentaire":""
            }
        }
    },
    computed: {},
    methods: {
        handleSave:function(){
            if(!this.inventaire.lieu_id){
                alert(this.$t("lieu-manquant"));
                return;
            }
            this.$emit("create", this.inventaire);
        }
    }
}
</script>

<style lang="scss">
</style>
