<template>

    <div :id="idMap" :ref="idMap" class="vg-leaflet"
        @drop="onDrop($event, 1)"
        @dragover.prevent
        @dragenter.prevent></div>

</template>

<script>
import L from "leaflet";

import PiecesLayerControl from "src/components/Vg/PlanInteractif/LayerControl/PiecesLayerControl.vue";
import PieceMarker from 'src/components/Vg/PlanInteractif/Marker/PieceMarker.vue';

export default {
    name: "vg-leaflet",
    components: {
        PieceMarker
    },
    props:{
        idMap:{
            type: String,
            default: "map"
        },
        /**
        * https://leafletjs.com/SlavaUkraini/reference.html#control-attribution
        */
        attribution:{
            type: String,
            default: null
        },
        layers:{
            type: Array,
            default: function(){
                return [];
            }
        },
        url:{
            type: String,
            default: null
        },
        center:{
            type: Array,
            default: function(){
                return null;
            }
        },
        zoom:{
            type: Number,
            default: 1
        }
    },
    data:function() {
        return {
            map: null,
            overlayLayers: [],
            overlayLayersControls: {},
            layersControls: null,
            backgroundLayer: {},
            currentCenter: null
        }
    },
    watch:{
        layers: {
            handler: function(newLayers, oldLayers){
                this.redrawLayers();
            },
            deep: true
        }
    },
    mounted:function(){
        this.drawMap();
    },
    methods: {
        redrawLayers: function(){
            if(this.overlayLayers && this.overlayLayers.length!=0 && this.map) this.removeLayers();
            if(this.layers && this.layers.length!=0) this.drawLayers();
        },
        onDrop: function(ev){
            let latlng = this.map.mouseEventToLatLng(ev);
            this.$emit("drop-external-element", {event: ev, latlng: latlng});
        },
        /**
        * Draw map and layer.
        *
        */
        drawMap:function(){
            if(this.url){
                var img = new Image();
                img.onload = () => {
                    let bounds = this.getBackgroundImageBounds(img);
                    console.log("BOUNDS", bounds);
                    let center = this.getInitialMapCenter(bounds);
                    let zoom = this.getMapZoom();
                    this.zoom = zoom;
                    this.setUrlQuery(center[0], center[1], zoom);
                    let backgroundLayer = L.imageOverlay(
                        this.url,
                        bounds
                    );
                    this.backgroundLayer = backgroundLayer;
                    let map = L.map(this.idMap, {
                        crs: L.CRS.Simple,  // @WARNING CRS simple dans le cas imageOverlay bg layer
                        center: center,
                        zoomDelta: 0.5,
                        zoomSnap: 0.1,
                        zoom: zoom,
                        layers: [backgroundLayer]
                    });
                    this.$emit("loaded-background-layer");
                    if(this.attribution) L.control.attribution({prefix: this.attribution}).addTo(map);
                    this.map = map;
                    if(this.layers && this.layers.length!=0) this.drawLayers();
                    this.setMapInteractions();
                    this.setMapEvents();
                };
                img.onerror = (e) => {
                    console.log("ERROR", e);
                };
                img.src = this.url;
            }else{
                // @TODO use tileLayer
                /*let backgroundLayer = L.tileLayer(this.layer.url, {
                  attribution: this.layer.attribution,
                  maxZoom: this.layer.maxZoom
              });*/
            }
        },
        /***
         * TEST
         * convertit des coordonnées en pixel (x, y) en LatLng
         * @param array coordPixels [pixelX, pîxelY]
         * @return object LatLng
         */
        convertPixelXPixelYToLatLng: function(coordPixels){
            // Convertir des pixels en coordonnées
            let coordonneesPixelOrigin = this.map.getPixelOrigin();
            let originX = coordonneesPixelOrigin.x;
            let originY = coordonneesPixelOrigin.y;
            let pixelX = coordPixels[0],
                pixelY = coordPixels[1];
            let pixelPoint = L.point(pixelX, pixelY);
            let coordonneesLatLng = this.map.layerPointToLatLng(pixelPoint);
            // @WARNING ces coordonnées en pixel correspondent au coordonnées sur l'image, ici ça me donne les coordonnées en fonction de l'angle superieur gauche du container
            // il faut faire une translation translationX=diff(angleContainerSuperieurGaucheX, angleImageSuperieurGaucheX) idem Y
            console.log("Coordonnées géographiques : ", coordonneesLatLng, coordonneesLatLng.lat, coordonneesLatLng.lng);
            return [coordonneesLatLng.lng, coordonneesLatLng.lat];
        },
        /***
         * TEST
         * 
         * @return object FeatureCollection
         */
        getNewPiecesGeoJson: function(){
            console.log("getPixelOrigin", this.map.getPixelOrigin());
            console.log("zoom", this.zoom);
            console.log("getSize", this.map.getSize()); // récupère la taille de l'image en fond de plan
            console.log("getPixelBounds", this.map.getPixelBounds());
            let newPieces = [
                {
                    "nom": "ORIGINE",
                    "renommage": "ORIGINE",
                    "coordonnees": [0, 0] // [507, 1803]
                },
                {
                    "nom": "COMMERCE",
                    "renommage": "COMMERCE",
                    "coordonnees": [137.77, 489.95] // [507, 1803]
                },
                {
                    "nom": "PARKING 3 (56 PLACES)",
                    "renommage": "PARKING 3 (56 PLACES)",
                    "coordonnees": [538.04, 288.86] //[1980, 1063]
                },
                {
                    "nom": "Piscine",
                    "renommage": "Piscine",
                    "coordonnees": [324.46, 255.71]//[1194, 941]
                },
                /*{
                    "nom": "Point max latitude",
                    "coordonnees": [800, 0],
                    "renommage": "POINT-LAT-800-LNG-0"
                },
                {
                    "nom": "Point max longitude",
                    "coordonnees": [0, 494],
                    "renommage": "POINT-LAT-0-LNG-MAX"
                },
                {
                    "nom": "Point max",
                    "coordonnees": [885, 494],
                    "renommage": "POINT-LAT-MAX-LNG-MAX"
                },
                {
                    "nom": "Point Lat=0 Lng=moitié",
                    "coordonnees": [0, 247],
                    "renommage": "POINT-LAT-0-LNG-MOITIE"
                },
                {
                    "nom": "Point Lat=moitié Lng=0",
                    "coordonnees": [441, 0],
                    "renommage": "POINT-LAT-MOITIE-LNG-0"
                },
                {
                    "nom": "Point moitié",
                    "coordonnees": [441, 247],
                    "renommage": "POINT-LAT-MOITIE-LNG-MOITIE"
                },
                */
            ];
            let piecesMarkers = [/*{
                    geometry: {
                        type: "Point",
                        coordinates: [0,0]
                    },
                    id: null,
                    mustBeIntegrated: true,
                    properties: {
                        id: null,
                        libel_lieu: "ORIGIN-TEST",
                        codeUn: "ORIGIN-TEST",
                        categorie_id: null,
                        libelleCatgorie: null
                    },
                    type: "Feature"
                }*/];
            newPieces.forEach((piece)=>{
                piecesMarkers.push({
                    geometry: {
                        type: "Point",
                        coordinates: this.convertPixelXPixelYToLatLng(piece.coordonnees),
                        coordinatesPixels: piece.coordonnees
                    },
                    id: null,
                    mustBeIntegrated: true,
                    properties: {
                        id: null,
                        libel_lieu: piece.renommage,
                        codeUn: piece.renommage,
                        categorie_id: null,
                        libelleCatgorie: null
                    },
                    type: "Feature"
                });
            });
            console.log("PIECES MARKERS", piecesMarkers);
            return {
                type: "FeatureCollection",
                features: piecesMarkers
            };
        },
        isLayerDisplayedInThisZoom: function(layer, zoom=null){
            let isDisplayed = true;
            let currentZoom = zoom?zoom:this.zoom;
            if(layer.hasOwnProperty("_displayConditions") && layer._displayConditions.hasOwnProperty("zoom")){
                let zoomMin = layer._displayConditions.zoom.min;
                let zoomMax = layer._displayConditions.zoom.max;
                if(zoomMin && zoomMax) isDisplayed = zoomMin<=currentZoom && currentZoom<zoomMax;
                else if(zoomMin) isDisplayed = zoomMin<=currentZoom;
                else if(zoomMax) isDisplayed = currentZoom<zoomMax;
            }
            console.log("isDisplayed", isDisplayed);
            return isDisplayed;
        },
        drawLayers: function(){
            let layerMarkers = [];
            let displayLayer = true;
            let layers = [...this.layers];
            // @Here TEST
            /*let newPiecesLayer = {
                name: "new-pieces",
                geoJson: this.getNewPiecesGeoJson(),
                options: {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        console.log("GEOJSON POINT", geoJsonPoint, latlng);
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: '<div style="background-color: whitesmoke;"><div style="height:5px;width:5px;background-color: blue;"></div><span>'
                                +geoJsonPoint.properties.libel_lieu
                                +'('+geoJsonPoint.geometry.coordinatesPixels[0]+','+geoJsonPoint.geometry.coordinatesPixels[1]
                                +')px->('+geoJsonPoint.geometry.coordinates[0]+','+geoJsonPoint.geometry.coordinates[1]
                                +')</span></div>'
                                //new PieceMarker({propsData: {piece: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, mustBeIntegrated: true}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": true
                        });
                        marker.on("click", (e) => {
                            console.log("GEOJSON POINT", geoJsonPoint);
                            
                        });
                        marker.on("dragend", (e) => {
                            console.log("DRAG END", e);
                            
                        });
                        return marker;
                    },
                },
                control: this.getPiecesLayerControl,
                _displayConditions: {
                    zoom: { min: 0, max: null }
                }
            };
            layers.push(newPiecesLayer);*/
            layers.forEach((layer)=>{
                displayLayer = this.isLayerDisplayedInThisZoom(layer);
                if(displayLayer){
                    layerMarkers = L.geoJSON(layer.geoJson.features, layer.options);
                    this.overlayLayersControls[layer.control?layer.control:layer.name] = layerMarkers;
                    this.overlayLayers.push(layerMarkers);
                    layerMarkers.addTo(this.map);
                    this.$emit("loaded-layer", {name: layer.name, layer: layer});
                }
            });
            this.layersControls = L.control.layers({}, this.overlayLayersControls, {collapsed: true}).addTo(this.map);
            this.$emit("loaded-layers");
        },
        setMapInteractions: function(){
            this.map.on("click", (e) => {
                console.log("CLICK", e.latlng);
                this.$emit("map-click", e);
            });
            this.map.on("drop", (e) => {
                console.log("DROP", e);
            });
        },
        setMapEvents: function(){
            this.map.on("zoom", (e)=>{
                console.log("zoom", e.target);
                this.zoom = e.target._animateToZoom;
                this.setUrlQuery(e.target._animateToCenter.lat, e.target._animateToCenter.lng, e.target._animateToZoom);
                this.redrawLayers();
            });
            this.map.on("moveend", (e)=>{
                console.log("moveend", e.target);
                let center = this.map.getCenter();
                this.setUrlQuery(center.lat, center.lng, e.target._zoom);
            });
        },
        setUrlQuery: function(lat, lng, zoom){
            history.replaceState(
                history.state,
                null,
                this.$route.path + "?_lat="+lat+"&_lng="+lng+"&_zoom="+zoom
            );
            this.$emit("map-position", {path: this.$route.path, lat: lat, lng: lng, zoom: zoom});
        },
        removeLayers: function(){
            this.overlayLayers.forEach((layer)=>{
                this.map.removeLayer(layer);
            });
            this.overlayLayers = [];
            if(this.layersControls) this.layersControls.remove();
        },
        /**
         * Get img size this will determine how to display the image with leaflet
         * handle portrait or landscape images.
         *
         * @see https://leafletjs.com/reference-1.7.1.html#bounds
         * @param Image img
         * @param integer expected height will be used for resizing proportionnaly
         * @return array init coordinates, with bounds
         */
        getBackgroundImageBounds: function(img, expected = 500){
            let realWidth = img.naturalWidth;
            let realHeight = img.naturalHeight;
            let width = 0;
            let height = 0;
            let isLandscapeImg = realWidth > realHeight ? 1 : 0;
            if (isLandscapeImg) {
                height = expected;
                width = (realWidth / realHeight * expected)
            } else {
                width = expected;
                height = (realHeight / realWidth * expected);
            }
            return [
                [0, 0],
                [height, width]
            ];
        },
        /**
        * récupère le centre de la map
        * @param Array bounds [[0,0], [height, width]]
        * @return Array center [lat, lng]
        */
        getInitialMapCenter: function(bounds){
            if(this.$route.query && this.$route.query._lat && this.$route.query._lng){ 
                return [this.$route.query._lat, this.$route.query._lng];
            }else{
                let storageCenter = this.getStorageCenter;
                if(storageCenter) return storageCenter;
                if(this.center) return this.center;
                else return [bounds[1][0]/2, bounds[1][1]/2];
            }
        },
        /**
        * récupère le zoom de la map
        * @return Integer zoom
        */
        getMapZoom: function(){
            if(this.$route.query && this.$route.query._zoom){ 
                return this.$route.query._zoom;
            }else{
                let storageZoom = this.getStorageZoom;
                if(storageZoom) return storageZoom;
                if(this.zoom) return this.zoom;
                else return 1;
            }
        }
    },
    computed:{
        getStorageCenter: function(){
            let positions = this.$storage.get("pi-positions");
            let path = this.$route.path;
            let center = null;
            if(positions && positions.hasOwnProperty(path)) center = [positions[path].lat, positions[path].lng];
            return center;
        },
        getStorageZoom: function(){
            let positions = this.$storage.get("pi-positions");
            let path = this.$route.path;
            let zoom = null;
            if(positions && positions.hasOwnProperty(path)) zoom = positions[path].zoom;
            return zoom;
        },
        getPiecesLayerControl: function(){
            return new PiecesLayerControl({propsData:{}}).$mount().$el.innerHTML;
        },
    }
};
</script>
<style lang="scss" scoped>
.vg-leaflet{
    height: 100%;
    width: 100%;
    z-index: 2;
}
</style>
<style lang="scss">
.leaflet-control-attribution:last-of-type{
    display: none !important;
}
</style>
