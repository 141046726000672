<template >
    <div>
        <div style="display: flex;justify-content: space-between;align-items: center;gap:10px;">
            <span style="color: #35B1EA;font-size: 16px;">{{ $t("list-equipements") }}</span>
            <vg-button v-if="$vgutils.isMobile()" @click="showBarodeScanner=true" type="success">{{ $t("scanner-equipements") }}</vg-button>
        </div>
        <br>
        <vg-collapse :title="$t('voir-equipements',{nEquipements: mouvementEquipements.equipements.length})"
            type="section" 
            :collapseByDefault="false"
        >
            <template #content>
                <vg-todo-list style="width:100% !important;overflow:auto;"
                    @deleteItem="removeEquipementFromList"
                    v-model="mouvementEquipements.equipements"
                    :hideInputText="true"
                    :messageWhenNoItems="$t('no-equipements-selected')"
                    >
                    <template v-slot:item="{item}">
                        <vg-equipement-viewer :key="item.id"
                            :equipement="item"/>
                    </template>
                </vg-todo-list>
                <vg-equipement-selector v-model="selectedEquipement"
                    key="equipement-selector"
                    :attributeValue="null"
                    :agFilters="equipementsFilters"
                    @input="onSelectEquipement"/>
            </template>
        </vg-collapse>
            
            <vg-modal 
                :title="$t('scannez-les-equipements')"
                v-show="showBarodeScanner"
                @close="showBarodeScanner = false"
                @save="showBarodeScanner = false"
            >
                <template #body-left>
                    <zxing-scanner
                        @scanSuccess="addEquipement"
                        :isDemo="false"
                        :code="'qrcode'"
                    ></zxing-scanner>
                </template>
                <template #body-right>

                </template>
                <template v-slot:footer>
                    <vg-button
                        type="success"
                        @click="showBarodeScanner=false"
                    >
                        {{ $t("terminer") }}
                    </vg-button>
                </template>
            </vg-modal>


            <hr>



            <form id="update-mouvements-form"
                ref="update-mouvements-form"
                onSubmit="return false;">
                <vg-input 
                    :title="$t('date-mouvement')" 
                    v-model="mouvementEquipements.dateMouvement" 
                    :inputType="'datetime-local'"
                    :is-required-value="true"
                />
                <vg-input 
                    v-if="typeMouvement == 'pret'"
                    :title="$t('date-retour-prevue')" 
                    v-model="mouvementEquipements.dateRetourPrevu" 
                    :inputType="'datetime-local'"
                    :is-required-value="false"
                >
                    <!--vg-date-picker-shortcuts @input="setDateRetourPrevueFromDateShortcut" :shortcutsCollection="['today','tommorow','week','chosenDate']"></vg-date-picker-shortcuts-->
                </vg-input>

                <vg-signataire-datalist v-model="mouvementEquipements.signataireDonneur"
                    :title="$t('signataire-donneur')"
                    :placeholder="$t('signataire-donneur')"
                    isBonDeSortieEquipements
                    typeSignataire="donneurs" 
                    isRequiredValue 
                    key="bs-eq-signataire-donneur"/>
                <vg-signataire-datalist v-model="mouvementEquipements.signataireReceveur"
                    :title="$t('signataire-receveur')"
                    :placeholder="$t('signataire-receveur')"
                    isBonDeSortieEquipements
                    typeSignataire="receveurs"
                    isRequiredValue 
                    key="bs-eq-signataire-receveur"/>
                <vg-input 
                    v-if="path"
                    :title="$t('destination-equipements')" 
                    v-model="path"  
                    :disabled="true"
                    :is-required-value="false"
                />

                <vg-input 
                    :title="$t('commentaire')" 
                    inputType="textarea"
                    v-model="mouvementEquipements.commentaire" 
                    :is-required-value="false"
                />

				<vg-checkbox
                    :label="$t('retour-au-lieu-dorigine')"
                    :defaultChecked="true"
                    v-model="returnEquipementsToTheirLieuOrigine"
                    @checked="selectLieuOrigineOnRetourEquipement(false)"
                    @unchecked="selectLieuOrigineOnRetourEquipement(true)"
                    v-show="mouvementEquipements.type=='retour'"
                >
				</vg-checkbox>

                <vg-input v-show="showSelectDestinationSelector" :title="$t('destination')" v-model="mouvementEquipements.destination" >
                    <vg-tree-lieux
                        :readOnly="true"
                        @select-piece="onClickLieu"
                        @remove-selected-node="onClickLieu"
                        >
                    </vg-tree-lieux>
                </vg-input>

                <div style="display: flex;flex-direction:column; justify-content: space-between;align-items: center;gap:10px;width:100%;">
                    <div style="width:100%">
                        <vg-button style="width:100%" @click="save" type="success" :disabled="!isValidMouvementEquipements">
                            {{ $t("valider") }}
                        </vg-button>

                    </div>
                    <div style="display:flex;width: 100%;gap:10px;">
                        <vg-button style="width:50%" v-show="mouvementEquipements.type=='pret' && showSelectDestinationSelector == false"  @click="showSelectDestinationSelector = true" type="default-info">
                            {{ $t('afficher-lieu-selector') }}
                        </vg-button>
                        <vg-button style="width:50%" @click="save(true)" type="default-info" v-show="!isAnUpdateForm" :disabled="!isValidMouvementEquipements">
                            {{ $t("valider-et-sortir-consommables") }}
                        </vg-button>
                    </div>
                </div>
                
                <input
                  v-show="false"
                  ref="mouvement-submit"
                  type="submit"
                  >
            </form>

    </div>
</template>

<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgEquipementSelector from "src/components/Vg/Selectors/VgEquipementSelector.vue";
import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgSignataireDatalist from "src/components/Vg/Datalists/VgSignataireDatalist.vue";
import MouvementsEquipementsMixins from "src/mixins/MouvementsEquipementsMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
import VgTodoList from "src/components/Vg/VgTodoList.vue";
import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";
export default {
    name: "vg-mouvements-equipements-form",
    i18n:    { "locale":navigator.language,
    "messages": {
            "fr":{
                "create-mouvements-equipements":"Créer",
                "update-mouvements-equipements":"Modifier",
                "delete-mouvements-equipements":"Supprimer",
                "afficher-lieu-selector":"Changer la destination",
                "destination":"Selectionner la destination des équipements sélectionnés",
                "no-equipements-selected":"Aucun équipement(s) selectionné(s), au moins 1 requis pour valider le bon de sortie",
                "list-equipements":"Liste des équipements",
                "scanner-equipements":"Ouvrir scanner qrCode",
                "retour-au-lieu-dorigine":"Les équipements doivent retourné à leur lieu d'origine",
                "destination-equipements":"Lieu de destination des équipements",
                "signataire-donneur":"Qui transmet le matériel",
                "signataire-receveur":"Qui reçoit le matériel",
                "date-mouvement":"Date du mouvement",
                "date-retour-prevue":"Date de retour prévue",
                "commentaire":"Commentaire",
                "valider":"Enregistrer le mouvement",
                "valider-et-sortir-consommables":"Combiner sortie consommables",
                "voir-equipements": "{nEquipements} équipements"
            },
            "en":{
                "create-mouvements-equipements":"Create",
                "update-mouvements-equipements":"Update",
                "delete-mouvements-equipements":"Delete"
            }
        }
    },
    components: {
        VgModal,
        VgInput,
        VgButton,
        VgCheckbox,
        VgCollapse,
        VgTreeLieux,
        VgEquipementSelector,
        VgEquipementViewer,
        zxingScanner,
        VgDatePickerShortcuts,
        VgTodoList,
        VgSignataireDatalist
    },
	mixins: [MouvementsEquipementsMixins, equipementsMixins],
    props: {
        /**
        *
        * Identifiant de mouvementsEquipements à modifier.
        * S'il est nourrie et que l'objet mouvementsEquipements n'est pas déjà
        * dans le store.
        */
        id:{
            type:Number,
            default:function(){
                return null;
            }
        },
        equipements:{
            type: Array,
            default:function(){
                return [];
            }
        },
        typeMouvement:{
            type: String,
            default: function(){
                return "pret";
            }
        },
        lieuOrigine:{
            type:Object,
            default: function(){
                return null;
            }
        }
    },
    data: function(){
        return{
            showSelectDestinationSelector:false,
            showBarodeScanner:false,
            returnEquipementsToTheirLieuOrigine:true,
            "path":null,
            mouvementEquipements:{
                "signataireReceveur": null,
                "signataireDonneur": this.$app.fullname || null,
                "commentaire": "",
                "type": this.typeMouvement,
                "dateMouvement": moment().format("YYYY-MM-DD HH:mm"),
                "dateRetourPrevu": null,
                "equipements": [],
                "origine": null,
                "destination": null,
                "createur": {"id":this.$app.idUser}
            },
            isLoading:false,
            selectedEquipement: null
        };
    },
    watch: {
        equipements:{
            handler:function(nEquipements){
                this.mouvementEquipements.equipements = nEquipements;
            },
            deep:true
        }
    },
    computed: {
        ...mapGetters({
            getSelectedMouvementsEquipements: "MouvementsEquipementsStore/getSelectedItem"
        }),
        isAnUpdateForm: function(){
            return this.getSelectedMouvementsEquipements && this.getSelectedMouvementsEquipements.hasOwnProperty("id");
        },
        allEquipementsHasTheSameOrigine: function(){},
        hasLieuOrigine: function(){
            return this.lieuOrigine;
        },
        isValidMouvementEquipements: function(){
            return this.mouvementEquipements.equipements.length && 
            this.mouvementEquipements.signataireReceveur != null &&
            this.mouvementEquipements.signataireDonneur != null ;
        },
        equipementsFilters: function(){
            let selectedEquipementsIds = [];
            if(this.mouvementEquipements && this.mouvementEquipements.equipements) selectedEquipementsIds = this.mouvementEquipements.equipements.map((equipement)=>equipement.id);
            return {
                equipement_id: {attr: "e.id", value: selectedEquipementsIds, action: "not_equals"},
                sortie: { attr: "se.dateSortie", value: 1, action: "is_null"}
            };
        },
    },
    methods: {
        save: function(openBonDeSortieConsommables=false){
            if(!this.isAnUpdateForm){
                this.MouvementsEquipementsMixins_create(this.mouvementEquipements,this.typeMouvement).then((datas)=>{
                    this.$emit("save", {
                        mouvement: datas, 
                        openBonDeSortieConsommables: openBonDeSortieConsommables
                    });
                });
            }else{
                this.MouvementsEquipementsMixins_update(this.mouvementEquipements).then((data)=>{
                    this.close();
                });
            }

        },
        delete: function(){
            this.MouvementsEquipementsMixins_delete(this.mouvementsEquipements).then((data)=>{
                this.close();
            });
        },
        close: function(){
            this.$emit("close");
        },
        onClickLieu : function(lieu){
            if(lieu){
                this.mouvementEquipements.destination = {
                    "id": lieu.id,
                    "path": lieu.path
                };
                this.path = lieu.path;
            }else{
                this.mouvementEquipements.destination = null;
                this.path = null;
            }
            this.showSelectDestinationSelector = false
        },
        setOrigine: function(lieuOrigine){
            return {
                "id": lieuOrigine.id,
                "path": lieuOrigine.path
            }
        },
        removeEquipementFromList:function(equipement){
            console.log({equipement});
            this.mouvementEquipements.equipements.splice(this.mouvementEquipements.equipements.indexOf(equipement), 1)
        },
        isEquipementInList : function(code){
            return this.mouvementEquipements.equipements.filter(equipement=>equipement.qrCode == code).length;
        },
        addEquipement: function(code){
            if(this.isEquipementInList(code)) return;

            return new Promise((resolve, reject)=>{
                let metadatas = new Metadatas();
                metadatas.setFilters({qrCode:{ attr:"e.qrCode",colId: "e.qrCode", value: code, action:"equals" }});
                this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{
                    this.mouvementEquipements.equipements.push(equipements.datas[0]);
                    this.mouvementEquipements.origine = this.setOrigine(equipements.datas[0].lieu);
                    this.showBarodeScanner = false;
                    resolve();
                });
            });

        },
        hasAnActiveMaintenance:function(equipement){
            let hasMaintenances = equipement.maintenances.length;
            let lastMaintenance =  equipement.maintenances[equipement.maintenances.length-1];
            return hasMaintenances && lastMaintenance.statut != "Resolue";
        },
        getLastMaintenance:function(equipement){
            if(this.hasAnActiveMaintenance(equipement)){
                return equipement.maintenances[equipement.maintenances.length-1];
            }else{
                return null;
            }
        },
        setDateRetourPrevu: function(){
            this.mouvementEquipements.dateRetourPrevu = moment().add(1,"days").format("YYYY-MM-DD HH:mm");
        },
        setDateRetourPrevueFromDateShortcut:function(dateShortcut){
            if(!dateShortcut) {
                this.mouvementEquipements.dateRetourPrevu = null;
                return;
            }
            this.mouvementEquipements.dateRetourPrevu = dateShortcut.end;
        },
        selectLieuOrigineOnRetourEquipement:function(checked){
            if(checked){
                this.showSelectDestinationSelector = true;
            }else{
                this.showSelectDestinationSelector = false;
                this.mouvementEquipements.destination = null;
            }
        },
        addInitialEquipments(){
            if(this.equipements.length){
                this.mouvementEquipements.equipements = this.equipements;
            }
        },
        onSelectEquipement: function(equipement){
            this.mouvementEquipements.equipements.push(Object.assign({}, {}, equipement));
            this.selectedEquipement = null;
        }
    },
	created: function(){
        this.mouvementEquipements = Object.assign({}, this.mouvementEquipements, this.getSelectedMouvementsEquipements);
        if(this.typeMouvement == "pret"){
            this.setDateRetourPrevu();
        }
        this.addInitialEquipments();
        //this.addEquipement("EQU-DEMO-000104")
    },
    mounted: function(){
        if(this.id){
            this.MouvementsEquipementsMixins_get(this.id).then((mouvementsEquipements)=>{});
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
