<template>
    <div class="vg-taches-table">
        <tag-grid
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="groupedTaches"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick" />
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange" />
    </div>
</template>
<script>
import TachesMixins from "src/mixins/TachesMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import OuiNonCellRender from 'src/components/Vg/TagGrid/OuiNonCellRender.vue';
import CategorieQuantiteEquipementsCellRender from 'src/components/Vg/TagGrid/CategorieQuantiteEquipementsCellRender.vue';
import RestrictionSiteCellRender from "src/components/Vg/TagGrid/RestrictionSiteCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-taches-table',
    components:{
        TagGrid,
        VgPagination
    },
    mixins:[ TachesMixins ],
    props: {
        id:{
            type: String,
            default: "taches-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement des tâches récurrentes...",
                "overlay-no-rows-text": "Aucune tâche récurrente correspondant au filtrage",
                "header-libel-tache": "Intitulé",
                "header-nb-equipement-checked": "Progression",
                "header-nb-equipement-to-check": "Nb d'équipements/pièces",
                "header-restriction-sites": "Restriction sites",
                "header-tags": "Etiquette",
                "header-intex": "Gestion",
                "header-is-active": "Active",
                "header-periodicite": "Périodicité (en jours)",
                "header-date-prochaine-verif": "Date butoir",
                "header-open-maintenance-on-error": "Ouverture fiche curative?",
                "header-nb-checkpoints": "Nb points de vérification",
                "header-norme": "Norme",
                "header-commentaire": "Commentaire",
                "header-created-at": "Créé le",
                "header-type-tache": "Type tâche",
                "header-categorie": "Catégorie",
                "header-average-time": "Durée moyenne en min",
                "header-is-scan-required": "Scann équipement requis",
                "header-affectation": "Programmation session"
            },
            "en": {
                "overlay-loading-text": "Loading recurrent tasks...",
                "overlay-no-rows-text": "No recurrent task matching filtering",
                "header-libel-tache": "Label",
                "header-nb-equipement-checked": "Progress",
                "header-nb-equipement-to-check": "Nb of equipment/parts",
                "header-restriction-sites": "Restriction sites",
                "header-tags": "Tag",
                "header-intex": "Management",
                "header-is-active": "Active",
                "header-periodicite": "Periodicity (in days)",
                "header-date-prochaine-verif": "Deadline",
                "header-open-maintenance-on-error": "Open corrective sheet?",
                "header-nb-checkpoints": "Nb checkpoints",
                "header-norme": "Standard",
                "header-commentaire": "Comment",
                "header-created-at": "Created at",
                "header-type-tache": "Task type",
                "header-categorie": "Category",
                "header-average-time": "Average duration in min",
                "header-is-scan-required": "Scan equipment required",
                "header-affectation": "Session programming"
            }
        }
    },
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                editType: 'fullRow',
                isFullWidthCell: function(rowNode) {
                    return rowNode.data.isSection;
                },
                fullWidthCellRenderer:(params) => {
                    let srcIcon = params.data.categorie.isGe==1?'/static/assets/icone/categorie-equipement-gris.png':params.data.categorie.icon;
                    return '<div style="width:100%;height:100%;display: flex;justify-content: flex-start;align-items: center;">'
                                +'<span style="padding-left: 12px;color: #42454a;font-size: 16px;margin:auto;width:100%;">'
                                    +'<img src="'+srcIcon+'" style="width:15px;height:15px;margin:auto;margin-right:5px;"/>'
                                    +params.data.categorie.libelleCatgorie+'</span></div>';
                },
            },
            columnDefs: [
                {
                    headerName: this.$t("header-libel-tache"),
                    field: "libel_tache",
                    width: 200,
                    pinned: 'left',
                    cellClass: ["vg-cell-ballam-theme"],
                },
                {
                    headerName: this.$t("header-nb-equipement-checked"),
                    field: "nbEquipementChecked",
                    width: 120,
                    pinned: 'left',
                    cellRenderer: (params) => {
                        if(params.data.tacheSites.length!=0){
                            return '<div data-toggle="tooltip" title="' + params.data.progressionGlobale.verified + ' vérifiés sur ' + params.data.progressionGlobale.total
                                + '\ndu '+moment(params.data.progressionGlobale.dateDebut).format("DD/MM/YYYY")+' au '+moment(params.data.progressionGlobale.dateFin).format("DD/MM/YYYY")+'" style="display:flex;align-items:center;gap:5px;">' +
                                '<progress style="width:50px;cursor:pointer;" max="' + params.data.progressionGlobale.total + '" value="' + params.data.progressionGlobale.verified + '">' +
                                '</progress><small>'+params.data.progressionGlobale.verified+'/'+params.data.progressionGlobale.total+'</small>' +
                                '</div>';
                        }else{
                            return '<small style="color:red;">'+this.$t("aucun-"+this.selectedTab)+'</small>';
                        }
                    },
                    cellClass: ["vg-cell-ballam-theme"],
                },
                {
                    headerName: this.$t("header-nb-equipement-to-check"),
                    field: "progressionGlobale.total",
                    width: 120,
                    pinned: 'left',
                    cellRenderer: (params) => new CategorieQuantiteEquipementsCellRender({propsData: {
                            params: params,
                            quantite: params.data.progressionGlobale.total,
                            libelleCategorie: params.data.categorie.libelleCatgorie,
                            typeEquipement: params.data.type_tache,
                            isGep: params.data.categorie.isGe
                        }})
                        .$on("open-page-equipements", this.openPageEquipements)
                        .$on("open-page-lieux", this.openPageLieux)
                        .$mount().$el,
                    cellClass: ["vg-cell-ballam-theme"],
                },
                {
                    headerName: this.$t("header-restriction-sites"),
                    field: "tacheSites",
                    width: 150,
                    pinned: 'left',
                    cellRenderer: (params) => new RestrictionSiteCellRender({propsData: {
                            lieux: params.data.tacheSites.map((tacheSite)=>tacheSite.site)
                        }}).$mount().$el,
                    cellClass: ["vg-cell-ballam-theme"],
                },
                {
                    headerName: this.$t("header-tags"), // string split ,
                    field: "tags",
                    width: 150,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-intex"),
                    field: "intex",
                    width: 80,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-is-active"),
                    field: "isActive",
                    width: 80,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.isActive && params.data.isActive == "1",
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-TacheActive")
                        }
                    }).$mount().$el,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-periodicite"),
                    field: "periodicite",
                    width: 80,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-date-prochaine-verif"),
                    field: "dateprochaineVerif",
                    getQuickFilterText: (params) => {
                        //// //console.log("getQuickFilterText", params.value);
                        return this.$vgutils.getDate(params.data.dateprochaineVerif);
                    },
                    width: 100,
                    cellRenderer: (params) => {
                        return this.$vgutils.getDate(params.data.dateprochaineVerif);
                    },
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-open-maintenance-on-error"),
                    field: "openMaintenanceOnError",
                    width: 80,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.openMaintenanceOnError && params.data.openMaintenanceOnError == "1",
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-OuvertureFM")
                        }
                    }).$mount().$el,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-nb-checkpoints"),
                    field: "nbCheckpoints",
                    width: 80,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-norme"),
                    field: "norme",
                    width: 150,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-commentaire"),
                    field: "commentaire_tache",
                    width: 150,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-created-at"),
                    field: "created_at",
                    hide: true,
                    cellRenderer: (params) => {
                        return this.$vgutils.getDate(params.data.created_at);
                    },
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("header-type-tache"),
                    field: "type_tache",
                    colId: "type_tache",
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    hide: true,
                },
                {
                    headerName: this.$t("header-categorie"),
                    field: "categorie",
                    hide: true,
                    cellRenderer: (params) => {
                        return params.categorie.isGe;
                    },
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: true,
                },
                {
                    headerName: this.$t("header-average-time"),
                    field: "averageTime",
                    hide: false,
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: false,
                },
                {
                    headerName: this.$t("header-is-scan-required"),
                    field: "isScanRequired",
                    hide: false,
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: false,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.isScanRequired,
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-scann-eq-requis")
                        }
                    }).$mount().$el
                },
                {
                    headerName: this.$t("header-affectation"),
                    field: "affectation",
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.hasOwnProperty("affectation") && params.data.affectation && params.data.affectation.id,
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-affectation")
                        }
                    }).$mount().$el
                }
            ],
            metadatas: new Metadatas(),
            groupedTaches: [],
            focusedTache: null
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        },
        taches: {
            handler: function(newTaches){
                //this.groupedTaches = this.groupTachesByCategorie(this.taches);
            },
            deep: true
        }
    },
    created:function(){
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            taches: 'TachesStore/getCollection',
            counters: 'TachesStore/getCounters'
        }),
        getTotalItems: function(){
            return this.counters.filtered;
        },
    },
    methods:{
        openPageEquipements: function(datas){
            // @TODO ajouter filtre sites
            this.$router.push({ name: '_equipements', params: { defaultFilters: datas.filters }});
        },
        openPageLieux: function(datas){
            // @TODO ajouter filtre sites
            this.$router.push({ name: '_lieux', params: { defaultFilters: datas.filters }});
        },
        /**
         *@param {array} taches
         *@return {array} taches
         */
        groupTachesByCategorie: function(taches) {
            let groupedByCategories = [];
            taches.forEach((tache)=>{
                if(!groupedByCategories.hasOwnProperty(tache.categorie.libelleCatgorie)){
                    groupedByCategories[tache.categorie.libelleCatgorie] = {
                        isSection: true,
                        categorie: tache.categorie,
                        taches: [tache]
                    };
                }else{
                    groupedByCategories[tache.categorie.libelleCatgorie].taches.push(tache);
                }
            });
            console.log("GROUPED BY CATEGORIES", groupedByCategories);
            let result = [];
            groupedByCategories = Object.values(groupedByCategories);
            groupedByCategories.forEach((item)=>{
                result.push({
                    isSection: true,
                    categorie: item.categorie
                });
                item.taches.forEach((tache)=>{
                    result.push(tache);
                });
            });
            return result;
        },
        fetch: function(){
            console.log("FETCH TACHES", this.filters);
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.tachesMixins_getTaches(this.metadatas).then((datas)=>{
                console.log("GET TACHES", datas);
                this.groupedTaches = this.groupTachesByCategorie(datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            if(!row.data.isSection) this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            if(!row.data.isSection) this.$emit("row-click",row.data);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-taches-table{
    height: 100% !important;
}
</style>