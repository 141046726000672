<template>
    <vg-select
        v-model="tiersTypeSelected"
        :options="tiersType"
        :clearable="clearable"
        @input="$emit('input',tiersTypeSelected)" />
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-tiers-type-selector',
		components: {
			VgSelect
		},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet tiers à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			clearable: {
				type: Boolean,
				default: false
			}
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "prestataire-maintenance": "Prestataire maintenance",
            "fournisseur-consommables": "Fournisseur consommables",
            "fournisseur-equipement": "Fournisseur equipements",
            "fournisseur-autre": "Autre",
            "prestataire-client": "Client"
        },
        "en": {
            "prestataire-maintenance": "Maintenance provider",
            "fournisseur-consommables": "Consummables supplier",
            "fournisseur-equipement": "Equipments supplier",
            "fournisseur-autre": "Other",
            "prestataire-client": "Customer"
        }
    }
},
        data: function() {
            return {
                tiersTypeSelected:null
            };
        },
        created:function(){
            this.tiersTypeSelected = this.value;
        },
        computed: {
            tiersType:function(){
                if(this.$app.account_type=="prestataire"){
                    return [
                        this.$t("prestataire-client"),
                        this.$t("prestataire-maintenance"),
                        this.$t("fournisseur-consommables"),
                        this.$t("fournisseur-equipement"),
                        this.$t("fournisseur-autre")
                    ]
                }else{
                    return [
                        this.$t("prestataire-maintenance"),
                        this.$t("fournisseur-consommables"),
                        this.$t("fournisseur-equipement"),
                        this.$t("fournisseur-autre")
                    ];
                }
            }
        }
    };

</script>
<style lang="scss" scoped>

</style>
