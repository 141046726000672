<template>
    <div style="display:flex;justify-content:flex-start;align-items:center;">
        <span style="margin-right:10px;">{{text?text:$t("text")}}</span>
        <vg-select v-model="limit"
            :options="limitValues"
            :clearable="false"
            style="width:100px;"
            @input="$emit('input', limit)">
        </vg-select>
    </div>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";

export default {
    name: "vg-pagination-limit-selector",
    props: {
        /**
        * @model
        */
        value: {
            type: Number
        },
        defaultValue:{
            type: Number,
            default: 10
        },
        text:{
            type: String,
            default: null
        }
    },
    components: {
        VgSelect
    },
    data: function() {
        return{
            limit: this.value?this.value:this.defaultValue,
            limitValues: [10, 20, 50, 100]
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "text": "Eléments affichés"
        }
    }
},
    mounted: function(){

    },
    methods: {

    },
    computed: {

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
