<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:defaultDateIntervalle="defaultDateIntervalle"
		key="dashboard-curatif-rapport-repartition-duree-traitement"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<vg-line-chart v-if="repartitionDureeTraitement"
			class="chart-bar"
			:chart-id="'dashboard-curatif-repartition-duree-traitement'"
			:height="'100%'"
			:datas="getDatas"
			:horizontalaxis="xAxis"
			:options="optionsBar"
			:showlegend="false"
			@selected="onSelectedBar"/>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgLineChart from 'src/views/Statistiques/components/VgLineChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-repartition-duree-traitement',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgLineChart,
			VgDashboardCuratifRapport,
			VgButton
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},
				xAxis: [],
				optionsBar: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 8,
								precision: 0,
								callback: (value, index, labels)=>{
									if(value>=1 && value<=1) return this.$t("jour");
									else if(value<1) return value;
									else return this.$t("jours", {jours: value});
								}
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks:{
								mirror: true,
								fontSize: 10,
								callback: (label, index, labels)=>{
									let dateMonth = moment(label+"-01");
									return this.$t("month-"+dateMonth.format("MM"))+" "+dateMonth.format("YY");
								}
							}
						}]
					},
					animation: {
						easing: "linear",
						onProgress: (e)=>this.drawCustomBarElements(e),
						onComplete: (e)=>this.drawCustomBarElements(e)
					},
					tooltips: {
						enabled: true,
						callbacks: {
							label: ((tooltipItems, data) => {
								let barData = this.repartitionDureeTraitement[tooltipItems.index];
								return this.$t("bar-hover-label")+": "+this.getDureeReadable(barData.dureeTraitementMoyen);
							}),
							title: ((tooltipItems)=>{
								console.log(tooltipItems);
								let dateMonth = moment(tooltipItems[0].xLabel+"-01");
								return this.$t("month-"+dateMonth.format("MM"))+" "+dateMonth.format("YY");	
							})
						}
					}
				},
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Evolution durée traitement fiches clôturées",
            "bar-hover-label": "Moyenne intervalle date ouverture date fermeture",
			"jour": "1 jour ",
            "jours": "{jours} jours ",
            "heures": "{heures} h ",
            "minutes": "{minutes} min ",
			"month-01": "Janv",
			"month-02": "Fév",
			"month-03": "Mars",
			"month-04": "Avril",
			"month-05": "Mai",
			"month-06": "Juin",
			"month-07": "Juillet",
			"month-08": "Août",
			"month-09": "Sept",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Déc"
        },
        "en": {
            "jour": "1 day",
            "jours": "{jours} days ",
            "heures": "{heures} h ",
            "minutes": "{minutes} min "
        }
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			onSelectedBar: function(bar){
				let barSelected = this.repartitionDureeTraitement[bar.index];
				console.log("BAR SELECTED", barSelected);
				let filters = [
					{attr: "fm.dateFermetureSAV", value: barSelected.dateMonth, action: "start_with"}
				];
				this.$emit("selected", {bar: barSelected, filters: filters, dateIntervalle: this.dateIntervalle});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				if(this.dateIntervalle){
					filters.dateFermetureSAV = {attr: "fm.dateFermetureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				}
				this.metadatas.setFilters(filters);
				//if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getCuratifRepartitionDureeTraitement(this.metadatas).then((datas)=>{
					console.log("GET DUREE TRAITEMENT--------------", datas);
				});
			},
			drawCustomBarElements: function(e){
				let ctx = e.chart.ctx;
				let datasets = e.chart.config.data.datasets;
				let meta = null;
			},
			getDureeReadable: function(duree){
				if(duree && duree!=0){
					let minutesParJour = 60 * 24;
					let minutesParHeure = 60;
					// Calculer le nombre total de jours
					let jours = Math.floor(duree / minutesParJour);
					// Calculer le nombre d'heures restantes après avoir extrait les jours
					let heuresRestantes = duree % minutesParJour;
					let heures = Math.floor(heuresRestantes / minutesParHeure);
					// Calculer le nombre de minutes restantes après avoir extrait les heures
					let minutes = heuresRestantes % minutesParHeure;
					let result = "";
					if(jours==1) result += this.$t("jour");
					else if(jours!=0) result += this.$t("jours", {jours: jours});
					if(heures!=0) result += this.$t("heures", {heures: heures});
					if(minutes!=0) result += this.$t("minutes", {minutes: minutes});
					return result;
				}else return "-";
			},
			convertMinutesToDays: function(dureeMinutes){
				return dureeMinutes/(24*60);
			}
		},
        computed: {
			...mapGetters({
	            repartitionDureeTraitement: 'DashboardStore/getCuratifRepartitionDureeTraitement',
	        }),
			getDatas: function(){
				let bar = {
					label: this.$t("bar-hover-label"),
					value: "dureeTraitementMoyen",
					backgroundColor: "#87b0dd",
					borderColor: "#87b0dd",
					borderWidth: 1,
					data: [],
					stack: "duree",
					hoverBackgroundColor: "#87b0dd",
					hoverBorderColor: "#80b6de",
					hoverBorderWidth: 1,
					filters: []
				};
				let datas = [];
				let xAxis = [];
				this.repartitionDureeTraitement.forEach((item)=>{
					xAxis.push(item.dateMonth);
					datas.push(this.convertMinutesToDays(item.dureeTraitementMoyen));
				});
				this.xAxis = xAxis;
				bar.data = datas;
				return [bar];
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
