<template>
    <div v-if="showViewer" class="overlay" @click="handleOverlayClick">
      <div class="overlay-content" @click.stop>
        <button class="close-btn" @click="closeViewer">✕</button>
  
        <div v-if="isImage">
          <img :src="fileUrl" alt="file preview" class="image-viewer" />
        </div>
  
        <div v-if="isPdf" ref="pdfViewer" class="pdf-viewer">
            <iframe height="100%" width=100% :src="fileUrl" ></iframe>
        </div>
        <div v-if="isAudio">
          <audio controls>
            <source :src="fileUrl" type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div v-if="isVideo">
          <video controls>
            <source :src="fileUrl" type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
        <div v-if="isMarkdown" class="pdf-viewer">
          <iframe height="100%" width=100% :src="fileUrl" ></iframe>
        </div>
        <div v-if="isText">
          Texte
        </div>
      </div>
    </div>
  </template>
  <script>
  
  export default {
    name: "vg-file-viewer",
    props: {
      file: { type: File, required: true },
    },
    data() {
      return {
        showViewer: true,
        fileUrl : null
      };
    },
    computed: {
      isImage() {
        return this.file.type.startsWith("image/");
      },
      isPdf() {
        return this.file.type === "application/pdf";
      },
      isAudio(){
        return this.file.type.startsWith("audio/");
      },
      isVideo(){
        return this.file.type.startsWith("video/");
      },
      isMarkdown(){
        return this.getFileExtension( ) === "md";
      },
      isText(){
        return this.file.type.startsWith("text/");
      }
    },
    watch: {
      file: "loadFile",
    },
    methods: {
      getFileExtension() {
        return this.file.name.split(".").pop();
      },
      handleOverlayClick(event) {
        // Close the overlay if the click is on the overlay background
        if (event.target.classList.contains("overlay")) {
            this.closeViewer();
        }
      },
      closeViewer() {
        this.showViewer = false;
        this.$emit("close");
      },
      async loadFile() {
        this.fileUrl = URL.createObjectURL(this.file);
      },
    },
    mounted() {
      this.loadFile();
    },
    beforeUnmount() {
      // Revoke Blob URL to free up memory when component is destroyed
      if (this.fileUrl) URL.revokeObjectURL(this.fileUrl);
    },
  };
  </script>
  
  <style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .overlay-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    overflow: auto;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .image-viewer {
    max-width: 100%;
    max-height: 100%;
  }
  
  .pdf-viewer{
    width: 80vw;
    height: 80vh;
  }
  .pdf-viewer canvas {
    max-width: 100%;
    max-height: 100%;
  }
  </style>
  