<template>
    <vg-app-layout
        :title="$t('title')"
        :colorheader="getColorHeader"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @backpage="btnBackPage()">
        <template #header-top-left-complement>
                <i class="fas fa-trash"@click="isDeleteInventaireModaledisplayed=true"
                style="margin-left: 15px;color:#e90202;font-size: smaller;"
                ></i>
        </template>
        <template #primary>

            <vg-collapse :title="$t('resume')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <vg-libelle-editable :label="$t('name')">
                         <template v-slot:read>
                             {{inventaire.name}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('commentaire')">
                         <template v-slot:read>
                             {{inventaire.commentaire}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('numero-inventaire')">
                         <template v-slot:read>
                             {{inventaire.numeroInventaire}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('path')">
                         <template v-slot:read>
                             {{inventaire.path}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('statut')">
                         <template v-slot:read>
                             {{inventaire.statut}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('totalEquipement')">
                         <template v-slot:read>
                             {{inventaire.totalEquipement}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('totalInventorie')">
                         <template v-slot:read>
                             {{inventaire.totalInventorie}}
                         </template>
                     </vg-libelle-editable>

                 </template>
            </vg-collapse>

            <vg-collapse :title="$t('mouvements')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <vg-libelle-editable :label="$t('not-found')">
                         <template v-slot:read>
                             {{getEtatInventaire.notFound}}
                         </template>
                     </vg-libelle-editable>
                     <vg-libelle-editable :label="$t('has-moved')">
                         <template v-slot:read>
                             {{getEtatInventaire.hasMoved}}
                         </template>
                     </vg-libelle-editable>

                 </template>
            </vg-collapse>

            <vg-collapse :title="$t('etat-equipements')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <vg-libelle-editable :label="$t('bon')">
                         <template v-slot:read>
                             {{getEtatInventaire.bon}}
                         </template>
                     </vg-libelle-editable>
                     <vg-libelle-editable :label="$t('moyen')">
                         <template v-slot:read>
                             {{getEtatInventaire.moyen}}
                         </template>
                     </vg-libelle-editable>
                     <vg-libelle-editable :label="$t('mauvais')">
                         <template v-slot:read>
                             {{getEtatInventaire.mauvais}}
                         </template>
                     </vg-libelle-editable>

                 </template>
            </vg-collapse>

        </template>
        <template #secondary>
            <div class="full-height">
                <action-menu-aggrid
                    :idTableau="'tableau-inventaire-lieu-id-mobile'"
                    disabledReport >
                </action-menu-aggrid>
                <vg-inventaire-recapitulatif-lieu-id
                    :searchQuery="searchQuery">
                </vg-inventaire-recapitulatif-lieu-id>

                <action-menu-aggrid
                    :idTableau="'tableau-inventaire-mobile'"
                    disabledReport >
                </action-menu-aggrid>
                <vg-inventaire-recapitulatif>
                </vg-inventaire-recapitulatif>
            </div>

        </template>
        <template v-if="$vgutils.isMobile()" #body>
            <!-- liste des pièces à inventorier de l'inventaire courant -->
            <vg-inventaire-recapitulatif
                :searchQuery="searchQuery"
                :agfilters="agfilters"
                @select-piece="onSelectPiece">
            </vg-inventaire-recapitulatif>
        </template>
        <template #search v-if="$vgutils.isMobile()">
            <vg-text-filter
                slot="search"
                v-model="searchQuery" />
        </template>
        <template #panel-filters-localisation>
            <div></div> <!-- @WARNING doit être préserver pour afficher la modal de filtres contenant uniquement le widget lieux -->
        </template>
        <template #panel-widget-lieux>
            <vg-tree-lieux
                v-model="agfilters.path.value" />
        </template>

        <dialog-secured-action
          v-if="isDeleteInventaireModaledisplayed"
          :valid-response="'1'"
          @save="handleDeleteInventaire"
          @close="isDeleteInventaireModaledisplayed = false"
        >
          <template v-slot:header-title>
            {{ $t("delete-inventaire") }}
          </template>
        </dialog-secured-action>
    </vg-app-layout>

</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgInventaireCreateUpdateForm from "src/components/Vg/Inventaire/VgInventaireCreateUpdateForm.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import LieuMixins from 'src/mixins/LieuMixins.js';
import InventaireMixins from "src/mixins/InventaireMixins.js";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgChips from 'src/components/Vg/VgChips.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgInventaireViewer from "src/components/Vg/Inventaire/VgInventaireViewer.vue"
import VgInventaireRecapitulatif from "src/components/Vg/Inventaire/VgInventaireRecapitulatif.vue"
import VgInventaireRecapitulatifLieuId from "src/components/Vg/Inventaire/VgInventaireRecapitulatifLieuId.vue";
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";

import { mapGetters } from 'vuex';
export default {
    name: "vg-inventaire",
    components: {
        TagGrid,

        VgTextFilter,
        VgSwitch,
        VgSelect,
        VgInput,
        VgChips,
        VgButton,
        VgInventaireCreateUpdateForm,
        VgCollapse,
        VgInventaireViewer,
        VgInventaireRecapitulatif,
        VgInventaireRecapitulatifLieuId,
        VgLibelleEditable,
        ActionMenuAggrid,
        DialogSecuredAction,
        VgTreeLieux,
        VgFilterSite,
        VgTextFilter
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Inventaire",
            "resume": "Résumé",
            "etat-equipements": "Etat équipements",
            "mouvements": "Mouvements",
            "name": "Libellé",
            "commentaire": "Commentaire",
            "numero-inventaire": "Numéro inventaire",
            "path": "Localisation",
            "statut": "Statut",
            "totalEquipement": "Total équipements",
            "totalInventorie": "Total inventoriés",
            "not-found": "Equipements Non trouvés",
            "has-moved": "Mouvements",
            "modal-filters-title": "Filtrer l'inventaire"
        },
        "en": {
            "title": "Assets tracking",
            "resume": "Recap",
            "etat-equipements": "Equipment states",
            "mouvements": "Moves",
            "name": "Name",
            "commentaire": "Comment",
            "numero-inventaire": "Assets tracking number",
            "path": "Location",
            "statut": "Status",
            "totalEquipement": "Equipment total",
            "totalInventorie": "inventoried total",
            "not-found": "Not found equipments",
            "has-moved": "Moves",
            "modal-filters-title": "Filter assets tracking"
        },
        "th": {
            "title": "สินค้าคงคลัง"
        }
    }
},
    mixins: [
        TagGridMixins,
        LieuMixins,
        InventaireMixins
    ],
    data: function() {
        return {
            isCreateInventaireModalOpened:false,
            isScannerOpened:false,
            isDeleteInventaireModaledisplayed:false,
            agfilters: {
                path: {attr: "path", value:null, action: "equals"}
            },
            /*agfilters:{
                statut: { colId: "statut", value: null },
                path: { colId: "path", value: null },
                service: { colId: "service", value: null },
                libelEquipement: { colId: "libelEquipement", value:null },
                commentaireClient : { colId: "commentaireClient", value:null },
            },*/
            searchQuery: ''
        }
    },
    watch:{
        /*agfilters: {
            handler: function(newfilters){
                // @WARNING fetch n'utilise pas de metadatas => filtrage en local
                this.TagGridMixins_setQuickFilter(this.gridOptions, newfilters.path.value);
            },
            deep: true
        }*/
    },
    computed: {
        ...mapGetters({
            inventaire: "InventairesStore/getSelectedItem",
            stateByPath: 'InventairesStore/getStateByPath',
            operationsInventaire: 'OperationsInventairesStore/getCollection'
        }),
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
        getColor:function(){
            return this.$vgutils.isMobile() ? "#ffffff" : "black";
        },
        getColorHeader:function(){
            return this.$vgutils.isMobile() ? "#429fff" : "white";
        },
        getEtatInventaire:function(){
            let bon = 0;
            let moyen = 0;
            let mauvais = 0;
            let notFound = 0;
            let hasMoved = 0;
            this.operationsInventaire.map((operationInventaire)=>{
                if(operationInventaire.etat == "Bon"){
                    ++bon;
                }
                else if (operationInventaire.etat == "Moyen") {
                    ++moyen
                }
                else if (operationInventaire.etat == "Mauvais") {
                    ++mauvais
                }
                if(operationInventaire.statut == "notFound"){
                    ++notFound;
                }
                if(operationInventaire.hasMoved){
                    ++hasMoved;
                }
            });
            return {"bon":bon,"moyen":moyen,"mauvais":mauvais,"notFound":notFound,"hasMoved":hasMoved};
        }
    },
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        onSelectPiece: function(row){
            this.$router.push({ name: '_vg_inventaire_id_lieu_id', params: { id: this.$route.params.id, lieuId:row.data.lieu_id }});
        },
        btnBackPage:function(){
            this.$router.back();
        },
        fetch: function(){
            // récupère la liste des pièces à inventorier dans l'inventaire en cours
            this.InventaireMixins_fetchById(this.$route.params.id).then((inventaire)=>{
                // récupère pour l'inventaire les équipements inventoriés et non inventoriés
                this.InventaireMixins_fetchOperationsByInventaireId(this.$route.params.id);
            });
        },
        handleDeleteInventaire:function(){
            console.log("delete inventaire ",this.$route.params.id)
            this.InventaireMixins_deleteInventaire(this.$route.params.id).then(()=>{
                this.isDeleteInventaireModaledisplayed = false;
                this.$router.go(-1);
            })

        }
    },
    created: function() {
        this.fetch();
    }
}
</script>

<style lang="scss">
</style>
