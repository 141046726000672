<template>
    <div class="vg-sites-table">
        <vg-map v-if="sites && sites.length && isMapSiteDisplayed"
            :markers="getSiteMarkers"
            @marker-click="handleClickMarker" />
        <tag-grid v-else-if="!isMapSiteDisplayed && !displayCard"
            :idTableau="'sites-tableau-recap'"
            v-model="gridOptions"
            :columnDefs="columnDefs"
            :rowData="sites"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="onClickRowSite"
            @ag-dbl-click="targetSite"
            @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged" />
        <div v-else-if="displayCard" style="width:100%;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:10px;">
            <div v-for="(site, index) in sites" :key="index"
                style="width:100%;display:flex;justify-content:flex-start;align-items:center;gap:10px;cursor:pointer;"
                @click="targetSite({data:site})">
                <img src="static/assets/icone/circle/site-cercle.png" width="20" height="20"/><span style="color:#3999FF;">{{site.libel_lieu}}</span>
            </div>
        </div>
        <vg-pagination v-if="paginate && !isMapSiteDisplayed"
            :totalItems="counters?counters.sites:0"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        />
    </div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins";
import Metadatas from "src/services/Metadatas";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";
import SiteMobileCellRender from "src/components/Vg/TagGrid/SiteMobileCellRender.vue";
import LibelLieuCellRender from "src/components/Vg/TagGrid/LibelLieuCellRender.vue";
import { mapGetters } from "vuex";
export default {
    name: 'vg-sites-table',
	props: {},
    mixins:[LieuMixins],
	components:{
        TagGrid,
        VgButton,
        VgPagination
	},
    props:{
        /**
        *
        * filters for metadatas.
        *
        */
        filters:{
            type: Array,
            default: function(){
                return [];
            }
        },
        displayMap:{
            type:Boolean,
            default: false
        },
        displayCard:{
            type: Boolean,
            default: false
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: true
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des sites...",
		"overlay-no-rows-text": "Aucun site correspondant au filtrage",
        "intitule": "Intitulé",
        "shob": "SHOB",
        "famille": "Famille",
        "typeErp": "Type ERP",
        "categorieErp": "Catégorie ERP",
        "dateProchaineCommissionDeSecurite": "Date Prochaine commission de securité"
    },
    "en": {
        "overlay-loading-text": "Loading sites...",
		"overlay-no-rows-text": "No site matching filtering",
        "intitule": "Title",
        "shob": "GSA (Grosse Surface Area)",
        "famille": "Family",
        "typeErp": "ERP type",
        "categorieErp": "ERP category",
        "dateProchaineCommissionDeSecurite": "Date Next security committee"
        }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
                {
                    headerName: '',
                    colId: 'checkbox',
                    width: 50,
                    maxWidth: 50,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    pinned: 'left',
                    isVisible: false
                },
                {
                    headerName: this.$t("intitule"),
                    width: 180,
                    field: 'libel_lieu',
                    colId: 'libel_lieu',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme", "vg-important-cell"],
                    cellStyle: {
                        fontWeight: 'bold'
                    },
                    cellRenderer: (params) => new LibelLieuCellRender({
                        propsData: {
                            icon: "static/assets/icone/circle/site-cercle.png",
                            params: params
                        }
                    }).$mount().$el
                },
                {
                    headerName: this.$t("SHOB"),
                    width: 90,
                    field: 'surfaceHorsOeuvreBrut',
                    colId: 'surfaceHorsOeuvreBrut',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("famille"),
                    width: 90,
                    field: 'famille',
                    colId: 'famille',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("typeErp"),
                    width: 90,
                    field: 'typeErp',
                    colId: 'typeErp',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("categorieErp"),
                    width: 90,
                    field: 'categorieErp',
                    colId: 'categorieErp',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("dateProchaineCommissionDeSecurite"),
                    width: 90,
                    field: 'dateProchaineCommissionDeSecurite',
                    colId: 'dateProchaineCommissionDeSecurite',
                    suppressSizeToFit: false,
                    cellClass: ["vg-cell-ballam-theme"]
                },
            ],
			sites: [],
            agfilters:{
                path:{action:"contains",attr: "l.path",colId: "l.path",value: null},
                famille:{action:"equals",attr: "l.famille",colId: "l.famille",value: null},
                field_type_lieu: {attr: "l.type_lieu", value: "Site", action: "equals"}
                //isGroupEqpSite:{"attr":"e.isGroupEqpSite","value":1,"action":"equals"}
            },
            isMapSiteDisplayed: this.displayMap,
            metadatas: new Metadatas()
        };
    },
    watch:{
        filters:{
            handler:function(newFilters){
                this.agfilters = Object.assign({}, this.agfilters, newFilters);
                this.fetch();
            },
            deep:true
        },
        displayMap:{
            handler:function(newValue){
                this.isMapSiteDisplayed = newValue;
            },
            deep:true
        }
    },
    created: function(){
        if (this.$vgutils.isMobile()) this.columnDefs = this.transformColumnDefsForMobile(this.columnDefs);
    },
    mounted:function(){
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    methods:{
        handleMultipleSelectionRowChanged: function(gridOptions){
            var selectedNodes = gridOptions.api.getSelectedNodes();
            let pieces = selectedNodes.map((node)=>{
                return node.data;
            });
            this.$store.dispatch("LieuxStore/setSelectedItems", pieces);
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.agfilters);
            this.LieuMixins_getSites(this.metadatas).then((datas)=>{
                console.log("GET SITES", datas);
                this.sites = datas.lieux;
                this.showLoadingOverlay = false;
            });
        },
        onClickRowSite: function(row){
            if(this.$vgutils.isMobile()) this.targetSite(row);
        },
        /**
        * Emit targetRow on dblClick row.
        *
        * @param Object row
        * @event targetRow
        */
        targetSite:function(row){
            this.$emit("targetRow",row);
            this.$router.push({ name: '_site_id', params: { id: row.data.id }});
        },
        handleClickMarker:function(marker){
            this.$router.push({ name: '_site_id', params: { id: marker.data.id }});

        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        },
        /**
        *
        * @param {array} columnDefs before transformation
        * @return {array} columnDefs all column are hide except the first one with the cell render
        */
        transformColumnDefsForMobile:function(columnDefs){
            columnDefs.forEach((columnDef, i) => {
                columnDef.hide = true;
            });
            columnDefs.unshift({
                cellRenderer: (params) => new SiteMobileCellRender({
                    propsData: {
                        params: params
                    }
                }).$mount().$el,
                cellClass: ["vg-cell-mobile-card"]
            });
            return columnDefs;
        }
    },
	computed: {
        ...mapGetters({
            familles: 'LieuxStore/getFamilles',
            counters: 'LieuxStore/getCounters'
        }),
        getSiteMarkers:function(){
            if(!this.sites || !this.sites.length)return[];
            let sitesWithGeographicCoordinates = this.sites.filter((site) => site.vary && site.varx);
            let famille = null;
            return sitesWithGeographicCoordinates.map((site)=>{
                if(this.familles && this.familles.length!=0 && site.famille) famille = this.familles.find((f)=>f.label==site.famille);
                else famille = null;
                return {
                    "title":site.libel_lieu,
                    "lat" : site.varx,
                    "lng" : site.vary,
                    "data":site,
                    "options":{},
                    "color": famille?famille.color:null
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
	.vg-sites-table{
        height: 90%;
    }
</style>
