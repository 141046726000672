<template>
	<div>
        <h4>Derniers déplacement temporaire</h4>
        <ul>
            <li>date : {{ value[0].dateMouvement }}</li>
            <li>retour prevu le :{{ value[0].dateRetourPrevu }}</li>
            <li>Commentaire : {{ value[0].commentaire }}</li>
            <li><a>Voir les détails de cette sortie</a></li>
        </ul>
    </div>
</template>
<script>

	export default {
        name: 'mouvement-viewer',
		i18n:
        {
            "locale":navigator.language,
            "messages": {
                "fr": {
                },
                "en": {
                }
            }
        },
		components:{

		},
    	props: {
			value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
			};
        },
		computed: {
			title: function(){}
        }
    };

</script>
<style scoped lang="scss">

</style>
