<template lang="html">
    <div class="vg-consommable-selector">
        <div class="search">
            <vg-input v-model="searchQuery"
                :placeholder="$t('search')"
                @delayed-input="onDelayedInput"/>
        </div>
        <div class="tableau">
            <vg-consommables-table
                :id="'consommables-list-selector'"
                :isTableau="false"
                :filters="agfilters"
                paginate
                @row-click="handleRowClick"/>
        </div>
    </div>
</template>

<script>
import VgConsommablesTable from "src/components/Vg/Consommables/VgConsommablesTable.vue";

import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgInput from "src/components/Vg/VgInput.vue";
import Metadatas from "src/services/Metadatas.js";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'vg-consommable-selector',
    components: {
        TagGrid,
        VgInput,
        VgConsommablesTable
    },
    props:{
        filters:{
            type: Object,
            default:function(){
                return {};
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "search": "Recherche..."
        },
        "en": {
            "search": "Searching..."
        }
    }
},
    mixins: [],
    data: function(){
        return {
            searchQuery:'',
            agfilters:{}
        }
    },
    created:function(){
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
    },
    methods:{
        onDelayedInput: function(value){
            console.log("DELAYED INPUT", value);
            this.agfilters = Object.assign({}, this.agfilters, {
                name: {attr: "c.name", value: value, action: "contains", openParenthesis: true},
                refExterne: {attr: "c.refExterne", value: value, action: "contains", logicalOperator: "OR"},
                marque: {attr: "c.marque", value: value, action: "contains", logicalOperator: "OR"},
                numSerie: {attr: "c.numSerie", value: value, action: "contains", logicalOperator: "OR"},
                commentaire: {attr: "c.commentaire", value: value, action: "contains", logicalOperator: "OR"},
                code: {attr: "c.code", value: value, action: "contains", logicalOperator: "OR"},
                productGroup: {attr: "c.productGroup", value: value, action: "contains", logicalOperator: "OR"},
                productSubGroup: {attr: "c.productSubGroup", value: value, action: "contains", logicalOperator: "OR"},
                category: {attr: "c.category", value: value, action: "contains", logicalOperator: "OR"},
                nature: {attr: "c.nature", value: value, action: "contains", logicalOperator: "OR", closeParenthesis: true},
            });
        },
        /**
         * action clique sur ligne du tableau
         * @params {Object} row
         * @emit {Object} consommable selectionné
         */
        handleRowClick: function(row){
            console.log("handleClickRow", row);
            this.$emit("selected", row.data);
        },
    },
    computed: {

    }
}
</script>

<style lang="scss" >
.vg-consommable-selector-cellrender{
    display: flex;
    align-items: center;
}
.vg-consommable-selector{
    height: 100%;
    .tableau{
        height: 300px;
    }
}
</style>
<docs>

    ### vg-consommable-selector


    ```js
          import VgConsommableSelect from "src/components/Vg/Selectors/VgConsommableSelector.vue";
    ```

    #### basic usage

    ```html static
          <vg-consommable-selector  @select-row="selectConsommable"></vg-input>
    ```
    #### Emit : select-row

    Retourne la valeur selectionner, clique sur la row


</docs>
