<template>
	<div class="vg-week-selector">
		<vg-button-group>
			<vg-button
				:type="'default-link'"
				:size="'sm'"
				@click="decrementWeek()">
				<i class="fas fa-chevron-left"></i>
			</vg-button>
			<vg-select v-model="period.week"
				:placeholder="$t('select-week')"
				:clearable="false"
				:options="weeks"
				@input="emitChange()">
			</vg-select>
			<vg-button
				:type="'default-link'"
				:size="'sm'"
				@click="incrementWeek()">
				<i class="fas fa-chevron-right"></i>
			</vg-button>
		</vg-button-group>
		<span>{{startday}} - {{endday}}</span>
	</div>
</template>
<script>
import VgButtonGroup from "src/components/Vg/VgButtonGroup.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
export default {
	name: "vg-week-selector",
	components: {
		VgButtonGroup,
		VgButton,
		VgSelect
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-week": "Sélectionner une semaine"
        },
        "en": {
            "select-week": "Select a week"
        },
        "th": {
            "select-week": "เลือกสัปดาห์"
        }
    }
} ,
	props: {
        value: Object
	},
	data: function(){
		return {
			period: this.value
		};
	},
	mounted: function() {

	},
	methods: {
		emitChange: function(){
			this.$emit('input', this.period);
		},
		decrementWeek: function(){
			var tmpweek = moment().year(this.period.year).isoWeek(this.period.week).subtract(1, 'week');
			this.period.week = tmpweek.isoWeek();
			this.period.year = tmpweek.year();
			this.emitChange();
		},
		incrementWeek: function(){
			var tmpweek = moment().year(this.period.year).isoWeek(this.period.week).add(1, 'week');
			this.period.week = tmpweek.isoWeek();
			this.period.year = tmpweek.year();
			this.emitChange();
		}
	},
	computed: {
		startday: function(){
			return moment().year(this.period.year).isoWeek(this.period.week).isoWeekday(1).format("DD/MM/YYYY");
		},
		endday: function(){
			return moment().year(this.period.year).isoWeek(this.period.week).isoWeekday(7).format("DD/MM/YYYY");
		},
		weeks: function(){
			var weeks = [];
			for(var week=52; 0<week; week--){
				weeks.push(week);
			}
			return weeks;
		}
	}
}
</script>

<style lang="scss" scoped>
.vg-week-selector{
	width: 180px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	>span{
		font-size: 11px;
		margin-top: 5px;
	}
}
</style>
