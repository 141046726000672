<template>
	<div class="reponse-consommation">
		<span class="text-reponse" @click="onClickReponse">
			{{$t("quantite-de", {unite: reponse.checkpoint.consommable.unite?reponse.checkpoint.consommable.unite:$t("default-unite"), name: reponse.checkpoint.consommable.name})}}
		</span>
		<div class="input-reponse">
			<vg-input-number
                v-model="reponse.reponse"
                @input="onChangeNumber"
                :placeholder="reponse.reponse" />
		</div>
	</div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import VgInputNumber from 'src/components/Vg/VgInputNumber.vue';

import moment from "moment";

export default {
    name: "reponse-consommation",
    components:{
        VgInputNumber
    },
    i18n:    {
    "messages": {
        "fr": {
            "checkCompteur": {
                "mobile": {
                    "min": "min",
                    "max": "max"
                }
            },
			"default-unite": "u.",
			"ref": "Réf.",
			"quantite-de": "Qté de {unite} de {name}"
        },
        "en": {
            "checkCompteur": {
                "mobile": {
                    "min": "min",
                    "max": "max"
                }
            },
			"quantite-de": "Qty of {unite} of {name}"
        },
        "th": {
            "checkCompteur": {
                "mobile": {
                    "min": "ต่ำสุด",
                    "max": "สูงสุด"
                }
            }
        }
    }
}       ,
    mixins:[],
    props:{
        tache: Object,
		reponse: Object
    },

    filters : {

    },
    data: function() {
        return {

        };

    },
    computed: {

    },
    methods: {
        onChangeNumber: function(value){
			// //console.log("ON CHANGE NUMBER", value);
			this.$emit("change", value);
		},
        onClickReponse: function(){
			this.$emit('select', this.reponse);
		},
    },
    created: function(){
		if(!this.reponse.reponse) this.reponse.reponse=0;
    },
    mounted: function(){

    }
};
</script>

<style lang="scss" scoped>
	.reponse-consommation{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 5px;
		padding: 10px 0;
		border-bottom: 1px solid #d7d7d9;
		.text-reponse{
			color: #74767a;
			font-size: 12px;
		}
		.input-reponse{

		}
	}
</style>
