<template>
    <div class="tui-full-calendar-popup tui-full-calendar-popup-detail" :style="getPosition">
        <div class="tui-full-calendar-popup-container">
            <div class="tui-full-calendar-popup-section tui-full-calendar-section-header">
                <div class="tui-full-calendar-schedule-title maintenance">
                    <div class="popup-title">
                        <span class="tui-full-calendar-schedule-private tui-full-calendar-icon tui-full-calendar-ic-private"></span>
                        <span class="header-tui">
                            {{schedule.raw.nEvents}} {{$t("events")}}
                        </span>
                        <div class="popup-close-modal">
                            <i class="fas fa-times close" @click="closePopup()"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tui-full-calendar-section-detail">
                <div class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                    <span class="tui-full-calendar-content">
                        <div class="popup-detail-body">
                            <div v-for="event, index in schedule.raw.datas" class="schedule-item">
                                <schedule-template
                                    v-model="schedule.raw.datas[index]"
                                    :calendar="getCalendar(event)"
                                    @click="onClickSchedule(event)">
                                </schedule-template>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import ScheduleTemplate from "src/components/Vg/Calendar/ScheduleTemplate.vue";

    export default Vue.extend({
        name: "popup-more",
		props: {
            /**
            * schedule
            */
			value: {
				type: Object,
				required: true
			},
            event: {
                type: Object,
                required: true
            },
            calendars: {
                type: Array,
                default: ()=>{return [];}
            }
		},
        filters:{
            onlyDate: function(timestamp) {
                return moment(timestamp).format("DD/MM/YY");
            },
            hour: function(timestamp) {
	            return moment(timestamp).format("HH:mm");
	        },
            site: function(path){
                var splitPath = path.split("/");
                return splitPath[1];
            }
        },
        data: function() {
            return {
                schedule: this.value,
                modalFm:false,
                modalVerification:false,
            };
        },
        components: {
            ScheduleTemplate
        },
        mixins:[],
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "events": "évènements supplémentaires"
        },
        "en": {
            "events": "additional events"
        },
        "th": {
            "events": "กิจกรรมเพิ่มเติม"
        }
    }
},
        watch: {
            value: {
                handler: function(val){
                    this.schedule = val;
                },
                deep: true
            }
        },
        methods:{
            closePopup: function(){
                this.$emit("close-popup", {schedule: this.schedule, calendar: this.calendar});
            },
            getCalendar: function(schedule){
                return this.calendars.find((calendar)=>calendar.id==schedule.calendarId);
            },
            onClickSchedule: function(schedule){
                this.$emit("open-schedule", {schedule: schedule, calendar: this.getCalendar(schedule)});
            }
        },
        computed: {
            getDate: function(){
                return moment(this.schedule.start._date).format("DD/MM/YYYY");
            },
            getHours: function(){
                if(moment(this.schedule.start._date).format("HH:mm")==moment(this.schedule.end._date).format("HH:mm"))
                    return moment(this.schedule.start._date).format("HH:mm");
                else return moment(this.schedule.start._date).format("HH:mm")+" - "+moment(this.schedule.end._date).format("HH:mm");
            },
            getPosition: function(){
                //console.log("GET POSITION", this.event, this.event.x, this.event.y);
                let event = this.event;
                var heightScreen = window.innerHeight;
                //console.log(heightScreen);
                var toptPos = 0;
                if (event.pageY >= heightScreen-300) {
                    toptPos = event.pageY - 330
                }
                else{
                    toptPos = event.pageY + 30;
                }
                var leftPos = event.pageX - 150;
                //console.log(toptPos ,leftPos);
                return "position:absolute !important;top:"+toptPos+"px !important;left:"+leftPos+"px !important;";
            },
            isAffectationInterne: function(){
                return this.schedule.raw.affectation.affectes.findIndex((aff)=>aff.roles)!=-1;
            },
            getTiers: function(){
                let tiers = this.schedule.raw.affectation.affectes.find((aff)=>aff.name);
                //console.log("TIERS", tiers);
                return tiers.name;
            }
        }
    });
</script>

<style lang="scss" scoped>
.schedule-item{

}
.tui-full-calendar-popup-detail{
    z-index: 1003;
}
.tui-full-calendar-popup-container{
    padding:0;
    &.red{
        border-top: 10px solid #f5504a;
    }
    &.green{
        border-top: 10px solid #53b84f;
    }
    &.blue{
        border-top: 10px solid #A6C5EB;
    }

    .tui-full-calendar-section-header{
        padding-left:10%;
        padding-top: 10%;
    }
    .tui-full-calendar-section-detail{
        padding:0 10%;
    }
    .popup-title{
        display: flex;
    }
    .header-tui{
        font-weight: bold;
        width: 80%;
        font-size: 12px;
        word-break: break-all;
        &.notBold{
            font-weight: normal;
        }
        &.verification{
            display: flex;
            .space{
                width: 22%;
            }
            .header-title{
                width: 78%;
                word-break: break-word
            }
        }
        .vg-fichesav-description{
            word-break: break-word;
        }
    }
    .popup-close-modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
    }
    .footer{
        background-color: #d7d7d7;
        height: 50px;
    }
}
.popup-detail-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 200px;
    overflow-y: auto;
    >.schedule-item{
        border: 1px solid #888888;
        margin-bottom: 5px;
        padding: 5px;
    }
}
.icon{
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.label-fm{
    color: #a4a4a4;
}
.close{
    float: right;
    cursor: pointer;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
