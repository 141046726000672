<template lang="html">
	<vg-input :title="title?title:$t('title')">
		<vg-sortie-type-selector v-model="selectedType"
			@input="handleChange" />
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSortieTypeSelector from "src/components/Vg/Selectors/VgSortieTypeSelector.vue";

    import Metadatas from "src/services/Metadatas.js"
    export default {
        name: "vg-filter-sortie-type",
	    components: {
			VgInput,
			VgSortieTypeSelector
        },
		mixins:[

        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: String
			},
			title: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par type sortie"
        },
        "en": {
            "title": "Filtering by output type"
        }
    }
},
        data: function(){
            return {
				selectedType: this.value
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedType = val;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			/**
	         * emit le type selectionné
	         * @event input type
	         */
			handleChange: function(type){
				this.$emit("input", type);
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
<docs>
    vg-filter-sortie-type
</docs>
