<template lang="html">
	<vg-input
		:title="$t('title')">
		<vg-categorie-selector v-model="focusedCategorie" 
			:multiple="multiple"
			@input="onInputCategorie" />
	</vg-input>
</template>
<script>

	import VgInput from "src/components/Vg/VgInput.vue";
	import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
    import CategorieMixins from "src/mixins/CategorieMixins";
    export default {
        name: 'vg-filter-categorie',
	    components: {
			VgInput,
			VgCategorieSelector
        },
		mixins:[CategorieMixins],
        props: {
			value: {
				type: String,
				required: true
			},
			multiple:{
				type: Boolean,
				default: false
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par catégories"
        },
        "en": {
            "title": "Filter by categories"
        },
        "th": {
            "title": "ตัวกรองตามหมวดหมู่"
        }
    }
},
        data: function(){
            return {
				focusedCategorie: this.value || null
			};
        },
		watch: {
			value: {
				handler: function(val){
					this.focusedCategorie = val;
				}
			}
		},
		created: function(){
            
		},
        methods: {
			onInputCategorie: function(e){
				this.$emit("input", this.focusedCategorie);
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
