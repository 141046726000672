//Notifications store module

// initial state
const state = {
    read:[],
    notRead:[],
    messages: []
};

// getters
const getters = {
    getAllNotifications: function( state ){
      return state.messages;
    },
    getAllDashboardRefreshNotifications: function( state ){
        return state.messages.filter(message => message.message.action == 'dashboard:refresh');
    },
    getNotificationsNotRead: function( state ){
      return state.messages.filter(message => !message.isRead);
    },
    getNotificationsRead: state => {
      return state.messages.filter(message => message.isRead);
    },
    countNotificationsNotRead: ( state, getters ) => {
      return getters.getNotificationsNotRead.length;
    }
};

// actions
const actions = {
    addNotRead: function( context, message ){
        context.commit('NOTIFICATION_ADD_NOT_READ', message);
    },
    addMessage: function( context, message ){
        context.commit('NOTIFICATION_ADD_MESSAGE', message);
    },
    addMessages: function( context, messages ){
        context.commit('NOTIFICATION_ADD_MESSAGES', messages );
    },
    notificationsRestoreFromStorage: function( context ){
        var storedNotifs = window.localStorage.getItem('notifs');
        ////// //console.log( storedNotifs );
        context.commit('NOTIFICATION_RESTORE_STORAGE', JSON.parse(storedNotifs) );
    },
    resetNotification: function( context ){
        context.commit('NOTIFICATION_RESET_MESSAGES');
    }
};

// mutations
const mutations = {
    NOTIFICATION_RESET_MESSAGES: function( state ){
            state.read = [],
            state.notRead = [],
            state.messages = []
    },
    NOTIFICATION_ADD_MESSAGE: function( state, message ){
        
        //console.group('NOTIFICATION_ADD_MESSAGE mutation');
        state.messages.push( message );
        //localStorage.removeItem('notifs');
        ////// //console.log(state.messages);
        //localStorage.setItem('notifs',JSON.stringify( state.messages ));
        //console.groupEnd('NOTIFICATION_ADD_MESSAGE mutation');
    },
    NOTIFICATION_ADD_MESSAGES: function( state, messages ){
        var lastMessageIndex = messages.length;
        for( var index = 0 ; index < lastMessageIndex ; ++index ){
            state.messages.push( messages[index] );
        }
    },
    NOTIFICATION_ADD_NOT_READ: function( state, message ){
        state.notRead.push( message );
    },
    NOTIFICATION_PUSH_NOT_READ_TO_READ: function( state, message ){
        state.read.push(message);
        //prevoir enlevement dans notRead
    },
    NOTIFICATION_RESTORE_STORAGE: function( state, storedNotifs ){
        
        //// //console.log("getAllNotifications from storage");
        if(state.messages.length == 0 && storedNotifs && storedNotifs.length != 0){
            state.messages = storedNotifs;
            //// //console.log("getAllNotifications from storage");
        }
    }
};
export default {
  state,
  getters,
  actions,
  mutations
};