<template>
	<span :class="'statut-'+fiche.statut">
		{{$t("statut-"+fiche.statut)}}
	</span>
</template>
<script>
    export default {
        name: 'vg-fiche-demande-consommables-statut-viewer',
    	props: {
            fiche: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "statut-en-cours": "Demande en cours",
            "statut-en-attente": "Demande en attente",
            "statut-prise-en-compte": "Demande prise en compte",
            "statut-resolue": "Consommables livrés"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>
span{
	margin-left: 5px;
	vertical-align: middle;
	font-size: 11px;
	display: inline-block;
	line-height: 1;
	padding: .5833em .833em;
	font-weight: 700;
	border: 0 solid transparent;
    border-radius: .28571429rem;
}
.statut-en-cours{
	color: white;
	border-color: #f5504a;
	background-color: #f5504a;
}
.statut-en-attente{
	color: white;
	border-color: #a5673f;
	background-color: #a5673f;
}
.statut-prise-en-compte{
	color: white;
	border-color: #2185d0;
	background-color: #2185d0;
}
.statut-resolue{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
</style>
