import 'src/directives/TableLoader/AppLoader.css';
var TabLoaderDirective = {}
TabLoaderDirective.install = function install (_Vue) {
  let _uid = 'vue-directive-loading-app' + Date.now().toString('16')
  _Vue.directive('app-loader', {
    inserted: function (el, binding) {
      let spinner = document.createElement('div');
      spinner.id = _uid;
      spinner.style.display = binding.value ? 'block' : 'none';
      let overlay = '<div id="overlay" ref="first-app-loader" ><div class="lds-heart"><div></div></div></div>';
      let htmlTemplate = `
                    ${overlay}
      `;
      spinner.id = _uid
      /*spinner.classList.add("loader");*/
      spinner.innerHTML = htmlTemplate.trim();
      /*el.childNodes.forEach((item) => {
        item.style.display = binding.value ? 'none' : ''
    })*/
      el.appendChild(spinner)
    },
    update: function (el, binding, vnode) {
      let spinner = document.getElementById(_uid);
      spinner.style.display = binding.value ? 'block' : 'none';
      el.childNodes.forEach((item) => {
        if(item.id === _uid) return;
        if(item && item.style) item.style.display = binding.value ? 'none' : '';
    })
    }
  })
}
export default TabLoaderDirective;
