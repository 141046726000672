<template>
	<div class="vg-fichesav-description">
		<img v-if="showIcon && fm.isGEP=='1' && fm.libelComposant && fm.iconComposant"
			class="icon"
			:src="fm.iconComposant"
			onerror="this.style.visibility='hidden'"/>
		<img v-else-if="showIcon && fm.equipement && fm.equipement.isGroupEqp=='1' && fm.composant && fm.composant.libelComposant && fm.composant.icon"
			class="icon"
			:src="fm.composant.icon"
			onerror="this.style.visibility='hidden'"/>
		<img v-else-if="showIcon && fm.equipement && fm.equipement.categorie_icon"
			class="icon"
			:src="fm.equipement.categorie_icon"
			onerror="this.style.visibility='hidden'"/>
        <div :class="['dotted-border', {'inline-text': inline}]">
            {{getLibel}} {{fm.commentaireClient}}
			<span v-if="showPiece && fm.equipement">
				{{$t("dans")}}
				<vg-lieu-path-viewer v-model="fm.equipement.path" :onlyPiece="true" />
			</span>
        </div>
	</div>
</template>
<script>
	import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    export default {
        name: 'vg-fichesav-description',
    	props: {
            fm: {
				type: Object,
				required: true
			},
			showIcon: {
				type: Boolean,
				default: true
			},
			showPiece: {
				type: Boolean,
				default: false
			},
			inline: {
				type: Boolean,
				default: false
			}
        },
		components:{
			VgLieuPathViewer
		},
		computed: {
			getLibel: function(){
				/*<span v-if="fm.libelEquipement && fm.isGEP!='1'">{{fm.libelEquipement}}&nbsp;</span> <!--OLD-->
				<span v-else-if="fm.libelEquipement && fm.isGEP=='1'">{{fm.libelComposant}}&nbsp;</span> <!--OLD-->
				<span v-else-if="fm.equipement && fm.equipement.isGroupEqp!='1'">{{fm.equipement.libel_equipement}}&nbsp;</span>
				<span v-else-if="fm.composant">{{fm.composant.libelComposant}}&nbsp;</span>*/
				if(this.fm.libelEquipement && this.fm.isGEP!='1') return this.fm.libelEquipement;
				else if(this.fm.libelEquipement && this.fm.isGEP=='1') return this.fm.libelComposant;
				else if(this.fm.equipement && this.fm.equipement.isGroupEqp!='1') return this.fm.equipement.libel_equipement;
				else if(this.fm.composant) return this.fm.composant.libelComposant;
				else return "-";
			}
		}
    };

</script>
<style lang="scss" scoped>
	.vg-fichesav-description{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 3px;
		overflow: auto;
		.icon{
			height: 20px;
			width: 20px;
			margin-right: 10px;
			margin-top: 4px;
		}
	}
	.dotted-border{
		height: inherit;
		text-overflow: ellipsis;
	}
    .dotted-border:hover{
        text-decoration: underline dotted #00abe2;
        cursor: pointer;
    }
	.inline-text{
		display: inline;
		white-space: nowrap;
		text-overflow: ellipsis;
		width:fit-content;
	}
	@media screen and (max-width: 600px) {
		.icon{
			margin-top: 0px !important;
		}
	}

</style>
