"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.IFCDatasExtractor = void 0;
var IFCManager_1 = require("./IFCManager");
var web_ifc_api_1 = require("web-ifc/web-ifc-api");
var IFCDatasExtractor = /** @class */ (function (_super) {
    __extends(IFCDatasExtractor, _super);
    function IFCDatasExtractor(ifcText) {
        var _this = _super.call(this) || this;
        _this.levels = [];
        _this.buildings = [];
        //this.ifcUrl = ifcUrl;
        _this.ifcText = ifcText;
        return _this;
    }
    IFCDatasExtractor.prototype.init = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.openModelText(_this.ifcText).then(function () {
                _this.getSpatialStructure().then(function (e) {
                    _this.rawDataObject = e;
                    console.log(e);
                    _this.extractBuildings(e);
                    _this.extractLevels(_this.buildings);
                    resolve(e);
                });
            });
            /*this.openModel(this.ifcUrl).then((e)=>{
                this.getSpatialStructure().then((e)=>{
                    this.rawDataObject = e;
                    console.log(e)
                    this.extractBuildings(e);
                    this.extractLevels(this.buildings);
                    resolve(e);
                });
            })*/
        });
    };
    IFCDatasExtractor.prototype.getLevels = function () {
        return this.levels;
    };
    /**
     * Get all IFCSPACE from ifc file
     * @param integer modelID
     * @param boolean modelID
     * @returns array
     */
    IFCDatasExtractor.prototype.getAllLevels = function (modelID, flatten) {
        // Get all the propertyset lines in the IFC file
        var lines = this.ifcManager.GetLineIDsWithType(modelID, web_ifc_api_1.IFCBUILDINGSTOREY);
        var lineSize = lines.size();
        var spaces = [];
        for (var i = 0; i < lineSize; i++) {
            // Getting the ElementID from Lines
            var relatedID = lines.get(i);
            // Getting Element Data using the relatedID
            var relDefProps = this.ifcManager.GetLine(modelID, relatedID, flatten);
            spaces.push(relDefProps);
        }
        console.log(spaces);
        return spaces;
    };
    IFCDatasExtractor.prototype.getLinesMatchingType = function (types) {
        var _this = this;
        var groups = {};
        var promises = [];
        types.forEach(function (type) {
            var lines = _this.ifcManager.GetLineIDsWithType(_this.modelID, type);
            var lineSize = lines.size();
            var entities = [];
            for (var i = 0; i < lineSize; i++) {
                // Getting the ElementID from Lines
                var relatedID = lines.get(i);
                // Getting Element Data using the relatedID
                var relDefProps = _this.ifcManager.GetLine(_this.modelID, relatedID, true);
                //promises.push(this.ifcManager.properties.getTypeProperties(this.modelID, relatedID));
                entities.push(relDefProps);
            }
            groups[type] = entities;
            Promise.all(promises).then(function (e) {
                console.log(e);
            });
        });
        console.log(groups);
    };
    IFCDatasExtractor.prototype.getSpatialStructure = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.ifcManager.properties.getSpatialStructure(_this.modelID, true).then(function (spatialStructure) {
                resolve(spatialStructure);
            });
        });
    };
    /**
     *
     * @param Object rawDataObject
     */
    IFCDatasExtractor.prototype.extractBuildings = function (rawDataObject) {
        if (this.hasSite() && this.hasBuildings()) {
            this.buildings = rawDataObject.children[0].children;
        }
        else if (!this.hasSite() && this.hasBuildings()) {
            this.buildings = rawDataObject.children;
        }
    };
    /**
     *
     * @param Building buildings
     */
    IFCDatasExtractor.prototype.extractLevels = function (buildings) {
        var _this = this;
        buildings.forEach(function (building) {
            if (_this.hasLevels(building)) {
                building.children.forEach(function (level) {
                    var _a, _b;
                    var entitiesGroupByTypes = level.children; //this.groupBy(level.children,"type");
                    var lvl = {
                        expressID: level.expressID,
                        type: level.type,
                        name: (_a = level.Name) === null || _a === void 0 ? void 0 : _a.value,
                        longName: (_b = level.LongName) === null || _b === void 0 ? void 0 : _b.value,
                        children: entitiesGroupByTypes,
                        building: building
                    };
                    _this.levels.push(lvl);
                });
            }
        });
    };
    IFCDatasExtractor.prototype.hasSite = function () {
        return this.rawDataObject.children.length && this.rawDataObject.children[0].type === "IFCSITE";
    };
    IFCDatasExtractor.prototype.hasBuildings = function () {
        return this.rawDataObject.children[0].children.length > 0;
    };
    IFCDatasExtractor.prototype.hasLevels = function (building) {
        return building.children.length > 0;
    };
    IFCDatasExtractor.prototype.groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    return IFCDatasExtractor;
}(IFCManager_1.IFCManager));
exports.IFCDatasExtractor = IFCDatasExtractor;
