<template>
	<vg-select v-model="selectedMaintenance"
		:options="maintenances"
		:attributeLabel="'name'"
		:attributeValue="attributeValue"
		@input="$emit('input',selectedMaintenance)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-maintenance-selector',
		components: {
			VgSelect
		},
		mixins: [MaintenanceMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet maintenance à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				selectedMaintenance: this.value,
				metadatasMaintenance: new Metadatas(),
				agfilters: {}
            };
        },
		watch: {
			value: {
				handler: function(val){
					this.selectedMaintenance = val;
				}
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				this.metadatasMaintenance.setFilters(this.agfilters);
				this.MaintenanceMixins_getMaintenances(this.metadatasMaintenance).then((datas)=>{
					//console.log("MAINTENANCES", datas);
					this.$emit("fetch-success", {maintenances: datas});
				});
			}
		},
        computed: {
			...mapGetters({
                maintenances: 'MaintenancesStore/getMaintenances'
            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
