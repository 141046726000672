import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.etage = null;
state.LatLng = [0, 0];
state.zoom = 0;
state.viewOptions = {};
state.files = {
    /*
    geojson-pieces: {file: ..., link: ...}
    geojson-pieces-draft: ...
    geojson-equipements: ...
    geojson-equipements-draft: ...
    */
};
state.fondDePlan = null;
state.geoJsonPieces = null;
state.geoJsonPiecesDraft = null;
state.geoJsonEquipements = null;
state.geoJsonEquipementsDraft = null;
state.newPieceMarker = null;
state.dragEquipement = null;
state.focusedPieceMarker = null;
state.hasPiecesDraftChanged = false;
state.hasEquipementsDraftChanged = false;
state.markerEquipementVersion = "version-1";
let getters = Base.getGetters();
getters.getEtage = function( state ){
    return state.etage;
};
getters.getLatLng = function( state ){
    return state.LatLng;
};
getters.getZoom = function( state ){
    return state.zoom;
};
getters.getViewOptions = function( state ){
    return state.viewOptions;
};
getters.getFiles = function( state ){
    return state.files;
};
getters.getFondDePlan = function( state ){
    return state.fondDePlan;
};
getters.getGeoJsonPieces = function( state ){
    return state.geoJsonPieces;
};
getters.getGeoJsonPiecesDraft = function( state ){
    return state.geoJsonPiecesDraft;
};
getters.getGeoJsonEquipements = function( state ){
    return state.geoJsonEquipements;
};
getters.getGeoJsonEquipementsDraft = function( state ){
    return state.geoJsonEquipementsDraft;
};
getters.getNewPieceMarker = function( state ){
    return state.newPieceMarker;
};
getters.getDragEquipement = function( state ){
    return state.dragEquipement;
};
getters.getFocusedPieceMarker = function( state ){
    return state.focusedPieceMarker;
};
getters.getHasPiecesDraftChanged = function( state ){
    return state.hasPiecesDraftChanged;
};
getters.getHasEquipementsDraftChanged = function( state ){
    return state.hasEquipementsDraftChanged;
};
getters.getMarkerEquipementVersion = function( state ){
    return state.markerEquipementVersion;
};
let actions = Base.getActions();
actions.setEtage = function( context, value ){
    context.commit('SET_ETAGE', value);
};
actions.setLatLng = function( context, value ){
    context.commit('SET_LAT_LNG', value);
};
actions.setZoom = function( context, value ){
    context.commit('SET_ZOOM', value);
};
actions.setViewOptions = function( context, value ){
    context.commit('SET_VIEW_OPTIONS', value);
};
actions.setFiles = function( context, value ){
    context.commit('SET_FILES', value);
};
actions.clearFiles = function( context ){
    context.commit('CLEAR_FILES');
};
actions.setFondDePlan = function( context, value ){
    context.commit('SET_FOND_DE_PLAN', value);
};
actions.setGeoJsonPieces = function( context, value ){
    context.commit('SET_GEOJSON_PIECES', value);
};
actions.setGeoJsonPiecesDraft = function( context, value ){
    context.commit('SET_GEOJSON_PIECES_DRAFT', value);
};
actions.setGeoJsonEquipements = function( context, value ){
    context.commit('SET_GEOJSON_EQUIPEMENTS', value);
};
actions.setGeoJsonEquipementsDraft = function( context, value ){
    context.commit('SET_GEOJSON_EQUIPEMENTS_DRAFT', value);
};
actions.setNewPieceMarker = function( context, value ){
    context.commit('SET_NEW_PIECE_MARKER', value);
};
actions.setDragEquipement = function( context, value ){
    context.commit('SET_DRAG_EQUIPEMENT', value);
};
actions.setFocusedPieceMarker = function( context, value ){
    context.commit('SET_FOCUS_PIECE_MARKER', value);
};
actions.setHasPiecesDraftChanged = function( context, value ){
    context.commit('SET_HAS_PIECES_DRAFT_CHANGED', value);
};
actions.setHasEquipementsDraftChanged = function( context, value ){
    context.commit('SET_HAS_EQUIPEMENTS_DRAFT_CHANGED', value);
};
actions.setMarkerEquipementVersion = function( context, value ){
    context.commit('SET_MARKER_EQUIPEMENT_VERSION', value);
};
let mutations = Base.getMutations();
mutations.SET_ETAGE = function(state, value){
    state.etage = value;
};
mutations.SET_LAT_LNG = function(state, value){
    state.LatLng = value;
};
mutations.SET_ZOOM = function(state, value){
    state.zoom = value;
};
mutations.SET_VIEW_OPTIONS = function(state, values){
    state.viewOptions = values;
};
mutations.SET_FILES = function(state, files){
    state.fondDePlan = files["fond-de-plan"];
    state.files = Object.assign({}, {}, files);
};
mutations.CLEAR_FILES = function(state){
    state.etage = null;
    state.fondDePlan = null;
    state.geoJsonPieces = null;
    state.geoJsonPiecesDraft = null;
    state.geoJsonEquipements = null;
    state.geoJsonEquipementsDraft = null;
    state.newPieceMarker = null;
    state.files = {};
};
mutations.SET_FOND_DE_PLAN = function(state, value){
    state.fondDePlan = value;
};
mutations.SET_GEOJSON_PIECES = function(state, value){
    state.geoJsonPieces = value;
};
mutations.SET_GEOJSON_PIECES_DRAFT = function(state, value){
    state.geoJsonPiecesDraft = value;
};
mutations.SET_GEOJSON_EQUIPEMENTS = function(state, value){
    state.geoJsonEquipements = value;
};
mutations.SET_GEOJSON_EQUIPEMENTS_DRAFT = function(state, value){
    state.geoJsonEquipementsDraft = value;
};
mutations.SET_NEW_PIECE_MARKER = function(state, value){
    state.newPieceMarker = value;
};
mutations.SET_DRAG_EQUIPEMENT = function(state, value){
    state.dragEquipement = value;
};
mutations.SET_FOCUS_PIECE_MARKER = function(state, value){
    state.focusedPieceMarker = value;
};
mutations.SET_HAS_PIECES_DRAFT_CHANGED = function(state, value){
    state.hasPiecesDraftChanged = value;
};
mutations.SET_HAS_EQUIPEMENTS_DRAFT_CHANGED = function(state, value){
    state.hasEquipementsDraftChanged = value;
};
mutations.SET_MARKER_EQUIPEMENT_VERSION = function(state, value){
    state.markerEquipementVersion = value;
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
