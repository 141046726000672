<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getIconPage"
        :colorheader="getHeaderBackgroundColor"
        :isActionButtonDisplay="false"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters">
        <template v-slot:search>
            <vg-text-filter
                v-model="searchQuery"
            >
            </vg-text-filter>
        </template>
        <template v-slot:primary>
            <vg-fichesav-activite :agfilters="agfilters"
                style="height:100%;"/>
        </template>
        <template #secondary-filters>
            <vg-filter-site v-model="agfilters.path.value"/>
            <!-- Interval date -->
            <vg-input :title="$t('filter-date')" >
                <vg-date-picker-shortcuts key="date-picker-shortcuts-fil"
                    previousDatesPresets
                    :defaultStartDate="dateIntervalleRange.startDate"
                    :defaultEndDate="dateIntervalleRange.endDate"
                    opens="left"
                    :appendToBody="true"
                    @select="onChangeDateIntervalle"
                    @finish-selection="onChangeDateIntervalle" />
            </vg-input>
            
            <!-- Filtre Service -->
            <vg-filter-service v-model="agfilters.service.value"/>
            <!-- Filtre demandeur -->
            <vg-filter-demandeur v-model="demandeurFilter"
                @input="onChangeFiltreDemandeur"/>
            <!-- Filtre statut -->
            <vg-filter-fichesav-statut v-model="agfilters.statut.value" />
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                :readOnly="true" v-model="agfilters.path.value"/>
        </template>
        <template #panel-filters-attributs>
            <!-- Interval date -->
            <vg-input :title="$t('filter-date')" >
                <vg-date-picker-shortcuts key="date-picker-shortcuts-fil"
                    previousDatesPresets
                    opens="right"
                    :defaultStartDate="dateIntervalleRange.startDate"
                    :defaultEndDate="dateIntervalleRange.endDate"
                    @select="onChangeDateIntervalle"
                    @finish-selection="onChangeDateIntervalle" />
            </vg-input>
            <!-- Filtre Service -->
            <vg-filter-service v-model="agfilters.service.value"/>
            <!-- Filtre demandeur -->
            <vg-filter-demandeur v-model="demandeurFilter"
                @input="onChangeFiltreDemandeur"/>
            <!-- Filtre statut -->
            <vg-filter-fichesav-statut v-model="agfilters.statut.value" />
        </template>
        <template #panel-filters-localisation>
            <vg-filter-site v-model="agfilters.path.value" />
        </template>
        <template #panel-widget-lieux>
            <vg-tree-lieux v-model="agfilters.path.value"/>
        </template>
    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgInput from 'src/components/Vg/VgInput.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
import VgFilterDemandeur from 'src/components/Vg/Filters/VgFilterDemandeur.vue';
import VgFilterFichesavStatut from 'src/components/Vg/Filters/VgFilterFichesavStatut.vue';
import VgFichesavActivite from 'src/components/Vg/FicheSAV/VgFichesavActivite.vue';
import VgDatePickerShortcuts from 'src/components/Vg/VgDatePickerShortcuts.vue';

import TagGridMixins from 'src/mixins/TagGridMixins.js';

const ICON_DESKTOP = "fil.png";
const ICON_MOBILE = "fil_blanc.png";
const HEADER_BACKGROUND_COLOR_MOBILE = "rgb(255, 199, 61)";

export default {
    name: "activites-maintenance",
    components: {
        TagGrid,
        VgFichesavActivite,
        VgTextFilter,
        VgFilterSite,
        VgFilterService,
        VgFilterDemandeur,
        VgFilterFichesavStatut,
        VgInput,
        VgGroup,
        VgDatePickerShortcuts
    },
    mixins: [
        TagGridMixins
    ],
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "page-title": "Fil fiches curatives",
                "filter-bloc-title": "Filtre",
                "filter-date": "Filtre par date",
                "modal-filters-title": "Filtrer l'activité curative"
            },
            "en": {
                "page-title": "Curative sheets activities",
                "filter-bloc-title": "Filter",
                "filter-date": "Filter by date",
                "modal-filters-title": "Filter curative activity"
            }
        }
    },
    data: function() {
        return {
            agfilters: {
                statut: {  attr:"fm.statut",value: null, action: "equals"},
                service: { attr: "l.service", value: null, action:"equals"},
                dateOuvertureSAV: { attr:"fm.dateOuvertureSAV", value: null, action:"between", openParenthesis: true}, // value = [start,end]
                dateFermetureSAV: { attr:"fm.dateFermetureSAV", value: null, action:"between", logicalOperator: "OR", closeParenthesis: true},
                path: { attr:"l.path", value: null, action:"contains"},
                libelEquipement: { attr:"eq.libel_equipement",value:null, action: "contains" },
                commentaireClient : {attr:"fm.commentaireClient", value:null, action: "contains"},
                field_demandeur_alone: {attr:"fm.demandeur", value: null, action: "equals"},
                field_demandeur: {attr:"fm.demandeur", value: null, action: "equals", openParenthesis: true},
                field_demandeur_nom: {attr:"fu.nom", value: null, action: "equals" , logicalOperator: "OR"},
                field_demandeur_prenom: {attr:"fu.prenom", value: null, action: "equals", logicalOperator: "OR", closeParenthesis: true}
            },
            searchQuery: "",
            demandeurFilter: null,
            dateIntervalleRange: {
                startDate: moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
                endDate: moment().endOf("month").format("YYYY-MM-DD")
            }
        }
    },
    watch: {
        searchQuery: {
            handler: function(query) {
                if(query){
                    this.agfilters.path = {attr:"l.path", value: query, action: "contains", openParenthesis: true};
                    this.agfilters.libelEquipement = { attr:"eq.libel_equipement",value: query, action: "contains", logicalOperator: "OR"};
                    this.agfilters.commentaireClient = { attr:"fm.commentaireClient",value: query, action: "contains", logicalOperator: "OR", closeParenthesis: true};
                }else{
                    this.agfilters.path = {attr:"l.path", value: null, action: "contains"};
                    this.agfilters.libelEquipement = { attr:"eq.libel_equipement", value: null, action: "contains"};
                    this.agfilters.commentaireClient = { attr:"fm.commentaireClient", value: null, action: "contains"};
                }
            }
        }
    },
    computed: {
        pageFilters: function(){
            return ["path", "service", "statut", "field_demandeur_alone", "field_demandeur"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
        getIconPage: function(){
            return this.$vgutils.isMobile() ? ICON_MOBILE : ICON_DESKTOP;
        }
    },
    methods: {
        onChangeDateIntervalle: function(intervalle){
            this.dateIntervalleRange = Object.assign({}, {}, intervalle);
            this.agfilters.dateOuvertureSAV.value = [this.dateIntervalleRange.startDate, this.dateIntervalleRange.endDate];
            this.agfilters.dateFermetureSAV.value = [this.dateIntervalleRange.startDate, this.dateIntervalleRange.endDate];
        },
        onChangeFiltreDemandeur: function(demandeur){
            let demandeurSplit = demandeur?demandeur.split(" "):[];
            if(demandeur && demandeurSplit.length>1){
                this.agfilters = Object.assign({}, this.agfilters, {
                    field_demandeur_alone: {attr:"fm.demandeur", value: null, action: "equals"},
                    field_demandeur: {attr:"fm.demandeur", value: demandeur, action: "equals", openParenthesis: true},
                    field_demandeur_nom: {attr:"fu.nom", value: demandeurSplit[0], action: "equals" , logicalOperator: "OR"},
                    field_demandeur_prenom: {attr:"fu.prenom", value: demandeurSplit[1], action: "equals", logicalOperator: "OR", closeParenthesis: true}
                });
            }else if(demandeur && demandeurSplit.length==1) {
                this.agfilters = Object.assign({}, this.agfilters, {
                    field_demandeur_alone: {attr:"fm.demandeur", value: demandeur, action: "equals"},
                    field_demandeur: {attr:"fm.demandeur", value: null, action: "equals", openParenthesis: true},
                    field_demandeur_nom: {attr:"fu.nom", value: null, action: "equals" , logicalOperator: "OR"},
                    field_demandeur_prenom: {attr:"fu.prenom", value: null, action: "equals", logicalOperator: "OR", closeParenthesis: true}
                });
                
            }else{
                this.agfilters = Object.assign({}, this.agfilters, {
                    field_demandeur_alone: {attr:"fm.demandeur", value: null, action: "equals"},
                    field_demandeur: {attr:"fm.demandeur", value: null, action: "equals", openParenthesis: true},
                    field_demandeur_nom: {attr:"fu.nom", value: null, action: "equals" , logicalOperator: "OR"},
                    field_demandeur_prenom: {attr:"fu.prenom", value: null, action: "equals", logicalOperator: "OR", closeParenthesis: true}
                });
            }
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.agfilters.field_demandeur_alone.value = null;
            this.agfilters.field_demandeur.value = null;
            this.agfilters.field_demandeur_nom.value = null;
            this.agfilters.field_demandeur_prenom.value = null;
            this.searchQuery = null;
        }
    },
    created: function() {
        this.agfilters.dateOuvertureSAV.value = [this.dateIntervalleRange.startDate, this.dateIntervalleRange.endDate];
        this.agfilters.dateFermetureSAV.value = [this.dateIntervalleRange.startDate, this.dateIntervalleRange.endDate];
    },
    mounted: function() {

    }
}
</script>

<style lang="scss">

</style>
