<template>
    <div class="vg-periodicite-shortcuts">
        <vg-select v-model="periodicite"
            :placeholder="$t(placeholder)"
            :options="shortcuts"
            :attributeValue="'value'"
            :attributeLabel="'label'"
            :clearable="clearable"
            @input="onChangePeriodicite" />
        <div v-if="showCustomPeriodicity" class="custom">
            <vg-input v-model="periodicite" inputType="number" @input="$emit('input', periodicite)"/>
            <span>{{ $t("jours") }}</span>
        </div>
    </div>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";

export default {
    name: "vg-periodicite-shortcuts",
    props: {
        /**
        * @model
        */
        value: {
            type: Number | String
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder:{
            type: String,
            default: "periodicite"
        },
        shortcutsCollection: {
            type: Array,
            default: function(){
                return null;
            }
        }
    },
    components: {
        VgSelect,
        VgInput
    },
    data: function() {
        return{
            shortcuts: [
                {label: this.$t("journaliere"), value: 1},
                {label: this.$t("hebdomadaire"), value: 7},
                {label: this.$t("bi-mensuelle"), value: 15},
                {label: this.$t("mensuelle"), value: 30},
                {label: this.$t("bimestrielle"), value: 30},
                {label: this.$t("trimestrielle"), value: 90},
                {label: this.$t("semestrielle"), value: 180},
                {label: this.$t("annuelle"), value: 365},
                {label: this.$t("biennale"), value: 730},
                {label: this.$t("triannuelle"), value: 1095},
                {label: this.$t("personnalisee"), value: null}
            ],
            periodicite: 7,
            showCustomPeriodicity: false
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "periodicite": "Périodicité",
            "journaliere": "Journalière",
            "hebdomadaire": "Hebdomadaire",
            "bi-mensuelle": "Bi-mensuelle",
            "mensuelle": "Mensuelle",
            "bimestrielle": "Bimestrielle",
            "trimestrielle": "Trimestrielle",
            "semestrielle": "Semestrielle",
            "annuelle": "Annuelle",
            "biennale": "Biennale",
            "triannuelle": "Triannuelle",
            "personnalisee": "Personnalisée",
            "jours": "jours"
        },
        "en": {
            "periodicite": "Periodicity",
            "journaliere": "Daily",
            "hebdomadaire": "Weekly",
            "mensuelle": "Monthly",
            "trimestrielle": "Quarterly",
            "annuelle": "Annual",
            "personnalisee": "Custom",
            "jours": "days"
        }
    }
},
    watch: {
        
    },
    mounted: function(){
        if(this.value) this.periodicite = this.value;
        if(this.value && this.shortcuts.findIndex((cut)=>cut.value==this.value)==-1) this.showCustomPeriodicity = true;
    },
    methods: {
        onChangePeriodicite: function(value){
            if(!value){
                this.showCustomPeriodicity = true;
            }else{
                this.showCustomPeriodicity = false;
            }
            this.$emit("input", this.periodicite);
        },
    },
    computed: {
        
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .vg-periodicite-shortcuts{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5px;
        .custom{
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 5px;
        }
    }
</style>
