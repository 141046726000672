<template lang="html">
    <div class="fm-cell-render" @click="$emit('clicked', {data: fm})">
        <div class="header">
            <div class="left">
                <span><b>{{fm.id}}</b></span>
                <span style="color:#9c9c9c;">&nbsp;{{$t("dans")}}&nbsp;</span>
                <vg-lieu-path-viewer v-model="fm.path || fm.equipement.path" onlyPiece></vg-lieu-path-viewer>
            </div>
            <vg-datetime-viewer v-if="!showAffectation" v-model="fm.dateOuvertureSAV" />
            <div v-else><vg-datetime-viewer v-model="fm.affectation.start" onlyTime /> - <vg-datetime-viewer v-model="fm.affectation.end" onlyTime /></div>
        </div>
        <div class="content">
            <vg-fichesav-description :fm="fm" />
        </div>
        <div class="footer" v-if="!affectable">
            <div class="left">
                <img src="\static\assets\icone\origine-DI-jaune.png" class="refimg-20" v-if="fm.origin== 'DI'">
                <img src="\static\assets\icone\origine-verification-orange.png" class="refimg-20" v-else-if="fm.origin== 'Verification'">
                <span>{{fm.contact}}</span>
                <img src="\static\assets\icone\urgence.png"   class="refimg-20" v-if="fm.urgence == '1'" />
            </div>
            <div style="display:flex;justify-content:flex-end;align-items:center;gap:5px;">
                <img src="static/assets/icone/maintenance-bloquante-red.svg" style="width:30px;height:30px;" v-if="fm.isBloquant == '1'"/>
                <vg-button v-if="showRelance" type="info" size="sm" @click="emitRelance" :disabled="disabledRelance">{{$t('relance')}}</vg-button>
                <vg-fichesav-statut-viewer :fm="fm" />
            </div>
        </div>
        <div class="footer" v-else>
            <span v-if="!fm.affectation">{{ $t("aucune-affectation") }}</span>
            <span v-else>{{ $t("affecte-a") }}: <span v-for="affecte in fm.affectation.affectes">{{ affecte.hasOwnProperty("user_name")?affecte.user_name:affecte.name }} | </span></span>
        </div>
        <div class="footer" v-if="isPhotoSent">
            <span v-if="fm.photos && fm.photos.length!=0">{{ $t("nb-photos", {nb: fm.photos.length}) }}</span>
            <span v-if="isPhotoSent" style="color:rgb(80, 177, 86);">{{ $t("photo-sent") }}</span>
        </div>
        <div class="footer" v-if="affectable || canTakePhotos">
            <vg-files-uploader :key="'uploader-photo-fm-'+fm.id"
                :uidToUpload="fm.uid"
                isOnlyPhoto 
                buttonColor="info"
                buttonSize="sm"
                :inputLabel="$t('upload-photo')"
                style="width:50%;"
                @upload-done="isPhotoSent=true;$emit('upload-done', fm)" />
            <vg-button size="sm" :type="fm.affectation && fm.affectation.affectes?'danger':'info'"
                style="width:50%;"
                @click="$emit('open-affectation', fm)">
                {{ fm.affectation && fm.affectation.affectes?$t("modifier-affectation"):$t("affecter-la-fiche") }}
            </vg-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
  name:"fm-cell-render",
  mixins:[LangMixins],
  props:{
        fm:{
            type:Object,
            required:true,
            default: function(){
                return {};
            }
        },
        showAffectation: {
            type: Boolean,
            default: false
        },
        showRelance: {
            type: Boolean,
            default: false
        },
        affectable: {
            type: Boolean,
            default: false
        },
        canTakePhotos:{
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "dans": "dans",
            "relance": "Relancer",
            "aucune-affectation": "Aucune affectation",
            "affecter-la-fiche": "Affecter la fiche",
            "modifier-affectation": "Modifier affectation",
            "affecte-a": "Affectée à",
            "nb-photos": "{nb} photo(s) sauvegardée(s)",
            "photo-sent": "Photo envoyée",
            "upload-photo": "Enregistrer une photo sur la fiche"
        },
        "en": {
            "dans": "in",
            "relance": "Revive",
            "aucune-affectation": "No assignment",
            "affecter-la-fiche": "Assign the sheet",
            "modifier-affectation": "Modify assignment",
            "affecte-a": "Assigned to",
            "nb-photos": "{nb} photo(s) saved",
            "photo-sent": "Photo sent",
            "upload-photo": "Save a photo on sheet"
        }
    }
},
    components:{
        VgFichesavDescription,
        VgFichesavStatutViewer,
        VgDatetimeViewer,
        VgLieuPathViewer,
        VgFilesUploader,
        VgButton
    },
    data: function(){
        return {
            disabledRelance: false,
            isPhotoSent: false
        };
    },
    methods: {
        emitRelance: function(){
            this.disabledRelance = true;
            this.$emit('relance', this.fm);
        }
    }
});
</script>

<style lang="scss" scoped>
.fm-cell-render{
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .vg-lieu-path-viewer{
                width: 40vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .content{

    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        gap: 5px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
        }
    }
}
</style>
