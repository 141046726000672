<template>
    <div :class="{'non-conforme': params.data.isNonConforme=='1'}">
        {{params.data.reponse}} <span v-if="params.data.type_reponse=='consommation' && params.data.consommable_unite">{{ params.data.consommable_unite }}</span>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		}
    });
</script>

<style style="scss" scoped>
.non-conforme{
    color: red;
}
</style>
