<template lang="html">
	<iframe height="100%" width=100% :src="getFilePath" ></iframe>
	<!--div id='pdfjsexpress-viewer' style='width: 1024px; height: 600px; margin: 0 auto;'></div-->
</template>
<script>
//https://pdfjs.express/blog/how-build-pdf-viewer-vuejs-pdfjs
	//import PDFJSExpress from '@pdftron/pdfjs-express'
    export default {
        name: 'vg-files-pdf-viewer',
	    components: {

        },
		mixins:[

        ],
        props: {
			pdf: {
				type: String,
				required: true
			}
        },
        data: function() {
            return {
				/*docViewer: null,
				annotManager: null*/
			};
        },
		filters:{

		},
		created: function(){

		},
        mounted: function(){
			// https://pdfjs.express/documentation/get-started/manually
/*
			WebViewer({
				path: "/static/libs/@pdftron/pdfjs-express/public"	// path to the PDF.js Express'lib' folder on your server
			}, document.getElementById('pdfjsexpress-viewer')).then(instance => {
				// internationalization https://pdfjs.express/documentation/viewer/localization
				instance.setLanguage(this.$langs.current);

				instance.loadDocument(this.pdf, { filename: this.getPdfName });

				this.docViewer = instance.docViewer;
				this.annotManager = instance.annotManager;


				this.docViewer.on('documentLoaded', () => {
					// perform document operations
					//console.log("DOCUMENT LOADED SUCCESS");



				});
			});*/
        },
        methods: {
			/*setWatermark: function(){
				// https://pdfjs.express/documentation/viewer/watermarks
				docViewer.setWatermark({
			      // Draw diagonal watermark in middle of the document
			      diagonal: {
			        fontSize: 25, // or even smaller size
			        fontFamily: 'sans-serif',
			        color: 'red',
			        opacity: 50, // from 0 to 100
			        text: 'Watermark'
			      },

			      // Draw header watermark
			      header: {
			        fontSize: 10,
			        fontFamily: 'sans-serif',
			        color: 'red',
			        opacity: 70,
			        left: 'left watermark',
			        center: 'center watermark',
			        right: ''
			      }
			    });
			}*/
		},
		computed: {
			getFilePath: function(){
				return this.pdf;
			},
			/*getPdfName: function(){
				let pdf = this.pdf.split("/");
				return pdf[pdf.length-1];
			}*/
		}
	};
</script>

<style lang="scss" scoped>

</style>
