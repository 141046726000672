<template lang="html">
    <vg-select v-model="selectedTaches"
        :options="getTaches"
        :attributeLabel="'libel_tache'"
        :attributeValue="attributeValue"
        :clearable="clearable"
        :placeholder="$t('placeholder')"
        @input="$emit('input',selectedTaches)">
        <template v-slot="{option}">
            <div v-if="verbose"
                style="display:flex;justify-content:space-between;align-items:center;">
                <span>{{option.libel_tache}}</span>
                <small style="color:#d9d9d9;">{{$t("type-tache-"+option.type_tache)}}</small>
            </div>
            <div v-else>
                {{option.libel_tache}}
            </div>
        </template>
    </vg-select>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import TachesMixins from "src/mixins/TachesMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-taches-selector',
    components: {
        VgSelect
    },
    mixins: [TachesMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "type-tache-Verification_Lieu": "Vérification lieu",
            "type-tache-Verification_lieu": "Vérification lieu",
            "type-tache-Verification_equipement": "Vérification équipement",
            "type-tache-Relever_Grandeur_physique": "Relever grandeur physique",
            "type-tache-Relever_compteur": "Relever compteur",
            "placeholder": "Sélectionner une tâche"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Object,
            default: null
        },
        /**
        * attribut de l'objet tache à retourner comme valeur
        * @default null
        * si attributeValue=null retourne l'objet sélectionné
        */
        attributeValue: {
            type: String,
            default: null
        },
        /**
        * will be merged with local agfilters
        */
        filters:{
            type: Object,
            default:function(){
                return {}
            }
        },
        verbose:{
            type: Boolean,
            default: false
        },
        /**
        * get tache lieux
        */
        isLieux:{
            type:Boolean,
            default:false
        },
        /**
        * get tache lieux
        */
        isEquipement:{
            type:Boolean,
            default:false
        },
        /**
        * get tache lieux
        */
        isCompteur:{
            type:Boolean,
            default:false
        },
        /**
        * get tache lieux
        */
        isReleverGrandeurPhysique:{
            type:Boolean,
            default:false
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            selectedTaches: this.value,
            metadatasTaches: new Metadatas(),
            agfilters: {}
        };
    },
    watch: {
        value: {
            handler: function(val){
                this.selectedTaches = val;
            }
        },
        filters: {
            handler: function(newValue){
                this.fetch();
            },
            deep: true
        }
    },
    created: function(){
        this.fetch();
    },
    mounted: function(){

    },
    methods: {
        fetch: function(){
            this.agfilters = Object.assign({}, {}, this.filters);
            this.setTypeTache();
            this.metadatasTaches.setFilters(this.agfilters);
            this.tachesMixins_getTaches(this.metadatasTaches).then((datas)=>{
                this.$emit("fetch-success", {taches: datas});
            });
        },
        setTacheLieuFilter:function(){
            if(this.isLieux){
                Object.assign(this.agfilters,{isLieu:{ attr:"t.type_tache",colId: "t.type_tache", value: "Verification_Lieu", action:"equals" }});
            }else{
                if(this.agfilters.hasOwnProperty("isLieu")){
                    delete this.agfilters.isLieu;
                }
            }
        },
        setTacheEquipementFilter:function(){
            if(this.isEquipement){
                Object.assign(this.agfilters,{isEquipement:{ attr:"t.type_tache",colId: "t.type_tache", value: "Verification_equipement", action:"equals" }});
            }else{
                if(this.agfilters.hasOwnProperty("isEquipement")){
                    delete this.agfilters.isEquipement;
                }
            }
        },
        setTacheReleverCompteurFilter:function(){
            if(this.isCompteur){
                Object.assign(this.agfilters,{isCompteur:{ attr:"t.type_tache",colId: "t.type_tache", value: "Relever_compteur", action:"equals" }});
            }else{
                if(this.agfilters.hasOwnProperty("isCompteur")){
                    delete this.agfilters.isCompteur;
                }
            }
        },
        setTacheReleverGrandeurPhysiqueFilter:function(){
            if(this.isReleverGrandeurPhysique){
                Object.assign(this.agfilters,{isReleverGrandeurPhysique:{ attr:"t.type_tache",colId: "t.type_tache", value: "Relever_Grandeur_physique", action:"equals" }});
            }else{
                if(this.agfilters.hasOwnProperty("isReleverGrandeurPhysique")){
                    delete this.agfilters.isReleverGrandeurPhysique;
                }
            }
        },
        setTypeTache:function(){
            this.setTacheLieuFilter();
            this.setTacheEquipementFilter();
            this.setTacheReleverCompteurFilter();
            this.setTacheReleverGrandeurPhysiqueFilter();
        }
    },
    computed: {
        ...mapGetters({
            taches: 'TachesStore/getCollection'
        }),
        getTaches: function(){
            return this.taches.sort((t1,t2)=>{
                if(t1.libel_tache.toLowerCase()<t2.libel_tache.toLowerCase()) return -1;
                else if(t1.libel_tache.toLowerCase()==t2.libel_tache.toLowerCase()) return 0;
                else return 1;
            });
        }
    }
}
</script>

<style lang="css" scoped>
</style>
