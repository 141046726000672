<template>
    <vg-modal
        :isSaveDisabled="!isValid"
        :theme="'redskin'"
        @close="closeDialog">
        <template #header>
            <div class="dial-header-title">
                <slot name="header-title"></slot>
            </div>
            <br>
            <div class="dial-header-title2" >
              <slot name="header-title2"></slot>
            </div>
        </template>
        <template #body>
            <div v-if="!!$slots['infos'] || !!$slots['warning']" style="margin-bottom: 10px;font-weight:bold;display:flex;justify-content:flex-start;align-items:center;">
                <slot name="infos">
                    <i class="fas fa-exclamation-triangle" style="color:#f9b934;margin-right:10px;"></i> <div><slot name="warning"></slot></div>
                </slot>
            </div>
            <div v-if="isAuthorized">
                <div id="dialog-secure-action-message" >
                    <slot v-bind:expectedResponse="validResponse" >
                        {{$t('saisir')}} <b><code>{{validResponse}}</code></b> <slot name="objective">{{$t('objective')}}</slot>
                    </slot>
                </div>
                <input v-model="userInput" id="dialog-secure-action-input" @keyup="validateUserInput" :class="['tx-input', { 'tx-input-valid': isValid ,'tx-input-notvalid': userInput.length && !isValid}]" />
            </div>
            <div v-else id="dialog-secure-action-message" >
                <slot name="unauthorize-message"></slot>
            </div>
        </template>
        <template #footer>
            <vg-button
                @click="closeDialog">
                {{$t("cancel")}}
            </vg-button>

            <vg-button
                type="danger"
                @click="validAction">
                {{$t("confirm")}}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";

export default {
    name: 'dialog-secured-action',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "saisir": "Saisir",
            "objective": "pour valider la suppression.",
            "cancel": "Annuler",
            "confirm": "Confirmer"
        },
        "en": {
            "saisir": "Enter",
            "objective": "to remove.",
            "cancel": "Cancel",
            "confirm": "Confirm"
        }
    }
} ,
    components:{
        VgButton
    },
    props: {
        validResponse: {
            type: String,
            default: 'delete'
        },
        onValidResponse: {
            type: Function,
            default: function() {}
        },
        isAuthorized:{
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            userInput: '',
            isValid : false
        };
    },
    methods: {
        validateUserInput: function() {
            if (this.validResponse == this.userInput) {
                this.onValidResponse && this.onValidResponse(this.userInput);
                // this.$emit('validateResponse');
                this.isValid = true;
            }else{
                this.isValid = false;
            }
        },
        validAction: function(){
            // //console.log("save");
            this.$emit("save");
        },
        closeDialog: function(){
            // //console.log("close");
            this.$emit("close");
        }
    },
    computed: {

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tx-input {
      -webkit-appearance: none;
      background-image: none;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #40475b;
      display: inline-block;
      font-size: 18px;
      font-family: "Open Sans";
      height: 30px;
      line-height: 40px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 25%;
      margin-top: 7px;
    }
    .tx-input-not-empty-field {
      background-color: #f7fafc;
    }
    .tx-input-valid {
      border: 1px solid #b2dcd2;
    }
    .tx-input-notvalid {
      border: 1px solid red;
    }
    .dial-header-title{
      font-size: 18px;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .dial-header-title2{
      font-size: 14px;
    }
    #dialog-secure-action-message{
      font-size: 16px;
      color:#908F9D;
    }
    #dialog-secure-action-input{
      width: 90%;
      height: 40px;
      font-size: 17px;
      color:#908F9D;
    }
</style>
