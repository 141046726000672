<template>
	<vg-select v-model="rate"
		:options="range"
		@input="$emit('input',rate)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";
    export default {
        name: 'vg-rate',
		components: {
			VgSelect
		},
        props: {
			/**
			* @model
			*/
			value: {
				type: Number | String,
				default: null
			},
			/**
			* min rate
			*/
			min: {
				type: Number | String,
				default: 1
			},
			/**
			* max rate
			*/
			max: {
				type: Number | String,
				default: 5
			},
			/**
			* type selector | stars | ...
			*/
			type: {
				type: String,
				default: "selector"
			}
        },
		watch:{
			value: {
				handler: function(val){
					this.rate = val;
				}
			}
		},
        data: function() {
            return {
				rate: this.value
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			range: function(){
				let range = [];
				for (var r = this.min; r <= this.max; r++) {
					range.push(r);
				}
				return range;
			}
		}
    };

</script>
<style style="scss" scoped>

</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-rate v-model="rate">
            </vg-rate>
        </div>
      `
    })
    ```
</docs>
