<template>
    <div>
        <vg-input-cost v-if="cost && cost!=''" v-model="cost">
        </vg-input-cost>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgInputCost from "src/components/Vg/VgInputCost.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    
    export default Vue.extend({
        name:"vg-cost-cell-render",
        mixins:[LangMixins],
		props: {
            value: {
                type: String
            }
		},
        data: function(){
            return {
                cost: this.value || null
            };
        },
        components: {
            VgInputCost
        }
    });
</script>

<style scoped>

</style>
