<template >
	<div class="vg-text-filter">

		<input v-model="inputValue"
          :placeholder="placeholder?placeholder:$t('rechercher')"
          class="vg-text-filter-input"
		  type="text"
          @input="doNothing"
		  @keyup="onKeyUp"
        />
		<span class="input_suffix" >
			<span class="input_suffix-inner" @click="onClearInput">
				<i class="fas fa-times"></i>
			</span>
			<span class="input_suffix-inner hovered" @click="emitValueToParent">
				<i class="fas fa-search"></i>
			</span>
		</span>
	</div>
</template>
<script>
export default {
	name:"vg-text-filter",
	props: {
        value:{
            type:String,
            default:""
        },
		placeholder:{
			type: String,
			default: null
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "rechercher": "Rechercher..."
        },
        "en": {
            "rechercher": "Search..."
        },
        "th": {
            "rechercher": "ค้นหา..."
        }
    }
},
	data: function(){
		return {
			inputValue: this.value,
			timeout: null
		};
	},
	watch: {
        value:{
            handler:function(val){
                this.inputValue = val;
            }
        }
	},
	methods: {
		onKeyUp: function(event){
			clearTimeout(this.timeout);
			this.timeout = setTimeout(()=>{
				this.emitValueToParent(event);
		    }, 1000);
		},
        emitValueToParent:function(event){
            this.$emit("input", this.inputValue);
        },
		onClearInput: function(){
            this.inputValue = null;
			this.$emit("input", "");
		},
        doNothing:function(){}
	}
}

</script>

<style lang="scss" scoped>
$default-input-color: #AFB6BF; /*rgba(42,155,212,0.5)*/
.vg-text-filter{
	width: 100%;
	position: relative;
    font-size: 14px;
    display: inline-block;
	input{
		-webkit-appearance: none;
	    background-color: #fff;
	    background-image: none;
	    box-sizing: border-box;
	    color: #40475b;
	    display: inline-block;
	    font-size: inherit;
	    height: 32px;
	    line-height: 40px;
	    outline: none;
	    padding: 0 30px;
	    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
	    width: 100%;
		border: 1px solid $default-input-color;
		border-radius: 4px;
	}
	input::placeholder {
		color: $default-input-color;
	}
	input:focus{
		border: 1px solid #2a9bd4;
	}
	.input_suffix{
		position: absolute;
	    height: 100%;
	    right: 10px;
	    top: 0;
	    text-align: center;
	    color: $default-input-color;
	    transition: all .3s;
	    pointer-events: all;
        .hovered:hover{
            color:#62bd6a;
        }
		.input_suffix-inner{
            padding:0px 5px;
			text-align: center;
			color: $default-input-color;
			>i{
			    line-height: 36px;
				cursor: pointer;
			}
		}
	}
	.input_prefix{
		position: absolute;
	    height: 100%;
	    left: 10px;
	    top: 0;
	    text-align: center;
	    color: $default-input-color;
	    transition: all .3s;
	    pointer-events: none;
		.input_prefix-inner{
			text-align: center;
			color: $default-input-color;
			>i{
			    line-height: 36px;
			}
		}
	}

}

</style>
