<template>
    <vg-calendar ref="vg-calendar"
        v-model="dateRange"
        :calendars="getCalendars"
        :calendarEvents="calendarEvents"
        :defaultView="defaultView"
        :showLeftPanel="false"
        @change-date="onChangeDate"
        @change-filter-sites="onChangeFiltreSites">
    </vg-calendar>
</template>

<script>
import VgCalendar from "src/components/Vg/VgCalendar.vue";
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import InterventionsMixins from "src/mixins/InterventionsMixins.js";
import VerificationMixins from "src/mixins/VerificationMixins.js";
import Metadatas from "src/services/Metadatas.js";
export default {
    name:"vg-calendar-dashboard",
    components: {
        VgCalendar
    },
    mixins:[MaintenanceMixins, InterventionsMixins, VerificationMixins],
    props:{
        /**
        * mode = curatif || preventif
        */
        mode:{
            type: String,
            default: "curatif"
        },
        filters:{
            type: Object,
            default: function(){
                return {};
            }
        },
        defaultView:{
            type: String,
            default: null
        }
    },
    data: function(){
        return {
            metadatasMaintenances: new Metadatas(),
            metadatasInterventions: new Metadatas(),
            calendarEvents: [],
            calendarsMaintenanceCurative: [
                {
                    id: "ouverture",
                    name: this.$t("calendar-fm-ouverture"),
                    checked: true,
                    color: "red",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                },
                {
                    id: "fermeture",
                    name: this.$t("calendar-fm-fermeture"),
                    checked: true,
                    color: "green",
                    bgColor: "#fff", //f45112
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                },
                {
                    id: "affectation",
                    name: this.$t("calendar-fm-affectation"),
                    checked: true,
                    color: "yellow",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                },
                {
                    id: "intervention-ponctuelle",
                    name: this.$t("calendar-intervention-ponctuelle"),
                    checked: true,
                    color: "purple",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                }
            ],
            calendarsMaintenancePreventive: [
                {
                    id: "intervention-previsionnelle",
                    name: this.$t("calendar-intervention-previsionnelle"),
                    checked: true,
                    color: "blue",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                },
                {
                    id: "verification-interne",
                    name: this.$t("calendar-verification-interne"),
                    checked: true,
                    color: "orange",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                },
                {
                    id: "intervention-effective",
                    name: this.$t("calendar-intervention-effective"),
                    checked: true,
                    color: "purple",
                    bgColor: "#fff",
                    dragBgColor: "#fff",
                    borderColor: "transparent"
                }
            ],
            maintenancesFilters: {
                dateOuvertureSAV: { attr:"fm.dateOuvertureSAV",colId: "dateOuvertureSAV", value: null, action:"between", openParenthesis:true },
                dateFermetureSAV: { attr:"fm.dateFermetureSAV",colId: "dateFermetureSAV", value: null, action:"between", "logicalOperator":"OR" },
                start: { attr:"aff.start",colId: "start", value: null, action:"between", "logicalOperator":"OR" },
                end: { attr:"aff.end",colId: "end", value: null, action:"between", "logicalOperator":"OR", closeParenthesis:true },
                path: { attr:"l.path",colId: "path", value: null, action:"start_with" }
            },
            interventionsFilters: {
                datePrevisionnelleDebut: { attr:"i.datePrevisionnelleDebut", colId: "datePrevisionnelleDebut", value: null, action:"between", openParenthesis:true },
                datePrevisionnelleFin: { attr:"i.datePrevisionnelleFin", colId: "datePrevisionnelleFin", value: null, action:"between" },
                dateEffectiveDebut: { attr:"i.dateEffectiveDebut", colId: "dateEffectiveDebut", value: null, action:"between" },
                dateEffectiveFin: { attr:"i.dateEffectiveFin", colId: "dateEffectiveFin", value: null, action:"between", closeParenthesis:true },
                fichesav_id: { attr:"i.fichesav_id", colId:"fichesav_id", value: 1, action:"is_not_null"},
                path: { attr:"l.path",colId: "path", value: null, action:"start_with" }
            },
            dateRange: {
                start: moment().startOf('month').format("YYYY-MM-DD"),
                end: moment().endOf('month').format("YYYY-MM-DD")
            },
        };
    },
    watch: {

    },
    created: function(){
        this.fetch();
    },
    methods: {
        onChangeFiltreSites: function(sites){
            //console.log("onChangeFiltreSites", sites);
            this.setFiltersSitesValues(sites);
            this.fetch();
        },
        /**
        * @param array sites
        */
        setFiltersSitesValues: function(sites){
            this.maintenancesFilters.path.value = sites;
            this.interventionsFilters.path.value = sites;
        },
        onChangeDate: function(dateRange){
            console.log("CHANGE DATE", dateRange);
            let currentDateMonthStart = this.dateRangeMonth.start;
            this.dateRange = dateRange;
            this.setFiltersValues();
            if(moment(dateRange.start).startOf('month').format('YYYY-MM-DD')!=currentDateMonthStart) this.fetch();
        },
        setFiltersValues: function(){
            let intervalleDate = [this.dateRangeMonth.start,this.dateRangeMonth.end];
            this.maintenancesFilters.dateOuvertureSAV.value = intervalleDate;
            this.maintenancesFilters.dateFermetureSAV.value = intervalleDate;
            this.maintenancesFilters.start.value = intervalleDate;
            this.maintenancesFilters.end.value = intervalleDate;
        },
        fetch: function(){
            this.calendarEvents = [];
            this.maintenancesFilters = Object.assign(this.maintenancesFilters, this.filters);
            this.interventionsFilters = Object.assign(this.interventionsFilters, this.filters);
            console.log("INTERVENTIONS FILTERS", this.interventionsFilters);
            if(this.mode=="curative"){
                this.metadatasMaintenances.setFilters(this.maintenancesFilters);
                this.MaintenanceMixins_getCalendarEvents(this.metadatasMaintenances).then((calendarEvents)=>{
                    console.log("CALENDAR EVENTS FMS", calendarEvents);
                    this.calendarEvents = this.calendarEvents.concat(calendarEvents);
                });
                this.metadatasInterventions.setFilters(this.interventionsFilters);
                this.InterventionsMixins_getCalendarEvents(this.metadatasInterventions).then((calendarEvents)=>{
                    console.log("SUCCESS GET INTERVENTIONS", calendarEvents);
                    this.calendarEvents = this.calendarEvents.concat(calendarEvents);
                });
            }else if(this.mode=="preventive"){
                this.metadatasInterventions.setFilters(this.interventionsFilters);
                this.InterventionsMixins_getCalendarEvents(this.metadatasInterventions).then((calendarEvents)=>{
                    console.log("SUCCESS GET INTERVENTIONS", calendarEvents);
                    this.calendarEvents = this.calendarEvents.concat(calendarEvents);
                });
                this.VerificationMixins_getCalendarEvents(this.getMetadatasVerificationsInternes).then((calendarEvents)=>{
                    console.log("CALENDAR EVENTS VERIFICATIONS INTERNE", calendarEvents);
                    this.calendarEvents = this.calendarEvents.concat(calendarEvents);
                });
            }
        },
    },
    computed:{
        getCalendars: function(){
            return this.mode=='curative' ? this.calendarsMaintenanceCurative : this.calendarsMaintenancePreventive;
        },
        dateRangeMonth: function(){
            return {
                start: moment(this.dateRange.start).startOf('month').format('YYYY-MM-DD'),
                end: moment(this.dateRange.end).endOf('month').format('YYYY-MM-DD')
            };
        },
        getMetadatasVerificationsInternes: function(){
            let metadatas = {
                "directives":[],
                "filters":[
                    {"attr":"et.dateVerif","value":[this.dateRangeMonth.start,this.dateRangeMonth.end],"action":"between"}
                ]
            };
            if(this.filtreSites) metadatas.filters.push({"attr":"l.path","value":this.filtreSites,"action":"contains"});
            return metadatas;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
