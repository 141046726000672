<template>
    <div>
        <vg-button :type="'grey'"
            :size="'xs'"
            @click="onClickUpdateCommentaire">
            {{$t("update-commentaire")}}
        </vg-button>
        <vg-button :type="'grey'"
            :size="'xs'"
            @click="onClickSigner">
            {{$t("signer")}}
        </vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "bon-de-sortie-actions-cell-render",
        mixins:[LangMixins],
		props: {
            data: {
                type: Object
            }
		},
        components: {
            VgButton
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "update-commentaire": "Modifier commentaire",
            "signer": "Signer"
        },
        "en": {
            "update-commentaire": "Edit comment",
            "signer": "Signature"
        }
    }
},
        methods: {
            onClickUpdateCommentaire: function(){
                console.log("CLICK UPDATE COMMENTAIRE", this.data);
                this.$emit("update-commentaire", this.data);
            },
            onClickSigner: function(){
                this.$emit("open-signer", this.data);
            }
        }
    });
</script>

<style lang="scss" scoped>

</style>
