<template>
	<iframe v-if="!isLoading && bonDeCommandePdfFileUrl" :src="bonDeCommandePdfFileUrl" 
		style="width:100%;height: 100%;"/>
	<div v-else
		style="width:100%;height: 100%;">
		{{ $t("chargement-pdf") }}
	</div>
</template>
<script>
	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

    import { mapGetters } from 'vuex';

    export default {
        name: "vg-bon-de-commande-pdf-viewer",
		components: {
			
		},
		mixins: [ BonsDeCommandeMixins ],
    	props: {

        },
		i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"chargement-pdf": "Chargement du PDF en cours..."
				}
			}
		},
        data: function() {
            return {
				urlLogoEntiteQuiPasseCommande: null,
				urlSignature: null,
				signataire: this.$app.nom+" "+this.$app.prenom,
				datePourBonDeCommande: moment().format("YYYY-MM-DD"),
				bonDeCommandePdfFileUrl: null,
				bonDeCommandePdfBlob: null,
				isLoading: false
            };
        },
		created: function(){
			this.VgFilesMixins_init(this.bonDeCommande.uid, "bon-de-commande");
		},
		mounted: function(){
			this.fetchBonDeCommandePDF();
		},
		methods: {
            fetchSignature: function(){
				return new Promise((resolve, reject)=>{
					this.VgFilesMixins_getUrlUserSignature().then((url)=>{
						console.log("AFTER GET SIGNATURE", url);
						this.urlSignature = url;
						resolve(url);
					});
				});
			},
            fetchEntiteQuiPasseCommandeLogo: function(){
				return new Promise((resolve, reject)=>{
					if(this.bonDeCommande.bonsDeCommandeEntite){
						this.VgFilesMixins_getUrlFirstDocument(this.bonDeCommande.bonsDeCommandeEntite.uid).then((url)=>{
							console.log("AFTER GET LOGO", url);
							this.urlLogoEntiteQuiPasseCommande = url;
							resolve(url);
						});
					}else{
						this.urlLogoEntiteQuiPasseCommande = window.sessionStorage.getItem("account_logo");
						resolve(this.urlLogoEntiteQuiPasseCommande);
					}
				});
			},
			fetchBonDeCommandePDF: function(){
				this.isLoading = true;
				Promise.all([this.fetchSignature(), this.fetchEntiteQuiPasseCommandeLogo()]).then(([e])=>{
                    this.BonsDeCommandeMixins_getPDF(this.bonDeCommande.id, {
						urlLogoEntiteQuiPasseCommande: this.urlLogoEntiteQuiPasseCommande,
						urlSignature: this.urlSignature,
						signataire: this.signataire,
						datePourBonDeCommande: this.datePourBonDeCommande
					}).then((datas)=>{
						console.log("BC PDF&&&&&&&&&&&&&&&&&&", datas);
						this.bonDeCommandePdfFileUrl = datas.fileURL;
						this.bonDeCommandePdfBlob = datas.blob;
						this.isLoading = false;
						this.emitGeneratedPdfDatas();
					});
                });
			},
			emitGeneratedPdfDatas: function(){
				this.$emit("generated-pdf-datas", this.bonDeCommandePdfBlob);
			},
		},
        computed: {
			...mapGetters({
                bonDeCommande: "BonsDeCommandeStore/getSelectedItem"
            })
		}
    };

</script>
<style lang="scss" scoped>

</style>
