import Validator from 'validators/Validator';
export default class LieuxValidator extends Validator{
    constructor() {
        super();
    }
    validatePiece(piece) {
        let validationPayload = {
            "success": true,
            "code": null,
            "type": null,
            "message": null
        }
            if(!piece.libel_lieu){
            validationPayload.success = false;
            validationPayload.message = ""
            validationPayload.code = "libellieu_manquant";
            validationPayload.type = "missing";
        }
            if(!piece.categorie_id){
            validationPayload.success = false;
            validationPayload.message = ""
            validationPayload.code = "categorie_manquante";
            validationPayload.type = "missing";
        }
            return validationPayload;
    }
    /**
     * foreach lieux check if categorie exist and is not null, check if libel_lieu exist and not null.
     * if a lieu is not validated return an index list of not valid lieu
     * @param array lieux
     * @return array list of not valid lieu index
     */
    getInvalidIndexes(pieces){
        let notValid = [];
        let validation = null;
        pieces.forEach((lieu, index)=>{
            validation = this.validatePiece(lieu);
            if(!validation.success){
                notValid.push(index);
            }
        });
        return notValid;
    }
}