<template>
	<span>{{ dureeReadable }}</span>
</template>
<script>

    export default {
        name: 'vg-duree-viewer',
		components: {

		},
  i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "jour": "1 jour ",
            "jours": "{jours} jours ",
            "heures": "{heures} h ",
            "minutes": "{minutes} min "
        },
        "en": {
            "jour": "1 day",
            "jours": "{jours} days ",
            "heures": "{heures} h ",
            "minutes": "{minutes} min "
        }
    }
},
        props: {
			/** value en minutes */
			value:{
				type: Number,
				default: 0
			}
        },
		watch:{
			value: {
				handler: function(val){
					this.feedJoursHeuresMinutes();
				}
			}
		},
        data: function() {
            return {
				jours: 0,
				heures: 0,
				minutes: 0
            };
        },
		created: function(){
			this.feedJoursHeuresMinutes();
		},
		methods: {
			feedJoursHeuresMinutes: function(){
				if(this.value){
					let minutesParJour = 60 * 24;
					let minutesParHeure = 60;
					// Calculer le nombre total de jours
					this.jours = Math.floor(this.value / minutesParJour);
					// Calculer le nombre d'heures restantes après avoir extrait les jours
					let heuresRestantes = this.value % minutesParJour;
					this.heures = Math.floor(heuresRestantes / minutesParHeure);
					// Calculer le nombre de minutes restantes après avoir extrait les heures
					this.minutes = heuresRestantes % minutesParHeure;
				}
			}
		},
        computed: {
			dureeReadable: function(){
				let result = "";
				if(this.jours==1) result += this.$t("jour");
				else if(this.jours!=0) result += this.$t("jours", {jours: this.jours});
				if(this.heures!=0) result += this.$t("heures", {heures: this.heures});
				if(this.minutes!=0) result += this.$t("minutes", {minutes: this.minutes});
				return result;
			}
		}
    };

</script>
<style style="scss" scoped>

</style>
