<template lang="html">
    <vg-select v-model="selectedUsers"
        :options="getUsers"
        :multiple="multiple"
        :clearable="clearable"
        :attributeValue="attributeValue"
        :placeholder="placeholder?placeholder:$t('placeholder')"
        @input="$emit('input', $event)">
        <template v-slot="{option}">
            <div v-if="option.tiers_id && option.roles == 'ROLE_SOUS_TRAITANT'">
                {{option.tiers.name}}
            </div>
            <div v-else>
                {{option.nom}} {{option.prenom}} 
            </div>
            <small v-if="showEmail">{{ option.email }}</small>
        </template>
        <template v-slot:selected-option="{option}">
            <div v-if="option.tiers_id && option.roles == 'ROLE_SOUS_TRAITANT'">
                {{option.tiers.name}}
            </div>
            <div v-else>
                {{option.nom}} {{option.prenom}} 
            </div>
            <small v-if="showEmail">{{ option.email }}</small>
        </template>
    </vg-select>
</template>

<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import UserMixins from "src/mixins/UserMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-users-selector',
    components: {
        VgSelect
    },
    mixins: [UserMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner un utilisateur"
        },
        "en": {
            "placeholder": "Select an user"
        }
    }
},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Object,
            default: null
        },
        /**
        * Attribut de l'objet tache à retourner comme valeur.
        * @default id
        * Si attributeValue=null retourne l'objet sélectionné.
        */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
         * Selection de plusieurs users.
         * @default false
         */
        multiple: {
            type:Boolean,
            default:false
        },
        clearable: {
        	type: Boolean,
        	default: true
        },
        placeholder:{
            type: String,
            default: null
        },
        showEmail: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            selectedUsers: this.value || [],
            metadatasUsers: new Metadatas(),
            agfilters: {
                enabled: {attr:"enabled", value: 1, action: "equals"}
            }
        };
    },
    watch: {
        value: {
            handler: function(val){
                this.selectedUsers = val;
            },
            deep: true
        }
    },
    created: function(){
        this.fetch();
    },
    mounted: function(){

    },
    methods: {
        fetch: function(){
            this.metadatasUsers.setFilters(this.agfilters);
            this.UserMixins_getUsers(this.$app.appID,this.metadatasUsers).then((datas)=>{
                this.$emit("fetch-success", {users: datas});
            });
        }
    },
    computed: {
        ...mapGetters({
            users: 'UsersStore/getCollection'
        }),
        getUsers: function(){
            let users = [...this.users];
            users.sort((u1, u2)=>u1.nom.localeCompare(u2.nom));
            return users;
        }
    }
}
</script>

<style lang="css" scoped>
</style>
<docs>

    ### vg-users-selector


    ```js
          import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
    ```

    #### basic usage

    ```html static
    <vg-users-selector v-model="users" ></vg-users-selector>

    ```
    ``` avec choix multiple utilisateur
    <vg-users-selector v-model="users" multiple></vg-users-selector>
    ```
    #### Emit : input

    Retourne la valeur selectionner

    ### mixins

    UserMixins_getUsers

</docs>
