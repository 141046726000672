<template>
    <span v-if="params.data.libel_lieu!='-'">
		<img v-if="icon" :src="icon" class="lieu-icon"/> {{params.data.libel_lieu}}
	</span>
	<span v-else-if="params.data.libel_lieu=='-' && params.data.type_lieu=='Batiment'">
        <img v-if="icon" :src="icon" class="lieu-icon"/> {{$t("batiment-generique")}}
	</span>
    <span v-else-if="params.data.libel_lieu=='-' && params.data.type_lieu=='Etage'">
        <img v-if="icon" :src="icon" class="lieu-icon"/> {{$t("etage-generique")}}
	</span>
	<span v-else-if="params.data.libel_lieu=='-'">
        <img v-if="icon" :src="icon" class="lieu-icon"/> {{LieuMixins_getInitiales(params.data)}}{{$t("piece-generique")}}
	</span>
</template>

<script>
    import Vue from "vue";
    import LieuMixins from "src/mixins/LieuMixins.js";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "libel-lieu-cell-renderer",
        mixins: [LieuMixins],
        props: {
			params: {
                type: Object,
                required: true
            },
            icon: {
                type: String,
                default: null
            }
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "piece-generique": "Pièce générique",
            "etage-generique": "Etage générique",
            "batiment-generique": "Batiment générique"
        },
        "en": {
            "piece-generique": "Generic room",
            "etage-generique": "Generic floor",
            "batiment-generique": "Generic building"
        }
    }
}
    });
</script>

<style lang="scss" scoped>
.lieu-icon{
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
</style>
