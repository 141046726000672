
<template>
	<div class="parametres-lieux" >
        <div style="display:flex;width:100%">
            <div style="display:flex;flex-direction: column;width:50%">
                <span class="title"> {{ $t('personnalisation-pieces-libel-header') }} </span>
                <vg-input
                    :title="$t('piece-libel-racine-a')"
                    v-model="racineACreationLibelPiece"
                    :placeholder="$t('placeholder-racine-a')">
                </vg-input>
                <vg-input
                    :title="$t('piece-libel-racine-b')"
                    v-model="racineBCreationLibelPiece"
                    :placeholder="$t('placeholder-racine-b')" >
                </vg-input>
                <vg-input
                    :title="$t('piece-libel-numero-initial')"
                    v-model="numeroInitialCreationLibelPiece"
                    :placeholder="$t('placeholder-numero-initial-piece')" >
                </vg-input>
                <vg-input
                    v-if="derniersNumero"
                    :title="$t('piece-libel-dernier-numero')"
                    v-model="derniersNumero"
                    disabled="true">
                </vg-input>
            </div>
            <div style="display:flex;flex-direction: column;width:50%;justify-content:center;align-items: center;">
                <span> {{ $t('exemple-rendu-libel_piece') }} <b>{{ exempleLibelPiece }}</b></span>
                <br>
                <vg-button size="xs" @click="handleUpdateLibelPiecePattern"> 
                    {{ $t('enregistrer') }}  
                </vg-button>
        
            </div>
        </div>
        
        <hr>
        
        <span class="title">{{ $t('personnalisation-lieux-header') }}</span>
        <vg-todo-list style="width:100%;"
            @addItem="handleSaveNewLibelleService"
            @deleteItem="handleDeleteLibelleService"
            v-model="libelServices"
        >
            <template v-slot:item="{item}">
               <div>{{item.libelService}}</div>
            </template>
        </vg-todo-list>
	</div>
</template>

<script>

    import LibelServicesMixins from "src/mixins/LibelServicesMixins";
	import ParametresMixins from "src/mixins/ParametresMixins.js";
    import VgTodoList from 'src/components/Vg/VgTodoList.vue';
    import VgInput from 'components/Vg/VgInput.vue';
    import VgButton from 'components/Vg/VgButton.vue';

    import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

    export default {
        name: 'parametres-lieux',
        mixins:[LibelServicesMixins, ParametresMixins],
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "personnalisation-lieux-header": "Personnalisation des Services",
            "personnalisation-lieux-services-onglet": "Configuration des services",
            "personnalisation-lieux-services-message-presentation": "Vous pouvez ici, gérer les différents services que vous souhaitez mettre en place",
            "personnalisation-pieces-libel-header": "Personnalisation des libellés des pièces",
            "piece-libel-racine-a": "Racine A",
            "piece-libel-racine-b": "Racine B",
            "piece-libel-numero-initial": "Numéro initial",
            "piece-libel-dernier-numero": "Dernier numéro",
            "placeholder-racine-a": "Racine A ( par défaut : acronyme du site)",
            "placeholder-racine-b": "Racine B ( par défaut la valeur 'P' pour pièce)",
            "placeholder-numero-initial-piece": "Numéro initial (par défaut : 100)",
            "exemple-rendu-libel_piece": "Exemple de rendu : ",
            "enregistrer": "valider ce modèle"
        },
        "en": {
            "personnalisation-lieux-header": "Unit's customisation",
            "personnalisation-lieux-services-onglet": "Service configuration",
            "personnalisation-lieux-services-message-presentation": "Manage the different units you want to set up here"
        },
        "th": {
            "personnalisation-lieux-header": "ความเป็นเฉพาะบุคคลของสถานที่",
            "personnalisation-lieux-services-onglet": "การกำหนดค่าการบริการ",
            "personnalisation-lieux-services-message-presentation": "จัดการบริการที่ต่างกันที่คุณต้องการตั้งค่าได้ที่นี่"
        }
    }
} ,
        components: {
            VgTodoList,
            VgInput,
            VgButton
        },
        data() {
            return {
                racineACreationLibelPiece: this.$app.racineACreationLibelPiece,
                racineBCreationLibelPiece: this.$app.racineBCreationLibelPiece,
                numeroInitialCreationLibelPiece: this.$app.numeroInitialCreationLibelPiece,
                derniersNumero: this.$app.derniersNumero,
				userId: this.$app.appID
            };
        },
        methods: {
			handleDeleteLibelleService: function(libelService){
				this.LibelServicesMixins_delete(libelService);
			},
			handleSaveNewLibelleService: function(newlibelle){
                let libelServices = [
                    {
                        "libelService":newlibelle,
                        "userId":this.userId
                    }
                ];
				this.LibelServicesMixins_create(libelServices);
			},
			fetchServices: function(){
                let metadatas = new Metadatas();
                this.LibelServicesMixins_getLibelServices(metadatas).then((services)=>{});
			},
            saveInSessionStorage: function(sessionValues){
				window.sessionStorage.setItem("user", JSON.stringify(
					Object.assign(this.$app, sessionValues)
				) );
			},
            handleUpdateLibelPiecePattern: function(){
                let payload = {
                        racineACreationLibelPiece: this.racineACreationLibelPiece,
                        racineBCreationLibelPiece: this.racineBCreationLibelPiece,
                        numeroInitialCreationLibelPiece: this.numeroInitialCreationLibelPiece
                    };
                this.ParametresMixins_update(payload).then((data) => {
                    this.saveInSessionStorage(payload);
				});
            }
        },
        computed: {
            ...mapGetters({
                libelServices: "LibelServicesStore/getCollection",
            }),
            exempleLibelPiece: function(){
                let racineA = this.racineACreationLibelPiece ? this.racineACreationLibelPiece : "LMJ";
                let racineB = this.racineBCreationLibelPiece ? this.racineBCreationLibelPiece : "P";
                let numeroInitial = this.numeroInitialCreationLibelPiece ? this.numeroInitialCreationLibelPiece : "100";
                let derniersNumero = this.derniersNumero ? this.derniersNumero : "250";
                return `${racineA}-${racineB}${numeroInitial}-Chambre standard ${derniersNumero}`;
            }
        },
		created: function(){
			this.fetchServices();
		}
    };
</script>
<style lang="scss" scoped>
.parametres-lieux{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
}
</style>
