<template>
    <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
        <vg-select
            v-model="targetedColumn"
            :options="targetColumns"
            :attributeValue="'value'"
            :clearable="false"
            style="max-width:50%;"
            @input="handleTargetedColumnChange" />
        <vg-date-picker-shortcuts
            key="filter-interval-date-picker-shortcuts"
            previousDatesPresets
            opens="left"
            :appendToBody="true"
            :defaultStartDate="defaultStartDate"
            :defaultEndDate="defaultEndDate"
            @select="onChangeDateIntervalle"
            @finish-selection="onChangeDateIntervalle" />
    </div>
</template>
<script>
    import VgSelect from 'src/components/Vg/VgSelect';
    import VgDatePicker from "src/components/Vg/VgDatePicker";
    import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";

    export default {
        name: 'vg-filter-interval-date',
		components: {
            VgDatePicker,
            VgSelect,
            VgDatePickerShortcuts
		},
        props: {
            value:{
                type: Object,
                default: function(){
                    return {
                        targetedColumn : "dateOuvertureSAV"
                    };
                }
            },
            defaultStartDate:{
                type: String,
                default: null
            },
            defaultEndDate:{
                type: String,
                default: null
            },
        },
		watch:{},
        data: function() {
            return {
                targetedColumn: this.value.targetedColumn,
                targetColumns:[
                    { label:"date ouverture", value:"dateOuvertureSAV" },
                    { label:"date fermeture", value:"dateFermetureSAV" }
                ]
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
            handleTargetedColumnChange: function(targetedColumn){
                if(targetedColumn != null){
                    this.targetedColumn = targetedColumn;
                }else{
                    this.targetedColumn = "dateOuvertureSAV";
                }
                this.onChangeDateIntervalle();
            },
            onChangeDateIntervalle: function(e){
                //console.log(e)
                if(e == null){
                    this.$emit("change",{
                        "targetedColumn": this.targetedColumn,
                        "startDate": null,
                        "endDate": null
                    });
                }else{
                    this.$emit("change",{
                        "targetedColumn": this.targetedColumn,
                        "startDate": e.startDate,
                        "endDate": e.endDate
                    });
                }
            },
        },
        computed: {}
    };

</script>
<style scoped>
</style>
