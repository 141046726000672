var MessagePlugin = {}
import Vue from "vue";
import VgSnackbar from "src/components/Vg/VgSnackbar.vue";
let snackbar = Vue.extend(VgSnackbar);

MessagePlugin.install = function install (_Vue) {
    if (install.installed) return;
    install.installed = true;
  let _uid = 'vue-directive-message' + Date.now().toString('16')
  _Vue.prototype.$message = function (message, config={}) {
    let propsData = {
        message: message
    };
    if(config && config.timeout) propsData.timeout = config.timeout;
    if(config && config.type) propsData.type = config.type;
    let snackbarInstance = new snackbar({propsData: propsData}).$mount().$el
    let app = document.getElementById("app")
    app.append(snackbarInstance);
  }
}
export default MessagePlugin;
