<template lang="html">
    <vg-modal
        :title="$t('modal-title')"
        :width="'75vw'"
        @close="handleClose">
        <template #body>
            <div v-if="isGe">
                <div v-for="(categorie, indexCategorie) in templateCategories.lieux" :key="indexCategorie"
                    style="border-bottom: 1px solid #f2f2f2;padding-bottom:5px;margin-bottom:20px;">
                    <vg-checkbox :key="'categorie-'+indexCategorie"
                        :defaultChecked="true"
                        @checked="onCheckCategorie(categorie, indexCategorie)"
                        @unchecked="onUncheckCategorie(categorie, indexCategorie)">
                        <span style="color:#3999ff;">{{categorie.libelleCatgorie}}</span>
                    </vg-checkbox>
                    <div style="margin:20px;">
                        <vg-checkbox v-for="(composant, indexComposant) in categorie.composants"
                            :key="'composant-'+indexCategorie+'-'+indexComposant"
                            :defaultChecked="true"
                            style="font-weight:normal;"
                            :isDisabled="isCategorieIsExclude(categorie)"
                            @checked="onCheckComposant(categorie, composant, indexComposant)"
                            @unchecked="onUncheckComposant(categorie, composant, indexComposant)">
                            <img :src="composant.icon" style="width:20px;height:20px;margin-right:5px"/>
                            <span style="color:#3999ff;font-weight:bold;font-size:12px;">{{composant.libelComposant}}</span> <span style="font-size:11px;"><i>{{composant.typeComposant}}</i></span>
                            ( <span v-for="problem in composant.problems" style="font-size:11px;"> {{problem.libelle}} , </span> )
                        </vg-checkbox>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <vg-button @click="handleClose">{{$t("close")}}</vg-button>
            <vg-button :type="'success'" @click="handleOpenShowConfirmationModal">{{$t("save")}}</vg-button>
            <vg-modal v-if="showConfirmationModal" :title="$t('modal-title-confirmation', {nbCategories: nbCategoriesACreer})"
                @close="showConfirmationModal=false;">
                <template #body>
                    <div style="display:flex;flex-direction:column;justify-content:flex-start;">
                        <div v-for="categorie in categoriesACreer"><span style="color:#3999ff;">{{categorie.libelleCatgorie}}</span> <i style="font-size:11px;"> - {{categorie.composants.length}} {{$t("composants")}}</i></div>
                    </div>
                </template>
                <template #footer>
                    <vg-button @click="showConfirmationModal=false;">{{$t("non")}}</vg-button>
                    <vg-button :type="'success'" @click="handleSave">{{$t("oui")}}</vg-button>
                </template>
            </vg-modal>
        </template>
    </vg-modal>

</template>

<script>

import VgButton from 'src/components/Vg/VgButton.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import CategorieMixins from "src/mixins/CategorieMixins.js";

export default {
    name:"vg-categorie-template-integration",
    components:{
        VgCheckbox,
        VgButton
    },
    mixins:[ CategorieMixins ],
    props:{
        isGe: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Intégrer set catégories de lieux",
            "modal-title-confirmation": "Voulez-vous intégrer ces {nbCategories} catégories?",
            "composants": "composants",
            "oui": "Oui",
            "non": "Non",
            "close": "Fermer",
            "save": "Générer ces catégories de lieux pour mon compte",
            "categorie-espace-commun": "Espace commun",
            "categorie-espace-prive": "Espace privé",
            "categorie-couloir": "Couloir",
            "categorie-sanitaire": "Sanitaire",
            "categorie-cuisine": "Cuisine",
            "categorie-restaurant": "Restaurant",
            "categorie-local-technique": "Local technique",
            "categorie-hebergement": "Hébergement",
            "composant-mur": "Mur",
            "composant-plafond": "Plafond",
            "composant-sol": "Sol",
            "composant-porte": "Porte",
            "composant-fenetre": "Fenêtre",
            "composant-plinthe": "Plinthe",
            "composant-meuble": "Meuble",
            "composant-prise-courant": "Prise courant",
            "composant-luminaire": "Luminaire",
            "composant-wifi": "Wifi",
            "composant-poste-informatique": "Poste informatique",
            "composant-video-projecteur": "Vidéo projecteur",
            "composant-televiseur": "Téléviseur",
            "composant-climatiseur": "Climatiseur",
            "composant-douche": "Douche",
            "composant-wc": "WC",
            "composant-lit": "Lit",
            "composant-lavabo": "Lavabo",
            "composant-evier": "Evier",
            "composant-siphon-de-sol": "Siphon de sol",
            "composant-type-bati": "Composant du bâti",
            "composant-type-menuiserie": "Menuiserie",
            "composant-type-mobilier": "Mobilier",
            "composant-type-electricite": "Electricité",
            "composant-type-informatique": "Informatique",
            "composant-type-plomberie": "Plomberie",
            "composant-type-exterieur": "Extérieur",
            "categorie-exterieur": "Extérieur",
            "composant-chaussee": "Chaussée",
            "composant-signaletique": "Signalétique",
            "composant-espace-vert": "Espace vert",
            "composant-haie": "Haie",
            "composant-parking": "Parking",
            "composant-allee": "Allée",
            "composant-trottoir": "Trottoir",
            "composant-caniveau": "Caniveau",
            "composant-point-eau": "Point d'eau",
            "composant-arrosage": "Arrosage",
            "composant-autre": "Autre",
            "problem-autre": "Autre",
            "problem-sale": "Sale",
            "problem-abime": "Abimé",
            "problem-abimee": "Abimée",
            "problem-fuite": "Fuite",
            "problem-hors-service": "Hors service",
            "problem-ne-fonctionne-plus": "Ne fonctionne plus",
            "problem-ne-refroidit-plus": "Ne refroidit plus",
            "problem-ne-s-ouvre-plus": "Ne s'ouvre plus",
            "problem-ne-s-allume-plus": "Ne s'allume plus",
            "problem-clignote": "Clignote",
            "problem-ne-ferme-plus": "Ne ferme plus",
            "problem-goutte": "Goutte",
            "problem-bouche": "Bouché"
        },
        "en": {
            "modal-title": "Integrate set of room categories",
            "modal-title-confirmation": "Do you want to integrate these {nbCategories} categories?",
            "composants": "components",
            "oui": "Yes",
            "non": "No",
            "close": "Close",
            "save": "Generate these room categories for my account",
            "categorie-espace-commun": "Common area",
            "categorie-espace-prive": "Private area",
            "categorie-couloir": "Corridor",
            "categorie-sanitaire": "Sanitation",
            "categorie-cuisine": "Kitchen",
            "categorie-restaurant": "Restaurant",
            "categorie-local-technique": "Technical room",
            "categorie-hebergement": "Accommodation",
            "composant-mur": "Wall",
            "composant-plafond": "Ceiling",
            "composant-sol": "Floor",
            "composant-porte": "Door",
            "composant-fenetre": "Window",
            "composant-plinthe": "Baseboard",
            "composant-meuble": "Furniture",
            "composant-prise-courant": "Power outlet",
            "composant-luminaire": "Light",
            "composant-wifi": "Wifi",
            "composant-poste-informatique": "Computer",
            "composant-video-projecteur": "Projector",
            "composant-televiseur": "TV",
            "composant-climatiseur": "Air conditioner",
            "composant-douche": "Shower",
            "composant-wc": "WC",
            "composant-lit": "Bed",
            "composant-lavabo": "Washbasin",
            "composant-evier": "Sink",
            "composant-siphon-de-sol": "Floor drain",
            "composant-type-bati": "Building component",
            "composant-type-menuiserie": "Joinery",
            "composant-type-mobilier": "Set of furniture",
            "composant-type-electricite": "Electricity",
            "composant-type-informatique": "Computing",
            "composant-type-plomberie": "Plumbing",
            "composant-type-exterieur": "Outdoor",
            "categorie-exterieur": "Outdoor",
            "composant-chaussee": "Pavement",
            "composant-signaletique": "Signage",
            "composant-espace-vert": "Green space",
            "composant-haie": "Hedgerow",
            "composant-parking": "Parking",
            "composant-allee": "Driveway",
            "composant-trottoir": "Footpath",
            "composant-caniveau": "Gutter",
            "composant-point-eau": "Water point",
            "composant-arrosage": "Spray",
            "composant-autre": "Other",
            "problem-autre": "Other",
            "problem-sale": "Dirty",
            "problem-abime": "Damaged",
            "problem-abimee": "Damaged",
            "problem-fuite": "Leak",
            "problem-hors-service": "Out of order",
            "problem-ne-fonctionne-plus": "No longer works",
            "problem-ne-refroidit-plus": "No longer cools",
            "problem-ne-s-ouvre-plus": "No longer opens",
            "problem-ne-s-allume-plus": "Does not light up",
            "problem-clignote": "Flash",
            "problem-ne-ferme-plus": "No longer closes",
            "problem-goutte": "Drop",
            "problem-bouche": "Blocked"
        },
        "th": {
            "close": "ปิด",
            "save": "บันทึก"
        }
    }
},
    data: function(){
        return{
            showConfirmationModal: false,
            categoriesACreer: [],
            templateCategories: {
                lieux: [
                    {
                        libelleCatgorie: this.$t("categorie-espace-commun"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-poste-informatique"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/pc-fixe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-video-projecteur"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/video-projecteur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            }
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-espace-prive"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-climatiseur"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/climatiseur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-refroidit-plus")},
                                    {libelle: this.$t("problem-goutte")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-couloir"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-climatiseur"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/climatiseur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-refroidit-plus")},
                                    {libelle: this.$t("problem-goutte")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-sanitaire"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-siphon-de-sol"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/syphon-de-sol.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-douche"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/douche-exterieure.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-refroidit-plus")},
                                    {libelle: this.$t("problem-goutte")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wc"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/WC.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-cuisine"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/meuble-mural-de-rangement.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-lavabo"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/lavabo.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-evier"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/lavabo.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-siphon-de-sol"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/syphon-de-sol.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-restaurant"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-poste-informatique"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/pc-fixe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-siphon-de-sol"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/syphon-de-sol.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-local-technique"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-personnel-haute.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            }
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-hebergement"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-mur"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/mur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plafond"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plafond.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-sol"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-fuite")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-porte"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/porte.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-fenetre"), typeComposant: this.$t("composant-type-menuiserie"),
                                icon: "/static/assets/icone/composant/fenetre.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-sale")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-s-ouvre-plus")},
                                    {libelle: this.$t("problem-ne-ferme-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-plinthe"), typeComposant: this.$t("composant-type-bati"),
                                icon: "/static/assets/icone/composant/plinthe.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-meuble"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/armoire-basse.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-prise-courant"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/prise-de-courant.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wifi"), typeComposant: this.$t("composant-type-informatique"),
                                icon: "/static/assets/icone/composant/wifi.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-televiseur"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/televiseur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-climatiseur"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/climatiseur.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-refroidit-plus")},
                                    {libelle: this.$t("problem-goutte")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-douche"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/douche-exterieure.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abimee")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-refroidit-plus")},
                                    {libelle: this.$t("problem-goutte")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-wc"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/WC.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-lit"), typeComposant: this.$t("composant-type-mobilier"),
                                icon: "/static/assets/icone/composant/lit.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")}
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-lavabo"), typeComposant: this.$t("composant-type-plomberie"),
                                icon: "/static/assets/icone/composant/lavabo.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-fuite")},
                                    {libelle: this.$t("problem-bouche")},
                                ]
                            },
                        ]
                    },
                    {
                        libelleCatgorie: this.$t("categorie-exterieur"),
                        isGe: true, openMaintenanceOnError: true, isGenerique: false,
                        composants: [
                            {
                                libelComposant: this.$t("composant-chaussee"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/chausee.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-signaletique"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/signaletique.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-espace-vert"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/espace-vert.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-haie"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/haie.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-parking"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/parking.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-allee"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/sol-dalle.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-trottoir"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/trottoir.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-caniveau"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/caniveau.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-point-eau"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/descente-de-gouttiere.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-arrosage"), typeComposant: this.$t("composant-type-exterieur"),
                                icon: "/static/assets/icone/composant/point-irrigation.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-luminaire"), typeComposant: this.$t("composant-type-electricite"),
                                icon: "/static/assets/icone/composant/luminaire.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")},
                                    {libelle: this.$t("problem-abime")},
                                    {libelle: this.$t("problem-hors-service")},
                                    {libelle: this.$t("problem-ne-fonctionne-plus")},
                                    {libelle: this.$t("problem-ne-s-allume-plus")},
                                    {libelle: this.$t("problem-clignote")},
                                ]
                            },
                            {
                                libelComposant: this.$t("composant-autre"), typeComposant: "",
                                icon: "/static/assets/icone/composant/support-refrigere.png",
                                problems:[
                                    {libelle: this.$t("problem-autre")}
                                ]
                            },

                        ]
                    },

                ]
            },
            excludeCategories: [], // array of libelleCatgorie ["libelleCatgorie1", "libelleCatgorie2"]
            excludeComposants: {}   // object {"libelleCatgorie1": ["libelComposant1", "libelComposant2"]}
        }
    },
    mounted: function() {

    },
    computed:{
        nbCategoriesACreer: function(){
            return this.categoriesACreer.length;
        }
    },
    methods:{
        isCategorieIsExclude: function(categorie){
            return this.excludeCategories.findIndex((libelle)=>libelle==categorie.libelleCatgorie)!=-1;
        },
        /**
        * Sélectionne la categorie.
        * La catégorie est supprimée de la liste des categories à exclure.
        * @param Object categorie
        * @param Number indexCategorie
        */
        onCheckCategorie: function(categorie, indexCategorie){
            let indexCategorieExclude = this.excludeCategories.findIndex((libelleCatgorie)=>libelleCatgorie==categorie.libelleCatgorie);
            if(indexCategorieExclude!=-1) this.excludeCategories.splice(indexCategorieExclude, 1);
        },
        /**
        * Désélectionne la categorie.
        * Ajoute la catégorie à la liste des catégorie à exclure.
        * @param Object categorie
        * @param Number indexCategorie
        */
        onUncheckCategorie: function(categorie, indexCategorie){
            this.excludeCategories.push(categorie.libelleCatgorie);
        },
        /**
        * Sélectionne le composant pour une catégorie.
        * Le composant est supprimé de la liste des composants à exclure. Si aucun autre composant est à exclure pour cette catégorie, supprime la référence de la catégorie de la liste des composants à exclure.
        * @param Object categorie
        * @param Number indexCategorie
        */
        onCheckComposant: function(categorie, composant, indexComposant){
            if(this.excludeComposants.hasOwnProperty(categorie.libelleCatgorie)){   // si il y a une liste de composants à eclure déjà pour cette meme categorie
                let indexComposant = this.excludeComposants[categorie.libelleCatgorie].findIndex((libelle)=>composant.libelComposant);
                this.excludeComposants[categorie.libelleCatgorie].splice(indexComposant, 1);
                if(this.excludeComposants[categorie.libelleCatgorie].length==0) delete this.excludeComposants[categorie.libelleCatgorie];
            }
        },
        /**
        * Desélectionne le composant pour une catégorie.
        * Le composant ajouté à la liste des composants à exclure.
        * @param Object categorie
        * @param Number indexCategorie
        */
        onUncheckComposant: function(categorie, composant, indexComposant){
            if(this.excludeComposants.hasOwnProperty(categorie.libelleCatgorie)){
                this.excludeComposants[categorie.libelleCatgorie].push(composant.libelComposant);
            }else{
                this.excludeComposants[categorie.libelleCatgorie] = [composant.libelComposant];
            }
        },
        handleClose: function(){
            this.$emit("close");
        },
        handleOpenShowConfirmationModal: function(){
            let categoriesACreer = [];
            let composantsACreer = [];
            let tmpCategorie = null;
            // enleve les categories dans excludeCategories && enleve les composants dans excludeComposants
            this.templateCategories.lieux.forEach((categorie)=>{
                if(this.excludeCategories.findIndex((libelle)=>libelle==categorie.libelleCatgorie)==-1){ // si la categorie n'est pas exclue
                    if(this.excludeComposants.hasOwnProperty(categorie.libelleCatgorie)){ // si au moins un composant de la categorie est à exclure
                        // on enleve tous les composants de la categorie à exclure
                        composantsACreer = categorie.composants.filter((composant)=>this.excludeComposants[categorie.libelleCatgorie].findIndex((libelle)=>libelle==composant.libelComposant)==-1);
                        tmpCategorie = Object.assign({}, {}, categorie);
                        tmpCategorie.composants = composantsACreer;
                        categoriesACreer.push(tmpCategorie);
                    }else{
                        categoriesACreer.push(categorie);
                    }
                }
            });
            console.log("CATEGORIES A CREER", categoriesACreer);
            this.categoriesACreer = categoriesACreer;
            this.showConfirmationModal = true;
        },
        handleSave: function(){
            this.CategorieMixins_create(this.categoriesACreer).then((datas)=>{
                console.log("AFTER CREATE CATEGORIES", datas);
                this.$emit("save", datas);
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
