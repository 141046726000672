<template>
    <div class="lieu-demande-intervention-cell-render" @click="emitSelect" :style="isPieceSelected?'color:#1e90ff;':''">
        <i v-if="isPieceSelected" class="far fa-check-square fa-2x"></i>
        <i v-else class="far fa-square fa-2x"></i>
        <div style="display:flex;flex-direction:column;justify-content:flex-start;">
            <vg-lieu-path-viewer class="path-cell-render"
                v-model="params.data.path"
                onlyPiece
                :showTooltip="false">
            </vg-lieu-path-viewer>
            <vg-lieu-path-viewer class="path-cell-render"
                v-model="params.data.path"
                onlyPath
                :showTooltip="false">
            </vg-lieu-path-viewer>
            <span v-if="params.data.service" style="margin-top:2px;font-size:11px;border:1px solid #f9b934;text-align:center;">{{params.data.service}}</span>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import { mapGetters } from 'vuex';
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"lieu-demande-intervention-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isChecked: {
                type: Boolean,
                default: false
            }
		},
        components: {
            VgLieuPathViewer
        },
        computed: {
            ...mapGetters({
                piecesSelected: 'LieuxStore/getSelectedItems'
            }),
            isPieceSelected: function(){
                return this.piecesSelected.findIndex((piece)=>piece.id==this.params.data.id)!=-1;
            },
        },
        methods: {
            emitSelect: function(){
                if(this.isPieceSelected) this.$emit("deselect-piece", this.params.data);
                else this.$emit("select-piece", this.params.data);
            }
        }
    });
</script>

<style lang="scss" scoped>
.lieu-demande-intervention-cell-render{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    padding-left: 0;
    border-bottom: 1px solid #DCDFE6;
    cursor: pointer;
    >i{
        margin-right: 20px;
    }
}
</style>
