<template>
    <div v-if="intervention"
        :title="getInfos"
        :class="['prochaine-intervention-cell-render',{'critique':isCritique,'warning':isWarning}]">
        <span>
            <small><vg-datetime-viewer v-model="getDateIntervention"></vg-datetime-viewer></small>
        </span><br>
        <progress :max="getProgressMax" :value="getProgressValue">{{getProgressValue}}</progress><br>
        <span><small>{{$t("rdv-confirme")}}: {{isConfirmed?$t("oui"):$t("non")}}</small></span>
    </div>
    <div v-else class="prochaine-intervention-cell-render empty">
        <span><small>{{$t("prochaine-intervention-not-set")}}</small></span><br>
        <progress :max="getProgressMax" :value="getProgressValue">{{getProgressValue}}</progress>
    </div>
</template>

<script>
import Vue from "vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"prochaine-intervention-cell-render",
    mixins:[LangMixins],
	props: {
        /**
        * contrat
        */
        contrat: {
            type: Object
        },
        /**
        * prochaine intervention
        */
        intervention: {
            type: Object
        }
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "rdv-confirme": "Rdv confirmé",
            "oui": "Oui",
            "non": "Non",
            "intervention-prochaine-arrive": "Intervention prévue dans {jours} jours",
            "intervention-prochaine-demain": "Intervention prévue demain",
            "intervention-prochaine-today": "Intervention prévue aujourd'hui",
            "prochaine-intervention-not-set": "Aucune intervention prévue"
        },
        "en": {
            "rdv-confirme": "Confirmed appointment",
            "oui": "Yes",
            "non": "No",
            "intervention-prochaine-arrive": "Intervention schelduled in {jours} days",
            "intervention-prochaine-demain": "Intervention schelduled tomorrow",
            "intervention-prochaine-today": "Intervention schelduled today",
            "prochaine-intervention-not-set": "No intervention schelduled"
        }
    }
},
    components:{
        VgDatetimeViewer
    },
    computed:{
        isCritique: function(){
            if(this.intervention){
                // date prévue today
                let today = moment();
                let datePrevue = moment(this.intervention.datePrevisionnelleDebut);
                return datePrevue.diff(today, "days")==0 && this.intervention.status=="intervention-prevue";
            }else return false;
        },
        isWarning: function(){
            if(this.intervention){
                // date prévue arrive dans moins de 2 semaines
                let today = moment();
                let datePrevue = moment(this.intervention.datePrevisionnelleDebut);
                return datePrevue.diff(today, "days")>0 && datePrevue.diff(today, "days")<=14 && this.intervention.status=="intervention-prevue";
            }else return false;
        },
        getInfos: function(){
            let today = moment();
            let datePrevue = moment(this.intervention.datePrevisionnelleDebut);
            let nbJoursAvantIntervention = datePrevue.diff(today, "days");
            if(nbJoursAvantIntervention==0) return this.$t("intervention-prochaine-today");
            else if(nbJoursAvantIntervention==1) return this.$t("intervention-prochaine-demain");
            else return this.$t("intervention-prochaine-arrive", {jours: nbJoursAvantIntervention});
        },
        getProgressValue: function(){
            let value = 0;
            if(this.intervention){
                let datePrevue = moment(this.intervention.datePrevisionnelleDebut);
                value = datePrevue.diff(this.contrat.startDate, "days");
            }else{
                let dateToday = moment();
                value = dateToday.diff(this.contrat.startDate, "days");
            }
            if(value>this.contrat.renewalPeriodicity){
                let v = value/this.contrat.renewalPeriodicity;
                v = v.toFixed(2);
                v = v-Math.floor(v);
                return parseInt(v*this.contrat.renewalPeriodicity);
            }else return value;
        },
        getProgressMax: function(){
            return this.contrat.renewalPeriodicity; // durée du contrat
        },
        isConfirmed: function(){
            if(this.intervention) return this.intervention.confirmed;
            else return null;
        },
        getDateIntervention: function(){
            return this.intervention.datePrevisionnelleDebut;
        }
    },
    methods:{

    }
});
</script>

<style lang="scss" scoped>
.prochaine-intervention-cell-render{
    line-height: 0.7;
}
progress:not(value) {
    background-color: whiteSmoke;
}
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 6px;
}
progress[value]::-webkit-progress-value {
    background-color: green;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
.critique{
    progress[value]::-webkit-progress-value {
        background-color: red;
    }
}
.warning{
    progress[value]::-webkit-progress-value {
        background-color: orange;
    }
}
.empty{
    progress[value]::-webkit-progress-value {
        background-color: grey;
    }
}
</style>
