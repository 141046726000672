/**
 *
 *   Subscriber. Subscribe to a BroadcastChannel
 *
 * @author TxTony
 * @version 1.0
 * @license MIT
 * @class
 * @example
        new Subscriber("user_actions","main.js")
         .whereAction([
             "open_maintenance",
             "open_maintenance_affectation"
         ])
         .onMessageReception(function (ev) {
             let message = ev.data.message;
             let maintenanceId = null;
             if(ev.data.userMetadata.action == "open_maintenance"){
                 maintenanceId = message.id;
             }
             else if(ev.data.userMetadata.action == "open_maintenance_affectation"){
                 maintenanceId = message.users[0].fichesavId || message.tiers[0].fichesavId;
             }
             window.location = "/maintenance/"+maintenanceId;
         }
         );
 */
class Subscriber {
    /**
     * @constructor
     * @param string channel
     * @param string  id
     */
    constructor(channel, id = null) {
        this.channelName = channel;
        this.id = id;
        if(this.isBroadcastChannelAvalaible()){
            this.channel = new BroadcastChannel(channel);
        }
        this.message = null;
        this.where = null;
        return this;
    }
        /**
     * @param boolean
     */
         isBroadcastChannelAvalaible(){
            return BroadcastChannel ? true : false;
        }
    /**
    * Get BroadcastChannel By name.
    *
    * @param string name
    * @return BroadcastChannel
    */
    getChannel(name){
        return this.channel;
    }
    /**
    * Set filters on receive message.
    *
    * @exemple ["a","b"]
    * @param array names
    * @return this
    */
    whereAction(names){
        this.where = names;
        return this;
    }
    /**
    * Check if metadata.action is in array of authorize action.
    *
    * @param array array of string
    * @return boolean
    */
    isAuthorizeAction(message){
        if(!this.where) return true;
        let action = message.data.userMetadata.action;
        return action ? this.where.includes(action) : true;
    }
    /**
    * On receive message.
    *
    * @param Function receiveCallback
    * @return this;
    */
    onMessageReception(receiveCallback){
        if(!this.isBroadcastChannelAvalaible()) return;
        this.channel.onmessage = (ev)=>{
            if(!this.isAuthorizeAction(ev)){
                //console.log("not ok")
            }

            // execute callback if pass filters
            receiveCallback && receiveCallback(ev)
        }
        return this;
    }
    /**
    * Unsubscribe this channel.
    */
    unsubscribe(){
        this.channel.close();
    }
}
export default  Subscriber;
