<template>
    <span v-if="(attributeQuantite && params.data[attributeQuantite]) || quantite"
        class="tache-quantite-cell-render"
        @click="isGep?goToPageLieux():goToPageEquipements()">
        {{ attributeQuantite?params.data[attributeQuantite]:quantite }} {{ $t("equipement-type-"+typeEquipement) }}
    </span>
    <span v-else
        class="tache-quantite-cell-render red">
        {{ $t("aucun-type-"+typeEquipement) }}
    </span>
    <!--span v-if="params.data[attributeQuantite] && (params.data.type_tache=='Verification_equipement' || params.data.type_tache=='Relever_Grandeur_physique')" 
        class="tache-quantite-cell-render"
        @click="goToPageEquipements()">
        {{ params.data[attributeQuantite] }} {{ $t("equipements") }}
    </span>
    <span v-else-if="params.data.type_tache=='Verification_equipement' || params.data.type_tache=='Relever_Grandeur_physique'" 
        class="tache-quantite-cell-render red">
        {{ $t("aucun-equipement") }}
    </span>
    <span v-else-if="params.data[attributeQuantite] && params.data.type_tache=='Verification_Lieu'" 
        class="tache-quantite-cell-render"
        @click="goToPageLieux()">
        {{ params.data[attributeQuantite] }} {{ $t("pieces") }}
    </span>
    <span v-else-if="params.data.type_tache=='Verification_Lieu'" 
        class="tache-quantite-cell-render red">
        {{ $t("aucune-piece") }}
    </span>
    <span v-else-if="params.data[attributeQuantite]" 
        class="tache-quantite-cell-render"
        @click="goToPageEquipements()">
        {{ params.data[attributeQuantite] }} {{ $t("compteurs") }}
    </span>
    <span v-else 
        class="tache-quantite-cell-render red">
        {{ $t("aucun-compteur") }}
    </span-->
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "categorie-quantite-equipements-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            quantite: {
                type: Number,
                default: null
            },
            attributeQuantite: {
                type: String,
                default: null
            },
            libelleCategorie: {
                type: String,
                required: true
            },
            typeEquipement:{
                type: String,
                default: "equipement"
            },
            isGep:{
                type: Boolean,
                default: false
            }
		},
        components:{
            
        },
        data: function() {
            return {

			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "equipement-type-equipement": "equipement(s)",
            "equipement-type-compteur": "compteur(s)",
            "equipement-type-piece": "pièce(s)",
            "equipement-type-Verification_equipement": "equipement(s)",
            "equipement-type-Relever_Grandeur_physique": "equipement(s)",
            "equipement-type-Relever_compteur": "compteur(s)",
            "equipement-type-Verification_Lieu": "pièce(s)",
            "aucun-type-equipement": "Aucun équipement",
            "aucun-type-piece": "Aucune pièce",
            "aucun-type-compteur": "Aucun compteur",
            "aucun-type-Verification_equipement": "Aucun équipement",
            "aucun-type-Relever_Grandeur_physique": "Aucun équipement",
            "aucun-type-Verification_Lieu": "Aucune pièce",
            "aucun-type-Relever_compteur": "Aucun compteur"
        },
        "en": {
            "equipement-type-equipement": "equipment(s)",
            "equipement-type-compteur": "meter(s)",
            "equipement-type-piece": "room(s)",
            "equipement-type-Verification_equipement": "equipment(s)",
            "equipement-type-Relever_Grandeur_physique": "equipment(s)",
            "equipement-type-Relever_compteur": "meter(s)",
            "equipement-type-Verification_Lieu": "room(s)",
            "aucun-equipement": "No equipment",
            "aucune-piece": "No room",
            "aucun-compteur": "No meter"
        }
    }
},
        methods:{
            goToPageEquipements: function(){
                this.$emit("open-page-equipements", {
                    tache: this.params.data, 
                    filters: {
                        field_categorie: { attr: "c.libelleCatgorie", value: this.libelleCategorie, action:"equals"}
                    }
                });
            },
            goToPageLieux: function(){
                this.$emit("open-page-lieux", {
                    tache: this.params.data, 
                    filters: {
                        field_categorie: { attr: "c.libelleCatgorie", value: this.libelleCategorie, action:"equals"}
                    }
                });
            },
        }
    });
</script>

<style lang="scss" scoped>
.tache-quantite-cell-render{
    cursor: pointer;
    text-decoration: underline;
    color: #35b1ea;
    font-size:10px;
}
.red{
    cursor: default;
    text-decoration: none;
    color: red;
}
</style>
