<template>
    <div>

        <div class="equipementmobilecellrender">
         <div class="row-1">
             <div class="libelPathText">
                 <span style='font-size: 15px; font-weight: bold;'> {{ $t(params.type) }} </span>
                 <!--span style='font-size: 12px;color: #c9c9c9;'> {{$t("dans")}} </span-->
                 <!--vg-lieu-path-viewer v-model="params.path" onlyPiece showTooltip="false" ></vg-lieu-path-viewer-->
             </div>

         </div>
         <div class="row-2">
            {{ $t("commentaire") }} : {{ params.commentaire }}
         </div>
         <div class="row-3">
             <div style='color:#c9c9c9'> {{$t("date-mouvement")}} </div>
             <vg-datetime-viewer v-model="params.dateMouvement"></vg-datetime-viewer>
             &nbsp;
         </div>
         <div class="row-3" v-if="params.type == 'pret'">
             <div style='color:#c9c9c9'> {{$t("retour-prevu-pour")}} </div>
             <vg-datetime-viewer v-model="params.dateRetourPrevu"></vg-datetime-viewer>
             &nbsp;
         </div>
     </div>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue"
export default Vue.extend({
    mixins:[LangMixins],
    components:{VgDatetimeViewer},
    props: {
        params: {
            type: Object,
            required: true,
            default: {}
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "pret": "Sortie temporaire équipement(s)",
            "retour": "Retour équipement(s)",
            "date-mouvement": "Date mouvement",
            "retour-prevu-pour": "Retour prévu pour le",
            "commentaire": "Commentaire"
        }
    }
},
    created:function(){
        console.log(this.params)
    },
    computed:{
    }
});
</script>

<style style="scss" scoped>
.equipementmobilecellrender{
    font-family: arial;
    .row-1,.row-2,.row-3{
        margin-bottom: 10px;
    }
    .row-1{
        display: flex;
        .libelPathText{

            display:flex;
            align-items:center;
        }
        img{
            align-self: flex-end;
            margin-right: 10px;
        }
        span{
            align-self: flex-end;
            margin-right: 5px;
        }
    }
    .row-2{
        display: flex;
        color:#999999;
        font-size: 12px;
        white-space: normal;
    }
    .row-3{
        display: flex;
        font-size: 12px;
        div{
            align-self: flex-end;
            margin-right: 5px;
        }
    }
}
</style>
