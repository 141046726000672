<template>
	<div v-if="operation.flag=='fermeture'" class="operation-fermeture">
		<div>
			<span>{{$t("fermeture")}}</span>
			{{$t("par")}}
			<span class="user-name">{{operation.username}}</span>
		</div>
		<div v-if="operation.operation && operation.operation!='fermeture'">
			<span class="subtitle">{{$t("rapport-cloture")}} :</span>
			<span style="white-space: normal">{{operation.operation}}</span>
		</div>
	</div>
	<div v-else-if="operation.flag=='a-valider'">
		<div>
			<span>{{$t("fermeture")}}</span>
			{{$t("par")}}
			<span class="user-name">{{operation.username}}</span>
		</div>
		<div v-if="operation.operation && operation.operation!='fermeture'">
			<span class="subtitle">{{$t("rapport-cloture")}} :</span>
			<span style="white-space: normal">{{operation.operation}}</span>
		</div>
	</div>
	<div v-else-if="operation.flag=='img'">
		{{$t("photo")}}
	</div>
	<div v-else-if="operation.flag=='relance'">
		<span>{{operation.operation}}</span>
	</div>
	<div v-else-if="operation.flag=='reouverture'">
		<span class="subtitle">{{$t("reouverture")}}</span> {{$t("par")}} <span class="user-name">{{operation.username}}</span>
	</div>
	<div v-else-if="operation.flag=='email-demande-devis'">

        <span class="subtitle">{{$t('email-demande-devis')}}</span>
        {{$t("par")}}
        <span class="user-name">{{operation.username}}</span>
        {{$t('to-prestataire')}}
        <span class="prestataire">{{operation.tiers.name}}</span>
	</div>
	<div v-else-if="operation.flag=='urgence'">
		{{$t("urgence")}}
	</div>
	<div v-else-if="operation.flag=='differe'">
		{{$t("differe")}}
	</div>
	<div v-else-if="operation.flag=='bi'">
		{{$t("bi")}}
	</div>
	<div v-else-if="operation.flag=='photo'">
		{{$t("photo")}}
	</div>
	<div v-else-if="operation.flag=='tiersinterventionponctuelle'" style="display: flex;flex-direction: column;justify-content: flex-start;gap:2px;line-height: 1.3;">
		<div>
			{{$t("intervention-n")}}<span class="link" name="intervention-link" :intervention-id="operation.tiers.intervention.id">{{operation.tiers.intervention.numeroIntervention}}</span>
        	{{$t("intervention-prestataire")}} <span class="prestataire">{{operation.tiers.name}}</span>
		</div>
        <div style="display:flex;flex-direction:column;justify-content:flex-start;line-height: 1.1;font-size:11px;">
			<span>
				{{$t("intervention-cout", {devise: $app.devise})}}: <span class="cout" v-if="operation.tiers.intervention.cout">{{operation.tiers.intervention.cout}}</span><small class="cout" v-else>{{ $t("aucun-cout-saisi") }}</small>
				<span class="rate" v-if="operation.tiers.intervention.rate"><i class="far fa-thumbs-up"></i> {{operation.tiers.intervention.rate}}</span>
			</span>
			<span v-if="operation.tiers.intervention.commentaire" style="white-space: normal">{{$t("commentaire")}}: {{ operation.tiers.intervention.commentaire }}</span>
		</div>
    </div>
	<!-- AFFECTATION INTERNE -->
	<div v-else-if="operation.flag=='affectation/interne'">
		<div v-if="operation.affectation && operation.affectation.start">
			<span>{{$t("affectation-interne")}} {{$t("du")}} </span>
			<vg-datetime-viewer v-model="operation.affectation.start" />
			<span> {{$t("au")}} </span>
			<vg-datetime-viewer v-model="operation.affectation.end" />
		</div>
		<br>
		<span v-for="(affecte, index) in getAffectes" :key="index">
			<b>{{affecte.nom}} {{affecte.prenom}} </b>
		</span>
	</div>
	<!-- AFFECTATION EXTERNE -->
	<div v-else-if="operation.flag=='affectation/externe'">
		<div v-if="operation.affectation && operation.affectation.start">
			<span>{{$t("affectation-externe")}} {{$t("du")}} </span>
			<vg-datetime-viewer v-model="operation.affectation.start" />
			<span> {{$t("au")}} </span>
			<vg-datetime-viewer v-model="operation.affectation.end" />
		</div>
		<b>{{operation.tiers.name}}</b>
	</div>
	<!-- AFFECTATION EXTERNE EMAIL -->
	<div v-else-if="operation.flag=='affectation/externe/email'">
		{{$t("commentaire")}}: {{operation.operation.split("|")[0]}}
		<br>
		{{$t("contact")}}: <b>{{operation.operation.split("|")[1]}}</b>
	</div>
	<div v-else-if="operation.flag=='validation'">
		{{$t("validation")}}
	</div>
	<div v-else-if="operation.flag=='consommation'">
        <span>{{$t("consommable")}} : <b>{{operation.consommable.name}}</b></span>
        <br>
        <span>{{$t("consommable-quantite")}} : {{operation.consommableMouvement.quantite}} {{operation.consommable.unite?operation.consommable.unite:"u."}}</span>
        <span v-if="operation.consommableMouvement.cout">{{$t("consommable-cout-consommation")}} : {{operation.consommableMouvement.cout}} {{$app.devise}}</span>
        <span v-else-if="operation.consommable.coutUnitaire">{{$t("consommable-cout-unitaire")}} : {{operation.consommable.coutUnitaire}} {{$app.devise}}</span>
        <span v-if="operation.consommable.refExterne">{{$t("consommable-ref-constructeur")}} : {{operation.consommable.refExterne}}</span>
        <span v-if="operation.consommable.marque">{{$t("consommable-marque")}} : {{operation.consommable.marque}}</span>
	</div>
	<div v-else-if="operation.flag=='relance/iot'">
		{{$t("relance-iot")}}
	</div>
	<div v-else-if="operation.flag=='en_attente'">
		<span class="subtitle">{{$t("en-attente")}}</span> {{$t("par")}} <span class="user-name">{{operation.username}}</span>
	</div>
	<div v-else-if="operation.flag=='prise_en_compte'">
		<span class="subtitle">{{$t("prise-en-compte")}}</span> {{$t("par")}} <span class="user-name">{{operation.username}}</span>
	</div>
	<div v-else-if="operation.flag=='retourAFaire'">
		<span class="subtitle">{{$t("retour-a-faire")}}</span> <span>{{operation.operation}}</span>
	</div>
    <div v-else-if="operation.flag=='retourFait'">
		<span class="subtitle">{{$t("retour-fait")}} {{$t("commentaire")}} :</span> <span>{{operation.operation}}</span>
	</div>
    <div v-else-if="operation.flag=='changement_equipement'">
		<span class="subtitle">{{$t("remplacement")}}</span> <b>{{operation.equipement.name}}</b> <span>{{operation.equipement.qrCode}}</span>
        <br>
        <span class="subtitle">{{$t("par")}}</span> <b>{{fm.equipement.libel_equipement}}</b> <span>{{fm.equipement.qrCode}}</span>
	</div>
	<div v-else-if="operation.flag=='intervention-reserve'">
		<u>{{$t("intervention-n")}}<span class="link" name="intervention-link" :intervention-id="fm.intervention_id">{{fm.intervention.numeroIntervention}}</span></u>
		<span><b>{{fm.intervention.intitule}}</b></span>
        {{$t("intervention-prestataire")}} <span class="prestataire"><b>{{fm.intervention.tiers.name}}</b></span>
        <br>
        {{$t("intervention-contrat")}}: <span class="contrat">{{fm.intervention.contrat.name}}</span>
	</div>
	<div v-else-if="operation.flag=='tache'" class="operation-check">
		<div>
			<i :class="['far fa-2x',{'fa-square':operation.statut=='en_cours','fa-check-square':operation.statut=='resolue'}]"
				@click="onClickOperationCheck"></i>
			<span>{{operation.operation}}</span>
			<vg-operation-tags-viewer v-if="operation.tags && operation.tags.length!=0"
				v-model="operation.tags">
			</vg-operation-tags-viewer>
		</div>
		<div v-if="operation.retourClient">
			<span class="subtitle">{{$t("commentaire-operation")}} :</span>
			<span>{{operation.retourClient}}</span>
		</div>
	</div>
	<div v-else-if="operation.flag=='a_prevoir'" class="operation-check">
		<div>
			<i :class="['far fa-2x',{'fa-square':operation.statut=='en_cours','fa-check-square':operation.statut=='resolue'}]"
				@click="onClickOperationCheck"></i>
			<span>{{operation.operation}}</span>
			<vg-operation-tags-viewer v-if="operation.tags && operation.tags.length!=0"
				v-model="operation.tags">
			</vg-operation-tags-viewer>
		</div>
		<div v-if="operation.retourClient">
			<span class="subtitle">{{$t("commentaire-operation")}} :</span>
			<span>{{operation.retourClient}}</span>
		</div>
	</div>
	<div v-else-if="operation.flag=='remplacement-equipement'" class="operation-remplacement-equipement">
		<span>{{$t("remplacement-equipement")}}</span>
		<vg-equipement-viewer :equipement="operation.sortieEquipement.equipement" style="line-height: 1.2;"/>
		<span>{{$t("par")}}</span>
		<vg-equipement-viewer :equipement="operation.sortieEquipement.equipementRemplacant" style="line-height: 1.2;"/>
	</div>
	<div v-else-if="operation.flag=='bon-de-commande'">
		<span>{{ $t("bon-de-commande-numero") }} <b>{{ operation.bonDeCommande.numero }}</b> - {{ operation.bonDeCommande.fournisseur.name }}</span>
		<div style="display: flex;justify-content: flex-start;align-items: center;gap: 20px;">
			<span>{{ $t("montant-ht") }}: <vg-input-cost v-model="operation.bonDeCommande.montantHT" /></span>
			<span style="margin-left:20px;">{{ $t("montant-ttc") }}: <vg-input-cost v-model="operation.bonDeCommande.montantTTC" /></span>
		</div>
	</div>
	<div v-else style="height: fit-content;line-height: normal;">
		<span style="white-space: normal;">{{operation.operation}}</span>
	</div>
</template>
<script>
	import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
	import VgOperationTagsViewer from "src/components/Vg/Operation/VgOperationTagsViewer.vue";
	import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
	import VgInputCost from "src/components/Vg/VgInputCost.vue";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	export default {
        name: 'vg-operation-viewer',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "intervention-n": "Intervention n°",
            "intervention-prestataire": "par prestataire",
            "to-prestataire": "Au tiers",
            "email-demande-devis": "Demande de devis email",
            "intervention-contrat": "Contrat",
            "intervention-cout": "Coût HT en {devise}",
            "photo": "Prise photo",
            "fermeture": "Fermeture",
            "relance": "Relance",
            "reouverture": "Ré-ouverture",
            "urgence": "Fiche curative déclarée urgente",
            "differe": "Fiche curative différée",
            "bi": "Bon d'intervention associé à la fiche curative",
            "affectation-interne": "Affectation interne",
            "affectation-externe": "Affectation externe",
            "du": "du",
            "au": "au",
            "validation": "Validation de la fermeture de la fiche curative",
            "consommation": "Consommation...",
            "relance-iot": "Relance IOT...",
            "par": "par",
            "en-attente": "Mise en attente",
            "prise-en-compte": "Prise en compte",
            "retour-a-faire": "Retour à faire à",
            "retour-fait": "Retour fait",
            "commentaire": "Commentaire",
            "contact": "Contact",
            "commentaire-operation": "Commentaire",
            "consommable": "Consommable",
            "consommable-quantite": "Quantité",
            "consommable-cout-consommation": "Coût consommation",
            "consommable-cout-unitaire": "Coût unitaire",
            "consommable-ref-constructeur": "Réf. constructeur",
            "consommable-marque": "Marque",
            "remplacement": "Réaffectation de",
            "rapport-cloture": "Rapport de clôture",
			"remplacement-equipement": "Remplacement de l'équipement",
			"par": "par",
			"aucun-cout-saisi": "Aucun coût saisi",
			"bon-de-commande-numero": "Bon de commande n°",
			"montant-ht": "Montant HT",
			"montant-ttc": "Montant TTC"
        },
        "en": {
            "intervention-n": "Intervention n°",
            "intervention-prestataire": "by service provider",
            "to-prestataire": "to service provider",
            "email-demande-devis": "Quote request email ",
            "intervention-cout": "Cost excl. VAT in {devise}",
            "photo": "Photo taken",
            "fermeture": "Closure",
            "relance": "Reminder",
            "reouverture": "Reopening",
            "urgence": "Curative sheet declared urgent",
            "differe": "Postponed curative sheet",
            "bi": "Intervention order linked to curative sheet",
            "affectation-interne": "Internal assignment",
            "affectation-externe": "External assignment",
            "validation": "Validation of the closure of the curative sheet",
            "consommation": "Consumption...",
            "relance-iot": "IOT reminder...",
            "par": "by",
            "en-attente": "Put on hold",
            "prise-en-compte": "taken into account",
            "retour-a-faire": "Feedback to give to",
            "retour-fait": "Feedback given",
            "commentaire": "Comment",
            "consommable": "Consumable",
            "consommable-quantite": "Quantity",
            "consommable-cout-consommation": "Consumption cost",
            "consommable-cout-unitaire": "Unit cost",
            "consommable-ref-constructeur": "Ref. Manufacturer",
            "consommable-marque": "Brand",
            "du": "from",
            "au": "to",
            "remplacement": "Reassignement of",
            "intervention-contrat": "Contract",
            "commentaire-operation": "Feedback",
            "rapport-cloture": "Closing report",
			"remplacement-equipement": "Equipment replacement",
			"par": "with",
			"aucun-cout-saisi": "No cost entered",
			"bon-de-commande-numero": "Purchase order n°",
			"montant-ht": "Amount excl. VAT",
			"montant-ttc": "Amount incl. VAT"
        },
        "th": {
            "intervention-n": "หมายเลขการแทรกแซง",
            "intervention-prestataire": "โดยผู้ให้บริการ",
            "photo": "ถ่ายรูปภาพแล้ว",
            "fermeture": "การปิด",
            "relance": "การเตือนความจำ",
            "reouverture": "กำลังเปิดอีกครั้ง",
            "consommation": "การบริโภค...",
            "relance-iot": "การเตือนความจำ IOT...",
            "par": "โดย",
            "en-attente": "พักไว้",
            "prise-en-compte": "รวม",
            "retour-a-faire": "ข้อเสนอแนะที่ต้องให้",
            "retour-fait": "ให้ข้อเสนอแนะแล้ว",
            "commentaire": "ความคิดเห็น",
            "consommable": "วัสดุสิ้นเปลือง",
            "consommable-quantite": "จำนวน",
            "consommable-cout-unitaire": "ต้นทุนต่อหน่วย",
            "consommable-ref-constructeur": "อ้างอิงผู้ผลิต",
            "consommable-marque": "ยี่ห้อ",
            "affectation-interne": "งานที่มอบหมายภายใน",
            "affectation-externe": "งานที่มอบหมายภายนอก",
            "du": "ตั้งแต่",
            "au": "ถึง",
            "remplacement": "งานที่มอบหมายอีกครั้งของ"
        }
    }
},
		components:{
			VgDatetimeViewer,
			VgOperationTagsViewer,
			VgEquipementViewer,
			VgInputCost
		},
		mixins:[
			MaintenanceMixins
		],
    	props: {
			value: {
				type: Object,
				required: true
			},
			fm: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
				operation: this.value
			};
        },
		watch:{
			value: {
				handler: function(val){
					this.operation = val;
				},
				deep: true
			}
		},
		methods:{
			onClickOperationCheck: function(){
				let operation = {
					id: this.operation.id,
					operation: this.operation.operation,
					retourClient: this.operation.retourClient,
					flag: this.operation.flag,
					statut: this.operation.statut
				};
				if(operation.statut=="en_cours") operation.statut = "resolue";
				else if(operation.statut=="resolue") operation.statut = "en_cours";
				this.$emit("update", operation);
			}
		},
		computed: {
            numeroIntervention: function(){
                return "IE-"+this.operation.tiersinterventions_numeroIntervention;
            },
			getAffectes: function(){
				if(this.fm.affectations && this.fm.affectations.users) return this.fm.affectations.users;
				else if(this.fm.affectation) return this.fm.affectation.affectes.filter((aff)=>aff.hasOwnProperty("nom"));
				else return [];
			},
        }
    };

</script>
<style scoped lang="scss">
.operation-check{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	>div:first-child{
		display:flex;
		justify-content: flex-start;
		align-items:center;
	}
	i{
		margin-right: 10px;
		cursor: pointer;
		color: #35b1ea;
	}
	>div>span{
		margin-right: 10px;
	}
	.subtitle{
		color: #BBBCBE;
	}
}
.operation-fermeture{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	.subtitle{
		color: #BBBCBE;
	}
}
.operation-remplacement-equipement{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap:10px;
}
</style>
