<template>
    <div>
        <vg-modal
            v-show="!isModalConfirmDeleteOpened"
            @close="$emit('close')">
            <template #header>
                <span v-if="!isAnUpdateCorpsDetatForm">{{$t("corpsDetats-form-add")}}</span>
                <span v-else>{{ $t("corpsDetats-form-update") }}</span>
            </template>
            <template #body>
                <form ref="corpsDetats-form" onSubmit="false">

                    <vg-input
                        :title="$t('name')"
                        v-model="corpsDetat.name"
                    >
                    </vg-input>


                    <input v-show="false" type="submit" ref="submitCorpsDetat" />
                </form>
            </template>
            <template #footer>
                <vg-button v-if="isAnUpdateCorpsDetatForm"
                    type="default"
                    @click="$emit('close')"
                    >
                    {{$t("corpsDetats-form-cancel")}}
                </vg-button>

                <vg-button v-if="isAnUpdateCorpsDetatForm"
                    type="danger"
                    @click="isModalConfirmDeleteOpened = true"
                    >
                    {{$t("corpsDetats-form-delete")}}
                </vg-button>

                <vg-button v-if="isAnUpdateCorpsDetatForm"
                    type="default-danger"
                    @click="handleUpdateCorpsDetat"
                    >
                    {{$t("corpsDetats-form-update")}}
                </vg-button>

                <vg-button v-if="!isAnUpdateCorpsDetatForm"
                    :disabled="corpsDetat.name == ''"
                    type="success"
                    @click="handleCreateCorpsDetat"
                    >
                    {{$t("corpsDetats-form-save")}}
                </vg-button>
              </template>
        </vg-modal>

        <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="handleDeleteCorpsDetat"
          @close="isModalConfirmDeleteOpened = false"
        >
          <template v-slot:header-title>
            {{ $t("corpsDetat-form-delete") }} <b>{{ corpsDetat.email }}</b> ?
          </template>
        </dialog-secured-action>
    </div>

</template>
<script>
    import CorpsDetatMixins from "src/mixins/CorpsDetatMixins.js";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";

    export default {
        name: 'vg-corps-detat-form',
        mixins: [ CorpsDetatMixins ],
        components:{
            VgModal,
            VgButton,
            VgInput,
            DialogSecuredAction,
            VgTiersSelector
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "corpsDetats-form-cancel": "Annuler",
            "corpsDetats-form-save": "Enregistrer",
            "corpsDetats-form-update": "Modifier",
            "corpsDetats-form-add": "Ajouter un corps d'état",
            "corpsDetats-form-delete": "Supprimer"
        },
        "en": {
            "corpsDetats-form-cancel": "Cancel",
            "corpsDetats-form-save": "Save",
            "corpsDetats-form-update": "Update",
            "corpsDetats-form-add": "Add technical field",
            "corpsDetats-form-delete": "Delete"
        }
    }
},
        props: {
            /**
            * @model
            */
            value:{
                type: Object,
                default:function(){
                    return {};
                }
            }
        },
        data: function() {
            return {
                corpsDetat:{
                    "name" : '',
                    "userId"  : this.$app.appID
                },
                isModalConfirmDeleteOpened:false
            };
        },
        computed:{
            isAnUpdateCorpsDetatForm:function(){
                return this.value && this.value.hasOwnProperty('id');
            }
        },
        created: function() {
            Object.assign(this.corpsDetat, this.value);
        },
        methods: {
            handleUpdateCorpsDetat:function(){
                this.CorpsDetatsMixins_update(this.corpsDetat).then(()=>{
                    this.$emit("updated");
                    this.$emit("close");
                });

            },
            handleCreateCorpsDetat:function(){
                this.CorpsDetatsMixins_create([this.corpsDetat]).then(()=>{
                    this.$emit("created");
                    this.$emit("close");
                });
            },
            handleDeleteCorpsDetat:function(){
                this.CorpsDetatsMixins_delete(this.corpsDetat).then(()=>{
                    this.$emit("deleted");
                    this.$emit("close");
                });
            }
        },
        destroyed:function(){
            this.$store.dispatch("CorpsDetatsStore/setSelectedItem", {});
        }
    };
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-corpsDetats-form exemple:

    #### basic usage
    Comment here
    ```html static
          <vg-corpsDetats-form></vg-corpsDetats-form>
    ```

    ### Create form corpsDetats
    ```js
    new Vue({
      data(){
        return {}
      },
      template: `
        <div>
            <vg-corpsDetats-form>
            </vg-corpsDetats-form>

        </div>
      `
    })
    ```
    ### update form corpsDetats
    ```js
    new Vue({
      data(){
        return {
            corpsDetats:{id:1}
        }
      },
      template: `
        <div>
            <vg-corpsDetats-form v-model="corpsDetats">
            </vg-corpsDetats-form>

        </div>
      `
    })
    ```
</docs>
