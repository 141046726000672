<template>
	<vg-modal
		:height="'100vh'"
		:title="$t('title')"
		@close="$emit('close')"
	>
		<template #body>
			<div id="vg-hubspot-form-create">

			</div>
		</template>
		<template #footer>
			<vg-button :type="'default-info'" @click="$emit('close')">
				{{$t("close")}}
			</vg-button>
		</template>
	</vg-modal>

</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
    export default {
        name: 'vg-hubspot-form-create',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Nous contacter",
            "close": "Fermer"
        },
        "en": {
            "title": "Contact us",
            "close": "Close"
        },
        "th": {
            "title": "ติดต่อเรา",
            "close": "ปิด"
        }
    }
},
		components: {

			VgButton
		},
        data: function() {
            return {

            };
        },
		created: function(){
			hbspt.forms.create({
				portalId: "4502775",
				formId: "95b4f763-5b01-4d96-b57a-92fd41a3b562",
				target: "#vg-hubspot-form-create"
			});
		}
    };

</script>
<style lang="scss" scoped>

</style>
