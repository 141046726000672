<template>
	<vg-modal ref="mainContainer"
		:title="$t('modal-title')"
		:isSaveDisabled="isSaveUnable"
		@close="$emit('close')"
		@save="save">
		<template #body>
			<vg-input
                inputType="datetime-local"
                :title="$t('date')"
                :isRequiredValue="true"
				v-model="dateMouvement" />
			<vg-input
                inputType="number"
                :title="$t('quantite')"
                :isRequiredValue="true"
				v-model="quantite">
			</vg-input>
			<vg-input :title="$t('commentaire')" :inputType="'textarea'"
				v-model="commentaire">
			</vg-input>
		</template>
	</vg-modal>
</template>
<script>

	import moment from "moment";
import VgInput from "src/components/Vg/VgInput.vue";
	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-consommable-inventaire-modal',
		components: {
			VgInput
		},
		mixins:[ ConsommablesMixins ],
        props: {

        },
        data: function() {
            return {
				dateMouvement: moment().format("YYYY-MM-DD HH:mm"),
                quantite: 0,
				commentaire: null
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Ajustement inventaire",
            "quantite": "Quantité en stock",
            "commentaire": "Commentaire",
			"date": "Date ajustement"
        },
        "en": {
            "modal-title": "Inventory adjustment",
            "quantite": "Inventory quantity",
            "commentaire": "Comment",
			"date": "Adjustment date"
        },
        "th": {
            "modal-title": "การปรับสินค้าคงคลัง",
            "quantite": "ปริมาณสินค้าคงคลัง",
            "commentaire": "ความคิดเห็น"
        }
    }
},
		mounted: function(){
			this.quantite = this.consommable.stock.quantite;
		},
		methods: {
			save: function(){
				let stock = Object.assign({}, this.consommable.stock, {
					quantite: this.quantite,
					consommation: {
						user_id: this.$app.idUser,
						dateMouvement: this.dateMouvement,
						quantite: this.quantite,
						quantiteInitialeOrigin: this.consommable.stock.quantite,
						flag: Number(this.quantite)-Number(this.consommable.stock.quantite)<0?"inventaire-moins":"inventaire-plus",
						commentaire: this.commentaire
					}
				});
				this.ConsommablesMixins_updateStock(this.consommable, stock).then((datas)=>{
					this.$emit("save", datas);
				});
			},
		},
        computed: {
			...mapGetters({
	            consommable: 'ConsommablesStore/getSelectedItem'
	        }),
			isSaveUnable: function(){
				return !this.quantite || this.quantite==this.consommable.stock.quantite || !this.dateMouvement;
			}
		}
    };

</script>
<style scoped>

</style>
