<template>
    <div class="vg-group" :style="'height:'+height">
        <div class="title-group">
            <slot name="title">{{title}}</slot>
            <vg-button v-if="collapsable && !isCollapse" style="float:right;" :size="'sm'" :type="'grey'" @click="isCollapse=!isCollapse">{{isCollapse?$t("deployer"):$t("reduire")}}</vg-button>
        </div>
        <div class="container-group" v-show="!isCollapse">
            <slot></slot>
        </div>
        <div class="container-group info" v-show="isCollapse && placeholder">
            <vg-button :size="'sm'" :type="'grey'" style="width:100%;" @click="isCollapse=false;">{{placeholder}}</vg-button>
        </div>
    </div>
</template>

<script>
import VgButton from "src/components/Vg/VgButton.vue";
export default {
    name:"vg-group",
    components:{
        VgButton
    },
    props:{
        title:{
            type: String,
            default: ""
        },
        height:{
            type: String,
            default: ""
        },
        collapsable:{
            type: Boolean,
            default: false
        },
        placeholder:{
            type: String,
            default: null
        }
    },
    data: function(){
        return {
            isCollapse: false
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "reduire": "Réduire",
            "deployer": "Déployer"
        }
    }
}
}
</script>

<style lang="scss">
.vg-group{
    width: 100%;
    .title-group{
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #E8EAEE;
        font-weight: bold;
        padding: 0 10px 0 20px;
        font-size: 13px;
        width: 100%;
        img{
            margin-bottom:0;
            margin-right: 10px;
        }
        .vg-button{
            font-weight: normal;
        }
    }
    .container-group{
        padding:10px 5px ;
        padding-bottom: 0;
        height:calc(100% - 40px);
    }

    .container-group.info{
        font-size: 11px;
        text-align: center;
    }
}

</style>
