/**
 *
 *   Adress validator.
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
class AdressValidator {
    /**
     * @example 852 rue renaudière de vaux, Le Port 97420, La Réunion
     *          852 rue renaudière de vaux, Le Port 97420
     *          rue renaudière de vaux, Le Port 97420
     * @param {string}
     * @return {boolean}
     */
    static validate(adress){
        const regex = /(\d{1,})?([^0-9]{1,},)(?:\s+\S+[^0-9]{1,}) \d{1,}(?:,\s+\S+[^0-9]{1,})?/gm;
        const found = adress.match(regex);
        return found ? true : false;
    }
  
}
export default AdressValidator;