//Contrats store module

import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
getters.getContratsByIdTiers = (state)=>(idTiers)=>{
    return state.collection.filter((contrat)=>contrat.tiers_id==idTiers);
};
let actions = Base.getActions();
let mutations = Base.getMutations();
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
