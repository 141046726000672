<template>
	<span >
		#{{$t("numero")}} - {{intervention.numeroIntervention}}
	</span>
</template>
<script>
    export default {
        name: 'vg-intervention-numero-viewer',
    	props: {
            intervention: {
				type: Object,
				required: true
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "numero": "IE"
        },
        "en": {
            "numero": "IN"
        },
        "th": {
            "numero": "ใน"
        }
    }
},
        data: function() {
            return {

            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>

</style>
