<template>
	<span :class="['vg-new-badge',{'negative':negativeColor}]">{{$t("new")}}</span>
</template>
<script>

    export default {
        name: 'vg-new-badge',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "new": "new"
        },
        "en": {
            "new": "new"
        }
    }
},
		components: {

		},
        props: {
			negativeColor:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {

            };
        },
		methods: {

		}
    };

</script>
<style lang="scss" scoped>
.vg-new-badge{
    display: inline-block;
	background-color: #3999ff;
	position: relative;
	color: white;
	font-size: 11px;
	padding: 0 5px;
	margin-left:0;
}
.negative{
	display: inline;
	background-color: white;
	color: #3999ff;
	font-weight: bold;
}
@media screen and (max-width: 991.98px) {
	.vg-new-badge{
		margin-left:5px;
	}
}
</style>
