<template>
    <div>
        <vg-modal :title="$t('title',{name:tiers.name})"
            @close="$emit('close')">
            <template v-slot:body>
                <span>{{$t("description")}}</span>
            </template>
            <template v-slot:footer>
                <vg-button
                    @click="$emit('close')">
                    {{ $t("non") }}
                </vg-button>
                <vg-button
                    :type="'success'"
                    @click="creerSousTraitant">
                    {{ $t("oui") }}
                </vg-button>
            </template>
        </vg-modal>
        <vg-user-form v-if="showModalCreateUser"
            v-model="user"
            @close="showModalCreateUser=false;user=null;"
            @created="onCreatedUser">
        </vg-user-form>
    </div>
</template>
<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgUserForm from "src/components/Vg/Forms/VgUserForm.vue";
export default {
    name: 'vg-sous-traitant-form',
    components:{
        
        VgButton,
        VgUserForm
    },
    mixins: [],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Voulez-vous authoriser l'accès au tiers {name}?",
            "description": "Ce tiers pourra se connecter à Verifgood avec ses propres identifiants.",
            "oui": "Oui",
            "non": "Non"
        },
        "en": {
            "title": "Give access to third parties {name}?",
            "description": "Third parties can access Verifgood with their ID",
            "oui": "Yes",
            "non": "No"
        }
    }
},
    props: {
        /**
        * tiers
        */
        tiers:{
            type: Object,
            default:function(){
                return {};
            },
            required: true
        }
    },
    data: function() {
        return {
            showModalCreateUser: false,
            user: {
                tiers_id: this.tiers.id,
                roles: "ROLE_SOUS_TRAITANT"
            }
        };
    },
    computed:{

    },
    created: function() {

    },
    methods: {
        creerSousTraitant: function(){
            this.showModalCreateUser = true;
        },
        onCreatedUser: function(e){
            this.$emit("close");
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-sous-traitant-form
    demande d'authorisation d'accès au tiers

    #### basic usage
    ```html static
        <vg-sous-traitant-form v-if="showSousTraitantModal"
            :tiers="tiers"
            @close="showSousTraitantModal=false;"
            @oui="showUserModal=true">
        </vg-sous-traitant-form>
    ```
</docs>
