<template>
    <span v-if="params.data.hasOwnProperty('categorie') && !params.data.categorie.isGe">{{ $t("equipement") }} {{ params.data.categorie.libelleCatgorie }}</span>
    <span v-else-if="params.data.hasOwnProperty('categorie') && params.data.categorie.isGe">{{ $t("lieux") }} {{ params.data.categorie.libelleCatgorie }}</span>
    <span v-else>{{ $t("lieux") }} {{ params.data.libelleCatgorie }}</span>
</template>
<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"equipement-lieu-categorie-cell-render",
    mixins:[LangMixins],
    components:{
        
    },
	props: {
		params: {
			type: Object,
			required: true
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "equipement": "équipement",
            "lieux": "lieux"
        }
    }
},
    methods:{
        
    },
    computed:{
        
    }
});
</script>

<style lang="scss" scoped>

</style>
