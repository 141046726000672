<template>
<div>
    <vg-modal
        :width="'800px'"
        class="vg-lieu-modal"
        :title="$t('modal-title')"
        @close="$emit('close')">
        <template #body>
            <form ref="equipement-generique-form"
                onSubmit="return false;">
                <vg-input
                    :title="$t('famille-lieu')">
                    <vg-lieu-famille-selector v-model="famille"
                        onlyExisting
                        @set-predefined-equipements="onSetPredefinedEquipements">
                    </vg-lieu-famille-selector>
                </vg-input>
                <br>
                <vg-input v-if="famille"
                    :title="$t('equipements-a-creer')">
                    <div v-if="listeDesEquipementsACreer && listeDesEquipementsACreer.length>0">
                        <div class="flex-row" v-for="(equipement, index) in listeDesEquipementsACreer">
                            <div class="flex-item-main">
                                <vg-input v-model="equipement.libel_equipement">
                                </vg-input>
                                <vg-categorie-selector
                                    v-model="equipement.categorie.libelleCatgorie"
                                    :isGe="false"
                                    :attributeValue="null"
                                    :clearable="false">
                                </vg-categorie-selector>
                            </div>
                            <vg-button class="margin-left-5"
                                type="default-danger"
                                size="sm"
                                @click="enleverEquipementACreerDeLaListe(piece)">
                                <i class="far fa-trash-alt"></i>
                            </vg-button>
                        </div>
                    </div>
                    <div v-else style="text-align:center;color:#9e9da9;">
                        <span>{{$t("aucun-equipement-a-creer")}}</span>
                    </div>
                </vg-input>
                <hr v-if="famille">
                <div class="flex-row" v-show="siteId || (!siteId && famille)">
                    <vg-input
                        :title="$t('libel-equipement')"
                        v-model="libel_equipement">
                    </vg-input>
                    <vg-input :title="$t('categorie-equipement')" class="margin-left-5">
                        <vg-categorie-selector
                            v-model="categorie"
                            :isGe="false"
                            :attributeValue="null">
                        </vg-categorie-selector>
                    </vg-input>
                    <vg-button
                        :type="'success'"
                        class="hackBtnAjout margin-left-5"
                        @click="AjouterEquipementsACreerDansLaListe">
                        <i class="fas fa-check"></i>
                    </vg-button>
                </div>
                <input
                  v-show="false"
                  ref="equipement-generique-submit"
                  type="submit"
                >
            </form>
        </template>
        <template #footer>
            <vg-button
                type="default"
                @click="$emit('close')">
                {{ $t("lieu-form-cancel") }}
            </vg-button>
            <vg-button
                type="success"
                @click="siteId?submitForm:showConfirm=true"
                :disabled="!siLaListeDesEquipementsACreerNestPasVide">
                {{ $t("lieu-form-save") }}
            </vg-button>
            <vg-modal v-if="showConfirm"
                :title="$t('creation-confirm-title')"
                @save="submitForm"
                @close="showConfirm=false;">
                <template #body>
                    {{$t("creation-confirm-body",{ nb: nbEquipementsGlobauxACreer, famille: famille })}}
                </template>
            </vg-modal>
        </template>
    </vg-modal>
</div>
</template>
<script>
import equipementsMixins from "src/mixins/equipementsMixins.js";

import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";

import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'vg-equipements-generique-form',
    components:{
        VgSelect,
        VgButton,
        VgCheckbox,
        VgInput,
        VgCategorieSelector,
        VgLieuFamilleSelector
    },
    mixins: [ equipementsMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Création d'équipements globaux",
            "equipements-a-creer": "Equipements à créer",
            "creation-confirm-title": "Confirmer la création des équipements globaux",
            "creation-confirm-body": "Voulez-vous créer ces {nb} équipements globaux pour tous les sites de la famille {famille} ?",
            "aucun-equipement-a-creer": "Aucun équipement global à créer",
            "famille-lieu": "Famille de site",
            "lieu-form-save": "Enregistrer",
            "lieu-form-cancel": "Annuler",
            "libel-equipement": "Libellé équipement",
            "categorie-equipement": "Catégorie"
        },
        "en": {
            "modal-title": "Creation of global equipment",
            "equipements-a-creer": "Equipment to be created",
            "creation-confirm-title": "Confirm the creation of global equipment",
            "creation-confirm-body": "Do you wish to create {nb} global equipment for all family sites {famille} ?",
            "aucun-equipement-a-creer": "No global equipment to create",
            "famille-lieu": "Site family",
            "lieu-form-save": "Save",
            "lieu-form-cancel": "Cancel",
            "libel-equipement": "Equipment label",
            "categorie-equipement": "Category"
        }
    }
},
    props: {

    },
    data: function() {
        return {
            famille: null,
            categorie:null,
            libel_equipement:"",
            listeDesEquipementsACreer:[],
            showConfirm: false
        };
    },
    computed:{
        siLaListeDesEquipementsACreerNestPasVide:function(){
            return this.listeDesEquipementsACreer && this.listeDesEquipementsACreer.length > 0;
        },
        nbEquipementsGlobauxACreer: function(){
            return this.listeDesEquipementsACreer.length;
        },
    },
    created:function(){

    },
    methods: {
        onSetPredefinedEquipements: function(equipements){
            console.log("SET PREDEFINED EQUIPEMENTS", equipements);
            this.listeDesEquipementsACreer = equipements;
        },
        AjouterEquipementsACreerDansLaListe:function(){
            console.log("LISTE DES EQS", this.listeDesEquipementsACreer);
            if(!(this.categorie && this.libel_equipement)) return;
            this.listeDesEquipementsACreer.push(Object.assign({},{libel_equipement:this.libel_equipement,categorie:this.categorie}));
            this.libel_equipement = "";
        },
        /**
        * @param Object piece
        */
        enleverEquipementACreerDeLaListe:function(equipement){
            this.listeDesEquipementsACreer.splice(this.listeDesEquipementsACreer.indexOf(equipement), 1);
        },
        submitForm:function(){
            this.$refs['equipement-generique-submit'].click();
            this.isDisabledUi = true;
            this.showConfirm = false;
            console.log("LISTE DES EQS A CREER", this.listeDesEquipementsACreer);
            this.equipementsMixins_createEquipementsGlobauxFamilleSite(this.famille, this.listeDesEquipementsACreer).then((equipements)=>{
                this.isDisabledUi = false;
                this.$emit("save", equipements);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
    .flex-row{
        width: 100%;
        display:flex;
        overflow-wrap: anywhere;
        justify-content:space-between;
        margin-bottom:15px;
        align-items: center;
        .flex-item-main{
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .vg-input-block{
                width: 60%;
                padding-right: 10px;
                margin-bottom: 0px !important;
            }
        }
    }
    .hackBtnAjout{
        margin-top: 15px;
    }
    .margin-left-5{
        margin-left: 5px;
    }
</style>
<docs>

    ### vg-equipements-generique-form
    Create

    #### basic usage

    ```html static
    <vg-equipements-generique-form
    >
    </vg-equipements-generique-form>
    ```
</docs>
