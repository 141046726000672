<template>
    <div class="vg-progression-tache-equipements-table">
        <tag-grid v-if="equipementsRestants"
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="equipementsRestants"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick" />
    </div>
</template>
<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import ZoomDashboardCellRender from "src/components/Vg/TagGrid/ZoomDashboardCellRender.vue";
import VerificationMixins from "src/mixins/VerificationMixins.js";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-progression-tache-equipements-table',
    components:{
        TagGrid
    },
    mixins:[ VerificationMixins ],
    props: {
        id:{
            type: String,
            default: "progression-tache-equipements-tableau"
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        },
        tache: {
            type: Object,
            required: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement des équipements à vérifier...",
                "overlay-no-rows-text": "Aucun équipement correspondant au filtrage"
            },
            "en": {
                "overlay-loading-text": "Loading equipments to verify...",
                "overlay-no-rows-text": "No equipment matching the filtering"
            }
        }
    },
    data: function() {
        return {
            agfilters: {
                libel_equipement: {attr: "e.libel_equipement", value: null, action: "contains", openParenthesis: true},
                qrcode: {attr: "e.qrCode", value: null, action: "contains", logicalOperator: "OR", closeParenthesis: true},
            },
            showLoadingOverlay: false,
            gridOptions: {
                headerHeight: 0,
                rowHeight: 100,
            },
            columnDefs: [
                {
                    headerName: "Libelle tache",
                    field: 'libel_equipement',
                    colId: 'libel_equipement',
                    suppressSizeToFit: false,
                    unSortIcon: true,
                    cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new ZoomDashboardCellRender({
                        propsData: {
                            params: params.data,
                            icon:this.focusedTache.categorie.icon
                        }
                    }).$mount().$el,
                },
                {
                    headerName: "id",
                    width: 180,
                    field: 'id',
                    colId: 'id',
                    hide: true,
                },
                {
                    headerName: "path",
                    width: 180,
                    field: 'path',
                    colId: 'path',
                    hide: true,
                },
                {
                    headerName: "qrCode",
                    width: 180,
                    field: 'qrCode',
                    colId: 'qrCode',
                    hide: true,
                },
                {
                    headerName: "isScanRequired",
                    width: 180,
                    field: 'isScanRequired',
                    colId: 'isScanRequired',
                    hide: true
                }
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.agfilters.libel_equipement.value = query;
                this.agfilters.qrcode.value = query;
                this.fetch();
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.agfilters = Object.assign({}, this.agfilters, newfilters);
                this.fetch();
            },
            deep: true
        }
    },
    created:function(){
        this.agfilters = Object.assign({}, this.agfilters, this.filters);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            focusedTache: "ProgressionsStore/getSelectedItem",
            equipementsRestants: "ProgressionsStore/getEquipementsRestants",
        })
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.agfilters);
            this.VerificationMixins_getTacheState(this.focusedTache, this.agfilters.path.value, this.metadatas).then((datas)=>{
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click", row);
            if(this.focusedTache.isScanRequired==0) this.$router.push({name:"_process_verification", params:{type:"equipement", equipementId:row.data.id, tacheId: this.focusedTache.id} });
            else alert(this.$t('progressionVerif.mobile.requiert-scan'));
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            if(this.$vgutils.isMobile()) this.handleDblClick(row);
            else this.$emit("row-click",row);
        },
    }
};
</script>
<style lang="scss" scoped>
.vg-progression-tache-equipements-table{
    height: 100%;
}
</style>