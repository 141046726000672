<template >

    <vg-app-layout
       :title="$t('parametres')"
       :icon="'fiche-de-maintenance.png'"
       :colorheader="getColorHeader"
       :isZoomLayout="true"
       :isActionButtonDisplay="false"
       @action-export="handleActionExport"
       @backpage="$router.go(-1)">

        <template #primary>
            <parametres-menu @activatesection="handleActivateSection"></parametres-menu>
        </template>

        <template #secondary>
            <parametres-utilisateurs v-if="isActivated('utilisateurs')" v-show="can('PARAMETRES.UTILISATEURS')"/>
            <parametres-entreprise v-if="isActivated('entreprise')" v-show="can('PARAMETRES.ENTREPRISE')"/>
            <parametres-maintenance v-if="isActivated('maintenance')" v-show="can('PARAMETRES.MAINTENANCE')"/>
            <parametres-lieux v-if="isActivated('lieux')" v-show="can('PARAMETRES.LIEUX')"/>
            <parametres-tags v-if="isActivated('tags')" v-show="can('PARAMETRES.ETIQUETTES')"/>
            <parametres-bon-de-commande v-if="isActivated('bon-de-commande')" v-show="can('PARAMETRES.BONS_DE_COMMANDE')"/>
            <parametres-corps-detat v-if="isActivated('corps-detat')" v-show="can('PARAMETRES.CORPS_D_ETAT')"/>
            <parametres-equipements v-if="isActivated('equipements')" v-show="can('PARAMETRES.EQUIPEMENTS')"/>
            <parametres-integrations v-if="isActivated('integrations')" />
        </template>
   </vg-app-layout>
</template>

<script>
import ParametresMenu from './components/ParametresMenu.vue';
import ParametresEntreprise from './components/ParametresEntreprise.vue';
import ParametresUtilisateurs from './components/ParametresUtilisateurs.vue';
import ParametresMaintenance from './components/ParametresMaintenance.vue';
import ParametresLieux from './components/ParametresLieux.vue';
import ParametresTags from './components/ParametresTags.vue';
import ParametresIntegrations from './components/ParametresIntegrations.vue';
import ParametresBonDeCommande from './components/ParametresBonDeCommande.vue';
import ParametresCorpsDetat from './components/ParametresCorpsDetat.vue';
import ParametresEquipements from './components/ParametresEquipements.vue';


export default {
    name: 'parametres',
    mixins:[],
	components: {
        ParametresMenu,
        ParametresEntreprise,
        ParametresUtilisateurs,
        ParametresMaintenance,
        ParametresLieux,
        ParametresTags,
        ParametresIntegrations,
		ParametresBonDeCommande,
        ParametresCorpsDetat,
        ParametresEquipements
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "parametres": "Paramètres"
        },
        "en": {
            "parametres": "Parameters"
        },
        "th": {
            "parametres": "พารามิเตอร์"
        }
    }
},
    data:function() {
        return {
            activate: "utilisateurs"
        };
    },
    methods: {
        /**
        * Show current section.
        * @param string section entreprise|utilisateurs etc..
        */
        handleActivateSection: function(section){
            this.activate = section;
		},
        /**
        * Validate active section.
        * @param string section entreprise|utilisateurs etc..
        * @return boolean
        */
		isActivated: function(section){
            return this.activate==section;
		}
    },
    computed:{
        getColor:function(){
            return this.$vgutils.isMobile() ? "#ffffff" : "black";
        },
        getColorHeader:function(){
            return this.$vgutils.isMobile() ? "#429fff" : "white";
        }
    },
    created:function(){
        if(!this.can("PARAMETRES.PAGE")){
            this.$router.go(-1);
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
