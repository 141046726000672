<template>
	<!-- <vg-input v-model="inputValue"
		:title="label"
		:inputType="'number'"
		:min="1">
	</vg-input> -->
	<div class="vg-Working-Time">
		<label> {{label}}</label>
		<vg-input-number v-model="inputValue" :min="0"></vg-input-number>
	</div>

</template>
<script>
	import VgInputNumber from "src/components/Vg/VgInputNumber.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
    export default {
        name: 'vg-working-time-selector',
		components: {
			VgInput,
			VgInputNumber
		},
        props: {
			label: {
				type: String,
				default: "no label"
			},
			value: {
				type: Number,
				default: 15
			}
        },
		watch:{
			inputValue: function(newvalue){
				this.emitInput(newvalue);
			}
		},
        data: function() {
            return {
				inputValue: this.value/15
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			emitInput: function(newvalue){
				this.$emit("input", newvalue*15);
			}
		},
        computed: {

		}
    };

</script>
<style scoped>
	.vg-Working-Time{
		float: left;
	    width: 100%;
		margin-bottom: 10px;
	}
</style>
