<template>

    <vg-modal
        :title="$t('title')"
        :isSaveDisabled="!file"
    >

        <template #body>
            <div class="body-header">
                <vg-equipement-viewer :equipement="equipement" />
            </div>
            <form v-if="!showValidation"
                ref="sortie-form"
                onSubmit="return false;" >
                <vg-input :title="$t('form-commentaire')"
                    v-model="sortie.commentaire"
                    :inputType="'textarea'" />

                <vg-input :title="$t('form-document')"
                    isRequiredValue>
                    <vg-files-uploader
                        :tagsList="VgFilesMixins.tags"
                        :isUploadOnFileChange="false"
                        @file-change="handleFileChange" />
                </vg-input>

                <vg-input :title="$t('remplacer-par')" v-if="equipementRemplacant">
                    <vg-equipement-viewer :equipement="equipementRemplacant" />
                </vg-input>

                <vg-equipement-form v-if="showNewEquipement"
                    :idLieu="equipement.lieux_id"
                    :reloadAfterSave="false"
                    @close="showNewEquipement=false;"
                    @save="onSaveNewEquipement" />

                <input v-show="false" type="submit" ref="sortie-submit" />
            </form>
            <vg-files-progress-upload-bar v-if="VgFilesMixins.uploadState"
    			:transferredBytes="VgFilesMixins.uploadState.bytesTransferred"
    			:totalBytes="VgFilesMixins.uploadState.totalBytes" />
        </template>
        <template #footer>
            <vg-button
                :type="'default'"
                @click="$emit('close')">
                {{$t("cancel")}}
            </vg-button>
            <vg-button v-if="!isDisabledUi"
                :type="'success'"
                @click="onSaveSortie">
                {{$t("valider")}}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>

import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFilesProgressUploadBar from "src/components/Vg/Files/VgFilesProgressUploadBar.vue";
import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";

export default {
    name:'vg-equipement-remplacement-form',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Remplacer équipement",
            "title-validation": "Equipement remplaçant",
            "form-commentaire": "Commentaire",
            "form-document": "Document",
            "form-equipement": "Equipement remplaçant",
            "remplacer-par": "Remplacement de l'équipement par",
            "remplacement": "Remplacement",
            "valider": "Valider",
            "cancel": "Annuler"
        },
        "en": {
            "title": "Replace equipment",
            "title-validation": "Confirm removal",
            "form-commentaire": "Commentary",
            "form-document": "Document",
            "form-equipement": "Replacement equipment",
            "remplacer-par": "Replace the equipment with",
            "remplacement": "Replacement",
            "valider": "Validate",
            "cancel": "Cancel"
        }
    }
},
    props:{
        equipement:{
            type: Object,
            required: true
        },
        maintenance:{
            type: Object,
            default: function(){
                return null;
            }
        }
    },
    mixins:[VgFilesMixins, equipementsMixins],
    components:{
        VgInput,
        VgSelect,
        VgButton,
        VgEquipementViewer,
        VgFilesUploader,
        VgLieuPathViewer,
        VgFilesProgressUploadBar,
        VgEquipementForm
    },
    data:function(){
        return{
            showNewEquipement: false,
            sortie: {
                type: this.$t("remplacement"),
                dateSortie: moment().format("YYYY-MM-DD HH:mm:ss"),
                commentaire: "",
                equipement_id: this.equipement.id,
                equipementRemplacant_id: null,
                userId: this.$app.appID,
                user_id: this.$app.idUser
            },
            file: null,
            equipementRemplacant: null,
            isDisabledUi: false
        }
    },
    created:function(){
        this.VgFilesMixins_init(null, "sortieequipement");
    },
    methods:{
        onSaveNewEquipement: function(equipements){
            this.equipementRemplacant = Object.assign({}, {}, equipements[0]);
            this.sortie.equipementRemplacant_id = this.equipementRemplacant.id;
            this.showNewEquipement = false;
        },
        remplacerEquipement: function(){
            this.isDisabledUi = true;
            this.equipementsMixins_remplacerEquipement(this.sortie, this.maintenance).then((reponse)=>{
                this.VgFilesMixins_setUid(reponse.sortieEquipement.uid);
                this.VgFilesMixins_uploadFile(this.file.file, this.file.idTag).then((document)=>{
                    this.$emit("save", {maintenance: reponse.maintenance, sortieEquipement: reponse.sortieEquipement});
                    this.isDisabledUi = false;
                });
            });
        },
        onSaveSortie:function(){
            if(!this.showNewEquipement && !this.equipementRemplacant){
                this.$refs["sortie-submit"].click();
                this.isDisabledUi = true;
                let form = this.$refs['sortie-form'];
                if(form.checkValidity() && this.sortie.type && this.file){
                    this.equipementsMixins_getEquipement(this.equipement.id).then((equipements)=>{
                        let eq = equipements[0];
                        let equipementRemplacant = {
                            userId: this.$app.appID,
                            idLieu_id: eq.idLieu_id,
                            idCategorie_id: eq.idCategorie_id,
                            //libel_equipement: eq.libel_equipement,
                            carac_techniques: eq.carac_techniques,
                            refConstructeur: eq.refConstructeur,
                            valeurAchat: eq.valeurAchat,
                            dureeAmortissement: eq.dureeAmortissement,
                            marque: eq.marque,
                            fournisseur_id: eq.fournisseur_id,
                            tauxDepreciationAnnuel: eq.tauxDepreciationAnnuel,
                            numeroImmobilisation: eq.numeroImmobilisation,
                            ReferenceDocumentExterne: eq.ReferenceDocumentExterne
                        };
                        this.$store.dispatch("EquipementsStore/setSelectedItem", equipementRemplacant);
                        this.showNewEquipement = true;
                        this.isDisabledUi = false;
                    });
                }else{
                    this.isDisabledUi = false;
                }
                return true;
            }else{
                this.remplacerEquipement();
            }
        },
        handleFileChange: function(data){
            this.file = data;
        }
    },
    computed:{

    }
}
</script>

<style lang="scss" scoped>
.body-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.body-content-validation{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sortie-equipement-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #a782b2;
    }
}
</style>
