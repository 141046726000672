<template>
<div>
    <vg-modal
        :width="'800px'"
        class="vg-lieu-modal"
        :title="$t('liste-des-pieces-a-creer')"
        @close="$emit('close')">
        <template v-slot:body>
            <form ref="piece-generique-form"
                onSubmit="return false;">
                <vg-input v-if="!siteId"
                    :title="$t('famille-lieu')">
                    <vg-lieu-famille-selector v-model="famille"
                        onlyExisting
                        @set-predefined-pieces="onSetPredefinedPieces">
                    </vg-lieu-famille-selector>
                </vg-input>
                <vg-input v-if="famille"
                    :title="$t('pieces-a-creer')">
                    <div v-if="listeDesPiecesACreer && listeDesPiecesACreer.length>0">
                        <div class="flex-row" v-for="(piece, index) in listeDesPiecesACreer">
                            <div class="flex-item-main">
                                <vg-input v-model="piece.libel_lieu">
                                </vg-input>
                                <vg-categorie-selector
                                    isGe
                                    v-model="piece.categorie.libelleCatgorie"
                                    :isGe="true"
                                    :attributeValue="null"
                                    :clearable="false">
                                </vg-categorie-selector>
                            </div>
                            <vg-button class="margin-left-5"
                                type="default-danger"
                                size="sm"
                                @click="enleverPieceACreerDeLaListe(piece)">
                                <i class="far fa-trash-alt"></i>
                            </vg-button>
                        </div>
                    </div>
                    <div v-else style="text-align:center;color:#9e9da9;">
                        <span>{{$t("aucune-piece-generique-a-creer")}}</span>
                    </div>
                </vg-input>
                <hr v-if="famille">
                <div v-if="listeDesPiecesACreer && listeDesPiecesACreer.length!=0">
                    <div class="flex-row" v-for="piece in listeDesPiecesACreer" :key="piece.libel_lieu">
                        <span>
                            <b>{{piece.libel_lieu}}</b>
                            <small style="margin-left:10px;">{{piece.categorie.libelleCatgorie}}</small>
                        </span>
                        <vg-button :type="'grey'" :size="'sm'" @click="enleverPieceACreerDeLaListe(piece)"><i class="fas fa-times"></i></vg-button>
                    </div>
                </div>
                <div class="flex-row" v-show="siteId || (!siteId && famille)">
                    <vg-input
                        :title="$t('libel-lieu')"
                        v-model="libel_lieu">
                    </vg-input>
                    <vg-input :title="$t('categorie-lieu')" class="margin-left-5">
                        <vg-categorie-selector
                            isGe
                            v-model="categorie"
                            :isGe="true"
                            :attributeValue="null">
                        </vg-categorie-selector>
                    </vg-input>
                    <vg-button
                        :type="'success'"
                        class="hackBtnAjout margin-left-5"
                        @click="AjouterPiecesACreerDansLaListe">
                        <i class="fas fa-check"></i>
                    </vg-button>
                </div>

                <input
                  v-show="false"
                  ref="piece-generique-submit"
                  type="submit"
                >
            </form>
        </template>
        <template v-slot:footer>
            <vg-button
                type="default"
                @click="$emit('close')">
                {{ $t("lieu-form-cancel") }}
            </vg-button>
            <vg-button
                type="success"
                @click="onClickSaveButton"
                :disabled="!siLaListeDesPiecesACreerNestPasVide">
                {{ $t("lieu-form-save") }}
            </vg-button>
            <vg-modal v-if="showConfirm"
                :title="$t('creation-pieces-confirm-title')"
                @save="submitForm"
                @close="showConfirm=false;">
                <template v-slot:body>
                    {{$t("creation-pieces-confirm-body",{ nb: nbPiecesGeneriquesACreer, famille: famille })}}
                </template>
            </vg-modal>
        </template>
    </vg-modal>
</div>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins.js";

import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";

import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'vg-pieces-generique-form',
    components:{
        VgSelect,
        VgButton,
        VgCheckbox,
        VgInput,
        VgCategorieSelector,
        VgLieuFamilleSelector
    },
    mixins: [ LieuMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "lieu-form-cancel": "Annuler",
            "lieu-form-save": "Ajouter ces pièces",
            "liste-des-pieces-a-creer": "Création de pièces génériques",
            "categorie-lieu": "Catégorie",
            "lieu-form-update": "Modification",
            "lieu-form-delete": "Supprimer",
            "lieu-form-add": "Ajouter",
            "dans": "dans",
            "lieu-form-add-pieces": "Ajouter des pièces dans ",
            "Site": "Site",
            "Batiment": "Batiment",
            "Etage": "Etage",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "Confirmer la suppression",
            "lieu-form-unauthorize-title": "Impossible de supprimer",
            "lieu-form-unauthorize-message": "contient {nbEquipement} équipement(s)",
            "libel-lieu": "Libellé du lieu",
            "famille-lieu": "Famille de site",
            "adresse": "Adresse",
            "fonction": "Fonction",
            "service": "Service",
            "categorie": "Catégorie du lieu",
            "add-piece": "Saisir une autre pièce",
            "remove-piece": "Enlever cette pièce",
            "pieces-a-creer": "Pièces à créer",
            "creation-pieces-confirm-title": "Confirmer la création des pièces génériques",
            "creation-pieces-confirm-body": "Voulez-vous créer ces {nb} pièces génériques sur tous les sites de la famille {famille} ?",
            "aucune-piece-generique-a-creer": "Aucune pièce générique à créer"
        },
        "en": {
            "lieu-form-cancel": "Cancel",
            "lieu-form-save": "Add these rooms",
            "categorie-lieu": "category",
            "lieu-form-update": "Update",
            "lieu-form-delete": "Delete",
            "lieu-form-add": "Add",
            "dans": "In",
            "lieu-form-add-pieces": "Add room in",
            "Site": "Site",
            "Batiment": "Building",
            "Etage": "Floor",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "Confirm removal",
            "lieu-form-unauthorize-title": "Unathorized removal",
            "lieu-form-unauthorize-message": "Contains {nbEquipement} equipment(s)",
            "libel-lieu": "Location name",
            "adresse": "Address",
            "fonction": "Position",
            "service": "Service",
            "categorie": "Location category",
            "add-piece": "Add room",
            "remove-piece": "Remove room",
            "liste-des-pieces-a-creer": "Generic parts creation",
            "famille-lieu": "Site family",
            "pieces-a-creer": "Parts to create",
            "creation-pieces-confirm-title": "Confirm the creation of generic parts",
            "creation-pieces-confirm-body": "Do  you wish to create {nb} generic parts on all family sites {famille} ?",
            "aucune-piece-generique-a-creer": "No generic parts to create"
        },
        "th": {
            "lieu-form-cancel": "ยกเลิก",
            "lieu-form-save": "บันทึก",
            "lieu-form-update": "อัปเดต",
            "lieu-form-delete": "ลบ",
            "lieu-form-add": "เพิ่ม",
            "dans": "ใน",
            "lieu-form-add-pieces": "เพิ่มห้องใน",
            "Site": "หน้างาน",
            "Batiment": "สิ่งปลูกสร้าง",
            "Etage": "พื้น",
            "lieu-form-lieu": "Lieu",
            "lieu-form-confirm-suppression": "ยืนยันการลบ",
            "lieu-form-unauthorize-title": "การลบที่ไม่ได้รับอนุญาต",
            "lieu-form-unauthorize-message": "มีอุปกรณ์ {nbEquipement} ชิ้น",
            "libel-lieu": "ชื่อที่ตั้ง",
            "adresse": "ที่อยู่",
            "fonction": "ตำแหน่ง",
            "service": "บริการ",
            "categorie": "หมวดหมู่ที่ตั้ง",
            "add-piece": "เพิ่มห้อง",
            "remove-piece": "ลบห้อง"
        }
    }
},
    props: {
        /**
        * Identifiant site.
        */
        siteId: {
            type: Object,
            default: null
        }
    },
    data: function() {
        return {
            famille: null,
            categorie:null,
            libel_lieu:"",
            listeDesPiecesACreer:[],
            showConfirm: false
        };
    },
    computed:{
        /**
        * @return boolean
        */
        siLaListeDesPiecesACreerNestPasVide:function(){
            return this.listeDesPiecesACreer.length > 0;
        },
        nbPiecesGeneriquesACreer: function(){
            return this.listeDesPiecesACreer.length;
        },
    },
    created:function(){

    },
    methods: {
        onSetPredefinedPieces: function(pieces){
            console.log("SET PREDEFINED PIECES", pieces);
            this.listeDesPiecesACreer = pieces;
        },
        AjouterPiecesACreerDansLaListe:function(){
            if(!(this.categorie && this.libel_lieu)) return;
            this.listeDesPiecesACreer.push(Object.assign({},{libel_lieu:this.libel_lieu,categorie:this.categorie}));
            this.libel_lieu = "";
        },
        /**
        * @param Object piece
        */
        enleverPieceACreerDeLaListe:function(piece){
            this.listeDesPiecesACreer.splice(this.listeDesPiecesACreer.indexOf(piece), 1);
        },
        onClickSaveButton: function(){
            if(this.siteId) this.submitForm();
            else this.showConfirm=true;
        },
        submitForm:function(){
            this.isDisabledUi = true;
            this.showConfirm = false;
            if(this.siteId){
                this.LieuMixins_createPiecesGeneriques(this.siteId, this.listeDesPiecesACreer).then((lieux)=>{
                    this.isDisabledUi = false;
                    this.$emit("save",lieux);
                });
            }else{
                this.LieuMixins_createPiecesGeneriquesFamilleSite(this.famille, this.listeDesPiecesACreer).then((lieux)=>{
                    this.isDisabledUi = false;
                    this.$emit("save", lieux);
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .flex-row{
        width: 100%;
        display:flex;
        overflow-wrap: anywhere;
        justify-content:space-between;
        margin-bottom:15px;
        align-items: center;
        .flex-item-main{
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .vg-input-block{
                width: 60%;
                padding-right: 10px;
                margin-bottom: 0px !important;
            }
        }
    }
    .hackBtnAjout{
        margin-top: 15px;
    }
    .margin-left-5{
        margin-left: 5px;
    }
</style>
<docs>

    ### vg-pieces-generique-form
    Create

    #### basic usage

    ```html static
    <vg-pieces-generique-form
         v-if="showPieceGeneriqueForm"
         :siteId="$route.params.id"
         @close="showPieceGeneriqueForm = false"
    >
    </vg-pieces-generique-form>
    ```
    ### Contrat Lieu object
    ```js
    var lieu = {

    }
    ```
</docs>
