//Notifications store module

// initial state
const state = {
    tfilters : [],
	references: {
		instances: {}, // { instance1: 0, instance2: 1, ... }
		fields: {} // { statut: 0, urgence: 1, filter1: 2, ... }
	}
};

// getters
const getters = {
    /**
    *
    *
    *
    */
    getFilters: ( state ) => {
		return state.tfilters;
    },
	getReferences: ( state ) => {
		return state.references;
	},
    
    /**
    *  récupère toutes les valeurs déterminé dans les filtre avec leur label associés
    *  @param  Object state
    *  @return Array liste d'objet contenant les valeurs label et value
    */
    getValues: ( state ) => {
        if(!state)return;
        var values = [];
        var lastObj = state.tfilters.length;
        
        for( var i=0 ; i < lastObj ; ++i ){
            var lastInput = state.tfilters[i].input.length;
            for( var y=0 ; y < lastInput ; ++y ){
                var fetchValues = {};
                var field = state.tfilters[i].input[y];
                    fetchValues.label = field.label;
                    
                    fetchValues.name = field.name;
                    fetchValues.component = field.component;
                    fetchValues.field = field.field;
                    fetchValues.defaultFilterType = field.defaultFilterType;
                    fetchValues.isExternable = field.isExternable;
                    fetchValues.instance = state.tfilters[i].filterInstance;
                    fetchValues.isClosable = field.isClosable ;
                    //AJOUTER proprité isClosable
                    ////// //console.log(field.isClosable);
                    
                    values.push( fetchValues );
                if( field.value ){
                    fetchValues.value = field.value ;
                }else{
                    fetchValues.value = null ;
                }
            }
        }
		//// //console.log("getValues -------------");
		//// //console.log(values);
        return values;
    }
};

// actions
const actions = {
    addFilters: function( context, filters ){
        context.commit( 'TFILTER_ADD_FILTERS', filters );
    },
    refreshFilters: function( context, filters, instance=null ){
        context.commit( 'TFILTER_REFRESH_FILTERS', filters, instance );
    },
    refreshField: function( context, field ){
        context.commit( 'TFILTER_REFRESH_FIELD', field );
    },
    resetFieldValue: function( context, field ){
        context.commit( 'TFILTER_RESET_FIELD', field );
    },
	refreshFields: function( context, fields ){
		context.commit( 'TFILTER_REFRESH_FIELDS', fields );
	},
    resetFields: function( context ){
        context.commit( 'TFILTER_RESET_FIELDS' );
    }
};

// mutations
const mutations = {
    TFILTER_RESET_FIELDS: function( state ){
        state.tfilters = [],
        state.references = {
            instances: {}, // { instance1: 0, instance2: 1, ... }
            fields: {} // { statut: 0, urgence: 1, filter1: 2, ... }
        }
    },
    TFILTER_ADD_FILTERS: function( state, filters ){
        var instanceFilter = state.tfilters.filter( function(filtre){
			return filtre.filterInstance == filters.filterInstance;
		});
		//// //console.log("filters", filters);
		/*if( !instanceFilter.length ){
			// //console.log("state.tfilters",state.tfilters);
            state.tfilters.push( filters );
			// //console.log("state.tfilters",state.tfilters);
            state.references.instances[filters.filterInstance] = state.tfilters.indexOf( filters );
            // //console.log("TFILTER_ADD_FILTERS set reference : "+filters.filterInstance+" = "+state.references.instances[filters.filterInstance]);
			// //console.log(state.tfilters[state.references.instances[filters.filterInstance]]);
            var input = state.tfilters[state.references.instances[filters.filterInstance]].input;
			// //console.log(input);
            for(var index in input){
                state.references.fields[input[index].name] = index; 	// ATTENTION si nom pas unique à travers différentes instances
				//// //console.log("TFILTER_ADD_FILTERS set reference : "+input[index].name+" = "+index);
            }
            return ;
        }else{
            //// //console.log('-------------------------FILTERS--------------------------');
            // //console.log(instanceFilter);
			// à implémenter : instance existe, et le filtre existe dans le input ou n'existe pas : dans tous les cas il faut le créer ou le remplacer.
			
        }*/
		if( instanceFilter.length==0 ){
            state.tfilters.push( filters );
            state.references.instances[filters.filterInstance] = state.tfilters.indexOf( filters );
		}else{
			if(!(filters.filterInstance in state.references.instances)){
				var refInstance = state.tfilters.indexOf( instanceFilter );
				state.references.instances[filters.filterInstance] = refInstance;
			}
            
            //Attention on met cette  instruction en commentaire on ne sait pas trop pourquoi elle est la
            //mais le but je penses était : donner la possibilité d'ajouter des filtres à la volé et mettre a jour le bloc d'input dans l'instance en cours
            //JAIO TROUVER LA RAISON DE SA PRESENCE : quand on utilise tfilter en mode single template il faut ajouter les filtre dans le store et non overiider l'objet pour lfilters.ionstance
            if( state.tfilters[state.references.instances[filters.filterInstance]].input.filter(current=>current.name == filters.input[0].name).length == 0 ){
                state.tfilters[state.references.instances[filters.filterInstance]].input.push(filters.input[0]) ;
            }
		}
		var input = state.tfilters[state.references.instances[filters.filterInstance]].input;
		for(var index in input){
			state.references.fields[input[index].name] = index; 	// ATTENTION si nom pas unique à travers différentes instances
		}
        
		return ;
    },
    TFILTER_REFRESH_FILTERS: function( state, filters ){
		state.tfilters = filters;
		return state.tfilters;
    },
    TFILTER_REFRESH_FIELD : function( state, field ){
		var index = field.index;
        var foundField = state.tfilters.filter( gfilter => gfilter.filterInstance == field.instance );
        foundField[0].input[index].value = field.value;
        
    },
	TFILTER_REFRESH_FIELDS : function( state, fields ){
		// //console.log("TFILTER_REFRESH_FIELDS ------------");
        
		var instance = fields.instance;
		// //console.log("instance",instance);
		delete fields.instance;
        var selectedinstance = state.tfilters[state.references.instances[instance]];
		// //console.log("selectedinstance",selectedinstance);
		var fields_references = state.references.fields;
		// //console.log("fields_references",fields_references);
		for(var name in fields){
			selectedinstance && selectedinstance.input[fields_references[name]] ? selectedinstance.input[fields_references[name]].value = fields[name] : null;
		}
    },
	TFILTER_REFRESH_FIELDS_B : function( state, fields ){
		//// //console.log("TFILTER_REFRESH_FIELDS ------------");
        if(!fields.length)  return null  ;
		var instance = fields[0].instance;
        var index = fields[0].index;
        var value = fields[0].value;
		//// //console.log(instance);
		delete fields[0].instance;
        var selectedinstance = state.tfilters[state.references.instances[instance]];
		// //console.log(value);
		var fields_references = state.references.fields;
        state.tfilters[state.references.instances[instance]].input[index].value = value;
		//// //console.log(fields_references);
        //// //console.log(fields);
		/*for(var name in fields[0]){
			// //console.log(name);
            // //console.log(fields_references);
			// //console.log(fields_references[name]);
			//selectedinstance.input[name.index].value = fields[name];
		}*/
    },
    TFILTER_RESET_FIELD : function( state, field ){
		// //console.log("FIELD", field);
        var index = field.index;
		// //console.log("index",index);
        var foundField = state.tfilters.filter( gfilter => gfilter.filterInstance == field.instance );
        if(!foundField)return;
		// //console.log("foundField",foundField);
        var foundInput = foundField[0].input.filter( gfilter => gfilter.label == field.label );
        ////// //console.log(foundInput);
        //attention ça sent la bidouille
        foundInput[0].value = null;
    }
};

export default {
  state,
  getters,
  actions,
  mutations
};