<template>
    <div class="vg-bons-dentree-table">
        <tag-grid
            v-model="gridOptions"
            :idTableau="id"
            :columnDefs="columnDefs"
            :rowData="bonsDentree"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
            @ag-dbl-click="handleDblClick" />
        <vg-pagination v-if="paginate"
            :totalItems="getTotalItems"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange" />
    </div>
</template>
<script>
import BonsDentreeMixins from "src/mixins/BonsDentreeMixins";
import TagGrid from 'src/components/Grid/TagGrid.vue';

import VgPagination from "src/components/Vg/VgPagination.vue";

import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import FileLinkCellRender from "src/components/Vg/TagGrid/FileLinkCellRender.vue";
import BonDentreeCellRender from "src/components/Vg/TagGrid/BonDentreeCellRender.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-bons-dentree-table',
    components:{
        TagGrid,
        VgPagination
    },
    mixins:[ BonsDentreeMixins ],
    props: {
        id:{
            type: String,
            default: "bons-dentree-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * affiche la lsite des consommables sous forme de tableau
        */
        isTableau: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "overlay-loading-text": "Chargement des bons d'entrée...",
                "overlay-no-rows-text": "Aucun bon d'entrée correspondant au filtrage",
                "header-numero": "Numéro",
                "header-date-creation": "Date création",
                "header-fournisseur": "Fournisseur",
                "header-createur": "Créateur",
                "header-signataire": "Signataire",
                "header-commentaire": "Commentaire"
            },
            "en": {
                "overlay-loading-text": "Loading entry vouchers...",
                "overlay-no-rows-text": "No entry voucher matching filtering",
                "header-numero": "Number",
                "header-date-creation": "Creation date",
                "header-fournisseur": "Supplier",
                "header-createur": "Creator",
                "header-signataire": "Signatory",
                "header-commentaire": "Comment"
            }
        }
    },
    data: function() {
        return {
            showLoadingOverlay:false,
            gridOptions: {
                headerHeight: this.$vgutils.isMobile()?0:40,
                rowHeight: this.$vgutils.isMobile()?84:40
            },
            columnDefs: [
				{
					headerName: this.$t("header-date-creation"),
					field: 'dateCreation',
					colId: 'dateCreation',
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateCreation}}).$mount().$el
				},
                {
					headerName: this.$t("header-fournisseur"),
					field: 'fournisseur_id',
					colId: 'fournisseur_id',
                    cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.fournisseur_id?params.data.fournisseur.name:null,isMainColumn:false,
                        route: { router: this.$router, name: '_tiers_id', params: {id: params.data.fournisseur.uid } }}}).$mount().$el
				},
                {
					headerName: this.$t("header-numero"),
					field: 'numero',
					colId: 'numero'
				},
                {
					headerName: this.$t("header-createur"),
					field: 'createur_id',
					colId: 'createur_id',
                    cellRenderer: (params) => params.data.createur.nom+" "+params.data.createur.prenom
				},
                {
					headerName: this.$t("header-signataire"),
					field: 'signataire',
					colId: 'signataire'
				},
				{
					headerName: this.$t("header-commentaire"),
					field: 'description',
					colId: 'description'
				}
            ],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                console.log("WATCH FILTERS", newfilters);
                this.fetch();
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            });
            this.columnDefs.unshift({
                headerName: "",
                field:"",
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new BonDentreeCellRender({propsData: {params: params}}).$mount().$el
            });
        }
        if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
        this.fetch();
    },
    computed: {
        ...mapGetters({
            bonsDentree: 'BonsDentreeStore/getCollection',
            counters: 'BonsDentreeStore/getCounters'
        }),
        getTotalItems: function(){
            return this.counters.filtered;
        },
    },
    methods:{
        fetch: function(){
            this.showLoadingOverlay = true;
            this.metadatas.setFilters(this.filters);
            this.BonsDentreeMixins_getBonsDentree(this.metadatas).then((datas)=>{
                console.log("GET BONS DENTREE", datas);
                this.showLoadingOverlay = false;
            });
        },
        /**
        * Emit row-dbl-click on dblClick row.
        *
        * @param {Object} row
        * @event row-dbl-click
        */
        handleDblClick:function(row){
            this.$emit("row-dbl-click",row);
        },
        /**
        * Emit.
        *
        * @param Object row
        * @event row-click
        */
        handleClick:function(row){
            this.$emit("row-click",row);
            if(this.$vgutils.isMobile()) this.$emit("row-dbl-click",row);
        },
        handlePaginationChange: function(pagination){
            console.log("handlePaginationChange", pagination);
            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
            this.fetch();
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-bons-dentree-table{
    height: 100%;
    width: 100%;
}
</style>
