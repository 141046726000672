
<template>
	<div class="parametres-tags" v-if="tags">
		<span class="title">{{$t('title')}}</span>
        <vg-tabs
            :tabs="tabs"
            :color="'gris'"
            @tabClicked="handleTabClick" />
        <tags-parametres v-for="(tagType, index) in tagsTypeListe"
            style="margin-bottom: 20px; padding-bottom:20px; border-bottom: 1px solid whitesmoke;"
            :key="'existant-'+index"
            :tagType="tagType"
            :hasASeparation="false"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag" />
        <span>{{ $t("nouveau-set") }}</span>
        <tags-parametres
            :key="'new-'+lastTagTypeNumber"
            :tagType="'equipements/statut-verification/'+newTagTypeNumber"
            :hasASeparation="false"
            @save="handleInputConfirm"
            @update="openConfirmUpdateTag"
            @delete="openConfirmDeleteTag" />
        <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="deleteTag"
          @close="isModalConfirmDeleteOpened = false"
        >
			<template #header-title>
				{{$t('delete-tag')}} {{currentTagSelected.label}}
			</template>
        </dialog-secured-action>
        <vg-modal
            :title="$t('update')"
            v-if="isModalConfirmUpdateOpened && currentTagSelected"
            @save="updateTag"
            @close="handleCloseUpdateForm"
        >
            <template #body>
                <vg-input
                    :title="$t('label')"
                    v-model="currentTagSelected.label"
                />
            </template>
        </vg-modal>
	</div>
	<div v-else>
        
	</div>
</template>

<script>
	import TagsMixins from "src/mixins/TagsMixins.js";
	import TagsParametres from "src/views/Parametres/components/TagsParametres.vue";
    import Metadatas from "src/services/Metadatas.js";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
    import VgCredits from "src/components/Vg/Layout/Credits/VgCredits.vue";

    import { mapGetters } from 'vuex';

    export default {
        name: "parametres-equipements",
    	i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Personnalisation des équipements",
            "update": "Modifier",
            "delete-tag": "Supprimer le statut",
            "label": "Libellé",
            "tab-statut": "Sets statut équipement",
            "nouveau-set": "Nouveau set de statut"
        },
        "en": {
            "title": "Customize equipments",
            "update": "Update",
            "label": "Label",
            "delete-tag": "Delete status",
            "tab-statut": "Equipment status set",
            "nouveau-set": "New status set"
        }
    }
},
        components: {
			TagsParametres,
            VgTabs,
            DialogSecuredAction,
            VgInput,
			VgCredits
        },
		mixins:[ TagsMixins ],
        data() {
            return {
                activeTabName: "categorie/comptable",
                isModalConfirmDeleteOpened:false,
                isModalConfirmUpdateOpened:false,
                activeTabNameDocuments: "documents/equipement",
                currentTagSelected:null
            };
        },
        methods: {
			handleInputConfirm: function(e){
				var newtag = {
					label: e.value,
					type: e.type
				};
				this.TagsMixins_postTags([newtag]).then((data)=>this.fetchStatutsVerifications());
			},
			updateTag: function(){
				this.TagsMixins_putTag(this.currentTagSelected).then((data)=>{
                    this.fetchStatutsVerifications();
                    this.handleCloseUpdateForm();
                });
			},
			deleteTag:function(){
				this.TagsMixins_deleteTag(this.currentTagSelected).then((data)=>{
                    this.fetchStatutsVerifications();
                    this.isModalConfirmDeleteOpened = false;
                    this.handleCloseUpdateForm();
                });
			},
			openConfirmUpdateTag: function(data){
                this.isModalConfirmUpdateOpened = true;
                this.currentTagSelected = data.tag;
                return true;
			},
			openConfirmDeleteTag: function(data){
                this.isModalConfirmDeleteOpened = true;
                this.currentTagSelected = data.tag;
                return true;
			},
			fetchStatutsVerifications: function(){
				this.TagsMixins_get(new Metadatas());
			},
            handleTabClick: function(event) {
                this.activeTabName = event.name;
            },
            handleTabDocumentClick: function(event) {
                this.activeTabNameDocuments = event.name;
            },
            handleCloseUpdateForm:function(){
                this.isModalConfirmUpdateOpened = false;
                this.currentTagSelected = null;
            }
        },
        computed: {
            ...mapGetters({
                tags: 'TagsStore/getCollection'
            }),
            tabs: function(){
                return [
                    {label: this.$t('tab-statut'), name: 'equipement/status-verification/1'}
                ];
            },
            tagsStatutVerification: function(){
                return this.tags.filter((tag)=>tag.type.includes("equipements/statut-verification/"));
            },
            tagsTypeListe: function(){
                let tagsType = this.tagsStatutVerification.map((tag)=>tag.type);
                return tagsType.filter((type, index, self)=>self.indexOf(type)===index);
            },
            lastTagTypeNumber: function(){
                let tagsTypeNumbers = this.tagsTypeListe.map((type)=>type.split("/").pop());
                if(tagsTypeNumbers && tagsTypeNumbers.length!=0){
                    tagsTypeNumbers.sort();
                    return parseInt(tagsTypeNumbers.pop());
                }else{
                    return 0;
                }
            },
            newTagTypeNumber: function(){
                return this.lastTagTypeNumber+1;
            }
        },
		created: function(){
			this.fetchStatutsVerifications();
		}
    };
</script>
<style lang="scss" scoped>
.parametres-tags{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	*{
		width: 100%;
	}
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
}
</style>
