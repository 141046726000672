<template >
	<vg-app-layout
        :title="$t('title')"
        :icon="'date.png'"
		:isActionButtonDisplay="false">
		<template #header-top-left-complement>
			<vg-select
			    v-model="view"
			    :options="typeMaintenance"
				:attributeValue="'value'"
			    :attributeLabel="'label'"
				:clearable="false"
				style="font-size:18px;"/>
		</template>
		<template #search>
			<vg-users-selector
				style="width:400px;"
				:multiple="true"
				:placeholder="$t('select-user')"
				@input="onChangeUsersSelector"/>
		</template>
		<template #create-button>
			<vg-button type="info"
				@click="showModalFiltreSites=true;">
				<span v-if="!maintenancesFilters.sites.value">{{$t("filtrer-par-sites")}}</span>
				<div v-else>
					<span>{{$t("restriction")}}: </span>
					<span v-if="maintenancesFilters.sites.value.length>0" class="site-name">{{maintenancesFilters.sites.value[0] | site}}</span>
					<span v-if="maintenancesFilters.sites.value.length>1" class="site-name">{{maintenancesFilters.sites.value[1] | site}}</span>
					<span v-if="maintenancesFilters.sites.value.length>2" class="site-name-more">+ {{maintenancesFilters.sites.value.length-2}}</span>
				</div>
			</vg-button>
        </template>
		<template #primary>
			<vg-calendar ref="planification-calendar"
				style="width:100%;"
				v-model="dateRange"
				:defaultView="'week'"
				:isReadOnly="false"
				:calendars="getCalendars"
				:showLeftPanel="false"
				:showViewMonthSelector="false"
				:calendarEvents="calendarEvents"
				@change-date="onChangeDate"
				@change-filter-sites="onChangeFiltreSites"
				@before-create-schedule="onBeforeCreateSchedule"
				@before-update-schedule="onBeforeUpdateSchedule"/>
			<vg-fichesav-affectation v-if="showAffectationModal"
				:defaultStartDate="defaultAffectationDate.start"
				:defaultEndDate="defaultAffectationDate.end"
				@close="showAffectationModal=false;"
				@save="onSaveAffectation"/>
			<vg-intervention-form v-if="showInterventionModal && contrat"
				isPeriodique
				:idTiers="contrat.tiers.id"
				:idContrat="contrat.id"
				:defaultStatut="'intervention-prevue'"
				:defaultDatePrevisionnelleDebut="defaultAffectationDate.start"
				:defaultDatePrevisionnelleFin="defaultAffectationDate.end"
				@close="showInterventionModal=false;"
				@save="onSaveAffectation"/>
			<vg-intervention-form v-else-if="showInterventionModal && !contrat"
				isPeriodique
				:defaultStatut="'intervention-prevue'"
				:defaultDatePrevisionnelleDebut="defaultAffectationDate.start"
				:defaultDatePrevisionnelleFin="defaultAffectationDate.end"
				@close="showInterventionModal=false;"
				@save="onSaveAffectation"/>
		</template>
		<template #secondary>
			<vg-tabs v-model="focusedTab"
				:tabs="tabs"/>
			<span v-if="focusedTab=='maintenances'" style="font-size:12px;color:#059cff;">{{$t('selectionner-maintenance-click-sur-planning')}}</span>
			<span v-else-if="focusedTab=='contrats'" style="font-size:12px;color:#059cff;">{{$t('selectionner-contrat-click-sur-planning')}}</span>
			<vg-fichesav-table v-if="focusedTab=='maintenances'"
				style="height: 80%;"
				showCards
				:filters="getMaintenancesNonAffectesFilters"
				:counterName="'filtered'" />
			<vg-contrats-table v-else-if="focusedTab=='contrats'"
				style="height: 80%;"
				showCards
				:filters="getContratsFilters"/>
		</template>
		<vg-modal v-if="showModalFiltreSites"
			:title="$t('title-modal-filtre-site')"
			@close="showModalFiltreSites=false;">
			<template #body>
				<vg-filter-site
					:display="'modal'"
					multiple
					isAllSitesSelected
					@change-sites-validation="onChangeSitesValidation"/>
			</template>
			<template #footer>
				<vg-button
					@click="showModalFiltreSites=false;">
					{{$t("fermer")}}
				</vg-button>
			</template>
		</vg-modal>
	</vg-app-layout>

</template>

<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgCalendar from "src/components/Vg/VgCalendar.vue";
	import VgTabs from "src/components/Vg/VgTabs.vue";

	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import InterventionsMixins from "src/mixins/InterventionsMixins.js";
	import ContratsMixins from "src/mixins/ContratsMixins.js";
	import VerificationMixins from "src/mixins/VerificationMixins.js";

	import VgStatistiquesMaintenanceDashboard from "src/components/Vg/Statistiques/VgStatistiquesMaintenanceDashboard.vue";
	import VgStatistiquesVerificationDashboard from "src/components/Vg/Statistiques/VgStatistiquesVerificationDashboard.vue";

	import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
	import VgFichesavAffectation from "src/components/Vg/FicheSAV/VgFichesavAffectation.vue";

	import VgContratsTable from "src/components/Vg/Contrat/VgContratsTable.vue";
	import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";

	import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
	import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: "planification",
		mixins: [ MaintenanceMixins, InterventionsMixins, ContratsMixins, VerificationMixins ],
        components: {
			VgCalendar,
			VgButton,
			VgSelect,
			VgTabs,
			VgStatistiquesMaintenanceDashboard,
			VgStatistiquesVerificationDashboard,
			VgFichesavTable,
			VgContratsTable,
			VgFichesavAffectation,
			VgFilterSite,
			VgUsersSelector,
			VgInterventionForm
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Planification / affectation des ressources",
            "calendar-fm-ouverture": "Fiche curative date ouverture",
            "calendar-fm-fermeture": "Fiche curative date fermeture",
            "calendar-fm-affectation": "Fiche curative date affectation",
            "calendar-intervention-effective": "Intervention effective",
            "calendar-intervention-previsionnelle": "Intervention prévisionnelle",
            "calendar-verification-interne": "Vérification interne",
            "calendar-intervention-ponctuelle": "Intervention ponctuelle",
            "curative-et-preventive": "Curative/Préventive",
            "curative": "Curative",
            "preventive": "Préventif",
            "tab-maintenances": "Curatif",
            "tab-verifications": "Préventif",
            "tab-contrats": "Contrats",
            "filtrer-par-sites": "Filtrer par sites",
            "title-modal-filtre-site": "Filtrer par sites",
            "select-user": "Tous affichés",
            "selectionner-maintenance-click-sur-planning": "Sélectionnez une fiche curative et clickez sur le planning pour affecter",
            "selectionner-contrat-click-sur-planning": "Sélectionnez un contrat et clickez sur le planning pour créer une intervention"
        },
        "en": {
            "title": "Planning/resource allocation",
            "calendar-fm-ouverture": "Curative sheet opening date",
            "calendar-fm-fermeture": "Curative sheet closing date",
            "calendar-fm-affectation": "Curative sheet assignment date",
            "calendar-intervention-effective": "Intervention effective",
            "calendar-intervention-previsionnelle": "Intervention forecast",
            "calendar-verification-interne": "Internal verification",
            "calendar-intervention-ponctuelle": "One-time intervention",
            "curative": "Curative",
            "curative-et-preventive": "Curative/Preventive",
            "preventive": "Preventive",
            "tab-maintenances": "Curative",
            "tab-verifications": "Preventive",
            "tab-contrats": "Contracts",
            "filtrer-par-sites": "Sites filter",
            "title-modal-filtre-site": "Sites filter",
            "select-user": "All shown",
            "selectionner-maintenance-click-sur-planning": "Select a curative sheet and click on the schedule to assign",
            "selectionner-contrat-click-sur-planning": "Select a contract and click on the schedule to create intervention"
        }
    }
},
        data: function() {
            return {
				dateRange: {
					start: moment().startOf('isoWeek').format("YYYY-MM-DD"),
					end: moment().endOf('isoWeek').format("YYYY-MM-DD")
				},
				calendarEvents: [],
				calendars: [
					{
						id: "affectation",
						name: this.$t("calendar-fm-affectation"),
						checked: true,
						color: "yellow",
						bgColor: "#fff",
						dragBgColor: "#fff",
						borderColor: "transparent"
					},
					{
						id: "intervention-previsionnelle",
						name: this.$t("calendar-intervention-previsionnelle"),
						checked: true,
						color: "blue",
						bgColor: "#fff",
						dragBgColor: "#fff",
						borderColor: "transparent"
					},
					{
						id: "intervention-effective",
						name: this.$t("calendar-intervention-effective"),
						checked: true,
						color: "purple",
						bgColor: "#fff",
						dragBgColor: "#fff",
						borderColor: "transparent"
					}
				],
				metadatasMaintenances: new Metadatas(),
				metadatasInterventions: new Metadatas(),
				maintenancesFilters: {
					dateOuvertureSAV: { attr:"fm.dateOuvertureSAV",colId: "dateOuvertureSAV", value: null, action:"between", openParenthesis:true },
					dateFermetureSAV: { attr:"fm.dateFermetureSAV",colId: "dateFermetureSAV", value: null, action:"between", logicalOperator:"OR" },
					start: { attr:"aff.start",colId: "start", value: null, action:"between", logicalOperator:"OR" },
					end: { attr:"aff.end",colId: "end", value: null, action:"between", logicalOperator:"OR", closeParenthesis:true },
					sites: { attr:"l.path",colId: "path", value: null, action:"start_with" },
					affu_user_id: { attr: "affu.user_id", value: null, action:"equals"}
				},
				interventionsFilters: {
					affectes_user_id: {attr: "ia.user_id", value: null, action: "equals"},
					datePrevisionnelleDebut: { attr:"i.datePrevisionnelleDebut", colId: "datePrevisionnelleDebut", value: null, action:"between", openParenthesis:true },
					datePrevisionnelleFin: { attr:"i.datePrevisionnelleFin", colId: "datePrevisionnelleFin", value: null, action:"between", logicalOperator: "OR" },
					dateEffectiveDebut: { attr:"i.dateEffectiveDebut", colId: "dateEffectiveDebut", value: null, action:"between", logicalOperator: "OR" },
					dateEffectiveFin: { attr:"i.dateEffectiveFin", colId: "dateEffectiveFin", value: null, action:"between", closeParenthesis:true, logicalOperator: "OR" },
					sites: {attr: "l.path", colId: "path", value: null, action:"start_with", openParenthesis: true},
                    site_id: {attr: "i.site_id", colId: "site_id", value: null, action:"equals", closeParenthesis: true, logicalOperator: "OR" }
				},
				showAffectationModal: false,
				showInterventionModal: false,
				defaultAffectationDate:{
					start: null,
					end: null
				},
				focusedTab: "maintenances",
				maintenancesNonAffectesFilters: {
					aff_ficheSav_id: {attr: "aff.ficheSav_id", value: 1, action: "is_null"},
					statut: {attr: "fm.statut", value:"Resolue", action: "not_equals"},
					sites: { attr:"l.path",colId: "path", value: null, action:"start_with" }
				},
				showModalFiltreSites: false,
				typeMaintenance: [
					{label:this.$t("curative-et-preventive"),value:"curative-preventive"},
					{label:this.$t("curative"),value:"curative"},
					{label:this.$t("preventive"),value:"preventive"}
				],
				view: "curative-preventive",
			};
        },
        computed: {
			...mapGetters({
                  maintenances: "MaintenancesStore/getMaintenances",
                  maintenance: "MaintenancesStore/getSelectedMaintenance",
                  contrat: "ContratsStore/getSelectedItem",
                  interventions: "InterventionsStore/getCollection",
                  intervention: "InterventionsStore/getSelectedItem",
                  sites: "LieuxStore/getSites",
            }),
			dateRangeMonth: function(){
				return {
					start: moment(this.dateRange.start).startOf('month').format('YYYY-MM-DD'),
					end: moment(this.dateRange.end).endOf('month').format('YYYY-MM-DD')
				};
			},
			getCalendars: function(){
				if(this.view=="curative-preventive") return this.calendars;
				else if(this.view=="curative") return this.calendars.filter((cal)=>cal.id=="affectation");
				else if(this.view=="preventive") return this.calendars.filter((cal)=>cal.id=="intervention-previsionnelle" || cal.id=="intervention-effective");
				else return this.calendars;
			},
			tabs: function(){
				let tabs = [];
				if(this.view=="curative-preventive"){
					tabs.push({label: this.$t("tab-maintenances"), name: "maintenances"});
					tabs.push({label: this.$t("tab-verifications"), name: "verifications"});
				}else if(this.view=="curative") tabs.push({label: this.$t("tab-maintenances"), name: "maintenances"});
				else if(this.view=="preventive") tabs.push({label: this.$t("tab-verifications"), name: "verifications"});
				if(this.$app.account_type=="prestataire" && (this.view=="curative-preventive" || this.view=="preventive")) tabs.push({label: this.$t("tab-contrats"), name: "contrats"});
				return tabs;
			},
			getMaintenancesNonAffectesFilters: function(){
				return Object.values(this.maintenancesNonAffectesFilters);
			},
			getContratsFilters: function(){
				return {
					lieu_id: {attr:"cl.lieu_id", value: null, action: "equals"}
				};
			}
        },
		watch:{

		},
        methods: {
			onSaveAffectation: function(){
				this.$store.dispatch("MaintenancesStore/deleteMaintenance", this.maintenance.id);
				this.$store.dispatch("MaintenancesStore/setSelectedMaintenance", null);
				this.$store.dispatch("ContratsStore/setSelectedItem", null);
				this.defaultAffectationDate = {
					start: null,
					end: null
				};
				this.showAffectationModal = false;
				this.showInterventionModal = false;
				this.fetch();
			},
			onBeforeCreateSchedule: function(event){
				if(this.focusedTab=="maintenances" && this.maintenance && this.maintenance.hasOwnProperty("id")){
					//console.log("CLICK CALENDAR", this.maintenance, event);
					this.defaultAffectationDate = {
						start: moment(event.start._date).format("YYYY-MM-DD HH:mm"),
						end: moment(event.end._date).format("YYYY-MM-DD HH:mm")
					};
					this.showAffectationModal = true;
				}else if(this.focusedTab=="contrats" && this.contrat && this.contrat.hasOwnProperty("id")){
					//console.log("CLICK CALENDAR", this.contrat, event);
					this.defaultAffectationDate = {
						start: moment(event.start._date).format("YYYY-MM-DD HH:mm"),
						end: moment(event.end._date).format("YYYY-MM-DD HH:mm")
					};
					this.showInterventionModal = true;
				}
			},
			onBeforeUpdateSchedule: function(event){
				//console.log("BEFORE UPDATE SCHEDULE", event);
				if(/*this.focusedTab=="maintenances" && */event.schedule.calendarId=="affectation"){
					this.$store.dispatch("MaintenancesStore/setMaintenance", event.schedule.raw);
					this.defaultAffectationDate = {
						start: moment(event.start._date).format("YYYY-MM-DD HH:mm"),
						end: moment(event.end._date).format("YYYY-MM-DD HH:mm")
					};
					this.showAffectationModal = true;
				}else if(/*this.focusedTab=="contrats" && */event.schedule.calendarId=="intervention-previsionnelle"){
					let scheduleIntervention = this.interventions.find((inter)=>inter.id==event.schedule.raw.id);
					this.$store.dispatch("InterventionsStore/setSelectedItem", scheduleIntervention);
					this.defaultAffectationDate = {
						start: moment(event.start._date).format("YYYY-MM-DD HH:mm"),
						end: moment(event.end._date).format("YYYY-MM-DD HH:mm")
					};
					this.showInterventionModal = true;
				}
			},
			onChangeUsersSelector: function(users){
				this.maintenancesFilters.affu_user_id.value = users;
				this.interventionsFilters.affectes_user_id.value = users;
				this.fetch();
			},
			onChangeSitesValidation: function(sites){
				this.setFiltersSitesValues(sites);
			},
			onChangeFiltreSites: function(sites){
				this.setFiltersSitesValues(sites);
				this.fetch();
			},
			fetch: function(){
				this.calendarEvents = [];
				this.metadatasMaintenances.setFilters(this.maintenancesFilters);
				this.MaintenanceMixins_getCalendarEvents(this.metadatasMaintenances).then((calendarEvents)=>{
					//console.log("CALENDAR EVENTS FMS", calendarEvents);
					this.calendarEvents = this.calendarEvents.concat(calendarEvents);
				});
				this.metadatasInterventions.setFilters(this.interventionsFilters);
				this.InterventionsMixins_getCalendarEvents(this.metadatasInterventions).then((calendarEvents)=>{
					//console.log("SUCCESS GET INTERVENTIONS", calendarEvents);
					this.calendarEvents = this.calendarEvents.concat(calendarEvents);
				});
			},
			onChangeDate: function(dateRange){
				let currentDateMonthStart = this.dateRangeMonth.start;
				this.dateRange = dateRange;
				this.setFiltersValues();
				if(moment(dateRange.start).startOf('month').format('YYYY-MM-DD')!=currentDateMonthStart) this.fetch();
			},
			setFiltersValues: function(){
				let intervalleDate = [this.dateRangeMonth.start,this.dateRangeMonth.end];
				this.maintenancesFilters.dateOuvertureSAV.value = intervalleDate;
				this.maintenancesFilters.dateFermetureSAV.value = intervalleDate;
				this.maintenancesFilters.start.value = intervalleDate;
				this.maintenancesFilters.end.value = intervalleDate;
				this.interventionsFilters.datePrevisionnelleDebut.value = intervalleDate;
				this.interventionsFilters.datePrevisionnelleFin.value = intervalleDate;
				this.interventionsFilters.dateEffectiveDebut.value = intervalleDate;
				this.interventionsFilters.dateEffectiveFin.value = intervalleDate;
			},
			/**
			* @param array sites
			*/
			setFiltersSitesValues: function(sites){
				this.maintenancesFilters.sites.value = sites;
				if(sites){
                    this.interventionsFilters.sites.value = sites;
                    this.interventionsFilters.site_id.value = this.sites.filter((s)=>sites.indexOf(s.path)!=-1).map((s)=>s.id);
                }else{
                    this.interventionsFilters.sites.value = null;
                    this.interventionsFilters.site_id.value = null;
                }
			}
        },
		created: function(){
			this.setFiltersValues();
			this.fetch();
		},
        mounted: function(){

        }
    };
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.bloc-statistiques{
	border: 1px solid #ccc;
	width: 100%;
	height: 100%;
	padding: 20px 0;
}
</style>
