<template>
	<div class="vg-search-datas-results">
		
		<div v-if="isLoading"
			class="loading">
			<span>{{ $t("loading") }} <b>&quot;{{ searchValue }}&quot;</b> ...</span>
		</div>

		<div v-if="showEmpty"
			class="empty">
			{{ $t("saisir-un-terme") }}
		</div>

		<!-- EQUIPEMENT -->
		<vg-search-datas-viewer v-if="equipements && can('RECHERCHE_GENERALE.EQUIPEMENTS_READ')" 
			v-model="equipements"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isEquipements
			key="equipements-viewer"
			@open="openEquipement"
			@redirect="redirectToEquipements"
			@create="showCreateEquipement=true;"
			@create-fm="openCreateEquipementFm"
			@demande-intervention="openDemandeIntervention"
			@check="openCheck"
			@update="openUpdateEquipement"/>
		<vg-equipement-form v-if="showCreateEquipement"
			key="equipement-form-create"
			skipVueXStorage 
			@close="showCreateEquipement=false;"
			@save="onSaveEquipement" />
		<vg-equipement-form v-if="showUpdateEquipement"
			:id="focusedEquipement.id"
			key="equipement-form-update"
			skipVueXStorage 
			@close="showUpdateEquipement=false;focusedEquipement=null;"
			@save="onUpdateEquipement" />
		<vg-maintenance-form v-if="showCreateEquipementFm"
			:idEquipement="focusedEquipement.id"
			skipVueXStorage
			@close="showCreateEquipementFm=false;focusedEquipement=null;"
			@created="showCreateEquipementFm=false;focusedEquipement=null;"
			@open-maintenance="openMaintenance" />

		<!-- LIEU -->
		<vg-search-datas-viewer v-if="lieux && can('RECHERCHE_GENERALE.LIEUX_READ')" 
			v-model="lieux"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isLieux
			key="lieux-viewer" 
			@open="openLieu"
			@redirect="redirectToLieux"
			@create="showCreateLieu=true;"
			@update="openUpdateLieu"
			@demande-intervention="openDemandeIntervention"
			@check="openCheck"/>
		<vg-lieu-info-create v-if="showCreateLieu"
			@close="showCreateLieu = false" />
		<vg-lieu-form v-if="showUpdateLieu"
			key="lieu-form-update"
			:id="focusedLieu.id"
			skipVueXStorage 
			@close="showUpdateLieu=false;focusedLieu=null;" 
			@updated="onUpdateLieu"/>
		
		<!-- MAINTENANCE -->
		<vg-search-datas-viewer v-if="maintenances && can('RECHERCHE_GENERALE.MAINTENANCES_READ')" 
			v-model="maintenances"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isMaintenances
			key="maintenances-viewer" 
			@open="openMaintenance"
			@redirect="redirectToMaintenances"
			@create="openDemandeIntervention"
			@open-actions="openMaintenanceActions"/>
			<!-- @TODO fichesav management passer skipVueXStorage à tous ses composants enfants -->
		<vg-fichesav-management v-if="showActionsMaintenance"
			skipVueXStorage
			@close="showActionsMaintenance=false;focusedMaintenance=null;" />

		<!-- CONTRAT -->
		<vg-search-datas-viewer v-if="contrats && can('RECHERCHE_GENERALE.CONTRATS_READ')" 
			v-model="contrats"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isContrats
			key="contrats-viewer" 
			@open="openContrat"
			@redirect="redirectToContrats"
			@create="showCreateContrat=true;"
			@update="openUpdateContrat"/>
		<vg-contrat-form v-if="showCreateContrat"
			key="contrat-form-create"
			skipVueXStorage
			@close="showCreateContrat=false;"
			@created="onSaveContrat" />
		<vg-contrat-form v-if="showUpdateContrat"
			key="contrat-form-update"
			:id="focusedContrat.id"
			skipVueXStorage
			@close="showUpdateContrat=false;"
			@updated="onUpdateContrat" />

		<!-- TIER -->
		<vg-search-datas-viewer v-if="tiers && can('RECHERCHE_GENERALE.TIERS_READ')" 
			v-model="tiers"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isTiers
			key="tiers-viewer" 
			@open="openTier"
			@redirect="redirectToTiers"
			@create="showCreateTier=true;"
			@update="openUpdateTier"/>
		<vg-tiers-form v-if="showCreateTier"
			key="tiers-form-create"
			skipVueXStorage
			@close="showCreateTier=false;"
			@created="onSaveTiers" />
		<vg-tiers-form v-if="showUpdateTier"
			key="tiers-form-update"
			:uid="focusedTier.uid"
			skipVueXStorage
			@close="showUpdateTier=false;"
			@updated="onUpdateTiers" />

		<!-- CATEGORIES -->
		<vg-search-datas-viewer v-if="categories && can('RECHERCHE_GENERALE.CATEGORIES_READ')" 
			v-model="categories"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isCategories
			key="categories-viewer" 
			@open="openCategorie"
			@redirect="redirectToCategories"
			@create="showCreateCategorie=true;"
			@update="openUpdateCategorie"/>
		<vg-categorie-form v-if="showCreateCategorie"
			key="categorie-form-create"
			skipVueXStorage
			@close="showCreateCategorie=false;"
			@save="onSaveCategorie"/>
		<vg-categorie-form v-if="showUpdateCategorie"
			key="categorie-form-update"
			:id="focusedCategorie.id"
			skipVueXStorage
			@close="showUpdateCategorie=false;"
			@save="onUpdateCategorie"/>

		<!-- INTERVENTIONS -->
		<vg-search-datas-viewer v-if="interventions && can('RECHERCHE_GENERALE.INTERVENTIONS_READ')" 
			v-model="interventions"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isInterventions
			key="interventions-viewer" 
			@open="openIntervention"
			@redirect="redirectToInterventions"
			@create="showCreateIntervention=true;"
			@update="openUpdateIntervention"/>
		<vg-intervention-form v-if="showCreateIntervention"
			key="intervention-form-create"
			skipVueXStorage
			@close="showCreateIntervention=false;"
			@save="onSaveIntervention"/>
		<vg-intervention-form v-if="showUpdateIntervention"
			key="intervention-form-update"
			:id="focusedIntervention.id"
			skipVueXStorage
			@close="showUpdateIntervention=false;"
			@save="onUpdateIntervention"/>

		<!-- CONTACTS -->
		<vg-search-datas-viewer v-if="contacts && can('RECHERCHE_GENERALE.CONTACTS_READ')" 
			v-model="contacts"
			:searchValue="searchValue"
			:showEmpty="showEmpty"
			isContacts
			key="contacts-viewer" 
			@open="openTierContact"
			@redirect="redirectToContacts"
			@create="showCreateContact=true;"
			@update="openUpdateContact"/>
		<vg-contacts-form v-if="showCreateContact"
			key="contact-form-create"
			skipVueXStorage
			@close="showCreateContact=false;"
			@save="onSaveContact"/>
		<vg-contacts-form v-if="showUpdateContact"
			key="contact-form-update"
			:id="focusedContact.id"
			skipVueXStorage
			@close="showUpdateContact=false;"
			@save="onUpdateContact"/>

		<div v-if="!isLoading && equipements.length==0 && lieux.length==0 && maintenances.length==0 && contrats.length==0 && tiers.length==0"
			class="result-no-datas">
			{{ $t("no-datas") }} <b>&quot;{{ searchValue }}&quot;</b>
		</div>

	</div>
</template>
<script>
import VgButton from "src/components/Vg/VgButton.vue";
import VgSearchDatasViewer from "src/components/Vg/SearchDatas/VgSearchDatasViewer.vue";

import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import VgLieuInfoCreate from "src/components/Vg/Lieu/VgLieuInfoCreate.vue";
import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";
import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
import VgContratForm from "src/components/Vg/Forms/VgContratForm.vue";
import VgTiersForm from "src/components/Vg/Forms/VgTiersForm.vue";
import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
import VgContactsForm from "src/components/Vg/Forms/VgContactsForm.vue";
import VgFichesavManagement from "src/components/Vg/FicheSAV/VgFichesavManagement.vue";

import SearchDatasMixins from "src/mixins/SearchDatasMixins.js";

import { mapGetters } from 'vuex';

    export default {
        name: 'vg-search-datas-results',
		i18n:    { 
			"locale":navigator.language,
			"messages": {
				"fr": {
					"no-datas": "Aucune donnée ne correspond à votre recherche",
					"loading": "Recherche en cours",
					"saisir-un-terme": "Saisir un terme",
					"impossible-charger-check": "Impossible de lancer check pour {libel}"
				},
				"en": {
					"no-datas": "No data matches your search",
					"loading": "Searching",
					"saisir-un-terme": "Enter a term",
					"impossible-charger-check": "Unable to launch check for {libel}"
				}
			}
		},
		components: {
			VgButton,
			VgSearchDatasViewer,
			VgEquipementForm,
			VgLieuInfoCreate,
			VgLieuForm,
			VgMaintenanceForm,
			VgContratForm,
			VgTiersForm,
			VgCategorieForm,
			VgInterventionForm,
			VgContactsForm,
			VgFichesavManagement
		},
		mixins: [ SearchDatasMixins ],
        props: {
			value:{
				type: String,
				default: null
			},
			showEmpty: {
				type: Boolean,
				default: false
			}
        },
		watch:{
			value: {
				handler: function(newValue){
					this.searchValue = newValue;
					this.fetch();
				}
			}
		},
        data: function() {
            return {
				searchValue: this.value,
				isLoading: false,
				showCreateEquipement: false,
				showCreateLieu: false,
				showCreateMaintenance: false,
				showCreateContrat: false,
				showCreateTier: false,
				showCreateEquipementFm: false,
				showCreateCategorie: false,
				showCreateIntervention: false,
				showCreateContact: false,
				
				showUpdateEquipement: false,
				showUpdateLieu: false,
				showUpdateContrat: false,
				showUpdateTier: false,
				showActionsMaintenance: false,
				showUpdateCategorie: false,
				showUpdateIntervention: false,
				showUpdateContact: false,

				focusedEquipement: null,
				focusedLieu: null,
				focusedMaintenance: null,
				focusedContrat: null,
				focusedTier: null,
				focusedCategorie: null,
				focusedIntervention: null,
				focusedContact: null,
            };
        },
		methods: {
			fetch: function(){
				if(this.searchValue==null){ 
					this.$store.dispatch("SearchDatasStore/clear");
					return;
				}
				this.isLoading = true;
				this.SearchDatasMixins_get(this.searchValue).then((datas)=>{
					this.isLoading = false;
				});
			},
			/**
			 * @TODO il faudrai refactorizer tout ça pour que ça marche en dehors de ce composant
			 */
			routerPush: function(routeName, filters){
				this.$router.push({name: routeName, params: {defaultFilters: filters}})
					.catch(failure => {
						if(failure.to && failure.from && failure.to.name==failure.from.name){
							this.$emit("filters-change", filters);
						}
					});
			},
			// Equipements
			redirectToEquipements: function(){
				this.routerPush("_equipements", this.filters.equipements);
			},
			openEquipement: function(equipement){
				this.$router.push({ name: '_equipement_id', params: { id: equipement.id } });
			},
			openCreateEquipementFm: function(equipement){
				this.focusedEquipement = equipement;
				this.showCreateEquipementFm = true;
			},
			openUpdateEquipement: function(equipement){
				this.focusedEquipement = equipement;
				this.showUpdateEquipement = true;
			},
			onSaveEquipement: function(equipements){
				this.showCreateEquipement = false;
				this.openEquipement(equipements[0]);
			},
			onUpdateEquipement: function(equipement){
				this.showUpdateEquipement = false;
				this.focusedEquipement = null;
				this.$emit("close");
			},
			// Lieux
			redirectToLieux: function(){
				this.routerPush("_lieux", this.filters.lieux);
			},
			openLieu: function(lieu){
				if(lieu.type_lieu=="Piece" || lieu.type_lieu=="Etage") this.$router.push({ name: '_lieu_id', params: { id: lieu.id } });
				else if(lieu.type_lieu=="Batiment") this.$router.push({ name: '_batiment_id', params: { id: lieu.id } });
				else if(lieu.type_lieu=="Site") this.$router.push({ name: '_site_id', params: { id: lieu.id } });
			},
			openUpdateLieu: function(lieu){
				this.focusedLieu = lieu;
				this.showUpdateLieu = true;
			},
			onUpdateLieu: function(lieu){
				this.showUpdateLieu = false;
				this.focusedLieu = null;
				this.$emit("close");
			},
			// Maintenances
			redirectToMaintenances: function(){
				this.routerPush("_maintenances", this.filters.maintenances);
			},
			openMaintenance: function(maintenance){
				this.$router.push({ name: '_maintenance', params: { id: maintenance.id } });
			},
			openMaintenanceActions: function(maintenance){
				this.focusedMaintenance = maintenance;
				this.$router.push({ name: '_maintenance', params: { id: maintenance.id, openActions: true } });
			},
			openDemandeIntervention: function(item=null){
				if(!item) this.routerPush("_demande_intervention", null);
				else if(item.hasOwnProperty("codeUn") && item.hasOwnProperty("libel_lieu") && item.libel_lieu){ // cas équipement
					this.$router.push({name: "_demande_intervention", params: {code: item.codeUn , isScann: true}})
						.catch(failure => {
							if(failure.to && failure.from && failure.to.name==failure.from.name){
								this.$emit("filters-change", {code: item.codeUn , isScann: true});
							}
						});
				}else if(item.hasOwnProperty("qrCode") && item.qrCode){ // cas équipement
					this.$router.push({name: "_demande_intervention", params: {code: item.qrCode, isScann: true}})
						.catch(failure => {
							if(failure.to && failure.from && failure.to.name==failure.from.name){
								this.$emit("filters-change", {code: item.qrCode , isScann: true});
							}
						});
				}
			},
			openCheck: function(item){
				if(item.hasOwnProperty("codeUn") && item.hasOwnProperty("libel_lieu") && item.libel_lieu){
					let tachesLieu = this.taches.filter((tache)=>tache.categorie_id==item.idCategorie_id);
					if(tachesLieu.length==1){
						this.$router.push({name: "_process_verification", params: {type: "lieux" , equipementId: item.equipement_id, tacheId: tachesLieu[0].id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_lieu}));
							});
					}else{
						this.$router.push({name: "_process_verification_taches_selection", params: {type: "lieux" , equipementId: item.equipement_id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_lieu}));
							});
					}
				}else if(item.hasOwnProperty("qrCode") && item.hasOwnProperty("libel_equipement") &&item.qrCode){
					let tachesEquipement = this.taches.filter((tache)=>tache.categorie_id==item.idCategorie_id);
					if(tachesEquipement.length==1){
						this.$router.push({name: "_process_verification", params: {type: "equipement" , equipementId: item.id, tacheId: tachesEquipement[0].id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_equipement}));
							});
					}else{
						this.$router.push({name: "_process_verification_taches_selection", params: {type: "equipement" , equipementId: item.id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-check", {libel: item.libel_equipement}));
							});
					}
				}
			},
			// Contrats
			redirectToContrats: function(){
				this.routerPush("_contrat", this.filters.contrats);
			},
			openContrat: function(contrat){
				this.$router.push({ name: '_contrat_id', params: { id: contrat.id } });
			},
			openUpdateContrat: function(contrat){
				this.focusedContrat = contrat;
				this.showUpdateContrat = true;
			},
			onSaveContrat: function(contrat){
				this.openContrat(contrat);
			},
			onUpdateContrat: function(contrat){
				this.showUpdateContrat = false;
				this.focusedContrat = null;
				this.$emit("close");
			},
			// Tiers
			redirectToTiers: function(){
				this.routerPush("_tiers", this.filters.tiers);
			},
			openTier: function(tier){
				this.$router.push({ name: '_tiers_id', params: { id: tier.uid } });
			},
			openUpdateTier: function(tier){
				this.focusedTier = tier;
				this.showUpdateTier = true;
			},
			onSaveTiers: function(tiers){
				this.openTiers(tiers[0]);		
			},
			onUpdateTiers: function(tier){
				this.showUpdateTier = false;
				this.focusedTier = null;
				this.$emit("close");
			},
			// categories
			redirectToCategories: function(){
				this.routerPush("_categories_equipements", this.filters.categories);
			},
			openCategorie: function(categorie){
				//this.$router.push({ name: '_categories_equipements', params: { id: tier.uid } });
			},
			openUpdateCategorie: function(categorie){
				this.focusedCategorie = categorie;
				this.showUpdateCategorie = true;
			},
			onSaveCategorie: function(categorie){
				this.showCreateCategorie = false;
				// this.openCategorie(categorie);		
			},
			onUpdateCategorie: function(categorie){
				this.showUpdateCategorie = false;
				this.focusedCategorie = null;
				this.$emit("close");
			},
			// interventions
			redirectToInterventions: function(){
				this.routerPush("_interventions", this.filters.interventions);
			},
			openIntervention: function(intervention){
				this.$router.push({ name: '_intervention', params: { id: intervention.id } });
			},
			openUpdateIntervention: function(intervention){
				this.focusedIntervention = intervention;
				this.showUpdateIntervention = true;
			},
			onSaveIntervention: function(intervention){
				this.showCreateIntervention = false;
				this.openIntervention(intervention);		
			},
			onUpdateIntervention: function(intervention){
				this.showUpdateIntervention = false;
				this.focusedIntervention = null;
				this.$emit("close");
			},
			// contacts
			redirectToContacts: function(){
				//this.routerPush("_tiers", this.filters.contacts);
			},
			openTierContact: function(contact){
				this.$router.push({ name: '_tiers_id', params: { id: contact.tiers_uid } });
			},
			openUpdateContact: function(contact){
				this.focusedContact = contact;
				this.showUpdateContact = true;
			},
			onSaveContact: function(contact){
				this.openTierContact(contact);
			},
			onUpdateContact: function(contact){
				this.showUpdateCategorie = false;
				this.focusedCategorie = null;
				this.$emit("close");
			},
		},
		computed: {
			...mapGetters({
                entities: "SearchDatasStore/getEntities",
                counters: "SearchDatasStore/getCounters",
                filters: "SearchDatasStore/getFilters",
                equipements: "SearchDatasStore/getEquipements",
				lieux: "SearchDatasStore/getLieux",
				maintenances: "SearchDatasStore/getMaintenances",
				contrats: "SearchDatasStore/getContrats",
				tiers: "SearchDatasStore/getTiers",
				categories: "SearchDatasStore/getCategories",
				interventions: "SearchDatasStore/getInterventions",
				contacts: "SearchDatasStore/getContacts",
				taches: "SearchDatasStore/getTaches"
            }),
			
		},
		mounted: function(){
			this.fetch();
		}
    };

</script>
<style lang="scss" scoped>
.vg-search-datas-results{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 5px;
	width: inherit;
	min-height: 40px;
	max-height: 60vh;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px #ccc;
	background-color: white;
	overflow-y: auto;
	z-index: 100;
    max-width: -webkit-fill-available;
	.result-no-datas{
		color: #35b1ea;

	}
	.loading{
		font-weight: normal;
		color: #35b1ea;
	}
	.empty{
		font-weight: normal;
		color: #35b1ea;
		margin: 5px 0;
	}
}
</style>
