<template>
	<vg-modal
		:title="$t('title')"
		@close="$emit('close')">
		<template #body>
			<vg-files-uploader v-if="!showSuccess"
                isOnlyPhoto
                isUnnamed
                :defaultIdTag="getDefaultTagSignature"
                :uidToUpload="getUserUid"
                :defaultType="'user-signature'"
                @upload-done="onUploadDone"/>
            <span v-else style="color: #50b659;">{{ $t("success-message") }}</span>
		</template>
		<template #footer>
			<vg-button type="default" @click="$emit('close')">{{ $t("close") }}</vg-button>
		</template>
	</vg-modal>
</template>
<script>
import UserMixins from "src/mixins/UserMixins";
import VgButton from "src/components/Vg/VgButton.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
export default {
    name: 'vg-my-signature',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Ma signature",
            "close": "Fermer",
            "success-message": "Signature enregistrée avec succès !"
        },
        "en": {
            "title": "My signature",
            "close": "Close",
            "success-message": "Signature uploaded successfully!"
        }
    }
} ,
	components: {
		VgButton,
		VgFilesUploader
	},
    mixins:[UserMixins],
    data: function() {
        return {
            signature: null,
            showSuccess: false
        };
    },
    computed: {
		getUserUid: function(){
            return this.$app.user_uid;
        },
        getDefaultTagSignature: function(){
            return 5101;
        },
    },
    methods: {
		onUploadDone: function(){
            this.showSuccess = true;
        }
    }
};
</script>
<style lang="scss">

</style>
