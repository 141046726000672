<template>
    <div>
        <vg-intervention-numero-viewer :intervention="params.data">
        </vg-intervention-numero-viewer>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgInterventionNumeroViewer from "src/components/Vg/Interventions/VgInterventionNumeroViewer.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgInterventionNumeroViewer
        }
    });
</script>

<style lang="scss" scoped>

</style>
