<template>
    <div class="vg-plan-interactif-panel">
        <vg-tabs v-model="focusedTab"
            :tabs="getTabs"
            color="violet"/>
        <div v-if="focusedTab=='pieces'" class="main-container">
            <vg-collapse
                style="margin: 0 10px;"
                :type="'section'"
                :collapseByDefault="false"
                :killContentOnCollapse="false"
                :title="$t('title-collapse-pieces-positionnees')">
                <template #content>
                    <piece-marker v-for="piece, index in piecesPositionnees" :piece="piece" :key="piece.id"
                        :pieces="pieces"
                        style="margin-bottom: 20px;"
                        @click="onClickPiece"/>
                </template>
            </vg-collapse>
            <vg-collapse
                style="margin: 0 10px;"
                :type="'section'"
                :collapseByDefault="false"
                :killContentOnCollapse="false"
                :title="$t('title-collapse-pieces-non-positionnees')">
                <template #content>
                    <span v-if="isModeEdition" class="info-drag-and-drop">{{$t("drag-and-drop-to-place-marker")}}</span>
                    <piece-marker v-for="piece, index in piecesNonPositionnees" :piece="piece" :key="piece.id"
                        :pieces="pieces"
                        style="margin-bottom:20px;"
                        draggable
                        @drag-start="dragStartPiece"
                        @click="onClickPiece"/>
                </template>
            </vg-collapse>
            <input type="file" @change="handleImportFileChange" ref="pieces_file_import" style="display: none;" />
            <button @click="$refs.pieces_file_import.click()">{{ $t("import-pieces") }}</button>

            <vg-modal v-if="showPieceModal"
                @close="showPieceModal=false;">
                <template #header>
                    <vg-lieu-path-viewer
                        v-model="selectedPiece.path"/>
                </template>
                <template #footer>
                    <vg-button type="default" @click="showPieceModal=false;">{{$t("btn-close")}}</vg-button>
                    <vg-button v-if="can('PIECE.PAGE')" type="success" @click="toZoomLieu">{{$t("voir-lieu")}}</vg-button>
                    <vg-button type="danger" @click="toDemandeIntervention">{{$t("faire-une-di")}}</vg-button>
                </template>
            </vg-modal>
        </div>
        <div v-else class="main-container">
            <vg-collapse
                style="margin: 0 10px;"
                :type="'section'"
                :collapseByDefault="false"
                :killContentOnCollapse="false"
                :title="$t('title-collapse-equipements-positionnes')">
                <template #content>
                    <equipement-marker v-for="equipement, index in equipementsPositionnes" :equipement="equipement" :key="equipement.id"
                        style="margin-bottom:20px;"/>
                </template>
            </vg-collapse>
            <vg-collapse
                style="margin: 0 10px;"
                :type="'section'"
                :collapseByDefault="false"
                :killContentOnCollapse="false"
                :title="$t('title-collapse-equipements-non-positionnes')">
                <template #content>
                    <span v-if="isModeEdition" class="info-drag-and-drop">{{$t("drag-and-drop-to-place-marker")}}</span>
                    <equipement-marker v-for="equipement, index in equipementsNonPositionnes" :equipement="equipement" :key="equipement.id"
                        style="margin-bottom:20px;"
                        draggable
                        @drag-start="dragStartEquipement"
                        @click="onClickEquipement"/>
                </template>
            </vg-collapse>
            

            <vg-modal v-if="showEquipementModal"
                @close="showEquipementModal=false;">
                <template #header>
                    <vg-equipement-viewer :equipement="selectedEquipement"/>
                </template>
                <template #footer>
                    <vg-button type="default" @click="showEquipementModal=false;">{{$t("btn-close")}}</vg-button>
                    <vg-button v-if="can('EQUIPEMENT.PAGE')" type="success" @click="toZoomEquipement">{{$t("voir-equipement")}}</vg-button>
                    <vg-button type="danger" @click="toDemandeIntervention">{{$t("faire-une-di")}}</vg-button>
                </template>
            </vg-modal>
        </div>
        
    </div>
</template>

<script>

import PlanInteractifMixins from "src/mixins/PlanInteractifMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";

import VgTabs from "src/components/Vg/VgTabs.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

import EquipementMarker from "src/components/Vg/PlanInteractif/Marker/EquipementMarker.vue";
import PieceMarker from "src/components/Vg/PlanInteractif/Marker/PieceMarker.vue";

import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: "vg-plan-interactif-panel",
    components: {
        VgTabs,
        VgCollapse,
        VgModal,
        VgButton,
        EquipementMarker,
        PieceMarker,
        VgEquipementViewer,
        VgLieuPathViewer,
        VgInput
    },
    mixins:[ LieuMixins, equipementsMixins, PlanInteractifMixins ],
    props:{
        isModeEdition: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "tab-pieces": "Pièces",
            "tab-equipements": "Equipements",
            "title-collapse-pieces-positionnees": "Positionnées",
            "title-collapse-pieces-non-positionnees": "Non positionnées",
            "title-collapse-equipements-positionnes": "Positionnés",
            "title-collapse-equipements-non-positionnes": "Non positionnés",
            "drag-and-drop-to-place-marker": "Drag&drop pour placer",
            "btn-close": "Fermer",
            "modal-title-equipement": "Equipement",
            "modal-title-piece": "Piece",
            "voir-equipement": "Voir équipement",
            "voir-lieu": "Voir pièce",
            "faire-une-di": "Faire une demande d'intervention",
            "import-pieces": "Importer des pièces"
        }
    }
},
    data:function() {
        return {
            focusedTab: "pieces",
            piecesPositionnees:[],
            piecesNonPositionnees:[],
            equipementsPositionnes:[],
            equipementsNonPositionnes:[],
            showEquipementModal: false,
            showPieceModal: false,
            selectedEquipement: null,
            selectedPiece: null
        }
    },
    watch:{
        /*pieces:{
            handler: function(newpieces){
                this.setPiecesPositionneesEtNonPositionnees();
            },
            deep: true
        },
        equipements:{
            handler: function(newequipements){
                this.setEquipementsPositionnesEtNonPositionnes();
            },
            deep: true
        },*/
        geoJsonPiecesDraft:{
            handler: function(newgeojson){
                this.setPiecesPositionneesEtNonPositionnees();
            },
            deep: true
        },
        geoJsonEquipementsDraft:{
            handler: function(newgeojson){
                this.setEquipementsPositionnesEtNonPositionnes();
            },
            deep: true
        }
    },
    mounted:function(){
        this.setPiecesPositionneesEtNonPositionnees();
        this.setEquipementsPositionnesEtNonPositionnes();
    },
    methods: {
        handleImportFileChange(event){
            let file = event.target.files[0];
            this.$emit("file-imported", file);
        },
        setPiecesPositionneesEtNonPositionnees: function(){
            let geoJson = this.geoJsonPiecesDraft && this.geoJsonPiecesDraft.features && this.geoJsonPiecesDraft.features.length!=0?this.geoJsonPiecesDraft:this.geoJsonPieces;
            let [positionnees, nonPositionnees] = this.PlanInteractif_getElementsPositionneesEtNonPositionnees(this.pieces, geoJson);
            this.piecesPositionnees = positionnees;
            this.piecesNonPositionnees = nonPositionnees;
        },
        setEquipementsPositionnesEtNonPositionnes: function(){
            let geoJson = this.geoJsonEquipementsDraft && this.geoJsonEquipementsDraft.features && this.geoJsonEquipementsDraft.features.length!=0?this.geoJsonEquipementsDraft:this.geoJsonEquipements;
            let [positionnes, nonPositionnes] = this.PlanInteractif_getElementsPositionneesEtNonPositionnees(this.equipements, geoJson);
            this.equipementsPositionnes = positionnes;
            this.equipementsNonPositionnes = nonPositionnes;
        },
        dragStartEquipement: function(datas){
            console.log("DRAG START EQ", datas.equipement);
            if(this.isModeEdition){
                let ev = datas.event;
                let equipement = datas.equipement;
                ev.dataTransfer.dropEffect = "move";
                ev.dataTransfer.effectAllowed = "move";
                ev.dataTransfer.setData("equipement", JSON.stringify(equipement));
            }
        },
        dragStartPiece: function(datas){
            if(this.isModeEdition){
                let ev = datas.event;
                let piece = datas.piece;
                ev.dataTransfer.dropEffect = "move";
                ev.dataTransfer.effectAllowed = "move";
                ev.dataTransfer.setData("piece", JSON.stringify(piece));
            }
        },
        onClickEquipement: function(datas){
            if(!this.isModeEdition){
                this.selectedEquipement = datas.equipement;
                this.showEquipementModal = true;
            }
        },
        onClickPiece: function(datas){
            if(!this.isModeEdition){
                this.selectedPiece = datas.piece;
                this.showPieceModal = true;
            }
        },
        toZoomEquipement: function(){
            this.$router.push({name:'_equipement_id', params: {id: this.selectedEquipement.id}});
        },
        toZoomLieu: function(){
            this.$router.push({name:'_lieu_id', params: {id: this.selectedPiece.id}});
        },
        toDemandeIntervention: function(){
            let qrCode = null;
            if(this.showEquipementModal) qrCode = this.selectedEquipement.qrCode;
            else if(this.showPieceModal) qrCode = this.selectedPiece.codeUn;
            this.$router.push({name: "_demande_intervention", params: {code: qrCode, isScann: true}});
        },
    },
    computed:{
        ...mapGetters({
            fondDePlan: "PlanInteractifStore/getFondDePlan",
            geoJsonPieces: "PlanInteractifStore/getGeoJsonPieces",
            geoJsonPiecesDraft: "PlanInteractifStore/getGeoJsonPiecesDraft",
            geoJsonEquipements: "PlanInteractifStore/getGeoJsonEquipements",
            geoJsonEquipementsDraft: "PlanInteractifStore/getGeoJsonEquipementsDraft",
            pieces: "LieuxStore/getCollection",
            equipements: "EquipementsStore/getCollection",
            newPieceMarker: "PlanInteractifStore/getNewPieceMarker"
        }),
        getTabs: function(){
            return [
                {label: this.$t("tab-pieces"), name:"pieces"},
                {label: this.$t("tab-equipements"), name:"equipements"}
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-plan-interactif-panel{
    height: 100%;
    width: 100%;
    .main-container{
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding: 10px 0;
    }
    .info-drag-and-drop{
        color: #02a7f0;
        font-size: 11px;
    }
}
</style>
