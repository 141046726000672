<template>
	<vg-select v-model="selected"
		:placeholder="$t('select-unite')"
		:options="unites"
		:clearable="false"
		:disabled="disabled"
		@input="$emit('input', selected)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";
    export default {
        name: "vg-unite-selector",
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-unite": "Sélectionné une unité"
        },
        "en": {
            "select-unite": "Select unit of measure"
        }
    }
},
		components: {
			VgSelect
		},
        props: {
			value: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			}
        },
		watch:{

		},
        data: function() {
            return {
				selected: this.value
            };
        },
		created: function(){

		},
		mounted: function(){
			if(this.unites.length && !this.value) this.selected = this.unites[0];
		},
		methods: {

		},
        computed: {
			isFr: function(){
				return this.$lang.toLowerCase()=="fr";
			},
			isEn: function(){
				return this.$lang.toLowerCase()=="en";
			},
			unites: function(){
				if(this.isFr) return ["u.", "kg", "L", "ml", "m", "m2", "m3"];
				else if(this.isEn) return ["unit", "kg", "L", "ml", "m", "m2", "m3", "gal", "inch", "foot", "yard", "grain", "ounce", "pound"];
				else return [];
			},
		}
    };

</script>
<style scoped>

</style>
