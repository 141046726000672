<template>
    <vg-modal
        :title="$t('title')"
        @close="close">
        <template v-slot:body>
            <div class="todo-list">
                <div style="display:flex;flex-direction:column;">
                    <h4 v-if="!seuils.length">{{$t("no-items")}}</h4>
                    <div v-else>
                        <div

                            v-for="(seuil,index) in seuils"
                            :key="index">
                            <div @click="$emit('click',seuil)" class="todo-row">
                                <vg-input v-model="seuil.value" inputType="number" :placeholder="$t('seuil')" disabled style="width:200px;"></vg-input>
                                <vg-input v-model="seuil.unite"  :placeholder="$t('unite')" disabled style="width:200px;"></vg-input>
                                <vg-input v-model="seuil.message" :placeholder="$t('message')" disabled ></vg-input>
                                <vg-button
                                    size="md"
                                    type="default-danger"
                                    @click="deleteSeuil(index)"
                                >
                                    {{$t('delete')}}
                                </vg-button>
                            </div>


                        </div>
                    </div>

                </div>
                <div style="display:flex;gap:10px;margin-top:10px;">
                    <vg-input v-model="seuil.value" inputType="number" :placeholder="$t('seuil')" style="width:200px;"></vg-input>
                    <vg-input v-model="seuil.unite" :placeholder="$t('unite')" style="width:200px;">
                        <vg-select
                            v-model="seuil.unite"
                            :options="[$t('km'),$t('hours'),$t('months'), $t('years')]"
                            >
                        </vg-select>
                    </vg-input>
                    <vg-input v-model="seuil.message" :placeholder="$t('message')"></vg-input>

                    <vg-button
                        type="success"
                        @click="addSeuil"
                    >
                        {{$t('Add')}}
                    </vg-button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <vg-button @click="close">{{$t("validate")}}</vg-button>
        </template>
    </vg-modal>

</template>

<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";

export default {
    name: "checkpoint-seuils",
    props: {
        value:{
            type:Object,
            required:true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-items": "Aucun éléments",
            "add": "Ajouter",
            "title": "Définir des seuils de déclenchement de la maintenance curative",
            "validate": "Valider",
            "update": "Modifier",
            "delete": "Supprimer",
            "km": "km",
            "hours": "Heures",
            "months": "Mois",
            "years": "Année(s)"
        },
        "en": {
            "no-items": "No elements",
            "add": "Add",
            "title": "Set thresholds for curative maintenance triggering",
            "validate": "Validate",
            "update": "Update",
            "delete": "Delete",
            "km": "km",
            "hours": "hours",
            "months": "months",
            "years": "years"
        }
    }
},
    components:{
        VgButton,
        VgInput,
        VgModal,
        VgSelect
    },
    watch:{
        value:{
            handler:function(newValue){
                this.seuils = newValue["seuils"];
            },
            deep:true
        }
    },
    data:function(){
        return {
            items:null,
            seuils:[],
            seuil:{
                "value":null,
                "message":"",
            },
        }
    },
    methods:{
        close:function(){
            this.$emit("change", this.seuils);
            this.$emit('close');
        },
        addSeuil:function(){
            this.seuils.push(Object.assign({},this.seuil));
            //this.$emit("change", this.seuils);
        },
        deleteSeuil:function(index){
            this.seuils.splice(index, 1);
            //this.$emit("change", this.seuils);
        },
    },
    created:function(){
        if(this.value.seuils) this.seuils = [...this.value.seuils];
    }
}
</script>

<style lang="scss" scoped>
.todo-row{

    display:flex;
    justify-content: space-between;
    padding:10px 5px;
    border-bottom:1px solid #d9d9d9;
    width:100%;
    gap:10px;
}
.todo-row:hover{
    background-color:#a2c2ff;
    color:white;
    cursor:pointer;
}
</style>
