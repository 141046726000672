<template>
	<div class="vg-form-reset-password" >
        <a id="reset-password-open-modal"
            @click="showModal=true;">
            {{$t("reset-password")}}
        </a>

		<vg-modal v-if="showModal"
            :title="$t('title')"
            @close="showModal=false;">
			<template #body>
                <p id="reset-password-feedback-area">{{feedback}}</p>
                <form id="reset-password-form" ref="resetPassword" onSubmit="return false;">
                    <vg-input id="reset-password-input-email"
                        :inputType="'email'"
                        :placeholder="$t('placeholder-email')"
                        :pattern="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'"
                        v-model="email" />

                    <input v-show="false" type="submit" ref="btnSubmit" />
                </form>
			</template>
			<template #footer>
				<vg-button id="reset-password-cancel-btn"
                    type="default-danger"
                    @click="showModal=false;">
					{{$t("close")}}
				</vg-button>
				<vg-button id="reset-password-validation-btn"
                    type="danger"
                    @click="handleValidation"
                    :disabled="isDisabledForm">
					{{$t("validation")}}
				</vg-button>
			</template>
		</vg-modal>
	</div>
</template>
<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
    export default {
        name: 'vg-form-reset-password',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Reinitialisation du mot de passe",
            "close": "Fermer",
            "placeholder-email": "Saisir votre adresse email",
            "reset-password": "Mot de passe oublié",
            "validation": "Valider",
            "success-feedback": "Succès! Veuillez consulter votre boîte mail",
            "error-feedback": "erreur"
        },
        "en": {
            "title": "Reset password",
            "close": "Close",
            "placeholder-email": "Enter your email",
            "reset-password": "Forgot password",
            "validation": "validate",
            "success-feedback": "Success! Please check your emails",
            "error-feedback": "error"
        },
        "th": {
            "title": "รีเซ็ตรหัสผ่าน",
            "close": "ปิด",
            "placeholder-email": "กรอกอีเมลของคุณ",
            "reset-password": "ลืมรหัสผ่าน",
            "validation": "ตรวจสอบ",
            "success-feedback": "สำเร็จ! โปรดตรวจสอบอีเมลของคุณ",
            "error-feedback": "ข้อผิดพลาด"
        }
    }
},
		components: {

			VgButton,
            VgInput
		},
        data: function() {
            return {
				showModal: false,
                feedback:"",
                email:null,
                isDisabledForm:false
            };
        },
		methods: {
            handleValidation:function(){
                this.isDisabledForm = true;
                if(!this.email){
                    return;
                }
                var rc = this.$rc;
                var that = this;
                var query = {
                    "email": this.email
                };
                this.$refs['btnSubmit'].click();
                rc.post("/api/reset_password", query, function(response) {
                    if(response.success){
                        that.feedback=that.$t('success-feedback');
                        alert(that.feedback);
                    }else{
                        alert(that.$t('error-feedback'));
                    }
                    that.showModal = false;
                    that.isDisabledForm = false;

				},function(err){
                    //console.log(err)

                });

            }
        }
    };

</script>
<style lang="scss" scoped>

</style>
