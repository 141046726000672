<template>
    <div class="full-height">
        <vg-app-layout
            :title="$t('page-title')"
            :icon="$vgutils.isMobile()?'cle_blanc.png':'fiche-de-maintenance.png'"
            :colorheader="getHeaderColor"
            :activeFilters="activeFilters"
            :filtersTitle="$t('modal-filters-title')"
            @action-export="handleActionExport"
            @remove-filters="onRemoveFilters">

            <template v-slot:search>
                <vg-text-filter
                    v-model="searchQuery">
                </vg-text-filter>
            </template>

            <template #panel-filters-attributs>
                <vg-input :title="$t('mes-affectations')">
                    <vg-checkbox
                        :defaultChecked="agfilters.affectations.value"
                        @checked="showMesAffectations"
                        @unchecked="showMesAffectations">
                    </vg-checkbox>
                </vg-input>
                <br>
                <vg-filter-tags v-if="focusedTab=='tache' || focusedTab=='a-prevoir'"
                    v-model="agfilters.tags.value"
                    :type="getTypeTags">
                </vg-filter-tags>
            </template>
            <template #panel-filters-localisation>
                <vg-filter-site
                   v-model="agfilters.path.value">
                </vg-filter-site>
            </template>
            <template #panel-widget-lieux>
                <vg-tree-lieux
                    v-model="agfilters.path.value"/>
            </template>

            <template v-slot:primary>
                <vg-tabs
                    :tabs="tabs"
                    :color="'jaune'"
                    @tabClicked="handleTabClick">
                </vg-tabs>
                <vg-operations-viewer
                    :idTableau="'operations-tache-a-prevoir'"
                    :flags="['tache','a_prevoir']"
                    :searchQuery="searchQuery"
                    :agfilters="agfilters"
                    :localFilters="localFilters"
                    verbose
                    @update="onShowUpdateOperation"
                    @delete="onDeleteOperation">
                </vg-operations-viewer>
            </template>

            <template v-slot:pagination>
            </template>

            <template #secondary-filters>
                <vg-input :title="$t('mes-affectations')">
                    <vg-checkbox
                        :defaultChecked="agfilters.affectations.value"
                        @checked="showMesAffectations"
                        @unchecked="showMesAffectations">
                    </vg-checkbox>
                </vg-input>

                <br>

                <vg-filter-site
                   v-model="agfilters.path.value">
                </vg-filter-site>

                <vg-filter-tags v-if="focusedTab=='tache' || focusedTab=='a-prevoir'"
                    v-model="agfilters.tags.value"
                    :type="getTypeTags">
                </vg-filter-tags>
            </template>
            <template #secondary-widget>
                <vg-tree-lieux
                    v-model="agfilters.path.value"/>
            </template>

                <vg-operation-update v-if="isUpdatingOperation"
                    v-model="focusedOperation"
                    :flag="focusedOperation.flag"
                    @close="isUpdatingOperation=false"
                    @save="isUpdatingOperation=false;focusedOperation=null;"
                >
                </vg-operation-update>

        </vg-app-layout>
    </div>
</template>

<script>
    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgFilterIntervalDate from "src/components/Vg/FicheSAV/VgFilterIntervalDate.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgFilterTags from "src/components/Vg/Filters/VgFilterTags.vue";
    import VgOperationsViewer from "src/components/Vg/Operation/VgOperationsViewer.vue";
    import VgOperationUpdate from "src/components/Vg/Operation/VgOperationUpdate.vue";

    import OperationMixins from "src/mixins/OperationMixins.js";

    import Metadatas from "src/services/Metadatas.js";

    export default {
        name: 'maintenance-operations',
        components: {
            VgTabs,
            VgTextFilter,
            VgFilterIntervalDate,
            VgInput,
            VgSelect,
            VgCheckbox,
            VgTreeLieux,
            VgGroup,
            VgButton,
            VgFilterSite,
            VgFilterTags,
            VgOperationsViewer,
            VgOperationUpdate
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Sous-actions fiches curatives",
            "en-cours": "Toutes (fiches en cours)",
            "resolue": "Toutes (fiches résolues)",
            "tache": "Taches",
            "a-prevoir": "A prévoir",
            "filtres": "Filtres",
            "mes-affectations": "Voir mes affectations",
            "modal-filters-title": "Filtrer les taches/à prévoir"
        },
        "en": {
            "page-title": "Curative sheets sub-actions",
            "en-cours": "All (sheets in progress)",
            "resolue": "All (resolved sheets)",
            "tache": "Tasks",
            "a-prevoir": "To Provide",
            "filtres": "Filters",
            "mes-affectations": "See my assignments",
            "modal-filters-title": "Filter tasks/to provide"
        }
    }
},
		mixins:[
            OperationMixins
        ],
        data: function() {
            return {
    			searchQuery: "",
                focusedTab:null,
                isModalConfirmDeleteOpened:false,
                agfilters: {
                    statut: {attr:"fm.statut", colId: "statut", value:"Resolue", action:"not_equals"},
                    path: {attr:"l.path", colId: "path", value:null, action:"start_with"},
                    tags: {attr:"top.tag_id", colId: "tags", value:null, action:"equals"},
                    affectations: {attr: "affu.user_id", value: null, action: "equals"}
                },
                localFilters: [],
                isUpdatingOperation:false,
                focusedOperation: null
            }
        },
        watch:{
            agfilters:{
                handler:function(nfilters){

                },
                deep:true
            },
            searchQuery:{
                handler: function(query){

                }
            }
        },
        methods:{
            onRemoveFilters: function(){
                this.pageFilters.forEach((filterName)=>{
                    this.agfilters[filterName].value = null;
                });
                this.searchQuery = null;
            },
            showMesAffectations: function(e){
                this.agfilters.affectations = {attr: "affu.user_id", value: e.value?this.$app.idUser:null, action: "equals"};
            },
            onShowUpdateOperation: function(operation){
                this.focusedOperation = operation;
                this.isUpdatingOperation = true;
            },
            /**
            * @param Object operation
            */
            onDeleteOperation: function(operation){
                this.OperationMixins_delete(operation).then((data)=>{

                });
            },
            handleTabClick: function(e){
                if(e.name!=this.focusedTab){
                    this.focusedTab = e.name;
                    this.agfilters = Object.assign(this.agfilters, e.filters);
                    this.localFilters = e.localFilters?Object.values(e.localFilters):[];
                }
    		},
            handleActionExport:function(e){
                let metadatas = new Metadatas();
                metadatas.setFilters(this.agfilters);
                switch (e.name) {
                    case "askForXLS":
                        this.OperationMixins_getFile(metadatas,"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.OperationMixins_getFile(metadatas,"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": {"idTableau":"operations-tache-a-prevoir"}
                        }));
                        break;
                    default:
                }

            },
            goToMaintenance: function(maintenance){
                this.$router.push({ name: '_maintenance', params: { id: maintenance.id, datas: maintenance } });
            }
        },
		created: function(){

		},
		mounted: function(){

		},
        computed:{
            pageFilters: function(){
                return ["tags", "path", "affectations"];
            },
            activeFilters: function(){
                let filtersNames = this.pageFilters;
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                let nFilters = 0;
                if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
                return activeFilters.length + nFilters;
            },
            getTypeTags: function(){
                if(this.focusedTab=="tache") return ["operation/tache"];
                else if(this.focusedTab=="a-prevoir") return ["operation/a-prevoir"];
                return ["operation/tache", "operation/a-prevoir"];
            },
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
            tabs: function(){
                return [
                    {label: this.$t("en-cours"), name:"encours", counter: null, filters:
                        {
                            statut: {attr:"fm.statut", colId: "statut", value:"Resolue", action:"not_equals"},
                            tags: {attr:"top.tag_id", colId: "tags", value:null, action:"equals"}
                        }
                    },
                    {label: this.$t("tache"), name:"tache", counter: null, filters:
                        {
                            statut: {attr:"fm.statut", colId: "statut", value:"Resolue", action:"not_equals"},
                            tags: {attr:"top.tag_id", colId: "tags", value:null, action:"equals"}
                        },
                        localFilters:{
                            flag: {attr:"op.flag", colId: "flag", value:"tache", action:"equals"}
                        }
                    },
                    {label: this.$t("a-prevoir"), name:"a-prevoir", counter: null, filters:
                        {
                            statut: {attr:"fm.statut", colId: "statut", value:"Resolue", action:"not_equals"},
                            tags: {attr:"top.tag_id", colId: "tags", value:null, action:"equals"}
                        },
                        localFilters:{
                            flag: {attr:"op.flag", colId: "flag", value:"a_prevoir", action:"equals"}
                        }
                    },
                    {label: this.$t("resolue"), name:"resolue", counter: null, filters:
                        {
                            statut: {attr:"fm.statut", colId: "statut", value:"Resolue", action:"equals"},
                            tags: {attr:"top.tag_id", colId: "tags", value:null, action:"equals"}
                        }
                    }
                ];
    		}
        }
    };
</script>
<style lang="scss" scoped>

</style>
