<template>
    <vg-select
        v-model="selectedType"
        :options="types"
        :attributeValue="'type'"
        :attributeLabel="'type'"
        :placeholder="$t('select-sortie-type')"
        @input="handleInputChange" />
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import SortieEquipementMixins from "src/mixins/SortieEquipementMixins.js";
import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: "vg-sortie-type-selector",
    mixins:[SortieEquipementMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        multiple:{
            type:Boolean,
            default:false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-sortie-type": "Sélectionner un type de sortie"
        },
        "en": {
            "select-sortie-type": "Select output type"
        }
    }
},
    data: function() {
        return {
            selectedType: this.value,
            agfilters: {},
            types: []
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selectedType = newValue;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        fetch: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agfilters);
            this.SortieEquipementMixins_getTypes(metadatas).then((reponse)=>{
                this.types = reponse.datas;
            });
        },
        /**
        * Emit input type.
        * @event input
        * @param String type
        */
        handleInputChange:function(type){
            this.$emit("input", type);
            this.$emit("change", type);
        }
    },
    computed:{

    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-sortie-type-selector:

    #### basic usage

    ```html static
        <vg-sortie-type-selector
            v-model="agfilters.typeSortie.value" />
    ```
</docs>
