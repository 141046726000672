<template>
    <div class="vg-button-group">
        <!-- only buttons -->
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "vg-button-group"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .vg-button-group {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        >*{
            margin-right: 0px !important;
        }
    }
</style>
<docs>

    ### Buttons group
    ```js
    new Vue({
      data(){
        return {
        }
      },
      template: `
        <div>
            <vg-button-group>
                <vg-button type="default">Button</vg-button>
                <vg-button type="danger">Button</vg-button>
                <vg-button type="default-danger">Button</vg-button>
            </vg-button-group>
        </div>
      `
    })
    ```

</docs>
