<template>
    <vg-button :type="type" size="sm" @click="handleClick">{{label}}</vg-button>
</template>

<script>
import Vue from "vue";
import VgButton from "src/components/Vg/VgButton";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"button-cell-render",
    mixins:[LangMixins],
    components:{VgButton},
	props: {
		params: {
			type: Object,
			required: true
		},
		tooltipTitle: {
			type: String,
			default: "default tooltip title"
		},
		type: {
			type: String,
			default: "default-info"
		},
		label: {
			type: String,
			default: ""
		},
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "details": "voir détails"
        },
        "en": {
            "details": "Details"
        },
        "th": {
            "details": "รายละเอียด"
        }
    }
},
    methods:{
        handleClick:function(){
            this.$emit("click",this.params);
        }
    }
});
</script>

<style scoped>

</style>
