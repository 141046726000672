<template>
    <vg-modal
        :title="typeLieu=='Piece'?$t('modal-title-pieces'):$t('modal-title-sites')"
        :isSaveDisabled="!this.selectedLieux || !this.selectedField || !this.userEntry"
        @close="close"
        @save="handleSaveUpdateLieux">
        <template #body>
            <form id="deplacements-equipement-form"
                ref="deplacements-equipements-form"
                onSubmit="return false;">
                <vg-select
                    v-model="selectedField"
                    :options="typeLieu=='Piece'?fieldsPieces:fieldsSites"
                    :attributeLabel="'label'"
                    :attributeValue="'value'"
                >
                </vg-select>
                <br>
                <vg-input v-if="selectedField=='categorie_id'">
                    <vg-categorie-selector
                        v-model="userEntry"
                        isGe
                        :clearable="false"/>
                </vg-input>
                <vg-input v-if="selectedField=='service'">
                    <vg-service-selector
                        v-model="userEntry"/>
                </vg-input>
                <input
                  v-show="false"
                  ref="lieu-submit"
                  type="submit"
                >
            </form>
        </template>
    </vg-modal>
</template>
<script>

import LieuMixins from "src/mixins/LieuMixins.js";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: 'vg-update-lieux-form',
    components:{
        VgSelect,
        VgInput,
        VgCategorieSelector,
        VgServiceSelector
    },
    mixins: [LieuMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-pieces": "Modifier les pièces sélectionnées",
            "modal-title-sites": "Modifier les sites sélectionnés",
            "categorie": "Catégorie",
            "service": "Service",
            "famille": "Famille",
            "type-erp": "Type ERP"
        },
        "en": {
            "modal-title-pieces": "Update selected rooms",
            "modal-title-sites": "Update selected sites",
            "categorie": "Category",
            "service": "Service",
            "famille": "Site category",
            "type-erp": "Public access building category"
        }
    }
},
    props: {
        typeLieu:{
            type: String,
            default: "Piece"
        }
    },
    data: function() {
        return {
            fieldsPieces:[
                {label:this.$t("categorie"),value:"categorie_id"},
                {label:this.$t("service"),value:"service"}
            ],
            fieldsSites:[
                {label:this.$t("famille"),value:"famille"},
                {label:this.$t("type-erp"),value:"typeErp"}
            ],
            selectedField:null,
            userEntry:null
        };
    },
    watch: {},
    created: function(){},
    mounted: function(){},
    computed:{
        ...mapGetters({
            selectedLieux: "LieuxStore/getSelectedItems"
        }),
        selectedFieldTranslated:function(){
            return this.$t(this.selectedField);
        }
    },
    methods: {
        close:function(){
            this.$emit("close");
        },
        handleSaveUpdateLieux:function(){
            let formatedLieu = null;
            let formatedLieux = this.selectedLieux.map((lieu)=>{
                formatedLieu = {
                    id: lieu.id,
                    userId: lieu.userId
                };
                formatedLieu[this.selectedField] = this.userEntry;
                return formatedLieu;
            });
            this.LieuMixins_update_lieux(formatedLieux).then((datas)=>{
                this.$emit("save");
            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
