<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		hideDatePickerShortcut
		:hideHeaderComplement="hideHeaderComplement"
		key="dashboard-curatif-rapport-repartition-age"
		@drop="onDrop"
		@drag-start="onDragStart">
		<vg-bar-chart v-if="repartitionAge"
			class="chart-bar"
			:chart-id="'dashboard-curatif-repartition-age'"
			:height="'100%'"
			:datas="getDatas"
			:horizontalaxis="xAxis"
			:options="optionsBar"
			:showlegend="false"
			@selected="onSelectedBar" />
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-dashboard-curatif-repartition-age',
		mixins: [ DashboardMixins ],
		components: {
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			hideHeaderComplement: {
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				xAxis: [ this.$t('plus-30-j'), this.$t('plus-15-j'), this.$t('plus-7-j'), this.$t('plus-1-j') ],
				optionsBar: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 5
							},
							gridLines: {
								display: true,
								zeroLineColor: "#80b6de",
								color: "#F0F0F0"
							}
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							ticks: {
								mirror: true
							}
						}]
					},
					animation: {
						easing: "linear",
						onProgress: (e)=>this.drawCustomBarElements(e),
						onComplete: (e)=>this.drawCustomBarElements(e)
					}
				},
            };
        },
		i18n:    { "locale":navigator.language,
		    "messages": {
		        "fr": {
		            "title": "Fiches curatives ouvertes répartition age",
		            "plus-30-j": "Plus de 30 j",
		            "plus-15-j": "Plus de 15 j",
		            "plus-7-j": "Plus de 7 j",
		            "plus-1-j": "Plus de 1 j",
		            "bar-hover-label": "Nombre de fiches curatives ouvertes"
		        },
		        "en": {
		            "title": "Open curative sheets age distribution",
		            "plus-30-j": "More than 30 days",
		            "plus-15-j": "More than 15 days",
		            "plus-7-j": "More than 7 days",
		            "plus-1-j": "More than 1 day",
		            "bar-hover-label": "Number of open curative sheets"
		        }
		    }
		},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onSelectedBar: function(bar){
				let repartitionAgeValues = [30, 15, 7, 1];
				let ageValue = repartitionAgeValues[bar.index];
				let dateMax = moment().subtract(ageValue, "days").format("YYYY-MM-DD");
				let filters = [
					{attr: "fm.dateOuvertureSAV", value: dateMax, action: "less_than"},
					{attr: "fm.statut", value: "Resolue", action: "not_equals"}
				];
				this.$emit("selected", {bar: ageValue, filters: filters});
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				this.metadatas.setFilters(filters);
				this.DashboardMixins_getCuratifRepartitionAge(this.metadatas).then((datas)=>{

				});
			},
			drawCustomBarElements: function(e){
				let ctx = e.chart.ctx;
				let datasets = e.chart.config.data.datasets;
				let meta = null;
			},
		},
        computed: {
			...mapGetters({
	            repartitionAge: 'DashboardStore/getCuratifRepartitionAge',
	        }),
			getDatas: function(){
				let bar = {
					label: this.$t("bar-hover-label"),
					value: "age",
					backgroundColor: ["#CB4335", "#EB984E", "#F8C471", "#F7DC6F"],
					borderColor: "#fff",
					borderWidth: 1,
					data: [Number(this.repartitionAge["30"]), Number(this.repartitionAge["15"]), Number(this.repartitionAge["7"]), Number(this.repartitionAge["1"])],
					stack: "age",
					hoverBackgroundColor: ["#CB4335", "#EB984E", "#F8C471", "#F7DC6F"],
					hoverBorderColor: ["#CB4335", "#EB984E", "#F8C471", "#F7DC6F"],
					hoverBorderWidth: 1,
					filters: []
				};
				return [bar];
			}
		}
    };

</script>
<style lang="scss" scoped>

</style>
