
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
let actions = Base.getActions();
let mutations = Base.getMutations();
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
