<template>
    <div class="vg-statistiques-maintenance-dashboard">
        <div v-if="showLeftPanel" class="charts-left-panel">
            <!-- LEGENDS -->
            <div class="chart-legend">
                <div v-for="stat in statistiquesBarEtatLegends">
                    <div v-if="stat.label" class="chart-legend-item">
                        <div class="rect" :style="'backgroundColor:'+stat.color"></div>
                        {{stat.label}}
                    </div>
                </div>
            </div>
            <vg-filter-site
                v-model="filtreSites"
                :multiple="true"
                :display="'modal'"
                :isAllSitesSelected="true"
                @input="onChangeFiltreSites">
            </vg-filter-site>
        </div>
        <div :class="['charts-container',{'width-100-percent':!showLeftPanel}]">
            <div class="chart-section">
                <vg-bar-chart class="chart-bar"
                    :chart-id="'statistiques-etat-annuelle'"
                    :height="'250px'"
                    :datas="getStatistiquesAnnuelle"
                    :horizontalaxis="yearXAxis"
                    :options="optionsBarStatistiques"
                    :showlegend="false"
                    @selected="onSelectedMonthlyBar">
                </vg-bar-chart>
                <div class="chart-selector">
                    <vg-year-selector
                        v-model="period"
                        @input="onInputPeriod">
                    </vg-year-selector>
                </div>
            </div>
            <br>
            <div class="chart-section">
                <vg-bar-chart class="chart-bar"
                    :chart-id="'statistiques-etat-hebdomadaire'"
                    :height="'150px'"
                    :datas="getStatistiquesHebdomadaire"
                    :horizontalaxis="weekXAxis"
                    :options="optionsBarStatistiques"
                    :showlegend="false"
                    @selected="onSelectedDailyBar">
                </vg-bar-chart>
                <div class="chart-selector">
                    <vg-week-selector
                        v-model="period"
                        @input="onInputPeriod">
                    </vg-week-selector>
                </div>
            </div>
        </div>
        <vg-modal v-if="showMaintenancesListModal"
            :width="'80vw'"
            :height="'75vh'"
            @close="onCloseMaintenancesListModal">
            <template #header>
                {{$t('modal-details-title')}} :
                <span>{{$t("du")}} {{intervalDate.start | readableDate}} {{$t("au")}} {{intervalDate.end | readableDate}}</span>
            </template>
            <template v-slot:body>
                <div class="full-height" :style="{'width': selectedFm?'70%':'100%'}">
                    <div style="display:flex;" >
                        <vg-tabs style="width:50%;" class="hidden-print"
                            :tabs="modaltabs"
                            :color="'jaune'"
                            @tabClicked="onModalTabClicked">
                        </vg-tabs>
                        <vg-text-filter style="width:50%;" class="hidden-print"
                            v-model="searchQuery">
                        </vg-text-filter>
                    </div>
                    <vg-fichesav-table
                        :searchQuery="searchQuery"
                        :filters="maintenanceFilters"
                        :localFilters="maintenanceLocalFilters"
                        @row-click="onRowClick">
                    </vg-fichesav-table>
                </div>

            </template>
            <template v-slot:footer>
                <vg-button
                    type="default"
                    @click="onCloseMaintenancesListModal">
                    {{$t("fermer")}}
                </vg-button>
                <vg-button
                    type="success"
                    @click="printingSheet = true;"
                    :disabled="!(maintenances && maintenances.length!=0)">
                    {{$t("generer-rapport")}}
                </vg-button>
                <vg-button
                    type="success"
                    @click="exportCSV()">
                    {{$t("export-csv")}}
                </vg-button>
            </template>
        </vg-modal>
    </div>
</template>

<script>
import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
import VgYearSelector from 'src/views/Statistiques/components/VgYearSelector.vue';
import VgWeekSelector from 'src/views/Statistiques/components/VgWeekSelector.vue';
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgButton from 'src/components/Vg/VgButton.vue';
import VgFichesavTable from 'src/components/Vg/FicheSAV/VgFichesavTable.vue';

import StatistiquesMixins from 'src/mixins/StatistiquesMixins.js';
import { mapGetters } from 'vuex';

export default {
    name: "vg-statistiques-maintenance-dashboard",
    components:{
        VgBarChart,
        VgYearSelector,
        VgWeekSelector,
        VgFilterSite,
        VgTabs,

        VgButton,
        VgFichesavTable
    },
    mixins: [StatistiquesMixins],
    props: {
        filters: {
            type: Array,
            default: function(){
                return [];
            }
        },
        showLeftPanel: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            period: {
				year: moment().year(),
				week: moment().week()
			},
            statistiquesAnnuelle: [],
            statistiquesHebdomadaire: [],
            filtreSites: null,
            yearXAxis: [this.$t('janvier'),this.$t('fevrier'),this.$t('mars'),this.$t('avril'),this.$t('mai'),this.$t('juin'),this.$t('juillet'),this.$t('aout'),this.$t('septembre'),this.$t('octobre'),this.$t('novembre'),this.$t('decembre')],
			weekXAxis: [this.$t('lundi'),this.$t('mardi'),this.$t('mercredi'),this.$t('jeudi'),this.$t('vendredi'),this.$t('samedi'),this.$t('dimanche')],
			optionsBarStatistiques: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						display: false,
						gridLines: {
							display: true
						},
						stacked: true
					}],
					xAxes: [{
						gridLines: {
							display: false
						},
						ticks: {
							mirror: true
						},
						stacked: true
					}]
				},
				animation: {
					easing: "linear",
					onProgress: (e)=>this.drawCustomBarElements(e),
					onComplete: (e)=>this.drawCustomBarElements(e)
				}
			},
            showMaintenancesListModal: false,
            focusedChartItem: null,
            maintenanceFilters: [],
            searchQuery: "",
			maintenanceLocalFilters: null,
			printingSheet: false,
			selectedFm: null
        }
    },
    filters:{
		readableDate: function(d){
			return moment(d).format("DD/MM/YYYY");
		}
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "ouvertures": "Ouvertures",
            "fermetures": "Fermetures",
            "modal-details-title": "Activité curative",
            "du": "du",
            "au": "au",
            "fermer": "Fermer",
            "generer-rapport": "Générer rapport pdf",
            "export-csv": "Export csv",
            "janvier": "Janvier",
            "fevrier": "Février",
            "mars": "Mars",
            "avril": "Avril",
            "mai": "Mai",
            "juin": "Juin",
            "juillet": "Juillet",
            "aout": "Août",
            "septembre": "Septembre",
            "octobre": "Octobre",
            "novembre": "Novembre",
            "decembre": "Décembre",
            "lundi": "Lundi {date}",
            "mardi": "Mardi {date}",
            "mercredi": "Mercredi {date}",
            "jeudi": "Jeudi {date}",
            "vendredi": "Vendredi {date}",
            "samedi": "Samedi {date}",
            "dimanche": "Dimanche {date}",
            "tabs-tab-toutes": "Toutes",
            "tabs-tab-encours": "En cours",
            "tabs-tab-resolue": "Résolue"
        },
        "en": {
            "ouvertures": "Opened",
            "fermetures": "Closed",
            "modal-details-title": "Curative activity",
            "du": "from",
            "au": "to",
            "fermer": "Close",
            "generer-rapport": "Create PDF",
            "export-csv": "Export csv",
            "janvier": "January",
            "fevrier": "February",
            "mars": "March",
            "avril": "April",
            "mai": "May",
            "juin": "June",
            "juillet": "July",
            "aout": "August",
            "septembre": "September",
            "octobre": "October",
            "novembre": "November",
            "decembre": "Décember",
            "lundi": "Monday {date}",
            "mardi": "Tuesday {date}",
            "mercredi": "Wednesday {date}",
            "jeudi": "Thursday {date}",
            "vendredi": "Friday {date}",
            "samedi": "Saturday {date}",
            "dimanche": "Sunday {date}",
            "tabs-tab-toutes": "All",
            "tabs-tab-encours": "Pending",
            "tabs-tab-resolue": "Done"
        },
        "th": {
            "ouvertures": "เปิดแล้ว",
            "fermetures": "ปิดแล้ว",
            "du": "ตั้งแต่",
            "au": "ถึง",
            "fermer": "ปิด",
            "generer-rapport": "สร้าง PDF",
            "export-csv": "ส่งออก csv",
            "janvier": "มกราคม",
            "fevrier": "กุมภาพันธ์",
            "mars": "มีนาคม",
            "avril": "เมษายน",
            "mai": "พฤษภาคม",
            "juin": "มิถุนายน",
            "juillet": "กรกฏาคม",
            "aout": "สิงหาคม",
            "septembre": "กันยายน",
            "octobre": "ตุลาคม",
            "novembre": "พฤศจิกายน",
            "decembre": "ธันวาคม",
            "lundi": "วันจันทร์ {date}",
            "mardi": "วันอังคาร {date}",
            "mercredi": "วันพุธ {date}",
            "jeudi": "วันพฤหัสบดี {date}",
            "vendredi": "วันศุกร์ {date}",
            "samedi": "วันเสาร์ {date}",
            "dimanche": "วันอาทิตย์ {date}",
            "tabs-tab-toutes": "ทั้งหมด",
            "tabs-tab-encours": "กำลังรอดำเนินการ",
            "tabs-tab-resolue": "ทำแล้ว"
        }
    }
},
    created: function(){
        this.fetch();
    },
    methods: {
        setWeekAxis: function(){
			this.weekXAxis = [
				this.$t('lundi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(1).format("DD / MM")}),
				this.$t('mardi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(2).format("DD / MM")}),
				this.$t('mercredi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(3).format("DD / MM")}),
				this.$t('jeudi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(4).format("DD / MM")}),
				this.$t('vendredi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(5).format("DD / MM")}),
				this.$t('samedi',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(6).format("DD / MM")}),
				this.$t('dimanche',{date:moment().year(this.period.year).week(this.period.week).isoWeekday(7).format("DD / MM")})
			];
		},
        onInputPeriod: function(period){
            let dt = moment().year(this.period.year).week(this.period.week);
			this.period.startdate = dt.startOf("isoWeek").format("YYYY-MM-DD");
			this.period.enddate = dt.endOf("isoWeek").format("YYYY-MM-DD");
			this.fetch();
		},
        exportCSV: function(){
			window.dispatchEvent(new CustomEvent("askForCSV", {
				"detail": {"idTableau": "vg-fichesav-table"}
			}));
		},
        onCloseMaintenancesListModal: function(){
			this.showMaintenancesListModal = false;
			this.maintenanceFilters = [];
			this.focusedChartItem = null;
			this.selectedFm = null;
		},
        drawCustomBarElements: function(e){
			let ctx = e.chart.ctx;
			let datasets = e.chart.config.data.datasets;
			let meta = null;
			// draw x axis line
			ctx.beginPath();
			ctx.moveTo(0, ctx.canvas.height-25);
			ctx.lineTo(ctx.canvas.width, ctx.canvas.height-25);
			ctx.closePath();
			ctx.lineWidth = 1;
			ctx.strokeStyle = "#787878";
			ctx.stroke();
		},
        onSelectedMonthlyBar: function(e){
            let statistiques = this.getStatistiquesAnnuelle;
			this.focusedChartItem = statistiques[e.datasetIndex];
			this.maintenanceFilters = this.focusedChartItem.filters || [];
			let monthFirstDay = moment(this.period.year+"-"+(e.index+1)+"-01").format("YYYY-MM-DD");
			let monthLastDay = moment(this.period.year+"-"+(e.index+1)+"-01").endOf("month").format("YYYY-MM-DD");
            if(this.filtreSites && this.filtreSites.length!=0) this.maintenanceFilters.push({"attr":"l.path","value":this.filtreSites,"action":"start_with"});
            if(this.filters && this.filters.length!=0) this.maintenanceFilters = this.maintenanceFilters.concat(this.filters);
            this.maintenanceFilters.push({"attr":e.datasetIndex==0?"fm.dateOuvertureSAV":"fm.dateFermetureSAV","value":[monthFirstDay, monthLastDay],"action":"between"});
            this.showMaintenancesListModal = true;
		},
		onSelectedDailyBar: function(e){
			let statistiques = this.getStatistiquesHebdomadaire;
			this.focusedChartItem = statistiques[e.datasetIndex];
			this.maintenanceFilters = this.focusedChartItem.filters || [];
			let day = moment().year(this.period.year).week(this.period.week).isoWeekday(e.index+1).format("YYYY/MM/DD");
            if(this.filtreSites && this.filtreSites.length!=0) this.maintenanceFilters.push({"attr":"l.path","value":this.filtreSites,"action":"start_with"});
            if(this.filters && this.filters.length!=0) this.maintenanceFilters = this.maintenanceFilters.concat(this.filters);
            this.maintenanceFilters.push({"attr":e.datasetIndex==0?"fm.dateOuvertureSAV":"fm.dateFermetureSAV","value":[day+" 00:00:00", day+" 23:59:59"],"action":"between"});
			this.showMaintenancesListModal = true;
		},
        onChangeFiltreSites: function(sites){
            this.fetch();
        },
        fetch: function(){
            this.StatistiquesMixins_fetchStatistiquesMaintenanceEtat(this.period.year, null, this.getEtatMetadatas).then((statistiques)=>{
                this.statistiquesAnnuelle = statistiques;
            });
            this.StatistiquesMixins_fetchStatistiquesMaintenanceEtat(this.period.year, this.period.week, this.getEtatMetadatas).then((statistiques)=>{
                this.statistiquesHebdomadaire = statistiques;
            });
            this.setWeekAxis();
		},
    },
    computed: {
        ...mapGetters({
            maintenanceCounters: 'MaintenancesStore/getMaintenancesCounters',
        }),
        getEtatMetadatas: function(){
            let metadatas = {
                directives:[],
                filters:[
                    {attr:"isReserve",value:"0",action:"equals"}
                ]
            };
            if(this.filtreSites && this.filtreSites.length!=0){
                metadatas.filters.push({"attr":"l.path","value":this.filtreSites,"action":"start_with"});
            }
            if(this.filters && this.filters.length!=0) metadatas.filters = metadatas.filters.concat(this.filters);
			return metadatas;
		},
        intervalDate: function(){
            let maintenanceFilterDateIntervalle = this.maintenanceFilters.find((maintenance)=>maintenance.attr=="fm.dateOuvertureSAV" || maintenance.attr=="fm.dateFermetureSAV");
            return {
				start: maintenanceFilterDateIntervalle.value[0],
				end: maintenanceFilterDateIntervalle.value[1]
			};
		},
        modaltabs: function(){
			return [
				{label: this.$t("tabs-tab-toutes"), name:"toutes", counter: 0, filters: [
					{colId: "statut", value:null}
				]},
				{label: this.$t("tabs-tab-encours"), name:"encours", counter: 0, filters: [
					{colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"]}
				]},
				{label: this.$t("tabs-tab-resolue"), name:"résolue", counter: 0, filters: [
					{colId: "statut", value:["Resolue"]}
				]}
			];
		},
        getStatistiquesAnnuelle: function(){
			let ouvertures = { label: this.$t("ouvertures"), value: "ouvertures", backgroundColor: "#f0c0c0", data: [], stack: "ouvertures", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
			filters: [] };
			let fermetures = { label: this.$t("fermetures"), value: "fermetures", backgroundColor: "#84e6cd", data: [], stack: "fermetures", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
			filters: [] };
			let statistiques = this.statistiquesAnnuelle;
			for(let key in statistiques){
				ouvertures.data.push(statistiques[key].ouvertures);
				fermetures.data.push(statistiques[key].fermetures);
			}
			return [ouvertures, fermetures];
		},
        getStatistiquesHebdomadaire: function(){
			let ouvertures = { label: this.$t("ouvertures"), value: "ouvertures", backgroundColor: "#f0c0c0", data: [], stack: "ouvertures", hoverBackgroundColor: "#f0c0c0", hoverBorderColor: "ccc", hoverBorderWidth: 1,
			filters: [] };
			let fermetures = { label: this.$t("fermetures"), value: "fermetures", backgroundColor: "#84e6cd", data: [], stack: "fermetures", hoverBackgroundColor: "#84e6cd", hoverBorderColor: "ccc", hoverBorderWidth: 1,
			filters: [] };
			let statistiques = this.statistiquesHebdomadaire;
			for(let key in statistiques){
				ouvertures.data.push(statistiques[key].ouvertures);
				fermetures.data.push(statistiques[key].fermetures);
			}
			return [ouvertures, fermetures];
		},
        statistiquesBarEtatLegends: function(){
			return {
				"ouvertures": { color: "#f0c0c0", label: this.$t("ouvertures") },
				"fermetures": { color: "#84e6cd", label: this.$t("fermetures") }
			};
		},
    }
}
</script>

<style lang="scss" scoped>
.vg-statistiques-maintenance-dashboard{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    height: 100%;
    .charts-left-panel{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 20%;
        height: 100%;
        border-right: 1px solid #ccc;
        padding:0 20px;
        .chart-legend{
    		.chart-legend-item{
    			display: flex;
    			justify-content: flex-start;
    			align-items: center;
    			.rect{
    				width: 24px;
    				height: 12px;
    				margin-right: 10px;
    			}
    		}
    	}
    }
    .charts-container{
		width: 80%;
		min-width: 400px;
		height: 100%;
		align-items: center;
        .chart-section{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.chart-bar{
				width: 100%;
                padding: 40px;
			}
            .chart-selector{
                width: 200px;
    			text-align: center;
    		}
		}
	}
    .width-100-percent{
        width: 100% !important;
    }
}
</style>
