<template>
    <vg-app-layout
        :title="$t('header-title')"
        :icon="'equipement.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        @remove-filters="onRemoveFilters"
        @action-export="actionExport">

        <template #search>
            <vg-text-filter v-model="searchQuery" />
        </template>

        <template #create-button>
            <vg-button v-if="activeTab=='tous' || activeTab=='preventif-interne'"
                type="success"
                @click="showCreateTache=true;">
                {{$t("btn-create-tache")}}
            </vg-button>
            <vg-button v-if="activeTab=='tous' || activeTab=='preventif-externe'"
                type="success"
                @click="showCreateContrat=true;">
                {{$t("btn-create-contrat")}}
            </vg-button>
        </template>

        <template #secondary-filters>
            <vg-input :title="$t('filtre-categorie')">
                <vg-categorie-selector v-model="agfilters.field_libelleCatgorie.value" 
                    :attributeValue="'libelleCatgorie'" />
            </vg-input>
            <vg-filter-tiers v-model="agfilters.field_tiers_id.value"/>
            <!--vg-input :title="$t('filtre-affecte')">
                <vg-users-selector v-model="agfilters.field_affecte_id.value" />
            </vg-input-->
            <vg-filter-site v-model="agfilters.field_site.value"/>
        </template>

        <template #header-bottom-left>
            <vg-tabs :tabs="tabs"
                :color="'gris'"
                @tabClicked="handleTabClicked" />
        </template>

        <template #primary>
            <vg-plan-maintenance-preventive-table
                :filters="agfilters"
                :isOnlyTaches="activeTab=='preventif-interne'"
                :isOnlyContrats="activeTab=='preventif-externe'" />
        </template>

        <template #tools>
            <vg-tache-form v-if="showCreateTache" 
                :hasTypeTache="false"
                @close="showCreateTache=false;"
                @created="onCreate"/>
            <vg-contrat-form v-if="showCreateContrat"  
                @close="showCreateContrat=false;"
                @created="onCreate" />
        </template>
    </vg-app-layout>
</template>

<script>
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgPlanMaintenancePreventiveTable from "src/components/Vg/PlanMaintenancePreventive/VgPlanMaintenancePreventiveTable.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTacheForm from 'src/components/Vg/Forms/VgTacheForm.vue';
import VgContratForm from 'src/components/Vg/Forms/VgContratForm.vue';
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import FiltersMixins from 'src/mixins/FiltersMixins';

export default {
    name:"plan-maintenance-preventive",
    components:{
        VgTabs,
		VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgCollapse,
        VgGroup,
        VgPlanMaintenancePreventiveTable,
        VgTacheForm,
        VgContratForm,
        VgCategorieSelector,
        VgUsersSelector,
        VgFilterTiers,
        VgFilterSite
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-title": "Plan de maintenance",
            "tab-tous": "Tous",
            "tab-preventif-interne": "Préventif internalisé",
            "tab-preventif-externe": "Préventif externalisé",
            "btn-create-tache": "Créer tâche récurrente",
            "btn-create-contrat": "Créer contrat",
            "filtre-categorie": "Filtrage par catégorie",
            "filtre-affecte": "Filtrage par utilisateur affecté"
        },
        "en": {
            "header-title": "Maintenance Plan",
            "tab-tous": "All",
            "tab-preventif-interne": "Internalized Preventive",
            "tab-preventif-externe": "Outsourced Preventive",
            "btn-create-tache": "Create Recurrent Task",
            "btn-create-contrat": "Create Contract",
            "filtre-categorie": "Filter by Category",
            "filtre-affecte": "Filter by Assigned User"
        }
    }
},
	mixins: [FiltersMixins],
    props:{
        defaultFilters: {
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data:function(){
        return {
            activeTab: 'tous',
			searchQuery: null,
            agfilters:{
                tache_intitule:{attr: "t.libel_tache", value: null, action: "contains"},
                contrat_intitule:{attr: "c.name", value: null, action: "contains"},
                field_libelleCatgorie: {attr: "c.libelleCatgorie", value: null, action: "equals"},
                field_tiers_id: {attr: "t.id", value: null, action: "equals"},
                //field_affecte_id: {attr: "t.id", value: null, action: "equals"},
                field_site: {attr: "l.path", value: null, action: "start_with"},
            },
            showCreateTache: false,
            showCreateContrat: false
        }
    },
	watch: {
		searchQuery: {
			handler: function(query){
                let queryValue = null;
                if(query && query.length!=0) queryValue = query.split(" ");
                this.agfilters.tache_intitule.value = queryValue;
                this.agfilters.contrat_intitule.value = queryValue;
			}
		}
	},
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        actionExport: function(event){
            console.log(event.name);
            window.dispatchEvent(new CustomEvent(event.name, {
				"detail": {"idTableau": "plan-maintenance-preventive-table", excludeCellrenderers: ["statut"]}
			}));
        },
		handleTabClicked: function(tab){
			this.activeTab = tab.name;
		},
        onCreate: function(){
            this.showCreateTache=false;
            this.showCreateContrat=false;
            location.reload();
        }
    },
	computed: {
        pageFilters: function(){
            return ["field_libelleCatgorie", "field_tiers_id", "field_site"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            return activeFilters.length;
        },
		counters: function(){
			var counters = {};
			return counters;
		},
		tabs: function(){
			var counters = this.counters;
			return [
				{label: this.$t("tab-tous"), name:"tous", counter: null, filters:[]},
				{label: this.$t("tab-preventif-interne"), name:"preventif-interne", counter: null, filters:[]},
				{label: this.$t("tab-preventif-externe"), name:"preventif-externe", counter: null, filters:[]}
			];
		},
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgPurple : 'white';
        }
	},
	created: function(){
        this.agfilters = Object.assign({}, this.agfilters, this.defaultFilters);
        this.FiltersMixins_page_name = "plan-maintenance-preventive";
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
	}
}
</script>
