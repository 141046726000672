<template>
	<div class="vg-invalid-login">
		<span>{{$t("msg-invalid-login")}}</span>
		<vg-form-reset-password />
	</div>
</template>
<script>
import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgFormResetPassword from "src/components/Vg/Utils/VgFormResetPassword.vue";

    export default {
        name: 'vg-invalid-login',
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "msg-invalid-login": "Vos identifiants de connexion sont incorrects, veuillez réessayer."
        },
        "en": {
			"msg-invalid-login": "Your login credentials are incorrect, please try again."
        }
	}
},
		components: {
			VgModal,
			VgButton,
			VgFormResetPassword
		},
        props: {
			
        },
        data: function() {
            return {
				
            };
        },
		methods: {
			openLink: function(){
				window.open("https://www.verifgood.io/rdv", "_blank");
			},
			openContact: function(){
				window.open("https://www.verifgood.io/contact", "_blank");
			}
		},
		computed: {
			
		}
    };

</script>
<style lang="scss" scoped>
.vg-invalid-login{
	width: inherit;
	padding: 10px;
	background-color: #f8d7da;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	font-size: 12px;
	span{
		color: #aa585c;
	}
}
</style>
