<template lang="html">
    <div class="vg-input-number">
        <button  @click="decrement" class="inputNumber-moins">-</button>
        <input type="number" name="" v-model="valueInput" @keyup="inputChange" :placeholder="placeholder">
        <button  @click="increment" class="inputNumber-plus">+</button>

    </div>
</template>

<script>
// import VgButton from "src/components/Vg/VgButton.vue";
export default {
    name: "vg-input-number",
    props: {
		value: {
          type: Number,
          default:null
        },
        min: {
          type: Number,
        },
        max: {
          type: Number,

        },
        placeholder:{
            type:String
        }
	},
    components:{
        // VgButton
    },
    data:function(){
        return {
            valueInput:this.value,
        }
    },
    methods:{
        increment:function(){
            if (!this.max) {
                this.valueInput++;
                this.inputChange();
            }
            else{
                if (this.valueInput < this.max) {
                    this.valueInput++;
                    this.inputChange();
                }
                else{
                    // //console.log("limit "+this.max);
                }
            }

        },
        decrement:function(){
            if (!this.min) {
                this.valueInput--;
                this.inputChange();
            }
            else{
                if (this.valueInput > this.min) {
                    this.valueInput--;
                    this.inputChange();
                }
                else{
                    // //console.log("limit "+this.min);
                }
            }
        },
        inputChange:function(){
            this.$emit('input', this.valueInput);
        }
    }
}
</script>

<style lang="scss" scoped>
$heightTotal:35px;
.vg-input-number{
    height: $heightTotal;
}
.inputNumber-moins{
    height: $heightTotal;
    width: $heightTotal;
    float: left;
    font-family: "Open Sans";
    background-color: #E4E4E4;
    font-weight: bold;
    border:1px solid rgb(185, 185, 185);
}
input{
    height: $heightTotal;
    float: left;
    text-align: center;
    // font-weight: bold;

    width: 60px;
}
.inputNumber-plus{
    @extend .inputNumber-moins ;
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {
            val:5
        }
      },
      template: `
        <div>
            <vg-input-number
                v-model="val"
                :placeholder="'placeholder'"
            >
            </vg-input-number>
        </div>
      `
    })
    ```
</docs>
