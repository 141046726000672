<template>
	<span v-html="text"></span>
</template>
<script>
    export default {
        name: "vg-text-multiligne",
        i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    
                }
            }
        },
        props: {
			/** value en minutes */
			value:{
				type: String,
				default: null
			},
            defaultMultiligneSeparator:{
                type: String,
                default: "\n"
            }
        },
        watch:{
            value: {
                handler: function(newval){
                    this.text = newval ? newval.replace(new RegExp(this.defaultMultiligneSeparator, "g"), "<br>") : newval;
                }
            }
        },
        data: function() {
            return {
				text: this.value ? this.value.replace(new RegExp(this.defaultMultiligneSeparator, "g"), "<br>") : this.value
            };
        }
    };

</script>
<style style="scss" scoped>

</style>
