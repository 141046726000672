<template>
	<li class="vg-tree-node">
		<div :class="['tree-node',{'selected':nodeSelected && nodeSelected.id==node.id}]">
			<div class="toggler" v-if="!nodePending && isFolder"
				@click="toggle()">
				<i :class="['fas',{'fa-chevron-down':isOpen,'fa-chevron-right':!isOpen}]"></i>
			</div>
			<div class="toggler" v-else-if="nodePending && isFolder">
				<i :class="['pending','fas',{'fa-chevron-down':isOpen,'fa-chevron-right':!isOpen}]"></i>
			</div>
			<div :class="['node',{'node-leaf':!isFolder}]">
				<!-- @slot Use this slot v-slot:node -->
				<slot name="node" :node="node">
					default (vg-tree-node)
				</slot>
			</div>
		</div>
		<ul v-show="isOpen"
			v-if="isFolder && (!nodePending || (nodePending && nodePending.id!=node.id)) && !isCollapseAll"
			:class="['tree-folder' , {'last-tree-folder': isLastFolderCallback(node)}]">
			<vg-tree-node
				v-for="child, index in node.children"
				v-model="node.children[index]"
				:isFolderCallback="isFolderCallback"
				:isOpenCallback="isOpenCallback"
				:isLastFolderCallback="isLastFolderCallback"
				:store="store"
				:key="'child-'+node.id"
				@open="onOpen">
				<template v-slot:node="{node}">
					<slot name="node" :node="node">
						default (vg-tree-node)
					</slot>
				</template>
			</vg-tree-node>
		</ul>
	</li>
</template>
<script>
    export default {
        name: 'vg-tree-node',
		components: {

		},
        props: {
			value:{
				type: Object
			},
			store:{
				type: String
			},
			isFolderCallback:{
				type: Function
			},
			isOpenCallback:{
				type: Function
			},
			isLastFolderCallback:{
				type: Function
			}
        },
		watch:{
			value: {
				handler: function(node){
					this.node = node;
				},
				deep: true
			},
			nodePending: {
				handler: function(node){
					if(node && node.id==this.node.id && !this.isOpen) this.isOpen=true;
				}
			},
			isCollapseAll: {
				handler: function(isCollapse){
					if(isCollapse) this.isOpen = false;
				}
			}
		},
        data: function() {
            return {
				node: this.value,
				isFolder: true,
				isOpen: false
            };
        },
		created: function(){
			this.isFolder = this.isFolderCallback(this.value);
			this.isOpen = this.isOpenCallback(this.value);
		},
		mounted: function(){

		},
		methods: {
			toggle: function(){
				if(this.isFolder){
					this.isOpen = !this.isOpen;
					if(this.isOpen && this.hasNoChildren) this.$emit("open", this.node);
					// si on deploie le node && l'Arborescence était collapse
					// on enleve le collapse all sur l'Arborescence
					if(this.isOpen && this.isCollapseAll) this.$store.dispatch(this.store+"/updateIsCollapseAll", false);
				}
			},
			onOpen: function(node){
				this.$emit("open", node);
			}
		},
        computed: {
			hasNoChildren: function(){
				return !this.node.children || (this.node.children && this.node.children.length==0);
			},
			/**
			* @return Object
			*/
			nodePending: function(){
				return this.$store.getters[this.store+"/getNodePending"];
			},
			/**
			* @return Boolean
			*/
			isCollapseAll: function(){
				return this.$store.getters[this.store+"/getIsCollapseAll"];
			},
			/**
			* @return Array
			*/
			nodeSelected: function(){
				return this.$store.getters[this.store+"/nodeSelected"];
			}
		}
    };

</script>
<style lang="scss" scoped>
$toggler-disabled-color: #cacaca;
.vg-tree-node{
	cursor: pointer;
	.tree-node{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 35px;
		.toggler{
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			>.pending{
				color: $toggler-disabled-color;
			}
		}
		.toggler:hover{
			color: #78208a;
		}
		>.node{
			width: 100%;
		}
		.node-leaf{
			margin-left: 20px;
		}
		>.selected{
			font-weight: bold;
		}
	}
}
@media only screen and (max-width: 1200px){
	.vg-tree-node{
		overflow-y: hidden;
		.tree-node{
			height: 50px;
			overflow-y: hidden;
			.toggler{
				width: 40px;
				height: 50px;
				i{
				    font-size: 1.6em !important;
					color: #5b5e62;
				}
			}
			.node{
				overflow-y: hidden;
			}
			.node-leaf{
				margin-left: 5px;
				overflow-y: hidden;
			}
		}
	}
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-tree-node
                >
            </vg-tree-node>
        </div>
      `
    })
    ```
</docs>
