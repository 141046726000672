<script>
import { Pie } from 'vue-chartjs';

// https://www.chartjs.org/docs/latest/charts/doughnut.html

export default {
	name: "vg-pie-chart",
    extends: Pie,
	props: {
        datas : Array,
		options : {
			type: Object,
			default: function(){
				return {};
			}
		},
		showlegend: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			required: true
		},
		showTitle: {
			type: Boolean,
			default: false
		}
	},
	i18n: {
		locale: "fr",
		messages: {
			fr: {
				"no-datas": "Aucune donnée à afficher"
			}
		}
	},
	data: function(){
		return {
			OPTIONS: {
				title:{
					text: this.title,
					display: this.showTitle,
					position: "bottom"
				},
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: this.showlegend,
					position: "bottom"
				},
				hover: {
					onHover: function(e) {
						var point = this.getElementAtEvent(e);
						if (point.length) e.target.style.cursor = 'pointer';
						else e.target.style.cursor = 'default';
					}
				},
				onClick: (e) => this.handleClickChart(e)
			}
		};
	},
	watch: {
		datas: {
			handler: function(value){
				this.renderDoughnutChart();
			}
		}
	},
	methods: {
		handleClickChart: function(e){
			var activeElement = this.$data._chart.getElementsAtEvent(e);
			if(activeElement && activeElement[0]) this.$emit("selected", { index: activeElement[0]._index });
		},
		renderDoughnutChart: function(){
			const emptyDataPlugin = {
				id: 'emptyData',
				afterDraw: (chart) => {
					if(chart.data && chart.data.datasets){
						if (chart.data.datasets.length==0 || chart.data.datasets[0].data.length==0) {
							const ctx = chart.ctx;
							const width = chart.width;
							const height = chart.height;
							ctx.save();
							ctx.textAlign = 'center';
							ctx.textBaseline = 'middle';
							ctx.font = '12px Arial';
							ctx.fillStyle = "#333";
							ctx.fillText(this.$t("no-datas"), width / 2, height / 2);
							ctx.restore();
						}
					}
				}
			};

			this.addPlugin(emptyDataPlugin);
			this.renderChart({
				labels: this.datas[0].label,
				datasets: this.datas
			}, Object.assign({}, this.OPTIONS, this.options));
		},
	},
	mounted: function() {
		//console.log("DATAS", this.datas);
		this.renderDoughnutChart();
	}
}
</script>

<style lang="scss" scoped>

</style>
