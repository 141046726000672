<template>
	<vg-modal
		width="70vw"
		@close="$emit('close')">
		<template #header>
			<slot name="title">{{ $t("title") }}</slot>
		</template>
		<template #body>
			<vg-text-filter v-model="searchQuery"/>
			<vg-verifications-viewer 
				:searchQuery="searchQuery"
				:filters="filters"
                @row-dbl-click="openVerificationDetails" />
			<vg-verification-modal-viewer v-if="showVerificationModal"
				:verification="focusedVerification"
				@close="showVerificationModal=false;" />
		</template>
		<template #footer>
			<vg-button type="default" @click="$emit('close')">{{ $t("close") }}</vg-button>
		</template>
	</vg-modal>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
	import VgVerificationsViewer from "src/components/Vg/Verification/VgVerificationsViewer.vue";
	import VgVerificationModalViewer from "src/components/Vg/Verification/VgVerificationModalViewer.vue";

	import { mapGetters } from 'vuex';

    export default {
        name: "vg-verifications-modal",
		components: {
			VgButton,
			VgModal,
			VgTextFilter,
			VgVerificationsViewer,
			VgVerificationModalViewer
		},
		mixins: [],
        props: {
			filters: {
				type: Object,
				default: function(){
					return [];
				}
			}
        },
		i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"title": "Vérifications",
					"close": "Fermer"
				}
			}
		},
        data: function() {
            return {
				agfilters: this.filters || {},
				searchQuery: null,
				focusedVerification: null,
				showVerificationModal: false
            };
        },
		watch: {
			
		},
		created: function(){
			
		},
		mounted: function(){

		},
		methods: {
			openVerificationDetails: function(verification){
				this.focusedVerification = verification;
				this.showVerificationModal = true;
			}
		},
        computed: {
			...mapGetters({

            }),
			
		}
    };

</script>
<style lang="scss" scoped>

</style>
