<template>
	<vg-app-layout
		:title="$t('tache.desktop.Header-Title')"
		:icon="'tache-de-verification.png'"
		@action-export="actionExport"
        :isLoadingState="isLoadingTache"
        >
        <template #create-button>
            <vg-button type="success" @click="openCreateTacheForm">{{labelBtnNouveltache}}</vg-button>
        </template>

        <template #search>
            <vg-text-filter v-model="searchQuery" />
        </template>

        <template #header-bottom-left>
            <vg-tabs :tabs="tabs"
                :color="'gris'"
                @tabClicked="handleTabClicked" />
		</template>
        <template #primary>
            <vg-taches-table 
                :filters="tachesFilters" 
                @row-click="onSelectTache"/>
            <vg-tache-viewer v-if="selectedTache" 
                style="height:80%;"
                v-model="selectedTache" 
                @loading="setLoadingTache"
                @close="onSelectTache(null)"/>
        </template>

        <!--template #secondary>
            <div v-if="!getSelectedTache" style="display: flex;justify-content: center;">
                <div style="margin-top: 50%">
                    <h3 style="color: #cccccc;">
                        {{$t('selection-tache')}}
                    </h3>
                </div>
            </div>

            <vg-button
                style="margin-bottom: 5px;"
                v-show="getSelectedTache && getSelectedTache.checkpoints"
                type="info"
                size="xs"
                @click="isEditionModeCheckpoints = !isEditionModeCheckpoints"
            >
                {{$t('update-checkpoint')}}
            </vg-button>
            <vg-checkpoints-form
                v-if="getSelectedTache && getSelectedTache.checkpoints"
                v-model="getSelectedTache"
                :isEditionMode="isEditionModeCheckpoints"
                @deleted="isEditionModeCheckpoints = false"/>
            <br>
            <div v-if="getSelectedTache">
                <vg-button size="small" @click="isUploaderVisible?isUploaderVisible=false:isUploaderVisible=true;">
                    <i :class="{'el-icon-arrow-down':!isUploaderVisible, 'el-icon-arrow-up':isUploaderVisible}"></i> {{ $t("attacher-document") }}
                </vg-button>
                <vg-files-uploader v-if="isUploaderVisible"
                    :tagsList="VgFilesMixins.tags"
                    @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                </vg-files-uploader>
                <vg-files-viewer-list-section
                    :files="VgFilesMixins_filesListSection"
                    @delete="VgFilesMixins_fetchFilesByUid()">
                </vg-files-viewer-list-section>
            </div>

            <div v-show="isLoadingCheckpoints" class="loading">
                {{$t('loading')}}
            </div>
        </template-->
        
        <!-- CREATE TACHE AND CHECKPOINTS -->
        <vg-tache-form v-if="showCreateTache"
            key="create-tache-form"
            :typeTache="typeTache"
            @close="showCreateTache = false"
            @created="showCreateTache=false;" />

    </vg-app-layout>

</template>

<script>
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTacheForm from "src/components/Vg/Forms/VgTacheForm.vue";
import VgTacheViewer from "src/components/Vg/Taches/VgTacheViewer.vue";
import VgTachesTable from "src/components/Vg/Taches/VgTachesTable.vue";



import { mapGetters } from 'vuex';
import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'taches',
    i18n:    { "locale":navigator.language,
  "messages": {
    "fr": {
        "aucun-lieu": "Aucune pièce",
        "aucun-equipement": "Aucun équipement",
        "aucun-compteur": "Aucun compteur",
        "aucun-Grandeur_physique": "Aucun équipement",
      "overlay-loading-text": "Chargement des tâches de vérification...",
      "overlay-no-rows-text": "Aucune tâche correspondant au filtrage",
      "loading": "Chargement en cours...",
      "update-checkpoint": "Modifier les points de vérifications",
      "selection-tache": "Veuillez sélectionner une tâche pour afficher ses points de vérifications",
      "attacher-document": "Attacher un document",
      "liste-taches": "tasks",
      "tache": {
        "desktop": {
          "Header-Title": "Gérer tâches récurrentes",
          "RightPanel-DefaultMessage": "Selectionner une Tache pour afficher le detail",
          "Modal-Create-Header": "Créer une tache de vérification ",
          "Modal-Create-Step-Task": "Tache de vérification",
          "Modal-Create-Step-Checkpoint": "Asssocier les points de vérification",
          "Modal-Create-NomTache": "Libellé tache",
          "Modal-Create-Gestion": "Gestion ",
          "Modal-Create-Categorie": "Catégorie des équipements associée",
          "Modal-Create-Periodicite": "Périodicité en nombre de jours",
          "Modal-Create-Active": "Active ",
          "Modal-Create-Tag": "Tag",
          "Modal-Create-Plh-Tag": "Tags de la tache",
          "Modal-Create-Norme": "Norme",
          "Modal-Create-Commentaire": "Commentaire",
          "Modal-Create-BtnAnnule": "Annuler",
          "Modal-Create-UpdateTask": "Modifier la tache",
          "Modal-Create-BtnSuite": "Ajouter les points de verification",
          "Modal-Update-Header": "Modifier la tache de vérification",
          "Modal-Create-DateButoire": "date butoir ( à terminer pour date suivante)",
          "Modal-Create-BtnFermer": "Fermer",
          "Modal-Create-BtnBack": "Retour détails tache",
          "Modal-Create-BtnSave": "Sauvegarder",
          "Header-Button-piece": "Créer tâche check lieu",
          "Modal-Create-Form-Libelle": "Veuillez renseigner un libellé à la tache",
          "Modal-Create-Form-Libelle3": "Libellé tache doit contenir plus de 3 caractères",
          "Modal-Create-Form-Categorie": "Veuillez sélectionner une catégorie",
          "Modal-Create-Form-Gestion": "Veuillez saisir une gestion interne ou externe",
          "Modal-Create-Form-Periodicite": "Veuillez saisir une périodicité",
          "Modal-Create-Form-dButoir": "Veuillez saisir la prochaine date butoire",
          "TabPanel-Onglet-piece": "Check lieu",
          "TabPanel-Onglet-equip": "Equipement",
          "TabPanel-Onglet-R-Compteur": "Relevé compteur",
          "TabPanel-Onglet-R-Grandeur": "Relevé Grandeur",
          "Header-Button-equip": "Créer tâche équipement",
          "Header-Button-R-Compteur": "Créer tâche relevé compteur",
          "Header-Button-R-Grandeur": "Créer tâche relevé grandeur",
          "Modal-Create-input-messageOnVerifError": "Défectueux",
          "Modal-temps-effectif": "durée moyenne en min",
          "requiert-scan": "Scan équipement requis",
          "programmation-session": "Programmation session",
          "LeftPanel-Tooltip-affectation": "Affectation programmée",
          "LeftPanel-Tooltip-scann-eq-requis": "Scan équipement requis"
        }
      },
      "categorie": {
        "desktop": {
          "Modal-CreateCat-Footer-BtnValide": "Créer"
        }
      }
    },
    "en": {
        "aucun-lieu": "No room",
        "aucun-equipement": "No equipment",
        "aucun-compteur": "No meter",
        "aucun-Grandeur_physique": "No equipment",
      "loading": "Loading...",
      "update-checkpoint": "Update checkpoints",
      "selection-tache": "Select a task to display its checkpoints",
      "attacher-document": "Attach file",
      "liste-taches": "tasks",
      "tache": {
        "desktop": {
          "Header-Title": "Manage recurrent tasks",
          "RightPanel-DefaultMessage": "Select a task to display the detail",
          "Modal-Create-Header": "Create a new task of ",
          "Modal-Create-Step-Task": "Task",
          "Modal-Create-Step-Checkpoint": "Associate checkpoint",
          "Modal-Create-NomTache": "Name of the task",
          "Modal-Create-Gestion": "Management",
          "Modal-Create-Categorie": "Category of associated equipment",
          "Modal-Create-Periodicite": "Frequency in number of days",
          "Modal-Create-Active": "Active ",
          "Modal-Create-Tag": "Tag",
          "Modal-Create-Plh-Tag": "Tags of the task",
          "Modal-Create-Norme": "Standard",
          "Modal-Create-Commentaire": "Comment",
          "Modal-Create-BtnAnnule": "Cancel",
          "Modal-Create-UpdateTask": "Update task",
          "Modal-Create-BtnSuite": "Add checkpoint",
          "Modal-Update-Header": "Modify the task",
          "Modal-Create-DateButoire": "deadline (to complete for next date)",
          "Modal-Create-BtnFermer": "Close",
          "Modal-Create-BtnBack": "Back details task",
          "Modal-Create-BtnSave": "Save",
          "Header-Button-piece": "Create task check place",
          "Modal-Create-Form-Libelle": "Please fill in a label to the task",
          "Modal-Create-Form-Libelle3": "Label must contain more than 3 characters",
          "Modal-Create-Form-Categorie": "Please select a category",
          "Modal-Create-Form-Gestion": "Please enter an internal or external management",
          "Modal-Create-Form-Periodicite": "Please enter a periodicity",
          "Modal-Create-Form-dButoir": "Please enter the next deadline",
          "TabPanel-Onglet-piece": "Task check place",
          "TabPanel-Onglet-equip": "Task equipment",
          "TabPanel-Onglet-R-Compteur": "Task meter reading",
          "TabPanel-Onglet-R-Grandeur": "Task read values",
          "Header-Button-equip": "Create task equipment ",
          "Header-Button-R-Compteur": "Create task meter reading",
          "Header-Button-R-Grandeur": "Create task read values",
          "Modal-Create-input-messageOnVerifError": "faulty",
          "Modal-temps-effectif": "average time per equipment",
          "requiert-scan": "Equipment scan required"
        }
      },
      "categorie": {
        "desktop": {
          "Modal-CreateCat-Footer-BtnValide": "Create"
        }
      }
    },
  }
},
    mixins: [
        
    ],
    components: {
        VgTabs,
        VgTextFilter,
		VgButton,
        VgTacheForm,
        VgTachesTable,
        VgTacheViewer
    },
    data: function() {
        return {
			showLoadingOverlay: false,
            selectedTab: "equipement", // lieu || equipement || compteur ||Grandeur_physique
            searchValue: "",
            showCreateTache: false,
            searchQuery: '',
            tachesFilters: {
                libel_tache: {attr:"t.libel_tache", value: null, action:"contains", openParenthesis: true},
                categorie_libel: {attr:"c.libelleCatgorie", value: null, action:"contains", logicalOperator: "OR"},
                commentaire_tache: {attr:"t.commentaire_tache", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true},
                typeTache: {attr:"t.type_tache", value: "Verification_equipement", action:"equals"}
            },
            isEditionModeCheckpoints:false,
            selectedTache: null,
            isLoadingTache: false
        };
    },
    watch: {
        searchValue: function(value) {
            //// //console.log("SEARCH VALUE", value);
            this.gridOptions.api.setQuickFilter(value);
        },
        searchQuery: {
            handler: function(query) {
                this.tachesFilters.libel_tache.value = query || query.length!=0?query:null;
                this.tachesFilters.categorie_libel.value = query || query.length!=0?query:null;
                this.tachesFilters.commentaire_tache.value = query || query.length!=0?query:null;
            }
        }
    },
    computed: {
        ...mapGetters({
            checkpoints: 'CheckpointsStore/getCollection',
            getSelectedTache: 'TachesStore/getSelectedItem',
            taches: 'TachesStore/getCollection',
            counters: 'TachesStore/getCounters'
        }),
        tabs: function() {
            return [
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-equip'),
                    name: "equipement",
                    counter: this.selectedTab=="equipement"?this.counters["filtered"]+"/"+this.counters["Verification_equipement"]:this.counters["Verification_equipement"],
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Verification_equipement", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-R-Compteur'),
                    name: "compteur",
                    counter: this.selectedTab=="compteur"?this.counters["filtered"]+"/"+this.counters["Relever_compteur"]:this.counters["Relever_compteur"],
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Relever_compteur", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-R-Grandeur'),
                    name: "Grandeur_physique",
                    counter: this.selectedTab=="Grandeur_physique"?this.counters["filtered"]+"/"+this.counters["Relever_Grandeur_physique"]:this.counters["Relever_Grandeur_physique"],
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Relever_Grandeur_physique", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-piece'),
                    name: "lieu",
                    counter: this.selectedTab=="lieu"?this.counters["filtered"]+"/"+this.counters["Verification_Lieu"]:this.counters["Verification_Lieu"],
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Verification_Lieu", action: "equals"}
					}
                }
            ];
        },
        /**
         * @deprecated
         */
        typeTache: function() {
            if (this.selectedTab == "lieu") return "Verification_Lieu";
            else if (this.selectedTab == "equipement") return "Verification_equipement";
            else if (this.selectedTab == "compteur") return "Relever_compteur";
            else if (this.selectedTab == "Grandeur_physique") return "Relever_Grandeur_physique";
            else return "default";
        },
        labelBtnNouveltache: function() {
            if (this.selectedTab == "lieu") return this.$t("tache.desktop.Header-Button-piece");
            else if (this.selectedTab == "equipement") return this.$t("tache.desktop.Header-Button-equip");
            else if (this.selectedTab == "compteur") return this.$t("tache.desktop.Header-Button-R-Compteur");
            else if (this.selectedTab == "Grandeur_physique") return this.$t("tache.desktop.Header-Button-R-Grandeur");
            else return "default";
        },

    },
    methods: {
        setLoadingTache: function(isLoading){
            this.isLoadingTache = isLoading;
        },
        onSelectTache: function(tache){
            this.$store.dispatch("TachesStore/setSelectedItem", null);
            this.selectedTache = tache?Object.assign({}, {}, tache):null;
        },
		actionExport: function(event){
            let metadatas = this.metadatasTaches; // Attention écrit en dur commun au tableau equipements

            switch (event.name) {
                case "askForXLS":
                    this.tachesMixins_getExcelFile(metadatas,this.$t('liste-taches'),"xlsx").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.tachesMixins_getExcelFile(metadatas,this.$t('liste-taches'),"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"taches-tableau-recap"}
                    }));
                    break;
                default:
            }
		},
        handleTabClicked: function(e) {
			this.tachesFilters = Object.assign({}, this.tachesFilters, e.filters);
            this.onSelectTache(null);
            this.selectedTab = e.name;
        },
        /**
         *
         */
        openCreateTacheForm: function(e) {
            this.selectedTache = null;
            this.showCreateTache = true;
        },
    },
    created: function() {
        
    }
};
</script>

<style lang="scss">
    .loading{
        font-weight: 600;
        letter-spacing: 0.1rem;
    }

</style>
