<template lang="html">
    <div class="contrat-cell-render" @click="$emit('clicked', {data: contrat})">
        <div class="header">
            <span><b>{{contrat.name}}</b></span>
            <span>{{$t("tiers")}}: {{contrat.tiers.name}}</span>
            <span>{{$t("type")}}: {{contrat.type}}</span>
        </div>
        <div class="content">
            <span>{{$t("periodicite-intervention", {jours: contrat.periodiciteIntervention})}}</span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
  name:"contrat-cell-render",
  mixins:[LangMixins],
  props:{
        contrat:{
            type:Object,
            required:true,
            default: function(){
                return {};
            }
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "tiers": "Tiers",
            "type": "Type",
            "periodicite-intervention": "Périodicité d'intervention: {jours} jours"
        },
        "en": {
            "tiers": "Supplier",
            "type": "Type",
            "periodicite-intervention": "Intervention periodicity: {jours} days"
        }
    }
},
    components:{
        VgDatetimeViewer,
        VgLieuPathViewer
    }
});
</script>

<style lang="scss" scoped>
.contrat-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
}
</style>
