<template>
    <div class="inventaire-lieu-cell-render">
        <div class="main">
            <vg-lieu-path-viewer class="libel"
                v-model="inventaire.path"
                onlyPiece/>
            <div class="complement">
                <span class="counters-inventoried">{{getTotalEquipementInventoried}}</span>
                /
                <span :class="['counters-total-'+getEtatInventaire]">{{getTotalEquipement}}</span>
                <div :class="['status', 'status-'+getEtatInventaire]">
                    {{$t("inventaire-"+getEtatInventaire)}}
                </div>
            </div>
        </div>
        <div class="progress-bar">
            <vg-progress v-model="getTotalEquipementInventoried"
                :total="getTotalEquipement"
                percentage/>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VgProgress from "src/components/Vg/VgProgress.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"inventaire-lieu-cell-render",
    mixins:[LangMixins],
    props:{
        inventaire:{
            type:Object
        }
    },
    components:{
        VgProgress,
        VgLieuPathViewer
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "inventaire-en-cours": "En cours",
            "inventaire-a-faire": "A faire",
            "inventaire-termine": "Terminé"
        },
        "en": {
            "inventaire-en-cours": "Ongoing",
            "inventaire-a-faire": "To do",
            "inventaire-termine": "Done"
        }
    }
},
  data: function (){
      return {

      }
  },
  mounted:function (){

  },
  methods:{

  },
  computed:{
    getEtatInventaire: function(){
        if(this.getTotalEquipementInventoried == this.getTotalEquipement) return "termine";
        else if(this.inventaire.qtyInventoried==0) return "a-faire";
        else return "en-cours";
    },
    getTotalEquipement:function(){
        if(this.inventaire.totalEquipement) return parseInt(this.inventaire.totalEquipement);
        else return parseInt(this.inventaire.qtyInventoried) + parseInt(this.inventaire.qtyNotInventoried);
    },
    getTotalEquipementInventoried:function(){
        if(this.inventaire.totalInventorie) return parseInt(this.inventaire.totalInventorie);
        else return parseInt(this.inventaire.qtyInventoried);
    },
    getTotalEquipementAbsent:function(){
        if(this.inventaire.totalEquipement && this.inventaire.totalInventorie) return Math.abs(parseInt(this.inventaire.totalEquipement) - parseInt(this.inventaire.totalInventorie));
        else return Math.abs(parseInt(this.inventaire.qtyNotInventoried) - parseInt(this.inventaire.qtyInventoried));
    }
  }

});
</script>

<style lang="scss" scoped>
$status-text-color: white;
$status-background-color-default: #ccc;
$status-background-color-en-cours: #ffbf44;
$status-background-color-termine: #45b348;
$status-background-color-a-faire: #ff3f2b;
.inventaire-lieu-cell-render{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
    .main{
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .libel{

        }
        .complement{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 3px;
            .counters-inventoried{

            }
            .status{
                text-transform: uppercase;
                color: $status-text-color;
                padding: 2px 5px;
                background-color: $status-background-color-default;
                margin-left: 10px;
                width:90px;
                font-size: 12px;
                text-align: center;
            }
            .counters-total-en-cours{
                color: $status-background-color-en-cours;
            }
            .status-en-cours{
                background-color: $status-background-color-en-cours;
            }
            .counters-total-termine{
                color: $status-background-color-termine;
            }
            .status-termine{
                background-color: $status-background-color-termine;
            }
            .counters-total-a-faire{
                color: $status-background-color-a-faire;
            }
            .status-a-faire{
                background-color: $status-background-color-a-faire;
            }
        }
    }
    .progress-bar{
        width:100%;
    }
}
</style>
