<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:defaultDateIntervalle="defaultDateIntervalle"
		:hideHeaderComplement="hideHeaderComplement"
		key="dashboard-bc-repartition-montant-ht"
		:inline="inline"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<div v-if="!showSelector" :class="{'charts-container-inline': inline, 'charts-container-col': !inline}">
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-site" v-if="repartitionMontantHtSite"
				:chart-id="'dashboard-bc-repartition-montant-ht-site'"
				:height="'180px'"
				:datas="repartitionMontantHtSiteDatas"
				:title="$t('graph-title-site')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedSitePie" />
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-service" v-if="repartitionMontantHtService"
				:chart-id="'dashboard-bc-repartition-montant-ht-service'"
				:height="'180px'"
				:datas="repartitionMontantHtServiceDatas"
				:title="$t('graph-title-service')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedServicePie" />
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-fournisseur" v-if="repartitionMontantHtFournisseur"
				:chart-id="'dashboard-bc-repartition-montant-ht-fournisseur'"
				:height="'180px'"
				:datas="repartitionMontantHtFournisseurDatas"
				:title="$t('graph-title-fournisseur')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedFournisseurPie" />
		</div>
		<div v-else class="charts-container-with-selector">
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-site" v-if="showGraph=='site' && repartitionMontantHtSite"
				:chart-id="'dashboard-bc-repartition-montant-ht-site'"
				:height="'180px'"
				:datas="repartitionMontantHtSiteDatas"
				:title="$t('graph-title-site')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedSitePie" />
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-service" v-if="showGraph=='service' && repartitionMontantHtService"
				:chart-id="'dashboard-bc-repartition-montant-ht-service'"
				:height="'180px'"
				:datas="repartitionMontantHtServiceDatas"
				:title="$t('graph-title-service')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedServicePie" />
			<vg-pie-chart key="dashboard-bc-repartition-montant-ht-fournisseur" v-if="showGraph=='fournisseur' && repartitionMontantHtFournisseur"
				:chart-id="'dashboard-bc-repartition-montant-ht-fournisseur'"
				:height="'180px'"
				:datas="repartitionMontantHtFournisseurDatas"
				:title="$t('graph-title-fournisseur')"
				:showlegend="false"
				:options="pieOptions"
				showTitle
				@selected="onSelectedFournisseurPie" />
			<vg-select v-model="showGraph"
				:options="graphs"
				attributeLabel="label"
				attributeValue="value"
				:clearable="false"/>
		</div>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";
	import VgPieChart from 'src/views/Statistiques/components/VgPieChart.vue';

	import VgSelect from 'src/components/Vg/VgSelect.vue';

	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-bons-de-commande-repartition-montant-ht',
		mixins: [ BonsDeCommandeMixins ],
		components: {
			VgDashboardCuratifRapport,
			VgPieChart,
			VgSelect
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return {startDate: null, endDate: null};
				}
			},
			hideHeaderComplement: {
				type: Boolean,
				default: false
			},
			inline: {
				type: Boolean,
				default: true
			},
			showSelector:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pieOptions:{
					tooltips: {
						enabled: true,
						callbacks: {
							label: ((tooltipItems, data) => {
								let dataset = data.datasets[tooltipItems.datasetIndex];
								console.log("DATASET", dataset);
								return dataset.label[tooltipItems.index]+": "+dataset.data[tooltipItems.index]+" "+this.$app.devise;
							}),
							title: ((tooltipItems, data) => {
								return this.$t("montant-ht");
							})
						}
					}
				},
				showGraph: "site",	// site || service || fournisseur
				graphs: [
					{label: this.$t("repartition-site"), value: "site"},
					{label: this.$t("repartition-service"), value: "service"},
					{label: this.$t("repartition-fournisseur"), value: "fournisseur"}
				]
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "REPARTITION montant HT par site / service / fournisseur",
			"graph-title-site": "Répartition montant HT par site",
			"graph-title-service": "Répartition montant HT par service",
			"graph-title-fournisseur": "Répartition montant HT par fournisseur",
			"montant-ht": "Montant HT",
			"aucun-site-associe": "Aucun site associé",
			"aucun-service-associe": "Aucun service associé",
			"aucun-fournisseur-associe": "Aucun fournisseur associé",
			"repartition-site": "Répartition par site",
			"repartition-service": "Répartition par service",
			"repartition-fournisseur": "Répartition par fournisseur"
		},
		"en": {
			"title": "DISTRIBUTION of excl.VAT amount by site / service / supplier",
			"graph-title-site": "Distribution of excl.VAT amount by site",
			"graph-title-service": "Distribution of excl.VAT amount by service",
			"graph-title-fournisseur": "Distribution of excl.VAT amount by supplier",
			"montant-ht": "HT amount",
			"aucun-site-associe": "No associated site",
			"aucun-service-associe": "No associated service",
			"aucun-fournisseur-associe": "No associated supplier",
			"repartition-site": "Distribution by site",
			"repartition-service": "Distribution by service",
			"repartition-fournisseur": "Distribution by supplier"
		},
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onSelected: function(dataSet, data){
				let filters = dataSet[0]._filters[data.index];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			onSelectedSitePie: function(data){
				this.onSelected(this.repartitionMontantHtSiteDatas, data);
			},
			onSelectedServicePie: function(data){
				this.onSelected(this.repartitionMontantHtServiceDatas, data);
			},
			onSelectedFournisseurPie: function(data){
				this.onSelected(this.repartitionMontantHtFournisseurDatas, data);
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.fetch();
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				filters.dateCreation = {attr: "bc.dateCreation", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				this.metadatas.setFilters(filters);
				this.BonsDeCommandeMixins_getRepartitionMontantHt(this.metadatas).then((datas)=>{

				});
			}
		},
        computed: {
			...mapGetters({
	            repartitionMontantHtSite: "BonsDeCommandeStore/getRepartitionMontantHtSite",
				repartitionMontantHtService: "BonsDeCommandeStore/getRepartitionMontantHtService",
				repartitionMontantHtFournisseur: "BonsDeCommandeStore/getRepartitionMontantHtFournisseur"
	        }),
			colors: function(){
				return [
					'#4A90E2', // Bleu clair
					'#50E3C2', // Vert
					'#F5A623', // Jaune
					'#D0021B', // Rouge
					'#9013FE', // Violet
					'#BD10E0', // Rose
					'#F8E71C', // Orange
					'#417505'  // Bleu foncé
				];
			},
			repartitionMontantHtSiteDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: [],
					hoverOffset: 4,
					_filters: []
				};
				this.repartitionMontantHtSite.forEach((repartition, index)=>{
					dataset.label.push(repartition.libel_lieu?repartition.libel_lieu:this.$t("aucun-site-associe"));
					dataset.data.push(Number(repartition.montantHT).toFixed(2));
					dataset.backgroundColor.push(this.colors[index%this.colors.length]);
					dataset._filters.push({siteDemandeur_id: {attr: "bc.siteDemandeur_id", value: repartition.siteDemandeur_id, action: "equals"}});
				});
				return [dataset];
			},
			repartitionMontantHtServiceDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: [],
					hoverOffset: 4,
					_filters: []
				};
				this.repartitionMontantHtService.forEach((repartition, index)=>{
					dataset.label.push(repartition.serviceDemandeur?repartition.serviceDemandeur:this.$t("aucun-service-associe"));
					dataset.data.push(Number(repartition.montantHT).toFixed(2));
					dataset.backgroundColor.push(this.colors[index%this.colors.length]);
					dataset._filters.push({serviceDemandeur: {attr: "bc.serviceDemandeur", value: repartition.serviceDemandeur, action: "equals"}});
				});
				return [dataset];
			},
			repartitionMontantHtFournisseurDatas: function(){
				let dataset = {
					label: [],
					data: [],
					backgroundColor: [],
					hoverOffset: 4,
					_filters: []
				};
				this.repartitionMontantHtFournisseur.forEach((repartition, index)=>{
					dataset.label.push(repartition.name?repartition.name:this.$t("aucun-fournisseur-associe"));
					dataset.data.push(Number(repartition.montantHT).toFixed(2));
					dataset.backgroundColor.push(this.colors[index%this.colors.length]);
					dataset._filters.push({fournisseur_id: {attr: "bc.fournisseur_id", value: repartition.fournisseur_id, action: "equals"}});
				});
				return [dataset];
			}
		}
    };

</script>
<style lang="scss">
.charts-container-inline{
	display: flex;
	justify-content: space-around;
	align-items:center;
	gap:20px;
	height: 100%;
}
.charts-container-col{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items:center;
	gap:20px;
	width: 100%;
}
</style>
