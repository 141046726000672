<template>
    <vg-button v-if="exist"
        type="danger"
        size="sm"
        @click="$emit('click', params)">
        {{$t("plan-interactif")}}
    </vg-button>
    <div v-else>
        <vg-files-uploader v-if="tagFondDePlan && can('PLAN_INTERACTIF.UPDATE')"
            :inputLabel="$t('importer-fichier-jpg-png')"
            :uidToUpload="params.data.uid"
            :defaultType="'plan-interactif'"
            :fileType="'image/*'"
            :defaultTag="tagFondDePlan"
            isUploadPI
            @begin-upload-file="onBeginUploadFondDePlan"
            @upload-done="onUploadFondDePlanDone" />
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "plan-interactif-cell-renderer",
        mixins:[LangMixins],
        components: {
            VgButton,
            VgFilesUploader
        },
        props: {
			params: {
                type: Object,
                required: true
            },
            exist: {
                type: Boolean,
                default: false
            },
            tagFondDePlan:{
                type: Object,
                default: null
            }
		},
		i18n:    { "locale":navigator.language,
            "messages": {
                "fr": {
                    "plan-interactif": "Voir plan interactif",
                    "importer-fichier-jpg-png": "Importer PNG/JPG"
                },
                "en": {
                    "plan-interactif": "Show interactive level plan"
                }
            }
        },
        methods:{
            /**
            * @param Object représente un fichier
            */
            onUploadFondDePlanDone: function(plan){
                this.$emit("upload-fond-de-plan-done", {etage: this.params.data, plan: plan});
            },
            /**
            * @param Object représente un fichier
            */
            onBeginUploadFondDePlan: function(plan){
                this.$emit("upload-fond-de-plan-begin", {etage: this.params.data, plan: plan});
            },
        }
    });
</script>

<style lang="scss" scoped>

</style>
