<template>
	<vg-dashboard-curatif-rapport
		:title="$app.handleFmBloquante?$t('title-bloquantes'):$t('title')"
		:defaultDateIntervalle="defaultDateIntervalle"
		:hideHeaderComplement="hideHeaderComplement"
		key="dashboard-curatif-rapport-repartition-urgences"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<vg-pie-chart v-if="counters"
			:chart-id="'dashboard-curatif-urgentes'"
			:height="'100%'"
			:datas="getStatistiques"
			:options="optionsDoughnut"
			:title="$t('title')"
			@selected="onSelectedPie" />
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";
	import VgPieChart from 'src/views/Statistiques/components/VgPieChart.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-curatif-urgences',
		mixins: [ DashboardMixins ],
		components: {
			VgDatePickerShortcuts,
			VgDashboardCuratifRapport,
			VgPieChart
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			defaultDateIntervalle: {
				type: Object,
				default: function(){
					return null;
				}
			},
			hideHeaderComplement: {
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Ouvertures fiches curatives urgentes / non urgentes",
            "title-bloquantes": "Ouvertures fiches curatives bloquantes / urgentes / non urgentes",
            "bloquantes": "Bloquantes",
            "urgentes": "Urgentes",
            "non-urgentes": "Non urgentes"
        },
        "en": {
            "title": "Urgent / non-urgent curative sheets openings",
            "title-bloquantes": "Blocking / urgent / non-urgent curative sheets openings",
            "bloquantes": "Blocking",
            "urgentes": "Urgent",
            "non-urgentes": "Non-urgent"
        }
    }
},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			},
			defaultDateIntervalle: {
				handler: function(newIntervalle){
					this.dateIntervalle = Object.assign({}, {}, newIntervalle);
					this.fetch();
				}
			}
		},
		created: function(){

		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			onSelectedPie: function(data){
				let filters = this.getStatistiques[0]._filters[data.index];
				this.$emit("selected", {filters: filters, dateIntervalle: this.dateIntervalle});
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.fetch();
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				if(this.dateIntervalle){
					filters = Object.assign(filters, {
						dateOuvertureSAV: {attr: "fm.dateOuvertureSAV", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"}
					});
				}
				this.metadatas.setFilters(filters);
				this.DashboardMixins_getCuratifUrgentes(this.metadatas).then((datas)=>{

				});
			}
		},
        computed: {
			...mapGetters({
	            counters: 'DashboardStore/getCuratifUrgentes',
	        }),
			getStatistiques: function(){
				if(this.$app.handleFmBloquante) return this.getStatistiquesUrgentesEtBloquantes;
				else return this.getStatistiquesUrgentes;
			},
			getStatistiquesUrgentes: function(){
				let counters = this.counters || [];
				let normalizedStatistiques = {
					label: [ this.$t("urgentes"), this.$t("non-urgentes") ],
					backgroundColor: ["#cb4335", "#f7dc6f"],
					data: [ counters.urgentes, counters.nonUrgentesNonBloquantes ],
					_datas: [ counters.urgentes, counters.nonUrgentesNonBloquantes ],
					_filters: [
						[{attr: "fm.urgence", value: "1", action: "equals"}],
						[
							{attr: "fm.urgence", colId: "notUrgence", value: "0", action: "equals", openParenthesis:true}, 
							{attr: "fm.urgence", colId: "nullUrgence", value: 1, action: "is_null", logicalOperator: "OR", closeParenthesis:true}
						]
					]
				};
				return [normalizedStatistiques];
			},
			getStatistiquesUrgentesEtBloquantes: function(){
				let counters = this.counters || [];
				let normalizedStatistiques = {
					label: [ this.$t("bloquantes"), this.$t("urgentes"), this.$t("non-urgentes") ],
					backgroundColor: ["#cb4335", "#ff7f00", "#f7dc6f"],
					data: [ counters.bloquantes, counters.urgentes, counters.nonUrgentesNonBloquantes ],
					_datas: [ counters.bloquantes, counters.urgentes, counters.nonUrgentesNonBloquantes ],
					_filters: [				
						[{attr: "fm.isBloquant", value: "1", action: "equals"}],
						[{attr: "fm.urgence", value: "1", action: "equals"}],
						[
							{attr: "fm.isBloquant", colId: "notBloquant", value: "0", action: "equals"},
							{attr: "fm.urgence", colId: "notUrgence", value: "0", action: "equals", openParenthesis:true}, 
							{attr: "fm.urgence", colId: "nullUrgence", value: 1, action: "is_null", logicalOperator: "OR", closeParenthesis:true}
						]
					]
				};
				return [normalizedStatistiques];
			},
		}
    };

</script>
<style lang="scss" scoped>

</style>
