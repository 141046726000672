//Notifications store module

// initial state
const state = {
	collection: [
		{ id:1, commentaireClient: "test 1", statut:"En_cours", dateOuvertureSAV: "2017-01-12 17:00", materiel: 196, idUser: 9, user: "SD" },
		{ id:2, commentaireClient: "test 2", statut:"En_cours", dateOuvertureSAV: "2017-01-12 17:30", materiel: 198, idUser: 9, user: "SD" },
		{ id:3, commentaireClient: "test 3", statut:"Resolue", dateOuvertureSAV: "2017-01-12 17:40", materiel: 196, idUser: 9, user: "SD" }
	]
};

// getters
const getters = {
	all: state => {
		return state.collection;
	}
};

// actions
const actions = {
	
};

// mutations
const mutations = {
	
};

export default {
  state,
  getters,
  actions,
  mutations
};