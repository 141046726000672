<template>
<div class="vg-verification-viewer vg-print-hide">
	<tag-grid
		:idTableau="idTableau"

		v-model="gridOptions"
		:columnDefs="columnDefs"
		:rowData="verifications"
		:showLoadingOverlay="showLoadingOverlay"
		:overlayLoadingText="$t('overlay-loading-text')"
		:overlayNoRowsText="$t('overlay-no-rows-text')"

		@grid-feeded="setDefaultFilter()"

		@on-grid-ready="setDefaultFilter()"
		@ag-click="handleClickRow"
		@ag-dbl-click="handleDoubleClickRow" />
	<vg-pagination v-show="paginate"
		:totalItems="counters.filtered"
		:offset="offset"
		:limit="limit"
		@pagination-change="handlePaginationChange" />
	<!-- IMPRESSION -->
    <vg-modal
        v-if="showRapport"  @close="showRapport=false">
        <template v-slot:body>
            <vg-reporting-prints
				class="vg-print-show"
				:idTableau="'vg-fiche-sav'"
				:data="maintenances"
				:columnDefs="columnDefs"
				:search="searchQuery"
				:allTitle="reportTitle?reportTitle:$t('report-title')">
			</vg-reporting-prints>
        </template>
    </vg-modal>
</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";
	import TagGridMixins from 'src/mixins/TagGridMixins.js';
	import VerificationMixins from 'src/mixins/VerificationMixins.js';
    import VgReportingPrints from "src/components/Vg/TagGrid/VgReportingPrints.vue";
    import VerificationCellRender from "src/components/Vg/TagGrid/VerificationCellRender.vue";
	import DatetimeCellRender from 'src/components/Vg/TagGrid/DatetimeCellRender.vue';
	import PathCellRender from 'src/components/Vg/TagGrid/PathCellRender.vue';
	import ThumbnailCellRender from 'src/components/Vg/TagGrid/ThumbnailCellRender.vue';
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import VgPagination from "src/components/Vg/VgPagination.vue";

	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-verifications-viewer',
    	props: {
			idTableau:{
				type: String,
				default: "vg-verifications-table"
			},
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return ["id", "commentaireClient", "piece"];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* titre rapport impression
			*/
			reportTitle: {
				type: String,
				default: null
			},
			/**
			* paginate
			*/
			paginate: {
				type: Boolean,
				default: false
			},
			offset: {
				type: Number,
				default: 0
			},
			limit: {
				type: Number,
				default: 25
			},
        },
		watch: {
			searchQuery: {
				handler: function(query){
					if(!this.paginate) this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
					this.reinitPagination();
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			}
		},
		mixins: [
			TagGridMixins,
			VerificationMixins
		],
		components: {
			TagGrid,
			VgReportingPrints,
			VgPagination
		},
		i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
		"overlay-loading-text": "Chargement des tâches récurrentes...",
		"overlay-no-rows-text": "Aucune tâche récurrente correspondant au filtrage",
        "column-header-date-verification": "Date vérification",
        "column-header-localisation": "Localisation",
        "column-header-tache": "Tache",
        "column-header-tache-type": "Type tache",
        "column-header-equipement": "Equipement/composant",
        "column-header-qrCode": "code",
        "column-header-nb-non-conformite": "Nb non conformité",
        "column-header-operateur": "Opérateur",
        "column-header-commentaire": "Commentaire",
        "column-header-checkpoint": "Point de vérification",
        "column-header-reponse": "Réponse",
        "column-header-reponse-attendue": "Réponse attendue",
        "column-header-statutFM": "Statut fiche curative",
        "column-header-spentTime": "Temps passé ( minutes )",
        "column-header-photo": "Photo"
    },
    "en": {
		"overlay-loading-text": "Loading recurrent tasks...",
		"overlay-no-rows-text": "No recurrent task matching filtering",
        "column-header-date-verification": "Verification date",
        "column-header-localisation": "Location",
        "column-header-tache": "Task",
        "column-header-tache-type": "Task type",
        "column-header-equipement": "Equipment/component",
        "column-header-qrCode": "code",
        "column-header-nb-non-conformite": "Non compliance amount",
        "column-header-operateur": "Operator",
        "column-header-commentaire": "Comment",
        "column-header-checkpoint": "Verification marks",
        "column-header-reponse": "Answer",
        "column-header-reponse-attendue": "Answer pending",
        "column-header-statutFM": "Curative sheet Status",
        "column-header-spentTime": "Time spent (minutes)",
        "column-header-photo": "Photo"
    },
    "th": {
        "column-header-date-verification": "วันที่การตรวจสอบ",
        "column-header-localisation": "ที่ตั้ง",
        "column-header-tache": "ภารกิจ",
        "column-header-tache-type": "ประเภทภารกิจ",
        "column-header-equipement": "อุปกรณ์/ชิ้นส่วน",
        "column-header-qrCode": "รหัส",
        "column-header-nb-non-conformite": "จำนวนการไม่ร้องเรียน",
        "column-header-operateur": "ผู้ปฏิบัติการ",
        "column-header-commentaire": "ความคิดเห็น",
        "column-header-checkpoint": "เครื่องหมายการตรวจสอบ",
        "column-header-reponse": "คำตอบ",
        "column-header-reponse-attendue": "คำตอบที่รอดำเนินการ",
        "column-header-spentTime": "เวลาที่ใช้ไป (นาที)"
        }
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
				showRapport: false,
				gridOptions: {
					rowHeight: this.$vgutils.isMobile()?114:40,
				},
				columnDefs: [
	                {
						headerName: this.$t("column-header-date-verification"),//"Date vérification"
						field: "dateVerif",
						colId: "dateVerif",
	                    getQuickFilterText: (params) => this.$vgutils.getDate(params.data.dateVerif),
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateVerif}}).$mount().$el,
					},
	                {
						headerName: this.$t("column-header-localisation"), // Localisation
						field: "path",
						colId: "path",
	                    tooltipField: 'path',
	                    cellRenderer: (params) => new PathCellRender({propsData: {params: params, path: params.data.path}}).$mount().$el
					},
	                {
						headerName: this.$t("column-header-tache"),//"Tache",
						field: "libel_tache",
						colId: "libel_tache",
	                    cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn:  params.data.libel_tache, icon: "static/assets/icone/circle/tache-de-verification.png"}}).$mount().$el
					},
	                {
						headerName: this.$t("column-header-tache-type"),
						field: "type_tache",
						colId: "type_tache",
	                    hide: true,
	                    cellRenderer: (params) => params.data.type_tache
					},
	                {
						headerName: this.$t("column-header-equipement"),//"Equipement/composant",
						field: "libel_equipement",
						colId: "libel_equipement",
	                    getQuickFilterText: (params) => params.data.isGroupEqp=="1"?params.data.libelComposant: params.data.libel_equipement,
	                    cellRenderer: (params) => params.data.isGroupEqp=="1"?params.data.libelComposant: params.data.libel_equipement
					},
	                {
						headerName: this.$t("column-header-qrCode"),//"Equipement/composant",
						field: "qrCode",
						colId: "qrCode"
	                },
	                {
						headerName: this.$t("column-header-nb-non-conformite"),//"Nb non conformité",
						field: "nbNonConformites",
						colId: "nbNonConformites",
	                    cellRenderer: (params) => params.data.nbNonConformites
					},
	                {
						headerName: this.$t("column-header-operateur"),//"Opérateur",
						field: "username",
						colId: "username",
	                    cellRenderer: (params) => params.data.username
					},
	                {
						headerName: this.$t("column-header-commentaire"),//"Commentaire",
						field: "commentaire",
						colId: "commentaire",
	                    cellRenderer: (params) => params.data.commentaire
					},
	                {
						headerName: this.$t("column-header-spentTime"),//"Commentaire",
						field: "spentTime",
						colId: "spentTime",
	                    cellRenderer: (params) => params.data.spentTime
					},
	                {
						field: "qrCode",
						colId: "qrCode",
	                    hide: true,
	                    isColumnShowable: true
					},
					{
						headerName: this.$t("column-header-photo"),
						field: "photos",
						colId: "photos",
						cellRenderer: (params) => new ThumbnailCellRender({propsData: {params: params, attributeName: "photos", attributeUid: "uid"}}).$mount().$el
					}
	            ],
				metadatas: new Metadatas()
            };
        },
		created: function(){
			if(this.$vgutils.isMobile()){
				this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                })
                this.columnDefs.unshift({
					cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new VerificationCellRender({propsData: {params: params}}).$mount().$el
				});
            }
			if(this.paginate) this.metadatas.setLimit(this.offset, this.limit);
			this.fetch();
		},
		mounted: function(){
			/*this.$root.$on("export-pdf",(event)=>{
				//console.log("EXPORT PDF", event);
			});*/
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		computed:{
			...mapGetters({
				verifications: 'VerificationsStore/getCollection',
				counters: "VerificationsStore/getCounters"
            }),
		},
		methods: {
			reinitPagination: function(){
				this.offset = 0;
				this.limit = 25;
				this.metadatas.setLimit(this.offset, this.limit);
			},
			generateReport: function(datas){
				if(datas.detail.idTableau=="vg-verifications-table"){
					this.showRapport = true;
				}
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				this.metadatas.setFilters(this.filters);
				this.VerificationMixins_getVerifications(this.metadatas).then((datas)=>{
					//console.log("FETCH SUCCESS", datas);
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", {verifications: this.verifications, columnDefs: this.columnDefs});
	            });
			},
			setDefaultFilter: function(){
				if(this.showPrint) this.gridOptions.domLayout= "forPrint";
				this.$emit("grid-feeded", this.gridOptions);
			},
			handleClickRow: function(e){
				this.$emit("row-click", e.data);
			},
			handleDoubleClickRow: function(e){
				this.$emit("row-dbl-click", e.data);
			},
			handlePaginationChange: function(pagination){
	            this.metadatas.setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
	            this.fetch();
	        }
		},
		beforeDestroy:function(){
	        window.removeEventListener("askGenerateReport", this.generateReport);
	    },
    };
</script>
<style lang="scss" scoped>
.vg-verification-viewer{
	height: 100%;
    width: 100%;
}
</style>
