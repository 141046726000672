<template lang="html">
	<vg-input
		:title="title?title:$t('title')">
		<vg-select v-model="selectedValidateur"
			:options="validateurs"
			:multiple="false"
			:attributeLabel="'nom'"
			:attributeValue="'id'"
			:clearable="true"
			:placeholder="title"
			@input="handleInputChange"
			>
			<template v-slot="{option}">
				{{ option.nom }} {{ option.prenom }}
			</template>
			<template v-slot:selected-option="{option}">
				{{ option.nom }} {{ option.prenom }}
			</template>
		</vg-select>
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	
	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-filter-bons-de-commande-validateurs',
	    components: {
			VgInput,
			VgSelect
        },
		mixins:[
			BonsDeCommandeMixins
        ],
        props: {
			/**
			 * @model
			 */
			value: {
				type: Array | String,
				required: true
			},
			title: {
				type: String,
				default: null
			},
			/**
			* attribut de l'entité choisi renvoyé au v-model
			* default "id"
			* peut être null renvoie tout l'objet entité
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			 * 2version d'affichage du composant disponible, "dropdown" = avec vg-select || "modal" = avec modale de selection multiple
			 * @values dropdown || modal
			 */
			display:{
				type:String,
				default:"dropdown"
			}
        },
		i18n:    { "locale":navigator.language,
			"messages": {
				"fr": {
					"title": "Filtrage par validateur"
				},
				"en": {
					"title": "Filter by validator"
				}
			}
		},
		data: function() {
				return {
					selectedValidateur: this.value || [],
					metadatas: new Metadatas(),
					agfilters: {}
				};
			},
			watch: {
				value: {
					handler: function(newValue){
						this.selectedValidateur = newValue;
					},
					deep: true
				}
			},
			created: function(){
				this.fetch();
			},
			methods: {
				fetch: function(){
					this.metadatas.setFilters(this.agfilters);
					this.BonsDeCommandeMixins_getValidateurs(this.metadatas).then((datas)=>{
						console.log("GET BCs VALIDATEURS", datas);
					});
				},
				handleInputChange:function(entite){
					this.$emit('input', entite);
					this.$emit('change', entite);
				}
			},
			computed: {
				...mapGetters({
					validateurs: "BonsDeCommandeStore/getValidateurs"
				}),
			}
	};
</script>

<style lang="scss" scoped>

</style>
