<template>
    <div v-if="params.data.type_reponse=='Index'">
        {{$t("min")}} = {{params.data.minVal}} | {{$t("max")}} = {{params.data.maxVal}}
    </div>
    <div v-else-if="params.data.type_reponse=='Nombre'">
        {{$t("min")}} = {{params.data.minVal}} | {{$t("max")}} = {{params.data.maxVal}}
    </div>
    <div v-else-if="params.data.type_reponse=='Oui/Non'">
        {{$t("oui")}}
    </div>
    <div v-else-if="params.data.type_reponse=='Non/Oui'">
        {{$t("non")}}
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
	    	"messages": {
                "fr": {
                    "oui": "Oui",
                    "non": "Non",
                    "min": "min",
                    "max": "max"
                },
                "en": {
                    "oui": "Yes",
                    "non": "No",
                    "min": "min",
                    "max": "max"
                }
            }
        }
    });
</script>

<style scoped>

</style>
