<template>
	<div v-if="value.hasOwnProperty('id')"
		:class="['vg-tree-node-categorie',{'selected':isSelected, 'tree-node-generique':isGenerique}]"
		@click="onClick"
		@contextmenu.prevent="onContextMenu">
		<div style="display:flex;justify-content:flex-start;align-items:center;gap:10px;">
			<img :src="value.icon" class="icon-categorie"/>
			<span>{{value.libelleCatgorie}}</span>
		</div>
	</div>
	<div v-else
		:class="['vg-tree-node-categorie',{'selected':isSelected, 'tree-node-generique':isGenerique}]"
		@click="onClick"
		@contextmenu.prevent="onContextMenu">
		<span>{{value.label?value.label:$t("empty-label")}}</span>
		<vg-button :type="isTagVisible?'grey':'danger'" size="sm" @click="onClickTagVisibility">
			<i class="fas fa-eye" v-if="isTagVisible"></i>
			<i class="fas fa-eye-slash" v-else></i>
		</vg-button>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import UserParametersMixins from "src/mixins/UserParametersMixins.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-tree-node-categorie',
		components: {
			VgButton
		},
		mixins: [UserParametersMixins],
        props: {
			value:{
				type: Object
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "empty-label": "Aucune étiquette"
        },
        "en": {
            "empty-label": "No tag"
        }
    }
},
		watch:{
			value: {
				handler: function(newnode){
					this.node = newnode;
				},
				deep: true
			}
		},
        data: function() {
            return {
				node: this.value,
				isSelected: false,
				isTagVisible: true
            };
        },
		created: function(){
			this.initTagVisibility();
		},
		mounted: function(){

		},
		methods: {
			initTagVisibility: function(){
				let restrictionCategorieTags = window.sessionStorage.getItem('exclude_categorie_tags');
				restrictionCategorieTags = restrictionCategorieTags?restrictionCategorieTags.split("|"):[];
				if(this.value.hasOwnProperty("location")) this.isTagVisible = restrictionCategorieTags.indexOf(this.value.location)==-1;
			},
			onClick: function(){
				this.isSelected = !this.isSelected;
				this.$emit("select", this.node);
			},
			onContextMenu: function(e){
				this.$emit("context-menu", {event: e, node: this.node, isGenerique: this.isGenerique});
			},
			emitLoading: function(isLoading=true){
				this.$emit("node-loading", isLoading);
			},
			emitFilter: function(){
				this.$emit("excluded-tags", window.sessionStorage.getItem("exclude_categorie_tags"));
			},
			saveTagVisibility: function(isTagVisible, tag){
				this.emitLoading();
				let restrictionCategorieTags = window.sessionStorage.getItem("exclude_categorie_tags");
				let userParameterId = window.sessionStorage.getItem("exclude_categorie_tags_user_parameter_id");
				restrictionCategorieTags = restrictionCategorieTags?restrictionCategorieTags.split("|"):[];
				let indexTag = restrictionCategorieTags.indexOf(tag);
				if(indexTag==-1) restrictionCategorieTags.push(tag);
				else restrictionCategorieTags.splice(indexTag, 1);
				restrictionCategorieTags = restrictionCategorieTags.join("|");
				if(!userParameterId || userParameterId=="null"){
					let userParameters = [
						{name: "exclude_categorie_tags", type: "string", entry: restrictionCategorieTags, "user_id": this.$app.idUser}
					];
					this.UserParametersMixins_create(userParameters).then((datas)=>{
						window.sessionStorage.setItem("exclude_categorie_tags", restrictionCategorieTags);
						window.sessionStorage.setItem("exclude_categorie_tags_user_parameter_id", datas[0].id);
						this.emitLoading(false);
						this.emitFilter();
					});
				}else{
					this.UserParametersMixins_update([{id: userParameterId, entry: restrictionCategorieTags}]).then((datas)=>{
						window.sessionStorage.setItem("exclude_categorie_tags", restrictionCategorieTags);
						this.emitLoading(false);
						this.emitFilter();
					});
				}
			},
			onClickTagVisibility: function(){
				this.isTagVisible = !this.isTagVisible;
				this.saveTagVisibility(this.isTagVisible, this.value.location);
			}
		},
        computed: {
			...mapGetters({

	        })
		}
    };

</script>
<style lang="scss" scoped>
.vg-tree-node-categorie{
	width: 100%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	.icon-categorie{
		width: 16px;
		height: 16px;
	}
}
</style>
<docs>
    ### Exemples
    ```js
    new Vue({
      data(){
        return {

        }
      },
      template: `
        <div>
            <vg-tree-node
                >
            </vg-tree-node>
        </div>
      `
    })
    ```
</docs>
