<template>
    <div v-if="params.data.lastInterventionExterne">
        <vg-datetime-viewer v-model="params.data.lastInterventionExterne.dateEffectiveDebut" />
        <span>{{$t("par")}}</span>
        <span v-if="params.data.lastInterventionExterne.tiers && params.data.lastInterventionExterne.tiers.name"
            style="font-weight: bold;">
            {{params.data.lastInterventionExterne.tiers.name}}
        </span>
        <vg-button type="info" size="sm" style="margin-left:10px;" @click="$emit('open-intervention', params.data.lastInterventionExterne)">{{ $t("voir-intervention") }}</vg-button>
    </div>
    <div v-else>

    </div>
</template>

<script>
    import Vue from "vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "intervention-externe-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgDatetimeViewer,
            VgButton
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "par": "par",
            "voir-intervention": "Voir intervention"
        },
        "en": {
            "par": "by",
            "voir-intervention": "Show intervention"
        },
        "th": {
            "par": "โดย"
        }
    }
}
    });
</script>

<style scoped>

</style>
