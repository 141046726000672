<template>
	<vg-button-group>
		<vg-button
			:type="'default-link'"
			:size="'sm'"
			@click="decrementYear()">
			<i class="fas fa-chevron-left"></i>
		</vg-button>
		<vg-select v-model="period.year"
			:placeholder="$t('select-year')"
			:clearable="false"
			:options="years"
			@input="emitChange()">
		</vg-select>
		<vg-button
			:type="'default-link'"
			:size="'sm'"
			@click="incrementYear()">
			<i class="fas fa-chevron-right"></i>
		</vg-button>
	</vg-button-group>
</template>
<script>
import VgButtonGroup from "src/components/Vg/VgButtonGroup.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
export default {
	name: "vg-year-selector",
	components: {
		VgButtonGroup,
		VgButton,
		VgSelect
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-year": "Sélectionner une année"
        },
        "en": {
            "select-year": "Select a year"
        },
        "th": {
            "select-year": "เลือกปี"
        }
    }
} ,
	props: {
        value: Object
	},
	data: function(){
		return {
			period: this.value
		};
	},
	mounted: function() {

	},
	methods: {
		emitChange: function(){
			this.$emit('input', this.period);
		},
		decrementYear: function(){
			this.period.year--;
			this.emitChange();
		},
		incrementYear: function(){
			this.period.year++;
			this.emitChange();
		},
	},
	computed:{
		years: function(){
			var year = 2014;
			var currentyear = moment().year();
			var years = [];
			while(currentyear>=year){
				years.push(currentyear);
				currentyear--;
			}
			return years;
		},
	},
}
</script>

<style lang="scss" scoped>
div{
	width: 180px;
}
</style>
