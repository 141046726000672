<template>
    <vg-modal
        :title="$t('title', {numero: maintenance.id})"
        @close="$emit('close')">
        <template #body >
            <vg-input v-model="commentaire"
                :title="$t('commentaire')"
                inputType="textarea"
                isRequiredValue />
        </template>
        <template #footer>
            <vg-button @click="$emit('close')" >
                {{ $t("cancel") }}
            </vg-button>
            <vg-button type="danger" :disabled="disabledRelance || !commentaire || commentaire.length==0"
                @click="saveRelance">
                {{ $t("relancer") }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgModal from "src/components/Vg/VgModal.vue";

import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

export default {
    name:"vg-fichesav-relance-modal",
    props:{

    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Relancer la maintenance n° {numero}",
            "commentaire": "Commentaire",
            "cancel": "Annuler",
            "relancer": "Relancer"
        },
        "en": {
            "title": "Reviving maintenance No {numero}",
            "commentaire": "Comment",
            "cancel": "Cancel",
            "relancer": "Revive"
        }
    }
},
    mixins: [ MaintenanceMixins ],
    data: function(){
        return {
            commentaire: null,
            disabledRelance: false
        };
    },
    components:{
        VgInput,
        VgButton,
        VgModal
    },
    computed:{
        ...mapGetters({
              maintenance: 'MaintenancesStore/getSelectedMaintenance'
        })
    },
    created:function(){

    },
    methods: {
        saveRelance: function(){
            this.disabledRelance = true;
            this.MaintenanceMixins_relancer(this.maintenance, this.commentaire).then((data)=>{
                this.$emit("save");
            });
        },
    },
    beforeDestroy: function(){

    }
}
</script>

<style lang="scss" scoped>

</style>
