<template>
    <div class="vg-mouvements-table">
        <tag-grid
            v-model="gridOptions"
            idTableau="mouvements-equipements-table"
            :columnDefs="columnDefs"
            :rowData="mouvements"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            @ag-click="handleClick"
        />
        <!--vg-pagination v-if="paginate"
            :totalItems="counters?counters.filtered:0"
            :offset="offset"
            :limit="limit"
            @pagination-change="handlePaginationChange"
        /-->
    </div>
</template>
<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';

import MouvementsEquipementsMixins from "src/mixins/MouvementsEquipementsMixins.js"
import MouvementsEquipementsCellRender from 'src/components/Vg/TagGrid/MouvementsEquipementsCellRender.vue';
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
import FileLinkCellRender from "src/components/Vg/TagGrid/FileLinkCellRender.vue";
import MouvementsEquipementTypeCellRender from "src/components/Vg/TagGrid/MouvementsEquipementTypeCellRender.vue"
import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-mouvements-equipements-table',
    components:{
        TagGrid
    },
    mixins:[MouvementsEquipementsMixins],
    props: {
        id:{
            type: String,
            default: "lieux-tableau"
        },
        /**
        * liste des columns à hide (column colId)
        */
        hiddenColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * liste des columns pined left (column colId)
        */
        pinnedColumns: {
            type: Array,
            default: function(){
                return ["id", "commentaireClient", "piece"];
            }
        },
        /**
        * liste des columns non showable (column colId)
        */
        notShowableColumns: {
            type: Array,
            default: function(){
                return [];
            }
        },
        /**
        * affiche le tableau en mode impression
        * default false
        */
        showPrint : {
            type: Boolean,
            default: false
        },
        /**
        * recherche
        */
        searchQuery: {
            type: String,
            default: ""
        },
        /**
        * filtres externes appliqué sur le tableau au fetch
        */
        filters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * filtres externes appliqué sur le tableau en local
        */
        localFilters: {
            type: Object,
            default: function(){
                return {};
            }
        },
        /**
        * titre rapport impression
        */
        reportTitle: {
            type: String,
            default: null
        },
        /**
        * paginate
        */
        paginate: {
            type: Boolean,
            default: false
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 25
        },
        /**
        * applique le rendu du tableau pour la page DI
        */
        isDemandeIntervention: {
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "pret":"Sortie temporaire",
        "retour":"Retour",
        "type-mouvement": "Type mouvement",
        "signataire-donneur": "Signataire donneur",
        "signataire-receveur": "Signataire receveur",
        "date-mouvement": "Date mouvement",
        "date-retour-prevue": "Date retour prévue",
        "commentaire": "Commentaire",
        "destination": "Destination",
        "document": "Document",
        "total-equipement": "Total équipements",
        "overlay-loading-text": "Chargement des mouvements",
        "overlay-no-rows-text": "Aucun mouvement"
    }
    }
},
    data: function() {
        return {
            showLoadingOverlay: false,
            gridOptions: {
                /*headerHeight: this.$vgutils.isMobile()||this.isDemandeIntervention?0:40,*/
                rowHeight: this.$vgutils.isMobile() ? 120:40
            },
            columnDefs: [
                {
                    headerName: '',
                    colId: 'checkbox',
                    width: 50,
                    maxWidth: 50,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    pinned: 'left',
                    isVisible: false
                },
                {
                    headerName: this.$t("type-mouvement"),
                    colId: 'type',
                    width: 200,
                    field: 'type',
                    suppressSorting: true,
                    cellRenderer: (params) => new MouvementsEquipementTypeCellRender({propsData: {params: params.data}}).$mount().$el,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("signataire-donneur"),
                    colId: 'commentaire',
                    width: 200,
                    field: 'signataireDonneur',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("signataire-receveur"),
                    colId: 'commentaire',
                    width: 200,
                    field: 'signataireReceveur',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("date-mouvement"),
                    colId: 'dateMouvement',
                    width: 200,
                    field: 'dateMouvement',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("date-retour-prevue"),
                    colId: 'dateRetourPrevu',
                    width: 200,
                    field: 'dateRetourPrevu',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("commentaire"),
                    colId: 'commentaire',
                    width: 200,
                    field: 'commentaire',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"]
                },
                {
                    headerName: this.$t("destination"),
                    colId: 'destination',
                    width: 200,
                    field: 'destination',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    cellRenderer: (params) => {
                        if(params.data.destination){ 
                            if(!params.data.destination.path){
                                return "-";
                            }else{
                                return new PathCellRender({propsData: {path: params.data.destination.path}}).$mount().$el
                            }
                        }else{
                            return "-";
                        }
                    },
                },
                {
                    headerName: this.$t("document"),
                    colId: 'document',
                    width: 200,
                    field: 'document',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    cellRenderer: (params) => {
                        if(!params.data.document) return "-";
                        return new FileLinkCellRender({propsData: {params: params, isAutomaticOpenLink: false}}).$mount().$el
                    },
                },
                {
                    headerName: this.$t("total-equipement"),
                    colId: 'destination',
                    width: 200,
                    field: 'destination',
                    suppressSorting: true,
                    cellClass: ["vg-cell-checkbox"],
                    cellRenderer: function (params) {
                        return params.data.equipements.length;
                    }
                },
            ],
            sites:[],
            metadatas: new Metadatas()
        };
    },
    watch: {
        searchQuery: {
            handler: function(query){
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
        filters: {
            handler: function(newfilters){
                this.fetch(newfilters);
            },
            deep: true
        },
        localFilters: {
            handler: function(newfilters){
                // //console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
                //if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
            },
            deep: true
        }
    },
    created:function(){
        if(this.$vgutils.isMobile()){
            this.columnDefs.map((columnDef)=>{
                columnDef.hide = true;
            })
            this.columnDefs.unshift({
                cellClass: ["vg-cell-mobile-card"],
                cellRenderer: (params) => new MouvementsEquipementsCellRender({propsData: {params: params.data}})
                .$mount().$el
            });
        }
        this.fetch(this.filters);
    },
    computed: {
        ...mapGetters({
            mouvements: 'MouvementsEquipementsStore/getCollection',
        }),
    },
    methods:{
        handleClick:function(row){
            this.$emit("row-click",row);
        },
        fetch: function(filters){
            let metadatas = new Metadatas();
        
            if(Object.keys(filters).length){
                metadatas.setFilters(filters);
            }
            this.MouvementsEquipementsMixins_get(metadatas).then(()=>{

            });
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-lieux-table{
        height: 100%;
        width: 100%;
        overflow: auto;
}
.loading-overlay{
    position: absolute;
    margin: auto;
    background-color: rgba(255,255,255,0.5);
    width: inherit;
    height: -webkit-fill-available;
    overflow: hidden;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .text{
        background-color:#35b1ea;
        color:white;
        border:none;
        padding:10px;
        font-size:14px;
        display: block;
    }
}
</style>
