//Interventions store module
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
state.depots = [];
let getters = Base.getGetters();
getters.getDepots = function(state){
    return state.depots;
};
getters.getDepotDefault = function(state){
    return state.depots.find((depot)=>depot.isDefault=="1");
};
getters.getDepotFlottant = function(state){
    return state.depots.find((depot)=>depot.isFlottant=="1");
};
let actions = Base.getActions();
actions.setDepots = function(context, depots){
    context.commit('SET_DEPOTS', depots);
};
let mutations = Base.getMutations();
mutations.SET_DEPOTS = function(state, depots){
    state.depots = [...depots];
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
