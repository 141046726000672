<template>
	<div>
		STATUTS PAIEMENT...
	</div>
</template>
<script>
	import GroupeValidateursMixins from "src/mixins/GroupeValidateursMixins.js";

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';

	export default {
        name: 'vg-bons-de-commande-parametrage-paiement',
		components: {
			VgButton,
			VgSelect
		},
		mixins: [ GroupeValidateursMixins ],
    	props: {

        },
        data: function() {
            return {
				metadatas: new Metadatas()
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {
			...mapGetters({
	            groupeValidateurs: 'GroupeValidateursStore/getCollection'
	        })
		}
    };

</script>
<style lang="scss" scoped>

</style>
