<template>
	<span>{{$t(role)}}</span>
</template>
<script>
export default {
    name: 'vg-user-role-viewer',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
			"ROLE_ADMIN": "Administrateur",
			"ROLE_TECHNICIEN": "Technicien",
			"ROLE_SUPERVISEUR": "Superviseur",
			"ROLE_SOUS_TRAITANT": "Sous-traitant",
			"ROLE_BASIC_VERIFICATEUR": "Demandeur vérificateur (ex: Gouvernante)",
			"ROLE_BASIC": "Demandeur"
        },
        "en": {
			"ROLE_ADMIN": "Administrator",
			"ROLE_TECHNICIEN": "Technician",
			"ROLE_SUPERVISEUR": "Supervisor",
			"ROLE_SOUS_TRAITANT": "Subcontractor",
			"ROLE_BASIC_VERIFICATEUR": "Verifier applicant (ex: Housekeeper)",
			"ROLE_BASIC": "Requester"
        }
    }
} ,
	props:{
		value:{
			type: String,
			required: true
		}
	},
	components: {

	},
    mixins:[],
    data: function() {
        return {
            role: this.value
        };
    },
    methods: {

    }
};
</script>
<style lang="scss" scoped>

</style>
