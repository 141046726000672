import Storage from "src/services/Storage";
import StorageShelf from "src/services/StorageShelf";

/**
 *
 *   AppConfigStorage.
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
 export default class AppConfigStorage extends Storage{
     constructor(storageName, __options={}){
         //this.storage = new Storage();
         super(__options);
         this.storageName = storageName;
     }
    /**
     * 
     * @param string storageName 
     */
    setStorageName(storageName){
        this.storageName = storageName;
        return this;
    }
    /**
     * 
     * @param string storageName 
     * @return StorageShelf
     */
    getShelf(storageName){
        let getExistingShelf = this.read(storageName);
        let storageShelf = new StorageShelf();
        if(getExistingShelf){
            storageShelf.setItems(getExistingShelf);
        }
        return storageShelf;
    }
    /**
     * 
     * @param string key 
     * @param any value 
     * @param string storage type
     * @return self
     */
    set(key,value,storageType="localStorage"){
        let storageShelf = this.getShelf(this.storageName);
        storageShelf.addItem(key,value);
        this.insert(this.storageName,storageShelf.items,storageType);
        return this;
    }
    /**
     * 
     * @param string key 
     * @param string storage type
     * @return any
     */
    get(key,storageType="localStorage"){
        let getExistingShelf = this.read(this.storageName,storageType);
        if(getExistingShelf){
            return getExistingShelf[key];
        }else{
            return null;
        }
        
    }   
 }