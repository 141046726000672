<template>
    <div class="VgInventaireViewer" >
        <VgLayoutShowInput
                :label="'name'"
                v-model="localInventaire.name"
                @blur="handleInputUpdate"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'numero inventaire'"
                v-model="localInventaire.numeroInventaire"
                @blur="handleInputUpdate"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'Site'"
                v-model="localInventaire.path"
                @blur="handleInputUpdate"
                :disabled="true"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'Services'"
                v-model="localInventaire.servicesLieu"
                @blur="handleInputUpdate"
                :disabled="true"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'Statut'"
                v-model="localInventaire.statut"
                @blur="handleInputUpdate"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'dateDebut'"
                v-model="localInventaire.dateDebut"
                @blur="handleInputUpdate"
                inputType="date"
                :disabled="true"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'date fin prévisionelle'"
                v-model="localInventaire.dateFin"
                @blur="handleInputUpdate"
                inputType="date"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'total equipement'"
                v-model="localInventaire.totalEquipement"
                @blur="handleInputUpdate"
                :disabled="true"
        >
        </VgLayoutShowInput>
        <VgLayoutShowInput
                :label="'total inventorie'"
                v-model="localInventaire.totalInventorie"
                @blur="handleInputUpdate"
                :disabled="true"
        >
        </VgLayoutShowInput>
    </div>
</template>

<script>
import VgLayoutShowInput from 'src/components/Vg/Layout/ShowLayout/VgLayoutShowInput';

import moment from 'moment';
export default {
    name: 'vg-inventaire-viewer',
    mixins:[],
    components: {
        VgLayoutShowInput
    },
    props: {
        inventaire:{
            type:Object,
            default:function(){
                return null
            },
            required:true
        }
    },
    data: function() {
        return {
            localInventaire:this.inventaire
        };
    },
    methods: {
        handleInputUpdate:function(){
            this.$emit('change-input-inventaire',this.localInventaire);
        }
    },
    computed: {}
};
</script>

<style scoped>

</style>
