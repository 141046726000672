/**
 *
 *  Publisher. Publish on a BroadcastChannel and standardize payload before
 *  sending
 *
 * @author TxTony
 * @version 1.0
 * @license MIT
 * @class
 * @example
        that.publisher.publish(m).then((datas)=>{
            // do whatever you want
        });
 */
class Publisher {
    /**
     *@constructor
     * @param string channel
     * @param string  id
     */
    constructor(channel, id = null) {
        this.channelName = channel;
        this.userMetadata = {};
        this.message = {};
        this.id = id;
        if(this.isBroadcastChannelAvalaible()){
            this.channel = new BroadcastChannel(channel);
        }
        return this;
    }
    /**
     * @param boolean
     */
    isBroadcastChannelAvalaible(){
        return BroadcastChannel ? true : false;
    }
    /**
    * Publish data on channel.
    *
    * @param string channel
    * @param Object payload
    * @return {Promise<Object>}
    */
    publish(payload){
        if(!this.isBroadcastChannelAvalaible())return;
        let datas = {
            channel: this.channelName,
            message: payload,
            publisher: this.id,
            userMetadata: this.userMetadata
        };
        this.message = datas;
        this.channel.postMessage(payload);
        return new Promise((resolve,reject)=>{
            resolve(datas);
        });
    }
    /**
    * Get BroadcastChannel.
    * @param string name
    * @return BroadcastChannel
    */
    getChannel(name){
        return this.channel;
    }
    /**
    * Setter. extra data relative to your message.
    *
    * @param Object userMetadata
    * @return this
    */
    setUserMetadata(userMetadata){
        this.userMetadata = userMetadata;
        return this;
    }
}
export default  Publisher;
