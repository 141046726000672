<template>
    <div class="origine-fm-cell-render">
		<img v-if="params.data.origin" :src="getIcon"
            onerror="this.style.visibility='hidden'"/>
        <small v-else>{{$t("manuelle")}}</small>
        <span v-if="params.data.origin!='intervention'">{{params.data.contact}}</span>
        <span v-else>{{$t('intervention-numero')}} {{params.data.intervention.numeroIntervention}}</span>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"origine-fm-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "manuelle": "Manuelle",
            "intervention-numero": "Intervention n°"
        },
        "en": {
            "manuelle": "Manual",
            "intervention-numero": "Intervention n°"
        },
        "th": {
            "manuelle": "ด้วยตนเอง"
        }
    }
},
        computed:{
            getIcon: function(){
                if(this.params.data.origin=="DI") return "/static/assets/icone/origine-DI-jaune.png";
                else if(this.params.data.origin=="Verification") return "/static/assets/icone/origine-verification-orange.png";
                else if(this.params.data.origin=="IOT") return "/static/assets/icone/capteur.png";
                else if(this.params.data.origin=="intervention") return "/static/assets/icone/intervention.png";
                else return "";
            }
        }
    });
</script>
<style lang="scss" scoped>
.origine-fm-cell-render{
    display:flex;
    align-items: center;
    img{
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
}
</style>
