<template>
    <div id="print-fm">
        <vg-button type="default-info" class="hidden-print"  @click="handleRetour">
            {{ $t("retour") }}
        </vg-button>
        <vg-button type="info" class="hidden-print" @click="print()">
            {{ $t("imprimer") }}
        </vg-button>
        <vg-button type="info" class="hidden-print" @click="changeModele">
            {{ $t("bouton-modele") }}
        </vg-button>
        <div class="modele1" v-if="modeleAlternatifDimpression">
            <div class="fm">
                <div class="fm__header row vg-margin-0 vg-vertical-center">
                    <div class="col-xs-6 vg-padding-0 vg-font-weight-bold">
                        {{ $t("ficheMaintenance") }}
                        <span class="vg-font-size-22">{{ fm.id }}</span>
                    </div>
                    <div class="col-xs-6 vg-padding-0 text-right">
                        {{ $t("dateOuverture") }} :
                        <span class="vg-font-weight-bold"><VgDatetimeViewer v-model="fm.dateOuvertureSAV"></VgDatetimeViewer></span>
                    </div>
                </div>
                <div id="fm__body">
                    <div class="description-fm">
                        <vg-fichesav-description :fm="fm"></vg-fichesav-description>
                        <!-- <span class="vg-font-size-18">{{ fm.commentaireClient }} {{$t("in-piece")}} </span> -->
                        <!-- <span class="vg-font-size-20 vg-font-weight-bold">{{ $vgutils.pathLevel(fm.equipement.path, 4) }}</span> -->
                    </div>

                    <div class="row vg-margin-0 vg-margin-bottom-10">
                        <div class="col-xs-4 vg-padding-0">{{ $t("emplacement") }} :</div>
                        <div class="col-xs-8 vg-padding-0">
                            <span class="vg-font-size-13">{{ $vgutils.formatPathWithoutRoom(fm.equipement.path) }}</span>
                            <span class=" vg-font-size-16 vg-font-weight-bold">/{{ $vgutils.pathLevel(fm.equipement.path, 4) }}</span>
                        </div>
                    </div>
                    <div class="row vg-margin-0 vg-margin-bottom-10">
                        <div class="col-xs-4 vg-padding-0">{{ $t("creeePar") }} :</div>
                        <div class="col-xs-8 vg-padding-0 vg-font-weight-bold" v-if="fm.contact">{{ fm.contact }} </div>
                        <div class="col-xs-8 vg-padding-0 vg-font-weight-bold"v-else>{{ fm.contact }} </div>

                    </div>
                    <div class="row vg-margin-0 vg-margin-bottom-10">
                        <div class="col-xs-4 vg-padding-0">{{ $t("origineDemande") }} :</div>
                        <div class="col-xs-8 vg-padding-0 vg-font-weight-bold">{{ fm.origin }}</div>
                    </div>
                    <div class="row vg-margin-0 vg-margin-bottom-40">
                        <div class="col-xs-4 vg-padding-0">{{ $t("equipementComposant") }} :</div>
                        <div class="col-xs-8 vg-padding-0">
                            <div v-if="fm.composant" class="row-equipement-composant">
                                <img :src="fm.composant.icon" alt="" v-if="fm.composant.icon" height="25">
                                <span class="vg-font-weight-bold">{{fm.composant.libelComposant}}</span>
                            </div>
                            <div v-else class="row-equipement-composant">
                                <span class="vg-font-weight-bold">{{ fm.equipement.libel_equipement }}</span>
                                <span class="vg-margin-left-10">{{ fm.equipement.qrCode }}</span>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div class="operation" >
                <div class="fm__body-operation">
                    <div>
                        {{$t("operationTable")}}
                    </div>
                    <vg-operations-viewer
                        v-if="operations.length"
    					:fm="fm"
    				>
    				</vg-operations-viewer>
                </div>
            </div>
        </div>
        <div class="modele2" v-else>
            <div class="header-tabPrint">
                {{$t("h-fichetravau")}}
            </div>
            <table class="table">

                <tr>
                    <td>{{ $t("ficheMaintenance") }}</td>
                    <td>{{ fm.id }}</td>
                </tr>
                <tr>
                    <td>{{ $t("dateOuverture") }} </td>
                    <td>{{ $vgutils.formatDatetimeFullDate(fm.dateOuvertureSAV) }}</td>
                </tr>
                <tr>
                    <td>{{$t('h-lieu')}}</td>
                    <td class="vg-font-weight-bold">{{ $vgutils.getPathEnd(fm.equipement.path) }}</td>
                </tr>
                <tr>
                    <td>{{ $t("equipementComposant") }}</td>
                    <td>
                        <div v-if="fm.equipement.isGroupEqp == '1'">
                            <span class="vg-font-weight-bold">{{fm.composant.libel_composant}}</span>
                        </div>
                        <div v-else>
                            <span class="vg-font-weight-bold">{{ fm.equipement.libel_equipement }}</span>
                            <span class="vg-margin-left-10">{{ fm.equipement.qrCode }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="height:80px">{{$t('h-objet')}}</td>
                    <td><vg-fichesav-description :fm="fm"></vg-fichesav-description></td>
                </tr>
                <tr>
                    <td>{{ $t("creeePar") }}</td>
                    <td v-if="fm.contact">
                        {{fm.contact}}
                    </td>
                    <td v-else>
                        {{ fm.nom }} {{ fm.prenom }}
                        <vg-input
                            style="width:100%"
                            v-model="getNomPrenom"
                        >
                        </vg-input>
                    </td>


                </tr>
                <tr>
                    <td>{{$t('h-corpsdetat')}}</td>
                    <td v-if="fm.affectation" style="display:flex;flex-direction:column;">
                        <span v-for="user in fm.affectation.affectes" :key="user.id">- {{user.user_name}} {{user.name}} </span>
                    </td>
                </tr>
                <tr>
                    <td>{{$t('h-service')}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{$t('h-datedebut')}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{$t('h-datefin')}} </td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{$t('h-nbHeure')}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{$t('h-observation')}}</td>
                    <td style="height:150px"></td>
                </tr>
                <tr>
                    <td>{{$t('h-signature')}}</td>
                    <td style="height:80px" ></td>
                </tr>

            </table>
        </div>

    </div>
</template>

<script>

import VgOperationsViewer from "src/components/Vg/Operation/VgOperationsViewer.vue";
import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

import { mapGetters } from 'vuex';
    export default {
        name: "print-fm",
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "imprimer": "Imprimer",
            "retour": "Retour",
            "ficheMaintenance": "Fiche curative N°",
            "dateOuverture": "Date d'ouverture",
            "emplacement": "Emplacement",
            "creeePar": "Demandeur",
            "origineDemande": "Origine demande",
            "equipementComposant": "Equipement/composant",
            "retoursObservations": "Retours/observations",
            "operationTable": "Operation",
            "h-lieu": "Lieu",
            "h-objet": "Objet",
            "h-datePrevi": "Date Previsionnel",
            "h-corpsdetat": "Traitant",
            "h-service": "Service",
            "h-datedebut": "Date debut",
            "h-datefin": "Date fin",
            "h-nbHeure": "Nombre d'heure",
            "h-observation": "Observation",
            "h-signature": "Signature Chef d'equipe",
            "h-fichetravau": "FICHE DE TRAVAUX",
            "bouton-modele": "Changer de modèle d'impression",
            "in-piece": "dans"
        },
        "en": {
            "imprimer": "Print",
            "retour": "Back",
            "ficheMaintenance": "Curative sheet N°",
            "dateOuverture": "Opening date",
            "emplacement": "Location",
            "creeePar": "Created by",
            "origineDemande": "Origin",
            "equipementComposant": "Equipment/component",
            "retoursObservations": "Observations",
            "operationTable": "Operation",
            "h-fichetravau": "WORKSHEET",
            "h-lieu": "Place",
            "h-objet": "Subject",
            "h-datePrevi": "Estimated date",
            "h-corpsdetat": "Trader",
            "h-service": "Service",
            "h-datedebut": "Start date",
            "h-datefin": "End date",
            "h-nbHeure": "Number of hours",
            "h-observation": "Comment",
            "h-signature": "Team leader's signature",
            "bouton-modele": "Change print model",
            "in-piece": "in"
        },
        "th": {
            "imprimer": "สั่งพิมพ์",
            "operationTable": "การปฏิบัติการ",
            "ficheMaintenance": "แผ่นงานการซ่อมบำรุงหมายเลข",
            "dateOuverture": "วันที่เปิด",
            "emplacement": "ตำแหน่งที่ตั้ง",
            "creeePar": "สร้างโดย",
            "origineDemande": "ที่มา",
            "equipementComposant": "อุปกรณ์/ส่วนประกอบ",
            "retoursObservations": "การสังเกตการณ์",
            "h-fichetravau": "แผ่นงาน",
            "h-lieu": "สถานที่",
            "h-objet": "หัวข้อ",
            "h-datePrevi": "วันที่โดยประมาณ",
            "h-corpsdetat": "ผู้ประกอบการ",
            "h-service": "บริการ",
            "h-datedebut": "วันที่เริ่มต้น",
            "h-datefin": "วันที่สิ้นสุด",
            "h-nbHeure": "จำนวนชั่วโมง",
            "h-observation": "ความคิดเห็น",
            "h-signature": "ลายมือชื่อของหัวหน้าทีม",
            "bouton-modele": "รุ่น",
            "in-piece": "ใน",
            "retour": "กลับ"
        }
    }
},
        mixins:[MaintenanceMixins],
        components:{
            VgDatetimeViewer,
            VgOperationsViewer,
            VgInput,
            VgButton,
            VgFichesavDescription
        },
        data () {
            return {
                fm:{},
                operations:[],
                modeleAlternatifDimpression:true,
            }
        },
        methods: {
            print: function() {
                window.print();
            },
            changeModele: function() {
                this.modeleAlternatifDimpression = !this.modeleAlternatifDimpression;
            },
            handleRetour: function() {
                this.$router.go(-1);
            },
            fetch: function(){
                this.MaintenanceMixins_getMaintenance(this.$route.params.id).then((maintenance)=>{
                    this.fm = this.getSelectedMaintenance;
                    this.operations = this.getSelectedMaintenance.operations;
                });
            }
        },
        mounted:function(){
            this.fetch();
        },
        computed:{
            ...mapGetters({
                  getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance'
            }),
            getNomPrenom:function(){
                return this.fm.nom+" "+this.fm.prenom;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #print-fm {
        min-height: 100vh;
        padding: 20px;
        .fm {
            border: 1px solid gray;
            font-size: 14px;
            padding: 20px;
            .fm__header {
                border-bottom: 2px solid gray;
                padding-bottom: 10px;
            }
            table {
                width: 100%;
                td {
                    border-bottom: 1px solid gray;
                    padding: 10px 0px;
                }
            }
            #fm__body{
                .description-fm{
                    margin: 20px 0px;
                    .vg-fichesav-description{
                        justify-content: center;
                    }
                }
                .row-equipement-composant{
                    display: flex;
                    justify-content:flex-start;
                    align-items:center;
                    img{
                        margin-right: 10px;
                    }
                }
            }
            .fm__body-observations {
                border: 1px solid gray;
                height: 200px;
                padding: 20px;
            }
            .fm__body-operation{
                margin-top: 25px;
                > div{
                    margin-bottom: 5px;
                }
            }
        }
        .operation {
            border: 1px solid gray;
            font-size: 14px;
            padding: 20px;
            // margin-top: 100%;
        }

    }
    .table{
        border: 1px solid black;
        tr{
            height: 40px;
            td:nth-child(1){
                width: 20%;
                font-size: 14px;
                font-weight: bold;
                padding: 8px;

            }
            td:nth-child(2){
                width: 60%;
                font-size: 15px;
                padding: 10px;
            }
        }
        th, td {
          border: 1px solid black;
        }
    }
    .header-tabPrint{
        height: 60px;
        background: lightgray !important;
        padding: 20px;
        font-size: 20px;
        width: 100%;
        border: 1px solid black;
        border-bottom: none;
        text-align: center;
    }
    /*@media print {
      .operation {
          border: 1px solid gray;
          font-size: 14px;
          padding: 20px;
          margin-top: 100%;
      }


    }*/

</style>
