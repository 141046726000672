<template>
	<vg-dashboard-curatif-rapport
		:title="$t('title')"
		:limit="limit"
		:defaultDateIntervalle="defaultDateIntervalle"
		showFutur
		key="dashboard-curatif-rapport-repartition-prochaines-interventions"
		@change="onChange"
		@drop="onDrop"
		@drag-start="onDragStart">
		<div style="overflow-y:auto;height:80%;" v-if="prochainesInterventions && prochainesInterventions.length!=0">
			<div v-for="(intervention, index) in prochainesInterventions" :key="index" class="intervention-row"
				@click="goToZoomTiers(intervention)">
				<div class="intervention-main">
					<b>{{intervention.tiers.name}}</b>
					<span>{{intervention.intitule}}</span>
				</div>
				<div class="intervention-date">
					<vg-datetime-viewer v-model="intervention.datePrevisionnelleDebut" />
					<span>{{$t("dans-x-jours", {jours: joursRestant(intervention.datePrevisionnelleDebut)})}}</span>
				</div>
			</div>
		</div>
		<div v-else style="color:#35b1ea;display:flex;justify-content:center;align-items:center;">
			<span>{{$t("aucune-prochaine-intervention-a-venir")}}</span>
		</div>
	</vg-dashboard-curatif-rapport>
</template>
<script>
	import VgPaginationLimitSelector from "src/components/Vg/VgPaginationLimitSelector.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
	import VgBarChart from 'src/views/Statistiques/components/VgBarChart.vue';
	import VgDashboardCuratifRapport from "src/components/Vg/Dashboard/VgDashboardCuratifRapport.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';
	import VgDatetimeViewer from 'src/components/Vg/VgDatetimeViewer.vue';

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import Metadatas from "src/services/Metadatas.js";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-dashboard-preventif-prochaines-interventions',
		mixins: [ DashboardMixins ],
		components: {
			VgPaginationLimitSelector,
			VgDatePickerShortcuts,
			VgBarChart,
			VgDashboardCuratifRapport,
			VgButton,
			VgDatetimeViewer
		},
    	props: {
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			limit:{
				type: Number,
				default: null
			},
			defaultDateIntervalle:{
				type: Object,
				default: function(){
					return null;
				}
			}
        },
        data: function() {
            return {
				metadatas: new Metadatas(),
				dateIntervalle: null,
				pagination: {
					offset: 0,
					limit: this.limit
				},

            };
        },
		i18n:    { "locale":navigator.language,
	    	"messages": {
			    "fr": {
					"title": "Interventions à venir",
					"dans-x-jours": "dans {jours} jours",
					"aucune-prochaine-intervention-a-venir": "Aucune intervention à venir"
			    },
			    "en": {
					"title": "Upcoming interventions",
					"dans-x-jours": "in {jours} days",
					"aucune-prochaine-intervention-a-venir": "No upcoming interventions"
			    }
	    	}
		},
		watch:{
			filters: {
				handler: function(f){
					this.fetch();
				},
				deep: true
			}
		},
		created: function(){
			
		},
		mounted: function(){
			this.init();
		},
		methods: {
			init: function(){
				this.dateIntervalle = Object.assign({}, {}, this.defaultDateIntervalle);
				this.fetch();
			},
			goToZoomTiers: function(intervention){
				this.$router.push({ name: '_tiers_id', params: { id: intervention.tiers.uid } });
			},
			joursRestant: function(dt){
				let today = moment();
				return moment(dt).diff(today, 'days');
			},
			onDrop: function(ev){
				this.$emit("drop", ev);
			},
			onDragStart: function(ev){
				this.$emit("drag-start", ev);
			},
			onChange: function(datas){
				this.dateIntervalle = datas.dateIntervalle;
				this.pagination = datas.pagination;
				this.fetch();
			},
			fetch: function(){
				let filters = Object.assign({}, {}, this.filters);
				filters.dateNow = {attr: "i.datePrevisionnelleDebut", value: moment().format("YYYY-MM-DD HH:mm"), action: "greater_than"};
				if(this.dateIntervalle) filters.datePrevisionnelleDebut = {attr: "i.datePrevisionnelleDebut", value: [this.dateIntervalle.startDate, this.dateIntervalle.endDate], action: "between"};
				this.metadatas.setFilters(filters);
				if(this.limit) this.metadatas.setLimit(this.pagination.offset, this.pagination.limit);
				this.DashboardMixins_getPreventifProchainesInterventionsExternes(this.metadatas).then((datas)=>{

				});
			},
		},
        computed: {
			...mapGetters({
	            prochainesInterventions: 'DashboardStore/getPreventifProchainesInterventions',
	        })
		}
    };

</script>
<style lang="scss" scoped>
.intervention-row{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding: 5px 15px;
	font-size: 11px;
	border: 1px solid #d3d7dd;
	margin-bottom: 10px;
	overflow-x: auto;
	cursor: pointer;
	.intervention-main{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 2px;
	}
	.intervention-date{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
}
.intervention-row:hover{
	background-color: whitesmoke;
}
</style>
