<template>
    <form id="tva-form"
        ref="tva-form"
        class="vg-tva-form"
        onSubmit="return false;">
        <div v-for="(tva,index) in tvas" style="margin:15px 0;display:flex;justify-content:flex-start;align-items:center;">
            <vg-input v-if="showUpdate && selectedTva.id==tva.id" v-model="selectedTva.name" style="margin-right:10px;"/>
            <span v-else style="border: 1px solid grey;padding: 5px;margin-right:10px;">{{tva.name}}</span>
            <vg-input v-if="showUpdate && selectedTva.id==tva.id" v-model="selectedTva.value" :inputType="'float'" style="margin-right:10px;"/>
            <span v-else style="border: 1px solid grey;padding: 5px;margin-right:10px;">{{tva.value}} %</span>
            <!-- btn update tva -->
            <vg-button v-if="showUpdate && selectedTva.id==tva.id"
                :type="'success'"
                :size="'sm'"
                @click="onUpdateTva(tva, index)">
                <i class="fas fa-save"></i>
            </vg-button>
            <!-- btn show update tva -->
            <vg-button v-else
                :type="'info'"
                :size="'sm'"
                :disabled="$app.role!='ROLE_ADMIN'"
                @click="showUpdateTva(tva, index)">
                <i class="fas fa-pen"></i>
            </vg-button>
            <!-- btn close update tva -->
            <vg-button v-if="showUpdate && selectedTva.id==tva.id"
                :type="'grey'"
                :size="'sm'"
                @click="showUpdate=false;selectedTva=null;">
                <i class="fas fa-times"></i>
            </vg-button>
            <!-- btn delete tva -->
            <vg-button v-else
                :type="'danger'"
                :size="'sm'"
                :disabled="$app.role!='ROLE_ADMIN'"
                @click="showDeleteTva(tva, index)">
                <i class="fas fa-trash-alt"></i>
            </vg-button>
        </div>
        <div v-if="showCreate" style="display:flex;justify-content:flex-start;align-items:center;">
            <vg-input v-model="newTVA.name" style="margin-right:10px;" :placeholder="$t('libel-tva')"/>
            <vg-input v-model="newTVA.value" :inputType="'float'" style="margin-right:10px;" :placeholder="$t('valeur-tva')"/>
            <vg-button
                :type="'success'"
                :size="'sm'"
                :disabled="!newTVA.name"
                @click="onSaveTva(tva, index)">
                <i class="fas fa-save"></i>
            </vg-button>
        </div>
        <vg-button v-else :type="'info'"
            :size="'sm'"
            :disabled="$app.role!='ROLE_ADMIN'"
            @click="showCreate=true;">
            <i class="fas fa-plus"></i>
        </vg-button>
        <input
          v-show="false"
          ref="tva-submit"
          type="submit"
        >
        <dialog-secured-action
          v-if="showDelete && selectedTva"
          :valid-response="selectedTva.name"
          @save="onDeleteTva"
          @close="showDelete=false;selectedTva=null;">
          <template v-slot:header-title>
            {{ $t("confirm-suppression") }} <b>{{ selectedTva.name }} <small>{{selectedTva.value}}%</small></b> ?
          </template>
        </dialog-secured-action>
    </form>
</template>
<script>

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import TvasMixins from "src/mixins/TvasMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'vg-tva-form',
    components:{
        VgButton,
        VgInput,
        DialogSecuredAction
    },
    mixins: [TvasMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "confirm-suppression": "Voulez-vous supprimer la tva ",
            "libel-tva": "Libellé TVA",
            "valeur-tva": "Valeur TVA (en %)"
        }
    }
},
    props: {

    },
    data: function() {
        return {
            metadatas: new Metadatas(),
            agfilters: {

            },
            selectedTva: null,
            showCreate: false,
            showUpdate: false,
            showDelete: false,
            newTVA: {
                name: null,
                value: 0,
                userId: this.$app.appID
            }
        };
    },
    watch:{

    },
    computed:{
        ...mapGetters({
            tvas: 'TvasStore/getCollection'
        })
    },
    created: function() {
        this.fetch();
    },
    methods: {
        onSaveTva: function(){
            this.showCreate = false;
            this.TvasMixins_create([this.newTVA]).then((datas)=>{
                this.newTVA = {
                    name: null,
                    value: 0,
                    userId: this.$app.appID
                };
            });
        },
        onDeleteTva: function(){
            this.showDelete = false;
            this.TvasMixins_delete(this.selectedTva).then((data)=>{
                this.selectedTva = null;
            });
        },
        onUpdateTva: function(){
            this.showUpdate = false;
            this.TvasMixins_update(this.selectedTva).then((data)=>{
                this.selectedTva = null;
            });
        },
        showUpdateTva: function(tva, index){
            this.selectedTva = Object.assign({}, {}, tva);
            this.showUpdate = true;
        },
        showDeleteTva: function(tva, index){
            this.selectedTva = Object.assign({}, {}, tva);
            this.showDelete = true;
        },
        fetch: function(){
            this.metadatas.setFilters(this.agfilters);
            this.TvasMixins_getTvas(this.metadatas).then((datas)=>{

            });
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
