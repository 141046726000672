<template lang="html">
    <div class="checkboxreadcellrender">

        <vg-checkbox :inputValue="params" v-if="!paramsUser" class="checkboxread-center" :isDisabled="true"></vg-checkbox>

        <vg-checkbox :defaultChecked="paramsUserValue" class="checkboxread-center" :isDisabled="true" v-else></vg-checkbox>
    </div>
</template>

<script>
import Vue from "vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    mixins:[LangMixins],
    props: {
        params: {
            type: Boolean,
        },
        paramsUser:{
            type: String,

        },
    },
    components:{
        VgCheckbox
    },
    computed:{
        paramsUserValue:function () {
            if (this.paramsUser == "1") {
                return true;
            }
            else{
                return false;
            }

        }
    }
})
</script>

<style lang="css">
    .checkboxreadcellrender{
        text-align: center;
    }
    .checkboxread-center{
        left: 50%;
    }
</style>
