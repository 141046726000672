<template lang="html">
    <div v-if="affectes" style="display:flex;justify-content:flex-start;align-items:center;gap:10px;">
        <div v-for="(affecte,index) in affectes" :key="index">
            <span >{{affecte.nom}} {{affecte.prenom}}</span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"intervention-affectes-cell-render",
    mixins:[LangMixins],
    props:{
        affectes:{
            type: Array,
            required: true
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
