<template>
    <vg-input :title="$t('filtre-statut-bc')">
        <vg-select
            v-model="selectedStatut"
            :options="statuts"
            :placeholder="$t('select-statut')"
            @input="handleInputChange">
        </vg-select>
    </vg-input>

</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-filter-bons-de-commande-statut',
    mixins:[],
	components: {
		VgSelect,
        VgInput
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        multiple:{
            type:Boolean,
            default:false
        },
        fetchIfCategorieStoreIsEmpty: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-statut": "Sélectionner un statut",
            "filtre-statut-bc": "Statut BC",
            "delivered": "Livré",
            "sended": "Envoyée",
            "skip-sending": "Non envoyée par email",
            "demande-validation": "Demande validation",
            "draft": "Brouillon",
            "partially-delivered": "Livré partiellement",
            "validation-complete": "Validation complète",
            "rejected": "Rejeté",
            "validation-partielle": "Validation Partielle"
        },
        "en": {
            "select-statut": "Select a status",
            "filtre-statut-bc": "Purchase order status",
            "delivered": "Delivered",
            "sended": "Sended",
            "skip-sending": "Skip sending email",
            "demande-validation": "Awaiting validation",
            "draft": "Draft",
            "partially-delivered": "Partially delivered",
            "validation-complete": "Complete validation",
            "rejected": "Rejected",
            "validation-partielle": "Partially validated"
        }
    }
},
    data: function() {
        return {
            statuts:[
                {value:"draft", label:this.$t("draft")},
                {value:"demande-validation", label:this.$t("demande-validation")},
                {value:"validation-partielle", label:this.$t("validation-partielle")},
                {value:"validation-complete", label:this.$t("validation-complete")},
                {value:"sended", label:this.$t("sended")},
                {value:"skip-sending", label:this.$t("skip-sending")},
                {value:"partially-delivered", label:this.$t("partially-delivered")},
                {value:"delivered", label:this.$t("delivered")},
                {value:"rejected", label:this.$t("rejected")}
            ],
            selectedStatut: this.value,
            agfilters: {}
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selectedStatut = newValue;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        fetch: function(){

        },
        /**
        * Emit input statut label.
        * @event input
        * @param String statut
        */
        handleInputChange:function(statut){
            this.$emit("input", statut);
            this.$emit("change", statut);
        }
    },
    computed:{

    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-statut-selector:
    permet de selectionner un statut, retourne le statut selectionné
    la propriété filters permet d'indiquer à la query quelles critères de filtrage
    appliqué sur la query getLieux
    #### basic usage

    ```html static
        <vg-statut-selector
            v-model="agfilters.statut.value" />
    ```
</docs>
