<template >
    <vg-app-layout
       :title="$t('title')+$t($route.params.type)"
       @save-filters="saveCurrentFilters"
       :colorheader="getHeaderColor"
       :filtersTitle="$t('modal-filters-title')"
       @global-filters-change="onGlobalFiltersChange"
       :isActionButtonDisplay="false"
       :isLoadingState="isOverlayActive"
       :isZoomLayout="!displayMouvementEquipementsBS"
       :reducedBody="!displayMouvementEquipementsBS">

       <template #action-button-item >
           <li @click="openRapportPrinter">{{$t("generer-rapport")}}</li>
       </template>

       <template #primary>
            <div style="display:flex;" v-if="bonDeSortiePDF">

            </div>
            
            <vg-mouvements-equipements-form 
                :equipements="equipements"
                v-show="displayMouvementEquipementsForm"
                :typeMouvement="typeMouvement"
                @close="displayMouvementEquipementsForm=false"
                @save="storeMouvement" />

            <vg-bon-de-sortie-form v-if="hasBonDeSortieConsommables && showBonDeSortieConsommables" 
                :showOpenBonDeSortieEquipementBouton="false"
                :defaultSignataire="mouvement.signataireReceveur"
                :defaultSignataireDonneur="mouvement.signataireDonneur"
                @close="hasBonDeSortieConsommables=false;showBonDeSortieConsommables=false;"
                @save="onSaveBonDeSortieConsommable" />

            <div id="eqp-viewer">
                <mouvement-equipements-viewer 
                    v-if="!displayMouvementEquipementsForm && $vgutils.isMobile() && displayMouvementEquipementsBS"
                    v-model="mouvement" />
            </div>

            <div id="footer">
                <div v-if="!allSignataireHasSigned && displayMouvementEquipementsBS" style="margin-bottom: 10px;">
                    <i class="fa-solid fa-circle-info"></i> {{ $t("manque-des-signatures",{typeMouvement:typeMouvement}) }}
                </div>
                <div :class='{ "mobile-actions-buttons": $vgutils.isMobile() , "desktop-actions-buttons": !$vgutils.isMobile()}'  v-if="bonDeSortiePDF">
                    <vg-button class="btn-action" v-if="!signatureImageDonneur" @click="displayMouvementEquipementsBS=false;showSignaturePadDonneur=true" 
                        type="info" >
                        {{ $t("faire-signer") }}: {{ mouvement.signataireDonneur }}
                    </vg-button>
                    <vg-button class="btn-action" v-else @click="displayMouvementEquipementsBS=false;showSignaturePadDonneur=true" >
                        <i class="fas fa-check"></i> {{ mouvement.signataireDonneur }} {{ $t("a-signer") }}
                    </vg-button>

                    <vg-button class="btn-action" v-if="!signatureImageReceveur" @click="displayMouvementEquipementsBS=false;showSignaturePadReceveur=true" type="info">
                        {{ $t("faire-signer") }}: {{ mouvement.signataireReceveur }}
                    </vg-button>
                    <vg-button class="btn-action" v-else @click="displayMouvementEquipementsBS=false;showSignaturePadReceveur=true" >
                        {{ mouvement.signataireReceveur }} {{ $t("a-signer") }}
                    </vg-button>

                    <vg-button v-if="$vgutils.isMobile() && !displayMouvementEquipementsForm && !showSignaturePadReceveur && !showSignaturePadDonneur" 
                        class="btn-action" 
                        @click="saveBonDeSortie" 
                        type="success" 
                        :disabled="!allSignataireHasSigned">
                        {{ $t("valider") }} {{ $t(typeMouvement) }}
                    </vg-button>
                    <vg-button v-if="!$vgutils.isMobile() && !displayMouvementEquipementsForm" 
                        class="btn-action" @click="saveBonDeSortie" 
                        type="success" 
                        :disabled="!allSignataireHasSigned">
                        {{ $t("valider-le-bon") }}
                    </vg-button>
                </div>
            </div>

            <mouvement-equipements-bon-de-sortie-pdf v-show="!$vgutils.isMobile()" v-if="displayMouvementEquipementsBS && isSortieTemporaireEquipement" 
                v-model="mouvement"
                :signaturesImages="signatures"
                @generated-pdf-datas="onGeneratedPDF" />

            <mouvement-equipements-bon-de-retour v-show="!$vgutils.isMobile()" v-if="displayMouvementEquipementsBS && isRetourEquipement"
                v-model="mouvement"
                :signaturesImages="signatures"
                @generated-pdf-datas="onGeneratedPDF" />

            <div v-if="showSignaturePadReceveur">
                <h3>{{ $t("signature-receveur", {receveur: mouvement.signataireReceveur}) }}</h3>
                <vg-signature-pad 
                    @close="showSignaturePadReceveur=false;"
                    @save="onSaveSignaturePadReceveur" 
                    key="signature-pad-receveur" />
            </div>

            <div v-if="showSignaturePadDonneur">
                <h3>{{ $t("signature-donneur", {donneur: mouvement.signataireDonneur}) }}</h3>
                <vg-signature-pad 
                    @close="showSignaturePadDonneur=false;"
                    @save="onSaveSignaturePadDonneur"
                    key="signature-pad-donneur" />
            </div>
        </template>
    </vg-app-layout>
</template>

<script>

import Metadatas from "src/services/Metadatas.js";
import MouvementEquipementsBonDeSortiePdf from "src/components/Vg/MouvementsEquipements/MouvementEquipementsBonDeSortiePdf.vue";
import MouvementEquipementsBonDeRetour from "src/components/Vg/MouvementsEquipements/MouvementEquipementsBonDeRetour.vue";
import VgMouvementsEquipementsForm from 'src/components/Vg/Forms/VgMouvementsEquipementsForm.vue';
import MouvementsEquipementsTable from 'src/components/Vg/MouvementsEquipements/MouvementsEquipementsTable.vue';
import MouvementEquipementsViewer from 'src/components/Vg/MouvementsEquipements/MouvementEquipementsViewer.vue';
import VgSignaturePad from "src/components/Vg/VgSignaturePad.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgBonDeSortieForm from "src/components/Vg/Forms/VgBonDeSortieForm.vue";
import equipementsMixins from 'src/mixins/equipementsMixins.js';
import { mapGetters } from 'vuex';

export default {
  name: 'mouvement-equipements-create',
  i18n:    { "locale":navigator.language,
  "messages": {
      "fr": {
          "title": "Bon de ",
          "pret": "Sortie temporaire équipements",
          "retour": "Retour équipements",
          "file-name":"bon_de_sortie_temporaire_equipements",
          "manque-des-signatures":"Tous les signataires doivent signer le document pour valider le {typeMouvement}",
          "valider":"Valider",
          "signature-donneur": "Signature donneur: {donneur}",
          "signature-receveur": "Signature receveur: {receveur}",
          "signature": "Signature",
          "generer-rapport": "Générer rapport",
          "valider-le-bon": "Valider le bon",
          "faire-signer": "Faire signer",
          "a-signer": "a signé"
      },
      "en": {
          "title": "Exit voucher",
          "faire-signer": "make signature",
          "a-signer": "has signed"
      }
  }
},
  mixins: [equipementsMixins],
  components: {
      MouvementEquipementsBonDeSortiePdf,
      VgMouvementsEquipementsForm,
      VgSignaturePad,
      VgButton,
      VgBonDeSortieForm,
      MouvementEquipementsViewer,
      MouvementsEquipementsTable,
      MouvementEquipementsBonDeRetour
  },
  props:{},
  data: function() {
      return {
          isOverlayActive:null,
          displayMouvementEquipementsForm:true,
          displayMouvementEquipementsBS:false,
          showSignaturePadReceveur:false,
          showSignaturePadDonneur:false,
          bonDeSortiePDF:null,
          signatureImageDonneur:null,
          signatureImageReceveur:null,
          typeMouvement:this.$route.params.type,
          equipementsIds:[],
          equipements:[],
          mouvement:{
                  "id": 401,
                  "signataireReceveur": "Jean-Pierre Naillet",
                  "signataireDonneur": "TT",
                  "commentaire": "prêt",
                  "type": "pret",
                  "dateMouvement": "2023-04-20 14:00:00",
                  "dateRetourPrevu": "2023-04-25 14:00:00",
                  "created_at": "2023-04-20 14:00:00",
                  "updated_at": null,
                  "origine_id": 517431,
                  "destination_id": 517201,
                  "createur_id": 16521,
                  "equipement_id": 1129481,
                  "libel_equipement": "Téléviseur - A124",
                  "origin_id": 517431,
                  "origine_path": "BERD/Votre hotel/BAT A/R+1/CH A124 - P59",
                  "destination_path": "BERD/Votre hotel/BAT A/R+1/CH A101 - P10",
                  "equipements": [
                      {
                          "id": 1129481,
                          "libel_equipement": "Téléviseur - A124",
                          "categorie": "Electromenager",
                          "qrCode":"0:12563254"
                      },
                      {
                          "id": 1129211,
                          "libel_equipement": "Téléviseur - A001",
                          "categorie": "Electromenager",
                          "qrCode":"0:12563254dddz88"
                      }
                  ],
                  "createur": {
                      "id": 16521,
                      "username":"Tony Tipveau"
                  },
                  "destination": {
                      "id": 517201,
                      "path": "BERD/Votre hotel/BAT A/R+1/CH A101 - P10"
                  },
                  "origine": {
                      "id": 517431,
                      "path": "BERD/Votre hotel/BAT A/R+1/CH A124 - P59"
                  }
          },
          hasBonDeSortieConsommables: false,
          showBonDeSortieConsommables: false,
          isSignatureDonneur: false,
          isSignatureReceveur: false
      };
  },
  watch: {

  },
  computed: {
      signatures:function(){
          return [
              {
                  name:this.mouvement.signataireDonneur,
                  img:this.signatureImageDonneur
              },
              {
                  name:this.mouvement.signataireReceveur,
                  img:this.signatureImageReceveur
              },
          ]
      },
      getHeaderColor: function(){
              return this.$vgutils.isMobile() ? '#2091cf' : 'white';
          },
          allSignataireHasSigned: function(){
            return this.isSignatureReceveur && this.isSignatureDonneur
          },
          isSortieTemporaireEquipement:function(){
            return this.$route.params.type == "pret";
          },
          isRetourEquipement:function(){
            return this.$route.params.type == "retour";
          },
          typeMouvement:function(){
            return this.$route.params.type;
          },
  },
  methods: {
        onSaveBonDeSortieConsommable: function(datas){
            this.signatureImageReceveur = datas.signatureImage;
            this.isSignatureReceveur = true;
            this.showBonDeSortieConsommables = false;
        },
      storeMouvement:function(datas){
          this.mouvement = datas.mouvement;
          this.hasBonDeSortieConsommables = datas.openBonDeSortieConsommables;
          if(datas.openBonDeSortieConsommables) this.showBonDeSortieConsommables = true;
          this.displayMouvementEquipementsForm = false;
          this.displayMouvementEquipementsBS = true;
          //this.showSignaturePadReceveur = true;
      },
      saveBonDeSortie: function(){
        this.isOverlayActive = true;
        let filename = this.$t(this.typeMouvement);
        let tagDocumentId;
        let documentLabel;

        switch (this.typeMouvement) {
            case "retour":
                tagDocumentId = 4831;
                documentLabel = "bon-de-retour-equipement";
                break;
            case "pret":
                tagDocumentId = 4821;
                documentLabel = "bon-"+this.$t(this.typeMouvement)+"-equipement";
                break;
        
            default:
                break;
        }
          this.VgFilesMixins_uploadPDFBlob(this.bonDeSortiePDF, this.mouvement.uid, tagDocumentId, documentLabel, filename+moment().format("YYYY-MM-DD_HH:mm")).then((document)=>{
              this.isOverlayActive = false;
              window.open(URL.createObjectURL(this.bonDeSortiePDF), '_blank');
              this.showMouvements();
          });
      },
      onSaveSignaturePadReceveur: function(signatureImage){
          this.signatureImageReceveur = signatureImage;
          this.isSignatureReceveur = true;
          this.showSignaturePadReceveur = false;
          this.displayMouvementEquipementsBS = true;
      },
      onSaveSignaturePadDonneur: function(signatureImage){
          this.signatureImageDonneur = signatureImage;
          this.isSignatureDonneur = true;
          this.showSignaturePadDonneur = false;
          this.displayMouvementEquipementsBS = true;
      },
      onGeneratedPDF: function(pdf){
          this.bonDeSortiePDF = pdf;
          //this.saveBonDeSortie();
      },
      effacerSignature:function(){
          this.signatureImageDonneur=null;
          this.signatureImageReceveur=null;
          if(!this.$vgutils.isMobile()){
            // hack refresh le pdf viewer pour le desktop le mobile lui n'a pas le viewer pdf ( surement bug) 
            this.displayMouvementEquipementsBS=false;
          }
          
      },
      showMouvement: function(mouvement){
          this.$router.push({ name: '_mouvement_equipements_id', params: { id: mouvement.data.id } })
      },
      showMouvements: function(){
          this.$router.push({ name: '_mouvements_equipements'})
      },
        hasReceivedEquipementsFromOutside: function(){
            return this.$route.query.equipementsIds.length;
        },
        base64ToEquipementsIds: function(base64EquipementsIdsString){
            return JSON.parse(atob(base64EquipementsIdsString));
        },
        getEquipementsFromRouter: function(){
            let equipementsIds = this.base64ToEquipementsIds(this.$route.query.equipementsIds);
            this.fetchEquipements(equipementsIds);
        },
        fetchEquipements: function(equipementsIds){
            this.isOverlayActive = true;
            let metadatas = new Metadatas();
            metadatas.setFilters({
                equipements_ids: {attr: "e.id", value: equipementsIds, action: "equals"}
            });
            this.equipementsMixins_getEquipements(metadatas).then((reponse)=>{
                this.isOverlayActive = false;
                this.equipements = reponse.datas;
            });
        },
  },
  created:function(){
    if(this.hasReceivedEquipementsFromOutside()) this.getEquipementsFromRouter();
  },
  destroyed:function(){
  }
};
</script>
<style lang="scss" scoped>
// on personnalise le label comme on veut
.btn-action{
  margin: 0 5px;
}
.mobile-actions-buttons{
    display:flex;
    flex-direction:column;
    gap:5px;
}
.desktop-actions-buttons{
    display:flex;
    flex-direction:row;
    gap:5px;
    margin-bottom: 10px;
}
</style>
