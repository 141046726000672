<template>
    <div class="verificationCellRender">
        <div class="vg-first-row">
            <vg-datetime-viewer v-model="params.data.dateVerif"/>

            <div class="conformity" v-if="params.data.nbNonConformites && params.data.nbNonConformites>0" >
                <div class="text-conformity">
                    {{params.data.nbNonConformites}} {{$t("non-conforme")}}
                </div>
                <img src="static/assets/icone/verif-non-conforme.png" width="20" height="20">
            </div>
            <img class="conformity" v-else src="static/assets/icone/verif-conforme.png" width="20" height="20" style="float: right; margin-right: 10px;">

        </div>
        <div class="vg-row">
            <img src="static/assets/icone/tache-de-verification.png" width="20" height="20">
            <b>{{params.data.libel_tache}}</b>


        </div>
        <div class="vg-row">
            {{params.data.isGroupEqp=="1"?params.data.libelComposant: params.data.libel_equipement}}
            <div class="qrCode-data">
                {{params.data.qrCode}}
            </div>
        </div>
        <div class="vg-row">
            <vg-lieu-path-viewer v-model="params.data.path"
                :showTooltip="false"
            />
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

    export default Vue.extend({
        name:"verification-cell-render",
        mixins:[LangMixins],
        components:{
            VgLieuPathViewer,
            VgDatetimeViewer
        },
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "non-conforme": "non conforme"
        },
        "en": {
            "non-conforme": "Non-compliant"
        },
        "th": {
            "non-conforme": "ไม่มีคำร้องเรียน"
        }
    }
}
    });
</script>

<style lang="scss" scoped>
.verificationCellRender{
    font-size: 13px;
    // font-family: Arial;
    .conformity{
        float: right;
        margin-right:10px;color: red;
        display: flex;
        .text-conformity{
            float: left;
        }
        img{
            margin-left: 10px;
        }
    }
    .vg-row{
        display: flex;
        margin-bottom: 3px;
        img{
            margin-right: 5px;
        }
    }
    .qrCode-data{
        color: #9e9e9e;
        margin-left: 5px;
        font-size: 12px;
    }
    .vg-first-row{
        height: 20px;
    }
    .path{
        color:#9e9e9e;
        margin-right: 5px;
        font-size: 12px;
        align-self:center;
    }
    .pathEnd{
        color: black;
        font-size: 14px;
        align-self:center;

    }
}

</style>
