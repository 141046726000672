<template>
    <div v-if="getSelectedTache" 
        style="width:100%;height: -webkit-fill-available;">
        <br>
        <div style="display: flex;justify-content: flex-start;align-items: center;gap:10px;">
            <span style="font-size:16px;font-weight: bold;">{{ $t("checkpoints") }} - </span>
            <span style="color: #3999FF;font-size:16px;font-weight: bold; ">{{ getSelectedTache.libel_tache }}</span>
            <vg-button type="danger" icon="add" size="sm" @click="showUpdateTache=true;">
                {{ $t("modifier-tache") }}
            </vg-button>
            <vg-button size="sm" type="grey" @click="$emit('close')">{{ $t("close") }}</vg-button>
        </div>
        <br>
        <br>
        <vg-checkpoints-form v-model="getSelectedTache" 
            :key="'checkpoint-table-'+getSelectedTache.id"
            :isEditionMode="false"/>
        <vg-tache-form v-if="showUpdateTache"
            v-model="getSelectedTache"
            :key="'tache-form-update-'+getSelectedTache.id"
            :typeTache="getSelectedTache.type_tache"
            @close="showUpdateTache = false"
            @updated="onUpdateTache" />
    </div>
    <div v-else style="width: 100%;color:#3999FF;font-size: 14px;display: flex;justify-content: center;align-items: center;">
        <span>{{ $t("chargement-tache") }}</span>
    </div>
</template>
<script>
import TachesMixins from "src/mixins/TachesMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgCheckpointsForm from "src/components/Vg/Forms/VgCheckpointsForm.vue";
import VgTacheForm from "src/components/Vg/Forms/VgTacheForm.vue";

import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-tache-viewer',
    components:{
        VgButton,
        VgCheckpointsForm,
        VgTacheForm
    },
    mixins:[ TachesMixins ],
    props: {
        value:{
            type: Object,
            required: true
        }
    },
    i18n:    { "locale":navigator.language,
        "messages": {
            "fr": {
                "chargement-tache": "Chargement de la tâche récurrente en cours...",
                "checkpoints": "Points de vérification",
                "close": "Fermer",
                "modifier-tache": "Modifier la tâche"
            },
            "en": {
                "chargement-tache": "Loading recurring task...",
                "checkpoints": "Checkpoints",
                "close": "Close"
            }
        }
    },
    data: function() {
        return {
            showUpdateTache: false
        };
    },
    watch: {
        value:{
            handler: function(newVal){
                this.fetchTache();
            },
            deep: true
        }
    },
    created:function(){
        this.fetchTache();
    },
    computed: {
        ...mapGetters({
            checkpoints: 'CheckpointsStore/getCollection',
            getSelectedTache: 'TachesStore/getSelectedItem',
        }),
    },
    methods:{
        onUpdateTache: function(){
            this.showUpdateTache = false;
            location.reload();
            //this.fetchTache();
        },
        fetchTache: function(){
            this.$emit("loading", true);
            this.tachesMixins_getTache(this.value.id).then(()=>{
                this.VgFilesMixins_init(this.value.uid, "tache");
                this.$emit("loading", false);
            });
        },
    }
};
</script>
<style lang="scss" scoped>

</style>