<template>
    <vg-app-layout
        :title="$vgutils.isMobile()?$t('page-title-mobile'):$t('page-title')"
        :icon="getHeaderTitle"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :filtersTitle="$t('modal-filters-title')"
        :isLoadingState="isLoadingState"
        @action-export="actionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters">

      <template #action-button-item>
        <li @click="exportSites">
          {{ $t("export-site") }}
        </li>
        <li @click="exportBatiments">
          {{ $t("export-batiments") }}
        </li>
        <li @click="exportEtages">
          {{ $t("export-etages") }}
        </li>
        <li @click="exportPieces">
          {{ $t("export-piece") }}
        </li>
        <li @click="handleChangeViewRecensement">
          {{ $t("menu-more-vue-tableau-recensement") }}
        </li>
      </template>

      <template #create-button >
        <vg-button
          type="success"
          @click="isShowCreateLieuForm = true"
        >
          {{ $t("menu-more-create-lieu") }}
        </vg-button>
      </template>

      <!--template #create-button v-else-if="focusedTab=='sites'">
        <vg-button
          type="success"
          @click="isShowCreatePiecesGeneriqueForm = true"
        >
          {{ $t("menu-more-generate-pieces-generiques") }}
        </vg-button>
        <vg-button
          type="success"
          @click="isShowCreateEquipementsGeneriqueForm = true"
        >
          {{ $t("menu-more-generate-equipements-globaux") }}
        </vg-button>
      </template-->

      <template #search>
        <!--v-model="agfilters.path.value"-->
        <vg-text-filter @input="onInputSmartFilter" />
      </template>

      <template #header-bottom-left v-if="selectedLieux.length">
          <span v-if="selectedLieux && selectedLieux[0].type_lieu=='Piece'">
              {{$t("pieces-selected", {nPieces: selectedLieux.length})}}
          </span>
          <span v-else-if="selectedLieux && selectedLieux[0].type_lieu=='Site'">
              {{$t("sites-selected", {nSites: selectedLieux.length})}}
          </span>
          <vg-button :type="'default'" style="margin-left: 20px;" @click="showUpdateLieuxMultiple=true;">
              {{$t("update")}}
          </vg-button>
      </template>

      <template #primary>
          <vg-tabs
            :tabs="tabs"
            :color="'gris'"
            @tabClicked="onTabClicked"
          />
          <vg-lieux-table
            v-if="focusedTab=='pieces'"
            :filters="piecesFilters"
            paginate
            style="height:100% !important;"
            @row-click="onRowPieceClick"
            @row-dbl-click="targetPiece"
          />
        <vg-sites-table
          v-else-if="focusedTab=='sites' || focusedTab=='cartographie'"
          style="height: 100% !important"
          :displayMap="isMapSiteDisplayed"
          :filters="agfilters"
        />
        <vg-batiments-table
          v-else-if="focusedTab=='batiments'"
          style="height: 100% !important"
        />
        <vg-etages-table
          v-else-if="focusedTab=='etages'"
          style="height: 100% !important"
          :filters="agfilters"
          @show-loading-overlay="onShowLoadingOverlay"
        />
      </template>

        <template #secondary-filters>
            <vg-input
              v-if="focusedTab=='sites'"
              :title="$t('famille-lieu')"
            >
              <vg-lieu-famille-selector v-model="agfilters.field_famille.value"
                  onlyExisting>
              </vg-lieu-famille-selector>
              </vg-input>
            <vg-input>
              <vg-filter-site v-model="agfilters.field_path.value" />
            </vg-input>
            <vg-input :title="$t('categorie-lieu')" v-if="focusedTab=='pieces'">
              <vg-categorie-selector
                v-model="agfilters.field_categorie.value"
                :isGe="true"
                :attributeValue="'libelleCatgorie'"
              />
            </vg-input>
            <vg-filter-service v-if="focusedTab=='pieces'"
                v-model="agfilters.field_service.value" />
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                @select-node="handleSelectNode"
                @select-piece="handleSelectNode"
                @remove-selected-node="onRemoveSelectedNode"
              />
        </template>

      <template #body v-if="$vgutils.isMobile()">
        <vg-lieux-table
          v-show="focusedTab=='pieces'"
          style="height: 75vh !important"
          :filters="piecesFilters"
          paginate
          @row-click="targetPiece"
        />
      </template>

      <template #panel-widget-lieux>
        <vg-tree-lieux
          @select-node="handleSelectNode"
          @select-piece="handleSelectPiece"
        />
      </template>

      <template #panel-filters-localisation>
        <vg-filter-site v-model="agfilters.field_path.value" />
      </template>

      <vg-pieces-generique-form v-if="isShowCreatePiecesGeneriqueForm"
        @close="isShowCreatePiecesGeneriqueForm = false"
        @save="isShowCreatePiecesGeneriqueForm = false"
      />

      <vg-equipements-generique-form v-if="isShowCreateEquipementsGeneriqueForm"
        @close="isShowCreateEquipementsGeneriqueForm = false"
        @save="isShowCreateEquipementsGeneriqueForm = false"
      />

      <vg-lieu-info-create v-if="isShowCreateLieuForm"
        @close="isShowCreateLieuForm = false"
      />

        <vg-update-lieux-form v-if="showUpdateLieuxMultiple"
            :typeLieu="selectedLieux[0].type_lieu"
            @close="showUpdateLieuxMultiple=false;"
            @save="showUpdateLieuxMultiple=false;" />

    </vg-app-layout>
</template>

<script>
import TagGrid from "src/components/Grid/TagGrid.vue";

import VgTabs from "src/components/Vg/VgTabs.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgGroup from "src/components/Vg/VgGroup.vue";

import VgLieuInfoCreate from "src/components/Vg/Lieu/VgLieuInfoCreate.vue";
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgSitesTable from "src/components/Vg/Lieu/VgSitesTable.vue";
import VgBatimentsTable from "src/components/Vg/Lieu/VgBatimentsTable.vue";
import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";
import VgEtagesTable from "src/components/Vg/Lieu/VgEtagesTable.vue";

import VgPiecesGeneriqueForm from "src/components/Vg/Forms/VgPiecesGeneriqueForm.vue";
import VgEquipementsGeneriqueForm from "src/components/Vg/Forms/VgEquipementsGeneriqueForm.vue";
import VgUpdateLieuxForm from "src/components/Vg/Forms/VgUpdateLieuxForm.vue";

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";

import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterService from "src/components/Vg/Filters/VgFilterService.vue";
import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

import Metadatas from "src/services/Metadatas.js";
import LieuMixins from "src/mixins/LieuMixins.js";
import FiltersMixins from 'src/mixins/FiltersMixins';

import { mapGetters } from "vuex";
export default {
    name: "Lieux",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Liste des lieux",
            "page-title-mobile": "Liste des pièces",
            "menu-more-vue-tableau": "Vue tableau",
            "menu-more-vue-cards": "Vue cards",
            "menu-more-vue-tableau-recensement": "Vue tableau recensement",
            "menu-more-create-lieu": "Créer lieu",
            "menu-more-generate-pieces-generiques": "Générer pièces génériques",
            "menu-more-generate-equipements-globaux": "Générer équipements globaux",
            "action-button": "Action",
            "site": "Site",
            "batiment": "Batiment",
            "etage": "Etage",
            "piece": "piece",
            "tab-pieces": "Pieces",
            "tab-sites": "Sites",
            "tab-cartographie": "Cartographie des Sites",
            "export-piece": "Export excel pièces",
            "export-site": "Export excel sites",
            "export-batiments": "Export excel batiments",
            "export-etages": "Export excel étages",
            "categorie-lieu": "Filtrage par catégorie",
            "liste-des-lieux": "Liste-des-lieux",
            "liste-des-sites": "Liste-des-sites",
            "liste-des-batiments": "Liste-des-batiments",
            "liste-des-etages": "Liste-des-etages",
            "liste-des-pieces": "Liste-des-pieces",
            "famille-lieu": "Famille",
            "tab-etages": "Etages",
            "zoom-statut-select-option--recensement-en-cours": "Recensement en cours",
            "Filtres_filtres_title": "Filtres",
            "Filtres_localisation": "Localisation",
            "modal-filters-title": "Fermer",
            "update": "Modifier",
            "pieces-selected": "{nPieces} pièce(s) sélectionnée(s)",
            "sites-selected": "{nSites} site(s) sélectionné(s)"
        },
        "en": {
            "page-title": "Location list",
            "page-title-mobile": "Room list",
            "site": "Site",
            "batiment": "Block",
            "etage": "Level",
            "piece": "Room",
            "famille-lieu": "Family",
            "tab-etages": "Level",
            "zoom-statut-select-option--recensement-en-cours": "Census in progress",
            "Filtres_filtres_title": "Filters",
            "Filtres_localisation": "Location",
            "menu-more-vue-tableau": "Table view",
            "menu-more-vue-tableau-recensement": "View census table",
            "menu-more-create-lieu": "Create room",
            "action-button": "Action",
            "export-piece": "Export excel rooms",
            "export-site": "Export excel locations",
            "export-batiments": "Export excel buildings",
            "export-etages": "Export excel floors",
            "liste-des-lieux": "Locations-list",
            "liste-des-sites": "Sites-list",
            "liste-des-batiments": "Building-list",
            "liste-des-etages": "Floor-level-list",
            "liste-des-pieces": "Room-list",
            "menu-more-vue-cards": "Cards view",
            "menu-more-generate-pieces-generiques": "Generate generic parts",
            "menu-more-generate-equipements-globaux": "Generate global equipment",
            "tab-pieces": "Rooms",
            "tab-sites": "Sites",
            "tab-cartographie": "Site mapping",
            "categorie-lieu": "Filter by category",
            "modal-filters-title": "Close",
            "update": "Update",
            "pieces-selected": "{nPieces} rooms selected",
            "sites-selected": "{nSites} sites selected"
        },
        "th": {
            "page-title": "สถานที่",
            "zoom-statut-select-option--recensement-en-cours": "การสำรวจกำลังดำเนินการ",
            "Filtres_filtres_title": "ตัวกรอง",
            "Filtres_localisation": "ตำแหน่งที่ตั้ง",
            "menu-more-vue-tableau": "มุมมองตาราง",
            "menu-more-vue-tableau-recensement": "เข้าดูตารางการสำรวจสำมะโนประชากร",
            "menu-more-create-lieu": "สร้างสถานที่",
            "site": "หน้างาน",
            "batiment": "บล็อก",
            "etage": "ระดับ",
            "piece": "ห้อง",
            "action-button": "ปฏิบัติการ",
            "export-piece": "ส่งออกห้อง excel",
            "export-site": "ส่งออกที่ตั้ง excel",
            "export-batiments": "ส่งออกสิ่งปลูกสร้าง excel",
            "export-etages": "ส่งออกพื้น excel"
        }
    }
},
    components: {
        TagGrid,
        VgTabs,
        VgTextFilter,
        VgButton,
        VgSelect,
        VgGroup,
        VgInput,
        VgCollapse,
        VgLieuInfoCreate,
        VgTreeLieux,
        VgSitesTable,
        VgFilterSite,
        VgPiecesGeneriqueForm,
        VgEquipementsGeneriqueForm,
        VgCategorieSelector,
        VgLieuxTable,
        VgEtagesTable,
        VgLieuFamilleSelector,
        VgUpdateLieuxForm,
        VgBatimentsTable,
        VgFilterService
    },
    mixins: [LieuMixins, FiltersMixins],
    props:{
        defaultFilters:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data: function () {
        return {
            agfilters: {
                libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
                codeUn: {attr: "l.codeUn", value: null, action: "contains", logicalOperator: "OR"},
                famille: {attr: "l.famille",colId: "l.famille",value: null,action: "contains",logicalOperator: "OR"},
                service: {attr: "service",colId: "service",value: null,action: "contains",logicalOperator: "OR",closeParenthesis: true},
                field_path: {attr: "l.path",colId: "l.path",value: null,action: "start_with"},
                field_categorie: {attr: "c.libelleCatgorie",colId: "c.libelleCatgorie",value: null,action: "equals"},
                field_service: {attr: "service",colId: "service",value: null,action: "equals"},
                field_famille: {attr: "famille",colId: "famille",value: null,action: "equals"}
            },
            gridOptions: {
                headerHeight: this.$vgutils.isMobile() ? 0 : 40,
                rowHeight: this.$vgutils.isMobile() ? 84 : 40
            },
            searchQuery: "",
            showViewRecensement: false,
            metadatasLieux: new Metadatas(),
            focusedTab: "pieces",
            isMapSiteDisplayed: false,
            isShowCreateLieuForm: false,
            isShowCreatePiecesGeneriqueForm: false,
            isShowCreateEquipementsGeneriqueForm: false,
            showUpdateLieuxMultiple: false,
            isLoadingState: false
        };
    },
    computed: {
        ...mapGetters({
            counters: 'LieuxStore/getCounters',
            selectedLieux: 'LieuxStore/getSelectedItems'
        }),
        piecesFilters: function(){
            return Object.assign({}, this.agfilters, {
                field_type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"}
            });
        },
        pageFilters: function(){
            return ["field_path", "field_categorie", "field_service", "field_famille"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getCurrentColumns: function(){
            if(this.getCurrentIdTableau=="lieux-tableau") return {
                "e.qrCode": "QRCode_lieux",
                "l.id": "id",
                "c.libelleCatgorie": "Catégorie_lieu",
                "c.id": "Id_catégorie_lieu",
                "l.service": "Service",
                "l.path": null
            };
            else if(this.getCurrentIdTableau) return {
                "l.id": "id",
                "l.path": null
            };
            else return {};
        },
        getCurrentIdTableau: function(){
            if(this.focusedTab=="pieces") return "lieux-tableau";
            else if(this.focusedTab=="sites") return "sites-tableau-recap";
            else if(this.focusedTab=="batiments") return "batiments-tableau-recap";
            else if(this.focusedTab=="etages") return "vg-etages-tableau";
            else return null;
        },
        tabs: function () {
            var tabs = [
                {
                    label: this.$t("tab-pieces"),
                    name: "pieces",
                    counter: this.counters && this.counters.pieces?this.counters.pieces:0,
                    filters: {
                        type_lieu: { attr: "type_lieu", value: "Piece", action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-sites"),
                    name: "sites",
                    counter: this.counters && this.counters.sites?this.counters.sites:0,
                    filters: {}
                },
                {
                    label: this.$t("batiments"),
                    name: "batiments",
                    counter: this.counters && this.counters.batiments?this.counters.batiments:0,
                    filters: {}
                },
                {
                    label: this.$t('tab-etages'),
                    name: "etages",
                    counter: this.counters && this.counters.etages?this.counters.etages:0,
                    filters: {
                        type_lieu: { attr: "type_lieu", value: "Etage", action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-cartographie"),
                    name: "cartographie",
                    counter: null,
                    filters: {}
                }
            ];
            return tabs;
        },
        getHeaderColor: function () {
            return this.$vgutils.isMobile() ? "rgb(108, 58, 158)" : "white";
        },
        getHeaderTitle: function () {
            return this.$vgutils.isMobile() ? "lieu-blanc.png" : "lieu.png";
        },
        hasActiveGlobalFilters: function(){
            let filters = this.agfilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        searchQuery: {
            handler: function (query) {
                if (query != null && query.length) {
                    this.agfilters.libel_lieu.value = query;
                    this.agfilters.codeUn.value = query;
                    this.agfilters.service.value = query;
                    this.agfilters.famille.value = query;
                }else{
                    this.agfilters.libel_lieu.value = null;
                    this.agfilters.codeUn.value = null;
                    this.agfilters.service.value = null;
                    this.agfilters.famille.value = null;
                }
            }
        },
        agfilters: {
            handler: function (nfilters) {
                if (this.focusedTab!="pieces") return;
                this.metadatasLieux.setFilters(nfilters);
                this.metadatasLieux.setLimit(0, 25);

            },
            deep: true
        }
    },
    created: function () {
        this.FiltersMixins_page_name = "lieux";
        this.agfilters = Object.assign({}, this.agfilters, this.defaultFilters);
        if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
    },
    mounted: function () {},
    methods: {
        onShowLoadingOverlay: function(isShow){
            this.isLoadingState = isShow;
        },
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.agfilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.agfilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.agfilters = filters;
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.agfilters = Object.assign({}, this.agfilters, filters);
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        /**
         * need factorization and move to lieuxMixins
         *
         */
        exportExcel: function (filters,filename) {
            let metadatasExportExcel = new Metadatas();
            metadatasExportExcel.setFilters(filters);
            this.LieuMixins_getExcelFile(metadatasExportExcel, filename);
        },
        // methodes dexport a factorisé
        exportPieces: function () {
            // i dont want agfilter to be watched
            let filters = Object.assign({}, this.agfilters, {
                field_path: {
                    attr: "path",
                    colId: "path",
                    value: null,
                    action: "contains"
                },
                field_service: {
                    attr: "service",
                    colId: "service",
                    value: null,
                    action: "contains"
                },
                field_type_lieu: {
                    attr: "l.type_lieu",
                    colId: "type_lieu",
                    value: "Piece",
                    action: "equals"
                }
            });
            this.exportExcel(filters,this.$t("liste-des-pieces"));
        },
        exportEtages: function () {
            // i dont want agfilter to be watched
            let filters = Object.assign({}, this.agfilters, {
                field_path: {
                    attr: "path",
                    colId: "path",
                    value: null,
                    action: "contains"
                },
                field_service: {
                    attr: "service",
                    colId: "service",
                    value: null,
                    action: "contains"
                },
                field_type_lieu: {
                    attr: "l.type_lieu",
                    colId: "type_lieu",
                    value: "Etage",
                    action: "equals"
                }
            });
            this.exportExcel(filters,this.$t("liste-des-etages"));
        },
        exportBatiments: function () {
            // i dont want agfilter to be watched
            let filters = Object.assign({}, this.agfilters, {
                field_path: {
                    attr: "path",
                    colId: "path",
                    value: null,
                    action: "contains"
                },
                field_service: {
                    attr: "service",
                    colId: "service",
                    value: null,
                    action: "contains"
                },
                field_type_lieu: {
                    attr: "l.type_lieu",
                    colId: "type_lieu",
                    value: "Batiment",
                    action: "equals"
                }
            });
            this.exportExcel(filters,this.$t("liste-des-batiments"));
        },
        exportSites: function () {
            let filters = Object.assign({}, this.agfilters, {
                field_path: {
                    attr: "path",
                    colId: "path",
                    value: null,
                    action: "contains"
                },
                field_service: {
                    attr: "service",
                    colId: "service",
                    value: null,
                    action: "contains"
                },
                field_type_lieu: {
                    attr: "l.type_lieu",
                    colId: "type_lieu",
                    value: "Site",
                    action: "equals"
                }
            });

            this.exportExcel(filters,this.$t("liste-des-sites"));
        },
        actionExport: function (event) {
            switch (event.name) {
                case "askForXLS":
                    this.LieuMixins_getExcelFile(this.metadatasLieux, this.$t("liste-des-lieux"));
                    break;
                case "askForCSV":
                    this.LieuMixins_getExcelFile(this.metadatasLieux, this.$t("liste-des-lieux"), "csv");
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(
                        new CustomEvent("gestionColonnesEventId", {
                            detail: { idTableau: this.getCurrentIdTableau }
                        })
                    );
                    break;
                default:
            }
            /*window.dispatchEvent(
                new CustomEvent(event.name, {
                    detail: {
                        idTableau: this.getCurrentIdTableau
                    }
                })
            );*/
        },
        onInputSmartFilter: function (input) {
            this.agfilters.field_path = {
                attr: "l.path",
                colId: "l.path",
                value: input,
                action: "contains"
            };
        },
        onRemoveSelectedNode: function(){
            this.agfilters.field_path = {
                attr: "l.path",
                colId: "l.path",
                value: null,
                action: "contains"
            };
        },
        /**
        * Will filter differently between Piece and other type_lieu.
        *
        * @param VgTree::Node
        */
        handleSelectNode: function (node) {
            this.agfilters.field_path = {
                attr: "l.path",
                colId: "l.path",
                value: node.path,
                action: "contains"
            };
        },

        /**
         *
         * @param  {Object} node node from vg-tree-lieu
         */
        handleSelectPiece: function (node) {
            var idPiece = node.id;
            this.$router.push({
                name: "_lieu_id",
                params: {
                    id: idPiece
                }
            });
        },
        setRecensementColumns: function () {
            this.gridOptions.columnApi.setColumnVisible(
                "statutRecensement",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "nbPhotoPanoramique",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "nbEquipement",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "totalNbMarker",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "googlePhotosAlbumLocation",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "preRecensementRequired",
                this.showViewRecensement
            );
            //this.gridOptions.columnApi.setColumnVisible('reRecensementRequired', this.showViewRecensement);
            this.gridOptions.columnApi.setColumnVisible(
                "affectationRecensement",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "dateRecensementPrevu",
                this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "commentaireRecensement",
                this.showViewRecensement
            );

            this.gridOptions.columnApi.setColumnVisible(
                "maintenance",
                !this.showViewRecensement
            );
            this.gridOptions.columnApi.setColumnVisible(
                "verification",
                !this.showViewRecensement
            );
        },
        handleChangeViewRecensement: function () {
            this.showViewRecensement = !this.showViewRecensement;
            this.setRecensementColumns();
        },
        onTabClicked: function (e) {
            this.$store.dispatch("LieuxStore/clearCollection");
            this.$store.dispatch("LieuxStore/deleteSelectedItems");
            this.$store.dispatch("LieuxStore/deleteSelectedItem");
            this.isMapSiteDisplayed = e.name == "cartographie";
            this.focusedTab = e.name;
            this.agfilters = Object.assign(this.agfilters, e.filters);
            this.FiltersMixins_saveDefaultTab(e.name);
        },
        onRowPieceClick: function(evt){
            if(this.$vgutils.isMobile()) this.targetPiece(evt);
        },
        targetPiece: function (evt) {
            var piece = evt.data;
            this.$router.push({
                name: "_lieu_id",
                params: {
                    id: piece.id
                }
            });
        }
    },
    destroyed:function(){
        this.$store.dispatch("LieuxStore/deleteSelectedItems");
    }
};
</script>

<style lang="scss" scoped>

</style>
