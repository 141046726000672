
import BaseStore from "src/store/modules/BaseStore.js";
let Base = new BaseStore();
let state = Base.getState();
let getters = Base.getGetters();
let actions = Base.getActions();
actions.addGroupeValidateursUsers = function( context, datas ){
    context.commit('ADD_GROUPE_VALIDATEURS_USERS', datas);
};
actions.deleteGroupeValidateurUser = function( context, data ){
    context.commit('DELETE_GROUPE_VALIDATEUR_USER', data);
};
let mutations = Base.getMutations();
mutations.ADD_GROUPE_VALIDATEURS_USERS = function(state, datas){
    let groupeValidateursUsers = datas.groupeValidateursUsers;
    let groupeValidateurs = datas.groupeValidateurs;
    let idGroupeValidateurs = state.collection.findIndex((groupe)=>{
        return groupeValidateurs.id==groupe.id;
    });
    if(idGroupeValidateurs!=-1){
        state.collection[idGroupeValidateurs].validateurs = state.collection[idGroupeValidateurs].validateurs.concat(groupeValidateursUsers);
    }
};
mutations.DELETE_GROUPE_VALIDATEUR_USER = function(state, data){
    let groupeValidateur_id = data.groupeValidateur_id;
    let groupeValidateurUser_id = data.groupeValidateurUser.id;
    let indexGroupeValidateur = state.collection.findIndex((groupeValidateur)=>{
        return groupeValidateur_id==groupeValidateur.id;
    });
    if(indexGroupeValidateur!=-1){
        let indexGroupeValidateurUser = state.collection[indexGroupeValidateur].validateurs.findIndex((validateur)=>{
            return validateur.id==groupeValidateurUser_id;
        });
        state.collection[indexGroupeValidateur].validateurs.splice(indexGroupeValidateurUser, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
