import LocalDB from "src/databases/LocalDB"
export default class LocalDBAlpha extends LocalDB{
    /**
     *@constructor
     */
     constructor(__options) {
		super(__options);
        
        this.setVersion(1);
        this.setTables(
            {
                lieux:"id++, categorie, path, service, expressId, userId"
            }
        )
        this.initLocalDB();

    }
}