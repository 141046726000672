<template>
    <vg-select
        v-model="selected"
        :options="tags"
        :attributeValue="attributeValue"
        :attributeLabel="attributeLabel"
        :multiple="multiple"
        @input="handleInputChange">
    </vg-select>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import TagsMixins from "src/mixins/TagsMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: 'vg-tags-selector',
    mixins:[TagsMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        attributeValue: {
            type:String,
            default:"id"
        },
        attributeLabel: {
            type:String,
            default:"label"
        },
        multiple:{
            type:Boolean,
            default:false
        },
        /**
        * type des tags à récupérer par default tous
        * valeurs: operation/tache operation/a-prevoir ...
        */
        type: {
            type: Array,
            default: null
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "composant-type-sanitaire": "Sanitaire",
            "composant-type-menuiserie": "Menuiserie",
            "composant-type-composant-du-bati": "Composant du bâti",
            "composant-type-plomberie": "Plomberie",
            "composant-type-accessoire": "Accessoire",
            "composant-type-appareil-electrique": "Appareil électrique",
            "composant-type-eclairage": "Eclairage",
            "composant-type-mobilier": "Mobilier",
            "composant-type-securite": "Sécurité",
            "composant-type-controle-acces": "Contrôle accès",
            "composant-type-exterieur": "Exterieur",
            "composant-type-image-son": "Image/son",
            "composant-type-telephonie": "Téléphonie",
            "composant-type-confort": "Confort",
            "composant-type-appareil-cuisine": "Appareil cuisine",
            "composant-type-appareil-froid": "Appareil froid",
            "composant-type-electricite": "Electricité",
            "consommable-statut-a-commander": "A commander",
            "consommable-statut-a-prevoir": "A prévoir",
            "consommable-statut-envoie-demande": "Envoie demande"
        },
        "en": {
            "composant-type-sanitaire": "Sanitary",
            "composant-type-menuiserie": "Carpentry",
            "composant-type-composant-du-bati": "Built component",
            "composant-type-plomberie": "Plumbing",
            "composant-type-accessoire": "Accessory",
            "composant-type-appareil-electrique": "Electrical appliance",
            "composant-type-eclairage": "Lighting",
            "composant-type-mobilier": "Furniture",
            "composant-type-securite": "Security",
            "composant-type-controle-acces": "Acces control",
            "composant-type-exterieur": "Outside",
            "composant-type-image-son": "Image/sound",
            "composant-type-telephonie": "Phone",
            "composant-type-confort": "Comfort",
            "composant-type-appareil-cuisine": "Kitchen appliance",
            "composant-type-appareil-froid": "Cold appliance",
            "composant-type-electricite": "Electricity",
            "consommable-statut-a-commander": "To order",
            "consommable-statut-a-prevoir": "To plan",
            "consommable-statut-envoie-demande": "Sending request"
        },
        "th": {
            "composant-type-sanitaire": "สุขาภิบาล",
            "composant-type-menuiserie": "งานไม้",
            "composant-type-composant-du-bati": "ชิ้นส่วนที่สร้างแล้ว",
            "composant-type-plomberie": "ระบบประปา",
            "composant-type-accessoire": "อุปกรณ์เสริม",
            "composant-type-appareil-electrique": "เครื่องใช้ไฟฟ้า",
            "composant-type-eclairage": "แสงสว่าง",
            "composant-type-mobilier": "เฟอร์นิเจอร์",
            "composant-type-securite": "ความปลอดภัย",
            "composant-type-controle-acces": "ควบคุมการเข้าถึง",
            "composant-type-exterieur": "ด้านนอก",
            "composant-type-image-son": "รูปภาพ/เสียง",
            "composant-type-telephonie": "โทรศัพท์",
            "composant-type-confort": "ความสะดวกสบาย",
            "composant-type-appareil-cuisine": "เครื่องใช้ในครัว",
            "composant-type-appareil-froid": "เครื่องทำความเย็น",
            "composant-type-electricite": "ไฟฟ้า"
        }
    }
},
    data: function() {
        return {
            selected:this.value,
            tags:[]
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selected = newValue;
            }
        },
        type: {
            handler: function(newType){
                this.fetch();
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        translateLabels: function(tags, prefix="composant-type-"){
            return tags.map((tag)=>{
                if(!tag.userId) return Object.assign({}, tag, {label: this.$t(prefix+tag.label)});
                else return tag;
            });
        },
        fetch: function(){
            let metadatas = new Metadatas();
            let filters = Object.assign({}, this.filters);
            if(this.type && this.type.length!=0) filters.type = {attr: "type", value: this.type, action: "equals"};
            metadatas.setFilters(filters);
            this.TagsMixins_get(metadatas).then((tags)=>{
                if(this.type=="composant/type") this.tags = this.translateLabels(tags.datas);
                else if(this.type=="consommable/statut") this.tags = this.translateLabels(tags.datas, "consommable-statut-");
                else this.tags = tags.datas;
            });
        },
        /**
        * Emit change with array of lieu_s id .
        * @event change
        * @param Array lieux_value
        */
        handleInputChange:function(lieux_value){
            if (!this.multiple) {
                this.$emit("input",lieux_value);
            }
            this.$emit("change",lieux_value);
        }
    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-lieux-selector:
    permet de selectionner un lieu, retourne un tableau d'identifiant lieu
    la propriété filters permet d'indiquer à la query quelles critères de filtrage
    appliqué sur la query getLieux
    #### basic usage

    ```html static
        <vg-lieux-selector

            v-model="user.lieux"
            :filters="{'type_lieu':{'attr':'type_lieu','value':'Site','action':'equals'}}"
            @change="handleChangeLieuxRestriction"
        >
        </vg-lieux-selector>
    ```
</docs>
