<template>
    <div class="equipement-fm-cell-render">
        <img v-if="params.data.composant && params.data.composant.icon"
			:src="params.data.composant.icon"
			onerror="this.style.visibility='hidden'">
		<span v-if="params.data.composant && params.data.composant.libelComposant">{{params.data.composant.libelComposant}}</span>
		<span v-else>{{params.data.equipement.libel_equipement}}</span>
    </div>
</template>

<script>
    import Vue from "vue";
	import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"equipement-fm-cell-render",
		mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        computed:{

        }
    });
</script>
<style lang="scss" scoped>
.equipement-fm-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
		margin-right:10px;
		vertical-align: inherit;
		width: 22px;
		height: 22px;
	}
}
</style>
