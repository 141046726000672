<template>
	<div style="height:300px;">
        <vg-text-filter v-model="searchString" ></vg-text-filter>
		<tag-grid
			:idTableau="'consommables-viewer'"

			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="value"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')" />
	</div>
</template>
<script>
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import TagGridMixins from 'src/mixins/TagGridMixins.js';

    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
    import LibelEquipementCellRender from "src/components/Vg/TagGrid/LibelEquipementCellRender.vue";
	import ConsommableCellRender from "src/components/Vg/TagGrid/ConsommableCellRender.vue"
    export default {
        name: 'vg-consommables-viewer',
		components: {
			TagGrid,
            VgTextFilter,
			ConsommableCellRender
		},
		mixins: [ TagGridMixins ],
        props: {
            value: Array
        },
        data: function() {
            return {
				showLoadingOverlay: false,
                searchString: '',
				gridOptions: {},
				columnDefs: [
					{
						cellRenderer: (params) => new ConsommableCellRender(
							{propsData: {params: params, document: params.data.document?JSON.parse(params.data.document):null}}
						)
							.$on("refresh", this.refresh)
							.$mount().$el,
						hide:!this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-intitule"),
						field: 'name',
						colId: 'name',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						pinned: 'left',
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-nature"),
						field: 'labelNature',
						colId: 'labelNature',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-coutUnitaire"),
						field: 'coutUnitaire',
						colId: 'coutUnitaire',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-refExterne"),
						field: 'refExterne',
						colId: 'refExterne',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-marque"),
						field: 'marque',
						colId: 'marque',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-numSerie"),
						field: 'numSerie',
						colId: 'numSerie',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile(),
					},
					{
						headerName: this.$t("column-stock"),
						field: 'quantite',
						colId: 'quantite',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
						headerName: this.$t("column-stockMin"),
						field: 'quantiteMin',
						colId: 'quantiteMin',
						cellClass: ["vg-cell-ballam-theme"],
						width: 132,
						hide:this.$vgutils.isMobile()
					},
					{
					  field: 'isACommander',
					  colId: 'isACommander',
					  cellClass: ["vg-cell-ballam-theme"],
					  width: 132,
					  hide:this.$vgutils.isMobile(),
					  isColumnShowable: true
					}
				]
            };
        },
	    i18n:    { "locale":navigator.language,
		"messages": {
			"fr": {
				"overlay-loading-text": "Chargement des consommables...",
				"overlay-no-rows-text": "Aucun consommable correspondant au filtrage",
				"column-intitule": "Intitulé",
				"column-nature": "Etiquette consommable/pièce",
				"column-coutUnitaire": "Coût unitaire par défaut",
				"column-fournisseur": "Fournisseur",
				"column-refExterne": "Réf. constructeur",
				"column-marque": "Marque",
				"column-nEqsAssocies": "Nb. équipements associés",
				"column-stock": "Quantité en stock",
				"column-dateDernierInventaire": "Date dernier inventaire",
				"column-aCommander": "A commander",
				"column-numSerie": "Numéro série",
				"column-stockMin": "Quantité en stock minimal",
				"tab-enStock": "En stock",
				"tab-stockExpired": "Stock expiré",
				"tab-stockACommander": "Stock à commander"
			},
			"en": {
				"overlay-loading-text": "Loading consumables...",
				"overlay-no-rows-text": "No consumable matching filtering",
				"column-intitule": "Title",
				"column-nature": "Consumable/part label",
				"column-coutUnitaire": "Default unit cost",
				"column-fournisseur": "Supplier",
				"column-refExterne": "Ref. Manufacturer",
				"column-marque": "Brand",
				"column-nEqsAssocies": "No. Associated equipment",
				"column-stock": "Quantity in stock",
				"column-dateDernierInventaire": "Last Inventory Date",
				"column-aCommander": "To order",
				"column-numSerie": "Serial number",
				"column-stockMin": "Minimum stock quantity",
				"tab-enStock": "In stock",
				"tab-stockExpired": "Expired stock",
				"tab-stockACommander": "Stock to order"
			},
			"th": {
				"column-intitule": "ชื่อเรื่อง",
				"column-nature": "ฉลาก/ชิ้นส่วนอุปกรณ์สิ้นเปลือง",
				"column-coutUnitaire": "ต้นทุนต่อหน่วยค่าเริ่มต้น",
				"column-fournisseur": "ซัพพลายเออร์",
				"column-refExterne": "อ้างอิงผู้ผลิต",
				"column-marque": "ยี่ห้อ",
				"column-nEqsAssocies": "หมายเลขอุปกรณ์ที่เกี่ยวข้อง",
				"column-stock": "จำนวนในสต็อก",
				"column-dateDernierInventaire": "วันที่สินค้าคงคลังล่าสุด",
				"column-aCommander": "เพื่อสั่งซื้อ",
				"column-numSerie": "หมายเลขซีเรียล",
				"column-stockMin": "จำนวนสต็อกขั้นต่ำ",
				"tab-enStock": "ในสต็อก",
				"tab-stockExpired": "สต็อกที่หมดอายุแล้ว",
				"tab-stockACommander": "สต็อกเพื่อสั่งซื้อ"
			}
		}
	},
        watch: {
			searchString: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			}
		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			refresh: function(){
				this.$emit("refresh-consommables");
			}
		}
    };

</script>
<style scoped>

</style>
