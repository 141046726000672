<template>
    <vg-select
        v-model="selectedDemandeur"
        :options="demandeurs"
        :attributeValue="'demandeur'"
        :attributeLabel="'demandeur'"
        :multiple="multiple"
        :placeholder="$t('select-demandeur')"
        @input="handleInputChange">
    </vg-select>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import Metadatas from "src/services/Metadatas";
import { mapGetters } from 'vuex';
export default {
    name: 'vg-demandeur-selector',
    mixins:[MaintenanceMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: String,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        multiple:{
            type:Boolean,
            default:false
        },
        fetchIfCategorieStoreIsEmpty: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "select-demandeur": "Sélectionner un demandeur"
        },
        "en": {
            "select-demandeur": "Select a requestor"
        }
    }
},
    data: function() {
        return {
            selectedDemandeur: this.value,
            agfilters: {}
        };
    },
    watch:{
        value: {
            handler: function(newValue){
                this.selectedDemandeur = newValue;
            }
        }
    },
	created: function(){
        this.fetch();
	},
    methods:{
        fetch: function(){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agfilters);
            this.MaintenanceMixins_getDemandeurs(metadatas).then((datas)=>{
                this.$emit("demandeurs-feeded", this.demandeurs);
            });
        },
        /**
        * Emit input demandeur.
        * @event input
        * @param String demandeur
        */
        handleInputChange:function(demandeur){
            this.$emit("input", demandeur);
            this.$emit("change", demandeur);
        }
    },
    computed:{
        ...mapGetters({
            demandeurs: 'MaintenancesStore/getDemandeurs'
        })
    }
};

</script>
<style lang="scss" scoped>

</style>
<docs>

    ### vg-service-selector:
    permet de selectionner un service, retourne le service selectionné
    la propriété filters permet d'indiquer à la query quelles critères de filtrage
    appliqué sur la query getLieux
    #### basic usage

    ```html static
        <vg-service-selector
            v-model="agfilters.service.value" />
    ```
</docs>
