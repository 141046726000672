<template>
	<vg-select v-model="selectedPeriodicite"
		:options="periodicites"
		:clearable="isClearable"
		@input="$emit('input',selectedPeriodicite)">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

    export default {
        name: 'vg-contrat-periodicite-selector',
		components: {
			VgSelect
		},
		mixins: [],
        props: {
			/**
			* @model
			*/
			value: {
				type: String,
				default: null
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			}
        },
        data: function() {
            return {
				selectedPeriodicite: this.value,
				periodicites: [
					this.$t("hebdomadaire"), this.$t("mensuelle"),
					this.$t("bimensuelle"), this.$t("trimestrielle"),
					this.$t("semestrielle"), this.$t("triannuelle"),
					this.$t("annuelle")
				]
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "hebdomadaire": "Hebdomadaire",
            "mensuelle": "Mensuelle",
            "bimensuelle": "Bimensuelle",
            "trimestrielle": "Trimestrielle",
            "semestrielle": "Semestrielle",
            "triannuelle": "Triannuelle",
            "annuelle": "Annuelle"
        },
        "en": {
            "hebdomadaire": "Weekly",
            "mensuelle": "Monthly",
            "bimensuelle": "Bi-monthly",
            "trimestrielle": "Quartely",
            "semestrielle": "Biannual",
            "triannuelle": "Three-yearly",
            "annuelle": "Yearly"
        },
        "th": {
            "hebdomadaire": "รายสัปดาห์",
            "mensuelle": "รายเดือน",
            "bimensuelle": "ทุกสองเดือน",
            "trimestrielle": "ทุกไตรมาส",
            "semestrielle": "ทุกสองปี",
            "triannuelle": "ทุกสามปี",
            "annuelle": "รายปี"
        }
    }
},
		watch: {
			value: {
				handler: function(type){
					this.selectedContratType = type;
				}
			}
		},
		created: function(){

		},
		mounted: function(){

		},
		methods: {

		},
        computed: {

		}
    };

</script>
<style lang="scss" scoped>

</style>
