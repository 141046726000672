<template>
	<vg-modal
        :width="width"
        @close="$emit('close')">
		<template #header>
            <!-- @slot Use this slot v-slot:title -->
            <slot name="title">
                {{$t("title")}}
            </slot>
        </template>
        <template v-slot:body>
            <div style="display: flex; justify-content: space-between;">
				<vg-tabs
					:tabs="tabs"
					:color="'dark'"
					@tabClicked="handleTabClick">
				</vg-tabs>
                <!-- search field -->
                <vg-text-filter
                    v-model="searchQuery">
                </vg-text-filter>
                <!-- menu more -->
                <action-menu-aggrid
                    :idTableau="'vg-fichesav-table'"
					placement="bottom-right"/>
            </div>
            <div style="height:300px;">
                <vg-fichesav-table
					:isReserves="true"
                    :searchQuery="searchQuery"
                    :filters="getReservesFiltersArray"
					:localFilters="localReservesFilters"
					@grid-feeded="onGridFeeded">
                </vg-fichesav-table>
            </div>
        </template>
        <template v-slot:footer>
            <vg-button
                @click="$emit('close')">
                {{$t("close")}}
            </vg-button>
        </template>
    </vg-modal>
</template>
<script>
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgTabs from "src/components/Vg/VgTabs.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
	import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
	import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-reserves-modal',
		components:{
			VgTextFilter,
			VgFichesavTable,
			ActionMenuAggrid,

			VgButton,
			VgTabs
		},
		mixins: [MaintenanceMixins],
    	props: {
			/**
			* titre de la modal
			*/
			title: {
				type: String,
				default: null
			},
			/**
			* modal width
			*/
			width: {
				type: String,
				default: "900px"
			},
			/**
			* contrat
			*/
			contrat: {
				type: Object,
				default: null
			},
			/**
			* intervention
			*/
			intervention: {
				type: Object,
				default: null
			},
			/**
			* filtres à passer au fetch reserves
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			}
        },
        data: function() {
            return {
				searchQuery: "",
				reservesFilters: {
					contrat_id: { attr:"i.contrat_id", colId: "contrat_id", value: this.contrat?this.contrat.id:null, action:"equals" },
					intervention_id: { attr:"i.id", colId: "intervention_id", value: this.intervention?this.intervention.id:null, action:"equals" }
				},
				localReservesFilters: null,
				metadatas: new Metadatas()
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Détails des réserves",
            "close": "Fermer",
            "onglet-non-levees": "Non levées",
            "onglet-levees": "Levées"
        },
        "en": {
            "title": "Reservations details",
            "close": "Close",
            "onglet-non-levees": "Not lifted",
            "onglet-levees": "Lifted"
        }
    }
},
		created: function(){
			this.reservesFilters = Object.assign(this.reservesFilters, this.filters);
		},
		mounted: function(){

		},
		methods: {
			onGridFeeded: function(event){
				this.localReservesFilters = Object.values(this.tabs[0].filters);
			},
			handleTabClick: function(event) {
				this.localReservesFilters = Object.values(event.filters);
	        },
		},
        computed: {
			...mapGetters({
				reserves: 'MaintenancesStore/getReserves'
            }),
			getReservesFiltersArray: function(){
				return Object.values(this.reservesFilters);
			},
			counters: function(){
	            var reserves = this.reserves;
				var counters = {
					nonlevees: reserves.filter((reserve)=>reserve.statut!="Resolue").length,
					levees: reserves.filter((reserve)=>reserve.statut=="Resolue").length
				};
				return counters;
			},
			tabs: function(){
				var counters = this.counters;
				return [
					{label: this.$t("onglet-non-levees"), name:"nonlevees", counter: counters.nonlevees, filters:{
						statut: {attr:"fm.statut",colId: "statut", value:["En_cours", "prise_en_compte", "en_attente"], action:"equals"}
					}},
					{label: this.$t("onglet-levees"), name:"levees", counter: counters.levees, filters:{
						statut: {attr:"fm.statut",colId: "statut", value:"Resolue", action:"equals"}
					}},
				];
			},
		}
    };

</script>
<style scoped lang="scss">
.vg-reserves-viewer{
	>div{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
</style>
