<template>
    <div class="vg-consommable-colisage-selector">
        <vg-select
            v-model="conditionnementColisage"
            :options="getConditionnements"
            @input="onInputColisage" 
            style="min-width:200px;"/>
        <span>{{$t("de")}}</span>
        <vg-input v-model="quantiteColisage"
            @input="onInputColisage"
            style="width:100px;"/>
    </div>
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: 'vg-consommable-colisage-selector',
    mixins:[ConsommablesMixins],
	components: {
		VgSelect,
        VgInput,
        VgButton
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "boite": "boîte",
            "paquet": "paquet",
            "sachet": "sachet",
            "palette": "palette",
            "carton": "carton"
        },
        "en": {
            "boite": "box",
            "paquet": "packet",
            "sachet": "bag",
            "palette": "palette",
            "carton": "carton"
        }
    }
},
    data: function() {
        return {
            conditionnements: [],
            conditionnementColisage: null,
            quantiteColisage: null,
            agfilters: {},
            metadatas: new Metadatas()
        };
    },
    watch:{
        
    },
	created: function(){
        this.fetch();
	},
    methods:{
        fetch: function(){
            let filters = Object.assign({}, this.agfilters, this.filters);
            this.metadatas.setFilters(filters);
            this.ConsommablesMixins_getConsommablesConditionnementsColisage(this.metadatas).then((datas)=>{
                this.conditionnements = datas.datas.filter((d)=>d.conditionnementColisage!=null);
            });
        },
        onInputColisage: function(){
            this.$emit('input', {
                conditionnementColisage: this.conditionnementColisage, 
                quantiteColisage: this.quantiteColisage
            });
        },
    },
    computed:{
        getConditionnements: function(){ // @TODO here à faire fonctionner!
            let localConditionnements = [this.$t('boite'),this.$t('paquet'),this.$t('sachet'),this.$t('palette'),this.$t('carton')];
            let conditionnements = [...this.conditionnements];
            conditionnements = conditionnements.map((c)=>c.conditionnementColisage);
            localConditionnements.forEach((conditionnement)=>{
                if(conditionnements.indexOf(conditionnement)==-1) conditionnements.push(conditionnement);
            });
            return conditionnements;
        }
    }
};

</script>
<style lang="scss" scoped>
.vg-consommable-colisage-selector{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
    span{
        color: rgb(158, 157, 169);
        font-family: "Open Sans";
        font-size: 12px;
    }
}
</style>