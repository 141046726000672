<template>
	<vg-select v-model="selectedCorpsDetat"
		:options="corpsDetats"
		:attributeLabel="'name'"
		:attributeValue="attributeValue"
		:placeholder="placeholder?placeholder:$t('selectionner-un-corpsDetat')"
		@input="handleInput">
	</vg-select>
</template>
<script>
	import VgSelect from "src/components/Vg/VgSelect.vue";

	import CorpsDetatMixins from "src/mixins/CorpsDetatMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-corps-detat-selector',
		components: {
			VgSelect
		},
		mixins: [CorpsDetatMixins],
        props: {
			/**
			* @model
			*/
			value: {
				type: String | Object,
				default: null
			},
			/**
			* attribut de l'objet corpsDetat à retourner comme valeur
			* default id
			* si attributeValue=null retourne l'objet sélectionné
			*/
			attributeValue: {
				type: String,
				default: "id"
			},
			/**
			* selecteur clearable
			*/
			isClearable: {
				type: Boolean,
				default: true
			},
			placeholder: {
				type: String,
				default: null
			}
        },
        data: function() {
            return {
				selectedCorpsDetat: this.value,
				metadatasCorpsDetat: new Metadatas(),
				agfilters: {}
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "selectionner-un-corpsDetat": "Sélectionner un corps D'état"
        },
        "en": {
            "selectionner-un-corpsDetat": "Set a technical field"
        }
    }
},
		watch: {
			value: {
				handler: function(val){
					this.selectedCorpsDetat = val;
				}
			}
		},
		created: function(){
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			fetch: function(){
				this.metadatasCorpsDetat.setFilters(this.agfilters);
				this.CorpsDetatsMixins_getCorpsDetats(this.metadatasCorpsDetat).then((datas)=>{
					// //console.log("TIERS", datas);
					this.$emit("fetch-success", {corpsDetat: datas});
				});
			},
            handleInput:function(selectedCorpsDetat){
                this.$emit('input',selectedCorpsDetat);
                this.$emit('change',selectedCorpsDetat);
            }
		},
        computed: {
			...mapGetters({
                corpsDetats: 'CorpsDetatsStore/getCollection'
            }),
		}
    };

</script>
<style lang="scss" scoped>

</style>
