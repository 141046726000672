<template lang="html">
    <div class="contrat-categorie-cell-render" v-if="params.data.categorie">
        <span>{{ params.data.categorie.libelleCatgorie }}</span>
        <small v-if="params.data.categorie.isGe==0 && params.data.categorie.nbEquipements>0" style="margin-left:5px;">{{ $t("categorie-nb-equipements", {nb: params.data.categorie.nbEquipements}) }}</small>
        <small v-else-if="params.data.categorie.isGe==0 && params.data.categorie.nbEquipements==0" style="margin-left:5px;color:red;"><i class="fas fa-exclamation-triangle" ></i> {{ $t("categorie-aucun-equipement") }}</small>
        <small v-else-if="params.data.categorie.isGe==1 && params.data.categorie.nbEquipements>0" style="margin-left:5px;">{{ $t("categorie-nb-pices", {nb: params.data.categorie.nbEquipements}) }}</small>
        <small v-else-if="params.data.categorie.isGe==1 && params.data.categorie.nbEquipements==0" style="margin-left:5px;color:red;"><i class="fas fa-exclamation-triangle" ></i> {{ $t("categorie-aucune-piece") }}</small>
    </div>
    <div v-else>

    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
  name:"contrat-categorie-cell-render",
  mixins:[LangMixins],
  props:{
        params:{
            type:Object,
            required:true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "categorie-nb-equipements": "{nb} équipements",
            "categorie-aucun-equipement": "aucun équipement",
            "categorie-nb-pices": "{nb} pièces",
            "categorie-aucune-piece": "aucune pièce"
        },
        "en": {
            "categorie-nb-equipements": "{nb} equipments",
            "categorie-aucun-equipement": "no equipment",
            "categorie-nb-pices": "{nb} rooms",
            "categorie-aucune-piece": "no room"
        }
    }
},
    components:{
        
    }
});
</script>

<style lang="scss" scoped>
.contrat-categorie-cell-render{
    
}
</style>
