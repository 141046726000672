/**
 *
 *   Storage Shelf
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */


 export default class StorageShelf {
     /**
      * @constructor
      */
     constructor(name=""){
        this.name = name;
        this.items = {};
     }
     addItem(key, value){
         this.items[key] = value;
         return this;
     }
     getItem(key){
        return this.items[key];
     }
     removeItem(key){
        delete this.items[key];
        return this;
     }
     getName(){
         return this.name;
     }
     setName(name){
        this.name = name;
        return this;
     }
     setItems(items){
        this.items = items;
        return this;
     }
 }