<template>
  <div>
      <!-- DESKTOP -->
      <div id="viewer-maintenance">
          <vg-modal
              :title="isAnUpdateMaintenanceForm?$t('update-maintenance-title'):$t('create-maintenance-title')"
              @close="$emit('close')" >
              <template #body v-if="isAnUpdateMaintenanceForm && equipement">
                  <form
                      ref="maintenance-form"
                      onSubmit="return false;"
                      id="maintenance-form">
                      <vg-input v-if="isDisplayed('path')"
                          v-model="maintenance.equipement.path"
                          :title="$t('localisation')"
                          :is-required-value="true">
                          <vg-lieu-path-viewer v-model="maintenance.equipement.path" onlyPath></vg-lieu-path-viewer>
                          <vg-lieu-path-viewer v-model="maintenance.equipement.path" onlyPiece></vg-lieu-path-viewer>
                      </vg-input>

                      <vg-input :title="$t('typologie')">
                        <vg-typologies-maintenance-selector 
                            v-model="maintenance.typologie"
                            attributeValue="name"
                        />
                      </vg-input>

                      <vg-input
                          :title="$t('corps-detat')"
                        >
                        <vg-corps-detat-selector
                            v-model="maintenance.corpsDetat_id"
                        >
                        </vg-corps-detat-selector>
                      </vg-input>
                      <vg-input v-if="isDisplayed('dateOuvertureSAV')"
                          :title="$t('dateOuvertureSAV')"
                          :is-required-value="true"
                          v-model="dateOuvertureSAV"
                          input-type="datetime-local"
                          @input="handleDateOuvertureChange"
                      />
                      <vg-input v-if="$app.role=='ROLE_ADMIN' && maintenance.statut=='Resolue' && isAnUpdateMaintenanceForm"
                          :min="dateOuvertureSAV"
                          :title="$t('dateFermetureSAV')"
                          :is-required-value="true"
                          v-model="dateFermetureSAV"
                          input-type="datetime-local"
                          @input="handleDateFermetureChange"
                      />
                      <vg-input v-if="isDisplayed('composant')"
                          :title="$t('equipement-composant')"
                          :is-required-value="true">
                          <vg-equipement-composant-select
                              :idLieu="maintenance.equipement.lieux_id"
                              :equipement="maintenance.equipement"
                              :composant="maintenance.composant"
                              @equipement-change="handleEquipmentChange"
                              @composant-change="handleComposantChange">
                          </vg-equipement-composant-select>
                      </vg-input>
                      <vg-input v-if="isDisplayed('commentaireClient')"
                            :title="$t('description')"
                          v-model="maintenance.commentaireClient"
                          input-type="textarea"
                          :is-required-maintenance="true"
                      />
                      <vg-input v-if="isDisplayed('workingTime') && (maintenance.statut=='Resolue' || maintenance.statut=='Supervisor') && $app.role=='ROLE_ADMIN'">
                            <vg-working-time-selector
                                v-model="maintenance.workingTime"
                                :label="$t('field-workingTime')">
                            </vg-working-time-selector>
                      </vg-input>
                      <vg-input v-if="isDisplayed('urgence')"
                            :title="$t('urgence')">
                          <vg-checkbox
                              :defaultChecked="getUrgenceDefaultChecked"
                              @checked="maintenance.urgence=true;"
                              @unchecked="maintenance.urgence=false;">
                          </vg-checkbox>
                      </vg-input>
                      <br>
                      <vg-input v-if="$app.handleFmBloquante && isDisplayed('isBloquant')"
                            :title="$t('bloquant')">
                          <vg-checkbox
                              :defaultChecked="getIsBloquantDefaultChecked"
                              @checked="maintenance.isBloquant=true;"
                              @unchecked="maintenance.isBloquant=false;">
                          </vg-checkbox>
                      </vg-input>
                      <input
                          v-show="false"
                          ref="maintenance-submit"
                          type="submit">
                  </form>
              </template>
                <template #body v-else-if="equipement">
                    <form ref="maintenance-form"
                        onSubmit="return false;"
                        id="maintenance-form">
                        <vg-input :title="$t('equipement')">
                            <span>{{equipement.libel_equipement}}</span>
                            <br>
                            <small>{{equipement.qrCode}}</small>
                        </vg-input>
                        <vg-checkbox :label="$t('urgence')"
                            :defaultChecked="false"
                            @checked="maintenance.urgence=true;"
                            @unchecked="maintenance.urgence=false;" />
                        <vg-checkbox v-if="$app.handleFmBloquante" 
                            :label="$t('bloquant')"
                            :defaultChecked="false"
                            @checked="maintenance.isBloquant=true;"
                            @unchecked="maintenance.isBloquant=false;" />
                        <vg-input :title="$t('libel-problem')">
                            <!-- doit afficher les libellesCategorie de la catégorie de l'équipement + libelleProblem génériques -->
                            <vg-libel-problem-selector v-model="libelProblemSelected"
                                :idCategorie="equipement.categorie.id" />
                        </vg-input>
                        <vg-input :title="$t('description')"
                            v-model="maintenance.commentaireClient"
                            inputType="textarea"
                            />
                        <vg-input :title="$t('demandeur')"
                            v-model="maintenance.demandeur" 
							id="di-demandeur-input"
							name="di-demandeur-input"
							autocomplete="on"
							type="text"/>
                        <input
                            v-show="false"
                            ref="maintenance-submit"
                            type="submit">
                    </form>
                </template>
              <template #footer>
                    <vg-button
                        type="default"
                        @click="$emit('close')">
                        {{ $t("close") }}
                    </vg-button>
                    <vg-button v-if="isAnUpdateMaintenanceForm"
                        type="danger"
                        :disabled="isSaveDisabled || disableSave"
                        @click="submitForm">
                        {{ $t("maintenance-form-save") }}
                    </vg-button>
                    <vg-button v-if="!isAnUpdateMaintenanceForm"
                        type="success"
                        :disabled="isSaveDisabled || disableSave"
                        @click="submitForm">
                        {{ $t("maintenance-form-save") }}
                    </vg-button>
                    <vg-button v-if="!isAnUpdateMaintenanceForm && displayedOpenButton"
                        type="success"
                        :disabled="isSaveDisabled || disableSave"
                        @click="submitForm(true)">
                        {{ $t("maintenance-form-save-and-open") }}
                    </vg-button>
              </template>
          </vg-modal>

      </div>

    <dialog-secured-action
      v-if="isModalConfirmDeleteOpened"
      :valid-response="1"
      @save="handleDeleteMaintenance"
      @close="isModalConfirmDeleteOpened = false"
    >
      <template v-slot:header-title>
        {{ $t("maintenance-form-confirm-suppression") }} <b>{{ maintenance.name }}</b> ?
      </template>
  </dialog-secured-action>
</div>
</template>
<script>
import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";

import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector";
import VgEquipementComposantSelect from "src/components/Vg/Equipements/VgEquipementComposantSelect.vue";
import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
import VgFichesavOrigin from "src/components/Vg/FicheSAV/VgFichesavOrigin.vue";
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgBoxInfo from "src/components/Vg/Forms/VgBoxInfo.vue";
import VgLibelleEditable from "src/components/Vg/Forms/VgLibelleEditable.vue";
import VgFichesavDescription from 'src/components/Vg/FicheSAV/VgFichesavDescription.vue';
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgWorkingTimeSelector from "src/components/Vg/FicheSAV/VgWorkingTimeSelector.vue";
import VgCorpsDetatSelector from "src/components/Vg/Selectors/VgCorpsDetatSelector.vue";
import VgLibelProblemSelector from "src/components/Vg/Selectors/VgLibelProblemSelector.vue";
import VgTypologiesMaintenanceSelector from "src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue";


import { mapGetters } from 'vuex';
export default {
    name: 'vg-maintenance-form',
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgCollapse,
        VgCheckbox,
        DialogSecuredAction,
        VgLieuxSelector,
        VgEquipementComposantSelect,
        VgFichesavOrigin,
        VgDatetimeViewer,
        VgBoxInfo,
        VgLibelleEditable,
        VgFichesavStatutViewer,
        VgFichesavDescription,
        VgLieuPathViewer,
        VgWorkingTimeSelector,
        VgCorpsDetatSelector,
        VgLibelProblemSelector,
        VgTypologiesMaintenanceSelector
    },
    mixins: [ MaintenanceMixins, equipementsMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "localisation": "Localisation",
            "equipement": "Equipement",
            "composant": "Composant",
            "description": "Description du problème",
            "demandeur": "Demandeur",
            "urgence": "Urgence",
            "bloquant": "Bloquant",
            "dans": "Dans",
            "maintenance-form-cancel": "Annuler",
            "maintenance-form-save": "Enregistrer",
            "maintenance-form-save-and-open": "Enregistrer et ouvrir",
            "maintenance-form-update": "Modifier",
            "maintenance-form-delete": "Supprimer",
            "maintenance-form-add": "Ajout",
            "maintenance-form-fonction": "Fonction",
            "maintenance-form-password": "Mot de passe",
            "maintenance-form-parametrage": "Paramétrage des notifications",
            "maintenance-form-confirm-suppression": "Confirmer la suppression",
            "maintenance-form-Restriction-lieux": "Attacher au site",
            "cloturer": "Clôturer",
            "reouvrir": "Ré-ouvrir",
            "composant-equipement": "Composant-Equipement",
            "affecte-a": "Affectation",
            "origin": "Origine",
            "dateOuvertureSAV": "Ouverte le",
            "dateFermetureSAV":"Fermée le",
            "equipement-composant": "Equipement/composant",
            "update-maintenance-title": "Modification de la fiche curative",
            "create-maintenance-title": "Création de la fiche curative",
            "close": "Fermer",
            "field-workingTime": "Durée intervention (en quart d'heure)",
            "corps-detat": "Corps d'état",
            "libel-problem": "Libellé problème",
            "typologie": "Typologie"
        },
        "en": {
            "localisation ": "Location",
            "equipement ": "Equipment",
            "composant ": "Component",
            "description ": "Description",
            "urgence ": "Emergency",
            "bloquant": "Blocking",
            "dans": "In",
            "maintenance-form-cancel": "Cancel",
            "maintenance-form-save": "Save",
            "maintenance-form-save-and-open": "Save and open",
            "maintenance-form-update": "Update",
            "maintenance-form-delete": "Delete",
            "maintenance-form-add": "Add",
            "maintenance-form-confirm-suppression": "Are you sure you want to permanently remove this item",
            "maintenance-form-Restriction-lieux": "Restrict to this site",
            "cloturer": "Close",
            "reouvrir": "Re-open",
            "composant-equipement": "Component-Equipment",
            "affecte-a": "Assignment",
            "origin": "Origin",
            "dateOuvertureSAV": "Opened on the",
            "dateFermetureSAV": "Closed on the",
            "maintenance-form-maintenance": "User",
            "maintenance-form-nom": "Last name",
            "maintenance-form-prenom": "First name",
            "maintenance-form-email": "Email",
            "maintenance-form-telephone": "Phone",
            "maintenance-form-role": "Role",
            "maintenance-form-fonction": "Position",
            "maintenance-form-password": "Password",
            "maintenance-form-parametrage": "Configurate notifications",
            "restriction-site": "Site restriction",
            "alerte_contrat_tacite_reconduction": "Alert contract tacit agreement",
            "rapport_hebdomadaire_verification": "Daily preventive report",
            "rapport_hebdomadaire_maintenance": "Weekly curative report",
            "alerte_fin_contrat": "End of contract alert",
            "alerte_stock_minimum": "Minimal stock alert",
            "alerte_enregistrement_intervention_periodique": "New periodic intervention alert",
            "alerte_sotie_equipement": "Equipment release alert",
            "alerte_deplacement_equipement": "Equipment movement alert",
            "update-maintenance-title": "Update curative sheet",
            "create-maintenance-title": "Create curative sheet",
            "localisation": "Location",
            "equipement": "Equipment",
            "composant": "Component",
            "description": "Problem description",
            "urgence": "Emergency",
            "equipement-composant": "Equipment/component",
            "close": "Close",
            "libel-problem": "Problem",
            "demandeur": "Requester",
            "field-workingTime": "Intervention duration (in quarter of an hour)",
            "corps-detat": "trades",
            "typologie": "Typology"
        },
        "th": {
            "localisation ": "ที่ตั้ง",
            "equipement ": "อุปกรณ์",
            "composant ": "ชิ้นส่วน",
            "description ": "คำอธิบาย",
            "urgence ": "ด่วน",
            "dans": "ใน",
            "maintenance-form-cancel": "ยกเลิก",
            "maintenance-form-save": "บันทึก",
            "maintenance-form-update": "อัปเดต",
            "maintenance-form-delete": "ลบ",
            "maintenance-form-add": "เพิ่ม",
            "maintenance-form-confirm-suppression": "แน่ใจหรือว่าต้องการลบรายการนี้ถาวร",
            "maintenance-form-Restriction-lieux": "หวงห้ามไปยังหน้างานนี้",
            "cloturer": "ปิด",
            "reouvrir": "เปิดใหม่",
            "composant-equipement": "ชิ้นส่วน-อุปกรณ์",
            "affecte-a": "งานที่มอบหมาย",
            "origin": "ต้นกำเนิด",
            "dateOuvertureSAV": "เปิดแล้วเมื่อ",
            "maintenance-form-maintenance": "ผู้ใช้งาน",
            "maintenance-form-nom": "นามสกุล",
            "maintenance-form-prenom": "ชื่อ",
            "maintenance-form-email": "อีเมล",
            "maintenance-form-telephone": "โทรศัพท์",
            "maintenance-form-role": "หน้าที่",
            "maintenance-form-fonction": "ตำแหน่ง",
            "maintenance-form-password": "รหัสผ่าน",
            "maintenance-form-parametrage": "กำหนดค่าการแจ้งเตือน",
            "restriction-site": "การหวงห้ามหน้างาน",
            "alerte_contrat_tacite_reconduction": "แจ้งเตือนข้อตกลงสัญญาปริยาย",
            "alerte_fin_contrat": "การสิ้นสุดของการแจ้งเตือนสัญญา",
            "alerte_stock_minimum": "การแจ้งเตือนสินค้าคงคลังขั้นต่ำ",
            "alerte_enregistrement_intervention_periodique": "การแจ้งเตือนการแทรกแซงตามระยะเวลาใหม่",
            "alerte_sotie_equipement": "การแจ้งเตือนการปล่อยอุปกรณ์",
            "alerte_deplacement_equipement": "การแจ้งเตือนการเคลื่อนย้ายอุปกรณ์",
            "localisation": "ที่ตั้ง",
            "equipement": "อุปกรณ์",
            "composant": "ชิ้นส่วน",
            "description": "คำอธิบายของปัญหา",
            "urgence": "ด่วน",
            "equipement-composant": "อุปกรณ์/ชิ้นส่วน"
        }
    }
},
    props: {
        /**
            * @model
            */
        value:{
            type: Object,
            default:function(){
                return {};
            }
        },
        idEquipement:{
            type: Number | String,
            default: null
        },
        isActiveForm:{
            type:Boolean,
            default:false
        },
        /**
        * displayed attributes.
        */
        displayedFields: {
            type: Array,
            default: null
        },
        displayedOpenButton: {
            type: Boolean,
            default: true
        },
        /**
         * permet de ne pas considérer l'équipement ou la maintenance stocker dans le store
         **/
        skipVueXStorage: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            maintenance:{},
            equipement: null,
            isModalConfirmDeleteOpened:false,
            isClosingFicheSav:false,
            rapportCloture:null,
            libelProblemSelected: null,
            disableSave: false
        };
    },
    computed:{
        ...mapGetters({
            getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance',
            selectedEquipement: "EquipementsStore/getSelectedItem"
        }),
        getUrgenceDefaultChecked: function(){
            return this.maintenance.urgence==1 || this.maintenance.urgence=="1";
        },
        getIsBloquantDefaultChecked: function(){
            return this.maintenance.isBloquant==1 || this.maintenance.isBloquant=="1";
        },
        isAnUpdateMaintenanceForm:function(){
            return this.value ? this.value.hasOwnProperty('id'):false;
        },
        batiment: function(){
            let split = this.maintenance.equipement.path.split("/");
            return split[split.length-3];
        },
        etage: function(){
            let split = this.maintenance.equipement.path.split("/");
            return split[split.length-2];
        },
        piece: function(){
            let split = this.maintenance.equipement.path.split("/");
            return split[split.length-1];
        },
        dateOuvertureSAV: function(){
            let splitted = this.maintenance.dateOuvertureSAV.split(":");
            delete splitted[splitted.length-1];
            let dateOuvertureSAV = splitted.join(":");

            return dateOuvertureSAV.slice(0,-1).replace(" ","T");
        },
        dateFermetureSAV: function(){
            return moment(this.maintenance.dateFermetureSAV).format("YYYY-MM-DD HH:mm");
        },
        labelEquipement:function(){
            return this.maintenance.composant?this.$t("composant"):this.$t("equipement")
        },
        isSaveDisabled: function(){
            if(this.libelProblemSelected && this.libelProblemSelected.length!=0) return false;
            else if(this.maintenance.commentaireClient && this.maintenance.commentaireClient.length!=0) return false;
            else return true;
        }
    },
    created: function() {
        if(this.value && this.value.id) this.maintenance = Object.assign({}, this.maintenance, this.value);
        else this.maintenance = {
            commentaireClient: "",
            urgence: false,
            isBloquant: false,
            origin: null,
            statut: "En_cours",
            demandeur: null,
            userId: this.$app.appID,
            idUser: this.$app.idUser
        };
        if(!this.skipVueXStorage) this.equipement = Object.assign({}, {}, this.selectedEquipement);
        else this.fetchEquipement();
    },
    methods: {
        fetchEquipement: function(){
            this.equipementsMixins_getEquipement(this.idEquipement, {skipVueXStorage: this.skipVueXStorage}).then((equipements)=>{
                this.equipement = Object.assign({}, {}, equipements[0]);
            });
        },
        isDisplayed: function(field){
            return (!this.displayedFields) || (this.displayedFields && this.displayedFields.indexOf(field)!=-1);
        },
        handleDateOuvertureChange:function(e){
            this.maintenance.dateOuvertureSAV = e.replace("T"," ");
        },
        handleDateFermetureChange:function(e){
            this.maintenance.dateFermetureSAV = e.replace("T"," ");
        },
        handleUpdateMaintenance:function(){
            this.disableSave = true;
            this.MaintenanceMixins_update([this.maintenance]).then((maintenance)=>{
                this.$emit("update-maintenance",maintenance);
            });
        },
        handleCreateMaintenance:function(open=false){
            this.disableSave = true;
            this.maintenance.dateOuvertureSAV = moment().format("YYYY-MM-DD HH:mm");
            this.maintenance.materiel_id = this.equipement.id;
            this.maintenance.corpsDetat_id = this.equipement.categorie && this.equipement.categorie.corpsDetat_id?this.equipement.categorie.corpsDetat_id:null;
            this.maintenance.commentaireClient = this.libelProblemSelected?this.libelProblemSelected+" "+this.maintenance.commentaireClient:this.maintenance.commentaireClient;
            this.MaintenanceMixins_createMaintenances([this.maintenance]).then((maintenance)=>{
                if(open) this.$emit("open-maintenance", maintenance[0]);
                else this.$emit("created", maintenance[0]);
            });
        },
        handleDeleteMaintenance:function(){
            this.MaintenanceMixins_delete(this.maintenance).then((maintenance)=>{
                this.$emit("deleted",maintenance);
                this.$emit("close");
                this.isModalConfirmDeleteOpened = false;
            });
        },
        handleEquipmentChange:function(equipement){
            this.maintenance.materiel_id = equipement.id;
            this.maintenance.composant_id = null;
            //this.handleUpdateMaintenance();
        },
        handleComposantChange:function(composant){
            this.maintenance.materiel_id = composant.equipement.id;
            this.maintenance.composant_id = composant.id;
        },
        submitForm:function(open=false){
            this.$refs['maintenance-submit'].click();

            let form = this.$refs['maintenance-form'];
            if(form.checkValidity()
                && (
                    (this.libelProblemSelected && this.libelProblemSelected.length!=0)
                    || (this.maintenance.commentaireClient && this.maintenance.commentaireClient.length!=0)
                )){
                if(this.isAnUpdateMaintenanceForm){
                    this.handleUpdateMaintenance();
                }else{
                    this.handleCreateMaintenance(open);
                }
                this.isActiveForm = false;
            }else{

            }
            return true;

        }
    }
};
</script>
<style lang="scss" scoped>

#viewer-maintenance{
    .label{
        color: #BBBCBE;
        font-size: 14px;
        text-align: right;
        width: 40%;
    }
    .field{
        margin-bottom:15px;
    }

    .equipement-data{
        div{
            margin-top: 8px;
        }
        .name-equipement{
            font-size: 17px;
        }
        .icon-composant{
            display: block;
            margin-top:3px;
        }
    }

}
#viewer-maintenance-mobile{
    .vg-flex{
        display: flex;
        .vg-fichesav-origin{
            margin-right: 7px;
        }
    }
    .vg-fichesav-description{
        white-space: normal;
        display: inline;

    }
    .header-maintenance{
        margin-bottom: 15px;
        padding:0 20px;
        font-size: 1.2em;
    }
}
</style>
