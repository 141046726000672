<template>
    <span v-if="value.flag=='bc-create'">
        {{$t("bc-create")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-canceled'">
        {{$t("bc-canceled")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-rejected'">
        {{$t("bc-rejected")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-demande-validation'">
        {{$t("bc-demande-validation")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-validation-accepted'">
        {{$t("bc-validation-accepted", {validation_name: value.validation.groupeValidateur.name})}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-validation-rejected'">
        {{$t("bc-validation-rejected", {validation_name: value.validation.groupeValidateur.name})}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-validation-complete'">
        {{$t("bc-validation-complete")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-sended'">
        {{$t("bc-sended")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-delivered'">
        {{$t("bc-delivered")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-partially-delivered'">
        {{$t("bc-partially-delivered")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else-if="value.flag=='bc-skip-sending'">
        {{$t("bc-skip-sending")}} {{value.user.nom}} {{value.user.prenom}}
    </span>
    <span v-else>
        {{value.flag}}
    </span>
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name:"bon-de-commande-historique-evenement-cell-render",
        mixins:[LangMixins],
		props: {
            value: {
                type: Object
            }
		},
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "bc-create": "Création du BC par",
            "bc-canceled": "Annulation du bon de commande par",
            "bc-rejected": "Refus du BC par",
            "bc-demande-validation": "Demande validation par",
            "bc-validation-accepted": "Validation {validation_name} acceptée par",
            "bc-validation-rejected": "Validation {validation_name} rejetée par",
            "bc-validation-complete": "Validation du BC complète",
            "bc-sended": "Commande envoyée par",
            "bc-delivered": "Livraison complète saisie par",
            "bc-skip-sending": "Commande non envoyée par email au fournisseur par",
            "bc-partially-delivered": "Livraison partielle saisie par"
        }
    }
},
        data: function(){
            return {

            };
        },
        components: {

        }
    });
</script>

<style lang="scss" scoped>

</style>
