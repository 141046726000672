<template>
	<vg-modal
		:title="$t('title')"
		@close="$emit('close')">
		<template v-slot:body v-if="intervention">
			<!--  -->
			<vg-input>
				<vg-checkbox
					:label="intervention.contrat_id?$t('tous-equipements-contrat'):$t('tous-equipements')"
					:defaultChecked="isAllEquipementsAreAssociate"
					@checked="onCheckedAllEquipements"
					@unchecked="onUncheckedAllEquipements">
				</vg-checkbox>
			</vg-input>
			<hr>
			<div class="equipements-list">
				<div v-for="equipement,index in equipements" :key="index" class="equipement-row">
					<vg-checkbox
						:defaultChecked="isEquipementIsAssociate(equipement)"
						@checked="onCheckedEquipement(equipement)"
						@unchecked="onUncheckedEquipement(equipement)">
						<!--:isDisabled="isEquipementDisabled(equipement)"-->
						<div class="equipement">
							<img :src="equipement.icon" height="20">
							<span>{{equipement.libelEquipement}}</span>
							<small style="color:#9e9da9;">{{ equipement.qrCode }}</small>
						</div>
					</vg-checkbox>
				</div>
			</div>
		</template>
		<template v-slot:footer>
			<vg-button
			   @click="$emit('close')">
			   {{$t("cancel")}}
			</vg-button>
			<vg-button
				:type="'success'"
			   @click="onSaveEquipementsAssocies">
			   {{$t("save")}}
			</vg-button>
		</template>
	</vg-modal>
</template>
<script>
	import InterventionsMixins from "src/mixins/InterventionsMixins.js";
	import equipementsMixins from "src/mixins/equipementsMixins.js";

    
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

    import Metadatas from "src/services/Metadatas.js";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-intervention-equipements-modal',
    	props: {
			/**
			* @model intervention
			*/
			value: {
				type: Object
			}
        },
		mixins: [
			InterventionsMixins,
			equipementsMixins
		],
		components: {
			
			VgInput,
			VgButton,
			VgCheckbox
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Liste des équipements concernés",
            "tous-equipements-contrat": "Tous les équipements du contrat",
            "tous-equipements": "Tous les équipements",
            "save": "Enregistrer",
            "cancel": "Annuler"
        },
        "en": {
            "save": "Save",
            "cancel": "Cancel",
            "title": "Equipment concerned",
            "tous-equipements-contrat": "All equipment in the contract",
            "tous-equipements": "All equipments"
        }
    }
},
        data: function(){
            return {
				intervention: this.value,
				metadatasEquipements: new Metadatas(),
                agfilters: {
                    "isGe": {attr:"e.isGroupEqp",value:1,action:"not_equals"},
                    "idCategorie_id": {attr:"e.idCategorie_id",value:null,action:"equals"},
					"site_path": {attr:"l.path", value: null, action:"start_with"}
                },
				equipements: [],
				equipementsToAssociate: [],
				interventionsEquipementsToDesassociate: []
            };
        },
		watch: {
			value:{
				handler: function(val){
					this.intervention = this.value;
				},
				deep: true
			}
		},
		created: function(){
			this.fetchEquipements();
		},
		mounted: function(){

		},
		methods: {
			isEquipementDisabled: function(equipement){
				return this.intervention.interventionsEquipements.findIndex((ie)=>ie.equipement_id==equipement.id)!=-1;
			},
			onCheckedAllEquipements: function(){
				this.equipementsToAssociate = [...this.equipements];
			},
			onUncheckedAllEquipements: function(){
				this.equipementsToAssociate = [];
			},
			onCheckedEquipement: function(equipement){
				//console.log("CHECKED EQUIPEMENT", equipement);
				// vérifie si l'équipement n'est pas déjà lié à l'intervention pour annuler la desassociation de l'équipement
				let indexInterventionEquipement = this.interventionsEquipementsToDesassociate.findIndex((ie)=>ie.equipement_id==equipement.id);
				if(indexInterventionEquipement!=-1) this.interventionsEquipementsToDesassociate.splice(indexInterventionEquipement, 1);
				else this.equipementsToAssociate.push(equipement); // je l'ajoute dans les équipements à associer
			},
			onUncheckedEquipement: function(equipement){
				//console.log("UNCHECKED EQUIPEMENT", equipement);
				// vérifie si l'équipement n'est pas déjà lié à l'intervention pour le desassocier
				let indexInterventionEquipement = this.intervention.interventionsEquipements.findIndex((ie)=>ie.equipement_id==equipement.id);
				if(indexInterventionEquipement!=-1) this.interventionsEquipementsToDesassociate.push(this.intervention.interventionsEquipements[indexInterventionEquipement]);
				else{ // je supprime l'équipement dans la liste des équipements à associer
					let indexEquipement = this.equipementsToAssociate.findIndex((eq)=>eq.id==equipement.id);
					this.equipementsToAssociate.splice(indexEquipement, 1);
				}
			},
			isEquipementIsAssociate: function(equipement){
				return (this.intervention.interventionsEquipements && this.intervention.interventionsEquipements.findIndex((ie)=>ie.equipement_id==equipement.id)!=-1)
					|| this.equipementsToAssociate.findIndex((eq)=>eq.id==equipement.id)!=-1;
			},
			fetchEquipements: function(){
				//console.log("FETCH EQUIPEMENTS", this.value);
				if(this.intervention.contrat && this.intervention.contrat.categorie) this.agfilters.idCategorie_id.value = this.intervention.contrat.categorie.id;
				if(this.intervention.site_id && this.intervention.site && this.intervention.site.path) this.agfilters.site_path.value = this.intervention.site.path;
				this.metadatasEquipements.setFilters(this.agfilters);
				this.equipementsMixins_getEquipements(this.metadatasEquipements).then((equipements)=>{
					//console.log("FETCH EQUIPEMENTS", equipements);
					this.equipements = equipements.datas;
				});
			},
			onSaveEquipementsAssocies: function(){
				////console.log("LISTE DES EQUIPEMENTS A ASSOCIER", this.isAllEquipementsAreAssociate, this.equipementsToAssociate, this.interventionsEquipementsToDesassociate);
				if(this.isAllEquipementsAreAssociate){ // si tous les equipements sont à associer
					// supprimer toutes les associations equipements de cette intervention
					if(this.intervention.interventionsEquipements){
						this.InterventionsMixins_deleteInterventionsEquipements(this.intervention.interventionsEquipements).then((datas)=>{
							////console.log("AFTER DELETE INTERVENTIONS EQUIPEMENTS", datas);
							this.$emit("save", datas);
						});
					}else this.$emit("save");
				}else if(this.equipementsToAssociate.length==0){ // aucun équipement à associer
					if(this.interventionsEquipementsToDesassociate && this.interventionsEquipementsToDesassociate.length!=0){ // si on a une liste d'équipements à désassocier
						this.InterventionsMixins_deleteInterventionsEquipements(this.interventionsEquipementsToDesassociate).then((datas)=>{
							////console.log("AFTER DELETE INTERVENTIONS EQUIPEMENTS", datas);
							this.$emit("save", datas);
						});
					}else this.$emit("save");
				}else{ // 1 ou plusieurs equipements à associer
					////console.log("UN OU PLUSIEURS EQ A ASSOCIER");
					this.InterventionsMixins_createInterventionsEquipements(this.intervention.id, this.equipementsToAssociate).then((datas)=>{
						////console.log("AFTER CREATE INTERVENTIONS EQUIPEMENTS", datas);
						if(this.interventionsEquipementsToDesassociate && this.interventionsEquipementsToDesassociate.length>0){ // si on a une liste d'équipements à désassocier
							this.InterventionsMixins_deleteInterventionsEquipements(this.interventionsEquipementsToDesassociate).then((datas)=>{
								////console.log("AFTER DELETE INTERVENTIONS EQUIPEMENTS", datas);
								this.$emit("save", datas);
							});
						}else{
							this.$emit("save", datas);
						}
					});
				}
			}
		},
        computed:{
			isAllEquipementsAreAssociate: function(){
				return this.equipements.every((equipement)=>
					(this.intervention.interventionsEquipements && this.intervention.interventionsEquipements.findIndex((ie)=>ie.equipement_id==equipement.id)!=-1)
					|| this.equipementsToAssociate.findIndex((eq)=>eq.id==equipement.id)!=-1
				);
			}
        }
    };
</script>
<style lang="scss" scoped>
.equipements-list{
	.equipement-row{
		.equipement{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
		}
	}
}
</style>
