<template>
	<vg-modal
		:title="$t('modal-title')"
        @close="$emit('close')">
		<template #body>
			<vg-text-filter v-model="searchEtage"
				style="margin-bottom:10px;"/>
			<vg-etages-table
				isDemandeIntervention
				:searchQuery="searchEtage"
				:paginate="false"/>
		</template>
		<template #footer>
			<vg-button @click="$emit('close')">{{$t("btn-close")}}</vg-button>
		</template>
    </vg-modal>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgEtagesTable from "src/components/Vg/Lieu/VgEtagesTable.vue";
	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

	export default {
        name: 'vg-lieu-detail-modal',
    	props: {
			value: {
                type: Object,
                required: true
            }
        },
		components:{
			VgButton,
			VgEtagesTable,
			VgTextFilter
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
			"modal-title": "Plans interactifs",
            "btn-close": "Fermer"
        },
        "en": {
			"modal-title": "Interactive level plans"
        }
    }
},
        data: function() {
            return {
				searchEtage: null
            };
        },
		computed: {

        },
		created: function(){

		}
    };

</script>
<style lang="scss" scoped>

</style>
