<template>
	<vg-modal
		:title="$t('modal-title')"
		:width="'90vw'"
		@close="emitClose()">
		<template #body>
			<div>
				<vg-input :title="$t('contrat')"
					inline >
					<span><b>{{contrat.name}}</b></span>
				</vg-input>
				<br>
				<vg-input :title="$t('restriction-site')"
					inline >
					<vg-lieux-selector v-model="site"
						:attributeValue="null"
						:filters="sitesFilters"/>
				</vg-input>
				<br>
				<vg-input
					:title="$t('date-de-depart')"
					inline >
					<vg-input v-model="dateDepart"
						:inputType="'date'"
						style="width:200px;" />
				</vg-input>
				<br>
				<vg-input
					:title="$t('repeter-tous-les')"
					inline>
					<vg-input v-model="recurrence"
						:inputType="'number'"
						:inputStep="1"
						style="width:100px;" />
					<vg-periode-selector v-model="recurrenceUnite"
						style="width:150px;" />
				</vg-input>
				<vg-input v-if="recurrenceUnite=='week'"
					:title="$t('repeter-le')"
					inline >
					<vg-periode-selector v-model="defaultDayOfWeek"
						week />
				</vg-input>
				<vg-input v-else-if="recurrenceUnite=='month' || recurrenceUnite=='trimestre' || recurrenceUnite=='semestre'"
					:title="$t('tous-les-mois-le')"
					inline >
					<vg-periode-selector v-model="defaultDayOfMonth"
						day
						style="margin-right:15px;width:100px;"/>
					<vg-checkbox v-model="excludeSaturdayAndSunday"
						:label="$t('exclure-samedi-dimanche')"
						:defaultChecked="excludeSaturdayAndSunday"
						@checked="excludeSaturdayAndSunday=true;"
						@unchecked="excludeSaturdayAndSunday=false;" />
				</vg-input>
				<vg-input
					:title="$t('plage-horaire-intervention')"
					inline >
					<vg-input v-model="plageHoraire.debut"
						:inputType="'time'"
						style="width:200px;"
						@input="onChangePlageHoraireDebut"/>
					<vg-input v-model="plageHoraire.fin"
						:inputType="'time'"
						style="width:200px;"/>
				</vg-input>
				<br>
				<vg-input
					:title="$t('se-termine-le')"
					inline >
					<vg-input v-model="dateEnd"
						:inputType="'date'"
						style="width:200px;" />
				</vg-input>
			</div>
			<vg-button :type="'success'"
				style="width:200px;margin-bottom:20px;"
				@click="onClickCalculerDates()" >
				{{$t("calculer-dates")}}
			</vg-button>
			<hr>
			<div>
				<table class="dates-interventions-table">
		            <thead>
		                <tr>
							<th style="width:15%;">{{$t('date-auto-generee')}}</th>
							<th style="width:10%;"></th>
							<th style="width:15%;">{{$t('date-ajustee')}}</th>
							<th style="width:30%;">{{$t('libel-intervention')}}</th>
							<th style="width:5%;">{{$t('rdv-confirme')}}</th>
							<th style="width:25%;">{{$t('commentaire')}}</th>
		                </tr>
		            </thead>
		            <tbody>
		                <tr v-for="(intervention, index) in datesInterventions" :key="index" style="padding:20px;">
							<th style="width:15%;">
								<div style="border:1px solid #dcdfe6;padding:5px;font-size:12px;">
									<vg-datetime-viewer v-model="intervention.dateAutoGeneree"
										:format="'dddd MM/DD/YYYY HH:mm'"/>
									<small>{{intervention.heureDebut}}</small>
								</div>
							</th>
							<th style="width:10%;">
								<vg-button-group style="width:100%;display:flex;justify-content:center;padding-top:10px;">
									<vg-button
										:size="'xs'"
										:type="'default-link'"
										@click="reprendreDate(intervention, index)">
										{{$t("reprendre")}}
									</vg-button>
									<vg-button
										:size="'xs'"
										:type="'default-link'"
										style="margin-left:2px;"
										@click="onClickInterventionDateAjustee(intervention, index)">
										{{$t("ajuster")}}
									</vg-button>
								</vg-button-group>
								<i class="fas fa-long-arrow-alt-right fa-2x" style="color:#3999ff;line-height: 0.5 !important;"></i>
							</th>
							<th style="width:15%;">
								<div v-if="!isUserAjusteCetteDate(intervention, index)"
									@click="onClickInterventionDateAjustee(intervention, index)"
								 	style="border:1px solid #dcdfe6;padding:5px;font-size:12px;cursor:pointer;"
									:class="{'highlight-date':intervention.dateAutoGeneree!=intervention.dateAjustee}">
									<vg-datetime-viewer v-model="intervention.dateAjustee"
										:format="'dddd MM/DD/YYYY HH:mm'"/>
									<small>{{intervention.heureDebut}}</small>
								</div>
								<vg-input v-model="intervention.dateAjustee" v-else
									:inputType="'date'"
									@blur="onChangeDateAjustee"/>
							</th>
							<th style="width:30%;">
								<vg-input v-model="intervention.intitule"
									:placeholder="$t('libel-intervention')"
									style="padding-top:15px;"/>
							</th>
							<th style="width:5%;padding-bottom:15px;padding-left:20px;">
								<vg-checkbox v-model="intervention.confirmed"
									:defaultChecked="intervention.confirmed"/>
							</th>
							<th style="width:25%;">
								<vg-input v-model="intervention.commentaire"
									:placeholder="$t('commentaire')"
									style="padding-top:15px;"/>
							</th>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
		<template #footer>
			<vg-button :type="'danger'" @click="exportPDF()">{{$t("export-pdf")}}</vg-button>
			<vg-interventions-previsionnelles-pdf v-if="showInterventionsPrevisionnellesPDF" v-show="false"
				:contrat="contrat"
				:interventions="getInterventions"
				directDownload
				@after-direct-download="showInterventionsPrevisionnellesPDF=false;"/>
			<vg-button @click="emitClose()">{{$t("cancel")}}</vg-button>
			<vg-button :type="'success'" @click="save" :disabled="isLoading || !datesInterventions || datesInterventions.length==0">{{$t("save", {number: datesInterventions.length})}}</vg-button>
		</template>
	</vg-modal>
</template>
<script>
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgButtonGroup from "src/components/Vg/VgButtonGroup.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
	import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
	import VgPeriodeSelector from "src/components/Vg/Utils/VgPeriodeSelector.vue";
	import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector.vue";

	import VgInterventionsPrevisionnellesPdf from "src/components/Vg/Interventions/VgInterventionsPrevisionnellesPdf.vue";

	import InterventionsMixins from "src/mixins/InterventionsMixins.js";

	import { mapGetters } from 'vuex';

    export default {
        name: 'vg-contrat-generateur-interventions',
		components: {
			VgModal,
			VgInput,
			VgButton,
			VgButtonGroup,
			VgCheckbox,
			VgDatetimeViewer,
			VgPeriodeSelector,
			VgLieuxSelector,
			VgInterventionsPrevisionnellesPdf
		},
		mixins: [InterventionsMixins],
        props: {

        },
        data: function() {
            return {
				dateDepart: null,
				recurrence: 1,
				recurrenceUnite: "month",
				defaultDayOfMonth: 1,
				defaultDayOfWeek: 1,
				excludeSaturdayAndSunday: true,
				dateEnd: null,
				//periodeAPartirDateDepart: 365,
				//periodicite: 31,
				plageHoraire: {
					debut: "08:00",
					fin: "09:00"
				},
				datesInterventions: [],
				dateEnCoursAjustement: null,
				showInterventionsPrevisionnellesPDF: false,
				isLoading: false,
				sitesFilters: {
					type_lieu: {attr:"type_lieu", value: "Site", action: "equals"}
				},
				site: null
            };
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title": "Générer dates prévisionnelles interventions",
            "contrat": "Contrat",
            "date-de-depart": "Date de départ",
            "repeter-tous-les": "Répéter tou(te)s les",
            "tous-les-mois-le": "Tous les mois le",
            "exclure-samedi-dimanche": "Exclure le samedi et dimanche",
            "repeter-le": "Répéter le",
            "se-termine-le": "Se termine le",
            "plage-horaire-intervention": "Plage horaire d'intervention",
            "date-auto-generee": "Date auto-générée",
            "date-ajustee": "Date ajustée",
            "libel-intervention": "Libellé intervention",
            "rdv-confirme": "Rdv confirmé?",
            "reprendre": "Reprendre",
            "ajuster": "Ajuster",
            "intitule-intervention": "Inter",
            "commentaire": "Commentaire",
            "cancel": "Annuler",
            "save": "Créer {number} dates prévisionnelles",
            "export-pdf": "Export PDF",
            "calculer-dates": "Calculer les dates",
            "restriction-site": "Restriction site (optionnel)"
        },
        "en": {
            "modal-title": "Generate intervention forecast dates"
        }
    }
},
		watch: {

		},
		created: function(){
			this.dateDepart = moment().format("YYYY-MM-DD");
			this.setDateEnd();
		},
		mounted: function(){

		},
		methods: {
			onClickCalculerDates: function(){
				this.generateDatesInterventions();
			},
			setDateEnd: function(){
				this.dateEnd = moment(this.dateDepart).add(1, "years").format("YYYY-MM-DD");
			},
			exportPDF: function(){
				this.showInterventionsPrevisionnellesPDF = true;
			},
			onChangePlageHoraireDebut: function(heure){
				this.plageHoraire.fin = moment(this.dateDepart+" "+heure).add(1, "hours").format("HH:mm");
			},
			emitClose: function(){
				this.$emit("close");
			},
			save: function(){
				this.isLoading = true;
				this.InterventionsMixins_create(this.getInterventions).then((datas)=>{
					this.$emit("save", datas);
				});
			},
			reprendreDate: function(dateIntervention, index){
				this.dateEnCoursAjustement = null;
				this.datesInterventions[index].dateAjustee = dateIntervention.dateAutoGeneree;
			},
			onChangeDateAjustee: function(value){
				this.dateEnCoursAjustement = null;
			},
			onClickInterventionDateAjustee: function(dateIntervention, index){
				this.dateEnCoursAjustement = dateIntervention;
			},
			isUserAjusteCetteDate: function(dateIntervention, index){
				if(!this.dateEnCoursAjustement) return false;
				let indexDateEnCoursAjustement = this.datesInterventions.findIndex((intervention)=>intervention.dateAutoGeneree==this.dateEnCoursAjustement.dateAutoGeneree);
				return indexDateEnCoursAjustement==index;
			},
			/**
			* génère l'intitulé de l'intervention "Inter-CEGELEC-08/11/21-8h" "Inter-CEGELEC-08/12/21-8h30"
			* @param String generatedDate date en YYYY-MM-DD
			* @return String
			*/
			generateInterventionIntitule: function(generatedDate){
				let currentDateDebut = moment(generatedDate+" "+this.plageHoraire.debut);
				let currentHeureDebut = currentDateDebut.format("H")+"h";
				if(currentDateDebut.format("mm")!="00") currentHeureDebut += currentDateDebut.format("mm");
				return this.$t("intitule-intervention")+"-"+this.getTiersName+"-"+moment(generatedDate).format("DD/MM/YY")+"-"+currentHeureDebut;
			},
			calculDate: function(currentDate){
				let nextDate = null;
				let recurrenceUnite = this.recurrenceUnite;
				if(recurrenceUnite=="day"){
					nextDate = moment(currentDate).add(this.recurrence, "days");
				}else if(recurrenceUnite=="week"){
					nextDate = moment(currentDate).isoWeekday(this.defaultDayOfWeek);
					if(nextDate.isSameOrBefore(currentDate)) nextDate = nextDate.add(this.recurrence, "week");
				}else if(recurrenceUnite=="month" || recurrenceUnite=="trimestre" || recurrenceUnite=="semestre"){
					let unite = this.getConvertRecurrenceUnite;
					let multiplicateur = this.getMultiplicateurRecurrence;
					let tmpNextDate = moment(currentDate).add(multiplicateur*this.recurrence, unite);
					let nextMonth = tmpNextDate.month();
					let nextMonthYear = tmpNextDate.year();
					let potentialNextDate = moment({year: nextMonthYear, month: nextMonth, date: this.defaultDayOfMonth});
					if(!potentialNextDate.isValid()) potentialNextDate = moment({year: nextMonthYear, month: nextMonth, date: 1}).endOf("month");
					if(this.excludeSaturdayAndSunday){
						if(potentialNextDate.isoWeekday()==6) potentialNextDate.add(2, "days");
						else if(potentialNextDate.isoWeekday()==7) potentialNextDate.add(1, "days");
					}
					nextDate = potentialNextDate;
				}else if(recurrenceUnite=="year"){
					nextDate = moment(currentDate).add(this.recurrence, "years");
				}
				return nextDate.format("YYYY-MM-DD");
			},
			generateDatesInterventions: function(){
				let interventions = [];
				let dateDepart = this.dateDepart;
				let maxDate = this.dateEnd;
				let recurrenceUnite = this.getConvertRecurrenceUnite;
				let multiplicateur = this.getMultiplicateurRecurrence;
				let generatedDate = this.calculDate(dateDepart);
				while(generatedDate<=maxDate){
					interventions.push({
						dateAutoGeneree: generatedDate,
						dateAjustee: generatedDate,
						heureDebut: this.plageHoraire.debut,
						heureFin: this.plageHoraire.fin,
						confirmed: false,
						commentaire: null,
						intitule: this.generateInterventionIntitule(generatedDate),
						site: this.site
					});
					generatedDate = this.calculDate(generatedDate);
				}
				this.datesInterventions = interventions;
			}
		},
        computed: {
			...mapGetters({
                contrat: 'ContratsStore/getSelectedItem'
            }),
			getTiersName: function(){
				return this.contrat.tiers.name.substring(0, 10);
			},
			getInterventions: function(){
				let interventions = this.datesInterventions.map((intervention)=>{
					return {
						tiers_id: this.contrat.tiers.id,
						contrat_id: this.contrat.id,
						datePrevisionnelleDebut: intervention.dateAjustee+" "+intervention.heureDebut,
						datePrevisionnelleFin: intervention.dateAjustee+" "+intervention.heureFin,
						confirmed: intervention.confirmed,
						commentaire: intervention.commentaire,
						intitule: intervention.intitule,
						userId: this.$app.appID,
						status: "intervention-prevue",
						site_id: this.site?this.site.id:null
					};
				});
				return interventions;
			},
			getMultiplicateurRecurrence: function(){
				return this.recurrenceUnite=="semestre"?6:1;
			},
			getConvertRecurrenceUnite: function(){
				let recurrenceUnite = null;
				switch(this.recurrenceUnite){
					case "day":
						recurrenceUnite = "days";
						break;
					case "week":
						recurrenceUnite = "weeks";
						break;
					case "month":
						recurrenceUnite = "months";
						break;
					case "trimestre":
						recurrenceUnite = "quarters";
						break;
					case "semestre":
						recurrenceUnite = "months";
						break;
					case "year":
						recurrenceUnite = "years";
						break;
					default:
						recurrenceUnite = "months";
						break;
				}
				return recurrenceUnite;
			},
		}
    };

</script>
<style lang="scss" scoped>
.dates-interventions-table{
	thead{
		th{
			color: #9e9da9;
			font-family: "Open Sans";
		    font-size: 12px;
		    padding-bottom: 10px;
		}
	}
	tbody{
		th{
			padding-right:5px;
			font-weight: normal;
		}
	}
}
.highlight-date{
	color: #0099ff;
}
</style>
